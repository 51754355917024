import IndigoRoundButton from 'components/Basics/Buttons/IndigoRoundButton';
import TextButton from 'components/components/MarketingHomePage/FiltersSection/Filters/common/TextButton';
import UserType from 'constants/userType';
import { motion } from 'framer-motion';
import useUser from 'hooks/useUser';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import MessageKeys from 'translations/translationKeys/MessageKeys';
import { useSelectedChat } from '../../SelectedChatContext';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const Buttons = ({ id, handle, type }) => {
  const { t } = useTranslation();
  const { user } = useUser();
  const navigate = useNavigate();
  const { openUserInfo } = useSelectedChat();
  const { isMobile } = useResponsiveScreen();

  const ownProfile = user?.id === id;

  // if (type === UserType.Client && !ownProfile) return null;

  return (
    <ButtonsWrapper className="md-hidden">
      <IndigoRoundButton
        padding="0.513rem 1rem"
        className="shadow-none sbh6 cstmBtn"
        onClick={() => {
          if (ownProfile) navigate('/profile-edit');
          else navigate(`/${handle ? `@${handle}` : id}`);
        }}
      >
        {ownProfile
          ? t(MessageKeys.UserInfo.Btn.edit_profile)
          : t(MessageKeys.UserInfo.Btn.visit_profile)}
      </IndigoRoundButton>

      {!ownProfile && type === UserType.Creator && (
        <TextButton
          className="sbh6 cstmBtn"
          onClick={() => {
            navigate(`/messages/${handle ? `@${handle}` : id}`);
            if (isMobile) {
              openUserInfo();
            }
          }}
        >
          {t(GeneralKeys.contact)}
        </TextButton>
      )}
    </ButtonsWrapper>
  );
};

export default Buttons;

const ButtonsWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  .cstmBtn {
    font-weight: 600;
    font-size: 14px;
  }
`;
