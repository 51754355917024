export const DoubleChevronRightIcon = ({ size = 1 }) => (
  <svg
    width={11 * size}
    height={8 * size}
    viewBox="0 0 11 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.41257 7.5396C7.15385 7.79486 6.73804 7.79486 6.47932 7.5396C6.21565 7.27944 6.21565 6.8539 6.47932 6.59374L8.38665 4.71184C8.78353 4.32026 8.78353 3.67974 8.38665 3.28816L6.47932 1.40626C6.21565 1.1461 6.21565 0.720562 6.47932 0.460403C6.73804 0.205136 7.15385 0.205136 7.41257 0.460403L10.2785 3.28816C10.6754 3.67974 10.6754 4.32026 10.2785 4.71184L7.41257 7.5396Z" />
    <path d="M1.41257 7.5396C1.15385 7.79486 0.738038 7.79486 0.479321 7.5396C0.215647 7.27944 0.215646 6.8539 0.479321 6.59374L2.38665 4.71184C2.78353 4.32026 2.78353 3.67974 2.38665 3.28816L0.479321 1.40626C0.215647 1.1461 0.215647 0.720562 0.479321 0.460403C0.738038 0.205136 1.15385 0.205136 1.41257 0.460403L4.27854 3.28816C4.67542 3.67974 4.67542 4.32026 4.27854 4.71184L1.41257 7.5396Z" />
  </svg>
);
