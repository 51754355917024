import dayjs from 'components/utilities/Internationalization/Dayjs/dayjs_config';
import i18n from 'i18next';
var relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

// if time is less then 24 hours, return time formatted as 12:00 AM
// if time is more then 24 hours and less than 48 hours, return yesterday
// if time is more then 48 hours and less then a week, return day of the week
// if time is more then a week, return time from the date
const dateFromNow = (date) => {
  const time = dayjs(date);
  const now = dayjs();
  const diff = now.diff(time, 'day');

  if (diff < 1) {
    return time.format(dayjs.Ls[i18n.language].dateFormat.time_12);
  }

  if (diff < 2) {
    return time.format(dayjs.Ls[i18n.language].dateFormat.yesterday);
  }

  if (diff < 7) {
    return time.format('dddd');
  }

  return time.fromNow();
};

export default dateFromNow;
