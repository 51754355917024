import PlatformIcon from 'components/Basics/PlatformIcon';
import { useCallback, useEffect, useRef, useState } from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';

const InstagramPost = ({ contentId, inView, onInternalRefChange }) => {
  const { t } = useTranslation();

  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  const scrollRef = useRef(null);
  const scrollPostIntoView = useCallback(() => {
    scrollRef.current?.scroll({ top: 54 });
  }, []);

  useEffect(() => {
    if (inView && !alreadyLoaded) {
      setAlreadyLoaded(true);
    }
  }, [inView, alreadyLoaded]);

  return (
    <Item>
      <PostWrapper ref={scrollRef}>
        {alreadyLoaded ? (
          <>
            <InstagramEmbed
              url={`https://www.instagram.com/p/${contentId}/`}
              width="100%"
              embedPlaceholder={
                <PostsLoading>
                  <PlatformIcon platform="instagram" variant="size3" />
                  <span className="h6 m-0 line-height-1.6 text-indigo-100">
                    {t(ProfileKeys.Channel_Contents.loading_post)}
                  </span>
                </PostsLoading>
              }
              captioned={false}
            />

            <PostOverlay
              ref={(node) => {
                if (inView) onInternalRefChange(node);
              }}
            />
          </>
        ) : (
          <PostsLoading ref={() => scrollPostIntoView()}>
            <PlatformIcon platform="instagram" variant="size3" />
            <span className="h6 m-0 line-height-1.6 text-indigo-100">
              {t(ProfileKeys.Channel_Contents.loading_post)}
            </span>
          </PostsLoading>
        )}
      </PostWrapper>
    </Item>
  );
};

export default InstagramPost;

const Item = styled.div`
  width: 360px;
  height: 100%;
  max-height: 516px;
  overflow: hidden;
  flex-shrink: 0;
`;

const PostWrapper = styled.div`
  overflow: scroll;
  width: 100%;
  height: 100%;
  max-height: 516px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const PostOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
`;

const PostsLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-top: 54px;
  height: 516px;
  flex-shrink: 0;
`;
