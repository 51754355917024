import AxiosClient from 'components/utilities/AxiosClient';

const verifiyClientEmail = async ({ clientEmailVerificationId, token }) => {
  const res = await AxiosClient.post('/verifyClentCompanyEmail', {
    clientEmailVerificationId,
    token,
  });

  return res.data;
};

export default verifiyClientEmail;
