import styled from 'styled-components';
import TextButton from '../common/TextButton';
import Tooltip from 'components/Basics/Tooltip';
import { useSubscribersFollowersFilter } from 'components/components/MarketingHomePage/FiltersContext/SubscribersFollowersFilterContext';
import Dropdown from '../common/Dropdown';
import SelectButton from '../common/SelectButton';
import SearchSubscribersFollowers from './SearchSubscribersFollowers';
import { useToggle } from 'react-use';
import TitleButton from '../common/TitleButton';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import FilterKeys from 'translations/translationKeys/FilterKeys';

const SubscribersFollowersFilter = () => {
  const { t } = useTranslation();
  const { subscribersFollowersRanges, selectSubscribersFollowers, selectedSubscribersFollowers } =
    useSubscribersFollowersFilter();
  const [showDropdown, toggleDropdown] = useToggle(false);

  return (
    <Wrapper>
      <Tooltip content={t(FilterKeys.home.subs_and_views.tooltip_subs)}>
        <TitleButton onClick={toggleDropdown}>
          {t(GeneralKeys.subscribers)}
        </TitleButton>
      </Tooltip>

      <Dropdown
        showDropdown={showDropdown}
        toggleDropdown={toggleDropdown}
        translateX="-30%"
        radius={16}
        trigger={
          <ButtonWrap>
            <TextButton onClick={toggleDropdown}>
              {t(selectedSubscribersFollowers.label)}
            </TextButton>
          </ButtonWrap>
        }
      >
        <Items>
          <SearchSubscribersFollowers />

          <Options>
            {subscribersFollowersRanges.map((subscribersFollowers, index) => (
              <SelectButton
                key={index}
                className="h6 mb-0"
                selected={
                  subscribersFollowers.min === selectedSubscribersFollowers.min &&
                  subscribersFollowers.max === selectedSubscribersFollowers.max
                }
                onClick={() => {
                  selectSubscribersFollowers(subscribersFollowers);
                  toggleDropdown();
                }}
                disabled={subscribersFollowers.disabled}
              >
                {t(subscribersFollowers.label)}
              </SelectButton>
            ))}
          </Options>
        </Items>
      </Dropdown>
    </Wrapper>
  );
};

export default SubscribersFollowersFilter;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  position: relative;
`;

const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 1.25rem 1.875rem;
`;

const Options = styled.div`
  display: flex;
  gap: 0.625rem 1.875rem;
  flex-wrap: wrap;
`;
