const dummyData = {
  viewsPerCountry: [
    {
      views: 60,
      country: 'JP',
    },
    {
      views: 70,
      country: 'KOR',
    },
    {
      views: 10,
      country: 'THA',
    },
    {
      views: 70,
      country: 'ESP',
    },
    {
      views: 6,
      country: 'CN',
    },
    {
      views: 2,
      country: 'IN',
    },
    {
      views: 120,
      country: 'US',
    },
  ],
  ageGender: [
    {
      gender: 'female',
      ageGroup: '13-17',
      viewerPercentage: 2,
    },
    {
      gender: 'male',
      ageGroup: '13-17',
      viewerPercentage: 3,
    },
    {
      gender: 'female',
      ageGroup: '18-24',
      viewerPercentage: 8,
    },
    {
      gender: 'male',
      ageGroup: '18-24',
      viewerPercentage: 18,
    },
    {
      gender: 'female',
      ageGroup: '25-34',
      viewerPercentage: 13,
    },
    {
      gender: 'male',
      ageGroup: '25-34',
      viewerPercentage: 25,
    },
    {
      gender: 'female',
      ageGroup: '35-44',
      viewerPercentage: 6,
    },
    {
      gender: 'male',
      ageGroup: '35-44',
      viewerPercentage: 14,
    },
    {
      gender: 'female',
      ageGroup: '45-54',
      viewerPercentage: 32,
    },
    {
      gender: 'male',
      ageGroup: '45-54',
      viewerPercentage: 25,
    },
    {
      gender: 'female',
      ageGroup: '55-64',
      viewerPercentage: 13,
    },
    {
      gender: 'male',
      ageGroup: '55-64',
      viewerPercentage: 21,
    },
    {
      gender: 'female',
      ageGroup: '65+',
      viewerPercentage: 14,
    },
    {
      gender: 'male',
      ageGroup: '65+',
      viewerPercentage: 10,
    },
  ],
  topics: ['Lifestyle', 'Entertainment', 'Technology'],
  topCategories: ['People & Blogs', 'Music', 'Entertainment'],
  topTags: ['walkman', 'sony', 'music', 'headphones', 'earphones'],
  keywords: [
    {
      keyword: 'told',
      score: 64,
    },
    {
      keyword: 'mistake',
      score: 11,
    },
    {
      keyword: 'thought',
      score: 16,
    },
    {
      keyword: 'bad',
      score: 17,
    },
    {
      keyword: 'correct',
      score: 10,
    },
    {
      keyword: 'day',
      score: 54,
    },
    {
      keyword: 'prescription',
      score: 12,
    },
    {
      keyword: 'time',
      score: 77,
    },
    {
      keyword: 'thing',
      score: 45,
    },
    {
      keyword: 'left',
      score: 19,
    },
    {
      keyword: 'pay',
      score: 13,
    },
    {
      keyword: 'people',
      score: 32,
    },
    {
      keyword: 'month',
      score: 22,
    },
    {
      keyword: 'again',
      score: 35,
    },
    {
      keyword: 'review',
      score: 24,
    },
    {
      keyword: 'call',
      score: 38,
    },
    {
      keyword: 'doctor',
      score: 70,
    },
    {
      keyword: 'asked',
      score: 26,
    },
    {
      keyword: 'finally',
      score: 14,
    },
    {
      keyword: 'insurance',
      score: 29,
    },
    {
      keyword: 'week',
      score: 41,
    },
    {
      keyword: 'called',
      score: 49,
    },
    {
      keyword: 'problem',
      score: 20,
    },
    {
      keyword: 'going',
      score: 59,
    },
    {
      keyword: 'help',
      score: 49,
    },
    {
      keyword: 'felt',
      score: 45,
    },
    {
      keyword: 'discomfort',
      score: 11,
    },
    {
      keyword: 'lower',
      score: 22,
    },
    {
      keyword: 'severe',
      score: 12,
    },
    {
      keyword: 'free',
      score: 38,
    },
    {
      keyword: 'better',
      score: 54,
    },
    {
      keyword: 'muscle',
      score: 14,
    },
    {
      keyword: 'neck',
      score: 41,
    },
    {
      keyword: 'root',
      score: 24,
    },
    {
      keyword: 'adjustment',
      score: 16,
    },
    {
      keyword: 'therapy',
      score: 29,
    },
    {
      keyword: 'injury',
      score: 20,
    },
    {
      keyword: 'excruciating',
      score: 10,
    },
    {
      keyword: 'chronic',
      score: 13,
    },
    {
      keyword: 'chiropractor',
      score: 35,
    },
    {
      keyword: 'treatment',
      score: 59,
    },
    {
      keyword: 'tooth',
      score: 32,
    },
    {
      keyword: 'chiropractic',
      score: 17,
    },
    {
      keyword: 'dr',
      score: 77,
    },
    {
      keyword: 'relief',
      score: 19,
    },
    {
      keyword: 'shoulder',
      score: 26,
    },
    {
      keyword: 'nurse',
      score: 17,
    },
    {
      keyword: 'room',
      score: 22,
    },
  ],
};

export default dummyData;
