import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import dayjs from 'dayjs';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSocket } from 'contexts/SocketContext';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import MessageKeys from 'translations/translationKeys/MessageKeys';

const statusVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const OnlineStatus = ({ id, initialLastSeen, hideOnMobile }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();
  const socket = useSocket();

  const [onlineAt, setOnlineAt] = useState(initialLastSeen);

  useEffect(() => {
    setOnlineAt(initialLastSeen);
  }, [initialLastSeen]);

  useEffect(() => {
    const catchOnline = ({ status }) => {
      setOnlineAt(status ? null : dayjs().toISOString());
    };

    socket.on(`online_${id}`, catchOnline);

    return () => {
      socket.off(`online_${id}`, catchOnline);
    };
  }, [socket, id]);

  const isOnline = useMemo(() => !onlineAt, [onlineAt]);

  return (
    <Wrapper className={`${hideOnMobile ? 'hidden md-flex' : ''}`}>
      <h6 className="sbh6 hidden md-inline">
        {t(MessageKeys.UserInfo.Status.offline)}
      </h6>

      <AnimatePresence>
        {isOnline ? (
          <>
            <Online variants={statusVariants}>
              <div className="hidden md-inline">
                {t(MessageKeys.UserInfo.Status.online)}
              </div>
            </Online>
            <div className="md-hidden h9">
              {t(MessageKeys.UserInfo.Status.online)}
            </div>
          </>
        ) : (
          <motion.span
            variants={statusVariants}
            className={`text-sm ${isMobile ? 'h9' : ''}`}
          >
            <div className="md-hidden inline h9">
              {t(MessageKeys.UserInfo.Status.online)}{' '}
            </div>
            {dayjs(onlineAt).fromNow()}
          </motion.span>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default OnlineStatus;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  exit: { opacity: 0, y: 0 },
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (${device.sm}) {
    width: fit-content;
    gap: 0.25rem;
  }
`;

const Online = styled(motion.span).attrs({
  className: 'btn6',
})`
  padding: 0 10px;
  border-radius: 999px;
  background-color: var(--color-green-500);
  color: var(--color-white);

  @media (${device.sm}) {
    padding: 0;
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }
`;
