export const TokenNMissionIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 18}
      height={size * 18}
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_31977_67028)">
        <path d="M6.75 15C5.075 15 3.65625 14.4187 2.49375 13.2562C1.33125 12.0937 0.75 10.675 0.75 9C0.75 7.325 1.33125 5.90625 2.49375 4.74375C3.65625 3.58125 5.075 3 6.75 3C8.425 3 9.84375 3.58125 11.0063 4.74375C12.1687 5.90625 12.75 7.325 12.75 9C12.75 10.675 12.1687 12.0937 11.0063 13.2562C9.84375 14.4187 8.425 15 6.75 15ZM6.75 13.5C8 13.5 9.0625 13.0625 9.9375 12.1875C10.8125 11.3125 11.25 10.25 11.25 9C11.25 7.75 10.8125 6.6875 9.9375 5.8125C9.0625 4.9375 8 4.5 6.75 4.5C5.5 4.5 4.4375 4.9375 3.5625 5.8125C2.6875 6.6875 2.25 7.75 2.25 9C2.25 10.25 2.6875 11.3125 3.5625 12.1875C4.4375 13.0625 5.5 13.5 6.75 13.5ZM6 11.625H7.5V7.875H9V6.75H4.5V7.875H6V11.625ZM14.25 6.75L13.3125 4.6875L11.25 3.75L13.3125 2.8125L14.25 0.75L15.1875 2.8125L17.25 3.75L15.1875 4.6875L14.25 6.75ZM14.25 17.25L13.3125 15.1875L11.25 14.25L13.3125 13.3125L14.25 11.25L15.1875 13.3125L17.25 14.25L15.1875 15.1875L14.25 17.25Z" />
        <path d="M6.75 15C5.075 15 3.65625 14.4187 2.49375 13.2562C1.33125 12.0937 0.75 10.675 0.75 9C0.75 7.325 1.33125 5.90625 2.49375 4.74375C3.65625 3.58125 5.075 3 6.75 3C8.425 3 9.84375 3.58125 11.0063 4.74375C12.1687 5.90625 12.75 7.325 12.75 9C12.75 10.675 12.1687 12.0937 11.0063 13.2562C9.84375 14.4187 8.425 15 6.75 15ZM6.75 13.5C8 13.5 9.0625 13.0625 9.9375 12.1875C10.8125 11.3125 11.25 10.25 11.25 9C11.25 7.75 10.8125 6.6875 9.9375 5.8125C9.0625 4.9375 8 4.5 6.75 4.5C5.5 4.5 4.4375 4.9375 3.5625 5.8125C2.6875 6.6875 2.25 7.75 2.25 9C2.25 10.25 2.6875 11.3125 3.5625 12.1875C4.4375 13.0625 5.5 13.5 6.75 13.5ZM6 11.625H7.5V7.875H9V6.75H4.5V7.875H6V11.625ZM14.25 6.75L13.3125 4.6875L11.25 3.75L13.3125 2.8125L14.25 0.75L15.1875 2.8125L17.25 3.75L15.1875 4.6875L14.25 6.75ZM14.25 17.25L13.3125 15.1875L11.25 14.25L13.3125 13.3125L14.25 11.25L15.1875 13.3125L17.25 14.25L15.1875 15.1875L14.25 17.25Z" />
        <path d="M6.75 15C5.075 15 3.65625 14.4187 2.49375 13.2562C1.33125 12.0937 0.75 10.675 0.75 9C0.75 7.325 1.33125 5.90625 2.49375 4.74375C3.65625 3.58125 5.075 3 6.75 3C8.425 3 9.84375 3.58125 11.0063 4.74375C12.1687 5.90625 12.75 7.325 12.75 9C12.75 10.675 12.1687 12.0937 11.0063 13.2562C9.84375 14.4187 8.425 15 6.75 15ZM6.75 13.5C8 13.5 9.0625 13.0625 9.9375 12.1875C10.8125 11.3125 11.25 10.25 11.25 9C11.25 7.75 10.8125 6.6875 9.9375 5.8125C9.0625 4.9375 8 4.5 6.75 4.5C5.5 4.5 4.4375 4.9375 3.5625 5.8125C2.6875 6.6875 2.25 7.75 2.25 9C2.25 10.25 2.6875 11.3125 3.5625 12.1875C4.4375 13.0625 5.5 13.5 6.75 13.5ZM6 11.625H7.5V7.875H9V6.75H4.5V7.875H6V11.625ZM14.25 6.75L13.3125 4.6875L11.25 3.75L13.3125 2.8125L14.25 0.75L15.1875 2.8125L17.25 3.75L15.1875 4.6875L14.25 6.75ZM14.25 17.25L13.3125 15.1875L11.25 14.25L13.3125 13.3125L14.25 11.25L15.1875 13.3125L17.25 14.25L15.1875 15.1875L14.25 17.25Z" />
        <path d="M6.75 15C5.075 15 3.65625 14.4187 2.49375 13.2562C1.33125 12.0937 0.75 10.675 0.75 9C0.75 7.325 1.33125 5.90625 2.49375 4.74375C3.65625 3.58125 5.075 3 6.75 3C8.425 3 9.84375 3.58125 11.0063 4.74375C12.1687 5.90625 12.75 7.325 12.75 9C12.75 10.675 12.1687 12.0937 11.0063 13.2562C9.84375 14.4187 8.425 15 6.75 15ZM6.75 13.5C8 13.5 9.0625 13.0625 9.9375 12.1875C10.8125 11.3125 11.25 10.25 11.25 9C11.25 7.75 10.8125 6.6875 9.9375 5.8125C9.0625 4.9375 8 4.5 6.75 4.5C5.5 4.5 4.4375 4.9375 3.5625 5.8125C2.6875 6.6875 2.25 7.75 2.25 9C2.25 10.25 2.6875 11.3125 3.5625 12.1875C4.4375 13.0625 5.5 13.5 6.75 13.5ZM6 11.625H7.5V7.875H9V6.75H4.5V7.875H6V11.625ZM14.25 6.75L13.3125 4.6875L11.25 3.75L13.3125 2.8125L14.25 0.75L15.1875 2.8125L17.25 3.75L15.1875 4.6875L14.25 6.75ZM14.25 17.25L13.3125 15.1875L11.25 14.25L13.3125 13.3125L14.25 11.25L15.1875 13.3125L17.25 14.25L15.1875 15.1875L14.25 17.25Z" />
      </g>
    </svg>
  );
};
