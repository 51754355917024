export const SortUpArrowIcon = ({ size = 1 }) => (
  <svg
    width={8 * size}
    height={12 * size}
    viewBox="0 0 8 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 4L4 0L8 4H0Z" />
  </svg>
);
