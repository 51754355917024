export const TelegramPlaneWhiteIcon = ({ size = 1 }) => (
  <svg
    width={20 * size}
    height={18 * size}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 0.97219L16.8367 16.9206C16.8367 16.9206 16.3944 18.0267 15.1778 17.4958L7.87827 11.8995L5.224 10.6166L0.755854 9.11237C0.755854 9.11237 0.0701495 8.86914 0.00373742 8.33826C-0.0626747 7.80738 0.777992 7.51987 0.777992 7.51987L18.5399 0.552134C18.5399 0.552134 19.9999 -0.0892957 19.9999 0.972467"
      fill="white"
    />
    <path
      d="M7.32579 16.7408C7.32579 16.7408 7.11272 16.7208 6.84707 15.8802C6.58183 15.0396 5.23242 10.6158 5.23242 10.6158L15.9605 3.803C15.9605 3.803 16.5799 3.42694 16.5578 3.803C16.5578 3.803 16.6683 3.86941 16.3364 4.17906C16.0047 4.48884 7.90897 11.7661 7.90897 11.7661"
      fill="#CFCFD7"
    />
    <path
      d="M10.6801 14.0449L7.79295 16.6774C7.79295 16.6774 7.56714 16.8486 7.32031 16.7413L7.87319 11.8517"
      fill="white"
    />
  </svg>
);
