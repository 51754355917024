const SettingsKeys = {
  Signed_With: {
    wallet: 'SettingsKeys.Signed_With.wallet',
    google: 'SettingsKeys.Signed_With.google',
    facebook: 'SettingsKeys.Signed_With.facebook',
  },

  Account: {
    connected_email: 'SettingsKeys.Account.connected_email',
  },

  Password: {
    current_password: 'SettingsKeys.Password.current_password',
    new_password: 'SettingsKeys.Password.new_password',
    confirm_new_password: 'SettingsKeys.Password.confirm_new_password',

    Rule: {
      title: 'SettingsKeys.Password.Rule.title',

      rule_1: 'SettingsKeys.Password.Rule.rule_1',
      rule_2: 'SettingsKeys.Password.Rule.rule_2',
      rule_3: 'SettingsKeys.Password.Rule.rule_3',
      rule_4: 'SettingsKeys.Password.Rule.rule_4',
    },

    Error: {
      not_follow_rule: 'SettingsKeys.Password.Error.not_follow_rule',
      characters: 'SettingsKeys.Password.Error.characters',
      not_match: 'SettingsKeys.Password.Error.not_match',
      current_password_incorrect:
        'SettingsKeys.Password.Error.current_password_incorrect',
    },

    update_success: 'SettingsKeys.Password.update_success',
  },

  Notification: {
    Description: {
      orders: 'SettingsKeys.Notification.Description.orders',
      messages: 'SettingsKeys.Notification.Description.messages',
      reminders: 'SettingsKeys.Notification.Description.reminders',
      newsletters: 'SettingsKeys.Notification.Description.newsletters',
      packages: 'SettingsKeys.Notification.Description.packages',
      real_time: 'SettingsKeys.Notification.Description.real_time',
    },
  },

  Referral_Code: {
    My: {
      title: 'SettingsKeys.Referral_Code.My.title',
      description: 'SettingsKeys.Referral_Code.My.description',

      copy_code: 'SettingsKeys.Referral_Code.My.copy_code',
      copied: 'SettingsKeys.Referral_Code.My.copied',
    },

    Enter: {
      title: 'SettingsKeys.Referral_Code.Enter.title',
    },

    Modal: {
      reward_arrive: 'SettingsKeys.Referral_Code.Modal.reward_arrive',
      my_evo: 'SettingsKeys.Referral_Code.Modal.my_evo',
      check_later: 'SettingsKeys.Referral_Code.Modal.check_later',
    },

    Referral_Page: {
      instruction: 'SettingsKeys.Referral_Code.Referral_Page.instruction',
      evo_policy: 'SettingsKeys.Referral_Code.Referral_Page.evo_policy',
      enter_code_here:
        'SettingsKeys.Referral_Code.Referral_Page.enter_code_here',
    },
  },

  Referral_System: {
    title: 'SettingsKeys.Referral_System.title',
    button: 'SettingsKeys.Referral_System.button',

    Refer_A_Friend: {
      lang: 'SettingsKeys.Referral_System.Refer_A_Friend.lang',
    },

    Referral_Reward: {
      lang: 'SettingsKeys.Referral_System.Referral_Reward.lang',
    },
  },

  Deactivate_Delete: {
    title: 'SettingsKeys.Deactivate_Delete.title',

    Q1: {
      title: 'SettingsKeys.Deactivate_Delete.Q1.title',
      description: 'SettingsKeys.Deactivate_Delete.Q1.description',
    },

    Q2: {
      title: 'SettingsKeys.Deactivate_Delete.Q2.title',
      description: 'SettingsKeys.Deactivate_Delete.Q2.description',
    },

    warning: 'SettingsKeys.Deactivate_Delete.warning',
  },

  Modal: {
    Wallet_Connected: {
      title: 'SettingsKeys.Modal.Wallet_Connected.title',
    },

    Notification: {
      Newsletters: {
        title: 'SettingsKeys.Modal.Notification.Newsletters.title',
        description: 'SettingsKeys.Modal.Notification.Newsletters.description',
      },

      Update_Success: {
        title: 'SettingsKeys.Modal.Notification.Update_Success.title',
      },
    },

    Referral_Code: {
      Submit_Success: {
        title: 'SettingsKeys.Modal.Referral_Code.Submit_Success.title',
        description:
          'SettingsKeys.Modal.Referral_Code.Submit_Success.description',
      },

      Submit_Valid: {
        title: 'SettingsKeys.Modal.Referral_Code.Submit_Valid.title',
        description:
          'SettingsKeys.Modal.Referral_Code.Submit_Valid.description',
      },
    },

    Deactivate: {
      title: 'SettingsKeys.Modal.Deactivate.title',
      description: 'SettingsKeys.Modal.Deactivate.description',
    },

    Delete: {
      title: 'SettingsKeys.Modal.Delete.title',
      description: 'SettingsKeys.Modal.Delete.description',
    },

    Complete_Cancel_Order: {
      title: 'SettingsKeys.Modal.Complete_Cancel_Order.title',
      description: 'SettingsKeys.Modal.Complete_Cancel_Order.description',
    },

    Why_Leaving: {
      title: 'SettingsKeys.Modal.Why_Leaving.title',

      reason_1: 'SettingsKeys.Modal.Why_Leaving.reason_1',
      reason_2: 'SettingsKeys.Modal.Why_Leaving.reason_2',
      reason_3: 'SettingsKeys.Modal.Why_Leaving.reason_3',
      reason_4: 'SettingsKeys.Modal.Why_Leaving.reason_4',
      reason_5: 'SettingsKeys.Modal.Why_Leaving.reason_5',
      reason_6: 'SettingsKeys.Modal.Why_Leaving.reason_6',
      reason_7: 'SettingsKeys.Modal.Why_Leaving.reason_7',
      reason_8: 'SettingsKeys.Modal.Why_Leaving.reason_8',

      button: 'SettingsKeys.Modal.Why_Leaving.button',
    },

    Cannot_Deactivate_Delete_Yet: {
      title: 'SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title',
      description:
        'SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description',
    },
  },
};

export default SettingsKeys;
