import FilterKeys from '../translationKeys/FilterKeys';

export const FilterTranslations = {
  // Influencer Marketing Home Page
  [FilterKeys.home.search_keywords]: '검색어를 입력하세요',

  [FilterKeys.home.expand]: '펼치기',
  [FilterKeys.home.collapse]: '접기',

  [FilterKeys.home.more_filter]: '＋ 필터 더보기',
  [FilterKeys.home.less_filter]: '– 필터 접기',

  [FilterKeys.home.try_searching]: '한 번 검색해 보세요',
  [FilterKeys.home.results_for_keyword]:
    '"{{searchText}}"에 대한 결과 {{number}}개',
  [FilterKeys.home.result_for_keyword]:
    '"{{searchText}}"에 대한 결과 {{number}}개',

  // Try Searching
  [FilterKeys.home.Try_Searching.brand_ambassador]: '브랜드 엠버서더',
  [FilterKeys.home.Try_Searching.live_shows]: '라이브 쇼',
  [FilterKeys.home.Try_Searching.product_review]: '제품 리뷰',
  [FilterKeys.home.Try_Searching.game_review]: '게임 리뷰',

  // Sort By
  [FilterKeys.home.sort_by.suggested]: '추천순',
  [FilterKeys.home.sort_by.by_subscribers]: '구독자 수',
  [FilterKeys.home.sort_by.lowest_price]: '가격 오름차순',
  [FilterKeys.home.sort_by.highest_price]: '가격 내림차순',

  // Country
  [FilterKeys.home.country.popular]: '인기 국가',
  [FilterKeys.home.country.selected]: '선택한 국가',
  [FilterKeys.home.country.no_result]: '검색 결과가 없습니다',

  // Price
  [FilterKeys.home.price._50]: '~5만',
  [FilterKeys.home.price._50_100]: '5만~10만',
  [FilterKeys.home.price._100_300]: '10만~30만',
  [FilterKeys.home.price._300_500]: '30만~50만',
  [FilterKeys.home.price._500_1k]: '50만~100만',
  [FilterKeys.home.price._1k_2k]: '100만~200만',
  [FilterKeys.home.price._2k_5k]: '200만~500만',
  [FilterKeys.home.price._5k_]: '500만+',

  // Subscribers
  [FilterKeys.home.subs_and_views.tooltip_subs]: '구독자 또는 팔로워 수',
  [FilterKeys.home.subs_and_views.tooltip_views]: `
    게시물 1개당 기대할 수 있는 180일 <br>
    평균 조회수 또는 노출 수입니다.`,

  [FilterKeys.home.subs_and_views.private]: '비공개',
  [FilterKeys.home.subs_and_views._10k]: '~1만',
  [FilterKeys.home.subs_and_views._10k_100k]: '1만~10만',
  [FilterKeys.home.subs_and_views._100k_1m]: '10만~100만',
  [FilterKeys.home.subs_and_views._1m_]: '100만+',

  // Gender
  [FilterKeys.home.gender.male]: '남성',
  [FilterKeys.home.gender.female]: '여성',

  // Mobile
  [FilterKeys.home.M.filter_by]: '필터:',
  [FilterKeys.home.M.all_categories]: '모든 카테고리',
  [FilterKeys.home.M.all_price_ranges]: '모든 가격 범위',
};
