import { AnimatePresence, motion } from 'framer-motion';
import { useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';

const Dropdown = ({
  showDropdown,
  toggleDropdown,
  trigger,
  className,
  children,
  marginTop,
  translateX,
  left,
  shadow,
  haveBorder = true,
  radius = 15,
  meuuWidth = null,
}) => {
  const ref = useRef(null);
  useClickAway(ref, () => toggleDropdown(false));

  return (
    <Wrapper className={className ? className : ''} ref={ref}>
      {trigger}

      <AnimatePresence>
        {showDropdown && (
          <Menu
            marginTop={marginTop}
            translateX={translateX}
            left={left}
            shadow={shadow}
            radius={radius}
            haveBorder={haveBorder}
            meuuWidth={meuuWidth}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            {children}
          </Menu>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default Dropdown;

const Wrapper = styled.div`
  position: relative;
`;

const Menu = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: ${({ left }) => left || '50%'};
  translate: ${({ translateX }) => translateX || '-50%'} 0;
  margin-top: ${({ marginTop }) => marginTop || '1rem'};
  box-shadow: ${({ shadow }) => shadow || 'var(--shadow-dropdown-art-assets)'};
  z-index: 10;
  background: var(--color-white);
  border: ${({ haveBorder }) =>
    haveBorder && '2px solid var(--color-pink-100)'};
  border-radius: ${({ radius }) => radius}px;
  width: max-content;
  transform-origin: top center;
  min-width: ${({ meuuWidth }) => meuuWidth};
`;
