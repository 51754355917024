import AxiosClient from 'components/utilities/AxiosClient';

const Metric = {
  Views: 'views',
  Likes: 'likes',
  Comments: 'comments',
  Shares: 'shares',
  SubscribersGained: 'subscribersGained',
  SubscribersLost: 'subscribersLost',
  CardClicks: 'cardClicks',
  CardTeaserClicks: 'cardTeaserClicks',
  AnnotationClicks: 'annotationClicks',
  Videos: 'videos',
  AvgViews: 'avgViews',
  AvgLikes: 'avgLikes',
  AvgComments: 'avgComments',
  AvgShares: 'avgShares',
  AvgSubscribers: 'avgSubscribers',
  Engagement: 'engagement',
  PrevVideos: 'prevVideos',
  PrevViews: 'prevViews',
  PrevLikes: 'prevLikes',
  PrevComments: 'prevComments',
  PrevShares: 'prevShares',
  PrevSubscribers: 'prevSubscribers',
  PrevAvgViews: 'prevAvgViews',
  PrevAvgLikes: 'prevAvgLikes',
  PrevAvgComments: 'prevAvgComments',
  PrevAvgShares: 'prevAvgShares',
  PrevAvgSubscribers: 'prevAvgSubscribers',
  PrevEngagement: 'prevEngagement',
};

const getTotal = async ({
  userId,
  handle,
  linksId,
  platformUid,
  interval,
  metrics,
  negateMetrics,
  platform
}) => {
  if (!(userId || handle || linksId || platformUid)||!platform)
    return Promise.reject({
      message: 'Missing required parameters for getTotal',
    });

  const metricsParam =
    metrics && metrics.length > 0
      ? negateMetrics
        ? '-' + metrics.join(',')
        : metrics.join(',')
      : undefined;

  const res = await AxiosClient.get('/youtube/Channel/Total', {
    params: {
      userId,
      handle,
      linksId,
      platformUid,
      interval,
      metrics: metricsParam,
      platform
    },
  });

  return res.data;
};

export default getTotal;

export { Metric };
