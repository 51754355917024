import FilterKeys from '../translationKeys/FilterKeys';

export const FilterTranslations = {
  // Influencer Marketing Home Page
  // Influencer Marketing Home Page
  [FilterKeys.home.search_keywords]: 'キーワードで検索',

  [FilterKeys.home.expand]: 'もっと見る',
  [FilterKeys.home.collapse]: '折りたたむ',

  [FilterKeys.home.more_filter]: '＋ フィルターを追加',
  [FilterKeys.home.less_filter]: '– フィルターを減らす',

  [FilterKeys.home.try_searching]: '次のキーワードで検索してみる',
  [FilterKeys.home.results_for_keyword]:
    '"{{searchText}}"の結果は{{number}}件です',
  [FilterKeys.home.result_for_keyword]:
    '"{{searchText}}"の結果は{{number}}件です',

  // Try Searching
  [FilterKeys.home.Try_Searching.brand_ambassador]: 'ブランドアンバサダー',
  [FilterKeys.home.Try_Searching.live_shows]: 'ライブショー',
  [FilterKeys.home.Try_Searching.product_review]: '商品レビュー',
  [FilterKeys.home.Try_Searching.game_review]: 'ゲームレビュー',

  // Sort By
  [FilterKeys.home.sort_by.suggested]: 'おすすめ',
  [FilterKeys.home.sort_by.by_subscribers]: '登録者数',
  [FilterKeys.home.sort_by.lowest_price]: '価格が安い順',
  [FilterKeys.home.sort_by.highest_price]: '価格が高い順',

  // Country
  [FilterKeys.home.country.popular]: 'よく検索される国',
  [FilterKeys.home.country.selected]: '選択された国',
  [FilterKeys.home.country.no_result]: '結果が見つかりませんでした',

  // Price
  [FilterKeys.home.price._50]: '~$50',
  [FilterKeys.home.price._50_100]: '$50~100',
  [FilterKeys.home.price._100_300]: '$100~300',
  [FilterKeys.home.price._300_500]: '$300~500',
  [FilterKeys.home.price._500_1k]: '$500~1K',
  [FilterKeys.home.price._1k_2k]: '$1K~2K',
  [FilterKeys.home.price._2k_5k]: '$2K~5K',
  [FilterKeys.home.price._5k_]: '$5K+',

  // Subscribers
  [FilterKeys.home.subs_and_views.tooltip_subs]:
    'ユーザーの登録者数またはフォロワー数',
  [FilterKeys.home.subs_and_views.tooltip_views]: `
    180日間の投稿毎に期待できる <br>
    平均の閲覧回数といいねの数`,

  [FilterKeys.home.subs_and_views.private]: '非公開',
  [FilterKeys.home.subs_and_views._10k]: '~10K',
  [FilterKeys.home.subs_and_views._10k_100k]: '10K~100K',
  [FilterKeys.home.subs_and_views._100k_1m]: '100K~1M',
  [FilterKeys.home.subs_and_views._1m_]: '1M+',

  // Gender
  [FilterKeys.home.gender.male]: '男性',
  [FilterKeys.home.gender.female]: '女性',

  // Mobile
  [FilterKeys.home.M.filter_by]: '絞り込む:',
  [FilterKeys.home.M.all_categories]: '全カテゴリー',
  [FilterKeys.home.M.all_price_ranges]: '全価格帯',
};
