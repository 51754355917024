export const CloseIcon = ({ size = 1, color = '#0E0D39' }) => {
  return (
    <svg
      width={size * 18}
      height={size * 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.8 18L0 16.2L7.2 9L0 1.8L1.8 0L9 7.2L16.2 0L18 1.8L10.8 9L18 16.2L16.2 18L9 10.8L1.8 18Z"
        fill={color}
      />
    </svg>
  );
};
