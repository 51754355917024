import AboutKeys from '../translationKeys/AboutPageKeys';

export const AboutPageTranslations = {
  [AboutKeys.Side_Popup.Btn.creator]: `Soy un creador`,
  [AboutKeys.Side_Popup.Btn.client]: `Soy una marca - empresa`,
  [AboutKeys.Side_Popup.Btn1]: 'Ir al sitio principal',

  // Part 1
  [AboutKeys.Part1.Creator.title]: `
    ¡Conéctese con top-  <br>
    empresas de todo <br>
    el mundo!`,
  [AboutKeys.Part1.Creator.subtitle]: `
    1~2 minutos para crear un perfil y <br>
    conocer marcas globales.`,
  [AboutKeys.Part1.Creator.btn]: 'Únete como creador',

  [AboutKeys.Part1.Client.title]: `
    Global Marketing de <br>
    influencers <br>
    Maximice su ROI`,
  [AboutKeys.Part1.Client.subtitle]: `
    Colaboraciones globales sin esfuerzo <br>
    Marketing sencillo y eficaz`,
  [AboutKeys.Part1.Client.btn]: 'Pedidos a influencers',

  // Part 2
  [AboutKeys.Part2.Creator.title]:
    'Oportunidades de colaboración global y herramientas para creadores',

  [AboutKeys.Part2.Creator.Section1.Image.description1]:
    'Colabore con marcas mundiales',
  [AboutKeys.Part2.Creator.Section1.Image.description2]:
    'Auto-exposición  del canal a marcas globales',
  [AboutKeys.Part2.Creator.Section1.Image.description3]:
    'Espacio de chat dedicado a las colaboraciones',
  [AboutKeys.Part2.Creator.Section1.text1]:
    'Conéctese con empresas globales verificadas de más de 40 países',
  [AboutKeys.Part2.Creator.Section1.text2]:
    'Compatible con múltiples plataformas ・ Auto-Traducciones',
  [AboutKeys.Part2.Creator.Section1.text3]:
    'Chat 1:1 en tiempo real con los anunciantes',

  [AboutKeys.Part2.Creator.Section2.Image.description1]:
    'Cree y venda sus paquetes sin esfuerzo',
  [AboutKeys.Part2.Creator.Section2.Image.description2]:
    'Agilización de la revisión de propuestas - pagos',
  [AboutKeys.Part2.Creator.Section2.text1]:
    'Cree y venda automáticamente paquetes que adapten a su canal',
  [AboutKeys.Part2.Creator.Section2.text2]:
    'Compare y acepta ofertas en 1 clic',
  [AboutKeys.Part2.Creator.Section2.text3]:
    'Cuadro de mandos inteligente - Pagos sencillos',

  [AboutKeys.Part2.Creator.Section3.Image.description1]:
    'Acceso a datos completos - estadísticas',
  [AboutKeys.Part2.Creator.Section3.Image.description2]:
    'Potentes análisis de redes sociales',
  [AboutKeys.Part2.Creator.Section3.text1]: 'UI sencilla e intuitiva',
  [AboutKeys.Part2.Creator.Section3.text2]:
    'Obtenga análisis detallados de \\n su canal y contenidos',

  [AboutKeys.Part2.Client.title]:
    'Potentes herramientas de marketing de influencers todo en uno',

  [AboutKeys.Part2.Client.Section1.Image.description1]:
    'Mercado mundial para marcas y creadores',
  [AboutKeys.Part2.Client.Section1.Image.description2]:
    'Chat dedicado a las colaboraciones',
  [AboutKeys.Part2.Client.Section1.text1]:
    'Encuentre creadores mundiales dispuestos a emitir su producto',
  [AboutKeys.Part2.Client.Section1.text2]:
    'Compatible con múltiples plataformas ・Auto-Traducciones',
  [AboutKeys.Part2.Client.Section1.text3]:
    'Chat 1:1 en tiempo real con los creadores',

  [AboutKeys.Part2.Client.Section2.Image.description1]:
    'Paquetes ofrecidos directamente por los creadores',
  [AboutKeys.Part2.Client.Section2.Image.description2]:
    'Gestione fácilmente todos los contenidos pedidos',
  [AboutKeys.Part2.Client.Section2.text1]:
    'Compruebe los precios de los anuncios antes de hacer el pedido',
  [AboutKeys.Part2.Client.Section2.text2]:
    'Entrega de propuestas sin \\n esfuerzo ・Pedidos de anuncios',
  [AboutKeys.Part2.Client.Section2.text3]:
    'Gestione sus pedidos y pagos a través de un panel de control',

  [AboutKeys.Part2.Client.Section3.Image.description1]:
    'Acceso a datos exhaustivos: estadísticas',
  [AboutKeys.Part2.Client.Section3.Image.description2]:
    'Potentes informes de pedidos y análisis de SNS',
  [AboutKeys.Part2.Client.Section3.text1]: 'UI sencilla e intuitiva',
  [AboutKeys.Part2.Client.Section3.text2]:
    'Obtenga análisis detallados de sus pedidos y contenidos',
  [AboutKeys.Part2.Client.Section3.text2_sub]:
    '* Puede que no se incluyan todos los tipos de contenido',

  // Part 3

  // Part 4

  // Part 5
  [AboutKeys.Part5.title]: 'Puntos y Ventajas EVO',
  [AboutKeys.Part5.text1]: 'Completa misiones para recibir EVOs',
  [AboutKeys.Part5.text2]: 'Productos personalizados de creador exclusivo',
  [AboutKeys.Part5.text3]:
    'Eventos offline que conectan a creadores, fans y marcas',

  // Feedback
  [AboutKeys.Feedback.Creator1.name]: '50,000+ suscriptores en Youtube',
  [AboutKeys.Feedback.Creator1.comment]:
    '¡He explorado a fondo MegaEvolution y la he encontrado como una plataforma excelente! No tenía un canal adecuado para comunicarme con los anunciantes, ¡pero gracias al sitio, estoy satisfecho de poder comunicarme sin esfuerzo!',
  [AboutKeys.Feedback.Creator2.name]:
    '1,500,000+ seguidores en TikTok · Instagram',
  [AboutKeys.Feedback.Creator2.comment]:
    'MegaEvolution está muy por encima de cualquier otra plataforma de mercado para creadores que he usado. Llevo más de 3.5 años siendo creador de contenido y he acumulado más de 1.5 millones de seguidores, así que he utilizado varias plataformas de conexión entre creadores y marcas. Mi experiencia con MegaEvolution ha sido la más fluida y efectiva de todas las que he probado. No solo la interfaz es extremadamente amigable y fluida, sino que este sistema de paquetes por niveles me permite diversificar realmente mis ofertas a las marcas. Estoy muy agradecido de haberla encontrado y honestamente desearía haberla descubierto antes 😄',
  [AboutKeys.Feedback.Creator3.name]:
    '50,000+ suscriptores en Youtube · Creador en Instagram',
  [AboutKeys.Feedback.Creator3.comment]:
    'MegaEvolution es un lugar realmente necesario para creadores como yo. Los creadores nuevos/existentes sin agencias ni siquiera saben dónde conseguir publicidad. ¡Me alegra que muchos creadores puedan recibir ayuda a través de MegaEvolución!',
  [AboutKeys.Feedback.Creator4.name]: '150,000+ suscriptores en Youtube',
  [AboutKeys.Feedback.Creator4.comment]:
    'Como propietario de un canal, la plataforma de MegaEvolution ofrece excelentes condiciones porque simplemente crear un perfil puede llevar a oportunidades de colaboración con empresas.',
  [AboutKeys.Feedback.Creator5.name]:
    '100,000+ suscriptores en Youtube · Instagram',
  [AboutKeys.Feedback.Creator5.comment]:
    'La ayuda que MegaEvolution me dio el año pasado aumentó mi confianza, gracias a lo cual volví a hacer YouTube. ¡Y mira dónde he llegado! (¡Mi canal creció tres veces desde entonces!)',
  [AboutKeys.Feedback.Creator6.name]: 'Youtube Creador de Videojuegos',
  [AboutKeys.Feedback.Creator6.comment]:
    'Estoy realmente impresionado por el servicio para creadores de contenido aquí, ¡y estoy seguro de que tanto aquellos que actualmente usan la plataforma como aquellos que la usarán en el futuro se beneficiarán enormemente de los servicios proporcionados por MegaEvolution!',
  [AboutKeys.Feedback.Creator7.name]:
    '30,000+ suscriptores en Youtube (Tech · Videojuegos)',
  [AboutKeys.Feedback.Creator7.comment]:
    '¡El servicio verificado por Google de la plataforma y la conexión directa con marcas globales sin contratos complejos me atrajeron especialmente!',
  [AboutKeys.Feedback.Creator8.name]: '50,000+ suscriptores en Youtube',
  [AboutKeys.Feedback.Creator8.comment]:
    'Me hablaron de esta plataforma por uno de mis amigos y descubrí que MegaEvolution es muy exitosa entre mis otros amigos YouTubers.',
  [AboutKeys.Feedback.Creator9.name]:
    '50,000+ suscriptores en YouTube · Instagram · TikTok Creador de belleza',
  [AboutKeys.Feedback.Creator9.comment]:
    '¡Estoy emocionado por MegaEvolution! ¡Es una gran plataforma para creadores y realmente me gusta el diseño de todo!',
};
