import { Package } from './dataTypes';

const PackageWeight = {
  [Package.Title]: 20,
  [Package.FeaturedContent]: 20,
  [Package.Description]: 20,
  [Package.Price]: 15,
  [Package.Delivery]: 15,
  [Package.TypeLength]: 10,
};

const getPackageCompletion = (currentPackage, platform, getPackageError) => {
  if (!currentPackage || !platform) return 0;

  const packageWeight = PackageWeight;

  const packageCompletion = Object.keys(currentPackage).reduce((acc, key) => {
    if (!packageWeight[key]) return acc;

    const errors = getPackageError?.(key) || '';

    if (key === Package.Description) {
      try {
        // to avoid case when description is a document (Template)
        if (currentPackage[key] instanceof Document)
          return (
            acc + (currentPackage[key] && !errors ? packageWeight[key] : 0)
          );

        const description = JSON.parse(currentPackage[key]);

        const length = description.length;

        return acc + (length && !errors ? packageWeight[key] : 0);
      } catch (e) {
        return acc;
      }
    }
    if (key === Package.FeaturedContent) {
      return acc + (currentPackage[key] && !errors ? packageWeight[key] : 0);
    }
  
    if (key === Package.Price) {
      if (
        currentPackage[Package.PriceType] !== 'private' &&
        !currentPackage[key]
      )
        return acc;

      return acc + (!errors ? packageWeight[key] : 0);
    }
    if (typeof currentPackage[key] === 'string') {
      return acc + (currentPackage[key] && !errors ? packageWeight[key] : 0);
    }
    if (Array.isArray(currentPackage[key])) {
      return (
        acc +
        (currentPackage[key].length &&
        currentPackage[key].some(Boolean) &&
        !errors?.length &&
        !errors?.some(Boolean)
          ? packageWeight[key]
          : 0)
      );
    }
    if (typeof currentPackage[key] === 'boolean') {
      return acc + (!errors ? packageWeight[key] : 0);
    }
    return acc;
  }, 0);

  return packageCompletion;
};

export default getPackageCompletion;
