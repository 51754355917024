import { useRef } from 'react';
import { useClickAway, useEvent } from 'react-use';
import styled from 'styled-components';

const AuthModal = ({ show, onClose, children, className }) => {
  const ref = useRef();
  useEvent('keydown', (e) => {
    if (e.keyCode === 27) {
      if (show) onClose();
    }
  });

  return (
    <div className={className}>
      <Backdrop isOpen={show} onClick={(e) => e.stopPropagation()} />
      <Container
        isOpen={show}
        onMouseDown={(e) => {
          if (show && !ref.current.contains(e.target)) {
            onClose();
          }
        }}
      >
        <ModalWrapper>
          <div ref={ref} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </ModalWrapper>
      </Container>
    </div>
  );
};

export default AuthModal;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1102;
  animation: fadeIn 200ms ease-in-out;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  margin: 0;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  animation: fadeIn 250ms ease-in-out;
  z-index: 1103;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
`;
