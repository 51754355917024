export const AutoTranslationIcon = ({ size = 1 }) => (
  <svg
    width={24 * size}
    height={24 * size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M19.0573 18.6699L16.9173 14.3999L14.7773 18.6699"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.1699 17.9097H18.6899"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9218 22.0008C14.1218 22.0008 11.8418 19.7307 11.8418 16.9207C11.8418 14.1207 14.1118 11.8408 16.9218 11.8408C19.7218 11.8408 22.0018 14.1107 22.0018 16.9207C22.0018 19.7307 19.7318 22.0008 16.9218 22.0008Z"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.01805 2.00049H8.93805C11.008 2.00049 12.008 3.00051 11.958 5.02051V8.94049C12.008 11.0105 11.008 12.0105 8.93805 11.9605H5.01805C2.99805 12.0005 1.99805 11.0005 1.99805 8.93048V5.0105C1.99805 3.0005 2.99805 2.00049 5.01805 2.00049Z"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.01117 5.8501H4.95117"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.97266 5.17041V5.8504"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.98945 5.83936C7.98945 7.58936 6.61945 9.00935 4.93945 9.00935"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.01015 9.0105C8.28015 9.0105 7.62016 8.62049 7.16016 8.00049"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.99805 15C1.99805 18.87 5.12805 22 8.99805 22L7.94805 20.25"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 9.00049C22 5.13049 18.87 2.00049 15 2.00049L16.05 3.75049"
      strokeWidth="1.35"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
