import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: '自动翻译',
  [AutoTranslationKeys.Dropdown.description]: '自动翻译描述和用户编写的文本。',

  [AutoTranslationKeys.Applying_Translation.title]: '已应用自动翻译。',
  [AutoTranslationKeys.Applying_Translation.btn]: '显示原始文本',

  [AutoTranslationKeys.Displaying_Original.title]: '显示原始文本。',
  [AutoTranslationKeys.Displaying_Original.btn]: '翻译',
};
