import BillingKeys from '../translationKeys/BillingPageKeys';

export const BillingTranslations = {
  [BillingKeys.Payment_Subscription.title]: 'การชำระเงินและการสมัครสมาชิก',
  [BillingKeys.Payment_Subscription.Method.title]: 'วิธีการชำระเงิน',
  [BillingKeys.Payment_Subscription.Method.btn]: 'เพิ่มบัตรเครดิตหรือเดบิต',
  [BillingKeys.Payment_Subscription.Side.my_plan]: 'แผนของฉัน',
  [BillingKeys.Payment_Subscription.Side.renews_on]: 'ต่ออายุเมื่อ',

  [BillingKeys.Billing_History.title]: 'ประวัติการเรียกเก็บเงิน',
  [BillingKeys.Billing_History.search]: 'ค้นหาตามชื่อผู้สร้างหรือบริการ',

  [BillingKeys.MEGA_Balance.title]: 'ยอดเงินคงเหลือ MEGA',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.title]: 'วิธีการถอนเงิน',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with]:
    'ถอนด้วย <icon></icon>',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon]: 'เร็ว ๆ นี้',
  [BillingKeys.MEGA_Balance.Balance_Summary.title]: 'สรุปยอดเงินคงเหลือ',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance]:
    'ยอดเงินคงเหลือที่ใช้ได้ทั้งหมด',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn]:
    'ยอดเงินที่ถอนไปแล้วทั้งหมด',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment]:
    'การชำระเงินที่รอดำเนินการ',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip]: `
      ซึ่งรวมถึงการชำระเงินจากคำสั่งซื้อ<br>
      ที่กำลังดำเนินการอยู่ ขณะนี้ เงินที่รอดำเนินการ<br>
      จะสามารถถอนได้หลังจากคำสั่งซื้อเสร็จสิ้น 24 วัน<br><br>
      กรุณาตรวจสอบให้แน่ใจว่าคอนเทนต์ที่สั่งซื้อ<br>
      จะไม่ถูกลบหรือตั้งเป็นส่วนตัวเป็นเวลา<br>
      อย่างน้อย 60 วันหลังจากอัปโหลด <TooltipLink>เรียนรู้เพิ่มเติม</TooltipLink>`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount]:
    'จำนวนเงินที่ถอน',
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.details]: `
      สำหรับการถอนเงินแต่ละครั้ง จะมีค่าธรรมเนียม PayPal 2.0% (ค่าธรรมเนียมสูงสุดคือ 20 USD)<br>
      การโอนเงินอาจใช้เวลาถึง 3~5 วันทำการ`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.btn]: 'ถอนยอดเงินคงเหลือ',
};
