export const CrossIconRounded = ({ size = 1, width, height }) => (
  <svg
    width={width ? width : 14 * size}
    height={height ? height : 14 * size}
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.23373 6.99925L13.7428 1.4989C13.9075 1.33424 14 1.11091 14 0.878038C14 0.645168 13.9075 0.421835 13.7428 0.257171C13.5782 0.0925073 13.3548 0 13.122 0C12.8891 0 12.6658 0.0925073 12.5011 0.257171L7.00075 5.76627L1.5004 0.257171C1.33573 0.0925073 1.1124 2.06752e-07 0.87953 2.08487e-07C0.64666 2.10222e-07 0.423328 0.0925073 0.258664 0.257171C0.0939997 0.421835 0.00149256 0.645168 0.00149256 0.878038C0.00149256 1.11091 0.0939997 1.33424 0.258664 1.4989L5.76776 6.99925L0.258664 12.4996C0.176702 12.5809 0.111647 12.6776 0.0672522 12.7842C0.022857 12.8907 0 13.005 0 13.1205C0 13.2359 0.022857 13.3502 0.0672522 13.4568C0.111647 13.5633 0.176702 13.66 0.258664 13.7413C0.339956 13.8233 0.436672 13.8884 0.543233 13.9327C0.649794 13.9771 0.764091 14 0.87953 14C0.994969 14 1.10927 13.9771 1.21583 13.9327C1.32239 13.8884 1.4191 13.8233 1.5004 13.7413L7.00075 8.23224L12.5011 13.7413C12.5824 13.8233 12.6791 13.8884 12.7857 13.9327C12.8922 13.9771 13.0065 14 13.122 14C13.2374 14 13.3517 13.9771 13.4583 13.9327C13.5648 13.8884 13.6615 13.8233 13.7428 13.7413C13.8248 13.66 13.8898 13.5633 13.9342 13.4568C13.9786 13.3502 14.0015 13.2359 14.0015 13.1205C14.0015 13.005 13.9786 12.8907 13.9342 12.7842C13.8898 12.6776 13.8248 12.5809 13.7428 12.4996L8.23373 6.99925Z" />
  </svg>
);
