export const YoutubeShadowIcon = ({ size = 1 }) => (
  <svg
    width={36 * size}
    height={26 * size}
    viewBox="0 0 36 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_29647_35527)">
      <path
        d="M32.3382 4.04028C32.1661 3.4526 31.8303 2.91685 31.3645 2.48636C30.8986 2.05588 30.3188 1.74569 29.6829 1.58669C27.3546 1 17.9849 1 17.9849 1C17.9849 1 8.61477 1.0178 6.28683 1.6045C5.65091 1.76352 5.07117 2.07372 4.60533 2.5042C4.13949 2.93468 3.80382 3.47043 3.63173 4.05809C2.9275 7.88091 2.65429 13.7056 3.651 17.3753C3.82309 17.963 4.15876 18.4987 4.6246 18.9292C5.09043 19.3597 5.67018 19.6699 6.3061 19.8289C8.63421 20.4156 18.0041 20.4156 18.0041 20.4156C18.0041 20.4156 27.374 20.4156 29.7021 19.8289C30.3381 19.6699 30.9178 19.3597 31.3836 18.9292C31.8495 18.4987 32.1852 17.963 32.3572 17.3753C33.0998 13.5472 33.3288 7.72609 32.338 4.04045L32.3382 4.04028Z"
        fill="#FF0000"
      />
      <path
        d="M14.8984 14.5483L22.6714 10.3879L14.8984 6.22729V14.5483Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_29647_35527"
        x="0"
        y="0"
        width="36"
        height="25.4155"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_29647_35527"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_29647_35527"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
