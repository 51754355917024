import { createContext, useCallback, useContext, useState } from 'react';
import { formatNumber } from 'components/utilities/stringFormatters';
import { useMap } from 'react-use';
import { usePlatformFilter } from './PlatformFilterContext';
import { useEffectOnce } from 'react-use';
import FilterKeys from 'translations/translationKeys/FilterKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { useMemo } from 'react';

const subscribersFollowersFilterContext = createContext();

const subscribersFollowersRanges = [
  {
    label: GeneralKeys.all,
    min: 0,
    max: 'Infinity',
  },
  {
    label: FilterKeys.home.subs_and_views._10k,
    min: 0,
    max: 10000,
  },
  {
    label: FilterKeys.home.subs_and_views._10k_100k,
    min: 10000,
    max: 100000,
  },
  {
    label: FilterKeys.home.subs_and_views._100k_1m,
    min: 100000,
    max: 1000000,
  },
  {
    label: FilterKeys.home.subs_and_views._1m_,
    min: 1000000,
    max: 'Infinity',
  },
];

// if last character is T and the number is more than 3 digits change it like 111..T
const shrinkNumber = (number) => {
  const lastChar = number.slice(-1);
  const numberWithoutLastChar = number.replace(/[^0-9]/g, '');

  if (lastChar === 'T' && numberWithoutLastChar.length > 3) {
    return numberWithoutLastChar.slice(0, 3) + '..' + lastChar;
  }

  return number;
};

const SubscribersFollowersFilterProvider = ({ children }) => {
  const { selectedPlatform, onPlatformChange } = usePlatformFilter();

  const [selectedSubscribersFollowers, setSelectedSubscribersFollowers] = useState(
    subscribersFollowersRanges[0]
  );
  const [
    customSubscribersFollowers,
    { set: setCustomSubscribersFollowers, reset: resetCustomSubscribersFollowers },
  ] = useMap({
    min: '',
    max: '',
  });

  useEffectOnce(() => {
    return onPlatformChange(() => {
      resetSubscribersFollowers();
    });
  });

  const selectSubscribersFollowers = useCallback(
    (subscribersFollowers) => {
      setSelectedSubscribersFollowers(subscribersFollowers);
      resetCustomSubscribersFollowers();
    },
    [resetCustomSubscribersFollowers]
  );

  const resetSubscribersFollowers = useCallback(() => {
    setSelectedSubscribersFollowers(subscribersFollowersRanges[0]);
    resetCustomSubscribersFollowers();
  }, [resetCustomSubscribersFollowers]);

  const setCustomMin = useCallback(
    (min) => {
      setCustomSubscribersFollowers('min', min);
    },
    [setCustomSubscribersFollowers]
  );

  const setCustomMax = useCallback(
    (max) => {
      setCustomSubscribersFollowers('max', max);
    },
    [setCustomSubscribersFollowers]
  );

  const applyCustomSubscribersFollowers = useCallback(() => {
    const min = parseInt(customSubscribersFollowers.min) || 0;
    const max = parseInt(customSubscribersFollowers.max) || 'Infinity';

    if ((!min && min !== 0) || !max || min > max) return;

    setSelectedSubscribersFollowers({
      label: `${
        min
          ? shrinkNumber(
              formatNumber(min, {
                compact: true,
              })
            )
          : ''
      }${
        max && max !== 'Infinity'
          ? `~${shrinkNumber(
              formatNumber(max, {
                compact: true,
              })
            )}`
          : '+'
      }`,
      min,
      max,
    });
  }, [customSubscribersFollowers]);

  const data = useMemo(
    () => ({
      subscribersFollowersRanges: subscribersFollowersRanges.filter(
        (range) => !range.hideWhen || range.hideWhen !== selectedPlatform?.value
      ),
      selectedSubscribersFollowers,
      selectSubscribersFollowers,
      resetSubscribersFollowers,
      customSubscribersFollowers,
      setCustomMin,
      setCustomMax,
      applyCustomSubscribersFollowers,
    }),
    [
      selectedPlatform,
      selectedSubscribersFollowers,
      selectSubscribersFollowers,
      resetSubscribersFollowers,
      customSubscribersFollowers,
      setCustomMin,
      setCustomMax,
      applyCustomSubscribersFollowers,
    ]
  );

  return (
    <subscribersFollowersFilterContext.Provider value={data}>
      {children}
    </subscribersFollowersFilterContext.Provider>
  );
};

const useSubscribersFollowersFilter = () => {
  return useContext(subscribersFollowersFilterContext);
};

export { SubscribersFollowersFilterProvider, useSubscribersFollowersFilter };
