import BillingKeys from '../translationKeys/BillingPageKeys';

export const BillingTranslations = {
  [BillingKeys.Payment_Subscription.title]: 'Pago y Suscripción',
  [BillingKeys.Payment_Subscription.Method.title]: 'Métodos de Pago',
  [BillingKeys.Payment_Subscription.Method.btn]:
    'Añadir una tarjeta de crédito o débito',
  [BillingKeys.Payment_Subscription.Side.my_plan]: 'Mi Plan',
  [BillingKeys.Payment_Subscription.Side.renews_on]: 'Renueva el',

  [BillingKeys.Billing_History.title]: 'Historial de Facturación',
  [BillingKeys.Billing_History.search]:
    'Buscar por creador o nombre del servicio',

  [BillingKeys.MEGA_Balance.title]: 'Saldo MEGA',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.title]: 'Método de Retiro',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with]:
    'Retirar con <icon></icon>',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon]: 'Próximamente',
  [BillingKeys.MEGA_Balance.Balance_Summary.title]: 'Resumen de Saldo',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance]:
    'Saldo Total Disponible',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn]: 'Total Retirado',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment]: 'Pago Pendiente',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip]: `
      Esto incluye pagos de pedidos<br>
      actualmente en progreso. Los fondos pendientes<br>
      estarán disponibles para retiro 24 días<br>
      después de que se complete el pedido.<br><br>
      Asegúrese de que los contenidos pedidos<br>
      no se eliminen ni se hagan privados durante<br>
      al menos 60 días después de subidos. <TooltipLink>Más información</TooltipLink>`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount]:
    'Cantidad de Retiro',
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.details]: `
      Por cada retiro se aplicará una tarifa de PayPal del 2.0% (límite de tarifa es 20 USD).<br>
      Las transferencias pueden tardar hasta 3~5 días hábiles.`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.btn]: 'Retirar Saldo',
};
