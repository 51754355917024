import styled from 'styled-components';

const SelectButton = styled.button`
  height: 1.875rem;
  border: none;
  background: ${({ selected }) =>
    selected ? 'var(--gradient-violet)' : 'transparent'};
  color: var(--color-indigo-500);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: ${({ selected }) => (selected ? '15px' : '0')};
  border-radius: 5rem;
  transition: all 150ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;

export default SelectButton;
