import { UserInfo, Package } from './dataTypes';
import UserType from 'constants/userType';
import Platform from 'constants/platform';
import PackageType from 'constants/packageType';
import contentTypes from 'constants/contentTypes';

const getInitialValues = (userType, data) => {
  const user = data?.user || {};
  const packages = data?.packages || {};
  return {
    [UserType.Client]: {
      userInfo: {
        [UserInfo.Name]: user?.name || '',
        [UserInfo.CompanyLogo]: user?.companyLogo || '',
        [UserInfo.Company]: user?.company || '',
        [UserInfo.Country]: user?.countries?.[0]?.value || '',
        [UserInfo.Website]: user?.website || '',
        [UserInfo.Bio]: user?.bio,
        [UserInfo.BannerShade]: user?.bannerShade === 1,
      },
    },
    [UserType.Creator]: {
      userInfo: {
        [UserInfo.Name]: user?.name || '',
        [UserInfo.Handle]: user?.handle || '',
        [UserInfo.Bio]: user?.bio,
        [UserInfo.Categories]: user?.categories || [],
        [UserInfo.Countries]:
          user?.countries?.map((country) => country.value) || [],
        [UserInfo.Languages]:
          user?.languages?.map((language) => language.value) || [],
        [UserInfo.BannerShade]: user?.bannerShade === 1,
      },
      packages: {
        ...Object.values(Platform).reduce((acc, platform) => {
          acc[platform] = {
            ...Object.values(PackageType).reduce((acc, type) => {
              let sampleContents = packages?.[platform]?.[
                type
              ]?.sampleContents?.map((sampleContent) =>
                platform === Platform.YouTube
                  ? `https://youtu.be/${sampleContent}`
                  : platform === Platform.Twitter
                  ? `https://twitter.com/twitter/status/${sampleContent}`
                  : platform === Platform.Instagram
                  ? `https://www.instagram.com/p/${sampleContent}`
                  : platform === Platform.TikTok
                  ? `https://tiktok.com/@user/video/${sampleContent}`
                  : sampleContent
              );
              if (
                sampleContents?.length &&
                (sampleContents?.length === 0 ||
                  sampleContents?.length % 2 === 1)
              ) {
                sampleContents = sampleContents.concat(
                  new Array(2 - (sampleContents?.length % 2)).fill('')
                );
              }
              if (!sampleContents?.length) sampleContents = ['', ''];

              const price = packages?.[platform]?.[type]?.price || 0;

              const currentContents = packages?.[platform]?.[type]?.contents;
              const contents =
                currentContents &&
                Object.values(currentContents).some((value) => value > 0)
                  ? currentContents
                  : { [contentTypes[platform][0].value]: 1 };

              const currentRevisions = packages?.[platform]?.[type]?.revisions;
              const revisions =
                currentRevisions === null || currentRevisions === undefined
                  ? null
                  : typeof currentRevisions === 'string'
                  ? parseInt(currentRevisions)
                  : currentRevisions;

              acc[type] = {
                [Package.IsRepresentative]:
                  packages?.[platform]?.[type]?.isRep === 1,
                [Package.Title]: packages?.[platform]?.[type]?.title || '',
                [Package.SampleContents]: sampleContents,
                [Package.Description]:
                  packages?.[platform]?.[type]?.description,
                [Package.Price]: price >= 5 ? '' + price : '',
                [Package.PriceType]:
                  packages?.[platform]?.[type]?.priceType || 'public',
                [Package.Contents]: contents,
                [Package.Delivery]: packages?.[platform]?.[type]?.delivery
                  ? packages?.[platform]?.[type]?.delivery + ''
                  : '',
                [Package.Revisions]: revisions === undefined ? null : revisions,
                [Package.TypeLength]:
                  packages?.[platform]?.[type]?.typeLength || '',
                [Package.FeaturedContent]:
                  packages?.[platform]?.[type]?.featuredContent || '',
                [Package.MinFeaturedContent]:
                  packages?.[platform]?.[type]?.minFeaturedContent || '',
              };
              return acc;
            }, {}),
          };
          return acc;
        }, {}),
      },
    },
  }[userType];
};

export default getInitialValues;
