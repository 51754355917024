import NotFoundKeys from '../translationKeys/NotFoundPageKeys';

export const NotFoundTranslations = {
  [NotFoundKeys.title]: '404 : ไม่พบหน้า',
  [NotFoundKeys.description]: 'ขออภัย เราไม่พบหน้าที่คุณกำลังมองหา',

  // Server Updating Page
  [NotFoundKeys.Server_Updating.title]: 'เซิร์ฟเวอร์กำลังอัปเดตในขณะนี้',
  [NotFoundKeys.Server_Updating.description]: 'โปรดกลับมาใหม่อีกครั้ง!',

  // Under Development Page
  [NotFoundKeys.Under_Development.title]: 'กำลังอยู่ในระหว่างการพัฒนา',
  [NotFoundKeys.Under_Development.description]:
    'ยินดีต้อนรับสู่ Web 3.0 Marketing!',
};
