import { createContext, useContext, useMemo, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import defaultValues from './defaultValues';
import proposalSchema from './validationSchema';
import useUser from 'hooks/useUser';

const ClientVerificationManagerContext = createContext({
  latestPatchNote: null,
  setLatestPatchNote: () => {},
});

const ClientVerificationManagerProvider = ({ children }) => {
  const [latestPatchNote, setLatestPatchNote] = useState(null);
  const { user } = useUser();

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      companyName: user?.company,
      companyWebsite: user?.website,
      country: user?.countries[0]?.value,
    },
    resolver: yupResolver(proposalSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  // console.log('Data', methods.watch(), methods.formState.errors);

  const data = useMemo(() => {
    return {
      latestPatchNote,
      setLatestPatchNote,
    };
  }, [latestPatchNote]);

  return (
    <ClientVerificationManagerContext.Provider value={data}>
      <FormProvider {...methods}>{children}</FormProvider>
    </ClientVerificationManagerContext.Provider>
  );
};

const useClientVerificationManager = () =>
  useContext(ClientVerificationManagerContext);

export { ClientVerificationManagerProvider, useClientVerificationManager };
