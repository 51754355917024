import { motion } from 'framer-motion';
import styled from 'styled-components';

const Error = styled(motion.span).attrs({
  className: 'h7',
  initial: { height: 0, opacity: 0 },
  animate: { height: '35px', opacity: 1 },
  exit: { height: 0, opacity: 0 },
})`
  color: var(--color-red-500);
  overflow: hidden;
`;

export default Error;
