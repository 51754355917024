export const OrderIcon = ({ size = 1 }) => (
  <svg
    width={size * 16}
    height={size * 18}
    viewBox="0 0 16 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15 4.76459H12V3.91753C12 3.01892 11.5786 2.15711 10.8284 1.52169C10.0783 0.886271 9.06087 0.529297 8 0.529297C6.93913 0.529297 5.92172 0.886271 5.17157 1.52169C4.42143 2.15711 4 3.01892 4 3.91753V4.76459H1C0.734784 4.76459 0.48043 4.85383 0.292893 5.01269C0.105357 5.17154 0 5.387 0 5.61165V14.9293C0 15.6033 0.316071 16.2496 0.87868 16.7262C1.44129 17.2027 2.20435 17.4705 3 17.4705H13C13.7956 17.4705 14.5587 17.2027 15.1213 16.7262C15.6839 16.2496 16 15.6033 16 14.9293V5.61165C16 5.387 15.8946 5.17154 15.7071 5.01269C15.5196 4.85383 15.2652 4.76459 15 4.76459ZM6 3.91753C6 3.46822 6.21071 3.03732 6.58579 2.71961C6.96086 2.4019 7.46957 2.22341 8 2.22341C8.53043 2.22341 9.03914 2.4019 9.41421 2.71961C9.78929 3.03732 10 3.46822 10 3.91753V4.76459H6V3.91753ZM14 14.9293C14 15.154 13.8946 15.3694 13.7071 15.5283C13.5196 15.6871 13.2652 15.7764 13 15.7764H3C2.73478 15.7764 2.48043 15.6871 2.29289 15.5283C2.10536 15.3694 2 15.154 2 14.9293V6.45871H4V7.30577C4 7.53042 4.10536 7.74587 4.29289 7.90473C4.48043 8.06358 4.73478 8.15283 5 8.15283C5.26522 8.15283 5.51957 8.06358 5.70711 7.90473C5.89464 7.74587 6 7.53042 6 7.30577V6.45871H10V7.30577C10 7.53042 10.1054 7.74587 10.2929 7.90473C10.4804 8.06358 10.7348 8.15283 11 8.15283C11.2652 8.15283 11.5196 8.06358 11.7071 7.90473C11.8946 7.74587 12 7.53042 12 7.30577V6.45871H14V14.9293Z" />
  </svg>
);
