import styled from 'styled-components';

const TextButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  transition: all 150ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;

export default TextButton;
