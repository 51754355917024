const countries = [
  {
    name: 'Aruba',
    value: 'abw',
  },
  {
    name: 'Afghanistan',
    value: 'afg',
  },
  {
    name: 'Angola',
    value: 'ago',
  },
  {
    name: 'Anguilla',
    value: 'aia',
  },
  {
    name: 'Åland Islands',
    value: 'ala',
  },
  {
    name: 'Albania',
    value: 'alb',
  },
  {
    name: 'Andorra',
    value: 'and',
  },
  {
    name: 'Arab Emirates',
    value: 'are',
  },
  {
    name: 'Argentina',
    value: 'arg',
  },
  {
    name: 'Armenia',
    value: 'arm',
  },
  {
    name: 'American Samoa',
    value: 'asm',
  },
  {
    name: 'Antigua and Barbuda',
    value: 'atg',
  },
  {
    name: 'Australia',
    value: 'aus',
  },
  {
    name: 'Austria',
    value: 'aut',
  },
  {
    name: 'Azerbaijan',
    value: 'aze',
  },
  {
    name: 'Burundi',
    value: 'bdi',
  },
  {
    name: 'Belgium',
    value: 'bel',
  },
  {
    name: 'Benin',
    value: 'ben',
  },
  {
    name: 'Burkina Faso',
    value: 'bfa',
  },
  {
    name: 'Bangladesh',
    value: 'bgd',
  },
  {
    name: 'Bulgaria',
    value: 'bgr',
  },
  {
    name: 'Bahrain',
    value: 'bhr',
  },
  {
    name: 'Bahamas',
    value: 'bhs',
  },
  {
    name: 'Bosnia and Herzegovina',
    value: 'bih',
  },
  {
    name: 'Belarus',
    value: 'blr',
  },
  {
    name: 'Belize',
    value: 'blz',
  },
  {
    name: 'Bermuda',
    value: 'bmu',
  },
  {
    name: 'Bolivia (Plurinational State of)',
    value: 'bol',
  },
  {
    name: 'Brazil',
    value: 'bra',
  },
  {
    name: 'Barbados',
    value: 'brb',
  },
  {
    name: 'Brunei Darussalam',
    value: 'brn',
  },
  {
    name: 'Bhutan',
    value: 'btn',
  },
  {
    name: 'Botswana',
    value: 'bwa',
  },
  {
    name: 'Central African Republic',
    value: 'caf',
  },
  {
    name: 'Canada',
    value: 'can',
  },
  {
    name: 'Switzerland',
    value: 'che',
  },
  {
    name: 'Chile',
    value: 'chl',
  },
  {
    name: 'China',
    value: 'chn',
  },
  {
    name: "Côte d'Ivoire",
    value: 'civ',
  },
  {
    name: 'Cameroon',
    value: 'cmr',
  },
  {
    name: 'Congo, Democratic Republic of the',
    value: 'cod',
  },
  {
    name: 'Congo',
    value: 'cog',
  },
  {
    name: 'Colombia',
    value: 'col',
  },
  {
    name: 'Comoros',
    value: 'com',
  },
  {
    name: 'Cabo Verde',
    value: 'cpv',
  },
  {
    name: 'Costa Rica',
    value: 'cri',
  },
  {
    name: 'Cuba',
    value: 'cub',
  },
  {
    name: 'Cyprus',
    value: 'cyp',
  },
  {
    name: 'Czechia',
    value: 'cze',
  },
  {
    name: 'Germany',
    value: 'deu',
  },
  {
    name: 'Djibouti',
    value: 'dji',
  },
  {
    name: 'Dominica',
    value: 'dma',
  },
  {
    name: 'Denmark',
    value: 'dnk',
  },
  {
    name: 'Dominican Republic',
    value: 'dom',
  },
  {
    name: 'Algeria',
    value: 'dza',
  },
  {
    name: 'Ecuador',
    value: 'ecu',
  },
  {
    name: 'Europe',
    value: 'eeu',
  },
  {
    name: 'Egypt',
    value: 'egy',
  },
  {
    name: 'Eritrea',
    value: 'eri',
  },
  {
    name: 'Western Sahara',
    value: 'esh',
  },
  {
    name: 'Spain',
    value: 'esp',
  },
  {
    name: 'Estonia',
    value: 'est',
  },
  {
    name: 'Ethiopia',
    value: 'eth',
  },
  {
    name: 'Finland',
    value: 'fin',
  },
  {
    name: 'France',
    value: 'fra',
  },
  {
    name: 'Faroe Islands',
    value: 'fro',
  },
  {
    name: 'Micronesia (Federated States of)',
    value: 'fsm',
  },
  {
    name: 'Gabon',
    value: 'gab',
  },
  {
    name: 'United Kingdom',
    value: 'gbr',
  },
  {
    name: 'Georgia',
    value: 'geo',
  },
  {
    name: 'Ghana',
    value: 'gha',
  },
  {
    name: 'Gibraltar',
    value: 'gib',
  },
  {
    name: 'Guinea',
    value: 'gin',
  },
  {
    name: 'Guadeloupe',
    value: 'glp',
  },
  {
    name: 'Gambia',
    value: 'gmb',
  },
  {
    name: 'Guinea-Bissau',
    value: 'gnb',
  },
  {
    name: 'Equatorial Guinea',
    value: 'gnq',
  },
  {
    name: 'Greece',
    value: 'grc',
  },
  {
    name: 'Grenada',
    value: 'grd',
  },
  {
    name: 'Greenland',
    value: 'grl',
  },
  {
    name: 'Guatemala',
    value: 'gtm',
  },
  {
    name: 'Guam',
    value: 'gum',
  },
  {
    name: 'Guyana',
    value: 'guy',
  },
  {
    name: 'Hong Kong',
    value: 'hkg',
  },
  {
    name: 'Honduras',
    value: 'hnd',
  },
  {
    name: 'Croatia',
    value: 'hrv',
  },
  {
    name: 'Haiti',
    value: 'hti',
  },
  {
    name: 'Hungary',
    value: 'hun',
  },
  {
    name: 'Indonesia',
    value: 'idn',
  },
  {
    name: 'India',
    value: 'ind',
  },
  {
    name: 'Ireland',
    value: 'irl',
  },
  {
    name: 'Iran',
    value: 'irn',
  },
  {
    name: 'Iraq',
    value: 'irq',
  },
  {
    name: 'Iceland',
    value: 'isl',
  },
  {
    name: 'Israel',
    value: 'isr',
  },
  {
    name: 'Italy',
    value: 'ita',
  },
  {
    name: 'Jamaica',
    value: 'jam',
  },
  {
    name: 'Jordan',
    value: 'jor',
  },
  {
    name: 'Japan',
    value: 'jpn',
  },
  {
    name: 'Kazakhstan',
    value: 'kaz',
  },
  {
    name: 'Kenya',
    value: 'ken',
  },
  {
    name: 'Kyrgyzstan',
    value: 'kgz',
  },
  {
    name: 'Cambodia',
    value: 'khm',
  },
  {
    name: 'Kiribati',
    value: 'kir',
  },
  {
    name: 'Saint Kitts and Nevis',
    value: 'kna',
  },
  {
    name: 'South Korea',
    value: 'kor',
  },
  {
    name: 'Kuwait',
    value: 'kwt',
  },
  {
    name: "Lao People's Democratic Republic",
    value: 'lao',
  },
  {
    name: 'Lebanon',
    value: 'lbn',
  },
  {
    name: 'Liberia',
    value: 'lbr',
  },
  {
    name: 'Libya',
    value: 'lby',
  },
  {
    name: 'Saint Lucia',
    value: 'lca',
  },
  {
    name: 'Liechtenstein',
    value: 'lie',
  },
  {
    name: 'Sri Lanka',
    value: 'lka',
  },
  {
    name: 'Lesotho',
    value: 'lso',
  },
  {
    name: 'Lithuania',
    value: 'ltu',
  },
  {
    name: 'Luxembourg',
    value: 'lux',
  },
  {
    name: 'Latvia',
    value: 'lva',
  },
  {
    name: 'Macao',
    value: 'mac',
  },
  {
    name: 'Morocco',
    value: 'mar',
  },
  {
    name: 'Monaco',
    value: 'mco',
  },
  {
    name: 'Moldova, Republic of',
    value: 'mda',
  },
  {
    name: 'Madagascar',
    value: 'mdg',
  },
  {
    name: 'Maldives',
    value: 'mdv',
  },
  {
    name: 'Mexico',
    value: 'mex',
  },
  {
    name: 'Marshall Islands',
    value: 'mhl',
  },
  {
    name: 'North Macedonia',
    value: 'mkd',
  },
  {
    name: 'Mali',
    value: 'mli',
  },
  {
    name: 'Malta',
    value: 'mlt',
  },
  {
    name: 'Myanmar',
    value: 'mmr',
  },
  {
    name: 'Montenegro',
    value: 'mne',
  },
  {
    name: 'Mongolia',
    value: 'mng',
  },
  {
    name: 'Mozambique',
    value: 'moz',
  },
  {
    name: 'Mauritania',
    value: 'mrt',
  },
  {
    name: 'Martinique',
    value: 'mtq',
  },
  {
    name: 'Mauritius',
    value: 'mus',
  },
  {
    name: 'Malawi',
    value: 'mwi',
  },
  {
    name: 'Malaysia',
    value: 'mys',
  },
  {
    name: 'Mayotte',
    value: 'myt',
  },
  {
    name: 'Namibia',
    value: 'nam',
  },
  {
    name: 'New Caledonia',
    value: 'ncl',
  },
  {
    name: 'Niger',
    value: 'ner',
  },
  {
    name: 'Nigeria',
    value: 'nga',
  },
  {
    name: 'Nicaragua',
    value: 'nic',
  },
  {
    name: 'Niue',
    value: 'niu',
  },
  {
    name: 'Netherlands',
    value: 'nld',
  },
  {
    name: 'Norway',
    value: 'nor',
  },
  {
    name: 'Nepal',
    value: 'npl',
  },
  {
    name: 'Nauru',
    value: 'nru',
  },
  {
    name: 'New Zealand',
    value: 'nzl',
  },
  {
    name: 'Oman',
    value: 'omn',
  },
  {
    name: 'Pakistan',
    value: 'pak',
  },
  {
    name: 'Panama',
    value: 'pan',
  },
  {
    name: 'Peru',
    value: 'per',
  },
  {
    name: 'Philippines',
    value: 'phl',
  },
  {
    name: 'Palau',
    value: 'plw',
  },
  {
    name: 'Papua New Guinea',
    value: 'png',
  },
  {
    name: 'Poland',
    value: 'pol',
  },
  {
    name: 'Puerto Rico',
    value: 'pri',
  },
  {
    name: 'North Korea',
    value: 'prk',
  },
  {
    name: 'Portugal',
    value: 'prt',
  },
  {
    name: 'Paraguay',
    value: 'pry',
  },
  {
    name: 'Palestine, State of',
    value: 'pse',
  },
  {
    name: 'Qatar',
    value: 'qat',
  },
  {
    name: 'Réunion',
    value: 'reu',
  },
  {
    name: 'Romania',
    value: 'rou',
  },
  {
    name: 'Russia',
    value: 'rus',
  },
  {
    name: 'Rwanda',
    value: 'rwa',
  },
  {
    name: 'Saudi Arabia',
    value: 'sau',
  },
  {
    name: 'Sudan',
    value: 'sdn',
  },
  {
    name: 'Senegal',
    value: 'sen',
  },
  {
    name: 'Singapore',
    value: 'sgp',
  },
  {
    name: 'Solomon Islands',
    value: 'slb',
  },
  {
    name: 'Sierra Leone',
    value: 'sle',
  },
  {
    name: 'El Salvador',
    value: 'slv',
  },
  {
    name: 'San Marino',
    value: 'smr',
  },
  {
    name: 'Somalia',
    value: 'som',
  },
  {
    name: 'Saint Pierre and Miquelon',
    value: 'spm',
  },
  {
    name: 'Serbia',
    value: 'srb',
  },
  {
    name: 'South Sudan',
    value: 'ssd',
  },
  {
    name: 'Sao Tome and Principe',
    value: 'stp',
  },
  {
    name: 'Suriname',
    value: 'sur',
  },
  {
    name: 'Slovakia',
    value: 'svk',
  },
  {
    name: 'Slovenia',
    value: 'svn',
  },
  {
    name: 'Sweden',
    value: 'swe',
  },
  {
    name: 'Eswatini',
    value: 'swz',
  },
  {
    name: 'Seychelles',
    value: 'syc',
  },
  {
    name: 'Syrian Arab Republic',
    value: 'syr',
  },
  {
    name: 'Turks and Caicos Islands',
    value: 'tca',
  },
  {
    name: 'Chad',
    value: 'tcd',
  },
  {
    name: 'Togo',
    value: 'tgo',
  },
  {
    name: 'Thailand',
    value: 'tha',
  },
  {
    name: 'Tajikistan',
    value: 'tjk',
  },
  {
    name: 'Tokelau',
    value: 'tkl',
  },
  {
    name: 'Turkmenistan',
    value: 'tkm',
  },
  {
    name: 'Timor-Leste',
    value: 'tls',
  },
  {
    name: 'Tonga',
    value: 'ton',
  },
  {
    name: 'Trinidad and Tobago',
    value: 'tto',
  },
  {
    name: 'Tunisia',
    value: 'tun',
  },
  {
    name: 'Turkiye',
    value: 'tur',
  },
  {
    name: 'Tuvalu',
    value: 'tuv',
  },
  {
    name: 'Taiwan',
    value: 'twn',
  },
  {
    name: 'Tanzania, United Republic of',
    value: 'tza',
  },
  {
    name: 'Uganda',
    value: 'uga',
  },
  {
    name: 'Ukraine',
    value: 'ukr',
  },
  {
    name: 'Uruguay',
    value: 'ury',
  },
  {
    name: 'United States',
    value: 'usa',
  },
  {
    name: 'Uzbekistan',
    value: 'uzb',
  },
  {
    name: 'Holy See',
    value: 'vat',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    value: 'vct',
  },
  {
    name: 'Venezuela (Bolivarian Republic of)',
    value: 'ven',
  },
  {
    name: 'Vietnam',
    value: 'vnm',
  },
  {
    name: 'Vanuatu',
    value: 'vut',
  },
  {
    name: 'Wallis and Futuna',
    value: 'wlf',
  },
  {
    name: 'Samoa',
    value: 'wsm',
  },
  {
    name: 'Yemen',
    value: 'yem',
  },
  {
    name: 'South Africa',
    value: 'zaf',
  },
  {
    name: 'Zambia',
    value: 'zmb',
  },
  {
    name: 'Zimbabwe',
    value: 'zwe',
  },
];

const popularCountries = [
  {
    name: 'United States',
    value: 'usa',
  },
  {
    name: 'Europe',
    value: 'eeu',
  },
  {
    name: 'South Korea',
    value: 'kor',
  },
  {
    name: 'Japan',
    value: 'jpn',
  },
  {
    name: 'Spain',
    value: 'esp',
  },
  {
    name: 'United Kingdom',
    value: 'gbr',
  },
  {
    name: 'Thailand',
    value: 'tha',
  },
  {
    name: 'Mexico',
    value: 'mex',
  },
  {
    name: 'Vietnam',
    value: 'vnm',
  },
];

export default countries;
export { popularCountries };
