import styled from 'styled-components';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const Button = styled.button`
  border: 1.5px solid var(--color-indigo-50);
  background-color: ${({ variant }) =>
    variant === 'filled' ? 'var(--color-indigo-500)' : 'var(--color-white)'};
  padding: ${({ padding }) => (padding ? padding : '1rem')};
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ variant }) =>
    variant === 'filled' ? 'var(--color-white)' : 'var(--color-indigo-500)'};
  min-width: ${({ minWidth }) => minWidth || '12.5rem'};
  border-radius: ${({ size, radius }) => radius};
  text-align: center;
  text-decoration: none;
  transition: all 150ms ease-in-out;
  cursor: pointer;

  &:active {
    outline: none;
    ${({ variant }) =>
      variant === 'filled'
        ? 'background-color: var(--color-indigo-400)'
        : 'color: var(--color-indigo-400)'};
    border: 1px solid var(--color-indigo-400);
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    background-color: var(--color-indigo-50);
    border: 1px solid var(--color-indigo-50);
    box-shadow: none;

    &:hover {
      background-color: var(--color-indigo-50);
      border: 1px solid var(--color-indigo-50);
      box-shadow: none;
    }
  }
`;

/**
 * @param {string} [variant] - The variant of the button.
 * - filled
 * - outline
 * @param {string} [as] - The tag name of the button. (optional)
 * - button
 * - a
 * - Link
 * @param {Node} children - The content of the button.
 * @param {string} [padding] - The padding of the button.
 * @param {string} [className] - The additional class name of the button.
 * @param {string} [minWidth] - The minimum width of the button. (default: 12.5rem)
 * @additional The rest of the props are passed to the underlying component.
 */
const GrayButton = ({
  variant = 'filled',
  as = 'button',
  size,
  children,
  padding,
  radius,
  minWidth,
  fontSize,
  className,
  disabled,
  ...rest
}) => {
  const { isMobile } = useResponsiveScreen();
  return (
    <Button
      as={as}
      variant={variant}
      className={className}
      fontSize={fontSize}
      padding={padding}
      radius={radius}
      minWidth={minWidth || (isMobile && '6.25rem')}
      disabled={disabled}
      isMobile={isMobile}
      size={size}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default GrayButton;
