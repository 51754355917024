import FAQKeys from '../translationKeys/FAQPageKeys';

export const FAQTranslations = {
  // General
  [FAQKeys.General.title]: 'General',

  [FAQKeys.General.Q1.title]: 'How do I get started with MegaEvolution?',
  [FAQKeys.General.Q1.description]:
    "To begin your influencer marketing journey with MegaEvolution, simply visit our website at megaevolution.io from your desktop or mobile browser. After clicking on the 'Login' button, you can sign up using your personal email, Google, Facebook, or wallet (with MetaMask or Coinbase Wallet). Agree to our terms of service and verify your email if required. Once registered, you can easily connect with influencers and leverage their reach to promote your products or services to a wider audience. Our platform offers advanced features such as creator channel/audience analytics and all-in-one order and payment system, making it effortless for businesses to launch an effective influencer campaign.",

  [FAQKeys.General.Q2.title]: 'What is MegaEvolution?',
  [FAQKeys.General.Q2.description]:
    'MegaEvolution is an influencer marketing platform that connects clients with global creators to promote their products and services. Clients can easily find and order marketing packages, while creators can promote themselves and gain more opportunities and profit through their services. MegaEvolution streamlines the entire process from ordering to managing ads, making it an easy and convenient experience for all users.',

  [FAQKeys.General.Q3.title]:
    'What does MegaEvolution provide for the creators?',
  [FAQKeys.General.Q3.description]:
    'MegaEvolution enables creators to connect with brand clients from around the world. By promoting their services through MegaEvolution, creators can easily receive orders from clients, and we support the entire order and payment process to help creators maximize their opportunities and profits.',

  [FAQKeys.General.Q4.title]:
    'What does MegaEvolution provide for the clients?',
  [FAQKeys.General.Q4.description]:
    'MegaEvolution connects clients with a diverse range of creators, providing access to information such as channel analytics and marketing prices. Clients can order marketing packages from creators with just one click, and we provide full support throughout the order and payment process to ensure a safe and convenient experience.',

  [FAQKeys.General.Q5.title]: 'What is the EVO point and how does it work?',
  [FAQKeys.General.Q5.description]:
    'EVO Point allows access to creator package prices and may soon be used for events, voting, and governance. We are planning to list it on various exchanges, enabling exchange to other coins or cash.',

  // Package System
  [FAQKeys.Package_System.title]: 'Package System',

  [FAQKeys.Package_System.Q1.title]: 'What are packages at MegaEvolution?',
  [FAQKeys.Package_System.Q1.description]:
    'Packages at MegaEvolution are a system that allows creators to showcase their services, including the content they will provide, its duration, price, and detailed description of how it will be delivered to clients. Users can easily purchase these packages through a few clicks and manage all orders via their dashboard. Additionally, users will receive notifications and analytics regarding the status of their package orders.',

  [FAQKeys.Package_System.Q2.title]:
    'What are the types of pricing for packages?',
  [FAQKeys.Package_System.Q2.description]:
    'There are three types of pricing options: ‘Open Price’ / ‘Unlockable Price’ / ‘Contact Creator’. The creator can set up different pricing options for each package plan. For instance, the standard plan may be set up with an Open Price and premium plan as an Unlockable Price.',

  [FAQKeys.Package_System.Q3.title]:
    'How is the price unlocking system done? How long are the unlocked prices shown?',
  [FAQKeys.Package_System.Q3.description]:
    'All users can unlock the package price a certain number of times for free every month. To unlock prices for a creator’s package, simply click on the locked price. Once unlocked, you will be able to view all prices for that specific creator. The unlocked prices are shown for 3 days.',

  // Creators
  [FAQKeys.Creators.title]: 'Creators',

  [FAQKeys.Creators.Q1.title]:
    'I want to know about the Service Fee for Creators.',
  [FAQKeys.Creators.Q1.description]:
    'Currently, we don’t charge Creators for each ad order.',

  [FAQKeys.Creators.Q2.title]: 'How long does it take to withdraw my balance?',
  [FAQKeys.Creators.Q2.description]:
    'Transfer typically take 1~3 business days through PayPal.',

  [FAQKeys.Creators.Q3.title]:
    'I have unlinked all of my social network accounts after registering as a Creator. Will my account be deleted automatically?',
  [FAQKeys.Creators.Q3.description]:
    'Your account will not be deleted. However, you will be unable to receive any orders. Also, you will not appear on our search window.',

  [FAQKeys.Creators.Q4.title]:
    'Can the client cancel the order after I accept it',
  [FAQKeys.Creators.Q4.description]:
    'Once a creator accepts an order, cancellation is only possible if both parties agree to the cancellation. However, if the creator fails to deliver the content by the due date or if the quality of the content is excessively unsatisfactory or different from the description, the order may be canceled through the Resolution Center. Please note that canceling orders may have negative consequences on your account and affect your reputation as a creator on MegaEvolution.',

  // Orders Help
  [FAQKeys.Orders_Help.title]: 'Orders Help',

  [FAQKeys.Orders_Help.Q1.title]: 'How do extensions and cancellations work?',
  [FAQKeys.Orders_Help.Q1.description]:
    'You can extend the due date of an order or cancel it by sending a request to your counterpart. You can either accept or decline the request you received. The requests will be automatically accepted if they are not responded to for several days (3 days for extensions, 5 days for cancellations).',

  [FAQKeys.Orders_Help.Q2.title]:
    'Can I cancel the order after the creator accepts it?',
  [FAQKeys.Orders_Help.Q2.description]:
    'Normally, once the Client accepts the order, it can only be canceled if the Creator agrees to it. However, it may be canceled via the Resolution Center if the delivery date was not abided or the quality was unsatisfactory.',

  [FAQKeys.Orders_Help.Q3.title]:
    'What do I do if I don’t receive the content?',
  [FAQKeys.Orders_Help.Q3.description]:
    'Creators are supposed to send the link of each ordered content. If you did not receive the content until the due date, you can request a cancellation and get a refund.',

  // Payment Help
  [FAQKeys.Payment_Help.title]: 'Payment Help',

  [FAQKeys.Payment_Help.Q1.title]: 'How do I check my payment history?',
  [FAQKeys.Payment_Help.Q1
    .description]: `1. Login to your account and click on ‘MySpace’ at the top right corner of the website. Then, click on the ‘Billing’ button. 
  2. Use the “Payment History” tab to view your transactions. You can also download the invoice in PDF.`,

  [FAQKeys.Payment_Help.Q2.title]: 'How can I pay?',
  [FAQKeys.Payment_Help.Q2
    .description]: `1. Go to ‘Myspace’ on the top right corner of the page. Then go to the ‘Billing’.
  2. On the ‘Payment Method’ tab, add ‘Credit & Debit Card’ or ‘PayPal Account’.
  3. Then, you can use the added information for the payments.`,

  [FAQKeys.Payment_Help.Q3.title]:
    'How does MegaEvolution make a refund to clients?',
  [FAQKeys.Payment_Help.Q3.description]:
    'For creator orders, full refunds are given upon cancellation. If there are already published contents, a proportional deduction will be made before refund. No refunds are given for monthly auto-billed platform subscriptions.',

  [FAQKeys.Payment_Help.Q4.title]:
    'What is the service fee for using MegaEvolution and how does it work?',
  [FAQKeys.Payment_Help.Q4.description]:
    'MegaEvolution charges a service fee of 5.5% + $3 for each package order. We offer one of the most competitive service fees in the industry. This small fee helps us operate the platform and support each order process. If you are a subscription user, you will receive a discount on the service fee.',

  [FAQKeys.Payment_Help.Q5.title]:
    'What kind of subscription plans are available?',
  [FAQKeys.Payment_Help.Q5.description]:
    'We offer 3 types of monthly subscription plans: Basic (free), Standard, and Premium. Basic provides access to most platform functions, while Standard and Premium include additional features such as more price unlocks, analytics, and bulk messaging. Paid plans also come with a small discount on service fees for orders.',

  // Account Help
  [FAQKeys.Account_Help.title]: 'Account Help',

  [FAQKeys.Account_Help.Q1
    .title]: `What can I do if I can't remember login details(ID or password)?`,
  [FAQKeys.Account_Help.Q1.description]:
    "To create an account on MegaEvolution, you can use your email, Google, Facebook, or a wallet (with MetaMask or Coinbase Wallet). If you forget your password for your email account, simply go to the login page and click on 'forgot password?”' Then, enter your email address and you will receive an email with a link to reset your password.",

  [FAQKeys.Account_Help.Q2.title]: 'How do I change my password?',
  [FAQKeys.Account_Help.Q2
    .description]: `1. Go to ‘My Space’ on the top right corner of the page after logging in. 
  2. Click on ‘Settings’ and update your new password in the ‘Account’ section.`,

  [FAQKeys.Account_Help.Q3.title]: 'How do I deactivate or delete my account?',
  [FAQKeys.Account_Help.Q3
    .description]: `1. Go to ‘My Space’ on the top right corner of the page after logging in.
  2. Click on Settings and scroll down to the Deactivate/Delete section.
  3. Click on the ‘Deactivate’ or ‘Delete’ button. (All data may be lost and cannot be restored after you delete an account.)`,

  // Safety & Privacy
  [FAQKeys.Safety_Privacy.title]: 'Safety & Privacy',

  [FAQKeys.Safety_Privacy.Q1.title]: 'How is my information secured?',
  [FAQKeys.Safety_Privacy.Q1.description]:
    'At MegaEvolution, we take privacy and security very seriously. We employ industry-standard measures to ensure that your personal information is safeguarded from unauthorized access or misuse. To learn more about our commitment to protecting your privacy, please refer to our Privacy Policy.',

  [FAQKeys.Safety_Privacy.Q2.title]:
    'What kind of Data does MegaEvolution keep?',
  [FAQKeys.Safety_Privacy.Q2.description]:
    'MegaEvolution stores various important data for our users, including order records, payment information, user data, profiles, package information, technical log information, and more.',

  [FAQKeys.Safety_Privacy.Q3.title]:
    'How do I deactivate or delete my account?',
  [FAQKeys.Safety_Privacy.Q3
    .description]: `1. Go to ‘My Space’ on the top right corner of the page after logging in.
  2. Click on Settings and scroll down to the Deactivate/Delete section.
  3. Click on the ‘Deactivate’ or ‘Delete’ button. (All data may be lost and cannot be restored after you delete an account.)`,
};
