import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: 'กำลังบันทึก',
  [ProposalKeys.changes_saved]: 'บันทึกการเปลี่ยนแปลงเรียบร้อยแล้ว',

  [ProposalKeys.New_Proposal.text]: 'ข้อเสนอใหม่',
  [ProposalKeys.New_Proposal.toast]:
    'คุณได้รับจำนวนข้อเสนอสูงสุดที่คุณสามารถสร้างได้แล้ว',

  [ProposalKeys.Clear.text]: 'ล้างทั้งหมด',
  [ProposalKeys.Clear.toast]: `
  ล้างเรียบร้อยแล้ว <button>คลิกที่นี่</button> เพื่อยกเลิกการเปลี่ยนแปลงนี้`,

  [ProposalKeys.Modal.Copy.title]: 'คุณแน่ใจหรือไม่ว่าต้องการเขียนทับข้อเสนอ?',
  [ProposalKeys.Modal.Copy.description]: `
    ข้อเสนอปัจจุบันจะถูกเขียนทับด้วยข้อเสนอที่เลือก<br>
    การกระทำนี้ไม่สามารถยกเลิกได้ คุณต้องการดำเนินการต่อหรือไม่?`,

  [ProposalKeys.Part1.Option_1.title]: 'หัวข้อ',
  [ProposalKeys.Part1.Option_1.placeholder]: 'กรอกชื่อโครงการ',
  [ProposalKeys.Part1.Option_1.error]: 'สูงสุด 100 ตัวอักษร',

  [ProposalKeys.Part1.Option_2.title]: 'เว็บไซต์ผลิตภัณฑ์ · แบรนด์',
  [ProposalKeys.Part1.Option_2.placeholder]: 'กรอก URL',
  [ProposalKeys.Part1.Option_2.error]: 'สูงสุด 400 ตัวอักษร',

  [ProposalKeys.Part1.Option_3.title]: 'รายละเอียดและไฟล์',
  [ProposalKeys.Part1.Option_3.placeholder]: `
  โปรดอธิบายรายละเอียดของโครงการและแนบไฟล์ของคุณที่นี่ <br>
  ครีเอเตอร์อาจค้นหาข้อมูลเกี่ยวกับ, <br><br><br>
  <ul>
  <li>คำอธิบายของโครงการ/ผลิตภัณฑ์</li>
  <li>เป้าหมายของแคมเปญและกลุ่มเป้าหมาย</li>
  <li>ข้อแนะนำเนื้อหาอย่างละเอียด</li>
  </ul>`,
  [ProposalKeys.Part1.Option_3.Snippet.title]: 'เลือกสไนป์เพื่อเพิ่ม',
  [ProposalKeys.Part1.Option_3.Snippet.option_1]:
    'ชื่อช่องโซเชียลของครีเอเตอร์',
  [ProposalKeys.Part1.Option_3.Snippet.option_2]: 'ชื่อผู้ใช้ของครีเอเตอร์',
  [ProposalKeys.Part1.Option_3.Snippet.option_3]: 'ประเทศหลักของครีเอเตอร์',
  [ProposalKeys.Part1.Option_3.Snippet.option_4]: 'หมวดหมู่หลักของครีเอเตอร์',

  [ProposalKeys.Part1.Option_3.Error.file_format]: `
  รูปแบบไฟล์ไม่ถูกต้อง (โปรดใช้ {{fileFormats}})`,
  [ProposalKeys.Part1.Option_3.Error.file_size_limit]: `
  ขนาดไฟล์รวมสูงสุด {{maxSize}}`,
  [ProposalKeys.Part1.Option_3.Error.max_file]: `
  คุณสามารถส่งไฟล์สูงสุด {{maxFiles}} ไฟล์`,

  [ProposalKeys.caution]: `
    เมื่อสั่งซื้อแพ็กเกจ ความต้องการทั้งหมดและภารกิจเนื้อหาที่เกี่ยวข้องจะถูก <strong>ปรับใช้โดยอัตโนมัติ</strong> ในแต่ละเนื้อหา หากมีการสั่งซื้อเนื้อหาที่ไม่สมบูรณ์หรือไม่เกี่ยวข้องกับเนื้อหาที่สั่งซื้อ จะไม่ถูกนำมาใช้.`,

  [ProposalKeys.Side_btn.text]: 'เนื้อหาที่เกี่ยวข้อง',
  [ProposalKeys.Side_btn.title]: 'เนื้อหาที่เกี่ยวข้อง: ',

  [ProposalKeys.Part2.title]: 'ส่งผลิตภัณฑ์ให้ครีเอเตอร์',

  [ProposalKeys.Part2.Option_1.title]: 'ส่งสินค้าที่ได้รับการสนับสนุน',
  [ProposalKeys.Part2.Option_1.tooltip]: `
    ตัวเลือกนี้ไม่สมบูรณ์และจะไม่ <br>
    ถูกนำไปใช้กับข้อเสนอที่กำลังถูกส่ง.`,

  [ProposalKeys.Part2.Option_1.Image.title]: 'รูปภาพการส่งสินค้า (ไม่บังคับ)',
  [ProposalKeys.Part2.Option_1.Image.description]:
    'เพิ่มรูปภาพไฟล์ได้สูงสุด 5 รูปในรูปแบบ JPG, JPEG, PNG, หรือ GIF ขนาดสูงสุด 100 MB',

  [ProposalKeys.Part2.Option_1.Info.title]: 'ข้อมูลการส่งสินค้า (บังคับ)',
  [ProposalKeys.Part2.Option_1.Info.placeholder]:
    'โปรดให้ข้อมูลเกี่ยวกับผลิตภัณฑ์และการจัดส่ง',

  [ProposalKeys.Part2.Option_1.caution_1]:
    'คุณจะได้รับที่อยู่จัดส่งของครีเอเตอร์สำหรับการส่งสินค้า การสร้างเนื้อหาจะเริ่มต้นหลังจากที่สินค้ามาถึง และวันที่ครบกำหนดจะถูกอัพเดตตามนี้',
  [ProposalKeys.Part2.Option_1.caution_2]:
    'เราไม่ดูแลกระบวนการคืนสินค้าหลังจากจัดส่ง หากการสั่งซื้อของคุณถูกยกเลิกหลังจากสินค้าถูกจัดส่ง คุณจะต้องโต้สอบเรื่องการคืนโดยตรงกับครีเอเตอร์',
  [ProposalKeys.Part2.Option_1.caution_3]: `
    เราสนับสนุนการติดตามการจัดส่งระหว่างประเทศ แต่ไม่รับประกันหรือคุ้มครองปัญหาการจัดส่ง เช่น การส่งผิดที่อยู่
    <Tooltip>การส่งภายในประเทศ</Tooltip> สามารถติดตามได้เฉพาะในประเทศบางประเทศเท่านั้น แต่คุณยังคงสามารถรวมการส่งในคำสั่งได้`,
  [ProposalKeys.Part2.Option_1.caution_3_tooltip]: `
    เราสนับสนุนการติดตามการส่งในประเทศสำหรับผู้ให้บริการขนส่งระหว่างประเทศหลัก ในประเทศบางประเทศเท่านั้น อย่างเช่น สหรัฐอเมริกาและแคนาดา ณ ขณะนี้เราไม่สนับสนุนการติดตามการส่งในประเทศอื่น ๆ`,

  [ProposalKeys.Part3.title]: 'ความต้องการ',
  [ProposalKeys.Part3.applied]: '{{number}} ถูกนำไปใช้',

  [ProposalKeys.Part3.Option_1.title]: 'ระบุการโฆษณาแบบจ่ายเงิน',
  [ProposalKeys.Part3.Option_1.description_1]:
    'ครีเอเตอร์จะเพิ่มหนึ่งในแฮชแท็กต่อไปนี้: #Ad, #Advertisement หรือ #Sponsored ไว้ที่จุดเริ่มต้นของรายการแฮชแท็กเพื่อระบุการโฆษณาแบบจ่ายเงินโดยชัดเจน',
  [ProposalKeys.Part3.Option_1.description_2]:
    'เพิ่มหน้าปกกำกับ "รวมการโฆษณาแบบจ่ายเงิน" ที่ YouTube ให้ภายในวิดีโอ',
  [ProposalKeys.Part3.Option_1.description_3]:
    'เพิ่มหน้าปกกำกับ "ความร่วมมือทางการจ่ายเงิน" ที่ Instagram ให้ภายในโพสต์/สตอรี/รีลส์ของ Instagram',

  [ProposalKeys.Part3.Option_2.placeholder]:
    'กรุณากรอกข้อกำหนดและเงื่อนไขของคุณที่นี่',
  [ProposalKeys.Part3.Option_2.description]:
    'ครีเอเตอร์จะต้องปฏิบัติตามข้อกำหนดและเงื่อนไขของคุณในการให้บริการ สามารถร้องขอการแก้ไขหรือปิดการใช้งานตัวเลือกนี้ได้หากข้อกำหนดและเงื่อนไขของคุณขัดแย้งกับการให้บริการของครีเอเตอร์',

  [ProposalKeys.Part4.title]: 'ภารกิจเนื้อหา',

  [ProposalKeys.Part4.Option_1.title]: 'รวมลิงก์',
  [ProposalKeys.Part4.Option_1.placeholder]: 'กรอก URL',
  [ProposalKeys.Part4.Option_2.btn]: 'เพิ่มแฮชแท็กนี้',
  [ProposalKeys.Part4.Option_1.description]:
    'ลิงก์ที่ให้มาจะถูกรวมอยู่ในคำอธิบายของโพสต์หรือวิดีโอ ใช้เพื่อเพิ่มการเข้าชมเว็บไซต์หรือแอป',

  [ProposalKeys.Part4.Option_2.title]: 'รวมแฮชแท็ก',
  [ProposalKeys.Part4.Option_2.placeholder]: 'กรอกแฮชแท็ก',
  [ProposalKeys.Part4.Option_2.description]:
    'อย่างน้อยหนึ่งหรือมากกว่าแฮชแท็กจากด้านซ้ายจะถูกรวมในโพสต์หรือคำอธิบายวิดีโอ',

  [ProposalKeys.Part4.Option_3.title]: 'แท็กสถานที่',
  [ProposalKeys.Part4.Option_3.placeholder]: 'ป้อนสถานที่',
  [ProposalKeys.Part4.Option_3.description]:
    'สถานที่ที่ระบุจะถูกแท็กในโพสต์หรือวิดีโอ มีประสิทธิภาพในการส่งเสริมสถานที่หรือสถานที่จัดกิจกรรมเฉพาะ',

  [ProposalKeys.Part4.Option_4.title]: 'แท็กบัญชีของเรา',
  [ProposalKeys.Part4.Option_4.placeholder]: 'ชื่อบัญชีของคุณ',
  [ProposalKeys.Part4.Option_4.description]:
    'บัญชี @handle จะถูกรวมในโพสต์หรือคำอธิบายวิดีโอ (เฉพาะบัญชีที่เกี่ยวข้องกับแพลตฟอร์มของเนื้อหาที่สั่งซื้อเท่านั้น)',

  [ProposalKeys.Part4.Option_5.title]: 'ปักหมุดความเห็นด้วยบัญชีของเรา',
  [ProposalKeys.Part4.Option_5.description]:
    'ความเห็นที่รวมถึงบัญชี @handle ที่ระบุจะถูกโพสต์และปักหมุดในเนื้อหา (เฉพาะบัญชีที่เกี่ยวข้องกับแพลตฟอร์มของเนื้อหาที่สั่งซื้อเท่านั้น)',

  [ProposalKeys.Part4.Option_6.title]: 'ปักหมุดความเห็นด้วยลิงก์',
  [ProposalKeys.Part4.Option_6.placeholder]: 'ป้อน URL',
  [ProposalKeys.Part4.Option_6.description]:
    'ความเห็นที่รวมถึงลิงก์ที่ระบุจะถูกโพสต์และปักหมุดในเนื้อหา',

  [ProposalKeys.Part4.Option_7.title]: 'รวมรูปภาพที่เฉพาะเจาะจง',
  [ProposalKeys.Part4.Option_7.description]:
    'ผู้สร้างจะถูกนำทางให้รวมรูปภาพที่ระบุให้อย่างน้อยหนึ่งรูปหรือมากกว่าในเนื้อหา',

  [ProposalKeys.Part4.Option_8.title]: 'เพิ่มแท็กสำหรับวิดีโอ',
  [ProposalKeys.Part4.Option_8.placeholder]: 'ป้อนแท็กวิดีโอ',
  [ProposalKeys.Part4.Option_8.btn]: 'เพิ่มแท็กนี้',
  [ProposalKeys.Part4.Option_8.description]:
    'อย่างน้อยหนึ่งหรือมากกว่าแท็กจากด้านซ้ายจะถูกเพิ่มในวิดีโอ YouTube เพื่อการค้นหาที่ดีขึ้น',
};
