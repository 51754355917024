export const MySpaceProfileIcon = ({
  size = 1,
  color = '#FFFFFF',
  noColor,
  ...rest
}) => {
  return (
    <svg
      width={size * 54}
      height={size * 55}
      viewBox="0 0 54 55"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M27 0C19.8674 0.0909609 13.0526 2.9648 8.0087 8.0087C2.9648 13.0526 0.0909609 19.8674 0 27C0.033342 31.1551 1.02525 35.2464 2.89848 38.9554C4.77172 42.6644 7.47573 45.891 10.8 48.384V48.6H11.07C15.6416 52.1032 21.2405 54.0018 27 54.0018C32.7595 54.0018 38.3584 52.1032 42.93 48.6H43.2V48.384C46.5243 45.891 49.2283 42.6644 51.1015 38.9554C52.9748 35.2464 53.9667 31.1551 54 27C53.909 19.8674 51.0352 13.0526 45.9913 8.0087C40.9474 2.9648 34.1326 0.0909609 27 0ZM16.389 45.711C16.7832 43.9059 17.7826 42.2898 19.2215 41.1308C20.6604 39.9718 22.4524 39.3396 24.3 39.339H29.7C31.5476 39.3396 33.3396 39.9718 34.7785 41.1308C36.2174 42.2898 37.2168 43.9059 37.611 45.711C34.3949 47.6032 30.7314 48.601 27 48.601C23.2686 48.601 19.6051 47.6032 16.389 45.711ZM42.147 42.228C41.12 39.7736 39.3906 37.6773 37.1761 36.2026C34.9616 34.7278 32.3606 33.9403 29.7 33.939H24.3C21.6394 33.9403 19.0384 34.7278 16.8239 36.2026C14.6094 37.6773 12.88 39.7736 11.853 42.228C9.82979 40.2381 8.21853 37.869 7.1113 35.2561C6.00406 32.6432 5.42255 29.8377 5.4 27C5.47003 21.2931 7.76822 15.8396 11.8039 11.8039C15.8396 7.76822 21.2931 5.47003 27 5.4C32.7069 5.47003 38.1604 7.76822 42.1961 11.8039C46.2318 15.8396 48.53 21.2931 48.6 27C48.5774 29.8377 47.9959 32.6432 46.8887 35.2561C45.7815 37.869 44.1702 40.2381 42.147 42.228Z"
        fill={noColor ? null : color}
        style={{ transition: 'fill 0.3s ease' }}
      />
      <path
        d="M26.8029 16.0029C25.3755 15.9696 23.9562 16.2262 22.6309 16.7572C21.3055 17.2882 20.1016 18.0825 19.0921 19.0921C18.0825 20.1016 17.2882 21.3055 16.7572 22.6309C16.2262 23.9562 15.9696 25.3755 16.0029 26.8029C15.9696 28.2302 16.2262 29.6495 16.7572 30.9749C17.2882 32.3002 18.0825 33.5041 19.0921 34.5137C20.1016 35.5232 21.3055 36.3175 22.6309 36.8485C23.9562 37.3795 25.3755 37.6361 26.8029 37.6029C28.2302 37.6361 29.6495 37.3795 30.9749 36.8485C32.3002 36.3175 33.5041 35.5232 34.5137 34.5137C35.5232 33.5041 36.3175 32.3002 36.8485 30.9749C37.3795 29.6495 37.6361 28.2302 37.6029 26.8029C37.6361 25.3755 37.3795 23.9562 36.8485 22.6309C36.3175 21.3055 35.5232 20.1016 34.5137 19.0921C33.5041 18.0825 32.3002 17.2882 30.9749 16.7572C29.6495 16.2262 28.2302 15.9696 26.8029 16.0029ZM26.8029 32.2029C26.0845 32.2376 25.3669 32.1217 24.696 31.8626C24.0251 31.6035 23.4158 31.207 22.9073 30.6984C22.3988 30.1899 22.0022 29.5806 21.7431 28.9097C21.484 28.2389 21.3681 27.5212 21.4029 26.8029C21.3681 26.0845 21.484 25.3669 21.7431 24.696C22.0022 24.0251 22.3988 23.4158 22.9073 22.9073C23.4158 22.3988 24.0251 22.0022 24.696 21.7431C25.3669 21.484 26.0845 21.3681 26.8029 21.4029C27.5212 21.3681 28.2389 21.484 28.9097 21.7431C29.5806 22.0022 30.1899 22.3988 30.6984 22.9073C31.207 23.4158 31.6035 24.0251 31.8626 24.696C32.1217 25.3669 32.2376 26.0845 32.2029 26.8029C32.2376 27.5212 32.1217 28.2389 31.8626 28.9097C31.6035 29.5806 31.207 30.1899 30.6984 30.6984C30.1899 31.207 29.5806 31.6035 28.9097 31.8626C28.2389 32.1217 27.5212 32.2376 26.8029 32.2029Z"
        fill={noColor ? null : color}
        style={{ transition: 'fill 0.3s ease' }}
      />
    </svg>
  );
};
