export const TelegramIcon = ({ size = 1 }) => (
  <svg
    width={size * 32}
    height={size * 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 0C7.16337 0 0 7.16337 0 16C0 24.8366 7.16337 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16337 24.8366 0 16 0Z"
      fill="#40B3E0"
    />
    <path
      d="M23.7878 9.20386L20.9299 23.6125C20.9299 23.6125 20.5303 24.6117 19.4311 24.1321L12.8364 19.0761L10.4384 17.9171L6.40163 16.5581C6.40163 16.5581 5.78213 16.3384 5.72213 15.8587C5.66213 15.3791 6.42163 15.1194 6.42163 15.1194L22.4686 8.82437C22.4686 8.82437 23.7876 8.24487 23.7876 9.20412"
      fill="white"
    />
    <path
      d="M12.3288 23.4505C12.3288 23.4505 12.1363 23.4325 11.8963 22.673C11.6566 21.9136 10.4375 17.9168 10.4375 17.9168L20.1298 11.7618C20.1298 11.7618 20.6894 11.4221 20.6694 11.7618C20.6694 11.7618 20.7693 11.8218 20.4694 12.1016C20.1698 12.3815 12.8556 18.9561 12.8556 18.9561"
      fill="#D2E5F1"
    />
    <path
      d="M15.3635 21.0142L12.7551 23.3925C12.7551 23.3925 12.5511 23.5472 12.3281 23.4502L12.8276 19.0327"
      fill="#B5CFE4"
    />
  </svg>
);
