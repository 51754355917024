const FAQKeys = {
  General: {
    title: 'FAQKeys.General.title',

    Q1: {
      title: 'FAQKeys.General.Q1.title',
      description: 'FAQKeys.General.Q1.description',
    },

    Q2: {
      title: 'FAQKeys.General.Q2.title',
      description: 'FAQKeys.General.Q2.description',
    },

    Q3: {
      title: 'FAQKeys.General.Q3.title',
      description: 'FAQKeys.General.Q3.description',
    },

    Q4: {
      title: 'FAQKeys.General.Q4.title',
      description: 'FAQKeys.General.Q4.description',
    },

    Q5: {
      title: 'FAQKeys.General.Q5.title',
      description: 'FAQKeys.General.Q5.description',
    },
  },

  Package_System: {
    title: 'FAQKeys.Package_System.title',

    Q1: {
      title: 'FAQKeys.Package_System.Q1.title',
      description: 'FAQKeys.Package_System.Q1.description',
    },

    Q2: {
      title: 'FAQKeys.Package_System.Q2.title',
      description: 'FAQKeys.Package_System.Q2.description',
    },

    Q3: {
      title: 'FAQKeys.Package_System.Q3.title',
      description: 'FAQKeys.Package_System.Q3.description',
    },
  },

  Creators: {
    title: 'FAQKeys.Creators.title',

    Q1: {
      title: 'FAQKeys.Creators.Q1.title',
      description: 'FAQKeys.Creators.Q1.description',
    },

    Q2: {
      title: 'FAQKeys.Creators.Q2.title',
      description: 'FAQKeys.Creators.Q2.description',
    },

    Q3: {
      title: 'FAQKeys.Creators.Q3.title',
      description: 'FAQKeys.Creators.Q3.description',
    },

    Q4: {
      title: 'FAQKeys.Creators.Q4.title',
      description: 'FAQKeys.Creators.Q4.description',
    },
  },

  Orders_Help: {
    title: 'FAQKeys.Orders_Help.title',

    Q1: {
      title: 'FAQKeys.Orders_Help.Q1.title',
      description: 'FAQKeys.Orders_Help.Q1.description',
    },

    Q2: {
      title: 'FAQKeys.Orders_Help.Q2.title',
      description: 'FAQKeys.Orders_Help.Q2.description',
    },

    Q3: {
      title: 'FAQKeys.Orders_Help.Q3.title',
      description: 'FAQKeys.Orders_Help.Q3.description',
    },
  },

  Payment_Help: {
    title: 'FAQKeys.Payment_Help.title',

    Q1: {
      title: 'FAQKeys.Payment_Help.Q1.title',
      description: 'FAQKeys.Payment_Help.Q1.description',
    },

    Q2: {
      title: 'FAQKeys.Payment_Help.Q2.title',
      description: 'FAQKeys.Payment_Help.Q2.description',
    },

    Q3: {
      title: 'FAQKeys.Payment_Help.Q3.title',
      description: 'FAQKeys.Payment_Help.Q3.description',
    },

    Q4: {
      title: 'FAQKeys.Payment_Help.Q4.title',
      description: 'FAQKeys.Payment_Help.Q4.description',
    },

    Q5: {
      title: 'FAQKeys.Payment_Help.Q5.title',
      description: 'FAQKeys.Payment_Help.Q5.description',
    },
  },

  Account_Help: {
    title: 'FAQKeys.Account_Help.title',

    Q1: {
      title: 'FAQKeys.Account_Help.Q1.title',
      description: 'FAQKeys.Account_Help.Q1.description',
    },

    Q2: {
      title: 'FAQKeys.Account_Help.Q2.title',
      description: 'FAQKeys.Account_Help.Q2.description',
    },

    Q3: {
      title: 'FAQKeys.Account_Help.Q3.title',
      description: 'FAQKeys.Account_Help.Q3.description',
    },
  },

  Safety_Privacy: {
    title: 'FAQKeys.Safety_Privacy.title',

    Q1: {
      title: 'FAQKeys.Safety_Privacy.Q1.title',
      description: 'FAQKeys.Safety_Privacy.Q1.description',
    },

    Q2: {
      title: 'FAQKeys.Safety_Privacy.Q2.title',
      description: 'FAQKeys.Safety_Privacy.Q2.description',
    },

    Q3: {
      title: 'FAQKeys.Safety_Privacy.Q3.title',
      description: 'FAQKeys.Safety_Privacy.Q3.description',
    },
  },
};

export default FAQKeys;
