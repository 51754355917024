import styled, { keyframes } from 'styled-components';
import { CheckIcon, ChevronRightIcon } from 'assets/icons';
import { useToggle } from 'react-use';
import { useRef } from 'react';
import { useClickAway } from 'react-use';
import device from 'styles/device';
import Tooltip from 'components/Basics/Tooltip';
import Image from 'components/Basics/Image';

/**
 * @param {string} selected - The currently selected value
 * @param {function} setSelected - The function to set the selected value
 * @param {function} trigger - The function that returns a trigger element
 * - Function signature: (isOpen, setIsOpen) => React.ReactElement
 * @param {array} options - The options to display in the dropdown
 * - Array of objects with the following properties:
 *  - label: The label to display for the option
 *  - icon: The icon to display for the option
 *  - divider: Whether or not to display a divider instead of the option (boolean, optional)
 *  - disabled: Whether the option is disabled
 *  - value: The value to set when the option is selected
 *   - value can be an array of objects if the option is a nested dropdown
 * @param {string} position - The position of the dropdown relative to the trigger element (left, right, or none)
 * @param {string} margin - The margin of the dropdown relative to the trigger element
 * @param {string} padding - The padding of the dropdown container
 * @param {string} radius - The radius of the dropdown container
 * @param {string} borderWidth - The border width of the dropdown container
 * @param {function} onClose - The function to call when the dropdown is closed
 * @param {boolean} closeByHover - Dropdown will be disappear when mouse move outside of dropdown , default = false
 * @param {boolean} white - Whether or not to display a white dropdown (for metaverse page dropdowns)
 * @param {Object} wrapperStyle - set styles for root container of dropdown
 * @param {Object} optionStyle - override default styles of option
 */
const CustomDropdown = ({
  selected,
  setSelected,
  trigger,
  options,
  position = 'bottom-left',
  margin = '0',
  padding,
  radius = 15,
  onClose,
  closeParent, // for nested dropdown only
  closeByHover = false,
  shadow = true,
  white = false,
  haveBorder = true,
  className = '',
  top,
  left,
  bottom,
  right,
  wrapperStyle,
  optionStyle,
  checkMarkSelected = false,
  wrapperFixedHeight = null,
}) => {
  const [showDropdown, toggleDropdown] = useToggle(false);
  const [isClickedOnce, setIsClickedOnce] = useToggle(false);
  const ref = useRef(null);
  useClickAway(ref, () => {
    toggleDropdown(false);
    onClose?.();
  });

  return (
    <Wrapper
      ref={ref}
      className={className}
      style={wrapperStyle}
      onMouseOver={() => {
        if (closeByHover) {
          toggleDropdown(true);
        }
      }}
      onMouseLeave={() => {
        if (closeByHover) {
          toggleDropdown(false);
          onClose?.();
        }
      }}
    >
      {trigger?.(showDropdown, (val) => {
        toggleDropdown(val);
        setIsClickedOnce(true);
      })}
      <Tooltip
        visible={showDropdown}
        background="transparent"
        interactive={true}
        width="120px"
        shadow="none"
        content={
          <>
            <Menu
              hide={!showDropdown}
              isClickedOnce={isClickedOnce}
              position={position}
              margin={margin}
              shadow={shadow}
              white={white}
              top={top}
              left={left}
              bottom={bottom}
              right={right}
              radius={radius}
              padding={padding}
              haveBorder={haveBorder}
            >
              {closeByHover && (
                <TransparentSupport
                  height={
                    margin ? `calc(${margin.split(' ')[0]} + 2px)` : '2px'
                  }
                />
              )}

              <Options
                fixedHeight={
                  wrapperFixedHeight
                    ? wrapperFixedHeight
                    : !options?.some(
                        (option) => typeof option.value === 'object'
                      )
                }
                white={white}
              >
                {options?.map(
                  (
                    {
                      label,
                      icon,
                      value,
                      disabled,
                      onClick,
                      divider,
                      hidden,
                      ...rest
                    },
                    index
                  ) => {
                    // Hidden
                    if (hidden) {
                      return null;
                    }

                    // Divider
                    if (divider) {
                      return <Divider key={index} />;
                    }

                    if (
                      typeof value === 'object' &&
                      Array.isArray(value) &&
                      value.length > 0
                    ) {
                      return (
                        <CustomDropdown
                          key={index}
                          selected={selected}
                          setSelected={setSelected}
                          trigger={(isOpen, setIsOpen) => {
                            return (
                              <Option
                                onClick={() => {
                                  if (onClick) {
                                    onClick();
                                  }
                                  setIsOpen(!isOpen);
                                  if (closeParent) {
                                    closeParent();
                                  }
                                }}
                                white={white}
                                style={optionStyle}
                                {...rest}
                              >
                                {icon &&
                                  (typeof icon === 'string' ? (
                                    <Image
                                      src={icon}
                                      alt={label}
                                      width={'1.125rem'}
                                      square
                                      contain
                                    />
                                  ) : (
                                    icon
                                  ))}
                                {label} <ChevronRightIcon />
                              </Option>
                            );
                          }}
                          options={value}
                          position="right"
                          closeParent={() => toggleDropdown(false)}
                        />
                      );
                    }

                    if (
                      typeof value === 'object' &&
                      Array.isArray(value) &&
                      value.length === 0
                    ) {
                      return (
                        <Option
                          key={index}
                          onClick={() => {
                            if (onClick) {
                              onClick();
                            }
                            setSelected?.(value);
                            if (closeParent) {
                              closeParent();
                            }
                          }}
                          disabled={true}
                          white={white}
                          style={optionStyle}
                          {...rest}
                        >
                          {icon &&
                            (typeof icon === 'string' ? (
                              <Image
                                src={icon}
                                alt={label}
                                width="1.125rem"
                                square
                                contain
                              />
                            ) : (
                              icon
                            ))}
                          {label} <ChevronRightIcon />
                        </Option>
                      );
                    }

                    return (
                      <Option
                        key={index}
                        onClick={() => {
                          if (onClick) {
                            onClick();
                          }
                          setSelected?.(value);
                          if (closeParent) {
                            closeParent();
                          }
                        }}
                        disabled={disabled}
                        white={white}
                        style={optionStyle}
                        {...rest}
                      >
                        {checkMarkSelected && selected === value && (
                          <CheckIcon size={0.84} />
                        )}
                        {icon &&
                          (typeof icon === 'string' ? (
                            <Image
                              src={icon}
                              alt={label}
                              width="1.125rem"
                              square
                              contain
                            />
                          ) : (
                            icon
                          ))}
                        {label}
                      </Option>
                    );
                  }
                )}

                {options?.length === 0 && (
                  <Option white={white} style={optionStyle}>
                    No options available
                  </Option>
                )}
              </Options>
            </Menu>
          </>
        }
      />
    </Wrapper>
  );
};

export default CustomDropdown;

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const growIn = keyframes`
    0% {
        opacity: 0;
        scale: 0;
    }
    1% {
        opacity: 0;
        scale: 0.9;
    }
    100% {
        opacity: 1;
      scale: 1;
    }
`;

const growOut = keyframes`
    0% {
        opacity: 1;
        scale: 1;
    }
    99% {
        opacity: 0;
        scale: 0.9;
    } 
    100% {
        opacity: 0;
        scale: 0;
    }
`;

const Menu = styled.div`
  position: relative;
  ${({ position }) =>
    position === 'left'
      ? 'right: 100%; top: 0;'
      : position === 'right'
      ? 'left: 100%; top: 0;'
      : position === 'bottom-right'
      ? 'top: 100%; right: 0;'
      : position === 'center'
      ? 'top: 100%; left: 50%; transform: translateX(-50%);'
      : 'top: 100%; left: 0;'}
  padding: ${({ padding }) => padding || '12px 8px 12px 18px'};
  border: ${({ haveBorder, white }) =>
    haveBorder
      ? white
        ? '1px solid #fff'
        : '1px solid var(--color-pink-100)'
      : 'none'};
  background: ${({ white }) => (white ? 'transparent' : 'var(--color-white)')};
  border-radius: ${({ radius }) => radius + 'px'};
  margin: ${({ margin }) => margin};
  margin-top: ${({ position }) =>
    position === 'left' || position === 'right' ? '0.25rem' : ''};
  z-index: 5;
  transform-origin: ${({ position }) =>
    position === 'left' || position === 'bottom-right'
      ? 'right top'
      : position === 'right' || position === 'bottom-left'
      ? 'left top'
      : 'left top'};
  ${({ shadow, white }) =>
    shadow
      ? white
        ? 'box-shadow: var(--shadow-dropdown-art-assets);'
        : 'box-shadow: var(--shadow-dropdown-art-assets);'
      : null}
  top: ${({ top }) =>
    !top ? null : typeof top === 'string' ? top : top + 'px'};
  left: ${({ left }) =>
    !left ? null : typeof left === 'string' ? left : left + 'px'};
  bottom: ${({ bottom }) =>
    !bottom ? null : typeof bottom === 'string' ? bottom : bottom + 'px'};
  right: ${({ right }) =>
    !right ? null : typeof right === 'string' ? right : right + 'px'};
  width: max-content;
  min-width: 0;
  max-width: ${({ maxWidth }) => maxWidth || 'max-content'};
  color: var(--color-indigo-500);
  display: flex;
  flex-direction: column;
  opacity: 0;
  scale: 0;
  width: max-content;

  animation: ${({ hide, isClickedOnce }) =>
      isClickedOnce ? (hide ? growOut : growIn) : ''}
    150ms ease-in-out forwards;
`;

const TransparentSupport = styled.div`
  position: absolute;
  left: 0;
  bottom: 100%;
  background: transparent;
  width: 100%;
  height: ${({ height }) => height || '0'};
`;

const Options = styled.div.attrs({ className: 'scrollbar-y-thin' })`
  height: 100%;
  ${({ fixedHeight }) =>
    typeof fixedHeight === 'string'
      ? ''
      : fixedHeight
      ? `max-height: 255px; 
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 5px;
     `
      : 'padding: 10px;'}

  width: 100%;
`;

const Option = styled.button.attrs({
  type: 'button',
})`
  font-family: inherit;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  background: ${({ white }) => (white ? 'transparent' : 'var(--color-white)')};
  color: ${({ white }) =>
    white ? 'var(--color-white)' : 'var(--color-indigo-500)'};
  border: none;
  width: 100%;
  min-width: max-content;
  text-align: left;
  padding: 6px 0;
  margin: 0;
  transition: all 150ms ease-in-out;
  display: flex;
  align-items: center;
  gap: 0.625rem;

  /* for mobile devices */
  @media ${device.sm} {
    &:focus {
      text-decoration: underline;
    }
  }

  @media ${device.md} {
    &:hover {
      opacity: 0.7;
    }
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: var(--color-indigo-100);
  }

  svg {
    flex-shrink: 0;
  }
`;

const Divider = styled.div`
  display: flex;
  height: 1.4px;
  background: var(--color-pink-100);
  margin-block: 6px;
`;
