export const PauseIcon = ({ size = 1 }) => (
  <svg
    width={12 * size}
    height={16 * size}
    viewBox="0 0 12 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.89169 0.964844C9.33253 0.964844 8.79628 1.18697 8.40089 1.58235C8.00551 1.97774 7.78338 2.51399 7.78338 3.07315V12.9119C7.78338 13.4711 8.00551 14.0073 8.40089 14.4027C8.79628 14.7981 9.33253 15.0202 9.89169 15.0202C10.4508 15.0202 10.9871 14.7981 11.3825 14.4027C11.7779 14.0073 12 13.4711 12 12.9119V3.07315C12 2.51399 11.7779 1.97774 11.3825 1.58235C10.9871 1.18697 10.4508 0.964844 9.89169 0.964844ZM2.10831 0.97705C1.54915 0.97705 1.01289 1.19918 0.617509 1.59456C0.222125 1.98994 0 2.5262 0 3.08536V12.9241C0 13.4833 0.222125 14.0195 0.617509 14.4149C1.01289 14.8103 1.54915 15.0324 2.10831 15.0324C2.66747 15.0324 3.20372 14.8103 3.59911 14.4149C3.99449 14.0195 4.21662 13.4833 4.21662 12.9241V3.08536C4.21662 2.5262 3.99449 1.98994 3.59911 1.59456C3.20372 1.19918 2.66747 0.97705 2.10831 0.97705Z" />
  </svg>
);
