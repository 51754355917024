export const PlusCircleGradientIcon = ({ size = 1 }) => (
  <svg
    width={20 * size}
    height={20 * size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 20C12.6423 19.9681 15.1673 18.9043 17.0358 17.0358C18.9043 15.1673 19.9681 12.6423 20 10C19.9681 7.35774 18.9043 4.83268 17.0358 2.96418C15.1673 1.09568 12.6423 0.0318775 10 0C7.35774 0.0318775 4.83268 1.09568 2.96418 2.96418C1.09568 4.83268 0.0318775 7.35774 0 10C0.0318775 12.6423 1.09568 15.1673 2.96418 17.0358C4.83268 18.9043 7.35774 19.9681 10 20ZM4.28571 9.28571H9.28571V4.28571H10.7143V9.28571H15.7143V10.7143H10.7143V15.7143H9.28571V10.7143H4.28571V9.28571Z"
      fill="url(#paint0_linear_29942_44022)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_29942_44022"
        x1="19.3319"
        y1="18.3371"
        x2="-1.72135"
        y2="15.9751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD6FF" />
        <stop offset="1" stopColor="#E4E2FF" />
      </linearGradient>
    </defs>
  </svg>
);
