import * as yup from 'yup';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';

const restrictedDomains = [
  'hotmail.com',
  'yahoo.com',
  'gmail.com',
  'aol.com',
  'msn.com',
  'yahoo.co.jp',
  'yahoo.co.kr',
  'naver.com',
  'daum.net',
  'kakao.com',
  'gmx.com',
  'mail.ru',
  'yandex.ru',
  'outlook.com',
  'tutanota.com',
  'protonmail.com',
  'proton.me',
  'gmx.net',
  'mail.com',
  'yandex.com',
  'outlook.de',
];

const domainRegex = new RegExp(
  `^(?!.*@(?:${restrictedDomains
    .map((domain) => domain.replace('.', '\\.'))
    .join('|')}))`
);

const proposalSchema = yup.object().shape({
  firstName: yup.string().required(PricingKeys.Modal.Error.required_field),
  lastName: yup.string().required(PricingKeys.Modal.Error.required_field),
  companyName: yup.string().required(PricingKeys.Modal.Error.required_field),
  companyType: yup.string().required(PricingKeys.Modal.Error.select_option),
  companyWebsite: yup
    .string()
    .required(PricingKeys.Modal.Error.required_field)
    .matches(
      /^(https?:\/\/)?([a-zA-Z-]*\.){0,}[a-zA-Z0-9-]+(\.[a-z]{2,})(\/[a-zA-Z0-9-]+)*$/,
      PricingKeys.Modal.Error.valid_url
    ),
  jobPosition: yup.string().required(PricingKeys.Modal.Error.select_option),
  country: yup.string().required(PricingKeys.Modal.Error.select_option),
  companyEmail: yup
    .string()
    .required(PricingKeys.Modal.Error.required_field)
    .email(PricingKeys.Modal.Error.valid_email)
    .matches(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
      PricingKeys.Modal.Error.valid_email
    )
    .matches(domainRegex, PricingKeys.Modal.Error.email_validation),
  companyDescription: yup
    .string()
    .required(PricingKeys.Modal.Error.required_field),
  verificationFile: yup
    .array()
    .required(PricingKeys.Modal.Error.required_field)
    .min(1, PricingKeys.Modal.Error.required_field),
  code: yup.string(),
});

export default proposalSchema;
