import orderState from 'constants/orderState';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const colors = {
  [orderState.PROPOSAL_REQUIRED]: 'var(--color-yellow-500)',
  [orderState.AWAITING_ACCEPTANCE]: 'var(--color-yellow-500)',
  [orderState.PRODUCT_DELIVERING]: 'var(--color-yellow-500)',
  [orderState.PREPARING_CONTENTS]: 'var(--color-yellow-500)',
  [orderState.CONTENTS_DELIVERED]: 'var(--color-blue-500)',
  [orderState.IN_REVISION]: 'var(--color-red-500)',
  [orderState.EXTENSION_REQUEST]: 'var(--color-red-500)',
  [orderState.CANCELLATION_REQUEST]: 'var(--color-red-500)',
  [orderState.CANCELED]: 'var(--color-red-500)',
  [orderState.COMPLETE]: 'var(--color-green-500)',
  [orderState.LATE]: 'var(--color-red-500)',
};

const text = {
  [orderState.PROPOSAL_REQUIRED]: 'Proposal Required',
  [orderState.AWAITING_ACCEPTANCE]: 'Awaiting Acceptance',
  [orderState.PRODUCT_DELIVERING]: 'Product Delivery in Progress',
  [orderState.PREPARING_CONTENTS]: 'Preparing Contents',
  [orderState.CONTENTS_DELIVERED]: 'All Contents Published',
  [orderState.IN_REVISION]: 'In Revision',
  [orderState.EXTENSION_REQUEST]: 'Extension Request',
  [orderState.CANCELLATION_REQUEST]: 'Cancellation Request',
  [orderState.CANCELED]: 'Order Canceled',
  [orderState.COMPLETE]: 'Completed',
  [orderState.LATE]: 'Late',
};

const OrderState = ({ state, variant = 'contained' }) => {
  if (variant === 'text')
    return <Text color={colors[state]}>{text[state]}</Text>;

  return <Wrapper color={colors[state]}>{text[state]}</Wrapper>;
};

export default OrderState;

const Wrapper = styled(motion.div).attrs(({ color }) => ({
  className: 'bh8',
  animate: {
    backgroundColor: color,
  },
}))`
  display: flex;
  width: 10rem;
  min-height: 1.75rem;
  padding-top: 1.5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  text-transform: uppercase;
  color: ${({ color }) =>
    color === 'var(--color-yellow-500)'
      ? 'var(--color-indigo-500)'
      : 'var(--color-white)'};
`;

const Text = styled(motion.span).attrs(({ color }) => ({
  className: 'sbh8',
  animate: {
    color,
  },
}))`
  text-transform: uppercase;
  flex-shrink: 0;
`;
