import SettingsKeys from '../translationKeys/SettingsPageKeys';

export const SettingsTranslations = {
  // Signed with
  [SettingsKeys.Signed_With.wallet]: 'Đăng nhập bằng',
  [SettingsKeys.Signed_With.google]: 'Đăng nhập bằng Google',
  [SettingsKeys.Signed_With.facebook]: 'Đăng nhập bằng Facebook',

  // Account
  [SettingsKeys.Account.connected_email]: 'Đã kết nối Email',

  // Password
  [SettingsKeys.Password.current_password]: 'Mật khẩu Hiện tại',
  [SettingsKeys.Password.new_password]: 'Mật khẩu Mới',
  [SettingsKeys.Password.confirm_new_password]: 'Xác Nhận Mật khẩu Mới',

  [SettingsKeys.Password.Rule.title]: 'Mật khẩu của bạn phải chứa',
  [SettingsKeys.Password.Rule.rule_1]: '8~20 ký tự',
  [SettingsKeys.Password.Rule.rule_2]: 'Ít nhất 1 ký tự in hoa',
  [SettingsKeys.Password.Rule.rule_3]: 'Ít nhất 1 ký tự thường',
  [SettingsKeys.Password.Rule.rule_4]: 'Ít nhất 1 số hoặc 1 ký tự đặc biệt',

  [SettingsKeys.Password.Error.not_follow_rule]: 'Mật khẩu không hợp lệ',
  [SettingsKeys.Password.Error.characters]:
    '8~20 ký tự gồm chữ cái (A-Z, a-z), số hoặc ký tự đặc biệt.',
  [SettingsKeys.Password.Error.not_match]: 'Mật khẩu không trùng khớp.',
  [SettingsKeys.Password.Error.current_password_incorrect]:
    'Mật khẩu hiện tại không chính xác.',

  [SettingsKeys.Password.update_success]:
    'Mật khẩu của bạn đã được cập nhật thành công!',

  // Notifications
  [SettingsKeys.Notification.Description.orders]:
    'Nhận email về trạng thái đơn hàng của tôi.',
  [SettingsKeys.Notification.Description.messages]:
    'Nhận email thông báo tin nhắn.',
  [SettingsKeys.Notification.Description.reminders]:
    'Nhận email nhắc nhở về các hạn chót.',
  [SettingsKeys.Notification.Description.newsletters]:
    'Nhận email hữu ích về phần thưởng, tính năng mới, khuyến mãi, v.v.',
  [SettingsKeys.Notification.Description.packages]:
    'Nhận email về các gói dịch vụ bao gồm việc mở khóa giá và tương tác giữa khách hàng và nhà sáng tạo.',
  [SettingsKeys.Notification.Description.real_time]: 'Bật âm thanh',

  // Referral Code
  [SettingsKeys.Referral_Code.My.title]: 'Mã Giới Thiệu Của Tôi',
  [SettingsKeys.Referral_Code.My.description]: 'Tìm hiểu về phần thưởng',
  [SettingsKeys.Referral_Code.My.copy_code]: 'Sao Chép Mã',
  [SettingsKeys.Referral_Code.My.copied]: 'Đã sao chép thành công!',

  [SettingsKeys.Referral_Code.Enter.title]: 'Nhập Mã',

  [SettingsKeys.Referral_Code.Modal.reward_arrive]:
    'Phần thưởng giới thiệu của bạn đã đến.',
  [SettingsKeys.Referral_Code.Modal.my_evo]: 'EVO Của Tôi',
  [SettingsKeys.Referral_Code.Modal.check_later]: 'Kiểm Tra Sau',

  [SettingsKeys.Referral_Code.Referral_Page.instruction]:
    'Vui lòng nhập mã giới thiệu hợp lệ.\nMã này có thể được tìm thấy trong tab Cài Đặt.',
  [SettingsKeys.Referral_Code.Referral_Page.evo_policy]:
    'Bạn có thể kiếm thêm điểm EVO.\nVui lòng kiểm tra chính sách của chúng tôi',
  [SettingsKeys.Referral_Code.Referral_Page.enter_code_here]:
    'Nhập mã giới thiệu ở đây',

  // Referral System
  [SettingsKeys.Referral_System.title]: 'Hệ Thống Giới Thiệu',
  [SettingsKeys.Referral_System.button]: 'Sao Chép Mã Giới Thiệu',

  [SettingsKeys.Referral_System.Refer_A_Friend.lang]: 'vi',
  [SettingsKeys.Referral_System.Referral_Reward.lang]: 'vi',

  // Deactivate/Delete
  [SettingsKeys.Deactivate_Delete.title]: 'Vô Hiệu Hóa/Xóa',

  [SettingsKeys.Deactivate_Delete.Q1.title]:
    'Nếu tôi vô hiệu hóa tài khoản của mình, điều gì sẽ xảy ra?',
  [SettingsKeys.Deactivate_Delete.Q1.description]: `
    <heading><span>{{dot}}</span> Hồ sơ của bạn sẽ không xuất hiện trong danh sách tìm kiếm cho đến khi bạn kích hoạt lại tài khoản của mình.</heading> <br>
    <heading><span>{{dot}}</span> Dữ liệu được lưu trong tài khoản của bạn sẽ bị ẩn.</heading> <br>
    <heading><span>{{dot}}</span> Bạn có thể kích hoạt lại tài khoản của mình bằng cách đăng nhập lại.</heading>`,

  [SettingsKeys.Deactivate_Delete.Q2.title]:
    'Nếu tôi xóa tài khoản của mình, điều gì sẽ xảy ra?',
  [SettingsKeys.Deactivate_Delete.Q2.description]: `
    <heading><span>{{dot}}</span> Hồ sơ của bạn sẽ bị xóa khỏi danh sách tìm kiếm.</heading> <br>
    <heading><span>{{dot}}</span> Dữ liệu được lưu trong tài khoản của bạn sẽ bị xóa vĩnh viễn.</heading> <br>
    <heading><span>{{dot}}</span> Bạn sẽ không thể kích hoạt lại tài khoản của mình.</heading>`,

  [SettingsKeys.Deactivate_Delete.warning]:
    'Nếu bạn đang có bất kỳ đơn hàng nào đang chờ xử lý, bạn không thể vô hiệu hóa/xóa tài khoản của mình. Vui lòng hủy đơn hàng trước khi vô hiệu hóa/xóa tài khoản.',

  // Modal
  [SettingsKeys.Modal.Wallet_Connected.title]:
    'Ví mới của bạn đã được kết nối thành công!',

  [SettingsKeys.Modal.Notification.Newsletters.title]:
    'Bạn có chắc chắn muốn huỷ đăng ký nhận bản tin từ chúng tôi không?',
  [SettingsKeys.Modal.Notification.Newsletters.description]:
    'Nếu bạn huỷ đăng ký, bạn sẽ không nhận được cập nhật về phần thưởng, tính năng mới và nhiều hơn nữa. Chúng tôi sẽ cố gắng cung cấp thông tin hữu ích và hỗ trợ cho bạn!',

  [SettingsKeys.Modal.Notification.Update_Success.title]:
    'Thông báo của bạn đã được cập nhật thành công!',

  [SettingsKeys.Modal.Referral_Code.Submit_Success.title]:
    'Mã của bạn đã được gửi thành công!',
  [SettingsKeys.Modal.Referral_Code.Submit_Success.description]:
    'Bây giờ bạn có thể kiếm thêm điểm EVO thông qua việc tham gia các hoạt động khác nhau trên nền tảng của chúng tôi. Vui lòng <u>bấm vào đây</u> để tìm hiểu thêm về phần thưởng mã giới thiệu.',

  [SettingsKeys.Modal.Referral_Code.Submit_Valid.title]:
    'Vui lòng gửi một mã giới thiệu hợp lệ',
  [SettingsKeys.Modal.Referral_Code.Submit_Valid.description]:
    'Mã bạn nhập không hợp lệ. Vui lòng nhập mã giới thiệu hợp lệ.',

  [SettingsKeys.Modal.Deactivate.title]:
    'Bạn có chắc chắn muốn vô hiệu hóa tài khoản của mình không?',
  [SettingsKeys.Modal.Deactivate.description]:
    'Bạn có thể kích hoạt lại tài khoản của mình bằng cách đăng nhập lại.',

  [SettingsKeys.Modal.Delete.title]:
    'Bạn có chắc chắn muốn xóa tài khoản của mình không?',
  [SettingsKeys.Modal.Delete.description]:
    'Khi bạn xóa tài khoản của mình, bạn sẽ không thể kích hoạt lại nó. Dữ liệu của bạn sẽ bị xóa và không thể khôi phục. Dữ liệu liên quan đến tin nhắn và đơn hàng có thể vẫn hiển thị với người khác.',

  [SettingsKeys.Modal.Complete_Cancel_Order.title]:
    'Vui lòng hoàn thành / hủy đơn hàng của bạn để vô hiệu hóa tài khoản.',
  [SettingsKeys.Modal.Complete_Cancel_Order.description]:
    'Bạn không thể vô hiệu hóa tài khoản của mình cho đến khi bạn hủy hoặc hoàn thành các đơn hàng đang chờ xử lý.',

  [SettingsKeys.Modal.Why_Leaving.title]:
    'Hãy cho chúng tôi biết lý do bạn đang rời đi',
  [SettingsKeys.Modal.Why_Leaving.reason_1]:
    'Không có dịch vụ nào tôi muốn sử dụng.',
  [SettingsKeys.Modal.Why_Leaving.reason_2]: 'Giá quá cao.',
  [SettingsKeys.Modal.Why_Leaving.reason_3]: 'Thường xuyên xảy ra lỗi.',
  [SettingsKeys.Modal.Why_Leaving.reason_4]:
    'Ít lợi ích như phiếu giảm giá và dự trữ.',
  [SettingsKeys.Modal.Why_Leaving.reason_5]: 'Chất lượng dịch vụ thấp.',
  [SettingsKeys.Modal.Why_Leaving.reason_6]: 'Tôi đã tìm thấy sự thay thế.',
  [SettingsKeys.Modal.Why_Leaving.reason_7]:
    'Tôi gặp một thành viên không tôn trọng.',
  [SettingsKeys.Modal.Why_Leaving.reason_8]: 'Tôi muốn xóa dữ liệu của mình.',
  [SettingsKeys.Modal.Why_Leaving.button]: 'Xóa Tài Khoản',

  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title]:
    'Bạn chưa thể vô hiệu hóa hoặc xóa tài khoản của mình',
  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description]:
    'Bạn có thể vô hiệu hóa hoặc xóa tài khoản sau 24 giờ kể từ khi tài khoản được tạo lần đầu. Vui lòng thử lại sau.',
};
