import styled from 'styled-components';

const TitleButton = styled.button.attrs({
  className: 'bh5',
})`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  transition: opacity 150ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;

export default TitleButton;
