import MessageKeys from '../translationKeys/MessageKeys';

export const MessageTranslations = {
  [MessageKeys.Dropdown.title]: '收件箱',
  [MessageKeys.Dropdown.no_message]: '暂无消息',
  [MessageKeys.Dropdown.bottom_btn]: '查看所有消息',

  [MessageKeys.ChatBox.title]: '欢迎来到您的收件箱！',
  [MessageKeys.ChatBox.no_chat]: `
  看起来还没有开始任何对话。 <br>
  但不用担心！当您开始一次对话时，您将在这里找到它们。`,
  [MessageKeys.ChatBox.no_selected_chat]: '点击聊天开始发送消息！',
  [MessageKeys.ChatBox.new_chat]: `
  2Start a conversation and connect with {{user}} to discuss your project details. <br>
  <span>Send a proposal to share information all-in-one.</span>`,
  [MessageKeys.ChatBox.new_chat]: `
    开始一次对话，与{{user}}建立联系，讨论您的项目细节。<br>
    <span>发送提案以一站式分享信息。</span>`,
  [MessageKeys.ChatBox.search]: `
    <h6>未找到结果</h6>
    <p>请尝试其他关键词。</p>`,

  [MessageKeys.ChatBox.Message.me]: '我',
  [MessageKeys.ChatBox.Message.copy]: '复制消息',
  [MessageKeys.ChatBox.Message.report]: '举报消息',
  [MessageKeys.ChatBox.Message.download_file]: '下载所有文件',

  [MessageKeys.ChatBox.Message.typing]: '正在输入...',
  [MessageKeys.ChatBox.Message.Offer_Package.text]: '这是为您定制的套餐。',
  [MessageKeys.ChatBox.Message.Offer_Package.description]:
    '接受客户的提案后，订单将开始。',
  [MessageKeys.ChatBox.Message.Offer_Package.btn]: '继续结账',

  [MessageKeys.ChatBox.Message.Select_Package.title]: '选择套餐',
  [MessageKeys.ChatBox.Message.Select_Package.btn]: '创建新套餐',
  [MessageKeys.ChatBox.Message.Select_Package.complete_package_first]:
    '先完成您的第一个套餐，然后开始发送报价！',
  [MessageKeys.ChatBox.Message.Select_Package.no_price]: '没有价格',
  [MessageKeys.ChatBox.Message.Select_Package.verified_only]: '仅限已验证用户',

  [MessageKeys.ChatBox.Message.Custom_Package.title]: '自定义套餐',
  [MessageKeys.ChatBox.Message.Custom_Package.description]:
    '显示给买家的价格将包括约{{number}}%的服务费。',
  [MessageKeys.ChatBox.Message.Custom_Package.offering]: '提供中...',
  [MessageKeys.ChatBox.Message.Custom_Package.btn1]: '提供套餐',
  [MessageKeys.ChatBox.Message.Custom_Package.btn2]: '返回选择',

  [MessageKeys.ChatBox.Message.Request_Price.text]: '我想要请求您的套餐价格。',
  [MessageKeys.ChatBox.Message.Request_Price.description]:
    '已成功向创作者请求价格。',
  [MessageKeys.ChatBox.Message.Request_Price.btn]: '提供价格和套餐',

  [MessageKeys.ChatBox.Message.File.singular]: '个文件',
  [MessageKeys.ChatBox.Message.File.plural]: '个文件',
  [MessageKeys.ChatBox.Message.File.plural_2]: '2 个文件',
  [MessageKeys.ChatBox.Message.File.plural_3]: '3 个文件',
  [MessageKeys.ChatBox.Message.File.plural_4]: '4 个文件',
  [MessageKeys.ChatBox.Message.File.plural_5]: '5 个文件',
  [MessageKeys.ChatBox.Message.File.plural_6]: '6 个文件',
  [MessageKeys.ChatBox.Message.File.plural_7]: '7 个文件',
  [MessageKeys.ChatBox.Message.File.plural_8]: '8 个文件',
  [MessageKeys.ChatBox.Message.File.plural_9]: '9 个文件',
  [MessageKeys.ChatBox.Message.File.plural_10]: '10 个文件',
  [MessageKeys.ChatBox.Message.File.expired]: '此文件已过期。',

  [MessageKeys.ChatBox.Message.Error.sending]: '发送中...',
  [MessageKeys.ChatBox.Message.Error.fail]: '发送失败',

  [MessageKeys.InputField.chat_disabled]: '无法再与此用户联系。',
  [MessageKeys.InputField.web]: '输入文本或拖放文件',
  [MessageKeys.InputField.mobile]: '输入您的消息',
  [MessageKeys.InputField.drag_drop]: '拖放文件',

  [MessageKeys.InputField.Btn.offer_package]: '提供套餐',
  [MessageKeys.InputField.Btn.send_proposal]: '发送提案',

  [MessageKeys.UserInfo.Verified.tooltip]: `
    此帐户代表一个已验证的 <br>
    公司或组织，或者是与它们有关的成员。`,

  [MessageKeys.UserInfo.Status.online]: '在线',
  [MessageKeys.UserInfo.Status.offline]: '最后查看',

  [MessageKeys.UserInfo.order_together]: '共同下单',

  [MessageKeys.UserInfo.Btn.edit_profile]: '编辑个人资料',
  [MessageKeys.UserInfo.Btn.visit_profile]: '访问个人资料',

  [MessageKeys.Error.max_characters]: '最多{{number}}个字符',
  [MessageKeys.Error.max_file_size_1]: '总文件大小限制为最大',
  [MessageKeys.Error.max_file_size_2]: 'MB',
  [MessageKeys.Error.max_file_1]: '您最多可以发送',
  [MessageKeys.Error.max_file_2]: '个文件',
};
