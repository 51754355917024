export const TwitterSpaceIcon = ({ size = 1 }) => (
  <svg
    width={size * 15}
    height={size * 20}
    viewBox="0 0 15 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.5 12.7273C8.49456 12.7273 9.44839 12.3442 10.1517 11.6622C10.8549 10.9803 11.25 10.0553 11.25 9.09091V3.63636C11.25 2.67194 10.8549 1.74702 10.1517 1.06507C9.44839 0.383116 8.49456 0 7.5 0C6.50544 0 5.55161 0.383116 4.84835 1.06507C4.14509 1.74702 3.75 2.67194 3.75 3.63636V9.09091C3.75 10.0553 4.14509 10.9803 4.84835 11.6622C5.55161 12.3442 6.50544 12.7273 7.5 12.7273ZM5.625 3.63636C5.625 3.15415 5.82254 2.69169 6.17418 2.35071C6.52581 2.00974 7.00272 1.81818 7.5 1.81818C7.99728 1.81818 8.47419 2.00974 8.82582 2.35071C9.17746 2.69169 9.375 3.15415 9.375 3.63636V9.09091C9.375 9.57312 9.17746 10.0356 8.82582 10.3766C8.47419 10.7175 7.99728 10.9091 7.5 10.9091C7.00272 10.9091 6.52581 10.7175 6.17418 10.3766C5.82254 10.0356 5.625 9.57312 5.625 9.09091V3.63636ZM15 9.09091C15 8.8498 14.9012 8.61857 14.7254 8.44808C14.5496 8.2776 14.3111 8.18182 14.0625 8.18182C13.8139 8.18182 13.5754 8.2776 13.3996 8.44808C13.2238 8.61857 13.125 8.8498 13.125 9.09091C13.125 10.5375 12.5324 11.9249 11.4775 12.9479C10.4226 13.9708 8.99184 14.5455 7.5 14.5455C6.00816 14.5455 4.57742 13.9708 3.52252 12.9479C2.46763 11.9249 1.875 10.5375 1.875 9.09091C1.875 8.8498 1.77623 8.61857 1.60041 8.44808C1.4246 8.2776 1.18614 8.18182 0.9375 8.18182C0.68886 8.18182 0.450403 8.2776 0.274588 8.44808C0.0987722 8.61857 0 8.8498 0 9.09091C0.00165543 10.8612 0.669114 12.5701 1.87725 13.8972C3.08538 15.2244 4.75123 16.0787 6.5625 16.3V18.1818H4.6875C4.43886 18.1818 4.2004 18.2776 4.02459 18.4481C3.84877 18.6186 3.75 18.8498 3.75 19.0909C3.75 19.332 3.84877 19.5632 4.02459 19.7337C4.2004 19.9042 4.43886 20 4.6875 20H10.3125C10.5611 20 10.7996 19.9042 10.9754 19.7337C11.1512 19.5632 11.25 19.332 11.25 19.0909C11.25 18.8498 11.1512 18.6186 10.9754 18.4481C10.7996 18.2776 10.5611 18.1818 10.3125 18.1818H8.4375V16.3C10.2488 16.0787 11.9146 15.2244 13.1228 13.8972C14.3309 12.5701 14.9983 10.8612 15 9.09091Z" />
  </svg>
);
