export const PieChartIcon = ({ size = 1 }) => (
  <svg
    width={22 * size}
    height={23 * size}
    viewBox="0 0 22 23"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.9537 0.566405C9.27849 0.563374 7.625 0.945307 6.12083 1.68272C4.61667 2.42014 3.30206 3.49332 2.27848 4.81943C1.2549 6.14554 0.549709 7.68912 0.217351 9.33101C-0.115006 10.9729 -0.0656475 12.6692 0.361618 14.289C0.788883 15.9088 1.58263 17.4088 2.68159 18.6731C3.78055 19.9375 5.15533 20.9324 6.69983 21.5811C8.24433 22.2298 9.91723 22.515 11.5894 22.4145C13.2616 22.3141 14.8883 21.8308 16.3441 21.0019H16.4206H16.4753C18.5804 19.8035 20.2279 17.9401 21.1593 15.7041C22.0908 13.4681 22.2533 10.9861 21.6215 8.64769C20.9896 6.30929 19.5992 4.2469 17.6684 2.78425C15.7376 1.3216 13.3758 0.541502 10.9537 0.566405ZM12.0471 2.82972C13.9715 3.07376 15.7601 3.95055 17.1318 5.3222C18.5034 6.69385 19.3802 8.48253 19.6243 10.4069H12.0471V2.82972ZM10.9537 20.2474C8.73903 20.2323 6.61255 19.3777 5.00339 17.856C3.39422 16.3343 2.42218 14.2589 2.28343 12.0485C2.14467 9.83817 2.84954 7.65747 4.25577 5.9465C5.66201 4.23552 7.66491 3.12167 9.8603 2.82972V11.5003C9.86461 11.6312 9.89046 11.7605 9.93683 11.883V11.9705V12.047L14.3104 19.5586C13.2495 20.0126 12.1076 20.247 10.9537 20.2474ZM16.2348 18.4543L12.8453 12.5937H19.6243C19.4771 13.7468 19.1015 14.8589 18.5195 15.8652C17.9375 16.8715 17.1608 17.7516 16.2348 18.4543Z" />
  </svg>
);
