export const BellIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 16}
    height={size * 20}
    viewBox="0 0 16 20"
    fill="currentColor"
  >
    <path d="M0 17V15H2V8C2 6.61667 2.41667 5.38733 3.25 4.312C4.08333 3.23733 5.16667 2.53333 6.5 2.2V1.5C6.5 1.08333 6.646 0.729333 6.938 0.438C7.22933 0.146 7.58333 0 8 0C8.41667 0 8.77067 0.146 9.062 0.438C9.354 0.729333 9.5 1.08333 9.5 1.5V2.2C10.8333 2.53333 11.9167 3.23733 12.75 4.312C13.5833 5.38733 14 6.61667 14 8V15H16V17H0ZM8 20C7.45 20 6.97933 19.8043 6.588 19.413C6.196 19.021 6 18.55 6 18H10C10 18.55 9.80433 19.021 9.413 19.413C9.021 19.8043 8.55 20 8 20ZM4 15H12V8C12 6.9 11.6083 5.95833 10.825 5.175C10.0417 4.39167 9.1 4 8 4C6.9 4 5.95833 4.39167 5.175 5.175C4.39167 5.95833 4 6.9 4 8V15Z" />
  </svg>
);
