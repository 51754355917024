import GuideKeys from '../translationKeys/GuidePageKeys';

export const GuideTranslations = {
  [GuideKeys.click_to_join]: 'Nhấp để tham gia',

  // Portfolio Banner
  [GuideKeys.Portfolio_Banner.description]: `
    Tạo một trang tuyệt vời <br>
    bằng URL đặc biệt của riêng bạn!`,
  [GuideKeys.Portfolio_Banner.view_sample]: 'Xem mẫu',

  // Creator Invitation
  [GuideKeys.Creator_Invitation.hi_ginseng_mega]: 'Xin chào, tôi là MEGA!',
  [GuideKeys.Creator_Invitation.nice_to_meet_you]: 'Rất vui được gặp bạn~!',
  [GuideKeys.Creator_Invitation.we_help_you_receive]: 'Chúng tôi giúp bạn nhận',
  [GuideKeys.Creator_Invitation.proposal_golbal_brands]:
    'Đề xuất từ các thương hiệu toàn cầu!',
  [GuideKeys.Creator_Invitation.let_companies_directly]:
    'Cho phép doanh nghiệp',
  [GuideKeys.Creator_Invitation.order_your_package]: 'Đặt gói của bạn!',
  [GuideKeys.Creator_Invitation.click_to_join]: 'Nhấp vào đây',
  [GuideKeys.Creator_Invitation.anytime]: 'Để tham gia bất cứ lúc nào!',
  [GuideKeys.Creator_Invitation.package_featured]:
    'Gói của bạn sẽ được giới thiệu với các doanh nghiệp',
  [GuideKeys.Creator_Invitation.showcase_channel]:
    'Từ 128+ quốc gia! Trưng bày kênh của bạn',
  [GuideKeys.Creator_Invitation.showcase_channel_2]:
    'và kết nối với khách hàng toàn cầu!',
  [GuideKeys.Creator_Invitation.order_package]: 'Đặt Gói',

  [GuideKeys.Creator_Invitation.Mobile.we_help_you_receive]:
    'Chúng tôi giúp bạn nhận',
  [GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands]:
    'Đề xuất từ các thương hiệu toàn cầu!',
  [GuideKeys.Creator_Invitation.Mobile.let_companies_directly]:
    'Cho phép doanh nghiệp',
  [GuideKeys.Creator_Invitation.Mobile.order_your_package]: 'Đặt gói của bạn!',
  [GuideKeys.Creator_Invitation.Mobile.click_to_join]: 'Nhấp để tham gia',
  [GuideKeys.Creator_Invitation.Mobile.anytime]: 'bất cứ lúc nào!',
};
