export const MetaverseIcon = ({ size = 1 }) => {
  return (
    <svg
      width={18 * size}
      height={22 * size}
      viewBox="0 0 18 22"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.9921 6.09206C17.9921 6.09206 17.9921 6.09206 17.9921 6.00678L17.9282 5.84687C17.9059 5.81294 17.881 5.78086 17.8536 5.75093C17.825 5.7058 17.7929 5.66302 17.7576 5.623L17.6617 5.54838L17.4911 5.46309L9.49571 0.527277C9.32628 0.421383 9.13051 0.365234 8.93071 0.365234C8.73091 0.365234 8.53513 0.421383 8.3657 0.527277L0.434279 5.46309L0.338334 5.54838L0.24239 5.623C0.207051 5.66302 0.174968 5.7058 0.146445 5.75093C0.119039 5.78086 0.0940838 5.81294 0.0718216 5.84687L0.00785834 6.00678C0.00785834 6.00678 0.00785834 6.00678 0.00785834 6.09206C-0.00261945 6.18416 -0.00261945 6.27714 0.00785834 6.36924V15.6865C0.0074958 15.8677 0.0533106 16.046 0.140977 16.2045C0.228644 16.3631 0.355271 16.4966 0.508903 16.5927L8.50429 21.5285C8.5535 21.5589 8.6076 21.5805 8.66419 21.5924C8.66419 21.5924 8.7175 21.5924 8.74948 21.5924C8.92983 21.6497 9.12347 21.6497 9.30382 21.5924C9.30382 21.5924 9.35713 21.5924 9.38911 21.5924C9.4457 21.5805 9.49981 21.5589 9.54902 21.5285L17.4911 16.5927C17.6447 16.4966 17.7714 16.3631 17.859 16.2045C17.9467 16.046 17.9925 15.8677 17.9921 15.6865V6.36924C18.0026 6.27714 18.0026 6.18416 17.9921 6.09206ZM7.89664 18.7141L2.03336 15.0895V8.28813L7.89664 11.902V18.7141ZM8.96269 10.0578L2.9928 6.36924L8.96269 2.69136L14.9326 6.36924L8.96269 10.0578ZM15.892 15.0895L10.0287 18.7141V11.902L15.892 8.28813V15.0895Z" />
    </svg>
  );
};
