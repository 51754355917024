import { createContext, useContext, useMemo, useState } from 'react';
import getPackage from './getPackage';
import { useEffect } from 'react';
import { useCallback } from 'react';
import useUser from 'hooks/useUser';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import { useLocation } from 'react-router-dom';

const PackagesContext = createContext();
const profilePageRegex = /^\/(@[a-zA-Z0-9_.-]+|[0-9]+)$/;

const PackagesProvider = ({
  children,
  userId,
  handle,
  userData,
  platform,
  packageType,
  fixedPackages,
  disabled = false,
}) => {
  const [selectedType, setSelectedType] = useState();

  const [currentPackages, setCurrentPackages] = useState({});
  const [loadingPackages, setLoadingPackages] = useState(false);
  const [settingUpPackage, setSettingUpPackage] = useState(false);
  const [error, setError] = useState(null);
  const { getPreferredDescription } = useAutoTranslation();
  const { pathname } = useLocation();

  const { user } = useUser();
  const isMine = useMemo(() => {
    if (!user || !(userId || handle)) return false;

    if (handle) return user?.handle === handle;

    return user?.id === userId;
  }, [user, userId, handle]);

  const loading = loadingPackages || settingUpPackage;

  useEffect(() => {
    if (!currentPackages || loadingPackages) return;

    const representativePackage = Object.keys(currentPackages).find(
      (key) => currentPackages[key].isRep
    );
    if (!selectedType && representativePackage) {
      setSelectedType(representativePackage);
      return;
    }
  }, [currentPackages, loadingPackages, selectedType]);

  useEffect(() => {
    setSelectedType('');
  }, [platform]);

  useEffect(() => {
    if (packageType) {
      setSelectedType(packageType);
    }
  }, [packageType]);

  const selectType = useCallback((type) => {
    setSelectedType(type);
  }, []);

  const getPackages = useCallback(async () => {
    if (disabled || !platform) return;

    return getPackage({
      userId,
      handle,
      platform,
      sendTranslations: profilePageRegex.test(pathname),
    })
      .then((res) => {
        setCurrentPackages(res);
      })
      .catch((err) => {
        setError(err);
      });
  }, [userId, handle, platform, disabled, pathname]);

  useEffect(() => {
    if (fixedPackages) return setCurrentPackages(fixedPackages[platform]);
    setLoadingPackages(true);
    setSettingUpPackage(true);
    getPackages().finally(() => {
      setSettingUpPackage(false);
      setLoadingPackages(false);
    });
  }, [fixedPackages, platform, isMine, userId, getPackages]);

  const getIsPackageComplete = useCallback(
    (currentPackage) => {
      if (!currentPackage) return false;

      const {
        title,
        featuredContent,
        description,
        price,
        priceType,
        delivery,
        typeLength,
      } = currentPackage;

      let descriptionLength = 0;

      try {
        const parsedDescription = JSON.parse(
          getPreferredDescription(description)
        );
        descriptionLength = parsedDescription.length;
      } catch (err) {
        descriptionLength = 0;
      }

      const validPrice =
        priceType === 'private' ||
        (priceType === 'locked' && !isMine) ||
        price > 0;

      if (
        !title ||
        !featuredContent ||
        !descriptionLength ||
        !validPrice ||
        !delivery ||
        !typeLength
      )
        return false;

      return true;
    },
    [isMine, getPreferredDescription]
  );

  const availablePackages = useMemo(() => {
    if (disabled || loadingPackages || !currentPackages) return [];

    const available = Object.keys(currentPackages).filter((key) => {
      const currentPackage = currentPackages[key];
      return getIsPackageComplete(currentPackage);
    });
    setSettingUpPackage(false);
    return available;
  }, [currentPackages, disabled, getIsPackageComplete, loadingPackages]);

  const isCurrentPackageComplete = useMemo(() => {
    if (disabled) return false;

    return availablePackages.includes(selectedType);
  }, [availablePackages, selectedType, disabled]);

  if (disabled || (!userId && !handle)) return children;

  return (
    <PackagesContext.Provider
      value={{
        userId,
        handle,
        userData,
        selectedType,
        selectType,
        platform,
        availablePackages,
        currentPackage: currentPackages?.[selectedType] || {},
        isCurrentPackageComplete,
        loading,
        error,
        refetch: getPackages,
      }}
    >
      {children}
    </PackagesContext.Provider>
  );
};

const usePackages = (condition = true) => {
  const context = useContext(PackagesContext);

  if (!condition) return {};

  if (!context) {
    throw new Error(
      'usePackages (Packages card) must be used within PackagesProvider or PackagesProvider must be disabled'
    );
  }

  const {
    userId,
    handle,
    userData,
    selectedType,
    selectType,
    platform,
    availablePackages,
    packages,
    currentPackage,
    isCurrentPackageComplete,
    refetch,
    loading,
    error,
  } = context;

  return {
    userId,
    handle,
    userData,
    selectedType,
    selectType,
    platform,
    availablePackages,
    packages,
    currentPackage,
    isCurrentPackageComplete,
    loading,
    error,
    refetch,
  };
};

export { PackagesProvider, usePackages };
