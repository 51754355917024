export const DoubleChevronLeftIcon = ({ size = 1 }) => (
  <svg
    width={11 * size}
    height={8 * size}
    viewBox="0 0 11 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.58743 0.460402C3.84615 0.205135 4.26196 0.205136 4.52068 0.460402C4.78435 0.720562 4.78435 1.1461 4.52068 1.40626L2.61335 3.28816C2.21647 3.67974 2.21647 4.32026 2.61335 4.71184L4.52068 6.59374C4.78435 6.8539 4.78435 7.27944 4.52068 7.5396C4.26196 7.79486 3.84615 7.79486 3.58743 7.5396L0.721456 4.71184C0.324583 4.32026 0.324583 3.67974 0.721456 3.28816L3.58743 0.460402Z" />
    <path d="M9.58743 0.460402C9.84615 0.205135 10.262 0.205136 10.5207 0.460402C10.7844 0.720562 10.7844 1.1461 10.5207 1.40626L8.61335 3.28816C8.21647 3.67974 8.21647 4.32026 8.61335 4.71184L10.5207 6.59374C10.7844 6.8539 10.7844 7.27944 10.5207 7.5396C10.262 7.79486 9.84615 7.79486 9.58743 7.5396L6.72146 4.71184C6.32458 4.32026 6.32458 3.67974 6.72146 3.28816L9.58743 0.460402Z" />
  </svg>
);
