import styled from 'styled-components';

/**
 * Simple styled component to wrap icons and use as a button.
 * @param {object} props - The props passed to the button component.
 */
const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  border-radius: 1rem;
  transition: all 150ms ease-in-out;
  pointer-events: all;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    color: var(--color-indigo-25);
    cursor: default;

    &:hover {
      opacity: 1;
    }
  }
`;

export default IconButton;
