import EvoMissionsKeys from '../translationKeys/EvoMissionsPage';

export const EvoMissionsTranslations = {
  [EvoMissionsKeys.claim_evos]: 'EVO 수령',
  [EvoMissionsKeys.my_evos]: '나의 EVO',
  [EvoMissionsKeys.special_missions]: '특별 미션',
  [EvoMissionsKeys.creator_missions]: '크리에이터 미션',
  [EvoMissionsKeys.general_missions]: '일반 미션',
  [EvoMissionsKeys.successfully_claimed]: '성공적으로 획득했습니다!',
  [EvoMissionsKeys.go_back_home]: '내 프로필로 이동',

  // Token Details
  [EvoMissionsKeys.Token_Details._1k]: '1천',
  [EvoMissionsKeys.Token_Details._10k]: '1만',
  [EvoMissionsKeys.Token_Details.no_data]: '표시할 데이터가 없습니다',
  [EvoMissionsKeys.Token_Details.results_found]: '총 {{number}}개의 결과',
  [EvoMissionsKeys.Token_Details.result_found]: '총 {{number}}개의 결과',

  // Side Popup
  [EvoMissionsKeys.Side_Popup.text]: 'EVO가 무엇인가요?',

  // Transaction
  [EvoMissionsKeys.Transaction.daily_reward]: '일일 보상',
  [EvoMissionsKeys.Transaction.price_unlock_received]:
    '클라이언트가 가격 해제하기',
  [EvoMissionsKeys.Transaction.price_unlock_spent]: '가격 잠금 해제하기',
  [EvoMissionsKeys.Transaction.referral_reward]: '초대 보상',
  [EvoMissionsKeys.Transaction.creator_registration_reward]:
    '크리에이터 등록 보상',

  [EvoMissionsKeys.Transaction.complete_1_special_completed]:
    '스페셜 미션 완료하기',
  [EvoMissionsKeys.Transaction.complete_777_special_completed]:
    '스페셜 미션 완료하기',
  [EvoMissionsKeys.Transaction.complete_77_special_completed]:
    '스페셜 미션 완료하기',
  [EvoMissionsKeys.Transaction.complete_7_special_completed]:
    '스페셜 미션 완료하기',
  [EvoMissionsKeys.Transaction.create_premium_package_completed]:
    '프리미엄 패키지 만들기',
  [EvoMissionsKeys.Transaction.first_990_creator_claimed_completed]:
    '최초 990 VIP',
  [EvoMissionsKeys.Transaction.refer_1000_early_vips_completed]:
    '최초 VIP 초대하기',
  [EvoMissionsKeys.Transaction.refer_100_early_vips_completed]:
    '최초 VIP 초대하기',
  [EvoMissionsKeys.Transaction.refer_10_early_vips_completed]:
    '최초 VIP 초대하기',
  [EvoMissionsKeys.Transaction.referral_ranking_completed]: 'VIP 초대 랭킹',
  [EvoMissionsKeys.Transaction.special_daily_reward_completed]:
    '일일 스페셜 보상',
  [EvoMissionsKeys.Transaction.tv_easter_egg_completed]:
    '플랫폼에서 이스터에그를 찾아보세요',
  [EvoMissionsKeys.Transaction.update_creator_profile_completed]:
    '크리에이터 프로필 업데이트하기',
  [EvoMissionsKeys.Transaction.update_profile_completed]: '프로필 업데이트하기',
  [EvoMissionsKeys.Transaction.visit_main_tabs_completed]: '메인 탭 들어가보기',

  // Special Mission
  // Early 990 VIPs
  [EvoMissionsKeys.Special.early_990_vips.title]: '최초 990 VIP',
  [EvoMissionsKeys.Special.early_990_vips.description]:
    '여러분은 최초 VIP 크리에이터입니다! 지금 크리에이터로 등록하고 프리미엄 패스를 잠금 해제하세요.',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d1]:
    '프리미엄 패스를 통해 플랫폼 수수료 없이',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d2]: '주문을 받으세요!',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d3]: '',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    ' *PayPal 및 신용 카드와 같은 제3자 수수료는 적용될 수 있습니다.',

  // General
  [EvoMissionsKeys.complete_1_special.title]: '스페셜 미션 완료하기',
  [EvoMissionsKeys.complete_1_special.description]:
    '스페셜 미션을 완료하고 보너스를 받아보세요',
  [EvoMissionsKeys.complete_1_special.extra_reward]: '',
  [EvoMissionsKeys.complete_1_special.reward_description]: '',

  [EvoMissionsKeys.complete_777_special.title]: '스페셜 미션 완료하기',
  [EvoMissionsKeys.complete_777_special.description]:
    '스페셜 미션을 완료하고 보너스를 받아보세요',
  [EvoMissionsKeys.complete_777_special.extra_reward]: '',
  [EvoMissionsKeys.complete_777_special.reward_description]: '',

  [EvoMissionsKeys.complete_77_special.title]: '스페셜 미션 완료하기',
  [EvoMissionsKeys.complete_77_special.description]:
    '스페셜 미션을 완료하고 보너스를 받아보세요',
  [EvoMissionsKeys.complete_77_special.extra_reward]: '',
  [EvoMissionsKeys.complete_77_special.reward_description]: '',

  [EvoMissionsKeys.complete_7_special.title]: '스페셜 미션 완료하기',
  [EvoMissionsKeys.complete_7_special.description]:
    '스페셜 미션을 완료하고 보너스를 받아보세요',
  [EvoMissionsKeys.complete_7_special.extra_reward]: '',
  [EvoMissionsKeys.complete_7_special.reward_description]: '',

  [EvoMissionsKeys.tv_easter_egg.title]: '플랫폼에서 이스터에그를 찾아보세요!',
  [EvoMissionsKeys.tv_easter_egg.description]:
    '홈페이지에서 이스터에그를 찾아보세요. 꼭꼭 숨겨놓았으니 찾기 어려울거에요',
  [EvoMissionsKeys.tv_easter_egg.extra_reward]: '',
  [EvoMissionsKeys.tv_easter_egg.reward_description]: '',

  [EvoMissionsKeys.update_profile.title]: '프로필 업데이트하기',
  [EvoMissionsKeys.update_profile.description]:
    '프로필을 업데이트 후, 보상을 받고 저희와 함께 시작해보세요!',
  [EvoMissionsKeys.update_profile.extra_reward]: '',
  [EvoMissionsKeys.update_profile.reward_description]: '',

  [EvoMissionsKeys.visit_main_tabs.title]: '메인 탭 들어가보기',
  [EvoMissionsKeys.visit_main_tabs.description]:
    '위쪽 4개의 메인 탭을 들어가서 새로운 것들을 구경한 후, 보상을 획득하세요.',
  [EvoMissionsKeys.visit_main_tabs.extra_reward]: '',
  [EvoMissionsKeys.visit_main_tabs.reward_description]: '',

  // Creator
  [EvoMissionsKeys.create_premium_package.title]: '프리미엄 패키지 만들기',
  [EvoMissionsKeys.create_premium_package.description]:
    '프로필에서 프리미엄 패키지를 만들어 보세요. 만들어 놓은 템플릿을 사용하거나 ChatGPT를 이용해서 최고의 서비스를 제공해보세요!',
  [EvoMissionsKeys.create_premium_package.extra_reward]: '',
  [EvoMissionsKeys.create_premium_package.reward_description]: '',

  [EvoMissionsKeys.update_creator_profile.title]:
    '크리에이터 프로필 업데이트하기',
  [EvoMissionsKeys.update_creator_profile.description]:
    '글로벌 슈퍼스타가 될 준비가 되셨나요? 프로필을 업데이트한 후 세계에 여러분의 채널을 알려보세요!',
  [EvoMissionsKeys.update_creator_profile.extra_reward]: '',
  [EvoMissionsKeys.update_creator_profile.reward_description]: '',

  // Special
  [EvoMissionsKeys.first_990_creator_claimed.title]: 'VIP 990 얼리 크리에이터!',
  [EvoMissionsKeys.first_990_creator_claimed.description]:
    '얼리 크리에이터로 초대 받으신 것을 환영합니다! 프로필을 완성하여 글로벌 브랜드와 협업하세요!',
  [EvoMissionsKeys.first_990_creator_claimed.extra_reward]:
    '프리미엄 크리에이터 패스',
  [EvoMissionsKeys.first_990_creator_claimed.reward_description]:
    'Creators with a premium pass are',

  [EvoMissionsKeys.refer_1000_early_vips.title]: '최초 VIP 초대하기',
  [EvoMissionsKeys.refer_1000_early_vips.description]:
    '1000명 이상의 사람들을 초대하고 보상을 획득하세요!',
  [EvoMissionsKeys.refer_1000_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_1000_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_100_early_vips.title]: '최초 VIP 초대하기',
  [EvoMissionsKeys.refer_100_early_vips.description]:
    '100명 이상의 사람들을 초대하고 보상을 획득하세요!',
  [EvoMissionsKeys.refer_100_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_100_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_10_early_vips.title]: '최초 VIP 초대하기',
  [EvoMissionsKeys.refer_10_early_vips.description]:
    '10명 이상의 사람들을 초대하고 보상을 획득하세요!',
  [EvoMissionsKeys.refer_10_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_10_early_vips.reward_description]: '',

  [EvoMissionsKeys.referral_ranking.title]: 'VIP 초대 랭킹',
  [EvoMissionsKeys.referral_ranking.description]:
    '추천 랭킹의 각 백분위에 대해 월말에 추가 EVO 포인트가 지급됩니다.',
  [EvoMissionsKeys.referral_ranking.extra_reward]: '',
  [EvoMissionsKeys.referral_ranking.reward_description]: '',

  [EvoMissionsKeys.special_daily_reward.title]: '일일 스페셜 보상',
  [EvoMissionsKeys.special_daily_reward.description]:
    '홈페이지를 방문하고 매일 일일 보상을 획득하세요',
  [EvoMissionsKeys.special_daily_reward.extra_reward]: '',
  [EvoMissionsKeys.special_daily_reward.reward_description]: '',

  [EvoMissionsKeys.Modal.already_unlock]: '이 이스터 에그는',
  [EvoMissionsKeys.Modal.easter_egg]: '이미 언락되었습니다.',
  [EvoMissionsKeys.Modal.unlock_premium_pass]: '프리미엄 패스를 획득했습니다.',
  [EvoMissionsKeys.Modal.visit_profile]: 'MY 프로필 방문',
  [EvoMissionsKeys.Modal.not_now]: '건너뛰기',
};
