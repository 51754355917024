import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useClickAway, useToggle } from 'react-use';
import { useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import NotificationBox from '../Common/NotificationBox';
import NoOrders from './NoOrders';
import OrderItem from './OrderItem';
import useOrders from './useOrders';
import OrderSkeleton from './OrderSkeleton';
import ModalKeys from 'translations/translationKeys/ModalKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const skeletons = Array.from({ length: 10 }).map((_, i) => (
  <OrderSkeleton key={i} id={i} />
));

const Orders = () => {
  const { t } = useTranslation();
  const [showNotificationBox, toggleNotificationBox] = useToggle(false);
  const ref = useRef(null);
  useClickAway(ref, () => {
    if (showNotificationBox) toggleNotificationBox(false);
  });

  const { orders, loading, fetching, loadMore } = useOrders();

  return (
    <>
      {showNotificationBox && <Backdrop />}

      <Wrapper ref={ref}>
        <TextButton className="text-inherit" onClick={toggleNotificationBox}>
          {t(GeneralKeys.my_orders)}
        </TextButton>

        <AnimatePresence>
          {showNotificationBox && (
            <NotificationBox
              width="552px"
              height="538px"
              renderFooter={
                <>
                  <span />

                  <MenuTextButton onClick={() => {}}>
                    {t(ModalKeys.My_Order.Dropdown.bottom_btn)}
                  </MenuTextButton>
                </>
              }
              translateX="-59%"
              onEndReached={loadMore}
            >
              <AnimatePresence mode="wait">
                {loading ? (
                  skeletons
                ) : orders.length > 0 ? (
                  <>
                    {orders
                      .sort((a, b) => {
                        return new Date(b.updatedAt) - new Date(a.updatedAt);
                      })
                      .map((order) => {
                        return (
                          <OrderItem
                            key={order?.id}
                            id={order?.id}
                            featuredImage={order?.package?.featuredImage}
                            minFeaturedImage={order?.package?.minFeaturedImage}
                            title={order?.package?.title}
                            platform={order?.package?.platform}
                            userName={order?.counterpart?.name}
                            status={order?.status}
                            closeMenu={() => toggleNotificationBox(false)}
                          />
                        );
                      })}

                    {fetching && skeletons}
                  </>
                ) : (
                  <NoOrders key="no-notifications" />
                )}
              </AnimatePresence>
            </NotificationBox>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  );
};

export default Orders;

const TextButton = styled.button.attrs({
  className: 'sbh3',
})`
  background: none;
  border: none;
  padding: 0;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const MenuTextButton = styled.button.attrs({
  className: 'bh7',
})`
  background: none;
  border: none;
  padding: 0;
  text-align: center;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
  }
`;

const Backdrop = styled.div.attrs({
  onMouseOver: (e) => e.stopPropagation(),
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
