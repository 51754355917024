import Stack from 'components/Basics/Layout/Stack';
import Tooltip from 'components/Basics/Tooltip';
import React from 'react';

const ContentIcon = ({ icon, text, tooltip, className = 'mh16' }) => {
  return (
    <Tooltip content={tooltip}>
      <Stack
        className={className}
        direction="row"
        gap="0.375rem"
        align="center"
      >
        {icon}
        {text}
      </Stack>
    </Tooltip>
  );
};

export default ContentIcon;
