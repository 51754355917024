export const QuotesIcon = ({ size = 1 }) => (
  <svg
    width={10 * size}
    height={6 * size}
    viewBox="0 0 10 6"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.19902 5.7641H0L1.63391 0.23584H3.4398L2.19902 5.7641Z" />
    <path d="M8.36609 5.7641H6.63391L7.87469 0.23584H10L8.36609 5.7641Z" />
  </svg>
);
