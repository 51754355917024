export const TikTokFullIcon = ({ size = 1 }) => (
  <svg
    width={size * 99}
    height={size * 24}
    viewBox="0 0 99 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7" clipPath="url(#clip0_72168_7070)">
      <path
        d="M8.36598 9.45182V8.51661C8.04134 8.46516 7.71343 8.43698 7.38477 8.43227C4.2029 8.42556 1.38586 10.4875 0.430385 13.5225C-0.525088 16.5575 0.602739 19.8614 3.21464 21.6786C1.25814 19.5847 0.714883 16.5365 1.82753 13.8956C2.94018 11.2547 5.50095 9.51433 8.36598 9.45182Z"
        fill="#25F4EE"
      />
      <path
        d="M8.5423 20.0458C10.3213 20.0434 11.7837 18.6418 11.8615 16.8645V1.00421H14.7592C14.7 0.672804 14.6718 0.336624 14.6749 0H10.7117V15.845C10.6458 17.6313 9.18003 19.0463 7.39245 19.0492C6.85826 19.0447 6.3328 18.9133 5.85931 18.666C6.4817 19.5283 7.47884 20.0411 8.5423 20.0458Z"
        fill="#25F4EE"
      />
      <path
        d="M20.1712 6.38547V5.50392C19.1048 5.50428 18.062 5.18959 17.1739 4.59937C17.9525 5.50447 19.0046 6.13144 20.1712 6.38547Z"
        fill="#25F4EE"
      />
      <path
        d="M17.1738 4.59946C16.299 3.60317 15.8167 2.32248 15.817 0.996582H14.7592C15.0353 2.47812 15.9085 3.78094 17.1738 4.59946Z"
        fill="#FE2C55"
      />
      <path
        d="M7.38474 12.3954C5.85808 12.4032 4.53268 13.4491 4.17009 14.9321C3.8075 16.4151 4.50079 17.9545 5.8516 18.6659C5.11967 17.6554 5.01627 16.3198 5.58396 15.2087C6.15165 14.0975 7.29451 13.3987 8.54226 13.3996C8.87495 13.4037 9.20536 13.4554 9.52346 13.5529V9.52075C9.19861 9.47208 8.87072 9.44646 8.54226 9.44409H8.36595V12.5104C8.04619 12.4246 7.71565 12.3859 7.38474 12.3954Z"
        fill="#FE2C55"
      />
      <path
        d="M20.1712 6.3855V9.45177C18.197 9.44794 16.2741 8.82308 14.6749 7.66567V15.7223C14.6664 19.7425 11.405 22.9971 7.38479 22.997C5.8918 22.9997 4.43476 22.539 3.21466 21.6785C5.25084 23.8685 8.41971 24.5894 11.2028 23.4957C13.986 22.4021 15.8164 19.7168 15.8171 16.7265V8.69287C17.4217 9.8427 19.347 10.4594 21.321 10.456V6.50815C20.9345 6.50692 20.5492 6.46582 20.1712 6.3855Z"
        fill="#FE2C55"
      />
      <path
        d="M14.6749 15.7224V7.66573C16.279 8.81649 18.2046 9.43333 20.1788 9.42884V6.36256C19.0125 6.11627 17.9578 5.49747 17.1739 4.59945C15.9085 3.78094 15.0353 2.47812 14.7592 0.996582H11.8615V16.8646C11.8036 18.2802 10.8554 19.5039 9.49898 19.9134C8.14259 20.3229 6.67567 19.8284 5.84398 18.6813C4.49317 17.97 3.79988 16.4305 4.16247 14.9475C4.52506 13.4645 5.85046 12.4186 7.37712 12.4108C7.70992 12.4138 8.04049 12.4654 8.35833 12.5641V9.49783C5.47723 9.54664 2.89712 11.2931 1.78131 13.9498C0.665493 16.6065 1.22478 19.6715 3.20698 21.7629C4.43911 22.595 5.89827 23.0261 7.38478 22.9971C11.405 22.9971 14.6664 19.7426 14.6749 15.7224Z"
        fill="black"
      />
      <path
        d="M36.2671 9.54096H33.0628V22.5726H29.0536V9.54096H25.0522L25.0445 5.96875H37.4016L36.2671 9.54096Z"
        fill="black"
      />
      <path
        d="M69.1452 9.54096H65.665V22.5726H61.6635V9.54096H57.6466L57.639 5.96875H70.2797L69.1452 9.54096Z"
        fill="black"
      />
      <path
        d="M42.116 22.5726H38.1835L38.1528 11.2504H42.116V22.5726Z"
        fill="black"
      />
      <path
        d="M47.6429 13.6574L51.5678 9.82457H56.2898L51.3301 14.6386L56.8878 22.5726H52.526L48.8158 17.061L47.6429 18.1955V22.5726H43.6874V5.92273H47.6429V13.6574Z"
        fill="black"
      />
      <path
        d="M89.6126 13.6574L93.5297 9.82457H98.2518L93.2921 14.6386L98.8114 22.5726H94.4496L90.7471 17.061L89.6126 18.1955V22.5726H85.6494L85.6111 5.92273H89.6126V13.6574Z"
        fill="black"
      />
      <path
        d="M40.1382 9.95491C41.239 9.95491 42.1313 9.06258 42.1313 7.96183C42.1313 6.86108 41.239 5.96875 40.1382 5.96875C39.0375 5.96875 38.1451 6.86108 38.1451 7.96183C38.1451 9.06258 39.0375 9.95491 40.1382 9.95491Z"
        fill="black"
      />
      <path
        d="M69.7738 15.7961C69.775 12.2596 72.4956 9.31864 76.0213 9.0426C75.845 9.0426 75.6074 9.0426 75.4311 9.0426C71.8479 9.25161 69.0492 12.2183 69.0492 15.8076C69.0492 19.3968 71.8479 22.3635 75.4311 22.5725C75.6074 22.5725 75.845 22.5725 76.0213 22.5725C72.4868 22.2958 69.763 19.3414 69.7738 15.7961Z"
        fill="#25F4EE"
      />
      <path
        d="M77.5622 9.01963C77.3782 9.01963 77.1405 9.01963 76.9642 9.01963C80.4785 9.30989 83.1829 12.2468 83.1829 15.7731C83.1829 19.2994 80.4785 22.2363 76.9642 22.5266C77.1405 22.5266 77.3782 22.5266 77.5622 22.5266C81.3047 22.5266 84.3386 19.4926 84.3386 15.7501C84.3386 12.0076 81.3047 8.97363 77.5622 8.97363V9.01963Z"
        fill="#FE2C55"
      />
      <path
        d="M76.4889 19.0924C74.6685 19.0924 73.1927 17.6166 73.1927 15.7961C73.1927 13.9757 74.6685 12.4999 76.4889 12.4999C78.3094 12.4999 79.7852 13.9757 79.7852 15.7961C79.781 17.6148 78.3076 19.0882 76.4889 19.0924ZM76.4889 9.01965C72.7464 9.01965 69.7125 12.0536 69.7125 15.7961C69.7125 19.5387 72.7464 22.5726 76.4889 22.5726C80.2315 22.5726 83.2654 19.5387 83.2654 15.7961C83.2654 13.9989 82.5515 12.2753 81.2806 11.0044C80.0098 9.7336 78.2862 9.01965 76.4889 9.01965Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_72168_7070">
        <rect
          width="98.7173"
          height="24"
          fill="white"
          transform="translate(0.0941162)"
        />
      </clipPath>
    </defs>
  </svg>
);
