import AboutKeys from '../translationKeys/AboutPageKeys';

export const AboutPageTranslations = {
  [AboutKeys.Side_Popup.Btn.creator]: `ฉันเป็นครีเอเตอร์`,
  [AboutKeys.Side_Popup.Btn.client]: `ฉันเป็นบริษัท/แบรนด์`,
  [AboutKeys.Side_Popup.Btn1]: 'ไปที่หน้าหลัก',

  // Part 1
  [AboutKeys.Part1.Creator.title]: `
    ทำงานร่วมกับบริษัท  <br>
    ระดับโลก แบบเรียลไทม <br>
    ได้อย่างง่ายดาย`,
  [AboutKeys.Part1.Creator.subtitle]: `
    ใช้เวลา 1-2นาทีในการสร้างโปรไฟล์ <br>
    เพื่อร่วมทำงานกับแบรนด์ระดับโลก`,
  [AboutKeys.Part1.Creator.btn]: 'ลงทะเบียนเป็นครีเอเตอร์',

  [AboutKeys.Part1.Client.title]: `
    การตลาดอินฟลูเอนเซอร์ <br>
    ระดับโลก แบบครบวงจร <br>
    ยกระดับผลกำไรที่สูงขึ้น`,
  [AboutKeys.Part1.Client.subtitle]: `
    การตลาดระดับโลกที่สะดวก <br>
    และมีประสิทธิภาพมากที่สุด`,
  [AboutKeys.Part1.Client.btn]: 'สั่งซื้อจากครีเอเตอร์',

  // Part 2
  [AboutKeys.Part2.Creator.title]:
    'ทำงานร่วมกับครีเอเตอร์ด้วยเครื่องมือการตลาดแบบครบวงจร',

  [AboutKeys.Part2.Creator.Section1.Image.description1]:
    'ทำงานร่วมกับแบรนด์ระดับโลก',
  [AboutKeys.Part2.Creator.Section1.Image.description2]:
    'โปรโมทช่องของคุณกับผู้สนับสนุนทั่วโลกโดยอัตโนมัติ',
  [AboutKeys.Part2.Creator.Section1.Image.description3]:
    'พื้นที่การทำงานและการสื่อสารร่วมกับบริษัทแบบส่วนตัว',
  [AboutKeys.Part2.Creator.Section1.text1]:
    'ทำงานร่วมกับบริษัทชั้นนำทั่วโลก \\n ที่ได้รับการรับรอง จากกว่า 40+ ประเทศ',
  [AboutKeys.Part2.Creator.Section1.text2]:
    'รองรับแพลตฟอร์มที่หลากหลาย \\n และระบบการแปลภาษาอัตโนมัติ',
  [AboutKeys.Part2.Creator.Section1.text3]:
    'แชทตัวต่อตัวกับผู้สนับสนุน แบบเรียลไทม์',

  [AboutKeys.Part2.Creator.Section2.Image.description1]:
    'สร้างและจำหน่ายแพ็กเกจของคุณอย่างง่ายดาย',
  [AboutKeys.Part2.Creator.Section2.Image.description2]:
    'ตรวจสอบข้อเสนอและการชำระเงินได้อย่างมีประสิทธิภาพ',
  [AboutKeys.Part2.Creator.Section2.text1]:
    'สร้างและจำหน่ายแพ็กเกจ \\n ที่เหมาะกับช่องของคุณโดยอัตโนมัติ',
  [AboutKeys.Part2.Creator.Section2.text2]:
    'ตรวจสอบและยอมรับข้อเสนอ \\n ได้ในเพียงคลิกเดียว',
  [AboutKeys.Part2.Creator.Section2.text3]:
    'การชำระเงินง่ายๆ ด้วยสมาร์ทแดชบอร์ด',

  [AboutKeys.Part2.Creator.Section3.Image.description1]:
    'เข้าถึงข้อมูล·สถิติอย่างครอบคลุม',
  [AboutKeys.Part2.Creator.Section3.Image.description2]:
    'การวิเคราะห์โซเชียลมีเดียและรายงานคำสั่งซื้อที่มีประสิทธิภาพ',
  [AboutKeys.Part2.Creator.Section3.text1]: 'UI ที่ใช้งานง่ายและสะดวกที่สุด',
  [AboutKeys.Part2.Creator.Section3.text2]:
    'การวิเคราะห์ข้อมูลของช่องและเนื้อหา',

  [AboutKeys.Part2.Client.title]:
    'ทำงานร่วมกับอินฟลูเอนเซอร์ด้วยเครื่องมือการตลาดแบบครบวงจร',

  [AboutKeys.Part2.Client.Section1.Image.description1]:
    'ค้นหาครีเอเตอร์ในประเทศไทยและทั่วโลก',
  [AboutKeys.Part2.Client.Section1.Image.description2]:
    'พื้นที่การทำงานและการสื่อสารร่วมกับครีเอเตอร์แบบส่วนตัว',
  [AboutKeys.Part2.Client.Section1.text1]:
    'ค้นหาครีเอเตอร์ระดับโลก \\n ที่พร้อมจะทำงานร่วมกับคุณ',
  [AboutKeys.Part2.Client.Section1.text2]:
    'รองรับแพลตฟอร์มที่หลากหลาย \\n และระบบการแปลภาษาอัตโนมัติ',
  [AboutKeys.Part2.Client.Section1.text3]:
    'แชทตัวต่อตัวกับครีเอเตอร์ แบบเรียลไทม์',

  [AboutKeys.Part2.Client.Section2.Image.description1]:
    'ครีเอเตอร์นำเสนอแพ็กเกจโดยตรง',
  [AboutKeys.Part2.Client.Section2.Image.description2]:
    'จัดการเนื้อหาที่คุณสั่งซื้อได้อย่างง่าย',
  [AboutKeys.Part2.Client.Section2.text1]: 'ตรวจสอบราคาโฆษณาก่อนสั่งซื้อ',
  [AboutKeys.Part2.Client.Section2.text2]:
    'สั่งซื้อโฆษณาจากทั่วโลก ด้วยคลิกเดียว',
  [AboutKeys.Part2.Client.Section2.text3]:
    'จัดการคำสั่งซื้อและการชำระเงิน \\n ด้วยสมาร์ทแดชบอร์ดที่สะดวกและรวดเร็ว',

  [AboutKeys.Part2.Client.Section3.Image.description1]:
    'เข้าถึงข้อมูล·สถิติอย่างครอบคลุม',
  [AboutKeys.Part2.Client.Section3.Image.description2]:
    'การวิเคราะห์โซเชียลมีเดียและรายงานคำสั่งซื้อที่มีประสิทธิภาพ',
  [AboutKeys.Part2.Client.Section3.text1]: 'UI ที่ใช้งานง่ายและสะดวกที่สุด',
  [AboutKeys.Part2.Client.Section3.text2]:
    'วิเคราะห์ข้อมูลของคำสั่งซื้อและเนื้อหา',
  [AboutKeys.Part2.Client.Section3.text2_sub]: '* ยกเว้นเนื้อหาบางประเภท',

  // Part 3

  // Part 4

  // Part 5
  [AboutKeys.Part5.title]: 'EVO Points และสิทธิพิเศษเพิ่มเติม',
  [AboutKeys.Part5.text1]: 'ทำภารกิจให้สำเร็จเพื่อรับ EVOs',
  [AboutKeys.Part5.text2]: 'รางวัลสุดพิเศษสำหรับครีเอเตอร์',
  [AboutKeys.Part5.text3]:
    'กิจกรรมแบบออฟไลน์เพื่อเชื่อมโยง ครีเอเตอร์ แฟนคลับ และแบรนด์',

  // Feedback
  [AboutKeys.Feedback.Creator1.name]: '50,000 + YouTube ครีเอเตอร์',
  [AboutKeys.Feedback.Creator1.comment]: `
    ผมสำรวจ MegaEvolution พบว่ามันเป็นแพลตฟอร์มที่ดีเยี่ยมเลย เมื่อก่อนเป็นเรื่องยากที่จะสื่อสารกับผู้ลงโฆษณา <br>
    แต่หลังจากใช้แพลตฟอร์มแล้ว ผมพอใจมากที่สามารถสื่อสารได้อย่างสะดวกสบาย`,
  [AboutKeys.Feedback.Creator2.name]:
    '1,500,000 + TikTok และ Instagram ครีเอเตอร์',
  [AboutKeys.Feedback.Creator2.comment]: `
    ในฐานะครีเอเตอร์ที่มีประสบการณ์มากกว่า 3 ปี เคยใช้แพลตฟอร์มที่เชื่อมโยงครีเอเตอร์กับแบรนด์มาก็เยอะ แต่ MegaEvolution เป็นแพลตฟอร์มที่ดีที่สุดที่ผมเคยใช้มาเมื่อเทียบกับแพลตฟอร์มอื่นๆ อินเทอร์เฟซใช้งานง่ายและสะดวก การเสนอตัวเลือกที่หลากหลายให้กับแบรนด์แต่ละระดับแพ็กเกจ ผมดีใจที่ได้ใช้แพลตฟอร์ม และผมน่าจะเจอแพลตฟอร์มให้เร็วกว่านี้`,
  [AboutKeys.Feedback.Creator3.name]:
    '50,000 + YouTube สตรีมเมอร์ · Instagram ครีเอเตอร์',
  [AboutKeys.Feedback.Creator3.comment]: `
    MegaEvolution เป็นพื้นที่สำคัญสำหรับครีเอเตอร์อย่างฉันจริงๆ โดยเฉพาะครีเอเตอร์น้องใหม่ที่ไม่มีพื้นที่ในการลงโฆษณา <br>
    ฉันดีใจมากที่ครีเอเตอร์สามารถรับความช่วยเหลือและทำงานผ่าน MegaEvolution ได้มากขึ้น`,
  [AboutKeys.Feedback.Creator4.name]: '150,000 + YouTube ครีเอเตอร์',
  [AboutKeys.Feedback.Creator4.comment]: `
    ในฐานะเจ้าของช่อง แพลตฟอร์ม MegaEvolution มีเงื่อนไขที่ดีและยอดเยี่ยม เพียงแค่สร้างโปรไฟล์ก็สามารถสร้างโอกาส <br>
    ในการทำงานร่วมกับบริษัทต่างๆ ได้แล้ว ดีจริงๆ`,
  [AboutKeys.Feedback.Creator5.name]:
    '100,000 + YouTube · Instagram ครีเอเตอร์',
  [AboutKeys.Feedback.Creator5.comment]: `
    ขอบคุณการสนับสนุนจากทีม Mega Evolution ในปีที่แล้ว ทำให้ผมมีความมั่นใจเพิ่มมากขึ้นและด้วยเหตุผลนี้ผมจึงตั้งใจเริ่มทำ YouTube อีกครั้ง และผมก็ทำสำเร็จ (ช่องของผมเติบโตขึ้น 3 เท่าตั้งแต่นั้นมา)`,
  [AboutKeys.Feedback.Creator6.name]: 'YouTube ครีเอเตอร์สายเกม',
  [AboutKeys.Feedback.Creator6.comment]: `
    ผมประทับใจมากกับบริการที่นำเสนอเนื้อหาของครีเอเตอร์ และผมก็มั่นใจว่าผู้ที่ใช้แพลตฟอร์มในปัจจุบันและในอนาคตจะได้รับประโยชน์อย่างมากจากบริการที่ MegaEvolution มอบให้`,
  [AboutKeys.Feedback.Creator7.name]:
    '30,000 + YouTube ครีเอเตอร์เทคโนโลยีและสายเกม',
  [AboutKeys.Feedback.Creator7.comment]: `
    ผมชอบบริการที่ได้รับการรับรองจาก Google ของแพลตฟอร์ม MegaEvolution และสามารถติดต่อโดยตรงกับแบรนด์ระดับโลก โดยไม่ต้องมีสัญญาที่ซับซ้อน`,
  [AboutKeys.Feedback.Creator8.name]: '50,000 + YouTube ครีเอเตอร์',
  [AboutKeys.Feedback.Creator8.comment]:
    'มีเพื่อนคนหนึ่งพูดถึงแพลตฟอร์มนี้และเพื่อนๆที่เป็นYouTuberบอกกันว่า MegaEvolution เป็นแพลตฟอร์มที่ประสบความสำเร็จมาก',
  [AboutKeys.Feedback.Creator9.name]:
    '70,000+ YouTube · Instagram · TikTok ครีเอเตอร์สายบิวตี้',
  [AboutKeys.Feedback.Creator9.comment]: `
    ฉันตื่นเต้นมากกับ MegaEvolution ไม่ใช่แค่เป็นแพลตฟอร์มที่ยอดเยี่ยมสำหรับครีเอเตอร์เพียงอย่างเดียว <br>
    แต่ฉันชอบวิธีการจัดวาง การออกแบบของแต่ละส่วนบนแพลตฟอร์มมากเช่นกัน`,
};
