import Popup from 'components/Basics/Popup';
import { mainFlow } from 'constants/authFlow';
import { useState, useRef, useCallback } from 'react';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import withAuthHOC from 'components/components/auth/withAuthHOC';
import { useTranslation } from 'react-i18next';
import TextField from 'components/Basics/Inputs/TextField';
import styled from 'styled-components';
import { useAuth } from 'hooks/useAauth';
import { useEffect } from 'react';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import { Trans } from 'react-i18next';

const { SIGN_IN, SIGN_UP, RESET_PASSWORD } = mainFlow;

const EnterEmailModal = ({ flowManager }) => {
  const [show] = useState(true);
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const emailRef = useRef();
  const { forwardStep, navigateFlow, authMethod, setSharedValue, mainFlow } =
    flowManager;
  const { utils } = useAuth(authMethod);
  const { isMobile } = useResponsiveScreen();

  const text = {
    [RESET_PASSWORD]: {
      title: t(LoginKeys.Modal.Enter_Your_Email.title),
      description: t(LoginKeys.Modal.Enter_Your_Email.description),
      buttonText: t(GeneralKeys.send),
      placeholder: t(LoginKeys.Modal.Enter_Email.Placeholder),
    },
    [SIGN_UP]: {
      title: t(LoginKeys.Modal.Enter_Email.title),
      description: <Trans>{t(LoginKeys.Modal.Enter_Email.description)}</Trans>,
      buttonText: t(GeneralKeys.submit),
      placeholder: t(LoginKeys.Modal.Enter_Email.Placeholder),
    },
  };
  function validateEmail(email) {
    if (utils.validateEmail(email)) {
      return true;
    } else {
      throw new Error(LoginKeys.Error.enter_valid_email);
    }
  }
  async function emailValidationProcess() {
    try {
      const email = emailRef.current?.value;
      if (validateEmail(email)) {
        const isEmailRegistable = await utils.checkEmailIsRegistable(email);
        if (isEmailRegistable) {
          setSharedValue((current) => {
            current.userData.email = email;
            return current;
          });
          forwardStep();
        }
      }
    } catch (error) {
      if (error.response?.status === 500) {
        setError(LoginKeys.Error.network);
      } else {
        if (error.message) {
          setError(error.message);
        }
      }
    }
  }

  async function resetPasswordProcess() {
    try {
      const email = emailRef.current?.value;
      if (validateEmail(email)) {
        await utils.resetPassword(email, {
          onSuccess: (res) => {
            setSharedValue({
              userData: {
                email,
              },
            });
            forwardStep();
          },
          onError: (error) => {
            if (error.response?.status === 500) {
              setError(LoginKeys.Error.network);
            } else if (error.key === 'TRY_SIGNIN_SOCIAL_APP') {
              setError(LoginKeys.Error.try_sign_in_social_app);
            } else if (error.key === 'TOO_MANY_REQUEST_RESET_PASSWORD') {
              setError(LoginKeys.Error.too_many_request);
            } else {
              setError(LoginKeys.Error.invalid_request);
            }
          },
        });
      }
    } catch (error) {
      if (error.message) {
        setError(error.message);
      }
    }
  }

  async function handleClick() {
    switch (mainFlow) {
      default:
      case SIGN_UP: {
        emailValidationProcess();
        break;
      }
      case RESET_PASSWORD: {
        resetPasswordProcess();
      }
    }
  }
  const onEnter = useCallback((event) => {
    if (event.key === 'Enter') {
      handleClick(event);
    }
  });
  useEffect(() => {
    if (emailRef.current) {
      window.addEventListener('keypress', onEnter);
    }
    return () => {
      window.removeEventListener('keypress', onEnter);
    };
  }, [onEnter]);
  return (
    <>
      <Popup
        show={show}
        onButtonClick={handleClick}
        title={text[mainFlow].title}
        description={text[mainFlow].description}
        buttonText={text[mainFlow].buttonText}
        secondaryButtonText={t(GeneralKeys.close)}
        buttonStyle={{
          boxShadow: isMobile && 'var(--shadow-mobile-text-box)',
          minWidth: isMobile && '136px',
        }}
        inputPlaceholder={text[mainFlow].placeholder}
        onClose={() => {
          navigateFlow({ step: 0, flow: SIGN_IN, method: 'local' });
        }}
        onOuterBounderyClick={() => {
          navigateFlow({ step: 0, flow: SIGN_IN, method: 'local' });
        }}
        asPage={isMobile}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: `${isMobile ? '0.375rem' : '0.625rem'}`,
          }}
        >
          <TextField
            ref={emailRef}
            variant={'transparentGray'}
            focusOutline={isMobile}
            className="position-relative"
            padding={isMobile ? '11.5px 24px' : '16px 30px'}
            radius={isMobile ? '999px' : '10px'}
            borderWidth={'2px'}
            fontSize={isMobile ? 'h7' : ''}
            placeholder={t(LoginKeys.Modal.Enter_Your_Email.placeholder)}
            width="100%"
          />
          <ErrorText isMobile={isMobile}>{t(error)}</ErrorText>
        </div>
      </Popup>
    </>
  );
};

export default withAuthHOC(EnterEmailModal);

const ErrorText = styled.p.attrs({
  className: 'text-red-500',
})`
  @media (max-width: 769px) {
    font-size: 12px;
  }
`;
