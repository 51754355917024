import useResponsiveScreen from 'hooks/useResponsiveScreen';
import withAuthHOC from './withAuthHOC';
import Popup from 'components/Basics/Popup';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';

const FacebookEmailNotPresentModal = ({ flowManager }) => {
  const { t } = useTranslation();

  const { navigateFlow, fbState } = useAuthFlowManager();

  const { isMobile } = useResponsiveScreen();

  return (
    <>
      <Popup
        show={true}
        onClose={() => {
          navigateFlow({
            flow: fbState,
            step: 0,
          });
        }}
        title={t(LoginKeys.Modal.Use_Another_Method.title)}
        description={t(LoginKeys.Modal.Use_Another_Method.description)}
        buttonText={t(GeneralKeys.close)}
        onButtonClick={() => {
          navigateFlow({
            flow: fbState,
            step: 0,
          });
        }}
        onOuterBounderyClick={() => {
          navigateFlow({
            flow: fbState,
            step: 0,
          });
        }}
        asPage={isMobile}
      />
    </>
  );
};

export default withAuthHOC(FacebookEmailNotPresentModal);
