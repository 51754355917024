import { LoadingIcon } from 'assets/icons';
import { usePackages } from 'contexts/PackagesContext';
import styled from 'styled-components';

const LoadingOverlay = () => {
  const { loading } = usePackages();

  if (!loading) return null;

  return (
    <Wrapper>
      <LoadingIcon />
    </Wrapper>
  );
};

export default LoadingOverlay;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 1.25rem;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);
  color: var(--color-indigo-500);
  display: flex;
  align-items: center;
  justify-content: center;
`;
