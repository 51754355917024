export const HamburgerIcon = ({ size = 1 }) => (
  <svg
    width={size * 28}
    height={size * 18}
    viewBox="0 0 28 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="28" height="3" rx="1" />
    <rect x="4" y="7.5" width="24" height="3" rx="1" />
    <rect x="8" y="14.5" width="20" height="3" rx="1" />
  </svg>
);
