export const LinkIcon = ({ size = 1, className }) => (
  <svg
    width={20 * size}
    height={20 * size}
    viewBox="0 0 20 20"
    fill="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 8.82C4.26522 8.82 4.51957 8.92536 4.70711 9.11289C4.89464 9.30043 5 9.55478 5 9.82V17C5 17.2652 5.10536 17.5196 5.29289 17.7071C5.48043 17.8946 5.73478 18 6 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V6C18 5.73478 17.8946 5.48043 17.7071 5.29289C17.5196 5.10536 17.2652 5 17 5H9.82C9.55478 5 9.30043 4.89464 9.11289 4.70711C8.92536 4.51957 8.82 4.26522 8.82 4C8.82 3.73478 8.92536 3.48043 9.11289 3.29289C9.30043 3.10536 9.55478 3 9.82 3H17C17.7956 3 18.5587 3.31607 19.1213 3.87868C19.6839 4.44129 20 5.20435 20 6V17C20 17.7956 19.6839 18.5587 19.1213 19.1213C18.5587 19.6839 17.7956 20 17 20H6C5.20435 20 4.44129 19.6839 3.87868 19.1213C3.31607 18.5587 3 17.7956 3 17V9.82C3 9.55478 3.10536 9.30043 3.29289 9.11289C3.48043 8.92536 3.73478 8.82 4 8.82ZM0.0799999 0.62C0.181475 0.375651 0.375652 0.181475 0.620001 0.0799999C0.740223 0.028759 0.869322 0.00157999 1 0H7C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1C8 1.26522 7.89464 1.51957 7.70711 1.70711C7.51957 1.89464 7.26522 2 7 2H3.41L13.71 12.29C13.8037 12.383 13.8781 12.4936 13.9289 12.6154C13.9797 12.7373 14.0058 12.868 14.0058 13C14.0058 13.132 13.9797 13.2627 13.9289 13.3846C13.8781 13.5064 13.8037 13.617 13.71 13.71C13.617 13.8037 13.5064 13.8781 13.3846 13.9289C13.2627 13.9797 13.132 14.0058 13 14.0058C12.868 14.0058 12.7373 13.9797 12.6154 13.9289C12.4936 13.8781 12.383 13.8037 12.29 13.71L2 3.41V7C2 7.26522 1.89464 7.51957 1.70711 7.70711C1.51957 7.89464 1.26522 8 1 8C0.734783 8 0.480429 7.89464 0.292892 7.70711C0.105356 7.51957 0 7.26522 0 7V1C0.00157928 0.869323 0.028759 0.740222 0.0799999 0.62Z" />
  </svg>
);
