import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]: '感谢您接受邀请!',
  [InvitationKeys.title2]: '',
  [InvitationKeys.subtitle]: '为了获得最佳体验，我们建议使用网络环境。',

  [InvitationKeys.description]:
    '仅凭一个简单的免费档案，即可轻松与美国、欧洲、韩国、日本等地的认证全球企业合作，无需MCN（多频道网络）。',

  [InvitationKeys.view_creator_tutorial]: '注册成为创作者',
  [InvitationKeys.explore_btn]: '查看示例档案',
  [InvitationKeys.learn_more]: '了解更多',
  [InvitationKeys.main_site]: '主站点',

  [InvitationKeys.Browser.title]: '您似乎在使用不受支持的浏览器',
  [InvitationKeys.Browser.description]:
    '请使用上述浏览器以获得最佳体验。某些功能在此浏览器中可能不受支持。',
  [InvitationKeys.Browser.button]: '我理解',

  [InvitationKeys.Mega_Flow.title]: '这是MegaEvolution的工作方式！',
  [InvitationKeys.Mega_Flow.Caption.one]: '仅需1~2分钟即可创建创作者档案',
  [InvitationKeys.Mega_Flow.Caption.two]:
    '我们*自动*推荐并连接档案至经验证的公司',
  [InvitationKeys.Mega_Flow.Caption.three]: '审阅并接受全球品牌的订单',
  [InvitationKeys.Mega_Flow.Caption.four]: '提交内容 & 通过内容授权*赚取更多*',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    '仅需1~2分钟即可创建创作者档案',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    '我们*自动*推荐并连接档案至经验证的公司',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]: '审阅并接受全球品牌的订单',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    '提交内容 & 通过内容授权*赚取更多*',

  [InvitationKeys.Creator_Review.title]: '来自MegaEvolution创作者的评论',
};
