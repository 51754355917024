import { useEditorContext } from './EditorContext';

const CharCount = ({ className, children }) => {
  const { charCount } = useEditorContext();

  if (typeof children === 'function') {
    return <span className={className}>{children(charCount)}</span>;
  }

  return <span className={className}>{charCount}</span>;
};

export default CharCount;
