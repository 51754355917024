const MessageKeys = {
  Dropdown: {
    title: 'MessageKeys.Dropdown.title',
    no_message: 'MessageKeys.Dropdown.no_message',
    bottom_btn: 'MessageKeys.Dropdown.bottom_btn',
  },

  ChatBox: {
    title: 'MessageKeys.ChatBox.title',
    no_chat: 'MessageKeys.ChatBox.no_chat',
    no_selected_chat: 'MessageKeys.ChatBox.no_selected_chat',
    new_chat: 'MessageKeys.ChatBox.new_chat',
    search: 'MessageKeys.ChatBox.search',

    Message: {
      me: 'MessageKeys.ChatBox.Message.me',
      copy: 'MessageKeys.ChatBox.Message.copy',
      report: 'MessageKeys.ChatBox.Message.report',
      download_file: 'MessageKeys.ChatBox.Message.download_file',
      typing: 'MessageKeys.ChatBox.Message.typing',

      Offer_Package: {
        text: 'MessageKeys.ChatBox.Message.Offer_Package.text',
        description: 'MessageKeys.ChatBox.Message.Offer_Package.description',
        btn: 'MessageKeys.ChatBox.Message.Offer_Package.btn',
      },

      Select_Package: {
        title: 'MessageKeys.ChatBox.Message.Select_Package.title',
        btn: 'MessageKeys.ChatBox.Message.Select_Package.btn',
        complete_package_first:
          'MessageKeys.ChatBox.Message.Select_Package.complete_package_first',
        no_price: 'MessageKeys.ChatBox.Message.Select_Package.no_price',
        verified_only:
          'MessageKeys.ChatBox.Message.Select_Package.verified_only',
      },

      Custom_Package: {
        title: 'MessageKeys.ChatBox.Message.Custom_Package.title',
        description: 'MessageKeys.ChatBox.Message.Custom_Package.description',
        offering: 'MessageKeys.ChatBox.Message.Custom_Package.offering',
        btn1: 'MessageKeys.ChatBox.Message.Custom_Package.btn1',
        btn2: 'MessageKeys.ChatBox.Message.Custom_Package.btn2',
      },

      Request_Price: {
        text: 'MessageKeys.ChatBox.Message.Request_Price.text',
        description: 'MessageKeys.ChatBox.Message.Request_Price.description',
        btn: 'MessageKeys.ChatBox.Message.Request_Price.btn',
      },

      File: {
        singular: 'MessageKeys.ChatBox.Message.File.singular',
        plural: 'MessageKeys.ChatBox.Message.File.plural',
        plural_2: 'MessageKeys.ChatBox.Message.File.plural_2',
        plural_3: 'MessageKeys.ChatBox.Message.File.plural_3',
        plural_4: 'MessageKeys.ChatBox.Message.File.plural_4',
        plural_5: 'MessageKeys.ChatBox.Message.File.plural_5',
        plural_6: 'MessageKeys.ChatBox.Message.File.plural_6',
        plural_7: 'MessageKeys.ChatBox.Message.File.plural_7',
        plural_8: 'MessageKeys.ChatBox.Message.File.plural_8',
        plural_9: 'MessageKeys.ChatBox.Message.File.plural_9',
        plural_10: 'MessageKeys.ChatBox.Message.File.plural_10',
        expired: 'MessageKeys.ChatBox.Message.File.expired',
      },

      Error: {
        sending: 'MessageKeys.ChatBox.Message.Error.sending',
        fail: 'MessageKeys.ChatBox.Message.Error.fail',
      },
    },
  },

  InputField: {
    chat_disabled: 'MessageKeys.InputField.chat_disabled',
    web: 'MessageKeys.InputField.web',
    mobile: 'MessageKeys.InputField.mobile',

    drag_drop: 'MessageKeys.InputField.drag_drop',

    Btn: {
      offer_package: 'MessageKeys.InputField.Btn.offer_package',
      send_proposal: 'MessageKeys.InputField.Btn.send_proposal',
    },
  },

  UserInfo: {
    Verified: {
      tooltip: 'MessageKeys.UserInfo.Verified.tooltip',
    },

    Status: {
      online: 'MessageKeys.UserInfo.Status.online',
      offline: 'MessageKeys.UserInfo.Status.offline',
    },

    order_together: 'MessageKeys.UserInfo.order_together',

    Btn: {
      edit_profile: 'MessageKeys.UserInfo.Btn.edit_profile',
      visit_profile: 'MessageKeys.UserInfo.Btn.visit_profile',
    },
  },

  Error: {
    max_characters: 'MessageKeys.Error.max_characters',
    max_file_size_1: 'MessageKeys.Error.max_file_size_1',
    max_file_size_2: 'MessageKeys.Error.max_file_size_2',
    max_file_1: 'MessageKeys.Error.max_file_1',
    max_file_2: 'MessageKeys.Error.max_file_2',
  },
};

export default MessageKeys;
