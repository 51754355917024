import SettingsKeys from '../translationKeys/SettingsPageKeys';

export const SettingsTranslations = {
  // Signed with
  [SettingsKeys.Signed_With.wallet]: '지갑으로 로그인',
  [SettingsKeys.Signed_With.google]: 'Google로 로그인',
  [SettingsKeys.Signed_With.facebook]: 'Facebook으로 로그인',

  // Account
  [SettingsKeys.Account.connected_email]: '연결된 이메일',

  // Password
  [SettingsKeys.Password.current_password]: '현재 비밀번호',
  [SettingsKeys.Password.new_password]: '새 비밀번호',
  [SettingsKeys.Password.confirm_new_password]: '새 비밀번호 확인',

  [SettingsKeys.Password.Rule.title]: '비밀번호는 다음 규칙을 충족해야 합니다',
  [SettingsKeys.Password.Rule.rule_1]: '8~20자의 문자',
  [SettingsKeys.Password.Rule.rule_2]: '최소 1개의 대문자',
  [SettingsKeys.Password.Rule.rule_3]: '최소 1개의 소문자',
  [SettingsKeys.Password.Rule.rule_4]: '최소 1개의 숫자 또는 특수 문자',

  [SettingsKeys.Password.Error.not_follow_rule]:
    '비밀번호가 규칙에 맞지 않습니다',
  [SettingsKeys.Password.Error.characters]:
    '문자(대문자(A-Z), 소문자(a-z)), 숫자 또는 특수 문자로 구성된 8~20자 비밀번호 필요.',
  [SettingsKeys.Password.Error.not_match]: '비밀번호가 일치하지 않습니다.',
  [SettingsKeys.Password.Error.current_password_incorrect]:
    '현재 비밀번호를 잘못 입력했습니다.',

  [SettingsKeys.Password.update_success]:
    '비밀번호가 성공적으로 업데이트되었습니다!',

  // Notifications
  [SettingsKeys.Notification.Description.orders]:
    '주문 상태에 대한 이메일 받기',
  [SettingsKeys.Notification.Description.messages]: '메시지에 대한 이메일 받기',
  [SettingsKeys.Notification.Description.reminders]:
    '마감일을 알리는 이메일 받기',
  [SettingsKeys.Notification.Description.newsletters]:
    '보상, 새로운 기능, 프로모션 등에 대한 유용한 이메일 받기',
  [SettingsKeys.Notification.Description.packages]:
    '가격 잠금 및 클라이언트 및 크리에이터 상호 작용과 관련된 패키지에 대한 이메일 받기',
  [SettingsKeys.Notification.Description.real_time]: '소리 켜기',

  // Referral Code
  [SettingsKeys.Referral_Code.My.title]: '내 추천 코드',
  [SettingsKeys.Referral_Code.My.description]: '보상에 대해 알아보기',
  [SettingsKeys.Referral_Code.My.copy_code]: '코드 복사',
  [SettingsKeys.Referral_Code.My.copied]: '성공적으로 복사되었습니다!',

  [SettingsKeys.Referral_Code.Enter.title]: '코드 입력',

  [SettingsKeys.Referral_Code.Modal.reward_arrive]: '래퍼럴 보상을 받았습니다.',
  [SettingsKeys.Referral_Code.Modal.my_evo]: '나의 EVO',
  [SettingsKeys.Referral_Code.Modal.check_later]: '나중에 확인',

  [SettingsKeys.Referral_Code.Referral_Page.instruction]:
    '유효한 추천 코드를 입력해주세요.\n 세팅 페이지에서 확인할 수 있습니다.',
  [SettingsKeys.Referral_Code.Referral_Page.evo_policy]:
    '추가 EVO 포인트를 얻을 수 있습니다. \n관련 정책을 확인해 주세요',
  [SettingsKeys.Referral_Code.Referral_Page.enter_code_here]:
    '추천 코드를 입력해주세요',

  // Referral System
  [SettingsKeys.Referral_System.title]: '추천 시스템',
  [SettingsKeys.Referral_System.button]: '추천코드 복사하기',

  [SettingsKeys.Referral_System.Refer_A_Friend.lang]: 'kr',
  [SettingsKeys.Referral_System.Referral_Reward.lang]: 'kr',

  // Deactivate/Delete
  [SettingsKeys.Deactivate_Delete.title]: '비활성화/삭제',

  [SettingsKeys.Deactivate_Delete.Q1.title]:
    '계정을 비활성화하면 어떻게 됩니까?',
  [SettingsKeys.Deactivate_Delete.Q1.description]: `
    <heading><span>{{dot}}</span> 계정을 다시 활성화할 때까지 프로필이 검색 목록에 표시되지 않습니다.</heading> <br>
    <heading><span>{{dot}}</span> 계정에 저장된 데이터가 숨겨집니다.</heading> <br>
    <heading><span>{{dot}}</span> 다시 로그인하여 계정을 다시 활성화할 수 있습니다.</heading>`,

  [SettingsKeys.Deactivate_Delete.Q2.title]: '계정을 삭제하면 어떻게 됩니까?',
  [SettingsKeys.Deactivate_Delete.Q2.description]: `
  <heading><span>{{dot}}</span> 프로필이 검색 목록에서 제거됩니다.</heading> <br>
  <heading><span>{{dot}}</span> 계정에 저장된 데이터가 영구적으로 삭제됩니다.</heading> <br>
  <heading><span>{{dot}}</span> 계정을 다시 활성화할 수 없습니다.</heading>`,

  [SettingsKeys.Deactivate_Delete.warning]:
    '진행 중인 주문이 있는 경우에는 계정을 비활성화/삭제할 수 없습니다. 계정을 비활성화/삭제하기 전에 주문을 취소해주세요.',

  // Modal
  [SettingsKeys.Modal.Wallet_Connected.title]:
    '새 지갑이 성공적으로 연결되었습니다!',

  [SettingsKeys.Modal.Notification.Newsletters.title]:
    '뉴스레터 구독을 취소하시겠습니까?',
  [SettingsKeys.Modal.Notification.Newsletters.description]:
    '구독을 취소하면 보상, 새로운 기능 등에 대한 업데이트를 받을 수 없게 됩니다. 유용하고 도움이 되는 정보를 전달하기 위해 최선을 다하겠습니다!',

  [SettingsKeys.Modal.Notification.Update_Success.title]:
    '알림 설정이 성공적으로 업데이트되었습니다!',

  [SettingsKeys.Modal.Referral_Code.Submit_Success.title]:
    '코드가 성공적으로 제출되었습니다!',
  [SettingsKeys.Modal.Referral_Code.Submit_Success.description]:
    '이제 플랫폼에서 다양한 활동에 참여하여 추가 EVO 포인트를 얻을 수 있습니다. 자세한 내용은 <u>여기</u>를 클릭하여 추천 코드 보상에 대해 알아보세요.',

  [SettingsKeys.Modal.Referral_Code.Submit_Valid.title]:
    '유효한 추천 코드를 제출해주세요',
  [SettingsKeys.Modal.Referral_Code.Submit_Valid.description]:
    '입력한 코드가 유효하지 않습니다. 유효한 추천 코드를 입력해주세요.',

  [SettingsKeys.Modal.Deactivate.title]: '계정을 비활성화하시겠습니까?',
  [SettingsKeys.Modal.Deactivate.description]:
    '계정을 다시 활성화하려면 다시 로그인하면 됩니다.',

  [SettingsKeys.Modal.Delete.title]: '계정을 삭제하시겠습니까?',
  [SettingsKeys.Modal.Delete.description]:
    '계정을 삭제하면 다시 활성화할 수 없습니다. 데이터가 삭제되며 복구할 수 없습니다. 상대방에게 표시되는 메시지 및 주문 관련 데이터는 남을 수 있습니다.',

  [SettingsKeys.Modal.Complete_Cancel_Order.title]:
    '계정을 비활성화하려면 주문을 완료/취소해주세요.',
  [SettingsKeys.Modal.Complete_Cancel_Order.description]:
    '진행 중인 주문을 취소하거나 완료하기 전까지 계정을 비활성화할 수 없습니다.',

  [SettingsKeys.Modal.Why_Leaving.title]: '탈퇴 사유를 알려주세요',
  [SettingsKeys.Modal.Why_Leaving.reason_1]: '원하는 서비스가 없습니다.',
  [SettingsKeys.Modal.Why_Leaving.reason_2]: '가격이 너무 높습니다.',
  [SettingsKeys.Modal.Why_Leaving.reason_3]: '자주 발생하는 오류가 있습니다.',
  [SettingsKeys.Modal.Why_Leaving.reason_4]:
    '쿠폰 및 예약과 같은 혜택이 적습니다.',
  [SettingsKeys.Modal.Why_Leaving.reason_5]: '서비스 품질이 낮습니다.',
  [SettingsKeys.Modal.Why_Leaving.reason_6]: '대체할 만한 서비스를 찾았습니다.',
  [SettingsKeys.Modal.Why_Leaving.reason_7]: '불쾌한 회원을 만났습니다.',
  [SettingsKeys.Modal.Why_Leaving.reason_8]: '데이터를 삭제하고 싶습니다.',
  [SettingsKeys.Modal.Why_Leaving.button]: '계정 삭제',

  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title]:
    '아직 계정을 비활성화하거나 삭제할 수 없습니다.',
  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description]:
    '계정을 생성한 후 24시간이 지난 후에 계정을 비활성화하거나 삭제할 수 있습니다. 나중에 다시 시도해주세요.',
};
