import BillingKeys from '../translationKeys/BillingPageKeys';

export const BillingTranslations = {
  [BillingKeys.Payment_Subscription.title]: 'Thanh Toán và Đăng Ký',
  [BillingKeys.Payment_Subscription.Method.title]: 'Phương Thức Thanh Toán',
  [BillingKeys.Payment_Subscription.Method.btn]: 'Thêm thẻ tín dụng · ghi nợ',
  [BillingKeys.Payment_Subscription.Side.my_plan]: 'Kế Hoạch Của Tôi',
  [BillingKeys.Payment_Subscription.Side.renews_on]: 'Gia hạn vào ngày',

  [BillingKeys.Billing_History.title]: 'Lịch Sử Thanh Toán',
  [BillingKeys.Billing_History.search]:
    'Tìm kiếm theo tên người tạo hoặc dịch vụ',

  [BillingKeys.MEGA_Balance.title]: 'Số Dư MEGA',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.title]: 'Phương Thức Rút Tiền',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with]:
    'Rút tiền với <icon></icon>',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon]: 'Sắp Ra Mắt',
  [BillingKeys.MEGA_Balance.Balance_Summary.title]: 'Tóm Tắt Số Dư',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance]:
    'Tổng Số Dư Khả Dụng',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn]:
    'Tổng Số Tiền Đã Rút',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment]:
    'Thanh Toán Đang Chờ',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip]: `
      Bao gồm các khoản thanh toán từ đơn hàng<br>
      hiện đang tiến hành. Các khoản tiền đang chờ<br>
      sẽ có sẵn để rút sau 24 ngày<br>
      kể từ khi hoàn tất đơn hàng.<br><br>
      Vui lòng đảm bảo rằng nội dung đã đặt hàng<br>
      không bị xóa hoặc đặt ở chế độ riêng tư trong<br>
      ít nhất 60 ngày sau khi tải lên. <TooltipLink>Tìm hiểu thêm</TooltipLink>`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount]:
    'Số Tiền Rút',
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.details]: `
      Mỗi lần rút tiền sẽ áp dụng phí PayPal 2.0% (giới hạn phí là 20 USD).<br>
      Chuyển khoản có thể mất đến 3~5 ngày làm việc.`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.btn]: 'Rút Số Dư',
};
