import AboutKeys from '../translationKeys/AboutPageKeys';

export const AboutPageTranslations = {
  [AboutKeys.Side_Popup.Btn.creator]: `クリエーターです`,
  [AboutKeys.Side_Popup.Btn.client]: `企業・ブランドです`,
  [AboutKeys.Side_Popup.Btn1]: 'メインサイトに移動',

  // Part 1
  [AboutKeys.Part1.Creator.title]: `
    グローバル企業と  <br>
    リアルタイムで <br>
    簡単にコラボ！`,
  [AboutKeys.Part1.Creator.subtitle]: `
    1~2分で無料プロフィールを作成し、 <br>
    コラボ、精算までオールインワンに！`,
  [AboutKeys.Part1.Creator.btn]: 'クリエイターとして登録',

  [AboutKeys.Part1.Client.title]: `
    オールインワン <br>
    インフルエンサー <br>
    マーケティング`,
  [AboutKeys.Part1.Client.subtitle]: `
    注文から精算、成果分析まで <br>
    シンプルで効果的なマーケティング`,
  [AboutKeys.Part1.Client.btn]: 'インフルエンサー検索・注文',

  // Part 2
  [AboutKeys.Part2.Creator.title]:
    'クリエイターのためのブランドコラボプラットフォーム',

  [AboutKeys.Part2.Creator.Section1.Image.description1]:
    '日本、アメリカ、韓国などのブランドとコラボ',
  [AboutKeys.Part2.Creator.Section1.Image.description2]:
    '世界中の広告主にチャンネルを自動プロモーション',
  [AboutKeys.Part2.Creator.Section1.Image.description3]:
    'ブランドコラボのためのカスタムメッセージシステム',
  [AboutKeys.Part2.Creator.Section1.text1]:
    '40カ国以上の認定完了した \\n グローバル企業との協力',
  [AboutKeys.Part2.Creator.Section1.text2]:
    '様々なSNSプラットフォーム、 \\n 自動翻訳システム',
  [AboutKeys.Part2.Creator.Section1.text3]: '広告主とリアルタイム1対1チャット',

  [AboutKeys.Part2.Creator.Section2.Image.description1]:
    '自分だけのパッケージ簡単生成・販売',
  [AboutKeys.Part2.Creator.Section2.Image.description2]:
    '提案書確認後、コラボ進行・簡単な精算',
  [AboutKeys.Part2.Creator.Section2.text1]:
    'チャンネルに合ったパッケージを \\n 1分で自動生成し、販売開始',
  [AboutKeys.Part2.Creator.Section2.text2]: 'ワンクリックで提案書を確認・承諾',
  [AboutKeys.Part2.Creator.Section2.text3]: 'コラボダッシュボード・精算機能',

  [AboutKeys.Part2.Creator.Section3.Image.description1]:
    '複合的なデータ・統計を簡単で直感的に提供',
  [AboutKeys.Part2.Creator.Section3.Image.description2]:
    '多様で強力なSNS統計・分析',
  [AboutKeys.Part2.Creator.Section3.text1]: '最も直感的でシンプルなUI',
  [AboutKeys.Part2.Creator.Section3.text2]:
    'チャンネルおよびコンテンツの \\n 詳細データ分析',

  [AboutKeys.Part2.Client.title]:
    '統合型マーケティングツールで世界中のクリエイターとコラボレーション',

  [AboutKeys.Part2.Client.Section1.Image.description1]:
    '世界中のクリエーターを検索',
  [AboutKeys.Part2.Client.Section1.Image.description2]:
    'ブランドコラボのためのカスタムメッセージシステム',
  [AboutKeys.Part2.Client.Section1.text1]:
    'すでに依頼の準備が整っている \\n クリエーター',
  [AboutKeys.Part2.Client.Section1.text2]: '様々なSNS・自動翻訳をサポート',
  [AboutKeys.Part2.Client.Section1.text3]:
    'クリエーターとリアルタイムで直接 \\n やりとり',

  [AboutKeys.Part2.Client.Section2.Image.description1]:
    '予測価格ではなく、クリエーターが直接作成したパッケージ',
  [AboutKeys.Part2.Client.Section2.Image.description2]:
    '簡単な注文・コンテンツ管理',
  [AboutKeys.Part2.Client.Section2.text1]: '提案・注文前に価格を確認',
  [AboutKeys.Part2.Client.Section2.text2]:
    'ワンクリックで世界中のクリエイ \\n ターに広告を注文',
  [AboutKeys.Part2.Client.Section2.text3]: 'コラボダッシュボード・精算機能',

  [AboutKeys.Part2.Client.Section3.Image.description1]:
    '複合的なデータ・統計を簡単で直感的に提供',
  [AboutKeys.Part2.Client.Section3.Image.description2]:
    '多様で強力なSNS統計・コンテンツレポート',
  [AboutKeys.Part2.Client.Section3.text1]: '最も直感的でシンプルなUI',
  [AboutKeys.Part2.Client.Section3.text2]:
    'チャンネル分析および注文ごとの \\n 詳細データレポートを提供',
  [AboutKeys.Part2.Client.Section3.text2_sub]: '* 一部のコンテンツタイプは除外',

  // Part 3

  // Part 4

  // Part 5
  [AboutKeys.Part5.title]: 'EVOポイントおよび追加特典',
  [AboutKeys.Part5.text1]:
    'プラットフォーム内のミッションを達成してEVOポイントを獲得',
  [AboutKeys.Part5.text2]: 'クリエイターのための特別な商品と特典',
  [AboutKeys.Part5.text3]:
    'クリエイターとファン、そして企業をつなぐオフラインイベントを提供',

  // Feedback
  [AboutKeys.Feedback.Creator1.name]: '5万＋、YouTubeクリエーター',
  [AboutKeys.Feedback.Creator1.comment]:
    'メガエボリューションのサイトを見て、素晴らしいサイトだとわかりました！広告主とコミュニケーションを取れるプラットフォームが少なく苦労していましたが、メガエボリューションのおかげで快適にコミュニケーションが取れるようになり、満足しています！',
  [AboutKeys.Feedback.Creator2.name]: '150万＋、TikTok・Instagramクリエーター',
  [AboutKeys.Feedback.Creator2.comment]:
    '私はこれまで3年半以上、クリエイターとして活動しており、計150万人のフォロワーを獲得してきました。その際、多くのクリエイターとブランドをつなぐプラットフォームを利用してきましたが、MegaEvolutionではこれまで経験した他のどのプラットフォームよりもスムーズで効果的でした。まず、インターフェースが非常に使いやすく、柔軟性があり、プランごとのパッケージシステムを通じてブランドにさまざまなオプションを提供できる点が特に便利だと感じました。このプラットフォームを見つけて今も本当に感謝していますが、正直、もっと早く見つけられたらよかったと思います 😄',
  [AboutKeys.Feedback.Creator3.name]:
    '5万＋、YouTubeストリーマー ・ Instagramクリエーター',
  [AboutKeys.Feedback.Creator3.comment]:
    '私と同じようなクリエイターたちにとって本当に必要な場所だと思います。所属会社がない新規/既存のクリエイターたちはどこで案件を受けれるのか不安でしたが、メガエボリューションを通じて多くのクリエイターの方々が幅広く活動できるようになったと思います！',
  [AboutKeys.Feedback.Creator4.name]: '15万＋、YouTubeクリエーター',
  [AboutKeys.Feedback.Creator4.comment]:
    'チャンネル運営者として、メガエボリューションプラットフォームはプロフィールを作っておくだけで企業とコラボレーションの機会が生まれる非常に良いプラットフォームです！',
  [AboutKeys.Feedback.Creator5.name]:
    '10万＋、YouTube ・ Instagramクリエーター',
  [AboutKeys.Feedback.Creator5.comment]:
    '昨年、メガエボリューションチームのおかげで自信がつき、そのおかげでYouTubeを再開することができました。そして今ここまで成長することができました！（その時から現在までチャンネルが3倍成長しました！）',
  [AboutKeys.Feedback.Creator6.name]: 'YouTubeゲームクリエーター',
  [AboutKeys.Feedback.Creator6.comment]:
    'コンテンツクリエイターのためのサービスということがよく感じられます！本当に画期的で、現在このプラットフォームを利用されている方々とこれから利用される方々全員がメガエボリューションが提供するサービスで大きな恩恵を受けることを確信しています！',
  [AboutKeys.Feedback.Creator7.name]:
    '3万＋、YouTubeテック ・ ゲームクリエーター',
  [AboutKeys.Feedback.Creator7.comment]:
    'Googleから認証を受けたメガエボリューションのプラットフォームのサービスと複雑な契約なしに、グローバルブランドと直接的なコンタクトが可能な点が特に魅力的に感じました！',
  [AboutKeys.Feedback.Creator8.name]: '5万＋、YouTubeクリエーター',
  [AboutKeys.Feedback.Creator8.comment]:
    '友人の一人からこのプラットフォームについて聞くことになり、その後、他のYouTuber友達の間でメガエボリューションが非常に成功したプラットフォームであるという事実を知ることになりました！',
  [AboutKeys.Feedback.Creator9.name]:
    '7万＋、YouTube・Instagram ・ TikTokビューティークリエーター',
  [AboutKeys.Feedback.Creator9.comment]:
    '私はワクワクする気持ちをメガエボリューションに対して持っているのですが、クリエイターたちにとって素晴らしいプラットフォームというだけでなく、プラットフォームの細部のレイアウトも本当に気に入っています！',
};
