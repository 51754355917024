export const UnderlineIcon = ({ size = 1 }) => (
  <svg
    width={12 * size}
    height={14 * size}
    viewBox="0 0 12 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.19444 10.8889C8.76889 10.8889 10.8611 8.79667 10.8611 6.22222V0H8.91667V6.22222C8.91667 7.72333 7.69556 8.94444 6.19444 8.94444C4.69333 8.94444 3.47222 7.72333 3.47222 6.22222V0H1.52778V6.22222C1.52778 8.79667 3.62 10.8889 6.19444 10.8889ZM0.75 12.4444V14H11.6389V12.4444H0.75Z" />
  </svg>
);
