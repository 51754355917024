export const GmailIcon = ({ size = 1 }) => (
  <svg
    width={size * 34}
    height={size * 26}
    viewBox="0 0 34 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_17_1309)">
      <path
        d="M7.57578 25.2088V12.2257L3.58164 8.54217L0 6.49805V22.9176C0 24.1853 1.01888 25.2088 2.27279 25.2088H7.57578Z"
        fill="#4285F4"
      />
      <path
        d="M25.7578 25.2086H31.0608C32.3185 25.2086 33.3336 24.1814 33.3336 22.9175V6.49805L29.2768 8.83935L25.7578 12.2256V25.2086Z"
        fill="#34A853"
      />
      <path
        d="M7.57474 12.2254L7.03125 7.15257L7.57474 2.29736L16.6656 9.1707L25.7565 2.29736L26.3645 6.89044L25.7565 12.2254L16.6656 19.0987L7.57474 12.2254Z"
        fill="#EA4335"
      />
      <path
        d="M25.7578 2.29755V12.2256L33.3336 6.4979V3.44306C33.3336 0.609798 30.1253 -1.00537 27.8792 0.693804L25.7578 2.29755Z"
        fill="#FBBC04"
      />
      <path
        d="M0 6.49795L3.48424 9.13236L7.57578 12.2257V2.29761L5.45443 0.693864C3.20443 -1.00544 0 0.609857 0 3.44299V6.49782V6.49795Z"
        fill="#C5221F"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_1309">
        <rect width="33.3333" height="25.3333" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
