export const CopyIcon = ({ size = 1 }) => (
  <svg
    width={15 * size}
    height={18 * size}
    viewBox="0 0 15 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.8333 15.167H4.16667C3.50363 15.167 2.86774 14.9036 2.3989 14.4348C1.93006 13.9659 1.66667 13.33 1.66667 12.667V4.33366C1.66667 4.11265 1.57887 3.90068 1.42259 3.7444C1.26631 3.58812 1.05435 3.50033 0.833333 3.50033C0.61232 3.50033 0.400358 3.58812 0.244078 3.7444C0.0877973 3.90068 0 4.11265 0 4.33366V12.667C0 13.7721 0.438987 14.8319 1.22039 15.6133C2.00179 16.3947 3.0616 16.8337 4.16667 16.8337H10.8333C11.0543 16.8337 11.2663 16.7459 11.4226 16.5896C11.5789 16.4333 11.6667 16.2213 11.6667 16.0003C11.6667 15.7793 11.5789 15.5674 11.4226 15.4111C11.2663 15.2548 11.0543 15.167 10.8333 15.167ZM15 5.95033C14.9913 5.87377 14.9746 5.79835 14.95 5.72533V5.65033C14.9099 5.56464 14.8565 5.48588 14.7917 5.41699L9.79167 0.416992C9.72278 0.352172 9.64402 0.298726 9.55833 0.258659H9.48333L9.21667 0.166992H5.83333C5.17029 0.166992 4.53441 0.430384 4.06557 0.899225C3.59673 1.36807 3.33333 2.00395 3.33333 2.66699V11.0003C3.33333 11.6634 3.59673 12.2993 4.06557 12.7681C4.53441 13.2369 5.17029 13.5003 5.83333 13.5003H12.5C13.163 13.5003 13.7989 13.2369 14.2678 12.7681C14.7366 12.2993 15 11.6634 15 11.0003V6.00033C15 6.00033 15 6.00033 15 5.95033ZM10 3.00866L12.1583 5.16699H10.8333C10.6123 5.16699 10.4004 5.0792 10.2441 4.92291C10.0878 4.76663 10 4.55467 10 4.33366V3.00866ZM13.3333 11.0003C13.3333 11.2213 13.2455 11.4333 13.0893 11.5896C12.933 11.7459 12.721 11.8337 12.5 11.8337H5.83333C5.61232 11.8337 5.40036 11.7459 5.24408 11.5896C5.0878 11.4333 5 11.2213 5 11.0003V2.66699C5 2.44598 5.0878 2.23402 5.24408 2.07774C5.40036 1.92146 5.61232 1.83366 5.83333 1.83366H8.33333V4.33366C8.33333 4.9967 8.59672 5.63259 9.06557 6.10143C9.53441 6.57027 10.1703 6.83366 10.8333 6.83366H13.3333V11.0003Z" />
  </svg>
);

