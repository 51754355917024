export const BlueCheckIcon = ({ size = 1 }) => (
  <svg
    width={70 * size}
    height={70 * size}
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="70" height="70" rx="35" fill="white" />
    <path
      d="M30.1 51.1L54.775 26.425L49.875 21.525L30.1 41.3L20.125 31.325L15.225 36.225L30.1 51.1ZM35 70C30.1583 70 25.6083 69.0807 21.35 67.242C17.0917 65.4057 13.3875 62.9125 10.2375 59.7625C7.0875 56.6125 4.59433 52.9083 2.758 48.65C0.919334 44.3917 0 39.8417 0 35C0 30.1583 0.919334 25.6083 2.758 21.35C4.59433 17.0917 7.0875 13.3875 10.2375 10.2375C13.3875 7.0875 17.0917 4.59317 21.35 2.7545C25.6083 0.918166 30.1583 0 35 0C39.8417 0 44.3917 0.918166 48.65 2.7545C52.9083 4.59317 56.6125 7.0875 59.7625 10.2375C62.9125 13.3875 65.4057 17.0917 67.242 21.35C69.0807 25.6083 70 30.1583 70 35C70 39.8417 69.0807 44.3917 67.242 48.65C65.4057 52.9083 62.9125 56.6125 59.7625 59.7625C56.6125 62.9125 52.9083 65.4057 48.65 67.242C44.3917 69.0807 39.8417 70 35 70ZM35 63C42.8167 63 49.4375 60.2875 54.8625 54.8625C60.2875 49.4375 63 42.8167 63 35C63 27.1833 60.2875 20.5625 54.8625 15.1375C49.4375 9.7125 42.8167 7 35 7C27.1833 7 20.5625 9.7125 15.1375 15.1375C9.7125 20.5625 7 27.1833 7 35C7 42.8167 9.7125 49.4375 15.1375 54.8625C20.5625 60.2875 27.1833 63 35 63Z"
      fill="#4C9BF8"
    />
  </svg>
);
