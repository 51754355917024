export const PremiumServiceIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 18}
      height={size * 18}
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_31977_67037)">
        <path d="M6.61094 9.50625L7.32188 7.19063L5.45312 5.6875H7.76875L8.5 3.4125L9.23125 5.6875H11.5469L9.65781 7.19063L10.3688 9.50625L8.5 8.06406L6.61094 9.50625ZM3.625 17.0625V10.7859C3.11042 10.2172 2.71094 9.56719 2.42656 8.83594C2.14219 8.10469 2 7.32604 2 6.5C2 4.68542 2.62969 3.14844 3.88906 1.88906C5.14844 0.629687 6.68542 0 8.5 0C10.3146 0 11.8516 0.629687 13.1109 1.88906C14.3703 3.14844 15 4.68542 15 6.5C15 7.32604 14.8578 8.10469 14.5734 8.83594C14.2891 9.56719 13.8896 10.2172 13.375 10.7859V17.0625L8.5 15.4375L3.625 17.0625ZM8.5 11.375C9.85417 11.375 11.0052 10.901 11.9531 9.95312C12.901 9.00521 13.375 7.85417 13.375 6.5C13.375 5.14583 12.901 3.99479 11.9531 3.04688C11.0052 2.09896 9.85417 1.625 8.5 1.625C7.14583 1.625 5.99479 2.09896 5.04688 3.04688C4.09896 3.99479 3.625 5.14583 3.625 6.5C3.625 7.85417 4.09896 9.00521 5.04688 9.95312C5.99479 10.901 7.14583 11.375 8.5 11.375ZM5.25 14.6453L8.5 13.8125L11.75 14.6453V12.1266C11.276 12.3974 10.765 12.6105 10.2168 12.766C9.6681 12.922 9.09583 13 8.5 13C7.90417 13 7.3319 12.922 6.78319 12.766C6.23502 12.6105 5.72396 12.3974 5.25 12.1266V14.6453Z" />
      </g>
    </svg>
  );
};
