import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import ToastContainer from './ToastContainer';
import Toast from './Toast';
import { Position, Type } from './constants';
import { nanoid } from 'nanoid';
import { useEffect } from 'react';

const ToastContext = createContext({
  success: (content, options) => {},
  plain: (content, options) => {},
  custom: (content, options) => {},
  error: (content, options) => {},
  loading: (content, options) => {},
  close: (id) => {},
});

const ToastProvider = ({
  children,
  position = 'top-center',
  autoClose = true,
  autoCloseDelay = 5000,
  pauseOnHover = true,
  showProgress = false,
  closeOnClick = true,
  toastClassName = '',
}) => {
  const [toasts, setToasts] = useState(
    localStorage.getItem('toasts')
      ? JSON.parse(localStorage.getItem('toasts'))
      : []
  );
  const { pathname } = window.location;

  useEffect(() => {
    if (pathname.includes('messages')) {
      localStorage.setItem('toasts', JSON.stringify(toasts));
    } else {
      localStorage.removeItem('toasts');
    }
  }, [pathname, toasts]);

  const createToast = useCallback(
    (content, type, options) => {
      const optionsWithDefaults = {
        position,
        autoClose,
        autoCloseDelay,
        pauseOnHover,
        showProgress,
        closeOnClick,
        className: toastClassName,
        ...options,
      };

      const id = nanoid();

      setToasts((prevToasts) => [
        {
          id,
          content,
          type,
          ...optionsWithDefaults,
        },
        ...prevToasts,
      ]);

      return id;
    },
    [
      position,
      autoClose,
      autoCloseDelay,
      pauseOnHover,
      showProgress,
      closeOnClick,
      toastClassName,
      setToasts,
    ]
  );

  const removeToast = useCallback(
    (id) => {
      setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    },
    [setToasts]
  );

  const custom = useCallback(
    (content, options) => {
      return createToast(content, Type.CUSTOM, options);
    },
    [createToast]
  );

  const plain = useCallback(
    (content, options) => {
      return createToast(content, Type.PLAIN, options);
    },
    [createToast]
  );

  const success = useCallback(
    (content, options) => {
      return createToast(content, Type.SUCCESS, options);
    },
    [createToast]
  );

  const error = useCallback(
    (content, options) => {
      return createToast(content, Type.ERROR, options);
    },
    [createToast]
  );

  const loading = useCallback(
    (content, options) => {
      return createToast(content, Type.LOADING, options);
    },
    [createToast]
  );

  const value = useMemo(
    () => ({
      success,
      plain,
      custom,
      error,
      loading,
      close: removeToast,
    }),
    [success, plain, custom, error, loading, removeToast]
  );

  return (
    <ToastContext.Provider value={value}>
      {children}

      {Object.values(Position).map((position) =>
        createPortal(
          <ToastContainer position={position}>
            {toasts
              .filter((toast) => toast.position === position)
              .map((toast) => {
                return (
                  <Toast
                    key={toast.id}
                    onClose={() => removeToast(toast.id)}
                    {...toast}
                  />
                );
              })}
          </ToastContainer>,
          document.body
        )
      )}
    </ToastContext.Provider>
  );
};

const useToast = () => useContext(ToastContext);

export { ToastProvider, useToast };
