const size2 = () => (
  <svg
    width="30"
    height="20"
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.7889 3.1318C28.6234 2.52643 28.3006 1.97454 27.8526 1.5311C27.4046 1.08766 26.8471 0.768136 26.2356 0.604351C23.9968 0 14.9871 0 14.9871 0C14.9871 0 5.97712 0.0183401 3.73865 0.622691C3.12717 0.786503 2.5697 1.10604 2.12177 1.54948C1.67383 1.99292 1.35106 2.54479 1.18558 3.15014C0.508412 7.08802 0.245696 13.088 1.20411 16.8682C1.36958 17.4735 1.69236 18.0254 2.14029 18.4689C2.58823 18.9123 3.14569 19.2318 3.75717 19.3956C5.99583 20 15.0057 20 15.0057 20C15.0057 20 24.0155 20 26.2541 19.3956C26.8656 19.2318 27.4231 18.9123 27.871 18.4689C28.319 18.0254 28.6417 17.4735 28.8072 16.8682C29.5213 12.9249 29.7414 6.92855 28.7887 3.13197L28.7889 3.1318Z"
      fill="#FF0000"
    />
    <path
      d="M12.1172 14.2902L19.5915 10.0046L12.1172 5.71875L12.1172 14.2902Z"
      fill="white"
    />
  </svg>
);

export default size2;
