import LoginKeys from '../translationKeys/LoginPageKeys';

export const LoginTranslations = {
  // Google
  [LoginKeys.Google.sign_in]: 'ลงชื่อเข้าใช้ด้วย Google',

  // Facebook
  [LoginKeys.Facebook.sign_in]: 'ลงชื่อเข้าใช้ด้วย Facebook',
  [LoginKeys.Facebook.sign_up]: 'ลงทะเบียนด้วย Facebook',

  // Wallet
  [LoginKeys.Wallet.sign_in]: 'ลงชื่อเข้าใช้ด้วย Wallet',
  [LoginKeys.Wallet.sign_up]: 'ลงทะเบียนด้วย Wallet',

  // Password
  [LoginKeys.Password.forgot]: 'ลืมรหัสผ่านใช่หรือไม่?',

  [LoginKeys.Password.Rule.title]: 'รหัสผ่านของคุณต้องประกอบด้วย:',
  [LoginKeys.Password.Rule.l1]: '· ตัวอักษรอย่างน้อย 8 ตัวอักษร',
  [LoginKeys.Password.Rule.l2]: '· ตัวอักษรภาษาอังกฤษอย่างน้อย 1 ตัว',
  [LoginKeys.Password.Rule.l3]: '· ตัวเลขอย่างน้อย 1 ตัว',
  [LoginKeys.Password.Rule.l4]: '· ตัวอักขระพิเศษอย่างน้อย 1 ตัว (!,@,#,$,...)',

  // Login Form
  [LoginKeys.Login_Form.Placeholder.email]: 'ป้อนอีเมล',
  [LoginKeys.Login_Form.Placeholder.password]: 'ป้อนรหัสผ่าน',

  [LoginKeys.Login_Form.no_account]: 'ยังไม่มีบัญชีใช่หรือไม่?',
  [LoginKeys.Login_Form.create_account]: 'สร้างบัญชี',

  [LoginKeys.Login_Form.sign_up]: 'ลงทะเบียน',

  // Sign Up Form
  [LoginKeys.Sign_Up_Form.Placeholder.email]: 'ลงทะเบียนด้วยอีเมล',
  [LoginKeys.Sign_Up_Form.Placeholder.password]: 'ป้อนรหัสผ่าน',

  [LoginKeys.Sign_Up_Form.Terms_of_Service.title]:
    'กรุณายอมรับข้อกำหนดและเงื่อนไขการใช้บริการ',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all]:
    'ยอมรับข้อกำหนดและเงื่อนไขทั้งหมด',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description]:
    'รวมถึงการยอมรับทั้งข้อกำหนดที่จำเป็นและข้อกำหนดเพิ่มเติม คุณสามารถใช้บริการได้โดยไม่ต้องยอมรับข้อกำหนดเพิ่มเติม',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.age]: 'ผู้ที่มีอายุ14 ปีขึ้นไป',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions]:
    'ข้อกำหนดและเงื่อนไข',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing]:
    'รับข้อมูลข่าวสารเกี่ยวกับกิจกรรมและการตลาด',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing_description]:
    'คุณสามารถรับการเเจ้งเตือนข้อมูลข่าวสารต่างๆเช่น กิจกรรมและสิทธิประโยชน์ของ MegaEvolution ผ่านทางอีเมลและช่องทางอื่นได้',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies]:
    'นโยบายความเป็นส่วนตัว',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies]:
    'นโยบายการชำระเงิน',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.optional]: '(ระบุหรือไม่ก็ได้)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.required]: '(ต้องระบุ)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree]: 'ยอมรับและเข้าร่วม',

  [LoginKeys.Sign_Up_Form.go_back]: 'กลับไปที่หน้าเข้าสู่ระบบ',

  [LoginKeys.Sign_Up_Form.password_updated]:
    'รหัสผ่านของคุณได้รับการอัปเดตแล้ว',
  [LoginKeys.Sign_Up_Form.thank_you_for_signing_up]:
    'ขอบคุณสำหรับการลงทะเบียน!',

  // Error
  [LoginKeys.Error.network]:
    'เกิดข้อผิดพลาดของเครือข่าย โปรดลองอีกครั้งในภายหลัง',
  [LoginKeys.Error.incorrect]: 'อีเมลหรือรหัสผ่านไม่ถูกต้อง',
  [LoginKeys.Error.recently_deleted]:
    'บัญชีนี้เพิ่งถูกลบไป โปรดลองอีกครั้งหลังจาก 24 ชั่วโมง',
  [LoginKeys.Error.login_failed]: 'การเข้าสู่ระบบล้มเหลว กรุณาลองอีกครั้ง',
  [LoginKeys.Error.already_in_use]: 'อีเมลนี้ถูกใช้งานแล้ว',
  [LoginKeys.Error.wallet_not_verified]: 'ไม่สามารถยืนยันวอลเล็ตได้',
  [LoginKeys.Error.already_in_use_or_connect_another_method]:
    'อีเมลนี้ได้เชื่อมต่อกับข้อมูลการเข้าสู่ระบบด้วยวิธีอื่นแล้ว',
  [LoginKeys.Error.currently_deactiated_reset_password]:
    'บัญชีถูกปิดใช้งานอยู่ขณะนี้ โปรดรีเซ็ตรหัสผ่านเพื่อเปิดใช้งาน',
  [LoginKeys.Error.currently_deactiated_login]:
    'บัญชีถูกปิดใช้งานอยู่ขณะนี้ โปรดพยายามเข้าสู่ระบบเพื่อเปิดใช้งานอีกครั้ง',
  [LoginKeys.Error.enter_valid_email]: 'ป้อนอีเมลที่ถูกต้อง',
  [LoginKeys.Error.invalid_request]: 'คำขอไม่ถูกต้อง',
  [LoginKeys.Error.too_many_request]:
    'มีคำขอมากเกินไป โปรดลองอีกครั้งในภายหลัง',
  [LoginKeys.Error.wallet_already_registered]: 'วอลเล็ตนี้ได้ลงทะเบียนไปแล้ว',
  [LoginKeys.Error.try_sign_in_social_app]: 'โปรดลองเข้าสู่ระบบด้วยแอปโซเชีย',
  [LoginKeys.Error.password_used]: 'รหัสผ่านเคยใช้ก่อนหน้านี้',

  // Modal
  [LoginKeys.Modal.Connect_Wallet.title]: 'เชื่อมต่อวอลเล็ต',
  [LoginKeys.Modal.Connect_Wallet.description]:
    'เลือกหนึ่งบริการกระเป๋าเงินที่มีอยู่หรือสร้างกระเป๋าเงินก่อน.',
  [LoginKeys.Modal.Reset_Password.reset]: 'ตั้งรหัสผ่านใหม่',

  [LoginKeys.Modal.Enter_Email.title]: 'โปรดป้อนอีเมลของคุณ',
  [LoginKeys.Modal.Enter_Email.description]: `
    โปรดป้อนอีเมลของคุณเพื่อสร้างบัญชี <br>
    คุณสามารถรับการแจ้งเตือนคำสั่งซื้อได้ที่นี่`,
  [LoginKeys.Modal.Enter_Email.Placeholder]: 'ป้อนอีเมล',
  [LoginKeys.Modal.Enter_Email.mobile_placeholder]: 'abcd@gmail.com',

  [LoginKeys.Modal.Reset_Password.description]:
    'รหัสผ่านของคุณหมดอายุแล้ว กรุณาใส่รหัสผ่านใหม่',
  [LoginKeys.Modal.Reset_Password.expired]: 'รหัสผ่านของคุณหมดอายุแล้ว!',
  [LoginKeys.Modal.Reset_Password.reset]: 'ตั้งรหัสผ่านใหม่',
  [LoginKeys.Modal.Reset_Password.update_success]:
    'รหัสผ่านได้รับการอัปเดตแล้ว!',

  [LoginKeys.Modal.Reset_Password.Error.valid_password]:
    'โปรดป้อนรหัสผ่านที่ถูกต้อง',

  [LoginKeys.Modal.Useful_Reward.title]:
    'คุณจะได้รับรางวัลและสิทธิประโยชน์หากคุณสมัครสมาชิก!',
  [LoginKeys.Modal.Useful_Reward.description]: `
    คุณสามารถยกเลิกการสมัครได้ทุกเมื่อเพียงคลิกเดียว <br>
    คุณยังคงได้รับการแจ้งเตือนการสั่งซื้อที่สำคัญ แม้ว่าคุณจะไม่ได้ <br>
    สมัครสมาชิกของเราก็ตาม`,
  [LoginKeys.Modal.Useful_Reward.button]: 'โอเค ฉันจะลองดู!',

  [LoginKeys.Modal.Verify_Email.title]: 'โปรดยืนยันอีเมลของคุณ',
  [LoginKeys.Modal.Verify_Email.description]: `
    ตรวจสอบอีเมลของคุณ {{email}} เพื่อยืนยันบัญชีของคุณ`,

  [LoginKeys.Modal.Check_Email.title]: 'โปรดตรวจสอบอีเมลของคุณ',
  [LoginKeys.Modal.Check_Email.description]: `
    โปรดตรวจสอบอีเมลของคุณเพื่อรีเซ็ตรหัสผ่าน.`,
  [LoginKeys.Modal.Check_Email.button]: 'ส่งอีเมลอีกครั้ง',

  [LoginKeys.Modal.VIP_Influencer.description]: `
    คุณเป็นครีเอเตอร์หรือไม่? <br>
    เปลี่ยนไปใช้บัญชีครีเอเตอร์เพื่อรับข้อเสนอ!`,
  [LoginKeys.Modal.VIP_Influencer.im_creator]: 'ฉันเป็นครีเอเตอร์!',
  [LoginKeys.Modal.VIP_Influencer.no_im_not]: 'ฉันไม่ใช่ครีเอเตอร์',

  [LoginKeys.Modal.Welcome_On_Board.description]: `
    ยินดีต้อนรับเข้าสู่เว็บไซต์ <br>
    MEGA มีของขวัญให้คุณ!`,
  [LoginKeys.Modal.Welcome_On_Board.hi_mega]: 'สวัสดี! MEGA',

  [LoginKeys.Modal.Use_Another_Method.title]: 'โปรดลงทะเบียนด้วยวิธีอื่น',
  [LoginKeys.Modal.Use_Another_Method.description]:
    'ดูเหมือนว่าบัญชี Facebook ของคุณไม่มีที่อยู่อีเมลที่เชื่อมต่อ. หากต้องการสมัครหรือเข้าสู่ระบบด้วย Facebook จําเป็นต้องมีที่อยู่อีเมล. โปรดลงทะเบียนด้วยวิธีอื่นเพื่อสร้างบัญชี',

  [LoginKeys.Modal.Enter_Your_Email.title]: 'ป้อนอีเมลของคุณ',
  [LoginKeys.Modal.Enter_Your_Email.description]:
    'โปรดป้อนอีเมลของคุณเพื่อรับลิงก์รีเซ็ตรหัสผ่าน',
  [LoginKeys.Modal.Enter_Your_Email.placeholder]: 'ป้อนอีเมลของคุณ',

  [LoginKeys.Modal.Reactivate.title]: 'บัญชีนี้ถูกปิดใช้งานอยู่ขณะนี้',
  [LoginKeys.Modal.Reactivate.description]:
    'คุณต้องการที่จะเปิดใช้งานบัญชีของคุณหรือไม่?',
  [LoginKeys.Modal.Register_type.buyer.title]: 'ยืนยันในฐานะบริษัท•หน่วยงาน',
  [LoginKeys.Modal.Register_type.buyer.description]:
    'ปลดล็อกสิทธิประโยชน์ถาวรด้วยการยืนยันอีเมลแบบง่ายๆ',
  [LoginKeys.Modal.Register_type.creator.description]:
    ' เปลี่ยนเป็นครีเอเตอร์ตอนนี้ และเริ่มรับข้อเสนอได้เลย',
  [LoginKeys.Modal.Register_type.skip]: 'ข้ามไปก่อน',
};
