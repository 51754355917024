export const HeadphoneIcon = ({ size = 1 }) => (
  <svg
    width={60 * size}
    height={60 * size}
    viewBox="0 0 60 60"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M30 0C13.458 0 0 13.458 0 30V42.429C0 45.501 2.691 48 6 48H9C9.79565 48 10.5587 47.6839 11.1213 47.1213C11.6839 46.5587 12 45.7957 12 45V29.571C12 28.7754 11.6839 28.0123 11.1213 27.4497C10.5587 26.8871 9.79565 26.571 9 26.571H6.276C7.944 14.961 17.934 6 30 6C42.066 6 52.056 14.961 53.724 26.571H51C50.2043 26.571 49.4413 26.8871 48.8787 27.4497C48.3161 28.0123 48 28.7754 48 29.571V48C48 51.309 45.309 54 42 54H36V51H24V60H42C48.618 60 54 54.618 54 48C57.309 48 60 45.501 60 42.429V30C60 13.458 46.542 0 30 0Z" />
  </svg>
);
