export const EyeIcon = ({ size = 1, height = null }) => (
  <svg
    width={20 * size}
    height={16 * (height || size)}
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0627 7.77831C17.2287 3.52036 13.7648 0.850031 10 0.850031C6.23527 0.850031 2.77144 3.52022 0.937488 7.77798C0.879784 7.91032 0.85 8.05314 0.85 8.19752C0.85 8.34178 0.879736 8.48448 0.937345 8.61673C2.77126 12.8747 6.23518 15.545 10 15.545C13.7647 15.545 17.2286 12.8748 19.0625 8.61706C19.1202 8.48472 19.15 8.3419 19.15 8.19752C19.15 8.05326 19.1203 7.91056 19.0627 7.77831ZM10 13.4456C7.24246 13.4456 4.60315 11.4638 3.0582 8.19752C4.60315 4.93128 7.24246 2.9494 10 2.9494C12.7575 2.9494 15.3968 4.93127 16.9418 8.19752C15.3968 11.4638 12.7575 13.4456 10 13.4456ZM10 4.44878C9.25857 4.44878 8.53379 4.66864 7.91731 5.08055C7.30083 5.49247 6.82034 6.07794 6.53661 6.76294C6.25288 7.44793 6.17864 8.20168 6.32329 8.92887C6.46793 9.65605 6.82496 10.324 7.34924 10.8483C7.87351 11.3726 8.54147 11.7296 9.26866 11.8742C9.99584 12.0189 10.7496 11.9446 11.4346 11.6609C12.1196 11.3772 12.7051 10.8967 13.117 10.2802C13.5289 9.66374 13.7487 8.93895 13.7487 8.19752C13.7487 7.20329 13.3538 6.24978 12.6508 5.54676C11.9477 4.84373 10.9942 4.44878 10 4.44878Z"
      fill="#0E0D39"
      stroke="#0E0D39"
      strokeWidth="0.3"
    />
  </svg>
);
