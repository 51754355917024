import i18n from 'i18next';

export const formatPriceInDollar = (
  price,
  { fractionDigits, minFractionDigits, maxFractionDigits, compact } = {}
) => {
  minFractionDigits = 0;
  maxFractionDigits = 1;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: minFractionDigits || fractionDigits || 0,
    maximumFractionDigits: maxFractionDigits || fractionDigits || 0,
    notation: compact ? 'compact' : 'standard',
    compactDisplay: 'short',
  });
  return formatter.format(price);
};

export const formatNumber = (
  number,
  {
    fractionDigits,
    minFractionDigits,
    maxFractionDigits,
    compact,
    language,
  } = {}
) => {
  const browserLanguage = i18n.language;

  if (!language) {
    if (browserLanguage.startsWith('ko')) {
      language = 'ko-KR';
    }
    if (browserLanguage.startsWith('ja')) {
      language = 'ja-JP';
    }
    // if (browserLanguage.startsWith('zh')) {
    //   language = 'zh-CN';
    // }
    // if (browserLanguage.startsWith('es')) {
    //   language = 'es-ES';
    // }
    // if (browserLanguage.startsWith('hi')) {
    //   language = 'hi-IN';
    // }
    // if (browserLanguage.startsWith('fr')) {
    //   language = 'fr-FR';
    // }
    // if (browserLanguage.startsWith('pt')) {
    //   language = 'pt-PT';
    // }
    // if (browserLanguage.startsWith('it')) {
    //   language = 'it-IT';
    // }
    // if (browserLanguage.startsWith('de')) {
    //   language = 'de-DE';
    // }
    else {
      language = 'en-US';
    }
  }

  const formatter = new Intl.NumberFormat(browserLanguage, {
    minimumFractionDigits: minFractionDigits || fractionDigits || 0,
    maximumFractionDigits: maxFractionDigits || fractionDigits || 0,
    notation: compact ? 'compact' : 'standard',
    compactDisplay: 'short',
  });
  return formatter.format(number);
};

export const numberAsCurrencyFormat = (n, digitLimit) => {
  if (digitLimit && n.length > digitLimit) {
    n = n.slice(0, digitLimit);
  }
  return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
