import GlobalModals from 'components/Basics/GlobalModals';
import LayoutRoutes from './LayoutRoutes';
import styled from 'styled-components';

const Router = () => {
  return (
    <Pages>
      <LayoutRoutes />
      <GlobalModals />
    </Pages>
  );
};

export default Router;

const Pages = styled.div``;
