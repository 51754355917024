import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: '没有要显示的创作者',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `订购#3(全球)#3
  #4(#1(影响者)#1 #2(营销)#2)#4
  服务#5(仅需一次点击!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: '试试“时尚营销”',
  [InfluKeys.search.option2]: '试试“视频游戏评测”',
  [InfluKeys.search.option3]: '试试“产品评测”',
  [InfluKeys.search.option4]: '尝试"食品评论"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: '注册成为创作者',

  // Mobile
  [InfluKeys.M.title]: '全球影响者<br/>营销平台',
  [InfluKeys.M.search]: '搜索影响者',
  [InfluKeys.M.content_box.request_creators.title]: '请求创作者',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    '为您的活动获取量身定制的全球创作者列表。',

  // Card
  [InfluKeys.Card.my_card]: '我的卡片',
  [InfluKeys.Card.view_package]: '查看套餐',
  [InfluKeys.Card.creator_profile]: '创作者档案',
  [InfluKeys.Card.view_packages]: '查看套餐',
  [InfluKeys.Card.view_analytics]: '查看分析',
  [InfluKeys.Card.send_a_message]: '发送消息',
  [InfluKeys.Card.view_profile]: '查看个人资料',
};
