export const HighLightIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 15}
      height={size * 15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.63867 8.9L5.03867 11V14.5H9.83867V11L12.2387 8.9V5.4H2.63867V8.9ZM6.63867 0.5H8.23867V2.6H6.63867V0.5ZM0.638672 3.216L1.76667 2.229L3.46267 3.713L2.33467 4.7L0.638672 3.216ZM11.4067 3.713L13.1027 2.229L14.2307 3.216L12.5427 4.7L11.4067 3.713Z"
        fill="#0E0D39"
      />
    </svg>
  );
};
