const lexicalTheme = {
  root: 'editor-root',
  paragraph: 'editor-text',
  text: {
    bold: 'editor-text-bold',
    italic: 'editor-text-italic',
    underline: 'editor-text-underline',
    highlight: 'editor-text-highlight',
  },
  list: {
    ol: 'editor-list-ol',
    olDepth: [
      'editor-list-ol-depth-1',
      'editor-list-ol-depth-2',
      'editor-list-ol-depth-3',
    ],
    ul: 'editor-list-ul',
    listitem: 'editor-listitem',
    nested: {
      listitem: 'editor-nested-listitem',
    },
  },
  image: 'editor-image',
};

export default lexicalTheme;
