export const ChartIcon = ({ size = 1 }) => (
  <svg
    width={21 * size}
    height={20 * size}
    viewBox="0 0 21 20"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.76 5.24C19.44 4.92 18.96 4.92 18.64 5.24L13.6 10.28L10.16 6.84C10 6.68 9.84 6.6 9.6 6.6C9.36 6.6 9.2 6.68 9.04 6.84L4.24 11.64C4.08 11.8 4 11.96 4 12.2C4 12.68 4.32 13 4.8 13C5.04 13 5.2 12.92 5.36 12.76L9.6 8.52L13.04 11.96C13.12 12.04 13.2 12.12 13.28 12.12C13.36 12.2 13.52 12.2 13.6 12.2C13.76 12.2 14 12.12 14.08 11.96C14.08 11.96 14.08 11.96 14.16 11.96L19.76 6.36C20.08 6.04 20.08 5.56 19.76 5.24Z"
      strokeWidth="0.6"
    />
    <path
      d="M19 18H18C18 18 18 18 17.5 18C17.5 18 17.2652 18 17 18C16.7348 18 16.5 18 16.5 18C16.5 18 15.5 18 16 18H14C14 18 14 18 13.5 18C13 18 13.2652 18 13 18C12.7348 18 12.5 18 12.5 18C12 18 12.5 18 12 18H10H9.5C9.5 18 9.26522 18 9 18C8.73478 18 8.5 18 8.5 18C8.5 18 7.5 18 8 18H6C6 18 6 18 5.5 18C5.5 18 5.26522 18 5 18C4.73478 18 4.5 18 4.5 18H4H2V1C2 0.734784 1.89464 0.48043 1.70711 0.292893C1.51957 0.105357 1.26522 0 1 0C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V19C0 19.2652 0.105357 19.5196 0.292893 19.7071C0.48043 19.8946 0.734784 20 1 20H19C19.2652 20 19.5196 19.8946 19.7071 19.7071C19.8946 19.5196 20 19.2652 20 19C20 18.7348 19.8946 18.4804 19.7071 18.2929C19.5196 18.1054 19.2652 18 19 18Z"
      stroke="none"
    />
  </svg>
);
