export const DeclineIcon = ({ size = 1, color = '#EC595A', style }) => {
  return (
    <svg
      width={size * 14}
      height={size * 15}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M1.4 14.5L0 13.1L5.6 7.5L0 1.9L1.4 0.5L7 6.1L12.6 0.5L14 1.9L8.4 7.5L14 13.1L12.6 14.5L7 8.9L1.4 14.5Z"
        fill={color}
      />
    </svg>
  );
};
