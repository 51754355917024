import Popup from 'components/Basics/Popup';
import { useState } from 'react';
import withAuthHOC from 'components/components/auth/withAuthHOC';
import CheckBox from 'components/Basics/Buttons/CheckBox';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import BorderedCard from 'components/Basics/Cards/BorderedCard';
import IndigoButton from 'components/Basics/Buttons/IndigoButton';
import Stack from 'components/Basics/Layout/Stack';
import Heading from 'components/Basics/Typography/Heading';
import Text from 'components/Basics/Typography/Text';
import device from 'styles/device';
import { useAuth } from 'hooks/useAauth';
import { authPlatform, mainFlow } from 'constants/authFlow';
import { authType, platformToType } from 'constants/authType';
import useUser from 'hooks/useUser';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import GrayButton from 'components/Basics/Buttons/GrayButton';
import { useNavigate } from 'react-router-dom';
import AuthModal from './AuthModal';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import Logo from 'components/Basics/Layout/Logo';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { Trans } from 'react-i18next';

const { GOOGLE, LOCAL, FACEBOOK } = authPlatform;
const { SIGN_UP, CREATORINVITATION } = mainFlow;

const TermsAndConditionsModal = ({ flowManager, loading, setIsLoading }) => {
  const {
    forwardStep,
    setSharedValue,
    sharedValue,
    authMethod,
    navigateFlow,
    closeModal,
  } = flowManager;
  const [show, setShow] = useState(true);
  const [noNewsletterPopup, setNoNewsletterPopup] = useState(false);

  const [ageCheck, setAgeCheck] = useState(false);
  const [termCheck, setTermCheck] = useState(false);
  const [marketingCheck, setMarketingCheck] = useState(false);
  const [privacyPolicyCheck, setPrivacyCheck] = useState(false);

  const [preventPopup, setPreventPopup] = useState(false);
  const { t, i18n } = useTranslation();

  const { refetch } = useUser();
  const { manager: localAuthManager } = useAuth(platformToType(LOCAL));
  const { manager: googleAuthManager } = useAuth(platformToType(GOOGLE));
  const { manager: facebookAuthManager } = useAuth(platformToType(FACEBOOK));

  const { manager: web3AuthManager } = useAuth(platformToType(authMethod));
  const { authSubMethod, setSubAuthMethod } = useAuthFlowManager();

  function onError(error) {
    setIsLoading(false);
  }
  async function handleSignup() {
    if (!marketingCheck && !preventPopup) {
      setNoNewsletterPopup(true);
      setPreventPopup(true);
      setShow(false);
      return;
    }
    setIsLoading(true);
    setSharedValue((current) => {
      current.marketingCheck = marketingCheck;
      return current;
    });

    switch (platformToType(authMethod)) {
      default:
      case authType.LOCAL: {
        await localAuthManager.signup(
          {
            ...sharedValue.userData,
            marketingCheck,
            registerType: LOCAL,
            language: i18n.language,
          },
          {
            onSuccess: function (userData) {
              setSharedValue((current) => {
                current.userData.id = userData.id;
                return current;
              });
              forwardStep();
            },
            onError,
          }
        );
        break;
      }
      case authType.GOOGLE: {
        await googleAuthManager.signup(
          {
            ...sharedValue.userData,
            marketingCheck,
            registerType: GOOGLE,
            language: i18n.language,
          },
          {
            onSuccess: (userData) => {
              setSharedValue((current) => {
                current.userData.id = userData.id;
                return current;
              });
            },
            onError,
          }
        );

        const { email, googleToken } = sharedValue.userData;

        await googleAuthManager.login(
          {
            email,
            googleToken,
            loginType: GOOGLE,
          },
          {
            onSuccess: () => {
              refetch()
                .then(() => {
                  if (authSubMethod === CREATORINVITATION) {
                    closeModal();
                    navigate('/creator-registration');
                    setSubAuthMethod('');
                  } else {
                    forwardStep();
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            },
            onError,
          }
        );

        break;
      }
      case authType.FACEBOOK: {
        await facebookAuthManager.signup(
          {
            email: sharedValue.userData.email,
            name: sharedValue.userData.name,
            pic: sharedValue.userData.pic,
            fbAccessToken: sharedValue.userData.fbAccessToken,
            marketingCheck,
            registerType: FACEBOOK,
            language: i18n.language,
          },
          {
            onSuccess: (userData) => {
              setSharedValue((current) => {
                current.userData.id = userData.id;
                return current;
              });
            },
            onError,
          }
        );

        await facebookAuthManager.login(
          {
            email: sharedValue.userData.email,
            fbAccessToken: sharedValue.userData.fbAccessToken,
            loginType: FACEBOOK,
          },
          {
            onSuccess: (e) => {
              refetch()
                .then(() => {
                  if (authSubMethod === CREATORINVITATION) {
                    closeModal();
                    navigate('/creator-registration');
                    setSubAuthMethod('');
                  } else {
                    forwardStep();
                  }
                })
                .catch((e) => {
                  console.log(e);
                });
            },
            onError,
          }
        );
        break;
      }

      case authType.WALLET: {
        await web3AuthManager.signup(
          {
            ...sharedValue.userData,
            type: authMethod,
            marketingCheck: sharedValue.marketingCheck,
            registerType: platformToType(authMethod),
            language: i18n.language,
          },
          {
            onSuccess: (userData) => {
              setSharedValue((current) => {
                current.userData.id = userData.id;
                return current;
              });
              forwardStep();
            },
            onError,
          }
        );
        break;
      }
    }
  }
  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';
  // }, []);

  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();

  return (
    <>
      <Popup
        show={noNewsletterPopup}
        onClose={() => {
          setNoNewsletterPopup(false);
          setShow(true);
        }}
        title={t(LoginKeys.Modal.Useful_Reward.title)}
        description={
          <Trans>{t(LoginKeys.Modal.Useful_Reward.description)}</Trans>
        }
        buttonText={t(LoginKeys.Modal.Useful_Reward.button)}
        onButtonClick={() => {
          setMarketingCheck(true);
          setNoNewsletterPopup(false);
          setShow(true);
        }}
        secondaryButtonText={t(GeneralKeys.close)}
        onSecondaryButtonClick={() => {
          setNoNewsletterPopup(false);
          setShow(true);
        }}
      />
      {!isMobile && (
        <AuthModal
          show={!isMobile && show}
          onClose={() => {
            navigateFlow({
              flow: SIGN_UP,
              method: LOCAL,
              step: 0,
            });
          }}
        >
          <ModalBody>
            {loading()}
            <BorderedCard
              color="plain"
              shadow="var(--shadow-modal)"
              borderWidth="0px"
              className="w-100"
              padding="3.75rem 4.375rem"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <Heading variant="H3" className="text-start">
                {t(LoginKeys.Sign_Up_Form.Terms_of_Service.title)}
              </Heading>
              <Stack gap="1.25rem" margin="1.875rem 0 0 0">
                <Stack>
                  <CheckBox
                    name="agreeAll"
                    label={t(LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all)}
                    checked={
                      ageCheck &&
                      termCheck &&
                      marketingCheck &&
                      privacyPolicyCheck
                    }
                    onClick={(e) => {
                      setAgeCheck(e.target.checked);
                      setTermCheck(e.target.checked);
                      setMarketingCheck(e.target.checked);
                      setPrivacyCheck(e.target.checked);
                    }}
                    className="bh2"
                  />
                  <Text
                    variant="xs"
                    className="text-indigo-100 ms-4"
                    style={{ textAlign: 'left' }}
                  >
                    {t(
                      LoginKeys.Sign_Up_Form.Terms_of_Service
                        .agree_all_description
                    )}
                  </Text>
                </Stack>
                <Divider />
                <Stack gap="0.875rem">
                  <CheckBox
                    className="h7"
                    name="ageCheck"
                    label={
                      t(LoginKeys.Sign_Up_Form.Terms_of_Service.age) +
                      ' ' +
                      t(LoginKeys.Sign_Up_Form.Terms_of_Service.required)
                    }
                    checked={ageCheck}
                    onClick={(e) => {
                      setAgeCheck(e.target.checked);
                    }}
                  />
                  <CheckBox
                    className="h7"
                    name="termCheck"
                    label={
                      <span>
                        MegaEvolution{' '}
                        <TextButton
                          onClick={() => {
                            window.open('/terms-and-conditions', '_blank');
                          }}
                          className="bh6"
                        >
                          {t(
                            LoginKeys.Sign_Up_Form.Terms_of_Service
                              .terms_conditions
                          )}
                        </TextButton>{' '}
                        {t(LoginKeys.Sign_Up_Form.Terms_of_Service.required)}
                      </span>
                    }
                    checked={termCheck}
                    onClick={(e) => {
                      setTermCheck(e.target.checked);
                    }}
                  />
                  <Stack gap="0.25rem">
                    <CheckBox
                      className="h7"
                      name="marketingCheck"
                      label={
                        t(
                          LoginKeys.Sign_Up_Form.Terms_of_Service
                            .receive_marketing
                        ) +
                        ' ' +
                        t(LoginKeys.Sign_Up_Form.Terms_of_Service.optional)
                      }
                      checked={marketingCheck}
                      onClick={(e) => {
                        setMarketingCheck(e.target.checked);
                      }}
                    />
                    <Text
                      variant="xs"
                      className="text-indigo-100 ms-4"
                      style={{ textAlign: 'left' }}
                    >
                      {t(
                        LoginKeys.Sign_Up_Form.Terms_of_Service
                          .receive_marketing_description
                      )}
                    </Text>
                  </Stack>
                  <CheckBox
                    className="h7"
                    name="privacyPolicyCheck"
                    label={
                      <span>
                        <TextButton
                          onClick={() => {
                            window.open('/privacy-policy', '_blank');
                          }}
                        >
                          {t(
                            LoginKeys.Sign_Up_Form.Terms_of_Service
                              .privacy_policies
                          )}
                        </TextButton>{' '}
                        {t(LoginKeys.Sign_Up_Form.Terms_of_Service.required)}
                      </span>
                    }
                    checked={privacyPolicyCheck}
                    onClick={(e) => {
                      setPrivacyCheck(e.target.checked);
                    }}
                  />
                </Stack>
              </Stack>

              <IndigoButton
                type="submit"
                className="bh5 text-white bh5 w-100 shadow-none"
                padding="13px"
                disabled={!ageCheck || !termCheck || !privacyPolicyCheck}
                style={{ marginTop: '28px' }}
                onClick={handleSignup}
              >
                {t(LoginKeys.Sign_Up_Form.Terms_of_Service.agree)}
              </IndigoButton>
            </BorderedCard>
          </ModalBody>
        </AuthModal>
      )}
      {/* Mobile version */}
      {isMobile && (
        <MobileContainer
          className="md-hidden overflow-hidden"
          show={isMobile}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {loading()}
          <LogoContainer
            className="md-hidden cursor-pointer"
            onClick={() => navigate('/')}
          >
            <Logo />
          </LogoContainer>
          <Heading variant="H7">
            {t(LoginKeys.Sign_Up_Form.Terms_of_Service.title)}
          </Heading>
          <Stack gap="1.375rem" margin="1.125rem 0 0 0">
            <Stack gap="11px">
              <CheckBox
                name="agreeAll"
                label={t(LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all)}
                checked={
                  ageCheck && termCheck && marketingCheck && privacyPolicyCheck
                }
                checkBoxSize="mdl"
                onClick={(e) => {
                  setAgeCheck(e.target.checked);
                  setTermCheck(e.target.checked);
                  setMarketingCheck(e.target.checked);
                  setPrivacyCheck(e.target.checked);
                }}
                className="bh5"
              />
              <Text
                variant="xs"
                className="text-indigo-100"
                style={{
                  fontSize: '12px',
                  border: '1px solid var(--color-indigo-50)',
                  padding: '0.75rem',
                  borderRadius: '10px',
                  background: '#fff',
                  color: 'var(--color-indigo-200)',
                  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                }}
              >
                {t(
                  LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description
                )}
              </Text>
            </Stack>
            <Divider />
            <Stack gap="19px">
              <CheckBox
                className="h7"
                checkBoxSize={'md'}
                name="ageCheck"
                label={
                  <span>
                    {t(LoginKeys.Sign_Up_Form.Terms_of_Service.age) + ' '}
                    <span className="h9">
                      {t(LoginKeys.Sign_Up_Form.Terms_of_Service.required)}
                    </span>
                  </span>
                }
                checked={ageCheck}
                onClick={(e) => {
                  setAgeCheck(e.target.checked);
                }}
              />
              <span>
                <CheckBox
                  className="h7"
                  name="termCheck"
                  checkBoxSize={'md'}
                  label={
                    <span>
                      MegaEvolution{' '}
                      <BoldText
                        onClick={() => {
                          window.open('/terms-and-conditions', '_blank');
                        }}
                        className="bh7"
                      >
                        {t(
                          LoginKeys.Sign_Up_Form.Terms_of_Service
                            .terms_conditions
                        )}
                      </BoldText>
                    </span>
                  }
                  checked={termCheck}
                  onClick={(e) => {
                    setTermCheck(e.target.checked);
                  }}
                />
                <OptionalRequired>
                  {t(LoginKeys.Sign_Up_Form.Terms_of_Service.required)}
                </OptionalRequired>
              </span>
              <Stack gap="9px">
                <span>
                  <CheckBox
                    className="h7"
                    name="marketingCheck"
                    label={
                      <span>
                        {t(
                          LoginKeys.Sign_Up_Form.Terms_of_Service
                            .receive_marketing
                        )}
                      </span>
                    }
                    checkBoxSize={'md'}
                    checked={marketingCheck}
                    onClick={(e) => {
                      setMarketingCheck(e.target.checked);
                    }}
                  />
                  <OptionalRequired>
                    {t(LoginKeys.Sign_Up_Form.Terms_of_Service.optional)}
                  </OptionalRequired>
                </span>
                <Text
                  variant="xs"
                  className="text-indigo-100"
                  style={{
                    fontSize: '12px',
                    border: '1px solid var(--color-indigo-50)',
                    padding: '0.75rem',
                    borderRadius: '10px',
                    background: '#fff',
                    color: 'var(--color-indigo-200)',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  {t(
                    LoginKeys.Sign_Up_Form.Terms_of_Service
                      .receive_marketing_description
                  )}
                </Text>
              </Stack>
              <CheckBox
                className="h7"
                name="privacyPolicyCheck"
                checkBoxSize={'md'}
                label={
                  <span>
                    <BoldText
                      onClick={() => {
                        window.open('/privacy-policy', '_blank');
                      }}
                    >
                      {t(
                        LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies
                      )}
                    </BoldText>
                    <span className="h9">
                      {' '}
                      {t(LoginKeys.Sign_Up_Form.Terms_of_Service.required)}
                    </span>
                  </span>
                }
                checked={privacyPolicyCheck}
                onClick={(e) => {
                  setPrivacyCheck(e.target.checked);
                }}
              />
            </Stack>
          </Stack>
          <Buttons>
            <GrayButton
              variant="outlined"
              radius="500px"
              padding="0.75rem 50px"
              className="shadow-mobile-text-box bh5"
              onClick={() =>
                navigateFlow({
                  flow: SIGN_UP,
                  method: LOCAL,
                  step: 0,
                })
              }
            >
              {t(GeneralKeys.cancel)}
            </GrayButton>
            <IndigoButton
              radius="500px"
              padding="0.75rem 54px"
              className="shadow-none bh5"
              minWidth="max-content"
              disabled={!ageCheck || !termCheck || !privacyPolicyCheck}
              onClick={handleSignup}
            >
              {t(GeneralKeys.agree)}
            </IndigoButton>
          </Buttons>
        </MobileContainer>
      )}
    </>
  );
};

export default withAuthHOC(TermsAndConditionsModal);

const ModalBody = styled.div.attrs({
  className: 'hidden md-block',
})`
  padding: 0 !important;
  width: 518px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  text-align: center;

  box-shadow: var(--shadow-modal);
  border: none;
  border-radius: 1.5rem;
  margin: 0 auto;
  background: white;
`;
const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background: var(--color-indigo-50);
`;

const TextButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  width: max-content;

  @media ${device.md} {
    font-weight: 700;
    font-size: 14px;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const MobileContainer = styled.div`
  width: 100%;
  height: min-content;
  background: var(--color-pink-50);
  box-shadow: none;
  padding: 0 1rem 2rem;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 10005;

  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const LogoContainer = styled.div.attrs({})`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  z-index: 1000;
  background-color: var(--color-pink-50);
  margin-bottom: 0.75rem;
`;

// const Divider = styled.div`
//   width: 100%;
//   height: 0.5px;
//   background: var(--color-indigo-50);
// `;

// const TextButton = styled.button`
//   background: none;
//   border: none;
//   padding: 0;
//   margin: 0;
//   font-weight: 600;
//   font-size: 12px;
//   width: max-content;

//   @media ${device.md} {
//     font-weight: 700;
//     font-size: 14px;
//   }

//   &:hover {
//     text-decoration: underline;
//   }
//   `;

const OptionalRequired = styled.div.attrs({
  className: 'h9',
})`
  padding-left: 26px;
`;

const BoldText = styled.div`
  display: inline-block;
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.5px;
  &:hover {
    text-decoration: underline;
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.25rem;

  @media ${device.sm} {
    margin-top: 2rem;
    min-width: 100%;
    justify-content: center;
    gap: 1rem;
  }
`;
