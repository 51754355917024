export const CheckRoundedIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12 * size}
    height={9 * size}
    viewBox="0 0 12 9"
    fill="currentColor"
  >
    <path d="M11.7251 0.461912C11.6457 0.383315 11.5513 0.320931 11.4472 0.278358C11.3432 0.235785 11.2316 0.213867 11.1189 0.213867C11.0062 0.213867 10.8946 0.235785 10.7905 0.278358C10.6865 0.320931 10.592 0.383315 10.5126 0.461912L4.15139 6.71758L1.47882 4.08449C1.3964 4.00631 1.29911 3.94483 1.1925 3.90357C1.08589 3.86231 0.972054 3.84207 0.857483 3.84402C0.742911 3.84597 0.629852 3.87006 0.524761 3.91491C0.419669 3.95977 0.324603 4.02452 0.244991 4.10546C0.165378 4.1864 0.102779 4.28194 0.0607656 4.38664C0.0187524 4.49134 -0.00185163 4.60314 0.000130571 4.71566C0.00211277 4.82818 0.0266423 4.93921 0.0723181 5.04242C0.117994 5.14563 0.183922 5.23899 0.266338 5.31718L3.54515 8.53725C3.62453 8.61585 3.71897 8.67823 3.82302 8.72081C3.92707 8.76338 4.03867 8.7853 4.15139 8.7853C4.26411 8.7853 4.37572 8.76338 4.47977 8.72081C4.58382 8.67823 4.67826 8.61585 4.75763 8.53725L11.7251 1.6946C11.8118 1.61607 11.881 1.52077 11.9283 1.41469C11.9756 1.30861 12 1.19406 12 1.07825C12 0.962447 11.9756 0.847896 11.9283 0.741818C11.881 0.635741 11.8118 0.540437 11.7251 0.461912Z" />
  </svg>
);
