const { useState, useEffect, useCallback } = require('react');

const loadingSrc = '/assets/gif/MegaLoading.gif';

const useLazyImage = (src, lazySrc, fallback) => {
  const [imageSrc, setImageSrc] = useState(lazySrc || loadingSrc);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const onError = useCallback(() => {
    if (fallback) setImageSrc(fallback);
    else setImageSrc('');
    setIsLoading(false);
    setError(true);
  }, [fallback]);

  useEffect(() => {
    if (!src) {
      return onError();
    }

    setIsLoading(true);

    setImageSrc(lazySrc || loadingSrc);

    const imageToLoad = new Image();
    imageToLoad.src = src;
    imageToLoad.onload = () => {
      setImageSrc(src);
      setIsLoading(false);
    };
    imageToLoad.onerror = onError;

    return () => {
      imageToLoad.onload = null;
      imageToLoad.onerror = null;
    };
  }, [src, lazySrc, onError]);

  return [imageSrc, isLoading, error];
};

export default useLazyImage;
