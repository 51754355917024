import { createContext, useContext, useEffect, useState } from 'react';
import { AnalyticsType, AnalysisOptions } from '../constants';
import { useAsync } from 'react-use';
import getContents from '../apis/getContents';
import getGPA from '../apis/getGPA';
import Platform from 'constants/platform';

const ContentAnalyticsContext = createContext();

const filters = AnalysisOptions[AnalyticsType.Content];

const ContentAnalyticsProvider = ({
  children,
  userId,
  handle,
  platform,
  isBasic,
}) => {
  const [selectedFilter, selectFilter] = useState(filters[0].value);

  useEffect(() => {
    if (platform === Platform.Instagram) {
      filters.find((f) => f.value === 'views').label = 'Most Reached';
    }
  }, [platform]);

  const {
    value: content,
    loading: contentLoading,
    error: contentError,
  } = useAsync(() => {
    if (!userId && !handle) return Promise.resolve({});
    return getContents({
      userId,
      handle,
      limit: platform === Platform.YouTube ? 30 : 40,
      sort: selectedFilter,
      platform,
      isBasic,
    });
  }, [userId, handle, selectedFilter, platform]);

  const {
    value: gpa,
    loading: gpaLoading,
    error: gpaError,
  } = useAsync(() => {
    if (!userId && !handle) return Promise.resolve({});

    return getGPA({
      userId,
      handle,
      platform,
      isBasic,
    });
  }, [userId, handle, platform]);

  return (
    <ContentAnalyticsContext.Provider
      value={{
        filters,
        selectedFilter,
        selectFilter,
        content,
        contentLoading,
        contentError,
        gpa,
        gpaLoading,
        gpaError,
        isBasic,
      }}
    >
      {children}
    </ContentAnalyticsContext.Provider>
  );
};

const useContentAnalytics = () => {
  const context = useContext(ContentAnalyticsContext);

  if (!context) {
    throw new Error(
      'useContentAnalytics (Analytics card) must be used within ContentAnalyticsProvider'
    );
  }

  const { filters, selectedFilter, selectFilter, isBasic } = context;

  return {
    filters,
    selectedFilter,
    selectFilter,
    isBasic,
  };
};

export default ContentAnalyticsContext;

export { ContentAnalyticsProvider, useContentAnalytics };
