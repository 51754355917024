export const MetaFullIcon = ({ size = 1 }) => (
  <svg
    width={size * 121}
    height={size * 24}
    viewBox="0 0 121 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7">
      <path
        d="M44.4144 0.758911H48.9292L56.6061 14.6458L64.2829 0.758911H68.7V23.5777H65.0165V6.08883L58.2849 18.1991H54.8295L48.0979 6.08883V23.5777H44.4144V0.758911Z"
        fill="#1C2B33"
      />
      <path
        d="M80.5818 23.9854C78.8757 23.9854 77.3762 23.6078 76.0833 22.8526C74.7901 22.0974 73.7822 21.0515 73.0597 19.715C72.337 18.3784 71.9757 16.8463 71.9759 15.1186C71.9759 13.3693 72.329 11.8208 73.0353 10.4733C73.7416 9.12613 74.7222 8.07209 75.9773 7.3112C77.2324 6.5508 78.6749 6.17049 80.3047 6.17029C81.9237 6.17029 83.3173 6.5533 84.4855 7.31932C85.6534 8.08555 86.5525 9.15861 87.1829 10.5385C87.813 11.9186 88.1281 13.5376 88.1283 15.3957V16.4061H75.6269C75.8551 17.7972 76.4174 18.892 77.3139 19.6904C78.2103 20.4891 79.3431 20.8884 80.7122 20.8884C81.8096 20.8884 82.755 20.7254 83.5482 20.3994C84.3413 20.0734 85.0857 19.579 85.7813 18.9163L87.7371 21.3122C85.7919 23.0944 83.4068 23.9855 80.5818 23.9854ZM83.2712 10.4488C82.4995 9.66111 81.489 9.2672 80.2396 9.26705C79.0225 9.26705 78.0038 9.66915 77.1835 10.4733C76.3629 11.2775 75.844 12.3586 75.6269 13.7169H84.5914C84.4827 12.3259 84.0426 11.2366 83.2712 10.4488Z"
        fill="#1C2B33"
      />
      <path
        d="M92.5946 9.5932H89.2043V6.57781H92.5946V1.59033H96.1478V6.57781H101.298V9.5932H96.1478V17.2374C96.1478 18.5087 96.3651 19.416 96.7998 19.9593C97.2342 20.5028 97.9785 20.7745 99.0328 20.7742C99.4998 20.7742 99.8964 20.7552 100.223 20.7172C100.549 20.6793 100.907 20.6277 101.298 20.5623V23.545C100.852 23.6742 100.397 23.7723 99.9373 23.8386C99.4112 23.916 98.88 23.9541 98.3481 23.9526C94.5123 23.9526 92.5945 21.8555 92.5946 17.6612V9.5932Z"
        fill="#1C2B33"
      />
      <path
        d="M119.815 23.5778H116.327V21.1981C115.707 22.0892 114.919 22.7765 113.963 23.2599C113.007 23.7432 111.92 23.9851 110.703 23.9854C109.204 23.9854 107.875 23.6023 106.718 22.8361C105.561 22.0701 104.651 21.0161 103.988 19.6742C103.325 18.3322 102.994 16.7973 102.994 15.0697C102.994 13.3312 103.331 11.7936 104.004 10.4569C104.678 9.12051 105.61 8.07195 106.8 7.3112C107.99 6.5508 109.356 6.17049 110.899 6.17029C112.062 6.17029 113.105 6.39578 114.028 6.84679C114.94 7.28755 115.729 7.94512 116.327 8.76185V6.57785H119.815V23.5778ZM116.261 12.527C115.881 11.5599 115.28 10.7965 114.46 10.2369C113.64 9.67733 112.692 9.39756 111.616 9.39756C110.095 9.39756 108.883 9.90825 107.981 10.9296C107.079 11.9511 106.628 13.3311 106.629 15.0697C106.629 16.8193 107.063 18.2047 107.933 19.226C108.802 20.2474 109.981 20.7581 111.469 20.7581C112.567 20.7581 113.545 20.4755 114.403 19.9104C115.261 19.3457 115.881 18.5851 116.261 17.6286L116.261 12.527Z"
        fill="#1C2B33"
      />
      <path
        d="M4.59698 15.8274C4.59698 17.2071 4.89981 18.2664 5.29562 18.9072C5.81458 19.7466 6.5886 20.1022 7.37771 20.1022C8.39551 20.1022 9.32661 19.8496 11.121 17.3679C12.5584 15.3788 14.2523 12.5868 15.3919 10.8364L17.322 7.871C18.6627 5.81153 20.2146 3.52209 21.9938 1.97026C23.4464 0.70367 25.0133 0 26.5903 0C29.2378 0 31.7597 1.53424 33.6897 4.41173C35.802 7.56314 36.8273 11.5326 36.8273 15.6289C36.8273 18.0641 36.3473 19.8534 35.5306 21.267C34.7415 22.6341 33.2035 24 30.6162 24V20.1022C32.8315 20.1022 33.3844 18.0666 33.3844 15.737C33.3844 12.4172 32.6104 8.73298 30.9052 6.10052C29.6952 4.2333 28.127 3.09235 26.4018 3.09235C24.5358 3.09235 23.0343 4.49968 21.3467 7.009C20.4495 8.3422 19.5285 9.9669 18.4944 11.8002L17.3559 13.817C15.069 17.8718 14.4898 18.7954 13.3463 20.3196C11.3421 22.9885 9.63069 24 7.37771 24C4.70503 24 3.01498 22.8427 1.96828 21.0986C1.11383 19.6775 0.694153 17.8128 0.694153 15.688L4.59698 15.8274Z"
        fill="#0081FB"
      />
      <path
        d="M3.77142 4.6869C5.56074 1.92878 8.14294 0 11.1046 0C12.8198 0 14.5249 0.507648 16.3055 1.96147C18.2531 3.551 20.3289 6.16839 22.9186 10.4821L23.8472 12.0302C26.0889 15.7646 27.3643 17.6859 28.1107 18.5918C29.0707 19.7554 29.7429 20.1022 30.6162 20.1022C32.8315 20.1022 33.3844 18.0666 33.3844 15.737L36.8273 15.6289C36.8273 18.0641 36.3473 19.8534 35.5306 21.267C34.7415 22.6341 33.2035 24 30.6162 24C29.0079 24 27.5829 23.6507 26.0072 22.1642C24.7959 21.0232 23.3798 18.9964 22.2904 17.1744L19.0497 11.7612C17.4238 9.0446 15.9323 7.01905 15.069 6.10177C14.1404 5.11539 12.9467 3.92418 11.0418 3.92418C9.50001 3.92418 8.19069 5.00607 7.09498 6.66094L3.77142 4.6869Z"
        fill="url(#paint0_linear_72168_7060)"
      />
      <path
        d="M11.0418 3.92418C9.50001 3.92418 8.19069 5.00607 7.09499 6.66094C5.54566 8.99937 4.59698 12.4825 4.59698 15.8274C4.59698 17.2071 4.89981 18.2664 5.29562 18.9072L1.96828 21.0986C1.11383 19.6775 0.694153 17.8128 0.694153 15.688C0.694153 11.8241 1.75467 7.79684 3.77143 4.6869C5.56074 1.92878 8.14295 0 11.1046 0L11.0418 3.92418Z"
        fill="url(#paint1_linear_72168_7060)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_72168_7060"
        x1="8.35907"
        y1="14.7016"
        x2="33.2386"
        y2="15.9581"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0064E1" />
        <stop offset="0.4" stopColor="#0064E1" />
        <stop offset="0.83" stopColor="#0073EE" />
        <stop offset="1" stopColor="#0082FB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_72168_7060"
        x1="6.3486"
        y1="17.466"
        x2="6.3486"
        y2="8.29319"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0082FB" />
        <stop offset="1" stopColor="#0064E0" />
      </linearGradient>
    </defs>
  </svg>
);
