import styled from 'styled-components';
import { MetamaskIcon, CoinBaseIcon, WalletConnectIcon } from 'assets/icons';
import Heading from 'components/Basics/Typography/Heading';
import useAuth from 'hooks/useAuth';
import useUser from 'hooks/useUser';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { authPlatform } from 'constants/authFlow';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys'



/**
 *
 * @param {string} types
 * - type of Wallet option , default, 'metaMask'
 * - metaMask
 * - coinBase
 * - walletConnect
 * @param {string} margin
 * - set margin
 * @param {function} signUpProcess
 * - part of onClick callback, for signUp process. once onClick is triggered, login process will be held, then this function will be executed
 * @param {function} needVerification
 * - if user isn't verified, will trigger this function.
 * @param {Dispatch<SetStateAction>} setModal
 * - setState function that closes wallet listing modal
 * @param {boolean} onlySignup
 * - when true, onClick function will skip sign in process and strait to sign up process
 */
const WalletOption = ({ type = '', margin, onClick }) => {
  const { t } = useTranslation();
  
  const types = {
    [authPlatform.METAMASK]: {
      Icon: MetamaskIcon,
      text: t(GeneralKeys.metamask),
      mobileIconSize: 0.75,
      style: {},
      textStyle: {},
    },
    [authPlatform.COINBASE]: {
      Icon: CoinBaseIcon,
      text: t(GeneralKeys.coinbase_wallet),
      mobileIconSize: 0.63,
      style: {
        position: 'relative',
        right: '3px',
      },
      textStyle: {
        position: 'relative',
        right: '5px',
      },
    },
  };

  const { Icon, text, mobileIconSize, style, textStyle } = types[type];
  const { generateWalletSignupData, signIn } = useAuth();
  const { updateUser } = useUser();
  const { isMobile } = useResponsiveScreen();
  const { backwardStep } = useAuthFlowManager();

  return (
    <>
      <Container
        margin={margin}
        onClick={async () => {
          onClick();
        }}
      >
        <Icon size={isMobile ? mobileIconSize : 1} style={style} />
        <Heading variant="SBH5" as="span" style={textStyle}>
          {text}
        </Heading>
      </Container>
    </>
  );
};

export default WalletOption;

const Container = styled.button`
  display: flex;
  gap: 1rem;
  width: 100%;
  background: white;
  padding: 16px;
  border-radius: 12px;
  border: 2px solid var(--color-indigo-30);
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  ${({ margin }) =>
    margin
      ? {
          margin,
        }
      : null};

  &:hover {
    background-color: var(--color-indigo-500);
    opacity: 1;
    span {
      color: white;
    }
  }

  @media (max-width: 769px) {
    padding: 0.625rem 1.25rem;
    border-radius: 999px;
    gap: 0.75rem;
    border: 1px solid var(--color-indigo-50);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
`;
