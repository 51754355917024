import FAQKeys from '../translationKeys/FAQPageKeys';

export const FAQTranslations = {
  // General
  [FAQKeys.General.title]: '一般',

  [FAQKeys.General.Q1.title]: 'MegaEvolutionの始め方は？',
  [FAQKeys.General.Q1.description]:
    'MegaEvolutionでインフルエンサーマーケティングの旅を始めるには、単にデスクトップまたはモバイルブラウザからmegaevolution.ioのウェブサイトを訪れてください。 「ログイン」ボタンをクリックした後、個人のメールアドレス、Google、Facebook、またはMetaMaskやCoinbase Walletを使用してサインアップできます。 利用規約に同意し、必要に応じてメールアドレスを確認してください。 登録後、インフルエンサーと簡単に接続し、彼らのリーチを利用して製品やサービスをより広い観客に宣伝できます。 当社のプラットフォームには、クリエーターのチャンネル/オーディエンス分析やオールインワンの注文および支払いシステムなどの高度な機能が備わっており、ビジネスが効果的なインフルエンサーキャンペーンを開始するのが簡単です。',

  [FAQKeys.General.Q2.title]: 'MegaEvolutionとは何ですか？',
  [FAQKeys.General.Q2.description]:
    'MegaEvolutionは、クライアントとグローバルなクリエーターをつなぐインフルエンサーマーケティングプラットフォームです。 クライアントは簡単にマーケティングパッケージを見つけて注文できます。一方、クリエーターは自分自身を宣伝し、サービスを通じてより多くの機会と利益を得ることができます。 MegaEvolutionは、注文から広告の管理までの全プロセスを効率化し、すべてのユーザーにとって簡単で便利な体験を提供します。',

  [FAQKeys.General.Q3.title]:
    'MegaEvolutionはクリエーターに何を提供していますか？',
  [FAQKeys.General.Q3.description]:
    'MegaEvolutionは、世界中のブランドクライアントとクリエーターを接続できるようにします。 MegaEvolutionを通じて自分のサービスを宣伝することで、クリエーターは簡単にクライアントから注文を受け取ることができます。また、クリエーターが機会と利益を最大化するために注文と支払いの全プロセスをサポートします。',

  [FAQKeys.General.Q4.title]:
    'MegaEvolutionはクライアントに何を提供していますか？',
  [FAQKeys.General.Q4.description]:
    'MegaEvolutionは、多様なクリエーターとクライアントをつなぎ、チャンネルの分析やマーケティング価格などの情報を提供します。クライアントはクリエーターから簡単にマーケティングパッケージを注文でき、注文と支払いの全プロセスを安全かつ便利にサポートします。',

  [FAQKeys.General.Q5.title]:
    'EVOトークンとは何ですか？どのように機能しますか？',
  [FAQKeys.General.Q5.description]:
    'EVOトークンはクリエーターパッケージ価格へのアクセスを提供し、イベントや投票、ガバナンスにも利用される可能性があります。私たちはさまざまな取引所に上場する予定であり、他のコインや現金に交換できるようにする予定です。',

  // Package System
  [FAQKeys.Package_System.title]: 'パッケージシステム',

  [FAQKeys.Package_System.Q1.title]: 'MegaEvolutionのパッケージとは何ですか？',
  [FAQKeys.Package_System.Q1.description]:
    'MegaEvolutionのパッケージは、クリエーターが提供するサービスを展示するためのシステムです。コンテンツ、期間、価格、およびクライアントに提供される方法の詳細な説明などが含まれます。ユーザーはこれらのパッケージをいくつかのクリックで簡単に購入し、ダッシュボードを介してすべての注文を管理できます。また、ユーザーはパッケージ注文の状態に関する通知と分析情報も受け取ります。',

  [FAQKeys.Package_System.Q2.title]:
    'パッケージの価格設定にはどのような種類がありますか？',
  [FAQKeys.Package_System.Q2.description]:
    'パッケージには、「オープン価格」「ロック解除価格」「クリエイターに連絡」の3つの価格オプションがあります。クリエーターは各パッケージプランに異なる価格オプションを設定できます。例えば、スタンダードプランはオープン価格とし、プレミアムプランはロック解除価格とすることができます。',

  [FAQKeys.Package_System.Q3.title]:
    '価格解除システムはどのように動作しますか？解除された価格はどれくらい表示されますか？',
  [FAQKeys.Package_System.Q3.description]:
    '全てのユーザーは、毎月一定回数までパッケージの価格を無料で解除できます。クリエーターのパッケージの価格を解除するには、ロックされた価格をクリックするだけです。解除されると、そのクリエーターのすべての価格を表示できます。解除された価格は3日間表示されます。',

  // Creators
  [FAQKeys.Creators.title]: 'クリエーター',

  [FAQKeys.Creators.Q1.title]:
    'クリエーター向けのサービス料金について知りたいです。',
  [FAQKeys.Creators.Q1.description]:
    '現在、クリエーターには各広告注文に対して料金はかかりません。',

  [FAQKeys.Creators.Q2.title]:
    '残高を引き出すのにどれくらい時間がかかりますか？',
  [FAQKeys.Creators.Q2.description]:
    '通常、PayPalを通じて1~3営業日かかります。',

  [FAQKeys.Creators.Q3.title]:
    'クリエーターとして登録後、全てのソーシャルネットワークアカウントを解除した場合、アカウントは自動的に削除されますか？',
  [FAQKeys.Creators.Q3.description]:
    'アカウントは自動的に削除されることはありません。ただし、注文を受け取ることはできなくなりますし、検索ウィンドウにも表示されません。',

  [FAQKeys.Creators.Q4.title]:
    'クライアントは、私が注文を受けた後に注文をキャンセルできますか？',
  [FAQKeys.Creators.Q4.description]:
    '通常、クリエーターが注文を受けた後、キャンセルはクリエーターが同意した場合にのみ可能です。ただし、納期を守らなかったり、品質が不満足だったりする場合は、リゾリューションセンターを通じて注文をキャンセルすることができます。注文をキャンセルすると、アカウントに対してネガティブな影響を及ぼす可能性があるため、注意してください。',

  // Orders Help
  [FAQKeys.Orders_Help.title]: '注文のヘルプ',

  [FAQKeys.Orders_Help.Q1.title]: '延長とキャンセルはどのように機能しますか？',
  [FAQKeys.Orders_Help.Q1.description]:
    '注文の期限を延長したり、キャンセルしたりするには、相手にリクエストを送信することができます。受け取ったリクエストを承諾または拒否することができます。数日間（延長の場合は3日間、キャンセルの場合は5日間）応答がない場合、リクエストは自動的に承諾されます。',

  [FAQKeys.Orders_Help.Q2.title]:
    'クリエイターが注文を受けた後、キャンセルすることはできますか？',
  [FAQKeys.Orders_Help.Q2.description]:
    '通常、クライアントが注文を受けた後、キャンセルはクリエイターが同意した場合にのみ可能です。ただし、納期を守らなかったり、品質が不満足だったりする場合は、リゾリューションセンターを通じて注文をキャンセルすることができます。',

  [FAQKeys.Orders_Help.Q3.title]:
    'コンテンツを受け取らなかった場合、どうすればよいですか？',
  [FAQKeys.Orders_Help.Q3.description]:
    'クリエイターは各注文されたコンテンツのリンクを送信することが義務付けられています。納期までにコンテンツを受け取っていない場合は、キャンセルをリクエストして返金を受けることができます。',

  // Payment Help
  [FAQKeys.Payment_Help.title]: '支払いに関するヘルプ',

  [FAQKeys.Payment_Help.Q1.title]: '支払い履歴を確認する方法は？',
  [FAQKeys.Payment_Help.Q1
    .description]: `1. アカウントにログインし、ウェブサイトの右上隅にある「MySpace」をクリックします。次に、「Billing」ボタンをクリックします。
2. 「Payment History」タブを使用してトランザクションを表示できます。PDFで請求書をダウンロードすることもできます。`,

  [FAQKeys.Payment_Help.Q2.title]: '支払い方法はどうすればよいですか？',
  [FAQKeys.Payment_Help.Q2
    .description]: `1. ページの右上にある「Myspace」に移動します。次に、「Billing」に移動します。
2. 「Payment Method」タブで「Credit & Debit Card」または「PayPal Account」を追加します。
3. その後、追加した情報を支払いに使用できます。`,

  [FAQKeys.Payment_Help.Q3.title]:
    'MegaEvolutionはクライアントへの返金をどのように行いますか？',
  [FAQKeys.Payment_Help.Q3.description]:
    'クリエイターの注文の場合、キャンセル時には全額返金されます。既に公開されているコンテンツがある場合は、返金前に比例控除が行われます。月額自動請求のプラットフォームサブスクリプションに対しては返金は行われません。',

  [FAQKeys.Payment_Help.Q4.title]:
    'MegaEvolutionのサービス料金とは何ですか？どのように機能しますか？',
  [FAQKeys.Payment_Help.Q4.description]:
    'MegaEvolutionは、各パッケージ注文に対して5.5％ + 3ドルのサービス料金を請求します。業界で最も競争力のあるサービス料金の一つを提供しています。この小さな料金は、プラットフォームの運営と各注文プロセスのサポートに役立ちます。サブスクリプションユーザーの場合、サービス料金に割引が適用されます。',

  [FAQKeys.Payment_Help.Q5.title]:
    'どのようなサブスクリプションプランが利用可能ですか？',
  [FAQKeys.Payment_Help.Q5.description]:
    '月額サブスクリプションプランは、Basic（無料）、Standard、およびPremiumの3つのタイプを提供しています。Basicはほとんどのプラットフォーム機能にアクセスできます。StandardとPremiumには、価格解除の回数増加、分析、一括メッセージングなどの追加機能が含まれています。有料プランでは、注文のサービス料金に対するわずかな割引も提供されます。',

  // Account Help
  [FAQKeys.Account_Help.title]: 'アカウントのヘルプ',

  [FAQKeys.Account_Help.Q1
    .title]: `ログイン情報（IDまたはパスワード）を忘れた場合はどうすればよいですか？`,
  [FAQKeys.Account_Help.Q1.description]:
    'MegaEvolutionでは、メールアドレス、Google、Facebook、またはウォレット（MetaMaskまたはCoinbase Wallet）を使用してアカウントを作成できます。メールアカウントのパスワードを忘れた場合は、ログインページに移動し、「パスワードをお忘れですか？」をクリックしてください。次に、メールアドレスを入力すると、パスワードをリセットするためのリンクが記載されたメールが届きます。',

  [FAQKeys.Account_Help.Q2.title]: 'パスワードを変更する方法は？',
  [FAQKeys.Account_Help.Q2
    .description]: `1. ログイン後、ページの右上隅にある「MySpace」に移動します。
2. 「Settings」をクリックし、「Account」セクションで新しいパスワードを更新します。`,

  [FAQKeys.Account_Help.Q3.title]: 'アカウントを無効化または削除する方法は？',
  [FAQKeys.Account_Help.Q3
    .description]: `1. ログイン後、ページの右上隅にある「MySpace」に移動します。
2. 「Settings」をクリックし、「Deactivate/Delete」セクションまでスクロールします。
3. 「Deactivate」または「Delete」ボタンをクリックします。（アカウントを削除するとすべてのデータが失われ、復元できなくなります。）`,

  // Safety & Privacy
  [FAQKeys.Safety_Privacy.title]: 'セキュリティとプライバシー',

  [FAQKeys.Safety_Privacy.Q1.title]: '情報はどのように保護されていますか？',
  [FAQKeys.Safety_Privacy.Q1.description]:
    'MegaEvolutionでは、プライバシーとセキュリティを非常に重視しています。個人情報が不正アクセスや誤用から保護されるように、業界標準の対策を講じています。プライバシー保護への取り組みについて詳細を知りたい場合は、プライバシーポリシーを参照してください。',

  [FAQKeys.Safety_Privacy.Q2.title]:
    'MegaEvolutionはどのようなデータを保持していますか？',
  [FAQKeys.Safety_Privacy.Q2.description]:
    'MegaEvolutionは、ユーザーの注文履歴、支払情報、ユーザーデータ、プロファイル、パッケージ情報、テクニカルログ情報など、さまざまな重要なデータを保管しています。',

  [FAQKeys.Safety_Privacy.Q3.title]: 'アカウントを無効化または削除する方法は？',
  [FAQKeys.Safety_Privacy.Q3
    .description]: `1. ログイン後、ページの右上隅にある「MySpace」に移動します。
2. 「Settings」をクリックし、「Deactivate/Delete」セクションまでスクロールします。
3. 「Deactivate」または「Delete」ボタンをクリックします。（アカウントを削除するとすべてのデータが失われ、復元できなくなります。）`,
};
