import styled from 'styled-components';
import BorderedCard from '../Cards/BorderedCard';
import Heading from '../Typography/Heading';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import IndigoButton from '../Buttons/IndigoButton';
import { forwardRef } from 'react';
import device from 'styles/device';
const BannerCard = ({
  onClose,
  children,
  image,
  imageHeight = '100%',
  description,
  buttonText,
  primaryButtonIndigoAndOnRight = false,
  onButtonClick,
  secondaryButtonText,
  onSecondaryButtonClick,
  outsideAlertRef,
  haveClickAway = true,
}) => {
  const { isMobile } = useResponsiveScreen();

  return (
    <BorderedCard
      as="div"
      color="purple"
      borderWidth="0px"
      shadow="var(--shadow-modal)"
      className="position-relative d-flex flex-column align-items-center justify-content-center"
      radius="30px"
      padding="0"
      width={isMobile ? '328px' : '400px'}
      height="auto"
      ref={haveClickAway ? outsideAlertRef : null}
    >
      <Layout isMobile={isMobile}>
        {!children ? (
          <ImageContainer isMobile={isMobile}>
            <img src={image} alt="" style={{ height: imageHeight }} />
          </ImageContainer>
        ) : (
          <></>
        )}

        {!children && description && (
          <Heading
            variant={`${isMobile ? 'BH4' : 'BH3'}`}
            className="text-center line-height-1.7"
          >
            {description}
          </Heading>
        )}

        {!children && buttonText && onButtonClick && (
          <Buttons
            primaryButtonIndigoAndOnRight={primaryButtonIndigoAndOnRight}
          >
            {!primaryButtonIndigoAndOnRight && (
              <MainButton
                className={isMobile ? 'bh5 shadow-yellow-btn1' : 'sbh5'}
                padding="9px 22px"
                radius="20px"
                onClick={onButtonClick}
              >
                {buttonText}
              </MainButton>
            )}

            {!!secondaryButtonText && (
              <TextButton
                onClick={onSecondaryButtonClick || onClose}
                className={
                  'text-decoration-underline ' + (isMobile ? 'sbh5' : 'sbh5')
                }
              >
                {secondaryButtonText || 'Skip'}
              </TextButton>
            )}

            {primaryButtonIndigoAndOnRight && (
              <MainButton
                radius="100px"
                padding="9px 22px"
                className={isMobile ? 'bh5 shadow-yellow-btn1' : 'sbh5'}
                onClick={onButtonClick}
              >
                {buttonText}
              </MainButton>
            )}
          </Buttons>
        )}
        {children}
      </Layout>
    </BorderedCard>
  );
};

export default BannerCard;

const Layout = styled.div`
  padding: 50px 1.25rem 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  @media ${device.md} {
    padding: 2.7rem 2rem;
    gap: 1.875rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${({ isMobile }) => (isMobile ? 150 : 222)}px;
  /* margin-bottom: 0.625rem; */
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem 1.875rem;
  flex-wrap: ${({ primaryButtonIndigoAndOnRight }) =>
    primaryButtonIndigoAndOnRight ? 'wrap-reverse' : 'wrap'};
  justify-content: center;

  @media ${device.sm} {
    gap: 1.25rem 1.5rem;
  }
`;

const MainButton = styled(IndigoButton)`
  min-width: 136px;

  @media ${device.md} {
    min-width: 64px;
  }
`;

const TextButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;
