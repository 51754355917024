import AxiosClient from 'components/utilities/AxiosClient';

const getPackage = async ({ userId, handle, platform, sendTranslations }) => {
  const res = await AxiosClient.get('getPackages', {
    params: {
      userId,
      handle,
      platform,
      sendTranslations,
    },
  });

  return res.data;
};

export default getPackage;
