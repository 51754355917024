export const validateEmail = (email) => {
  const regex = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/, 'g');
  return regex.test(email);
};

export const validatePassword = (password) => {
  var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[\d#?!@$%^&*-])[A-Za-z\d#?!@$%^&*-]{8,20}$/
;
  return re.test(password);
};
