import styled from 'styled-components';
import MenuList from './MenuList';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';

const Menus = ({ className }) => {
  const { t } = useTranslation();

  return (
    <Wrapper className={className}>
      <MenuList
        title={t(GeneralKeys.marketing)}
        items={[
          { text: t(GeneralKeys.creator_marketing), href: '/' },
          { text: t(GeneralKeys.coming_soon), href: '' },
        ]}
        width="200px"
      />

      <MenuList
        title={t(GeneralKeys.support)}
        items={[
          {
            text: t(GeneralKeys.help_center),
            href: '/help-center',
          },
          { text: t(GeneralKeys.creator_faq), href: '/help-center' },
          { text: t(GeneralKeys.company_faq), href: '/help-center' },
        ]}
        width="200px"
      />

      <MenuList
        title={t(GeneralKeys.partnerships)}
        items={[
          { text: t(GeneralKeys.evo_tokens), href: '/evo-points' },
          // {
          //   text: t(AboutKeys.Part4.title),
          //   href: '/about',
          //   state: {
          //     scroll: 'clients',
          //   },
          // },
          { text: t(GeneralKeys.coming_soon), href: '' },
        ]}
        width="180px"
      />

      <MenuList
        title={t(GeneralKeys.information)}
        items={[
          {
            text: t(LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions),
            href: '/terms-and-conditions',
          },
          {
            text: t(LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies),
            href: 'privacy-policy',
          },
          {
            text: t(GeneralKeys.website_credits),
            href: 'https://docs.google.com/document/d/1BEHmicFi3o75-42ZNCgVxp3MrBQZvGHvLGOnHyUpoQs/edit?usp=sharing',
            target: '_blank',
          },
        ]}
        width="160px"
      />
    </Wrapper>
  );
};

export default Menus;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 3.125rem;
`;
