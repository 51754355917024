import { FastMessageIcon } from 'assets/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MessageKeys from 'translations/translationKeys/MessageKeys';

const NoMessages = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <FastMessageIcon size={0.867} />
      {t(MessageKeys.Dropdown.no_message)}
    </Wrapper>
  );
};

export default NoMessages;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.9 },
  transition: { duration: 0.2 },
  className: 'sbh5.5',
})`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--color-indigo-100);
`;
