export const MessagesIcon = ({ size = 1 }) => (
  <svg
    width={60 * size}
    height={61 * size}
    viewBox="0 0 60 61"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8574 18.7143C12.8574 16.9391 14.2965 15.5 16.0717 15.5H56.786C58.5612 15.5 60.0003 16.9391 60.0003 18.7143V57.2857C60.0003 58.541 59.2695 59.6814 58.1291 60.2059C56.9887 60.7305 55.6472 60.5431 54.6942 59.7262L40.597 47.6429H16.0717C14.2965 47.6429 12.8574 46.2038 12.8574 44.4286V18.7143ZM19.286 21.9286V41.2143H41.786C42.5533 41.2143 43.2953 41.4888 43.8778 41.9881L53.5717 50.2971V21.9286H19.286Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3.71429C0 1.93908 1.43908 0.5 3.21429 0.5H46.0714C47.8466 0.5 49.2857 1.93908 49.2857 3.71429V16.5714C49.2857 18.3466 47.8466 19.7857 46.0714 19.7857C44.2962 19.7857 42.8571 18.3466 42.8571 16.5714V6.92857H6.42857V32.3829L13.7986 25.0129C15.0538 23.7576 17.089 23.7576 18.3443 25.0129C19.5995 26.2681 19.5995 28.3033 18.3443 29.5586L5.48713 42.4157C4.56785 43.335 3.18533 43.61 1.98423 43.1125C0.783136 42.615 0 41.4429 0 40.1429V3.71429Z"
    />
  </svg>
);
