export const DateChangeIndicatorIcon = ({
  size = 1,
  color = '#0E0D39',
  style,
}) => {
  return (
    <svg
      width={size * 16}
      height={size * 22}
      viewBox="0 0 16 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6.58648 9.8698C6.77155 10.0646 6.99175 10.2193 7.23435 10.3248C7.47696 10.4303 7.73718 10.4846 8 10.4846C8.26282 10.4846 8.52304 10.4303 8.76564 10.3248C9.00825 10.2193 9.22844 10.0646 9.41352 9.8698L15.3861 3.63405C15.577 3.44025 15.7294 3.2091 15.8347 2.95379C15.94 2.69848 15.9961 2.42402 15.9998 2.14608C16.0035 1.86814 15.9547 1.59216 15.8563 1.3339C15.7578 1.07564 15.6116 0.840157 15.426 0.640894C15.2403 0.441631 15.0189 0.282491 14.7744 0.172562C14.5299 0.062632 14.267 0.00406468 14.0008 0.000204393C13.7346 -0.00365589 13.4702 0.0472658 13.2229 0.150063C12.9755 0.25286 12.75 0.405518 12.5591 0.599322L8 5.46321L3.44089 0.599322C3.05544 0.207917 2.53686 -0.00759181 1.99922 0.000204393C1.46158 0.00800059 0.948923 0.238464 0.574034 0.640894C0.199145 1.04332 -0.00727146 1.58476 0.000195767 2.14608C0.007663 2.70741 0.228401 3.24265 0.613851 3.63405L6.58648 9.8698ZM12.5591 12.1147L8 16.8954L3.44089 12.1147C3.05544 11.7233 2.53686 11.5078 1.99922 11.5156C1.46158 11.5234 0.948923 11.7538 0.574034 12.1562C0.199145 12.5587 -0.00727146 13.1001 0.000195767 13.6614C0.007663 14.2228 0.228401 14.758 0.613851 15.1494L6.58648 21.3852C6.77155 21.58 6.99175 21.7346 7.23435 21.8401C7.47696 21.9457 7.73718 22 8 22C8.26282 22 8.52304 21.9457 8.76564 21.8401C9.00825 21.7346 9.22844 21.58 9.41352 21.3852L15.3861 15.1494C15.761 14.758 15.9716 14.2271 15.9716 13.6736C15.9716 13.1201 15.761 12.5892 15.3861 12.1978C15.0113 11.8064 14.5028 11.5865 13.9726 11.5865C13.4425 11.5865 12.934 11.8064 12.5591 12.1978V12.1147Z"
        fill={color}
      />
    </svg>
  );
};
