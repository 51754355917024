export const DownloadLightRoundIcon = ({ size = 1 }) => (
  <svg
    width={size * 14}
    height={size * 14}
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.697 5.80445L6.3 7.41485V0.700175C6.3 0.514478 6.37375 0.336385 6.50503 0.205077C6.6363 0.0737691 6.81435 1.62981e-09 7 1.62981e-09C7.18565 1.62981e-09 7.3637 0.0737691 7.49497 0.205077C7.62625 0.336385 7.7 0.514478 7.7 0.700175V7.41485L9.303 5.80445C9.36807 5.73882 9.44549 5.68673 9.5308 5.65119C9.6161 5.61564 9.70759 5.59734 9.8 5.59734C9.89241 5.59734 9.9839 5.61564 10.0692 5.65119C10.1545 5.68673 10.2319 5.73882 10.297 5.80445C10.3626 5.86954 10.4147 5.94698 10.4502 6.0323C10.4858 6.11762 10.5041 6.20914 10.5041 6.30157C10.5041 6.394 10.4858 6.48552 10.4502 6.57084C10.4147 6.65617 10.3626 6.73361 10.297 6.7987L7.497 9.59939C7.43043 9.66314 7.35193 9.71311 7.266 9.74643C7.09558 9.81646 6.90442 9.81646 6.734 9.74643C6.64807 9.71311 6.56957 9.66314 6.503 9.59939L3.703 6.7987C3.63773 6.73341 3.58596 6.65591 3.55064 6.57061C3.51532 6.48532 3.49714 6.3939 3.49714 6.30157C3.49714 6.20925 3.51532 6.11783 3.55064 6.03253C3.58596 5.94723 3.63773 5.86973 3.703 5.80445C3.76827 5.73916 3.84575 5.68738 3.93103 5.65205C4.0163 5.61672 4.1077 5.59853 4.2 5.59853C4.2923 5.59853 4.3837 5.61672 4.46897 5.65205C4.55425 5.68738 4.63173 5.73916 4.697 5.80445ZM13.3 6.99825C13.1143 6.99825 12.9363 7.07202 12.805 7.20333C12.6737 7.33464 12.6 7.51273 12.6 7.69843V11.8995C12.6 12.0852 12.5263 12.2633 12.395 12.3946C12.2637 12.5259 12.0857 12.5997 11.9 12.5997H2.1C1.91435 12.5997 1.7363 12.5259 1.60503 12.3946C1.47375 12.2633 1.4 12.0852 1.4 11.8995V7.69843C1.4 7.51273 1.32625 7.33464 1.19497 7.20333C1.0637 7.07202 0.885652 6.99825 0.7 6.99825C0.514348 6.99825 0.336301 7.07202 0.205025 7.20333C0.0737498 7.33464 0 7.51273 0 7.69843V11.8995C0 12.4566 0.221249 12.9908 0.615076 13.3848C1.0089 13.7787 1.54305 14 2.1 14H11.9C12.457 14 12.9911 13.7787 13.3849 13.3848C13.7788 12.9908 14 12.4566 14 11.8995V7.69843C14 7.51273 13.9263 7.33464 13.795 7.20333C13.6637 7.07202 13.4857 6.99825 13.3 6.99825Z" />
  </svg>
);
