export const MasterCardIcon = ({ size = 1 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size * 40}
      height={size * 26}
      viewBox="0 0 40 26"
      fill="none"
    >
      <path
        d="M25.4104 3.28027H14.5942V22.7173H25.4104V3.28027Z"
        fill="#FF5F00"
      />
      <path
        d="M15.279 13.0001C15.2773 11.1281 15.7015 9.28036 16.5195 7.59661C17.3375 5.91285 18.5278 4.43726 20.0004 3.28154C18.1768 1.84818 15.9868 0.956798 13.6806 0.70927C11.3744 0.461743 9.04503 0.868061 6.95883 1.88178C4.87263 2.8955 3.11372 4.47573 1.88315 6.44183C0.65258 8.40794 0 10.6806 0 13.0001C0 15.3195 0.65258 17.5922 1.88315 19.5583C3.11372 21.5244 4.87263 23.1046 6.95883 24.1183C9.04503 25.1321 11.3744 25.5384 13.6806 25.2909C15.9868 25.0433 18.1768 24.1519 20.0004 22.7186C18.5278 21.5629 17.3375 20.0873 16.5195 18.4035C15.7015 16.7198 15.2774 14.872 15.279 13.0001Z"
        fill="#EB001B"
      />
      <path
        d="M40 13.0001C40.0001 15.3195 39.3476 17.5921 38.1171 19.5583C36.8866 21.5244 35.1277 23.1046 33.0416 24.1183C30.9554 25.1321 28.6261 25.5384 26.3199 25.2909C24.0138 25.0433 21.8238 24.1519 20.0002 22.7186C21.4715 21.5617 22.6609 20.0859 23.4787 18.4024C24.2966 16.7189 24.7215 14.8717 24.7215 13.0001C24.7215 11.1284 24.2966 9.28123 23.4787 7.59775C22.6609 5.91426 21.4715 4.43842 20.0002 3.28154C21.8238 1.84818 24.0138 0.956793 26.3199 0.709268C28.6261 0.461744 30.9554 0.86807 33.0416 1.8818C35.1277 2.89553 36.8866 4.47576 38.1171 6.44187C39.3476 8.40798 40.0001 10.6806 40 13.0001Z"
        fill="#F79E1B"
      />
      <path
        d="M38.8202 20.659V20.2611H38.9807V20.18H38.572V20.2611H38.7325V20.659H38.8202ZM39.6136 20.659V20.1792H39.4883L39.3442 20.5092L39.2001 20.1792H39.0748V20.659H39.1632V20.2971L39.2984 20.6091H39.3901L39.5252 20.2963V20.659H39.6136Z"
        fill="#F79E1B"
      />
    </svg>
  );
};
