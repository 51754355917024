import useUser from 'hooks/useUser';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const PlatformFilterContext = createContext();

const platforms = [
  {
    label: GeneralKeys.youtube,
    value: 'youtube',
  },
  {
    label: GeneralKeys.instagram,
    value: 'instagram',
  },
  {
    label: GeneralKeys.twitter,
    value: 'twitter',
  },
  {
    label: GeneralKeys.tiktok,
    value: 'tiktok',
  },
];

const PLATFORM_CHANGE_EVENT = 'platformChange';
const platformChangeEvent = new Event(PLATFORM_CHANGE_EVENT);

const PlatformFilterProvider = ({ children }) => {
  const { isLoggedIn } = useUser();
  const localStorageValue = localStorage.getItem('platform');

  const [selectedPlatform, setSelectedPlatform] = useState(
    isLoggedIn
      ? localStorageValue
        ? platforms.find((platform) => platform.value === localStorageValue)
        : platforms[0]
      : platforms[0]
  );

  const selectPlatform = useCallback(
    (platform) => {
      setSelectedPlatform(platform);
      window.dispatchEvent(platformChangeEvent);

      if (isLoggedIn) {
        localStorage.setItem('platform', platform.value);
      }
    },
    [isLoggedIn]
  );

  const resetPlatform = useCallback(() => {
    setSelectedPlatform(platforms[0]);
  }, []);

  const onPlatformChange = useCallback((callback) => {
    window.addEventListener(PLATFORM_CHANGE_EVENT, callback);

    return () => {
      window.removeEventListener(PLATFORM_CHANGE_EVENT, callback);
    };
  }, []);

  const data = useMemo(
    () => ({
      platforms,
      selectedPlatform,
      selectPlatform,
      resetPlatform,
      onPlatformChange,
    }),
    [selectedPlatform, selectPlatform, resetPlatform, onPlatformChange]
  );

  return (
    <PlatformFilterContext.Provider value={data}>
      {children}
    </PlatformFilterContext.Provider>
  );
};

const usePlatformFilter = () => {
  return useContext(PlatformFilterContext);
};

export { PlatformFilterProvider, usePlatformFilter };
