import GuideKeys from '../translationKeys/GuidePageKeys';

export const GuideTranslations = {
  [GuideKeys.click_to_join]: 'Click to join',

  // Portfolio Banner
  [GuideKeys.Portfolio_Banner.description]: `
    Create your own portfolio <br>
    page with a unique URL!`,
  [GuideKeys.Portfolio_Banner.view_sample]: 'View sample',

  // Creator Invitation
  [GuideKeys.Creator_Invitation.hi_ginseng_mega]: "Hi, i'm MEGA!",
  [GuideKeys.Creator_Invitation.nice_to_meet_you]: 'Nice to meet you~!',
  [GuideKeys.Creator_Invitation.we_help_you_receive]:
    'We help you receive proposals',
  [GuideKeys.Creator_Invitation.proposal_golbal_brands]: 'from global brands!',
  [GuideKeys.Creator_Invitation.let_companies_directly]: 'Let companies',
  [GuideKeys.Creator_Invitation.order_your_package]:
    'directly order your package!',
  [GuideKeys.Creator_Invitation.click_to_join]: 'Click here',
  [GuideKeys.Creator_Invitation.anytime]: 'to join anytime!',
  [GuideKeys.Creator_Invitation.package_featured]:
    'Your package will be featured to companies',
  [GuideKeys.Creator_Invitation.showcase_channel]:
    'from 128+ countries! Showcase your channel',
  [GuideKeys.Creator_Invitation.showcase_channel_2]:
    'and connect with global clients!',
  [GuideKeys.Creator_Invitation.order_package]: 'Order Package',

  [GuideKeys.Creator_Invitation.Mobile.we_help_you_receive]:
    'We help you receive',
  [GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands]:
    'proposals from global brands!',
  [GuideKeys.Creator_Invitation.Mobile.let_companies_directly]:
    'Let companies directly',
  [GuideKeys.Creator_Invitation.Mobile.order_your_package]:
    'order your package!',
  [GuideKeys.Creator_Invitation.Mobile.click_to_join]: 'Click to join',
  [GuideKeys.Creator_Invitation.Mobile.anytime]: 'anytime!',
};
