import GuideKeys from '../translationKeys/GuidePageKeys';

export const GuideTranslations = {
  // Portfolio Banner
  [GuideKeys.Portfolio_Banner.description]: `
    자신만의 특별한 URL로 <br>
    멋진 페이지를 만들어보세요!`,
  [GuideKeys.Portfolio_Banner.view_sample]: '샘플 보기',

  // Creator Invitation
  [GuideKeys.Creator_Invitation.hi_ginseng_mega]: '안녕하세요, MEGA 입니다!',
  [GuideKeys.Creator_Invitation.nice_to_meet_you]: '만나서 반갑습니다~!',
  [GuideKeys.Creator_Invitation.we_help_you_receive]:
    '메세지를 통해 글로벌 기업들의',
  [GuideKeys.Creator_Invitation.proposal_golbal_brands]:
    '제안서를 받을 수 있습니다!',
  [GuideKeys.Creator_Invitation.let_companies_directly]:
    '고객들은 이 곳을 통해 여러분의',
  [GuideKeys.Creator_Invitation.order_your_package]:
    '패키지를 주문할 수 있습니다!',
  [GuideKeys.Creator_Invitation.click_to_join]: '크리에이터로 등록하려면',
  [GuideKeys.Creator_Invitation.anytime]: '여기를 클릭하세요!',
  [GuideKeys.Creator_Invitation.package_featured]:
    '전세계 128 개국+ 기업들에게 여러분의',
  [GuideKeys.Creator_Invitation.showcase_channel]:
    '채널을 쇼케이스 하세요! 무료로 손쉽게',
  [GuideKeys.Creator_Invitation.showcase_channel_2]:
    '프로필을 만들고 글로벌 브랜드와 Connect!',
  [GuideKeys.Creator_Invitation.order_package]: '패키지 주문하기',

  // Mobile
  [GuideKeys.Creator_Invitation.Mobile.we_help_you_receive]:
    '메세지를 통해 글로벌 기업들의',
  [GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands]:
    '제안서를 받을 수 있습니다!',
  [GuideKeys.Creator_Invitation.Mobile.let_companies_directly]:
    '고객들은 이 곳을 통해 여러분의',
  [GuideKeys.Creator_Invitation.Mobile.order_your_package]:
    '패키지를 주문할 수 있습니다!',
  [GuideKeys.Creator_Invitation.Mobile.click_to_join]:
    '크리에이터로 틍록하려면',
  [GuideKeys.Creator_Invitation.Mobile.anytime]: '여기를 클릭하세요!',
};
