import NotFoundKeys from '../translationKeys/NotFoundPageKeys';

export const NotFoundTranslations = {
  [NotFoundKeys.title]: '404：页面未找到',
  [NotFoundKeys.description]: '很抱歉，您所查找的页面不存在。',

  // Server Updating Page
  [NotFoundKeys.Server_Updating.title]: '服务器正在更新中',
  [NotFoundKeys.Server_Updating.description]: '请稍后再回来！',

  // Under Development Page
  [NotFoundKeys.Under_Development.title]: '目前正在开发中',
  [NotFoundKeys.Under_Development.description]: '欢迎来到 Web 3.0 市场营销！',
};
