import styled from 'styled-components';
import { useToolbar } from '.';
import Tool from './Tool';
import { useCallback, useMemo } from 'react';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

const ToolButton = ({ name, className, children }) => {
  const [editor] = useLexicalComposerContext();
  const { appliedStyles } = useToolbar();

  const isActive = useMemo(
    () => appliedStyles.includes(name),
    [appliedStyles, name]
  );

  const handleUnorderedList = useCallback(() => {
    if (isActive) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    } else {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    }
  }, [isActive, editor]);

  const handleOrderedList = useCallback(() => {
    if (isActive) {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    } else {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    }
  }, [isActive, editor]);

  const handleButtonClick = useCallback(() => {
    if (
      name === Tool.BOLD ||
      name === Tool.ITALIC ||
      name === Tool.UNDERLINE ||
      name === Tool.HIGHLIGHT
    ) {
      return editor.dispatchCommand(FORMAT_TEXT_COMMAND, name);
    }

    if (name === Tool.UNORDERED_LIST) {
      return handleUnorderedList();
    }

    if (name === Tool.ORDERED_LIST) {
      return handleOrderedList();
    }
  }, [name, editor, handleUnorderedList, handleOrderedList]);

  return (
    <Button
      disabled={!editor.isEditable()}
      className={className + (isActive ? ' active' : '')}
      onClick={handleButtonClick}
    >
      {children}
    </Button>
  );
};

export default ToolButton;

const Button = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: 0;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
`;
