export const InvisibilityIcon = ({ size = 1, color = '#CFCFD7' }) => {
  return (
    <svg
      width={size * 18}
      height={size * 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3545 9.49081L11.1682 8.30445C11.2909 7.66354 11.1068 7.06354 10.6159 6.50445C10.125 5.94536 9.49091 5.72717 8.71364 5.8499L7.52727 4.66354C7.75909 4.55445 7.99418 4.47263 8.23255 4.41808C8.47145 4.36354 8.72727 4.33627 9 4.33627C10.0227 4.33627 10.8922 4.69408 11.6084 5.40972C12.324 6.1259 12.6818 6.99536 12.6818 8.01808C12.6818 8.29081 12.6545 8.54663 12.6 8.78554C12.5455 9.0239 12.4636 9.25899 12.3545 9.49081ZM14.9727 12.0681L13.7864 10.9226C14.3045 10.5272 14.7649 10.0941 15.1675 9.62335C15.5695 9.15317 15.9136 8.61808 16.2 8.01808C15.5182 6.64081 14.5396 5.54636 13.2644 4.73472C11.9896 3.92363 10.5682 3.51808 9 3.51808C8.60455 3.51808 8.21591 3.54536 7.83409 3.5999C7.45227 3.65445 7.07727 3.73627 6.70909 3.84536L5.44091 2.57717C6 2.34536 6.57273 2.17136 7.15909 2.05517C7.74545 1.93954 8.35909 1.88172 9 1.88172C11.0591 1.88172 12.8932 2.4509 14.5023 3.58927C16.1114 4.72817 17.2773 6.20445 18 8.01808C17.6864 8.82263 17.274 9.56936 16.7629 10.2583C16.2513 10.9466 15.6545 11.5499 14.9727 12.0681ZM15.3818 17.0999L11.9455 13.7044C11.4682 13.8544 10.9876 13.9671 10.5038 14.0424C10.0195 14.1171 9.51818 14.1544 9 14.1544C6.94091 14.1544 5.10682 13.5853 3.49773 12.4469C1.88864 11.308 0.722727 9.83172 0 8.01808C0.286364 7.29536 0.647727 6.62363 1.08409 6.0029C1.52045 5.38272 2.01818 4.82717 2.57727 4.33627L0.327273 2.04536L1.47273 0.899902L16.5273 15.9544L15.3818 17.0999ZM3.72273 5.48172C3.32727 5.83627 2.96591 6.2249 2.63864 6.64763C2.31136 7.07036 2.03182 7.52717 1.8 8.01808C2.48182 9.39536 3.46009 10.4895 4.73482 11.3006C6.01009 12.1123 7.43182 12.5181 9 12.5181C9.27273 12.5181 9.53864 12.5012 9.79773 12.4674C10.0568 12.433 10.3227 12.3954 10.5955 12.3544L9.85909 11.5772C9.70909 11.6181 9.56591 11.6486 9.42955 11.6688C9.29318 11.6895 9.15 11.6999 9 11.6999C7.97727 11.6999 7.10782 11.3421 6.39164 10.6264C5.676 9.91026 5.31818 9.04081 5.31818 8.01808C5.31818 7.86808 5.32827 7.7249 5.34845 7.58854C5.36918 7.45217 5.4 7.30899 5.44091 7.15899L3.72273 5.48172Z"
        fill={color}
      />
    </svg>
  );
};
