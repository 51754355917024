import AxiosClient from 'components/utilities/AxiosClient';

const removeImage = async (url, thumbnail) => {
  try {
    const res = await AxiosClient.delete('/admin/faq/upload', {
      data: {
        fileUrl: url,
        thumbnailUrl: thumbnail,
      },
    });
    return res;
  } catch (err) {}
};

export default removeImage;
