import LoginKeys from '../translationKeys/LoginPageKeys';

export const LoginTranslations = {
  // Google
  [LoginKeys.Google.sign_in]: '使用谷歌登录',

  // Facebook
  [LoginKeys.Facebook.sign_in]: '使用Facebook登录',
  [LoginKeys.Facebook.sign_up]: '使用Facebook注册',

  // Wallet
  [LoginKeys.Wallet.sign_in]: '使用钱包登录',
  [LoginKeys.Wallet.sign_up]: '使用钱包注册',

  // Password
  [LoginKeys.Password.forgot]: '忘记密码？',

  [LoginKeys.Password.Rule.title]: '您的密码必须包含以下内容：',
  [LoginKeys.Password.Rule.l1]: '· 至少8个字符',
  [LoginKeys.Password.Rule.l2]: '· 至少1个字母字符',
  [LoginKeys.Password.Rule.l3]: '· 至少1个数字',
  [LoginKeys.Password.Rule.l4]: '· 至少1个特殊字符（！，@，＃，$，...）',

  // Login Form
  [LoginKeys.Login_Form.Placeholder.email]: '输入电子邮件',
  [LoginKeys.Login_Form.Placeholder.password]: '输入密码',

  [LoginKeys.Login_Form.no_account]: '还没有账户？',
  [LoginKeys.Login_Form.create_account]: '创建一个账户',

  [LoginKeys.Login_Form.sign_up]: '注册',

  // Sign Up Form
  [LoginKeys.Sign_Up_Form.Placeholder.email]: '使用电子邮件注册',
  [LoginKeys.Sign_Up_Form.Placeholder.password]: '输入密码',

  [LoginKeys.Sign_Up_Form.Terms_of_Service.title]: '请同意服务条款',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all]: '同意所有条款',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description]:
    '这包括所有必需和可选条款的协议。您可以选择同意或不同意单个条款。即使您不同意任何可选条款，您仍然可以使用该服务。',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.age]: '我年满14岁或以上。',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions]: '条款与条件',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing]:
    '接收活动和营销信息',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing_description]:
    '您可以通过电子邮件和应用程序收到来自MegaEvolution的有用新闻通讯和各种信息，例如活动和福利。',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies]: '隐私政策',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies]: '支付政策',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.optional]: '（可选）',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.required]: '（必需）',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree]: '同意并加入',

  [LoginKeys.Sign_Up_Form.go_back]: '返回登录页',

  [LoginKeys.Sign_Up_Form.password_updated]: '您的密码已更新！',
  [LoginKeys.Sign_Up_Form.thank_you_for_signing_up]: '感谢您的注册！',

  // Error
  [LoginKeys.Error.network]: '网络错误 - 请稍后重试。',
  [LoginKeys.Error.incorrect]: '电子邮件地址或密码不正确。',
  [LoginKeys.Error.recently_deleted]: '此帐户最近已删除。请在24小时后再试。',
  [LoginKeys.Error.login_failed]: '登录失败。请稍后重试。',
  [LoginKeys.Error.already_in_use]: '此电子邮件已经在使用中。',
  [LoginKeys.Error.wallet_not_verified]: '钱包验证失败。',
  [LoginKeys.Error.already_in_use_or_connect_another_method]:
    '此电子邮件已经在使用中或与其他登录方法连接。',
  [LoginKeys.Error.currently_deactiated_reset_password]:
    '此帐户当前已停用。请重置密码以重新激活。',
  [LoginKeys.Error.currently_deactiated_login]:
    '此帐户当前已停用。请尝试登录以重新激活。',
  [LoginKeys.Error.enter_valid_email]: '请输入有效的电子邮件地址。',
  [LoginKeys.Error.invalid_request]: '无效的请求',
  [LoginKeys.Error.too_many_request]: '请求过多。请稍后重试。',
  [LoginKeys.Error.wallet_already_registered]: '此钱包已经注册。',
  [LoginKeys.Error.try_sign_in_social_app]: '请尝试使用社交应用程序登录。',
  [LoginKeys.Error.password_used]: '此密码先前已使用。',

  // Modal
  [LoginKeys.Modal.Connect_Wallet.title]: '连接钱包',
  [LoginKeys.Modal.Connect_Wallet.description]:
    '选择一个可用的钱包提供商或先创建一个钱包。',
  [LoginKeys.Modal.Reset_Password.reset]: '重置密码',

  [LoginKeys.Modal.Enter_Email.title]: '请输入您的电子邮件',
  [LoginKeys.Modal.Enter_Email.description]: `
    请输入您的电子邮件以创建一个账户。 <br>
    您将在此处接收重要的订单通知。`,
  [LoginKeys.Modal.Enter_Email.Placeholder]: '输入电子邮件',
  [LoginKeys.Modal.Enter_Email.mobile_placeholder]: 'abcd@gmail.com',

  [LoginKeys.Modal.Reset_Password.description]:
    '您的密码已过期。请设置一个新密码。',
  [LoginKeys.Modal.Reset_Password.expired]: '您的密码已过期！',
  [LoginKeys.Modal.Reset_Password.reset]: '重置您的密码',
  [LoginKeys.Modal.Reset_Password.update_success]: '您的密码已更新！',

  [LoginKeys.Modal.Reset_Password.Error.valid_password]: '请输入一个有效的密码',

  [LoginKeys.Modal.Useful_Reward.title]: '如果您订阅，您将获得有用的奖励信息！',
  [LoginKeys.Modal.Useful_Reward.description]: `
  您可以随时一键退订！ <br>
  即使您没有订阅我们的通讯， <br>
  您仍将收到重要的订单通知 <br>
  我们的新闻通讯`,
  [LoginKeys.Modal.Useful_Reward.button]: '好的，我试试！',

  [LoginKeys.Modal.Verify_Email.title]: '请验证您的电子邮件',
  [LoginKeys.Modal.Verify_Email.description]: `
    请检查您的电子邮件{{email}}并验证您的帐户。`,

  [LoginKeys.Modal.Check_Email.title]: '请查看您的电子邮件',
  [LoginKeys.Modal.Check_Email.description]: `
    请检查您的电子邮件以重置您的密码。`,
  [LoginKeys.Modal.Check_Email.button]: '重新发送邮件',

  [LoginKeys.Modal.VIP_Influencer.description]: `
    您是创作者吗？ 切换到创作者帐户， <br>
    开始合作！`,
  [LoginKeys.Modal.VIP_Influencer.im_creator]: '是的，我是创作者!',
  [LoginKeys.Modal.VIP_Influencer.no_im_not]: '跳过',

  [LoginKeys.Modal.Welcome_On_Board.description]: `
    欢迎加入我们！ <br>
    MEGA为您准备了一份礼物！`,
  [LoginKeys.Modal.Welcome_On_Board.hi_mega]: '嗨，MEGA',

  [LoginKeys.Modal.Use_Another_Method.title]: '请使用另一种注册方法',
  [LoginKeys.Modal.Use_Another_Method.description]:
    '您的Facebook账户似乎没有连接的电子邮件地址。要使用Facebook注册或登录我们的平台，需要提供一个电子邮件地址。请使用其他注册方法创建一个账户',

  [LoginKeys.Modal.Enter_Your_Email.title]: '输入您的电子邮件',
  [LoginKeys.Modal.Enter_Your_Email.description]:
    '请输入您的电子邮件地址，以接收重置密码的链接。',
  [LoginKeys.Modal.Enter_Your_Email.placeholder]: '输入您的电子邮件',

  [LoginKeys.Modal.Reactivate.title]: '账户当前已停用',
  [LoginKeys.Modal.Reactivate.description]: '您想重新激活您的账户吗？',
  [LoginKeys.Modal.Register_type.buyer.title]: '以公司或机构身份验证',
  [LoginKeys.Modal.Register_type.buyer.description]:
    '通过简单的电子邮件验证解锁永久福利。',
  [LoginKeys.Modal.Register_type.creator.description]:
    '立即切换为创作者并开始接收优惠！',
  [LoginKeys.Modal.Register_type.skip]: '暂时跳过',
};
