import Popup from 'components/Basics/Popup';
import { useState, useLayoutEffect, useEffect } from 'react';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import withAuthHOC from './withAuthHOC';
import { useAuth } from 'hooks/useAauth';
import { mainFlow } from 'constants/authFlow';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import { Trans } from 'react-i18next';

const { RESET_PASSWORD } = mainFlow;

const VerifyEmailModal = ({ flowManager }) => {
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(true);
  const { isMobile } = useResponsiveScreen();
  const { flushFlow, sharedValue, closeModal, mainFlow } = flowManager;
  const email = sharedValue?.userData?.email;
  const { utils } = useAuth();
  const text = (function () {
    if (mainFlow === RESET_PASSWORD) {
      return {
        title: t(LoginKeys.Modal.Check_Email.title),
        description: () => t(LoginKeys.Modal.Check_Email.description),
      };
    } else {
      return {
        title: t(LoginKeys.Modal.Verify_Email.title),
        description: (mobile) =>
          mobile ? (
            <>
              <Trans>
                {t(LoginKeys.Modal.Verify_Email.description, { email: email })}
              </Trans>
            </>
          ) : (
            <Trans>
              {t(LoginKeys.Modal.Verify_Email.description, { email: email })}
            </Trans>
          ),
      };
    }
  })();
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [closeModal]);
  useLayoutEffect(() => {
    if (mainFlow !== RESET_PASSWORD) {
      utils.sendVerificationEmail(sharedValue.userData.email, i18n.language);
    }
  }, [mainFlow, sharedValue.userData, utils, i18n.language]);

  return (
    <>
      <Popup
        show={show}
        onClose={() => {
          setShow(false);
          flushFlow();
        }}
        title={text.title}
        description={text.description(isMobile)}
        buttonText={t(GeneralKeys.close)}
        onButtonClick={() => {
          flushFlow();
        }}
        buttonStyle={isMobile ? { minWidth: '136px', boxShadow: 'none' } : {}}
        asPage={isMobile}
      />
    </>
  );
};

export default withAuthHOC(VerifyEmailModal);
