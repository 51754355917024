import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import Stack from 'components/Basics/Layout/Stack';
import { useTranslation } from 'react-i18next';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const CompanyDescription = ({ maxCharCount = 1000 }) => {
  const { formState, register, watch } = useFormContext();
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();

  const error = formState.errors['companyDescription'];

  return (
    <Stack gap={isMobile ? '0.625rem' : '1rem'} width="100%">
      <Header>
        <p className="h7 text-red-500">{t(error?.message)}</p>
        <p
          className={`h7 ${
            watch('companyDescription')?.length > maxCharCount
              ? 'text-red-500'
              : 'text-indigo-100'
          }`}
        >
          {watch('companyDescription')?.length || 0} / {maxCharCount}
        </p>
      </Header>

      <TextArea
        {...register('companyDescription', {
          required: 'Required field',
        })}
        maxLength={maxCharCount}
        placeholder={t(PricingKeys.Modal.Final.description_placeholder)}
      />
    </Stack>
  );
};

export default CompanyDescription;

const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextArea = styled.textarea.attrs({
  className: 'scrollbar-y-thin h6-lh160',
})`
  width: 100%;
  height: 172px;
  border-radius: 0.75rem;
  border: 2px solid rgba(157, 153, 176, 0.16);
  background: transparent;
  resize: none;
  padding: 1.125rem 1rem 1.125rem 1.625rem;
  overflow-y: scroll;

  @media ${device.sm} {
    padding: 7px 7px 17px 11px;
    font-size: 14px;
  }

  &::placeholder {
    color: var(--color-indigo-100);
  }

  &:focus {
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }
`;
