export const OrderedListIcon = ({ size = 1 }) => (
  <svg
    width={17 * size}
    height={14 * size}
    viewBox="0 0 17 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.230896 11.375H1.9809V11.8125H1.1059V12.6875H1.9809V13.125H0.230896V14H2.8559V10.5H0.230896V11.375ZM1.1059 3.5H1.9809V0H0.230896V0.875H1.1059V3.5ZM0.230896 6.125H1.8059L0.230896 7.9625V8.75H2.8559V7.875H1.2809L2.8559 6.0375V5.25H0.230896V6.125ZM4.6059 0.875V2.625H16.8559V0.875H4.6059ZM4.6059 13.125H16.8559V11.375H4.6059V13.125ZM4.6059 7.875H16.8559V6.125H4.6059V7.875Z" />
  </svg>
);
