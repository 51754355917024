export const ThumbsDownIcon = ({ size = 1 }) => (
  <svg
    width={19 * size}
    height={18 * size}
    viewBox="0 0 19 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16.2971 0H4.84014C4.20851 0.000210513 3.59695 0.221858 3.11185 0.626377C2.62676 1.0309 2.29884 1.59268 2.18514 2.214L1.04215 8.51398C0.970929 8.90318 0.986117 9.30327 1.08664 9.68594C1.18716 10.0686 1.37055 10.4245 1.62385 10.7285C1.87714 11.0324 2.19414 11.277 2.55242 11.4449C2.91069 11.6128 3.30148 11.6998 3.69714 11.7H7.80113L7.29713 12.987C7.08749 13.5505 7.01768 14.1564 7.09368 14.7528C7.16967 15.3493 7.38921 15.9183 7.73346 16.4113C8.07772 16.9042 8.53641 17.3062 9.07018 17.583C9.60396 17.8597 10.1969 18.0028 10.7981 18C10.9713 17.9996 11.1406 17.9493 11.2859 17.8551C11.4311 17.7609 11.5461 17.6269 11.6171 17.469L14.1821 11.7H16.2971C17.0132 11.7 17.7 11.4155 18.2063 10.9092C18.7126 10.4028 18.9971 9.71606 18.9971 8.99998V2.69999C18.9971 1.98391 18.7126 1.29716 18.2063 0.79081C17.7 0.284463 17.0132 0 16.2971 0ZM12.6971 10.611L10.2491 16.119C9.99865 16.0432 9.76634 15.9169 9.56652 15.7479C9.36669 15.579 9.20357 15.3709 9.08721 15.1365C8.97086 14.9021 8.90373 14.6463 8.88995 14.385C8.87618 14.1237 8.91606 13.8623 9.00713 13.617L9.48413 12.33C9.58577 12.0579 9.62008 11.7654 9.58414 11.4772C9.5482 11.189 9.44307 10.9138 9.27773 10.6751C9.11239 10.4364 8.89174 10.2412 8.63461 10.1062C8.37749 9.97125 8.09153 9.90049 7.80113 9.89998H3.69714C3.56492 9.90019 3.43428 9.87127 3.3145 9.81527C3.19473 9.75927 3.08876 9.67757 3.00414 9.57598C2.91744 9.47579 2.85394 9.35769 2.81815 9.23012C2.78237 9.10254 2.7752 8.96865 2.79714 8.83798L3.94014 2.53799C3.97848 2.32833 4.08998 2.13908 4.25479 2.00393C4.4196 1.86879 4.62703 1.79652 4.84014 1.8H12.6971V10.611ZM17.1971 8.99998C17.1971 9.23867 17.1023 9.46759 16.9335 9.63637C16.7647 9.80516 16.5358 9.89998 16.2971 9.89998H14.4971V1.8H16.2971C16.5358 1.8 16.7647 1.89482 16.9335 2.0636C17.1023 2.23238 17.1971 2.4613 17.1971 2.69999V8.99998Z" />
  </svg>
);
