export const GridIcon = ({ size = 1 }) => (
  <svg
    width={12 * size}
    height={12 * size}
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.4 0H0.6C0.44087 0 0.288258 0.0632141 0.175736 0.175736C0.0632141 0.288258 0 0.44087 0 0.6V11.4C0 11.5591 0.0632141 11.7117 0.175736 11.8243C0.288258 11.9368 0.44087 12 0.6 12H11.4C11.5591 12 11.7117 11.9368 11.8243 11.8243C11.9368 11.7117 12 11.5591 12 11.4V0.6C12 0.44087 11.9368 0.288258 11.8243 0.175736C11.7117 0.0632141 11.5591 0 11.4 0ZM3.6 10.8H1.2V8.4H3.6V10.8ZM3.6 7.2H1.2V4.8H3.6V7.2ZM3.6 3.6H1.2V1.2H3.6V3.6ZM7.2 10.8H4.8V8.4H7.2V10.8ZM7.2 7.2H4.8V4.8H7.2V7.2ZM7.2 3.6H4.8V1.2H7.2V3.6ZM10.8 10.8H8.4V8.4H10.8V10.8ZM10.8 7.2H8.4V4.8H10.8V7.2ZM10.8 3.6H8.4V1.2H10.8V3.6Z" />
  </svg>
);
