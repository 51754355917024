export const LockIcon = ({ size = 1, color = '#0E0D39' }) => {
  return (
    <svg
      width={size * 18}
      height={size * 23}
      viewBox="0 0 18 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.625 8.19924H5.625V5.99913C5.62336 5.3459 5.82001 4.70689 6.19004 4.16303C6.56008 3.61916 7.08685 3.19491 7.70364 2.944C8.32044 2.6931 8.99951 2.62682 9.65485 2.75357C10.3102 2.88032 10.9123 3.19439 11.385 3.656C11.8079 4.0785 12.1105 4.60186 12.2625 5.17408C12.2994 5.31421 12.3642 5.44585 12.4532 5.56149C12.5422 5.67714 12.6535 5.77451 12.781 5.84806C12.9084 5.92161 13.0495 5.96989 13.196 5.99015C13.3425 6.0104 13.4917 6.00224 13.635 5.96613C13.7783 5.93001 13.9129 5.86665 14.0312 5.77966C14.1495 5.69267 14.249 5.58375 14.3243 5.45913C14.3995 5.33451 14.4488 5.19662 14.4696 5.05333C14.4903 4.91005 14.4819 4.76418 14.445 4.62406C14.1887 3.67229 13.6804 2.80339 12.9712 2.10492C12.184 1.33748 11.1818 0.815364 10.0912 0.604491C9.00065 0.393617 7.87065 0.503447 6.84389 0.920111C5.81713 1.33678 4.93965 2.04159 4.32226 2.94556C3.70486 3.84952 3.37524 4.91211 3.375 5.99913V8.19924C2.47989 8.19924 1.62145 8.54694 0.988515 9.16584C0.355579 9.78475 0 10.6242 0 11.4994V19.1998C0 20.0751 0.355579 20.9145 0.988515 21.5334C1.62145 22.1523 2.47989 22.5 3.375 22.5H14.625C15.5201 22.5 16.3786 22.1523 17.0115 21.5334C17.6444 20.9145 18 20.0751 18 19.1998V11.4994C18 10.6242 17.6444 9.78475 17.0115 9.16584C16.3786 8.54694 15.5201 8.19924 14.625 8.19924ZM15.75 19.1998C15.75 19.4916 15.6315 19.7714 15.4205 19.9777C15.2095 20.184 14.9234 20.2999 14.625 20.2999H3.375C3.07663 20.2999 2.79048 20.184 2.5795 19.9777C2.36853 19.7714 2.25 19.4916 2.25 19.1998V11.4994C2.25 11.2077 2.36853 10.9279 2.5795 10.7216C2.79048 10.5153 3.07663 10.3994 3.375 10.3994H14.625C14.9234 10.3994 15.2095 10.5153 15.4205 10.7216C15.6315 10.9279 15.75 11.2077 15.75 11.4994V19.1998Z" />
    </svg>
  );
};
