export const CancellationIcon = ({ size = 1, color = '#EC595A', style }) => {
  return (
    <svg
      width={size * 29}
      height={size * 29}
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14.5 0C6.525 0 0 6.525 0 14.5C0 22.475 6.525 29 14.5 29C22.475 29 29 22.475 29 14.5C29 6.525 22.475 0 14.5 0ZM2.9 14.5C2.9 8.12 8.12 2.9 14.5 2.9C17.11 2.9 19.575 3.77 21.605 5.365L5.365 21.605C3.77 19.575 2.9 17.11 2.9 14.5ZM14.5 26.1C11.89 26.1 9.425 25.23 7.395 23.635L23.635 7.395C25.23 9.425 26.1 11.89 26.1 14.5C26.1 20.88 20.88 26.1 14.5 26.1Z"
        fill={color}
      />
    </svg>
  );
};
