import { CrossCircleIcon, DownloadLightRoundIcon } from 'assets/icons';
import IconButton from 'components/Basics/Buttons/IconButton';
import { motion } from 'framer-motion';
import { useCallback } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import Preview from './Preview';
import { useRef } from 'react';
import AxiosClient from 'components/utilities/AxiosClient';
import ProgressLoading from './ProgressLoading';
import { useFormContext } from 'react-hook-form';

const fileSize = (size) => {
  if (size < 1024) return `${size} B`;

  if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;

  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;

  return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
};

const File = ({ file, name, onRemove }) => {
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const { getValues, setValue } = useFormContext();

  const handleRemove = useCallback(() => {
    if (dismiss.current) {
      dismiss.current();
    }

    setValue(
      name,
      getValues(name).filter((f) => f.id !== file.id)
    );
    onRemove?.(file.id);
  }, [getValues, name, setValue, onRemove, file.id]);

  const dismiss = useRef(null);

  return (
    <Wrapper>
      <Preview file={file} />

      {!file.expired && (
        <Details className="hidden md-flex">
          <Clickable
            onClick={() => {
              if (downloading) return;

              if (file.url.startsWith('blob:')) {
                const link = document.createElement('a');
                link.href = file.url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                link.remove();
                return;
              }

              const url = !file.dbName
                ? file.url
                : `/proposals/uploads/${file.dbName}?download=true`;

              setDownloading(true);
              setDownloadProgress(0);
              AxiosClient.get(url, {
                responseType: 'blob',
                onDownloadProgress: (event) => {
                  const progress = Math.round((100 * event.loaded) / file.size);
                  setDownloadProgress(progress);
                },
              })
                .then((res) => {
                  const url = window.URL.createObjectURL(
                    new Blob([res.data], {
                      type: file.type,
                    })
                  );

                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', file.name);
                  document.body.appendChild(link);
                  link.click();
                  link.remove();
                })
                .finally(() => {
                  setDownloading(false);
                });
            }}
          >
            <IconBox>
              {downloading ? (
                <ProgressLoading
                  radius={7}
                  strokeWidth={3}
                  progress={downloadProgress}
                />
              ) : (
                <DownloadLightRoundIcon />
              )}
            </IconBox>

            {file.name}
          </Clickable>
          <Size>{fileSize(file.size)}</Size>

          <IconButton onClick={handleRemove}>
            <CrossCircleIcon size={0.7} />
          </IconButton>
        </Details>
      )}
      <span className="close-button md-hidden" onClick={handleRemove}>
        <IconButton onClick={handleRemove}>
          <CrossCircleIcon size={0.7} />
        </IconButton>
      </span>
    </Wrapper>
  );
};

export default File;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: {
      opacity: 0,
      scale: 0.9,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  transition: { duration: 0.1 },
  layout: true,
})`
  width: 100%;
  max-width: 160px;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  position: relative;

  .close-button {
    cursor: pointer;
    background: var(--color-white);
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 10;
    border-radius: 999px;
  }
`;

const Details = styled.div.attrs({
  className: 'h8',
})`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
`;

const Clickable = styled.button.attrs({
  className: 'h8',
})`
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;

const IconBox = styled.span`
  flex-shrink: 0;
`;

const Size = styled.span`
  flex-shrink: 0;
  color: var(--color-indigo-100);
  cursor: default;
`;
