import { authPlatform } from './authFlow';
export const authType = {
  LOCAL: 'local',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  WALLET: 'wallet',
};
export function platformToType(platform) {
  switch (platform) {
    case authPlatform.LOCAL: {
      return authType.LOCAL;
    }
    case authPlatform.GOOGLE: {
      return authType.GOOGLE;
    }
    case authPlatform.FACEBOOK: {
      return authType.FACEBOOK;
    }
    case authPlatform.METAMASK: {
      return authType.WALLET;
    }
    case authPlatform.COINBASE: {
      return authType.WALLET;
    }
  }
}
