export const ImageSmallIcon = ({ size = 1 }) => (
  <svg
    width={size * 20}
    height={size * 17}
    viewBox="0 0 20 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17 0.5H3C2.20435 0.5 1.44129 0.816071 0.87868 1.37868C0.316071 1.94129 0 2.70435 0 3.5V13.5C0 14.2956 0.316071 15.0587 0.87868 15.6213C1.44129 16.1839 2.20435 16.5 3 16.5H17C17.7956 16.5 18.5587 16.1839 19.1213 15.6213C19.6839 15.0587 20 14.2956 20 13.5V3.5C20 2.70435 19.6839 1.94129 19.1213 1.37868C18.5587 0.816071 17.7956 0.5 17 0.5ZM3 14.5C2.73478 14.5 2.48043 14.3946 2.29289 14.2071C2.10536 14.0196 2 13.7652 2 13.5V11.08L5.3 7.79C5.48693 7.60677 5.73825 7.50414 6 7.50414C6.26175 7.50414 6.51307 7.60677 6.7 7.79L13.41 14.5H3ZM18 13.5C18 13.7652 17.8946 14.0196 17.7071 14.2071C17.5196 14.3946 17.2652 14.5 17 14.5H16.23L12.42 10.67L13.3 9.79C13.4869 9.60677 13.7382 9.50414 14 9.50414C14.2618 9.50414 14.5131 9.60677 14.7 9.79L18 13.08V13.5ZM18 10.26L16.12 8.39C15.5501 7.84243 14.7904 7.53663 14 7.53663C13.2096 7.53663 12.4499 7.84243 11.88 8.39L11 9.27L8.12 6.39C7.55006 5.84243 6.79036 5.53663 6 5.53663C5.20964 5.53663 4.44994 5.84243 3.88 6.39L2 8.26V3.5C2 3.23478 2.10536 2.98043 2.29289 2.79289C2.48043 2.60536 2.73478 2.5 3 2.5H17C17.2652 2.5 17.5196 2.60536 17.7071 2.79289C17.8946 2.98043 18 3.23478 18 3.5V10.26Z" />
  </svg>
);
