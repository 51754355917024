export const SearchIcon = ({ size = 1 }) => (
  <svg
    width={22 * size}
    height={22 * size}
    viewBox="0 0 22 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.6938 13.8187H14.7063L14.3563 13.4812C15.5813 12.0562 16.3188 10.2062 16.3188 8.19373C16.3188 3.70623 12.6813 0.0687256 8.19375 0.0687256C3.70625 0.0687256 0.0687504 3.70623 0.0687504 8.19373C0.0687504 12.6812 3.70625 16.3187 8.19375 16.3187C10.2063 16.3187 12.0562 15.5812 13.4812 14.3562L13.8188 14.7062V15.6937L20.0688 21.9312L21.9313 20.0687L15.6938 13.8187ZM8.19375 13.8187C5.08125 13.8187 2.56875 11.3062 2.56875 8.19373C2.56875 5.08123 5.08125 2.56873 8.19375 2.56873C11.3063 2.56873 13.8188 5.08123 13.8188 8.19373C13.8188 11.3062 11.3063 13.8187 8.19375 13.8187Z" />
  </svg>
);
<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M15.7753 14.6511L12.8091 11.7067C13.9605 10.2699 14.5181 8.44635 14.3672 6.61085C14.2164 4.77535 13.3685 3.06745 11.998 1.83834C10.6275 0.609226 8.83851 -0.0476794 6.9989 0.00269722C5.15928 0.0530739 3.40887 0.806903 2.10758 2.10918C0.806291 3.41146 0.0530336 5.1632 0.00269517 7.00421C-0.0476432 8.84523 0.608763 10.6356 1.83694 12.0071C3.06512 13.3787 4.77172 14.2272 6.60583 14.3781C8.43994 14.5291 10.2621 13.9711 11.6978 12.8188L14.64 15.7633C14.7143 15.8383 14.8028 15.8978 14.9002 15.9385C14.9976 15.9791 15.1021 16 15.2077 16C15.3132 16 15.4177 15.9791 15.5152 15.9385C15.6126 15.8978 15.701 15.8383 15.7753 15.7633C15.9194 15.6141 16 15.4147 16 15.2072C16 14.9997 15.9194 14.8003 15.7753 14.6511ZM7.21243 12.8188C6.10552 12.8188 5.02346 12.4903 4.10309 11.8749C3.18272 11.2595 2.46538 10.3847 2.04178 9.36129C1.61818 8.33786 1.50735 7.2117 1.7233 6.12523C1.93925 5.03875 2.47228 4.04076 3.25499 3.25746C4.0377 2.47416 5.03493 1.94072 6.12058 1.72461C7.20622 1.50849 8.33153 1.61941 9.35419 2.04333C10.3768 2.46725 11.2509 3.18514 11.8659 4.1062C12.4809 5.02727 12.8091 6.11015 12.8091 7.21791C12.8091 8.70337 12.2195 10.128 11.1699 11.1784C10.1203 12.2287 8.69676 12.8188 7.21243 12.8188Z" />
</svg>;
