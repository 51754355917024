export const WalletConnectIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 38}
      height={size * 39}
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_35945_155167)">
        <g clipPath="url(#clip0_35945_155167)">
          <rect
            x="3"
            y="1.3999"
            width="32"
            height="32"
            rx="6"
            fill="url(#paint0_radial_35945_155167)"
          />
          <path
            d="M13.0469 13.7577C16.2656 10.614 21.4906 10.614 24.7094 13.7577L25.0969 14.139C25.2594 14.2952 25.2594 14.5515 25.0969 14.7077L23.7719 16.0015C23.6906 16.0827 23.5594 16.0827 23.4781 16.0015L22.9469 15.4827C20.6969 13.289 17.0594 13.289 14.8094 15.4827L14.2406 16.039C14.1594 16.1202 14.0281 16.1202 13.9469 16.039L12.6219 14.7452C12.4594 14.589 12.4594 14.3327 12.6219 14.1765L13.0469 13.7577ZM27.4531 16.4327L28.6344 17.5827C28.7969 17.739 28.7969 17.9952 28.6344 18.1515L23.3156 23.3452C23.1531 23.5015 22.8906 23.5015 22.7344 23.3452L18.9594 19.6577C18.9219 19.6202 18.8531 19.6202 18.8156 19.6577L15.0406 23.3452C14.8781 23.5015 14.6156 23.5015 14.4594 23.3452L9.12187 18.1515C8.95938 17.9952 8.95938 17.739 9.12187 17.5827L10.3031 16.4327C10.4656 16.2765 10.7281 16.2765 10.8844 16.4327L14.6594 20.1202C14.6969 20.1577 14.7656 20.1577 14.8031 20.1202L18.5781 16.4327C18.7406 16.2765 19.0031 16.2765 19.1594 16.4327L22.9344 20.1202C22.9719 20.1577 23.0406 20.1577 23.0781 20.1202L26.8531 16.4327C27.0281 16.2765 27.2906 16.2765 27.4531 16.4327Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_35945_155167"
          x="0"
          y="0.399902"
          width={size * 38}
          height={size * 38}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35945_155167"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_35945_155167"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_35945_155167"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(3.0001 17.4004) scale(32)"
        >
          <stop stopColor="#5D9DF6" />
          <stop offset="1" stopColor="#006FFF" />
        </radialGradient>
        <clipPath id="clip0_35945_155167">
          <rect x="3" y="1.3999" width="32" height="32" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
