const FilterKeys = {
  home: {
    search_keywords: 'FilterKeys.home.search_keywords',

    expand: 'FilterKeys.home.expand',
    collapse: 'FilterKeys.home.collapse',

    more_filter: 'FilterKeys.home.more_filter',
    less_filter: 'FilterKeys.home.less_filter',

    try_searching: 'FilterKeys.home.try_searching',
    results_for_keyword: 'FilterKeys.home.results_for_keyword',
    result_for_keyword: 'FilterKeys.home.result_for_keyword',

    Try_Searching: {
      brand_ambassador: 'FilterKeys.home.Try_Searching.brand_ambassador',
      live_shows: 'FilterKeys.home.Try_Searching.live_shows',
      product_review: 'FilterKeys.home.Try_Searching.product_review',
      game_review: 'FilterKeys.home.Try_Searching.game_review',
    },

    sort_by: {
      suggested: 'FilterKeys.home.sort_by.suggested',
      by_subscribers: 'FilterKeys.home.sort_by.by_subscribers',
      lowest_price: 'FilterKeys.home.sort_by.lowest_price',
      highest_price: 'FilterKeys.home.sort_by.highest_price',
    },

    country: {
      popular: 'FilterKeys.home.country.popular',
      selected: 'FilterKeys.home.country.selected',
      no_result: 'FilterKeys.home.country.no_result',
    },

    price: {
      _50: 'FilterKeys.home.price._50',
      _50_100: 'FilterKeys.home.price._50_100',
      _100_300: 'FilterKeys.home.price._100_300',
      _300_500: 'FilterKeys.home.price._300_500',
      _500_1k: 'FilterKeys.home.price._500_1k',
      _1k_2k: 'FilterKeys.home.price._1k_2k',
      _2k_5k: 'FilterKeys.home.price._2k_5k',
      _5k_: 'FilterKeys.home.price._5k_',
    },

    subs_and_views: {
      tooltip_subs: 'FilterKeys.home.subs_and_views.tooltip_subs',
      tooltip_views: 'FilterKeys.home.subs_and_views.tooltip_views',

      private: 'FilterKeys.home.subs_and_views.private',
      _10k: 'FilterKeys.home.subs_and_views._10k',
      _10k_100k: 'FilterKeys.home.subs_and_views._10k_100k',
      _100k_1m: 'FilterKeys.home.subs_and_views._100k_1m',
      _1m_: 'FilterKeys.home.subs_and_views._1m_',
    },

    gender: {
      male: 'FilterKeys.home.gender.male',
      female: 'FilterKeys.home.gender.female',
    },

    M: {
      filter_by: 'FilterKeys.home.M.filter_by',
      all_categories: 'FilterKeys.home.M.all_categories',
      all_price_ranges: 'FilterKeys.home.M.all_price_ranges',
    },
  },
};

export default FilterKeys;
