import styled from 'styled-components';
import { forwardRef } from 'react';

/**
 * A simple component that displays an image without distortion.
 * @param {string} src - The source of the image.
 * @param {string} alt - The alt text of the image.
 * @param {string} width - The width of the image.
 * @param {string} height - The height of the image. If not provided, the image will be square.
 * @param {boolean} circle - Whether the image should be circular. (height will be ignored if this is true)
 * @param {boolean} square - Whether the image should be square. (height will be ignored if this is true)
 * @param {string} radius - The border radius of the image.
 * @param {boolean} gray - Whether the image should be gray.
 * @param {boolean} contain - Whether the image should be contained.
 * @param {string} position - The position of the image.
 * @param {boolean} rotate
 * @param {string} shadow - The shadow of the image.
 * @param {string} margin
 * @additionalProps - Any additional props to be passed to the image.
 */
const Image = forwardRef(
  (
    {
      src,
      alt,
      width = 'auto',
      height,
      aspect,
      circle,
      square,
      radius,
      gray,
      contain,
      position,
      className,
      style,
      background,
      rotate,
      maxWidth,
      maxHeight,
      scaleDown,
      top,
      left,
      right,
      bottom,
      margin,
      shadow,
      ...props
    },
    ref
  ) => {
    return (
      <ImageContainer
        ref={ref}
        width={width}
        height={height}
        aspect={aspect}
        circle={circle}
        square={square}
        radius={radius}
        gray={gray}
        className={className}
        style={style}
        background={background}
        rotate={rotate}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        position={position}
        top={top}
        left={left}
        right={right}
        bottom={bottom}
        shadow={shadow}
        margin={margin}
      >
        {src && (
          <Img
            src={src}
            alt={alt}
            {...props}
            scaleDown={scaleDown}
            contain={contain}
          />
        )}
      </ImageContainer>
    );
  }
);

export default Image;

const ImageContainer = styled.div`
  width: ${(props) => props.width};
  height: ${(props) =>
    props.height
      ? props.height
      : props.square || props.circle || props.aspect
      ? 'auto'
      : !props.height
      ? props.width
      : props.height};
  /* ${(props) => (props.square || props.circle ? 'aspect-ratio: 1;' : '')} */
  aspect-ratio: ${(props) =>
    props.aspect ? props.aspect : props.square || props.circle ? '1' : 'auto'};
  border-radius: ${(props) => (props.circle ? '50%' : props.radius || '0')};
  overflow: hidden;
  background: ${({ background }) => background ?? 'transparent'};
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ margin }) => (margin ? { margin } : null)};
  ${(props) => (props.rotate ? `transform: rotate(1deg);` : null)};
  ${({ maxHeight }) => (maxHeight ? { maxHeight } : null)}
  ${({ maxWidth }) => (maxWidth ? { maxWidth } : null)}
	${({ position, top, bottom, left, right }) =>
    position ? { position, bottom, left, right, top } : null};
  ${({ shadow }) => (shadow ? `box-shadow: ${shadow}` : '')}
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.contain ? 'contain' : 'cover')};
  object-position: ${(props) => props.position || 'center'};
  transform-origin: center center;
  transform: scale(${({ scaleDown }) => scaleDown});
`;
