const Tools = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  HIGHLIGHT: 'highlight',
  ORDERED_LIST: 'ordered-list',
  UNORDERED_LIST: 'unordered-list',
  CHAT_GPT: 'chat-gpt',
  SNIPPET: 'snippet',
  IMAGE: 'image',
};

export default Tools;
