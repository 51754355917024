import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { useState } from 'react';
import styled from 'styled-components';
import { useEditorContext } from './EditorContext';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { useTranslation } from 'react-i18next';

const Content = ({
  className,
  placeholder,
  onBlur,
  placeholderStyles,
  ...rest
}) => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const { gptEnabled } = useEditorContext();
  const [container, setContainer] = useState(null);
  const onContainerRefChange = (node) => {
    if (node) setContainer(node);
    else setContainer(null);
  };

  return (
    <Container
      className={className}
      ref={onContainerRefChange}
      onClick={() => {
        editor.focus();
      }}
      {...rest}
    >
      <RichTextPlugin
        contentEditable={<ContentEditable onBlur={onBlur} />}
        placeholder={
          <Placeholder
            padding={
              container
                ? window.getComputedStyle(container).getPropertyValue('padding')
                : '0'
            }
            style={placeholderStyles}
          >
            {gptEnabled
              ? t(ProfileKeys.Edit.Package.Description.placeholder_gpt)
              : placeholder}
          </Placeholder>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
    </Container>
  );
};

export default Content;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  cursor: text;
`;

const Placeholder = styled.div.attrs({
  className: 'editor-placeholder',
})`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--color-indigo-100);
  user-select: none;
  pointer-events: none;
  transition: color 150ms ease-in-out;
  padding: ${({ padding }) => padding || '0'};
`;
