const size1 = () => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2379 10.1624L27.9803 0H25.9086L18.3176 8.82384L12.2546 0H5.26172L14.4301 13.3432L5.26172 24H7.33351L15.3499 14.6817L21.7528 24H28.7457L19.2373 10.1624H19.2379ZM16.4003 13.4608L15.4713 12.1321L8.08001 1.55962H11.2622L17.227 10.0919L18.156 11.4206L25.9096 22.5113H22.7274L16.4003 13.4613V13.4608Z"
      fill="white"
    />
  </svg>
);
export default size1;
