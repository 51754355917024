import { DecoratorNode } from 'lexical';
import { SnippetOptionsNames } from './SnippetOptions';
import SnippetComponent from './SnippetComponent';

const convertSnippetElement = (domNode) => {
  const snippetName = domNode.getAttribute('data-lexical-snippet');

  if (snippetName !== null) {
    const node = $createSnippetNode(snippetName);
    return {
      node,
    };
  }

  return null;
};

export class SnippetNode extends DecoratorNode {
  __snippet;

  static getType() {
    return 'snippet';
  }

  static clone(node) {
    return new SnippetNode(node.__snippet, node.__key);
  }

  static importJSON(serializedNode) {
    const node = $createSnippetNode(serializedNode.snippetName);
    return node;
  }

  constructor(snippetName, key) {
    super(key);
    this.__snippet = snippetName;
  }

  exportJSON() {
    return {
      //   ...super.exportJSON(),
      snippetName: this.__snippet,
      type: 'snippet',
      version: 1,
    };
  }

  createDOM(config) {
    return document.createElement('span');
  }

  updateDOM(prevNode, dom, config) {
    return false;
  }

  decorate() {
    return <SnippetComponent name={this.__snippet} />;
  }

  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('data-lexical-snippet', this.__snippet);
    element.textContent =
      '{{' + (SnippetOptionsNames[this.__snippet] ?? this.__snippet) + '}}';
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute('data-lexical-snippet')) {
          return null;
        }
        return {
          conversion: convertSnippetElement,
          priority: 1,
        };
      },
    };
  }

  getTextContent() {
    return '{{' + this.__snippet + '}}';
  }

  getTextContentSize() {
    return 20;
  }
}

export function $createSnippetNode(snippetName) {
  const snippetNode = new SnippetNode(snippetName);
  return snippetNode;
}

export function $isSnippetNode(node) {
  return node instanceof SnippetNode;
}
