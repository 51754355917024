import 'dayjs/locale/ko';

const koLocale = {
  name: 'ko',
  relativeTime: {
    future: '%s 후',
    past: '%s 전',
    s: '1분',
    m: '1분',
    mm: '%d분',
    h: '1시간',
    hh: '%d시간',
    d: '1일',
    dd: '%d일',
    M: '1개월',
    MM: '%d개월',
    y: '1년',
    yy: '%d년',
  },
  dateFormat: {
    dot_date: 'YYYY.MM.DD',
    dot_date_time_12: 'YYYY.MM.DD A h:mm',
    time_12: 'A h:mm',
    abbr_date_time_12: 'M[월] D[일], A h:mm',
    date_time: 'YYYY[년] M[월] D[일]',
    yesterday: '어제',
    full_abbr_date_time_12: 'YYYY[년] M[월] D[일] A h:mm',
  },
  meridiem: (hour) => {
    return hour > 12 ? '오후' : '오전';
  },
};

export default koLocale;
