export const PDFIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 20}
    height={size * 20}
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path d="M17.5 0H2.5C1.125 0 0 1.125 0 2.5V17.5C0 18.875 1.125 20 2.5 20H17.5C18.875 20 20 18.875 20 17.5V2.5C20 1.125 18.875 0 17.5 0ZM6.875 9.375C6.875 10.4125 6.0375 11.25 5 11.25H3.75V13.75H1.875V6.25H5C6.0375 6.25 6.875 7.0875 6.875 8.125V9.375ZM13.125 11.875C13.125 12.9125 12.2875 13.75 11.25 13.75H8.125V6.25H11.25C12.2875 6.25 13.125 7.0875 13.125 8.125V11.875ZM18.125 8.125H16.25V9.375H18.125V11.25H16.25V13.75H14.375V6.25H18.125V8.125ZM3.75 9.375H5V8.125H3.75V9.375ZM10 11.875H11.25V8.125H10V11.875Z" />
  </svg>
);
