import styled from 'styled-components';
import MySpaceButton from './MySpaceButton';
import useUser from 'hooks/useUser';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import Orders from './Orders';
import Notifications from './Notifications';
import Messages from './Messages';
import { ChatListProvider } from 'components/components/MessagesPage/ChatsList/ChatListContext';
import LanguageFlag from 'components/Basics/Layout/LanguageFlag';

const Options = () => {
  const { t } = useTranslation();

  const { isLoggedIn } = useUser();
  const { openModal } = useAuthFlowManager();

  if (isLoggedIn)
    return (
      <Wrapper>
        <Orders />
        <Notifications />
        <ChatListProvider>
          <Messages />
        </ChatListProvider>

        <MySpaceButton />
      </Wrapper>
    );

  return (
    <Wrapper>
      <TextButton className="text-inherit" onClick={openModal}>
        {t(GeneralKeys.login)}
      </TextButton>

      <LanguageFlag top />
    </Wrapper>
  );
};

export default Options;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.875rem;
  pointer-events: all;
`;

const TextButton = styled.button.attrs({
  className: 'sbh3',
})`
  background: none;
  border: none;
  padding: 0;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }
`;
