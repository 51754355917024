export const PassThroughIcon = ({
  size = 1,
  color = 'var(--color-indigo-500)',
  style,
}) => {
  return (
    <svg
      width={size * 30}
      height={size * 22}
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M24.5455 5.54537L19.0909 10.9999H23.1818C23.1818 15.5135 19.5136 19.1817 15 19.1817C13.6227 19.1817 12.3136 18.8408 11.1818 18.2272L9.19091 20.2181C10.8682 21.2817 12.8591 21.909 15 21.909C21.0273 21.909 25.9091 17.0272 25.9091 10.9999H30L24.5455 5.54537ZM6.81818 10.9999C6.81818 6.48627 10.4864 2.81809 15 2.81809C16.3773 2.81809 17.6864 3.159 18.8182 3.77264L20.8091 1.78173C19.1318 0.718093 17.1409 0.0908203 15 0.0908203C8.97273 0.0908203 4.09091 4.97264 4.09091 10.9999H0L5.45455 16.4545L10.9091 10.9999H6.81818Z"
        fill={color}
      />
    </svg>
  );
};
