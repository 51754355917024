import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'การแปลอัตโนมัติ',
  [AutoTranslationKeys.Dropdown.description]:
    'คำอธิบายและข้อความที่ผู้ใช้งานเขียนจะได้รับการแปลโดยอัตโนมัติ',

  [AutoTranslationKeys.Applying_Translation.title]:
    'การแปลอัตโนมัติได้รับการใช้งานแล้ว',
  [AutoTranslationKeys.Applying_Translation.btn]: 'แสดงข้อความเดิม',

  [AutoTranslationKeys.Displaying_Original.title]: 'การแสดงข้อความเดิม',
  [AutoTranslationKeys.Displaying_Original.btn]: 'แปล',
};
