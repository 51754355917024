const link2 = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="9" fill="black" />
    <path
      d="M9.89506 8.26694L13.3944 4.19922H12.5652L9.52669 7.73117L7.09985 4.19922H4.30078L7.97063 9.54015L4.30078 13.8058H5.13006L8.33879 10.0759L10.9017 13.8058H13.7008L9.89485 8.26694H9.89506ZM8.75924 9.5872L8.38741 9.05537L5.42887 4.82349H6.7026L9.09017 8.23875L9.46201 8.77059L12.5656 13.2099H11.2918L8.75924 9.58741V9.5872Z"
      fill="white"
    />
  </svg>
);
export default link2;
