import FilterKeys from '../translationKeys/FilterKeys';

export const FilterTranslations = {
  // Influencer Marketing Home Page
  [FilterKeys.home.search_keywords]: '搜索关键词',

  [FilterKeys.home.expand]: '展开',
  [FilterKeys.home.collapse]: '收起',

  [FilterKeys.home.more_filter]: '＋ 更多过滤选项',
  [FilterKeys.home.less_filter]: '– 较少过滤选项',

  [FilterKeys.home.try_searching]: '试试搜索',
  [FilterKeys.home.results_for_keyword]:
    '"{{searchText}}" 共有 {{number}} 个结果',
  [FilterKeys.home.result_for_keyword]:
    '"{{searchText}}" 共有 {{number}} 个结果',

  // Try Searching
  [FilterKeys.home.Try_Searching.brand_ambassador]: '品牌大使',
  [FilterKeys.home.Try_Searching.live_shows]: '直播节目',
  [FilterKeys.home.Try_Searching.product_review]: '产品评测',
  [FilterKeys.home.Try_Searching.game_review]: '游戏评测',

  // Sort By
  [FilterKeys.home.sort_by.suggested]: '推荐',
  [FilterKeys.home.sort_by.by_subscribers]: '订阅者',
  [FilterKeys.home.sort_by.lowest_price]: '最低价格',
  [FilterKeys.home.sort_by.highest_price]: '最高价格',

  // Country
  [FilterKeys.home.country.popular]: '热门国家',
  [FilterKeys.home.country.selected]: '已选国家',
  [FilterKeys.home.country.no_result]: '未找到结果',

  // Price
  [FilterKeys.home.price._50]: '~50美元',
  [FilterKeys.home.price._50_100]: '50~100美元',
  [FilterKeys.home.price._100_300]: '100~300美元',
  [FilterKeys.home.price._300_500]: '300~500美元',
  [FilterKeys.home.price._500_1k]: '500~1,000美元',
  [FilterKeys.home.price._1k_2k]: '1,000~2,000美元',
  [FilterKeys.home.price._2k_5k]: '2,000~5,000美元',
  [FilterKeys.home.price._5k_]: '5,000美元以上',

  // Subscribers
  [FilterKeys.home.subs_and_views.tooltip_subs]: '用户的订阅者或关注者数量',
  [FilterKeys.home.subs_and_views.tooltip_views]: `
  在180天内每篇帖子可以预 <br>
  期的平均浏览或印象次数。`,

  [FilterKeys.home.subs_and_views.private]: '私密',
  [FilterKeys.home.subs_and_views._10k]: '~10,000',
  [FilterKeys.home.subs_and_views._10k_100k]: '10,000~100,000',
  [FilterKeys.home.subs_and_views._100k_1m]: '100,000~1,000,000',
  [FilterKeys.home.subs_and_views._1m_]: '1,000,000以上',

  // Gender
  [FilterKeys.home.gender.male]: '男性',
  [FilterKeys.home.gender.female]: '女性',

  // Mobile
  [FilterKeys.home.M.filter_by]: '筛选方式：',
  [FilterKeys.home.M.all_categories]: '所有类别',
  [FilterKeys.home.M.all_price_ranges]: '所有价格范围',
};
