import styled from 'styled-components';
import { StarIcon } from 'assets/icons';
import { usePackages } from 'contexts/PackagesContext';

const DeatilsModalRatings = () => {
  const { userData: user, loading: isLoading, platform } = usePackages();

  const reviews = user?.buyerReviews;

  const totalRatingParticipant = reviews?.length;
  const averageRating =
    totalRatingParticipant === 0
      ? '--'
      : reviews?.reduce((acc, curr) => {
          return acc + curr.rating;
        }, 0) / totalRatingParticipant;

  return (
    <RatingBox
      onClick={() =>
        window.open(
          `/${
            user?.handle ? `@${user?.handle}` : user?.id
          }?platform=${platform}&section=reviews`,
          '_blank'
        )
      }
    >
      <span className="text-yellow-500 flex align-items-center">
        <StarIcon size={1.1} />
      </span>

      <span className="bh5 rating">
        {isLoading ? '--' : isNaN(averageRating) ? '--' : averageRating}
      </span>

      <span className="sbh5 text-indigo-100">
        {isLoading
          ? '(0)'
          : `(${
              totalRatingParticipant === undefined ? 0 : totalRatingParticipant
            })`}
      </span>
    </RatingBox>
  );
};

export default DeatilsModalRatings;

const RatingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
  transition: all 150ms ease-in-out;
  cursor: pointer;

  &:hover .rating {
    text-decoration: underline;
  }
`;
