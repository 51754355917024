import styled from 'styled-components';
import ProgressLoading from '../ProgressLoading';
import {
  FileCrossIcon,
  FileIcon,
  ImageIcon,
  MusicIcon,
  VideoIcon,
} from 'assets/icons';
import FileType from './FileType';
import { motion } from 'framer-motion';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const LoadingPreview = ({ id, fileType, progress, borderRadius }) => {
  const { isMobile } = useResponsiveScreen();

  return (
    <Wrapper borderRadius={borderRadius}>
      <Progress
        radius={isMobile ? 16 : 20}
        strokeWidth={isMobile ? 3 : 4}
        progress={progress}
      />

      <motion.div
        style={{
          scale: 0.75,
        }}
      >
        {fileType === FileType.Video && <VideoIcon size={isMobile ? 0.8 : 1} />}
        {fileType === FileType.Image && <ImageIcon size={isMobile ? 0.8 : 1} />}
        {fileType === FileType.Audio && <MusicIcon size={isMobile ? 0.8 : 1} />}
        {fileType === FileType.Other && <FileIcon size={isMobile ? 0.8 : 1} />}
        {fileType === FileType.Expired && (
          <FileCrossIcon size={isMobile ? 0.8 : 1} />
        )}
      </motion.div>
    </Wrapper>
  );
};

export default LoadingPreview;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: var(--color-indigo-50);
  border: 1px solid var(--color-indigo-25);
  border-radius: ${({ borderRadius }) => borderRadius || '0.25rem'};
  position: relative;
`;

const Progress = styled(ProgressLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
