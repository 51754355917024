export const ClipboardThunderIcon = ({ size = 1 }) => (
  <svg
    width={54 * size}
    height={64 * size}
    viewBox="0 0 54 64"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.1406 6.5H48.875C49.7038 6.5 50.4987 6.81607 51.0847 7.37869C51.6708 7.9413 52 8.70437 52 9.50002V59.0004C52 59.796 51.6708 60.5591 51.0847 61.1217C50.4987 61.6843 49.7038 62.0004 48.875 62.0004H5.125C4.2962 62.0004 3.50134 61.6843 2.91529 61.1217C2.32924 60.5591 2 59.796 2 59.0004V9.50002C2 8.70437 2.32924 7.9413 2.91529 7.37869C3.50134 6.81607 4.2962 6.5 5.125 6.5H16.0625V11H37.9375V6.5H41.1406Z"
      strokeWidth="4"
      strokeLinejoin="round"
    />
    <path
      d="M31.6875 24.5002L19.1875 36.5017H34.8188L22.3125 48.5018M16.0625 2H37.9375V11.0001H16.0625V2Z"
      strokeWidth="4"
      strokeLineCap="round"
      strokeLinejoin="round"
    />
  </svg>
);
