export const ThumbsUpIcon = ({ size = 1 }) => (
  <svg
    width={18 * size}
    height={18 * size}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.37 7.27202C17.1171 6.96841 16.8006 6.724 16.443 6.55605C16.0853 6.3881 15.6951 6.3007 15.3 6.30003H11.196L11.7 5.01303C11.9096 4.44952 11.9794 3.84358 11.9034 3.24716C11.8274 2.65075 11.6079 2.08167 11.2636 1.58874C10.9194 1.09582 10.4607 0.693755 9.92693 0.417048C9.39315 0.140341 8.80021 -0.00275348 8.19898 4.0132e-05C8.02586 0.000401357 7.85651 0.0506877 7.71125 0.14487C7.56598 0.239053 7.45096 0.373134 7.37998 0.531039L4.81499 6.30003H2.69999C1.98391 6.30003 1.29716 6.58449 0.79081 7.09084C0.284463 7.59718 0 8.28394 0 9.00002V15.3C0 16.0161 0.284463 16.7028 0.79081 17.2092C1.29716 17.7155 1.98391 18 2.69999 18H14.157C14.7886 17.9998 15.4002 17.7781 15.8853 17.3736C16.3703 16.9691 16.6983 16.4073 16.812 15.786L17.955 9.48602C18.0257 9.09666 18.0099 8.69652 17.9088 8.31393C17.8077 7.93134 17.6238 7.57564 17.37 7.27202ZM4.49999 16.2H2.69999C2.4613 16.2 2.23238 16.1052 2.0636 15.9364C1.89482 15.7676 1.8 15.5387 1.8 15.3V9.00002C1.8 8.76133 1.89482 8.53241 2.0636 8.36363C2.23238 8.19484 2.4613 8.10002 2.69999 8.10002H4.49999V16.2ZM16.2 9.16202L15.057 15.462C15.0186 15.6717 14.9071 15.8609 14.7423 15.9961C14.5775 16.1312 14.3701 16.2035 14.157 16.2H6.29999V7.38902L8.74798 1.88104C8.99997 1.9545 9.23399 2.0794 9.43527 2.24786C9.63655 2.41632 9.80073 2.62467 9.91744 2.85978C10.0341 3.09488 10.1008 3.35162 10.1133 3.6138C10.1258 3.87598 10.0838 4.1379 9.98998 4.38303L9.51298 5.67003C9.41134 5.94206 9.37703 6.23464 9.41297 6.5228C9.44891 6.81097 9.55404 7.08616 9.71938 7.32489C9.88472 7.56362 10.1054 7.7588 10.3625 7.89378C10.6196 8.02875 10.9056 8.09951 11.196 8.10002H15.3C15.4322 8.09981 15.5628 8.12873 15.6826 8.18473C15.8024 8.24073 15.9083 8.32243 15.993 8.42402C16.0797 8.52421 16.1432 8.64231 16.179 8.76988C16.2147 8.89746 16.2219 9.03135 16.2 9.16202Z" />
  </svg>
);
