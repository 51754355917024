export const UserIcon = ({ size = 1, height = null }) => (
  <svg
    width={16 * size}
    height={17 * (height || size)}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_502_4125)">
      <path
        d="M4.66436 4.37536C4.66436 6.28904 6.22168 7.84636 8.13536 7.84636C10.049 7.84636 11.6064 6.28904 11.6064 4.37536C11.6064 2.46168 10.049 0.904358 8.13536 0.904358C6.22168 0.904358 4.66436 2.46168 4.66436 4.37536ZM14.306 15.5597H15.0774V14.7884C15.0774 11.8118 12.6546 9.38903 9.67803 9.38903H6.5927C3.61535 9.38903 1.19336 11.8118 1.19336 14.7884V15.5597H14.306Z"
        fill="#0E0D39"
      />
    </g>
    <defs>
      <clipPath id="clip0_502_4125">
        <rect
          width="14.8096"
          height="15.7352"
          fill="white"
          transform="translate(0.730469 0.287262)"
        />
      </clipPath>
    </defs>
  </svg>
);
