import useResponsiveScreen from 'hooks/useResponsiveScreen';
import styled from 'styled-components';
import { useRef, useState, useEffect } from 'react';
import Heading from '../../Basics/Typography/Heading';
import Stack from '../../Basics/Layout/Stack';
import TextField from '../../Basics/Inputs/TextField';
import PasswordInput from 'components/components/auth/PasswordInput';
import IndigoButton from '../../Basics/Buttons/IndigoButton';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Basics/Layout/Logo';
import { createPortal } from 'react-dom';
import SocialLoginSection from 'components/components/auth/Login/SocialLoginSection';
import { mainFlow, authPlatform } from 'constants/authFlow';
import withAuthHOC from 'components/components/auth/withAuthHOC';
import { useAuth } from 'hooks/useAauth';
import useUser from 'hooks/useUser';
import { CrossIconRounded } from 'assets/icons';
import IconButton from 'components/Basics/Buttons/IconButton';
import { useCallback } from 'react';
import AuthModal from './AuthModal';
import { useTranslation } from 'react-i18next';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const { LOCAL } = authPlatform;
const { REACTIVATE, SIGN_UP, RESET_PASSWORD } = mainFlow;

const LoginPopup = ({ flowManager, loading, setIsLoading }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();
  const { navigateFlow, closeModal, setSharedValue, sharedValue } = flowManager;
  const { refetch } = useUser();

  const [error, setError] = useState('');
  const [loginStarted, setLoginStarted] = useState(false);
  const emailRef = useRef();
  const emailMobileRef = useRef();
  const passwordRef = useRef();
  const passwordMobileRef = useRef();

  const { manager, errorHandlers } = useAuth(LOCAL);

  const handleLogin = useCallback(
    async (e) => {
      setIsLoading(true);
      setLoginStarted(true);
      await manager.login(
        {
          email: isMobile
            ? emailMobileRef.current?.value
            : emailRef.current?.value,
          password: isMobile
            ? passwordMobileRef.current?.value
            : passwordRef.current?.value,
        },
        {
          onError: (e) => {
            console.log(e);
            setIsLoading(false);
            if (errorHandlers.needsVerification(e)) {
              setLoginStarted(false);
              setSharedValue({
                userData: {
                  ...e.response.data.user,
                  email: emailRef.current?.value,
                },
              });
              navigateFlow({
                flow: SIGN_UP,
                method: LOCAL,
                step: 2,
              });
            } else if (errorHandlers.deactivated(e)) {
              navigateFlow({
                flow: REACTIVATE,
                step: 0,
              });
            } else {
              setLoginStarted(false);
              if (e.response.status === 500 || e.response.status === 502) {
                setError(LoginKeys.Error.network);
              } else if (e.key === 'EMAIL_ALREADY_EXISTS') {
                setError(
                  LoginKeys.Error.already_in_use_or_connect_another_method
                );
              } else if (e.key === 'INCORRECT_EMAIL_PASSWORD') {
                setError(LoginKeys.Error.incorrect);
              } else {
                setError(e.response.data.message);
              }
            }
          },
          onSuccess: () => {
            refetch()
              .then(() => {
                closeModal();
              })
              .then(() => {
                setIsLoading(false);
                setLoginStarted(false);
              });
          },
        }
      );
    },
    [
      manager,
      navigateFlow,
      setSharedValue,
      closeModal,
      refetch,
      errorHandlers,
      isMobile,
      setIsLoading,
    ]
  );

  const onEnter = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        handleLogin(event);
        setIsLoading(true);
        setLoginStarted(true);
      }
    },
    [handleLogin, setIsLoading]
  );

  const handleDocumentClick = useCallback(
    (e) => {
      if (loading && loginStarted && !error) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [loading, loginStarted, error]
  );

  useEffect(() => {
    if (isMobile) return;
    if (emailRef.current && passwordRef.current) {
      window.addEventListener('keypress', onEnter);
    }

    if (!loading || error) {
      window.removeEventListener('click', handleDocumentClick, true);
    } else if (loading && loginStarted) {
      window.addEventListener('click', handleDocumentClick, true);
    } else {
      window.removeEventListener('click', handleDocumentClick, true);
    }

    return () => {
      window.removeEventListener('keypress', onEnter);
      window.removeEventListener('click', handleDocumentClick, true);
    };
  }, [
    handleLogin,
    emailRef,
    passwordRef,
    isMobile,
    loading,
    loginStarted,
    error,
    handleDocumentClick,
    onEnter,
  ]);

  useEffect(() => {
    if (!loading) {
      setLoginStarted(false);
      window.removeEventListener('click', handleDocumentClick, true);
    }
    if (error) {
      setLoginStarted(false);
    }
  }, [loading, error, handleDocumentClick]);

  // For mobiles
  useEffect(() => {
    if (!isMobile) return;
    if (emailMobileRef.current && passwordMobileRef.current) {
      window.addEventListener('keypress', onEnter);
    }
    return () => {
      window.removeEventListener('keypress', onEnter);
    };
  }, [handleLogin, emailMobileRef, passwordMobileRef, isMobile, onEnter]);

  // lock scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [closeModal]);

  const modalRoot = useRef(document.getElementById('root'));

  return createPortal(
    <>
      {!isMobile && (
        <AuthModal show={!isMobile} onClose={closeModal}>
          <ModalBody>
            {loading()}
            <Heading variant="BH2" className="text-align-center">
              {t(GeneralKeys.login)}
            </Heading>

            <Stack gap="1.875rem">
              <Stack>
                <SocialLoginSection
                  setError={setError}
                  setIsLoading={setIsLoading}
                  setLoginStarted={setLoginStarted}
                />
              </Stack>
              <Stack>
                <TextField
                  ref={emailRef}
                  placeholder={t(LoginKeys.Login_Form.Placeholder.email)}
                  variant="transparentGray"
                  type="email"
                  padding="13px 1.25rem 14px"
                  fontSize="text-md"
                />
                <PasswordInput
                  ref={passwordRef}
                  placeholder={t(LoginKeys.Login_Form.Placeholder.password)}
                  variant="transparentGray"
                  padding="13px 1.25rem 14px"
                  width="100%"
                  fontSize="text-md"
                  style={{
                    minWidth: '100%',
                    marginTop: '0px',
                    maxHeight: '55px',
                  }}
                  iconColor={'var(--color-indigo-25)'}
                />
                <TextButton
                  className="h8"
                  onClick={() => {
                    navigateFlow({
                      flow: RESET_PASSWORD,
                      step: 1,
                    });
                  }}
                  type="button"
                >
                  {t(LoginKeys.Password.forgot)}
                </TextButton>
                <Stack gap="1.25rem">
                  <Stack align="flex-start">
                    <IndigoButton
                      padding="12px"
                      radius="0.75rem"
                      className="bh5 text-white shadow-none w-100 bh5 h-100"
                      onClick={handleLogin}
                    >
                      {t(GeneralKeys.login)}
                    </IndigoButton>
                    <ErrorContainer className="h7 text-red-500 text-align-left">
                      {t(error)}
                    </ErrorContainer>
                  </Stack>
                  <Stack direction="row" align="center" gap="0.5rem">
                    <Heading variant="H7" className="text-indigo-500">
                      {t(LoginKeys.Login_Form.no_account)}
                    </Heading>

                    <TextButton
                      className="h7 text-blue-500 text-decoration-underline"
                      onClick={(e) => {
                        e.preventDefault();
                        navigateFlow({
                          flow: SIGN_UP,
                          method: LOCAL,
                          step: 0,
                        });
                      }}
                    >
                      {t(LoginKeys.Login_Form.create_account)}
                    </TextButton>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
        </AuthModal>
      )}

      {/* Mobile */}
      <MobileContainer
        className="d-flex flex-column justify-content-center md-hidden"
        show={isMobile}
      >
        {loading()}
        <Container className="cursor-pointer">
          <CloseButton onClick={closeModal}>
            <CrossIconRounded />
          </CloseButton>
          <Logo
            onClick={() => {
              closeModal();
              navigate('/');
            }}
          />
        </Container>
        <div className="px-2" style={{ height: '100vh' }}>
          <Stack gap="0.5rem" width="100%">
            <TextField
              ref={emailMobileRef}
              placeholder={t(GeneralKeys.email)}
              variant="lightGray"
              type="email"
              padding="13px 23px"
              fontSize="h7"
              radius="999px"
              borderWidth="1px"
              shadow="var(--shadow-mobile-text-box)"
              focusOutline="1px solid var(--color-indigo-500)"
            />
            <PasswordInput
              ref={passwordMobileRef}
              placeholder={t(GeneralKeys.password)}
              variant="lightGray"
              padding="13px 23px"
              width="100%"
              fontSize="h7"
              radius="999px"
              borderWidth="1px"
              shadow="var(--shadow-mobile-text-box)"
              iconColor={'var(--color-indigo-25)'}
              focusOutline="1px solid var(--color-indigo-500)"
            />
          </Stack>
          <div className="d-flex justify-content-between align-items-center mt-2">
            <Heading
              variant="H8"
              as="span"
              className="text-red-500"
              style={{ width: '50%' }}
            >
              {t(error.messager)}
            </Heading>
            <TextButton
              className="ms-auto h8"
              onClick={() => {
                navigateFlow({
                  flow: RESET_PASSWORD,
                  step: 1,
                });
              }}
              type="button"
              width="110px"
            >
              {t(LoginKeys.Password.forgot)}
            </TextButton>
          </div>

          <Buttons isMobile={isMobile} gap="30px">
            <IndigoButton
              radius="500px"
              padding="11px 0"
              className="w-100 shadow-none bh5"
              onClick={handleLogin}
            >
              {t(GeneralKeys.login_upper_case)}
            </IndigoButton>
            <Stack width="100%">
              <Line>
                <LineText>{t(GeneralKeys.or_upper_case)}</LineText>
              </Line>
            </Stack>
            <div
              className={`d-flex justify-content-center align-items-center ${
                isMobile && 'flex-column'
              }`}
              style={{ gap: '12px' }}
            >
              <SocialLoginSection
                setError={setError}
                setIsLoading={setIsLoading}
                setLoginStarted={setLoginStarted}
              />
            </div>
          </Buttons>
          <GoToSignUp>
            <Heading
              variant="H8"
              className="m-0 text-center"
              color="var(--color-indigo-200)"
            >
              {t(LoginKeys.Login_Form.no_account)}
            </Heading>
            <Heading variant="H8">
              <TextButton
                onClick={(e) => {
                  e.preventDefault();
                  navigateFlow({
                    flow: SIGN_UP,
                    method: LOCAL,
                    step: 0,
                  });
                }}
              >
                <Heading
                  variant="H8"
                  className="text-blue-500 text-decoration-underline"
                >
                  <u>{t(LoginKeys.Login_Form.sign_up)}</u>
                </Heading>
              </TextButton>
            </Heading>
          </GoToSignUp>
        </div>
      </MobileContainer>
    </>,
    modalRoot.current
  );
};

export default withAuthHOC(LoginPopup);

const ModalBody = styled.div`
  padding: 2.5rem 3.8125rem 3rem !important;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  text-align: center;
  max-width: 442px;
  box-shadow: var(--shadow-modal);
  border: none;
  border-radius: 1.5rem;
  width: 100%;
  margin: 0 auto;
  background: white;

  &:focus {
    outline: none;
  }
`;

const TextButton = styled.button`
  border: none;
  background: none;
  align-self: flex-start;
  margin: 0;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`;

// Mobile
const MobileContainer = styled.div`
  position: fixed;
  left: 0;
  top: 0%;
  right: 0;
  width: 100%;
  background-color: var(--color-pink-50);
  z-index: 1015;
  min-height: 100vh;
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  ${({ isMobile }) =>
    isMobile
      ? {
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }
      : {}}
  gap: ${({ gap }) => gap};
  margin-top: 2.25rem;
  @media (max-width: 769px) {
    margin-top: 1.875rem;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-indigo-100);
  position: relative;
  margin-top: 1rem;
  margin-bottom: 10px;
`;

const LineText = styled.div.attrs({
  className: 'h7 bg-pink-50',
})`
  width: 40px;
  height: max-content;
  text-align: center;
  color: var(--color-indigo-100);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

const Container = styled.div.attrs({})`
  padding: 1.75rem 0;
  align-self: center;
  height: 80px;
  margin-bottom: 2rem;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
`;
const ErrorContainer = styled.div`
  text-align: start;
`;
// d - flex justify - content - center align - items - center mt - 5 gap - 2
const GoToSignUp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.375rem;
`;
