import PlatformIcon from 'components/Basics/PlatformIcon';
import Platform from 'constants/platform';
import { useState } from 'react';
import { useEffect } from 'react';
import { TwitterEmbed } from 'react-social-media-embed';
import styled from 'styled-components';

const Tweet = ({ contentId, inView, onInternalRefChange }) => {
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);

  useEffect(() => {
    if (inView && !alreadyLoaded) {
      setAlreadyLoaded(true);
    }
  }, [inView, alreadyLoaded]);

  if (!alreadyLoaded) return <Empty />;

  return (
    <Item>
      <TweetContainer>
        <TweetWrapper>
          <TwitterEmbed
            url={`https://twitter.com/twitter/status/${contentId}`}
            width="100%"
            embedPlaceholder={
              <TweetsLoading>
                <PlatformIcon platform={Platform.Twitter} variant="size3" />
                <span className="h6 m-0 line-height-1.6 text-indigo-100">
                  Loading Tweet
                </span>
              </TweetsLoading>
            }
            twitterTweetEmbedProps={{
              conversation: 'none',
            }}
          />

          <TweetOverlay
            ref={(node) => {
              if (inView) onInternalRefChange(node);
            }}
          />
        </TweetWrapper>
      </TweetContainer>
    </Item>
  );
};

export default Tweet;

const Empty = styled.div`
  width: 480px;
  height: 360px;
  flex-shrink: 0;
`;

const Item = styled.div`
  width: 480px;
  max-height: 360px;
  overflow: hidden;
  flex-shrink: 0;
  padding: 0.25rem;

  .twitter-tweet-rendered {
    margin: 0 !important;
  }
`;

const TweetContainer = styled.div`
  width: 100%;
  box-shadow: var(--shadow-card-small);
  background: var(--color-white);
  border-radius: 0.625rem;
  overflow: hidden;
  position: relative;
`;

const TweetWrapper = styled.div`
  overflow: scroll;
  width: 100%;
  max-height: 344px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const TweetOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.75rem;
  border: 2px solid #fff;
  pointer-events: none;
  user-select: none;
`;

const TweetsLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 360px;
  flex-shrink: 0;
`;
