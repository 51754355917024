import styled from 'styled-components';
import { useState } from 'react';
export const CircularDownloadIcon = ({
  size = 1,
  color = '#2867E0',
  hover = false,
  ...rest
}) => {
  const [colorState, setColor] = useState(color);
  return (
    <Svg
      width={size * 28}
      height={size * 28}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={colorState}
      onMouseEnter={() => (hover ? setColor('#fff') : null)}
      onMouseLeave={() => (hover ? setColor(color) : null)}
      {...rest}
    >
      <Path
        d="M8.75 21C8.26875 21 7.85692 20.8288 7.5145 20.4864C7.1715 20.1434 7 19.7312 7 19.25V16.625H8.75V19.25H19.25V16.625H21V19.25C21 19.7312 20.8288 20.1434 20.4864 20.4864C20.1434 20.8288 19.7312 21 19.25 21H8.75ZM14 17.5L9.625 13.125L10.85 11.8563L13.125 14.1312V7H14.875V14.1312L17.15 11.8563L18.375 13.125L14 17.5Z"
        fill={colorState}
      />
      <Rect
        x="1"
        y="1"
        width="26"
        height="26"
        rx="13"
        stroke={color}
        strokeWidth="2"
      />
    </Svg>
  );
};
//'linear-gradient(#14BDE6,#80A2F4,#E274FF)'
const Svg = styled.svg`
  transition: all 0.3s ease;
  border-radius: 50%;
  background: ${({ color }) => (color === '#2867E0' ? '#fff' : '#2867E0')};
`;
const Path = styled.path`
  transition: all 0.3s ease;
`;
const Rect = styled.rect`
  transition: all 0.3s ease;
`;
