export const DiscordIcon = ({ size = 1 }) => (
  <svg
    width={size * 36}
    height={size * 27}
    viewBox="0 0 36 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_17_1280)">
      <path
        d="M5.50463 2.22393C7.86553 1.1713 10.3632 0.423355 12.9316 -0.00012207C13.2515 0.551034 13.6253 1.29234 13.8831 1.88209C16.6523 1.4853 19.396 1.4853 22.1143 1.88209C22.3719 1.29247 22.7541 0.551034 23.077 -0.00012207C25.6479 0.42355 28.1477 1.1734 30.5097 2.22943C35.21 8.9978 36.4842 15.5979 35.8472 22.1045C32.7296 24.323 29.7084 25.6706 26.7382 26.5525C26 25.5852 25.3474 24.561 24.7873 23.4907C25.8537 23.1038 26.8818 22.627 27.8594 22.0659C27.6021 21.8842 27.3509 21.6949 27.1062 21.4982C21.1828 24.1383 14.7466 24.1383 8.89383 21.4982C8.64801 21.6936 8.39691 21.8829 8.14078 22.0659C9.11988 22.6284 10.1499 23.1062 11.2185 23.4935C10.6552 24.5682 10.0038 25.5933 9.26761 26.5552C6.29452 25.6734 3.27052 24.3258 0.152999 22.1045C-0.594564 14.5618 1.42987 8.02212 5.50463 2.2238V2.22393ZM23.9802 18.1031C25.7584 18.1031 27.2167 16.5211 27.2167 14.5949C27.2167 12.6686 25.7895 11.084 23.9802 11.084C22.1708 11.084 20.7127 12.6657 20.7437 14.5949C20.7409 16.5211 22.1708 18.1031 23.9802 18.1031ZM12.0199 18.1031C13.7981 18.1031 15.2563 16.5211 15.2563 14.5949C15.2563 12.6686 13.8292 11.084 12.0199 11.084C10.2105 11.084 8.75236 12.6657 8.78344 14.5949C8.78344 16.5211 10.2105 18.1031 12.0199 18.1031V18.1031Z"
        fill="#5865F2"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_1280">
        <rect
          width="36"
          height="26.6667"
          fill="white"
          transform="matrix(-1 0 0 1 36 0)"
        />
      </clipPath>
    </defs>
  </svg>
);
