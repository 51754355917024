export const DownloadLightIcon = ({ size = 1 }) => (
  <svg
    width={10 * size}
    height={11 * size}
    viewBox="0 0 10 11"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.75 7.375V9.25H1.25V7.375H0V9.25C0 9.9375 0.5625 10.5 1.25 10.5H8.75C9.4375 10.5 10 9.9375 10 9.25V7.375H8.75ZM8.125 4.875L7.24375 3.99375L5.625 5.60625V0.5H4.375V5.60625L2.75625 3.99375L1.875 4.875L5 8L8.125 4.875Z" />
  </svg>
);
