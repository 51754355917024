import TermsConditionsKeys from '../translationKeys/TermsAndConditionsPageKeys';

export const TermsAndConditionsTranslations = {
  [TermsConditionsKeys.title]: 'Terms and Conditions',

  [TermsConditionsKeys.top_text]: `The following Terms and Conditions (“Terms”) shall apply to all business relations between MegaEvolution Inc. of 8 The Green, STE R, Dover, DE 19901, USA trading as MegaEvolution (hereinafter "MegaEvolution" and/or "Platform") and you the User of our Platform and our services. Please read these Terms carefully before using our Platform. These Terms constitute a legal agreement between you and MegaEvolution governing the use of our Platform and our services. We license use of our Platform and our services to you on the basis of these Terms.`,

  // 1. General Terms
  [TermsConditionsKeys.General_Terms.title]: '1. General Terms',

  [TermsConditionsKeys.General_Terms
    ._1_1]: `1.1. Users within the meaning of these Terms are both Service Users (hereinafter “Service User(s)”) and professional service providers (hereinafter “Creator(s)”) and both collectively “User(s)”.`,
  [TermsConditionsKeys.General_Terms
    ._1_2]: `1.2. For the purposes of these Terms, "Service Users" refers to natural or legal persons, or individuals and companies with legal capacity, who engage in business relations with Creators on the Platform, but who are not acting in the course of, or for the purpose of, commercial or independent professional activities.`,
  [TermsConditionsKeys.General_Terms
    ._1_3]: `1.3. For the purposes of these Terms, "Creators" refers to natural or legal persons, or individuals and companies with legal capacity, who engage in business relations with Service Users and are acting in the course of their commercial or self-employed professional activities on the Platform.`,
  [TermsConditionsKeys.General_Terms
    ._1_4]: `1.4. The provisions set out in these Terms govern your access to and
  your use of our Platform and shall constitute a legally binding
  agreement between you and us.`,
  [TermsConditionsKeys.General_Terms
    ._1_5]: `1.5. Subject to you agreeing to abide by these Terms, we hereby grant
  to you a revocable, non-exclusive and non-transferable license to use
  our Platform on these Terms.`,
  [TermsConditionsKeys.General_Terms
    ._1_6]: `1.6. By registering for an Account, which involves providing us with
  certain mandatory and voluntary information as required for a
  successful registration and using our Platform, you agree and
  acknowledge that:`,
  [TermsConditionsKeys.General_Terms
    ._1_6_1]: `1.6.1. you have read the terms set out in these Terms and agree to be
  bound by and comply with them; and`,
  [TermsConditionsKeys.General_Terms._1_6_2]: `1.6.2. you shall ensure that all
  Users of your Account abide by these Terms.`,
  [TermsConditionsKeys.General_Terms
    ._1_7]: `1.7. You are responsible for maintaining the confidentiality of your
  Account and you are responsible for all activities that occur under
  your Account. You agree that all actions carried out by any person
  through your Account shall be deemed to be an act carried out by you,
  and you shall ensure that all persons who have access to and use your
  Account are authorised to do so. We are not responsible for any loss,
  damage or liabilities arising as a result of or in connection with the
  wrongful, fraudulent or illegal use of your Account.`,
  [TermsConditionsKeys.General_Terms
    ._1_8]: `1.8. We may, at our discretion and with careful consideration, suspend or limit your access to the Platform or your Account at any time. These actions will only be taken in cases where we identify potential legal or ethical issues, such as fraud, money laundering, harassment, or violation of our community guidelines. We will always try to notify you in advance whenever possible, but in certain situations, immediate action may be required to ensure the safety and integrity of the Platform for all users.`,
  [TermsConditionsKeys.General_Terms
    ._1_9]: `1.9. We may make changes, updates, or discontinuations to any part of our Services or the Platform at any time. These adjustments will be made primarily to improve the overall experience and to ensure compliance with legal or ethical standards.`,
  [TermsConditionsKeys.General_Terms
    ._1_10]: `1.10. Supplemental Terms or documents that may be posted on the
  Platform from time to time are hereby expressly incorporated herein by
  reference. We reserve the right, in our sole discretion, to make
  changes or modifications to these Terms at any time and for any
  reason.`,
  [TermsConditionsKeys.General_Terms
    ._1_11]: `1.11. It is your responsibility to periodically review these Terms to
  stay informed of updates. You will be subject to and will be deemed to
  have been made aware of and to have accepted, the changes in any
  revised Terms and Conditions by your continued use of the Platform
  after the date such revised Terms and Conditions are posted.`,
  [TermsConditionsKeys.General_Terms
    ._1_12]: `1.12. The information provided on the Platform is not intended for
  distribution to or use by any person or entity in any jurisdiction or
  country where such distribution or use would be contrary to law or
  regulation or which would subject us to any registration requirement
  within such jurisdiction or country.`,

  // 2. Your relationship with Mega Evolution
  [TermsConditionsKeys.Your_Relationship.title]:
    '2. Your relationship with MegaEvolution',

  [TermsConditionsKeys.Your_Relationship
    ._2_1]: `2.1. MegaEvolution does not offer the services and merely provides the
  technical and organisational infrastructure to ensure the proper
  conduct of transactions on the Platform.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_2]: `2.2. MegaEvolution does not itself become a contracting party to the
  contracts concluded exclusively between the Users.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3]: `2.3. Users themselves are responsible for compliance with legal
  requirements and the assertion of claims arising from the contracts
  they have concluded. In particular, MegaEvolution does not guarantee:`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3_1]: `2.3.1. the accuracy and completeness of the statements and
  declarations made in relation to the Services offered,`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3_2]: `2.3.2. the Services offered as such; and`,
  [TermsConditionsKeys.Your_Relationship
    ._2_3_3]: `2.3.3. the conduct and performance of the relevant Service
  User/Creator, respectively.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_4]: `2.4. Users are required to comply with applicable laws when using the
  Platform and Service.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_5]: `2.5. It is each Creators own responsibility to ensure that their
  offers are lawful and do not infringe the rights of third parties.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_6]: `2.6. Users may not use addresses, contact details and e-mail addresses
  obtained through the use of MegaEvolution for any purpose other than
  contractual and pre-contractual communication. In particular, it is
  forbidden to resell this data or use it to send advertising, unless
  the respective Users have expressly consented to this in advance.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_7]: `2.7. The content published on MegaEvolution by the respective Users is
  generally not reviewed by MegaEvolution and does not represent the
  opinion of MegaEvolution.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_8]: `2.8. MegaEvolution is entitled to check the personal details of the
  Creators by means of suitable official documents in order to confirm
  the correctness of the data entered.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_9]: `2.9. Although MegaEvolution is reviewing and selecting the Creators
  after sign-up, MegaEvolution can only perform a limited review of the
  Qualifications provided by Creators. Therefore, no guarantee can be
  given for the accuracy of the Services provided by the respective
  Creators.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_10]: `2.10. The offer of MegaEvolution contains services which are provided
  in connection with an internet-based online Platform. At
  MegaEvolution, the respective service offers can be accessed at will.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_11]: `2.11. MegaEvolution is not a mediator or arbitrator but offers limited
  dispute resolution services if service was not as described or Creator
  is not responding whether trough electronic means or customer support.
  Any such decision made shall be binding on both parties.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_12]: `2.12. Subject to the forgoing, the service for Service Users is
  limited to giving them the opportunity to get to know Creators and the
  service for Creators, is limited to giving them the opportunity to
  create a profile, through which other Users are encouraged to contact
  them, at their own initiative. MegaEvolution does not guarantee that
  Users will find suitable Creators or that Creators will find suitable
  Users.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_13]: `2.13. MegaEvolution assumes no liability for the content that Creators
  provide, nor the ability to fulfil the requirements for a by the
  Creators advertised service or also any information provided by the
  Creators.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_14]: `2.14. MegaEvolution has no influence on the offers made by Creators.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_15]: `2.15. MegaEvolution reserves the right to save or delete the Creators’
  profile and statistical data in the case of false information and
  gross violations of these Terms without prior notification or naming
  of reasons. In the case of gross deception, falsification of documents
  or other willful misrepresentation, MegaEvolution will take
  appropriate action, including legal action.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_16]: `2.16. Third-party services offered via MegaEvolution are clearly
  marked as such. MegaEvolution assumes no liability for such when using
  such services.`,
  [TermsConditionsKeys.Your_Relationship
    ._2_17]: `2.17. You agree that by accessing the Platform, you have read,
  understood, and agree to be bound by all of these Terms. If you do not
  agree with all of these Terms, then you are expressly prohibited from
  using the Platform, and you must discontinue use immediately.`,

  // 3. My Balance and Refund Policy
  [TermsConditionsKeys.Mega_Balance.title]: '3. My Balance and Refund Policy',

  [TermsConditionsKeys.Mega_Balance
    ._3_1]: `3.1. You can use "My Balance" to withdraw earnings, receive refunds, or make purchases (some services excluded).`,
  [TermsConditionsKeys.Mega_Balance
    ._3_2]: `3.2. Refunds will be processed either directly to the original payment method or to My Balance, depending on the service and order status.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_3]: `3.3. Refunds in My Balance can be used for purchases but cannot be returned to the original payment method after a certain period depending on the payment provider (typically 3-6 months).`,
  [TermsConditionsKeys.Mega_Balance
    ._3_4]: `3.4. My Balance is displayed in USD.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_5]: `3.5. Refunds and withdrawals via My Balance will be processed promptly through the respective payment providers; however, delays may occur due to system issues or other unforeseen circumstances.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_6]: `3.6. Earnings can be withdrawn via My Balance after a certain period has passed following the completion of an order, depending on the type of service.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_7]: `3.7. Users are responsible for all taxes and fees applied to the use of My Balance or withdrawals.`,
  [TermsConditionsKeys.Mega_Balance
    ._3_8]: `3.8. In the event that any issues arise with the payment provider during refunds or withdrawals via My Balance, disputes must be resolved directly with the relevant payment provider.`,

  // 4. Prices and Payment
  [TermsConditionsKeys.Prices_Payment.title]: '4. Prices and Payment',

  [TermsConditionsKeys.Prices_Payment
    ._4_1]: `4.1. The prices stated for the respective services by Creators shall
  apply at the time the order is placed.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_2]: `4.2. The prices shown to the buyer during checkout are final and include the statutory value added tax, insofar as this is applicable to the respective service. Varying Service Fees may be additionally applied and the amount of the Service Fees will depend on the user’s subscription plan and the price of the service.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_3]: `4.3. The applicable prices are shown in the respective service
  description. The Service Users undertakes to pay this price. Depending
  on the order, the Payment may be due upon ordering the service or
  product or once the order is fully accepted by the Creator.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_4]: `4.4. Prices and methods of payments are published on the Platform, the
  content of which is included in these Terms by reference.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_5]: `4.5. Payment is considered to be completed when the MEGA Balance is
  credited to MegaEvolution’ account or a third party, collecting fees
  on behalf of Creators.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_6]: `4.6. The Service User is solely responsible for ensuring that the
  payment made is correct and abiding by any taxes applicable in their
  own country of residence.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_7]: `4.7. The Service User is solely responsible for paying for third-party
  services (such as services communications, Internet, etc.) required to
  receive services.`,
  [TermsConditionsKeys.Prices_Payment
    ._4_8]: `4.8. In the event of default in payment, MegaEvolution may suspend the
  continuation of all current orders, irrespective of the exercise of
  other rights.`,

  // 5. Creator Renumeration and Payout
  [TermsConditionsKeys.Creator_Renumeration.title]:
    '5. Creator Renumeration and Payout',

  [TermsConditionsKeys.Creator_Renumeration
    ._5_1]: `5.1. When providing services available for purchase on MegaEvolution,
  the Creator will be required to select a price for the Service.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_2]: `5.2. When a Service User purchases services, MegaEvolution calculates
  the gross amount of the sale as the amount actually received by
  MegaEvolution from the Service User. From this, MegaEvolution will
  subtract any Transaction Taxes and a varying amount of service and
  processing fee depending on the price and subscription plans, and any
  amounts paid to third parties in connection with the content to
  calculate the net amount of the sale.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_3]: `5.3. MegaEvolution reserves the right to change the in subsection 2
  described service and processing fee at any time.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_4]: `5.4. MegaEvolution is not responsible for currency conversion fees,
  wiring fees, or any other processing fees that you may incur. Your
  earnings report will show the sales price and your converted earnings
  amount.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_5]: `5.5. MegaEvolution and the Creator each expressly agree and understand
  that they are creating an independent contractor relationship, and
  that the Creator shall not be considered an employee of MegaEvolution
  for any purpose. The Creator is not entitled to receive or participate
  in any medical, retirement, vacation, paid or unpaid leave, or other
  benefits provided by MegaEvolution to its employees.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_6]: `5.6. The Creator is exclusively responsible for all Social Security,
  self-employment, and income taxes, disability insurance, workers’
  compensation insurance, any other statutory benefits otherwise
  required to be provided to employees, and all fees and licenses, if
  any, required for the performance of the services hereunder.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_7]: `5.7. To withdraw earnings, the Creator must have an account with at
  least one of MegaEvolution's Payment Service Providers for the
  withdrawal methods listed below. All funds eligible for Withdrawal
  will be held on your behalf at an account with MegaEvolution’s Payment
  Services Provider. All payment services, including withdrawal
  services, will be provided by MegaEvolution’s Payment Services
  Provider.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_8]: `5.8. The Creator's MegaEvolution profile can be associated with only
  one account from each MegaEvolution withdrawal method. A Payment
  Service Provider withdrawal account can be associated with only one
  MegaEvolution profile.`,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_9]: `5.9. Creators can withdraw their earnings after a certain number of days following the completion of an order. The earnings will be made available for withdrawal on the billing page. `,
  [TermsConditionsKeys.Creator_Renumeration
    ._5_10]: `5.10. For security concerns, MegaEvolution may temporarily disable a
  Creator’s ability to withdraw earnings to prevent fraudulent or illicit
  activity. This may come as a result of security issues, improper
  behaviour reported by other Users, or associating multiple
  MegaEvolution accounts to a single withdrawal provider.`,

  // 6. User Representations
  [TermsConditionsKeys.User_Representations.title]: '6. User Representations',

  [TermsConditionsKeys.User_Representations
    ._6_1]: `6.1. By using the Platform, you represent and warrant that:`,
  [TermsConditionsKeys.User_Representations
    ._6_1_1]: `6.1.1. all registration information you submit will be true, accurate,
  current, and complete;`,
  [TermsConditionsKeys.User_Representations
    ._6_1_2]: `6.1.2. you will maintain the accuracy of such information and promptly
  update such registration information as necessary;`,
  [TermsConditionsKeys.User_Representations
    ._6_1_3]: `6.1.3. you have the legal capacity, and you agree to comply with these
  Terms;`,
  [TermsConditionsKeys.User_Representations
    ._6_1_4]: `6.1.4. you are not under the age of 18;`,
  [TermsConditionsKeys.User_Representations
    ._6_1_5]: `6.1.5. you will not access the Platform through automated or non-human
  means, whether through a bot, script, or otherwise;`,
  [TermsConditionsKeys.User_Representations
    ._6_1_6]: `6.1.6. you will not use the Platform for any illegal or unauthorised
  purpose;`,
  [TermsConditionsKeys.User_Representations
    ._6_1_7]: `6.1.7. your use of the Platform will not violate any applicable law or
  regulation.`,
  [TermsConditionsKeys.User_Representations
    ._6_1_8]: `6.1.8. If you provide any information that is untrue, inaccurate, not
  current, or incomplete, we have the right to suspend or terminate your
  account and refuse any and all current or future use of the Platform
  (or any portion thereof).`,

  // 7. No reliance on information and limitation of liability
  [TermsConditionsKeys.No_Reliance.title]:
    '7. No reliance on information and limitation of liability',

  [TermsConditionsKeys.No_Reliance
    ._7_1]: `7.1. Commentary and other materials posted on our Platform are not
  intended to amount to advice on which reliance should be placed; they
  are there for guidance purposes only.`,
  [TermsConditionsKeys.No_Reliance
    ._7_2]: `7.2. MegaEvolution makes no representations, warranties, or
  guarantees, whether express or implied, that the content on the
  Platform is accurate, complete, or up to date.`,
  [TermsConditionsKeys.No_Reliance
    ._7_3]: `7.3. MegaEvolution will not be liable for any loss or damage
  including, without limitation, loss of profit, indirect, incidental,
  or consequential loss, or any damages whatsoever arising from the use
  of, or in connection with such use or loss of use of, the Platform,
  whether in contract or tort (including negligence), breach of
  statutory duty, or otherwise, even if foreseeable.`,
  [TermsConditionsKeys.No_Reliance
    ._7_4]: `7.4. We will not be liable for any loss or damage caused by a virus,
  distributed denial-of-service attack, or other technologically harmful
  material that may infect your device equipment, device programs, data
  or other proprietary material due to your use of the Platform or to
  your downloading of any content on it, or on any Platform linked to
  it.`,
  [TermsConditionsKeys.No_Reliance
    ._7_5]: `7.5. This does not affect our liability for death or personal injury
  arising from our negligence, nor our liability for fraudulent
  misrepresentation, nor any other liability which cannot be excluded or
  limited under applicable law.`,

  // 8. Technical requirements and responsibility
  [TermsConditionsKeys.Technical_Requirements.title]:
    '8. Technical requirements and responsibility',

  [TermsConditionsKeys.Technical_Requirements
    ._8_1]: `8.1. Users are responsible for ensuring that the technical
  requirements for access to and use of the respective services are met.`,
  [TermsConditionsKeys.Technical_Requirements
    ._8_2]: `8.2. This applies in particular to the hardware and operating system
  software used, the connection to the Internet, the firewall settings
  (if any) and the current browser software. The User shall carry out
  necessary and reasonable adjustment measures himself/herself and shall
  bear the costs for the Internet connection in order to be able to
  access the services.`,

  // 9. Uploading content to our Platform
  [TermsConditionsKeys.Uploading_Content.title]:
    '9. Uploading content to our Platform',

  [TermsConditionsKeys.Uploading_Content
    ._9_1_l1]: `9.1. You irrevocably and unconditionally represent and warrant that
    any of your content uploaded to our Platform complies with our`,
  [TermsConditionsKeys.Uploading_Content._9_1_l2]: `Privacy Policy`,
  [TermsConditionsKeys.Uploading_Content
    ._9_1_l3]: `and any other applicable laws.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_2]: `9.2. You are fully responsible for your content uploaded to our
  Platform. We will not be responsible, or liable to any third party,
  for:`,
  [TermsConditionsKeys.Uploading_Content
    ._9_2_1]: `9.2.1. the content or accuracy of any content or data uploaded by you,
  by us on your behalf, or any other User of our Platform; or`,
  [TermsConditionsKeys.Uploading_Content
    ._9_2_2]: `9.2.2. the loss of any content or data (whether in physical or digital
    form) provided to us by you. You should keep a record of all such
    content and data.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_3]: `9.3. We will only use the content uploaded by you for the purposes of
  providing the Services, carrying out our obligations in this Agreement
  and any other purpose expressly set out in this Agreement or otherwise
  agreed between us. We will not otherwise disclose or distribute the
  content uploaded by you, save for when required by law, a court of
  competent jurisdiction or any governmental or regulatory authority.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_4]: `9.4. We may use the content uploaded by you for the purpose of data
  analytics or to implement artificial intelligence or machine learning.
  Any such content shall be anonymized and used only for the purposes of
  improving the Services and our response to Users of the Platform.`,
  [TermsConditionsKeys.Uploading_Content
    ._9_5]: `9.5. We have the right to disclose your identity to any third party
  claiming that any content posted or uploaded by you to our Platform
  constitutes a violation of their rights under applicable law.`,

  // 10. Contribution License
  [TermsConditionsKeys.Contribution_License.title]: '10. Contribution License',

  [TermsConditionsKeys.Contribution_License
    ._10_1]: `10.1. By connecting your social media accounts on the Platform, you acknowledge and agree that your content in those accounts may be displayed on your profile, reviews, or other parts of the Platform to facilitate collaboration with other users. However, this does not grant the Platform any rights or licenses to your content. You retain full ownership and control over your content, and any rights related to your content are solely yours. The Platform may display your content as necessary to enhance your experience and the collaborative process, but this does not extend to any commercial use, distribution, or modification of your content beyond these limited purposes. You continue to hold all rights to your content, and we will ensure that any use of it within the Platform respects your ownership and complies with this agreement.`,
  [TermsConditionsKeys.Contribution_License
    ._10_2]: `10.2. The Platform does not have any rights or licenses to your content beyond what is necessary for displaying your content on your profile, in reviews, or in other areas of the Platform to facilitate collaboration and interaction with other users. You retain full ownership of your content, including all intellectual property and moral rights. The Platform may use your name, profile image, and other publicly shared information solely for the purpose of facilitating interaction on the Platform, and only in ways that respect your rights and are consistent with the nature of the Platform.`,
  [TermsConditionsKeys.Contribution_License
    ._10_3]: `10.3. We do not assert any ownership over your Contributions. You
  retain full ownership of all of your Contributions and any
  intellectual property rights, or other proprietary rights associated
  with your Contributions. We are not liable for any statements or
  representations in your Contributions provided by you in any area on
  the Platform.`,
  [TermsConditionsKeys.Contribution_License
    ._10_4]: `10.4. You are solely responsible for your Contributions to the
  Platform, and you expressly agree to exonerate us from any and all
  responsibility and to refrain from any legal action against us
  regarding your Contributions.`,
  [TermsConditionsKeys.Contribution_License
    ._10_5]: `10.5. We have the right, in our sole and absolute discretion,`,
  [TermsConditionsKeys.Contribution_License
    ._10_6]: `10.6. to edit, redact, or otherwise change any Contributions;`,
  [TermsConditionsKeys.Contribution_License
    ._10_7]: `10.7. to re-categorise any Contributions to place them in more
  appropriate locations on the Platform; and`,
  [TermsConditionsKeys.Contribution_License
    ._10_8]: `10.8. to pre-screen or delete any Contributions at any time and for
  any reason, without notice.`,
  [TermsConditionsKeys.Contribution_License
    ._10_9]: `10.9. We have no obligation to monitor your Contributions.`,

  // 11. Restrictions
  [TermsConditionsKeys.Restrictions.title]: '11. Restrictions',

  [TermsConditionsKeys.Restrictions
    ._11_1]: `11.1. Except as expressly set out in this Agreement or as permitted by
  any applicable law, you undertake:`,
  [TermsConditionsKeys.Restrictions
    ._11_1_1]: `11.1.1. not to reproduce, copy, modify, adapt, translate, publish,
  display, communicate, transmit, sell, exploit or use the whole or any
  part of any Service, our Platform or any of the contents therein for
  any commercial or other purposes;`,
  [TermsConditionsKeys.Restrictions
    ._11_1_2]: `11.1.2. not to disassemble, decompile, reverse-engineer or create
  derivative works based on the whole or any part of the source code of
  our Platform nor attempt to do any such thing, or to reproduce,
  display or otherwise provide access to the Services, our Platform or
  any of the contents therein, including but not limited to framing,
  mirroring, linking, spidering, scraping or any other technological
  means;`,
  [TermsConditionsKeys.Restrictions
    ._11_1_3]: `11.1.3. not to provide or otherwise make available our Platform in
  whole or in part (including but not limited to program listings,
  object and source program listings, object code and source code), in
  any form to any person without prior written consent from us;`,
  [TermsConditionsKeys.Restrictions
    ._11_1_4]: `11.1.4. to include our copyright notice on all entire and partial
  copies you make of our Platform on any medium;`,
  [TermsConditionsKeys.Restrictions
    ._11_1_5]: `11.1.5. to comply with all applicable technology control or export
  laws and regulations; and`,
  [TermsConditionsKeys.Restrictions
    ._11_1_6]: `11.1.6. not to disrupt, disable, or otherwise impair the proper
  working of the Services, our Platform, or servers, such as through
  hacking, cyber-attacks (including but not limited to denial-of-service
  attacks), tampering or reprogramming.`,

  // 12. Intellectual Property Rights
  [TermsConditionsKeys.Intellectual_Property.title]:
    '12. Intellectual Property Rights',

  [TermsConditionsKeys.Intellectual_Property
    ._12_1]: `12.1. You acknowledge that all intellectual property rights in our
  Platform anywhere in the world belong to us, that rights in our
  Platform are licensed (not sold) to you, and that you have no rights
  in, or to, our Platform other than the right to use them in accordance
  with these Terms.`,
  [TermsConditionsKeys.Intellectual_Property
    ._12_2]: `12.2. Any intellectual property rights in content uploaded by you to
  our Platform shall continue to belong to you or their respective
  owners. You agree that you grant us a royalty-free and non-exclusive
  license to use, reproduce, publish, and display such intellectual
  property rights for the purposes of performing the Services,
  promotional purposes, internal administrative purposes and any other
  purposes set out in these Terms, including for the purpose of
  improving the Services and our responses to Users of the Platform.`,
  [TermsConditionsKeys.Intellectual_Property
    ._12_3]: `12.3. You acknowledge that you have no right to have access to our
  Platform in source code form.`,

  // 13. Acceptable Use Policy
  [TermsConditionsKeys.Acceptable_Use_Policy.title]:
    '13. Acceptable Use Policy',

  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_1]: `13.1. You may use our Platform only for lawful purposes.`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2]: `13.2. You may not use our Platform:`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_1]: `13.2.1. in any way that breaches any applicable local or international
  laws or regulations;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_2]: `13.2.2. in any way that is unlawful or fraudulent, or has any unlawful
  or fraudulent purpose or effect;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_3]: `13.2.3. to send, knowingly receive, upload, download, use or re-use
  any material which does not comply with our content standards as set
  out in our prevailing Terms as amended from time to time; and`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_4]: `13.2.4. to knowingly transmit any data, send or upload any material
  that contains viruses, Trojan horses, worms, time-bombs, keystroke
  loggers, spyware, adware or any other harmful programs or similar
  device code designed to adversely affect the operation of any device
  software or hardware.`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_5]: `13.2.5. You also agree:`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_6]: `13.2.6. not to reproduce, duplicate, copy or re-sell any part of our
  Platform in contravention of the provisions of our Terms; and`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_7]: `13.2.7. not to access without authority, interfere with, damage or
  disrupt:`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_8]: `13.2.8. any part of our Platform;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_9]: `13.2.9. any equipment or network on which our Platform is stored;`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_10]: `13.2.10.any software used in the provision of our Platform; or`,
  [TermsConditionsKeys.Acceptable_Use_Policy
    ._13_2_11]: `13.2.11.any equipment or network or software owned or used by any
  third party.`,

  // Suspension
  [TermsConditionsKeys.Suspension.title]: '14. Suspension',

  [TermsConditionsKeys.Suspension
    ._14_1]: `14.1. We will determine, in our discretion, whether there has been a
  breach of our Acceptable Use Policy through your use of our Platform.`,
  [TermsConditionsKeys.Suspension
    ._14_2]: `14.2. When a breach of this policy has occurred, we may take such
  action as we deem appropriate.`,
  [TermsConditionsKeys.Suspension
    ._14_3]: `14.3. Failure to comply with our Acceptable Use Policy constitutes a
  material breach of the Terms and Conditions upon which you are
  permitted to use our Platform, and may result in our taking all or any
  of the following actions:`,
  [TermsConditionsKeys.Suspension
    ._14_3_1]: `14.3.1. immediate temporary or permanent cancellation of your right to
  use our Platform;`,
  [TermsConditionsKeys.Suspension
    ._14_3_2]: `14.3.2. immediate temporary or permanent removal of any Contribution;`,
  [TermsConditionsKeys.Suspension
    ._14_3_3]: `14.3.3. issuance of a warning to you;`,
  [TermsConditionsKeys.Suspension
    ._14_3_4]: `14.3.4. legal proceedings against you for reimbursement of all costs
  on an indemnity basis (including but not limited to reasonable
  administrative and legal costs) resulting from the breach;`,
  [TermsConditionsKeys.Suspension
    ._14_3_5]: `14.3.5. further legal action against you; and/or`,
  [TermsConditionsKeys.Suspension
    ._14_3_6]: `14.3.6. disclosure of such information to law enforcement authorities
  as we reasonably feel is necessary.`,
  [TermsConditionsKeys.Suspension
    ._14_4]: `14.4. We exclude liability for actions taken in response to breaches
  of this Acceptable Use Policy . The responses described in this policy
  are not limited, and we may take any other action we reasonably deem
  appropriate.`,

  // 15. Submissions
  [TermsConditionsKeys.Submissions.title]: '15. Submissions',

  [TermsConditionsKeys.Submissions
    ._15_1]: `15.1. You acknowledge and agree that any questions, comments,
  suggestions, ideas, feedback, or other information regarding the
  Platform ("Submissions") provided by you to us are non-confidential
  and shall become our sole property. We shall own exclusive rights,
  including all intellectual property rights, and shall be entitled to
  the unrestricted use and dissemination of these Submissions for any
  lawful purpose, commercial or otherwise, without acknowledgment or
  compensation to you.`,
  [TermsConditionsKeys.Submissions
    ._15_2]: `15.2. You hereby waive all moral rights to any such Submissions, and
  you hereby warrant that any such Submissions are original with you or
  that you have the right to submit such Submissions. You agree there
  shall be no recourse against us for any alleged or actual infringement
  or misappropriation of any proprietary right in your Submissions.`,

  // 16. Modifications And Interruptions
  [TermsConditionsKeys.Modifications.title]:
    '16. Modifications And Interruptions',

  [TermsConditionsKeys.Modifications
    ._16_1]: `16.1. We reserve the right to change, modify, or remove the contents
  of the Platform at any time or for any reason at our sole discretion
  without notice. However, we have no obligation to update any
  information on our Platform. We also reserve the right to modify or
  discontinue all or part of the Platform without notice at any time.`,
  [TermsConditionsKeys.Modifications
    ._16_2]: `16.2. We will not be liable to you or any third party for any
  modification, price change, suspension, or discontinuance of the
  Platform.`,
  [TermsConditionsKeys.Modifications
    ._16_3]: `16.3. We cannot guarantee the Platform will be available at all times.`,
  [TermsConditionsKeys.Modifications
    ._16_4]: `16.4. We may experience hardware, software, or other problems or need
  to perform maintenance related to the Platform, resulting in
  interruptions, delays, or errors.`,
  [TermsConditionsKeys.Modifications
    ._16_5]: `16.5. We reserve the right to change, revise, update, suspend,
  discontinue, or otherwise modify the Platform at any time or for any
  reason without notice to you. You agree that we have no liability
  whatsoever for any loss, damage, or inconvenience caused by your
  inability to access or use the Platform during any downtime or
  discontinuance of the Platform.`,
  [TermsConditionsKeys.Modifications
    ._16_6]: `16.6. Nothing in these Terms will be construed to obligate us to
  maintain and support the Platform or to supply any corrections,
  updates, or releases in connection therewith.`,

  // 17. Availability of the Platform
  [TermsConditionsKeys.Availability.title]: '17. Availability of the Platform',

  [TermsConditionsKeys.Availability
    ._17_1]: `17.1. Our Platform is provided “as is” and on an “as available” basis.
  We give no warranty that our Platform will be free of defects and / or
  faults. To the maximum extent permitted by the law we provide no
  warranties (express or implied) of fitness for a particular purpose,
  accuracy of information, compatibility and satisfactory quality.`,
  [TermsConditionsKeys.Availability
    ._17_2]: `17.2. MegaEvolution accepts no liability for any disruption or
  non-availability of the Platform resulting from external causes
  including, but not limited to, ISP equipment failure, host equipment
  failure, communications network failure, power failure, natural
  events, acts of war or legal restrictions and censorship.`,

  // 18. Corrections
  [TermsConditionsKeys.Corrections.title]: '18. Corrections',

  [TermsConditionsKeys.Corrections
    ._18_1]: `There may be information on the Platform that contains typographical
  errors, inaccuracies, or omissions that may relate to the Platform,
  including descriptions, pricing, availability, and various other
  information. We reserve the right to correct any errors, inaccuracies,
  or omissions and to change or update the information on the Platform
  at any time, without prior notice.`,

  // 19. Privacy
  [TermsConditionsKeys.Privacy.title]: '19. Privacy',

  [TermsConditionsKeys.Privacy
    ._19_1_l1]: `19.1. For the purposes of applicable data protection legislation,
  MegaEvolution will process any personal data you have provided to us
  in accordance with our`,
  [TermsConditionsKeys.Privacy._19_1_l2]: `Privacy Policy`,
  [TermsConditionsKeys.Privacy
    ._19_1_l3]: `available on the MegaEvolution Platform or on request from
  MegaEvolution.`,
  [TermsConditionsKeys.Privacy
    ._19_2]: `19.2. You agree that if you have provided MegaEvolution with personal
  data relating to a third party:`,
  [TermsConditionsKeys.Privacy
    ._19_2_1]: `19.2.1. you have in place all necessary appropriate consents and
  notices to enable lawful transfer such personal data to MegaEvolution
  and`,
  [TermsConditionsKeys.Privacy
    ._19_2_2]: `19.2.2. that you have brought to the attention of any such third party
  the Privacy Notice available on the MegaEvolution’s Platform or
  otherwise provided a copy of it to the third party.`,
  [TermsConditionsKeys.Privacy
    ._19_3]: `19.3. You agree to indemnify MegaEvolution in relation to all and any
  liabilities, penalties, fines, awards, or costs arising from your
  non-compliance with these requirements.`,

  // 20. Term and Termination
  [TermsConditionsKeys.Term_Termination.title]: '20. Term and Termination',

  [TermsConditionsKeys.Term_Termination
    ._20_1]: `20.1. These Terms and Conditions shall remain in full force and effect
  while you use the Platform. Without limiting any other provision of
  these Terms, we reserve the right to, in our sole discretion and
  without notice or liability, deny access to and use of the Platform
  (including blocking certain IP addresses), to any person for any
  reason or for no reason, including without limitation for breach of
  any representation, warranty, or covenant contained in these Terms or
  of any applicable law or regulation. We may terminate your use or
  participation in the Platform or delete your account and any content
  or information that you posted at any time, without warning, in our
  sole discretion.`,
  [TermsConditionsKeys.Term_Termination
    ._20_2]: `20.2. If we terminate or suspend your account for any reason, you are
  prohibited from registering and creating a new account under your
  name, a fake or borrowed name, or the name of any third party, even if
  you may be acting on behalf of the third party.`,
  [TermsConditionsKeys.Term_Termination
    ._20_3]: `20.3. In addition to terminating or suspending your account, we
  reserve the right to take appropriate legal action, including without
  limitation pursuing civil, criminal, and injunctive redress.`,

  // 21. Limitation of Liability

  [TermsConditionsKeys.Limitation_Liability.title]:
    '21. Limitation of Liability',

  [TermsConditionsKeys.Limitation_Liability
    ._21_1]: `21.1. We are not liable for the completeness, accuracy or correctness
  of any information uploaded on our Platform and any Related Content.
  You expressly agree that your use of the Services and our Platform,
  including reliance on any Advice, is at your sole risk.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2]: `21.2. You agree not to use the Services, our Platform and the Related
  Content for any re-sale purposes, and we have no liability to you,
  whether in contract, tort (including negligence), breach of statutory
  duty, or otherwise, arising under or in connection with these Terms
  (including but not limited to the use of, or inability to use, the
  Services, our Platform or any other website or software) for:`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_1]: `21.2.1. loss of profits, sales, business, or earnings;`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_2]: `21.2.2. business interruption;`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_3]: `21.2.3. loss of anticipated savings;`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_4]: `21.2.4. loss or corruption of data or information;`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_5]: `21.2.5. loss of business opportunity, goodwill or reputation; or`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_2_6]: `21.2.6. any other indirect or consequential loss or damage.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_3]: `21.3. Nothing in these Terms shall limit or exclude our liability for:`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_3_1]: `21.3.1. death or personal injury resulting from our negligence;`,
  [TermsConditionsKeys.Limitation_Liability._21_3_2]: `21.3.2. fraud; and/or`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_3_3]: `21.3.3. any other matter in respect of which we are prohibited under
  applicable law from limiting or excluding our liability.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_4]: `21.4. Our Platform is not intended to serve a record-keeping function
  and we shall not be liable for any loss of data or content.`,
  [TermsConditionsKeys.Limitation_Liability
    ._21_5]: `21.5. These Terms set out the full extent of our obligations and
  liabilities in respect of the supply of the Services and our Platform.
  Except as expressly stated in these Terms, there are no conditions,
  warranties, representations or other terms, express or implied, that
  are binding on us. Any condition, warranty, representation or other
  term concerning the supply of the Services and our Platform which
  might otherwise be implied into, or incorporated in, these Terms
  whether by statute or otherwise, is excluded to the fullest extent
  permitted by law.`,

  // 22. Other Important Terms
  [TermsConditionsKeys.Other_Important_Terms.title]:
    '22. Other Important Terms',

  [TermsConditionsKeys.Other_Important_Terms
    ._22_1]: `22.1. We may transfer our rights and obligations under these Terms to
  another organization, but this will not affect your rights or
  obligations under these Terms.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_2]: `22.2. You may only transfer your rights or your obligations under
  these Terms to another person if we agree in writing.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_3]: `22.3. No joint venture, partnership or agency or employment
  relationship has arisen by reason of these Terms.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_4]: `22.4. These Terms and any document expressly referred to in it
  constitutes the entire agreement between us regarding their subject
  matter, and supersedes and extinguishes all previous agreements,
  promises, assurances, warranties, representations, and understandings
  between us, whether written or oral, relating to that subject matter.
  You agree that you shall have no remedies in respect of any statement,
  representation, assurance, or warranty (whether made innocently or
  negligently) that is not set out in these Terms, or any document
  expressly referred to in it. You agree that you shall have no claim
  for innocent or negligent misrepresentation or negligent misstatement
  based on any statement in these Terms or any document expressly
  referred to in it.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_5]: `22.5. If we fail to insist that you perform any of your obligations
  under these Terms, or if we do not enforce our rights against you, or
  if we delay in doing so, that will not mean that we have waived our
  rights against you and will not mean that you do not have to comply
  with those obligations. If we do waive a default by you, we will only
  do so in writing, and that will not mean that we will automatically
  waive any later default by you.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_6]: `22.6. Each of the conditions of these Terms operates separately. If
  any court or competent authority decides that any of them are unlawful
  or unenforceable, the remaining conditions will remain in full force
  and effect.`,
  [TermsConditionsKeys.Other_Important_Terms
    ._22_7]: `22.7. These Terms, its subject matter and its formation, and any other
  disputes or claims in connection therewith, are governed by law of
  South Korea. In the event of any such disputes or claims in connection
  with these Terms, you agree to first engage in good faith discussions
  with us to resolve such dispute or claim. If such dispute or claim is
  not resolved within sixty (60) days, we both irrevocably submit to the
  exclusive jurisdiction of the courts of South Korea.`,
};
