export const InstagramIcon = ({ size = 1 }) => (
  <svg
    width={size * 32}
    height={size * 32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.0154 30.3918L8.01535 30.407C4.49535 30.4102 1.61295 27.5334 1.60895 24.0134L1.59375 8.0134C1.59055 4.4934 4.46735 1.611 7.98735 1.607L23.9874 1.5918C27.5074 1.5886 30.3898 4.4654 30.3938 7.9854L30.409 23.9854C30.413 27.5062 27.5354 30.3886 24.0154 30.3918Z"
      fill="url(#paint0_radial_17_1328)"
    />
    <path
      d="M24.0154 30.3918L8.01535 30.407C4.49535 30.4102 1.61295 27.5334 1.60895 24.0134L1.59375 8.0134C1.59055 4.4934 4.46735 1.611 7.98735 1.607L23.9874 1.5918C27.5074 1.5886 30.3898 4.4654 30.3938 7.9854L30.409 23.9854C30.413 27.5062 27.5354 30.3886 24.0154 30.3918Z"
      fill="url(#paint1_radial_17_1328)"
    />
    <path
      d="M15.9984 21.5999C12.9112 21.5999 10.3984 19.0879 10.3984 15.9999C10.3984 12.9119 12.9112 10.3999 15.9984 10.3999C19.0856 10.3999 21.5984 12.9119 21.5984 15.9999C21.5984 19.0879 19.0856 21.5999 15.9984 21.5999ZM15.9984 11.9999C13.7928 11.9999 11.9984 13.7943 11.9984 15.9999C11.9984 18.2055 13.7928 19.9999 15.9984 19.9999C18.204 19.9999 19.9984 18.2055 19.9984 15.9999C19.9984 13.7943 18.204 11.9999 15.9984 11.9999Z"
      fill="white"
    />
    <path
      d="M22.0008 11.1998C22.6635 11.1998 23.2008 10.6625 23.2008 9.9998C23.2008 9.33706 22.6635 8.7998 22.0008 8.7998C21.338 8.7998 20.8008 9.33706 20.8008 9.9998C20.8008 10.6625 21.338 11.1998 22.0008 11.1998Z"
      fill="white"
    />
    <path
      d="M20.8016 26.4001H11.2016C8.11436 26.4001 5.60156 23.8881 5.60156 20.8001V11.2001C5.60156 8.1121 8.11436 5.6001 11.2016 5.6001H20.8016C23.8888 5.6001 26.4016 8.1121 26.4016 11.2001V20.8001C26.4016 23.8881 23.8888 26.4001 20.8016 26.4001ZM11.2016 7.2001C8.99596 7.2001 7.20156 8.9945 7.20156 11.2001V20.8001C7.20156 23.0057 8.99596 24.8001 11.2016 24.8001H20.8016C23.0072 24.8001 24.8016 23.0057 24.8016 20.8001V11.2001C24.8016 8.9945 23.0072 7.2001 20.8016 7.2001H11.2016Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_17_1328"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12.3058 30.4278) scale(35.9192)"
      >
        <stop stopColor="#FFDD55" />
        <stop offset="0.328" stopColor="#FF543F" />
        <stop offset="0.348" stopColor="#FC5245" />
        <stop offset="0.504" stopColor="#E64771" />
        <stop offset="0.643" stopColor="#D53E91" />
        <stop offset="0.761" stopColor="#CC39A4" />
        <stop offset="0.841" stopColor="#C837AB" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_17_1328"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(6.23055 1.23204) scale(23.8504 15.8915)"
      >
        <stop stopColor="#4168C9" />
        <stop offset="0.999" stopColor="#4168C9" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);
