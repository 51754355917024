import styled from 'styled-components';
import { motion } from 'framer-motion';
import OnlineStatus from './OnlineStatus';
import OrdersTogether from './OrdersTogether';
import Categories from './Categories';
import Countries from './Countries';
import Languages from './Languages';
import Rating from './Rating';
import UserType from 'constants/userType';
import Company from './Company';
import Country from './Country';
import device from 'styles/device';
import Buttons from './Buttons';
import DescriptionBox from './DescriptionBox';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import { SettingsIcon } from 'assets/icons';
import AutoTranslationKeys from 'translations/translationKeys/AutoTranslationKeys';
import Stack from 'components/Basics/Layout/Stack';
import { useTranslation } from 'react-i18next';

const Details = ({
  id,
  handle,
  lastSeen,
  type,
  bio,
  company,
  companyLogo,
  categories,
  countries,
  languages,
  reviews,
}) => {
  const { t } = useTranslation();
  const { displayTranslatedText, toggleDisplayTranslatedText } =
    useAutoTranslation();

  return (
    <Wrapper>
      <OnlineStatus id={id} initialLastSeen={lastSeen} hideOnMobile />
      <OrdersTogether />
      <DescriptionBox bio={bio} />

      {type === UserType.Client && (
        <>
          <Company company={company} companyLogo={companyLogo} />
          <Country country={countries?.[0]} />
        </>
      )}

      {type === UserType.Creator && (
        <>
          <Categories categories={categories} />
          <Countries countries={countries} />
        </>
      )}

      <Languages languages={languages} />
      <Rating
        reviews={
          type === UserType.Creator
            ? reviews?.buyerReviews
            : reviews?.sellerReviews
        }
        hideOnMobile
      />

      {true && (
        <AutoTranslationContainer className="h8 md-hidden">
          <span>
            <SettingsIcon size={0.6} />
          </span>
          <Stack direction="row" gap="3px" className="flex-wrap">
            {displayTranslatedText
              ? t(AutoTranslationKeys.Applying_Translation.title)
              : t(AutoTranslationKeys.Displaying_Original.title)}
            <span
              className="text-decoration-underline"
              onClick={toggleDisplayTranslatedText}
            >
              {displayTranslatedText
                ? t(AutoTranslationKeys.Applying_Translation.btn)
                : t(AutoTranslationKeys.Displaying_Original.btn)}
            </span>
          </Stack>
        </AutoTranslationContainer>
      )}

      <Buttons id={id} handle={handle} type={type} />
    </Wrapper>
  );
};

export default Details;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.2,
      },
    },
  },
  className: 'scrollbar-y-thin',
})`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  padding-inline: 1.5rem;
  overflow-x: hidden;

  @media (${device.sm}) {
    flex: 1;
    padding-inline: 1rem;
    gap: 1rem;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--color-indigo-50);
  }
`;

const AutoTranslationContainer = styled.div`
  width: 100%;
  padding: 0.625rem 0.75rem;
  display: flex;
  align-items: start;
  gap: 0.5rem;
  background: rgba(231, 231, 235, 0.3);
  border-radius: 0.375rem;
  color: var(--color-indigo-200);
`;