import { EnvelopeIcon } from 'assets/icons';
import Image from 'components/Basics/Image';
import Stack from 'components/Basics/Layout/Stack';
import dateFromNow from 'components/utilities/dateFromNow';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Markdown from 'markdown-to-jsx';

const NotificationItem = ({
  id,
  type,
  pic,
  userId,
  userHandle,
  isRead,
  text,
  date,
  navigateTo,
  readNotification,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Wrapper
      layoutId={`notification-${id}`}
      isRead={isRead}
      onClick={() => {
        if (navigateTo && navigateTo !== pathname) navigate(navigateTo);
        if (!isRead) readNotification();
      }}
    >
      <Stack align="center" className="text-indigo-100">
        <ImageButton
          disabled={!userHandle && !userId}
          onClick={(e) => {
            e.stopPropagation();

            if (userHandle || userId)
              navigate(`/${userHandle ? `@${userHandle}` : userId}`);
          }}
        >
          <Image
            src={
              type === 'reminder'
                ? '/img/misc/BellNoti.png'
                : type === 'mega' || !pic
                ? '/img/misc/MegaNoti.png'
                : pic
            }
            width="2.5rem"
            height="2.5rem"
            circle
          />
        </ImageButton>
        <AnimatePresence>
          {!isRead && (
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.1 }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EnvelopeIcon size={0.875} />
            </motion.div>
          )}
        </AnimatePresence>
      </Stack>

      <Stack gap="0.25rem">
        <MarkdownContainer>
          <Markdown>{text}</Markdown>
        </MarkdownContainer>
        <span className="text-xs text-indigo-100">{dateFromNow(date)}</span>
      </Stack>
    </Wrapper>
  );
};

export default NotificationItem;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
})`
  width: 100%;
  padding: 1rem;
  padding-bottom: calc(1rem - 1px);
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  cursor: pointer;
  border-bottom: 1px solid var(--color-indigo-25);
  background: ${({ isRead }) => (isRead ? '#fff' : 'var(--color-pink-100)')};
  transition: background 150ms ease-in-out;

  &:hover {
    background: var(--color-pink-75);
  }

  a {
    color: inherit;
  }
`;

const ImageButton = styled.button`
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MarkdownContainer = styled.div.attrs({
  className: 'h7',
})``;