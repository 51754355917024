export const YoutubeShortsIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 22}
    viewBox="0 0 18 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.177 0.484964C13.2172 -0.596438 15.7433 0.19134 16.8171 2.24493C17.8909 4.29852 17.1076 6.83911 15.0673 7.92051L13.3897 8.8175C14.835 8.87121 16.2133 9.67868 16.935 11.0591C18.0088 13.1127 17.2273 15.6533 15.1853 16.7347L6.23931 21.515C4.19909 22.5964 1.67302 21.8087 0.599221 19.7551C-0.474578 17.7015 0.308768 15.1609 2.34899 14.0795L4.02658 13.1825C2.58135 13.1288 1.20301 12.3213 0.481279 10.9409C-0.592519 8.88733 0.190826 6.34674 2.23104 5.26534L11.177 0.484964ZM6.70898 8.5L11.709 11.5L6.70898 14.5V8.5Z"
    />
  </svg>
);
