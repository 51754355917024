import Platform, { idRegex } from 'constants/platform';

// extract id from url of youtube or twitter
const extractId = (url, platform = Platform.YouTube) => {
  const regex = idRegex[platform];

  if (!regex.test(url)) return '';

  return url.match(regex)[1];
};

export default extractId;
