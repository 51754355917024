import { VisibilityIcon, InvisibilityIcon } from 'assets/icons';
import TextField from 'components/Basics/Inputs/TextField';
import Text from 'components/Basics/Typography/Text';
import { useState, forwardRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
`;

const Error = styled(Text).attrs({
  className: 'h8 text-red-500 w-50',
})`
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  text-align: start;

  @media (max-height: 769px) {
    min-width: 100%;
  }
`;

const IconButton = styled.button`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 769px) {
    color: var(--color-indigo-50);
    right: 1.5rem;
  }
`;

const PasswordInput = (
  { variant, value, onChange, error, iconColor, ...props },
  ref
) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Wrapper>
      <TextField
        variant={variant}
        type={showPassword ? 'text' : 'password'}
        fontSize="text-lg"
        value={value}
        onChange={onChange}
        error={error}
        ref={ref}
        {...props}
      />
      <IconButton type="button" onClick={() => setShowPassword(!showPassword)}>
        {showPassword ? <InvisibilityIcon /> : <VisibilityIcon />}
      </IconButton>
      {error && <Error>{error}</Error>}
    </Wrapper>
  );
};

export default forwardRef(PasswordInput);
