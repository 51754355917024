export const PinSolidIcon = ({ size = 1 }) => (
  <svg
    width={size * 9}
    height={size * 9}
    viewBox="0 0 9 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.84889 0L5.60565 0.331695L3.86978 2.69779C2.93905 2.53661 1.95572 2.79858 1.23833 3.51597L0.984029 3.75921L1.23833 4.01351L2.86364 5.63882L0 8.50246V9H0.497543L3.36118 6.13636L4.98649 7.76167L5.24079 8.01597L5.48403 7.76167C6.20142 7.04428 6.46339 6.06095 6.30221 5.13022L8.6683 3.39435L9 3.15111L8.71253 2.86364L6.13636 0.287469L5.84889 0ZM5.93735 1.08354L7.91646 3.06265L5.71622 4.6769L5.5172 4.82064L5.58354 5.05283C5.7541 5.70153 5.56798 6.37697 5.16339 6.94349L2.05651 3.83661C2.62302 3.43201 3.29847 3.2459 3.94717 3.41646L4.17936 3.4828L4.3231 3.28378L5.93735 1.08354Z" />
    <rect
      x="5.94141"
      y="0.755859"
      width="3.13943"
      height="2.09206"
      transform="rotate(43.395 5.94141 0.755859)"
    />
    <rect
      x="4.83203"
      y="1.88867"
      width="3.13943"
      height="2.09206"
      transform="rotate(43.395 4.83203 1.88867)"
    />
    <rect
      x="2.96875"
      y="2.84961"
      width="4.52876"
      height="1.67836"
      transform="rotate(44.8945 2.96875 2.84961)"
    />
  </svg>
);
