import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import Loading from 'components/Basics/Loading/SpinnerLoading';
import Stack from 'components/Basics/Layout/Stack';
import Heading from 'components/Basics/Typography/Heading';
import device from 'styles/device';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const withAuthHOC = (Component) => {
  function EmbedManager(props) {
    const flowManager = useAuthFlowManager();
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const LoadingView = () => {
      return isLoading ? (
        <LoadingContainer>
          <Stack direction="row" gap="16px">
            <Loading size={24} thickness={3} />
            <Heading variant="H6">{t(GeneralKeys.loading)}</Heading>
          </Stack>
        </LoadingContainer>
      ) : (
        <></>
      );
    };

    return (
      <Component
        flowManager={flowManager}
        setIsLoading={setIsLoading}
        loading={LoadingView}
        {...props}
      ></Component>
    );
  }
  return EmbedManager;
};

export default withAuthHOC;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  @media (max-width: 769px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1015;
    min-width: 100vw;
    min-height: 100vh;
  }

  @media ${device.md} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    border-radius: 30px;
  }
`;
