export const RetweetIcon = ({ size = 1 }) => (
  <svg
    width={size * 24}
    height={size * 16}
    viewBox="0 0 24 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.7793 11.379C23.4871 11.0957 23.0116 11.0957 22.7184 11.379L20.4964 13.5251V3.62575C20.4964 1.62654 18.812 0.000483572 16.7431 0.000483572H10.888C10.4737 0.000483572 10.1374 0.325308 10.1374 0.725537C10.1374 1.12577 10.4737 1.45059 10.888 1.45059H16.7431C17.9842 1.45059 18.9951 2.427 18.9951 3.62575V13.5251L16.7732 11.379C16.4799 11.0957 16.0045 11.0957 15.7122 11.379C15.42 11.6622 15.418 12.1214 15.7122 12.4037L19.2153 15.7873C19.3604 15.9294 19.5526 16 19.7458 16C19.9389 16 20.1291 15.9304 20.2762 15.7873L23.7793 12.4037C24.0736 12.1214 24.0736 11.6622 23.7793 11.379ZM13.11 14.5499H7.25485C6.01376 14.5499 5.00288 13.5735 5.00288 12.3747V2.47533L7.22482 4.62149C7.37295 4.7636 7.56512 4.83417 7.75729 4.83417C7.94946 4.83417 8.14162 4.7636 8.28775 4.62149C8.58101 4.33824 8.58101 3.87904 8.28775 3.59675L4.78469 0.213166C4.49143 -0.0710553 4.01601 -0.0710553 3.72376 0.213166L0.220693 3.59675C-0.0735644 3.87904 -0.0735644 4.33824 0.220693 4.62149C0.514951 4.90475 0.988365 4.90475 1.28162 4.62149L3.50357 2.47533V12.3747C3.50357 14.3739 5.18804 16 7.25685 16H13.112C13.5263 16 13.8626 15.6752 13.8626 15.2749C13.8626 14.8747 13.5253 14.5499 13.112 14.5499H13.11Z" />
  </svg>
);
