import Tooltip from 'components/Basics/Tooltip';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import device from 'styles/device';

const Option = ({ name, onClick }) => {
  const ref = useRef();

  const [clientWidth, setClientWidth] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const { t } = useTranslation();

  useLayoutEffect(() => {
    setClientWidth(ref.current?.clientWidth);
    setScrollWidth(ref.current?.scrollWidth);
  }, [name]);

  // check if component is in view
  const [inView, setInView] = useState(false);
  useLayoutEffect(() => {
    const element = ref.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setInView(true);
        } else {
          setInView(false);
        }
      },
      {
        threshold: 0.1,
        // closest parent element of ref with class dropdown-menu-parent
        root: element.closest('.dropdown-menu-parent'),
      }
    );
    observer.observe(element);
    return () => {
      observer.disconnect();
    };
  }, [name]);

  return (
    <Tooltip
      content={name}
      margin="-0.25rem"
      disabled={clientWidth >= scrollWidth || !inView}
    >
      <TextButton className="h6" onClick={onClick} ref={ref}>
        {t(name)}
      </TextButton>
    </Tooltip>
  );
};

export default Option;

const TextButton = styled.button`
  background: none;
  border: none;
  padding: 4px 0;
  margin: 0;
  text-align: left;
  min-width: 289px;
  max-width: 289px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;

  @media ${device.sm} {
    min-width: 100%;
  }

  &:focus {
    outline: none;
    background: var(--color-indigo-10);
  }

  &:hover {
    background: var(--color-indigo-10);
  }
`;
