export const UserLightIcon = ({ size = 1 }) => (
  <svg
    width={16 * size}
    height={17 * size}
    viewBox="0 0 16 17"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.9707 9.07054C11.7589 8.45042 12.3342 7.60005 12.6166 6.63774C12.899 5.67543 12.8744 4.64902 12.5463 3.70133C12.2182 2.75363 11.6028 1.93177 10.7859 1.35009C9.96892 0.768398 8.99097 0.455811 7.98808 0.455811C6.98519 0.455811 6.00723 0.768398 5.19027 1.35009C4.37331 1.93177 3.75797 2.75363 3.42985 3.70133C3.10173 4.64902 3.07716 5.67543 3.35954 6.63774C3.64193 7.60005 4.21723 8.45042 5.00542 9.07054C3.65485 9.61163 2.47642 10.5091 1.59578 11.6672C0.715138 12.8254 0.165288 14.2008 0.0048493 15.6469C-0.00676409 15.7524 0.00253114 15.8593 0.0322041 15.9612C0.061877 16.0632 0.111346 16.1584 0.177788 16.2412C0.311973 16.4086 0.507144 16.5158 0.720365 16.5392C0.933586 16.5627 1.14739 16.5005 1.31475 16.3663C1.4821 16.2321 1.5893 16.0369 1.61275 15.8237C1.78929 14.2522 2.53865 12.8007 3.71767 11.7467C4.89669 10.6927 6.4227 10.1101 8.00416 10.1101C9.58561 10.1101 11.1116 10.6927 12.2906 11.7467C13.4697 12.8007 14.219 14.2522 14.3956 15.8237C14.4174 16.0213 14.5117 16.2037 14.6601 16.3358C14.8086 16.468 15.0008 16.5404 15.1995 16.5392H15.2879C15.4987 16.515 15.6913 16.4084 15.8238 16.2428C15.9563 16.0771 16.018 15.8658 15.9954 15.6549C15.8342 14.2047 15.2814 12.8258 14.3962 11.6659C13.5111 10.5059 12.3269 9.60877 10.9707 9.07054ZM7.98808 8.49973C7.35205 8.49973 6.73031 8.31113 6.20147 7.95777C5.67264 7.60442 5.26046 7.10218 5.01706 6.51457C4.77367 5.92695 4.70998 5.28036 4.83406 4.65656C4.95815 4.03276 5.26442 3.45975 5.71416 3.01002C6.1639 2.56028 6.7369 2.254 7.3607 2.12992C7.98451 2.00584 8.6311 2.06952 9.21871 2.31292C9.80632 2.55631 10.3086 2.96849 10.6619 3.49733C11.0153 4.02616 11.2039 4.64791 11.2039 5.28393C11.2039 6.13682 10.8651 6.95477 10.262 7.55785C9.65891 8.16093 8.84096 8.49973 7.98808 8.49973Z" />
  </svg>
);
