export const MegaIcon = ({ size = 1, className, ...rest }) => (
  <svg
    width={20 * size}
    height={20 * size}
    viewBox="0 0 20 20"
    className={className}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M14.9691 8.98227L14.9438 0.811881C14.9438 0.660235 14.7954 0.552397 14.6521 0.599576C6.88025 3.11691 2.92268 8.99743 2.03236 10.4684C1.94299 10.6167 2.04922 10.8071 2.2229 10.8071H4.80114C4.92423 10.8071 5.02372 10.9065 5.0254 11.0295L5.0507 19.1881C5.0507 19.3414 5.20414 19.4509 5.35085 19.3987C13.4464 16.4938 17.1342 10.9554 17.9689 9.53999C18.0565 9.39171 17.9486 9.20468 17.775 9.20468H15.1917C15.0686 9.20468 14.9691 9.10527 14.9674 8.98227H14.9691Z" />
  </svg>
);

