export const ChevronLeftIcon = ({ size = 1 }) => (
  <svg
    width={9 * size}
    height={14 * size}
    viewBox="0 0 9 14"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.93356 1.6792L2.59375 7.01901L7.93356 12.3588"
      strokeWidth="2.50485"
    />
  </svg>
);

<svg
  width="5"
  height="8"
  viewBox="0 0 5 8"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M3.58743 0.460402C3.84615 0.205135 4.26196 0.205136 4.52068 0.460402C4.78435 0.720562 4.78435 1.1461 4.52068 1.40626L2.61335 3.28816C2.21647 3.67974 2.21647 4.32026 2.61335 4.71184L4.52068 6.59374C4.78435 6.8539 4.78435 7.27944 4.52068 7.5396C4.26196 7.79486 3.84615 7.79486 3.58743 7.5396L0.721456 4.71184C0.324583 4.32026 0.324583 3.67974 0.721456 3.28816L3.58743 0.460402Z"
    fill="white"
  />
</svg>;
