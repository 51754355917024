export const VolumeOffIcon = ({ size = 1 }) => (
  <svg
    width={23 * size}
    height={32 * size}
    viewBox="0 0 23 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.8831 0.164601C20.5579 0.0239299 20.2012 -0.0279475 19.8495 0.0142629C19.4977 0.0564734 19.1635 0.19126 18.8808 0.404866L9.31028 7.97322H2.00221C1.47119 7.97322 0.961922 8.18417 0.586434 8.55966C0.210947 8.93514 0 9.44441 0 9.97543V21.9887C0 22.5197 0.210947 23.029 0.586434 23.4045C0.961922 23.78 1.47119 23.9909 2.00221 23.9909H9.31028L18.7807 31.5593C19.133 31.8419 19.5705 31.9971 20.0221 31.9998C20.3212 32.0047 20.6169 31.9359 20.8831 31.7995C21.2238 31.6373 21.5117 31.382 21.7136 31.0632C21.9155 30.7443 22.0232 30.3749 22.0243 29.9975V1.96659C22.0232 1.5892 21.9155 1.2198 21.7136 0.900972C21.5117 0.582145 21.2238 0.326878 20.8831 0.164601ZM18.0199 25.8329L11.2524 20.427C10.9002 20.1443 10.4627 19.9891 10.0111 19.9865H4.00442V11.9776H10.0111C10.4627 11.975 10.9002 11.8198 11.2524 11.5372L18.0199 6.13119V25.8329Z" />
  </svg>
);
