import styled from 'styled-components';
import FileType from './FileType';
import VideoPreview from './VideoPreview';
import ImagePreview from './ImagePreview';
import IconPreview from './IconPreview';
import { useMemo } from 'react';
import ViewableFiles from './ViewableFiles';
import LoadingPreview from './LoadingPreview';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const Preview = ({ file, uploading, progress, viewOnly, singleFile }) => {
  const { isMobile } = useResponsiveScreen();

  const fileType = useMemo(() => {
    if (file.expired) return FileType.Expired;

    const type = file.type.split('/')[0];

    if (type !== 'image' && type !== 'video' && type !== 'audio') {
      return FileType.Other;
    }

    return type;
  }, [file]);

  const isViewable = useMemo(() => {
    const viewableExtensions = ViewableFiles[fileType];
    const extension = file.name.split('.').pop();

    return viewableExtensions.includes(extension);
  }, [file, fileType]);

  const borderRadius = useMemo(() => {
    if (!isMobile || (viewOnly && !singleFile)) return '0.25rem';
    return '0.5rem';
  }, [isMobile, viewOnly, singleFile]);

  return (
    <Wrapper viewOnly={viewOnly} singleFile={singleFile}>
      {!uploading &&
        (fileType === FileType.Video && isViewable ? (
          <VideoPreview file={file} borderRadius={borderRadius} />
        ) : fileType === FileType.Image && isViewable ? (
          <ImagePreview file={file} borderRadius={borderRadius} />
        ) : (
          <IconPreview
            file={file}
            fileType={fileType}
            isViewable={isViewable}
            borderRadius={borderRadius}
          />
        ))}

      {uploading && (
        <LoadingPreview
          id={file.id}
          fileType={fileType}
          progress={progress}
          borderRadius={borderRadius}
        />
      )}
    </Wrapper>
  );
};

export default Preview;

const Wrapper = styled.div`
  width: 100%;
  height: 90px;

  @media (${device.sm}) {
    height: ${({ viewOnly, singleFile }) =>
      viewOnly ? (singleFile ? '162px' : '80px') : '72px'};
  }
`;
