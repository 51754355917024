export const PencilIcon = ({ size = 1 }) => (
  <svg
    width={19 * size}
    height={20 * size}
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.362 17.125H3.762L12.387 8.5L10.987 7.1L2.362 15.725V17.125ZM16.662 7.05L12.412 2.85L13.812 1.45C14.1953 1.06667 14.6663 0.875 15.225 0.875C15.783 0.875 16.2537 1.06667 16.637 1.45L18.037 2.85C18.4203 3.23333 18.6203 3.696 18.637 4.238C18.6537 4.77933 18.4703 5.24167 18.087 5.625L16.662 7.05ZM15.212 8.525L4.612 19.125H0.362V14.875L10.962 4.275L15.212 8.525Z"
      fill="#9D99B0"
    />
  </svg>
);
