import styled from 'styled-components';
import { StarIcon } from 'assets/icons';
import { useProfileState } from 'components/components/ProfilePage/common/ProfileContext';
import { scroller } from 'react-scroll';
import Stack from 'components/Basics/Layout/Stack';

const Ratings = () => {
  const { user, isLoading } = useProfileState();
  const adminAccountReviews = '(28)';
  const adminAccountRating = '5.0';

  const reviews = user?.sellerReviews;

  const totalRatingParticipant = reviews?.length;
  const averageRating =
    totalRatingParticipant === 0
      ? '--'
      : reviews?.reduce((acc, curr) => {
          return acc + curr.rating;
        }, 0) / totalRatingParticipant;

  return (
    <RatingBox>
      <Stack
        direction="row"
        className="cursor-pointer rating"
        onClick={() => {
          scroller.scrollTo('reviews', {
            duration: 0.5 - 0.1 * 2,
            smooth: true,
            ignoreCancelEvents: true,
            offset: -80,
          });
        }}
      >
        <span className="text-yellow-500 flex align-items-center">
          <StarIcon size={1.1} />
        </span>

        <span className="bh5 reviewsCount">
          {isLoading
            ? '--'
            : !!user.isAdmin
            ? adminAccountRating
            : averageRating}
        </span>

        <span className="sbh5 text-indigo-100">
          {isLoading
            ? '(0)'
            : !!user.isAdmin
            ? adminAccountReviews
            : `(${totalRatingParticipant})`}
        </span>
      </Stack>
    </RatingBox>
  );
};

export default Ratings;

const RatingBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
  transition: all 150ms ease-in-out;

  .rating:hover .reviewsCount {
    text-decoration: underline;
  }
`;
