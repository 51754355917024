import ModalKeys from '../translationKeys/ModalKeys';

export const ModalTranslations = {
  // Official Launch
  [ModalKeys.official_launch.title]:
    '¡Esta función se lanzará pronto en tu país!',
  [ModalKeys.official_launch.content]: `
    Esta función se añadirá adicionalmente en tu región pronto. <br> 
    Proporcionaremos el mejor servicio para tus colaboraciones globales.`,

  // Patch Note
  [ModalKeys.Patch_Note.close]: 'No volver a abrir durante 14 días',

  [ModalKeys.Notification.Dropdown.title]: 'Notificaciones',
  [ModalKeys.Notification.Dropdown.no_notification]: 'No hay notificaciones',
  [ModalKeys.Notification.Dropdown.bottom_btn]: 'Marcar todo como leído',

  [ModalKeys.My_Order.Dropdown.content]: '¡Comienza tu primer pedido!',
  [ModalKeys.My_Order.Dropdown.bottom_btn]: 'Ver todos los pedidos',
};
