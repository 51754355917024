export const InfluencerIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 18}
      height={size * 14}
      viewBox="0 0 18 14"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.601 5.67L8.901 4.1475C8.66112 4.00971 8.38761 3.93703 8.109 3.93703C7.83039 3.93703 7.55688 4.00971 7.317 4.1475C7.07529 4.27997 6.87491 4.47352 6.73707 4.70765C6.59924 4.94177 6.52907 5.20778 6.534 5.4775V8.5225C6.52907 8.79222 6.59924 9.05823 6.73707 9.29235C6.87491 9.52648 7.07529 9.72003 7.317 9.8525C7.55688 9.99029 7.83039 10.063 8.109 10.063C8.38761 10.063 8.66112 9.99029 8.901 9.8525L11.601 8.33C11.8318 8.19226 12.0225 7.99929 12.1547 7.7695C12.287 7.53972 12.3564 7.28081 12.3564 7.0175C12.3564 6.75419 12.287 6.49528 12.1547 6.2655C12.0225 6.03571 11.8318 5.84274 11.601 5.705V5.67ZM8.316 8.155V5.845L10.368 7L8.316 8.155ZM15.3 0H2.7C1.98392 0 1.29716 0.276562 0.790812 0.768845C0.284464 1.26113 0 1.92881 0 2.625V11.375C0 12.0712 0.284464 12.7389 0.790812 13.2312C1.29716 13.7234 1.98392 14 2.7 14H15.3C16.0161 14 16.7028 13.7234 17.2092 13.2312C17.7155 12.7389 18 12.0712 18 11.375V2.625C18 1.92881 17.7155 1.26113 17.2092 0.768845C16.7028 0.276562 16.0161 0 15.3 0V0ZM16.2 11.375C16.2 11.6071 16.1052 11.8296 15.9364 11.9937C15.7676 12.1578 15.5387 12.25 15.3 12.25H2.7C2.46131 12.25 2.23239 12.1578 2.0636 11.9937C1.89482 11.8296 1.8 11.6071 1.8 11.375V2.625C1.8 2.39294 1.89482 2.17038 2.0636 2.00628C2.23239 1.84219 2.46131 1.75 2.7 1.75H15.3C15.5387 1.75 15.7676 1.84219 15.9364 2.00628C16.1052 2.17038 16.2 2.39294 16.2 2.625V11.375Z" />
    </svg>
  );
};
