import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const Countries = ({ countries }) => {
  const { isMobile } = useResponsiveScreen();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <h6 className={isMobile ? 'sbh8 text-indigo-100' : 'sbh6'}>
        {t(GeneralKeys.countries)}
      </h6>

      <Items>
        {isMobile ? (
          <span className="sbh7">
            {countries?.map((country) => t(country.value)).join(', ')}
          </span>
        ) : (
          countries?.map((country) => (
            <span key={country.value}>{t(country.value)}</span>
          ))
        )}
      </Items>
    </Wrapper>
  );
};

export default Countries;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  exit: { opacity: 0, y: 0 },
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (${device.sm}) {
    gap: 0.75rem;
  }
`;

const Items = styled.div.attrs({
  className: 'text-sm',
})`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  @media (${device.sm}) {
    gap: 0.75rem 0;
  }
`;
