const size5 = () => (
  <svg
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3319 6.05831L16.5437 0H15.3087L10.7833 5.26035L7.16883 0H3L8.46574 7.95458L3 14.3076H4.2351L9.01406 8.75254L12.8312 14.3076H17L11.3316 6.05831H11.3319ZM9.64026 8.02466L9.08647 7.23256L4.68013 0.929768H6.57717L10.1331 6.01632L10.6869 6.80842L15.3093 13.4202H13.4122L9.64026 8.02496V8.02466Z"
      fill="black"
    />
  </svg>
);
export default size5;
