import styled from 'styled-components';
import { forwardRef } from 'react';
const Card = styled.div`
  background: #fff;
  border-radius: ${({ borderRadius }) => borderRadius || '20px'};
  box-shadow: ${({ shadow }) =>
    shadow ? 'var(--shadow-card-innocent)' : 'none'};
  border: none;
  border-top: 5px solid
    ${({ color }) =>
      color === 'pink'
        ? 'var(--color-pink-500)'
        : color === 'yellow'
        ? 'var(--color-yellow-500)'
        : color === 'blue'
        ? 'var(--color-blue-500)'
        : color === 'red'
        ? 'var(--color-red-500)'
        : color === 'green'
        ? 'var(--color-green-500)'
        : color === 'skyblue'
        ? 'var(--color-blue-450)'
        : color === 'violet'
        ? 'var(--color-purple-300)'
        : color === 'indigo'
        ? 'var(--color-indigo-500)'
        : color};
  padding: ${({ padding }) => padding || '0'};
  width: ${({ width }) => (width ? width : 'auto')};
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
  height: ${({ height }) => height || 'auto'};
  max-height: ${({ maxHeight }) => maxHeight || 'auto'};
  transition: all 0.3s ease;

  &:active {
    outline: none;
  }
`;

/**
 * @param {string} color - Color of the Card.
 * - pink
 * - yellow
 * - blue
 * - red
 * - green
 * - indigo
 * @param {string} as - The tag name of the button. (optional)
 * - div
 * - form (default)
 * @param {boolean} shadow - Whether to show a shadow.
 * @param {string} className - Classes of the Card.
 * @param {Node} children - The content of the Card.
 * @additional The rest of the props are passed to the underlying component.
 *
 */
const TopBorderedCard = forwardRef(function TopBorderedCard(
  {
    color,
    as = 'form',
    shadow = false,
    className,
    children,
    padding,
    width,
    maxWidth,
    height,
    maxHeight,
    radius,
    ...rest
  },
  ref
) {
  return (
    <Card
      as={as}
      color={color}
      className={className}
      shadow={shadow}
      padding={padding}
      width={width}
      maxWidth={maxWidth}
      height={height}
      maxHeight={maxHeight}
      borderRadius={radius}
      {...rest}
      ref={ref}
    >
      {children}
    </Card>
  );
});

export default TopBorderedCard;
