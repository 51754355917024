export const CartIcon = ({ size = 1, height = null, className }) => (
  <svg
    width={18 * size}
    height={16 * (height || size)}
    viewBox="0 0 18 16"
    fill="currentColor"
    stroke="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.25273 1.12158L6.31315 8.5378C6.48512 9.12263 6.84231 9.63706 7.33189 10.005C7.82148 10.373 8.41747 10.5749 9.03175 10.581H17.3688V1.12158H4.25273Z"
      stroke="none"
    />
    <path
      d="M9.27022 15.3108C10.0604 15.3108 10.7011 14.6755 10.7011 13.8919C10.7011 13.1082 10.0604 12.4729 9.27022 12.4729C8.47998 12.4729 7.83938 13.1082 7.83938 13.8919C7.83938 14.6755 8.47998 15.3108 9.27022 15.3108Z"
      stroke="none"
    />
    <path
      d="M15.9475 15.3108C16.7377 15.3108 17.3783 14.6755 17.3783 13.8919C17.3783 13.1082 16.7377 12.4729 15.9475 12.4729C15.1572 12.4729 14.5166 13.1082 14.5166 13.8919C14.5166 14.6755 15.1572 15.3108 15.9475 15.3108Z"
      stroke="none"
    />
    <path
      d="M4.02379 1.12158H1.16211M4.25273 1.12158L6.31315 8.5378C6.48512 9.12263 6.84231 9.63706 7.33189 10.005C7.82148 10.373 8.41747 10.5749 9.03175 10.581H17.3688V1.12158H4.25273ZM10.7011 13.8919C10.7011 14.6755 10.0604 15.3108 9.27022 15.3108C8.47998 15.3108 7.83938 14.6755 7.83938 13.8919C7.83938 13.1082 8.47998 12.4729 9.27022 12.4729C10.0604 12.4729 10.7011 13.1082 10.7011 13.8919ZM17.3783 13.8919C17.3783 14.6755 16.7377 15.3108 15.9475 15.3108C15.1572 15.3108 14.5166 14.6755 14.5166 13.8919C14.5166 13.1082 15.1572 12.4729 15.9475 12.4729C16.7377 12.4729 17.3783 13.1082 17.3783 13.8919Z"
      fill="none"
      strokeWidth="0.804989"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
