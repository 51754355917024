export const BellOffIcon = ({ size = 1 }) => (
  <svg
    width={43 * size}
    height={44 * size}
    viewBox="0 0 43 44"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.33366 36.0834V31.9167H10.5003V17.3334C10.5003 16.1876 10.6482 15.0591 10.9441 13.948C11.2385 12.8369 11.6809 11.7779 12.2712 10.7709L15.3962 13.8959C15.1531 14.4515 14.9712 15.0154 14.8503 15.5876C14.7281 16.1612 14.667 16.7431 14.667 17.3334V31.9167H27.5837L0.916992 5.25008L3.83366 2.33341L42.167 40.6667L39.2503 43.5834L31.6462 36.0834H6.33366ZM35.5003 28.0626L31.3337 23.8959V17.3334C31.3337 15.0417 30.5177 13.0799 28.8857 11.448C27.2538 9.81605 25.292 9.00008 23.0003 9.00008C22.0628 9.00008 21.1864 9.148 20.3712 9.44383C19.5545 9.73828 18.7989 10.1459 18.1045 10.6667L15.0837 7.64591C15.7781 7.12508 16.5246 6.6473 17.3232 6.21258C18.1219 5.77925 18.9725 5.45841 19.8753 5.25008V3.79175C19.8753 2.92369 20.1795 2.18619 20.7878 1.57925C21.3948 0.970915 22.1323 0.666748 23.0003 0.666748C23.8684 0.666748 24.6059 0.970915 25.2128 1.57925C25.8212 2.18619 26.1253 2.92369 26.1253 3.79175V5.25008C28.8337 5.94453 31.0732 7.39383 32.8441 9.598C34.6149 11.8036 35.5003 14.382 35.5003 17.3334V28.0626ZM23.0003 42.3334C21.8545 42.3334 20.8739 41.9258 20.0587 41.1105C19.242 40.2938 18.8337 39.3126 18.8337 38.1667H27.167C27.167 39.3126 26.7594 40.2938 25.9441 41.1105C25.1274 41.9258 24.1462 42.3334 23.0003 42.3334Z" />
  </svg>
);
