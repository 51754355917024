export const UnorderedListIcon = ({ size = 1 }) => (
  <svg
    width={19 * size}
    height={14 * size}
    viewBox="0 0 19 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.2559 5.6C1.48123 5.6 0.855896 6.22533 0.855896 7C0.855896 7.77467 1.48123 8.4 2.2559 8.4C3.03056 8.4 3.6559 7.77467 3.6559 7C3.6559 6.22533 3.03056 5.6 2.2559 5.6ZM2.2559 0C1.48123 0 0.855896 0.625333 0.855896 1.4C0.855896 2.17467 1.48123 2.8 2.2559 2.8C3.03056 2.8 3.6559 2.17467 3.6559 1.4C3.6559 0.625333 3.03056 0 2.2559 0ZM2.2559 11.2C1.48123 11.2 0.855896 11.8347 0.855896 12.6C0.855896 13.3653 1.49056 14 2.2559 14C3.02123 14 3.6559 13.3653 3.6559 12.6C3.6559 11.8347 3.03056 11.2 2.2559 11.2ZM5.0559 13.5333H18.1226V11.6667H5.0559V13.5333ZM5.0559 7.93333H18.1226V6.06667H5.0559V7.93333ZM5.0559 0.466667V2.33333H18.1226V0.466667H5.0559Z" />
  </svg>
);
