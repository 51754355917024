import { BellIcon, VolumeMuteIcon, VolumeUpIcon } from 'assets/icons';
import IconButton from 'components/Basics/Buttons/IconButton';
import NotificationBox from '../Common/NotificationBox';
import styled from 'styled-components';
import { useClickAway, useToggle } from 'react-use';
import { useRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import NoNotifications from './NoNotifications';
import NotificationItem from './NotificationItem';
import NotificationSkeleton from './NotificationSkeleton';
import useNotifications from './useNotifications';
import { useNotificationSound } from 'contexts/NotificationSoundContext';
import { useTranslation } from 'react-i18next';
import ModalKeys from 'translations/translationKeys/ModalKeys';

const skeletons = Array.from({ length: 10 }).map((_, i) => (
  <NotificationSkeleton key={i} id={i} />
));

const Notifications = () => {
  const { t } = useTranslation();
  const [showNotificationBox, toggleNotificationBox] = useToggle(false);
  const { isSoundOn, toggleSound } = useNotificationSound();
  const ref = useRef(null);
  useClickAway(ref, () => {
    toggleNotificationBox(false);
  });

  const {
    loading,
    fetching,
    items,
    unreadCount,
    readNotification,
    readAllNotifications,
    loadMore,
  } = useNotifications();

  return (
    <>
      {showNotificationBox && <Backdrop />}

      <Wrapper ref={ref}>
        <IconButton
          className="text-inherit position-relative"
          onClick={() => {
            toggleNotificationBox();
          }}
        >
          <BellIcon />

          <AnimatePresence>{unreadCount > 0 && <Dot />}</AnimatePresence>
        </IconButton>

        <AnimatePresence>
          {showNotificationBox && (
            <NotificationBox
              title={`${t(ModalKeys.Notification.Dropdown.title)} ${
                unreadCount > 0 ? `(${unreadCount})` : ''
              }`}
              renderFooter={
                <>
                  <IconButton onClick={toggleSound} className="text-indigo-200">
                    {isSoundOn ? <VolumeUpIcon /> : <VolumeMuteIcon />}
                  </IconButton>

                  <TextButton onClick={readAllNotifications}>
                    {t(ModalKeys.Notification.Dropdown.bottom_btn)}
                  </TextButton>
                </>
              }
              translateX="-68%"
              onEndReached={loadMore}
            >
              <AnimatePresence mode="wait">
                {loading ? (
                  skeletons
                ) : items.length > 0 ? (
                  <>
                    {items.map((item, index) => {
                      return (
                        <NotificationItem
                          key={item.id}
                          id={item.id}
                          type={item.type}
                          pic={item.user.pic}
                          userId={item.user.id}
                          userHandle={item.user.handle}
                          isRead={item.isRead}
                          text={item.text}
                          date={item.date}
                          navigateTo={item.navigateTo}
                          readNotification={() => {
                            readNotification(item.id);
                          }}
                        />
                      );
                    })}

                    {fetching && skeletons}
                  </>
                ) : (
                  <NoNotifications key="no-notifications" />
                )}
              </AnimatePresence>
            </NotificationBox>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  );
};

export default Notifications;

const Wrapper = styled.div`
  position: relative;
`;

const Dot = styled(motion.span).attrs({
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  transition: { duration: 0.2 },
})`
  position: absolute;
  top: -4px;
  right: -8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--color-red-500);
`;

const TextButton = styled.button.attrs({
  className: 'sbh6.5',
})`
  background: none;
  border: none;
  padding: 0;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

const Backdrop = styled.div.attrs({
  onMouseOver: (e) => e.stopPropagation(),
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
