import MessageKeys from '../translationKeys/MessageKeys';

export const MessageTranslations = {
  [MessageKeys.Dropdown.title]: 'กล่องข้อความ',
  [MessageKeys.Dropdown.no_message]: 'ไม่มีข้อความ',
  [MessageKeys.Dropdown.bottom_btn]: 'ดูข้อความทั้งหมด',

  [MessageKeys.ChatBox.title]: 'ยินดีต้อนรับสู่กล่องข้อความของคุณ',
  [MessageKeys.ChatBox.no_chat]: `
    แต่อย่ากังวล เมื่อคุณเริ่มบทสนทนา จะพบว่ามีเราที่นี่ <br>
    คลิกที่การสนทนาเพื่อเริ่มส่งข้อความ`,
  [MessageKeys.ChatBox.no_selected_chat]:
    'คลิกที่การสนทนาเพื่อเริ่มส่งข้อความ!',
  [MessageKeys.ChatBox.new_chat]: `
    เริ่มสนทนาและเชื่อมต่อกับ {{user}} เพื่อพูดคุยเรื่องรายละเอียดโครงการของคุณ <br>
    <span>ส่งข้อเสนอเพื่อแบ่งปันข้อมูลทั้งหมดในที่เดียว</span>`,
  [MessageKeys.ChatBox.search]: `
    <h6>ไม่พบผลลัพธ์</h6>
    <p>โปรดลองคำหลักอื่น</p>`,

  [MessageKeys.ChatBox.Message.me]: 'ฉัน',
  [MessageKeys.ChatBox.Message.copy]: 'คัดลอกข้อความ',
  [MessageKeys.ChatBox.Message.report]: 'รายงานข้อความ',
  [MessageKeys.ChatBox.Message.download_file]: 'ดาวน์โหลดไฟล์ทั้งหมด',

  [MessageKeys.ChatBox.Message.typing]: 'กำลังพิมพ์...',
  [MessageKeys.ChatBox.Message.Offer_Package.text]:
    'นี่คือแพ็กเกจที่ปรับแต่งสำหรับคุณ',
  [MessageKeys.ChatBox.Message.Offer_Package.description]:
    'คำสั่งจะเริ่มต้นเมื่อคุณยอมรับข้อเสนอของลูกค้า',
  [MessageKeys.ChatBox.Message.Offer_Package.btn]: 'ดำเนินการชำระเงินต่อ',

  [MessageKeys.ChatBox.Message.Select_Package.title]: 'เลือกแพ็กเกจ',
  [MessageKeys.ChatBox.Message.Select_Package.btn]: 'สร้างแพ็กเกจใหม่',
  [MessageKeys.ChatBox.Message.Select_Package.complete_package_first]:
    'เสร็จสิ้นแพ็กเกจแรกของคุณเพื่อเริ่มส่งข้อเสนอ!',
  [MessageKeys.ChatBox.Message.Select_Package.no_price]: 'ไม่มีราคา',
  [MessageKeys.ChatBox.Message.Select_Package.verified_only]:
    'ผู้ใช้ที่ได้รับการยืนยันเท่านั้น',

  [MessageKeys.ChatBox.Message.Custom_Package.title]: 'แพ็กเกจแบบกำหนดเอง',
  [MessageKeys.ChatBox.Message.Custom_Package.description]:
    'ราคาที่แสดงให้กับผู้ซื้อจะรวมค่าบริการประมาณ {{number}}%',
  [MessageKeys.ChatBox.Message.Custom_Package.offering]: 'กำลังเสนอ...',
  [MessageKeys.ChatBox.Message.Custom_Package.btn1]: 'เสนอแพ็กเกจ',
  [MessageKeys.ChatBox.Message.Custom_Package.btn2]: 'กลับไปที่การเลือก',

  [MessageKeys.ChatBox.Message.Request_Price.text]:
    'ฉันต้องการขอราคาแพ็กเกจของคุณ',
  [MessageKeys.ChatBox.Message.Request_Price.description]:
    'ราคาถูกขอสำเร็จจากผู้สร้างแล้ว',
  [MessageKeys.ChatBox.Message.Request_Price.btn]: 'เสนอราคาและแพ็กเกจ',

  [MessageKeys.ChatBox.Message.File.singular]: 'ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural]: 'ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_2]: '2 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_3]: '3 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_4]: '4 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_5]: '5 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_6]: '6 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_7]: '7 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_8]: '8 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_9]: '9 ไฟล์',
  [MessageKeys.ChatBox.Message.File.plural_10]: '10 ไฟล์',
  [MessageKeys.ChatBox.Message.File.expired]: 'ไฟล์นี้หมดอายุแล้ว',

  [MessageKeys.ChatBox.Message.Error.sending]: 'กำลังส่ง...',
  [MessageKeys.ChatBox.Message.Error.fail]: 'การส่งล้มเหลว',

  [MessageKeys.InputField.chat_disabled]: 'ไม่สามารถติดต่อผู้ใช้นี้ได้อีกต่อไป',
  [MessageKeys.InputField.web]: 'ป้อนข้อความหรือลากและวางไฟล์',
  [MessageKeys.InputField.mobile]: 'พิมพ์ข้อความของคุณ',
  [MessageKeys.InputField.drag_drop]: 'ลากและวางไฟล์',

  [MessageKeys.InputField.Btn.offer_package]: 'เสนอแพ็กเกจ',
  [MessageKeys.InputField.Btn.send_proposal]: 'ส่งข้อเสนอ',

  [MessageKeys.UserInfo.Verified.tooltip]: `
    บัญชีนี้แทนองค์กรที่ได้รับการตรวจสอบ <br>
    เช่น บริษัทหรือองค์กรหรือเป็น <br>
    สมาชิกที่เกี่ยวข้องกับพวกเขา`,

  [MessageKeys.UserInfo.Status.online]: 'ออนไลน์',
  [MessageKeys.UserInfo.Status.offline]: 'เห็นครั้งล่าสุด',

  [MessageKeys.UserInfo.order_together]: 'สั่งร่วมกัน',

  [MessageKeys.UserInfo.Btn.edit_profile]: 'แก้ไขโปรไฟล์',
  [MessageKeys.UserInfo.Btn.visit_profile]: 'เข้าชมโปรไฟล์',

  [MessageKeys.Error.max_characters]: 'มากสุด {{number}} ตัวอักษร',
  [MessageKeys.Error.max_file_size_1]: 'ขนาดไฟล์รวมสูงสุด',
  [MessageKeys.Error.max_file_size_2]: 'MB',
  [MessageKeys.Error.max_file_1]: 'คุณสามารถส่งได้สูงสุด',
  [MessageKeys.Error.max_file_2]: 'ไฟล์',
};
