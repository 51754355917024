export const KoreaFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.0664062"
      y="0.146484"
      width="33"
      height="33"
      rx="16.5"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.2681 16.6467C24.2681 20.8993 20.8207 24.3467 16.5681 24.3467C12.3155 24.3467 8.8681 20.8993 8.8681 16.6467C8.8681 14.1827 14.0381 13.1487 18.3897 13.5381C18.8517 13.4149 19.3489 13.3467 19.8681 13.3467C22.2991 13.3467 24.2681 14.8251 24.2681 16.6467Z"
      fill="#232C80"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4681 15.5464C15.4681 15.5464 17.4371 13.3464 19.8681 13.3464C22.2991 13.3464 24.2681 14.8248 24.2681 16.6464C24.2681 12.3938 20.8207 8.94641 16.5681 8.94641C13.4947 8.94641 10.8415 10.746 9.6073 13.3508C8.8681 15.5464 8.8681 16.1888 8.8681 16.6464C9.9681 17.7464 11.1913 17.7464 12.1681 17.7464C13.1449 17.7464 14.0227 17.3218 14.6277 16.6464L15.4681 15.5464Z"
      fill="#F93939"
    />
    <path
      fillRule="evenodd"
      clipurle="evenodd"
      d="M26.4688 11.1467H30.8688L26.4688 6.7467H24.2688L26.4688 11.1467ZM6.6688 11.1467H2.2688L6.6688 6.7467H8.8688L6.6688 11.1467ZM26.4688 22.1467H30.8688L26.4688 26.5467H24.2688L26.4688 22.1467ZM6.6688 22.1467H2.2688L6.6688 26.5467H8.8688L6.6688 22.1467Z"
      fill="#151515"
    />
  </svg>
);
