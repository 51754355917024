import GeneralKeys from '../translationKeys/GeneralKeys';

export const GeneralTranslations = {
  [GeneralKeys.continue]: 'ดำเนินการต่อ',
  [GeneralKeys.submit]: 'ส่ง',
  [GeneralKeys.cancel]: 'ยกเลิก',
  [GeneralKeys.close]: 'ปิด',
  [GeneralKeys.return]: 'ย้อนกลับ',
  [GeneralKeys.account]: 'บัญชี',
  [GeneralKeys.coinbase]: 'CoinBase',
  [GeneralKeys.password]: 'รหัสผ่าน',
  [GeneralKeys.update]: 'อัปเดต',
  [GeneralKeys.coming_soon]: 'พบกันเร็วๆนี้',
  [GeneralKeys.all]: 'ทั้งหมด',
  [GeneralKeys.filter]: 'ตัวกรอง',
  [GeneralKeys.contents]: 'เนื้อหา',
  [GeneralKeys.myspace]: 'พื้นที่ของฉัน',
  [GeneralKeys.myproposal]: 'ข้อเสนอของฉัน',
  [GeneralKeys.proposals]: 'ข้อเสนอ',
  [GeneralKeys.billing]: 'การเรียกเก็บเงิน',
  [GeneralKeys.settings]: 'การตั้งค่า',
  [GeneralKeys.profile]: 'โปรไฟล์',
  [GeneralKeys.my_orders]: 'คำสั่งซื้อของฉัน',
  [GeneralKeys.orders]: 'คำสั่งซื้อ',
  [GeneralKeys.message]: 'ข้อความ',
  [GeneralKeys.support]: 'การสนับสนุน',
  [GeneralKeys.help_center]: 'ศูนย์ช่วยเหลือ',
  [GeneralKeys.creator_faq]: 'FAQ ครีเอเตอร์',
  [GeneralKeys.company_faq]: 'FAQ บริษัทและแบรนด์',
  [GeneralKeys.myCart]: 'รถเข็นของฉัน',
  [GeneralKeys.platform]: 'แพลตฟอร์ม',
  [GeneralKeys.sort_by]: 'เรียงลำดับตาม',
  [GeneralKeys.country]: 'ประเทศ',
  [GeneralKeys.categories]: 'หมวดหมู่',
  [GeneralKeys.price]: 'ราคา',
  [GeneralKeys.subscribers]: 'สมาชิก',
  [GeneralKeys.avg_views]: 'ค่าเฉลี่ยการดู',
  [GeneralKeys.audience_gender]: 'เพศของกลุ่มผู้ชม',
  [GeneralKeys.audience_age]: 'อายุของกลุ่มผู้ชม',
  [GeneralKeys.youtube]: 'YouTube',
  [GeneralKeys.twitter]: 'X (Twitter)',
  [GeneralKeys.instagram]: 'Instagram',
  [GeneralKeys.tiktok]: 'TikTok',
  [GeneralKeys.delivery]: 'ระยะเวลาการจัดส่ง',
  [GeneralKeys.type_length]: 'ประเภท/ความยาว',
  [GeneralKeys.day_singular]: `{{number}} วัน`,
  [GeneralKeys.day_plural]: `{{number}} วัน`,
  [GeneralKeys.loading]: 'กำลังโหลด',
  [GeneralKeys.company]: 'บริษัท',
  [GeneralKeys.bio]: 'ประวัติ',
  [GeneralKeys.subs]: 'สมาชิก',
  [GeneralKeys.followers]: 'ผู้ติดตาม',
  [GeneralKeys.videos]: 'วิดีโอ',
  [GeneralKeys.x_posts]: 'โพสต์ X',
  [GeneralKeys.posts_reels]: 'โพสต์และรีลส์',
  [GeneralKeys.tiktok_videos]: 'วิดีโอ TikTok',
  [GeneralKeys.since]: 'ตั้งแต่ {{date}}',
  [GeneralKeys.countries]: 'ประเทศ',
  [GeneralKeys.reviews]: 'รีวิว',
  [GeneralKeys.demographics]: 'ข้อมูลประชากร',
  [GeneralKeys.others]: 'อื่นๆ',
  [GeneralKeys.check_plan]: 'ตรวจสอบแผน',
  [GeneralKeys.login]: 'เข้าสู่ระบบ',
  [GeneralKeys.login_upper_case]: 'เข้าสู่ระบบ',
  [GeneralKeys.total]: 'รวม',
  [GeneralKeys.likes]: 'ชอบ',
  [GeneralKeys.comments]: 'ความคิดเห็น',
  [GeneralKeys.reposts]: 'รีโพสต์ X',
  [GeneralKeys.search]: 'ค้นหา',
  [GeneralKeys.contact]: 'ติดต่อ',
  [GeneralKeys.price_sign]: '$ --',
  [GeneralKeys.unlocking]: 'การปลดล็อก',
  [GeneralKeys.click_to_unlock]: 'คลิกเพื่อปลดล็อก',
  [GeneralKeys.add_to_cart]: 'เพิ่มไปยังรถเข็น',
  [GeneralKeys.show_less]: 'ดูน้อยลง',
  [GeneralKeys.show_more]: 'เพิ่มเติม',
  [GeneralKeys.go_back]: 'ย้อนกลับ',
  [GeneralKeys.load_more]: 'โหลดเพิ่มเติม',
  [GeneralKeys.metamask]: 'MetaMask',
  [GeneralKeys.coinbase_wallet]: 'Coinbase Wallet',
  [GeneralKeys.send]: 'ส่ง',
  [GeneralKeys.email]: 'อีเมล',
  [GeneralKeys.or_upper_case]: 'หรือ',
  [GeneralKeys.agree]: 'ยอมรับ',
  [GeneralKeys.client]: 'แบรนด์',
  [GeneralKeys.influencer]: 'ครีเอเตอร์',
  [GeneralKeys.marketing]: 'การตลาด',
  [GeneralKeys.creator_marketing]: 'การตลาดครีเอเตอร์',
  [GeneralKeys.partnerships]: 'คะแนน:พาร์ทเนอร์',
  [GeneralKeys.evo_tokens]: 'EVO Points',
  [GeneralKeys.information]: 'ข้อมูล',
  [GeneralKeys.website_credits]: 'เครดิตเว็บไซต์',
  [GeneralKeys.home]: 'หน้าแรก',
  [GeneralKeys.about]: 'เกี่ยวกับ',
  [GeneralKeys.pricing]: 'ราคา',
  [GeneralKeys.proposal]: 'ข้อเสนอ',
  [GeneralKeys.logout]: 'ออกจากระบบ',
  [GeneralKeys.view_more]: 'ดูเพิ่มเติม',
  [GeneralKeys.view_less]: 'ดูน้อยลง',
  [GeneralKeys.title]: 'หัวข้อ',
  [GeneralKeys.preview]: 'ดูตัวอย่าง',
  [GeneralKeys.upload_a_file]: 'อัปโหลดไฟล์',
  [GeneralKeys.description]: 'คำอธิบาย',
  [GeneralKeys.type]: 'ประเภท',
  [GeneralKeys.category]: 'หมวดหมู่',
  [GeneralKeys.select]: 'เลือก',
  [GeneralKeys.unlimited_revisions]: 'แก้ไขได้ไม่จำกัด',
  [GeneralKeys.unlimited]: 'ไม่จำกัด',
  [GeneralKeys.revision]: 'แก้ไขได้ {{number}} ครั้ง',
  [GeneralKeys.revisions]: 'แก้ไขได้ {{number}} ครั้ง',
  [GeneralKeys.number_revision]: 'การแก้ไข',
  [GeneralKeys.optional]: 'ตัวเลือก',
  [GeneralKeys.username]: 'ชื่อผู้ใช้',
  [GeneralKeys.social_network]: 'โซเชียลมีเดีย',
  [GeneralKeys.unlink]: 'ยกเลิกการลิงก์',
  [GeneralKeys.unlink_platform]: 'ยกเลิกการลิงก์บัญชี {{platform}}',
  [GeneralKeys.language]: 'ภาษา',
  [GeneralKeys.languages]: 'ภาษา',
  [GeneralKeys.package]: 'แพ็กเกจ',
  [GeneralKeys.save]: 'บันทึก',
  [GeneralKeys.creator]: 'ครีเอเตอร์',
  [GeneralKeys.basic]: 'พื้นฐาน',
  [GeneralKeys.standard]: 'พื้นฐาน',
  [GeneralKeys.premium]: 'มาตรฐาน',
  [GeneralKeys.per_day]: '/วัน',
  [GeneralKeys.per_month]: '/เดือน',
  [GeneralKeys.summary]: 'สรุป',
  [GeneralKeys.general]: 'ทั่วไป',
  [GeneralKeys.register]: 'ลงทะเบียน',
  [GeneralKeys.click]: 'คลิก',
  [GeneralKeys.connect]: 'เชื่อมต่อ',
  [GeneralKeys.reconnect]: 'เชื่อมต่ออีกครั้ง',
  [GeneralKeys.connecting]: 'กำลังเชื่อมต่อ',
  [GeneralKeys.notifications]: 'การแจ้งเตือน',
  [GeneralKeys.messages]: 'ข้อความ',
  [GeneralKeys.reminders]: 'การเตือน',
  [GeneralKeys.newsletters]: 'จดหมายข่าว',
  [GeneralKeys.packages]: 'แพ็กเกจ',
  [GeneralKeys.real_time]: 'Real-Time',
  [GeneralKeys.unsubscribe]: 'ยกเลิกการรับจดหมาย',
  [GeneralKeys.referral_code]: 'รหัสอ้างอิง',
  [GeneralKeys.total_invites]: 'เชิญทั้งหมด {{number}}',
  [GeneralKeys.submitted]: 'ถูกส่งแล้ว',
  [GeneralKeys.here]: 'ที่นี่',
  [GeneralKeys.confirm]: 'ยืนยัน',
  [GeneralKeys.deactivate]: 'ปิดการใช้งาน',
  [GeneralKeys.delete]: 'ลบ',
  [GeneralKeys.wallet]: 'กระเป๋าเงิน',
  [GeneralKeys.yes]: 'ตกลง',
  [GeneralKeys.privacy_policy]: 'นโยบายความเป็นส่วนตัว',
  [GeneralKeys.payment_policy]: 'นโยบายการชำระเงิน',
  [GeneralKeys.reactivate]: 'เปิดการใช้งานอีกครั้ง',
  [GeneralKeys.analytics]: 'การวิเคราะห์',
  [GeneralKeys.user_info]: 'ข้อมูลผู้ใช้',
  [GeneralKeys.enterprise]: 'พรีเมียม',
  [GeneralKeys.details]: 'รายละเอียด',
  [GeneralKeys.claim]: 'เรียกร้อง',
  [GeneralKeys.in_progress]: 'กำลังดำเนินการ',
  [GeneralKeys.free]: 'ฟรี',
  [GeneralKeys.report]: 'รายงาน',
  [GeneralKeys.link_copied]: 'คัดลอกลิงก์แล้ว',
  [GeneralKeys.contents_lowercase]: 'เนื้อหา',
  [GeneralKeys.channel_lowercase]: 'ช่อง',
  [GeneralKeys.audience_lowercase]: 'ผู้ชม',
  [GeneralKeys.my_url]: 'ลิงก์ของฉัน',
  [GeneralKeys.username_lowercase]: 'ชื่อผู้ใช้',
  [GeneralKeys.shorts]: 'Shorts',
  [GeneralKeys.x_post]: 'โพสต์ X',
  [GeneralKeys.repost]: 'การรีโพสต์ X',
  [GeneralKeys.website]: 'เว็บไซต์ของฉัน',
  [GeneralKeys.banner]: 'หน้าปก',
  [GeneralKeys.profile_lowercase]: 'โปรไฟล์',
  [GeneralKeys.congratulations]: 'ขอแสดงความยินดี',
  [GeneralKeys.skip]: 'ข้าม',
  [GeneralKeys.back]: 'ย้อนกลับ',
  [GeneralKeys.shares]: 'แชร์',
  [GeneralKeys.posts]: 'โพสต์',
  [GeneralKeys.client_company]: 'แบรนด์ㆍบริษัท',
  [GeneralKeys.contents_in]: 'เนื้อหา {{categories}} ใน {{countries}}',
  [GeneralKeys.videos_lowercase]: 'วิดีโอ',
  [GeneralKeys.tweets_lowercase]: 'โพสต์ X',
  [GeneralKeys.posts_lowercase]: 'โพสต์',
  [GeneralKeys.rating]: 'การให้คะแนน',
  [GeneralKeys.users]: 'ผู้ใช้',
  [GeneralKeys.browse_files]: 'เรียกดูไฟล์',
  [GeneralKeys.upload_failed]: 'อัปโหลดไม่สำเร็จ',
  [GeneralKeys.downloading]: 'กำลังดาวน์โหลด...',
  [GeneralKeys.package_summary]: 'สรุปแพ็กเกจ',
  [GeneralKeys.contents_plural]: '{{number}} เนื้อหา',
  [GeneralKeys.contents_singular]: '{{number}} เนื้อหา',
  [GeneralKeys.creator_brand_help_center]: 'ศูนย์ช่วยเหลือและคำถามที่พบบ่อย',
  [GeneralKeys.main]: 'ตัวหลัก',
  [GeneralKeys.date]: 'วันที่',
  [GeneralKeys.service]: 'บริการ',
  [GeneralKeys.order_id]: 'รหัสคำสั่งซื้อ',
  [GeneralKeys.transaction]: 'ธุรกรรม',
  [GeneralKeys.pdf]: 'PDF',
  [GeneralKeys.no_result_to_display]: 'ไม่มีผลลัพธ์ที่จะแสดง',
  [GeneralKeys.oldest]: 'เก่าสุด',
  [GeneralKeys.rows_10]: '10 แถว',
  [GeneralKeys.rows_50]: '50 แถว',
  [GeneralKeys.rows_100]: '100 แถว',
  [GeneralKeys.rows_500]: '500 แถว',
  [GeneralKeys.rows_1000]: '1000 แถว',
  [GeneralKeys.refresh]: 'รีเฟรช',

  // Category
  [GeneralKeys.Category.digital]: 'ดิจิทัล',
  [GeneralKeys.Category.entertainment]: 'ความบันเทิง',
  [GeneralKeys.Category.society]: 'สังคม',
  [GeneralKeys.Category.life_style]: 'วิถีชีวิต',

  [GeneralKeys.Category.tech_science]: 'เทคโนโลยี · วิทยาศาสตร์',
  [GeneralKeys.Category.video_games]: 'วิดีโอเกม',
  [GeneralKeys.Category.metaverse]: 'มีต้าเวิร์ส',
  [GeneralKeys.Category.crypto_nft]: 'แอป · บทแนะนำ',
  [GeneralKeys.Category.finance]: 'การเงิน',
  [GeneralKeys.Category.movies]: 'ภาพยนตร์',
  [GeneralKeys.Category.anime_cartoon]: 'อนิเมะ · การ์ตูน',
  [GeneralKeys.Category.music]: 'เพลง',
  [GeneralKeys.Category.dance]: 'การเต้น',
  [GeneralKeys.Category.sports]: 'กีฬา',
  [GeneralKeys.Category.comedy]: 'คอมเมดี้',
  [GeneralKeys.Category.politics_society]: 'การเมือง · สังคม',
  [GeneralKeys.Category.people_blogs]: 'ผู้คน · บล็อก',
  [GeneralKeys.Category.fashion]: 'แฟชั่น',
  [GeneralKeys.Category.beauty]: 'ความงาม',
  [GeneralKeys.Category.food_cooking]: 'อาหาร · การทำอาหาร',
  [GeneralKeys.Category.youth_infant]: 'วัยรุ่น · เด็ก',
  [GeneralKeys.Category.animals_pets]: 'สัตว์ · สัตว์เลี้ยง',
  [GeneralKeys.Category.travel_event]: 'การเดินทาง · วัฒนธรรม',
  [GeneralKeys.Category.auto_vehicles]: 'รถยนต์ · ยานพาหนะ',
  [GeneralKeys.Category.unboxing]: 'แกะกล่อง',
  [GeneralKeys.Category.education]: 'การศึกษา',
  [GeneralKeys.Category.art_crafts]: 'ศิลปะ · งานฝีมือ',
  [GeneralKeys.Category.diy_home]: 'DIY · การตกแต่ง',
  [GeneralKeys.Category.virtual_vtuber]: 'เสมือนจริง ㆍ Vtuber',
  [GeneralKeys.Category.ai_automation]: 'AI · ระบบอัตโนมัติ',
  [GeneralKeys.Category.gym_fitness]: 'ยิม · ฟิตเนส',

  // Country
  [GeneralKeys.Country.are]: 'สหรัฐอาหรับเอมิเรตส์',
  [GeneralKeys.Country.arg]: 'อาร์เจนตินา',
  [GeneralKeys.Country.aus]: 'ออสเตรเลีย',
  [GeneralKeys.Country.aut]: 'ออสเตรีย',
  [GeneralKeys.Country.bel]: 'เบลเยียม',
  [GeneralKeys.Country.bra]: 'บราซิล',
  [GeneralKeys.Country.can]: 'แคนาดา',
  [GeneralKeys.Country.che]: 'สวิตเซอร์แลนด์',
  [GeneralKeys.Country.chn]: 'จีน',
  [GeneralKeys.Country.deu]: 'เยอรมนี',
  [GeneralKeys.Country.dnk]: 'เดนมาร์ก',
  [GeneralKeys.Country.eeu]: 'ยุโรป',
  [GeneralKeys.Country.esp]: 'สเปน',
  [GeneralKeys.Country.fin]: 'ฟินแลนด์',
  [GeneralKeys.Country.fra]: 'ฝรั่งเศส',
  [GeneralKeys.Country.gbr]: 'อังกฤษ',
  [GeneralKeys.Country.glb]: 'โลก',
  [GeneralKeys.Country.hkg]: 'ฮ่องกง',
  [GeneralKeys.Country.idn]: 'อินโดนีเซีย',
  [GeneralKeys.Country.ind]: 'อินเดีย',
  [GeneralKeys.Country.irn]: 'อิหร่าน',
  [GeneralKeys.Country.isr]: 'อิสราเอล',
  [GeneralKeys.Country.ita]: 'อิตาลี',
  [GeneralKeys.Country.jpn]: 'ญี่ปุ่น',
  [GeneralKeys.Country.kor]: 'เกาหลีใต้',
  [GeneralKeys.Country.mex]: 'เม็กซิโก',
  [GeneralKeys.Country.mys]: 'มาเลเซีย',
  [GeneralKeys.Country.nld]: 'เนเธอร์แลนด์',
  [GeneralKeys.Country.nor]: 'นอร์เวย์',
  [GeneralKeys.Country.nzl]: 'นิวซีแลนด์',
  [GeneralKeys.Country.pak]: 'ปากีสถาน',
  [GeneralKeys.Country.phl]: 'ฟิลิปปินส์',
  [GeneralKeys.Country.pol]: 'โปแลนด์',
  [GeneralKeys.Country.prt]: 'โปรตุเกส',
  [GeneralKeys.Country.sau]: 'ซาอุดีอาระเบีย',
  [GeneralKeys.Country.sgp]: 'สิงคโปร์',
  [GeneralKeys.Country.swe]: 'สวีเดน',
  [GeneralKeys.Country.tha]: 'ไทย',
  [GeneralKeys.Country.tur]: 'ตุรกี',
  [GeneralKeys.Country.twn]: 'ไต้หวัน',
  [GeneralKeys.Country.usa]: 'อเมริกา',
  [GeneralKeys.Country.vnm]: 'เวียดนาม',

  // Client
  [GeneralKeys.Country.abw]: 'อารูบา',
  [GeneralKeys.Country.afg]: 'อัฟกานิสถาน',
  [GeneralKeys.Country.ago]: 'แองโกลา',
  [GeneralKeys.Country.aia]: 'แองกิลลา',
  [GeneralKeys.Country.ala]: 'หมู่เกาะโอลันด์',
  [GeneralKeys.Country.alb]: 'แอลเบเนีย',
  [GeneralKeys.Country.and]: 'อันดอร์รา',
  [GeneralKeys.Country.arm]: 'อาร์เมเนีย',
  [GeneralKeys.Country.asm]: 'อเมริกันซามัว',
  [GeneralKeys.Country.ata]: 'แอนตาร์กติกา',
  [GeneralKeys.Country.atf]: 'เฟรนช์เซาเทิร์นเทร์ริทอรีส์',
  [GeneralKeys.Country.atg]: 'แอนติกาและบาร์บูดา',
  [GeneralKeys.Country.aze]: 'อาเซอร์ไบจาน',
  [GeneralKeys.Country.bdi]: 'บุรุนดี',
  [GeneralKeys.Country.ben]: 'เบนิน',
  [GeneralKeys.Country.bes]: 'โบนแอร์, ซินต์ เอวสตาเซียส และ ซาบา',
  [GeneralKeys.Country.bfa]: 'บูร์กินาฟาโซ',
  [GeneralKeys.Country.bgd]: 'บังกลาเทศ',
  [GeneralKeys.Country.bgr]: 'บัลแกเรีย',
  [GeneralKeys.Country.bhr]: 'บาห์เรน',
  [GeneralKeys.Country.bhs]: 'บาฮามาส',
  [GeneralKeys.Country.bih]: 'บอสเนียและเฮอร์เซโกวีนา',
  [GeneralKeys.Country.blm]: 'เซนต์บาร์เธเลมี',
  [GeneralKeys.Country.blr]: 'เบลารุส',
  [GeneralKeys.Country.blz]: 'เบลีซ',
  [GeneralKeys.Country.bmu]: 'เบอร์มิวดา',
  [GeneralKeys.Country.bol]: 'โบลิเวีย',
  [GeneralKeys.Country.brb]: 'บาร์เบโดส',
  [GeneralKeys.Country.brn]: 'บรูไน',
  [GeneralKeys.Country.btn]: 'ภูฏาน',
  [GeneralKeys.Country.bvt]: 'เกาะบูเวต',
  [GeneralKeys.Country.bwa]: 'บอตสวานา',
  [GeneralKeys.Country.caf]: 'สาธารณรัฐแอฟริกากลาง',
  [GeneralKeys.Country.cck]: 'หมู่เกาะโคโคส (คีลิง)',
  [GeneralKeys.Country.chl]: 'ชิลี',
  [GeneralKeys.Country.civ]: 'ไอวอรี่โคสต์',
  [GeneralKeys.Country.cmr]: 'แคเมอรูน',
  [GeneralKeys.Country.cod]: 'สาธารณรัฐประชาธิปไตยคองโก',
  [GeneralKeys.Country.cog]: 'คองโก',
  [GeneralKeys.Country.cok]: 'หมู่เกาะคุก',
  [GeneralKeys.Country.col]: 'โคลอมเบีย',
  [GeneralKeys.Country.com]: 'โคโมโรส',
  [GeneralKeys.Country.cpv]: 'เคปเวิร์ด',
  [GeneralKeys.Country.cri]: 'คอสตาริกา',
  [GeneralKeys.Country.cub]: 'คิวบา',
  [GeneralKeys.Country.cuw]: 'คูราเซา',
  [GeneralKeys.Country.cxr]: 'เกาะคริสต์มาส',
  [GeneralKeys.Country.cym]: 'หมู่เกาะเคย์แมน',
  [GeneralKeys.Country.cyp]: 'ไซปรัส',
  [GeneralKeys.Country.cze]: 'เช็ก',
  [GeneralKeys.Country.dji]: 'จิบูตี',
  [GeneralKeys.Country.dma]: 'โดมินิกา',
  [GeneralKeys.Country.dom]: 'สาธารณรัฐโดมินิกัน',
  [GeneralKeys.Country.dza]: 'แอลจีเรีย',
  [GeneralKeys.Country.ecu]: 'เอกวาดอร์',
  [GeneralKeys.Country.egy]: 'อียิปต์',
  [GeneralKeys.Country.eri]: 'เอริเทรีย',
  [GeneralKeys.Country.esh]: 'ซาฮาราตะวันตก',
  [GeneralKeys.Country.est]: 'เอสโตเนีย',
  [GeneralKeys.Country.eth]: 'เอธิโอเปีย',
  [GeneralKeys.Country.fji]: 'ฟีจี',
  [GeneralKeys.Country.flk]: 'หมู่เกาะฟอล์กแลนด์ (มัลวีนัส)',
  [GeneralKeys.Country.fro]: 'หมู่เกาะแฟโร',
  [GeneralKeys.Country.fsm]: 'ไมโครนีเซีย (รัฐสหรัฐ)',
  [GeneralKeys.Country.gab]: 'กาบอง',
  [GeneralKeys.Country.geo]: 'จอร์เจีย',
  [GeneralKeys.Country.ggy]: 'เกิร์นซีย์',
  [GeneralKeys.Country.gha]: 'กานา',
  [GeneralKeys.Country.gib]: 'ยิบรอลตาร์',
  [GeneralKeys.Country.gin]: 'กินี',
  [GeneralKeys.Country.glp]: 'กวาเดลูป',
  [GeneralKeys.Country.gmb]: 'แกมเบีย',
  [GeneralKeys.Country.gnb]: 'กินี-บิสเซา',
  [GeneralKeys.Country.gnq]: 'อิเควทอเรียลกินี',
  [GeneralKeys.Country.grc]: 'กรีซ',
  [GeneralKeys.Country.grd]: 'เกรเนดา',
  [GeneralKeys.Country.grl]: 'กรีนแลนด์',
  [GeneralKeys.Country.gtm]: 'กัวเตมาลา',
  [GeneralKeys.Country.gum]: 'กวม',
  [GeneralKeys.Country.guy]: 'กายอานา',
  [GeneralKeys.Country.hmd]: 'เฮิร์ดและหมู่เกาะแมคโดนัลด์',
  [GeneralKeys.Country.hnd]: 'ฮอนดูรัส',
  [GeneralKeys.Country.hrv]: 'โครเอเชีย',
  [GeneralKeys.Country.hti]: 'เฮติ',
  [GeneralKeys.Country.hun]: 'ฮังการี',
  [GeneralKeys.Country.imn]: 'ไอล์แอนด์แมน',
  [GeneralKeys.Country.iot]: 'บริติชอินเดียนโอเชียนเทร์ริทอรี',
  [GeneralKeys.Country.irl]: 'ไอร์แลนด์',
  [GeneralKeys.Country.irq]: 'อิรัก',
  [GeneralKeys.Country.isl]: 'ไอซ์แลนด์',
  [GeneralKeys.Country.jam]: 'จาเมกา',
  [GeneralKeys.Country.jey]: 'เจอร์ซีย์',
  [GeneralKeys.Country.jor]: 'จอร์แดน',
  [GeneralKeys.Country.kaz]: 'คาซัคสถาน',
  [GeneralKeys.Country.ken]: 'เคนยา',
  [GeneralKeys.Country.kgz]: 'คีร์กีซสถาน',
  [GeneralKeys.Country.khm]: 'กัมพูชา',
  [GeneralKeys.Country.kir]: 'คิริบาส',
  [GeneralKeys.Country.kna]: 'เซนต์คิตส์และเนวิส',
  [GeneralKeys.Country.kwt]: 'คูเวต',
  [GeneralKeys.Country.lao]: 'ประเทศลาวประชาธิปไตยประชาชนลาว',
  [GeneralKeys.Country.lbn]: 'เลบานอน',
  [GeneralKeys.Country.lbr]: 'ลิเบีย',
  [GeneralKeys.Country.lby]: 'ลิเบีย',
  [GeneralKeys.Country.lca]: 'เซนต์ลูเซีย',
  [GeneralKeys.Country.lie]: 'ลิกเตนสไตน์',
  [GeneralKeys.Country.lka]: 'ศรีลังกา',
  [GeneralKeys.Country.lso]: 'เลโซโท',
  [GeneralKeys.Country.ltu]: 'ลิทัวเนีย',
  [GeneralKeys.Country.lux]: 'ลักเซมเบิร์ก',
  [GeneralKeys.Country.lva]: 'ลัตเวีย',
  [GeneralKeys.Country.mac]: 'มาเก๊า',
  [GeneralKeys.Country.maf]: 'เซนต์มาร์ติน (ฝั่งฝรั่งเศส)',
  [GeneralKeys.Country.mar]: 'โมร็อกโก',
  [GeneralKeys.Country.mco]: 'โมนาโก',
  [GeneralKeys.Country.mda]: 'มอลโดวา (สาธารณรัฐ)',
  [GeneralKeys.Country.mdg]: 'มาดากัสการ์',
  [GeneralKeys.Country.mdv]: 'มัลดีฟส์',
  [GeneralKeys.Country.mhl]: 'หมู่เกาะมาร์แชล',
  [GeneralKeys.Country.mkd]: 'สาธารณรัฐมาซิโดเนียเหนือ',
  [GeneralKeys.Country.mli]: 'มาลี',
  [GeneralKeys.Country.mlt]: 'มอลตา',
  [GeneralKeys.Country.mmr]: 'พม่า',
  [GeneralKeys.Country.mne]: 'มอนเตเนโกร',
  [GeneralKeys.Country.mng]: 'มองโกเลีย',
  [GeneralKeys.Country.mnp]: 'หมู่เกาะนอร์เทิร์นมาเรียนา',
  [GeneralKeys.Country.moz]: 'โมซัมบิก',
  [GeneralKeys.Country.mrt]: 'โมริเตเนีย',
  [GeneralKeys.Country.msr]: 'มอนต์เซอร์รัต',
  [GeneralKeys.Country.mtq]: 'มาร์ตีนิก',
  [GeneralKeys.Country.mus]: 'มอริเชียส์',
  [GeneralKeys.Country.mwi]: 'มาลาวี',
  [GeneralKeys.Country.myt]: 'มายอต',
  [GeneralKeys.Country.nam]: 'นามิเบีย',
  [GeneralKeys.Country.ncl]: 'นิวแคลิโดเนีย',
  [GeneralKeys.Country.ner]: 'ไนเจอร์',
  [GeneralKeys.Country.nfk]: 'เกาะนอร์ฟอล์ค',
  [GeneralKeys.Country.nga]: 'ไนจีเรีย',
  [GeneralKeys.Country.nic]: 'นิการากัว',
  [GeneralKeys.Country.niu]: 'นีอูเอ',
  [GeneralKeys.Country.npl]: 'เนปาล',
  [GeneralKeys.Country.nru]: 'นาอูรู',
  [GeneralKeys.Country.omn]: 'โอมาน',
  [GeneralKeys.Country.pan]: 'ปานามา',
  [GeneralKeys.Country.pcn]: 'พิตแคร์น',
  [GeneralKeys.Country.per]: 'เปรู',
  [GeneralKeys.Country.plw]: 'ปาเลา',
  [GeneralKeys.Country.png]: 'ปาปัวนิวกินี',
  [GeneralKeys.Country.pri]: 'เปอร์โตริโก',
  [GeneralKeys.Country.prk]: 'เกาหลี (สาธารณรัฐประชาชนเกาหลี)',
  [GeneralKeys.Country.pry]: 'ปารากวัย',
  [GeneralKeys.Country.pse]: 'ปาเลสไตน์ รัฐปาเลสไตน์',
  [GeneralKeys.Country.qat]: 'กาตาร์',
  [GeneralKeys.Country.reu]: 'เรอูเนียง',
  [GeneralKeys.Country.rou]: 'โรมาเนีย',
  [GeneralKeys.Country.rus]: 'รัสเซีย',
  [GeneralKeys.Country.rwa]: 'รวันดา',
  [GeneralKeys.Country.sdn]: 'ซูดาน',
  [GeneralKeys.Country.sen]: 'เซเนกัล',
  [GeneralKeys.Country.sgs]: 'เกาะซาวท์จอร์เจียและเกาะแซวต์แซนด์วิช',
  [GeneralKeys.Country.shn]: 'เซนต์เฮเลนา แอสเซนชัน และตริสตันดาคูน่า',
  [GeneralKeys.Country.sjm]: 'สฟาลบาร์ดและยังมาเยน',
  [GeneralKeys.Country.slb]: 'หมู่เกาะโซโลมอน',
  [GeneralKeys.Country.sle]: 'เซียร์ราลีโอน',
  [GeneralKeys.Country.slv]: 'เอลซัลวาดอร์',
  [GeneralKeys.Country.smr]: 'ซานมาริโน',
  [GeneralKeys.Country.som]: 'โซมาเลีย',
  [GeneralKeys.Country.spm]: 'แซงปีแยร์และมีเคอลอง',
  [GeneralKeys.Country.srb]: 'เซอร์เบีย',
  [GeneralKeys.Country.ssd]: 'ซานต์สุดาน',
  [GeneralKeys.Country.stp]: 'เซาตูเมและปรินซิปี',
  [GeneralKeys.Country.sur]: 'ซูรินาเม',
  [GeneralKeys.Country.svk]: 'สโลวะเกีย',
  [GeneralKeys.Country.svn]: 'สโลวีเนีย',
  [GeneralKeys.Country.swz]: 'เอสวาตีนี',
  [GeneralKeys.Country.sxm]: 'ซินต์มาร์เทิน',
  [GeneralKeys.Country.syc]: 'เซเชลส์',
  [GeneralKeys.Country.syr]: 'สาธารณรัฐซีเรีย',
  [GeneralKeys.Country.tca]: 'หมู่เกาะเติกส์และเคโคส',
  [GeneralKeys.Country.tcd]: 'ชาด',
  [GeneralKeys.Country.tgo]: 'โตโก',
  [GeneralKeys.Country.tjk]: 'ทาจิกิสถาน',
  [GeneralKeys.Country.tkl]: 'โทเคลาว',
  [GeneralKeys.Country.tkm]: 'เติร์กเมนิสถาน',
  [GeneralKeys.Country.tls]: 'ติมอร์-เลสเต',
  [GeneralKeys.Country.ton]: 'ตองกา',
  [GeneralKeys.Country.tto]: 'ตรินิแดดและโตเบโก',
  [GeneralKeys.Country.tun]: 'ตูนิเซีย',
  [GeneralKeys.Country.tuv]: 'ตูวาลู',
  [GeneralKeys.Country.tza]: 'แทนซาเนีย สหรัฐแห่ง',
  [GeneralKeys.Country.uga]: 'ยูกันดา',
  [GeneralKeys.Country.ukr]: 'ยูเครน',
  [GeneralKeys.Country.umi]: 'หมู่เกาะเอาต์ไลอิงของสหรัฐอเมริกา',
  [GeneralKeys.Country.ury]: 'อุรุกวัย',
  [GeneralKeys.Country.uzb]: 'อุซเบกิสถาน',
  [GeneralKeys.Country.vat]: 'วาติกัน',
  [GeneralKeys.Country.vct]: 'เซนต์วินเซนต์และเกรนาดีนส์',
  [GeneralKeys.Country.ven]: 'เวเนซุเอลา (สาธารณรัฐโบลิเวีย)',
  [GeneralKeys.Country.vgb]: 'หมู่เกาะบริติชเวอร์จิน',
  [GeneralKeys.Country.vir]: 'หมู่เกาะเวอร์จิน (สหรัฐอเมริกา)',
  [GeneralKeys.Country.vut]: 'วานูอาตู',
  [GeneralKeys.Country.wlf]: 'วาลลิสและฟุตูนา',
  [GeneralKeys.Country.wsm]: 'ซามัว',
  [GeneralKeys.Country.yem]: 'เยเมน',
  [GeneralKeys.Country.zaf]: 'แอฟริกาใต้',
  [GeneralKeys.Country.zmb]: 'แซมเบีย',
  [GeneralKeys.Country.zwe]: 'ซิมบับเว',

  // 2-Char
  [GeneralKeys.Country.ae]: 'สหรัฐอาหรับเอมิเรตส์',
  [GeneralKeys.Country.ar]: 'อาร์เจนตินา',
  [GeneralKeys.Country.au]: 'ออสเตรเลีย',
  [GeneralKeys.Country.at]: 'ออสเตรีย',
  [GeneralKeys.Country.be]: 'เบลเยียม',
  [GeneralKeys.Country.br]: 'บราซิล',
  [GeneralKeys.Country.ca]: 'แคนาดา',
  [GeneralKeys.Country.ch]: 'สวิตเซอร์แลนด์',
  [GeneralKeys.Country.cn]: 'จีน',
  [GeneralKeys.Country.de]: 'เยอรมนี',
  [GeneralKeys.Country.dk]: 'เดนมาร์ก',
  [GeneralKeys.Country.eeu]: 'ยุโรป',
  [GeneralKeys.Country.es]: 'สเปน',
  [GeneralKeys.Country.fi]: 'ฟินแลนด์',
  [GeneralKeys.Country.fr]: 'ฝรั่งเศส',
  [GeneralKeys.Country.gb]: 'อังกฤษ',
  [GeneralKeys.Country.glb]: 'ทั่วโลก',
  [GeneralKeys.Country.hk]: 'ฮ่องกง',
  [GeneralKeys.Country.id]: 'อินโดนีเซีย',
  [GeneralKeys.Country.in]: 'อินเดีย',
  [GeneralKeys.Country.ir]: 'อิหร่าน',
  [GeneralKeys.Country.il]: 'อิสราเอล',
  [GeneralKeys.Country.it]: 'อิตาลี',
  [GeneralKeys.Country.jp]: 'ญี่ปุ่น',
  [GeneralKeys.Country.kr]: 'เกาหลีใต้',
  [GeneralKeys.Country.mx]: 'เม็กซิโก',
  [GeneralKeys.Country.my]: 'มาเลเซีย',
  [GeneralKeys.Country.nl]: 'เนเธอร์แลนด์',
  [GeneralKeys.Country.no]: 'นอร์เวย์',
  [GeneralKeys.Country.nz]: 'นิวซีแลนด์',
  [GeneralKeys.Country.pk]: 'ปากีสถาน',
  [GeneralKeys.Country.ph]: 'ฟิลิปปินส์',
  [GeneralKeys.Country.pl]: 'โปแลนด์',
  [GeneralKeys.Country.pt]: 'โปรตุเกส',
  [GeneralKeys.Country.sa]: 'ซาอุดีอาระเบีย',
  [GeneralKeys.Country.sg]: 'สิงคโปร์',
  [GeneralKeys.Country.se]: 'สวีเดน',
  [GeneralKeys.Country.th]: 'ประเทศไทย',
  [GeneralKeys.Country.tr]: 'ตุรกี',
  [GeneralKeys.Country.tw]: 'ไต้หวัน',
  [GeneralKeys.Country.us]: 'อเมริกา',
  [GeneralKeys.Country.vn]: 'เวียดนาม',

  // Client
  [GeneralKeys.Country.aw]: 'อารูบา',
  [GeneralKeys.Country.af]: 'อัฟกานิสถาน',
  [GeneralKeys.Country.ao]: 'แองโกลา',
  [GeneralKeys.Country.ai]: 'แองกวิลลา',
  [GeneralKeys.Country.ax]: 'หมู่เกาะโอลันด์',
  [GeneralKeys.Country.al]: 'แอลเบเนีย',
  [GeneralKeys.Country.ad]: 'อันดอร์รา',
  [GeneralKeys.Country.am]: 'อาร์เมเนีย',
  [GeneralKeys.Country.as]: 'อเมริกันซามัว',
  [GeneralKeys.Country.aq]: 'แอนตาร์กติกา',
  [GeneralKeys.Country.tf]: 'เฟรนช์เซาเทิร์นเทร์ริทอรีส์',
  [GeneralKeys.Country.ag]: 'แอนติกาและบาร์บูดา',
  [GeneralKeys.Country.az]: 'อาเซอร์ไบจาน',
  [GeneralKeys.Country.bi]: 'บุรุนดี',
  [GeneralKeys.Country.bj]: 'เบนิน',
  [GeneralKeys.Country.bq]: 'โบแนร์, ซินต์ เอสเตเซียส และ ซาบา',
  [GeneralKeys.Country.bf]: 'บูร์กินาฟาโซ',
  [GeneralKeys.Country.bd]: 'บังกลาเทศ',
  [GeneralKeys.Country.bg]: 'บัลแกเรีย',
  [GeneralKeys.Country.bh]: 'บาห์เรน',
  [GeneralKeys.Country.bs]: 'บาฮามาส',
  [GeneralKeys.Country.ba]: 'บอสเนียและเฮอร์เซโกวีนา',
  [GeneralKeys.Country.bl]: 'เซนต์บาร์เธเลมี',
  [GeneralKeys.Country.by]: 'เบลารุส',
  [GeneralKeys.Country.bz]: 'เบลีซ',
  [GeneralKeys.Country.bm]: 'เบอร์มิวดา',
  [GeneralKeys.Country.bo]: 'โบลิเวีย',
  [GeneralKeys.Country.bb]: 'บาร์เบโดส',
  [GeneralKeys.Country.bn]: 'บรูไน',
  [GeneralKeys.Country.bt]: 'ภูฏาน',
  [GeneralKeys.Country.bv]: 'เกาะบูเวต',
  [GeneralKeys.Country.bw]: 'บอตสวานา',
  [GeneralKeys.Country.cf]: 'สาธารณรัฐแอฟริกากลาง',
  [GeneralKeys.Country.cc]: 'หมู่เกาะโคโคส (คีลิง)',
  [GeneralKeys.Country.cl]: 'ชิลี',
  [GeneralKeys.Country.ci]: 'ไอวอรี่โคสต์',
  [GeneralKeys.Country.cm]: 'แคเมอรูน',
  [GeneralKeys.Country.cd]: 'คองโก (สาธารณรัฐประชาธิปไตยคองโก)',
  [GeneralKeys.Country.cg]: 'คองโก (สาธารณรัฐคองโก)',
  [GeneralKeys.Country.ck]: 'หมู่เกาะคุก',
  [GeneralKeys.Country.co]: 'โคลอมเบีย',
  [GeneralKeys.Country.km]: 'โคโมโรส',
  [GeneralKeys.Country.cv]: 'เคปเวิร์ด',
  [GeneralKeys.Country.cr]: 'คอสตาริกา',
  [GeneralKeys.Country.cu]: 'คิวบา',
  [GeneralKeys.Country.cw]: 'คูราเซา',
  [GeneralKeys.Country.cx]: 'เกาะคริสต์มาส',
  [GeneralKeys.Country.ky]: 'หมู่เกาะเคย์แมน',
  [GeneralKeys.Country.cy]: 'ไซปรัส',
  [GeneralKeys.Country.cz]: 'เช็ก',
  [GeneralKeys.Country.dj]: 'จิบูตี',
  [GeneralKeys.Country.dm]: 'โดมินิกา',
  [GeneralKeys.Country.do]: 'สาธารณรัฐโดมินิกัน',
  [GeneralKeys.Country.dz]: 'แอลจีเรีย',
  [GeneralKeys.Country.ec]: 'เอกวาดอร์',
  [GeneralKeys.Country.eg]: 'อียิปต์',
  [GeneralKeys.Country.er]: 'เอริเทรีย',
  [GeneralKeys.Country.eh]: 'ซาฮาราตะวันตก',
  [GeneralKeys.Country.ee]: 'เอสโตเนีย',
  [GeneralKeys.Country.et]: 'เอธิโอเปีย',
  [GeneralKeys.Country.fj]: 'ฟิจิ',
  [GeneralKeys.Country.fk]: 'หมู่เกาะฟอล์กแลนด์ [มัลวีนัส]',
  [GeneralKeys.Country.fo]: 'หมู่เกาะแฟโร',
  [GeneralKeys.Country.fm]: 'ไมโครนีเซีย (รัฐสหภาพ)',
  [GeneralKeys.Country.ga]: 'กาบอง',
  [GeneralKeys.Country.ge]: 'จอร์เจีย',
  [GeneralKeys.Country.gg]: 'เกิร์นซีย์',
  [GeneralKeys.Country.gh]: 'กานา',
  [GeneralKeys.Country.gi]: 'ยิบรอลตาร์',
  [GeneralKeys.Country.gn]: 'กินี',
  [GeneralKeys.Country.gp]: 'กวาเดอลูป',
  [GeneralKeys.Country.gm]: 'แกมเบีย',
  [GeneralKeys.Country.gw]: 'กินี-บิสเซา',
  [GeneralKeys.Country.gq]: 'อิเควทอเรียลกินี',
  [GeneralKeys.Country.gr]: 'กรีซ',
  [GeneralKeys.Country.gd]: 'เกรเนดา',
  [GeneralKeys.Country.gl]: 'กรีนแลนด์',
  [GeneralKeys.Country.gt]: 'กัวเตมาลา',
  [GeneralKeys.Country.gu]: 'กวม',
  [GeneralKeys.Country.gy]: 'กายอานา',
  [GeneralKeys.Country.hm]: 'เกาะเฮิร์ดและหมู่เกาะแมคโดนัลด์',
  [GeneralKeys.Country.hn]: 'ฮอนดูรัส',
  [GeneralKeys.Country.hr]: 'โครเอเชีย',
  [GeneralKeys.Country.ht]: 'เฮติ',
  [GeneralKeys.Country.hu]: 'ฮังการี',
  [GeneralKeys.Country.im]: 'เกาะแมน',
  [GeneralKeys.Country.io]: 'บริติชอินเดียนอเชนเทร์ริทอรี',
  [GeneralKeys.Country.ie]: 'ไอร์แลนด์',
  [GeneralKeys.Country.iq]: 'อิรัก',
  [GeneralKeys.Country.is]: 'ไอซ์แลนด์',
  [GeneralKeys.Country.jm]: 'จาเมกา',
  [GeneralKeys.Country.je]: 'เจอร์ซีย์',
  [GeneralKeys.Country.jo]: 'จอร์แดน',
  [GeneralKeys.Country.kz]: 'คาซัคสถาน',
  [GeneralKeys.Country.ke]: 'เคนยา',
  [GeneralKeys.Country.kg]: 'คีร์กีซสถาน',
  [GeneralKeys.Country.kh]: 'กัมพูชา',
  [GeneralKeys.Country.ki]: 'คิริบาส',
  [GeneralKeys.Country.kn]: 'เซนต์คิตส์และเนวิส',
  [GeneralKeys.Country.kw]: 'คูเวต',
  [GeneralKeys.Country.la]: 'ສາທາລະນະລັດປະເທດລາວ',
  [GeneralKeys.Country.lb]: 'เลบานอน',
  [GeneralKeys.Country.lr]: 'ไลบีเรีย',
  [GeneralKeys.Country.ly]: 'ลิเบีย',
  [GeneralKeys.Country.lc]: 'เซนต์ลูเซีย',
  [GeneralKeys.Country.li]: 'ลิกเตนสไตน์',
  [GeneralKeys.Country.lk]: 'ศรีลังกา',
  [GeneralKeys.Country.ls]: 'เลโซโท',
  [GeneralKeys.Country.lt]: 'ลิทัวเนีย',
  [GeneralKeys.Country.lu]: 'ลักเซมเบิร์ก',
  [GeneralKeys.Country.lv]: 'ลัตเวีย',
  [GeneralKeys.Country.mo]: 'มาเก๊า',
  [GeneralKeys.Country.mf]: 'เซนต์มาร์ติน (ฝั่งฝรั่งเศส)',
  [GeneralKeys.Country.ma]: 'โมร็อกโก',
  [GeneralKeys.Country.mc]: 'โมนาโก',
  [GeneralKeys.Country.md]: 'มอลโดวา (สาธารณรัฐ)',
  [GeneralKeys.Country.mg]: 'มาดากัสการ์',
  [GeneralKeys.Country.mv]: 'มัลดีฟส์',
  [GeneralKeys.Country.mh]: 'หมู่เกาะมาร์แชลล์',
  [GeneralKeys.Country.mk]: 'สาธารณรัฐมาซิโดเนียเหนือ',
  [GeneralKeys.Country.ml]: 'มาลี',
  [GeneralKeys.Country.mt]: 'มอลตา',
  [GeneralKeys.Country.mm]: 'พม่า',
  [GeneralKeys.Country.me]: 'มอนเตเนโกร',
  [GeneralKeys.Country.mn]: 'มองโกเลีย',
  [GeneralKeys.Country.mp]: 'หมู่เกาะนอร์เทิร์นมาเรียนา',
  [GeneralKeys.Country.mz]: 'โมซัมบิก',
  [GeneralKeys.Country.mr]: 'มอริเตเนีย',
  [GeneralKeys.Country.ms]: 'มอนต์เซอร์รัต',
  [GeneralKeys.Country.mq]: 'มาร์ตีนิก',
  [GeneralKeys.Country.mu]: 'มอริเชียส',
  [GeneralKeys.Country.mw]: 'มาลาวี',
  [GeneralKeys.Country.yt]: 'มายอต',
  [GeneralKeys.Country.na]: 'นามิเบีย',
  [GeneralKeys.Country.nc]: 'นิวแคลิโดเนีย',
  [GeneralKeys.Country.ne]: 'ไนเจอร์',
  [GeneralKeys.Country.nf]: 'เกาะนอร์ฟอล์ก',
  [GeneralKeys.Country.ng]: 'ไนจีเรีย',
  [GeneralKeys.Country.ni]: 'นิการากัว',
  [GeneralKeys.Country.nu]: 'นีอูเอ',
  [GeneralKeys.Country.np]: 'เนปาล',
  [GeneralKeys.Country.nr]: 'นาอูรู',
  [GeneralKeys.Country.om]: 'โอมาน',
  [GeneralKeys.Country.pa]: 'ปานามา',
  [GeneralKeys.Country.pn]: 'พิตแคร์น',
  [GeneralKeys.Country.pe]: 'เปรู',
  [GeneralKeys.Country.pw]: 'ปาเลา',
  [GeneralKeys.Country.pg]: 'ปาปัวนิวกินี',
  [GeneralKeys.Country.pr]: 'เปอร์โตริโก',
  [GeneralKeys.Country.kp]: 'เกาหลีเหนือ (สาธารณรัฐประชาชนเฉพาะ)',
  [GeneralKeys.Country.py]: 'ปารากวัย',
  [GeneralKeys.Country.ps]: 'ปาเลสไตน์',
  [GeneralKeys.Country.qa]: 'กาตาร์',
  [GeneralKeys.Country.re]: 'เรอูเนียง',
  [GeneralKeys.Country.ro]: 'โรมาเนีย',
  [GeneralKeys.Country.ru]: 'รัสเซีย',
  [GeneralKeys.Country.rw]: 'รวันดา',
  [GeneralKeys.Country.sd]: 'ซูดาน',
  [GeneralKeys.Country.sn]: 'เซเนกัล',
  [GeneralKeys.Country.gs]: 'เกาะเซาท์จอร์เจียและหมู่เกาะแซวิช',
  [GeneralKeys.Country.sh]: 'เซนต์เฮเลนาและเซนต์ทริสตันดาคูนา',
  [GeneralKeys.Country.sj]: 'สฟาลบาร์และยันเมเยน',
  [GeneralKeys.Country.sb]: 'หมู่เกาะโซโลมอน',
  [GeneralKeys.Country.sl]: 'เซียร์ราลีโอน',
  [GeneralKeys.Country.sv]: 'เอลซัลวาดอร์',
  [GeneralKeys.Country.sm]: 'ซานมารีโน',
  [GeneralKeys.Country.so]: 'โซมาเลีย',
  [GeneralKeys.Country.pm]: 'แซงปีแยร์และมีเคอลอง',
  [GeneralKeys.Country.rs]: 'เซอร์เบีย',
  [GeneralKeys.Country.ss]: 'ซูดานใต้',
  [GeneralKeys.Country.st]: 'เซาตูเมและปรินซิปี',
  [GeneralKeys.Country.sr]: 'ซูรินาเม',
  [GeneralKeys.Country.sk]: 'สโลวะเกีย',
  [GeneralKeys.Country.si]: 'สโลวีเนีย',
  [GeneralKeys.Country.sz]: 'อิสวาตินี',
  [GeneralKeys.Country.sx]: 'ซินต์มาร์เทิน',
  [GeneralKeys.Country.sc]: 'เซเชลส์',
  [GeneralKeys.Country.sy]: 'สาธารณรัฐซีเรีย',
  [GeneralKeys.Country.tc]: 'หมู่เกาะเติกส์และเคคอส',
  [GeneralKeys.Country.td]: 'ชาด',
  [GeneralKeys.Country.tg]: 'โตโก',
  [GeneralKeys.Country.tj]: 'ทาจิกิสถาน',
  [GeneralKeys.Country.tk]: 'โทเกเลา',
  [GeneralKeys.Country.tm]: 'เติร์กเมนิสถาน',
  [GeneralKeys.Country.tl]: 'ติมอร์-เลสเต',
  [GeneralKeys.Country.to]: 'ตองกา',
  [GeneralKeys.Country.tt]: 'ตรินิแดดและโตเบโก',
  [GeneralKeys.Country.tn]: 'ตูนิเซีย',
  [GeneralKeys.Country.tv]: 'ตูวาลู',
  [GeneralKeys.Country.tz]: 'แทนซาเนีย (สหภาพแรงงาน)',
  [GeneralKeys.Country.ug]: 'ยูกันดา',
  [GeneralKeys.Country.ua]: 'ยูเครน',
  [GeneralKeys.Country.um]: 'หมู่เกาะเครือข่ายรอบนอกของสหรัฐอเมริกา',
  [GeneralKeys.Country.uy]: 'อุรุกวัย',
  [GeneralKeys.Country.uz]: 'อุซเบกิสถาน',
  [GeneralKeys.Country.va]: 'นครวาติกัน',
  [GeneralKeys.Country.vc]: 'เซนต์วินเซนต์และเกรนาดีนส์',
  [GeneralKeys.Country.ve]: 'เวเนซุเอลา (สาธารณรัฐโบลิวาเรีย)',
  [GeneralKeys.Country.vg]: 'หมู่เกาะเวอร์จิน (อังกฤษ)',
  [GeneralKeys.Country.vi]: 'หมู่เกาะเวอร์จิน (สหรัฐ)',
  [GeneralKeys.Country.vu]: 'วานูอาตู',
  [GeneralKeys.Country.wf]: 'วาลลิสและฟุตูนา',
  [GeneralKeys.Country.ws]: 'ซามัว',
  [GeneralKeys.Country.ye]: 'เยเมน',
  [GeneralKeys.Country.za]: 'แอฟริกาใต้',
  [GeneralKeys.Country.zm]: 'แซมเบีย',
  [GeneralKeys.Country.zw]: 'ซิมบับเว',
};
