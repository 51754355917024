const languages = [
  {
    label: 'English',
    value: 'en',
    flag: '/img/flags/US-s.png',
  },
  {
    label: '한국어',
    value: 'ko',
    flag: '/img/flags/Korea-s.png',
    disabled: false,
  },
  {
    label: '日本語',
    value: 'ja',
    flag: '/img/flags/Japan-s.png',
    disabled: false,
  },
  {
    label: '中文',
    value: 'zh',
    flag: '/img/flags/China-s.png',
    disabled: false,
  },
  {
    label: 'Español',
    value: 'es',
    flag: '/img/flags/Spain-s.png',
    disabled: false,
  },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    flag: '/img/flags/Vietnam-s.png',
    disabled: false,
  },
  {
    label: 'ภาษาไทย',
    value: 'th',
    flag: '/img/flags/Thailand-s.png',
    disabled: false,
  },
  {
    label: 'Français',
    value: 'fr',
    flag: '/img/flags/France-s.png',
    disabled: true,
  },
  {
    label: 'Português',
    value: 'pt',
    flag: '/img/flags/Portugal-s.png',
    disabled: true,
  },
  {
    label: 'Italiano',
    value: 'it',
    flag: '/img/flags/Italy-s.png',
    disabled: true,
  },
  {
    label: 'Deutsche',
    value: 'de',
    flag: '/img/flags/German-s.png',
    disabled: true,
  },
  {
    label: 'Hindi',
    value: 'hi',
    flag: '/img/flags/India-s.png',
    disabled: true,
  },
];

export default languages;
