import { createContext, useContext, useEffect } from 'react';
import connectSocket from 'components/utilities/connectSocket';

const socket = connectSocket();
const SocketContext = createContext(socket);

const SocketProvider = ({ children }) => {
  useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};

const useSocket = () => {
  return useContext(SocketContext);
};

export { SocketProvider, useSocket };
