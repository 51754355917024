import AxiosClient from 'components/utilities/AxiosClient';
import { createContext, useContext, useRef, useState } from 'react';
import { useProfileState } from '../../common/ProfileContext';
import { useFormik } from 'formik/dist';
import { useMemo } from 'react';
import { useCallback } from 'react';
import getInitialValues from './getInitialValues';
import getValidationSchema from './getValidationSchema';
import getPackageCompletion from './getPackageCompletion';
import getIncompletePackages from './getIncompletePackages';
import processPackages from './processPackages';
import { toFormData } from '../../../../utilities/toFormData';
import UserType from 'constants/userType';
import PackagesType from 'constants/packageType';
import { useEvent } from 'react-use';
import { forEach, cloneDeep } from 'lodash';
import PackagesPreviewModal from './PackagesPreviewModal';
import { Package } from './dataTypes';
import { useEffect } from 'react';
import { useToast } from 'components/Basics/Toast';
import useUser from 'hooks/useUser';
import IncompletePackagesModal from './IncompletePackagesModal';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';

const getPackages = async (userId) => {
  return AxiosClient.get('/getPackages', {
    params: {
      userId,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return {};
    });
};

const EditManagerContext = createContext();

export const EditMangerProvider = ({ children }) => {
  const { t } = useTranslation();

  const toast = useToast();
  const { refetch } = useUser();
  const { platform, type, user } = useProfileState();
  const [packages, setPackages] = useState({});
  const [selectedPackageType, setSelectedPackageType] = useState(
    PackagesType.Standard
  );
  const [incompletePackages, setIncompletePackages] = useState([]);

  useEffect(() => {
    const currentPackages = packages?.[platform];

    if (!currentPackages) return;

    const representativePackage = Object.values(currentPackages).find(
      (currentPackage) => currentPackage.isRep
    );
    if (representativePackage) {
      setSelectedPackageType(representativePackage.tier);
    }
  }, [packages, platform]);

  const [packageLoading, setPackageLoading] = useState(
    type === UserType.Creator
  );
  useEffect(() => {
    if (type !== UserType.Creator) return;

    setPackageLoading(true);
    if (user?.id)
      getPackages(user.id)
        .then((res) => {
          setPackages(res);
        })
        .finally(() => {
          setPackageLoading(false);
        });
  }, [type, user?.id]);

  const [links, setLinks] = useState([]);
  useEffect(() => {
    if (user?.links) {
      setLinks(user?.links?.sort((a, b) => a.priority - b.priority) || []);
    }
  }, [user?.links]);

  const [showPackagesPreview, setShowPackagesPreview] = useState(false);

  const [isDataPending, setIsDataPending] = useState(false);
  const onSubmit = useCallback(
    async (values) => {
      if (isDataPending) return;

      const savingToastId = toast.loading(
        t(ProfileKeys.Edit.Toast.being_saved),
        {
          autoClose: false,
          closeOnClick: false,
        }
      );

      const cloneValues = cloneDeep(values);

      if (cloneValues.packages)
        cloneValues.packages = processPackages(cloneValues.packages);

      const formData = toFormData(cloneValues);

      return AxiosClient.post('/updateUserProfile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          toast.close(savingToastId);
          toast.success(t(ProfileKeys.Edit.Toast.saved), {
            autoCloseDelay: 2500,
          });
          refetch();
        })
        .catch(() => {
          toast.close(savingToastId);
          toast.error(t(ProfileKeys.Edit.Toast.failed), {
            autoCloseDelay: 2500,
          });
        });
    },
    [isDataPending, toast, refetch, t]
  );

  const validationSchema = useMemo(() => getValidationSchema(type), [type]);
  const formik = useFormik({
    initialValues: getInitialValues(type),
    onSubmit,
    validateOnChange: false,
    validationSchema,
  });

  const formikSubmitForm = formik.submitForm;
  const submitForm = useCallback(() => {
    if (type === UserType.Client) return formikSubmitForm();

    const packagesToCheck = links.reduce((acc, link) => {
      return {
        ...acc,
        [link.type]: formik.values?.packages?.[link.type],
      };
    }, {});

    const incompletePackages = getIncompletePackages(packagesToCheck);
    if (incompletePackages.length > 0)
      setIncompletePackages(incompletePackages);
    else formikSubmitForm();
  }, [formikSubmitForm, formik.values?.packages, type, links]);

  // save on ctrl + s
  useEvent('keydown', (e) => {
    if ((e.ctrlKey || e.metaKey) && e.key === 's') {
      e.preventDefault();
      submitForm();
    }
  });

  // console.log('formik.values', formik.values);
  // console.log('formik.errors', formik.errors);

  const [userInitialized, setUserInitialized] = useState(false);
  const [packagesInitialized, setPackagesInitialized] = useState(false);
  const setValues = formik.setValues;

  // for client to creator (registration), we should re-initialize data
  useEffect(() => {
    setUserInitialized(false);
    setPackagesInitialized(false);
  }, [type]);

  useEffect(() => {
    if (
      !(userInitialized && packagesInitialized) &&
      user &&
      Object.keys(user).length > 0
    ) {
      if (
        type === UserType.Client ||
        (type === UserType.Creator && !packageLoading)
      )
        setPackagesInitialized(true);

      setUserInitialized(true);
      setValues(getInitialValues(type, { user, packages }));
    }
  }, [
    userInitialized,
    packagesInitialized,
    user,
    packages,
    type,
    setValues,
    packageLoading,
  ]);

  const getPackageFieldName = useCallback(
    (key) => {
      return `packages.${platform}.${selectedPackageType}.${key}`;
    },
    [platform, selectedPackageType]
  );

  const getUserInfoFieldName = useCallback((key) => {
    return `userInfo.${key}`;
  }, []);

  const getPackageValue = useCallback(
    (key) => {
      return formik.values?.packages?.[platform]?.[selectedPackageType]?.[key];
    },
    [formik.values, platform, selectedPackageType]
  );

  const getUserInfoValue = useCallback(
    (key) => {
      return formik.values?.userInfo?.[key];
    },
    [formik.values]
  );

  const setFieldValue = formik.setFieldValue;
  const setPackageValue = useCallback(
    (key, value, shouldValidate) => {
      setFieldValue(getPackageFieldName(key), value, shouldValidate);
    },
    [setFieldValue, getPackageFieldName]
  );

  const setUserInfoValue = useCallback(
    (key, value, shouldValidate) => {
      setFieldValue(getUserInfoFieldName(key), value, shouldValidate);
    },
    [setFieldValue, getUserInfoFieldName]
  );

  const getPackageError = useCallback(
    (key) => {
      return formik.errors.packages?.[platform]?.[selectedPackageType]?.[key];
    },
    [formik.errors, platform, selectedPackageType]
  );

  const getUserInfoError = useCallback(
    (key) => {
      return formik.errors.userInfo?.[key];
    },
    [formik.errors]
  );

  const setFieldError = formik.setFieldError;
  const setPackageError = useCallback(
    (key, value) => {
      setFieldError(getPackageFieldName(key), value);
    },
    [setFieldError, getPackageFieldName]
  );

  const setUserInfoError = useCallback(
    (key, value) => {
      setFieldError(getUserInfoFieldName(key), value);
    },
    [setFieldError, getUserInfoFieldName]
  );

  const getFieldProps = formik.getFieldProps;
  const getPackageFieldProps = useCallback(
    (key) => {
      return getFieldProps(getPackageFieldName(key));
    },
    [getFieldProps, getPackageFieldName]
  );

  const getUserInfoFieldProps = useCallback(
    (key) => {
      return getFieldProps(getUserInfoFieldName(key));
    },
    [getFieldProps, getUserInfoFieldName]
  );

  const updateRepresentativePackage = useCallback(
    (packageType) => {
      if (!platform || packageLoading) return;

      forEach(PackagesType, (type) => {
        if (type === packageType) {
          setFieldValue(
            `packages.${platform}.${type}.${Package.IsRepresentative}`,
            true
          );
        } else {
          setFieldValue(
            `packages.${platform}.${type}.${Package.IsRepresentative}`,
            false
          );
        }
      });
    },
    [setFieldValue, platform, packageLoading]
  );

  const noRepresentativePackage = useMemo(() => {
    return !Object.values(PackagesType).some(
      (type) =>
        formik.values?.packages?.[platform]?.[type]?.[Package.IsRepresentative]
    );
  }, [formik.values, platform]);

  useEffect(() => {
    if (noRepresentativePackage) {
      const selectedPlatformPackages = formik.values?.packages?.[platform];
      const firstCompletedPackage = Object.values(PackagesType).find(
        (type) =>
          getPackageCompletion(
            selectedPlatformPackages?.[type],
            platform,
            getPackageError
          ) === 100
      );

      if (firstCompletedPackage)
        updateRepresentativePackage(firstCompletedPackage);
    }
  }, [
    noRepresentativePackage,
    updateRepresentativePackage,
    platform,
    formik.values?.packages,
    getPackageError,
  ]);

  const packageCompletionPercentage = useMemo(() => {
    if (
      type !== UserType.Creator ||
      !platform ||
      !selectedPackageType ||
      packageLoading
    )
      return 0;

    const percentage =
      links?.length > 0 && platform && selectedPackageType
        ? getPackageCompletion(
            formik.values?.packages?.[platform]?.[selectedPackageType],
            platform,
            getPackageError
          )
        : 0;

    if (percentage === 100 && noRepresentativePackage) {
      updateRepresentativePackage(selectedPackageType);
    }

    if (percentage < 100) {
      setPackageValue(Package.IsRepresentative, false);
    }

    return percentage;
  }, [
    formik.values,
    getPackageError,
    platform,
    selectedPackageType,
    type,
    noRepresentativePackage,
    updateRepresentativePackage,
    setPackageValue,
    packageLoading,
    links,
  ]);

  const applyTemplate = useCallback(
    (template) => {
      const title = template.title;
      const description = template.description;
      const typeLength = template.typeLength;
      const contents = template.contents;

      if (title) setPackageValue(Package.Title, title);
      if (description) setPackageValue(Package.Description, description);
      if (typeLength) setPackageValue(Package.TypeLength, typeLength);
      if (contents) setPackageValue(Package.Contents, contents);
    },
    [setPackageValue]
  );

  const data = useMemo(
    () => ({
      isDataPending,
      setIsDataPending,
      selectedPackageType,
      setSelectedPackageType,
      packageCompletionPercentage,
      submitForm,
      isSubmitting: formik.isSubmitting,
      getPackageValue,
      getUserInfoValue,
      setPackageValue,
      setUserInfoValue,
      getPackageError,
      getUserInfoError,
      setPackageError,
      setUserInfoError,
      getPackageFieldProps,
      getUserInfoFieldProps,
      getPackageFieldName,
      getUserInfoFieldName,
      setFieldValue: formik.setFieldValue,
      updateRepresentativePackage,
      handleChange: formik.handleChange,
      handleBlur: formik.handleBlur,
      packages: formik.values?.packages,
      showPackagesPreview: () => setShowPackagesPreview(true),
      applyTemplate,
      packageLoading,
      links,
      setLinks,
    }),
    [
      isDataPending,
      selectedPackageType,
      packageCompletionPercentage,
      submitForm,
      formik.isSubmitting,
      getPackageValue,
      getUserInfoValue,
      setPackageValue,
      setUserInfoValue,
      getPackageError,
      getUserInfoError,
      setPackageError,
      setUserInfoError,
      getPackageFieldProps,
      getUserInfoFieldProps,
      getPackageFieldName,
      getUserInfoFieldName,
      formik.setFieldValue,
      updateRepresentativePackage,
      formik.handleChange,
      formik.handleBlur,
      formik.values?.packages,
      applyTemplate,
      packageLoading,
      links,
    ]
  );

  return (
    <EditManagerContext.Provider value={data}>
      {children}
      <PackagesPreviewModal
        show={showPackagesPreview}
        onClose={() => setShowPackagesPreview(false)}
      />
      <IncompletePackagesModal
        incompletePackages={incompletePackages}
        onClose={() => setIncompletePackages([])}
        onSubmit={() => {
          setIncompletePackages([]);
          formikSubmitForm();
        }}
      />
    </EditManagerContext.Provider>
  );
};

export const useEditManager = () => {
  const context = useContext(EditManagerContext);
  if (!context) {
    return {};
  } else {
    return context;
  }
};

export const useFileUploadManager = (
  key,
  { initialValue, maxSize, allowedTypes, enableReinitialize, afterSave } = {}
) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { setIsDataPending, setFieldValue } = useContext(EditManagerContext);

  const uploaderRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(initialValue || null);
  const currentFileRef = useRef(null);

  useEffect(() => {
    if (enableReinitialize) {
      setSelectedFile(initialValue);
    }
  }, [initialValue, enableReinitialize]);

  const [error, setError] = useState(null);

  const onFileChange = async (file) => {
    setError(null);

    if (maxSize && file.size > maxSize) {
      setError('FILE_TOO_LARGE');
      toast.error(
        t(ProfileKeys.Edit.Package.Featured_Image.Error.file_large) +
          ` ${maxSize / 1024 / 1024} MB)`,
        {
          autoCloseDelay: 2500,
        }
      );
      if (uploaderRef.current) {
        uploaderRef.current.value = null;
      }
      return;
    }

    // types can be like ['image/png', 'image/jpeg'] or ['image/*']
    if (
      allowedTypes &&
      !(
        allowedTypes.includes(file.type) ||
        allowedTypes.some(
          (type) =>
            type.includes('/*') && file.type.includes(type.split('/')[0])
        )
      )
    ) {
      setError('INVALID_FILE_TYPE');
      toast.error(
        t(ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid),
        {
          autoCloseDelay: 2500,
        }
      );
      if (uploaderRef.current) {
        uploaderRef.current.value = null;
      }
      return;
    }

    setIsDataPending(true);
    currentFileRef.current = selectedFile;
    let newFile = file;

    if (typeof file !== 'string') {
      newFile = URL.createObjectURL(file);
    } else {
      newFile = file;
    }

    setSelectedFile(newFile);
    setFieldValue(key, file);
    afterSave?.(newFile);
    setIsDataPending(false);

    if (uploaderRef.current) {
      uploaderRef.current.value = null;
    }
  };

  return {
    uploaderRef,
    selectedFile,
    onFileChange,
    error,
  };
};
