const size5 = () => (
  <svg
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5588 2.1713C19.444 1.7516 19.2202 1.36897 18.9097 1.06153C18.5991 0.754085 18.2126 0.532556 17.7886 0.419002C16.2364 0 9.9899 0 9.9899 0C9.9899 0 3.74318 0.0127154 2.19122 0.431718C1.76727 0.54529 1.38078 0.766827 1.07022 1.07427C0.759663 1.38171 0.53588 1.76433 0.421157 2.18402C-0.0483324 4.9142 -0.230477 9.07406 0.434001 11.6949C0.548724 12.1146 0.772507 12.4972 1.08307 12.8046C1.39362 13.1121 1.78012 13.3336 2.20407 13.4472C3.75614 13.8662 10.0027 13.8662 10.0027 13.8662C10.0027 13.8662 16.2493 13.8662 17.8014 13.4472C18.2254 13.3336 18.6119 13.1121 18.9224 12.8046C19.233 12.4972 19.4568 12.1146 19.5715 11.6949C20.0665 8.96096 20.2192 4.80363 19.5586 2.17143L19.5588 2.1713Z"
      fill="#FF0000"
    />
    <path d="M8 9.90753L13.182 6.93625L8 3.96484L8 9.90753Z" fill="white" />
  </svg>
);

export default size5;
