import FAQKeys from '../translationKeys/FAQPageKeys';

export const FAQTranslations = {
  // General
  [FAQKeys.General.title]: '通用问题',

  [FAQKeys.General.Q1.title]: '如何开始使用 MegaEvolution？',
  [FAQKeys.General.Q1.description]:
    '要开始您的 MegaEvolution 微博营销之旅，请在您的桌面或移动浏览器中访问我们的网站 megaevolution.io。点击“登录”按钮后，您可以使用您的个人邮箱、Google、Facebook 或钱包（使用 MetaMask 或 Coinbase 钱包）进行注册。同意我们的服务条款并在必要时验证您的邮箱。注册后，您可以轻松地与影响者取得联系，利用他们的影响力将您的产品或服务推广给更广泛的受众。我们的平台提供先进功能，如创作者频道/受众分析和一站式订单和支付系统，为企业轻松启动有效的影响者营销活动提供了便利。',

  [FAQKeys.General.Q2.title]: '什么是 MegaEvolution？',
  [FAQKeys.General.Q2.description]:
    'MegaEvolution 是一家影响者营销平台，将客户与全球创作者连接，以推广他们的产品和服务。客户可以轻松地找到和订购营销套餐，而创作者可以通过他们的服务进行自我推广，并获得更多机会和利润。MegaEvolution 简化了整个从订购到管理广告的过程，为所有用户提供了简单和便捷的体验。',

  [FAQKeys.General.Q3.title]: 'MegaEvolution 为创作者提供了哪些服务？',
  [FAQKeys.General.Q3.description]:
    'MegaEvolution 让创作者与来自世界各地的品牌客户连接。通过在 MegaEvolution 上推广他们的服务，创作者可以轻松地接收客户订单，并且我们支持整个订单和付款过程，帮助创作者最大化机会和利润。',

  [FAQKeys.General.Q4.title]: 'MegaEvolution 为客户提供了哪些服务？',
  [FAQKeys.General.Q4.description]:
    'MegaEvolution 连接客户与各类创作者，提供包括频道分析和营销价格等信息。客户可以通过一键订购创作者的营销套餐，我们在整个订购和付款过程中提供全面支持，确保安全和便捷的体验。',

  [FAQKeys.General.Q5.title]: '什么是 EVO 代币及其工作原理？',
  [FAQKeys.General.Q5.description]:
    'EVO 代币允许访问创作者套餐价格，可能很快将用于活动、投票和治理。我们计划在各种交易所上市，以实现与其他货币或现金的兑换。',

  // Package System
  [FAQKeys.Package_System.title]: '套餐系统',

  [FAQKeys.Package_System.Q1.title]: 'MegaEvolution 的套餐是什么？',
  [FAQKeys.Package_System.Q1.description]:
    'MegaEvolution 的套餐是一个系统，允许创作者展示他们的服务，包括提供的内容、时长、价格和详细的交付方式说明。用户可以通过几次点击轻松购买这些套餐，并通过他们的仪表板管理所有订单。此外，用户将收到有关其套餐订单状态的通知和分析信息。',

  [FAQKeys.Package_System.Q2.title]: '套餐的定价类型有哪些？',
  [FAQKeys.Package_System.Q2.description]:
    '有三种定价选项：“公开价格” / “可解锁价格” / “联系创作者”。创作者可以为每个套餐计划设置不同的定价选项。例如，标准套餐可以设置为公开价格，高级套餐可以设置为可解锁价格。',

  [FAQKeys.Package_System.Q3.title]:
    '解锁价格系统是如何进行的？解锁后的价格会显示多久？',
  [FAQKeys.Package_System.Q3.description]:
    '所有用户每个月都可以免费解锁特定次数的套餐价格。要解锁创作者套餐的价格，只需点击锁定价格。解锁后，您将能够查看该特定创作者的所有价格。解锁后的价格会显示 3 天。',

  // Creators
  [FAQKeys.Creators.title]: '创作者',

  [FAQKeys.Creators.Q1.title]: '我想了解创作者的服务费用。',
  [FAQKeys.Creators.Q1.description]: '目前，我们不会对创作者每个广告订单收费。',

  [FAQKeys.Creators.Q2.title]: '提现余额需要多长时间？',
  [FAQKeys.Creators.Q2.description]: '通过 PayPal 转账通常需要 1~3 个工作日。',

  [FAQKeys.Creators.Q3.title]:
    '我在注册成为创作者后解绑了所有社交网络账户，我的账户会自动删除吗？',
  [FAQKeys.Creators.Q3.description]:
    '您的账户不会被删除。但是，您将无法接收任何订单，并且您将不会出现在我们的搜索窗口中。',

  [FAQKeys.Creators.Q4.title]: '客户在我接受订单后能取消订单吗？',
  [FAQKeys.Creators.Q4.description]:
    '一旦创作者接受订单，只有在双方都同意取消的情况下才能取消订单。但是，如果创作者未能按时交付内容，或者内容质量与描述明显不符或极差，订单可以通过解决中心取消。请注意，取消订单可能对您的账户产生负面影响，并影响您在MegaEvolution上的创作者声誉。',

  // Orders Help
  [FAQKeys.Orders_Help.title]: '订单帮助',

  [FAQKeys.Orders_Help.Q1.title]: '如何处理延期和取消订单？',
  [FAQKeys.Orders_Help.Q1.description]:
    '您可以通过向对方发送请求来延期订单的截止日期或取消订单。您可以接受或拒绝收到的请求。如果几天内（延期为3天，取消为5天）没有回应，请求将自动接受。',

  [FAQKeys.Orders_Help.Q2.title]: '在创作者接受订单后，我能取消订单吗？',
  [FAQKeys.Orders_Help.Q2.description]:
    '通常，一旦客户接受订单，只有在创作者同意的情况下才能取消订单。但是，如果创作者未能遵守交付日期或内容质量不令人满意，订单可以通过解决中心取消。',

  [FAQKeys.Orders_Help.Q3.title]: '如果我没有收到内容怎么办？',
  [FAQKeys.Orders_Help.Q3.description]:
    '创作者应该发送每个已订购内容的链接。如果您在截止日期之前没有收到内容，您可以请求取消并退款。',

  // Payment Help
  [FAQKeys.Payment_Help.title]: '支付帮助',

  [FAQKeys.Payment_Help.Q1.title]: '如何查看我的支付历史记录？',
  [FAQKeys.Payment_Help.Q1
    .description]: `1. 登录您的账户，点击网站右上角的‘个人空间（MySpace）’，然后点击‘账单’按钮。
  2. 使用“支付历史记录”选项卡查看您的交易。您还可以下载PDF格式的发票。`,

  [FAQKeys.Payment_Help.Q2.title]: '如何进行支付？',
  [FAQKeys.Payment_Help.Q2
    .description]: `1. 在页面右上角点击‘个人空间（MySpace）’，然后转到‘账单’。
  2. 在“支付方式”选项卡中添加“信用卡和借记卡”或“PayPal账户”。
  3. 接下来，您可以使用添加的信息进行支付。`,

  [FAQKeys.Payment_Help.Q3.title]: 'MegaEvolution 如何向客户退款？',
  [FAQKeys.Payment_Help.Q3.description]:
    '对于创作者订单，取消后会全额退款。如果已发布内容，将在退款前进行相应扣除。月度自动订阅平台不提供退款。',

  [FAQKeys.Payment_Help.Q4.title]: 'MegaEvolution 的服务费是多少，如何计算？',
  [FAQKeys.Payment_Help.Q4.description]:
    'MegaEvolution 对每个套餐订单收取5.5% + $3的服务费。我们提供业界最具竞争力的服务费用。这个小费用有助于我们运营平台并支持每个订单过程。如果您是订阅用户，您将获得服务费的折扣。',

  [FAQKeys.Payment_Help.Q5.title]: '有哪些订阅计划可用？',
  [FAQKeys.Payment_Help.Q5.description]:
    '我们提供3种类型的月度订阅计划：基本（免费）、标准和高级。基本计划提供大多数平台功能，而标准和高级计划包括额外功能，如更多价格解锁、分析和批量消息发送。付费计划还可以为订单提供服务费的小折扣。',

  // Account Help
  [FAQKeys.Account_Help.title]: '账户帮助',

  [FAQKeys.Account_Help.Q1
    .title]: `如果我无法记住登录详情（ID或密码），该怎么办？`,
  [FAQKeys.Account_Help.Q1.description]:
    "在MegaEvolution上创建账户时，您可以使用您的电子邮件、Google、Facebook或钱包（使用MetaMask或Coinbase Wallet）。如果您忘记了电子邮件账户的密码，只需转到登录页面，然后点击'忘记密码？'。然后，输入您的电子邮件地址，您将收到一封带有重置密码链接的电子邮件。",

  [FAQKeys.Account_Help.Q2.title]: '如何更改密码？',
  [FAQKeys.Account_Help.Q2
    .description]: `1. 在登录后，点击页面右上角的‘个人空间（MySpace）’。
  2. 点击‘设置（Settings）’，在‘账户（Account）’部分更新您的新密码。`,

  [FAQKeys.Account_Help.Q3.title]: '如何停用或删除我的账户？',
  [FAQKeys.Account_Help.Q3
    .description]: `1. 在登录后，点击页面右上角的‘个人空间（MySpace）’。
  2. 点击‘设置（Settings）’，滚动到‘停用/删除（Deactivate/Delete）’部分。
  3. 点击‘停用（Deactivate）’或‘删除（Delete）’按钮。（删除账户后，所有数据可能会丢失，无法恢复。）`,

  // Safety & Privacy
  [FAQKeys.Safety_Privacy.title]: '安全与隐私',

  [FAQKeys.Safety_Privacy.Q1.title]: '我的信息如何得到保障？',
  [FAQKeys.Safety_Privacy.Q1.description]:
    '在MegaEvolution，我们非常重视隐私和安全。我们采用行业标准的措施，确保您的个人信息免受未经授权的访问或滥用。欲了解更多关于我们保护您隐私的承诺，请参阅我们的隐私政策。',

  [FAQKeys.Safety_Privacy.Q2.title]: 'MegaEvolution保留什么样的数据？',
  [FAQKeys.Safety_Privacy.Q2.description]:
    'MegaEvolution为我们的用户存储各种重要数据，包括订单记录、支付信息、用户数据、用户资料、套餐信息、技术日志信息等等。',

  [FAQKeys.Safety_Privacy.Q3.title]: '如何停用或删除我的账户？',
  [FAQKeys.Safety_Privacy.Q3
    .description]: `1. 在登录后，点击页面右上角的‘个人空间（MySpace）’。
  2. 点击‘设置（Settings）’，滚动到‘停用/删除（Deactivate/Delete）’部分。
  3. 点击‘停用（Deactivate）’或‘删除（Delete）’按钮。（删除账户后，所有数据可能会丢失，无法恢复。）`,
};
