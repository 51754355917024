import { createElement, forwardRef } from 'react';

/**
 * @param {string} variant - The variant of the text.
 * - lg
 * - md
 * - sm
 * - xs
 * @param {string} [as] - The tag name of the text i.e. p(default), span.
 * @param {string} children - The content of the heading.
 * @param {string} [className] - The additional class name of the heading.
 * @param {string} [fontFamily]
 * @param {string} [color] - color of text
 * @additional The rest of the props are passed to the underlying component.
 */
const Text = forwardRef(
  (
    {
      variant = 'md',
      as,
      children,
      className,
      fontFamily,
      color,
      margin,
      style,
      ...rest
    },
    ref
  ) => {
    const tagName = as || 'p';

    return createElement(
      tagName,
      {
        className: `text-${variant.toLowerCase()} ${className}`,

        style: {
          fontFamily: fontFamily ? fontFamily : null,
          color: color ?? null,
          margin: margin ?? 0,
          padding: 0,
          ...style,
        },
        ref,
        ...rest,
      },
      children
    );
  }
);

export default Text;
