export const StarIcon = ({ size = 1 }) => (
  <svg
    width={size * 20}
    height={size * 20}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.3536 7.04225L13.358 6.16272L10.6778 0.678186C10.6046 0.528023 10.4841 0.406462 10.3354 0.332572C9.96227 0.146656 9.50887 0.301586 9.32232 0.678186L6.64213 6.16272L0.646516 7.04225C0.481218 7.06609 0.330088 7.14474 0.214379 7.26392C0.0744931 7.40905 -0.00259025 7.60429 6.6464e-05 7.80676C0.00272317 8.00922 0.0849026 8.20233 0.228547 8.34367L4.56645 12.6126L3.5416 18.6406C3.51757 18.7808 3.53294 18.925 3.58598 19.0569C3.63901 19.1887 3.72759 19.3029 3.84166 19.3866C3.95573 19.4702 4.09074 19.5199 4.23137 19.53C4.37199 19.5401 4.51262 19.5103 4.63729 19.4438L10 16.5979L15.3628 19.4438C15.5092 19.5225 15.6792 19.5487 15.8422 19.5201C16.2531 19.4486 16.5293 19.0553 16.4585 18.6406L15.4336 12.6126L19.7715 8.34367C19.8896 8.22687 19.9675 8.07433 19.9912 7.90748C20.0549 7.49036 19.7668 7.10422 19.3536 7.04225V7.04225Z" />
  </svg>
);
