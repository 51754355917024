import * as Yup from 'yup';
import { UserInfo, Package } from './dataTypes';
import UserType from 'constants/userType';
import Platform from 'constants/platform';
import PackageType from 'constants/packageType';
import AxiosClient from 'components/utilities/AxiosClient';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';

const checkEditorContent = (limit) => (state) => {
  try {
    if (!state || state === 'undefined' || state === 'null') return true;

    const parsedState = JSON.parse(state);

    const length = parsedState.length;

    return length <= limit;
  } catch (e) {
    return false;
  }
};

export const getValidationSchema = (userType) => {
  return {
    [UserType.Client]: Yup.object().shape({
      userInfo: Yup.object().shape({
        [UserInfo.Name]: Yup.string()
          .max(50, ProfileKeys.Edit.User_Info.Username.Error.max_50)
          .required(ProfileKeys.Edit.User_Info.Username.Error.enter_name),
        [UserInfo.CompanyLogo]: Yup.mixed().nullable(),
        [UserInfo.Company]: Yup.string().max(
          30,
          ProfileKeys.Edit.User_Info.Company.Error.max_30
        ),
        [UserInfo.Country]: Yup.string(),
        [UserInfo.Website]: Yup.string(),
        [UserInfo.Bio]: Yup.mixed().test({
          name: 'bio',
          message: ProfileKeys.Edit.User_Info.Username.Error.max_2000,
          test: checkEditorContent(2000),
        }),
        [UserInfo.BannerShade]: Yup.boolean(),
      }),
    }),
    [UserType.Creator]: Yup.object().shape({
      userInfo: Yup.object().shape({
        [UserInfo.Name]: Yup.string()
          .max(50, ProfileKeys.Edit.User_Info.Username.Error.max_50)
          .required(ProfileKeys.Edit.User_Info.Username.Error.enter_name),
        [UserInfo.Handle]: Yup.string()
          .min(3, ProfileKeys.Edit.User_Info.My_URL.Error.char_limit)
          .max(30, ProfileKeys.Edit.User_Info.My_URL.Error.char_limit)
          .matches(
            /^[a-zA-Z0-9_.-]+$/,
            ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol
          )
          .required(ProfileKeys.Edit.User_Info.My_URL.Error.required_handle)
          .test({
            name: 'handle_taken',
            message: ProfileKeys.Edit.User_Info.My_URL.Error.already_taken,
            test: async (value) => {
              try {
                await AxiosClient.post('checkHandle', { handle: value });
                return true;
              } catch (err) {
                return false;
              }
            },
          }),
        [UserInfo.Bio]: Yup.mixed().test({
          name: 'bio',
          message: ProfileKeys.Edit.User_Info.Username.Error.max_2000,
          test: checkEditorContent(2000),
        }),
        [UserInfo.Categories]: Yup.array().max(5),
        [UserInfo.Countries]: Yup.array().max(5),
        [UserInfo.Languages]: Yup.array().max(5),
        [UserInfo.BannerShade]: Yup.boolean(),
      }),
      packages: Yup.object().shape({
        ...Object.values(Platform).reduce((acc, platform) => {
          acc[platform] = Yup.object().shape({
            ...Object.values(PackageType).reduce((acc, type) => {
              acc[type] = Yup.object().shape({
                [Package.IsRepresentative]: Yup.boolean(),
                [Package.Title]: Yup.string().max(
                  100,
                  ProfileKeys.Edit.User_Info.Username.Error.max_100
                ),
                [Package.SampleContents]: Yup.array().of(
                  Yup.string().max(
                    500,
                    ProfileKeys.Edit.User_Info.Username.Error.max_500
                  )
                ),
                [Package.Description]: Yup.mixed().test({
                  name: 'description',
                  message: ProfileKeys.Edit.User_Info.Username.Error.max_2000,
                  test: checkEditorContent(2000),
                }),
                [Package.Price]: Yup.string().matches(
                  /[0-9]+/,
                  ProfileKeys.Edit.User_Info.Username.Error.invalid_price
                ),
                [Package.PriceType]: Yup.string(),
                [Package.Contents]: Yup.object(),
                [Package.Delivery]: Yup.string()
                  .matches(
                    /[0-9]+/,
                    ProfileKeys.Edit.User_Info.Username.Error
                      .invalid_delivery_time
                  )
                  .test(
                    'delivery',
                    ProfileKeys.Edit.User_Info.Username.Error
                      .delivery_time_1_60,
                    (value) =>
                      !value || (parseInt(value) >= 1 && parseInt(value) <= 60),
                    ProfileKeys.Edit.User_Info.Username.Error
                      .invalid_delivery_time
                  ),
                [Package.Revisions]: Yup.number().nullable(),
                [Package.TypeLength]: Yup.string().max(
                  200,
                  ProfileKeys.Edit.User_Info.Username.Error.max_200
                ),
                [Package.FeaturedContent]: Yup.mixed().nullable(),
              });
              return acc;
            }, {}),
          });
          return acc;
        }, {}),
      }),
    }),
  }[userType];
};

export default getValidationSchema;
