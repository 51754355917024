import { createContext, useContext, useState } from 'react';
import Popup from 'components/Basics/Popup';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import Image from 'components/Basics/Image';
import Stack from 'components/Basics/Layout/Stack';
import styled from 'styled-components';
import IndigoButton from 'components/Basics/Buttons/IndigoButton';
import { useTranslation } from 'react-i18next';
import ModalKeys from 'translations/translationKeys/ModalKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { Trans } from 'react-i18next';

const OfficialLaunchModalContext = createContext();

export const useOfficialLaunchModal = () =>
  useContext(OfficialLaunchModalContext);

export const OfficialLaunchModalProvider = ({ children }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);

  const { isMobile } = useResponsiveScreen();

  return (
    <OfficialLaunchModalContext.Provider value={openModal}>
      {children}

      <Popup
        show={isOpen && !isMobile}
        onClose={() => setIsOpen(false)}
        title={t(ModalKeys.official_launch.title)}
        description={<Trans>{t(ModalKeys.official_launch.content)}</Trans>}
        buttonText={t(GeneralKeys.close)}
        onButtonClick={() => setIsOpen(false)}
      />

      <Popup
        show={isOpen && isMobile}
        onClose={() => setIsOpen(false)}
        title={
          <Stack gap="1.25rem" align="center" margin="1.875rem 0 0.625rem 0">
            <Image
              height="auto"
              width="205px"
              src="/img/bannerCards/order_accept.png"
            />
            <>{t(ModalKeys.official_launch.title)}</>
          </Stack>
        }
        noButton
      >
        <MobileIndigoButton
          variant="filled"
          className="bh5"
          padding="0.53125rem"
          radius="999px"
          onClick={() => setIsOpen(false)}
          minWidth="136px"
          autoFocus
        >
          {t(GeneralKeys.return)}
        </MobileIndigoButton>
      </Popup>
    </OfficialLaunchModalContext.Provider>
  );
};

const MobileIndigoButton = styled(IndigoButton)`
  max-width: 136px;
  min-width: 136px;
  box-shadow: var(--shadow-yellow-btn1);
  align-self: center;
  margin-bottom: 1rem;
`;
