export const DiscordGrayIcon = ({ size = 1 }) => (
  <svg
    width={24 * size}
    height={20 * size}
    viewBox="0 0 24 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.3302 2.26691C18.7563 1.53047 17.0912 1.00721 15.3789 0.710938C15.1656 1.09653 14.9165 1.61516 14.7446 2.02775C12.8985 1.75016 11.0693 1.75016 9.25715 2.02775C9.0854 1.61525 8.83058 1.09653 8.61533 0.710938C6.90139 1.00734 5.23486 1.53194 3.66018 2.27075C0.526681 7.00596 -0.322788 11.6234 0.1019 16.1755C2.18024 17.7276 4.19437 18.6704 6.17455 19.2874C6.66668 18.6106 7.10171 17.8941 7.47515 17.1453C6.76417 16.8746 6.07877 16.5411 5.42709 16.1486C5.59858 16.0214 5.76604 15.889 5.92921 15.7513C9.87815 17.5984 14.1689 17.5984 18.0708 15.7513C18.2346 15.8881 18.4021 16.0205 18.5728 16.1486C17.9201 16.5421 17.2334 16.8763 16.521 17.1473C16.8965 17.8991 17.3308 18.6163 17.8216 19.2893C19.8036 18.6724 21.8196 17.7295 23.898 16.1755C24.3964 10.8986 23.0467 6.32337 20.3302 2.26681V2.26691ZM8.01318 13.3761C6.82771 13.3761 5.85552 12.2694 5.85552 10.9217C5.85552 9.57406 6.80699 8.46546 8.01318 8.46546C9.21946 8.46546 10.1916 9.57209 10.1708 10.9217C10.1727 12.2694 9.21946 13.3761 8.01318 13.3761ZM15.9867 13.3761C14.8012 13.3761 13.8291 12.2694 13.8291 10.9217C13.8291 9.57406 14.7805 8.46546 15.9867 8.46546C17.193 8.46546 18.1651 9.57209 18.1444 10.9217C18.1444 12.2694 17.193 13.3761 15.9867 13.3761Z" />
  </svg>
);
