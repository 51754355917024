export const WalletIcon1 = ({ size = 1 }) => (
  <svg
    width={size * 12}
    height={size * 10}
    viewBox="0 0 12 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.85 2.2498H9.3V1.6998C9.3 1.2622 9.12616 0.842514 8.81673 0.533079C8.50729 0.223644 8.08761 0.0498047 7.65 0.0498047H2.15C1.71239 0.0498047 1.29271 0.223644 0.983274 0.533079C0.673839 0.842514 0.5 1.2622 0.5 1.6998V8.2998C0.5 8.73741 0.673839 9.15709 0.983274 9.46653C1.29271 9.77597 1.71239 9.9498 2.15 9.9498H9.85C10.2876 9.9498 10.7073 9.77597 11.0167 9.46653C11.3262 9.15709 11.5 8.73741 11.5 8.2998V3.8998C11.5 3.4622 11.3262 3.04251 11.0167 2.73308C10.7073 2.42364 10.2876 2.2498 9.85 2.2498ZM2.15 1.1498H7.65C7.79587 1.1498 7.93576 1.20775 8.03891 1.3109C8.14205 1.41404 8.2 1.55394 8.2 1.6998V2.2498H2.15C2.00413 2.2498 1.86424 2.19186 1.76109 2.08871C1.65795 1.98557 1.6 1.84567 1.6 1.6998C1.6 1.55394 1.65795 1.41404 1.76109 1.3109C1.86424 1.20775 2.00413 1.1498 2.15 1.1498ZM10.4 6.6498H9.85C9.70413 6.6498 9.56424 6.59186 9.46109 6.48871C9.35795 6.38557 9.3 6.24567 9.3 6.0998C9.3 5.95394 9.35795 5.81404 9.46109 5.7109C9.56424 5.60775 9.70413 5.5498 9.85 5.5498H10.4V6.6498ZM10.4 4.4498H9.85C9.41239 4.4498 8.99271 4.62364 8.68327 4.93308C8.37384 5.24251 8.2 5.6622 8.2 6.0998C8.2 6.53741 8.37384 6.9571 8.68327 7.26653C8.99271 7.57597 9.41239 7.7498 9.85 7.7498H10.4V8.2998C10.4 8.44567 10.3421 8.58557 10.2389 8.68871C10.1358 8.79186 9.99587 8.8498 9.85 8.8498H2.15C2.00413 8.8498 1.86424 8.79186 1.76109 8.68871C1.65795 8.58557 1.6 8.44567 1.6 8.2998V3.2563C1.7767 3.31846 1.96269 3.35008 2.15 3.3498H9.85C9.99587 3.3498 10.1358 3.40775 10.2389 3.5109C10.3421 3.61404 10.4 3.75394 10.4 3.8998V4.4498Z" />
  </svg>
);
