export const UAEFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29896_35080)">
      <path
        d="M37.2319 0.064209H-0.168145C-2.5982 0.064209 -4.56815 2.03416 -4.56815 4.46421V28.6642C-4.56815 31.0943 -2.5982 33.0642 -0.168145 33.0642H37.2319C39.6619 33.0642 41.6319 31.0943 41.6319 28.6642V4.46421C41.6319 2.03416 39.6619 0.064209 37.2319 0.064209Z"
        fill="#F5F5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-4.56815 22.0643H41.6319V33.0643H-4.56815V22.0643Z"
        fill="#151515"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-4.56815 0.064209H41.6319V11.0642H-4.56815V0.064209Z"
        fill="#249F58"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-4.56815 0.064209V33.0642H8.63186V0.064209H-4.56815Z"
        fill="#F93939"
      />
    </g>
    <defs>
      <clipPath id="clip0_29896_35080">
        <rect
          x="0.128418"
          y="0.064209"
          width="33"
          height="33"
          rx="16.5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
