import ModalKeys from '../translationKeys/ModalKeys';

export const ModalTranslations = {
  // OfficialLaunch
  [ModalKeys.official_launch.title]:
    'คุณสมบัตินี้จะถูกเปิดตัวในประเทศไทยเร็วๆนี้',
  [ModalKeys.official_launch.content]: `
    คุณสมบัตินี้จะถูกปลดล็อกเพิ่มเติมในภูมิภาคของคุณเร็วๆนี้ <br>
    เราจะให้บริการที่ดีที่สุดสำหรับการทำงานร่วมกับบริษัทระดับโลกของคุณ`,

  // Patch Node
  [ModalKeys.Patch_Note.close]: 'ปิดการแจ้งเตือน 14วัน',

  [ModalKeys.Notification.Dropdown.title]: 'การแจ้งเตือน',
  [ModalKeys.Notification.Dropdown.no_notification]: 'ไม่มีการแจ้งเตือน',
  [ModalKeys.Notification.Dropdown.bottom_btn]:
    'ทำเครื่องหมายทั้งหมดว่าอ่านแล้ว',

  [ModalKeys.My_Order.Dropdown.content]: 'เริ่มคำสั่งซื้อครั้งแรกของคุณ',
  [ModalKeys.My_Order.Dropdown.bottom_btn]: 'ดูรายการคำสั่งซื้อทั้งหมด',
};
