import GuideKeys from '../translationKeys/GuidePageKeys';

export const GuideTranslations = {
  [GuideKeys.click_to_join]: 'คลิกเพื่อเข้าร่วม',

  // Portfolio Banner
  [GuideKeys.Portfolio_Banner.description]: `
    สร้างผลงานของคุณเอง <br>
    ด้วย URL ที่ไม่ซ้ำใคร`,
  [GuideKeys.Portfolio_Banner.view_sample]: 'ดูตัวอย่าง',

  // Creator Invitation
  [GuideKeys.Creator_Invitation.hi_ginseng_mega]: 'สวัสดี ฉันชื่อ MEGA',
  [GuideKeys.Creator_Invitation.nice_to_meet_you]: 'ยินดีที่ได้รู้จัก',
  [GuideKeys.Creator_Invitation.we_help_you_receive]:
    'เราช่วยให้คุณได้รับข้อเสนอ',
  [GuideKeys.Creator_Invitation.proposal_golbal_brands]: 'จากแบรนด์ระดับโลก',
  [GuideKeys.Creator_Invitation.let_companies_directly]: 'ให้บริษัทต่างๆ',
  [GuideKeys.Creator_Invitation.order_your_package]:
    'สั่งซื้อแพ็กเกจของคุณโดยตรง',
  [GuideKeys.Creator_Invitation.click_to_join]: 'คลิกที่นี่',
  [GuideKeys.Creator_Invitation.anytime]: 'เพื่อเข้าร่วมได้ตลอดเวลา',
  [GuideKeys.Creator_Invitation.package_featured]:
    'แพ็กเกจของคุณจะถูกนําเสนอต่อบริษัทต่างๆ',
  [GuideKeys.Creator_Invitation.showcase_channel]:
    'จากกว่า 128+ ประเทศนำเสนอช่องของคุณ',
  [GuideKeys.Creator_Invitation.showcase_channel_2]:
    'และเชื่อมต่อกับลูกค้าทั่วโลก',
  [GuideKeys.Creator_Invitation.order_package]: 'สั่งแพ็กเกจ',

  [GuideKeys.Creator_Invitation.Mobile.we_help_you_receive]:
    'เราช่วยให้คุณได้รับ',
  [GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands]:
    'ข้อเสนอจากแบรนด์ระดับโลก',
  [GuideKeys.Creator_Invitation.Mobile.let_companies_directly]:
    'ให้บริษัทต่างๆ',
  [GuideKeys.Creator_Invitation.Mobile.order_your_package]:
    'สั่งซื้อแพ็กเกจของคุณโดยตรง',
  [GuideKeys.Creator_Invitation.Mobile.click_to_join]: 'คลิกเพื่อเข้าร่วม',
  [GuideKeys.Creator_Invitation.Mobile.anytime]: 'ได้ตลอดเวลา',
};
