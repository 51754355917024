import AxiosClient from 'components/utilities/AxiosClient';

const sendVerificationEmail = async (email = '') => {
  const res = await AxiosClient.post('/initVerifyClientCompanyEmail', {
    email: email,
  });

  return res.data;
};

export default sendVerificationEmail;
