import Stack from 'components/Basics/Layout/Stack';
import { usePackages } from 'contexts/PackagesContext';
import LexicalEditor from 'components/Basics/LexicalEditor';
import MaxLines from 'components/Basics/Typography/MaxLines';
import styled from 'styled-components';
import Variant from '../packagesCardVariant';
import { useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import { Trans } from 'react-i18next';
import DetailsBox from '../DetailsSection';
import Platform from 'constants/platform';

const DescriptionBox = ({ variant, containerHeight }) => {
  const { t } = useTranslation();
  const { loading, currentPackage, isCurrentPackageComplete, platform } =
    usePackages();
  const [height, setHeight] = useState(null);
  const ref = useRef(null);

  const { getPreferredDescription } = useAutoTranslation();

  const description = useMemo(() => {
    return getPreferredDescription(currentPackage?.description);
  }, [currentPackage?.description, getPreferredDescription]);

  const descriptionLength = useMemo(() => {
    try {
      const parsed = JSON.parse(description);
      return parsed?.length;
    } catch (e) {
      return 0;
    }
  }, [description]);

  useEffect(() => {
    if (platform === Platform.YouTube || platform === Platform.Twitter) return;
    const myComponentElement = ref.current;

    if (myComponentElement) {
      const height = myComponentElement.clientHeight;
      setHeight(containerHeight - height - 10);
    }
  }, [containerHeight, platform]);

  // details box have 2x2 layout for instagram and tiktok and when there are sample contents
  const isTwoByTwo = useMemo(() => {
    return (
      (platform === Platform.Instagram || platform === Platform.TikTok) &&
      currentPackage?.sampleContents.length > 0
    );
  }, [platform, currentPackage?.sampleContents]);

  if (loading || (variant !== Variant.Preview && !isCurrentPackageComplete))
    return (
      <Stack
        justify="center"
        style={{
          height: '297px',
        }}
      >
        <NoContent>
          <Trans>{t(ProfileKeys.Package.Description.default)}</Trans>
        </NoContent>
      </Stack>
    );

  if (variant === Variant.Preview && !descriptionLength)
    return (
      <NoContent>
        <Trans
          components={{
            ul: <ul></ul>,
            li: <li></li>,
          }}
        >
          {t(ProfileKeys.Edit.Package.Description.placeholder)}
        </Trans>
      </NoContent>
    );

  if (!isTwoByTwo) {
    return (
      <MaxLinesStyled
        updateOn={containerHeight}
        limit={Math.floor(containerHeight / (16 * 1.6))}
      >
        <LexicalEditor
          name="PreviewDescription"
          key={description}
          value={description}
          readOnly
        >
          <Content />
        </LexicalEditor>
      </MaxLinesStyled>
    );
  }

  return (
    <Stack
      justify="space-between"
      gap="0.625rem !important"
      wrap="nowrap"
      style={{ minHeight: containerHeight + 'px' }}
    >
      <Stack gap="0" wrap="nowrap" className="flex-1">
        <MaxLinesStyled
          updateOn={containerHeight}
          limit={Math.floor(height / (16 * 1.6))}
        >
          <LexicalEditor
            name="PreviewDescription"
            key={description}
            value={description}
            readOnly
          >
            <Content />
          </LexicalEditor>
        </MaxLinesStyled>
      </Stack>
      <span ref={ref}>
        <DetailsBox variant={variant} />
      </span>
    </Stack>
  );
};

export default DescriptionBox;

const NoContent = styled.div`
  position: relative;
  font-family: Kumbh Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.15px;
  margin: 0;
  padding: 0;
  color: var(--color-indigo-100);

  .list {
    margin: 0 0.5rem 0 1.25rem;
  }
`;

const MaxLinesStyled = styled(MaxLines)`
  font-family: Kumbh Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.15px;
  display: flex;
`;

const Content = styled(LexicalEditor.Content)``;
