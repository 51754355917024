import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: '保存中',
  [ProposalKeys.changes_saved]: '変更が保存されました',

  [ProposalKeys.New_Proposal.text]: '新しい提案',
  [ProposalKeys.New_Proposal.toast]: '提案を作成できる最大数に達しました。',

  [ProposalKeys.Clear.text]: 'すべてクリア',
  [ProposalKeys.Clear.toast]: `
  正常にクリアされました。この変更を元に戻すには<button>ここをクリック</button>してください。`,

  [ProposalKeys.Modal.Copy.title]: '提案を上書きしてもよろしいですか？',
  [ProposalKeys.Modal.Copy.description]: `
    現在の提案は選択した提案で上書きされます。<br>
    この操作は取り消すことができません。続行しますか？`,

  [ProposalKeys.Part1.Option_1.title]: 'タイトル',
  [ProposalKeys.Part1.Option_1.placeholder]:
    'プロジェクトのタイトルを入力してください',
  [ProposalKeys.Part1.Option_1.error]: '最大100文字',

  [ProposalKeys.Part1.Option_2.title]: '製品・ブランドのウェブサイト',
  [ProposalKeys.Part1.Option_2.placeholder]: 'URLを入力してください',
  [ProposalKeys.Part1.Option_2.error]: '最大400文字',

  [ProposalKeys.Part1.Option_3.title]: '説明とファイル',
  [ProposalKeys.Part1.Option_3.placeholder]: `
    プロジェクトの詳細を説明し、ここにファイルを添付してください。クリエイターは、以下に関する情報を探しているかもしれません。<br><br><br>
    <ul>
    <li>プロジェクト/製品の説明</li>
    <li>キャンペーンの目標とターゲットオーディエンス</li>
    <li>詳細なコンテンツガイドライン</li>
    </ul>`,
  [ProposalKeys.Part1.Option_3.Snippet.title]: '追加するスニペットを選択',
  [ProposalKeys.Part1.Option_3.Snippet.option_1]:
    'クリエイターのソーシャルチャンネル名',
  [ProposalKeys.Part1.Option_3.Snippet.option_2]: 'クリエイターのユーザー名',
  [ProposalKeys.Part1.Option_3.Snippet.option_3]: 'クリエイターのメイン国',
  [ProposalKeys.Part1.Option_3.Snippet.option_4]:
    'クリエイターのトップカテゴリ',

  [ProposalKeys.Part1.Option_3.Error.file_format]: `
  無効なファイル形式です（{{fileFormats}}を使用してください）`,
  [ProposalKeys.Part1.Option_3.Error.file_size_limit]: `
  合計ファイルサイズ制限は最大{{maxSize}}です`,
  [ProposalKeys.Part1.Option_3.Error.max_file]: `
  最大{{maxFiles}}ファイルを送信できます`,

  [ProposalKeys.caution]: `
    パッケージを注文する際、すべての適用可能な<strong>要件とコンテンツミッション</strong>が、各コンテンツに<strong>自動的に適用</strong>されます。注文されたコンテンツに適用されないもの、または不完全なものは適用されません。`,

  [ProposalKeys.Side_btn.text]: '適用可能なコンテンツ',
  [ProposalKeys.Side_btn.title]: '適用可能なコンテンツ：',

  [ProposalKeys.Part2.title]: 'クリエイターへの商品の送付',

  [ProposalKeys.Part2.Option_1.title]: 'スポンサー商品を送信する',
  [ProposalKeys.Part2.Option_1.tooltip]: `
    このオプションは不完全であり、<br>
    送信される提案には適用されません。`,

  [ProposalKeys.Part2.Option_1.Image.title]: '配送画像（オプション）',
  [ProposalKeys.Part2.Option_1.Image.description]:
    'JPG、JPEG、PNG、GIF形式の最大5つの画像ファイルを追加します。最大100 MB。',

  [ProposalKeys.Part2.Option_1.Info.title]: '配送情報（必須）',
  [ProposalKeys.Part2.Option_1.Info.placeholder]:
    '製品と配送に関する情報を提供してください。',

  [ProposalKeys.Part2.Option_1.caution_1]:
    '製品の配送のためにクリエイターの送付先住所を受け取ります。製品が到着した時点でコンテンツの作成が開始され、締切り日が更新されます。',
  [ProposalKeys.Part2.Option_1.caution_2]:
    '配送された商品の返品は処理していません。製品が発送された後に注文がキャンセルされた場合、返品については直接クリエイターと話し合う必要があります。',
  [ProposalKeys.Part2.Option_1.caution_3]: `
    国際配送の追跡をサポートしていますが、誤配などの配送に関する問題を保証またはカバーしていません。
    <Tooltip>国内配送</Tooltip>は一部の国でのみ追跡可能ですが、注文には含めることができます。`,
  [ProposalKeys.Part2.Option_1.caution_3_tooltip]: `
    主要なキャリアによる国内追跡を、米国やカナダなど一部の国でサポートしています。その他の国では現在、国内追跡をサポートしていません。`,

  [ProposalKeys.Part3.title]: '要件',
  [ProposalKeys.Part3.applied]: '{{number}} 適用済み',

  [ProposalKeys.Part3.Option_1.title]: '有料プロモーションを示す',
  [ProposalKeys.Part3.Option_1.description_1]:
    '次のいずれかのハッシュタグ：#Ad、#Advertisement、または#Sponsoredは、有料プロモーションを明示するためにクリエイターによってハッシュタグのリストの先頭に配置されます。',
  [ProposalKeys.Part3.Option_1.description_2]:
    'YouTubeが提供する「有料プロモーションを含む」というラベルをビデオ内に追加します。',
  [ProposalKeys.Part3.Option_1.description_3]:
    'Instagramが提供する「有料パートナーシップ」というラベルをInstagramの投稿/ストーリー/Reels内に追加します。',

  [ProposalKeys.Part3.Option_2.placeholder]:
    'ここに利用規約を入力してください。',
  [ProposalKeys.Part3.Option_2.description]:
    'クリエイターは、提供されたサービスのためにあなたの利用規約に従う必要があります。このオプションがクリエイターのサービスと矛盾する場合、改訂または無効化を要求することができます。',

  [ProposalKeys.Part4.title]: 'コンテンツミッション',

  [ProposalKeys.Part4.Option_1.title]: 'リンクを含める',
  [ProposalKeys.Part4.Option_1.placeholder]: 'URLを入力してください',
  [ProposalKeys.Part4.Option_2.btn]: 'このハッシュタグを追加',
  [ProposalKeys.Part4.Option_1.description]:
    '提供されたリンクは、投稿またはビデオの説明に含まれます。これを使用してウェブサイトやアプリへのトラフィックを増やすことができます。',

  [ProposalKeys.Part4.Option_2.title]: 'ハッシュタグを含める',
  [ProposalKeys.Part4.Option_2.placeholder]: 'ハッシュタグを入力してください',
  [ProposalKeys.Part4.Option_2.description]:
    '左側の少なくとも1つまたはそれ以上のハッシュタグが、投稿またはビデオの説明に含まれます。',

  [ProposalKeys.Part4.Option_3.title]: '場所をタグ付け',
  [ProposalKeys.Part4.Option_3.placeholder]: '場所を入力してください',
  [ProposalKeys.Part4.Option_3.description]:
    '提供された場所は、投稿またはビデオにタグ付けされます。特定の場所や会場の宣伝に効果的です。',

  [ProposalKeys.Part4.Option_4.title]: '当社のアカウントをタグ付け',
  [ProposalKeys.Part4.Option_4.placeholder]: 'あなたのハンドル',
  [ProposalKeys.Part4.Option_4.description]:
    'アカウント@ハンドルは、投稿またはビデオの説明に含まれます（注文されたコンテンツのプラットフォームに対応するハンドルのみが適用されます）',

  [ProposalKeys.Part4.Option_5.title]: '当社のアカウントを含むコメントを固定',
  [ProposalKeys.Part4.Option_5.description]:
    '提供されたアカウント@ハンドルを含むコメントが投稿され、コンテンツに固定されます（注文されたコンテンツのプラットフォームに対応するハンドルのみが適用されます）',

  [ProposalKeys.Part4.Option_6.title]: 'リンクを含むコメントを固定',
  [ProposalKeys.Part4.Option_6.placeholder]: 'URLを入力してください',
  [ProposalKeys.Part4.Option_6.description]:
    '提供されたリンクを含むコメントが投稿され、コンテンツに固定されます。',

  [ProposalKeys.Part4.Option_7.title]: '特定の画像を含める',
  [ProposalKeys.Part4.Option_7.description]:
    'クリエイターに、提供された画像の少なくとも1つまたはそれ以上をコンテンツに含めるように指示されます。',

  [ProposalKeys.Part4.Option_8.title]: 'ビデオにタグを追加',
  [ProposalKeys.Part4.Option_8.placeholder]: 'ビデオのタグを入力してください',
  [ProposalKeys.Part4.Option_8.btn]: 'このタグを追加',
  [ProposalKeys.Part4.Option_8.description]:
    '左側から少なくとも1つまたはそれ以上のタグがYouTubeビデオに追加され、検索最適化に役立ちます。',
};
