import FilterKeys from '../translationKeys/FilterKeys';

export const FilterTranslations = {
  // Influencer Marketing Home Page
  [FilterKeys.home.search_keywords]: 'Search keywords',

  [FilterKeys.home.expand]: 'Expand',
  [FilterKeys.home.collapse]: 'Collapse',

  [FilterKeys.home.more_filter]: '＋ More filters',
  [FilterKeys.home.less_filter]: '– Less filters',

  [FilterKeys.home.try_searching]: 'Try searching',
  [FilterKeys.home.results_for_keyword]:
    '{{number}} results for "{{searchText}}"',
  [FilterKeys.home.result_for_keyword]:
    '{{number}} result for "{{searchText}}"',

  // Try Searching
  [FilterKeys.home.Try_Searching.brand_ambassador]: 'Brand ambassador',
  [FilterKeys.home.Try_Searching.live_shows]: 'Live shows',
  [FilterKeys.home.Try_Searching.product_review]: 'Product review',
  [FilterKeys.home.Try_Searching.game_review]: 'Game review',

  // Sort By
  [FilterKeys.home.sort_by.suggested]: 'Suggested',
  [FilterKeys.home.sort_by.by_subscribers]: 'Subscribers',
  [FilterKeys.home.sort_by.lowest_price]: 'Lowest Price',
  [FilterKeys.home.sort_by.highest_price]: 'Highest Price',

  // Country
  [FilterKeys.home.country.popular]: 'Popular Countries',
  [FilterKeys.home.country.selected]: 'Selected Countries',
  [FilterKeys.home.country.no_result]: 'No results found',

  // Price
  [FilterKeys.home.price._50]: '~$50',
  [FilterKeys.home.price._50_100]: '$50~100',
  [FilterKeys.home.price._100_300]: '$100~300',
  [FilterKeys.home.price._300_500]: '$300~500',
  [FilterKeys.home.price._500_1k]: '$500~1K',
  [FilterKeys.home.price._1k_2k]: '$1K~2K',
  [FilterKeys.home.price._2k_5k]: '$2K~5K',
  [FilterKeys.home.price._5k_]: '$5K+',

  // Subscribers
  [FilterKeys.home.subs_and_views.tooltip_subs]:
    'The number of subscribers or followers of the user',
  [FilterKeys.home.subs_and_views.tooltip_views]: `
    The average number of views or impressions <br>
    that you can expect per post in 180 days`,

  [FilterKeys.home.subs_and_views.private]: 'Private',
  [FilterKeys.home.subs_and_views._10k]: '~10K',
  [FilterKeys.home.subs_and_views._10k_100k]: '10K~100K',
  [FilterKeys.home.subs_and_views._100k_1m]: '100K~1M',
  [FilterKeys.home.subs_and_views._1m_]: '1M+',

  // Gender
  [FilterKeys.home.gender.male]: 'Male',
  [FilterKeys.home.gender.female]: 'Female',

  // Mobile
  [FilterKeys.home.M.filter_by]: 'Filter By:',
  [FilterKeys.home.M.all_categories]: 'All Categories',
  [FilterKeys.home.M.all_price_ranges]: 'All Price Ranges',
};
