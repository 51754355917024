import styled from 'styled-components';
import device from 'styles/device';

const Logo = styled.img.attrs(({ white }) => ({
  src: white ? '/img/logo-white.png' : '/img/logo-black.png',
  alt: 'MegaEvolution',
}))`
  width: 174.5px;
  overflow: visible;

  @media ${device.md} {
    width: 263px;
  }
`;

export default Logo;
