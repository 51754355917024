import styled from 'styled-components';

/**
 * A simple Stack component that displays a stack of elements.
 * @param {string} direction - The direction of the stack. (default: 'column')
 * @param {string} gap - The spacing between elements. (default: '0.625rem')
 * @param {string} justify - The justify content of the stack. (default: 'flex-start')
 * @param {string} align - The align items of the stack. (default: 'stretch')
 * @param {string} wrap - The wrap of the stack. (default: 'wrap')
 * @param {string} margin - The margin of the stack. (default: '0')
 * @param {string} padding - The padding of the stack. (default: '0')
 * @param {string} className - The class name of the stack.
 */
const Stack = styled.div`
  display: flex;
  gap: ${({ gap }) => gap || '0.625rem'};
  flex-wrap: ${({ wrap }) => wrap || 'wrap'};
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  justify-content: ${(props) => (props.justify ? props.justify : 'flex-start')};
  align-items: ${(props) => (props.align ? props.align : 'stretch')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  padding: ${(props) => (props.padding ? props.padding : '0')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  position: ${(props) => (props.position ? props.position : 'static')};
  top: ${(props) => (props.top ? props.top : 'auto')};
  left: ${(props) => (props.left ? props.left : 'auto')};
  border-radius: ${(props) => (props.radius ? props.radius : '0')};
`;

export default Stack;
