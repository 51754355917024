const ProfileKeys = {
  Menu: {
    edit: 'ProfileKeys.Menu.edit',
    save: 'ProfileKeys.Menu.save',
    message_me: 'ProfileKeys.Menu.message_me',
    Error: {
      creator_to_creator: 'ProfileKeys.Menu.Error.creator_to_creator',
      something_went_wrong: 'ProfileKeys.Menu.Error.something_went_wrong',
      self_chat: 'ProfileKeys.Menu.Error.self_chat',
    },
  },

  Package: {
    edit: 'ProfileKeys.Package.edit',
    no_content: 'ProfileKeys.Package.no_content',
    available: 'ProfileKeys.Package.available',
    click_to_view: 'ProfileKeys.Package.click_to_view',
    click_to_view_recent: 'ProfileKeys.Package.click_to_view_recent',
    view_recent_videos: 'ProfileKeys.Package.view_recent_videos',

    TopBar: {
      tooltip: 'ProfileKeys.Package.TopBar.tooltip',
    },

    YouTube: {
      Contents: {
        video_prepare: 'ProfileKeys.Package.YouTube.Contents.video_prepare',
        video_display: 'ProfileKeys.Package.YouTube.Contents.video_display',
      },
    },

    Description: {
      default: 'ProfileKeys.Package.Description.default',
    },

    Details: {
      Price: {
        contact_me: 'ProfileKeys.Package.Details.Price.contact_me',
        contact_creator: 'ProfileKeys.Package.Details.Price.contact_creator',
        service_fee: 'ProfileKeys.Package.Details.Price.service_fee',
        tooltip: 'ProfileKeys.Package.Details.Price.tooltip',
      },

      Toast: {
        update_successful:
          'ProfileKeys.Package.Details.Toast.update_successful',
        could_not_update: 'ProfileKeys.Package.Details.Toast.could_not_update',
      },
    },
  },

  Creator_Summary: {
    title: 'ProfileKeys.Creator_Summary.title',
    sub_title: 'ProfileKeys.Creator_Summary.sub_title',
    package_for_other_platforms:
      'ProfileKeys.Creator_Summary.package_for_other_platforms',

    BasicInfo: {
      hollow: 'ProfileKeys.Creator_Summary.BasicInfo.hollow',

      Tooltip: {
        subscribers:
          'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers',

        followers: 'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers',

        total_videos:
          'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos',
        total_tweets:
          'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets',
        total_posts:
          'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts',

        Join_Date: {
          youtube:
            'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube',
          twitter:
            'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter',
          instagram:
            'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram',
          tiktok:
            'ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok',
        },
      },
    },
  },

  Channel_Contents: {
    title: 'ProfileKeys.Channel_Contents.title',
    sub_title: 'ProfileKeys.Channel_Contents.sub_title',
    views_to_subs: 'ProfileKeys.Channel_Contents.views_to_subs',
    views_to_followers: 'ProfileKeys.Channel_Contents.views_to_followers',
    reach_to_followers: 'ProfileKeys.Channel_Contents.reach_to_followers',
    est_engagement: 'ProfileKeys.Channel_Contents.est_engagement',
    channel_data: 'ProfileKeys.Channel_Contents.channel_data',
    loading_post: 'ProfileKeys.Channel_Contents.loading_post',

    Twitter: {
      feed: 'ProfileKeys.Channel_Contents.Twitter.feed',
      loading: 'ProfileKeys.Channel_Contents.Twitter.loading',
      most_popular: 'ProfileKeys.Channel_Contents.Twitter.most_popular',
    },

    Tiktok: {
      loading: 'ProfileKeys.Channel_Contents.Tiktok.loading',
    },

    Filter: {
      _1_month: 'ProfileKeys.Channel_Contents.Filter._1_month',
      _3_months: 'ProfileKeys.Channel_Contents.Filter._3_months',
      _1_year: 'ProfileKeys.Channel_Contents.Filter._1_year',
      newest: 'ProfileKeys.Channel_Contents.Filter.newest',
      most_viewed: 'ProfileKeys.Channel_Contents.most_viewed',
      most_liked: 'ProfileKeys.Channel_Contents.most_liked',
      most_commented: 'ProfileKeys.Channel_Contents.most_commented',
    },

    Tooltip: {
      youtube: 'ProfileKeys.Channel_Contents.Tooltip.youtube',
      twitter: 'ProfileKeys.Channel_Contents.Tooltip.twitter',
      instagram: 'ProfileKeys.Channel_Contents.Tooltip.instagram',
      tiktok: 'ProfileKeys.Channel_Contents.Tooltip.tiktok',

      likes: 'ProfileKeys.Channel_Contents.Tooltip.likes',
      comments: 'ProfileKeys.Channel_Contents.Tooltip.comments',
      x_reposts: 'ProfileKeys.Channel_Contents.Tooltip.x_reposts',
      shares: 'ProfileKeys.Channel_Contents.Tooltip.shares',

      views_subs_rating:
        'ProfileKeys.Channel_Contents.Tooltip.views_subs_rating',
      views_follower_rating:
        'ProfileKeys.Channel_Contents.Tooltip.views_follower_rating',

      engagements: 'ProfileKeys.Channel_Contents.Tooltip.engagements',
    },
  },

  Channel_Analytics: {
    title: 'ProfileKeys.Channel_Analytics.title',
    sub_title: 'ProfileKeys.Channel_Analytics.sub_title',
    updated_on: 'ProfileKeys.Channel_Analytics.updated_on',
    avg_views: 'ProfileKeys.Channel_Analytics.avg_views',
    avg_impressions: 'ProfileKeys.Channel_Analytics.avg_impressions',
    avg_reach: 'ProfileKeys.Channel_Analytics.avg_reach',
    avg_likes: 'ProfileKeys.Channel_Analytics.avg_likes',
    avg_x_reposts: 'ProfileKeys.Channel_Analytics.avg_x_reposts',
    engagements: 'ProfileKeys.Channel_Analytics.engagements',

    Views_Analysis: {
      title: 'ProfileKeys.Channel_Analytics.Views_Analysis.title',
      title_instagram:
        'ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram',
      periodic: 'ProfileKeys.Channel_Analytics.Views_Analysis.periodic',
      total: 'ProfileKeys.Channel_Analytics.Views_Analysis.total',
    },

    Subscribers_Data: {
      title: 'ProfileKeys.Channel_Analytics.Subscribers_Data.title',
    },

    Followers_Data: {
      title: 'ProfileKeys.Channel_Analytics.Followers_Data.title',
    },
  },

  Audience_Analytics: {
    title: 'ProfileKeys.Audience_Analytics.title',
    sub_title: 'ProfileKeys.Audience_Analytics.sub_title',
    preparing_data: 'ProfileKeys.Audience_Analytics.preparing_data',

    Audience_Interest: {
      title: 'ProfileKeys.Audience_Analytics.Audience_Interest.title',
      popular_tags:
        'ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags',
    },

    Keyword_Cloud: {
      title: 'ProfileKeys.Audience_Analytics.Keyword_Cloud.title',
    },

    Top_10: {
      title: 'ProfileKeys.Audience_Analytics.Top_10.title',
    },

    LockedOverlay: {
      Upgrade_Plan: {
        title:
          'ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title',
        description:
          'ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description',
      },

      Login: {
        title: 'ProfileKeys.Audience_Analytics.LockedOverlay.Login.title',
        description:
          'ProfileKeys.Audience_Analytics.LockedOverlay.Login.description',
      },
    },
  },

  Review: {
    review_as_client: 'ProfileKeys.Review.review_as_client',
    most_recent: 'ProfileKeys.Review.most_recent',
    most_helpful: 'ProfileKeys.Review.most_helpful',
    no_reviews: 'ProfileKeys.Review.no_reviews',
    recent_clients: 'ProfileKeys.Review.recent_clients',

    star: 'ProfileKeys.Review.star',
    stars: 'ProfileKeys.Review.stars',
  },

  Edit: {
    Toast: {
      being_saved: 'ProfileKeys.Edit.Toast.being_saved',
      saved: 'ProfileKeys.Edit.Toast.saved',
      failed: 'ProfileKeys.Edit.Toast.failed',
    },

    Package: {
      complete_percentage: 'ProfileKeys.Edit.Package.complete_percentage',

      Topbar: {
        tooltip: 'ProfileKeys.Edit.Package.Topbar.tooltip',

        Representative: {
          tooltip: 'ProfileKeys.Edit.Package.Topbar.Representative.tooltip',
        },

        Completion: {
          tooltip: 'ProfileKeys.Edit.Package.Topbar.Completion.tooltip',
        },
      },

      Banner: {
        heading: 'ProfileKeys.Edit.Package.Banner.heading',
        mobile_heading: 'ProfileKeys.Edit.Package.Banner.mobile_heading',
        tooltip: 'ProfileKeys.Edit.Package.Banner.tooltip',
      },

      Options: {
        intro_and_closing: 'ProfileKeys.Edit.Package.Options.intro_and_closing',
        product_review: 'ProfileKeys.Edit.Package.Options.product_review',
        visit_review: 'ProfileKeys.Edit.Package.Options.visit_review',
        digital_product_review:
          'ProfileKeys.Edit.Package.Options.digital_product_review',
        brand_ambassador: 'ProfileKeys.Edit.Package.Options.brand_ambassador',
        short_video: 'ProfileKeys.Edit.Package.Options.short_video',
        product_placement: 'ProfileKeys.Edit.Package.Options.product_placement',
        interview: 'ProfileKeys.Edit.Package.Options.interview',
        commercial_break: 'ProfileKeys.Edit.Package.Options.commercial_break',
        live_streaming: 'ProfileKeys.Edit.Package.Options.live_streaming',
        giveaways: 'ProfileKeys.Edit.Package.Options.giveaways',
        meme_and_artwork: 'ProfileKeys.Edit.Package.Options.meme_and_artwork',
        interview_and_ama: 'ProfileKeys.Edit.Package.Options.interview_and_ama',
      },

      Title: {
        use_a_template: 'ProfileKeys.Edit.Package.Title.use_a_template',
        placeholder: 'ProfileKeys.Edit.Package.Title.placeholder',
        placeholder2: 'ProfileKeys.Edit.Package.Title.placeholder2',
      },

      Featured_Image: {
        YouTube: {
          title: 'ProfileKeys.Edit.Package.Featured_Image.YouTube.title',
          tooltip: 'ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip',
        },

        title: 'ProfileKeys.Edit.Package.Featured_Image.title',
        placeholder: 'ProfileKeys.Edit.Package.Featured_Image.placeholder',
        tooltip: 'ProfileKeys.Edit.Package.Featured_Image.tooltip',

        Error: {
          file_upload:
            'ProfileKeys.Edit.Package.Featured_Image.Error.file_upload',
          file_large:
            'ProfileKeys.Edit.Package.Featured_Image.Error.file_large',
          file_invalid:
            'ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid',
        },
      },

      Sample_Contents: {
        title: 'ProfileKeys.Edit.Package.Sample_Contents.title',
        placeholder_youtube:
          'ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube',
        placeholder_twitter:
          'ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter',
        placeholder_instagram:
          'ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram',
        placeholder_tiktok:
          'ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok',

        add_more_contents:
          'ProfileKeys.Edit.Package.Sample_Contents.add_more_contents',

        Error: {
          invalid_link:
            'ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link',
        },
      },

      Description: {
        placeholder: 'ProfileKeys.Edit.Package.Description.placeholder',
        placeholder_gpt: 'ProfileKeys.Edit.Package.Description.placeholder_gpt',

        ChatGPT: {
          title: 'ProfileKeys.Edit.Package.Description.ChatGPT.title',
          tooltip: 'ProfileKeys.Edit.Package.Description.ChatGPT.tooltip',

          Additional_Info: {
            title:
              'ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title',
            placeholder:
              'ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder',
          },
        },
      },

      Price: {
        placeholder: 'ProfileKeys.Edit.Package.Price.placeholder',
        tooltip: 'ProfileKeys.Edit.Package.Price.tooltip',

        Type: {
          open: 'ProfileKeys.Edit.Package.Price.Type.open',
          unlockable: 'ProfileKeys.Edit.Package.Price.Type.unlockable',
          contact_me: 'ProfileKeys.Edit.Package.Price.Type.contact_me',
        },

        Modal: {
          MessageQuota: {
            Basic: {
              title:
                'ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title',
              description:
                'ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description',
            },

            Verified: {
              title:
                'ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title',
              description:
                'ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description',
            },
          },

          UnlockPriceQuota: {
            Basic: {
              title:
                'ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title',
              description:
                'ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description',
            },

            Verified: {
              title:
                'ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title',
              description:
                'ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.description',
            },
          },

          btn: 'ProfileKeys.Edit.Package.Price.Modal.btn',
        },

        toast: 'ProfileKeys.Edit.Package.Price.toast',

        Error: {
          minimum: 'ProfileKeys.Edit.Package.Price.Error.minimum',
          maximum: 'ProfileKeys.Edit.Package.Price.Error.maximum',
        },
      },

      Contents: {
        YouTube: {
          video: 'ProfileKeys.Edit.Package.Contents.YouTube.video',
          shorts: 'ProfileKeys.Edit.Package.Contents.YouTube.shorts',
          live: 'ProfileKeys.Edit.Package.Contents.YouTube.live',
        },

        Twitter: {
          post: 'ProfileKeys.Edit.Package.Contents.Twitter.post',
        },

        Instagram: {
          instagram_post:
            'ProfileKeys.Edit.Package.Contents.Instagram.instagram_post',
          instagram_story:
            'ProfileKeys.Edit.Package.Contents.Instagram.instagram_story',
          instagram_reels:
            'ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels',
          instagram_live:
            'ProfileKeys.Edit.Package.Contents.Instagram.instagram_live',
        },

        Tiktok: {
          video: 'ProfileKeys.Edit.Package.Contents.Tiktok.video',
          live: 'ProfileKeys.Edit.Package.Contents.Tiktok.live',
        },
      },

      Delivery: {
        placeholder: 'ProfileKeys.Edit.Package.Delivery.placeholder',
        input: 'ProfileKeys.Edit.Package.Delivery.input',
        tooltip: 'ProfileKeys.Edit.Package.Delivery.tooltip',
      },

      Revisions: {
        no_revisions: 'ProfileKeys.Edit.Package.Revisions.no_revisions',
        tooltip: 'ProfileKeys.Edit.Package.Revisions.tooltip',
      },

      Type_Length: {
        placeholder: 'ProfileKeys.Edit.Package.Type_Length.placeholder',
      },
    },

    User_Info: {
      Username: {
        placeholder: 'ProfileKeys.Edit.User_Info.Username.placeholder',
        error: 'ProfileKeys.Edit.User_Info.Username.error',

        Error: {
          enter_name: 'ProfileKeys.Edit.User_Info.Username.Error.enter_name',
          max_50: 'ProfileKeys.Edit.User_Info.Username.Error.max_50',
          max_30: 'ProfileKeys.Edit.User_Info.Username.Error.max_30',
          invalid_symbol:
            'ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol',
          max_2000: 'ProfileKeys.Edit.User_Info.Username.Error.max_2000',
          max_100: 'ProfileKeys.Edit.User_Info.Username.Error.max_100',
          max_500: 'ProfileKeys.Edit.User_Info.Username.Error.max_500',
          max_200: 'ProfileKeys.Edit.User_Info.Username.Error.max_200',
          invalid_price:
            'ProfileKeys.Edit.User_Info.Username.Error.invalid_price',
          invalid_delivery_time:
            'ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time',
          delivery_time_1_60:
            'ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60',
        },
      },

      My_URL: {
        placeholder: 'ProfileKeys.Edit.User_Info.My_URL.placeholder',

        Error: {
          char_limit: 'ProfileKeys.Edit.User_Info.My_URL.Error.char_limit',
          invalid_symbol:
            'ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol',
          required_handle:
            'ProfileKeys.Edit.User_Info.My_URL.Error.required_handle',
          already_taken:
            'ProfileKeys.Edit.User_Info.My_URL.Error.already_taken',
        },
      },

      Social_Network: {
        Toast: {
          priority: 'ProfileKeys.Edit.User_Info.Social_Network.Toast.priority',
          unlink: 'ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink',
        },

        Popup: {
          title: 'ProfileKeys.Edit.User_Info.Social_Network.Popup.title',
          description:
            'ProfileKeys.Edit.User_Info.Social_Network.Popup.description',
        },

        Unlink: {
          title: 'ProfileKeys.Edit.User_Info.Social_Network.Unlink.title',

          description:
            'ProfileKeys.Edit.User_Info.Social_Network.Unlink.description',
        },
      },

      Bio: {
        placeholder_creator:
          'ProfileKeys.Edit.User_Info.Bio.placeholder_creator',
        placeholder_client: 'ProfileKeys.Edit.User_Info.Bio.placeholder_client',
      },

      Categories: {
        add_a_category: 'ProfileKeys.Edit.User_Info.Categories.add_a_category',
      },

      Target_Countries: {
        title: 'ProfileKeys.Edit.User_Info.Target_Countries.title',
        add_a_country:
          'ProfileKeys.Edit.User_Info.Target_Countries.add_a_country',
        tooltip: 'ProfileKeys.Edit.User_Info.Target_Countries.tooltip',
      },

      Languages: {
        add_a_language: 'ProfileKeys.Edit.User_Info.Languages.add_a_language',
      },
      Website: {
        placeholder: 'ProfileKeys.Edit.User_Info.Website.placeholder',
      },

      Company: {
        placeholder: 'ProfileKeys.Edit.User_Info.Company.placeholder',
        tooltip: 'ProfileKeys.Edit.User_Info.Company.tooltip',

        Error: {
          max_30: 'ProfileKeys.Edit.User_Info.Company.Error.max_30',
        },
      },
    },
  },

  Modal: {
    Request_Price: {
      title: 'ProfileKeys.Modal.Request_Price.title',
      btn: 'ProfileKeys.Modal.Request_Price.btn',
      toast: 'ProfileKeys.Modal.Request_Price.toast',

      Message: {
        title: 'ProfileKeys.Modal.Request_Price.Message.title',
        placeholder: 'ProfileKeys.Modal.Request_Price.Message.placeholder',
      },
    },

    Edit: {
      Banner: {
        heading: 'ProfileKeys.Modal.Edit.Banner.heading',
        body: 'ProfileKeys.Modal.Edit.Banner.body',
        loading: 'ProfileKeys.Modal.Edit.Banner.loading',
        use_my: 'ProfileKeys.Modal.Edit.Banner.use_my',
        not_found: 'ProfileKeys.Modal.Edit.Banner.not_found',
      },

      Incomplete_Package: {
        title: 'ProfileKeys.Modal.Edit.Incomplete_Package.title',
        description: 'ProfileKeys.Modal.Edit.Incomplete_Package.description',
      },

      new_reward: 'ProfileKeys.Modal.Edit.new_reward',
      skip_for_now: 'ProfileKeys.Modal.Edit.skip_for_now',
    },

    Reconnection: {
      title: 'ProfileKeys.Modal.Reconnection.title',
      description: 'ProfileKeys.Modal.Reconnection.description',

      Reconnect_Btn_Modal: {
        title: 'ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title',
        description:
          'ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description',
      },
    },
  },

  Guide: {
    hi_ginseng_mega: 'ProfileKeys.Guide.hi_ginseng_mega',
    nice_to_meet_you: 'ProfileKeys.Guide.nice_to_meet_you',
    create_unique_url: 'ProfileKeys.Guide.create_unique_url',
    we_help_you_receive: 'ProfileKeys.Guide.we_help_you_receive',
    we_help_you_receive_mobile: 'ProfileKeys.Guide.we_help_you_receive_mobile',
    proposal_golbal_brands: 'ProfileKeys.Guide.proposal_golbal_brands',
    proposal_golbal_brands_mobile:
      'ProfileKeys.Guide.proposal_golbal_brands_mobile',
    create_unique_portfolio: 'ProfileKeys.Guide.create_unique_portfolio',
    just_few_clicks: 'ProfileKeys.Guide.just_few_clicks',
    click_to_join: 'ProfileKeys.Guide.click_to_join',
    click_to_join_mobile: 'ProfileKeys.Guide.click_to_join_mobile',
    anytime: 'ProfileKeys.Guide.anytime',
    anytime_mobile: 'ProfileKeys.Guide.anytime_mobile',
    what_a_pleasure: 'ProfileKeys.what_a_pleasure',
    hope_to_see: 'ProfileKeys.hope_to_see',
    create_unique_handle: 'ProfileKeys.create_unique_handle',

    Profile: {
      welcome: 'ProfileKeys.Guide.Profile.welcome',
      portfolio_space: 'ProfileKeys.Guide.Profile.portfolio_space',
      specially_created: 'ProfileKeys.Guide.Profile.specially_created',
      click_here_to_start_editing:
        'ProfileKeys.Guide.Profile.click_here_to_start_editing',
      package_will_show_up: 'ProfileKeys.Guide.Profile.package_will_show_up',
      click_here: 'ProfileKeys.Guide.Profile.click_here',
      to_start_editing: 'ProfileKeys.Guide.Profile.to_start_editing',
      package_will_be_displated:
        'ProfileKeys.Guide.Profile.package_will_be_displated',
    },

    Profile_Edit: {
      welcome: 'ProfileKeys.Guide.Profile_Edit.welcome',
      try_me: 'ProfileKeys.Guide.Profile_Edit.try_me',
      compelling_package: 'ProfileKeys.Guide.Profile_Edit.compelling_package',
      upload_image: 'ProfileKeys.Guide.Profile_Edit.upload_image',
      showcase_service: 'ProfileKeys.Guide.Profile_Edit.showcase_service',
      add_post_video: 'ProfileKeys.Guide.Profile_Edit.add_post_video',
      for_your_clients: 'ProfileKeys.Guide.Profile_Edit.for_your_clients',
      set_up_price: 'ProfileKeys.Guide.Profile_Edit.set_up_price',
      choose_best_option: 'ProfileKeys.Guide.Profile_Edit.choose_best_option',
      complete_package: 'ProfileKeys.Guide.Profile_Edit.complete_package',
      make_it_public: 'ProfileKeys.Guide.Profile_Edit.make_it_public',
      save_description1: 'ProfileKeys.Guide.Profile_Edit.save_description1',
      save_description2: 'ProfileKeys.Guide.Profile_Edit.save_description2',
      save_description3: 'ProfileKeys.Guide.Profile_Edit.save_description3',
      create_unique_package:
        'ProfileKeys.Guide.Profile_Edit.create_unique_package',
      quick_setup: 'ProfileKeys.Guide.Profile_Edit.quick_setup',

      Toast: {
        register_success:
          'ProfileKeys.Guide.Profile_Edit.Toast.register_success',
      },
    },
  },
};

export default ProfileKeys;
