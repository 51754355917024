import NotFoundKeys from '../translationKeys/NotFoundPageKeys';

export const NotFoundTranslations = {
  [NotFoundKeys.title]: '404 : ページが見つかりません',
  [NotFoundKeys.description]:
    '申し訳ありません。お探しのページが見つかりませんでした。',

  // Server Updating Page
  [NotFoundKeys.Server_Updating.title]: 'サーバーが現在更新中です',
  [NotFoundKeys.Server_Updating.description]:
    'また後でもう一度お越しください！',

  // Under Development Page
  [NotFoundKeys.Under_Development.title]: '現在開発中です',
  [NotFoundKeys.Under_Development.description]:
    'Web 3.0マーケティングへようこそ！',
};
