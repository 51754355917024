export const ShadeToggleIcon = ({ size = 1 }) => (
  <svg
    width={20 * size}
    height={20 * size}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.8359 5.31998C18.0255 3.80444 16.8425 2.52067 15.3985 1.58998C14.0917 0.74866 12.6069 0.223924 11.062 0.0574622C9.51708 -0.108999 7.95466 0.087402 6.49888 0.631061C5.0431 1.17472 3.73407 2.05066 2.67579 3.18927C1.61751 4.32789 0.839156 5.69781 0.402558 7.19019C-0.0340409 8.68258 -0.116848 10.2563 0.160716 11.7863C0.43828 13.3163 1.06857 14.7605 2.0015 16.004C2.93443 17.2476 4.14429 18.2563 5.53497 18.9499C6.92564 19.6436 8.4588 20.0031 10.0126 20H10.3024C12.0002 19.9498 13.6571 19.4674 15.1168 18.5983C16.5765 17.7292 17.7907 16.5021 18.6448 15.0328C19.4989 13.5635 19.9646 11.9006 19.9981 10.2012C20.0315 8.5017 19.6315 6.82175 18.8359 5.31998ZM9.0134 17.93C7.09302 17.6741 5.33085 16.7289 4.05456 15.2703C2.77827 13.8116 2.07471 11.9388 2.07471 9.99998C2.07471 8.06116 2.77827 6.18831 4.05456 4.72968C5.33085 3.27105 7.09302 2.3259 9.0134 2.06998V17.93ZM11.0119 2.06998C11.8757 2.18057 12.716 2.43047 13.5 2.80998L11.0119 7.11998V2.06998ZM11.0119 11.07L15.1787 3.89998C15.793 4.42793 16.3252 5.04473 16.7575 5.72998L11.0119 15.69V11.07ZM12.161 17.65L17.7467 7.99998C17.9156 8.65333 18.0029 9.32513 18.0065 9.99998C18.0053 11.746 17.4333 13.4436 16.3779 14.8339C15.3224 16.2242 13.8415 17.2307 12.161 17.7V17.65Z" />
  </svg>
);
