import styled from 'styled-components';
import { forwardRef } from 'react';

const Card = styled.div`
  background: ${({ color }) => (color === 'white' ? 'transparent' : '#fff')};
  border-radius: ${({ radius }) => radius || '20px'};
  color: var(--color-indigo-500);
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  line-height: var(--line-height-1);
  outline: none;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  z-index: ${({ zIndex }) => zIndex ?? null};
  position: relative;
  ${({ scale }) => (scale ? `transform: scale(${scale})` : '')};
  transition: all 150ms ease-in-out;
  box-shadow: ${({ shadow, color }) =>
    shadow
      ? typeof shadow === 'string'
        ? shadow
        : color === 'white'
        ? 'var(--shadow-dropdown-art-assets)'
        : 'var(--shadow-form)'
      : ''};
  ${({ color }) => (color === 'white' ? 'backdrop-filter: blur(4px);' : '')}

  border-color: ${({ color, error }) =>
    color === 'white'
      ? 'var(--color-white)'
      : error
      ? 'var(--color-red-500)'
      : color === 'pink'
      ? 'var(--gradient-violet)'
      : color === 'strongPink'
      ? '#e4e1ff'
      : color === 'white'
      ? 'rgba(0, 0, 0, 0.075)'
      : color === 'gray'
      ? 'var(--color-indigo-50)'
      : color === 'lightGray'
      ? 'var(--color-indigo-25)'
      : color === 'purple'
      ? 'var(--color-purple-50)'
      : 'var(--color-indigo-500)'};
  border-width: ${({ borderWidth }) => borderWidth || '2px'};
  border-style: solid;

  &:focus {
    outline: none;
  }
`;

/**
 * @param {string} color - Color of the Card.
 * - pink
 * - indigo
 * - white
 * - purple
 * @param {boolean} error - Whether the Card is in error state.
 * @param {string} as - The tag name of the button. (optional)
 * - div
 * - form (default)
 * - button
 * @param {boolean} shadow - Whether to show a shadow.
 * @param {string} borderWidth - The width of the border.
 * @param {string} padding - The padding of the Card.
 * @param {string} margin - The margin of the Card.
 * @param {string} radius - The border radius of the Card. (optional)
 * @param {string} width - The width of the Card. (optional)
 * @param {string} className - Note: Don't use any classes for padding and margin.
 * @param {string} wrapperClassName - wrapper class names
 * @param {Node} children - The content of the Card.
 * @additional The rest of the props are passed to the underlying component.
 */
const BorderedCard = (
  {
    color,
    error,
    as = 'form',
    shadow = false,
    borderWidth = '2px',
    padding,
    margin,
    radius,
    width,
    height,
    className,
    children,
    zIndex,
    scale,
    containerStyle,
    ...rest
  },
  ref
) => {
  return (
    <Card
      color={color}
      as={as}
      padding={padding}
      className={className}
      radius={radius}
      borderWidth={borderWidth}
      margin={margin}
      shadow={shadow}
      width={width}
      height={height}
      error={error}
      zIndex={zIndex}
      scale={scale}
      style={containerStyle}
      ref={ref}
      {...rest}
    >
      {children}
    </Card>
  );
};

export default forwardRef(BorderedCard);
