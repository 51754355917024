import styled from 'styled-components';
import {
  InstagramGraySolidIcon,
  InstagramWhiteIcon,
  YoutubeGrayIcon,
  YoutubeWhiteIcon,
  TikTokFilledIcon,
  XIcon,
} from 'assets/icons';
import { useMedia } from 'react-use';
import device, { size } from 'styles/device';
import LanguageFlag from '../LanguageFlag';

const Socials = ({ white, className }) => {
  const isMobile = useMedia(`(max-width: ${size.md}px)`);

  return (
    <Wrapper className={className}>
      <Button
        white={white}
        href="https://www.instagram.com/megaevo1ution/"
        target="_blank"
        rel="noreferrer noopener"
      >
        {white ? (
          <InstagramWhiteIcon size={isMobile ? 0.64 : 1} />
        ) : (
          <InstagramGraySolidIcon size={isMobile ? 0.64 : 1} />
        )}
      </Button>
      <Button
        white={white}
        href="https://www.youtube.com/@megaevo1ution"
        target="_blank"
        rel="noreferrer noopener"
      >
        {white ? (
          <YoutubeWhiteIcon size={isMobile ? 0.67 : 1} />
        ) : (
          <YoutubeGrayIcon size={isMobile ? 0.381 : 0.57} />
        )}
      </Button>
      <Button
        white={white}
        href="https://twitter.com/MegaEvo1ution"
        target="_blank"
        rel="noreferrer noopener"
      >
        <XIcon size={isMobile ? 0.57 : 0.94} />
      </Button>

      <Button
        white={white}
        href="https://tiktok.com/@megaevo1ution"
        target="_blank"
        rel="noreferrer noopener"
      >
        <TikTokFilledIcon size={isMobile ? 0.625 : 1} />
      </Button>
      <LanguageFlag showChevronIcon={false} white={white} />
    </Wrapper>
  );
};

export default Socials;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 0.625rem 0;

  @media ${device.md} {
    gap: 1.875rem;
    padding: 0;
  }
`;

const Button = styled.a`
  background: none;
  border: none;
  padding: 0;
  color: ${({ white }) =>
    white ? 'var(--color-white)' : 'var(--color-indigo-300)'};

  &:hover {
    color: ${({ white }) =>
    white ? 'var(--color-white)' : 'var(--color-indigo-300)'};
  }
`;
