const TermsConditionsKeys = {
  title: 'TermsConditionsKeys.title',

  top_text: 'TermsConditionsKeys.top_text',

  General_Terms: {
    title: 'TermsConditionsKeys.General_Terms.title',

    _1_1: 'TermsConditionsKeys.General_Terms._1_1',
    _1_2: 'TermsConditionsKeys.General_Terms._1_2',
    _1_3: 'TermsConditionsKeys.General_Terms._1_3',
    _1_4: 'TermsConditionsKeys.General_Terms._1_4',
    _1_5: 'TermsConditionsKeys.General_Terms._1_5',
    _1_6: 'TermsConditionsKeys.General_Terms._1_6',
    _1_6_1: 'TermsConditionsKeys.General_Terms._1_6_1',
    _1_6_2: 'TermsConditionsKeys.General_Terms._1_6_2',
    _1_7: 'TermsConditionsKeys.General_Terms._1_7',
    _1_8: 'TermsConditionsKeys.General_Terms._1_8',
    _1_9: 'TermsConditionsKeys.General_Terms._1_9',
    _1_10: 'TermsConditionsKeys.General_Terms._1_10',
    _1_11: 'TermsConditionsKeys.General_Terms._1_11',
    _1_12: 'TermsConditionsKeys.General_Terms._1_12',
  },

  Your_Relationship: {
    title: 'TermsConditionsKeys.Your_Relationship.title',

    _2_1: 'TermsConditionsKeys.Your_Relationship._2_1',
    _2_2: 'TermsConditionsKeys.Your_Relationship._2_2',
    _2_3: 'TermsConditionsKeys.Your_Relationship._2_3',
    _2_3_1: 'TermsConditionsKeys.Your_Relationship._2_3_1',
    _2_3_2: 'TermsConditionsKeys.Your_Relationship._2_3_2',
    _2_3_3: 'TermsConditionsKeys.Your_Relationship._2_3_3',
    _2_4: 'TermsConditionsKeys.Your_Relationship._2_4',
    _2_5: 'TermsConditionsKeys.Your_Relationship._2_5',
    _2_6: 'TermsConditionsKeys.Your_Relationship._2_6',
    _2_7: 'TermsConditionsKeys.Your_Relationship._2_7',
    _2_8: 'TermsConditionsKeys.Your_Relationship._2_8',
    _2_9: 'TermsConditionsKeys.Your_Relationship._2_9',
    _2_10: 'TermsConditionsKeys.Your_Relationship._2_10',
    _2_11: 'TermsConditionsKeys.Your_Relationship._2_11',
    _2_12: 'TermsConditionsKeys.Your_Relationship._2_12',
    _2_13: 'TermsConditionsKeys.Your_Relationship._2_13',
    _2_14: 'TermsConditionsKeys.Your_Relationship._2_14',
    _2_15: 'TermsConditionsKeys.Your_Relationship._2_15',
    _2_16: 'TermsConditionsKeys.Your_Relationship._2_16',
    _2_17: 'TermsConditionsKeys.Your_Relationship._2_17',
  },

  Mega_Balance: {
    title: 'TermsConditionsKeys.Mega_Balance.title',

    _3_1: 'TermsConditionsKeys.Mega_Balance._3_1',
    _3_2: 'TermsConditionsKeys.Mega_Balance._3_2',
    _3_3: 'TermsConditionsKeys.Mega_Balance._3_3',
    _3_4: 'TermsConditionsKeys.Mega_Balance._3_4',
    _3_5: 'TermsConditionsKeys.Mega_Balance._3_5',
    _3_6: 'TermsConditionsKeys.Mega_Balance._3_6',
    _3_7: 'TermsConditionsKeys.Mega_Balance._3_7',
    _3_8: 'TermsConditionsKeys.Mega_Balance._3_8',
  },

  Prices_Payment: {
    title: 'TermsConditionsKeys.Prices_Payment.title',

    _4_1: 'TermsConditionsKeys.Prices_Payment._4_1',
    _4_2: 'TermsConditionsKeys.Prices_Payment._4_2',
    _4_3: 'TermsConditionsKeys.Prices_Payment._4_3',
    _4_4: 'TermsConditionsKeys.Prices_Payment._4_4',
    _4_5: 'TermsConditionsKeys.Prices_Payment._4_5',
    _4_6: 'TermsConditionsKeys.Prices_Payment._4_6',
    _4_7: 'TermsConditionsKeys.Prices_Payment._4_7',
    _4_8: 'TermsConditionsKeys.Prices_Payment._4_8',
  },

  Creator_Renumeration: {
    title: 'TermsConditionsKeys.Creator_Renumeration.title',

    _5_1: 'TermsConditionsKeys.Creator_Renumeration._5_1',
    _5_2: 'TermsConditionsKeys.Creator_Renumeration._5_2',
    _5_3: 'TermsConditionsKeys.Creator_Renumeration._5_3',
    _5_4: 'TermsConditionsKeys.Creator_Renumeration._5_4',
    _5_5: 'TermsConditionsKeys.Creator_Renumeration._5_5',
    _5_6: 'TermsConditionsKeys.Creator_Renumeration._5_6',
    _5_7: 'TermsConditionsKeys.Creator_Renumeration._5_7',
    _5_8: 'TermsConditionsKeys.Creator_Renumeration._5_8',
    _5_9: 'TermsConditionsKeys.Creator_Renumeration._5_9',
    _5_10: 'TermsConditionsKeys.Creator_Renumeration._5_10',
  },

  User_Representations: {
    title: 'TermsConditionsKeys.User_Representations.title',

    _6_1: 'TermsConditionsKeys.User_Representations._6_1',
    _6_1_1: 'TermsConditionsKeys.User_Representations._6_1_1',
    _6_1_2: 'TermsConditionsKeys.User_Representations._6_1_2',
    _6_1_3: 'TermsConditionsKeys.User_Representations._6_1_3',
    _6_1_4: 'TermsConditionsKeys.User_Representations._6_1_4',
    _6_1_5: 'TermsConditionsKeys.User_Representations._6_1_5',
    _6_1_6: 'TermsConditionsKeys.User_Representations._6_1_6',
    _6_1_7: 'TermsConditionsKeys.User_Representations._6_1_7',
    _6_1_8: 'TermsConditionsKeys.User_Representations._6_1_8',
  },

  No_Reliance: {
    title: 'TermsConditionsKeys.No_Reliance.title',

    _7_1: 'TermsConditionsKeys.No_Reliance._7_1',
    _7_2: 'TermsConditionsKeys.No_Reliance._7_2',
    _7_3: 'TermsConditionsKeys.No_Reliance._7_3',
    _7_4: 'TermsConditionsKeys.No_Reliance._7_4',
    _7_5: 'TermsConditionsKeys.No_Reliance._7_5',
  },

  Technical_Requirements: {
    title: 'TermsConditionsKeys.Technical_Requirements.title',

    _8_1: 'TermsConditionsKeys.Technical_Requirements._8_1',
    _8_2: 'TermsConditionsKeys.Technical_Requirements._8_2',
  },

  Uploading_Content: {
    title: 'TermsConditionsKeys.Uploading_Content.title',

    _9_1_l1: 'TermsConditionsKeys.Uploading_Content._9_1_l1',
    _9_1_l2: 'TermsConditionsKeys.Uploading_Content._9_1_l2',
    _9_1_l3: 'TermsConditionsKeys.Uploading_Content._9_1_l3',
    _9_2: 'TermsConditionsKeys.Uploading_Content._9_2',
    _9_2_1: 'TermsConditionsKeys.Uploading_Content._9_2_1',
    _9_2_2: 'TermsConditionsKeys.Uploading_Content._9_2_2',
    _9_3: 'TermsConditionsKeys.Uploading_Content._9_3',
    _9_4: 'TermsConditionsKeys.Uploading_Content._9_4',
    _9_5: 'TermsConditionsKeys.Uploading_Content._9_5',
  },

  Contribution_License: {
    title: 'TermsConditionsKeys.Contribution_License.title',

    _10_1: 'TermsConditionsKeys.Contribution_License._10_1',
    _10_2: 'TermsConditionsKeys.Contribution_License._10_2',
    _10_3: 'TermsConditionsKeys.Contribution_License._10_3',
    _10_4: 'TermsConditionsKeys.Contribution_License._10_4',
    _10_5: 'TermsConditionsKeys.Contribution_License._10_5',
    _10_6: 'TermsConditionsKeys.Contribution_License._10_6',
    _10_7: 'TermsConditionsKeys.Contribution_License._10_7',
    _10_8: 'TermsConditionsKeys.Contribution_License._10_8',
    _10_9: 'TermsConditionsKeys.Contribution_License._10_9',
  },

  Restrictions: {
    title: 'TermsConditionsKeys.Restrictions.title',

    _11_1: 'TermsConditionsKeys.Restrictions._11_1',
    _11_1_1: 'TermsConditionsKeys.Restrictions._11_1_1',
    _11_1_2: 'TermsConditionsKeys.Restrictions._11_1_2',
    _11_1_3: 'TermsConditionsKeys.Restrictions._11_1_3',
    _11_1_4: 'TermsConditionsKeys.Restrictions._11_1_4',
    _11_1_5: 'TermsConditionsKeys.Restrictions._11_1_5',
    _11_1_6: 'TermsConditionsKeys.Restrictions._11_1_6',
  },

  Intellectual_Property: {
    title: 'TermsConditionsKeys.Intellectual_Property.title',

    _12_1: 'TermsConditionsKeys.Intellectual_Property._12_1',
    _12_2: 'TermsConditionsKeys.Intellectual_Property._12_2',
    _12_3: 'TermsConditionsKeys.Intellectual_Property._12_3',
  },

  Acceptable_Use_Policy: {
    title: 'TermsConditionsKeys.Acceptable_Use_Policy.title',

    _13_1: 'TermsConditionsKeys.Acceptable_Use_Policy._13_1',
    _13_2: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2',
    _13_2_1: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_1',
    _13_2_2: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_2',
    _13_2_3: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_3',
    _13_2_4: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_4',
    _13_2_5: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_5',
    _13_2_6: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_6',
    _13_2_7: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_7',
    _13_2_8: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_8',
    _13_2_9: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_9',
    _13_2_10: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_10',
    _13_2_11: 'TermsConditionsKeys.Acceptable_Use_Policy._13_2_11',
  },

  Suspension: {
    title: 'TermsConditionsKeys.Suspension.title',

    _14_1: 'TermsConditionsKeys.Suspension._14_1',
    _14_2: 'TermsConditionsKeys.Suspension._14_2',
    _14_3: 'TermsConditionsKeys.Suspension._14_3',
    _14_3_1: 'TermsConditionsKeys.Suspension._14_3_1',
    _14_3_2: 'TermsConditionsKeys.Suspension._14_3_2',
    _14_3_3: 'TermsConditionsKeys.Suspension._14_3_3',
    _14_3_4: 'TermsConditionsKeys.Suspension._14_3_4',
    _14_3_5: 'TermsConditionsKeys.Suspension._14_3_5',
    _14_3_6: 'TermsConditionsKeys.Suspension._14_3_6',
    _14_4: 'TermsConditionsKeys.Suspension._14_4',
  },

  Submissions: {
    title: 'TermsConditionsKeys.Submissions.title',

    _15_1: 'TermsConditionsKeys.Submissions._15_1',
    _15_2: 'TermsConditionsKeys.Submissions._15_2',
  },

  Modifications: {
    title: 'TermsConditionsKeys.Modifications.title',

    _16_1: 'TermsConditionsKeys.Modifications._16_1',
    _16_2: 'TermsConditionsKeys.Modifications._16_2',
    _16_3: 'TermsConditionsKeys.Modifications._16_3',
    _16_4: 'TermsConditionsKeys.Modifications._16_4',
    _16_5: 'TermsConditionsKeys.Modifications._16_5',
    _16_6: 'TermsConditionsKeys.Modifications._16_6',
  },

  Availability: {
    title: 'TermsConditionsKeys.Availability.title',

    _17_1: 'TermsConditionsKeys.Availability._17_1',
    _17_2: 'TermsConditionsKeys.Availability._17_2',
  },

  Corrections: {
    title: 'TermsConditionsKeys.Corrections.title',

    _18_1: 'TermsConditionsKeys.Corrections._18_1',
  },

  Privacy: {
    title: 'TermsConditionsKeys.Privacy.title',

    _19_1_l1: 'TermsConditionsKeys.Privacy._19_1_l1',
    _19_1_l2: 'TermsConditionsKeys.Privacy._19_1_l2',
    _19_1_l3: 'TermsConditionsKeys.Privacy._19_1_l3',
    _19_2: 'TermsConditionsKeys.Privacy._19_2',
    _19_2_1: 'TermsConditionsKeys.Privacy._19_2_1',
    _19_2_2: 'TermsConditionsKeys.Privacy._19_2_2',
    _19_3: 'TermsConditionsKeys.Privacy._19_3',
  },

  Term_Termination: {
    title: 'TermsConditionsKeys.Term_Termination.title',

    _20_1: 'TermsConditionsKeys.Term_Termination._20_1',
    _20_2: 'TermsConditionsKeys.Term_Termination._20_2',
    _20_3: 'TermsConditionsKeys.Term_Termination._20_3',
  },

  Limitation_Liability: {
    title: 'TermsConditionsKeys.Limitation_Liability.title',

    _21_1: 'TermsConditionsKeys.Limitation_Liability._21_1',
    _21_2: 'TermsConditionsKeys.Limitation_Liability._21_2',
    _21_2_1: 'TermsConditionsKeys.Limitation_Liability._21_2_1',
    _21_2_2: 'TermsConditionsKeys.Limitation_Liability._21_2_2',
    _21_2_3: 'TermsConditionsKeys.Limitation_Liability._21_2_3',
    _21_2_4: 'TermsConditionsKeys.Limitation_Liability._21_2_4',
    _21_2_5: 'TermsConditionsKeys.Limitation_Liability._21_2_5',
    _21_2_6: 'TermsConditionsKeys.Limitation_Liability._21_2_6',
    _21_3: 'TermsConditionsKeys.Limitation_Liability._21_3',
    _21_3_1: 'TermsConditionsKeys.Limitation_Liability._21_3_1',
    _21_3_2: 'TermsConditionsKeys.Limitation_Liability._21_3_2',
    _21_3_3: 'TermsConditionsKeys.Limitation_Liability._21_3_3',
    _21_4: 'TermsConditionsKeys.Limitation_Liability._21_4',
    _21_5: 'TermsConditionsKeys.Limitation_Liability._21_5',
  },

  Other_Important_Terms: {
    title: 'TermsConditionsKeys.Other_Important_Terms.title',

    _22_1: 'TermsConditionsKeys.Other_Important_Terms._22_1',
    _22_2: 'TermsConditionsKeys.Other_Important_Terms._22_2',
    _22_3: 'TermsConditionsKeys.Other_Important_Terms._22_3',
    _22_4: 'TermsConditionsKeys.Other_Important_Terms._22_4',
    _22_5: 'TermsConditionsKeys.Other_Important_Terms._22_5',
    _22_6: 'TermsConditionsKeys.Other_Important_Terms._22_6',
    _22_7: 'TermsConditionsKeys.Other_Important_Terms._22_7',
  },
};

export default TermsConditionsKeys;
