import ProfileKeys from '../translationKeys/ProfilePageKeys';

export const ProfileTranslations = {
  // Menu
  [ProfileKeys.Menu.edit]: 'プロフィール編集',
  [ProfileKeys.Menu.save]: 'プロフィール保存',
  [ProfileKeys.Menu.message_me]: 'メッセージを送る',

  // Menu Error
  [ProfileKeys.Menu.Error.creator_to_creator]:
    '非クリエイターアカウントを使用してください。',
  [ProfileKeys.Menu.Error.something_went_wrong]: '問題が発生しました。',
  [ProfileKeys.Menu.Error.self_chat]: 'メッセージを送信できません。',

  // Package
  [ProfileKeys.Package.edit]: 'パッケージを編集',
  [ProfileKeys.Package.no_content]: 'コンテンツがありません',
  [ProfileKeys.Package.available]: '利用可能',
  [ProfileKeys.Package.click_to_view]: 'もっと見る',
  [ProfileKeys.Package.click_to_view_recent]:
    'クリックして最新の{{content}}を見る',
  [ProfileKeys.Package.view_recent_videos]: '最新の動画を表示',

  // Topbar
  [ProfileKeys.Package.TopBar.tooltip]: 'このプランは現在利用できません。',

  // YouTube
  [ProfileKeys.Package.YouTube.Contents.video_prepare]: 'パッケージ準備中',
  [ProfileKeys.Package.YouTube.Contents.video_display]:
    '動画はこちらに表示されます。',

  // Description
  [ProfileKeys.Package.Description.default]: `
    クリエイターが現在パッケージの準備中です。 <br>
    しばらくしてから再度ご確認ください。`,

  // Details
  [ProfileKeys.Package.Details.Price.contact_me]: 'お問い合わせ',
  [ProfileKeys.Package.Details.Price.contact_creator]: 'クリエイターに連絡',
  [ProfileKeys.Package.Details.Price.service_fee]: 'サービス料込み',
  [ProfileKeys.Package.Details.Price.tooltip]: `
    別のクリエイターのパッケージで <br>
    もう一度試してください。`,

  [ProfileKeys.Package.Details.Toast.update_successful]: '更新が成功しました！',
  [ProfileKeys.Package.Details.Toast.could_not_update]:
    '更新できませんでした！',

  // Creator Summary
  [ProfileKeys.Creator_Summary.title]: 'クリエイター要約',
  [ProfileKeys.Creator_Summary.sub_title]:
    'クリエイターの基本的なチャンネル情報',
  [ProfileKeys.Creator_Summary.package_for_other_platforms]:
    '他のプラットフォーム向けパッケージ',

  // Basic Info
  [ProfileKeys.Creator_Summary.BasicInfo.hollow]:
    'ソーシャルネットワークの準備中',

  // Tooltip
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers]: 'フォロワー数',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers]: 'フォロワー数',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos]: '総動画数',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets]: '合計X件の投稿',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts]: '総投稿数',

  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube]:
    'YouTube 参加日',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter]: 'X 参加日',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram]:
    'Instagram 参加日',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok]:
    'TikTok 参加日',

  // Channel Contents
  [ProfileKeys.Channel_Contents.title]: 'チャンネルコンテンツ',
  [ProfileKeys.Channel_Contents.sub_title]:
    'コンテンツのエンゲージメントレベルを測定します',
  [ProfileKeys.Channel_Contents.views_to_subs]: '視聴回数対登録者',
  [ProfileKeys.Channel_Contents.views_to_followers]: '視聴回数対フォロワー',
  [ProfileKeys.Channel_Contents.reach_to_followers]: 'リーチ対フォロワー',
  [ProfileKeys.Channel_Contents.est_engagement]: '推定エンゲージメント',
  [ProfileKeys.Channel_Contents.channel_data]: 'チャンネルデータ',
  [ProfileKeys.Channel_Contents.loading_post]: '投稿読み込み中',

  [ProfileKeys.Channel_Contents.Twitter.feed]: 'X フィード',
  [ProfileKeys.Channel_Contents.Twitter.loading]: 'X 投稿を読み込んでいます',
  [ProfileKeys.Channel_Contents.Twitter.most_popular]: '最も人気のある X 投稿',
  [ProfileKeys.Channel_Contents.Tiktok.loading]: 'TikTok 読み込み中',

  [ProfileKeys.Channel_Contents.Filter._1_month]: '1ヶ月',
  [ProfileKeys.Channel_Contents.Filter._3_months]: '3ヶ月',
  [ProfileKeys.Channel_Contents.Filter._1_year]: '1年',
  [ProfileKeys.Channel_Contents.Filter.newest]: '新着順',
  [ProfileKeys.Channel_Contents.Filter.most_viewed]: '最も閲覧された順',
  [ProfileKeys.Channel_Contents.Filter.most_liked]: '最もいいねされた順',
  [ProfileKeys.Channel_Contents.Filter.most_commented]: '最もコメントされた順',

  // Channel Contents Tooltips - YouTube
  [ProfileKeys.Channel_Contents.Tooltip.youtube]: `
    ここに表示される分析は <br>
    過去1〜6ヶ月のデータに基づいています。 <br>
    公開された動画には過去1年間に、 <br>
    アップロードされたコンテンツが含まれています。`,
  [ProfileKeys.Channel_Contents.Tooltip.twitter]: `
    Xへの関与を含めたいくつかのデータの推測値を <br>
    使用しています。 ここでの分析は過去1-3ヶ月の<br>
    データに基づいているため、リアルタイムの <br>
    データと違いがある場合があります。
  `,
  [ProfileKeys.Channel_Contents.Tooltip.instagram]: `
    以下の統計は、過去1〜3ヶ月間の <br>
    データに基づいています。
  `,
  [ProfileKeys.Channel_Contents.Tooltip.tiktok]: `
    以下の統計は、過去1〜3ヶ月間の <br>
    データに基づいています。
  `,

  // Channel Contents Tooltips - Engagement Metrics
  [ProfileKeys.Channel_Contents.Tooltip.likes]: '閲覧ごとのいいね数',
  [ProfileKeys.Channel_Contents.Tooltip.comments]: '閲覧ごとのコメント数',
  [ProfileKeys.Channel_Contents.Tooltip.x_reposts]: '閲覧ごとのXリポスト',
  [ProfileKeys.Channel_Contents.Tooltip.shares]: '閲覧ごとのシェア数',

  [ProfileKeys.Channel_Contents.Tooltip.views_subs_rating]:
    '登録者ごとの閲覧数',
  [ProfileKeys.Channel_Contents.Tooltip.views_follower_rating]:
    'フォロワーごとのビュー数',
  [ProfileKeys.Channel_Contents.Tooltip.engagements]:
    '全体のエンゲージメント評価',

  // Channel Analytics
  [ProfileKeys.Channel_Analytics.title]: 'チャンネル分析',
  [ProfileKeys.Channel_Analytics.sub_title]:
    'チャンネルのパフォーマンスを数量化して評価します。',
  [ProfileKeys.Channel_Analytics.updated_on]: '更新日： {{date}}',
  [ProfileKeys.Channel_Analytics.avg_views]: '平均閲覧数',
  [ProfileKeys.Channel_Analytics.avg_impressions]: '平均インプレッション',
  [ProfileKeys.Channel_Analytics.avg_reach]: '平均リーチ',
  [ProfileKeys.Channel_Analytics.avg_likes]: '平均いいね数',
  [ProfileKeys.Channel_Analytics.avg_x_reposts]: '平均Xリポスト',
  [ProfileKeys.Channel_Analytics.engagements]: 'エンゲージメント',

  // Views Analysis
  [ProfileKeys.Channel_Analytics.Views_Analysis.title]: '閲覧分析',
  [ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram]: 'リーチ分析',
  [ProfileKeys.Channel_Analytics.Views_Analysis.periodic]: '閲覧数の推移',
  [ProfileKeys.Channel_Analytics.Views_Analysis.total]: '合計',

  // Subscribers Data
  [ProfileKeys.Channel_Analytics.Subscribers_Data.title]:
    'サブスクライバーデータ',

  // Followers Data
  [ProfileKeys.Channel_Analytics.Followers_Data.title]: 'フォロワーデータ',

  // Audience Analytics
  [ProfileKeys.Audience_Analytics.title]: '視聴者分析',
  [ProfileKeys.Audience_Analytics.sub_title]:
    'チャンネルのターゲット視聴者向けのビッグデータ分析',
  [ProfileKeys.Audience_Analytics.preparing_data]: 'データの準備中',

  // Audience Interest
  [ProfileKeys.Audience_Analytics.Audience_Interest.title]: '視聴者の興味',
  [ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags]: '人気のタグ',
  [ProfileKeys.Audience_Analytics.Keyword_Cloud.title]: 'キーワードクラウド',
  [ProfileKeys.Audience_Analytics.Top_10.title]: 'トップ10キーワード',

  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title]:
    'プランを確認する',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description]: `
    より多くの視聴者データにアクセスするには <br>
    プランを確認してアップグレードしてください。`,

  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.title]:
    'ログインが必要です',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.description]: `
    ユーザーの視聴者データにアクセスするには <br>
    まずログインしてください。`,

  // Review
  [ProfileKeys.Review.review_as_client]: 'クライアントとしてのレビュー',
  [ProfileKeys.Review.most_recent]: '最新順',
  [ProfileKeys.Review.most_helpful]: 'おすすめ順',
  [ProfileKeys.Review.no_reviews]: '表示するレビューはありません',
  [ProfileKeys.Review.recent_clients]: '最近のクライアント',

  [ProfileKeys.Review.star]: 'スター',
  [ProfileKeys.Review.stars]: 'スター',

  // Edit
  [ProfileKeys.Edit.Toast.being_saved]: 'プロフィールを保存しています。',
  [ProfileKeys.Edit.Toast.saved]: 'プロフィールが正常に保存されました！',
  [ProfileKeys.Edit.Toast.failed]:
    'プロフィールの保存に失敗しました。後でもう一度試してください。',

  // Package
  [ProfileKeys.Edit.Package.complete_percentage]:
    'このパッケージは{{number}}% 完了しています',

  [ProfileKeys.Edit.Package.Topbar.tooltip]: `
    パッケージは完了すると <br>
    プロフィールに表示されます。`,

  [ProfileKeys.Edit.Package.Topbar.Representative.tooltip]: `
    メインのパッケージは、 <br>
    ユーザーおよび企業側に <br>
    優先的に表示されます。`,

  [ProfileKeys.Edit.Package.Topbar.Completion.tooltip]: `
    メインのパッケージとして選択するには、 <br>
    まずパッケージを完了してください。`,

  // Banner
  [ProfileKeys.Edit.Package.Banner.heading]: '推奨 1920X318 / 最大サイズ 20MB',
  [ProfileKeys.Edit.Package.Banner.mobile_heading]:
    '最大サイズ 20MB / 1920X318のバナー',
  [ProfileKeys.Edit.Package.Banner.tooltip]: `
    バナー画像に影を追加します。`,

  // Options
  [ProfileKeys.Edit.Package.Options.intro_and_closing]: 'Intro & Closing',
  [ProfileKeys.Edit.Package.Options.product_review]: 'Product Review',
  [ProfileKeys.Edit.Package.Options.visit_review]: 'Visit Review',
  [ProfileKeys.Edit.Package.Options.digital_product_review]:
    'Digital Product Review',
  [ProfileKeys.Edit.Package.Options.brand_ambassador]: 'Brand Ambassador',
  [ProfileKeys.Edit.Package.Options.short_video]: 'Short Video',
  [ProfileKeys.Edit.Package.Options.product_placement]: 'Product Placement',
  [ProfileKeys.Edit.Package.Options.interview]: 'Interview',
  [ProfileKeys.Edit.Package.Options.commercial_break]: 'Commercial Break',
  [ProfileKeys.Edit.Package.Options.live_streaming]: 'Live Streaming',
  [ProfileKeys.Edit.Package.Options.giveaways]: 'Giveaways',
  [ProfileKeys.Edit.Package.Options.meme_and_artwork]: 'Meme / Artwork',
  [ProfileKeys.Edit.Package.Options.interview_and_ama]: 'Interview / AMA',

  // Title
  [ProfileKeys.Edit.Package.Title.use_a_template]: 'テンプレートを使用する',
  [ProfileKeys.Edit.Package.Title.placeholder]:
    'パッケージのタイトルを入力してください',
  [ProfileKeys.Edit.Package.Title.placeholder2]:
    'ここにパッケージのタイトルを入力してください',

  // Featured Image
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.title]: '代表サムネイル',
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip]: `
    チャンネルのスタイルをよく反映する <br>
    YouTubeサムネイルをお勧めします。`,

  [ProfileKeys.Edit.Package.Featured_Image.title]: '注目の画像',
  [ProfileKeys.Edit.Package.Featured_Image.placeholder]:
    'JPG、JPEG、PNG ファイルのみ可。16:9推奨。最大200MB。',
  [ProfileKeys.Edit.Package.Featured_Image.tooltip]: `
    該当の画像は、パッケージの <br>
    代表サムネイルとして使用されます。`,

  [ProfileKeys.Edit.Package.Featured_Image.Error.file_upload]: `
    ファイルのアップロードがまだ進行中です。 <br>
    しばらくしてからもう一度お試しください。`,
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_large]:
    'ファイルサイズが大きすぎます。 (最大 ',
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid]:
    '無効なファイル形式です。 (JPG、JPEG、PNGを使用してください)',

  // Sample Contents
  [ProfileKeys.Edit.Package.Sample_Contents.title]: 'サンプルコンテンツ',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube]:
    'ビデオリンクをコピーして貼り付け',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter]:
    'X リンクをコピーして貼り付けてください',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram]:
    '投稿リンクをコピーして貼り付け',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok]:
    'TikTokリンクをコピーして貼り付け',

  [ProfileKeys.Edit.Package.Sample_Contents.add_more_contents]:
    'さらにコンテンツを追加',
  [ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link]:
    '無効なリンクが検出されました',

  // Description
  [ProfileKeys.Edit.Package.Description.placeholder]: `
    ここにサービスの詳細を記入してください。 <br>
    バイヤーは以下の情報を探す場合があります。 <br><br>
    <ul>
      <li>メインコンテンツまたはサービス</li>
      <li>ターゲット視聴者</li>
      <li>スケジュール</li>
    </ul>`,
  [ProfileKeys.Edit.Package.Description.placeholder_gpt]:
    '文を入力し始めると、ChatGPTが残りを提案します。',

  [ProfileKeys.Edit.Package.Description.ChatGPT.title]:
    'ChatGPTでユニークなパッケージを作成',
  [ProfileKeys.Edit.Package.Description.ChatGPT.tooltip]: `
    サービスを最もよく説明する <br>
    ものを選択してください。`,

  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title]:
    '追加情報',
  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder]:
    '説明に含める必要のある情報を入力してください。',

  // Price
  [ProfileKeys.Edit.Package.Price.placeholder]: '価格を入力',
  [ProfileKeys.Edit.Package.Price.tooltip]: `
    お客様が価格を確認できるように <br>
    価格をオープンに設定します。 <br>
    他のオプションでは価格が設定されていないか、 <br>
    検証済みの企業のみが利用可能です。`,

  [ProfileKeys.Edit.Package.Price.Type.open]:
    '公開価格 - すべてのクライアントに表示',
  [ProfileKeys.Edit.Package.Price.Type.unlockable]:
    'ロック解除価格 - 検証済みの企業のみ',
  [ProfileKeys.Edit.Package.Price.Type.contact_me]: 'お問い合わせ - 価格不要',

  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title]:
    'メッセージを送信するには認証が必要です',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description]: `
    より多くのクリエイターにメッセージを送信するには、簡単な認証が必要です。認証には1〜2分程度かかります。認証後に再試行してください。 <br><br> 
    次回更新日: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title]:
    'メッセージを送信するにはプランをアップグレードしてください',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description]: `
    より多くのクリエイターにメッセージを送信するには、プランのアップグレードが必要です。 <br> 
    使用中のプランを確認して、再試行してください。 <br><br> 
    次回更新日: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title]:
    '価格を解除するには認証が必要です',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description]: `
    より多くの料金を解除するには、簡単な認証が必要です。認証には1〜2分程度かかります。認証後に再試行してください。 <br><br> 
    次回更新日: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title]:
    'より多くの価格を解除するためにプランをアップグレードしてください',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
    .description]: `
    次回の更新日：{{date}} <br> 
    これらの価格を解除するためにプランをアップグレードしてください。`,
  [ProfileKeys.Edit.Package.Price.Modal.btn]: '今すぐアップグレード',

  [ProfileKeys.Edit.Package.Price.toast]: `
    ロック解除に成功しました！ ({{number}}残り)`,

  [ProfileKeys.Edit.Package.Price.Error.minimum]: '最低価格は{{number}}です',
  [ProfileKeys.Edit.Package.Price.Error.maximum]: '最大価格は{{number}}です',

  // Contents
  [ProfileKeys.Edit.Package.Contents.YouTube.video]: 'YouTube 動画',
  [ProfileKeys.Edit.Package.Contents.YouTube.shorts]: 'YouTube ショート',
  [ProfileKeys.Edit.Package.Contents.YouTube.live]: 'YouTube ライブ',

  [ProfileKeys.Edit.Package.Contents.Twitter.post]: 'X 投稿',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_post]:
    'Instagram ポスト',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_story]:
    'Instagram ストーリー',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels]:
    'Instagram リール',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_live]:
    'Instagram ライブ',

  [ProfileKeys.Edit.Package.Contents.Tiktok.video]: 'TikTok 動画',
  [ProfileKeys.Edit.Package.Contents.Tiktok.live]: 'TikTok ライブ',

  // Delivery
  [ProfileKeys.Edit.Package.Delivery.placeholder]:
    '制作期間を入力してください。(7〜60日)',
  [ProfileKeys.Edit.Package.Delivery.input]: '日',
  [ProfileKeys.Edit.Package.Delivery.tooltip]: `
    コンテンツの制作およびアップロードに <br>
    必要な時間を選択してください。`,

  // Revisions
  [ProfileKeys.Edit.Package.Revisions.no_revisions]: '修正なし',
  [ProfileKeys.Edit.Package.Revisions.tooltip]: `
    注文に提供する修正の数を選択します。`,

  // Type/Length
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    '例: 5~10分の製品レビュー動画',

  // User Info
  // Username
  [ProfileKeys.Edit.User_Info.Username.placeholder]:
    'ユーザー名を入力してください',
  [ProfileKeys.Edit.User_Info.Username.Error.enter_name]:
    '名前を入力してください',
  [ProfileKeys.Edit.User_Info.Username.Error.max_50]: '最大50文字',
  [ProfileKeys.Edit.User_Info.Username.Error.max_30]:
    '3〜30文字を入力してください',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol]:
    '無効な記号またはスペースが検出されました',
  [ProfileKeys.Edit.User_Info.Username.Error.max_2000]: '最大2000文字',
  [ProfileKeys.Edit.User_Info.Username.Error.max_100]: '最大100文字',
  [ProfileKeys.Edit.User_Info.Username.Error.max_500]: '最大500文字',
  [ProfileKeys.Edit.User_Info.Username.Error.max_200]: '最大200文字',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_price]: '無効な価格',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time]:
    '無効な配信時間',
  [ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60]:
    '配信時間は1日から60日の間で設定してください。',

  // My URL
  [ProfileKeys.Edit.User_Info.My_URL.placeholder]: 'URLを入力してください',

  [ProfileKeys.Edit.User_Info.My_URL.Error.char_limit]:
    '3〜30文字を入力してください',
  [ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol]:
    '無効な記号またはスペースが検出されました',
  [ProfileKeys.Edit.User_Info.My_URL.Error.required_handle]:
    'URL を入力してください',
  [ProfileKeys.Edit.User_Info.My_URL.Error.already_taken]:
    'この URL はすでに使用されています',

  // Social Network
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.priority]:
    '優先度を変更できませんでした',
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink]:
    'プラットフォームのリンクを解除できませんでした',

  [ProfileKeys.Edit.User_Info.Social_Network.Popup.title]:
    'まず注文を完了してください',
  [ProfileKeys.Edit.User_Info.Social_Network.Popup.description]:
    '注文をキャンセルするか完了した後に、 確認されたソーシャルネットワークアカウントをリンク解除できます。',

  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.title]:
    'このアカウントのリンクを解除しますか？',
  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.description]:
    '確認されたSNSアカウントのリンクを解除すると、それに関連するデータが失われる場合があります。また、関連するパッケージ情報は、プロフィールや検索結果に表示されなくなります。',

  // Bio
  [ProfileKeys.Edit.User_Info.Bio.placeholder_creator]:
    'グローバルなブランドと企業あなたのストーリーを共有しましょう！',
  [ProfileKeys.Edit.User_Info.Bio.placeholder_client]:
    'グローバルなクリエーターとあなたのストーリーを共有しましょう！',

  // Categories
  [ProfileKeys.Edit.User_Info.Categories.add_a_category]: 'カテゴリーを追加',

  // Target Countries
  [ProfileKeys.Edit.User_Info.Target_Countries.title]: '国',
  [ProfileKeys.Edit.User_Info.Target_Countries.add_a_country]: '国を追加',
  [ProfileKeys.Edit.User_Info.Target_Countries.tooltip]: `
    現在居住している国、または拠点としている <br>
    国を選択してください。（最大2カ国まで選択可）`,

  // Languages
  [ProfileKeys.Edit.User_Info.Languages.add_a_language]: '言語を追加',

  // Company
  [ProfileKeys.Edit.User_Info.Company.placeholder]:
    '所属 · 会社名を入力してください',
  [ProfileKeys.Edit.User_Info.Company.tooltip]: `
    会社のロゴをアップロード <br>
    PNG、JPG、JPEG形式、 <br>
    360x360が推奨されます。 <br>
    最大サイズは50MBです`,
  [ProfileKeys.Edit.User_Info.Company.Error.max_30]: '最大30文字',

  // Modal
  [ProfileKeys.Modal.Request_Price.title]: '価格をリクエスト',
  [ProfileKeys.Modal.Request_Price.btn]: 'リクエストを送信',
  [ProfileKeys.Modal.Request_Price.toast]: 'リクエストが正常に送信されました。',
  [ProfileKeys.Modal.Request_Price.Message.title]: 'メッセージ',
  [ProfileKeys.Modal.Request_Price.Message.placeholder]:
    '質問またはプロジェクトの詳細（予算、製品情報、要件など）を記入してください。',

  [ProfileKeys.Modal.Edit.Banner.heading]: '方法を選択',
  [ProfileKeys.Modal.Edit.Banner.body]: '画像をアップロード',
  [ProfileKeys.Modal.Edit.Banner.loading]: '読み込み中',
  [ProfileKeys.Modal.Edit.Banner.use_my]: '私の{{platform}} {{type}}を使用する',
  [ProfileKeys.Modal.Edit.Banner.not_found]:
    '{{platform}} {{type}}が見つかりません',

  [ProfileKeys.Modal.Edit.Incomplete_Package.title]:
    '未完成なパッケージがあります',
  [ProfileKeys.Modal.Edit.Incomplete_Package.description]: `
    以下のパッケージが未完成です： <br><br>
    <package></package> <br>
    これらのパッケージを完成して、検索結果とプロフィールに表示されるようにしましょう。これらを今すぐ完成させますか？`,

  [ProfileKeys.Modal.Edit.new_reward]: '新しいリワードがアンロックされました。',
  [ProfileKeys.Modal.Edit.skip_for_now]: '今はスキップ',

  [ProfileKeys.Modal.Reconnection.title]: '再接続が必要',
  [ProfileKeys.Modal.Reconnection.description]: `
    各ソーシャルメディアプラットフォームの長期的な活動停止またはポリシー-機能の更新のため、以下のプラットフォームでは再接続が必要です。続行するには、各プラットフォームに再接続するか、リンクを解除してください。 <br><br>
    MegaEvolutionは、Google、Meta（Instagram）、TikTokの公式認証済みパートナーAPIを介した安全な接続をサポートしています。`,
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title]:
    '現在のアカウントのリンクを解除した後、新しいアカウントに接続できます。',
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description]:
    '現在接続されているアカウントと異なるアカウントが検出されました。新しいアカウントに接続するには、既存のアカウントのリンクを解除し、もう一度やり直してください。',

  // Guide
  [ProfileKeys.Guide.hi_ginseng_mega]: 'こんにちは、私はMEGAです！',
  [ProfileKeys.Guide.nice_to_meet_you]: 'はじめまして〜！',
  [ProfileKeys.Guide.create_unique_url]: 'ユニークなURLを作成する',
  [ProfileKeys.Guide.we_help_you_receive]: 'グローバルブランドから',
  [ProfileKeys.Guide.we_help_you_receive_mobile]:
    '依頼を受け取るお手伝いをします',
  [ProfileKeys.Guide.proposal_golbal_brands]: 'グローバルブランドからの依頼を',
  [ProfileKeys.Guide.proposal_golbal_brands_mobile]: '受け取るお手伝いをします',
  [ProfileKeys.Guide.create_unique_portfolio]:
    'ユニークなポートフォリオを作成する',
  [ProfileKeys.Guide.just_few_clicks]: 'たった数回のクリックで！',
  [ProfileKeys.Guide.click_to_join]: '参加するにはここをクリック',
  [ProfileKeys.Guide.click_to_join_mobile]: 'ここをクリック',
  [ProfileKeys.Guide.anytime]: 'いつでも！',
  [ProfileKeys.Guide.anytime_mobile]: 'いつでも参加してください！',
  [ProfileKeys.Guide.what_a_pleasure]: '今日はお会いできて嬉しいです！',
  [ProfileKeys.Guide.hope_to_see]:
    'ユニークなページを早く見れるのを楽しみにしています！',
  [ProfileKeys.create_unique_handle]:
    'ユニークな@ハンドルでURLを作成してください！',

  // Profile
  [ProfileKeys.Guide.Profile.welcome]: 'ようこそ、私はMEGAです！',
  [ProfileKeys.Guide.Profile.portfolio_space]: 'これはあなたのために特別に',
  [ProfileKeys.Guide.Profile.specially_created]:
    '作成されたポートフォリオスペースです。',
  [ProfileKeys.Guide.Profile.click_here_to_start_editing]:
    'ここをクリックして編集を開始してください。',
  [ProfileKeys.Guide.Profile.package_will_show_up]:
    'あなたのパッケージはここに表示されます！',
  [ProfileKeys.Guide.Profile.click_here]: 'ここをクリック',
  [ProfileKeys.Guide.Profile.to_start_editing]: '編集を開始するには',
  [ProfileKeys.Guide.Profile.package_will_be_displated]:
    'あなたのパッケージが表示されます！',

  // Profile Edit
  [ProfileKeys.Guide.Profile_Edit.welcome]: 'プロフィールへようこそ！',
  [ProfileKeys.Guide.Profile_Edit.try_me]: 'こちらを利用して簡単かつ迅速に',
  [ProfileKeys.Guide.Profile_Edit.compelling_package]:
    '魅力的なプロフィールを完成できます。',
  [ProfileKeys.Guide.Profile_Edit.upload_image]: '画像をアップロードして',
  [ProfileKeys.Guide.Profile_Edit.showcase_service]: 'サービスを紹介',
  [ProfileKeys.Guide.Profile_Edit.add_post_video]:
    'コンテンツリンクを追加して企業と',
  [ProfileKeys.Guide.Profile_Edit.for_your_clients]:
    'お客様の関心を惹きましょう！',
  [ProfileKeys.Guide.Profile_Edit.set_up_price]:
    '様々なオプションで価格設定可能。',
  [ProfileKeys.Guide.Profile_Edit.choose_best_option]:
    'パッケージに最適なオプションを選択してください！',
  [ProfileKeys.Guide.Profile_Edit.complete_package]:
    'パッケージを完成させれば、',
  [ProfileKeys.Guide.Profile_Edit.make_it_public]:
    'バイヤーに自動公開されます。',
  [ProfileKeys.Guide.Profile_Edit.save_description1]:
    'プロフィールが保存されているか',
  [ProfileKeys.Guide.Profile_Edit.save_description2]:
    'ご確認ください。完成したら訪問者の',
  [ProfileKeys.Guide.Profile_Edit.save_description3]:
    'ために、自動で翻訳されます。',
  [ProfileKeys.Guide.Profile_Edit.create_unique_package]:
    '世界中の企業に向けた、素敵なプロフィールを作りましょう！',
  [ProfileKeys.Guide.Profile_Edit.quick_setup]: '簡単セットアップ可能！',

  [ProfileKeys.Guide.Profile_Edit.Toast.register_success]:
    '登録完了！注文を受けるにはプロフィールを設定してください。',
};
