import dayjs from 'dayjs';
import i18n from 'translations/i18n';
import updateLocale from 'dayjs/plugin/updateLocale';
import relativeTime from 'dayjs/plugin/relativeTime';
import enLocale from './Languages/en';
import koLocale from './Languages/ko';
import jaLocale from './Languages/ja';
import thLocale from './Languages/th';
import esLocale from './Languages/es';
import zhLocale from './Languages/zh';
import viLocale from './Languages/vi';

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.updateLocale('en', enLocale);
dayjs.updateLocale('ko', koLocale);
dayjs.updateLocale('ja', jaLocale);
dayjs.updateLocale('th', thLocale);
dayjs.updateLocale('es', esLocale);
dayjs.updateLocale('zh', zhLocale);
dayjs.updateLocale('vi', viLocale);

dayjs.locale(i18n.language);

export default dayjs;
