export const TelegramWhiteIcon = ({ size = 1 }) => (
  <svg
    width={22 * size}
    height={22 * size}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0C4.92482 0 0 4.92482 0 11C0 17.0752 4.92482 22 11 22C17.0752 22 22 17.0752 22 11C22 4.92482 17.0752 0 11 0Z"
      fill="white"
    />
    <path
      d="M16.3521 6.32744L14.3873 16.2334C14.3873 16.2334 14.1126 16.9204 13.3569 16.5906L8.82306 13.1146L7.17443 12.3178L4.39917 11.3835C4.39917 11.3835 3.97326 11.2324 3.93201 10.9027C3.89076 10.5729 4.41292 10.3944 4.41292 10.3944L15.4452 6.06654C15.4452 6.06654 16.352 5.66813 16.352 6.32762"
      fill="#3CB2EA"
    />
    <path
      d="M8.47992 16.1222C8.47992 16.1222 8.34758 16.1098 8.18258 15.5876C8.01784 15.0656 7.17969 12.3178 7.17969 12.3178L13.8431 8.08623C13.8431 8.08623 14.2279 7.85266 14.2141 8.08623C14.2141 8.08623 14.2828 8.12748 14.0766 8.31981C13.8706 8.51223 8.84215 13.0323 8.84215 13.0323"
      fill="white"
    />
    <path
      d="M10.5634 14.4472L8.77012 16.0823C8.77012 16.0823 8.62987 16.1887 8.47656 16.122L8.81997 13.085"
      fill="#3CB2EA"
    />
  </svg>
);
