import ProposalKeys from 'translations/translationKeys/ProposalPageKeys';

const SnippetOptions = {
  CREATOR_CHANNEL_NAME: 'creatorChannelName',
  CREATOR_USERNAME: 'creatorUsername',
  CREATOR_COUNTRY: 'creatorCountry',
  CREATOR_CATEGORIES: 'creatorCategories',
};

export const SnippetOptionsNames = {
  [SnippetOptions.CREATOR_CHANNEL_NAME]:
    ProposalKeys.Part1.Option_3.Snippet.option_1,
  [SnippetOptions.CREATOR_USERNAME]:
    ProposalKeys.Part1.Option_3.Snippet.option_2,
  [SnippetOptions.CREATOR_COUNTRY]:
    ProposalKeys.Part1.Option_3.Snippet.option_3,
  [SnippetOptions.CREATOR_CATEGORIES]:
    ProposalKeys.Part1.Option_3.Snippet.option_4,
};

export default SnippetOptions;
