import GeneralKeys from '../translationKeys/GeneralKeys';

export const GeneralTranslations = {
  [GeneralKeys.continue]: 'Continuar',
  [GeneralKeys.submit]: 'Enviar',
  [GeneralKeys.cancel]: 'Cancelar',
  [GeneralKeys.close]: 'Cerrar',
  [GeneralKeys.return]: 'Volver',
  [GeneralKeys.account]: 'Cuenta',
  [GeneralKeys.coinbase]: 'CoinBase',
  [GeneralKeys.password]: 'Contraseña',
  [GeneralKeys.update]: 'Actualizar',
  [GeneralKeys.coming_soon]: 'Proximamente',
  [GeneralKeys.all]: 'Todo',
  [GeneralKeys.filter]: 'Filtrar',
  [GeneralKeys.contents]: 'Contenidos',
  [GeneralKeys.myspace]: 'Mi Espacio',
  [GeneralKeys.myproposal]: 'Mi Propuesta',
  [GeneralKeys.proposals]: 'Propuestas',
  [GeneralKeys.billing]: 'Facturación',
  [GeneralKeys.settings]: 'Configuración',
  [GeneralKeys.profile]: 'Perfil',
  [GeneralKeys.my_orders]: 'Mis Pedidos',
  [GeneralKeys.orders]: 'Pedidos',
  [GeneralKeys.message]: 'Mensaje',
  [GeneralKeys.support]: 'Soporte',
  [GeneralKeys.help_center]: 'Centro de ayuda',
  [GeneralKeys.creator_faq]: 'FAQ del creador',
  [GeneralKeys.company_faq]: 'FAQ de empresas y marcas',
  [GeneralKeys.myCart]: 'Mi Carrito',
  [GeneralKeys.platform]: 'Plataforma',
  [GeneralKeys.sort_by]: 'Ordenar por',
  [GeneralKeys.country]: 'País',
  [GeneralKeys.categories]: 'Categorías',
  [GeneralKeys.price]: 'Precio',
  [GeneralKeys.subscribers]: 'Suscriptores',
  [GeneralKeys.avg_views]: 'Vistas promedio',
  [GeneralKeys.audience_gender]: 'Género del público',
  [GeneralKeys.audience_age]: 'Edad del público',
  [GeneralKeys.youtube]: 'YouTube',
  [GeneralKeys.twitter]: 'X (Twitter)',
  [GeneralKeys.instagram]: 'Instagram',
  [GeneralKeys.tiktok]: 'TikTok',
  [GeneralKeys.delivery]: 'Tiempo de entrega',
  [GeneralKeys.type_length]: 'Tipo/Duración',
  [GeneralKeys.day_singular]: `{{number}} Día`,
  [GeneralKeys.day_plural]: `{{number}} Días`,
  [GeneralKeys.loading]: 'Cargando',
  [GeneralKeys.company]: 'Empresa',
  [GeneralKeys.bio]: 'Biografía',
  [GeneralKeys.subs]: 'Suscriptores',
  [GeneralKeys.followers]: 'Seguidores',
  [GeneralKeys.videos]: 'Videos',
  [GeneralKeys.x_posts]: 'X Publicaciones',
  [GeneralKeys.posts_reels]: 'Publicaciones y Reels',
  [GeneralKeys.tiktok_videos]: 'Videos de TikTok',
  [GeneralKeys.since]: 'Desde {{date}}',
  [GeneralKeys.countries]: 'Países',
  [GeneralKeys.reviews]: 'Reseñas',
  [GeneralKeys.demographics]: 'Demografía',
  [GeneralKeys.others]: 'Otros',
  [GeneralKeys.check_plan]: 'Revisar plan',
  [GeneralKeys.login]: 'Iniciar sesión',
  [GeneralKeys.login_upper_case]: 'INICIAR SESIÓN',
  [GeneralKeys.total]: 'Total',
  [GeneralKeys.likes]: 'Me gusta',
  [GeneralKeys.comments]: 'Comentarios',
  [GeneralKeys.reposts]: 'X Reposts',
  [GeneralKeys.search]: 'Buscar',
  [GeneralKeys.contact]: 'Contacto',
  [GeneralKeys.price_sign]: '$ --',
  [GeneralKeys.unlocking]: 'Desbloqueando',
  [GeneralKeys.click_to_unlock]: 'Clic para desbloquear',
  [GeneralKeys.add_to_cart]: 'Agregar al carrito',
  [GeneralKeys.show_less]: 'Mostrar menos',
  [GeneralKeys.show_more]: 'Mostrar más',
  [GeneralKeys.go_back]: 'Volver atrás',
  [GeneralKeys.load_more]: 'Cargar más',
  [GeneralKeys.metamask]: 'MetaMask',
  [GeneralKeys.coinbase_wallet]: 'Cartera de Coinbase',
  [GeneralKeys.send]: 'Enviar',
  [GeneralKeys.email]: 'Correo electrónico',
  [GeneralKeys.or_upper_case]: 'O',
  [GeneralKeys.agree]: 'Aceptar',
  [GeneralKeys.client]: 'Marca',
  [GeneralKeys.influencer]: 'Creador',
  [GeneralKeys.marketing]: 'Marketing',
  [GeneralKeys.creator_marketing]: 'Marketing de creadores',
  [GeneralKeys.partnerships]: 'Puntos · Socios',
  [GeneralKeys.evo_tokens]: 'Puntos de EVO',
  [GeneralKeys.information]: 'Información',
  [GeneralKeys.website_credits]: 'Créditos del Sitio Web',
  [GeneralKeys.home]: 'Inicio',
  [GeneralKeys.about]: 'Sobre',
  [GeneralKeys.pricing]: 'Precios',
  [GeneralKeys.proposal]: 'Propuesta',
  [GeneralKeys.logout]: 'Cerrar sesión',
  [GeneralKeys.view_more]: 'Ver más',
  [GeneralKeys.view_less]: 'Ver menos',
  [GeneralKeys.title]: 'Título',
  [GeneralKeys.preview]: 'Vista previa',
  [GeneralKeys.upload_a_file]: 'Cargar un archivo',
  [GeneralKeys.description]: 'Descripción',
  [GeneralKeys.type]: 'Tipo',
  [GeneralKeys.category]: 'Categoría',
  [GeneralKeys.select]: 'Seleccionar',
  [GeneralKeys.unlimited_revisions]: 'Revisiones ilimitadas',
  [GeneralKeys.unlimited]: 'Ilimitado',
  [GeneralKeys.revision]: '{{number}} Revisión',
  [GeneralKeys.revisions]: '{{number}} Revisiones',
  [GeneralKeys.number_revision]: 'Número de Revisiones',
  [GeneralKeys.optional]: 'Opcional',
  [GeneralKeys.username]: 'Nombre de usuario',
  [GeneralKeys.social_network]: 'Red social',
  [GeneralKeys.unlink]: 'Desvincular',
  [GeneralKeys.unlink_platform]: 'Desvincular {{platform}}',
  [GeneralKeys.language]: 'lengua',
  [GeneralKeys.languages]: 'Idiomas',
  [GeneralKeys.package]: 'Paquete',
  [GeneralKeys.save]: 'Guardar',
  [GeneralKeys.creator]: 'Creador',
  [GeneralKeys.basic]: 'Básico',
  [GeneralKeys.standard]: 'básico',
  [GeneralKeys.premium]: 'estándar',
  [GeneralKeys.per_day]: '/día',
  [GeneralKeys.per_month]: '/mes',
  [GeneralKeys.summary]: 'Resumen',
  [GeneralKeys.general]: 'General',
  [GeneralKeys.register]: 'Registrarse',
  [GeneralKeys.click]: 'Hacer clic',
  [GeneralKeys.connect]: 'Conectar',
  [GeneralKeys.reconnect]: 'Reconectar',
  [GeneralKeys.connecting]: 'Conectando',
  [GeneralKeys.notifications]: 'Notificaciones',
  [GeneralKeys.messages]: 'Mensajes',
  [GeneralKeys.reminders]: 'Recordatorios',
  [GeneralKeys.newsletters]: 'Boletines informativos',
  [GeneralKeys.packages]: 'Paquetes',
  [GeneralKeys.real_time]: 'Tiempo real',
  [GeneralKeys.unsubscribe]: 'Darse de baja',
  [GeneralKeys.referral_code]: 'Código de Referencia',
  [GeneralKeys.total_invites]: 'Total de invitaciones: {{number}}',
  [GeneralKeys.submitted]: 'Enviado',
  [GeneralKeys.here]: 'aquí',
  [GeneralKeys.confirm]: 'Confirmar',
  [GeneralKeys.deactivate]: 'Desactivar',
  [GeneralKeys.delete]: 'Eliminar',
  [GeneralKeys.wallet]: 'Billetera',
  [GeneralKeys.yes]: 'Sí',
  [GeneralKeys.privacy_policy]: 'Política de Privacidad',
  [GeneralKeys.payment_policy]: 'Política de Pago',
  [GeneralKeys.reactivate]: 'Reactivar',
  [GeneralKeys.analytics]: 'Análisis',
  [GeneralKeys.user_info]: 'Información del Usuario',
  [GeneralKeys.enterprise]: 'premium',
  [GeneralKeys.details]: 'Detalles',
  [GeneralKeys.claim]: 'Reclamar',
  [GeneralKeys.in_progress]: 'En progreso',
  [GeneralKeys.free]: 'Gratis',
  [GeneralKeys.report]: 'Reportar',
  [GeneralKeys.link_copied]: 'Enlace copiado',
  [GeneralKeys.contents_lowercase]: 'contenidos',
  [GeneralKeys.channel_lowercase]: 'canal',
  [GeneralKeys.audience_lowercase]: 'audiencia',
  [GeneralKeys.my_url]: 'Mi URL',
  [GeneralKeys.username_lowercase]: 'username',
  [GeneralKeys.shorts]: 'Shorts',
  [GeneralKeys.x_post]: 'X Publicación',
  [GeneralKeys.repost]: 'X Repost',
  [GeneralKeys.website]: 'Mi Sitio Web',
  [GeneralKeys.banner]: 'banner',
  [GeneralKeys.profile_lowercase]: 'perfil',
  [GeneralKeys.congratulations]: '¡Felicitaciones!',
  [GeneralKeys.skip]: 'Omitir',
  [GeneralKeys.back]: 'Atrás',
  [GeneralKeys.shares]: 'Compartidos',
  [GeneralKeys.posts]: 'publicaciones',
  [GeneralKeys.client_company]: 'Cliente · Empresa',
  [GeneralKeys.contents_in]: '{{categories}} contenidos en {{countries}}.',
  [GeneralKeys.videos_lowercase]: 'videos',
  [GeneralKeys.tweets_lowercase]: 'X publicaciones',
  [GeneralKeys.posts_lowercase]: 'publicaciones',
  [GeneralKeys.rating]: 'Valoración',
  [GeneralKeys.users]: 'Usuarios',
  [GeneralKeys.browse_files]: 'Explorar archivos',
  [GeneralKeys.upload_failed]: 'Fallo en la carga',
  [GeneralKeys.downloading]: 'Descargando...',
  [GeneralKeys.package_summary]: 'Resumen del paquete',
  [GeneralKeys.contents_plural]: '{{number}} Contenidos',
  [GeneralKeys.contents_singular]: '{{number}} Contenido',
  [GeneralKeys.creator_brand_help_center]: 'Centro de Ayuda y FAQ',
  [GeneralKeys.main]: 'Principal',
  [GeneralKeys.date]: 'Fecha',
  [GeneralKeys.service]: 'Servicio',
  [GeneralKeys.order_id]: 'ID de Pedido',
  [GeneralKeys.transaction]: 'Transacción',
  [GeneralKeys.pdf]: 'PDF',
  [GeneralKeys.no_result_to_display]: 'No hay resultados para mostrar',
  [GeneralKeys.oldest]: 'Más antiguo',
  [GeneralKeys.rows_10]: '10 filas',
  [GeneralKeys.rows_50]: '50 filas',
  [GeneralKeys.rows_100]: '100 filas',
  [GeneralKeys.rows_500]: '500 filas',
  [GeneralKeys.rows_1000]: '1000 filas',
  [GeneralKeys.refresh]: 'Actualizar',

  // Category
  [GeneralKeys.Category.digital]: 'Digital',
  [GeneralKeys.Category.entertainment]: 'Entretenimiento',
  [GeneralKeys.Category.society]: 'Sociedad',
  [GeneralKeys.Category.life_style]: 'Estilo de vida',

  [GeneralKeys.Category.tech_science]: 'Tecnología · Ciencia',
  [GeneralKeys.Category.video_games]: 'Videojuegos',
  [GeneralKeys.Category.metaverse]: 'Metaverso',
  [GeneralKeys.Category.crypto_nft]: 'Aplicación · Tutoriales',
  [GeneralKeys.Category.finance]: 'Finanzas',
  [GeneralKeys.Category.movies]: 'Películas',
  [GeneralKeys.Category.anime_cartoon]: 'Anime · Dibujos animados',
  [GeneralKeys.Category.music]: 'Música',
  [GeneralKeys.Category.dance]: 'Baile',
  [GeneralKeys.Category.sports]: 'Deportes',
  [GeneralKeys.Category.comedy]: 'Comedia',
  [GeneralKeys.Category.politics_society]: 'Política · Sociedad',
  [GeneralKeys.Category.people_blogs]: 'Personas · Blogs',
  [GeneralKeys.Category.fashion]: 'Moda',
  [GeneralKeys.Category.beauty]: 'Belleza',
  [GeneralKeys.Category.food_cooking]: 'Comida · Cocina',
  [GeneralKeys.Category.youth_infant]: 'Juventud · Infantes',
  [GeneralKeys.Category.animals_pets]: 'Animales · Mascotas',
  [GeneralKeys.Category.travel_event]: 'Viajes · Cultura',
  [GeneralKeys.Category.auto_vehicles]: 'Automóviles · Vehículos',
  [GeneralKeys.Category.unboxing]: 'Unboxing',
  [GeneralKeys.Category.education]: 'Educación',
  [GeneralKeys.Category.art_crafts]: 'Arte · Artesanía',
  [GeneralKeys.Category.diy_home]: 'DIY · Interior',
  [GeneralKeys.Category.virtual_vtuber]: 'Virtual · VTuber',
  [GeneralKeys.Category.ai_automation]: 'IA · Automatización',
  [GeneralKeys.Category.gym_fitness]: 'Gimnasio · Fitness',

  // Country
  [GeneralKeys.Country.are]: 'Emiratos Árabes Unidos',
  [GeneralKeys.Country.arg]: 'Argentina',
  [GeneralKeys.Country.aus]: 'Australia',
  [GeneralKeys.Country.aut]: 'Austria',
  [GeneralKeys.Country.bel]: 'Bélgica',
  [GeneralKeys.Country.bra]: 'Brasil',
  [GeneralKeys.Country.can]: 'Canadá',
  [GeneralKeys.Country.che]: 'Suiza',
  [GeneralKeys.Country.chn]: 'China',
  [GeneralKeys.Country.deu]: 'Alemania',
  [GeneralKeys.Country.dnk]: 'Dinamarca',
  [GeneralKeys.Country.eeu]: 'Europa',
  [GeneralKeys.Country.esp]: 'España',
  [GeneralKeys.Country.fin]: 'Finlandia',
  [GeneralKeys.Country.fra]: 'Francia',
  [GeneralKeys.Country.gbr]: 'Reino Unido',
  [GeneralKeys.Country.glb]: 'Global',
  [GeneralKeys.Country.hkg]: 'Hong Kong',
  [GeneralKeys.Country.idn]: 'Indonesia',
  [GeneralKeys.Country.ind]: 'India',
  [GeneralKeys.Country.irn]: 'Irán',
  [GeneralKeys.Country.isr]: 'Israel',
  [GeneralKeys.Country.ita]: 'Italia',
  [GeneralKeys.Country.jpn]: 'Japón',
  [GeneralKeys.Country.kor]: 'Corea del Sur',
  [GeneralKeys.Country.mex]: 'México',
  [GeneralKeys.Country.mys]: 'Malasia',
  [GeneralKeys.Country.nld]: 'Países Bajos',
  [GeneralKeys.Country.nor]: 'Noruega',
  [GeneralKeys.Country.nzl]: 'Nueva Zelanda',
  [GeneralKeys.Country.pak]: 'Pakistán',
  [GeneralKeys.Country.phl]: 'Filipinas',
  [GeneralKeys.Country.pol]: 'Polonia',
  [GeneralKeys.Country.prt]: 'Portugal',
  [GeneralKeys.Country.sau]: 'Arabia Saudita',
  [GeneralKeys.Country.sgp]: 'Singapur',
  [GeneralKeys.Country.swe]: 'Suecia',
  [GeneralKeys.Country.tha]: 'Tailandia',
  [GeneralKeys.Country.tur]: 'Turquía',
  [GeneralKeys.Country.twn]: 'Taiwán',
  [GeneralKeys.Country.usa]: 'Estados Unidos',
  [GeneralKeys.Country.vnm]: 'Vietnam',

  // Client
  [GeneralKeys.Country.abw]: 'Aruba',
  [GeneralKeys.Country.afg]: 'Afganistán',
  [GeneralKeys.Country.ago]: 'Angola',
  [GeneralKeys.Country.aia]: 'Anguila',
  [GeneralKeys.Country.ala]: 'Islas Åland',
  [GeneralKeys.Country.alb]: 'Albania',
  [GeneralKeys.Country.and]: 'Andorra',
  [GeneralKeys.Country.arm]: 'Armenia',
  [GeneralKeys.Country.asm]: 'Samoa Americana',
  [GeneralKeys.Country.ata]: 'Antártida',
  [GeneralKeys.Country.atf]: 'Territorios Australes Franceses',
  [GeneralKeys.Country.atg]: 'Antigua y Barbuda',
  [GeneralKeys.Country.aze]: 'Azerbaiyán',
  [GeneralKeys.Country.bdi]: 'Burundi',
  [GeneralKeys.Country.ben]: 'Benín',
  [GeneralKeys.Country.bes]: 'Bonaire, Sint Eustatius y Saba',
  [GeneralKeys.Country.bfa]: 'Burkina Faso',
  [GeneralKeys.Country.bgd]: 'Bangladesh',
  [GeneralKeys.Country.bgr]: 'Bulgaria',
  [GeneralKeys.Country.bhr]: 'Baréin',
  [GeneralKeys.Country.bhs]: 'Bahamas',
  [GeneralKeys.Country.bih]: 'Bosnia y Herzegovina',
  [GeneralKeys.Country.blm]: 'San Bartolomé',
  [GeneralKeys.Country.blr]: 'Bielorrusia',
  [GeneralKeys.Country.blz]: 'Belice',
  [GeneralKeys.Country.bmu]: 'Bermudas',
  [GeneralKeys.Country.bol]: 'Bolivia (Estado Plurinacional de)',
  [GeneralKeys.Country.brb]: 'Barbados',
  [GeneralKeys.Country.brn]: 'Brunéi Darussalam',
  [GeneralKeys.Country.btn]: 'Bután',
  [GeneralKeys.Country.bvt]: 'Isla Bouvet',
  [GeneralKeys.Country.bwa]: 'Botsuana',
  [GeneralKeys.Country.caf]: 'República Centroafricana',
  [GeneralKeys.Country.cck]: 'Islas Cocos (Keeling)',
  [GeneralKeys.Country.chl]: 'Chile',
  [GeneralKeys.Country.civ]: 'Costa de Marfil',
  [GeneralKeys.Country.cmr]: 'Camerún',
  [GeneralKeys.Country.cod]: 'Congo (República Democrática del)',
  [GeneralKeys.Country.cog]: 'Congo',
  [GeneralKeys.Country.cok]: 'Islas Cook',
  [GeneralKeys.Country.col]: 'Colombia',
  [GeneralKeys.Country.com]: 'Comoras',
  [GeneralKeys.Country.cpv]: 'Cabo Verde',
  [GeneralKeys.Country.cri]: 'Costa Rica',
  [GeneralKeys.Country.cub]: 'Cuba',
  [GeneralKeys.Country.cuw]: 'Curazao',
  [GeneralKeys.Country.cxr]: 'Isla de Navidad',
  [GeneralKeys.Country.cym]: 'Islas Caimán',
  [GeneralKeys.Country.cyp]: 'Chipre',
  [GeneralKeys.Country.cze]: 'Chequia',
  [GeneralKeys.Country.dji]: 'Yibuti',
  [GeneralKeys.Country.dma]: 'Dominica',
  [GeneralKeys.Country.dom]: 'República Dominicana',
  [GeneralKeys.Country.dza]: 'Argelia',
  [GeneralKeys.Country.ecu]: 'Ecuador',
  [GeneralKeys.Country.egy]: 'Egipto',
  [GeneralKeys.Country.eri]: 'Eritrea',
  [GeneralKeys.Country.esh]: 'Sahara Occidental',
  [GeneralKeys.Country.est]: 'Estonia',
  [GeneralKeys.Country.eth]: 'Etiopía',
  [GeneralKeys.Country.fji]: 'Fiyi',
  [GeneralKeys.Country.flk]: 'Islas Malvinas',
  [GeneralKeys.Country.fro]: 'Islas Feroe',
  [GeneralKeys.Country.fsm]: 'Estados Federados de Micronesia',
  [GeneralKeys.Country.gab]: 'Gabón',
  [GeneralKeys.Country.geo]: 'Georgia',
  [GeneralKeys.Country.ggy]: 'Guernsey',
  [GeneralKeys.Country.gha]: 'Ghana',
  [GeneralKeys.Country.gib]: 'Gibraltar',
  [GeneralKeys.Country.gin]: 'Guinea',
  [GeneralKeys.Country.glp]: 'Guadalupe',
  [GeneralKeys.Country.gmb]: 'Gambia',
  [GeneralKeys.Country.gnb]: 'Guinea-Bissau',
  [GeneralKeys.Country.gnq]: 'Guinea Ecuatorial',
  [GeneralKeys.Country.grc]: 'Grecia',
  [GeneralKeys.Country.grd]: 'Granada',
  [GeneralKeys.Country.grl]: 'Groenlandia',
  [GeneralKeys.Country.gtm]: 'Guatemala',
  [GeneralKeys.Country.gum]: 'Guam',
  [GeneralKeys.Country.guy]: 'Guyana',
  [GeneralKeys.Country.hmd]: 'Islas Heard y McDonald',
  [GeneralKeys.Country.hnd]: 'Honduras',
  [GeneralKeys.Country.hrv]: 'Croacia',
  [GeneralKeys.Country.hti]: 'Haití',
  [GeneralKeys.Country.hun]: 'Hungría',
  [GeneralKeys.Country.imn]: 'Isla de Man',
  [GeneralKeys.Country.iot]: 'Territorio Británico del Océano Índico',
  [GeneralKeys.Country.irl]: 'Irlanda',
  [GeneralKeys.Country.irq]: 'Irak',
  [GeneralKeys.Country.isl]: 'Islandia',
  [GeneralKeys.Country.jam]: 'Jamaica',
  [GeneralKeys.Country.jey]: 'Jersey',
  [GeneralKeys.Country.jor]: 'Jordania',
  [GeneralKeys.Country.kaz]: 'Kazajistán',
  [GeneralKeys.Country.ken]: 'Kenia',
  [GeneralKeys.Country.kgz]: 'Kirguistán',
  [GeneralKeys.Country.khm]: 'Camboya',
  [GeneralKeys.Country.kir]: 'Kiribati',
  [GeneralKeys.Country.kna]: 'San Cristóbal y Nieves',
  [GeneralKeys.Country.kwt]: 'Kuwait',
  [GeneralKeys.Country.lao]: 'República Democrática Popular Lao',
  [GeneralKeys.Country.lbn]: 'Líbano',
  [GeneralKeys.Country.lbr]: 'Liberia',
  [GeneralKeys.Country.lby]: 'Libia',
  [GeneralKeys.Country.lca]: 'Santa Lucía',
  [GeneralKeys.Country.lie]: 'Liechtenstein',
  [GeneralKeys.Country.lka]: 'Sri Lanka',
  [GeneralKeys.Country.lso]: 'Lesoto',
  [GeneralKeys.Country.ltu]: 'Lituania',
  [GeneralKeys.Country.lux]: 'Luxemburgo',
  [GeneralKeys.Country.lva]: 'Letonia',
  [GeneralKeys.Country.mac]: 'Macao',
  [GeneralKeys.Country.maf]: 'San Martín (parte francesa)',
  [GeneralKeys.Country.mar]: 'Marruecos',
  [GeneralKeys.Country.mco]: 'Mónaco',
  [GeneralKeys.Country.mda]: 'Moldova (República de)',
  [GeneralKeys.Country.mdg]: 'Madagascar',
  [GeneralKeys.Country.mdv]: 'Maldivas',
  [GeneralKeys.Country.mhl]: 'Islas Marshall',
  [GeneralKeys.Country.mkd]: 'República de Macedonia del Norte',
  [GeneralKeys.Country.mli]: 'Malí',
  [GeneralKeys.Country.mlt]: 'Malta',
  [GeneralKeys.Country.mmr]: 'Myanmar',
  [GeneralKeys.Country.mne]: 'Montenegro',
  [GeneralKeys.Country.mng]: 'Mongolia',
  [GeneralKeys.Country.mnp]: 'Islas Marianas del Norte',
  [GeneralKeys.Country.moz]: 'Mozambique',
  [GeneralKeys.Country.mrt]: 'Mauritania',
  [GeneralKeys.Country.msr]: 'Montserrat',
  [GeneralKeys.Country.mtq]: 'Martinica',
  [GeneralKeys.Country.mus]: 'Mauricio',
  [GeneralKeys.Country.mwi]: 'Malaui',
  [GeneralKeys.Country.myt]: 'Mayotte',
  [GeneralKeys.Country.nam]: 'Namibia',
  [GeneralKeys.Country.ncl]: 'Nueva Caledonia',
  [GeneralKeys.Country.ner]: 'Níger',
  [GeneralKeys.Country.nfk]: 'Isla Norfolk',
  [GeneralKeys.Country.nga]: 'Nigeria',
  [GeneralKeys.Country.nic]: 'Nicaragua',
  [GeneralKeys.Country.niu]: 'Niue',
  [GeneralKeys.Country.npl]: 'Nepal',
  [GeneralKeys.Country.nru]: 'Nauru',
  [GeneralKeys.Country.omn]: 'Omán',
  [GeneralKeys.Country.pan]: 'Panamá',
  [GeneralKeys.Country.pcn]: 'Islas Pitcairn',
  [GeneralKeys.Country.per]: 'Perú',
  [GeneralKeys.Country.plw]: 'Palaos',
  [GeneralKeys.Country.png]: 'Papúa Nueva Guinea',
  [GeneralKeys.Country.pri]: 'Puerto Rico',
  [GeneralKeys.Country.prk]: 'Corea (República Democrática Popular de)',
  [GeneralKeys.Country.pry]: 'Paraguay',
  [GeneralKeys.Country.pse]: 'Palestina, Estado de',
  [GeneralKeys.Country.qat]: 'Catar',
  [GeneralKeys.Country.reu]: 'Reunión',
  [GeneralKeys.Country.rou]: 'Rumania',
  [GeneralKeys.Country.rus]: 'Rusia',
  [GeneralKeys.Country.rwa]: 'Ruanda',
  [GeneralKeys.Country.sdn]: 'Sudán',
  [GeneralKeys.Country.sen]: 'Senegal',
  [GeneralKeys.Country.sgs]: 'Islas Georgias del Sur y Sandwich del Sur',
  [GeneralKeys.Country.shn]: 'Santa Elena, Ascensión y Tristán de Acuña',
  [GeneralKeys.Country.sjm]: 'Svalbard y Jan Mayen',
  [GeneralKeys.Country.slb]: 'Islas Salomón',
  [GeneralKeys.Country.sle]: 'Sierra Leona',
  [GeneralKeys.Country.slv]: 'El Salvador',
  [GeneralKeys.Country.smr]: 'San Marino',
  [GeneralKeys.Country.som]: 'Somalia',
  [GeneralKeys.Country.spm]: 'San Pedro y Miquelón',
  [GeneralKeys.Country.srb]: 'Serbia',
  [GeneralKeys.Country.ssd]: 'Sudán del Sur',
  [GeneralKeys.Country.stp]: 'Santo Tomé y Príncipe',
  [GeneralKeys.Country.sur]: 'Surinam',
  [GeneralKeys.Country.svk]: 'Eslovaquia',
  [GeneralKeys.Country.svn]: 'Eslovenia',
  [GeneralKeys.Country.swz]: 'Esuatini',
  [GeneralKeys.Country.sxm]: 'Sint Maarten',
  [GeneralKeys.Country.syc]: 'Seychelles',
  [GeneralKeys.Country.syr]: 'República Árabe Siria',
  [GeneralKeys.Country.tca]: 'Islas Turcas y Caicos',
  [GeneralKeys.Country.tcd]: 'Chad',
  [GeneralKeys.Country.tgo]: 'Togo',
  [GeneralKeys.Country.tjk]: 'Tayikistán',
  [GeneralKeys.Country.tkl]: 'Tokelau',
  [GeneralKeys.Country.tkm]: 'Turkmenistán',
  [GeneralKeys.Country.tls]: 'Timor Oriental',
  [GeneralKeys.Country.ton]: 'Tonga',
  [GeneralKeys.Country.tto]: 'Trinidad y Tobago',
  [GeneralKeys.Country.tun]: 'Túnez',
  [GeneralKeys.Country.tuv]: 'Tuvalu',
  [GeneralKeys.Country.tza]: 'Tanzania, República Unida de',
  [GeneralKeys.Country.uga]: 'Uganda',
  [GeneralKeys.Country.ukr]: 'Ucrania',
  [GeneralKeys.Country.umi]: 'Islas Ultramarinas Menores de Estados Unidos',
  [GeneralKeys.Country.ury]: 'Uruguay',
  [GeneralKeys.Country.uzb]: 'Uzbekistán',
  [GeneralKeys.Country.vat]: 'Santa Sede',
  [GeneralKeys.Country.vct]: 'San Vicente y las Granadinas',
  [GeneralKeys.Country.ven]: 'Venezuela (República Bolivariana de)',
  [GeneralKeys.Country.vgb]: 'Islas Vírgenes Británicas',
  [GeneralKeys.Country.vir]: 'Islas Vírgenes de los Estados Unidos',
  [GeneralKeys.Country.vut]: 'Vanuatu',
  [GeneralKeys.Country.wlf]: 'Wallis y Futuna',
  [GeneralKeys.Country.wsm]: 'Samoa',
  [GeneralKeys.Country.yem]: 'Yemen',
  [GeneralKeys.Country.zaf]: 'Sudáfrica',
  [GeneralKeys.Country.zmb]: 'Zambia',
  [GeneralKeys.Country.zwe]: 'Zimbabue',

  // 2-Char
  [GeneralKeys.Country.ae]: 'Emiratos Árabes Unidos',
  [GeneralKeys.Country.ar]: 'Argentina',
  [GeneralKeys.Country.au]: 'Australia',
  [GeneralKeys.Country.at]: 'Austria',
  [GeneralKeys.Country.be]: 'Bélgica',
  [GeneralKeys.Country.br]: 'Brasil',
  [GeneralKeys.Country.ca]: 'Canadá',
  [GeneralKeys.Country.ch]: 'Suiza',
  [GeneralKeys.Country.cn]: 'China',
  [GeneralKeys.Country.de]: 'Alemania',
  [GeneralKeys.Country.dk]: 'Dinamarca',
  [GeneralKeys.Country.eeu]: 'Europa',
  [GeneralKeys.Country.es]: 'España',
  [GeneralKeys.Country.fi]: 'Finlandia',
  [GeneralKeys.Country.fr]: 'Francia',
  [GeneralKeys.Country.gb]: 'Reino Unido',
  [GeneralKeys.Country.glb]: 'Global',
  [GeneralKeys.Country.hk]: 'Hong Kong',
  [GeneralKeys.Country.id]: 'Indonesia',
  [GeneralKeys.Country.in]: 'India',
  [GeneralKeys.Country.ir]: 'Irán',
  [GeneralKeys.Country.il]: 'Israel',
  [GeneralKeys.Country.it]: 'Italia',
  [GeneralKeys.Country.jp]: 'Japón',
  [GeneralKeys.Country.kr]: 'Corea del Sur',
  [GeneralKeys.Country.mx]: 'México',
  [GeneralKeys.Country.my]: 'Malasia',
  [GeneralKeys.Country.nl]: 'Países Bajos',
  [GeneralKeys.Country.no]: 'Noruega',
  [GeneralKeys.Country.nz]: 'Nueva Zelanda',
  [GeneralKeys.Country.pk]: 'Pakistán',
  [GeneralKeys.Country.ph]: 'Filipinas',
  [GeneralKeys.Country.pl]: 'Polonia',
  [GeneralKeys.Country.pt]: 'Portugal',
  [GeneralKeys.Country.sa]: 'Arabia Saudita',
  [GeneralKeys.Country.sg]: 'Singapur',
  [GeneralKeys.Country.se]: 'Suecia',
  [GeneralKeys.Country.th]: 'Tailandia',
  [GeneralKeys.Country.tr]: 'Turquía',
  [GeneralKeys.Country.tw]: 'Taiwán',
  [GeneralKeys.Country.us]: 'Estados Unidos',
  [GeneralKeys.Country.vn]: 'Vietnam',

  // Client
  [GeneralKeys.Country.aw]: 'Aruba',
  [GeneralKeys.Country.af]: 'Afganistán',
  [GeneralKeys.Country.ao]: 'Angola',
  [GeneralKeys.Country.ai]: 'Anguila',
  [GeneralKeys.Country.ax]: 'Islas Åland',
  [GeneralKeys.Country.al]: 'Albania',
  [GeneralKeys.Country.ad]: 'Andorra',
  [GeneralKeys.Country.am]: 'Armenia',
  [GeneralKeys.Country.as]: 'Samoa Americana',
  [GeneralKeys.Country.aq]: 'Antártida',
  [GeneralKeys.Country.tf]: 'Territorios Australes Franceses',
  [GeneralKeys.Country.ag]: 'Antigua y Barbuda',
  [GeneralKeys.Country.az]: 'Azerbaiyán',
  [GeneralKeys.Country.bi]: 'Burundi',
  [GeneralKeys.Country.bj]: 'Benín',
  [GeneralKeys.Country.bq]: 'Bonaire, Sint Eustatius y Saba',
  [GeneralKeys.Country.bf]: 'Burkina Faso',
  [GeneralKeys.Country.bd]: 'Bangladesh',
  [GeneralKeys.Country.bg]: 'Bulgaria',
  [GeneralKeys.Country.bh]: 'Baréin',
  [GeneralKeys.Country.bs]: 'Bahamas',
  [GeneralKeys.Country.ba]: 'Bosnia y Herzegovina',
  [GeneralKeys.Country.bl]: 'San Bartolomé',
  [GeneralKeys.Country.by]: 'Bielorrusia',
  [GeneralKeys.Country.bz]: 'Belice',
  [GeneralKeys.Country.bm]: 'Bermudas',
  [GeneralKeys.Country.bo]: 'Bolivia (Estado Plurinacional de)',
  [GeneralKeys.Country.bb]: 'Barbados',
  [GeneralKeys.Country.bn]: 'Brunéi Darussalam',
  [GeneralKeys.Country.bt]: 'Bután',
  [GeneralKeys.Country.bv]: 'Isla Bouvet',
  [GeneralKeys.Country.bw]: 'Botsuana',
  [GeneralKeys.Country.cf]: 'República Centroafricana',
  [GeneralKeys.Country.cc]: 'Islas Cocos (Keeling)',
  [GeneralKeys.Country.cl]: 'Chile',
  [GeneralKeys.Country.ci]: "Côte d'Ivoire",
  [GeneralKeys.Country.cm]: 'Camerún',
  [GeneralKeys.Country.cd]: 'Congo (República Democrática del)',
  [GeneralKeys.Country.cg]: 'Congo',
  [GeneralKeys.Country.ck]: 'Islas Cook',
  [GeneralKeys.Country.co]: 'Colombia',
  [GeneralKeys.Country.km]: 'Comoras',
  [GeneralKeys.Country.cv]: 'Cabo Verde',
  [GeneralKeys.Country.cr]: 'Costa Rica',
  [GeneralKeys.Country.cu]: 'Cuba',
  [GeneralKeys.Country.cw]: 'Curazao',
  [GeneralKeys.Country.cx]: 'Isla de Navidad',
  [GeneralKeys.Country.ky]: 'Islas Caimán',
  [GeneralKeys.Country.cy]: 'Chipre',
  [GeneralKeys.Country.cz]: 'Chequia',
  [GeneralKeys.Country.dj]: 'Yibuti',
  [GeneralKeys.Country.dm]: 'Dominica',
  [GeneralKeys.Country.do]: 'República Dominicana',
  [GeneralKeys.Country.dz]: 'Argelia',
  [GeneralKeys.Country.ec]: 'Ecuador',
  [GeneralKeys.Country.eg]: 'Egipto',
  [GeneralKeys.Country.er]: 'Eritrea',
  [GeneralKeys.Country.eh]: 'Sáhara Occidental',
  [GeneralKeys.Country.ee]: 'Estonia',
  [GeneralKeys.Country.et]: 'Etiopía',
  [GeneralKeys.Country.fj]: 'Fiyi',
  [GeneralKeys.Country.fk]: 'Islas Malvinas',
  [GeneralKeys.Country.fo]: 'Islas Feroe',
  [GeneralKeys.Country.fm]: 'Micronesia (Estados Federados de)',
  [GeneralKeys.Country.ga]: 'Gabón',
  [GeneralKeys.Country.ge]: 'Georgia',
  [GeneralKeys.Country.gg]: 'Guernsey',
  [GeneralKeys.Country.gh]: 'Ghana',
  [GeneralKeys.Country.gi]: 'Gibraltar',
  [GeneralKeys.Country.gn]: 'Guinea',
  [GeneralKeys.Country.gp]: 'Guadalupe',
  [GeneralKeys.Country.gm]: 'Gambia',
  [GeneralKeys.Country.gw]: 'Guinea-Bissau',
  [GeneralKeys.Country.gq]: 'Guinea Ecuatorial',
  [GeneralKeys.Country.gr]: 'Grecia',
  [GeneralKeys.Country.gd]: 'Granada',
  [GeneralKeys.Country.gl]: 'Groenlandia',
  [GeneralKeys.Country.gt]: 'Guatemala',
  [GeneralKeys.Country.gu]: 'Guam',
  [GeneralKeys.Country.gy]: 'Guyana',
  [GeneralKeys.Country.hm]: 'Islas Heard y McDonald',
  [GeneralKeys.Country.hn]: 'Honduras',
  [GeneralKeys.Country.hr]: 'Croacia',
  [GeneralKeys.Country.ht]: 'Haití',
  [GeneralKeys.Country.hu]: 'Hungría',
  [GeneralKeys.Country.im]: 'Isla de Man',
  [GeneralKeys.Country.io]: 'Territorio Británico del Océano Índico',
  [GeneralKeys.Country.ie]: 'Irlanda',
  [GeneralKeys.Country.iq]: 'Irak',
  [GeneralKeys.Country.is]: 'Islandia',
  [GeneralKeys.Country.jm]: 'Jamaica',
  [GeneralKeys.Country.je]: 'Jersey',
  [GeneralKeys.Country.jo]: 'Jordania',
  [GeneralKeys.Country.kz]: 'Kazajistán',
  [GeneralKeys.Country.ke]: 'Kenia',
  [GeneralKeys.Country.kg]: 'Kirguistán',
  [GeneralKeys.Country.kh]: 'Camboya',
  [GeneralKeys.Country.ki]: 'Kiribati',
  [GeneralKeys.Country.kn]: 'San Cristóbal y Nieves',
  [GeneralKeys.Country.kw]: 'Kuwait',
  [GeneralKeys.Country.la]: 'República Democrática Popular Lao',
  [GeneralKeys.Country.lb]: 'Líbano',
  [GeneralKeys.Country.lr]: 'Liberia',
  [GeneralKeys.Country.ly]: 'Libia',
  [GeneralKeys.Country.lc]: 'Santa Lucía',
  [GeneralKeys.Country.li]: 'Liechtenstein',
  [GeneralKeys.Country.lk]: 'Sri Lanka',
  [GeneralKeys.Country.ls]: 'Lesoto',
  [GeneralKeys.Country.lt]: 'Lituania',
  [GeneralKeys.Country.lu]: 'Luxemburgo',
  [GeneralKeys.Country.lv]: 'Letonia',
  [GeneralKeys.Country.mo]: 'Macao',
  [GeneralKeys.Country.mf]: 'San Martín (parte francesa)',
  [GeneralKeys.Country.ma]: 'Marruecos',
  [GeneralKeys.Country.mc]: 'Mónaco',
  [GeneralKeys.Country.md]: 'Moldavia (República de)',
  [GeneralKeys.Country.mg]: 'Madagascar',
  [GeneralKeys.Country.mv]: 'Maldivas',
  [GeneralKeys.Country.mh]: 'Islas Marshall',
  [GeneralKeys.Country.mk]: 'República de Macedonia del Norte',
  [GeneralKeys.Country.ml]: 'Malí',
  [GeneralKeys.Country.mt]: 'Malta',
  [GeneralKeys.Country.mm]: 'Myanmar',
  [GeneralKeys.Country.me]: 'Montenegro',
  [GeneralKeys.Country.mn]: 'Mongolia',
  [GeneralKeys.Country.mp]: 'Islas Marianas del Norte',
  [GeneralKeys.Country.mz]: 'Mozambique',
  [GeneralKeys.Country.mr]: 'Mauritania',
  [GeneralKeys.Country.ms]: 'Montserrat',
  [GeneralKeys.Country.mq]: 'Martinica',
  [GeneralKeys.Country.mu]: 'Mauricio',
  [GeneralKeys.Country.mw]: 'Malaui',
  [GeneralKeys.Country.yt]: 'Mayotte',
  [GeneralKeys.Country.na]: 'Namibia',
  [GeneralKeys.Country.nc]: 'Nueva Caledonia',
  [GeneralKeys.Country.ne]: 'Níger',
  [GeneralKeys.Country.nf]: 'Isla Norfolk',
  [GeneralKeys.Country.ng]: 'Nigeria',
  [GeneralKeys.Country.ni]: 'Nicaragua',
  [GeneralKeys.Country.nu]: 'Niue',
  [GeneralKeys.Country.np]: 'Nepal',
  [GeneralKeys.Country.nr]: 'Nauru',
  [GeneralKeys.Country.om]: 'Omán',
  [GeneralKeys.Country.pa]: 'Panamá',
  [GeneralKeys.Country.pn]: 'Islas Pitcairn',
  [GeneralKeys.Country.pe]: 'Perú',
  [GeneralKeys.Country.pw]: 'Palaos',
  [GeneralKeys.Country.pg]: 'Papúa Nueva Guinea',
  [GeneralKeys.Country.pr]: 'Puerto Rico',
  [GeneralKeys.Country.kp]: 'Corea (República Democrática Popular de)',
  [GeneralKeys.Country.py]: 'Paraguay',
  [GeneralKeys.Country.ps]: 'Palestina, Estado de',
  [GeneralKeys.Country.qa]: 'Catar',
  [GeneralKeys.Country.re]: 'Reunión',
  [GeneralKeys.Country.ro]: 'Rumania',
  [GeneralKeys.Country.ru]: 'Rusia',
  [GeneralKeys.Country.rw]: 'Ruanda',
  [GeneralKeys.Country.sd]: 'Sudán',
  [GeneralKeys.Country.sn]: 'Senegal',
  [GeneralKeys.Country.gs]: 'Islas Georgia del Sur y Sandwich del Sur',
  [GeneralKeys.Country.sh]: 'Santa Elena, Ascensión y Tristán de Acuña',
  [GeneralKeys.Country.sj]: 'Svalbard y Jan Mayen',
  [GeneralKeys.Country.sb]: 'Islas Salomón',
  [GeneralKeys.Country.sl]: 'Sierra Leona',
  [GeneralKeys.Country.sv]: 'El Salvador',
  [GeneralKeys.Country.sm]: 'San Marino',
  [GeneralKeys.Country.so]: 'Somalia',
  [GeneralKeys.Country.pm]: 'San Pedro y Miquelón',
  [GeneralKeys.Country.rs]: 'Serbia',
  [GeneralKeys.Country.ss]: 'Sudán del Sur',
  [GeneralKeys.Country.st]: 'Santo Tomé y Príncipe',
  [GeneralKeys.Country.sr]: 'Surinam',
  [GeneralKeys.Country.sk]: 'Eslovaquia',
  [GeneralKeys.Country.si]: 'Eslovenia',
  [GeneralKeys.Country.sz]: 'Esuatini',
  [GeneralKeys.Country.sx]: 'Sint Maarten',
  [GeneralKeys.Country.sc]: 'Seychelles',
  [GeneralKeys.Country.sy]: 'República Árabe Siria',
  [GeneralKeys.Country.tc]: 'Islas Turcas y Caicos',
  [GeneralKeys.Country.td]: 'Chad',
  [GeneralKeys.Country.tg]: 'Togo',
  [GeneralKeys.Country.tj]: 'Tayikistán',
  [GeneralKeys.Country.tk]: 'Tokelau',
  [GeneralKeys.Country.tm]: 'Turkmenistán',
  [GeneralKeys.Country.tl]: 'Timor Oriental',
  [GeneralKeys.Country.to]: 'Tonga',
  [GeneralKeys.Country.tt]: 'Trinidad y Tobago',
  [GeneralKeys.Country.tn]: 'Túnez',
  [GeneralKeys.Country.tv]: 'Tuvalu',
  [GeneralKeys.Country.tz]: 'República Unida de Tanzania',
  [GeneralKeys.Country.ug]: 'Uganda',
  [GeneralKeys.Country.ua]: 'Ucrania',
  [GeneralKeys.Country.um]: 'Islas menores alejadas de los Estados Unidos',
  [GeneralKeys.Country.uy]: 'Uruguay',
  [GeneralKeys.Country.uz]: 'Uzbekistán',
  [GeneralKeys.Country.va]: 'Santa Sede',
  [GeneralKeys.Country.vc]: 'San Vicente y las Granadinas',
  [GeneralKeys.Country.ve]: 'Venezuela (República Bolivariana de)',
  [GeneralKeys.Country.vg]: 'Islas Vírgenes Británicas',
  [GeneralKeys.Country.vi]: 'Islas Vírgenes de los Estados Unidos',
  [GeneralKeys.Country.vu]: 'Vanuatu',
  [GeneralKeys.Country.wf]: 'Wallis y Futuna',
  [GeneralKeys.Country.ws]: 'Samoa',
  [GeneralKeys.Country.ye]: 'Yemen',
  [GeneralKeys.Country.za]: 'Sudáfrica',
  [GeneralKeys.Country.zm]: 'Zambia',
  [GeneralKeys.Country.zw]: 'Zimbabue',
};
