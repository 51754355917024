export const FolderIcon = ({ size = 1, width, height, ...rest }) => (
  <svg
    width={width ?? 45 * size}
    height={height ?? 33 * size}
    viewBox="0 0 45 33"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M43.2188 13.1133H37.8398V7.45547C37.8398 6.57393 37.1276 5.86172 36.2461 5.86172H20.5576L14.7155 0.273633C14.6412 0.204044 14.5434 0.164936 14.4416 0.164062H1.78125C0.899707 0.164062 0.1875 0.87627 0.1875 1.75781V31.2422C0.1875 32.1237 0.899707 32.8359 1.78125 32.8359H36.5449C37.1924 32.8359 37.7801 32.4425 38.0241 31.8398L44.6979 15.3047C44.7727 15.1154 44.8125 14.9112 44.8125 14.707C44.8125 13.8255 44.1003 13.1133 43.2188 13.1133ZM3.77344 3.75H13.1616L19.1183 9.44766H34.2539V13.1133H8.85352C8.20605 13.1133 7.61836 13.5067 7.37432 14.1094L3.77344 23.0344V3.75ZM35.4144 29.25H4.91895L10.0638 16.5H40.5642L35.4144 29.25Z" />
  </svg>
);
