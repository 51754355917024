import styled from 'styled-components';
import Variant from '../packagesCardVariant';
import useUser from 'hooks/useUser';
import { usePackages } from 'contexts/PackagesContext';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { useTranslation } from 'react-i18next';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';

const Button = ({ variant }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userId } = usePackages();
  const { user } = useUser();

  const isMine = user?.id === userId;

  const toEditProfile = useCallback(() => {
    navigate(`/profile-edit`);
  }, [navigate]);

  const handleClick = () => {
    const event = new CustomEvent('package-order-button-click', {
      detail: {
        event: true,
      },
    });
    window.dispatchEvent(event);
  };

  if (isMine)
    return (
      <Btn
        variant={variant}
        onClick={variant === Variant.Preview ? null : toEditProfile}
      >
        {t(ProfileKeys.Package.edit)}
      </Btn>
    );

  return (
    <Btn variant={variant} onClick={handleClick}>
      {t(PricingKeys.order_now)}
    </Btn>
  );
};

export default Button;

const Btn = styled.button.attrs({
  className: 'sbh5',
})`
  flex-shrink: 0;
  width: max-content;
  border: none;
  background: ${({ variant }) =>
    ({
      [Variant.Modal]: 'var(--color-indigo-500)',
      [Variant.Profile]: 'var(--color-yellow-400)',
      [Variant.Preview]: 'var(--color-yellow-400)',
    }[variant])};
  color: ${({ variant }) =>
    ({
      [Variant.Modal]: 'var(--color-white)',
      [Variant.Profile]: 'var(--color-indigo-500)',
      [Variant.Preview]: 'var(--color-indigo-500)',
    }[variant])};
  padding: 9px 22px;
  border-radius: 9999px;
  box-shadow: var(--shadow-yellow-btn);
  transition: all 150ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;

  &:hover {
    background: ${({ variant }) =>
      ({
        [Variant.Modal]: 'var(--color-indigo-300)',
        [Variant.Profile]: 'var(--color-yellow-300)',
      }[variant])};
  }

  &:not(:disabled):active {
    background: ${({ variant }) =>
      ({
        [Variant.Modal]: 'var(--color-indigo-300)',
        [Variant.Profile]: 'var(--color-yellow-300)',
      }[variant])};
    box-shadow: none;
  }

  &:disabled {
    background: ${({ variant }) =>
      ({
        [Variant.Modal]: 'var(--color-indigo-50)',
        [Variant.Profile]: 'var(--color-yellow-50)',
      }[variant])};
  }
`;
