import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import AxiosClient from 'components/utilities/AxiosClient';
import PasswordInput from './PasswordInput';
import Popup from 'components/Basics/Popup';
import Stack from 'components/Basics/Layout/Stack';
import Text from 'components/Basics/Typography/Text';
import Heading from 'components/Basics/Typography/Heading';
import { useTranslation } from 'react-i18next';
import withAuthHOC from './withAuthHOC';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useToast } from 'components/Basics/Toast';
import useUser from 'hooks/useUser';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import SettingsKeys from 'translations/translationKeys/SettingsPageKeys';

const List = styled.ul.attrs({
  className: 'text-md text-indigo-200',
})`
  list-style-type: '\\00B7   ';
  text-align: left;
  padding-left: 1.5rem;
  margin-bottom: 0;
`;

const ResetPasswordModal = ({ flowManager, id, token }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  /* States */
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [verifyPasswordError, setVerifyPasswordError] = useState('');
  const { closeModal } = flowManager;
  const { refetch } = useUser();
  //toast
  const toast = useToast();
  /* Functions */
  const handleClose = () => {
    navigate('/', {
      state: {
        message: t(LoginKeys.Modal.Reset_Password.expired),
        success: false,
        btnType: 1,
        id: id,
        token: token,
        buttonText: t(LoginKeys.Modal.Reset_Password.reset),
      },
    });
  };

  const validatePassword = (password) => {
    var re =
      /^(?=.*[0-9!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,20}$/;
    return re.test(password);
  };

  const updatePassword = async () => {
    setVerifyPasswordError('');
    setErrorMessage('');
    if (password !== verifyPassword) {
      setVerifyPasswordError(SettingsKeys.Password.Error.not_match);
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage(LoginKeys.Modal.Reset_Password.Error.valid_password);
      return;
    }

    await AxiosClient.post('/updatePassword', {
      id: id,
      token: token,
      password: password,
    })
      .then(async (res) => {
        localStorage.setItem('user', JSON.stringify({ id: id }));
        navigate('/', {
          state: {
            message: t(LoginKeys.Modal.Reset_Password.update_success),
            success: true,
            btnType: 0,
            id: id,
            token: token,
            buttonText: t(GeneralKeys.continue),
          },
        });
        await toast.success(t(SettingsKeys.Password.update_success));
        await refetch();
      })
      .catch((error) => {
        setErrorMessage(LoginKeys.Error.password_used);
        return;
      });
  };

  const { isMobile } = useResponsiveScreen();

  return (
    <Popup
      show={true}
      onClose={handleClose}
      title={t(LoginKeys.Modal.Reset_Password.reset)}
      buttonText={t(GeneralKeys.submit)}
      onButtonClick={updatePassword}
      secondaryButtonText={t(GeneralKeys.cancel)}
      onSecondaryButtonClick={handleClose}
      asPage={isMobile}
    >
      <Stack gap="2.75rem" padding="0 4.5rem" className="hidden md-flex">
        <div>
          <Text variant="md" className="text-indigo-200 text-start">
            {t(LoginKeys.Modal.Reset_Password.description)}
            <br />
            <br />
            {t(SettingsKeys.Password.Rule.title)}
          </Text>
          <List>
            <li>{t(SettingsKeys.Password.Rule.rule_1)}</li>
            <li>{t(SettingsKeys.Password.Rule.rule_2)}</li>
            <li>{t(SettingsKeys.Password.Rule.rule_3)}</li>
            <li>{t(SettingsKeys.Password.Rule.rule_4)}</li>
          </List>
        </div>
        <Stack>
          <Heading variant="BH6" className="text-start">
            {t(SettingsKeys.Password.new_password)}
          </Heading>
          <PasswordInput
            variant="transparentGray"
            className="h6"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ padding: '0.875rem 1rem', width: '100%' }}
            error={t(errorMessage)}
          />
        </Stack>
        <Stack>
          <Heading variant="BH6" className="text-start">
            {t(SettingsKeys.Password.confirm_new_password)}
          </Heading>
          <PasswordInput
            variant="transparentGray"
            className="h6"
            value={verifyPassword}
            onChange={(e) => setVerifyPassword(e.target.value)}
            style={{ padding: '0.875rem 1rem', width: '100%' }}
            error={t(verifyPasswordError)}
          />
        </Stack>
      </Stack>

      {/* Mobile version */}
      <Stack gap="1.25rem" padding="0" className="md-hidden">
        <div>
          <Text variant="sm" className="text-indigo-200 text-center">
            {t(LoginKeys.Modal.Reset_Password.description)}
            <br />
            <br />
            {t(SettingsKeys.Password.Rule.title)}
            <br />
          </Text>
          <List style={{ fontSize: '14px', marginTop: '12px' }}>
            <li>{t(SettingsKeys.Password.Rule.rule_1)}</li>
            <li>{t(SettingsKeys.Password.Rule.rule_2)}</li>
            <li>{t(SettingsKeys.Password.Rule.rule_3)}</li>
            <li>{t(SettingsKeys.Password.Rule.rule_4)}</li>
          </List>
        </div>
        <Stack gap="1.75rem" margin="0 0 10px 0">
          <PasswordInput
            value={password}
            placeholder={t(SettingsKeys.Password.new_password)}
            variant="lightGray"
            borderWidth="1px"
            radius={'999px'}
            fontSize="14px"
            padding="10.625px 24px"
            width="100%"
            onChange={(e) => setPassword(e.target.value)}
            error={t(errorMessage)}
          />
          <PasswordInput
            variant="lightGray"
            value={verifyPassword}
            placeholder={t(SettingsKeys.Password.confirm_new_password)}
            radius={'999px'}
            borderWidth="1px"
            fontSize="14px"
            padding="10.625px 24px"
            width="100%"
            onChange={(e) => setVerifyPassword(e.target.value)}
            error={t(verifyPasswordError)}
          />
        </Stack>
      </Stack>
    </Popup>
  );
};

export default withAuthHOC(ResetPasswordModal);
