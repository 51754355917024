import { ChevronLeftIcon, ChevronRightIcon } from 'assets/icons';
import { usePackages } from 'contexts/PackagesContext';
import { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import styled from 'styled-components';
import FeaturedImage from './FeaturedImage';

const TRANSITION_SPEED = 300;

const ContentCarousal = ({
  contents,
  itemWidth,
  itemHeight,
  onInternalRefChange,
  featuredImageAction,
  Item,
}) => {
  const { currentPackage } = usePackages();

  const numContents = contents.length;

  const [scrollTransition, setScrollTransition] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(numContents);

  const ref = useRef(null);

  useEffect(() => {
    setScrollPosition(numContents);
  }, [numContents]);

  const contentsToShow = useMemo(
    () => contents.concat(contents).concat(contents),
    [contents]
  );

  const next = useCallback(() => {
    if (!ref.current) return;

    const newScrollPosition = scrollPosition + 1;
    const maxScrollPosition = numContents * 2;

    const scrolledAhead = newScrollPosition - maxScrollPosition;

    if (scrolledAhead >= 0) {
      const behindMax = maxScrollPosition - scrollPosition;

      const MinScrollPosition = numContents;
      const jumpTo = MinScrollPosition - behindMax;

      setScrollTransition(false);
      setScrollPosition(jumpTo);

      setTimeout(() => {
        setScrollTransition(true);
        setScrollPosition(jumpTo + 1);
      }, 1);
    } else {
      setScrollPosition((scrollPosition) => scrollPosition + 1);
    }
  }, [scrollPosition, setScrollTransition, setScrollPosition, numContents]);

  const prev = useCallback(() => {
    if (!ref.current) return;

    const newScrollPosition = scrollPosition - 1;
    const minScrollPosition = numContents;

    const scrolledBehind = newScrollPosition - minScrollPosition;

    if (scrolledBehind < 0) {
      const behindMin = minScrollPosition - scrollPosition;

      const MaxScrollPosition = numContents * 2;
      const jumpTo = MaxScrollPosition - behindMin;

      setScrollTransition(false);
      setScrollPosition(jumpTo);

      setTimeout(() => {
        setScrollTransition(true);
        setScrollPosition(jumpTo - 1);
      }, 1);
    } else {
      setScrollPosition((scrollPosition) => scrollPosition - 1);
    }
  }, [scrollPosition, setScrollTransition, setScrollPosition, numContents]);

  return (
    <Wrapper ref={ref} itemWidth={itemWidth} itemHeight={itemHeight}>
      <PrevButton onClick={prev}>
        <ChevronLeftIcon size={0.82} />
      </PrevButton>

      <Items
        scrollTransition={scrollTransition}
        scrollPosition={scrollPosition * itemWidth}
      >
        {contentsToShow.map((contentId, index) => {
          if (contentId === 'featuredContent') {
            if (!currentPackage.featuredContent) return null;

            return (
              <FeaturedImage
                onClick={featuredImageAction}
                onRefChange={onInternalRefChange}
              />
            );
          }

          return (
            <Item
              key={contentId + index}
              contentId={contentId}
              inView={index === scrollPosition}
              onInternalRefChange={onInternalRefChange}
            />
          );
        })}
      </Items>

      <NextButton onClick={next}>
        <ChevronRightIcon size={0.82} />
      </NextButton>
    </Wrapper>
  );
};

export default ContentCarousal;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 30px;
  border: none;
  background-color: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  opacity: 0;
  transition: all 150ms ease-in-out;

  &:hover {
    opacity: 0.9;
  }

  &:focus {
    outline: none;
  }
`;

const Wrapper = styled.div`
  width: ${({ itemWidth }) => itemWidth}px;
  height: ${({ itemHeight }) => itemHeight}px;
  position: relative;
  overflow-x: hidden;

  &:hover ${Button} {
    opacity: 1;
  }
`;

const Items = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  position: relative;

  transition: ${({ scrollTransition }) =>
    scrollTransition ? `transform ${TRANSITION_SPEED}ms ease-in-out` : 'none'};
  transform: ${({ scrollPosition }) => `translateX(-${scrollPosition}px)`};
`;

const PrevButton = styled(Button)`
  left: 0;
  border-radius: 0 50px 50px 0;
  padding: 0 5px 0 0;
`;

const NextButton = styled(Button)`
  right: 0;
  border-radius: 50px 0 0 50px;
  padding: 0 0 0 5px;
`;
