import styled from 'styled-components';
import File from './File';
import { AnimatePresence, motion } from 'framer-motion';
import device from 'styles/device';
import { useFormContext } from 'react-hook-form';

const Files = ({ name, onRemove }) => {
  const { watch } = useFormContext();

  return (
    <Wrapper>
      <AnimatePresence>
        {watch(name)?.map((file) => (
          <File key={file.id} file={file} name={name} onRemove={onRemove} />
        ))}
      </AnimatePresence>
    </Wrapper>
  );
};

export default Files;

const Wrapper = styled(motion.div).attrs({
  layout: true,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    duration: 0.15,
  },
})`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.75rem 1.25rem;
  overflow: hidden;

  @media (${device.sm}) {
    flex-wrap: ${({ viewOnly }) => (viewOnly ? 'wrap' : 'nowrap')};
    overflow-x: ${({ viewOnly }) => (viewOnly ? '' : 'auto')};
    max-width: 100%;
    gap: 0.75rem 0.5rem;
    order: ${({ viewOnly }) => (viewOnly ? 'auto' : -1)};
    padding-top: ${({ viewOnly }) => (viewOnly ? 0 : '0.75rem')};

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
