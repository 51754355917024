import MessageKeys from '../translationKeys/MessageKeys';

export const MessageTranslations = {
  [MessageKeys.Dropdown.title]: 'Inbox',
  [MessageKeys.Dropdown.no_message]: 'No messages',
  [MessageKeys.Dropdown.bottom_btn]: 'View All Messages',

  [MessageKeys.ChatBox.title]: 'Welcome to your inbox!',
  [MessageKeys.ChatBox.no_chat]: `
    It looks like there aren't any conversations started yet. <br>
    But don't worry! When you start one, you'll find them here.`,
  [MessageKeys.ChatBox.no_selected_chat]: 'Click on a chat to start messaging!',
  [MessageKeys.ChatBox.new_chat]: `
    Start a conversation and connect with {{user}} to discuss your project details. <br>
    <span>Send a proposal to share information all-in-one.</span>`,
  [MessageKeys.ChatBox.search]: `
    <h6>No results found</h6>
    <p>Please try another keyword.</p>`,

  [MessageKeys.ChatBox.Message.me]: 'Me',
  [MessageKeys.ChatBox.Message.copy]: 'Copy message',
  [MessageKeys.ChatBox.Message.report]: 'Report message',
  [MessageKeys.ChatBox.Message.download_file]: 'Download all files',

  [MessageKeys.ChatBox.Message.typing]: 'Typing...',
  [MessageKeys.ChatBox.Message.Offer_Package.text]:
    'Here is the custom package for you.',
  [MessageKeys.ChatBox.Message.Offer_Package.description]:
    "Order will start once you accept client's proposal.",
  [MessageKeys.ChatBox.Message.Offer_Package.btn]: 'Continue to Checkout',

  [MessageKeys.ChatBox.Message.Select_Package.title]: 'Select a package',
  [MessageKeys.ChatBox.Message.Select_Package.btn]: 'Create a new package',
  [MessageKeys.ChatBox.Message.Select_Package.complete_package_first]:
    'Complete your first package to start sending an offer!',
  [MessageKeys.ChatBox.Message.Select_Package.no_price]: 'No price',
  [MessageKeys.ChatBox.Message.Select_Package.verified_only]:
    'Verified users only',

  [MessageKeys.ChatBox.Message.Custom_Package.title]: 'Custom package',
  [MessageKeys.ChatBox.Message.Custom_Package.description]: `
    The price displayed to the buyer will include a ~{{number}}% service fee.`,
  [MessageKeys.ChatBox.Message.Custom_Package.offering]: 'Offering...',
  [MessageKeys.ChatBox.Message.Custom_Package.btn1]: 'Offer Package',
  [MessageKeys.ChatBox.Message.Custom_Package.btn2]: 'Back to Select',

  [MessageKeys.ChatBox.Message.Request_Price.text]:
    'I would like to request the price of your package.',
  [MessageKeys.ChatBox.Message.Request_Price.description]:
    'The price was successfully requested to the creator.',
  [MessageKeys.ChatBox.Message.Request_Price.btn]: 'Offer a price & package',

  [MessageKeys.ChatBox.Message.File.singular]: 'file',
  [MessageKeys.ChatBox.Message.File.plural]: 'files',
  [MessageKeys.ChatBox.Message.File.plural_2]: '2 files',
  [MessageKeys.ChatBox.Message.File.plural_3]: '3 files',
  [MessageKeys.ChatBox.Message.File.plural_4]: '4 files',
  [MessageKeys.ChatBox.Message.File.plural_5]: '5 files',
  [MessageKeys.ChatBox.Message.File.plural_6]: '6 files',
  [MessageKeys.ChatBox.Message.File.plural_7]: '7 files',
  [MessageKeys.ChatBox.Message.File.plural_8]: '8 files',
  [MessageKeys.ChatBox.Message.File.plural_9]: '9 files',
  [MessageKeys.ChatBox.Message.File.plural_10]: '10 files',
  [MessageKeys.ChatBox.Message.File.expired]: 'This file is expired.',

  [MessageKeys.ChatBox.Message.Error.sending]: 'Sending...',
  [MessageKeys.ChatBox.Message.Error.fail]: 'Failed to send',

  [MessageKeys.InputField.chat_disabled]:
    'This user cannot be contacted anymore.',
  [MessageKeys.InputField.web]: 'Enter text or drag and drop files',
  [MessageKeys.InputField.mobile]: 'Type your message',
  [MessageKeys.InputField.drag_drop]: 'Drag and drop files',

  [MessageKeys.InputField.Btn.offer_package]: 'Offer a package',
  [MessageKeys.InputField.Btn.send_proposal]: 'Send a proposal',

  [MessageKeys.UserInfo.Verified.tooltip]: `
    This account represents a verified <br>
    company or organization, or is a <br>
    member affiliated with them.`,

  [MessageKeys.UserInfo.Status.online]: 'Online',
  [MessageKeys.UserInfo.Status.offline]: 'Last Seen',

  [MessageKeys.UserInfo.order_together]: 'Orders Together',

  [MessageKeys.UserInfo.Btn.edit_profile]: 'Edit Profile',
  [MessageKeys.UserInfo.Btn.visit_profile]: 'Visit Profile',

  [MessageKeys.Error.max_characters]: 'Max {{number}} characters',
  [MessageKeys.Error.max_file_size_1]: 'Total file size limit is max',
  [MessageKeys.Error.max_file_size_2]: 'MB',
  [MessageKeys.Error.max_file_1]: 'You can send max',
  [MessageKeys.Error.max_file_2]: 'files',
};
