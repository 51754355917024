import AxiosClient from 'components/utilities/AxiosClient';

const requestPrice = async ({
  userId,
  creatorHandle,
  platform,
  tier,
  message,
}) => {
  const res = await AxiosClient.post('/requestPrice', {
    to: userId,
    toHandle: creatorHandle,
    text: message,
    packageData: {
      userId,
      platform,
      tier,
    },
  });
  return res.data;
};

export default requestPrice;
