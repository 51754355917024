export const WalletIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 22}
      height={size * 14}
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="10" y="0.5" width="11.5" height="13" rx="1.5" stroke="#2867E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H16C17.1046 14 18 13.1046 18 12V2C18 0.895431 17.1046 0 16 0H2ZM5 8.99999C6.10457 8.99999 7 8.10456 7 6.99999C7 5.89542 6.10457 4.99999 5 4.99999C3.89543 4.99999 3 5.89542 3 6.99999C3 8.10456 3.89543 8.99999 5 8.99999Z"
        fill="#2867E0"
      />
    </svg>
  );
};
