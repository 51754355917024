export const EyeLightIcon = ({
  size = 1,
  width,
  height,
  color = 'currentColor',
}) => (
  <svg
    width={width ? width : 25 * size}
    height={height ? height : 19 * size}
    viewBox="0 0 25 19"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.1713 8.99861C21.7206 3.44552 17.1104 0 12.1363 0C7.16213 0 2.55196 3.44552 0.101284 8.99861C0.034481 9.148 0 9.30923 0 9.47222C0 9.63521 0.034481 9.79645 0.101284 9.94583C2.55196 15.4989 7.16213 18.9444 12.1363 18.9444C17.1104 18.9444 21.7206 15.4989 24.1713 9.94583C24.2381 9.79645 24.2725 9.63521 24.2725 9.47222C24.2725 9.30923 24.2381 9.148 24.1713 8.99861ZM12.1363 16.5764C8.29041 16.5764 4.6508 13.865 2.55196 9.47222C4.6508 5.07948 8.29041 2.36806 12.1363 2.36806C15.9821 2.36806 19.6217 5.07948 21.7206 9.47222C19.6217 13.865 15.9821 16.5764 12.1363 16.5764ZM12.1363 4.73611C11.1765 4.73611 10.2382 5.01388 9.44019 5.53429C8.64215 6.0547 8.02015 6.79438 7.65285 7.65979C7.28555 8.5252 7.18945 9.47748 7.3767 10.3962C7.56395 11.3149 8.02613 12.1588 8.70481 12.8212C9.38349 13.4835 10.2482 13.9346 11.1895 14.1173C12.1309 14.3001 13.1066 14.2063 13.9934 13.8478C14.8801 13.4894 15.638 12.8823 16.1712 12.1035C16.7045 11.3246 16.9891 10.4089 16.9891 9.47222C16.9891 8.21613 16.4778 7.01148 15.5677 6.12329C14.6577 5.23509 13.4233 4.73611 12.1363 4.73611ZM12.1363 11.8403C11.6564 11.8403 11.1873 11.7014 10.7882 11.4412C10.3892 11.181 10.0782 10.8111 9.89456 10.3784C9.71091 9.94573 9.66286 9.46959 9.75649 9.01024C9.85011 8.55088 10.0812 8.12893 10.4205 7.79775C10.7599 7.46658 11.1922 7.24104 11.6629 7.14967C12.1336 7.0583 12.6215 7.10519 13.0648 7.28442C13.5082 7.46366 13.8871 7.76718 14.1538 8.1566C14.4204 8.54603 14.5627 9.00387 14.5627 9.47222C14.5627 10.1003 14.307 10.7026 13.852 11.1467C13.397 11.5908 12.7798 11.8403 12.1363 11.8403Z" />
  </svg>
);
