import { useRef, useEffect, useState, useCallback } from 'react';

/**
 * Hook to detect if the user has clicked outside of a given element.
 * @param {boolean} initialState - Initial state of the hook.
 * @param {function} onClose - Function to call when the user has clicked outside of the element.
 * @returns {Array} - Array with the state and a function to set the state and a ref to put at the element to listen.
 * @example
 * const [visible, setVisible, ref] = useOutsideAlerter(false);
 */
const useOutsideAlerter = (initialState, onClose, closeByHover = false) => {
  const [visible, setVisible] = useState(initialState);
  const ref = useRef(null);
  const triggerRef = useRef(null);

  const handleClickOutside = useCallback(
    (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (triggerRef.current?.contains(event.target)) {
          return;
        }

        setVisible((prevState) => {
          if (prevState) {
            onClose?.();
          }
          return false;
        });
      }
    },
    [ref, onClose, triggerRef]
  );
  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === 'Escape') {
        setVisible((prevState) => {
          if (prevState) {
            onClose?.();
          }
          return false;
        });
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (closeByHover) {
      document.removeEventListener('mouseover', handleClickOutside);
      document.addEventListener('mouseover', handleClickOutside, true);
    } else {
      document.removeEventListener('click', handleClickOutside);
      document.addEventListener('click', handleClickOutside, true);
    }

    document.addEventListener('keydown', handleKeyPress, true);
    return () => {
      if (closeByHover) {
        document.removeEventListener('mouseover', handleClickOutside, true);
      } else {
        document.removeEventListener('click', handleClickOutside, true);
      }

      document.removeEventListener('keydown', handleKeyPress, true);
    };
  }, [ref, handleClickOutside, handleKeyPress, closeByHover]);

  return [visible, setVisible, ref, triggerRef];
};

export default useOutsideAlerter;
