import { LoadingIcon, PlayIcon } from 'assets/icons';
import { useState } from 'react';
import styled from 'styled-components';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import VideoPlayer from 'components/components/MessagesPage/MediaPlayer/VideoPlayer';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect } from 'react';
import { useRef } from 'react';

const VideoPreview = ({ file, borderRadius }) => {
  const [openMediaViewer, setOpenMediaViewer] = useState(false);
  const { isMobile } = useResponsiveScreen();
  const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState(null);
  const wrapperRef = useRef(null);

  // only load when in view
  useEffect(() => {
    if (!wrapperRef.current || !file.thumbnail || src) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          const image = new Image();
          image.src = file.thumbnail;
          setLoading(true);
          image.onload = () => {
            setLoading(false);
            setSrc(image.src);
          };
          image.onerror = () => {
            setLoading(false);
          };
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    observer.observe(wrapperRef.current);

    return () => {
      observer.disconnect();
    };
  }, [file.thumbnail, src]);

  return (
    <>
      <Wrapper
        ref={wrapperRef}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (isMobile) {
            setOpenMediaViewer(true);
          } else {
            // open video in new tab on desktop
            const url =
              file.url.startsWith('blob:') || !file.dbName
                ? file.url
                : `${process.env.REACT_APP_MEGA_SERVER}/proposals/uploads/${file.dbName}`;

            if (url) {
              window.open(url, '_blank', 'noopener noreferrer');
            }
          }
        }}
        borderRadius={borderRadius}
      >
        <Thumbnail src={src} alt="" />
        <AnimatePresence>
          {loading ? (
            <Loading key="loading" />
          ) : (
            <PlayButton key="play-button" isMobile={isMobile} />
          )}
        </AnimatePresence>
      </Wrapper>

      <AnimatePresence>
        {isMobile && openMediaViewer && (
          <VideoPlayer
            name={file.name}
            type={file.type}
            size={file.size}
            url={file.url}
            onClose={() => setOpenMediaViewer(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default VideoPreview;

const Wrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: ${({ borderRadius }) => borderRadius || '0.25rem'};
  overflow: hidden;

  &:focus {
    outline: none;
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background: #cfcfcf;
`;

const PlayButton = styled(motion.div).attrs(({ isMobile }) => ({
  children: <PlayIcon size={isMobile ? 0.56 : 0.7} />,
  variants: {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding-left: 2px;
`;

const Loading = styled(motion.div).attrs({
  children: <LoadingIcon />,
  variants: {
    hidden: {
      opacity: 0,
      scale: 0.5,
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
})`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;
