export const ListIcon = ({ size = 1 }) => (
  <svg
    width={15 * size}
    height={10 * size}
    viewBox="0 0 15 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 9.16667C0 9.6269 0.373096 10 0.833333 10H14.1667C14.6269 10 15 9.6269 15 9.16667C15 8.70643 14.6269 8.33333 14.1667 8.33333H0.833334C0.373096 8.33333 0 8.70643 0 9.16667ZM0 5C0 5.46024 0.373096 5.83333 0.833333 5.83333H14.1667C14.6269 5.83333 15 5.46024 15 5C15 4.53976 14.6269 4.16667 14.1667 4.16667H0.833334C0.373096 4.16667 0 4.53976 0 5ZM0.833334 0C0.373096 0 0 0.373096 0 0.833333C0 1.29357 0.373096 1.66667 0.833333 1.66667H14.1667C14.6269 1.66667 15 1.29357 15 0.833333C15 0.373096 14.6269 0 14.1667 0H0.833334Z" />
  </svg>
);
