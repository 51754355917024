import { forEach, isEmpty } from 'lodash';

// convert deep nested object, array, array of objects and files to FormData
const toFormData = (data, formData = new FormData(), parentKey = null) => {
  if (data instanceof File || data instanceof Blob) {
    formData.append(parentKey, data, encodeURIComponent(data?.name));
    return formData;
  }

  if (data instanceof Array) {
    if (data.length === 0) {
      formData.append(`${parentKey}[0]`, '');
      return formData;
    }

    data.forEach((value, index) => {
      toFormData(value, formData, `${parentKey}[${index}]`);
    });
    return formData;
  }

  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      toFormData(data[key], formData, parentKey ? `${parentKey}.${key}` : key);
    });
    return formData;
  }

  formData.append(parentKey, data);
  return formData;
};

const toFormData2 = (data, formData = new FormData(), parentKey = null) => {
  forEach(data, (value, key) => {
    if (value === null) return; // else "null" will be added

    let formattedKey = isEmpty(parentKey) ? key : `${parentKey}[${key}]`;

    if (value instanceof File) {
      formData.set(formattedKey, value);
      // Remove it from data so we can serialize it and send separately
      delete data[key];
    }
  });

  console.log('remaining data', data);

  formData.set('userInfo', JSON.stringify(data));

  return formData;
};

export { toFormData, toFormData2 };

// module.exports = {
//   toFormData,
//   toFormData2
// };
