import FileType from './FileType';

// list of file extensions that can be viewed in the browser
const ViewableFiles = {
  [FileType.Video]: ['mp4', 'webm', 'ogg'],
  [FileType.Image]: ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'ico'],
  [FileType.Audio]: ['mp3', 'wav', 'ogg', 'm4a'],
  [FileType.Other]: ['pdf', 'txt', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'],
  [FileType.Expired]: [],
};

export default ViewableFiles;
