import { AutoTranslationIcon, CheckRoundedIcon } from 'assets/icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import languages from 'constants/translationLanguages';
import styled from 'styled-components';
import IndigoSwitch from 'components/Basics/Buttons/IndigoSwitch';
import Stack from '../../Stack';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import AutoTranslationKeys from 'translations/translationKeys/AutoTranslationKeys';

const SidebarLanguagesView = ({ toggleVisible }) => {
  const { i18n, t } = useTranslation();
  const {
    autoTranslate,
    toggleAutoTranslate,
    shouldReloadPageOnLanguageChange,
  } = useAutoTranslation();

  const selected = useMemo(
    () =>
      languages.find(
        ({ value }) => value === i18n.language || i18n.language.includes(value)
      ),
    [i18n.language]
  );

  return (
    <>
      <Stack gap="0.75rem">
        <Stack direction="row" gap="1.875rem" align="center">
          <Stack direction="row">
            <AutoTranslationIcon size={0.67} />
            <h5 className="sbh7">{t(AutoTranslationKeys.Dropdown.title)}</h5>
          </Stack>
          <IndigoSwitch
            id="footer-switch"
            checked={autoTranslate}
            onChange={() => {
              toggleAutoTranslate();
              shouldReloadPageOnLanguageChange();
            }}
            size="sm"
          />
        </Stack>
        <p className="h9">{t(AutoTranslationKeys.Dropdown.description)}</p>
      </Stack>
      <Divider />
      {languages.map((language, index) => (
        <Item
          key={index}
          onClick={() => {
            i18n.changeLanguage(language.value);
            toggleVisible();
            shouldReloadPageOnLanguageChange();
          }}
          disabled={language.disabled}
          selected={language.value === selected.value}
        >
          {selected.value === language.value && <CheckRoundedIcon size={0.8} />}
          {language.label}
        </Item>
      ))}
    </>
  );
};
export default SidebarLanguagesView;

const Item = styled.button.attrs({
  className: 'mh13',
})`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ selected }) =>
    selected ? 'var(--color-indigo-500)' : 'var(--color-indigo-100)'};
  width: 100%;
  text-align: left;
  display: flex;
  gap: 0.625rem;
  align-items: center;

  &:hover {
    background: var(--color-indigo-75);
  }

  &:disabled {
    color: var(--color-indigo-25);
  }
`;

const Divider = styled.div`
  width: 180px;
  height: 1px;
  background: var(--color-indigo-25);
`;
