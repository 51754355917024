import SubscribersFollowersFilter from 'components/components/MarketingHomePage/FiltersSection/Filters/SubscribersFollowersFilter';
import AxiosClient from 'components/utilities/AxiosClient';

const Metric = {
  Day: 'day',
  Views: 'views',
  Likes: 'likes',
  Comments: 'comments',
  Shares: 'shares',
  Engagements: 'engagements',
  SubscribersFollowers: 'subscribersFollowers',
};

const getHistorical = async ({
  userId,
  handle,
  linksId,
  platformUid,
  interval,
  metrics,
  negateMetrics,
  platform
}) => {
  if (!(userId || handle || linksId || platformUid)||!platform)
    return Promise.reject({
      message: 'Missing required parameters for getHistorical',
    });

  const metricsParam =
    metrics && metrics.length > 0
      ? negateMetrics
        ? '-' + metrics.join(',')
        : metrics.join(',')
      : undefined;

  const res = await AxiosClient.get('/platforms/getHistory', {
    params: {
      userId,
      handle,
      linksId,
      platformUid,
      interval,
      metrics: metricsParam,
      platform
    },
  });

  return res.data;
};

export default getHistorical;

export { Metric };
