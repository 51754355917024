export const ArrowLeftIcon = ({ size = 1 }) => (
  <svg
    width={size * 16}
    height={size * 15}
    viewBox="0 0 16 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.8571 6.35683H3.89144L7.66287 2.59993C7.87808 2.3849 7.99898 2.09326 7.99898 1.78917C7.99898 1.48507 7.87808 1.19343 7.66287 0.978406C7.44767 0.763379 7.15579 0.642578 6.85144 0.642578C6.5471 0.642578 6.25522 0.763379 6.04002 0.978406L0.32573 6.68799C0.221683 6.79659 0.140123 6.92465 0.0857297 7.06482C-0.0285766 7.34283 -0.0285766 7.65466 0.0857297 7.93268C0.140123 8.07285 0.221683 8.20091 0.32573 8.30951L6.04002 14.0191C6.14626 14.1261 6.27266 14.2111 6.41193 14.269C6.5512 14.327 6.70057 14.3569 6.85144 14.3569C7.00232 14.3569 7.15169 14.327 7.29096 14.269C7.43023 14.2111 7.55663 14.1261 7.66287 14.0191C7.76999 13.9129 7.85501 13.7866 7.91303 13.6475C7.97106 13.5083 8.00093 13.3591 8.00093 13.2083C8.00093 13.0576 7.97106 12.9083 7.91303 12.7692C7.85501 12.63 7.76999 12.5037 7.66287 12.3976L3.89144 8.64066H14.8571C15.1602 8.64066 15.4509 8.52035 15.6653 8.3062C15.8796 8.09205 16 7.80258 16 7.49972C16 7.19687 15.8796 6.90544 15.6653 6.69129C15.4509 6.47714 15.1602 6.35683 14.8571 6.35683Z" />
  </svg>
);
