export const PlayIcon = ({ size = 1 }) => (
  <svg
    width={32 * size}
    height={34 * size}
    viewBox="0 0 32 34"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M27.9397 11.3312L9.69131 0.865791C8.70387 0.295595 7.5831 -0.00310749 6.44286 2.43768e-05C5.30261 0.00315625 4.18351 0.30801 3.19921 0.883622C2.21491 1.45923 1.40049 2.2851 0.838668 3.27733C0.276843 4.26956 -0.0123695 5.39281 0.000405604 6.53299V27.5394C0.000405604 29.2529 0.681074 30.8961 1.89267 32.1077C3.10427 33.3193 4.74755 34 6.46101 34C7.59528 33.9981 8.70916 33.6984 9.69131 33.131L27.9397 22.6656C28.9202 22.0981 29.7343 21.2828 30.3002 20.3013C30.8662 19.3199 31.1641 18.2069 31.1641 17.074C31.1641 15.9411 30.8662 14.8281 30.3002 13.8466C29.7343 12.8652 28.9202 12.0498 27.9397 11.4823V11.3312ZM26.0506 19.2464L7.80225 29.8629C7.39324 30.0947 6.93114 30.2166 6.46101 30.2166C5.99088 30.2166 5.52878 30.0947 5.11977 29.8629C4.71192 29.6275 4.37325 29.2888 4.13781 28.8809C3.90236 28.473 3.77846 28.0103 3.77854 27.5394V6.45742C3.77846 5.98647 3.90236 5.5238 4.13781 5.11593C4.37325 4.70805 4.71192 4.36935 5.11977 4.13387C5.53047 3.90559 5.99119 3.7823 6.46101 3.77495C6.9305 3.78459 7.39069 3.90774 7.80225 4.13387L26.0506 14.6749C26.4586 14.9102 26.7975 15.2489 27.033 15.6568C27.2686 16.0647 27.3927 16.5274 27.3927 16.9984C27.3927 17.4694 27.2686 17.9322 27.033 18.34C26.7975 18.7479 26.4586 19.0866 26.0506 19.322V19.2464Z" />
  </svg>
);
