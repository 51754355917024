import styled from 'styled-components';
import { FacebookIconBlue, GoogleIcon, WalletIcon } from 'assets/icons';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import LoginKeys from 'translations/translationKeys/LoginPageKeys'

/**
 *
 * @param {string} type
 * - type of button
 * - google
 * facebook
 * - wallet
 */
const ChildComponent = forwardRef(({ children }, ref) => {
  return (
    <div style={{ display: 'none' }} ref={ref}>
      {children}
    </div>
  );
});

const SocialSignInButton = ({
  type = 'google',
  context = 'login',
  margin,
  children,
  onClick,
  ...rest
}) => {
  const { t } = useTranslation();
  const childRef = useRef();

  const types = {
    google: {
      text: t(LoginKeys.Google.sign_in),
      Icon: GoogleIcon,
    },
    wallet: {
      text: t(LoginKeys.Wallet.sign_in),
      Icon: WalletIcon,
    },
    facebook: {
      text: t(LoginKeys.Facebook.sign_in),
      Icon: FacebookIconBlue,
    },
  };
  const signUpTypes = {
    wallet: {
      text: t(LoginKeys.Wallet.sign_up),
      Icon: WalletIcon,
    },
    facebook: {
      text: t(LoginKeys.Facebook.sign_up),
      Icon: FacebookIconBlue,
    },
  };

  const { text, Icon } = context === 'login' ? types[type] : signUpTypes[type];
  const { isMobile } = useResponsiveScreen();

  return (
    <Container
      isMobile={isMobile}
      margin={margin}
      onClick={(e) => {
        if (type === 'facebook') {
          childRef.current?.children[0].children[0].click();
        } else {
          onClick();
        }
      }}
      {...rest}
      type="button"
    >
      <Icon size={isMobile ? 0.91 : 1} />
      <ButtonText isMobile={isMobile} type={type}>
        {text}
      </ButtonText>
      <ChildComponent ref={childRef}>{children}</ChildComponent>
    </Container>
  );
};

export default SocialSignInButton;

const Container = styled.button`
  transition: background-color 0.218s, border-color 0.218s;
  color: #3c4043;
  border-radius: 30px;
  white-space: nowrap;
  width: ${({ isMobile }) => (isMobile ? '250px' : '320px')};
  padding: 8px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.15px;
  justify-content: flex-start;
  ${({ margin }) => (margin ? { margin } : null)};
  transition: all 0.3s ease;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.14);
  box-shadow: ${({ isMobile }) =>
    isMobile ? '0px 0px 4px rgba(0, 0, 0, 0.1)' : ''};
  pointer-event: none;
  &:hover {
    background-color: #f8fafe;
    border-color: #ddeafd;
  }

  &:active {
    background-color: #ecf2fe;
    border-color: #d2e2fc;
  }
`;

const ButtonText = styled.span`
  font-family: 'Kumbh sans', sans-serif !important;
  color: black;
  font-weight: 500;
  margin-left: ${({ type }) => (type === 'facebook' ? '63px' : '70px')};

  @media (max-width: 769px) {
    margin-left: ${({ type }) => (type === 'facebook' ? '28px' : '37px')};
    color: #4c5054;
    font-weight: 550;
    letter-spacing: 0.25px;
  }
`;
