export const ThreeDBox1 = ({ size = 1 }) => (
  <svg
    width={size * 12}
    height={size * 12}
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.0938 3.33683C11.0904 3.32092 11.0904 3.30447 11.0938 3.28856C11.0909 3.27464 11.0909 3.26026 11.0938 3.24634V3.19204L11.0576 3.10155C11.0429 3.07686 11.0246 3.05449 11.0033 3.03519L10.949 2.98693H10.9189L8.54198 1.48477L6.2978 0.0972414C6.24588 0.0560557 6.18646 0.0253251 6.12285 0.00675013H6.07458C6.02066 -0.00225004 5.96562 -0.00225004 5.9117 0.00675013H5.85137C5.7813 0.0222496 5.71411 0.0487175 5.65229 0.0851759L1.14583 2.8904L1.09154 2.93263L1.03724 2.98089L0.976914 3.02312L0.94675 3.05932L0.910553 3.14981V3.20411V3.2403C0.904694 3.28031 0.904694 3.32095 0.910553 3.36096V8.62755C0.910348 8.73007 0.936275 8.83095 0.985885 8.92067C1.0355 9.0104 1.10715 9.08599 1.19409 9.14033L5.71865 11.9395L5.80914 11.9757H5.85741C5.95947 12.0081 6.06905 12.0081 6.17111 11.9757H6.21937L6.30986 11.9395L10.7982 9.18256C10.8852 9.12822 10.9568 9.05262 11.0064 8.9629C11.056 8.87318 11.082 8.7723 11.0818 8.66978V3.40319C11.0818 3.40319 11.0938 3.36096 11.0938 3.33683ZM5.97203 1.31586L7.04586 1.97946L3.67355 4.06679L2.59369 3.40319L5.97203 1.31586ZM5.36875 10.365L2.05074 8.33798V4.48305L5.36875 6.53418V10.365ZM5.97203 5.47242L4.81977 4.77865L8.19208 2.68529L9.35037 3.40319L5.97203 5.47242ZM9.89331 8.31988L6.5753 10.3831V6.53418L9.89331 4.48305V8.31988Z" />
  </svg>
);
