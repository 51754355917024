import SettingsKeys from '../translationKeys/SettingsPageKeys';

export const SettingsTranslations = {
  // Signed with
  [SettingsKeys.Signed_With.wallet]: '使用钱包登录',
  [SettingsKeys.Signed_With.google]: '使用Google登录',
  [SettingsKeys.Signed_With.facebook]: '使用Facebook登录',

  // Account
  [SettingsKeys.Account.connected_email]: '已连接的邮箱',

  // Password
  [SettingsKeys.Password.current_password]: '当前密码',
  [SettingsKeys.Password.new_password]: '新密码',
  [SettingsKeys.Password.confirm_new_password]: '确认新密码',

  [SettingsKeys.Password.Rule.title]: '您的密码必须包含',
  [SettingsKeys.Password.Rule.rule_1]: '8~20个字符',
  [SettingsKeys.Password.Rule.rule_2]: '至少一个大写字母',
  [SettingsKeys.Password.Rule.rule_3]: '至少一个小写字母',
  [SettingsKeys.Password.Rule.rule_4]: '至少一个数字或特殊字符',

  [SettingsKeys.Password.Error.not_follow_rule]: '密码不符合规则',
  [SettingsKeys.Password.Error.characters]:
    '必须包含8~20个字符，包括字母（A-Z，a-z），数字或特殊字符。',
  [SettingsKeys.Password.Error.not_match]: '两次输入的密码不一致。',
  [SettingsKeys.Password.Error.current_password_incorrect]: '当前密码不正确。',

  [SettingsKeys.Password.update_success]: '您的密码已成功更新！',

  // Notifications
  [SettingsKeys.Notification.Description.orders]:
    '接收有关订单状态的电子邮件。',
  [SettingsKeys.Notification.Description.messages]: '接收消息的电子邮件。',
  [SettingsKeys.Notification.Description.reminders]:
    '接收截止日期提醒的电子邮件。',
  [SettingsKeys.Notification.Description.newsletters]:
    '接收有关奖励、新功能、促销等的有用电子邮件。',
  [SettingsKeys.Notification.Description.packages]:
    '接收有关套餐、价格解锁和客户与创作者互动的电子邮件。',
  [SettingsKeys.Notification.Description.real_time]: '启用声音提醒',

  // Referral Code
  [SettingsKeys.Referral_Code.My.title]: '我的推荐码',
  [SettingsKeys.Referral_Code.My.description]: '了解奖励',
  [SettingsKeys.Referral_Code.My.copy_code]: '复制代码',
  [SettingsKeys.Referral_Code.My.copied]: '已成功复制！',

  [SettingsKeys.Referral_Code.Enter.title]: '输入代码',

  [SettingsKeys.Referral_Code.Modal.reward_arrive]: '您的推荐奖励已到账。',
  [SettingsKeys.Referral_Code.Modal.my_evo]: '我的 EVO',
  [SettingsKeys.Referral_Code.Modal.check_later]: '稍后再查看',

  [SettingsKeys.Referral_Code.Referral_Page.instruction]:
    '请输入有效的推荐码。\n可以在设置选项卡中找到该代码。',
  [SettingsKeys.Referral_Code.Referral_Page.evo_policy]:
    '您可以赚取额外的 EVO 积分\n请查看我们的政策。',
  [SettingsKeys.Referral_Code.Referral_Page.enter_code_here]: '在此输入推荐码',

  // Referral System
  [SettingsKeys.Referral_System.title]: '推荐系统',
  [SettingsKeys.Referral_System.button]: '复制推荐码',

  [SettingsKeys.Referral_System.Refer_A_Friend.lang]: 'en',
  [SettingsKeys.Referral_System.Referral_Reward.lang]: 'en',

  // Deactivate/Delete
  [SettingsKeys.Deactivate_Delete.title]: '停用/删除账户',

  [SettingsKeys.Deactivate_Delete.Q1.title]: '如果我停用我的账户会发生什么？',
  [SettingsKeys.Deactivate_Delete.Q1.description]: `
    <heading><span>{{dot}}</span> 您的资料将不会出现在搜索列表中，直到您重新激活您的账户。</heading> <br>
    <heading><span>{{dot}}</span> 存储在您的账户中的数据将被隐藏。</heading> <br>
    <heading><span>{{dot}}</span> 您可以通过重新登录来重新激活您的账户。</heading>`,

  [SettingsKeys.Deactivate_Delete.Q2.title]: '如果我删除我的账户会发生什么？',
  [SettingsKeys.Deactivate_Delete.Q2.description]: `
  <heading><span>{{dot}}</span> 您的资料将从搜索列表中删除。</heading> <br>
  <heading><span>{{dot}}</span> 存储在您的账户中的数据将被永久删除。</heading> <br>
  <heading><span>{{dot}}</span> 您将无法重新激活您的账户。</heading>`,

  [SettingsKeys.Deactivate_Delete.warning]:
    '如果您有正在进行中的订单，您无法停用/删除您的账户。请在停用/删除您的账户之前取消您的订单。',

  // Modal
  [SettingsKeys.Modal.Wallet_Connected.title]: '您的新钱包已成功连接！',

  [SettingsKeys.Modal.Notification.Newsletters.title]:
    '您确定要取消订阅我们的通讯？',
  [SettingsKeys.Modal.Notification.Newsletters.description]:
    '如果您取消订阅，您将无法接收关于奖励、新功能等更新的信息。我们将尽力为您提供有用和有帮助的信息！',

  [SettingsKeys.Modal.Notification.Update_Success.title]:
    '您的通知设置已成功更新！',

  [SettingsKeys.Modal.Referral_Code.Submit_Success.title]:
    '您的代码已成功提交！',
  [SettingsKeys.Modal.Referral_Code.Submit_Success.description]:
    '现在您可以通过参加我们平台上的各种活动来获得额外的EVO积分。请<u>点击这里</u>了解更多关于推荐代码奖励的信息。',

  [SettingsKeys.Modal.Referral_Code.Submit_Valid.title]: '请提交有效的推荐代码',
  [SettingsKeys.Modal.Referral_Code.Submit_Valid.description]:
    '您输入的代码无效，请输入有效的推荐代码。',

  [SettingsKeys.Modal.Deactivate.title]: '您确定要停用您的账户吗？',
  [SettingsKeys.Modal.Deactivate.description]:
    '您可以通过重新登录来重新激活您的账户。',

  [SettingsKeys.Modal.Delete.title]: '您确定要删除您的账户吗？',
  [SettingsKeys.Modal.Delete.description]:
    '一旦您删除您的账户，您将无法重新激活它。您的数据将被删除并且无法恢复。您的消息和订单相关数据可能会对相关方可见。',

  [SettingsKeys.Modal.Complete_Cancel_Order.title]:
    '请完成/取消您的订单以停用您的账户。',
  [SettingsKeys.Modal.Complete_Cancel_Order.description]:
    '在您取消或完成正在进行中的订单之前，您无法停用您的账户。',

  [SettingsKeys.Modal.Why_Leaving.title]: '请告诉我们您为什么离开',
  [SettingsKeys.Modal.Why_Leaving.reason_1]: '没有我想使用的服务。',
  [SettingsKeys.Modal.Why_Leaving.reason_2]: '价格太高。',
  [SettingsKeys.Modal.Why_Leaving.reason_3]: '经常出现错误。',
  [SettingsKeys.Modal.Why_Leaving.reason_4]: '没有优惠券和预留等好处。',
  [SettingsKeys.Modal.Why_Leaving.reason_5]: '服务质量较低。',
  [SettingsKeys.Modal.Why_Leaving.reason_6]: '我找到了替代品。',
  [SettingsKeys.Modal.Why_Leaving.reason_7]: '我遇到了不尊重的成员。',
  [SettingsKeys.Modal.Why_Leaving.reason_8]: '我想删除我的数据。',
  [SettingsKeys.Modal.Why_Leaving.button]: '删除账户',

  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title]:
    '您暂时无法停用或删除您的账户',
  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description]:
    '您可以在创建账户后的24小时后停用或删除账户。 请稍后再试。',
};
