import SettingsKeys from '../translationKeys/SettingsPageKeys';

export const SettingsTranslations = {
  // Signed with
  [SettingsKeys.Signed_With.wallet]: 'Signed in with',
  [SettingsKeys.Signed_With.google]: 'Signed in with Google',
  [SettingsKeys.Signed_With.facebook]: 'Signed in with Facebook',

  // Account
  [SettingsKeys.Account.connected_email]: 'Connected Email',

  // Password
  [SettingsKeys.Password.current_password]: 'Current Password',
  [SettingsKeys.Password.new_password]: 'New Password',
  [SettingsKeys.Password.confirm_new_password]: 'Confirm New Password',

  [SettingsKeys.Password.Rule.title]: 'Your password must contain',
  [SettingsKeys.Password.Rule.rule_1]: '8~20 characters',
  [SettingsKeys.Password.Rule.rule_2]: 'At least 1 uppercase character',
  [SettingsKeys.Password.Rule.rule_3]: 'At least 1 lowercase character',
  [SettingsKeys.Password.Rule.rule_4]: 'At least 1 number or special character',

  [SettingsKeys.Password.Error.not_follow_rule]:
    'The password does not follow the rules',
  [SettingsKeys.Password.Error.characters]:
    '8~20 characters consisting of letters(A-Z, a-z), numbers, or special characters.',
  [SettingsKeys.Password.Error.not_match]: 'The passwords do not match.',
  [SettingsKeys.Password.Error.current_password_incorrect]:
    'The current password is incorrect.',

  [SettingsKeys.Password.update_success]:
    'Your password was successfully updated!',

  // Notifications
  [SettingsKeys.Notification.Description.orders]:
    'Receive emails about my order status.',
  [SettingsKeys.Notification.Description.messages]:
    'Receive emails for messages.',
  [SettingsKeys.Notification.Description.reminders]:
    'Receive emails reminding me of due dates.',
  [SettingsKeys.Notification.Description.newsletters]:
    'Receive useful emails about my rewards, new features, promotions, etc.',
  [SettingsKeys.Notification.Description.packages]:
    'Receive emails about packages including price unlocks and client & creator interactions.',
  [SettingsKeys.Notification.Description.real_time]: 'Enable Sound',

  // Referral Code
  [SettingsKeys.Referral_Code.My.title]: 'My Referral Code',
  [SettingsKeys.Referral_Code.My.description]: 'Learn about rewards',
  [SettingsKeys.Referral_Code.My.copy_code]: 'Copy Code',
  [SettingsKeys.Referral_Code.My.copied]: 'Successfully copied!',

  [SettingsKeys.Referral_Code.Enter.title]: 'Enter a Code',

  [SettingsKeys.Referral_Code.Modal.reward_arrive]:
    'Your referral reward has arrived.',
  [SettingsKeys.Referral_Code.Modal.my_evo]: 'My EVO',
  [SettingsKeys.Referral_Code.Modal.check_later]: 'Check Later',

  [SettingsKeys.Referral_Code.Referral_Page.instruction]:
    'Please enter a valid referral code.\nThis can be found in the Settings tab.',
  [SettingsKeys.Referral_Code.Referral_Page.evo_policy]:
    'You can earn extra EVO points.\nPlease check our policy',
  [SettingsKeys.Referral_Code.Referral_Page.enter_code_here]:
    'Enter a referral code here',

  // Referral System
  [SettingsKeys.Referral_System.title]: 'Referral System',
  [SettingsKeys.Referral_System.button]: 'Copy Referral Code',

  [SettingsKeys.Referral_System.Refer_A_Friend.lang]: 'en',
  [SettingsKeys.Referral_System.Referral_Reward.lang]: 'en',

  // Deactivate/Delete
  [SettingsKeys.Deactivate_Delete.title]: 'Deactivate/Delete',

  [SettingsKeys.Deactivate_Delete.Q1.title]:
    'What happens if I deactivate my account?',
  [SettingsKeys.Deactivate_Delete.Q1.description]: `
    <heading><span>{{dot}}</span> Your profile will not appear on the search list until you reactive your account.</heading> <br>
    <heading><span>{{dot}}</span> Data stored in your account will be hidden.</heading> <br>
    <heading><span>{{dot}}</span> You can reactivate your account by logging back in.</heading>`,

  [SettingsKeys.Deactivate_Delete.Q2.title]:
    'What happens if I delete my account?',
  [SettingsKeys.Deactivate_Delete.Q2.description]: `
    <heading><span>{{dot}}</span> Your profile will be removed from the search list.</heading> <br>
    <heading><span>{{dot}}</span> Data stored in your account will be permanently deleted.</heading> <br>
    <heading><span>{{dot}}</span> You won’t be able to reactivate your account.</heading>`,

  [SettingsKeys.Deactivate_Delete.warning]:
    'If you have any ongoing orders, you cannot deactivate/delete your account. Please cancel your order(s) before deactivating/deleting your account.',

  // Modal
  [SettingsKeys.Modal.Wallet_Connected.title]:
    'Your new wallet has been successfully connected!',

  [SettingsKeys.Modal.Notification.Newsletters.title]:
    'Are you sure you want to unsubscribe from our newsletters?',
  [SettingsKeys.Modal.Notification.Newsletters.description]:
    'You will be unable to receive updates about your rewards, new features, and more if you unsubscribe. We will try our best to deliver useful and helpful information for you!',

  [SettingsKeys.Modal.Notification.Update_Success.title]:
    'Your notifications has been successfully updated!',

  [SettingsKeys.Modal.Referral_Code.Submit_Success.title]:
    'Your code was successfully submitted!',
  [SettingsKeys.Modal.Referral_Code.Submit_Success.description]: `
    Now you can earn extra EVO points through joining various activities on our platform. Please <u>click here</u> to learn more about referral code rewards.`,

  [SettingsKeys.Modal.Referral_Code.Submit_Valid.title]:
    'Please submit a valid referral code',
  [SettingsKeys.Modal.Referral_Code.Submit_Valid.description]:
    'The code you entered is invalid. Please enter a valid referral code.',

  [SettingsKeys.Modal.Deactivate.title]:
    'Are you sure you want to deactivate your account?',
  [SettingsKeys.Modal.Deactivate.description]:
    'You can reactivate your account by logging back in.',

  [SettingsKeys.Modal.Delete.title]:
    'Are you sure you want to delete your account?',
  [SettingsKeys.Modal.Delete.description]:
    'Once you delete your account, you won’t be able to reactivate it. Your data will be deleted and won’t be recovered. Your message and order-related data may remain visible to the counterparts.',

  [SettingsKeys.Modal.Complete_Cancel_Order.title]:
    'Please complete / cancel your order(s) to deactivate your account.',
  [SettingsKeys.Modal.Complete_Cancel_Order.description]:
    'You cannot deactivate your account until you cancel or complete your ongoing order(s).',

  [SettingsKeys.Modal.Why_Leaving.title]:
    'Please, let us know why you are leaving',
  [SettingsKeys.Modal.Why_Leaving.reason_1]:
    "There's no service I want to use.",
  [SettingsKeys.Modal.Why_Leaving.reason_2]: 'The price is too high.',
  [SettingsKeys.Modal.Why_Leaving.reason_3]: 'There are frequent errors.',
  [SettingsKeys.Modal.Why_Leaving.reason_4]:
    'There are few benefits such as coupons and reserves.',
  [SettingsKeys.Modal.Why_Leaving.reason_5]: 'The service quality is low.',
  [SettingsKeys.Modal.Why_Leaving.reason_6]: 'I found a replacement.',
  [SettingsKeys.Modal.Why_Leaving.reason_7]: 'I met a disrespectful member.',
  [SettingsKeys.Modal.Why_Leaving.reason_8]: 'I want to delete my data.',
  [SettingsKeys.Modal.Why_Leaving.button]: 'Delete Account',

  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title]:
    'You cannot deactivate or delete your account yet',
  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description]: `
    You can deactivate or delete an account 24 hours after it was first created. Please try again later.`,
};
