import { useToast } from 'components/Basics/Toast';
import AxiosClient from 'components/utilities/AxiosClient';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import MessageStatus from './MessageStatus';
import useUser from 'hooks/useUser';
import { useSocket } from 'contexts/SocketContext';

const SelectedChatContext = createContext({
  loading: true,
  chatId: null,
  messages: [],
  user: {},
  chatDisabled: false,
  loadMore: () => {},
  loadingMore: false,
});

const getChat = ({ userId, userHandle, cursor, pageSize }) => {
  const controller = new AbortController();
  const signal = controller.signal;

  const promise = new Promise(async (resolve, reject) => {
    AxiosClient.get('/getChat', {
      params: { userId, userHandle, cursor, pageSize },
      signal,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') reject(err);
      });
  });

  return {
    promise,
    dismiss() {
      controller.abort();
    },
  };
};

const MESSAGE_LIMIT = 30;

const SelectedChatProvider = ({ children }) => {
  const { t } = useTranslation();
  const toast = useToast();
  const { selected } = useParams();
  const navigate = useNavigate();
  const { user: currentUser } = useUser();
  const socket = useSocket();

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState({});
  const [chatDisabled, setChatDisabled] = useState(false);
  const [error, setError] = useState(null);

  const cursorRef = useRef(null);
  const haveMoreRef = useRef(true);
  const [isUserInfoOpen, setIsUserInfoOpen] = useState(false);
  const { isMobile } = useResponsiveScreen();

  useEffect(() => {
    setLoading(true);
    setMessages([]);
    setUser({});
    setChatDisabled(false);
    cursorRef.current = null;
    haveMoreRef.current = true;
    if (selected) {
      const handle = selected.startsWith('@') ? selected.slice(1) : null;
      const id = handle ? null : Number(selected) || null;

      const { dismiss, promise } = getChat({
        userId: id,
        userHandle: handle,
        pageSize: MESSAGE_LIMIT,
      });

      promise
        .then((res) => {
          const { messages, user, cursor, haveMore, isDisabled } = res;

          setMessages((prev) => messages);
          setUser(user);
          setChatDisabled(isDisabled);
          cursorRef.current = cursor;
          haveMoreRef.current = haveMore;
        })
        .catch((err) => {
          if (err?.code !== 'ERR_CANCELED') {
            if (err?.response?.status === 500) {
              setError('somethingWentWrong');
            } else if (err?.response?.status === 420) {
              setError('selfChatError');
            } else if (err?.response?.status === 401) {
              setError('creatorToCreatorChatError');
            }
            navigate('/messages', {
              replace: true,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });

      return () => {
        dismiss();
      };
    } else {
      setLoading(false);
    }
  }, [selected, navigate]);

  const loadMore = useCallback(() => {
    if (!haveMoreRef.current || loadingMore || !selected) return;

    setLoadingMore(true);

    const handle = selected.startsWith('@') ? selected.slice(1) : null;
    const id = handle ? null : Number(selected) || null;

    const { dismiss, promise } = getChat({
      userId: id,
      userHandle: handle,
      cursor: cursorRef.current,
      pageSize: MESSAGE_LIMIT,
    });

    promise
      .then((res) => {
        const { messages, cursor, haveMore } = res;
        setMessages((prev) => [...prev, ...messages]);
        cursorRef.current = cursor;
        haveMoreRef.current = haveMore;
      })
      .catch((err) => {
        console.log(err);
        haveMoreRef.current = false;
      })
      .finally(() => {
        setLoadingMore(false);
      });

    return () => {
      dismiss();
    };
  }, [loadingMore, selected]);

  useEffect(() => {
    if (error === 'creatorToCreatorChatError') {
      toast.error(t(ProfileKeys.Menu.Error.creator_to_creator), {
        autoCloseDelay: 2500,
      });
      setError(null);
    }
    if (error === 'somethingWentWrong') {
      toast.error(t(ProfileKeys.Menu.Error.something_went_wrong), {
        autoCloseDelay: 2500,
      });
      setError(null);
    }
    if (error === 'selfChatError') {
      toast.error(t(ProfileKeys.Menu.Error.self_chat), {
        autoCloseDelay: 2500,
      });
      setError(null);
    }
  }, [error, toast, t]);

  const openUserInfo = useCallback(
    (url = '') => {
      if (isMobile) {
        setIsUserInfoOpen(!isUserInfoOpen);
        return;
      }
      window.open(
        `/${user?.handle ? '@' + user?.handle : user?.id}${url}`,
        '_blank'
      );
    },
    [isMobile, user, isUserInfoOpen]
  );

  // send message event listener
  useEffect(() => {
    const catchMessage = (e) => {
      const { message } = e.detail;

      setMessages((messages) => messages.concat(message));

      const onMessageDeliveryReport = (e) => {
        const { success, actualId } = e.detail;

        if (success) {
          setMessages((messages) =>
            messages.map((m) => {
              if (m.id === message.id) {
                return {
                  ...m,
                  actualId,
                  status: MessageStatus.SENT,
                };
              }

              return m;
            })
          );
        } else {
          setMessages((messages) =>
            messages.map((m) => {
              if (m.id === message.id) {
                return {
                  ...m,
                  status: MessageStatus.FAILED,
                };
              }

              return m;
            })
          );
        }

        document.removeEventListener(
          `messageDeliveryReport_${message.id}`,
          onMessageDeliveryReport
        );
      };

      document.addEventListener(
        `messageDeliveryReport_${message.id}`,
        onMessageDeliveryReport
      );
    };

    document.addEventListener('sendMessage', catchMessage);

    return () => {
      document.removeEventListener('sendMessage', catchMessage);
    };
  }, []);

  // message resent event listener
  useEffect(() => {
    const catchMessage = (e) => {
      const { message } = e.detail;

      setMessages((messages) =>
        messages.map((m) => {
          if (m.id === message.id) {
            return {
              ...m,
              actualId: message.actualId,
              status: MessageStatus.SENT,
              date: message.date,
            };
          }

          return m;
        })
      );
    };

    document.addEventListener('messageResent', catchMessage);

    return () => {
      document.removeEventListener('messageResent', catchMessage);
    };
  }, []);

  // new message event listener
  useEffect(() => {
    const catchMessage = (message) => {
      if (
        message.sender.id === user.id ||
        (message.sender.id === currentUser.id &&
          message.recipient.id === user.id)
      )
        setMessages((messages) => messages.concat(message));
    };

    socket.on('newMessage', catchMessage);

    return () => socket.off('newMessage', catchMessage);
  }, [socket, user?.id, currentUser?.id]);

  const contextValue = useMemo(
    () => ({
      loading,
      messages,
      user,
      chatDisabled,
      loadMore,
      loadingMore,
      isUserInfoOpen,
      openUserInfo,
    }),
    [
      loading,
      messages,
      user,
      chatDisabled,
      loadMore,
      loadingMore,
      isUserInfoOpen,
      openUserInfo,
    ]
  );

  return (
    <SelectedChatContext.Provider value={contextValue}>
      {children}
    </SelectedChatContext.Provider>
  );
};

const useSelectedChat = () => {
  return useContext(SelectedChatContext);
};

export { SelectedChatProvider, useSelectedChat };
