import styled from 'styled-components';
import Viewer from './Viewer';
import { FileIcon, ImageIcon, MusicIcon, VideoIcon } from 'assets/icons';
import FileType from '../ChatBox/Chat/Files/Preview/FileType';

const FileViewer = ({ name, type, size, url, onClose }) => {
  return (
    <StyledViewer name={name} size={size} url={url} onClose={onClose}>
      {type === FileType.Video && <VideoIcon size={2.4} />}
      {type === FileType.Image && <ImageIcon size={2.4} />}
      {type === FileType.Audio && <MusicIcon size={2.4} />}
      {type === FileType.Other && <FileIcon size={2.4} />}
    </StyledViewer>
  );
};

export default FileViewer;

const StyledViewer = styled(Viewer)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;
