import BillingKeys from '../translationKeys/BillingPageKeys';

export const BillingTranslations = {
  [BillingKeys.Payment_Subscription.title]: '支払いとサブスクリプション',
  [BillingKeys.Payment_Subscription.Method.title]: '支払い方法',
  [BillingKeys.Payment_Subscription.Method.btn]:
    'クレジットカード・デビットカードを追加',
  [BillingKeys.Payment_Subscription.Side.my_plan]: 'マイプラン',
  [BillingKeys.Payment_Subscription.Side.renews_on]: '更新日',

  [BillingKeys.Billing_History.title]: '請求履歴',
  [BillingKeys.Billing_History.search]: 'クリエイター名またはサービス名で検索',

  [BillingKeys.MEGA_Balance.title]: 'MEGA残高',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.title]: '出金方法',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with]:
    '出金方法 <icon></icon>',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon]: '近日公開',
  [BillingKeys.MEGA_Balance.Balance_Summary.title]: '残高概要',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance]:
    '利用可能な総残高',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn]: '総出金額',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment]: '保留中の支払い',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip]: `
      これには現在進行中の注文からの支払いが含まれます。<br>
      保留中の資金は、注文完了後24日後に出金可能になります。<br><br>
      アップロード後少なくとも60日間、注文されたコンテンツが削除されたり非公開になったりしないようにしてください。<TooltipLink>詳しくはこちら</TooltipLink>`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount]: '出金額',
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.details]: `
      各出金には2.0%のPayPal手数料が適用されます（手数料の上限は20 USDです）。<br>
      振込には3〜5営業日かかる場合があります。`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.btn]: '残高を出金する',
};
