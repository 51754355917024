import { StarIcon } from 'assets/icons';
import { motion } from 'framer-motion';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useMemo } from 'react';
import styled from 'styled-components';
import device from 'styles/device';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const Rating = ({ reviews, hideOnMobile, hideOnDesktop }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();

  const rating = useMemo(
    () =>
      (
        (reviews?.reduce((acc, curr) => acc + curr.rating, 0) || 0) /
        reviews?.length
      ).toFixed(1),
    [reviews]
  );

  return (
    <Wrapper
      className={`${hideOnMobile ? 'hidden md-flex' : ''}
    ${hideOnDesktop ? 'md-hidden' : ''}`}
    >
      <h6 className="sbh6 hidden md-block">{t(GeneralKeys.rating)}</h6>

      <Value className={`${isMobile ? 'h7' : 'text-sm'}`}>
        <Star size={isMobile ? 0.8 : 1} /> {rating > 0 ? rating : 'N/A'}
      </Value>
    </Wrapper>
  );
};

export default Rating;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  exit: { opacity: 0, y: 0 },
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (${device.sm}) {
    width: auto;
  }
`;

const Value = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.375rem;

  @media (${device.sm}) {
    align-items: center;
  }
`;

const Star = styled.span.attrs({
  children: <StarIcon size={0.7} />,
})`
  color: var(--color-yellow-500);
  display: flex;
  align-items: center;
  justify-content: center;
`;
