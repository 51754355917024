const link1 = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="20" height="20" rx="10" fill="black" />
    <path
      d="M11.0077 9.16709L14.9537 4.58008H14.0187L10.5923 8.56291L7.85562 4.58008H4.69922L8.83756 10.6028L4.69922 15.413H5.63437L9.25272 11.207L12.1428 15.413H15.2992L11.0074 9.16709H11.0077ZM9.72684 10.6559L9.30754 10.0562L5.97132 5.28405H7.40765L10.1 9.13529L10.5193 9.73502L14.0191 14.7411H12.5828L9.72684 10.6561V10.6559Z"
      fill="white"
    />
  </svg>
);
export default link1;
