import LazyImage from 'components/Basics/LazyImage';
import ProgressLoading from 'components/Basics/ProgressLoading';
import AxiosClient from 'components/utilities/AxiosClient';
import imageCompressor from 'components/utilities/imageCompressor';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const uploadFile = async (file, thumbnail, onProgress) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('thumbnail', thumbnail);

  const res = await AxiosClient.post('/admin/faq/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      onProgress(progress);
    },
  });

  return {
    src: res.data.file,
    thumbnail: res.data.thumbnail,
  };
};

const getSize = async (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
  });
};

const ImageUploader = ({
  isUploading,
  setIsUploading,
  setValue,
  image,
  thumbnail,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const onProgress = useCallback((progress) => {
    setUploadProgress(progress);
  }, []);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg'],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      setIsUploading(true);
      setUploadProgress(0);
      const file = acceptedFiles[0];
      if (!/image/.test(file.type)) {
        setIsUploading(false);
        alert('Only images are allowed');
        return;
      }
      const { width } = await getSize(file);
      setValue('src', URL.createObjectURL(file));
      setValue('thumbnail', URL.createObjectURL(file));
      setValue('width', width);
      setValue('height', 'auto');

      const compressed = await imageCompressor(file, 100);
      const { src, thumbnail } = await uploadFile(file, compressed, onProgress);
      setValue('src', src);
      setValue('thumbnail', thumbnail);
      setIsUploading(false);
    },
  });

  if (image) {
    return (
      <ImageWrapper>
        <LazyImage
          src={image}
          lazySrc={thumbnail}
          alt="uploaded"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />

        {isUploading && (
          <UploadingOverlay>
            <ProgressLoading
              progress={uploadProgress}
              radius={20}
              strokeWidth={5}
              baseColor="rgba(255, 255, 255, 0.5)"
              progressColor="rgba(255, 255, 255, 0.9)"
            />
            <p className="text-inherit">Uploading...</p>
          </UploadingOverlay>
        )}
      </ImageWrapper>
    );
  }

  return (
    <ImageUploaderContainer {...getRootProps()} isDragActive={isDragActive}>
      <input {...getInputProps()} />

      <p className="text-indigo-200">
        Drag 'n' drop an image here, or click to select an image
      </p>
    </ImageUploaderContainer>
  );
};

export default ImageUploader;

const ImageUploaderContainer = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: 2px dashed
    ${({ isDragActive }) =>
      isDragActive ? 'var(--color-indigo-200)' : 'var(--color-indigo-50)'};
  background: ${({ isDragActive }) =>
    isDragActive ? 'var(--color-indigo-10)' : 'none'};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &:hover {
    background: var(--color-indigo-10);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  max-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
`;

const UploadingOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: white;
`;
