export const VisibilityIcon = ({ size = 1, color = '#CFCFD7' }) => {
  return (
    <svg
      width={size * 18}
      height={size * 14}
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 10.6818C10.0227 10.6818 10.8922 10.324 11.6084 9.60837C12.324 8.89219 12.6818 8.02274 12.6818 7.00001C12.6818 5.97728 12.324 5.10783 11.6084 4.39165C10.8922 3.67601 10.0227 3.31819 9 3.31819C7.97727 3.31819 7.10782 3.67601 6.39164 4.39165C5.676 5.10783 5.31818 5.97728 5.31818 7.00001C5.31818 8.02274 5.676 8.89219 6.39164 9.60837C7.10782 10.324 7.97727 10.6818 9 10.6818ZM9 9.2091C8.38636 9.2091 7.86491 8.99419 7.43564 8.56437C7.00582 8.1351 6.79091 7.61365 6.79091 7.00001C6.79091 6.38637 7.00582 5.86465 7.43564 5.43483C7.86491 5.00556 8.38636 4.79092 9 4.79092C9.61364 4.79092 10.1354 5.00556 10.5652 5.43483C10.9945 5.86465 11.2091 6.38637 11.2091 7.00001C11.2091 7.61365 10.9945 8.1351 10.5652 8.56437C10.1354 8.99419 9.61364 9.2091 9 9.2091ZM9 13.1364C7.00909 13.1364 5.19545 12.5806 3.55909 11.4689C1.92273 10.3578 0.736364 8.86819 0 7.00001C0.736364 5.13183 1.92273 3.64192 3.55909 2.53028C5.19545 1.41919 7.00909 0.863647 9 0.863647C10.9909 0.863647 12.8045 1.41919 14.4409 2.53028C16.0773 3.64192 17.2636 5.13183 18 7.00001C17.2636 8.86819 16.0773 10.3578 14.4409 11.4689C12.8045 12.5806 10.9909 13.1364 9 13.1364ZM9 11.5C10.5409 11.5 11.9558 11.0942 13.2447 10.2826C14.5331 9.47147 15.5182 8.37728 16.2 7.00001C15.5182 5.62274 14.5331 4.52828 13.2447 3.71665C11.9558 2.90556 10.5409 2.50001 9 2.50001C7.45909 2.50001 6.04418 2.90556 4.75527 3.71665C3.46691 4.52828 2.48182 5.62274 1.8 7.00001C2.48182 8.37728 3.46691 9.47147 4.75527 10.2826C6.04418 11.0942 7.45909 11.5 9 11.5Z"
        fill={color}
      />
    </svg>
  );
};
