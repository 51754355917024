import LoginKeys from '../translationKeys/LoginPageKeys';

export const LoginTranslations = {
  // Google
  [LoginKeys.Google.sign_in]: 'Đăng nhập bằng Google',

  // Facebook
  [LoginKeys.Facebook.sign_in]: 'Đăng nhập bằng Facebook',
  [LoginKeys.Facebook.sign_up]: 'Đăng ký bằng Facebook',

  // Wallet
  [LoginKeys.Wallet.sign_in]: 'Đăng nhập bằng Ví điện tử',
  [LoginKeys.Wallet.sign_up]: 'Đăng ký bằng Ví điện tử',

  // Password
  [LoginKeys.Password.forgot]: 'Quên mật khẩu?',

  [LoginKeys.Password.Rule.title]: 'Mật khẩu của bạn phải chứa:',
  [LoginKeys.Password.Rule.l1]: '· Ít nhất 8 ký tự',
  [LoginKeys.Password.Rule.l2]: '· Ít nhất 1 ký tự chữ cái',
  [LoginKeys.Password.Rule.l3]: '· Ít nhất 1 số',
  [LoginKeys.Password.Rule.l4]: '· Ít nhất 1 ký tự đặc biệt (!,@,#,$,...)',

  // Login Form
  [LoginKeys.Login_Form.Placeholder.email]: 'Nhập Email',
  [LoginKeys.Login_Form.Placeholder.password]: 'Nhập mật khẩu',

  [LoginKeys.Login_Form.no_account]: 'Chưa có tài khoản?',
  [LoginKeys.Login_Form.create_account]: 'Tạo tài khoản',

  [LoginKeys.Login_Form.sign_up]: 'Đăng ký',

  // Sign Up Form
  [LoginKeys.Sign_Up_Form.Placeholder.email]: 'Đăng ký bằng email',
  [LoginKeys.Sign_Up_Form.Placeholder.password]: 'Nhập mật khẩu',

  [LoginKeys.Sign_Up_Form.Terms_of_Service.title]:
    'Vui lòng đồng ý với điều khoản dịch vụ',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all]:
    'Đồng ý với tất cả các Điều khoản',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description]:
    'Bao gồm các thỏa thuận cần thiết và tùy chọn. Bạn có thể chọn đồng ý hoặc không đồng ý với từng điều khoản. Bạn có thể sử dụng dịch vụ mà không phải đồng ý với bất kỳ điều khoản tùy chọn nào.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.age]: 'Tôi đã đủ 14 tuổi hoặc hơn.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions]:
    'Điều khoản & Điều kiện',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing]:
    'Nhận thông tin sự kiện & marketing',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing_description]:
    'Bạn có thể nhận được bản tin hữu ích và các thông tin khác như sự kiện và lợi ích được cung cấp bởi MegaEvolution qua email và ứng dụng.',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies]:
    'Chính sách bảo mật',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies]:
    'Chính sách thanh toán',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.optional]: '(tùy chọn)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.required]: '(bắt buộc)',
  [LoginKeys.Sign_Up_Form.Terms_of_Service.agree]: 'Đồng ý và Tham gia',

  [LoginKeys.Sign_Up_Form.go_back]: 'Quay lại đăng nhập',

  [LoginKeys.Sign_Up_Form.password_updated]:
    'Mật khẩu của bạn đã được cập nhật!',
  [LoginKeys.Sign_Up_Form.thank_you_for_signing_up]: 'Cảm ơn bạn đã đăng ký!',

  // Error
  [LoginKeys.Error.network]: 'Lỗi mạng - Vui lòng thử lại sau.',
  [LoginKeys.Error.incorrect]: 'Địa chỉ email hoặc mật khẩu không chính xác.',
  [LoginKeys.Error.recently_deleted]:
    'Tài khoản này vừa được xóa. Vui lòng thử lại sau 24 giờ.',
  [LoginKeys.Error.login_failed]: 'Đăng nhập thất bại. Vui lòng thử lại sau',
  [LoginKeys.Error.already_in_use]: 'Địa chỉ email này đã được sử dụng.',
  [LoginKeys.Error.wallet_not_verified]: 'Ví không thể được xác minh.',
  [LoginKeys.Error.already_in_use_or_connect_another_method]:
    'Địa chỉ email này đã được sử dụng hoặc đã được kết nối với phương thức đăng nhập khác.',
  [LoginKeys.Error.currently_deactiated_reset_password]:
    'Tài khoản này đang bị vô hiệu hóa. Vui lòng đặt lại mật khẩu để kích hoạt lại',
  [LoginKeys.Error.currently_deactiated_login]:
    'Tài khoản này đang bị vô hiệu hóa. Vui lòng thử đăng nhập để kích hoạt lại',
  [LoginKeys.Error.enter_valid_email]: 'Nhập địa chỉ email hợp lệ.',
  [LoginKeys.Error.invalid_request]: 'Yêu cầu không hợp lệ',
  [LoginKeys.Error.too_many_request]:
    'Quá nhiều yêu cầu đã được thực hiện. Thử lại sau.',
  [LoginKeys.Error.wallet_already_registered]: 'Ví này đã được đăng ký.',
  [LoginKeys.Error.try_sign_in_social_app]:
    'Vui lòng thử đăng nhập bằng ứng dụng xã hội của bạn.',
  [LoginKeys.Error.password_used]: 'Mật khẩu này đã được sử dụng trước đó.',

  // Modal
  [LoginKeys.Modal.Connect_Wallet.title]: 'Kết nối Ví',
  [LoginKeys.Modal.Connect_Wallet.description]:
    'Chọn một trong những nhà cung cấp ví có sẵn hoặc tạo ví trước.',

  [LoginKeys.Modal.Reset_Password.reset]: 'Đặt lại mật khẩu',

  [LoginKeys.Modal.Enter_Email.title]: 'Vui lòng nhập email của bạn',
  [LoginKeys.Modal.Enter_Email.description]: `
    Vui lòng nhập email để tạo tài khoản. <br>
    Bạn sẽ nhận được thông báo đặt hàng quan trọng ở đây.`,
  [LoginKeys.Modal.Enter_Email.Placeholder]: 'Nhập email',
  [LoginKeys.Modal.Enter_Email.mobile_placeholder]: 'abcd@gmail.com',

  [LoginKeys.Modal.Reset_Password.description]:
    'Mật khẩu của bạn đã hết hạn. Vui lòng chọn một mật khẩu mới.',
  [LoginKeys.Modal.Reset_Password.expired]: 'Mật Khẩu Của Bạn Đã Hết Hạn!',
  [LoginKeys.Modal.Reset_Password.reset]: 'Đặt Lại Mật Khẩu Của Bạn',
  [LoginKeys.Modal.Reset_Password.update_success]:
    'Mật Khẩu Của Bạn Đã Được Cập Nhật!',

  [LoginKeys.Modal.Reset_Password.Error.valid_password]:
    'Nhập một mật khẩu hợp lệ',

  [LoginKeys.Modal.Useful_Reward.title]:
    'Bạn sẽ nhận được thông tin phần thưởng hữu ích nếu bạn đăng ký!',
  [LoginKeys.Modal.Useful_Reward.description]: `
    Bạn có thể hủy đăng ký chỉ bằng một nhấp chuột bất cứ lúc nào! <br>
    Bạn vẫn sẽ nhận được thông báo đặt hàng quan trọng ngay cả khi <br>
    bạn không đăng ký nhận bản tin của chúng tôi`,
  [LoginKeys.Modal.Useful_Reward.button]: 'Được, Tôi Sẽ Thử!',

  [LoginKeys.Modal.Verify_Email.title]: 'Vui lòng xác minh email của bạn',
  [LoginKeys.Modal.Verify_Email.description]: `
    Kiểm tra email của bạn tại {{email}} và xác minh tài khoản của bạn.`,

  [LoginKeys.Modal.Check_Email.title]: 'Vui lòng Kiểm Tra Email của Bạn',
  [LoginKeys.Modal.Check_Email.description]: `
    Vui lòng kiểm tra email của bạn để đặt lại mật khẩu.`,
  [LoginKeys.Modal.Check_Email.button]: 'Gửi Email Lại',

  [LoginKeys.Modal.VIP_Influencer.description]: `
    Bạn là một nhà sáng tạo? Hãy đổi sang tài khoản nhà sáng tạo nội dung và nhận được các ưu đãi!`,
  [LoginKeys.Modal.VIP_Influencer.im_creator]: 'Tôi là người tạo nội dung!',
  [LoginKeys.Modal.VIP_Influencer.no_im_not]: 'Không, Tôi không phải',

  [LoginKeys.Modal.Welcome_On_Board.description]: `
    Chào mừng bạn đến với MEGA! <br>
    MEGA có một món quà dành cho bạn!`,
  [LoginKeys.Modal.Welcome_On_Board.hi_mega]: 'Xin chào, MEGA',

  [LoginKeys.Modal.Use_Another_Method.title]:
    'Vui lòng sử dụng phương thức đăng ký khác',
  [LoginKeys.Modal.Use_Another_Method.description]:
    'Dường như tài khoản Facebook của bạn không có địa chỉ email được kết nối. Để đăng ký hoặc đăng nhập vào nền tảng của chúng tôi bằng Facebook, cần có một địa chỉ email. Vui lòng sử dụng phương thức đăng ký khác để tạo tài khoản',

  [LoginKeys.Modal.Enter_Your_Email.title]: 'Nhập Email Của Bạn',
  [LoginKeys.Modal.Enter_Your_Email.description]:
    'Vui lòng nhập địa chỉ email của bạn để nhận liên kết để đặt lại mật khẩu.',
  [LoginKeys.Modal.Enter_Your_Email.placeholder]: 'Nhập email của bạn',

  [LoginKeys.Modal.Reactivate.title]: 'Tài khoản đang bị vô hiệu hóa',
  [LoginKeys.Modal.Reactivate.description]:
    'Bạn có muốn kích hoạt lại tài khoản của mình không?',
  [LoginKeys.Modal.Register_type.buyer.title]: 'Xác minh Công ty • Agency',
  [LoginKeys.Modal.Register_type.buyer.description]:
    'Mở khóa vĩnh viễn các lợi ích bằng cách xác minh email đơn giản.',
  [LoginKeys.Modal.Register_type.creator.description]:
    'Trở thành Nhà sáng tạo ngay bây giờ và bắt đầu nhận đơn hàng!',
  [LoginKeys.Modal.Register_type.skip]: 'Bỏ qua bây giờ',
};
