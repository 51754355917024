import styled from 'styled-components';
import { CheckIcon } from 'assets/icons';
import Tooltip from 'components/Basics/Tooltip';

const TypeButton = ({ type, active, disabled, onClick, tooltip }) => {
  return (
    <Tooltip content={tooltip}>
      <TextButton active={active} onClick={onClick} disabled={disabled}>
        {!disabled && active && <CheckIcon />}

        {type}
      </TextButton>
    </Tooltip>
  );
};

export default TypeButton;

const TextButton = styled.button.attrs({
  className: 'bh4',
})`
  border: none;
  background-color: transparent;
  display: flex;
  padding: 0;
  align-items: center;
  gap: 0.625rem;
  color: var(--color-indigo-500);
  text-transform: capitalize;
  transition: all 150ms ease-in-out;

  &:hover {
    color: var(--color-indigo-200);
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: var(--color-indigo-50);
  }

  &:disabled:hover {
    color: var(--color-indigo-50);
  }
`;
