import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $findMatchingParent,
  $getNearestNodeOfType,
} from '@lexical/utils';
import { $getSelection, $isRangeSelection, $isRootOrShadowRoot } from 'lexical';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
} from 'react';

import Tool from './Tool';

const ToolbarContext = createContext();

const Toolbar = ({ className, children }) => {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isHighlight, setIsHighlight] = useState(false);
  const [isUnorderedList, setIsUnorderedList] = useState(false);
  const [isOrderedList, setIsOrderedList] = useState(false);

  const appliedStyles = useMemo(() => {
    const styles = [];

    if (isBold) styles.push(Tool.BOLD);
    if (isItalic) styles.push(Tool.ITALIC);
    if (isUnderline) styles.push(Tool.UNDERLINE);
    if (isHighlight) styles.push(Tool.HIGHLIGHT);
    if (isUnorderedList) styles.push(Tool.UNORDERED_LIST);
    if (isOrderedList) styles.push(Tool.ORDERED_LIST);

    return styles;
  }, [
    isBold,
    isItalic,
    isUnderline,
    isHighlight,
    isUnorderedList,
    isOrderedList,
  ]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            }) || anchorNode.getTopLevelElementOrThrow();

      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);

      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsHighlight(selection.hasFormat('highlight'));

      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();

          if (type === 'ul') {
            setIsUnorderedList(true);
            setIsOrderedList(false);
          } else if (type === 'ol') {
            setIsUnorderedList(false);
            setIsOrderedList(true);
          }
        } else {
          setIsOrderedList(false);
          setIsUnorderedList(false);
        }
      }
    }
  }, [editor]);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar();
      });
    });
  }, [updateToolbar, editor]);

  return (
    <div className={className}>
      <ToolbarContext.Provider
        value={{
          appliedStyles,
        }}
      >
        {children}
      </ToolbarContext.Provider>
    </div>
  );
};

export default Toolbar;

export const useToolbar = () => {
  const context = useContext(ToolbarContext);

  if (context === undefined) {
    throw new Error('useToolbarContext must be used within a ToolbarContext');
  }

  const { appliedStyles } = context;

  return {
    appliedStyles,
  };
};
