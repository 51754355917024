import PrivacyPolicyKeys from '../translationKeys/PrivacyPolicyPageKeys';

export const PrivacyPolicyTranslations = {
  [PrivacyPolicyKeys.title]: 'Privacy Policy',

  [PrivacyPolicyKeys.top_text]: `At MegaEvolution we are committed to protecting your Personal
  Information and to being clear and transparent about what data is
  collected and used. This Privacy Policy explains how we process
  Personal Information that you provide or that is collected when you
  visit our platform at www.megaevolution.io.`,

  // Disclosure regarding Google APIs
  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.title]:
    'Disclosure regarding Google APIs',

  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description
    .l1]: `MegaEvolution use and transfer to any other app of information
  received from Google APIs will adhere to`,
  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description
    .l2]: `Google API Services User Data Policy`,
  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description
    .l3]: `, including the Limited Use requirements.`,

  // Who we are and how to contact us
  [PrivacyPolicyKeys.Who_We_Are_And_How_To_Contact_Us.title]:
    'Who we are and how to contact us',

  [PrivacyPolicyKeys.Who_We_Are_And_How_To_Contact_Us
    .description]: `We are Intaverse Inc. of 1178, 15, Teheran-ro 82-gil, Gangnam-gu,
  Seoul, Republic of Korea (Postal code 06178) trading as MegaEvolution,
  and in the case of your direct use of our platform or our services, we
  act as the data controller in accordance with South Korea’s Personal
  Information Protection Act (“PIPA”), the California Consumer Privacy
  Act (“CCPA”) and the subsequent amendments from the California Privacy
  Rights and Enforcement Act (“CPRA”). As well as the EU's General Data
  Protection Regulation (“GDPR”). If you have any questions about the
  processing of your Personal Information by us or about data protection
  in general, you can reach us at info@megaevolution.io.`,

  // How do we use your Personal Information?
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.title]:
    'How do we use your Personal Information?',

  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description
    .d1]: `All Personal Information that we obtain from you via the platform will
  only be processed for the purposes described in more detail below.
  This is done within the framework of the respective legal regulations
  mentioned above or only with your consent. In particular, we only
  process and collect Personal Information if:`,
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description
    .l1]: `you have given your consent,`,
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description
    .l2]: `the data is necessary for the fulfillment of a contract /
  pre-contractual measures,`,
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description
    .l3]: `the data is necessary for the fulfillment of a legal obligation,
  or`,
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description
    .l4]: `the data is necessary to protect the legitimate interests of our
    company, provided that your interests are not overridden.`,
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description
    .d2]: `In doing so, we only collect and process the data that is absolutely
  necessary to maintain and use the platform. Personal Information may
  be collected in two ways, that is directly when you for example
  volunteer it to us or automatically for example when you install and
  use our platform.`,
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description
    .d3]: `We process and store your Personal Information only for the period of
  time required to achieve the respective processing purpose or for as
  long as a legal retention period (in particular commercial and tax
  law) exists. Once the purpose has been achieved or the retention
  period has expired, the corresponding data is routinely deleted.`,

  // Personal Information you provide to us directly
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.title]:
    'Personal Information you provide to us directly',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.A.title]:
    'a) Contacting us and using our services',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.A
    .description]: `If you contact us, book a general consultation or one of our other
  services offered we process the data from you for the purpose of
  processing and handling your request and the contract. This may
  include your first name, last name, e-mail address, and, if
  applicable, other information such as your phone number, if you have
  provided it. The legal basis for the data processing is our obligation
  to fulfill the contract and/or to fulfill our pre-contractual
  obligations and/or our overriding legitimate interest in processing
  your request. Some of the data you choose to provide may be considered
  “sensitive” or special category data, by choosing to provide this
  data, you consent to our processing of that data. We ask you not to
  provide us with any such information from the outset. If such
  information is relevant to the services, we process it together with
  your other data.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.B.title]:
    'b) Financial data',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.B
    .description]: `If you make a purchase or sell a service, your payment will be
  processed our 3rd party payment service provider and payment data will
  solely be processed through their payment system. The legal basis for
  the provision of a payment system is the establishment and
  implementation of the user contract for the use of the service.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.C.title]:
    'c) Creating an account',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.C
    .description]: `You can voluntarily create an account, through which we can store your
  data for future purchases. When you create an account under "My
  Account", the data you provide will be stored revocably. You can
  always delete all further data, including your user account, in your
  account settings. The legal basis for the data processing is your
  consent. Alternatively, you are able to sign up using the convenience
  log in and sign up from available wallet providers (MetaMask, Coinbase
  Wallet, WalletConnect, and others.) or Google. For wallet providers
  and Google log in and sign up, you will be asked to provide your basic
  information (i.e., name, email address, and display picture) linked to
  your account. If granted, your username and password will be auto
  generated to fill in the rest of required user data. When registering
  via the wallet providers's and Google's connect function, you agree to
  the relevant terms and conditions and consent to certain data from
  your respective profile of being transferred to us.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.D.title]:
    'd) Profile',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.D
    .description]: `As a registered user, you have the opportunity to create a user
  profile with just a few clicks and details. If you make use of the
  option, the relevant profile data you provide will be transferred to
  your profile. Of course, you can change the information at any time
  via the settings in your profile. When creating a profile, you can
  submit Personal Information. You have choices about the information on
  your profile. You don’t have to provide additional information on your
  profile; however, profile information helps you to get more from our
  Services. It’s your choice whether to include sensitive information on
  your profile and to make that sensitive information public. Please do
  not post or add Personal Information to your profile that you would
  not want to be available. The legal basis for the processing of your
  Personal Information is the establishment and implementation of the
  user contract for the use of the service. We store the data until you
  delete your user account. Insofar as legal retention periods are to be
  observed, storage also takes place beyond the time of deletion of a
  user account.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.E.title]:
    'e) Reviews',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.E
    .description]: `Within your review you may be able to display certain information,
  share certain details, engage with others, exchange knowledge and
  insights, post and view relevant content. Content and data are
  publicly viewable. You have choices about the information on your
  review. You don’t have to provide additional information on your
  review. It’s your choice whether to include sensitive information on
  your review and to make that sensitive information public. Please do
  not post or add Personal Information in your review that you would not
  want to be available.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.F.title]:
    'f) MegaEvolution social media channels',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.F
    .description]: `We are present on social network on the basis of our legitimate interest currently X, Discord, YouTube, Instagram, etc. If you contact us via social media service platforms, you should note that the chat history can neither be deleted by us nor by you. And that, in accordance with the GDPR, the relevant social network service platform and we are jointly responsible for the processing of your data and enter into a so-called joint controller agreement. A Joint Controller Agreement itself is very legalistic and lengthy, but in a nutshell, it clarifies how the jointly responsible parties will fulfill the obligations arising from data protection laws that are applicable to them. The legal basis for the use of the relevant social network service platform is our legitimate interest, your consent or, in the case of a (pre) contractual relationship with us, the initiation of a contractual service, if any.`,

  // Personal Information you provide to us automatically
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically
    .title]: 'Personal Information you provide to us automatically',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .title]: 'a) Cookies',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .Description
    .l1]: `To improve the services provided on the platform, we use cookies that
  collect information about your usage behavior. Cookies are files that
  your web browser stores on your hard drive when you visit a website.
  Cookies may, under certain circumstances, personally identify you
  either directly (for example, with an e-mail address) or indirectly
  (for example, with a unique identification code of a cookie, an IP
  address or the identification code of a device). We store data for
  only authenticated users (the users that sign up to MegaEvolution).
  The data stored may include the pages you visit, the date and time of
  your visit, and other tracking information. For more information,
  please refer to our`,
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .Description.l2]: `Cookie Policy`,
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .Description.l3]: `.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.B
    .title]: 'b) Social network connection',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.B
    .description]: `MegaEvolution collects additional statistics and information about a
  user through such cases when the user connects a third party social
  network (such as Google, YouTube, Facebook, Instagram, X,
  Tiktok, Discord, etc.) account to our system. The information that we
  collect may include your social network account specific statistical
  data such as channel statistics, contents statistics, number of
  subscribers, audience and subscriber data, channel information, etc.
  We also collect profile data including username, email address,
  profile picture, banner picture, etc. The collected data of your
  connected social network account will be used to feature your
  MegaEvolution profile and services to other users using our website.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.C
    .title]: 'c) Access data and log files',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.C
    .description]: `We also collect data on every access to our platform. The access data
  includes the name of the website accessed, file, date and time of
  access, amount of data transferred, notification of successful access,
  browser type and version, the user's operating system, referrer URL
  (the previously visited page), IP address and the requesting provider.
  Log file information is stored for security reasons (e.g., for the
  clarification of abuse or fraud) for a maximum of 7 days and then
  deleted. Data whose further storage is necessary for evidentiary
  purposes is exempt from deletion until the respective incident is
  finally clarified. The legal basis for the data processing is our
  legitimate interest in providing an appealing platform.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.D
    .title]: 'd) Hosting',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.D
    .description]: `To provide our platform, we use the services of Amazon Web Services
  (AWS) who process the below-mentioned data and all data to be
  processed in connection with the operation of our platform on our
  behalf. The legal basis for the data processing is our legitimate
  interest in providing our platform.`,

  // 3rd Party Services & Links
  [PrivacyPolicyKeys._3rd_Party_Services_Links.title]:
    '3rd Party Services & Links',

  [PrivacyPolicyKeys._3rd_Party_Services_Links.C
    .description]: `We integrate below listed services and privacy policy from 3rd
  parties.`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.A.title]: 'a) Google Analytics',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.A
    .description]: `We use Google Analytics to evaluate your use of our platform, to
  compile reports on the activities and to provide other services
  related to the use of our platform in order to improve the user
  experience. When Google Analytics is used, interactions of website
  visitors are primarily recorded and systematically evaluated with the
  help of cookies. This represents a legitimate interest but also
  requires your consent due to the data transfer in the USA and the
  analytical functionality.`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.B.title]:
    'b) Google Tag Manager',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.B
    .description]: `We also use Google Tag Manager. This service allows website tags to be
  managed via an interface. The Google Tag Manager only implements tags.
  No cookies are set, and no Personal Information is collected. The
  Google Tag Manager triggers other tags that may collect data. The
  Google Tag Manager does not access this data. If a deactivation has
  been made at domain or cookie level, it remains in place for all
  tracking tags, insofar as these are implemented with the Google Tag
  Manager.`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.title]:
    'c) Priavcy Policies of 3rd Parties We Integrate With',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d1]: `The following applies to all users.`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d2]: `Google Privacy Policy`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d3]: `YouTube API Terms of Service`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d4]: `YouTube Terms of Service`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d5]: `Google API Services User Data Policy`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.D.title]:
    'd) Other website Links',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.D
    .description]: `We provide links to other third party websites including our business
  partner websites. For all third party websites and links,
  MegaEvolution is not responsible for the privacy policies or practices
  or the included contents. Third party links are provided for
  convenience purposes only and MegaEvolution is not responsible for the
  risk of accessing or using them.`,

  // Disclosure to third parties
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.title]:
    'Disclosure to third parties',

  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l1]: `We will not disclose or otherwise distribute your Personal Information
  to third parties unless this:`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l2]: `· is necessary for the performance of our services,`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l3]: `· you have consented to the disclosure,`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l4]: `· or the disclosure of data is permitted by relevant legal provisions.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l5]: `However, we are entitled to outsource the processing of your Personal
  Information in whole or in part to external service providers acting
  as processors within the framework of the respective legal regulations
  mentioned above. External service providers support us, for example,
  in the technical operation and support of the platform (see above),
  data management, the provision and performance of services, marketing,
  as well as the implementation and fulfillment of reporting
  obligations.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l6]: `The service providers commissioned by us however will process your
  data exclusively in accordance with our instructions and we remain in
  accordance with the respective legal regulations mentioned above
  responsible for the protection of your data. Doing so we always make
  sure that service providers commissioned by us are carefully selected,
  follow strict contractual regulations, technical and organizational
  measures, and additional controls by us.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l7]: `Further, we might, making available, transfer, communicate
  electronically, Personal Information by the business to a business
  affiliated inclusive with a third party but not for monetary but for
  other valuable consideration.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l8]: `We may also disclose Personal Information to third parties if we are
  legally obliged to do so e.g., by court order or if this is necessary
  to support criminal or legal investigations or proceedings at home or
  abroad or to fulfill our legitimate interests.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l9]: `Transfers of data to recipients into third countries are not intended,
  however if we do this is governed by Processing Agreements that
  include Standard Contractual Clauses to ensure a high level of data
  protection.`,

  // Marketing
  [PrivacyPolicyKeys.Marketing.title]: 'Marketing',
  [PrivacyPolicyKeys.Marketing
    .description]: `Insofar as you have also given us your consent to process your
  Personal Information for marketing and advertising purposes, we are
  entitled to contact you for these purposes via the communication
  channels you have given your consent to. You may give us your consent
  in a number of ways including by selecting a box on a form where we
  seek your permission, or sometimes your consent is implied from your
  interactions or contractual relationship. Where your consent is
  implied, it is on the basis that you would have a reasonable
  expectation of receiving a marketing communication based on your
  interactions or contractual relationship with us. Our Marketing
  generally takes the form of e-mail but may also include other less
  traditional or emerging channels. These forms of contact will be
  managed by us, or by our contracted service providers. Every directly
  addressed marketing sent by us or on our behalf will include a means
  by which you may unsubscribe or opt out.`,

  // Direct Marketing Disclosures
  [PrivacyPolicyKeys.Direct_Marketing_Disclosures.title]:
    'Direct Marketing Disclosures',
  [PrivacyPolicyKeys.Direct_Marketing_Disclosures
    .description]: `California's “Shine the Light” law (Civil Code Section 1798.83)
  requires businesses to respond to requests from California customers
  asking about the business’s practices related to disclosing Personal
  Information to third parties for the third parties’ direct marketing
  purposes. You may make a request about our collection and disclosure
  of your Personal Information using the contact details provided.`,

  // Automated decision-making
  [PrivacyPolicyKeys.Automated_Decision_Making.title]:
    'Automated decision-making',
  [PrivacyPolicyKeys.Automated_Decision_Making
    .description]: `Automated decision-making including profiling does not take place at
  MegaEvolution`,

  // Data of Children
  [PrivacyPolicyKeys.Data_Of_Children.title]: 'Data of Children',
  [PrivacyPolicyKeys.Data_Of_Children
    .description]: `Our services are aimed at people aged 14 and over. We will not
    knowingly collect, use, or disclose Personal Information from minors
    under the age of 14 without first obtaining consent from a legal
    guardian through direct offline contact.`,

  // Do Not Sell
  [PrivacyPolicyKeys.Do_Not_Sell.title]: 'Do Not Sell',
  [PrivacyPolicyKeys.Do_Not_Sell
    .description]: `We do not sell Personal Information to third parties.`,

  // Data Security
  [PrivacyPolicyKeys.Data_Security.title]: 'Data Security',
  [PrivacyPolicyKeys.Data_Security
    .description]: `Technical and organizational measures have been implemented by us to
    ensure the protection, security and integrity of your Personal
    Information. Access to Personal Information is restricted to those who
    have a need to know and who have been trained to comply with
    confidentiality requirements. Appropriate mechanisms include, for
    example, SSL encryption (so that data is unreadable by others) during
    the collection and transmission of data. Said data is used only for
    the purpose it was collected for and we assure you that we will do our
    utmost to ensure that your Personal Information is not altered or
    corrupted and is not accessed by unauthorized third parties.
    Nonetheless, databases or data sets that include Personal Information
    may be breached inadvertently or through wrongful intrusion. Upon
    becoming aware of a data breach, we will notify all affected
    individuals whose Personal Information may have been compromised, and
    the notice will be accompanied by a description of the action being
    taken to reconcile any damage as a result of the data breach. Notices
    will be provided as expeditiously as possible after which the breach
    was discovered.`,

  // Your rights under data protection legislation
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.title]:
    'Your rights under data protection legislation',

  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l1]: `Under the PIPA, you have the following rights:`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l2]: `· Right to be informed`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l3]: `· Right to access`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l4]: `· Right to rectification`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l5]: `· Right to erasure`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l6]: `· Right to object/opt-out`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l7]: `· Right to Consent`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l8]: `· Right to Redressal`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l9]: `Under the CCPA and the CPRA amendment, you can exercise the following
  rights:`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l10]: `· Right to Know/Access`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l11]: `· Right to Delete`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l12]: `· Right to Opt-out of Sale`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l13]: `· Right to Non-Discrimination`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l14]: `· Right to Rectification`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l15]: `· Right to Limit Use and Disclosure of Sensitive Personal Information`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l16]: `Under the GDPR, you can exercise the following rights:`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l17]: `· Right to information`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l18]: `· Right to rectification`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l19]: `· Right to object to processing`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l20]: `· Right to deletion`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l21]: `· Right to information`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l22]: `· Right to data portability`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l23]: `· Right of objection`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l24]: `· Right to withdraw consent`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l25]: `· Right to complain to a supervisory authority`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l26]: `· Right not to be subject to a decision based solely on automated
          processing.`,

  // Updating and Deleting your Information
  [PrivacyPolicyKeys.Updating_Your_Information.title]:
    'Updating and Deleting your Information',
  [PrivacyPolicyKeys.Updating_Your_Information
    .description]: `If you believe that the information, we hold about you is inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion, or object to its processing, you can always delete and/or update your data through your personalized settings dashboard on our website. You may also do so by contacting us through our website or through a separate writing at info@megaevolution.io. <br><br> 
    There are no restrictions on who can take this action—any user is fully entitled to update or delete their data directly or request us to do so on their behalf. However, data associated to other users' orders may not be deletable.`,

  // Access Request and updating your Personal Information
  [PrivacyPolicyKeys.Access_Request_And_Updating_Your_Personal_Information
    .title]: 'Access Request and updating your Personal Information',
  [PrivacyPolicyKeys.Access_Request_And_Updating_Your_Personal_Information
    .description]: `In the event that you wish to make a Data Subject Access Request, you may inform us through our website or in separate writing at info@megaevolution.io. We will respond to requests regarding access and correction as soon as reasonably possible. Should we not be able to respond to your request within thirty (30) days after receiving your request, we will inform you in writing within thirty (30) days of the time by which we will be able to respond to your request. If we are unable to provide you with any Personal Information or to make a correction requested by you, we shall generally inform you of the reasons why we are unable to do so (except where we are not required to do so under the respective legal regulations mentioned above).`,

  // Controls For Do-Not-Track Features
  [PrivacyPolicyKeys.Controls_For_Do_Not_Track_Features.title]:
    'Controls For Do-Not-Track Features',
  [PrivacyPolicyKeys.Controls_For_Do_Not_Track_Features
    .description]: `Most web browsers and some mobile operating systems and mobile
    applications include a Do-Not-Track ('DNT') feature or setting you can
    activate to signal your privacy preference not to have data about your
    online browsing activities monitored and collected. At this stage no
    uniform technology standard for recognizing and implementing DNT
    signals has been finalized. As such, we do not currently respond to
    DNT browser signals or any other mechanism that automatically
    communicates your choice not to be tracked online. If a standard for
    online tracking is adopted that we must follow in the future, we will
    inform you about that practice in a revised version of this statement.`,

  // Does this policy change?
  [PrivacyPolicyKeys.Does_This_Policy_Change.title]: 'Does this policy change?',
  [PrivacyPolicyKeys.Does_This_Policy_Change
    .description]: `We may update our Privacy Policy from time to time. This might be for
    a number of reasons, such as to reflect a change in the law or to
    accommodate a change in our business practices and the way we use
    cookies. We recommend that you check here periodically for any changes
    to our Privacy Policy.`,

  // Who should I contact for more information?
  [PrivacyPolicyKeys.Who_Should_I_Contact_For_More_Information.title]:
    'Who should I contact for more information?',
  [PrivacyPolicyKeys.Who_Should_I_Contact_For_More_Information
    .description]: `If you have any questions or comments about our Privacy Policy or wish
    to exercise your rights under applicable laws, please contact us.`,
};
