export const DownloadIcon = ({ size = 1, color = 'currentColor', style }) => (
  <svg
    width={13 * size}
    height={13 * size}
    viewBox="0 0 13 13"
    fill={color}
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00343 8.64657L6.00341 8.64659L6.00577 8.64886C6.07151 8.71179 6.14888 8.76129 6.23355 8.7946C6.3176 8.83122 6.4083 8.85013 6.5 8.85013C6.5917 8.85013 6.6824 8.83122 6.76645 8.7946C6.85112 8.76129 6.92849 8.71179 6.99423 8.64886L6.99425 8.64888L6.99657 8.64657L9.19657 6.44657C9.32826 6.31487 9.40225 6.13625 9.40225 5.95C9.40225 5.76375 9.32826 5.58513 9.19657 5.45343C9.06487 5.32174 8.88625 5.24775 8.7 5.24775C8.51375 5.24775 8.33513 5.32174 8.20343 5.45343L8.2032 5.45367L7.2 6.46125V1.55C7.2 1.36435 7.12625 1.1863 6.99497 1.05503C6.8637 0.92375 6.68565 0.85 6.5 0.85C6.31435 0.85 6.1363 0.92375 6.00503 1.05503C5.87375 1.1863 5.8 1.36435 5.8 1.55V6.46125L4.7968 5.45367L4.79657 5.45343C4.73136 5.38822 4.65394 5.3365 4.56874 5.30121L4.51134 5.43979L4.56874 5.3012C4.48354 5.26591 4.39222 5.24775 4.3 5.24775C4.20778 5.24775 4.11646 5.26591 4.03126 5.3012L4.08866 5.43979L4.03126 5.30121C3.94606 5.3365 3.86864 5.38822 3.80343 5.45343C3.73822 5.51864 3.6865 5.59606 3.65121 5.68126C3.61591 5.76646 3.59775 5.85778 3.59775 5.95C3.59775 6.04222 3.61591 6.13354 3.65121 6.21874C3.6865 6.30394 3.73822 6.38136 3.80343 6.44657L6.00343 8.64657ZM11.45 7.45C11.2643 7.45 11.0863 7.52375 10.955 7.65503C10.8237 7.7863 10.75 7.96435 10.75 8.15V10.35C10.75 10.4561 10.7079 10.5578 10.6328 10.6328C10.5578 10.7079 10.4561 10.75 10.35 10.75H2.65C2.54391 10.75 2.44217 10.7079 2.36716 10.6328C2.29214 10.5578 2.25 10.4561 2.25 10.35V8.15C2.25 7.96435 2.17625 7.7863 2.04497 7.65503C1.9137 7.52375 1.73565 7.45 1.55 7.45C1.36435 7.45 1.1863 7.52375 1.05503 7.65503C0.92375 7.7863 0.85 7.96435 0.85 8.15V10.35C0.85 10.8274 1.03964 11.2852 1.37721 11.6228C1.71477 11.9604 2.17261 12.15 2.65 12.15H10.35C10.8274 12.15 11.2852 11.9604 11.6228 11.6228C11.9604 11.2852 12.15 10.8274 12.15 10.35V8.15C12.15 7.96435 12.0763 7.7863 11.945 7.65503C11.8137 7.52375 11.6357 7.45 11.45 7.45Z"
      strokeWidth="0.3"
    />
  </svg>
);
