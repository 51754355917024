import styled from 'styled-components';
import Modal from 'components/Basics/Modal';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import Stack from 'components/Basics/Layout/Stack';
import {
  DocumentCheckIcon,
  LoadingIcon,
  TimeIcon,
  UndoIcon,
} from 'assets/icons';
import ContentIcon from './ContentIcon';
import { useEffect, useState } from 'react';
import MessageBox from './MessageBox';
import PlatformIcon from 'components/Basics/PlatformIcon';
import IndigoRoundButton from 'components/Basics/Buttons/IndigoRoundButton';
import requestPrice from './api/requestPrice';
import { useToast } from 'components/Basics/Toast';
import MobileVersion from './MobileVersion';
import { useToggle } from 'react-use';
import LazyImage from 'components/Basics/LazyImage';
import { flatten } from 'lodash';
import contentTypes from 'constants/contentTypes';
import { useTranslation } from 'react-i18next';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import { Trans } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const contentsMap = flatten(Object.values(contentTypes)).reduce((acc, curr) => {
  acc[curr.value] = curr.label;
  return acc;
}, {});

const MAX_CHAR = 2000;

const RequestPackagePriceModal = () => {
  const { isMobile } = useResponsiveScreen();
  const [message, setMessage] = useState('');
  const toast = useToast();
  const [loading, toggleLoading] = useToggle(false);
  const { t } = useTranslation();
  const { getPreferredText } = useAutoTranslation();
  const [data, setData] = useState();
  const [isOpen, toggleIsOpen] = useState(false);

  useEffect(() => {
    const catchModalClose = (e) => {
      if (e?.detail?.data) {
        setData(e?.detail?.data);
        toggleIsOpen(true);
        if (!isMobile) {
          e.stopImmediatePropagation();
        }
      }
    };

    window.addEventListener('open-request-price-modal', catchModalClose);

    return () => {
      window.removeEventListener('open-request-price-modal', catchModalClose);
    };
  }, [setData, isMobile]);

  const submit = () => {
    toggleLoading(true);
    requestPrice({
      userId: data?.creatorId,
      creatorHandle: data?.creatorHandle,
      platform: data?.currentPackage?.platform,
      tier: data?.currentPackage?.tier,
      message,
    })
      .then(() => {
        toast.success(t(ProfileKeys.Modal.Request_Price.toast));
        setMessage('');
        toggleIsOpen(false);
      })
      .catch((err) => {
        if (err?.response?.data?.key === 'QUOTA_EXCEEDED_FOR_SENDING_MESSAGE') {
          toggleIsOpen(false);
          const event = new CustomEvent('open-message-quota-modal', {
            detail: {
              event: true,
              data: {
                onClose: () => toggleIsOpen(true),
                messageTo: data?.creatorId,
                messageToHandle: data?.creatorHandle,
              },
            },
          });
          window.dispatchEvent(event);
        } else {
          toast.error(t(ProfileKeys.Menu.Error.something_went_wrong));
        }
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  if (!data) return null;

  return (
    <>
      <Modal
        title={t(ProfileKeys.Modal.Request_Price.title)}
        isOpen={isOpen}
        onClose={() => (loading ? {} : toggleIsOpen(false))}
        size="lg"
        fullScreen={isMobile}
        backdropClosesModal={false}
      >
        {!isMobile && (
          <ModalContent>
            <Container>
              <LazyImage
                src={data?.currentPackage?.featuredContent}
                lazySrc={data?.currentPackage?.minFeaturedContent}
                width="128px"
                height="72px"
                radius="0.25rem"
              />
              <Content>
                <h5 className="sbh5">
                  {getPreferredText(data?.currentPackage?.title)}
                </h5>
                <Stack
                  direction="row"
                  gap="1.25rem"
                  align="center"
                  style={{
                    height: '19px',
                  }}
                >
                  <PlatformIcon
                    platform={data?.currentPackage?.platform}
                    variant="size4"
                  />
                  <ContentIcon
                    text={
                      <Trans
                        values={{ number: data?.currentPackage?.delivery }}
                      >
                        {data?.currentPackage?.delivery > 1
                          ? t(GeneralKeys.day_plural)
                          : t(GeneralKeys.day_singular)}
                      </Trans>
                    }
                    icon={<TimeIcon size={1.067} />}
                  />

                  <ContentIcon
                    text={
                      <Trans
                        values={{
                          number: Object.values(
                            data?.currentPackage?.contents
                          )?.reduce((acc, curr) => acc + curr, 0),
                        }}
                      >
                        {Object.values(data?.currentPackage?.contents)?.reduce(
                          (acc, curr) => acc + curr,
                          0
                        ) > 1
                          ? t(GeneralKeys.contents_plural)
                          : t(GeneralKeys.contents_singular)}
                      </Trans>
                    }
                    icon={<DocumentCheckIcon size={0.89} />}
                    tooltip={Object.keys(data?.currentPackage?.contents).map(
                      (key, index) => (
                        <>
                          {t(contentsMap[key])} x{' '}
                          {data?.currentPackage?.contents[key]}
                          {index !==
                            Object.keys(data?.currentPackage?.contents).length -
                              1 && <br />}
                        </>
                      )
                    )}
                  />
                  {data?.currentPackage?.revisions !== null ? (
                    <ContentIcon
                      text={
                        data?.currentPackage?.revisions === -1 ? (
                          t(GeneralKeys.unlimited_revisions)
                        ) : (
                          <Trans
                            values={{ number: data?.currentPackage?.revisions }}
                          >
                            {data?.currentPackage?.revisions > 1
                              ? t(GeneralKeys.revisions)
                              : t(GeneralKeys.revision)}
                          </Trans>
                        )
                      }
                      icon={<UndoIcon />}
                    />
                  ) : null}
                </Stack>
              </Content>
              <h5 className="sbh5">$--</h5>
            </Container>

            <Stack gap="1.5rem">
              <MessageBox
                value={message}
                onChange={setMessage}
                maxChar={MAX_CHAR}
              />
              <IndigoRoundButton
                size="md"
                onClick={submit}
                disabled={loading}
                className="mx-auto"
              >
                {loading && <LoadingIcon size={0.8} />}
                {t(ProfileKeys.Modal.Request_Price.btn)}
              </IndigoRoundButton>
            </Stack>
          </ModalContent>
        )}
        <MobileVersion
          currentPackage={data?.currentPackage}
          message={message}
          setMessage={setMessage}
          onSubmit={submit}
          maxChar={MAX_CHAR}
        />
      </Modal>
    </>
  );
};

export default RequestPackagePriceModal;

const ModalContent = styled.div`
  padding: 1.5rem 0 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Container = styled.div`
  display: flex;
  gap: 1rem;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.375rem;
`;
