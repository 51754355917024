const AutoTranslationKeys = {
  Dropdown: {
    title: 'AutoTranslationKeys.Dropdown.title',
    description: 'AutoTranslationKeys.Dropdown.description',
  },
  Applying_Translation: {
    title: 'AutoTranslationKeys.Applying_Translation.title',
    btn: 'AutoTranslationKeys.Applying_Translation.btn',
  },
  Displaying_Original: {
    title: 'AutoTranslationKeys.Displaying_Original.title',
    btn: 'AutoTranslationKeys.Displaying_Original.btn',
  },
};

export default AutoTranslationKeys;
