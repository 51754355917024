export const SparkleIcon = ({ size = 1 }) => (
  <svg
    width={12 * size}
    height={13 * size}
    viewBox="0 0 12 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.01775 12.3799C5.54965 12.3922 5.16778 12.1089 5.1185 11.7516C5.08155 11.4683 5.08155 11.1788 5.08771 10.8955C5.12466 9.65751 4.50258 8.69667 3.78811 7.78511C3.47399 7.38476 2.99973 7.19999 2.48236 7.18767C2.03274 7.17535 1.57695 7.18151 1.12733 7.17535C0.924078 7.16919 0.71467 7.16919 0.517576 7.12608C0.25273 7.07064 0.00635219 6.76268 0.00019298 6.50399C-0.00596623 6.23299 0.135696 6.01125 0.369746 5.91887C0.979507 5.68482 1.58928 5.43229 2.22367 5.29063C3.54174 4.99499 4.22541 4.08342 4.6504 2.91317C4.74278 2.6668 4.68735 2.35884 4.68119 2.08168C4.66887 1.55198 4.6196 1.0223 4.63808 0.492612C4.64424 0.233925 4.83517 0.0429806 5.11234 0.00602532C5.3895 -0.0309299 5.60508 0.104573 5.70363 0.344782C5.86377 0.757449 6.03622 1.18243 6.11629 1.61974C6.52896 3.79394 8.51223 5.34607 10.7295 5.20441C10.9328 5.19209 11.1361 5.14281 11.3393 5.15513C11.5918 5.16745 11.8259 5.27831 11.9306 5.53083C12.0538 5.82032 12.0168 6.11597 11.7889 6.32538C11.6165 6.48552 11.3886 6.61486 11.1607 6.67645C9.47307 7.11991 8.34593 8.30249 7.30502 9.57745C7.04018 9.90388 6.97858 10.4028 6.83692 10.8278C6.71373 11.2158 6.65215 11.6285 6.47969 11.9919C6.39962 12.1766 6.13478 12.2875 6.01775 12.3799ZM8.37672 6.42392C7.82239 6.06052 7.32966 5.78337 6.88619 5.44461C6.44889 5.10585 6.0547 4.70551 5.62355 4.31132C5.0877 4.9334 4.59497 5.50621 4.05912 6.12829C4.84134 6.61487 5.28481 7.29853 5.70979 8.00068C5.82682 8.19162 5.99927 8.3456 6.18405 8.56118C6.5844 8.14851 6.92315 7.79743 7.27423 7.45252C7.6253 7.11376 7.98253 6.79347 8.37672 6.42392Z" />
  </svg>
);
