import SettingsKeys from '../translationKeys/SettingsPageKeys';

export const SettingsTranslations = {
  // Signed with
  [SettingsKeys.Signed_With.wallet]: 'Inició sesión con',
  [SettingsKeys.Signed_With.google]: 'Inició sesión con Google',
  [SettingsKeys.Signed_With.facebook]: 'Inició sesión con Facebook',

  // Account
  [SettingsKeys.Account.connected_email]: 'Email Conectado',

  // Password
  [SettingsKeys.Password.current_password]: 'Contraseña actual',
  [SettingsKeys.Password.new_password]: 'Nueva contraseña',
  [SettingsKeys.Password.confirm_new_password]: 'Confirmar nueva contraseña',

  [SettingsKeys.Password.Rule.title]: 'Tu contraseña debe contener',
  [SettingsKeys.Password.Rule.rule_1]: '8~20 caracteres',
  [SettingsKeys.Password.Rule.rule_2]: 'Al menos 1 letra mayúscula',
  [SettingsKeys.Password.Rule.rule_3]: 'Al menos 1 letra minúscula',
  [SettingsKeys.Password.Rule.rule_4]: 'Al menos 1 número o carácter especial',

  [SettingsKeys.Password.Error.not_follow_rule]:
    'La contraseña no cumple las reglas',
  [SettingsKeys.Password.Error.characters]:
    '8~20 caracteres que consisten en letras (A-Z, a-z), números o caracteres especiales.',
  [SettingsKeys.Password.Error.not_match]: 'Las contraseñas no coinciden.',
  [SettingsKeys.Password.Error.current_password_incorrect]:
    'La contraseña actual es incorrecta.',

  [SettingsKeys.Password.update_success]:
    '¡Tu contraseña se actualizó correctamente!',

  // Notifications
  [SettingsKeys.Notification.Description.orders]:
    'Recibir correos electrónicos sobre el estado de mis pedidos.',
  [SettingsKeys.Notification.Description.messages]:
    'Recibir correos electrónicos para mensajes.',
  [SettingsKeys.Notification.Description.reminders]:
    'Recibir correos electrónicos recordándome fechas de vencimiento.',
  [SettingsKeys.Notification.Description.newsletters]:
    'Recibir correos electrónicos útiles sobre mis recompensas, nuevas funciones, promociones, etc.',
  [SettingsKeys.Notification.Description.packages]:
    'Recibir correos electrónicos sobre paquetes, incluyendo desbloqueos de precios e interacciones entre clientes y creadores.',
  [SettingsKeys.Notification.Description.real_time]: 'Activar Sonido',

  // Referral Code
  [SettingsKeys.Referral_Code.My.title]: 'Mi Código de Referencia',
  [SettingsKeys.Referral_Code.My.description]: 'Conoce sobre las recompensas',
  [SettingsKeys.Referral_Code.My.copy_code]: 'Copiar Código',
  [SettingsKeys.Referral_Code.My.copied]: '¡Copiado exitosamente!',

  [SettingsKeys.Referral_Code.Enter.title]: 'Ingresa un Código',

  [SettingsKeys.Referral_Code.Modal.reward_arrive]:
    'Tu recompensa de referencia ha llegado.',
  [SettingsKeys.Referral_Code.Modal.my_evo]: 'Mi EVO',
  [SettingsKeys.Referral_Code.Modal.check_later]: 'Verificar luego',

  [SettingsKeys.Referral_Code.Referral_Page.instruction]:
    'Por favor, ingresa un código de referencia válido.\nPuedes encontrarlo en la pestaña de Configuración.',
  [SettingsKeys.Referral_Code.Referral_Page.evo_policy]:
    'Puedes ganar EVO puntos adicionales.\nPor favor, consulta nuestra política',
  [SettingsKeys.Referral_Code.Referral_Page.enter_code_here]:
    'Ingresa un código de referencia aquí',

  // Referral System
  [SettingsKeys.Referral_System.title]: 'Sistema de Referencia',
  [SettingsKeys.Referral_System.button]: 'Copiar Código de Referencia',

  [SettingsKeys.Referral_System.Refer_A_Friend.lang]: 'en',
  [SettingsKeys.Referral_System.Referral_Reward.lang]: 'en',

  // Deactivate/Delete
  [SettingsKeys.Deactivate_Delete.title]: 'Desactivar/Borrar',

  [SettingsKeys.Deactivate_Delete.Q1.title]:
    '¿Qué pasa si desactivo mi cuenta?',
  [SettingsKeys.Deactivate_Delete.Q1.description]: `
    <heading><span>{{dot}}</span> Tu perfil no aparecerá en la lista de búsqueda hasta que reactives tu cuenta.</heading> <br>
    <heading><span>{{dot}}</span> Los datos almacenados en tu cuenta estarán ocultos.</heading> <br>
    <heading><span>{{dot}}</span> Puedes reactivar tu cuenta volviendo a iniciar sesión.</heading>`,

  [SettingsKeys.Deactivate_Delete.Q2.title]: '¿Qué sucede si borro mi cuenta?',
  [SettingsKeys.Deactivate_Delete.Q2.description]: `
  <heading><span>{{dot}}</span> Tu perfil se eliminará de la lista de búsqueda.</heading> <br>
  <heading><span>{{dot}}</span> Los datos almacenados en tu cuenta se eliminarán permanentemente.</heading> <br>
  <heading><span>{{dot}}</span> No podrás reactivar tu cuenta.</heading>`,

  [SettingsKeys.Deactivate_Delete.warning]:
    'Si tienes algún pedido en curso, no podrás desactivar/borrar tu cuenta. Por favor, cancela tu(s) pedido(s) antes de desactivar/borrar tu cuenta.',

  // Modal
  [SettingsKeys.Modal.Wallet_Connected.title]:
    '¡Tu nueva billetera se ha conectado exitosamente!',

  [SettingsKeys.Modal.Notification.Newsletters.title]:
    '¿Estás seguro de que quieres cancelar la suscripción a nuestros boletines?',
  [SettingsKeys.Modal.Notification.Newsletters.description]:
    'Si te das de baja, no recibirás actualizaciones sobre tus recompensas, nuevas características y más. ¡Haremos nuestro mejor esfuerzo para proporcionar información útil y valiosa para ti!',

  [SettingsKeys.Modal.Notification.Update_Success.title]:
    '¡Tus notificaciones se han actualizado exitosamente!',

  [SettingsKeys.Modal.Referral_Code.Submit_Success.title]:
    '¡Tu código se ha enviado con éxito!',
  [SettingsKeys.Modal.Referral_Code.Submit_Success.description]:
    'Ahora puedes ganar puntos EVO adicionales al unirte a diversas actividades en nuestra plataforma. Por favor, <u>haga clic aquí</u> para obtener más información sobre las recompensas del código de referencia.',

  [SettingsKeys.Modal.Referral_Code.Submit_Valid.title]:
    'Por favor, ingresa un código de referencia válido',
  [SettingsKeys.Modal.Referral_Code.Submit_Valid.description]:
    'El código que has ingresado no es válido. Por favor, ingresa un código de referencia válido.',

  [SettingsKeys.Modal.Deactivate.title]:
    '¿Estás seguro de que quieres desactivar tu cuenta?',
  [SettingsKeys.Modal.Deactivate.description]:
    'Puedes reactivar tu cuenta si vuelves a iniciar sesión.',

  [SettingsKeys.Modal.Delete.title]:
    '¿Estás seguro de que quieres borrar tu cuenta?',
  [SettingsKeys.Modal.Delete.description]:
    'Una vez que borras tu cuenta, no podrás reactivarla. Tus datos se eliminarán y no se podrán recuperar. Los mensajes y datos relacionados con pedidos pueden permanecer visibles para los destinatarios.',

  [SettingsKeys.Modal.Complete_Cancel_Order.title]:
    'Completa / cancela tu(s) pedido(s) para desactivar tu cuenta.',
  [SettingsKeys.Modal.Complete_Cancel_Order.description]:
    'No puedes desactivar tu cuenta hasta que canceles o completes tus pedidos en curso.',

  [SettingsKeys.Modal.Why_Leaving.title]: 'Por favor, dinos por qué te vas',
  [SettingsKeys.Modal.Why_Leaving.reason_1]:
    'No hay servicio que quiera utilizar.',
  [SettingsKeys.Modal.Why_Leaving.reason_2]: 'El precio es demasiado alto.',
  [SettingsKeys.Modal.Why_Leaving.reason_3]: 'Hay errores frecuentes.',
  [SettingsKeys.Modal.Why_Leaving.reason_4]:
    'Hay pocos beneficios como cupones y reservas.',
  [SettingsKeys.Modal.Why_Leaving.reason_5]: 'La calidad del servicio es baja.',
  [SettingsKeys.Modal.Why_Leaving.reason_6]: 'He encontrado una alternativa.',
  [SettingsKeys.Modal.Why_Leaving.reason_7]:
    'He tenido encuentros desrespetuosos con otros miembros.',
  [SettingsKeys.Modal.Why_Leaving.reason_8]: 'Quiero eliminar mis datos.',
  [SettingsKeys.Modal.Why_Leaving.button]: 'Borrar Cuenta',

  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title]:
    'Aún no puedes desactivar o eliminar tu cuenta',
  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description]:
    'Puedes desactivar o eliminar una cuenta 24 horas después de haber sido creada por primera vez. Por favor, inténtalo de nuevo luego.',
};
