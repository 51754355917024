import { createContext, useContext, useState } from 'react';
import { useAsync } from 'react-use';
import getDetails, { Metric as DetailsMetric } from '../apis/getDetails';
import getTotal, { Metric as TotalMetric } from '../apis/getTotal';
import getHistorical, {
  Metric as HistoricalMetric,
} from '../apis/getHistorical';
import { AnalyticsType, AnalysisOptions } from '../constants';
import { useAnalytics } from '..';
import Details from 'components/components/MessagesPage/UserInfo/Details';

const ChannelAnalyticsContext = createContext();

const filters = AnalysisOptions[AnalyticsType.Channel];

const ChannelAnalyticsProvider = ({
  children,
  userId,
  handle,
  platform,
  isBasic,
}) => {
  const [selectedFilter, selectFilter] = useState(filters[1].value);
  const [analysisOption, selectAnalysisOption] = useState('periodic');
  const { isUserAdmin } = useAnalytics();

  const {
    value: details,
    loading: detailsLoading,
    error: detailsError,
  } = useAsync(() => {
    if ((!userId && !handle) || !!isUserAdmin) return Promise.resolve({});

    return getDetails({
      userId,
      handle,
      negateMetrics: true,
      platform,
      metrics: [
        DetailsMetric.AgeGender,
        DetailsMetric.ViewsPerCountry,
        DetailsMetric.Topics,
        DetailsMetric.CustomUrl,
        DetailsMetric.Banner,
        DetailsMetric.ProfilePicMedium,
        DetailsMetric.ProfilePicHigh,
        DetailsMetric.Description,
      ],
      isBasic,
    });
  }, [userId, handle, platform, isUserAdmin]);

  const {
    value: total,
    loading: totalLoading,
    error: totalError,
  } = useAsync(() => {
    if ((!userId && !handle) || !!isUserAdmin) return Promise.resolve({});

    return getTotal({
      userId,
      handle,
      metrics: [
        TotalMetric.Videos,
        TotalMetric.PrevVideos,
        TotalMetric.AvgViews,
        TotalMetric.PrevAvgViews,
        TotalMetric.AvgLikes,
        TotalMetric.PrevAvgLikes,
        TotalMetric.Engagement,
        TotalMetric.PrevEngagement,
      ],
      interval: selectedFilter,
      platform,
    });
  }, [userId, handle, selectedFilter, platform, isUserAdmin]);

  const {
    value: historical,
    loading: historicalLoading,
    error: historicalError,
  } = useAsync(() => {
    if (!userId && !handle) return Promise.resolve({});

    return getHistorical({
      userId,
      handle,
      platform,
      metrics: [
        HistoricalMetric.Day,
        HistoricalMetric.Views,
        HistoricalMetric.SubscribersFollowers,
      ],
      interval: selectedFilter,
    });
  }, [userId, handle, selectedFilter, platform]);

  return (
    <ChannelAnalyticsContext.Provider
      value={{
        filters,
        selectedFilter,
        selectFilter,
        analysisOption,
        selectAnalysisOption,
        details,
        detailsLoading,
        detailsError,
        total,
        totalLoading,
        totalError,
        historical,
        historicalLoading,
        historicalError,
      }}
    >
      {children}
    </ChannelAnalyticsContext.Provider>
  );
};

const useChannelAnalytics = () => {
  const context = useContext(ChannelAnalyticsContext);
  if (!context) {
    throw new Error(
      'useChannelAnalytics must be used within ChannelAnalyticsProvider'
    );
  }

  const {
    filters,
    selectedFilter,
    selectFilter,
    analysisOption,
    selectAnalysisOption,
  } = context;

  return {
    filters,
    selectedFilter,
    selectFilter,
    analysisOption,
    selectAnalysisOption,
  };
};

export default ChannelAnalyticsContext;
export { ChannelAnalyticsProvider, useChannelAnalytics };
