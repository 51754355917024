import Heading from 'components/Basics/Typography/Heading';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Stack from './../../Stack';

const Box = ({ icon, label, to, onClick, toggleVisible, labelColor = 'var(--color-indigo-500)' }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    toggleVisible();
    if(to)
      navigate(to)
    else 
      onClick()
  }

  return (
    <Stack onClick={handleClick}
      direction='row'
      align='center'
      justify='center'
      gap='0.5rem'
    >
      {icon}
      <Label variant='BH7' color={labelColor}>
        {label}
      </Label>
    </Stack>
  );
};
export default Box;

const Label = styled(Heading)`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  cursor: pointer;

  &:hover {
    color: var(--color-indigo-100);
  }
  
  &:active {
    color: var(--color-indigo-100);
  }
`;