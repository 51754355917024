import ProfileKeys from '../translationKeys/ProfilePageKeys';

export const ProfileTranslations = {
  // Menu
  [ProfileKeys.Menu.edit]: '프로필 편집',
  [ProfileKeys.Menu.save]: '프로필 저장',
  [ProfileKeys.Menu.message_me]: '메시지 보내기',

  // Menu Error
  [ProfileKeys.Menu.Error.creator_to_creator]:
    '크리에이터로 등록되지 않은 계정을 이용해주십시오.',
  [ProfileKeys.Menu.Error.something_went_wrong]: '문제가 발생했습니다.',
  [ProfileKeys.Menu.Error.self_chat]: '메시지를 보낼 수 없습니다.',

  // Package
  [ProfileKeys.Package.edit]: '패키지 편집',
  [ProfileKeys.Package.no_content]: '이용 가능한',
  [ProfileKeys.Package.available]: '콘텐츠가 없습니다',
  [ProfileKeys.Package.click_to_view]: '클릭해서 더보기',
  [ProfileKeys.Package.click_to_view_recent]: '클릭해서 최근 {{content}} 보기',
  [ProfileKeys.Package.view_recent_videos]: '최근 영상 보기',

  // Topbar
  [ProfileKeys.Package.TopBar.tooltip]: '패키지 준비 중',

  // YouTube
  [ProfileKeys.Package.YouTube.Contents.video_prepare]: '패키지 준비 중',
  [ProfileKeys.Package.YouTube.Contents.video_display]:
    '여기에 비디오가 표시됩니다.',

  // Description
  [ProfileKeys.Package.Description.default]: `
    크리에이터가 현재 패키지를 준비 중입니다. <br>
    나중에 다시 확인해주세요.`,

  // Details
  [ProfileKeys.Package.Details.Price.contact_me]: '크리에이터 문의',
  [ProfileKeys.Package.Details.Price.contact_creator]: '크리에이터 문의',
  [ProfileKeys.Package.Details.Price.service_fee]: '수수료 포함',
  [ProfileKeys.Package.Details.Price.tooltip]: `
    다른 크리에이터의 패키지로 <br>
    다시 시도해주세요.`,

  [ProfileKeys.Package.Details.Toast.update_successful]:
    '성공적으로 업데이트되었습니다!',
  [ProfileKeys.Package.Details.Toast.could_not_update]:
    '업데이트를 할 수 없습니다!',

  // Creator Summary
  [ProfileKeys.Creator_Summary.title]: '크리에이터 개요',
  [ProfileKeys.Creator_Summary.sub_title]: '크리에이터의 기본 채널 정보',
  [ProfileKeys.Creator_Summary.package_for_other_platforms]:
    '다른 플랫폼용 패키지',

  // Basic Info
  [ProfileKeys.Creator_Summary.BasicInfo.hollow]: '소셜 네트워크 준비 중',

  // Tooltip
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers]: '',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers]: '',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos]:
    '전체 동영상 수',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets]:
    '전체 X 게시물 수',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts]: '전체 포스트 수',

  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube]:
    '유튜브 가입일',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter]: 'X 가입일',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram]:
    '인스타 가입일',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok]:
    '틱톡 가입일',

  // Channel Contents
  [ProfileKeys.Channel_Contents.title]: '채널 콘텐츠',
  [ProfileKeys.Channel_Contents.sub_title]:
    '콘텐츠 참여 수준을 측정하는 스마트 지표',
  [ProfileKeys.Channel_Contents.views_to_subs]: '조회수 대 구독자',
  [ProfileKeys.Channel_Contents.views_to_followers]: '조회수 대 팔로워',
  [ProfileKeys.Channel_Contents.reach_to_followers]: '도달 대 팔로워',
  [ProfileKeys.Channel_Contents.est_engagement]: '예상 참여율',
  [ProfileKeys.Channel_Contents.channel_data]: '채널 데이터',
  [ProfileKeys.Channel_Contents.loading_post]: '게시물 로딩 중',

  [ProfileKeys.Channel_Contents.Twitter.feed]: 'X 피드',
  [ProfileKeys.Channel_Contents.Twitter.loading]: 'X 게시물 로딩 중',
  [ProfileKeys.Channel_Contents.Twitter.most_popular]:
    '가장 인기 있는 X 게시물',
  [ProfileKeys.Channel_Contents.Tiktok.loading]: '틱톡 로딩 중',

  [ProfileKeys.Channel_Contents.Filter._1_month]: '1개월',
  [ProfileKeys.Channel_Contents.Filter._3_months]: '3개월',
  [ProfileKeys.Channel_Contents.Filter._1_year]: '1년',
  [ProfileKeys.Channel_Contents.Filter.newest]: '최신순',
  [ProfileKeys.Channel_Contents.Filter.most_viewed]: '뷰 많은 순',
  [ProfileKeys.Channel_Contents.Filter.most_liked]: '좋아요 순',
  [ProfileKeys.Channel_Contents.Filter.most_commented]: '댓글 순',

  [ProfileKeys.Channel_Contents.Tooltip.youtube]: `
    본 통계는 지난 1~6개월의 데이터를 <br>
    기반으로 집계되었으며, 지난 1년간 <br>
    업로드된 영상을 확인할 수 있습니다.`,
  [ProfileKeys.Channel_Contents.Tooltip.twitter]: `
    X의 일부 데이터에는 예상치를 사용하여.<br>
    실시간 데이터와 차이가 있을 수 있습니다. <br>
    지난 1~3개월간의 데이터를 기반으로 합니다.`,
  [ProfileKeys.Channel_Contents.Tooltip.instagram]: `
    본 통계는 지난 1~3개월간의 <br>
    데이터를 기반으로 합니다.`,
  [ProfileKeys.Channel_Contents.Tooltip.tiktok]: `
    본 통계는 지난 1~3개월간의 <br>
    데이터를 기반으로 합니다.`,

  [ProfileKeys.Channel_Contents.Tooltip.likes]: '조회수 대 좋아요',
  [ProfileKeys.Channel_Contents.Tooltip.comments]: '조회수 대 댓글',
  [ProfileKeys.Channel_Contents.Tooltip.x_reposts]: '조회수 대 X 리포스트',
  [ProfileKeys.Channel_Contents.Tooltip.shares]: '조회수 대 공유',

  [ProfileKeys.Channel_Contents.Tooltip.views_subs_rating]:
    '조회수 대 구독자 비율',
  [ProfileKeys.Channel_Contents.Tooltip.views_follower_rating]:
    '조회수 대 팔로워 비율',
  [ProfileKeys.Channel_Contents.Tooltip.engagements]: '전체 참여율',

  // Channel Analytics
  [ProfileKeys.Channel_Analytics.title]: '채널 분석',
  [ProfileKeys.Channel_Analytics.sub_title]:
    '채널 지표롤 통해 전반적인 퍼포먼스를 측정',
  [ProfileKeys.Channel_Analytics.updated_on]: '업데이트 날짜: {{date}}',
  [ProfileKeys.Channel_Analytics.avg_views]: '평균 조회수',
  [ProfileKeys.Channel_Analytics.avg_impressions]: '평균 노출수',
  [ProfileKeys.Channel_Analytics.avg_reach]: '평균 도달',
  [ProfileKeys.Channel_Analytics.avg_likes]: '평균 좋아요',
  [ProfileKeys.Channel_Analytics.avg_x_reposts]: '평균 X 리포스트',
  [ProfileKeys.Channel_Analytics.engagements]: '참여율',

  // Views Analysis
  [ProfileKeys.Channel_Analytics.Views_Analysis.title]: '조회수 분석',
  [ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram]: '도달 분석',
  [ProfileKeys.Channel_Analytics.Views_Analysis.periodic]: '단기 통계',
  [ProfileKeys.Channel_Analytics.Views_Analysis.total]: '누적 통계',

  // Subscribers Data
  [ProfileKeys.Channel_Analytics.Subscribers_Data.title]: '구독자 데이터',

  // Followers Data
  [ProfileKeys.Channel_Analytics.Followers_Data.title]: '팔로워 데이터',

  // Audience Analytics
  [ProfileKeys.Audience_Analytics.title]: '시청자 분석',
  [ProfileKeys.Audience_Analytics.sub_title]:
    '채널의 대상 시청자를 위한 빅 데이터 분석',
  [ProfileKeys.Audience_Analytics.preparing_data]: '데이터 준비중',

  // Audience Interest
  [ProfileKeys.Audience_Analytics.Audience_Interest.title]: '시청자 관심사',
  [ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags]: '인기 태그',
  [ProfileKeys.Audience_Analytics.Keyword_Cloud.title]: '키워드 클라우드',
  [ProfileKeys.Audience_Analytics.Top_10.title]: 'TOP 10 키워드',

  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title]:
    'MY 요금제 확인',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description]: `
    더 많은 데이터를 확인하려면 <br>
    요금제를 확인하고 업그레이드하세요.`,

  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.title]: '로그인 필요',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.description]: `
    크리에이터의 시청자 데이터를 <br>
    확인하려면 먼저 로그인하세요.`,

  // Review
  [ProfileKeys.Review.review_as_client]: '고객 리뷰',
  [ProfileKeys.Review.most_recent]: '최신순',
  [ProfileKeys.Review.most_helpful]: '추천순',
  [ProfileKeys.Review.no_reviews]: '표시할 리뷰가 없습니다',
  [ProfileKeys.Review.recent_clients]: '최근 고객',

  [ProfileKeys.Review.star]: '별점',
  [ProfileKeys.Review.stars]: '별점',

  // Edit
  [ProfileKeys.Edit.Toast.being_saved]: '프로필 저장중입니다.',
  [ProfileKeys.Edit.Toast.saved]: '프로필이 성공적으로 저장되었습니다!',
  [ProfileKeys.Edit.Toast.failed]:
    '프로필 저장에 실패했습니다. 다시 시도해주세요.',

  // Package
  [ProfileKeys.Edit.Package.complete_percentage]: '패키지 {{number}}% 완료',

  [ProfileKeys.Edit.Package.Topbar.tooltip]: `
    패키지가 완성되면 <br>
    프로필에 나타납니다.`,

  [ProfileKeys.Edit.Package.Topbar.Representative.tooltip]: `
    대표로 설정된 패키지가 <br>
    유저 및 기업 측에 <br>
    우선적으로 노출됩니다.`,

  [ProfileKeys.Edit.Package.Topbar.Completion.tooltip]: `
    대표 패키지로 <br>
    선택하기 위해서는 <br>
    패키지를 완료해야합니다.`,

  // Banner
  [ProfileKeys.Edit.Package.Banner.heading]:
    '추천 배너 비율 1920X318 / 최대 사이즈 20MB',
  [ProfileKeys.Edit.Package.Banner.mobile_heading]:
    '최대 사이즈 20 MB / 1920X318 배너',
  [ProfileKeys.Edit.Package.Banner.tooltip]: `
    배너 이미지에 <br>
    그림자 효과를 추가합니다.`,

  // Options
  [ProfileKeys.Edit.Package.Options.intro_and_closing]: '소개 및 마무리',
  [ProfileKeys.Edit.Package.Options.product_review]: '제품 리뷰',
  [ProfileKeys.Edit.Package.Options.visit_review]: '방문 리뷰',
  [ProfileKeys.Edit.Package.Options.digital_product_review]: '디지털 제품 리뷰',
  [ProfileKeys.Edit.Package.Options.brand_ambassador]: '브랜드 앰배서더',
  [ProfileKeys.Edit.Package.Options.short_video]: '짧은 동영상',
  [ProfileKeys.Edit.Package.Options.product_placement]: '제품 홍보',
  [ProfileKeys.Edit.Package.Options.interview]: '인터뷰',
  [ProfileKeys.Edit.Package.Options.commercial_break]: '영상 중간 광고 삽입',
  [ProfileKeys.Edit.Package.Options.live_streaming]: '라이브 스트리밍',
  [ProfileKeys.Edit.Package.Options.giveaways]: '이벤트/선물 증정',
  [ProfileKeys.Edit.Package.Options.meme_and_artwork]: '밈 / 아트워크',
  [ProfileKeys.Edit.Package.Options.interview_and_ama]: '인터뷰 / AMA',

  // Title
  [ProfileKeys.Edit.Package.Title.use_a_template]: '템플릿 사용',
  [ProfileKeys.Edit.Package.Title.placeholder]: '패키지 제목 입력',
  [ProfileKeys.Edit.Package.Title.placeholder2]: '패키지 제목을 입력하세요.',

  // Featured Image
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.title]: '대표 썸네일',
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip]: `
    채널 스타일을 잘 반영하는 <br>
    유튜브 썸네일을 권장드립니다.`,

  [ProfileKeys.Edit.Package.Featured_Image.title]: '대표 이미지',
  [ProfileKeys.Edit.Package.Featured_Image.placeholder]:
    'JPG, JPEG, PNG 파일이 허용됩니다. 16:9 권장. 최대 200MB.',
  [ProfileKeys.Edit.Package.Featured_Image.tooltip]: `
    해당 이미지는 패키지의 <br>
    대표 썸네일로 사용됩니다.`,

  [ProfileKeys.Edit.Package.Featured_Image.Error.file_upload]: `
    파일이 아직 업로드 중 입니다. <br>
    다시 시도해주세요.`,
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_large]:
    '파일 크기가 너무 큽니다. (최대 ',
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid]:
    '잘못된 파일 형식입니다. (JPG, JPEG, PNG을 사용해주세요)',

  // Sample Contents
  [ProfileKeys.Edit.Package.Sample_Contents.title]: '예시 콘텐츠',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube]:
    '비디오 링크를 복사하여 붙여넣기',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter]:
    'X 링크를 복사하여 붙여넣기',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram]:
    '포스팅 링크를 복사하여 붙여넣기',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok]:
    '틱톡 링크를 복사하여 붙여넣기',

  [ProfileKeys.Edit.Package.Sample_Contents.add_more_contents]:
    '더 많은 콘텐츠 추가',
  [ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link]:
    '잘못된 링크입니다',

  // Description
  [ProfileKeys.Edit.Package.Description.placeholder]: `
    여기에 서비스에 대한 세부 정보를 설명해주세요. <br>
    기업 측은 다음과 관련된 정보를 찾을 수 있을 수 있습니다. <br><br>
    <ul>
      <li>주요 콘텐츠 또는 서비스</li>
      <li>주요 시청자 / 타겟</li>
      <li>세부 일정</li>
    </ul>`,
  [ProfileKeys.Edit.Package.Description.placeholder_gpt]:
    '문장을 입력하기 시작하면 ChatGPT가 최적의 글을 제안합니다.',

  [ProfileKeys.Edit.Package.Description.ChatGPT.title]:
    'ChatGPT를 사용하여 독특한 패키지 만들기',
  [ProfileKeys.Edit.Package.Description.ChatGPT.tooltip]: `
    서비스를 가장 잘 설명하는 것을 선택하세요.`,

  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title]:
    '추가 정보',
  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder]:
    '설명에 포함되어야 할 추가 정보를 입력하세요.',

  // Price
  [ProfileKeys.Edit.Package.Price.placeholder]: '가격 입력',
  [ProfileKeys.Edit.Package.Price.tooltip]: `
    다양한 가격 옵션을 사용하여 기업과 소통하세요. <br>
    가격 없이 직접 문의하도록 하거나, 인증된 기업에게만 <br>
    가격을 노출할 수도 있습니다.`,

  [ProfileKeys.Edit.Package.Price.Type.open]:
    '공개 가격 - 모든 클라이언트에게 표시',
  [ProfileKeys.Edit.Package.Price.Type.unlockable]:
    '잠금 해제 가격 - 인증된 기업에게만 표시',
  [ProfileKeys.Edit.Package.Price.Type.contact_me]: '직접 문의 - 가격 없음',

  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title]:
    '메시지를 보내려면 인증이 필요합니다',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description]: `
    더 많은 크리에이터에게 메시지를 보내려면 간단한 인증 절차를 완료해야 합니다. 인증에는 1-2분 정도 소요됩니다. 인증 후에 다시 시도해주세요. <br><br> 
    다음 갱신 날짜: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title]:
    '더 많은 메시지를 보내려면 플랜을 업그레이드하세요',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description]: `
    크리에이터에게 메시지를 보내려면 플랜을 업그레이드해야 합니다. <br> 
    사용중인 플랜 확인하고 다시 시도해주세요. <br><br> 
    다음 갱신 날짜: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title]:
    '가격을 해제하려면 인증이 필요합니다',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description]: `
    더 많은 가격을 해제하려면 간단한 인증 절차를 완료해야 합니다. 인증에는 1-2분 정도 소요됩니다. 인증 후에 다시 시도해주세요. <br><br> 
    다음 갱신 날짜: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title]:
    '더 많은 가격을 해제하려면 플랜을 업그레이드하세요',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
    .description]: `
    다음 갱신 날짜: {{date}} <br> 
    이 가격을 해제하려면 플랜을 업그레이드하세요.`,
  [ProfileKeys.Edit.Package.Price.Modal.btn]: '지금 업그레이드',

  [ProfileKeys.Edit.Package.Price.toast]: `
    성공적으로 잠금 해제되었습니다! ({{number}} 남음)`,

  [ProfileKeys.Edit.Package.Price.Error.minimum]:
    '최소 가격은 {{number}}입니다',
  [ProfileKeys.Edit.Package.Price.Error.maximum]:
    '최대 가격은 {{number}}입니다',

  // Contents
  [ProfileKeys.Edit.Package.Contents.YouTube.video]: '유튜브 영상',
  [ProfileKeys.Edit.Package.Contents.YouTube.shorts]: '유튜브 쇼츠',
  [ProfileKeys.Edit.Package.Contents.YouTube.live]: '유튜브 라이브',

  [ProfileKeys.Edit.Package.Contents.Twitter.post]: 'X 게시물',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_post]:
    '인스타그램 포스트',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_story]:
    '인스타그램 스토리',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels]:
    '인스타그램 릴스',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_live]:
    '인스타그램 라이브',

  [ProfileKeys.Edit.Package.Contents.Tiktok.video]: '틱톡 영상',
  [ProfileKeys.Edit.Package.Contents.Tiktok.live]: '틱톡 라이브',

  // Delivery
  [ProfileKeys.Edit.Package.Delivery.placeholder]:
    '업로드까지 총 소요 기한 입력 (7~60일)',
  [ProfileKeys.Edit.Package.Delivery.input]: '일',
  [ProfileKeys.Edit.Package.Delivery.tooltip]: `
    콘텐츠를 업로드하기 위해 <br>
    필요한 기한을 선택합니다.`,

  // Revisions
  [ProfileKeys.Edit.Package.Revisions.no_revisions]: '수정 불가능',
  [ProfileKeys.Edit.Package.Revisions.tooltip]: `
    클라이언트에게 제공 가능한 <br>
    콘텐츠 수정 횟수입니다.`,

  // Type/Length
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    '예: 5~10분짜리 제품 리뷰 비디오',

  // User Info
  // Username
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    '예: 5~10분 짜리 제품 리뷰 동영상',

  // User Info
  // Username
  [ProfileKeys.Edit.User_Info.Username.placeholder]: '사용자명 입력',
  [ProfileKeys.Edit.User_Info.Username.Error.enter_name]:
    '사용자명을 입력해 주세요',
  [ProfileKeys.Edit.User_Info.Username.Error.max_50]: '최대 50 글자',
  [ProfileKeys.Edit.User_Info.Username.Error.max_30]:
    '3~30 글자를 입력해 주세요',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol]:
    '올바르지 않은 기호 또는 공백이 있습니다.',
  [ProfileKeys.Edit.User_Info.Username.Error.max_2000]: '최대 2000 글자',
  [ProfileKeys.Edit.User_Info.Username.Error.max_100]: '최대 100 글자',
  [ProfileKeys.Edit.User_Info.Username.Error.max_500]: '최대 500 글자',
  [ProfileKeys.Edit.User_Info.Username.Error.max_200]: '최대 200 글자',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_price]:
    '올바르지 않은 가격입니다',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time]:
    '올바르지 않은 배송 기간입니다',
  [ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60]:
    '배송 기간은 1에서 60 사이여야 합니다',

  // My URL
  [ProfileKeys.Edit.User_Info.My_URL.placeholder]: 'URL을 입력하세요',

  [ProfileKeys.Edit.User_Info.My_URL.Error.char_limit]:
    '3~30 글자를 입력하세요',
  [ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol]:
    '잘못된 특수문자나 띄어쓰기가 있습니다',
  [ProfileKeys.Edit.User_Info.My_URL.Error.required_handle]: 'URL을 입력하세요',
  [ProfileKeys.Edit.User_Info.My_URL.Error.already_taken]:
    '해당 URL은 이미 사용 중입니다',

  // Social Network
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.priority]:
    '우선 순위를 변경할 수 없습니다',
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink]:
    '플랫폼과의 연결을 해제할 수 없습니다',

  [ProfileKeys.Edit.User_Info.Social_Network.Popup.title]:
    '주문을 먼저 완료해 주세요',
  [ProfileKeys.Edit.User_Info.Social_Network.Popup.description]:
    '진행 중인 주문을 취소하거나 완료한 후에 인증된 소셜 네트워크 계정을 연결 해제할 수 있습니다.',

  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.title]:
    '이 계정을 연결 해제하시겠습니까?',
  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.description]:
    '인증된 소셜 네트워크 계정을 연결 해제하면 해당 계정과 관련된 데이터가 삭제될 수 있습니다. 또한 관련된 패키지 정보가 프로필 및 검색 결과에 더 이상 나타나지 않습니다.',
  // Bio
  [ProfileKeys.Edit.User_Info.Bio.placeholder_creator]:
    '글로벌 브랜드와 클라이언트와의 이야기를 공유하세요!',
  [ProfileKeys.Edit.User_Info.Bio.placeholder_client]:
    '글로벌 크리에이터들과 여러분의 이야기를 공유하세요!',

  // Categories
  [ProfileKeys.Edit.User_Info.Categories.add_a_category]: '카테고리 추가',

  // Target Countries
  [ProfileKeys.Edit.User_Info.Target_Countries.title]: '국가',
  [ProfileKeys.Edit.User_Info.Target_Countries.add_a_country]: '국가 추가',
  [ProfileKeys.Edit.User_Info.Target_Countries.tooltip]: `
  현재 거주중이거나 본인을 가장 잘 나타내는 <br>
  국가를 선택해주십시오. (최대 2개국 선택)`,

  // Languages
  [ProfileKeys.Edit.User_Info.Languages.add_a_language]: '언어 추가',
  [ProfileKeys.Edit.User_Info.Website.placeholder]: 'URL 복사 붙여넣기',

  // Company
  [ProfileKeys.Edit.User_Info.Company.placeholder]: '소속 · 회사명 입력',
  [ProfileKeys.Edit.User_Info.Company.tooltip]: `
    PNG, JPG, JPEG 형식으로 회사 로고를 업로드하세요. <br> 
    권장 크기는 360x360, <br> 
    최대 크기는 50 MB입니다.`,
  [ProfileKeys.Edit.User_Info.Company.Error.max_30]: '최대 30 글자',

  // Modal
  [ProfileKeys.Modal.Request_Price.title]: '가격 요청하기',
  [ProfileKeys.Modal.Request_Price.btn]: '요청 보내기',
  [ProfileKeys.Modal.Request_Price.toast]: '요청이 성공적으로 전송되었습니다!',
  [ProfileKeys.Modal.Request_Price.Message.title]: '메시지',
  [ProfileKeys.Modal.Request_Price.Message.placeholder]:
    '질문 또는 프로젝트 세부 정보(예산, 제품 정보, 오구 사항 등)를 작성해주세요.',

  [ProfileKeys.Modal.Edit.Banner.heading]: '이미지 선택',
  [ProfileKeys.Modal.Edit.Banner.body]: '이미지 업로드',
  [ProfileKeys.Modal.Edit.Banner.loading]: '로부터 로딩 중',
  [ProfileKeys.Modal.Edit.Banner.use_my]: '나의 {{platform}} {{type}} 사용하기',
  [ProfileKeys.Modal.Edit.Banner.not_found]:
    '{{platform}} {{type}}을(를) 찾지 못했습니다',

  [ProfileKeys.Modal.Edit.Incomplete_Package.title]: '미완성된 패키지',
  [ProfileKeys.Modal.Edit.Incomplete_Package.description]: `
    다음은 미완성된 패키지입니다: <br><br>
    <package></package> <br>
    패키지를 완성하면 검색 결과 및 프로필에서 표시됩니다. 지금 완성하시겠습니까?`,

  [ProfileKeys.Modal.Edit.new_reward]: '새로운 보상을 획득했습니다.',
  [ProfileKeys.Modal.Edit.skip_for_now]: '스킵하기',

  [ProfileKeys.Modal.Reconnection.title]: '재연결 필요',
  [ProfileKeys.Modal.Reconnection.description]: `
    장기 미접속 또는 각 소셜 미디어 플랫폼의 정책 · 기능 업데이트로 인해 아래 플랫폼에 대한 재연결이 필요합니다. 계속하려면 해당 플랫폼을 연결하거나 해제해주십시오. <br><br>
    메가에볼루션은 구글, 메타 (인스타그램), 틱톡으로부터 인증 받은 공식 · 파트너 API를 통해 안전한 연결을 지원합니다. `,
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title]:
    '현재 연결된 계정 해제 후 새로운 계정을 연결할 수 있습니다',
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description]:
    '현재 연결된 계정과 다른 계정이 감지되었습니다. 새로운 계정을 연결하려면 기존 계정을 연결 해제한 후 다시 시도해주세요.',

  // Guide
  // Profile
  [ProfileKeys.Guide.Profile.welcome]: '안녕하세요, MEGA 입니다!',
  [ProfileKeys.Guide.Profile.portfolio_space]: '이곳은 여러분만을 위한',
  [ProfileKeys.Guide.Profile.specially_created]: '포트폴리오 공간입니다.',
  [ProfileKeys.Guide.Profile.click_here_to_start_editing]:
    '이곳을 클릭해서 수정해보세요',
  [ProfileKeys.Guide.Profile.package_will_show_up]:
    '이곳에 패키지가 쇼케이스 됩니다!',
  [ProfileKeys.Guide.Profile.click_here]: '이곳을 클릭해서',
  [ProfileKeys.Guide.Profile.to_start_editing]: '수정해보세요',
  [ProfileKeys.Guide.Profile.package_will_be_displated]:
    '이곳에 패키지가 쇼케이스 됩니다!',

  // Profile Edit
  [ProfileKeys.Guide.Profile_Edit.welcome]:
    '여러분의 프로필에 오신 것을 환영합니다!',
  [ProfileKeys.Guide.Profile_Edit.try_me]: '이곳을 통해 매력적인 패키지를',
  [ProfileKeys.Guide.Profile_Edit.compelling_package]:
    '빠르게 설정할 수 있습니다.',
  [ProfileKeys.Guide.Profile_Edit.upload_image]: '이미지를 통해 어떤',
  [ProfileKeys.Guide.Profile_Edit.showcase_service]:
    '서비스를 제공하는지 보여주세요.',
  [ProfileKeys.Guide.Profile_Edit.add_post_video]:
    '콘텐츠 링크를 추가해서 고객의',
  [ProfileKeys.Guide.Profile_Edit.for_your_clients]: '이목을 사로잡으세요!',
  [ProfileKeys.Guide.Profile_Edit.set_up_price]:
    '옵션을 통해 가격을 공개하거나 숨길 수 있습니다.',
  [ProfileKeys.Guide.Profile_Edit.choose_best_option]: '',
  [ProfileKeys.Guide.Profile_Edit.complete_package]:
    '패키지를 완성시키면 128+개국',
  [ProfileKeys.Guide.Profile_Edit.make_it_public]:
    '기업들에게 쇼케이스 됩니다!',
  [ProfileKeys.Guide.Profile_Edit.save_description1]:
    '떠나기 전에 저장했는지 확인해주세요!',
  [ProfileKeys.Guide.Profile_Edit.save_description2]:
    '프로필은 다국어로 자동 번역됩니다.',
  [ProfileKeys.Guide.Profile_Edit.save_description3]: '',
  [ProfileKeys.Guide.Profile_Edit.create_unique_package]:
    '전세계 기업에 선보일 멋진 프로필을 만들어보세요!',
  [ProfileKeys.Guide.Profile_Edit.quick_setup]:
    '템플릿으로 간단하게 설정해보세요!',

  [ProfileKeys.Guide.Profile_Edit.Toast.register_success]:
    '등록 성공! 이제 주문을 받기 위한 프로필을 설정해주세요.',
};
