const AboutKeys = {
  Side_Popup: {
    Btn: {
      creator: 'AboutKeys.Side_Popup.Btn.creator',
      client: 'AboutKeys.Side_Popup.Btn.client',
    },
    Btn1: 'AboutKeys.Side_Popup.Btn1',
  },

  Part1: {
    Creator: {
      title: 'AboutKeys.Part1.Creator.title',
      subtitle: 'AboutKeys.Part1.Creator.subtitle',
      btn: 'AboutKeys.Part1.Creator.btn',
    },

    Client: {
      title: 'AboutKeys.Part1.Client.title',
      subtitle: 'AboutKeys.Part1.Client.subtitle',
      btn: 'AboutKeys.Part1.Client.btn',
    },
  },

  Part2: {
    Creator: {
      title: 'AboutKeys.Part2.Creator.title',

      Section1: {
        Image: {
          description1: 'AboutKeys.Part2.Creator.Section1.Image.description1',
          description2: 'AboutKeys.Part2.Creator.Section1.Image.description2',
          description3: 'AboutKeys.Part2.Creator.Section1.Image.description3',
        },

        text1: 'AboutKeys.Part2.Creator.Section1.text1',
        text2: 'AboutKeys.Part2.Creator.Section1.text2',
        text3: 'AboutKeys.Part2.Creator.Section1.text3',
      },

      Section2: {
        Image: {
          description1: 'AboutKeys.Part2.Creator.Section2.Image.description1',
          description2: 'AboutKeys.Part2.Creator.Section2.Image.description2',
        },

        text1: 'AboutKeys.Part2.Creator.Section2.text1',
        text2: 'AboutKeys.Part2.Creator.Section2.text2',
        text3: 'AboutKeys.Part2.Creator.Section2.text3',
      },

      Section3: {
        Image: {
          description1: 'AboutKeys.Part2.Creator.Section3.Image.description1',
          description2: 'AboutKeys.Part2.Creator.Section3.Image.description2',
        },

        text1: 'AboutKeys.Part2.Creator.Section3.text1',
        text2: 'AboutKeys.Part2.Creator.Section3.text2',
      },
    },

    Client: {
      title: 'AboutKeys.Part2.Client.title',

      Section1: {
        Image: {
          description1: 'AboutKeys.Part2.Client.Section1.Image.description1',
          description2: 'AboutKeys.Part2.Client.Section1.Image.description2',
        },

        text1: 'AboutKeys.Part2.Client.Section1.text1',
        text2: 'AboutKeys.Part2.Client.Section1.text2',
        text3: 'AboutKeys.Part2.Client.Section1.text3',
      },

      Section2: {
        Image: {
          description1: 'AboutKeys.Part2.Client.Section2.Image.description1',
          description2: 'AboutKeys.Part2.Client.Section2.Image.description2',
        },

        text1: 'AboutKeys.Part2.Client.Section2.text1',
        text2: 'AboutKeys.Part2.Client.Section2.text2',
        text3: 'AboutKeys.Part2.Client.Section2.text3',
      },

      Section3: {
        Image: {
          description1: 'AboutKeys.Part2.Client.Section3.Image.description1',
          description2: 'AboutKeys.Part2.Client.Section3.Image.description2',
        },

        text1: 'AboutKeys.Part2.Client.Section3.text1',
        text2: 'AboutKeys.Part2.Client.Section3.text2',
        text2_sub: 'AboutKeys.Part2.Client.Section3.text2_sub',
      },
    },
  },

  Part3: {
    info1: 'AboutKeys.Part3.info1',
    info2: 'AboutKeys.Part3.info2',
    info3: 'AboutKeys.Part3.info3',
  },

  Part4: {
    title: 'AboutKeys.Part4.title',
  },

  Part5: {
    title: 'AboutKeys.Part5.title',

    text1: 'AboutKeys.Part5.text1',
    text2: 'AboutKeys.Part5.text2',
    text3: 'AboutKeys.Part5.text3',
  },

  Part6: {
    title: 'AboutKeys.Part6.title',
    placeholder: 'AboutKeys.Part6.placeholder',
  },

  Feedback: {
    Creator1: {
      name: 'AboutKeys.Feedback.Creator1.name',
      comment: 'AboutKeys.Feedback.Creator1.comment',
    },
    Creator2: {
      name: 'AboutKeys.Feedback.Creator2.name',
      comment: 'AboutKeys.Feedback.Creator2.comment',
    },
    Creator3: {
      name: 'AboutKeys.Feedback.Creator3.name',
      comment: 'AboutKeys.Feedback.Creator3.comment',
    },
    Creator4: {
      name: 'AboutKeys.Feedback.Creator4.name',
      comment: 'AboutKeys.Feedback.Creator4.comment',
    },
    Creator5: {
      name: 'AboutKeys.Feedback.Creator5.name',
      comment: 'AboutKeys.Feedback.Creator5.comment',
    },
    Creator6: {
      name: 'AboutKeys.Feedback.Creator6.name',
      comment: 'AboutKeys.Feedback.Creator6.comment',
    },
    Creator7: {
      name: 'AboutKeys.Feedback.Creator7.name',
      comment: 'AboutKeys.Feedback.Creator7.comment',
    },
    Creator8: {
      name: 'AboutKeys.Feedback.Creator8.name',
      comment: 'AboutKeys.Feedback.Creator8.comment',
    },
    Creator9: {
      name: 'AboutKeys.Feedback.Creator9.name',
      comment: 'AboutKeys.Feedback.Creator9.comment',
    },
  },
};

export default AboutKeys;
