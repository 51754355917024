import GuideKeys from '../translationKeys/GuidePageKeys';

export const GuideTranslations = {
  [GuideKeys.click_to_join]: 'Haz clic para unirte',

  // Portfolio Banner
  [GuideKeys.Portfolio_Banner.description]: `
    Crea tu propio <br>
    portfolio con una URL única!`,
  [GuideKeys.Portfolio_Banner.view_sample]: 'Ver muestra',

  // Creator Invitation
  [GuideKeys.Creator_Invitation.hi_ginseng_mega]: '¡Hola, soy MEGA!',
  [GuideKeys.Creator_Invitation.nice_to_meet_you]: '¡Encantado de conocerte!',
  [GuideKeys.Creator_Invitation.we_help_you_receive]:
    'Te ayudamos a recibir propuestas',
  [GuideKeys.Creator_Invitation.proposal_golbal_brands]: 'de marcas globales!',
  [GuideKeys.Creator_Invitation.let_companies_directly]:
    'Permite que las empresas',
  [GuideKeys.Creator_Invitation.order_your_package]:
    'pidan directamente tu paquete!',
  [GuideKeys.Creator_Invitation.click_to_join]: 'Haz clic aquí',
  [GuideKeys.Creator_Invitation.anytime]: 'para unirte en cualquier momento!',
  [GuideKeys.Creator_Invitation.package_featured]:
    '¡Tu paquete será destacado para',
  [GuideKeys.Creator_Invitation.showcase_channel]:
    'empresas de más de 128 países! ¡Muestra',
  [GuideKeys.Creator_Invitation.showcase_channel_2]:
    'tu canal y conecta con clientes globales!',
  [GuideKeys.Creator_Invitation.order_package]: 'Ordenar paquete',

  [GuideKeys.Creator_Invitation.Mobile.we_help_you_receive]:
    'Te ayudamos a recibir',
  [GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands]:
    'propuestas de marcas globales!',
  [GuideKeys.Creator_Invitation.Mobile.let_companies_directly]:
    'Permite que las empresas',
  [GuideKeys.Creator_Invitation.Mobile.order_your_package]:
    'pidan directamente tu paquete!',
  [GuideKeys.Creator_Invitation.Mobile.click_to_join]: 'Haz clic para unirte',
  [GuideKeys.Creator_Invitation.Mobile.anytime]: '¡en cualquier momento!',
};
