export const RewardIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 18}
      height={size * 18}
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g mask="url(#mask0_31977_67034)">
        <path d="M3 12.75V14.25H15V12.75H3ZM3 4.5H4.65C4.5875 4.3875 4.54675 4.26875 4.52775 4.14375C4.50925 4.01875 4.5 3.8875 4.5 3.75C4.5 3.125 4.71875 2.59375 5.15625 2.15625C5.59375 1.71875 6.125 1.5 6.75 1.5C7.125 1.5 7.47175 1.59675 7.79025 1.79025C8.10925 1.98425 8.3875 2.225 8.625 2.5125L9 3L9.375 2.5125C9.6 2.2125 9.875 1.96875 10.2 1.78125C10.525 1.59375 10.875 1.5 11.25 1.5C11.875 1.5 12.4063 1.71875 12.8438 2.15625C13.2812 2.59375 13.5 3.125 13.5 3.75C13.5 3.8875 13.4905 4.01875 13.4715 4.14375C13.453 4.26875 13.4125 4.3875 13.35 4.5H15C15.4125 4.5 15.7658 4.647 16.0598 4.941C16.3533 5.2345 16.5 5.5875 16.5 6V14.25C16.5 14.6625 16.3533 15.0158 16.0598 15.3098C15.7658 15.6033 15.4125 15.75 15 15.75H3C2.5875 15.75 2.2345 15.6033 1.941 15.3098C1.647 15.0158 1.5 14.6625 1.5 14.25V6C1.5 5.5875 1.647 5.2345 1.941 4.941C2.2345 4.647 2.5875 4.5 3 4.5ZM3 10.5H15V6H11.175L12.75 8.1375L11.55 9L9 5.55L6.45 9L5.25 8.1375L6.7875 6H3V10.5ZM6.75 4.5C6.9625 4.5 7.14075 4.42825 7.28475 4.28475C7.42825 4.14075 7.5 3.9625 7.5 3.75C7.5 3.5375 7.42825 3.35925 7.28475 3.21525C7.14075 3.07175 6.9625 3 6.75 3C6.5375 3 6.3595 3.07175 6.216 3.21525C6.072 3.35925 6 3.5375 6 3.75C6 3.9625 6.072 4.14075 6.216 4.28475C6.3595 4.42825 6.5375 4.5 6.75 4.5ZM11.25 4.5C11.4625 4.5 11.6408 4.42825 11.7848 4.28475C11.9283 4.14075 12 3.9625 12 3.75C12 3.5375 11.9283 3.35925 11.7848 3.21525C11.6408 3.07175 11.4625 3 11.25 3C11.0375 3 10.8595 3.07175 10.716 3.21525C10.572 3.35925 10.5 3.5375 10.5 3.75C10.5 3.9625 10.572 4.14075 10.716 4.28475C10.8595 4.42825 11.0375 4.5 11.25 4.5Z" />
      </g>
    </svg>
  );
};
