import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $getSelection, COMMAND_PRIORITY_EDITOR, createCommand } from 'lexical';
import { useEffect } from 'react';
import { $createSnippetNode, SnippetNode } from './SnippetNode';

export const INSERT_SNIPPET_COMMAND = createCommand('INSERT_SNIPPET_COMMAND');

export default function SnippetPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([SnippetNode])) {
      throw new Error('SnippetPlugin: SnippetNode not registered on editor');
    }

    return editor.registerCommand(
      INSERT_SNIPPET_COMMAND,
      (payload) => {
        const snippetNode = $createSnippetNode(payload);
        const selection = $getSelection();
        selection.insertNodes([snippetNode]);

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
}
