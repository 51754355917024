import { useNavigate } from 'react-router-dom';
import BannerCard from './BannerCard';
import BannerModal from './BannerModal';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import { useSocket } from 'contexts/SocketContext';

const popupDisabledMissions = [
  'update_creator_profile',
  'update_profile',
  'create_premium_package',
];

const MissionCompleteBanner = () => {
  const socket = useSocket();
  const navigate = useNavigate();
  const [showMissionCompleteBanner, setShowMissionCompleteBanner] =
    useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    socket.on('missionCompleted', (args) => {
      if (
        args.mission.some((mission) => popupDisabledMissions.includes(mission))
      ) {
        return;
      }
      setShowMissionCompleteBanner(true);
    });

    return () => {
      socket.off('missionCompleted');
    };
  }, [socket]);

  const onClose = () => {
    setShowMissionCompleteBanner(false);
  };

  return (
    <>
      {showMissionCompleteBanner && (
        <BannerModal isOpen={showMissionCompleteBanner} onClose={onClose}>
          <BannerCard
            onClose={onClose}
            image={
              process.env.PUBLIC_URL + '/img/bannerCards/complete_mission.png'
            }
            description={
              <>
                {t(GeneralKeys.congratulations)}
                <br />
                {t(ProfileKeys.Modal.Edit.new_reward)}
              </>
            }
            buttonText={t(LoginKeys.Modal.Welcome_On_Board.hi_mega)}
            onButtonClick={() => {
              onClose();
              navigate('/evo-points');
            }}
            secondaryButtonText={t(ProfileKeys.Modal.Edit.skip_for_now)}
          />
        </BannerModal>
      )}
    </>
  );
};

export default MissionCompleteBanner;
