import styled from 'styled-components';
import Links from './Links';
import Options from './Options';

const Menu = ({ className, haveLinks }) => {
  return (
    <Wrapper className={className}>
      <MenuItemsContainer>
        <MenuItems>
          {haveLinks && <Links />}

          <Options />
        </MenuItems>
      </MenuItemsContainer>
    </Wrapper>
  );
};

export default Menu;

const Wrapper = styled.nav`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;

  .dropdown:hover {
    .menu-item {
      font-weight: 700;
    }
  }
`;

const MenuItemsContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;

  max-width: 100%;
  justify-content: flex-end;
`;

const MenuItems = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  margin: 0;
  padding: 0;
`;
