import AxiosClient from 'components/utilities/AxiosClient';

const getLatestPatchNote = async (language = 'en') => {
  const res = await AxiosClient.get(
    `/releaseNotes/latest`,
    { params: { language: language } }
  );

  return res.data;
};

export default getLatestPatchNote;
