import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'Tự động dịch',
  [AutoTranslationKeys.Dropdown.description]:
    'Tự động dịch mô tả và văn bản do người dùng viết.',

  [AutoTranslationKeys.Applying_Translation.title]: 'Đã áp dụng tự động dịch.',
  [AutoTranslationKeys.Applying_Translation.btn]: ' Hiển thị văn bản gốc',

  [AutoTranslationKeys.Displaying_Original.title]: 'Hiển thị văn bản gốc.',
  [AutoTranslationKeys.Displaying_Original.btn]: ' Dịch',
};
