import EvoMissionsKeys from '../translationKeys/EvoMissionsPage';

export const EvoMissionsTranslations = {
  [EvoMissionsKeys.claim_evos]: '领取 EVO',
  [EvoMissionsKeys.my_evos]: '我的 EVO',
  [EvoMissionsKeys.special_missions]: '特殊任务',
  [EvoMissionsKeys.creator_missions]: '创作者任务',
  [EvoMissionsKeys.general_missions]: '普通任务',
  [EvoMissionsKeys.successfully_claimed]: '领取成功！',
  [EvoMissionsKeys.go_back_home]: '返回我的个人资料',

  // Token Details
  [EvoMissionsKeys.Token_Details._1k]: '1千',
  [EvoMissionsKeys.Token_Details._10k]: '1万',
  [EvoMissionsKeys.Token_Details.no_data]: '无数据显示',
  [EvoMissionsKeys.Token_Details.results_found]: '共找到 {{number}} 条结果',
  [EvoMissionsKeys.Token_Details.result_found]: '共找到 {{number}} 条结果',

  // Side Popup
  [EvoMissionsKeys.Side_Popup.text]: '什么是 EVO?',

  // Transaction
  [EvoMissionsKeys.Transaction.daily_reward]: '每日奖励',
  [EvoMissionsKeys.Transaction.price_unlock_received]: '客户发送 - 价格解锁',
  [EvoMissionsKeys.Transaction.price_unlock_spent]: '价格解锁',
  [EvoMissionsKeys.Transaction.referral_reward]: '推荐奖励',
  [EvoMissionsKeys.Transaction.creator_registration_reward]: '创作者注册奖励',

  [EvoMissionsKeys.Transaction.complete_1_special_completed]: '完成特别任务',
  [EvoMissionsKeys.Transaction.complete_777_special_completed]: '完成特别任务',
  [EvoMissionsKeys.Transaction.complete_77_special_completed]: '完成特别任务',
  [EvoMissionsKeys.Transaction.complete_7_special_completed]: '完成特别任务',
  [EvoMissionsKeys.Transaction.create_premium_package_completed]:
    '创建高级套餐',
  [EvoMissionsKeys.Transaction.first_990_creator_claimed_completed]:
    '早期 990 VIP',
  [EvoMissionsKeys.Transaction.refer_1000_early_vips_completed]: '早期VIP推荐',
  [EvoMissionsKeys.Transaction.refer_100_early_vips_completed]: '早期VIP推荐',
  [EvoMissionsKeys.Transaction.refer_10_early_vips_completed]: '早期VIP推荐',
  [EvoMissionsKeys.Transaction.referral_ranking_completed]: 'VIP推荐排名',
  [EvoMissionsKeys.Transaction.special_daily_reward_completed]: '每日奖励',
  [EvoMissionsKeys.Transaction.tv_easter_egg_completed]:
    '在平台上找到复活节彩蛋',
  [EvoMissionsKeys.Transaction.update_creator_profile_completed]:
    '更新创作者档案',
  [EvoMissionsKeys.Transaction.update_profile_completed]: '更新档案',
  [EvoMissionsKeys.Transaction.visit_main_tabs_completed]: '访问所有主选项卡',

  // Special Mission
  // Early 990 VIPs
  [EvoMissionsKeys.Special.early_990_vips.title]: '早期 990 VIP!',
  [EvoMissionsKeys.Special.early_990_vips.description]:
    '您是我们的早期 VIP 影响者！立即注册成为创作者，解锁我们的高级特权通行证。立刻占位！',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d1]:
    '通过此高级通行证无需支付平台服务',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d2]: '费用即可获得订单！',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d3]: '',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    ' *第三方费用，如 PayPal 和信用卡费用可能仍然适用。',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    ' *第三方费用（如 PayPal 和信用卡费用）可能适用。',

  // General
  [EvoMissionsKeys.complete_1_special.title]: '完成特殊任务',
  [EvoMissionsKeys.complete_1_special.description]: '完成特殊任务，获得小奖励',
  [EvoMissionsKeys.complete_1_special.extra_reward]: '',
  [EvoMissionsKeys.complete_1_special.reward_description]: '',

  [EvoMissionsKeys.complete_777_special.title]: '完成特殊任务',
  [EvoMissionsKeys.complete_777_special.description]:
    '完成特殊任务，获得小奖励',
  [EvoMissionsKeys.complete_777_special.extra_reward]: '',
  [EvoMissionsKeys.complete_777_special.reward_description]: '',

  [EvoMissionsKeys.complete_77_special.title]: '完成特殊任务',
  [EvoMissionsKeys.complete_77_special.description]: '完成特殊任务，获得小奖励',
  [EvoMissionsKeys.complete_77_special.extra_reward]: '',
  [EvoMissionsKeys.complete_77_special.reward_description]: '',

  [EvoMissionsKeys.complete_7_special.title]: '完成特殊任务',
  [EvoMissionsKeys.complete_7_special.description]: '完成特殊任务，获得小奖励',
  [EvoMissionsKeys.complete_7_special.extra_reward]: '',
  [EvoMissionsKeys.complete_7_special.reward_description]: '',

  [EvoMissionsKeys.tv_easter_egg.title]: '在平台上找到复活节彩蛋！',
  [EvoMissionsKeys.tv_easter_egg.description]:
    '在主页找到复活节彩蛋。它的位置在某个地方',
  [EvoMissionsKeys.tv_easter_egg.extra_reward]: '',
  [EvoMissionsKeys.tv_easter_egg.reward_description]: '',

  [EvoMissionsKeys.update_profile.title]: '更新个人资料',
  [EvoMissionsKeys.update_profile.description]:
    '加入我们的旅程！更新您的个人资料并解锁此奖励。',
  [EvoMissionsKeys.update_profile.extra_reward]: '',
  [EvoMissionsKeys.update_profile.reward_description]: '',

  [EvoMissionsKeys.visit_main_tabs.title]: '访问所有主选项卡',
  [EvoMissionsKeys.visit_main_tabs.description]:
    '访问我们上方导航栏的所有四个选项卡以解锁此奖励。与我们一起探索新事物！',
  [EvoMissionsKeys.visit_main_tabs.extra_reward]: '',
  [EvoMissionsKeys.visit_main_tabs.reward_description]: '',

  // Creator
  [EvoMissionsKeys.create_premium_package.title]: '创建高级套餐',
  [EvoMissionsKeys.create_premium_package.description]:
    '在您的个人资料上创建高级套餐。使用我们的模板或 ChatGPT 功能展示您最好的服务！',
  [EvoMissionsKeys.create_premium_package.extra_reward]: '',
  [EvoMissionsKeys.create_premium_package.reward_description]: '',

  [EvoMissionsKeys.update_creator_profile.title]: '更新创作者个人资料',
  [EvoMissionsKeys.update_creator_profile.description]:
    '准备成为全球超级巨星了吗？更新您的个人资料，让世界了解您的惊人频道！',
  [EvoMissionsKeys.update_creator_profile.extra_reward]: '',
  [EvoMissionsKeys.update_creator_profile.reward_description]: '',

  // Special
  [EvoMissionsKeys.first_990_creator_claimed.title]: 'VIP 990 早期创作者!',
  [EvoMissionsKeys.first_990_creator_claimed.description]:
    '欢迎您作为早期创作者加入我们！完善您的个人资料，与全球品牌合作！',
  [EvoMissionsKeys.first_990_creator_claimed.extra_reward]: '高级创作者通行证',
  [EvoMissionsKeys.first_990_creator_claimed.reward_description]:
    '拥有高级通行证的创作者将',

  [EvoMissionsKeys.refer_1000_early_vips.title]: '早期 VIP 推荐',
  [EvoMissionsKeys.refer_1000_early_vips.description]:
    '推荐 1000 人或更多以获取此奖励！',
  [EvoMissionsKeys.refer_1000_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_1000_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_100_early_vips.title]: '早期 VIP 推荐',
  [EvoMissionsKeys.refer_100_early_vips.description]:
    '推荐 100 人或更多以获取此奖励！',
  [EvoMissionsKeys.refer_100_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_100_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_10_early_vips.title]: '早期 VIP 推荐',
  [EvoMissionsKeys.refer_10_early_vips.description]:
    '推荐 10 人或更多以获取此奖励！',
  [EvoMissionsKeys.refer_10_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_10_early_vips.reward_description]: '',

  [EvoMissionsKeys.referral_ranking.title]: 'VIP 推荐排名',
  [EvoMissionsKeys.referral_ranking.description]:
    '在推荐排名中的每个百分位，您将在月底额外获得 EVO 点数奖励。',
  [EvoMissionsKeys.referral_ranking.extra_reward]: '',
  [EvoMissionsKeys.referral_ranking.reward_description]: '',

  [EvoMissionsKeys.special_daily_reward.title]: '特殊每日奖励',
  [EvoMissionsKeys.special_daily_reward.description]:
    '连续一周来领取您的每日奖励。',
  [EvoMissionsKeys.special_daily_reward.extra_reward]: '',
  [EvoMissionsKeys.special_daily_reward.reward_description]: '',

  [EvoMissionsKeys.Modal.already_unlock]: '您已解锁了',
  [EvoMissionsKeys.Modal.easter_egg]: '这个复活节彩蛋！',
  [EvoMissionsKeys.Modal.unlock_premium_pass]: '您解锁了高级通行证。',
  [EvoMissionsKeys.Modal.visit_profile]: '访问我的个人资料',
  [EvoMissionsKeys.Modal.not_now]: '跳过',
};
