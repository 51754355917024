import { useRef } from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const YoutubePlayer = ({ contentId, inView, onInternalRefChange }) => {
  const [alreadyInView, setAlreadyInView] = useState(false);
  const [clicked, setClicked] = useState(false);

  const ref = useRef(null);
  useEffect(() => {
    // if the video is not in view, pause it
    if (ref.current && clicked && !inView) {
      ref.current.contentWindow.postMessage(
        '{"event":"command","func":"pauseVideo","args":""}',
        '*'
      );
    }
  }, [inView, clicked]);

  useEffect(() => {
    if (inView && !alreadyInView) {
      setAlreadyInView(true);
    }
  }, [inView, alreadyInView]);

  if (!alreadyInView) return <Empty />;

  if (!clicked)
    // if the video is not clicked, show the thumbnail
    return (
      <ThumbnailWrapper
        ref={(node) => {
          onInternalRefChange(node);
        }}
        onClick={() => setClicked(true)}
      >
        <Thumbnail
          src={`https://img.youtube.com/vi/${contentId}/hqdefault.jpg`}
          alt="Thumbnail"
        />
        <PlayButton />
      </ThumbnailWrapper>
    );

  return (
    <iframe
      width="528"
      height="297"
      src={`https://www.youtube.com/embed/${contentId}?rel=0&autoplay=1&muted=1&enablejsapi=1`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
      style={{
        flexShrink: 0,
        backgroundColor: 'black',
        // loader on background
        background:
          'url(https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExNHhoMzgyaWtsdDlrZHI4Zm93OGx6YzZsMzI3b21zY2x6bnQ1ZW5jZSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/52enxwNHdx2KIqlTnC/giphy.gif) center center / 20px no-repeat',
      }}
      ref={(node) => {
        onInternalRefChange(node);

        ref.current = node;
      }}
    ></iframe>
  );
};

export default YoutubePlayer;

const Empty = styled.div`
  width: 528px;
  height: 297px;
  flex-shrink: 0;
  background-color: black;
`;

const ThumbnailWrapper = styled.div`
  width: 528px;
  height: 297px;
  flex-shrink: 0;
  background-color: black;
  position: relative;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const PlayIcon = () => (
  <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%">
    <path
      class="ytp-large-play-button-bg"
      d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
      fill="#f00"
    ></path>
    <path d="M 45,24 27,14 27,34" fill="#fff"></path>
  </svg>
);

const PlayButton = styled.div.attrs({
  children: <PlayIcon />,
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 68px;
  height: 48px;
`;
