import styled from 'styled-components';
import { usePackages } from 'contexts/PackagesContext';
import PlatformIcon from 'components/Basics/PlatformIcon';

const DeatilsModalPlatformIconButton = () => {
  const { userData: user } = usePackages();

  return (
    <Wrapper href={user.link} target="_blank" rel="noopener noreferrer">
      <PlatformIcon platform={user?.platformType} variant="size1" />
    </Wrapper>
  );
};

export default DeatilsModalPlatformIconButton;

const Wrapper = styled.a`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 150ms ease-in-out;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;
