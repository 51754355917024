import ProfileKeys from '../translationKeys/ProfilePageKeys';

export const ProfileTranslations = {
  // Menu
  [ProfileKeys.Menu.edit]: '编辑个人资料',
  [ProfileKeys.Menu.save]: '保存个人资料',
  [ProfileKeys.Menu.message_me]: '给我留言',

  // Menu Error
  [ProfileKeys.Menu.Error.creator_to_creator]: '请使用非创作者账户。',
  [ProfileKeys.Menu.Error.something_went_wrong]: '出现了一些问题。',
  [ProfileKeys.Menu.Error.self_chat]: '无法向此聊天发送消息。',

  // Package
  [ProfileKeys.Package.edit]: '编辑套餐',
  [ProfileKeys.Package.no_content]: '暂无内容',
  [ProfileKeys.Package.available]: '可用',
  [ProfileKeys.Package.click_to_view]: '点击查看',
  [ProfileKeys.Package.click_to_view_recent]: '点击查看最近的{{content}}',
  [ProfileKeys.Package.view_recent_videos]: '查看最新视频',

  // Topbar
  [ProfileKeys.Package.TopBar.tooltip]: '该套餐目前不可用。',

  // YouTube
  [ProfileKeys.Package.YouTube.Contents.video_prepare]: '准备套餐',
  [ProfileKeys.Package.YouTube.Contents.video_display]: '您的视频将在此显示。',

  // Description
  [ProfileKeys.Package.Description.default]: `
    创作者目前正在准备一个套餐。<br>
    请很快再回来查看。`,

  // Details
  [ProfileKeys.Package.Details.Price.contact_me]: '联系我',
  [ProfileKeys.Package.Details.Price.contact_creator]: '联系创作者',
  [ProfileKeys.Package.Details.Price.service_fee]: '包含服务费',
  [ProfileKeys.Package.Details.Price.tooltip]: '请再尝试另一位创作者的套餐。',

  [ProfileKeys.Package.Details.Toast.update_successful]: '更新成功！',
  [ProfileKeys.Package.Details.Toast.could_not_update]: '无法更新！',

  // Creator Summary
  [ProfileKeys.Creator_Summary.title]: '创作者简介',
  [ProfileKeys.Creator_Summary.sub_title]: '创作者的基本频道信息',
  [ProfileKeys.Creator_Summary.package_for_other_platforms]: '其他平台的套餐',

  // Basic Info
  [ProfileKeys.Creator_Summary.BasicInfo.hollow]: '正在准备社交网络',

  // Tooltip
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers]: '订阅者',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers]: '粉丝',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos]: '视频总数',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets]: '总计X篇帖子',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts]: '总帖数',

  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube]:
    'YouTube 加入日期',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter]:
    'X 加入日期',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram]:
    'Instagram 加入日期',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok]:
    'TikTok 加入日期',

  // Channel Contents
  [ProfileKeys.Channel_Contents.title]: '频道内容',
  [ProfileKeys.Channel_Contents.sub_title]: '我们的智能指标测量内容参与水平',
  [ProfileKeys.Channel_Contents.views_to_subs]: '观看 vs 订阅',
  [ProfileKeys.Channel_Contents.views_to_followers]: '观看 vs 粉丝',
  [ProfileKeys.Channel_Contents.reach_to_followers]: '触达 vs 粉丝',
  [ProfileKeys.Channel_Contents.est_engagement]: '估计的参与度',
  [ProfileKeys.Channel_Contents.channel_data]: '频道数据',
  [ProfileKeys.Channel_Contents.loading_post]: '加载帖子',

  [ProfileKeys.Channel_Contents.Twitter.feed]: 'X 动态',
  [ProfileKeys.Channel_Contents.Twitter.loading]: '加载 X 帖子',
  [ProfileKeys.Channel_Contents.Twitter.most_popular]: '最热门 X 帖子',
  [ProfileKeys.Channel_Contents.Tiktok.loading]: '加载 TikTok',

  [ProfileKeys.Channel_Contents.Filter._1_month]: '1 个月',
  [ProfileKeys.Channel_Contents.Filter._3_months]: '3 个月',
  [ProfileKeys.Channel_Contents.Filter._1_year]: '1 年',
  [ProfileKeys.Channel_Contents.Filter.newest]: '最新',
  [ProfileKeys.Channel_Contents.Filter.most_viewed]: '最多浏览',
  [ProfileKeys.Channel_Contents.Filter.most_liked]: '最多点赞',
  [ProfileKeys.Channel_Contents.Filter.most_commented]: '最多评论',

  [ProfileKeys.Channel_Contents.Tooltip.youtube]: `
    这里的分析基于 <br>
    过去 1~6 个月的数据。 <br>
    精选视频包括过去 1 <br>
    年内上传的内容。`,
  [ProfileKeys.Channel_Contents.Tooltip.twitter]: `
    我们对某些数据使用估算 <br>
    包括 X 上的参与度。 <br>
    与实时数据可能存在差异。 <br>
    这里的分析基于过去 1~3 个月的数据。`,
  [ProfileKeys.Channel_Contents.Tooltip.instagram]:
    '此统计基于过去1-3个月的数据。',
  [ProfileKeys.Channel_Contents.Tooltip.tiktok]:
    '此统计基于过去1-3个月的数据。',

  [ProfileKeys.Channel_Contents.Tooltip.likes]: '每次浏览的点赞数比率',
  [ProfileKeys.Channel_Contents.Tooltip.comments]: '每次浏览的评论数比率',
  [ProfileKeys.Channel_Contents.Tooltip.x_reposts]: '每个浏览评级的X转发',
  [ProfileKeys.Channel_Contents.Tooltip.shares]: '每次浏览的分享数比率',

  [ProfileKeys.Channel_Contents.Tooltip.views_subs_rating]:
    '每个订阅者的浏览量比率',
  [ProfileKeys.Channel_Contents.Tooltip.views_follower_rating]:
    '每个粉丝的浏览量比率',
  [ProfileKeys.Channel_Contents.Tooltip.engagements]: '总体参与度比率',

  // Channel Analytics
  [ProfileKeys.Channel_Analytics.title]: '频道分析',
  [ProfileKeys.Channel_Analytics.sub_title]: '频道指标量化衡量总体表现',
  [ProfileKeys.Channel_Analytics.updated_on]: '更新于 {{date}}',
  [ProfileKeys.Channel_Analytics.avg_views]: '平均浏览量',
  [ProfileKeys.Channel_Analytics.avg_impressions]: '平均印象',
  [ProfileKeys.Channel_Analytics.avg_reach]: '平均触达',
  [ProfileKeys.Channel_Analytics.avg_likes]: '平均点赞数',
  [ProfileKeys.Channel_Analytics.avg_x_reposts]: '平均X转发',
  [ProfileKeys.Channel_Analytics.engagements]: '参与度',

  // Views Analysis
  [ProfileKeys.Channel_Analytics.Views_Analysis.title]: '浏览量分析',
  [ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram]: '触达分析',
  [ProfileKeys.Channel_Analytics.Views_Analysis.periodic]: '周期性',
  [ProfileKeys.Channel_Analytics.Views_Analysis.total]: '总计',

  // Subscribers Data
  [ProfileKeys.Channel_Analytics.Subscribers_Data.title]: '订阅者数据',

  // Followers Data
  [ProfileKeys.Channel_Analytics.Followers_Data.title]: '粉丝数据',

  // Audience Analytics
  [ProfileKeys.Audience_Analytics.title]: '受众分析',
  [ProfileKeys.Audience_Analytics.sub_title]: '面向频道目标观众的大数据分析',
  [ProfileKeys.Audience_Analytics.preparing_data]: '正在准备数据',

  // Audience Interest
  [ProfileKeys.Audience_Analytics.Audience_Interest.title]: '受众兴趣',
  [ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags]: '热门标签',
  [ProfileKeys.Audience_Analytics.Keyword_Cloud.title]: '关键词云',
  [ProfileKeys.Audience_Analytics.Top_10.title]: '前 10 个关键词',

  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title]:
    '查看我的计划',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description]: `
    请检查并升级您的计划 <br>
    以获取更多受众数据。`,

  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.title]: '需要登录',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.description]: `
    请先登录以访问 <br>
    用户的受众数据。`,

  // Review
  [ProfileKeys.Review.review_as_client]: '以客户身份查看评价',
  [ProfileKeys.Review.most_recent]: '最新排序',
  [ProfileKeys.Review.most_helpful]: '推荐排序',
  [ProfileKeys.Review.no_reviews]: '暂无评价',
  [ProfileKeys.Review.recent_clients]: '最近的客户',

  [ProfileKeys.Review.star]: '颗星',
  [ProfileKeys.Review.stars]: '颗星',

  // Edit
  [ProfileKeys.Edit.Toast.being_saved]: '您的个人资料正在保存中。',
  [ProfileKeys.Edit.Toast.saved]: '您的个人资料已成功保存！',
  [ProfileKeys.Edit.Toast.failed]: '个人资料保存失败，请稍后重试。',

  // Package
  [ProfileKeys.Edit.Package.complete_percentage]: '此套餐已完成{{number}}%',

  [ProfileKeys.Edit.Package.Topbar.tooltip]: `
    套餐将在完成后出现在您的个人资料中。`,

  [ProfileKeys.Edit.Package.Topbar.Representative.tooltip]: `
    默认套餐将用于向客户展示您的服务。`,

  [ProfileKeys.Edit.Package.Topbar.Completion.tooltip]: `
    请完成套餐以选择为默认套餐。`,

  // Banner
  [ProfileKeys.Edit.Package.Banner.heading]:
    '推荐尺寸 1920X318 / 最大大小 20MB',
  [ProfileKeys.Edit.Package.Banner.mobile_heading]:
    '最大大小 20MB / 1920X318 横幅',
  [ProfileKeys.Edit.Package.Banner.tooltip]: `
    在您的横幅图像上添加一层阴影。`,

  // Options
  [ProfileKeys.Edit.Package.Options.intro_and_closing]: 'Intro & Closing',
  [ProfileKeys.Edit.Package.Options.product_review]: 'Product Review',
  [ProfileKeys.Edit.Package.Options.visit_review]: 'Visit Review',
  [ProfileKeys.Edit.Package.Options.digital_product_review]:
    'Digital Product Review',
  [ProfileKeys.Edit.Package.Options.brand_ambassador]: 'Brand Ambassador',
  [ProfileKeys.Edit.Package.Options.short_video]: 'Short Video',
  [ProfileKeys.Edit.Package.Options.product_placement]: 'Product Placement',
  [ProfileKeys.Edit.Package.Options.interview]: 'Interview',
  [ProfileKeys.Edit.Package.Options.commercial_break]: 'Commercial Break',
  [ProfileKeys.Edit.Package.Options.live_streaming]: 'Live Streaming',
  [ProfileKeys.Edit.Package.Options.giveaways]: 'Giveaways',
  [ProfileKeys.Edit.Package.Options.meme_and_artwork]: 'Meme / Artwork',
  [ProfileKeys.Edit.Package.Options.interview_and_ama]: 'Interview / AMA',

  // Title
  [ProfileKeys.Edit.Package.Title.use_a_template]: '使用模板',
  [ProfileKeys.Edit.Package.Title.placeholder]: '输入套餐标题',
  [ProfileKeys.Edit.Package.Title.placeholder2]: '在此输入套餐标题。',

  // Featured Image
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.title]: '特色缩略图',
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip]: `
    我们建议上传反映您频道 <br>
    风格的YouTube缩略图。`,

  [ProfileKeys.Edit.Package.Featured_Image.title]: '精选图片',
  [ProfileKeys.Edit.Package.Featured_Image.placeholder]:
    '允许JPG，JPEG，PNG格式文件。建议16:9比例。最大200 MB。',
  [ProfileKeys.Edit.Package.Featured_Image.tooltip]: `
    该图像将用作套餐的代表缩略图。`,

  [ProfileKeys.Edit.Package.Featured_Image.Error.file_upload]: `
  文件仍在上传中。 请稍后再试。`,
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_large]:
    '文件大小过大。（最大限制为',
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid]:
    '无效的文件格式。（请使用JPG，JPEG，PNG）',

  // Sample Contents
  [ProfileKeys.Edit.Package.Sample_Contents.title]: '样本内容',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube]:
    '复制并粘贴视频链接',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter]:
    '复制并粘贴 X 链接',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram]:
    '复制并粘贴帖子链接',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok]:
    '复制并粘贴TikTok链接',

  [ProfileKeys.Edit.Package.Sample_Contents.add_more_contents]: '添加更多内容',
  [ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link]:
    '检测到无效链接',

  // Description
  [ProfileKeys.Edit.Package.Description.placeholder]: `
    请在此处描述您的服务详细信息。 <br>
    客户可能会寻找以下信息， <br><br>
    <ul>
      <li>主要内容或服务</li>
      <li>目标受众</li>
      <li>日程安排</li>
    </ul>`,
  [ProfileKeys.Edit.Package.Description.placeholder_gpt]:
    '开始输入一个句子，ChatGPT 将会建议其余部分。',

  [ProfileKeys.Edit.Package.Description.ChatGPT.title]:
    '使用ChatGPT创建独特的服务包',
  [ProfileKeys.Edit.Package.Description.ChatGPT.tooltip]: `
    选择最能描述您的服务的那一个。`,

  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title]:
    '附加信息',
  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder]:
    '输入应包含在描述中的任何信息。',

  // Price
  [ProfileKeys.Edit.Package.Price.placeholder]: '输入价格',
  [ProfileKeys.Edit.Package.Price.tooltip]: `
  将您的价格设定为公开，以方便客户访问。<br>
  其他选项将不设定价格，或仅对经过验证的公司和客户提供。`,

  [ProfileKeys.Edit.Package.Price.Type.open]: '公开价格 - 所有客户可见',
  [ProfileKeys.Edit.Package.Price.Type.unlockable]:
    '可解锁价格 - 仅限已验证客户',
  [ProfileKeys.Edit.Package.Price.Type.contact_me]: '联系我 - 无需价格',

  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title]:
    '请进行验证以发送更多消息',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description]: `
    要发送更多消息给其他创作者，您需要完成一个快速的验证过程，只需1-2分钟。立即尝试！<br><br>
    您的下次续费日期：{{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title]:
    '请升级您的计划以发送更多消息',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description]: `
    您需要升级您的计划以向创作者发送消息。<br>
    请检查您的计划，然后再试一次。<br><br>
    您的下次续费日期：{{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title]:
    '请进行验证以解锁更多价格',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description]: `
    要解锁更多价格，您需要完成一个快速的验证过程，只需1-2分钟。立即尝试！<br><br>
    您的下次续费日期：{{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title]:
    '请升级您的计划以解锁更多价格',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
    .description]: `
    您的下次续订日期：{{date}} <br> 
    请升级您的计划以解锁这些价格。`,
  [ProfileKeys.Edit.Package.Price.Modal.btn]: '立即升级',

  [ProfileKeys.Edit.Package.Price.toast]: `
    解锁成功！（剩余{{number}}次）`,

  [ProfileKeys.Edit.Package.Price.Error.minimum]: '最低价格为{{number}}',
  [ProfileKeys.Edit.Package.Price.Error.maximum]: '最高价格为{{number}}',

  // Contents
  [ProfileKeys.Edit.Package.Contents.YouTube.video]: 'YouTube 视频',
  [ProfileKeys.Edit.Package.Contents.YouTube.shorts]: 'YouTube 短视频',
  [ProfileKeys.Edit.Package.Contents.YouTube.live]: 'YouTube 直播',

  [ProfileKeys.Edit.Package.Contents.Twitter.post]: 'X 文章',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_post]:
    'Instagram 帖子',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_story]:
    'Instagram 动态',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels]:
    'Instagram 卷轴',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_live]:
    'Instagram 直播',

  [ProfileKeys.Edit.Package.Contents.Tiktok.video]: 'TikTok 视频',
  [ProfileKeys.Edit.Package.Contents.Tiktok.live]: 'TikTok 直播',

  // Delivery
  [ProfileKeys.Edit.Package.Delivery.placeholder]: '输入交付时长（7~60天）',
  [ProfileKeys.Edit.Package.Delivery.input]: ' 天交付',
  [ProfileKeys.Edit.Package.Delivery.tooltip]: `
    选择您需要为准备完整交付所需的时间。`,

  // Revisions
  [ProfileKeys.Edit.Package.Revisions.no_revisions]: '无修订',
  [ProfileKeys.Edit.Package.Revisions.tooltip]: `
    您将在订单中提供的修改次数。`,

  // Type/Length
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    '例如：一个5~10分钟的产品评测视频',

  // User Info
  // Username
  [ProfileKeys.Edit.User_Info.Username.placeholder]: '输入用户名',
  [ProfileKeys.Edit.User_Info.Username.Error.enter_name]: '请输入名称',
  [ProfileKeys.Edit.User_Info.Username.Error.max_50]: '最多50个字符',
  [ProfileKeys.Edit.User_Info.Username.Error.max_30]: '输入3~30个字符',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol]:
    '检测到无效符号或空格',
  [ProfileKeys.Edit.User_Info.Username.Error.max_2000]: '最多2000个字符',
  [ProfileKeys.Edit.User_Info.Username.Error.max_100]: '最多100个字符',
  [ProfileKeys.Edit.User_Info.Username.Error.max_500]: '最多500个字符',
  [ProfileKeys.Edit.User_Info.Username.Error.max_200]: '最多200个字符',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_price]: '无效价格',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time]:
    '无效交付时间',
  [ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60]:
    '交付时间必须在1到60天之间',

  // My URL
  [ProfileKeys.Edit.User_Info.My_URL.placeholder]: '输入URL',

  [ProfileKeys.Edit.User_Info.My_URL.Error.char_limit]: '输入3~30个字符',
  [ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol]:
    '检测到无效符号或空格',
  [ProfileKeys.Edit.User_Info.My_URL.Error.required_handle]: '请输入网址',
  [ProfileKeys.Edit.User_Info.My_URL.Error.already_taken]: '该URL已被使用。',

  // Social Network
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.priority]: '无法更改优先级',
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink]: '无法解除平台链接',

  [ProfileKeys.Edit.User_Info.Social_Network.Popup.title]: '请先完成您的订单',
  [ProfileKeys.Edit.User_Info.Social_Network.Popup.description]:
    '您可以在取消或完成您的进行中的订单后取消链接您的已验证社交网络帐户。',

  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.title]:
    '您确定要解除此账户的链接吗？',
  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.description]:
    '一旦您取消链接已验证的社交网络帐户，与之相关的数据可能会丢失。此外，相关的包信息将不再出现在您的个人资料和我们的搜索中。',

  // Bio
  [ProfileKeys.Edit.User_Info.Bio.placeholder_creator]:
    '与全球品牌和客户分享您的故事！',
  [ProfileKeys.Edit.User_Info.Bio.placeholder_client]:
    '与全球创作者分享您的故事！',

  // Categories
  [ProfileKeys.Edit.User_Info.Categories.add_a_category]: '添加类别',

  // Target Countries
  [ProfileKeys.Edit.User_Info.Target_Countries.title]: '国家',
  [ProfileKeys.Edit.User_Info.Target_Countries.add_a_country]: '添加国家',
  [ProfileKeys.Edit.User_Info.Target_Countries.tooltip]: `
    请选择您目前居住或最能代表自己的国家。 （最多可选择2个国家）`,

  // Languages
  [ProfileKeys.Edit.User_Info.Languages.add_a_language]: '添加语言',

  // Company
  [ProfileKeys.Edit.User_Info.Company.placeholder]: '所属 · 输入公司名称',
  [ProfileKeys.Edit.User_Info.Company.tooltip]: `
    上传公司标志格式：PNG、JPG、JPEG。 <br> 
    推荐尺寸：360x360。 最大尺寸为50 MB。`,
  [ProfileKeys.Edit.User_Info.Company.Error.max_30]: '最多30个字符',

  // Modal
  [ProfileKeys.Modal.Request_Price.title]: '请求报价',
  [ProfileKeys.Modal.Request_Price.btn]: '发送请求',
  [ProfileKeys.Modal.Request_Price.toast]: '您的请求已成功发送！',
  [ProfileKeys.Modal.Request_Price.Message.title]: '发送消息',
  [ProfileKeys.Modal.Request_Price.Message.placeholder]:
    '提出问题或分享您的项目详情（预算、产品摘要、要求等）',

  [ProfileKeys.Modal.Edit.Banner.heading]: '选择方法',
  [ProfileKeys.Modal.Edit.Banner.body]: '上传图片',
  [ProfileKeys.Modal.Edit.Banner.loading]: '从以下位置加载',
  [ProfileKeys.Modal.Edit.Banner.use_my]: '使用我的{{platform}} {{type}}',
  [ProfileKeys.Modal.Edit.Banner.not_found]: '{{platform}} {{type}}未找到',

  [ProfileKeys.Modal.Edit.Incomplete_Package.title]: '检测到不完整的服务包',
  [ProfileKeys.Modal.Edit.Incomplete_Package.description]: `
    以下服务包不完整： <br><br>
    <package></package> <br>
    请完成这些服务包，以便它们在搜索结果和您的个人资料中显示。您想现在完成它们吗？`,

  [ProfileKeys.Modal.Edit.new_reward]: '您已解锁一个新的奖励。',
  [ProfileKeys.Modal.Edit.skip_for_now]: '暂时跳过',

  [ProfileKeys.Modal.Reconnection.title]: '需要重新连接',
  [ProfileKeys.Modal.Reconnection.description]: `
    由于长时间不活动或政策·功能更新了每个社交媒体平台，请重新连接以下列出的平台。请重新连接或取消关联相应的平台以继续。<br><br>
    MegaEvolution 支持通过 Google、Meta（Instagram）和 TikTok 的官方验证·合作伙伴 API 进行安全连接。`,
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title]:
    '您可以在取消关联当前帐户后连接新帐户',
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description]:
    '检测到与当前连接的帐户不同的帐户。要连接新帐户，请取消关联现有帐户，然后重试。',

  // Guide
  [ProfileKeys.Guide.hi_ginseng_mega]: '你好，我是MEGA！',
  [ProfileKeys.Guide.nice_to_meet_you]: '很高兴认识你~！',
  [ProfileKeys.Guide.create_unique_url]: '创建一个独特的URL',
  [ProfileKeys.Guide.we_help_you_receive]: '我们帮您接收',
  [ProfileKeys.Guide.we_help_you_receive_mobile]: '我们帮您接收提案',
  [ProfileKeys.Guide.proposal_golbal_brands]: '来自全球品牌的提案！',
  [ProfileKeys.Guide.proposal_golbal_brands_mobile]: '来自全球品牌的提案！',
  [ProfileKeys.Guide.create_unique_portfolio]: '创建一个独特的作品集',
  [ProfileKeys.Guide.just_few_clicks]: '只需几个点击！',
  [ProfileKeys.Guide.click_to_join]: '点击加入',
  [ProfileKeys.Guide.click_to_join_mobile]: '点击这里',
  [ProfileKeys.Guide.anytime]: '随时！',
  [ProfileKeys.Guide.anytime_mobile]: '随时加入！',
  [ProfileKeys.Guide.what_a_pleasure]: '今天见到你真是太高兴了！',
  [ProfileKeys.Guide.hope_to_see]: '希望能尽快看到您的独特页面！',
  [ProfileKeys.create_unique_handle]: '使用独特的@handle创建您的URL！',

  // Profile
  [ProfileKeys.Guide.Profile.welcome]: '欢迎，我是MEGA！',
  [ProfileKeys.Guide.Profile.portfolio_space]: '这是专门为您创建的作品集空间',
  [ProfileKeys.Guide.Profile.specially_created]: '特别为您创建。',
  [ProfileKeys.Guide.Profile.click_here_to_start_editing]: '点击这里开始编辑。',
  [ProfileKeys.Guide.Profile.package_will_show_up]: '您的服务包将在此显示！',
  [ProfileKeys.Guide.Profile.click_here]: '点击这里',
  [ProfileKeys.Guide.Profile.to_start_editing]: '开始编辑',
  [ProfileKeys.Guide.Profile.package_will_be_displated]:
    '您的服务包将在此展示！',

  // Profile Edit
  [ProfileKeys.Guide.Profile_Edit.welcome]: '欢迎来到您的个人资料！',
  [ProfileKeys.Guide.Profile_Edit.try_me]:
    '试试我，快速设置一个引人注目的服务包',
  [ProfileKeys.Guide.Profile_Edit.compelling_package]: '引人注目的服务包',
  [ProfileKeys.Guide.Profile_Edit.upload_image]: '上传一张图片来',
  [ProfileKeys.Guide.Profile_Edit.showcase_service]: '展示您的服务',
  [ProfileKeys.Guide.Profile_Edit.add_post_video]: '通过添加内容链接吸引',
  [ProfileKeys.Guide.Profile_Edit.for_your_clients]: '您的客户的注意力！',
  [ProfileKeys.Guide.Profile_Edit.set_up_price]: '在不同模式下设置价格。',
  [ProfileKeys.Guide.Profile_Edit.choose_best_option]:
    '选择最适合您的服务包选项！',
  [ProfileKeys.Guide.Profile_Edit.complete_package]: '完善您的服务包',
  [ProfileKeys.Guide.Profile_Edit.make_it_public]: '使其对公众可见',
  [ProfileKeys.Guide.Profile_Edit.save_description1]:
    '请在离开之前确认是否已保存！',
  [ProfileKeys.Guide.Profile_Edit.save_description2]:
    '个人资料将自动翻译为多种语言。',
  [ProfileKeys.Guide.Profile_Edit.save_description3]: '',
  [ProfileKeys.Guide.Profile_Edit.create_unique_package]:
    '为全球客户创建独特的个人资料！',
  [ProfileKeys.Guide.Profile_Edit.quick_setup]: '快速设置',

  [ProfileKeys.Guide.Profile_Edit.Toast.register_success]:
    '注册成功！请设置个人资料以接收订单。',
};
