export const EditIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16 * size}
    height={17 * size}
    viewBox="0 0 16 17"
    fill="currentColor"
  >
    <path d="M2.4 13.3009H5.792C5.89728 13.3015 6.00166 13.2814 6.09912 13.2416C6.1966 13.2018 6.28525 13.1431 6.36 13.069L11.896 7.5266L14.168 5.30325C14.243 5.2289 14.3025 5.14044 14.3431 5.04298C14.3837 4.94552 14.4046 4.84099 14.4046 4.73541C14.4046 4.62983 14.3837 4.5253 14.3431 4.42784C14.3025 4.33038 14.243 4.24192 14.168 4.16758L10.776 0.73657C10.7016 0.661609 10.6131 0.602111 10.5157 0.561508C10.4182 0.520905 10.3136 0.5 10.208 0.5C10.1024 0.5 9.99783 0.520905 9.90034 0.561508C9.80285 0.602111 9.71437 0.661609 9.64 0.73657L7.384 2.99991L1.832 8.54231C1.75785 8.61704 1.69919 8.70566 1.65938 8.80311C1.61957 8.90055 1.59939 9.00489 1.6 9.11014V12.5012C1.6 12.7133 1.68429 12.9167 1.83431 13.0667C1.98434 13.2167 2.18783 13.3009 2.4 13.3009ZM10.208 2.43208L12.472 4.69542L11.336 5.83109L9.072 3.56775L10.208 2.43208ZM3.2 9.43805L7.944 4.69542L10.208 6.95877L5.464 11.7014H3.2V9.43805ZM15.2 14.9005H0.8C0.587827 14.9005 0.384344 14.9847 0.234315 15.1347C0.0842854 15.2847 0 15.4881 0 15.7002C0 15.9123 0.0842854 16.1158 0.234315 16.2658C0.384344 16.4157 0.587827 16.5 0.8 16.5H15.2C15.4122 16.5 15.6157 16.4157 15.7657 16.2658C15.9157 16.1158 16 15.9123 16 15.7002C16 15.4881 15.9157 15.2847 15.7657 15.1347C15.6157 14.9847 15.4122 14.9005 15.2 14.9005Z" />
  </svg>
);
