import SettingsKeys from '../translationKeys/SettingsPageKeys';

export const SettingsTranslations = {
  // Signed with
  [SettingsKeys.Signed_With.wallet]: 'ウェレットでサインイン',
  [SettingsKeys.Signed_With.google]: 'Googleでサインイン',
  [SettingsKeys.Signed_With.facebook]: 'facebookでサインイン',

  // Account
  [SettingsKeys.Account.connected_email]: '接続されたメールアドレス',

  // Password
  [SettingsKeys.Password.current_password]: '現在のパスワード',
  [SettingsKeys.Password.new_password]: '新しいパスワード',
  [SettingsKeys.Password.confirm_new_password]: '新しいパスワードの確認',

  [SettingsKeys.Password.Rule.title]:
    'パスワードには以下を含む必要があります。',
  [SettingsKeys.Password.Rule.rule_1]: '8~20文字',
  [SettingsKeys.Password.Rule.rule_2]: '少なくとも1つの大文字',
  [SettingsKeys.Password.Rule.rule_3]: '少なくとも1つの小文字',
  [SettingsKeys.Password.Rule.rule_4]: '少なくとも1つの数字または特殊文字',

  [SettingsKeys.Password.Error.not_follow_rule]:
    'パスワードが規則に従っていません',
  [SettingsKeys.Password.Error.characters]:
    '8~20文字の大文字（A-Z）、小文字（a-z）、数字、または特殊文字を含む必要があります。',
  [SettingsKeys.Password.Error.not_match]: 'パスワードが一致しません。',
  [SettingsKeys.Password.Error.current_password_incorrect]:
    '現在のパスワードが正しくありません。',

  [SettingsKeys.Password.update_success]: 'パスワードが正常に更新されました！',

  // Notifications
  [SettingsKeys.Notification.Description.orders]:
    '注文ステータスに関するメールを受け取る。',
  [SettingsKeys.Notification.Description.messages]:
    'メッセージに関するメールを受け取る。',
  [SettingsKeys.Notification.Description.reminders]:
    '締切のリマインダーを受け取る。',
  [SettingsKeys.Notification.Description.newsletters]:
    'リワード、新機能、プロモーションなどに関する有用なメールを受け取る。',
  [SettingsKeys.Notification.Description.packages]:
    'パッケージに関するメールを受け取る（価格のアンロック、クライアントとのやり取りなど）。',
  [SettingsKeys.Notification.Description.real_time]: 'サウンドを有効にする',

  // Referral Code
  [SettingsKeys.Referral_Code.My.title]: '自分の紹介コード',
  [SettingsKeys.Referral_Code.My.description]: 'リワードについてもっと知る',
  [SettingsKeys.Referral_Code.My.copy_code]: 'コードをコピー',
  [SettingsKeys.Referral_Code.My.copied]: 'コピーしました！',

  [SettingsKeys.Referral_Code.Enter.title]: 'コードを入力',

  [SettingsKeys.Referral_Code.Modal.reward_arrive]:
    '紹介ボーナスが届きました。',
  [SettingsKeys.Referral_Code.Modal.my_evo]: '自分のEVO',
  [SettingsKeys.Referral_Code.Modal.check_later]: '後で確認する',

  [SettingsKeys.Referral_Code.Referral_Page.instruction]:
    '有効な紹介コードを入力してください。\n紹介コードは設定タブから探すことができます。',
  [SettingsKeys.Referral_Code.Referral_Page.evo_policy]:
    'エクストラEVOポイント獲得できます。\nポリシーをご確認ください',
  [SettingsKeys.Referral_Code.Referral_Page.enter_code_here]:
    'ここに紹介コードを入力してください',

  // Referral System
  [SettingsKeys.Referral_System.title]: '紹介システム',
  [SettingsKeys.Referral_System.button]: '紹介コードをコピー',

  [SettingsKeys.Referral_System.Refer_A_Friend.lang]: 'en',
  [SettingsKeys.Referral_System.Referral_Reward.lang]: 'en',

  // Deactivate/Delete
  [SettingsKeys.Deactivate_Delete.title]: 'アカウントを無効にする/消去する',

  [SettingsKeys.Deactivate_Delete.Q1.title]:
    'アカウントを無効にするとどうなりますか？',
  [SettingsKeys.Deactivate_Delete.Q1.description]: `
    <heading><span>{{dot}}</span> 無効にしたアカウントのプロフィールが検索一覧に表示されなくなります。</heading> <br>
    <heading><span>{{dot}}</span> アカウント内のデータは非表示になります。</heading> <br>
    <heading><span>{{dot}}</span> 再度アカウントを有効にするには再度ログインをしてください。</heading>`,

  [SettingsKeys.Deactivate_Delete.Q2.title]:
    'アカウントを削除するとどうなりますか？',
  [SettingsKeys.Deactivate_Delete.Q2.description]: `
    <heading><span>{{dot}}</span> 消去したアカウントのプロフィールが検索一覧から消去されます。</heading> <br>
    <heading><span>{{dot}}</span> アカウント内のデータは永久に削除されます。</heading> <br>
    <heading><span>{{dot}}</span> アカウントを再度使用することはできません。</heading>`,

  [SettingsKeys.Deactivate_Delete.warning]:
    '進行中の注文がある場合、アカウントを無効または消去することはできません。アカウントを無効にする/削除する前に、注文をキャンセルしてください。',

  [SettingsKeys.Modal.Wallet_Connected.title]:
    '新しいウォレットが正常に接続されました！',

  [SettingsKeys.Modal.Notification.Newsletters.title]:
    'ニュースレターの購読を解除してもよろしいですか？',
  [SettingsKeys.Modal.Notification.Newsletters.description]:
    'ニュースレターを解除すると、報酬、新機能などに関するアップデートを受け取れなくなります。役立つ情報をお届けすることに努めます！',

  [SettingsKeys.Modal.Notification.Update_Success.title]:
    '通知設定が更新されました。',

  [SettingsKeys.Modal.Referral_Code.Submit_Success.title]:
    'コードが正常に提出されました！',
  [SettingsKeys.Modal.Referral_Code.Submit_Success.description]:
    '今後、さまざまな活動に参加してエクストラEVOポイント獲得することができます。紹介コードの報酬の詳細については、<u>こちらをクリック</u>してください。',

  [SettingsKeys.Modal.Referral_Code.Submit_Valid.title]:
    '有効な紹介コードを提出してください',
  [SettingsKeys.Modal.Referral_Code.Submit_Valid.description]:
    '入力したコードは無効です。有効な紹介コードを入力してください。',

  [SettingsKeys.Modal.Deactivate.title]:
    'アカウントを無効にしてもよろしいですか？',
  [SettingsKeys.Modal.Deactivate.description]:
    '再度ログインすることでアカウントを再び使用することが可能です。',

  [SettingsKeys.Modal.Delete.title]: 'アカウントを削除してもよろしいですか？',
  [SettingsKeys.Modal.Delete.description]:
    'アカウントを消去するとアカウントのデータが消去され、復旧することができなくなります。メッセージや注文に関するデータはクライアント側に残る場合があります。',

  [SettingsKeys.Modal.Complete_Cancel_Order.title]:
    'アカウントを無効にするには、、注文を完了またはキャンセルしてください。',
  [SettingsKeys.Modal.Complete_Cancel_Order.description]:
    '進行中の注文がある場合、アカウントを無効にすることはできません。',

  [SettingsKeys.Modal.Why_Leaving.title]: '退会の理由を教えてください',
  [SettingsKeys.Modal.Why_Leaving.reason_1]: '利用したいサービスがない。',
  [SettingsKeys.Modal.Why_Leaving.reason_2]: '価格が高すぎる。',
  [SettingsKeys.Modal.Why_Leaving.reason_3]: '頻繁にエラーが発生する。',
  [SettingsKeys.Modal.Why_Leaving.reason_4]:
    'クーポンや予約などの利益が少ない。',
  [SettingsKeys.Modal.Why_Leaving.reason_5]: 'サービスの品質が低い。',
  [SettingsKeys.Modal.Why_Leaving.reason_6]: '代替サービスを見つけた。',
  [SettingsKeys.Modal.Why_Leaving.reason_7]: '不適切なメンバーに出会った。',
  [SettingsKeys.Modal.Why_Leaving.reason_8]: 'データを削除したい。',
  [SettingsKeys.Modal.Why_Leaving.button]: 'アカウントを削除',

  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title]:
    'まだアカウントを無効または消去することができません。',
  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description]:
    'アカウントを作成してから24時間後に無効または消去することができます。しばらくしてから再度お試しください。',
};
