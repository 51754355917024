import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]:
    '認証されたグローバル企業とプラットフォームで自動的に接続＆コラボしましょう！',
  [InvitationKeys.title2]: '',
  [InvitationKeys.subtitle]:
    '＊最適な体験のために、Web環境の利用をお勧めします。',

  [InvitationKeys.description]:
    '無料のプロフィールだけで、MCNなしにも日本、アメリカ、ヨーロッパ、韓国などの認証されたグローバル企業と <br> 気軽にコラボできます。',

  [InvitationKeys.view_creator_tutorial]: 'クリエイターとして登録',
  [InvitationKeys.explore_btn]: 'プロフィールサンプルを見る',
  [InvitationKeys.learn_more]: '詳細を見る',
  [InvitationKeys.main_site]: 'メインサイト',

  [InvitationKeys.Browser.title]:
    'サポートされていないブラウザを使用しているようです',
  [InvitationKeys.Browser.description]:
    '最適な体験のために上記のブラウザをご利用ください。一部の機能はこのブラウザでは利用できない場合があります。',
  [InvitationKeys.Browser.button]: '了解しました',

  [InvitationKeys.Mega_Flow.title]:
    'メガエボリューションでは、以下のように進行します！',
  [InvitationKeys.Mega_Flow.Caption.one]:
    '1〜2分でクリエーター\n プロフィールを作成できます。',
  [InvitationKeys.Mega_Flow.Caption.two]:
    'プロフィールを*自動的に*認証された\n 企業に推薦し、紹介します。',
  [InvitationKeys.Mega_Flow.Caption.three]:
    'グローバルブランドからの注文を\n 確認して受け入れます。',
  [InvitationKeys.Mega_Flow.Caption.four]:
    'コンテンツを提出して、コンテンツ\n ライセンスで*さらに収益*を得ます。',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    '1〜2分でクリエータープロフィールを作成できます。',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    'プロフィールを*自動的に*認証された\n 企業に推薦し、紹介します。',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]:
    'グローバルブランドからの注文を\n 確認して受け入れます。',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    'コンテンツを提出して、コンテンツ\n ライセンスで*さらに収益*を得ます。',

  [InvitationKeys.Creator_Review.title]: 'クリエーターレビュー',
};
