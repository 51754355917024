import FAQKeys from '../translationKeys/FAQPageKeys';

export const FAQTranslations = {
  // General
  [FAQKeys.General.title]: 'Chung',

  [FAQKeys.General.Q1.title]: 'Làm thế nào để bắt đầu với MegaEvolution?',
  [FAQKeys.General.Q1.description]:
    'Để bắt đầu hành trình marketing người ảnh hưởng của bạn với MegaEvolution, chỉ cần truy cập trang web của chúng tôi tại megaevolution.io từ trình duyệt trên máy tính hoặc điện thoại di động của bạn. Sau khi nhấp vào nút "Đăng nhập", bạn có thể đăng ký bằng email cá nhân, Google, Facebook hoặc ví (với MetaMask hoặc Coinbase Wallet). Đồng ý với các điều khoản dịch vụ của chúng tôi và xác minh email của bạn nếu cần thiết. Khi đã đăng ký, bạn có thể dễ dàng kết nối với các nhà ảnh hưởng và tận dụng sức lan rộng của họ để quảng bá sản phẩm hoặc dịch vụ của bạn đến một đối tượng khách hàng rộng lớn hơn. Nền tảng của chúng tôi cung cấp các tính năng tiên tiến như phân tích kênh/người xem của người tạo nội dung và hệ thống đơn đặt hàng và thanh toán tổng thể, giúp cho doanh nghiệp triển khai một chiến dịch người ảnh hưởng hiệu quả một cách dễ dàng.',

  [FAQKeys.General.Q2.title]: 'MegaEvolution là gì?',
  [FAQKeys.General.Q2.description]:
    'MegaEvolution là một nền tảng marketing người ảnh hưởng kết nối khách hàng với người tạo nội dung toàn cầu để quảng bá sản phẩm và dịch vụ của họ. Khách hàng có thể dễ dàng tìm và đặt các gói marketing, trong khi người tạo nội dung có thể quảng bá bản thân và tăng cơ hội và lợi nhuận thông qua dịch vụ của họ. MegaEvolution tối ưu hóa toàn bộ quy trình từ đặt hàng đến quản lý quảng cáo, tạo ra một trải nghiệm dễ dàng và thuận tiện cho tất cả người dùng.',

  [FAQKeys.General.Q3.title]:
    'MegaEvolution cung cấp gì cho người tạo nội dung?',
  [FAQKeys.General.Q3.description]:
    'MegaEvolution cho phép người tạo nội dung kết nối với khách hàng thương hiệu từ khắp nơi trên thế giới. Bằng cách quảng cáo dịch vụ của họ thông qua MegaEvolution, người tạo nội dung có thể dễ dàng nhận được đơn đặt hàng từ khách hàng, và chúng tôi hỗ trợ toàn bộ quy trình đặt hàng và thanh toán để giúp người tạo nên tối đa cơ hội và lợi nhuận của mình.',

  [FAQKeys.General.Q4.title]: 'MegaEvolution cung cấp gì cho khách hàng?',
  [FAQKeys.General.Q4.description]:
    'MegaEvolution kết nối khách hàng với nhiều người tạo nội dung khác nhau, cung cấp thông tin như phân tích kênh và giá cả marketing. Khách hàng có thể đặt mua các gói marketing từ người tạo nội dung chỉ bằng một cú nhấp chuột, và chúng tôi cung cấp hỗ trợ toàn diện trong quá trình đặt hàng và thanh toán để đảm bảo một trải nghiệm an toàn và thuận tiện.',

  [FAQKeys.General.Q5.title]: 'EVO point là gì và hoạt động như thế nào?',
  [FAQKeys.General.Q5.description]:
    'EVO Point cho phép truy cập vào giá gói dịch vụ của người tạo nội dung và sớm có thể được sử dụng cho sự kiện, bỏ phiếu và quản trị. Chúng tôi đang lên kế hoạch niêm yết nó trên các sàn giao dịch khác nhau, cho phép chuyển đổi sang các loại tiền hoặc tiền mặt khác nhau.',

  // Hệ thống Gói
  [FAQKeys.Package_System.title]: 'Hệ thống Gói',

  [FAQKeys.Package_System.Q1.title]: 'Gói là gì tại MegaEvolution?',
  [FAQKeys.Package_System.Q1.description]:
    'Gói tại MegaEvolution là một hệ thống cho phép người tạo nên trưng bày dịch vụ của họ, bao gồm nội dung mà họ sẽ cung cấp, thời lượng, giá cả và mô tả chi tiết về cách nó sẽ được giao cho khách hàng. Người dùng có thể dễ dàng mua các gói này thông qua một vài cú nhấp chuột và quản lý tất cả các đơn đặt hàng thông qua bảng điều khiển của họ. Ngoài ra, người dùng sẽ nhận được thông báo và phân tích về tình trạng các đơn hàng của gói của họ.',

  [FAQKeys.Package_System.Q2.title]: 'Có những loại giá cả nào cho các gói?',
  [FAQKeys.Package_System.Q2.description]:
    'Có ba loại tùy chọn giá cả: ‘Giá Công Khai’ / ‘Giá Có Thể Mở Khóa’ / ‘Liên Hệ Tạo Hóa’. Người tạo có thể thiết lập các tùy chọn giá cả khác nhau cho từng kế hoạch gói. Ví dụ, kế hoạch tiêu chuẩn có thể được thiết lập với Giá Công Khai và kế hoạch cao cấp là Giá Có Thể Mở Khóa.',

  [FAQKeys.Package_System.Q3.title]:
    'Hệ thống mở khóa giá cả được thực hiện như thế nào? Giá cả mở khóa được hiển thị trong bao lâu?',
  [FAQKeys.Package_System.Q3.description]:
    'Tất cả người dùng có thể mở khóa giá của gói một số lần miễn phí mỗi tháng. Để mở khóa giá cho gói của một người tạo, chỉ cần nhấp vào giá bị khóa. Một khi đã mở khóa, bạn sẽ có thể xem tất cả các giá cho người tạo cụ thể đó. Giá mở khóa được hiển thị trong 3 ngày.',

  // Người Tạo
  [FAQKeys.Creators.title]: 'Người Tạo',

  [FAQKeys.Creators.Q1.title]: 'Tôi muốn biết về Phí Dịch Vụ cho Người Tạo.',
  [FAQKeys.Creators.Q1.description]:
    'Hiện tại, chúng tôi không thu phí từ Người Tạo cho mỗi đơn đặt hàng quảng cáo.',

  [FAQKeys.Creators.Q2.title]: 'Mất bao lâu để rút số dư của tôi?',
  [FAQKeys.Creators.Q2.description]:
    'Chuyển khoản thông thường mất từ 1 đến 3 ngày làm việc thông qua PayPal.',

  [FAQKeys.Creators.Q3.title]:
    'Tôi đã hủy liên kết tất cả các tài khoản mạng xã hội sau khi đăng ký làm Người Tạo. Tài khoản của tôi có bị xóa tự động không?',
  [FAQKeys.Creators.Q3.description]:
    'Tài khoản của bạn sẽ không bị xóa. Tuy nhiên, bạn sẽ không thể nhận được bất kỳ đơn đặt hàng nào. Ngoài ra, bạn sẽ không xuất hiện trên cửa sổ tìm kiếm của chúng tôi.',

  [FAQKeys.Creators.Q4.title]:
    'Khách hàng có thể hủy đơn hàng sau khi tôi chấp nhận không',
  [FAQKeys.Creators.Q4.description]:
    'Sau khi người tạo chấp nhận một đơn hàng, việc hủy bỏ chỉ có thể thực hiện nếu cả hai bên đồng ý với việc hủy bỏ. Tuy nhiên, nếu người tạo không giao nội dung đúng hạn hoặc nếu chất lượng nội dung không đạt yêu cầu hoặc khác biệt so với mô tả, đơn hàng có thể bị hủy thông qua Trung tâm Giải quyết. Lưu ý rằng việc hủy đơn hàng có thể có hậu quả tiêu cực đối với tài khoản của bạn và ảnh hưởng đến uy tín của bạn là một người tạo trên MegaEvolution.',

  // Trợ Giúp Đơn Đặt Hàng
  [FAQKeys.Orders_Help.title]: 'Trợ Giúp Đơn Đặt Hàng',

  [FAQKeys.Orders_Help.Q1.title]: 'Làm thế nào để gia hạn và hủy đơn đặt hàng?',
  [FAQKeys.Orders_Help.Q1.description]:
    'Bạn có thể gia hạn thời hạn của một đơn đặt hàng hoặc hủy nó bằng cách gửi yêu cầu cho đối tác của bạn. Bạn có thể chấp nhận hoặc từ chối yêu cầu bạn nhận được. Các yêu cầu sẽ được tự động chấp nhận nếu không được phản hồi trong vài ngày (3 ngày cho gia hạn, 5 ngày cho hủy).',

  [FAQKeys.Orders_Help.Q2.title]:
    'Tôi có thể hủy đơn hàng sau khi người tạo chấp nhận không?',
  [FAQKeys.Orders_Help.Q2.description]:
    'Thường thì, sau khi Khách hàng chấp nhận đơn đặt hàng, chỉ có thể hủy nếu Người Tạo đồng ý. Tuy nhiên, nó có thể bị hủy qua Trung tâm Giải quyết nếu ngày giao hàng không được tuân thủ hoặc chất lượng không đạt yêu cầu.',

  [FAQKeys.Orders_Help.Q3.title]:
    'Tôi phải làm gì nếu tôi không nhận được nội dung?',
  [FAQKeys.Orders_Help.Q3.description]:
    'Người Tạo được cho là phải gửi liên kết của từng nội dung được đặt hàng. Nếu bạn không nhận được nội dung cho đến ngày đến hạn, bạn có thể yêu cầu hủy đơn hàng và nhận hoàn tiền.',

  // Payment Help
  [FAQKeys.Payment_Help.title]: 'Trợ Giúp Thanh Toán',

  [FAQKeys.Payment_Help.Q1.title]:
    'Làm thế nào để kiểm tra lịch sử thanh toán của tôi?',
  [FAQKeys.Payment_Help.Q1
    .description]: `1. Đăng nhập vào tài khoản của bạn và nhấp vào 'Không Gian Của Tôi' ở góc trên bên phải của trang web. Sau đó, nhấp vào nút 'Thanh Toán'.
2. Sử dụng tab “Lịch Sử Thanh Toán” để xem giao dịch của bạn. Bạn cũng có thể tải hóa đơn dưới dạng PDF.`,

  [FAQKeys.Payment_Help.Q2.title]: 'Làm thế nào để thanh toán?',
  [FAQKeys.Payment_Help.Q2
    .description]: `1. Đi đến 'Không Gian Của Tôi' ở góc trên bên phải của trang web. Sau đó, đi đến 'Thanh Toán'.
2. Trên tab 'Phương Thức Thanh Toán', thêm 'Thẻ Tín Dụng & Ghi Nợ' hoặc 'Tài Khoản PayPal'.
3. Sau đó, bạn có thể sử dụng thông tin đã thêm để thanh toán.`,

  [FAQKeys.Payment_Help.Q3.title]:
    'MegaEvolution làm hoàn tiền cho khách hàng như thế nào?',
  [FAQKeys.Payment_Help.Q3.description]:
    'Đối với các đơn đặt hàng từ người tạo, hoàn tiền đầy đủ được thực hiện khi hủy. Nếu đã có nội dung được xuất bản, sẽ có sự trừ khấu trừ tỷ lệ tương ứng trước khi hoàn tiền. Không có hoàn tiền nào được thực hiện cho các đăng ký hàng tháng tự động của nền tảng.',

  [FAQKeys.Payment_Help.Q4.title]:
    'Phí dịch vụ sử dụng MegaEvolution là bao nhiêu và hoạt động như thế nào?',
  [FAQKeys.Payment_Help.Q4.description]:
    'MegaEvolution thu phí dịch vụ là 5.5% + $3 cho mỗi đơn đặt hàng gói. Chúng tôi cung cấp một trong những mức phí dịch vụ cạnh tranh nhất trong ngành. Phí nhỏ này giúp chúng tôi vận hành nền tảng và hỗ trợ quá trình từng đơn đặt hàng. Nếu bạn là người dùng đăng ký, bạn sẽ nhận được giảm giá trên phí dịch vụ.',

  [FAQKeys.Payment_Help.Q5.title]: 'Có những loại gói đăng ký hàng tháng nào?',
  [FAQKeys.Payment_Help.Q5.description]:
    'Chúng tôi cung cấp 3 loại gói đăng ký hàng tháng: Cơ Bản (miễn phí), Tiêu Chuẩn và Premium. Gói Cơ Bản cung cấp quyền truy cập vào hầu hết các chức năng của nền tảng, trong khi Tiêu Chuẩn và Premium bao gồm các tính năng bổ sung như thêm lượt mở khóa giá, phân tích và gửi thông điệp hàng loạt. Các gói trả phí cũng đi kèm với mức giảm giá nhỏ trên phí dịch vụ cho các đơn đặt hàng.',

  // Account Help
  [FAQKeys.Account_Help.title]: 'Trợ Giúp Tài Khoản',

  [FAQKeys.Account_Help.Q1.title]:
    'Tôi phải làm gì nếu tôi không nhớ chi tiết đăng nhập (ID hoặc mật khẩu)?',
  [FAQKeys.Account_Help.Q1.description]:
    "Để tạo tài khoản trên MegaEvolution, bạn có thể sử dụng email của bạn, Google, Facebook, hoặc ví tiền (với MetaMask hoặc Coinbase Wallet). Nếu bạn quên mật khẩu cho tài khoản email của mình, chỉ cần vào trang đăng nhập và nhấp vào 'quên mật khẩu?' sau đó, nhập địa chỉ email của bạn và bạn sẽ nhận được một email có liên kết để đặt lại mật khẩu.",

  [FAQKeys.Account_Help.Q2.title]: 'Làm thế nào để thay đổi mật khẩu?',
  [FAQKeys.Account_Help.Q2
    .description]: `1. Đi đến 'Không Gian Của Tôi' ở góc trên bên phải của trang sau khi đăng nhập.
2. Nhấp vào 'Cài Đặt' và cập nhật mật khẩu mới của bạn trong phần 'Tài Khoản'.`,

  [FAQKeys.Account_Help.Q3.title]:
    'Làm thế nào để vô hiệu hóa hoặc xóa tài khoản của tôi?',
  [FAQKeys.Account_Help.Q3
    .description]: `1. Đi đến 'Không Gian Của Tôi' ở góc trên bên phải của trang sau khi đăng nhập.
2. Nhấp vào Cài Đặt và cuộn xuống phần Vô Hiệu Hóa/Xóa.
3. Nhấp vào nút ‘Vô Hiệu Hóa’ hoặc ‘Xóa’. (Tất cả dữ liệu có thể bị mất và không thể khôi phục sau khi bạn xóa tài khoản.)`,

  // Safety & Privacy
  [FAQKeys.Safety_Privacy.title]: 'An Toàn & Bảo Mật',

  [FAQKeys.Safety_Privacy.Q1.title]:
    'Thông tin của tôi được bảo mật như thế nào?',
  [FAQKeys.Safety_Privacy.Q1.description]:
    'Tại MegaEvolution, chúng tôi rất nghiêm túc trong việc bảo vệ quyền riêng tư và an ninh của bạn. Chúng tôi sử dụng các biện pháp tiêu chuẩn ngành để đảm bảo thông tin cá nhân của bạn được bảo vệ khỏi việc truy cập hoặc sử dụng trái phép. Để biết thêm thông tin về cam kết bảo vệ quyền riêng tư của chúng tôi, vui lòng tham khảo Chính Sách Quyền Riêng Tư của chúng tôi.',

  [FAQKeys.Safety_Privacy.Q2.title]: 'MegaEvolution lưu trữ loại dữ liệu nào?',
  [FAQKeys.Safety_Privacy.Q2.description]:
    'MegaEvolution lưu trữ nhiều loại dữ liệu quan trọng cho người dùng của chúng tôi, bao gồm hồ sơ đặt hàng, thông tin thanh toán, dữ liệu người dùng, hồ sơ, thông tin gói dịch vụ, thông tin nhật ký kỹ thuật, và nhiều hơn nữa.',

  [FAQKeys.Safety_Privacy.Q3.title]:
    'Làm thế nào để vô hiệu hóa hoặc xóa tài khoản của tôi?',
  [FAQKeys.Safety_Privacy.Q3
    .description]: `1. Đi đến 'Không Gian Của Tôi' ở góc trên bên phải của trang sau khi đăng nhập.
2. Nhấp vào Cài Đặt và cuộn xuống phần Vô Hiệu Hóa/Xóa.
3. Nhấp vào nút ‘Vô Hiệu Hóa’ hoặc ‘Xóa’. (Tất cả dữ liệu có thể bị mất và không thể khôi phục sau khi bạn xóa tài khoản.)`,
};
