import ProfileKeys from '../translationKeys/ProfilePageKeys';

export const ProfileTranslations = {
  // Menu
  [ProfileKeys.Menu.edit]: 'แก้ไขโปรไฟล์',
  [ProfileKeys.Menu.save]: 'บันทึกโปรไฟล์',
  [ProfileKeys.Menu.message_me]: 'ส่งข้อความถึงฉัน',

  // Menu Error
  [ProfileKeys.Menu.Error.creator_to_creator]:
    'โปรดใช้บัญชีที่ไม่ใช่ครีเอเตอร์',
  [ProfileKeys.Menu.Error.something_went_wrong]: 'เกิดข้อผิดพลาดบางอย่าง.',
  [ProfileKeys.Menu.Error.self_chat]: 'ไม่สามารถส่งข้อความถึงการสนทนานี้ได้.',

  // Package
  [ProfileKeys.Package.edit]: 'แก้ไขแพ็กเกจ',
  [ProfileKeys.Package.no_content]: 'ไม่มีเนื้อหา',
  [ProfileKeys.Package.available]: 'ที่ว่างอยู่พร้อมใช้งาน',
  [ProfileKeys.Package.click_to_view]: 'คลิกเพื่อดู',
  [ProfileKeys.Package.click_to_view_recent]: 'คลิกเพื่อดู {{content}} ล่าสุด',
  [ProfileKeys.Package.view_recent_videos]: 'ดูวิดีโอล่าสุด',

  // Topbar
  [ProfileKeys.Package.TopBar.tooltip]: 'แผนนี้ไม่สามารถใช้ได้ในขณะนี้.',

  // YouTube
  [ProfileKeys.Package.YouTube.Contents.video_prepare]: 'กำลังเตรียมแพ็กเกจ',
  [ProfileKeys.Package.YouTube.Contents.video_display]:
    'วิดีโอของคุณจะแสดงที่นี่.',

  // Description
  [ProfileKeys.Package.Description.default]: `
    ครีเอเตอร์กำลังเตรียมแพ็กเกจในขณะนี้ <br>
    โปรดตรวจสอบอีกครั้ง`,

  // Details
  [ProfileKeys.Package.Details.Price.contact_me]: 'ติดต่อฉัน',
  [ProfileKeys.Package.Details.Price.contact_creator]: 'ติดต่อครีเอเตอร์',
  [ProfileKeys.Package.Details.Price.service_fee]: 'รวมค่าบริการ',
  [ProfileKeys.Package.Details.Price.tooltip]: `
    โปรดลองอีกครั้งด้วย <br>
    แพ็กเกจของครีเอเตอร์อื่น`,

  [ProfileKeys.Package.Details.Toast.update_successful]: 'อัปเดตสำเร็จแล้ว',
  [ProfileKeys.Package.Details.Toast.could_not_update]: 'ไม่สามารถอัปเดตได้',

  // Creator Summary
  [ProfileKeys.Creator_Summary.title]: 'สรุปโปรไฟล์ครีเอเตอร์',
  [ProfileKeys.Creator_Summary.sub_title]: 'ข้อมูลพื้นฐานของช่องครีเอเตอร์',
  [ProfileKeys.Creator_Summary.package_for_other_platforms]:
    'แพ็กเกจสำหรับแพลตฟอร์มอื่น',

  // Basic Info
  [ProfileKeys.Creator_Summary.BasicInfo.hollow]:
    'กำลังเตรียมความพร้อมเครือข่ายทางสังคม',

  // Tooltip
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers]: 'สมาชิก',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers]: 'ผู้ติดตาม',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos]: 'วิดีโอทั้งหมด',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets]:
    'โพสต์ X ทั้งหมด',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts]: 'โพสต์ทั้งหมด',

  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube]:
    'วันที่เข้าร่วม YouTube',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter]:
    'วันที่เข้าร่วม X',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram]:
    'วันที่เข้าร่วม Instagram',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok]:
    'วันที่เข้าร่วม TikTok',

  // Channel Contents
  [ProfileKeys.Channel_Contents.title]: 'เนื้อหาของช่อง',
  [ProfileKeys.Channel_Contents.sub_title]: 'ตัวชี้วัดระดับความสนใจในเนื้อหา',
  [ProfileKeys.Channel_Contents.views_to_subs]: 'จำนวนการดู vs ผู้ติดตาม',
  [ProfileKeys.Channel_Contents.views_to_followers]: 'จำนวนการดู vs ผู้ติดตาม',
  [ProfileKeys.Channel_Contents.reach_to_followers]: 'การดู vs ผู้ติดตาม',
  [ProfileKeys.Channel_Contents.est_engagement]: 'อัตราการเข้าร่วมที่คาดหวัง',
  [ProfileKeys.Channel_Contents.channel_data]: 'ข้อมูลช่อง',
  [ProfileKeys.Channel_Contents.loading_post]: 'กำลังโหลดโพสต์',

  [ProfileKeys.Channel_Contents.Twitter.feed]: 'โพสต์ X',
  [ProfileKeys.Channel_Contents.Twitter.loading]: 'กำลังโหลด โพสต์ X',
  [ProfileKeys.Channel_Contents.Twitter.most_popular]: 'โพสต์ X ยอดนิยมที่สุด',
  [ProfileKeys.Channel_Contents.Tiktok.loading]: 'กำลังโหลด TikTok',

  [ProfileKeys.Channel_Contents.Filter._1_month]: '1 เดือน',
  [ProfileKeys.Channel_Contents.Filter._3_months]: '3 เดือน',
  [ProfileKeys.Channel_Contents.Filter._1_year]: '1 ปี',
  [ProfileKeys.Channel_Contents.Filter.newest]: 'ล่าสุด',
  [ProfileKeys.Channel_Contents.Filter.most_viewed]: 'จัดเรียงตามจำนวนการดู',
  [ProfileKeys.Channel_Contents.Filter.most_liked]: 'จัดเรียงตามความชอบ',
  [ProfileKeys.Channel_Contents.Filter.most_commented]:
    'จัดเรียงตามความคิดเห็น',

  [ProfileKeys.Channel_Contents.Tooltip.youtube]: `
    สถิตินี้เป็นข้อมูลจากช่วง 1-6 เดือนที่ผ่านมา <br>
    คุณสามารถตรวจสอบวิดีโอที่อัปโหลดในปีที่ผ่านมาได้`,
  [ProfileKeys.Channel_Contents.Tooltip.twitter]: `
    สถิติข้อมูล X บางส่วน <br>
    ซึ่งข้อมูลนี้อาจแตกต่างจากความเป็นจริง <br>
    สถิตินี้เป็นข้อมูลจากช่วง 1-3 เดือนที่ผ่านมา`,
  [ProfileKeys.Channel_Contents.Tooltip.instagram]: `
    สถิตินี้เป็นข้อมูลจากช่วง 1-3 เดือนที่ผ่านมา`,
  [ProfileKeys.Channel_Contents.Tooltip.tiktok]: `
    สถิตินี้เป็นข้อมูลจากช่วง 1-3 เดือนที่ผ่านมา`,

  [ProfileKeys.Channel_Contents.Tooltip.likes]: 'คะแนนความชอบ vs จำนวนการดู',
  [ProfileKeys.Channel_Contents.Tooltip.comments]: 'ความคิดเห็น vs จำนวนการดู',
  [ProfileKeys.Channel_Contents.Tooltip.x_reposts]:
    'การรีโพสต์ X vs จำนวนการดู',
  [ProfileKeys.Channel_Contents.Tooltip.shares]: 'แชร์ vs จำนวนการดู',

  [ProfileKeys.Channel_Contents.Tooltip.views_subs_rating]:
    'จำนวนผู้ติดตาม vs จำนวนการดู',
  [ProfileKeys.Channel_Contents.Tooltip.views_follower_rating]:
    'จำนวนผู้ติดตาม vs จำนวนการดู',
  [ProfileKeys.Channel_Contents.Tooltip.engagements]: 'อัตราการเข้าร่วมทั้งหมด',

  // Channel Analytics
  [ProfileKeys.Channel_Analytics.title]: 'การวิเคราะห์ช่อง',
  [ProfileKeys.Channel_Analytics.sub_title]:
    'วัดผลประสิทธิภาพทั่วไปโดยมาตรการปริมาณผลชี้วัดของช่อง',
  [ProfileKeys.Channel_Analytics.updated_on]: 'อัปเดตเมื่อ {{date}}',
  [ProfileKeys.Channel_Analytics.avg_views]: 'ค่าเฉลี่ยจำนวนการดู',
  [ProfileKeys.Channel_Analytics.avg_impressions]: 'ค่าเฉลี่ยการแสดงผล',
  [ProfileKeys.Channel_Analytics.avg_reach]: 'ค่าเฉลี่ยจำนวนคนดู',
  [ProfileKeys.Channel_Analytics.avg_likes]: 'ค่าเฉลี่ยความชอบ',
  [ProfileKeys.Channel_Analytics.avg_x_reposts]: 'ค่าเฉลี่ยการรีโพสต์ X',
  [ProfileKeys.Channel_Analytics.engagements]: 'อัตราการดู',

  // Views Analysis
  [ProfileKeys.Channel_Analytics.Views_Analysis.title]:
    'การวิเคราะห์จำนวนการดู',
  [ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram]:
    'การวิเคราะห์จำนวนคนดู',
  [ProfileKeys.Channel_Analytics.Views_Analysis.periodic]: 'ระยะช่วงสั้น',
  [ProfileKeys.Channel_Analytics.Views_Analysis.total]: 'ระยะรวม',

  // Subscribers Data
  [ProfileKeys.Channel_Analytics.Subscribers_Data.title]: 'ข้อมูลผู้ติดตาม',

  // Followers Data
  [ProfileKeys.Channel_Analytics.Followers_Data.title]: 'ข้อมูลผู้ติดตาม',

  // Audience Analytics
  [ProfileKeys.Audience_Analytics.title]: 'การวิเคราะห์ผู้ชม',
  [ProfileKeys.Audience_Analytics.sub_title]: 'วิเคราะห์ฐานข้อมูลการดูช่อง',
  [ProfileKeys.Audience_Analytics.preparing_data]: 'ระบบกำลังเก็บข้อมูล',

  // Audience Interest
  [ProfileKeys.Audience_Analytics.Audience_Interest.title]: 'ความสนใจของผู้ชม',
  [ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags]:
    'แท็กยอดนิยม',
  [ProfileKeys.Audience_Analytics.Keyword_Cloud.title]: 'คลาวด์คีย์เวิร์ด',
  [ProfileKeys.Audience_Analytics.Top_10.title]: '10 คำค้นหายอดฮิต',

  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title]:
    'เช็กแผนเรา',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description]: `
    โปรดตรวจสอบและอัปเกรดแผนของคุณ <br>
    เพื่อเข้าถึงข้อมูลผู้ชมเพิ่มเติม`,

  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.title]:
    'จำเป็นต้องเข้าสู่ระบบ',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.description]: `
    โปรดเข้าสู่ระบบก่อน <br>
    เพื่อเข้าถึงข้อมูลผู้ชมของครีเอเตอร์`,

  // Review
  [ProfileKeys.Review.review_as_client]: 'รีวิวลูกค้า',
  [ProfileKeys.Review.most_recent]: 'ล่าสุด',
  [ProfileKeys.Review.most_helpful]: 'ที่เป็นประโยชน์',
  [ProfileKeys.Review.no_reviews]: 'ไม่มีรีวิวให้แสดง',
  [ProfileKeys.Review.recent_clients]: 'ลูกค้าล่าสุด',

  [ProfileKeys.Review.star]: 'ดาว',
  [ProfileKeys.Review.stars]: 'ดาว',

  // Edit
  [ProfileKeys.Edit.Toast.being_saved]: 'กำลังบันทึกโปรไฟล์ของคุณ',
  [ProfileKeys.Edit.Toast.saved]: 'บันทึกโปรไฟล์ของคุณสำเร็จแล้ว',
  [ProfileKeys.Edit.Toast.failed]: 'ไม่สามารถบันทึกโปรไฟล์ได้ โปรดลองอีกครั้ง',

  // Package
  [ProfileKeys.Edit.Package.complete_percentage]:
    'แพ็กเกจนี้เสร็จสมบูรณ์ {{number}}%',

  [ProfileKeys.Edit.Package.Topbar.tooltip]: `
    เมื่อทำแพ็กเกจเสร็จสมบูรณ์แล้ว <br>
    จะปรากฏในโปรไฟล์`,

  [ProfileKeys.Edit.Package.Topbar.Representative.tooltip]: `
    แพ็กเกจตัวแทนจะถูกแสดง <br>
    แก่บริษัทและผู้ใช้`,

  [ProfileKeys.Edit.Package.Topbar.Completion.tooltip]: `
    จำเป็นต้องทำแพ็กเกจให้เสร็จก่อน <br>
    ค่อยเลือกแพ็กเกจตัวแทน`,

  // Banner
  [ProfileKeys.Edit.Package.Banner.heading]:
    'แนะนำอัตราส่วนหน้าปก 1920X318 / ขนาดไม่เกิน 20MB',
  [ProfileKeys.Edit.Package.Banner.mobile_heading]:
    'ขนาดไม่เกิน 20 MB / หน้าปก 1920X318',
  [ProfileKeys.Edit.Package.Banner.tooltip]: `
    เพิ่มแสงเงาในหน้าปก`,

  // Options
  [ProfileKeys.Edit.Package.Options.intro_and_closing]: 'บทนำและปิด',
  [ProfileKeys.Edit.Package.Options.product_review]: 'รีวิวสินค้า',
  [ProfileKeys.Edit.Package.Options.visit_review]: 'รีวิวสถานที่',
  [ProfileKeys.Edit.Package.Options.digital_product_review]:
    'รีวิวสินค้าดิจิทัล',
  [ProfileKeys.Edit.Package.Options.brand_ambassador]: 'ตัวแทนของแบรนด์',
  [ProfileKeys.Edit.Package.Options.short_video]: 'วิดีโอสั้น',
  [ProfileKeys.Edit.Package.Options.product_placement]: 'การโฆษณาแฝง',
  [ProfileKeys.Edit.Package.Options.interview]: 'สัมภาษณ์',
  [ProfileKeys.Edit.Package.Options.commercial_break]: 'พักโฆษณา',
  [ProfileKeys.Edit.Package.Options.live_streaming]: 'สตรีมสด',
  [ProfileKeys.Edit.Package.Options.giveaways]: 'ให้ของขวัญ',
  [ProfileKeys.Edit.Package.Options.meme_and_artwork]: 'มีม / งานศิลปะ',
  [ProfileKeys.Edit.Package.Options.interview_and_ama]: 'สัมภาษณ์ / AMA',

  // Title
  [ProfileKeys.Edit.Package.Title.use_a_template]: 'ใช้เทมเพลต',
  [ProfileKeys.Edit.Package.Title.placeholder]: 'พิมพ์ชื่อแพ็กเกจ',
  [ProfileKeys.Edit.Package.Title.placeholder2]: 'กรุณาพิมพ์ชื่อแพ็กเกจ',

  // Featured Image
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.title]: 'ไฮไลท์ภาพขนาดย่อ',
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip]: `
    แนะนำให้อัปโหลดไฮไลท์ภาพขนาดย่อ <br>
    YouTube ที่เข้ากับช่องคุณ`,

  [ProfileKeys.Edit.Package.Featured_Image.title]: 'รูปภาพตัวอย่าง',
  [ProfileKeys.Edit.Package.Featured_Image.placeholder]:
    'โปรดอัปโหลดไฟล์ JPG, JPEG, PNG เท่านั้น แนะนำสัดส่วน 16:9 ขนาดไม่เกิน 200 MB',
  [ProfileKeys.Edit.Package.Featured_Image.tooltip]: `
    รูปภาพนี้จะถูกใช้เป็นไฮไลท์ภาพขนาดย่อของแพ็กเกจ`,

  [ProfileKeys.Edit.Package.Featured_Image.Error.file_upload]: `
    ไฟล์ยังอยู่ในระหว่างการอัปโหลด <br>
    โปรดลองอีกครั้ง`,
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_large]:
    'ขนาดไฟล์ใหญ่เกินไป (สูงสุด ',
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid]:
    'รูปแบบไฟล์ไม่ถูกต้อง (โปรดใช้ JPG, JPEG, PNG)',

  // Sample Contents
  // Sample Contents
  [ProfileKeys.Edit.Package.Sample_Contents.title]: 'เนื้อหาตัวอย่าง',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube]:
    'คัดลอกและวางลิงก์วิดีโอ',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter]:
    'คัดลอกและวางลิงก์ X',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram]:
    'คัดลอกและวางลิงก์โพสต์',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok]:
    'คัดลอกและวางลิงก์ TikTok',

  [ProfileKeys.Edit.Package.Sample_Contents.add_more_contents]:
    'ใส่เนื้อหาเพิ่มเติม',
  [ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link]:
    'ลิงก์ไม่ถูกต้อง',

  // Description
  [ProfileKeys.Edit.Package.Description.placeholder]: `
    โปรดอธิบายรายละเอียดของบริการของคุณที่นี่ <br>
    ลูกค้าอาจค้นหาข้อมูลเกี่ยวกับ <br><br>
    <ul>
      <li>เนื้อหาหลักหรือบริการ</li>
      <li>กลุ่มผู้ชมหลัก</li>
      <li>ตารางเวลา</li>
    </ul>`,
  [ProfileKeys.Edit.Package.Description.placeholder_gpt]:
    'เริ่มพิมพ์ประโยคและ ChatGPT จะแนะนำส่วนที่เหลือ',

  [ProfileKeys.Edit.Package.Description.ChatGPT.title]:
    'สร้างแพ็กเกจที่ไม่ซ้ำใครด้วย ChatGPT',
  [ProfileKeys.Edit.Package.Description.ChatGPT.tooltip]: `
    เลือกการอธิบายที่เข้ากับบริการของคุณที่สุด`,

  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title]:
    'ข้อมูลเพิ่มเติม',
  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder]:
    'กรอกข้อมูลที่ต้องการจะเพิ่มเติมในส่วนของคำอธิบาย',

  // Price
  [ProfileKeys.Edit.Package.Price.placeholder]: 'ใส่ราคา',
  [ProfileKeys.Edit.Package.Price.tooltip]: `
    กำหนดราคาของคุณเพื่อการเข้าถึงลูกค้าได้ง่าย <br>
    ตัวเลือกอื่นๆ จะไม่มีการกำหนดราคาหรือเปิดให้ <br>
    เฉพาะบริษัทและลูกค้าที่ได้รับการรับรองเท่านั้น`,

  [ProfileKeys.Edit.Package.Price.Type.open]: 'ราคาเปิด - ทุกคนมองเห็นราคาได้',
  [ProfileKeys.Edit.Package.Price.Type.unlockable]:
    'ราคาปิด - ลูกค้าที่ได้รับการยืนยันเห็นเท่านั้น',
  [ProfileKeys.Edit.Package.Price.Type.contact_me]:
    'ติดต่อฉัน - ราคาตามที่ต้องการกำหนด',

  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title]:
    'กรุณารับการยืนยันเพื่อการส่งข้อความถึงครีเอเตอร์',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description]: `
    คุณต้องทำตามขั้นตอนการตรวจสอบให้เสร็จสิ้นใช้เวลาเพียง1-2นาที เพื่อการส่งข้อความถึงครีเอเตอร์อื่นๆ กรุณาลองใหม่อีกครั้งหลังจากที่ได้รับการตรวจสอบเเล้ว <br><br> 
    วันต่ออายุครั้งถัดไปของคุณ: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title]:
    'กรุณาอัปเกรดแผนสำหรับส่งข้อความเพิ่มเติม',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description]: `
    คุณต้องการที่จะอัปเกรดแผนเพื่อการส่งข้อความถึงครีเอเตอร์ <br> 
    กรุณาตรวจสอบแผนของคุณแล้วลองอีกครั้ง <br><br> 
    วันต่ออายุครั้งถัดไปของคุณ: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title]:
    'กรุณารับการยืนยันเพื่อปลดล็อกราคาเพิ่มเติม',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description]: `
    คุณต้องทำตามขั้นตอนการตรวจสอบให้เสร็จสิ้นใช้เวลาเพียง1-2นาที เพื่อการปลดล็อกราคาเพิ่มเติม กรุณาลองใหม่อีกครั้งหลังจากที่ได้รับการตรวจสอบเเล้ว <br><br> 
    วันต่ออายุครั้งถัดไปของคุณ: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title]:
    'โปรดอัปเกรดแผนของคุณเพื่อปลดล็อกราคาเพิ่มเติม',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
    .description]: `
    วันต่ออายุถัดไปของคุณ: {{date}} <br>
    โปรดอัปเกรดแผนของคุณเพื่อปลดล็อกราคานี้`,
  [ProfileKeys.Edit.Package.Price.Modal.btn]: 'อัปเกรดเดี๋ยวนี้',

  [ProfileKeys.Edit.Package.Price.toast]: `
    ปลดล็อคสำเร็จแล้ว (เหลือ {{number}})`,

  [ProfileKeys.Edit.Package.Price.Error.minimum]: 'ราคาต่ำสุดคือ {{number}}',
  [ProfileKeys.Edit.Package.Price.Error.maximum]: 'ราคาสูงสุดคือ {{number}}',

  // Contents
  [ProfileKeys.Edit.Package.Contents.YouTube.video]: 'วิดีโอ YouTube',
  [ProfileKeys.Edit.Package.Contents.YouTube.shorts]: 'YouTube Shorts',
  [ProfileKeys.Edit.Package.Contents.YouTube.live]: 'YouTube Live',

  [ProfileKeys.Edit.Package.Contents.Twitter.post]: 'โพสต์ X',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_post]:
    'โพสต์ Instagram',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_story]:
    'สตอรี Instagram',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels]:
    'รีลส์ Instagram',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_live]:
    'Instagram Live',

  [ProfileKeys.Edit.Package.Contents.Tiktok.video]: 'วิดีโอ TikTok',
  [ProfileKeys.Edit.Package.Contents.Tiktok.live]: 'TikTok Live',

  // Delivery
  [ProfileKeys.Edit.Package.Delivery.placeholder]:
    'ใส่เวลาการจัดส่ง (7-60 วัน)',
  [ProfileKeys.Edit.Package.Delivery.input]: ' วัน',
  [ProfileKeys.Edit.Package.Delivery.tooltip]: `
    เลือกระยะเวลาที่คุณต้องการสำหรับการจัดส่งที่เสร็จสมบูรณ์`,

  // Revisions
  [ProfileKeys.Edit.Package.Revisions.no_revisions]: 'ไม่สามารถแก้ไขได้',
  [ProfileKeys.Edit.Package.Revisions.tooltip]: `
    จำนวนการแก้ไขที่คุณจะให้ในคำสั่งซื้อ`,

  // Type/Length
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    'ตัวอย่าง: วิดีโอรีวิวสินค้า 5-10 นาที',

  // User Info
  // Username
  [ProfileKeys.Edit.User_Info.Username.placeholder]: 'ลงชื่อผู้ใช้',
  [ProfileKeys.Edit.User_Info.Username.Error.enter_name]: 'กรุณาลงชื่อผู้ใช้',
  [ProfileKeys.Edit.User_Info.Username.Error.max_50]:
    'ความยาวไม่เกิน 50 ตัวอักษร',
  [ProfileKeys.Edit.User_Info.Username.Error.max_30]: 'พิมพ์ 3-30 ตัวอักษร',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol]:
    'ข้อมูลไม่ถูกต้องกรุณาตรวจสอบตัวอักษรและช่องว่างอีกครั้ง',
  [ProfileKeys.Edit.User_Info.Username.Error.max_2000]:
    'ความยาวไม่เกิน 2000 ตัวอักษร',
  [ProfileKeys.Edit.User_Info.Username.Error.max_100]:
    'ความยาวไม่เกิน 100 ตัวอักษร',
  [ProfileKeys.Edit.User_Info.Username.Error.max_500]:
    'ความยาวไม่เกิน 500 ตัวอักษร',
  [ProfileKeys.Edit.User_Info.Username.Error.max_200]:
    'ความยาวไม่เกิน 200 ตัวอักษร',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_price]: 'ราคาไม่ถูกต้อง',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time]:
    'เวลาการส่งไม่ถูกต้อง',
  [ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60]:
    'ระยะเวลาในการส่งต้องอยู่ระหว่าง 1 - 60 วัน',

  // My URL
  [ProfileKeys.Edit.User_Info.My_URL.placeholder]: 'ลง URL',

  [ProfileKeys.Edit.User_Info.My_URL.Error.char_limit]: 'พิมพ์ 3-30 ตัวอักษร',
  [ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol]:
    'ข้อมูลไม่ถูกต้องกรุณาตรวจสอบตัวอักษรและช่องว่างอีกครั้ง',
  [ProfileKeys.Edit.User_Info.My_URL.Error.required_handle]: 'ลง URL',
  [ProfileKeys.Edit.User_Info.My_URL.Error.already_taken]: 'URL นี้ถูกใช้แล้ว',

  // Social Network
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.priority]:
    'เปลี่ยนลำดับไม่สำเร็จ',
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink]:
    'ยกเลิกการลิงก์แพลตฟอร์มไม่สำเร็จ',

  [ProfileKeys.Edit.User_Info.Social_Network.Popup.title]:
    'โปรดทำการสั่งซื้อให้เสร็จก่อน',
  [ProfileKeys.Edit.User_Info.Social_Network.Popup.description]:
    'คุณสามารถยกเลิกการลิงก์บัญชี SNS ที่ได้รับการรับรอง หลังจากยกเลิกหรือเสร็จการสั่งซื้อที่กำลังดำเนินการ',

  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.title]:
    'คุณแน่ใจหรือไม่ว่าจะต้องการยกเลิกการลิงก์บัญชีนี้?',
  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.description]:
    'เมื่อคุณยกเลิกการลิงก์บัญชี SNS ที่ได้รับการรับรองแล้ว ข้อมูลที่เกี่ยวข้องกับบัญชีนั้นอาจหายไป และข้อมูลแพ็กเกจที่เกี่ยวข้องจะไม่ปรากฏอยู่ในโปรไฟล์และการค้นหาของเราอีกต่อไป',

  // Bio
  [ProfileKeys.Edit.User_Info.Bio.placeholder_creator]:
    'แชร์เรื่องราวของคุณกับแบรนด์และลูกค้าระดับโลก',
  [ProfileKeys.Edit.User_Info.Bio.placeholder_client]:
    'แชร์เรื่องราวของคุณกับครีเอเตอร์ระดับโลก',

  // Categories
  [ProfileKeys.Edit.User_Info.Categories.add_a_category]: 'เพิ่มหมวดหมู่',

  // Target Countries
  [ProfileKeys.Edit.User_Info.Target_Countries.title]: 'ประเทศ',
  [ProfileKeys.Edit.User_Info.Target_Countries.add_a_country]: 'เพิ่มประเทศ',
  [ProfileKeys.Edit.User_Info.Target_Countries.tooltip]: `
    เลือก 1-2 ประเทศที่คุณอาศัยอยู่หรือ <br>
    เป็นตัวแทนของคุณมากที่สุด`,

  // Languages
  [ProfileKeys.Edit.User_Info.Languages.add_a_language]: 'เพิ่มภาษา',
  [ProfileKeys.Edit.User_Info.Website.placeholder]: 'คัดลอกและวาง URL',

  // Company
  [ProfileKeys.Edit.User_Info.Company.placeholder]: 'ลงชื่อบริษัท',
  [ProfileKeys.Edit.User_Info.Company.tooltip]: `
    อัปโหลดโลโก้บริษัท <br>
    ในรูปแบบ PNG, JPG, JPEG <br>
    ขนาดที่แนะนำ 360x360 <br>
    ขนาดไม่เกิน 50 MB`,
  [ProfileKeys.Edit.User_Info.Company.Error.max_30]: 'ไม่เกิน 30 ตัวอักษร',

  // Modal
  [ProfileKeys.Modal.Request_Price.title]: 'ขอราคา',
  [ProfileKeys.Modal.Request_Price.btn]: 'ส่งคำขอ',
  [ProfileKeys.Modal.Request_Price.toast]: 'คำขอคุณถูกส่งสำเร็จแล้ว',
  [ProfileKeys.Modal.Request_Price.Message.title]: 'ส่งข้อความ',
  [ProfileKeys.Modal.Request_Price.Message.placeholder]:
    'คำถามหรือแชร์รายละเอียดโปรเจกต์(งบประมาณ, ข้อสรุปผลิตภัณฑ์, ข้อกำหนด, ฯลฯ)',

  [ProfileKeys.Modal.Edit.Banner.heading]: 'เลือกรูปภาพ',
  [ProfileKeys.Modal.Edit.Banner.body]: 'อัปโหลดรูปภาพ',
  [ProfileKeys.Modal.Edit.Banner.loading]: 'กำลังโหลดจาก',
  [ProfileKeys.Modal.Edit.Banner.use_my]: 'ใช้{{type}} {{platform}} ของฉัน',
  [ProfileKeys.Modal.Edit.Banner.not_found]: 'ไม่พบ{{type}} {{platform}}',

  // Modal Incomplete Package
  [ProfileKeys.Modal.Edit.Incomplete_Package.title]: 'แพ็กเกจยังไม่สมบูรณ์',
  [ProfileKeys.Modal.Edit.Incomplete_Package.description]: `
    แพ็กเกจต่อไปนี้ยังไม่สมบูรณ์: <br><br>
    <package></package> <br>
    เมื่อคุณทำแพ็กเกจเสร็จแล้ว จะปรากฏในโปรไฟล์และการค้นหา <br>
    คุณต้องการดำเนินการให้เสร็จสิ้นตอนนี้หรือไม่?`,

  // Modal New Reward
  [ProfileKeys.Modal.Edit.new_reward]: 'คุณปลดล็อกรางวัลใหม่',
  [ProfileKeys.Modal.Edit.skip_for_now]: 'ข้าม',

  [ProfileKeys.Modal.Reconnection.title]: 'ต้องการเชื่อมต่อใหม่',
  [ProfileKeys.Modal.Reconnection.description]: `
    เนื่องจากไม่มีกิจกรรมเป็นเวลานานหรือมีการอัปเดตนโยบายและฟังก์ชั่นในแต่ละแพลตฟอร์มโซเชียลมีเดีย จำเป็นต้องเชื่อมต่อใหม่สำหรับแพลตฟอร์มที่ระบุด้านล่าง โปรดเชื่อมต่อหรือยกเลิกการเชื่อมต่อของแพลตฟอร์มที่เกี่ยวข้องเพื่อดำเนินการต่อ <br><br>
    MegaEvolution รองรับการเชื่อมต่อที่ปลอดภัยผ่าน API ที่ได้รับการยืนยันโดยอย่างเป็นทางการและพาร์ทเนอร์จาก Google, Meta (Instagram) และ TikTok`,
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title]:
    'คุณสามารถเชื่อมต่อบัญชีใหม่หลังจากยกเลิกการเชื่อมต่อบัญชีปัจจุบันของคุณ',
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description]:
    'ตรวจพบบัญชีที่แตกต่างจากบัญชีที่เชื่อมต่ออยู่ในปัจจุบัน หากต้องการเชื่อมต่อบัญชีใหม่ โปรดยกเลิกการเชื่อมต่อบัญชีที่มีอยู่แล้วและลองอีกครั้ง',

  // Guide
  [ProfileKeys.Guide.hi_ginseng_mega]: 'สวัสดี, ฉันชื่อ MEGA',
  [ProfileKeys.Guide.nice_to_meet_you]: 'ยินดีที่ได้พบคุณ',
  [ProfileKeys.Guide.create_unique_url]: 'สร้าง URL ที่ไม่ซ้ำ',
  [ProfileKeys.Guide.we_help_you_receive]: 'เราช่วยคุณรับ',
  [ProfileKeys.Guide.we_help_you_receive_mobile]: 'เราช่วยคุณรับข้อเสนอ',
  [ProfileKeys.Guide.proposal_golbal_brands]: 'ข้อเสนอจากแบรนด์ระดับโลก',
  [ProfileKeys.Guide.proposal_golbal_brands_mobile]: 'จากแบรนด์ระดับโลก',
  [ProfileKeys.Guide.create_unique_portfolio]: 'สร้างพอร์ตโฟลิโอที่ไม่ซ้ำ',
  [ProfileKeys.Guide.just_few_clicks]: 'ในไม่กี่คลิก',
  [ProfileKeys.Guide.click_to_join]: 'คลิกเพื่อเข้าร่วม',
  [ProfileKeys.Guide.click_to_join_mobile]: 'คลิกที่นี่',
  [ProfileKeys.Guide.anytime]: 'ได้ตลอดเวลา',
  [ProfileKeys.Guide.anytime_mobile]: 'เพื่อเข้าร่วมได้ตลอดเวลา',
  [ProfileKeys.Guide.what_a_pleasure]: 'ยินดีที่ได้พบคุณวันนี้',
  [ProfileKeys.Guide.hope_to_see]: 'หวังว่าจะได้เห็นหน้าเพจของคุณเร็ว ๆ นี้',
  [ProfileKeys.create_unique_handle]: 'สร้าง URL ของคุณด้วย @handle ที่ไม่ซ้ำ',

  // Guide Profile
  [ProfileKeys.Guide.Profile.welcome]: 'ยินดีต้อนรับ, ฉันคือ MEGA',
  [ProfileKeys.Guide.Profile.portfolio_space]: 'นี่คือพื้นที่พอร์ตโฟลิโอ',
  [ProfileKeys.Guide.Profile.specially_created]: 'สร้างขึ้นเป็นพิเศษสำหรับคุณ',
  [ProfileKeys.Guide.Profile.click_here_to_start_editing]:
    'คลิกที่นี่เพื่อเริ่มแก้ไข',
  [ProfileKeys.Guide.Profile.package_will_show_up]:
    'แพ็กเกจของคุณจะปรากฏที่นี่',
  [ProfileKeys.Guide.Profile.click_here]: 'คลิกที่นี่',
  [ProfileKeys.Guide.Profile.to_start_editing]: 'เพื่อเริ่มแก้ไข',
  [ProfileKeys.Guide.Profile.package_will_be_displated]:
    'แพ็กเกจของคุณจะแสดงที่นี่',

  // Profile Edit
  [ProfileKeys.Guide.Profile_Edit.welcome]: 'ยินดีต้อนรับเข้าสู่โปรไฟล์ของคุณ',
  [ProfileKeys.Guide.Profile_Edit.try_me]: 'ลองใช้ฉันเพื่อตั้งค่าโปรไฟล์',
  [ProfileKeys.Guide.Profile_Edit.compelling_package]: 'แพ็กเกจที่น่าสนใจ',
  [ProfileKeys.Guide.Profile_Edit.upload_image]: 'อัปโหลดรูปภาพเพื่อ',
  [ProfileKeys.Guide.Profile_Edit.showcase_service]: 'แสดงบริการของคุณ',
  [ProfileKeys.Guide.Profile_Edit.add_post_video]: 'เพิ่มลิงก์โพสต์/วิดีโอ',
  [ProfileKeys.Guide.Profile_Edit.for_your_clients]: 'สำหรับลูกค้าของคุณ',
  [ProfileKeys.Guide.Profile_Edit.set_up_price]: 'กำหนดราคาในโหมดต่างๆ',
  [ProfileKeys.Guide.Profile_Edit.choose_best_option]:
    'เลือกตัวเลือกที่ดีที่สุดสำหรับแพ็กเกจของคุณ',
  [ProfileKeys.Guide.Profile_Edit.complete_package]: 'เสร็จสิ้นแพ็กเกจของคุณ',
  [ProfileKeys.Guide.Profile_Edit.make_it_public]: 'เพื่อทำให้เป็นสาธารณะ',
  [ProfileKeys.Guide.Profile_Edit.save_description1]:
    'อย่าลืมบันทึกก่อนที่คุณจะออก',
  [ProfileKeys.Guide.Profile_Edit.save_description2]: '',
  [ProfileKeys.Guide.Profile_Edit.save_description3]: '',
  [ProfileKeys.Guide.Profile_Edit.create_unique_package]:
    'สร้างโปรไฟล์เจ๋งๆ สำหรับบริษัททั่วโลก',
  [ProfileKeys.Guide.Profile_Edit.quick_setup]: 'การตั้งค่าแบบด่วน',

  [ProfileKeys.Guide.Profile_Edit.Toast.register_success]:
    'ลงทะเบียนเรียบร้อยแล้ว กรุณาตั้งโปรไฟล์เพื่อรับคำสั่งซื้อ',
};
