export const UserInfo = {
  Name: 'name',
  Handle: 'handle',
  CompanyLogo: 'companyLogo',
  Company: 'company',
  Country: 'country',
  Bio: 'bio',
  Categories: 'categories',
  Countries: 'countries',
  Languages: 'languages',
  BannerShade: 'bannerShade',
  Links: 'links',
  Website: 'website',
};

export const Package = {
  Title: 'title',
  SampleContents: 'sampleContents',
  Description: 'description',
  Price: 'price',
  PriceType: 'priceType',
  Contents: 'contents',
  Delivery: 'delivery',
  Revisions: 'revisions',
  TypeLength: 'typeLength',
  FeaturedContent: 'featuredContent',
  MinFeaturedContent: 'minFeaturedContent',
  IsRepresentative: 'isRep',
};

const DataTypes = {
  UserInfo,
  Package,
};

export default DataTypes;
