import styled from 'styled-components';
import device from 'styles/device';
import { Position } from './constants';

const ToastContainer = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.625rem;

  @media ${device.sm}{
  ${({ position }) =>
    ({
      [Position.TOP_LEFT]: {
        top: '1rem',
        left: '1rem',
        alignItems: 'flex-start',
      },
      [Position.TOP_CENTER]: {
        top: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        alignItems: 'center',
      },
      [Position.TOP_RIGHT]: {
        top: '1rem',
        right: '1rem',
        alignItems: 'flex-end',
      },
      [Position.BOTTOM_LEFT]: {
        bottom: '1rem',
        left: '1rem',
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
      },
      [Position.BOTTOM_CENTER]: {
        bottom: '1rem',
        left: '50%',
        transform: 'translateX(-50%)',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
      [Position.BOTTOM_RIGHT]: {
        bottom: '1rem',
        right: '1rem',
        flexDirection: 'column-reverse',
        alignItems: 'flex-end',
      },
    }[position])}
  }



  ${({ position }) =>
  ({
    [Position.TOP_LEFT]: {
      top: '3.125rem',
      left: '3.125rem',
      alignItems: 'flex-start',
    },
    [Position.TOP_CENTER]: {
      top: '3.125rem',
      left: '50%',
      transform: 'translateX(-50%)',
      alignItems: 'center',
    },
    [Position.TOP_RIGHT]: {
      top: '3.125rem',
      right: '3.125rem',
      alignItems: 'flex-end',
    },
    [Position.BOTTOM_LEFT]: {
      bottom: '3.125rem',
      left: '3.125rem',
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
    [Position.BOTTOM_CENTER]: {
      bottom: '3.125rem',
      left: '50%',
      transform: 'translateX(-50%)',
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
    [Position.BOTTOM_RIGHT]: {
      bottom: '3.125rem',
      right: '3.125rem',
      flexDirection: 'column-reverse',
      alignItems: 'flex-end',
    },
  }[position])}
`;

export default ToastContainer;
