export const TwitterGrayIcon = ({ size = 1 }) => (
  <svg
    width={size * 21 * 2}
    height={size * 16 * 2}
    viewBox="0 0 21 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_385_148)">
      <path d="M20.8772 1.94839C20.1326 2.2735 19.3429 2.4869 18.5342 2.5815C19.3766 2.08389 20.0233 1.29591 20.3278 0.357036C19.5271 0.825389 18.6511 1.15537 17.7375 1.33273C16.9934 0.551333 15.9333 0.0629883 14.76 0.0629883C12.5071 0.0629883 10.6806 1.86326 10.6806 4.08366C10.6806 4.39884 10.7167 4.70567 10.7862 5.00002C7.39601 4.83229 4.39026 3.2316 2.37826 0.798988C2.0272 1.39283 1.82604 2.08358 1.82604 2.82035C1.82604 4.21533 2.54626 5.44596 3.64076 6.16703C2.99297 6.14703 2.35943 5.97458 1.79304 5.66406C1.7928 5.6809 1.7928 5.69775 1.7928 5.71466C1.7928 7.66276 3.19891 9.28787 5.06496 9.65725C4.46426 9.81827 3.83418 9.84184 3.22283 9.72615C3.74188 11.3235 5.24841 12.486 7.03338 12.5185C5.6373 13.5968 3.87835 14.2397 1.96724 14.2397C1.63793 14.2397 1.31328 14.2206 0.994141 14.1835C2.79938 15.3243 4.94357 15.99 7.2472 15.99C14.7505 15.99 18.8535 9.86318 18.8535 4.54996C18.8535 4.37556 18.8496 4.20217 18.8417 4.02976C19.6403 3.46072 20.3296 2.7559 20.8772 1.94839Z" />
    </g>
    <defs>
      <clipPath id="clip0_385_148">
        <rect
          width="19.883"
          height="16"
          fill="white"
          transform="translate(0.994141)"
        />
      </clipPath>
    </defs>
  </svg>
);
