import EvoMissionsKeys from '../translationKeys/EvoMissionsPage';

export const EvoMissionsTranslations = {
  [EvoMissionsKeys.claim_evos]: 'EVOを獲得する',
  [EvoMissionsKeys.my_evos]: '私のEVO',
  [EvoMissionsKeys.special_missions]: 'スペシャルミッション',
  [EvoMissionsKeys.creator_missions]: 'クリエイターミッション',
  [EvoMissionsKeys.general_missions]: 'ジェネラルミッション',
  [EvoMissionsKeys.successfully_claimed]: '獲得に成功しました！',
  [EvoMissionsKeys.go_back_home]: 'プロフィールに戻る',

  // Token Details
  [EvoMissionsKeys.Token_Details._1k]: '1,000',
  [EvoMissionsKeys.Token_Details._10k]: '10,000',
  [EvoMissionsKeys.Token_Details.no_data]: '表示するデータはありません',
  [EvoMissionsKeys.Token_Details.results_found]: '合計 {{number}} 件の結果',
  [EvoMissionsKeys.Token_Details.result_found]: '合計 {{number}} 件の結果',

  // Side Popup
  [EvoMissionsKeys.Side_Popup.text]: 'EVOとは何ですか？',

  // Transaction
  [EvoMissionsKeys.Transaction.daily_reward]: 'デイリーリワード',
  [EvoMissionsKeys.Transaction.price_unlock_received]:
    'クライアントからの送信 - 価格解除',
  [EvoMissionsKeys.Transaction.price_unlock_spent]: '価格解除',
  [EvoMissionsKeys.Transaction.referral_reward]: '紹介報酬',
  [EvoMissionsKeys.Transaction.creator_registration_reward]:
    'クリエイター登録報酬',

  [EvoMissionsKeys.Transaction.complete_1_special_completed]:
    '特別ミッション完了',
  [EvoMissionsKeys.Transaction.complete_777_special_completed]:
    '特別ミッション完了',
  [EvoMissionsKeys.Transaction.complete_77_special_completed]:
    '特別ミッション完了',
  [EvoMissionsKeys.Transaction.complete_7_special_completed]:
    '特別ミッション完了',
  [EvoMissionsKeys.Transaction.create_premium_package_completed]:
    'プレミアムパッケージの作成',
  [EvoMissionsKeys.Transaction.first_990_creator_claimed_completed]: '早期VIP',
  [EvoMissionsKeys.Transaction.refer_1000_early_vips_completed]:
    'アーリーVIP紹介',
  [EvoMissionsKeys.Transaction.refer_100_early_vips_completed]:
    'アーリーVIP紹介',
  [EvoMissionsKeys.Transaction.refer_10_early_vips_completed]:
    'アーリーVIP紹介',
  [EvoMissionsKeys.Transaction.referral_ranking_completed]: 'VIP紹介ランキング',
  [EvoMissionsKeys.Transaction.special_daily_reward_completed]:
    'デイリーリワード',
  [EvoMissionsKeys.Transaction.tv_easter_egg_completed]:
    'プラットフォームでイースターエッグを見つける',
  [EvoMissionsKeys.Transaction.update_creator_profile_completed]:
    'クリエイタープロフィールの更新',
  [EvoMissionsKeys.Transaction.update_profile_completed]: 'プロフィールの更新',
  [EvoMissionsKeys.Transaction.visit_main_tabs_completed]:
    'すべてのメインタブを訪問',

  // Special Mission
  // Early 990 VIPs
  [EvoMissionsKeys.Special.early_990_vips.title]: '早期VIP',
  [EvoMissionsKeys.Special.early_990_vips.description]:
    'あなたは私たちの早期VIPインフルエンサーです！今すぐクリエイターとして登録して、プレミアムパスをアンロックしてください。お待ちしています！',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d1]:
    'このプレミアムパスでプラットフォームサービス料なしで',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d2]: '注文を受ける！',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d3]: '',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    ' *PayPal やクレジットカードなどの第三者手数料が発生する場合があります。',

  // General
  [EvoMissionsKeys.complete_1_special.title]: 'スペシャルミッションを完了する',
  [EvoMissionsKeys.complete_1_special.description]:
    'スペシャルミッションを完了して少しのボーナスをゲット！',
  [EvoMissionsKeys.complete_1_special.extra_reward]: '',
  [EvoMissionsKeys.complete_1_special.reward_description]: '',

  [EvoMissionsKeys.complete_777_special.title]:
    'スペシャルミッションを完了する',
  [EvoMissionsKeys.complete_777_special.description]:
    'スペシャルミッションを完了して少しのボーナスをゲット！',
  [EvoMissionsKeys.complete_777_special.extra_reward]: '',
  [EvoMissionsKeys.complete_777_special.reward_description]: '',

  [EvoMissionsKeys.complete_77_special.title]: 'スペシャルミッションを完了する',
  [EvoMissionsKeys.complete_77_special.description]:
    'スペシャルミッションを完了して少しのボーナスをゲット！',
  [EvoMissionsKeys.complete_77_special.extra_reward]: '',
  [EvoMissionsKeys.complete_77_special.reward_description]: '',

  [EvoMissionsKeys.complete_7_special.title]: 'スペシャルミッションを完了する',
  [EvoMissionsKeys.complete_7_special.description]:
    'スペシャルミッションを完了して少しのボーナスをゲット！',
  [EvoMissionsKeys.complete_7_special.extra_reward]: '',
  [EvoMissionsKeys.complete_7_special.reward_description]: '',

  [EvoMissionsKeys.tv_easter_egg.title]:
    'プラットフォームでイースターエッグを見つける！',
  [EvoMissionsKeys.tv_easter_egg.description]:
    'ホームページでイースターエッグを見つけよう。どこかにあります。',
  [EvoMissionsKeys.tv_easter_egg.extra_reward]: '',
  [EvoMissionsKeys.tv_easter_egg.reward_description]: '',

  [EvoMissionsKeys.update_profile.title]: 'プロフィールを更新する',
  [EvoMissionsKeys.update_profile.description]:
    '私たちと一緒にこの旅を始めましょう！プロフィールを更新して報酬をアンロックしましょう。',
  [EvoMissionsKeys.update_profile.extra_reward]: '',
  [EvoMissionsKeys.update_profile.reward_description]: '',

  [EvoMissionsKeys.visit_main_tabs.title]: 'すべてのメインタブを訪れる',
  [EvoMissionsKeys.visit_main_tabs.description]:
    '上部のナビゲーションバーにある4つのタブをすべて訪れて報酬をアンロックしましょう。私たちと一緒に新しいことを発見しましょう！',
  [EvoMissionsKeys.visit_main_tabs.extra_reward]: '',
  [EvoMissionsKeys.visit_main_tabs.reward_description]: '',

  // Creator
  [EvoMissionsKeys.create_premium_package.title]:
    'プレミアムパッケージを作成する',
  [EvoMissionsKeys.create_premium_package.description]:
    'プロフィールにプレミアムパッケージを作成してください。テンプレートまたはChatGPTの機能を使用して、あなたの最高のサービスを紹介しましょう！',
  [EvoMissionsKeys.create_premium_package.extra_reward]: '',
  [EvoMissionsKeys.create_premium_package.reward_description]: '',

  [EvoMissionsKeys.update_creator_profile.title]:
    'クリエイタープロフィールを更新する',
  [EvoMissionsKeys.update_creator_profile.description]:
    '世界的なスーパースターになる準備はできていますか？プロフィールを更新して、あなたの素晴らしいチャンネルについて世界に知らせましょう！',
  [EvoMissionsKeys.update_creator_profile.extra_reward]: '',
  [EvoMissionsKeys.update_creator_profile.reward_description]: '',

  // Special
  [EvoMissionsKeys.first_990_creator_claimed.title]:
    'VIP 990 初期クリエーター！',
  [EvoMissionsKeys.first_990_creator_claimed.description]:
    '初期クリエーターとしてようこそ！プロフィールを完成させて世界のブランドと協力しましょう！',
  [EvoMissionsKeys.first_990_creator_claimed.extra_reward]:
    'プレミアムクリエーターパス',
  [EvoMissionsKeys.first_990_creator_claimed.reward_description]:
    'プレミアムパスを持つクリエイターは',

  [EvoMissionsKeys.refer_1000_early_vips.title]: '早期VIPリファラル',
  [EvoMissionsKeys.refer_1000_early_vips.description]:
    '1000人以上を紹介してこの報酬を受け取ろう！',
  [EvoMissionsKeys.refer_1000_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_1000_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_100_early_vips.title]: '早期VIPリファラル',
  [EvoMissionsKeys.refer_100_early_vips.description]:
    '100人以上を紹介してこの報酬を受け取ろう！',
  [EvoMissionsKeys.refer_100_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_100_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_10_early_vips.title]: '早期VIPリファラル',
  [EvoMissionsKeys.refer_10_early_vips.description]:
    '10人以上を紹介してこの報酬を受け取ろう！',
  [EvoMissionsKeys.refer_10_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_10_early_vips.reward_description]: '',

  [EvoMissionsKeys.referral_ranking.title]: 'VIPリファラルランキング',
  [EvoMissionsKeys.referral_ranking.description]:
    'リファラルランキングの各パーセンタイルに対して、月末に追加のEVOポイントが授与されます。',
  [EvoMissionsKeys.referral_ranking.extra_reward]: '',
  [EvoMissionsKeys.referral_ranking.reward_description]: '',

  [EvoMissionsKeys.special_daily_reward.title]: 'スペシャルデイリーリワード',
  [EvoMissionsKeys.special_daily_reward.description]:
    '1週間毎日デイリーリワードを受け取りに来てください。',
  [EvoMissionsKeys.special_daily_reward.extra_reward]: '',
  [EvoMissionsKeys.special_daily_reward.reward_description]: '',

  [EvoMissionsKeys.Modal.already_unlock]: 'すでにアンロック済みです',
  [EvoMissionsKeys.Modal.easter_egg]: 'このイースターエッグ！',
  [EvoMissionsKeys.Modal.unlock_premium_pass]:
    'プレミアムパスをアンロックしました。',
  [EvoMissionsKeys.Modal.visit_profile]: 'マイプロフィールを見る',
  [EvoMissionsKeys.Modal.not_now]: 'スキップ',
};
