export const RefreshSolidIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 18}
    height={size * 19}
    viewBox="0 0 18 19"
    fill="currentColor"
  >
    <circle cx="9" cy="9.5" r="9" />
    <path
      d="M13.6286 8.98599C13.32 8.98599 13.1143 9.19171 13.1143 9.50028C13.1143 10.9917 12.3429 12.3288 11.0572 13.0489C9.10289 14.1803 6.58289 13.5117 5.45146 11.5574C4.32003 9.60314 4.98861 7.08314 6.94289 5.95171C8.64003 4.97456 10.6972 5.33456 11.9829 6.67171H10.7486C10.44 6.67171 10.2343 6.87742 10.2343 7.18599C10.2343 7.49456 10.44 7.70028 10.7486 7.70028H13.0629C13.3715 7.70028 13.5772 7.49456 13.5772 7.18599V4.87171C13.5772 4.56314 13.3715 4.35742 13.0629 4.35742C12.7543 4.35742 12.5486 4.56314 12.5486 4.87171V5.79742C11.5715 4.87171 10.3372 4.35742 9.00003 4.35742C6.17146 4.35742 3.85718 6.67171 3.85718 9.50028C3.85718 12.3288 6.17146 14.6431 9.00003 14.6431C11.8286 14.6431 14.1429 12.3288 14.1429 9.50028C14.1429 9.19171 13.9372 8.98599 13.6286 8.98599Z"
      fill="white"
    />
  </svg>
);
