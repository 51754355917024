const Position = {
  TOP_LEFT: 'top-left',
  TOP_CENTER: 'top-center',
  TOP_RIGHT: 'top-right',
  BOTTOM_LEFT: 'bottom-left',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_RIGHT: 'bottom-right',
};

const Type = {
  CUSTOM: 'custom',
  PLAIN: 'plain',
  SUCCESS: 'success',
  ERROR: 'error',
  LOADING: 'loading',
};

export { Position, Type };
