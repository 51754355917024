export const BlogShadowIcon = ({ size = 1 }) => (
  <svg
    width={24 * size}
    height={31 * size}
    viewBox="0 0 24 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_29647_35613)">
      <path
        d="M20.9897 8.23862C20.9897 7.28803 20.8087 6.34675 20.4571 5.46852C20.1056 4.59029 19.5902 3.79231 18.9406 3.12014C18.291 2.44797 17.5197 1.91478 16.6709 1.55101C15.8221 1.18723 14.9124 1 13.9937 1H4.99885C4.46872 1 3.96031 1.2179 3.58545 1.60576C3.21059 1.99361 3 2.51966 3 3.06818V23.7499C3 24.2985 3.21059 24.8245 3.58545 25.2124C3.96031 25.6002 4.46872 25.8181 4.99885 25.8181H13.9937C15.3862 25.8244 16.7487 25.3993 17.9058 24.5975C19.0628 23.7957 19.9615 22.654 20.4861 21.3193C21.0107 19.9846 21.1372 18.518 20.8493 17.1082C20.5615 15.6985 19.8724 14.4102 18.8709 13.4091C19.5391 12.7367 20.0708 11.933 20.4347 11.0451C20.7985 10.1572 20.9872 9.20303 20.9897 8.23862ZM13.9937 21.6818H6.99771V15.4772H13.9937C14.7889 15.4772 15.5515 15.8041 16.1138 16.3859C16.6761 16.9677 16.992 17.7567 16.992 18.5795C16.992 19.4023 16.6761 20.1913 16.1138 20.7731C15.5515 21.3549 14.7889 21.6818 13.9937 21.6818ZM13.9937 11.3409H6.99771V5.13635H13.9937C14.7889 5.13635 15.5515 5.4632 16.1138 6.04498C16.6761 6.62677 16.992 7.41584 16.992 8.23862C16.992 9.06139 16.6761 9.85046 16.1138 10.4322C15.5515 11.014 14.7889 11.3409 13.9937 11.3409Z"
        fill="#0E0D39"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_29647_35613"
        x="0"
        y="0"
        width="24"
        height="30.8182"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_29647_35613"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_29647_35613"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
