import AxiosClient from 'components/utilities/AxiosClient';

const resendVerificationCode = async (clientEmailVerificationId) => {
  try {
    const res = await AxiosClient.put(
      '/resendVerifyClientCompanyEmail',
      clientEmailVerificationId
    );

    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export default resendVerificationCode;
