import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: '保存中',
  [ProposalKeys.changes_saved]: '保存成功',

  [ProposalKeys.New_Proposal.text]: '新提案',
  [ProposalKeys.New_Proposal.toast]: '您已达到可以创建的提案的最大数量。',

  [ProposalKeys.Clear.text]: '清除所有',
  [ProposalKeys.Clear.toast]: '已成功清除。单击此处以撤消此更改。',

  [ProposalKeys.Modal.Copy.title]: '您确定要覆盖提案吗？',
  [ProposalKeys.Modal.Copy.description]: `
    当前提案将被所选提案覆盖。此操作无法撤消。您要继续吗？`,

  [ProposalKeys.Part1.Option_1.title]: '标题',
  [ProposalKeys.Part1.Option_1.placeholder]: '输入项目标题',
  [ProposalKeys.Part1.Option_1.error]: '最多100个字符',

  [ProposalKeys.Part1.Option_2.title]: '产品 · 品牌网站',
  [ProposalKeys.Part1.Option_2.placeholder]: '输入网址',
  [ProposalKeys.Part1.Option_2.error]: '最多400个字符',

  [ProposalKeys.Part1.Option_3.title]: '描述与文件',
  [ProposalKeys.Part1.Option_3.placeholder]: `
    请在此处描述项目的详细信息并附上您的文件。<br>
    创作者可能会寻找有关以下方面的信息，<br><br><br>
    <ul>
    <li>项目/产品描述</li>
    <li>活动目标和目标受众</li>
    <li>详细的内容指南</li>
    </ul>`,
  [ProposalKeys.Part1.Option_3.Snippet.title]: '选择要添加的片段',
  [ProposalKeys.Part1.Option_3.Snippet.option_1]: '创作者的社交频道名称',
  [ProposalKeys.Part1.Option_3.Snippet.option_2]: '创作者的用户名',
  [ProposalKeys.Part1.Option_3.Snippet.option_3]: '创作者的主要国家',
  [ProposalKeys.Part1.Option_3.Snippet.option_4]: '创作者的顶级类别',

  [ProposalKeys.Part1.Option_3.Error.file_format]:
    '无效的文件格式（请使用 {{fileFormats}} ）',
  [ProposalKeys.Part1.Option_3.Error.file_size_limit]:
    '总文件大小限制最多 {{maxSize}}',
  [ProposalKeys.Part1.Option_3.Error.max_file]:
    '您最多可以发送 {{maxFiles}} 个文件',

  [ProposalKeys.caution]: `
    下订单后，所有适用的<strong>要求和内容任务</strong>将自动应用于每个内容。对于已订购的内容不完整或不适用的要求和内容任务将不会应用。`,

  [ProposalKeys.Side_btn.text]: '适用的内容',
  [ProposalKeys.Side_btn.title]: '适用的内容：',

  [ProposalKeys.Part2.title]: '向创作者发送产品',

  [ProposalKeys.Part2.Option_1.title]: '发送赞助产品',
  [ProposalKeys.Part2.Option_1.tooltip]: `
    此选项不完整，将不会应用于发送的提案。`,

  [ProposalKeys.Part2.Option_1.Image.title]: '交付图像（可选）',
  [ProposalKeys.Part2.Option_1.Image.description]:
    '以JPG、JPEG、PNG、GIF格式添加最多5个图像文件。最大100 MB。',

  [ProposalKeys.Part2.Option_1.Info.title]: '交付信息（必填）',
  [ProposalKeys.Part2.Option_1.Info.placeholder]:
    '请提供有关产品和运送的信息。',

  [ProposalKeys.Part2.Option_1.caution_1]:
    '您将收到创作者的寄送地址以交付产品。产品送达后将开始内容创作，并相应更新截止日期。',
  [ProposalKeys.Part2.Option_1.caution_2]:
    '我们不处理已交付产品的退货。如果产品在发货后取消订单，则您需要直接与创作者讨论退货事宜。',
  [ProposalKeys.Part2.Option_1.caution_3]: `
    我们协助跟踪国际交付，但不保证或承担诸如错误交付等运输问题。
    <Tooltip>国内交付</Tooltip>仅适用于少数国家，但您仍然可以包含在订单中。`,
  [ProposalKeys.Part2.Option_1.caution_3_tooltip]: `
    我们支持主要运营商的国内跟踪<br>
    仅适用于有限的国家，包括美国和加拿大。<br>
    目前我们不支持其他国家的国内跟踪。<br>`,

  [ProposalKeys.Part3.title]: '要求',
  [ProposalKeys.Part3.applied]: '{{number}} 个已应用',

  [ProposalKeys.Part3.Option_1.title]: '指明付费推广',
  [ProposalKeys.Part3.Option_1.description_1]:
    '创作者将在哈希标签列表的开头加入以下其中之一的哈希标签：#Ad、#Advertisement或#Sponsored，以明确指示付费推广。',
  [ProposalKeys.Part3.Option_1.description_2]:
    '在视频内添加由YouTube提供的“包含付费推广”标签。',
  [ProposalKeys.Part3.Option_1.description_3]:
    '在Instagram帖子/故事/Reels内添加由Instagram提供的“付费合作”标签。',

  [ProposalKeys.Part3.Option_2.placeholder]: '请在此处输入您的条款和条件。',
  [ProposalKeys.Part3.Option_2.description]:
    '创作者将需要遵守您的服务条款和条件。如果它与创作者的服务相矛盾，可以请求修改或停用此选项。',

  [ProposalKeys.Part4.title]: '内容任务',

  [ProposalKeys.Part4.Option_1.title]: '包含链接',
  [ProposalKeys.Part4.Option_1.placeholder]: '输入URL',
  [ProposalKeys.Part4.Option_2.btn]: '添加此哈希标签',
  [ProposalKeys.Part4.Option_1.description]:
    '提供的链接将包括在帖子或视频说明中。可用于将更多流量引导到网站或应用程序。',

  [ProposalKeys.Part4.Option_2.title]: '包含哈希标签',
  [ProposalKeys.Part4.Option_2.placeholder]: '输入哈希标签',
  [ProposalKeys.Part4.Option_2.description]:
    '左侧的至少一个或多个哈希标签将包括在帖子或视频说明中。',

  [ProposalKeys.Part4.Option_3.title]: '标记位置',
  [ProposalKeys.Part4.Option_3.placeholder]: '输入位置',
  [ProposalKeys.Part4.Option_3.description]:
    '提供的位置将被标记在帖子或视频中。对于推广特定位置或场馆非常有效。',

  [ProposalKeys.Part4.Option_4.title]: '标记我们的帐户',
  [ProposalKeys.Part4.Option_4.placeholder]: '您的账号',
  [ProposalKeys.Part4.Option_4.description]:
    '账户 @handle 将包括在帖子或视频说明中。（仅适用于与已订购内容的平台相对应的 handle。）',

  [ProposalKeys.Part4.Option_5.title]: '使用我们的帐户置顶评论',
  [ProposalKeys.Part4.Option_5.description]:
    '将包含提供的帐户 @handle 的评论发布并置顶在内容上。（仅适用于与已订购内容的平台相对应的 handle。）',

  [ProposalKeys.Part4.Option_6.title]: '使用链接置顶评论',
  [ProposalKeys.Part4.Option_6.placeholder]: '输入URL',
  [ProposalKeys.Part4.Option_6.description]:
    '将包含提供的链接的评论发布并置顶在内容上。',

  [ProposalKeys.Part4.Option_7.title]: '包含特定图片',
  [ProposalKeys.Part4.Option_7.description]:
    '创作者将被引导至在内容中包含至少一个或多个提供的图片。',

  [ProposalKeys.Part4.Option_8.title]: '为视频添加标签',
  [ProposalKeys.Part4.Option_8.placeholder]: '输入视频标签',
  [ProposalKeys.Part4.Option_8.btn]: '添加此标签',
  [ProposalKeys.Part4.Option_8.description]:
    '左侧的至少一个或多个标签将被添加到YouTube视频以进行搜索优化。',
};
