import BillingKeys from '../translationKeys/BillingPageKeys';

export const BillingTranslations = {
  [BillingKeys.Payment_Subscription.title]: '결제 및 구독',
  [BillingKeys.Payment_Subscription.Method.title]: '결제 방법',
  [BillingKeys.Payment_Subscription.Method.btn]: '신용 · 직불 카드 추가',
  [BillingKeys.Payment_Subscription.Side.my_plan]: '내 플랜',
  [BillingKeys.Payment_Subscription.Side.renews_on]: '갱신 일자',

  [BillingKeys.Billing_History.title]: '결제 내역',
  [BillingKeys.Billing_History.search]: '크리에이터 또는 서비스 이름으로 검색',

  [BillingKeys.MEGA_Balance.title]: 'MEGA 잔액',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.title]: '출금 방법',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with]:
    '<icon></icon>로 출금하기',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon]: '곧 제공 예정',
  [BillingKeys.MEGA_Balance.Balance_Summary.title]: '잔고 내역',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance]:
    '총 사용 가능 잔액',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn]: '총 출금액',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment]:
    '대기 중인 결제금',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip]: `
    여기에는 현재 진행 중인 주문의 <br>
    결제가 포함됩니다. 대기 중인 자금은 <br>
    주문 완료 후 24일 후에 출금할 수 <br>
    있습니다. <br><br>
    업로드 후 최소 60일 동안 주문된 <br>
    콘텐츠가 삭제되거나 비공개로 설정되지 <br>
    않도록 하십시오. <TooltipLink>자세히 알아보기</TooltipLink>`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount]:
    '출금 금액',
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.details]: `
    각 출금 시 2.0%의 PayPal 수수료가 적용됩니다 (수수료 한도는 20 USD). <br>
    이체는 최대 3~5 영업일이 소요될 수 있습니다.`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.btn]: '잔액 출금',
};
