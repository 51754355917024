import ModalKeys from '../translationKeys/ModalKeys';

export const ModalTranslations = {
  // OfficialLaunch
  [ModalKeys.official_launch.title]: `
    본 기능은 한국에 곧 출시될 예정입니다!`,
  [ModalKeys.official_launch.content]: `
    일부 기능은 한국 서버에 빠른 시일 안에 추가로 제공될 예정입니다. <br>
    글로벌 기업과 편하게 협업할 수 있는 최고의 서비스를 제공하겠습니다.`,

  // Patch Note
  [ModalKeys.Patch_Note.close]: '14일동안 열지않기',

  [ModalKeys.Notification.Dropdown.title]: '알림',
  [ModalKeys.Notification.Dropdown.no_notification]: '알림 없음',
  [ModalKeys.Notification.Dropdown.bottom_btn]: '모두 읽음으로 표시',

  [ModalKeys.My_Order.Dropdown.content]: '첫 주문을 시작해보세요!',
  [ModalKeys.My_Order.Dropdown.bottom_btn]: '모든 주문 보기',
};
