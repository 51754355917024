const CookiePolicyKeys = {
  title: 'CookiePolicyKeys.title',
  top_text_1: 'CookiePolicyKeys.top_text_1',
  top_text_2: 'CookiePolicyKeys.top_text_2',

  Who_We_Are: {
    title: 'CookiePolicyKeys.Who_We_Are.title',
    description_1: 'CookiePolicyKeys.Who_We_Are.description_1',
    description_2: 'CookiePolicyKeys.Who_We_Are.description_2',
  },

  What_Is_Cookie: {
    title: 'CookiePolicyKeys.What_Is_Cookie.title',

    Description: {
      l1: 'CookiePolicyKeys.What_Is_Cookie.Description.l1',
      l2: 'CookiePolicyKeys.What_Is_Cookie.Description.l2',
      l3: 'CookiePolicyKeys.What_Is_Cookie.Description.l3',
      l4: 'CookiePolicyKeys.What_Is_Cookie.Description.l4',
      l5: 'CookiePolicyKeys.What_Is_Cookie.Description.l5',
    },
  },

  Why_Does_MegaEvolution_Use_Cookies: {
    title: 'CookiePolicyKeys.Why_Does_MegaEvolution_Use_Cookies.title',
    description:
      'CookiePolicyKeys.Why_Does_MegaEvolution_Use_Cookies.description',
  },

  How_Can_I_Manage_Cookies: {
    title: 'CookiePolicyKeys.How_Can_I_Manage_Cookies.title',

    A: {
      title: 'CookiePolicyKeys.How_Can_I_Manage_Cookies.A.title',
      description: 'CookiePolicyKeys.How_Can_I_Manage_Cookies.A.description',
    },

    B: {
      title: 'CookiePolicyKeys.How_Can_I_Manage_Cookies.B.title',
      description_1:
        'CookiePolicyKeys.How_Can_I_Manage_Cookies.A.description_1',
      description_2:
        'CookiePolicyKeys.How_Can_I_Manage_Cookies.A.description_2',
    },
  },

  What_types_of_cookies_do_we_use: {
    title: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.title',

    Description: {
      l1: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l1',
      l2: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l2',
      l3: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l3',
      l4: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l4',
      l5: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l5',
      l6: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l6',
      l7: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l7',
      l8: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l8',
      l9: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l9',
      l10: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l10',
      l11: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l11',
      l12: 'CookiePolicyKeys.What_types_of_cookies_do_we_use.Description.l12',
    },
  },

  Google_Analytics: {
    title: 'CookiePolicyKeys.Google_Analytics.title',

    Description: {
      l1: 'CookiePolicyKeys.Google_Analytics.Description.l1',
      l2: 'CookiePolicyKeys.Google_Analytics.Description.l2',
      l3: 'CookiePolicyKeys.Google_Analytics.Description.l3',
      l4: 'CookiePolicyKeys.Google_Analytics.Description.l4',
      l5: 'CookiePolicyKeys.Google_Analytics.Description.l5',
      l6: 'CookiePolicyKeys.Google_Analytics.Description.l6',
      l7: 'CookiePolicyKeys.Google_Analytics.Description.l7',
      l8: 'CookiePolicyKeys.Google_Analytics.Description.l8',
      l9: 'CookiePolicyKeys.Google_Analytics.Description.l9',
    },
  },

  Google_Analytics_Does_this_policy_change: {
    title: 'CookiePolicyKeys.Google_Analytics_Does_this_policy_change.title',
    description:
      'CookiePolicyKeys.Google_Analytics_Does_this_policy_change.description',
  },

  Who_Should_I_Contact_For_More_Information: {
    title: 'CookiePolicyKeys.Who_Should_I_Contact_For_More_Information.title',
    description:
      'CookiePolicyKeys.Who_Should_I_Contact_For_More_Information.description',
  },
};

export default CookiePolicyKeys;
