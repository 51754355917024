const InvitationKeys = {
  title1: 'InvitationKeys.title1',
  title2: 'InvitationKeys.title2',
  subtitle: 'InvitationKeys.subtitle',

  description: 'InvitationKeys.description',

  view_creator_tutorial: 'InvitationKeys.view_creator_tutorial',
  explore_btn: 'InvitationKeys.explore_btn',
  learn_more: 'InvitationKeys.learn_more',
  main_site: 'InvitationKeys.main_site',

  Browser: {
    title: 'InvitationKeys.Browser.title',
    description: 'InvitationKeys.Browser.description',
    button: 'InvitationKeys.Browser.button',
  },

  Mega_Flow: {
    title: 'InvitationKeys.Mega_Flow.title',

    Caption: {
      one: 'InvitationKeys.Mega_Flow.Caption.one',
      two: 'InvitationKeys.Mega_Flow.Caption.two',
      three: 'InvitationKeys.Mega_Flow.Caption.three',
      four: 'InvitationKeys.Mega_Flow.Caption.four',
    },

    Caption_Mobile: {
      one: 'InvitationKeys.Mega_Flow.Caption_Mobile.one',
      two: 'InvitationKeys.Mega_Flow.Caption_Mobile.two',
      three: 'InvitationKeys.Mega_Flow.Caption_Mobile.three',
      four: 'InvitationKeys.Mega_Flow.Caption_Mobile.four',
    },
  },

  Creator_Review: {
    title: 'InvitationKeys.Creator_Review.title',
  },

  Brand: {
    title: 'InvitationKeys.Brand.title',
    subTitle: 'InvitationKeys.Brand.subTitle',

    Business_Flow: {
      title: 'InvitationKeys.Brand.Business_Flow.title',
      title_mobile: 'InvitationKeys.Brand.Business_Flow.title_mobile',

      Caption: {
        one: 'InvitationKeys.Brand.Business_Flow.Caption.one',
        two: 'InvitationKeys.Brand.Business_Flow.Caption.two',
        three: 'InvitationKeys.Brand.Business_Flow.Caption.three',
        four: 'InvitationKeys.Brand.Business_Flow.Caption.four',
      },

      Caption_Mobile: {
        one: 'InvitationKeys.Brand.Business_Flow.Caption_Mobile.one',
        two: 'InvitationKeys.Brand.Business_Flow.Caption_Mobile.two',
        three: 'InvitationKeys.Brand.Business_Flow.Caption_Mobile.three',
        four: 'InvitationKeys.Brand.Business_Flow.Caption_Mobile.four',
      },
    },

    List: {
      title: 'InvitationKeys.Brand.List.title',
    },

    Registration: {
      title: 'InvitationKeys.Brand.Registration.title',
      title_mobile: 'InvitationKeys.Brand.Registration.title_mobile',
      subTitle: 'InvitationKeys.Brand.Registration.subTitle',

      Name: {
        title: 'InvitationKeys.Brand.Registration.Name.title',
        placeholder: 'InvitationKeys.Brand.Registration.Name.placeholder',
      },

      Email: {
        title: 'InvitationKeys.Brand.Registration.Email.title',
        placeholder: 'InvitationKeys.Brand.Registration.Email.placeholder',
      },

      receive_mega_guide_checkbox:
        'InvitationKeys.Brand.Registration.receive_mega_guide_checkbox',
      btn: 'InvitationKeys.Brand.Registration.btn',
    },

    Verified_Service: {
      title: 'InvitationKeys.Brand.Verified_Service.title',
      title_mobile: 'InvitationKeys.Brand.Verified_Service.title_mobile',
    },

    Error: {
      invalid_email: 'InvitationKeys.Brand.Error.invalid_email',
      required_field: 'InvitationKeys.Brand.Error.required_field',
    },

    Toast: {
      registration_complete: 'InvitationKeys.Brand.Toast.registration_complete',
      error: 'InvitationKeys.Brand.Toast.error',
    },
  },
};

export default InvitationKeys;
