import CookiePolicyKeys from '../translationKeys/CookiePolicyPageKeys';

export const CookiePolicyTranslations = {
  [CookiePolicyKeys.title]: 'Cookie Policy',
  [CookiePolicyKeys.top_text_1]: `At MegaEvolution we are committed to protecting your Personal Data
  and to providing clear and transparent disclosures about the types
  of information we collect and how we use it. When you visit our
  platform at www.megaevolution.io, cookies may be used to collect
  information that distinguishes you from other users of our website.`,
  [CookiePolicyKeys.top_text_2]: `This Cookie Policy describes the purposes for which we and our
  partners use cookies and similar technologies and how you can manage
  your preferences regarding cookies.`,

  // Who we are and how to contact us
  [CookiePolicyKeys.Who_We_Are.title]: 'Who we are and how to contact us',
  [CookiePolicyKeys.Who_We_Are
    .description_1]: `We are Intaverse Inc. of 1178, 15, Teheran-ro 82-gil, Gangnam-gu,
  Seoul, Republic of Korea (Postal code 06178) trading as MegaEvolution,
  and in the case of your direct use of our website or our services, we
  act as the data controller in accordance with South Korea’s Personal
  Information Protection Act (“PIPA”). As well as the EU's Privacy and
  Electronic Communications Regulations (“PECR”) and General Data
  Protection Regulation (“GDPR”).`,
  [CookiePolicyKeys.Who_We_Are
    .description_2]: `If you have any questions about Cookies or about data protection in
  general, you can reach us at info@megaevolution.io.`,

  // What is a cookie?
  [CookiePolicyKeys.What_Is_Cookie.title]: 'What is a cookie?',

  [CookiePolicyKeys.What_Is_Cookie.Description
    .l1]: `A cookie is a text file or software element that records information
  about your device's navigation on the Internet. It is controlled by
  your browser and sometimes contains a unique number or a random
  number. When we mention "cookies", these may be cookies or other
  similar technologies based on access to the device (computer, tablet,
  smartphone) used by you when you visit our websites. Third parties,
  such as our partners or service providers, may use cookies and similar
  technologies in connection with our Services.`,
  [CookiePolicyKeys.What_Is_Cookie.Description
    .l2]: `A cookie allows us or our partners, as creators of cookies, to
  recognize your device during the period of its validity when it
  accesses digital content that contains cookies from the same creator.`,
  [CookiePolicyKeys.What_Is_Cookie.Description
    .l3]: `Some of the information from cookies does not allow your direct or
  indirect identification, nor does it constitute Personal Information.
  Other information may allow your identification and as such is subject
  to our`,
  [CookiePolicyKeys.What_Is_Cookie.Description.l4]: `Privacy Policy`,
  [CookiePolicyKeys.What_Is_Cookie.Description
    .l5]: `. If you would like more information regarding how we use, store, and
  protect your Personal Information and your rights, we recommend that
  you read our Privacy Policy.`,

  // Why does MegaEvolution use cookies?
  [CookiePolicyKeys.Why_Does_MegaEvolution_Use_Cookies.title]:
    'Why does MegaEvolution use cookies?',
  [CookiePolicyKeys.Why_Does_MegaEvolution_Use_Cookies
    .description]: `We are always looking for new ways to improve our website and provide
  better content and experiences to you. Consequently, we and our
  partners use different types of cookies (as described below). In the
  case of some cookie categories, your consent is required and obtained
  through our Cookie Pop Up before the cookie is placed on your device
  or read. In any case, you always have control over cookies and can
  refuse, allow, or delete cookies.`,

  // How can I manage cookies?
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.title]:
    'How can I manage cookies?',

  [CookiePolicyKeys.How_Can_I_Manage_Cookies.A.title]:
    'a) Contacting us and using our services',
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.A
    .description]: `Most browsers allow you to control cookies through the cookie
  settings, which can be customized for giving your consent to the use
  of cookies. In addition, most browsers will enable you to review and
  delete cookies. To do so, please refer to the documentation provided
  by your browser manufacturer by following the for you relevant link
  Google Chrome, Mozilla Firefox, Flash cookies, Microsoft Internet
  Explorer and Edge, Opera, Safari.`,

  [CookiePolicyKeys.How_Can_I_Manage_Cookies.B.title]: 'b) Sign up',
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.B
    .description_1]: `We only collect cookies from users who sign up to our service. You are
  consenting to our Cookie policies by signing up to our platform. This
  permits you to make an informed decision about the cookies we use. You
  are free to reject cookies by not signing up, but note that browsing
  our website might be less user-friendly and the relevant content might
  be affected if you do not sign up.`,
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.B
    .description_2]: `You may, regardless of your original choice, from time to time adjust
  your settings using the Browser control settings, clearing your cookie
  folder.`,

  // What types of cookies do we use?
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.title]:
    'What types of cookies do we use?',

  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l1]: `A cookie can be classified in different ways.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l2]: `According to its lifetime, a cookie is either a:`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l3]: `· Session cookie, which is deleted when you close your browser.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l4]: `· Persistent cookie, which remains on your device for a predefined
  period of time, according to the specific cookie’s lifespan.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l5]: `Given the domain to which they belong, they are either:`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l6]: `· First-party cookies, which are set by the web server of the visited
  site and have the same domain, in this case www.megaevolution.io`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l7]: `· Third-party cookies, which are stored by another domain in the
  domain of the visited page. They are set by a third party, partners
  contracted by us. These external partners, to the extent that they use
  cookies, are responsible for the cookies they use. We recommend that
  you read their Privacy Policies.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l8]: `Apart from these characteristics Cookie can be classified in terms of
  how they work:`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l9]: `· The least privacy invasive type of cookies are Essential or
  Necessary cookies. These cookies are essential or necessary to ensure
  that a website works properly and is secure so that you can navigate a
  website and use its features. Without these cookies, certain features
  of a website would not function, and thus you would not be able to use
  certain services. They are strictly necessary for your navigation,
  consequently they do not require your prior consent. These cookies are
  NOT managed by our Pop-Up Consent Tool.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l10]: `· There are also Functional cookies or sometimes called convenience
  cookies. These cookies allow a website to remember the options a user
  has made (including user ID's stored, consents given, or languages
  selected) and other personalization options you have selected when
  browsing. You are free to accept or reject them, but if they are
  blocked, there is a possibility that the service choice you previously
  made will not be saved.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l11]: `· Further there are also Analysis and performance cookies, which are
  used to monitor and improve the function and service of a website.
  Those can track down problems when using a website, facilitate online
  surveys, record visitor numbers, and provide analytics metrics. You
  are free to accept or reject them, but if they are blocked, less
  information will be available to us to improve the performance of its
  website.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l12]: `· Lastly, advertising cookies or targeting cookies. They are used to
  deliver customized advertising to the user. This can be very
  convenient, but also very annoying. The use of these cookies requires
  your prior consent.`,

  // Google Analytics
  [CookiePolicyKeys.Google_Analytics.title]: 'Google Analytics',

  [CookiePolicyKeys.Google_Analytics.Description
    .l1]: `We use Google Analytics, a web analytics service provided by Google,
  on our website. Google Analytics also uses cookies to enable our
  website to analyze how users use our website across multiple devices.
  The information generated by the cookies about your use of our website
  is transmitted to and stored by Google, including transmission to the
  United States. The following data is processed through the use of
  Google Analytics:`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l2]: `· 3 bytes of the IP address of the called system of the website
  visitor (anonymized IP address),`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l3]: `· the website called up,`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l4]: `· the website from which the user reached the accessed page of our
  website (referrer),`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l5]: `· the subpages accessed from the website,`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l6]: `· the time spent on the website`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l7]: `· the frequency with which the website is accessed.`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l8]: `Google states that it will not associate your IP address with any
  other data held by Google.`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l9]: `You can disable tracking by Google Analytics with future effect by
  downloading and installing the Google Analytics Opt-out Browser Add-on
  for your current web browser following this link
  http://tools.google.com/dlpage/gaoptout?hl=en. The legal basis is our
  legitimate interest and your consent.`,

  // Google Analytics Does this policy change?
  [CookiePolicyKeys.Google_Analytics_Does_this_policy_change.title]:
    'Google Analytics Does this policy change?',
  [CookiePolicyKeys.Google_Analytics_Does_this_policy_change
    .description]: `We may update our Cookie Policy from time to time. This might be for a
  number of reasons, such as to reflect a change in the law or to
  accommodate a change in our business practices and the way we use
  cookies. We recommend that you check here periodically for any changes
  to our Cookie Policy.`,

  // Who should I contact for more information?
  [CookiePolicyKeys.Who_Should_I_Contact_For_More_Information.title]:
    'Who should I contact for more information?',
  [CookiePolicyKeys.Who_Should_I_Contact_For_More_Information
    .description]: `If you have any questions or comments about our Cookie Policy or wish
  to exercise your rights under applicable laws, please contact us using
  info@megaevolution.io.`,
};
