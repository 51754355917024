export const HashTagIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 18}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.5625 17L12.1875 1H14.0625L11.4375 17H9.5625ZM2.32031 12.7187L2.625 10.8437H14.75L14.4453 12.7187H2.32031ZM3.9375 17L6.5625 1H8.4375L5.8125 17H3.9375ZM3.25 7.15625L3.55469 5.28125H15.6797L15.375 7.15625H3.25Z" />
  </svg>
);
