import styled from 'styled-components';
import useScript from 'hooks/useScript';
import { useState, useLayoutEffect, useEffect, useRef } from 'react';
import { jwtDecoder } from 'components/utilities';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import device from 'styles/device';

const client_id = process.env.REACT_APP_GOOGLE_API_CLIENT_KEY;
const src = 'https://accounts.google.com/gsi/client';

const GoogleLogin = ({ onSuccess, onFailure, context = 'signin_with' }) => {
  const state = useScript(src, true, true);
  const buttonRef = useRef(null);
  const [gapi, setGapi] = useState(null);

  const { isMobile } = useResponsiveScreen();

  const callbackHandler = async (res) => {
    const jwt = res.credential;
    const payload = jwtDecoder(jwt);
    const { email, name, picture } = JSON.parse(payload);
    const response = await onSuccess({
      email,
      name,
      imageUrl: picture,
      googleToken: jwt,
    });
  };
  const initializeClient = () => {
    gapi.accounts.id.initialize({
      client_id,
      use_fedcm_for_prompt: true,
      callback: callbackHandler,
      auto_select: false,
      intermediate_iframe_close_callback: (res) => {},
    });
    const buttonWidth = isMobile ? '250px' : '320px';
    const renderOption = {
      theme: 'outline',
      size: 'large',
      shape: 'pill',
      text: context,
      width: buttonWidth,
    };
    gapi.accounts.id.renderButton(buttonRef.current, renderOption);
    gapi.accounts.id.prompt((notification) => {});
  };
  useLayoutEffect(() => {
    if (state === 'ready') {
      setGapi(window.google);
    }
  }, [state]);

  useEffect(() => {
    if (gapi) {
      initializeClient();
    }
  }, [gapi]);

  return <Button isMobile={isMobile} ref={buttonRef} />;
};

export default GoogleLogin;

const Button = styled.div`
  width: 320px;
  overflow: visible;
  border-radius: 200px;

  @media ${device.sm}{
    min-width: 250px;
    max-width: 250px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius : 25px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  }
`;
