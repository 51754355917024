import Heading from 'components/Basics/Typography/Heading';
import { motion } from 'framer-motion';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import device from 'styles/device';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const Country = ({ country }) => {
  const { isMobile } = useResponsiveScreen();
  const { t } = useTranslation();

  if (!country?.value) return null;

  return (
    <Wrapper>
      <Heading
        variant={`${isMobile ? 'sbh8' : 'sbh6'}`}
        color={isMobile ? 'var(--color-indigo-100)' : ''}
      >
        {t(GeneralKeys.country)}
      </Heading>

      <span className={`${isMobile ? 'sbh7' : 'text-sm'}`}>
        {t(country.value)}
      </span>
    </Wrapper>
  );
};

export default Country;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  exit: { opacity: 0, y: 0 },
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (${device.sm}) {
    gap: 0.75rem;
  }
`;
