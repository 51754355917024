import ModalKeys from '../translationKeys/ModalKeys';

export const ModalTranslations = {
  // Official Launch Modal
  [ModalKeys.official_launch.title]:
    'Tính năng này sẽ sớm được phát hành tại quốc gia của bạn!',
  [ModalKeys.official_launch.content]: `
    Tính năng này sẽ được bổ sung thêm tại khu vực của bạn trong thời gian sớm nhất. <br> 
    Chúng tôi sẽ cung cấp dịch vụ tốt nhất cho phép bạn dễ dàng cộng tác với các doanh nghiệp toàn cầu.`,

  // Patch Note Modal
  [ModalKeys.Patch_Note.close]: 'Không hiển thị lại trong vòng 14 ngày',

  [ModalKeys.Notification.Dropdown.title]: 'Thông báo',
  [ModalKeys.Notification.Dropdown.no_notification]: 'Không thông báo',
  [ModalKeys.Notification.Dropdown.bottom_btn]: 'Đánh dấu là đã đọc tất cả',

  [ModalKeys.My_Order.Dropdown.content]: 'Bắt đầu đơn hàng đầu tiên của bạn!',
  [ModalKeys.My_Order.Dropdown.bottom_btn]: 'Xem tất cả các đơn hàng',
};
