export const size = {
  mmd: {
    min: 400,
    max: 769,
  },
  md: 769,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
};

const device = {
  sm: `(max-width: ${size.md}px)`,
  mmd: `(min-width: ${size.mmd.min}px) and (max-width: ${size.mmd.max}px)`,
  md: `(min-width: ${size.md}px)`,
  lg: `(min-width: ${size.lg}px)`,
  xl: `(min-width: ${size.xl}px)`,
  xxl: `(min-width: ${size.xxl}px)`,
};

export default device;
