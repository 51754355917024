import GuideKeys from '../translationKeys/GuidePageKeys';

export const GuideTranslations = {
  // Click to join
  [GuideKeys.click_to_join]: '点击加入',

  // Portfolio Banner
  [GuideKeys.Portfolio_Banner.description]: `
    创建您自己的作品集 <br>
    页面，拥有独特的URL！`,
  [GuideKeys.Portfolio_Banner.view_sample]: '查看示例',

  // Creator Invitation
  [GuideKeys.Creator_Invitation.hi_ginseng_mega]: '你好，我是MEGA！',
  [GuideKeys.Creator_Invitation.nice_to_meet_you]: '很高兴认识你~！',
  [GuideKeys.Creator_Invitation.we_help_you_receive]: '我们帮您接收提案',
  [GuideKeys.Creator_Invitation.proposal_golbal_brands]: '来自全球品牌的提案！',
  [GuideKeys.Creator_Invitation.let_companies_directly]: '让公司直接',
  [GuideKeys.Creator_Invitation.order_your_package]: '订购您的服务包！',
  [GuideKeys.Creator_Invitation.click_to_join]: '点击这里',
  [GuideKeys.Creator_Invitation.anytime]: '随时加入！',
  [GuideKeys.Creator_Invitation.package_featured]:
    '您的套餐将向来自128多个国家',
  [GuideKeys.Creator_Invitation.showcase_channel]: '的公司推荐！ 展示您的频',
  [GuideKeys.Creator_Invitation.showcase_channel_2]: '道并与全球客户建立联系！',
  [GuideKeys.Creator_Invitation.order_package]: '订购服务包',

  [GuideKeys.Creator_Invitation.Mobile.we_help_you_receive]: '我们帮您接收',
  [GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands]:
    '来自全球品牌的提案！',
  [GuideKeys.Creator_Invitation.Mobile.let_companies_directly]: '让公司直接',
  [GuideKeys.Creator_Invitation.Mobile.order_your_package]: '订购您的服务包！',
  [GuideKeys.Creator_Invitation.Mobile.click_to_join]: '点击加入',
  [GuideKeys.Creator_Invitation.Mobile.anytime]: '随时加入！',
};
