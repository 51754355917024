import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: '저장 중',
  [ProposalKeys.changes_saved]: '변경 사항 저장됨',

  [ProposalKeys.New_Proposal.text]: '새 제안서',
  [ProposalKeys.New_Proposal.toast]:
    '만들 수 있는 제안 최대 수에 도달했습니다.',

  [ProposalKeys.Clear.text]: '모두 지우기',
  [ProposalKeys.Clear.toast]: `
    성공적으로 지워졌습니다. 취소하려면 <button>여기</button>를 클릭하세요.`,

  [ProposalKeys.Modal.Copy.title]: '제안을 덮어쓰시겠습니까?',
  [ProposalKeys.Modal.Copy.description]: `
    현재 제안서가 선택한 제안서에 덮어쓰기됩니다. <br>
    이 작업은 취소할 수 없습니다. 계속하시겠습니까?`,

  [ProposalKeys.Part1.Option_1.title]: '제목',
  [ProposalKeys.Part1.Option_1.placeholder]: '프로젝트 제목을 입력하세요',
  [ProposalKeys.Part1.Option_1.error]: '최대 100자',

  [ProposalKeys.Part1.Option_2.title]: '제품 · 브랜드 웹사이트',
  [ProposalKeys.Part1.Option_2.placeholder]: 'URL 입력',
  [ProposalKeys.Part1.Option_2.error]: '최대 400자',

  [ProposalKeys.Part1.Option_3.title]: '설명 및 파일',
  [ProposalKeys.Part1.Option_3.placeholder]: `
    프로젝트의 세부 정보를 설명하고 파일을 첨부하세요. <br>
    크리에이터는 다음과 관련된 정보를 찾을 수 있습니다. <br><br><br>
    <ul>
      <li>프로젝트/제품 설명</li>
      <li>캠페인 목표 및 대상 관객</li>
      <li>상세 콘텐츠 가이드라인</li>
    </ul>`,
  [ProposalKeys.Part1.Option_3.Snippet.title]: '추가할 스니펫 선택',
  [ProposalKeys.Part1.Option_3.Snippet.option_1]: '크리에이터의 소셜 채널 이름',
  [ProposalKeys.Part1.Option_3.Snippet.option_2]: '크리에이터의 사용자 이름',
  [ProposalKeys.Part1.Option_3.Snippet.option_3]: '크리에이터의 주요 국가',
  [ProposalKeys.Part1.Option_3.Snippet.option_4]:
    '크리에이터의 최상위 카테고리',

  [ProposalKeys.Part1.Option_3.Error.file_format]: `
    잘못된 파일 형식입니다. (다음 파일 형식을 사용하세요: {{fileFormats}})`,
  [ProposalKeys.Part1.Option_3.Error.file_size_limit]: `
    총 파일 크기 제한은 최대 {{maxSize}}입니다.`,
  [ProposalKeys.Part1.Option_3.Error.max_file]: `
    최대 {{maxFiles}} 파일을 전송할 수 있습니다.`,

  [ProposalKeys.caution]: `
    패키지를 주문하면 모든 적용 가능한 <strong>요구 사항 및 콘텐츠 미션</strong>이 주문된 콘텐츠에 <strong>자동으로 적용</strong>됩니다. <strong>불완전한</strong> 또는 <strong>적용할 수 없는</strong> 경우 주문된 콘텐츠에 적용되지 않습니다.`,

  [ProposalKeys.Side_btn.text]: '적용 가능한 콘텐츠',
  [ProposalKeys.Side_btn.title]: '적용 가능한 콘텐츠 : ',

  [ProposalKeys.Part2.title]: '크리에이터에게 제품 보내기',

  [ProposalKeys.Part2.Option_1.title]: '협찬 제품 보내기',
  [ProposalKeys.Part2.Option_1.tooltip]: `
    이 옵션은 제안서에 아직 적용되지 않습니다.`,

  [ProposalKeys.Part2.Option_1.Image.title]: '배송 이미지 (선택 사항)',
  [ProposalKeys.Part2.Option_1.Image.description]:
    'JPG, JPEG, PNG, GIF 형식의 최대 5개의 이미지 파일을 추가하세요. 최대 100MB까지 가능합니다.',

  [ProposalKeys.Part2.Option_1.Info.title]: '배송 정보 (필수)',
  [ProposalKeys.Part2.Option_1.Info.placeholder]:
    '제품 및 배송에 관한 정보를 제공해주세요.',

  [ProposalKeys.Part2.Option_1.caution_1]:
    '크리에이터의 배송 주소를 제공받게 됩니다. 제품이 도착하면 콘텐츠 제작이 시작되며 마감일이 업데이트됩니다.',
  [ProposalKeys.Part2.Option_1.caution_2]:
    '배송된 제품에 대한 반품 처리를 하지 않습니다. 제품이 발송된 후 주문이 취소된 경우, 반품에 대한 직접 협의가 필요합니다.',
  [ProposalKeys.Part2.Option_1.caution_3]: `
    국제 배송을 추적하는 데 도움을 드리지만 배송 문제 (예: 잘못 배송)와 같은 문제에 대한 보장은 제공하지 않습니다.
    <Tooltip>국내 배송</Tooltip>은 일부 국가에 대해서만 추적 가능하며 주문에 포함시킬 수는 있지만 보장되지 않습니다.`,
  [ProposalKeys.Part2.Option_1.caution_3_tooltip]: `
    저희는 주요 국가, 미국 및 캐나다를 포함한 일부 국가에 대한 국내 추적을 지원합니다. <br>
    현재 다른 국가에 대한 국내 추적을 지원하지 않습니다.`,

  [ProposalKeys.Part3.title]: '요구 사항',
  [ProposalKeys.Part3.applied]: '{{number}}개 적용됨',

  [ProposalKeys.Part3.Option_1.title]: '유료 프로모션 표시',
  [ProposalKeys.Part3.Option_1.description_1]:
    '크리에이터가 유료 프로모션을 명확히 나타내기 위해 다음 해시태그 중 하나인 #Ad, #Advertisement 또는 #Sponsored를 해시태그 목록의 시작에 추가할 것입니다.',
  [ProposalKeys.Part3.Option_1.description_2]:
    '크리에이터가 동영상 안에 제공된 "유료 프로모션 포함" 레이블을 추가할 것입니다.',
  [ProposalKeys.Part3.Option_1.description_3]:
    '크리에이터가 Instagram 게시물/스토리/릴즈 내부에 제공된 "유료 제휴" 레이블을 추가할 것입니다.',

  [ProposalKeys.Part3.Option_2.placeholder]:
    '여기에 귀사의 약관을 입력해주세요.',
  [ProposalKeys.Part3.Option_2.description]:
    '크리에이터는 서비스 제공을 위해 귀사의 약관을 준수해야 합니다. 이 옵션의 수정 또는 비활성화를 요청할 수 있으며 이는 크리에이터의 서비스와 충돌하는 경우에 해당합니다.',

  [ProposalKeys.Part4.title]: '콘텐츠 미션',

  [ProposalKeys.Part4.Option_1.title]: '링크 포함',
  [ProposalKeys.Part4.Option_1.placeholder]: 'URL 입력',
  [ProposalKeys.Part4.Option_2.btn]: '해시태그 추가',
  [ProposalKeys.Part4.Option_1.description]:
    '제공된 링크는 게시물 또는 동영상 설명에 포함됩니다. 웹사이트 또는 앱으로 더 많은 트래픽을 유도하는 데 사용하세요.',

  [ProposalKeys.Part4.Option_2.title]: '해시태그 포함',
  [ProposalKeys.Part4.Option_2.placeholder]: '해시태그 입력',
  [ProposalKeys.Part4.Option_2.description]:
    '왼쪽에서 제공된 해시태그 중 하나 이상이 게시물 또는 동영상 설명에 포함됩니다.',

  [ProposalKeys.Part4.Option_3.title]: '위치 태그',
  [ProposalKeys.Part4.Option_3.placeholder]: '위치 입력',
  [ProposalKeys.Part4.Option_3.description]:
    '제공된 위치는 게시물 또는 동영상에 태그됩니다. 특정 위치 또는 장소를 홍보하는 데 효과적입니다.',

  [ProposalKeys.Part4.Option_4.title]: '계정 태그',
  [ProposalKeys.Part4.Option_4.placeholder]: '계정 핸들 입력',
  [ProposalKeys.Part4.Option_4.description]:
    '계정 @핸들은 게시물 또는 동영상 설명에 포함됩니다. (주문된 콘텐츠의 플랫폼에 해당하는 핸들만 적용됩니다.)',

  [ProposalKeys.Part4.Option_5.title]: '저희 계정을 포함한 코멘트 고정',
  [ProposalKeys.Part4.Option_5.description]:
    '제공된 계정 @핸들을 포함한 코멘트가 게시되고 콘텐츠에 고정됩니다. (주문된 콘텐츠의 플랫폼에 해당하는 핸들만 적용됩니다.)',

  [ProposalKeys.Part4.Option_6.title]: '링크를 포함한 코멘트 고정',
  [ProposalKeys.Part4.Option_6.placeholder]: 'URL 입력',
  [ProposalKeys.Part4.Option_6.description]:
    '제공된 링크를 포함한 코멘트가 게시되고 콘텐츠에 고정됩니다.',

  [ProposalKeys.Part4.Option_7.title]: '특정 이미지 포함',
  [ProposalKeys.Part4.Option_7.description]:
    '크리에이터에게 콘텐츠에 제공된 이미지 중 하나 이상을 포함하도록 안내됩니다.',

  [ProposalKeys.Part4.Option_8.title]: '동영상 태그 추가',
  [ProposalKeys.Part4.Option_8.placeholder]: '동영상 태그 입력',
  [ProposalKeys.Part4.Option_8.btn]: '이 태그 추가',
  [ProposalKeys.Part4.Option_8.description]:
    '왼쪽에서 제공된 태그 중 하나 이상이 YouTube 동영상에 추가되어 검색 최적화에 사용됩니다.',
};
