import useResponsiveScreen from 'hooks/useResponsiveScreen';
import styled from 'styled-components';
import { useRef, useState, useEffect } from 'react';
import Heading from '../../Basics/Typography/Heading';
import Stack from '../../Basics/Layout/Stack';
import TextField from '../../Basics/Inputs/TextField';
import PasswordInput from 'components/components/auth/PasswordInput';
import IndigoButton from '../../Basics/Buttons/IndigoButton';
import { useNavigate } from 'react-router-dom';
import Logo from 'components/Basics/Layout/Logo';
import { createPortal } from 'react-dom';
import withAuthHOC from 'components/components/auth/withAuthHOC';
import SocialSignupSection from './Signup/SocialSignupSection';
import { mainFlow, authPlatform } from 'constants/authFlow';
import { useAuth } from 'hooks/useAauth';
import { authType } from 'constants/authType';
import { CrossIconRounded } from 'assets/icons';
import IconButton from 'components/Basics/Buttons/IconButton';
import { useCallback } from 'react';
import AuthModal from './AuthModal';
import { useTranslation } from 'react-i18next';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const { LOCAL } = authPlatform;
const { SIGN_IN } = mainFlow;

const SignUpPopup = ({ flowManager, loading, setIsLoading }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();
  const { closeModal, navigateFlow, forwardStep, setSharedValue, sharedValue } =
    flowManager;
  const [error, setError] = useState(
    sharedValue?.error ? sharedValue.error : ''
  );

  const { utils } = useAuth(authType.LOCAL);

  const emailRef = useRef();
  const emailMobileRef = useRef();
  const passwordRef = useRef();
  const passwordMobileRef = useRef();

  const [loginStarted, setLoginStarted] = useState(false);

  const validateUserInput = useCallback(async () => {
    setIsLoading(true);
    setLoginStarted(true);
    try {
      await utils.validate({
        email: isMobile
          ? emailMobileRef.current?.value
          : emailRef.current?.value,
        password: isMobile
          ? passwordMobileRef.current?.value
          : passwordRef.current?.value,
      });

      setError('');
      setSharedValue({
        userData: {
          email: isMobile
            ? emailMobileRef.current?.value
            : emailRef.current?.value,
          password: isMobile
            ? passwordMobileRef.current?.value
            : passwordRef.current?.value,
        },
      });
      forwardStep();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setLoginStarted(false);
      if (error.response?.status === 500 || error.response?.status === 502) {
        setError(LoginKeys.Error.network);
      } else {
        setError(error.message);
      }
    }
  }, [
    emailMobileRef,
    emailRef,
    isMobile,
    passwordMobileRef,
    passwordRef,
    forwardStep,
    setSharedValue,
    utils,
    setIsLoading,
  ]);

  const onEnter = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        validateUserInput(event);
        setIsLoading(true);
        setLoginStarted(true);
      }
    },
    [validateUserInput, setIsLoading, setLoginStarted]
  );

  const handleDocumentClick = useCallback(
    (e) => {
      if (loading && loginStarted && !error) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [loading, loginStarted, error]
  );

  useEffect(() => {
    if (isMobile) return;

    if (emailRef.current && passwordRef.current) {
      window.addEventListener('keypress', onEnter);
    }
    if (loading && loginStarted) {
      window.addEventListener('click', handleDocumentClick, true);
    } else if (!loading || error) {
      window.removeEventListener('click', handleDocumentClick, true);
    } else {
      window.removeEventListener('click', handleDocumentClick, true);
    }
    return () => {
      window.removeEventListener('keypress', onEnter);
      window.removeEventListener('click', handleDocumentClick, true);
    };
  }, [
    onEnter,
    emailRef,
    passwordRef,
    isMobile,
    loading,
    loginStarted,
    validateUserInput,
    error,
    handleDocumentClick,
  ]);

  useEffect(() => {
    if (!loading) {
      setLoginStarted(false);
      window.removeEventListener('click', handleDocumentClick, true);
    }
    if (error) {
      setLoginStarted(false);
    }
    return () => {
      window.removeEventListener('click', handleDocumentClick, true);
    };
  }, [loading, error, handleDocumentClick]);

  //mobile
  useEffect(() => {
    if (!isMobile) return;
    if (emailMobileRef.current && passwordMobileRef.current) {
      window.addEventListener('keypress', onEnter);
    }
    return () => {
      window.removeEventListener('keypress', onEnter);
    };
  }, [onEnter, emailMobileRef, passwordMobileRef, isMobile]);

  // lock scroll when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [closeModal]);

  const modalRoot = useRef(document.getElementById('root'));

  return createPortal(
    <>
      {/* Desktop */}
      {!isMobile && (
        <AuthModal show={!isMobile} onClose={closeModal}>
          <ModalBody>
            {loading()}
            <Heading variant="BH2" className="text-align-center">
              {t(LoginKeys.Login_Form.sign_up)}
            </Heading>

            <Stack gap="1.875rem">
              <Stack>
                <SocialSignupSection
                  flowManager={flowManager}
                  setError={setError}
                  setIsLoading={setIsLoading}
                />
              </Stack>
              <Stack>
                <TextField
                  ref={emailRef}
                  placeholder={t(LoginKeys.Sign_Up_Form.Placeholder.email)}
                  placeholderColor="var(--color-indigo-100)"
                  variant="transparentGray"
                  type="email"
                  padding="13px 1.25rem 14px"
                  borderWidth="2px"
                  border="2px solid var(--color-indigo-25)"
                  fontSize="text-md"
                />
                <PasswordInput
                  ref={passwordRef}
                  placeholder={t(LoginKeys.Sign_Up_Form.Placeholder.password)}
                  placeholderColor="var(--color-indigo-100)"
                  variant="transparentGray"
                  padding="13.5px 1.25rem 13.5px"
                  borderWidth="2px"
                  width="100%"
                  border="2px solid var(--color-indigo-25)"
                  fontSize="text-md"
                  style={{
                    minWidth: '100%',
                    marginTop: '0px',
                    maxHeight: '55px',
                  }}
                  iconColor={'var(--color-indigo-25)'}
                />
                <Stack gap="1.25rem">
                  <Stack align="flex-start">
                    <IndigoButton
                      padding="12.2px"
                      radius="0.75rem"
                      className="bh5 text-white shadow-none w-100 h-100"
                      onClick={validateUserInput}
                    >
                      {t(LoginKeys.Login_Form.sign_up)}
                    </IndigoButton>

                    {/* error text */}
                    <ErrorContainer className="h7 text-red-500 text-align-left">
                      {t(error)}
                    </ErrorContainer>
                  </Stack>
                  <TextButton
                    onClick={() => {
                      navigateFlow({ flow: SIGN_IN, method: LOCAL, step: 0 });
                    }}
                    className="h7 w-100"
                  >
                    {t(LoginKeys.Sign_Up_Form.go_back)}
                  </TextButton>
                </Stack>
              </Stack>
            </Stack>
          </ModalBody>
        </AuthModal>
      )}
      {/* Mobile */}
      {isMobile && (
        <MobileContainer className="d-flex flex-column justify-content-center md-hidden">
          {loading()}
          <Container className="md-hidden cursor-pointer">
            <CloseButton onClick={closeModal}>
              <CrossIconRounded />
            </CloseButton>
            <Logo
              onClick={() => {
                closeModal();
                navigate('/');
              }}
            />
          </Container>
          <div className="px-2" style={{ height: '100vh' }}>
            <Stack gap="0.5rem">
              <TextField
                ref={emailMobileRef}
                placeholder={t(GeneralKeys.email)}
                variant="lightGray"
                type="email"
                padding="13px 23px"
                fontSize="h7"
                radius="999px"
                borderWidth="1px"
                shadow="var(--shadow-mobile-text-box)"
                focusOutline="1px solid var(--color-indigo-500)"
              />
              <PasswordInput
                ref={passwordMobileRef}
                placeholder={t(GeneralKeys.password)}
                variant="lightGray"
                padding="13px 23px"
                width="100%"
                fontSize="h7"
                radius="999px"
                borderWidth="1px"
                shadow="var(--shadow-mobile-text-box)"
                iconColor={'var(--color-indigo-25)'}
                focusOutline="1px solid var(--color-indigo-500)"
              />
            </Stack>
            <ErrorContainer>
              <Heading
                variant="H8"
                as="span"
                className="text-red-500"
                style={{ width: '50%' }}
              >
                {t(error)}
              </Heading>
            </ErrorContainer>
            <Buttons isMobile={true} gap="30px">
              <IndigoButton
                type="submit"
                radius="500px"
                padding="11px 0"
                className="bh5 w-100 shadow-none"
                onClick={validateUserInput}
              >
                {t(LoginKeys.Login_Form.sign_up)}
              </IndigoButton>
              <Stack width="100%">
                <Line>
                  <LineText>{t(GeneralKeys.or_upper_case)}</LineText>
                </Line>
              </Stack>
              <Stack gap="0.75rem">
                <SocialSignupSection
                  flowManager={flowManager}
                  setError={setError}
                  setIsLoading={setIsLoading}
                />
              </Stack>
            </Buttons>

            <div className="d-flex justify-content-center">
              <TextButtonM
                isMobile={isMobile}
                onClick={() => {
                  navigateFlow({ flow: SIGN_IN, method: LOCAL, step: 0 });
                }}
              >
                <u>{t(LoginKeys.Sign_Up_Form.go_back)}</u>
              </TextButtonM>
            </div>
          </div>
        </MobileContainer>
      )}
    </>,
    modalRoot.current
  );
};

export default withAuthHOC(SignUpPopup);

const ModalBody = styled.div`
  padding: 2.5rem 3.8125rem 3rem !important;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  text-align: center;
  max-width: 442px;
  box-shadow: var(--shadow-modal);
  border: none;
  border-radius: 1.5rem;
  width: 100%;
  margin: 0 auto;
  background: white;

  &:focus {
    outline: none;
  }
`;

const TextButton = styled.button`
  border: none;
  background: none;
  color: var(--color-indigo-500);
  align-self: flex-start;
  margin: 0;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`;

// MObile
const MobileContainer = styled.div`
  position: fixed;
  top: 0%;
  left: 0;
  width: 100%;
  background-color: var(--color-pink-50);
  z-index: 1015;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ gap }) => gap};
  margin-top: 3.125rem;
  ${({ isMobile }) =>
    isMobile
      ? {
          flexDirection: 'column',
          marginTop: '3.5rem',
        }
      : {}}
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-indigo-100);
  position: relative;
  margin-top: 1rem;
  margin-bottom: 10px;
`;

const LineText = styled.div.attrs({
  className: 'h7 bg-pink-50',
})`
  width: 40px;
  height: max-content;
  text-align: center;
  color: var(--color-indigo-100);
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
`;

const Container = styled.div.attrs({})`
  padding: 1.75rem 0;
  align-self: center;
  height: 80px;
  margin-bottom: 2rem;
`;

const TextButtonM = styled.button.attrs((props) => ({
  className: props.isMobile ? 'h8' : 'h6',
}))`
  background: none;
  border: none;
  padding: 0;
  margin: 1.5rem 0 0 0;
  transition: all 150ms ease-in-out;
  &:hover {
    color: var(--color-indigo-300);
    text-decoration: underline;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  padding: 0.5rem;
`;

const ErrorContainer = styled.div`
  text-align: start;
`;
