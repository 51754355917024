export const ShareIcon = ({ size = 1 }) => (
  <svg
    width={19 * size}
    height={20 * size}
    viewBox="0 0 19 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.0602 14.1365C14.2972 14.1365 13.6145 14.4378 13.0924 14.9096L5.93373 10.743C5.98394 10.512 6.0241 10.2811 6.0241 10.0402C6.0241 9.7992 5.98394 9.56827 5.93373 9.33735L13.012 5.21084C13.5542 5.71285 14.2671 6.0241 15.0602 6.0241C16.7269 6.0241 18.0723 4.67871 18.0723 3.01205C18.0723 1.34538 16.7269 0 15.0602 0C13.3936 0 12.0482 1.34538 12.0482 3.01205C12.0482 3.25301 12.0884 3.48394 12.1386 3.71486L5.06024 7.84137C4.51807 7.33936 3.80522 7.02811 3.01205 7.02811C1.34538 7.02811 0 8.37349 0 10.0402C0 11.7068 1.34538 13.0522 3.01205 13.0522C3.80522 13.0522 4.51807 12.741 5.06024 12.239L12.2088 16.4157C12.1586 16.6265 12.1285 16.8474 12.1285 17.0683C12.1285 18.6847 13.4438 20 15.0602 20C16.6767 20 17.992 18.6847 17.992 17.0683C17.992 15.4518 16.6767 14.1365 15.0602 14.1365Z" />
  </svg>
);
