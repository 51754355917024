import EvoMissionsKeys from '../translationKeys/EvoMissionsPage';

export const EvoMissionsTranslations = {
  [EvoMissionsKeys.claim_evos]: 'Reclamar EVOs',
  [EvoMissionsKeys.my_evos]: 'Mis EVOs',
  [EvoMissionsKeys.special_missions]: 'Misiones Especiales',
  [EvoMissionsKeys.creator_missions]: 'Misiones para Creadores',
  [EvoMissionsKeys.general_missions]: 'Misiones Generales',
  [EvoMissionsKeys.successfully_claimed]: '¡Reclamado exitosamente!',
  [EvoMissionsKeys.go_back_home]: 'Volver a mi perfil',

  // Detalles del Token
  [EvoMissionsKeys.Token_Details._1k]: '1,000',
  [EvoMissionsKeys.Token_Details._10k]: '10,000',
  [EvoMissionsKeys.Token_Details.no_data]: 'No hay datos para mostrar',
  [EvoMissionsKeys.Token_Details.results_found]:
    'Se encontraron un total de {{number}} resultados',
  [EvoMissionsKeys.Token_Details.result_found]:
    'Se encontró un total de {{number}} resultado',

  // Ventana Emergente Lateral
  [EvoMissionsKeys.Side_Popup.text]: '¿Qué es EVO?',

  // Transacción
  [EvoMissionsKeys.Transaction.daily_reward]: 'Recompensas Diarias',
  [EvoMissionsKeys.Transaction.price_unlock_received]:
    'Enviado por el Cliente - Desbloqueo de Precio',
  [EvoMissionsKeys.Transaction.price_unlock_spent]: 'Desbloqueo de Precio',
  [EvoMissionsKeys.Transaction.referral_reward]: 'Recompensa de Referidos',
  [EvoMissionsKeys.Transaction.creator_registration_reward]:
    'Recompensa de Registro de Creador',

  [EvoMissionsKeys.Transaction.complete_1_special_completed]:
    'Completar misiones especiales',
  [EvoMissionsKeys.Transaction.complete_777_special_completed]:
    'Completar misiones especiales',
  [EvoMissionsKeys.Transaction.complete_77_special_completed]:
    'Completar misiones especiales',
  [EvoMissionsKeys.Transaction.complete_7_special_completed]:
    'Completar misiones especiales',
  [EvoMissionsKeys.Transaction.create_premium_package_completed]:
    'Crear un paquete Premium',
  [EvoMissionsKeys.Transaction.first_990_creator_claimed_completed]:
    'Early 990 VIPs',
  [EvoMissionsKeys.Transaction.refer_1000_early_vips_completed]:
    'Referencias tempranas de VIP',
  [EvoMissionsKeys.Transaction.refer_100_early_vips_completed]:
    'Referencias tempranas de VIP',
  [EvoMissionsKeys.Transaction.refer_10_early_vips_completed]:
    'Referencias tempranas de VIP',
  [EvoMissionsKeys.Transaction.referral_ranking_completed]:
    'Clasificación de referencias VIP',
  [EvoMissionsKeys.Transaction.special_daily_reward_completed]:
    'Recompensa diaria',
  [EvoMissionsKeys.Transaction.tv_easter_egg_completed]:
    'Encuentra el huevo de Pascua en la plataforma',
  [EvoMissionsKeys.Transaction.update_creator_profile_completed]:
    'Actualizar perfil de creador',
  [EvoMissionsKeys.Transaction.update_profile_completed]: 'Actualizar perfil',
  [EvoMissionsKeys.Transaction.visit_main_tabs_completed]:
    'Visitar todas las pestañas principales',

  // Misión Especial
  // Early 990 VIPs
  [EvoMissionsKeys.Special.early_990_vips.title]: 'Early 990 VIPs',
  [EvoMissionsKeys.Special.early_990_vips.description]:
    '¡Eres nuestro VIP influencer temprano! Regístrate como creador ahora y desbloquea nuestro pase premium. ¡Asegura tu lugar!',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d1]:
    '¡Obtén pedidos sin tarifas de servicio de plataforma',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d2]: 'con este Pase Premium!',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d3]: '',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    ' *Pueden aplicarse tarifas de terceros como PayPal y tarjeta de crédito.',

  // General
  [EvoMissionsKeys.complete_1_special.title]: 'Completa Misiones Especiales',
  [EvoMissionsKeys.complete_1_special.description]:
    'Obtén un pequeño bono por completar tus misiones especiales',
  [EvoMissionsKeys.complete_1_special.extra_reward]: '',
  [EvoMissionsKeys.complete_1_special.reward_description]: '',

  [EvoMissionsKeys.complete_777_special.title]: 'Completa Misiones Especiales',
  [EvoMissionsKeys.complete_777_special.description]:
    'Obtén un pequeño bono por completar tus misiones especiales',
  [EvoMissionsKeys.complete_777_special.extra_reward]: '',
  [EvoMissionsKeys.complete_777_special.reward_description]: '',

  [EvoMissionsKeys.complete_77_special.title]: 'Completa Misiones Especiales',
  [EvoMissionsKeys.complete_77_special.description]:
    'Obtén un pequeño bono por completar tus misiones especiales',
  [EvoMissionsKeys.complete_77_special.extra_reward]: '',
  [EvoMissionsKeys.complete_77_special.reward_description]: '',

  [EvoMissionsKeys.complete_7_special.title]: 'Completa Misiones Especiales',
  [EvoMissionsKeys.complete_7_special.description]:
    'Obtén un pequeño bono por completar tus misiones especiales',
  [EvoMissionsKeys.complete_7_special.extra_reward]: '',
  [EvoMissionsKeys.complete_7_special.reward_description]: '',

  [EvoMissionsKeys.tv_easter_egg.title]:
    '¡Encuentra el huevo de Pascua en la plataforma!',
  [EvoMissionsKeys.tv_easter_egg.description]:
    'Encuentra el Huevo de Pascua en la página de inicio. Está en algún lugar',
  [EvoMissionsKeys.tv_easter_egg.extra_reward]: '',
  [EvoMissionsKeys.tv_easter_egg.reward_description]: '',

  [EvoMissionsKeys.update_profile.title]: 'Actualiza tu Perfil',
  [EvoMissionsKeys.update_profile.description]:
    '¡Únete a nosotros en este viaje! Actualiza tu perfil y desbloquea esta recompensa.',
  [EvoMissionsKeys.update_profile.extra_reward]: '',
  [EvoMissionsKeys.update_profile.reward_description]: '',

  [EvoMissionsKeys.visit_main_tabs.title]:
    'Visita Todas las Pestañas Principales',
  [EvoMissionsKeys.visit_main_tabs.description]:
    'Visita las cuatro pestañas de nuestra barra de navegación superior para desbloquear esta recompensa. ¡Descubre cosas nuevas con nosotros!',
  [EvoMissionsKeys.visit_main_tabs.extra_reward]: '',
  [EvoMissionsKeys.visit_main_tabs.reward_description]: '',

  // Creator
  [EvoMissionsKeys.create_premium_package.title]: 'Crea un Paquete Premium',
  [EvoMissionsKeys.create_premium_package.description]:
    'Crea un paquete premium en tu perfil. ¡Usa nuestra plantilla o las funciones de ChatGPT para mostrar tus mejores servicios!',
  [EvoMissionsKeys.create_premium_package.extra_reward]: '',
  [EvoMissionsKeys.create_premium_package.reward_description]: '',

  [EvoMissionsKeys.update_creator_profile.title]:
    'Actualiza el Perfil de Creador',
  [EvoMissionsKeys.update_creator_profile.description]:
    '¿Listo para convertirte en una estrella global? Actualiza tu perfil y ¡permite que el mundo conozca tu increíble canal!',
  [EvoMissionsKeys.update_creator_profile.extra_reward]: '',
  [EvoMissionsKeys.update_creator_profile.reward_description]: '',

  // Special
  [EvoMissionsKeys.first_990_creator_claimed.title]:
    '¡Creador VIP 990 temprano!',
  [EvoMissionsKeys.first_990_creator_claimed.description]:
    '¡Bienvenido como Creador Temprano! Completa tu perfil y colabora con marcas globales.',
  [EvoMissionsKeys.first_990_creator_claimed.extra_reward]:
    'Pase de Creador Premium',
  [EvoMissionsKeys.first_990_creator_claimed.reward_description]:
    'Los creadores con pase premium están exentos de todas las tarifas de servicio de la plataforma durante 2 años completos después del lanzamiento oficial.',

  [EvoMissionsKeys.refer_1000_early_vips.title]: 'Referencias VIP Tempranas',
  [EvoMissionsKeys.refer_1000_early_vips.description]:
    '¡Refiere a 1000 o más personas para reclamar esta recompensa!',
  [EvoMissionsKeys.refer_1000_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_1000_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_100_early_vips.title]: 'Referencias VIP Tempranas',
  [EvoMissionsKeys.refer_100_early_vips.description]:
    '¡Refiere a 100 o más personas para reclamar esta recompensa!',
  [EvoMissionsKeys.refer_100_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_100_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_10_early_vips.title]: 'Referencias VIP Tempranas',
  [EvoMissionsKeys.refer_10_early_vips.description]:
    '¡Refiere a 10 o más personas para reclamar esta recompensa!',
  [EvoMissionsKeys.refer_10_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_10_early_vips.reward_description]: '',

  [EvoMissionsKeys.referral_ranking.title]: 'Clasificación de Referencias VIP',
  [EvoMissionsKeys.referral_ranking.description]:
    'Por cada percentil en la clasificación de referencias, recibirás puntos EVO adicionales al final del mes.',
  [EvoMissionsKeys.referral_ranking.extra_reward]: '',
  [EvoMissionsKeys.referral_ranking.reward_description]: '',

  [EvoMissionsKeys.special_daily_reward.title]: 'Recompensa Especial Diaria',
  [EvoMissionsKeys.special_daily_reward.description]:
    'Ven y reclama tu recompensa diaria todos los días durante una semana.',
  [EvoMissionsKeys.special_daily_reward.extra_reward]: '',
  [EvoMissionsKeys.special_daily_reward.reward_description]: '',

  [EvoMissionsKeys.Modal.already_unlock]: 'Ya has desbloqueado',
  [EvoMissionsKeys.Modal.easter_egg]: 'este Huevo de Pascua!',
  [EvoMissionsKeys.Modal.unlock_premium_pass]:
    'Has desbloqueado un pase premium.',
  [EvoMissionsKeys.Modal.visit_profile]: 'Visitar Mi Perfil',
  [EvoMissionsKeys.Modal.not_now]: 'Omitir',
};
