import FacebookLogin from 'react-facebook-login';
import SocialSignInButton from '../common/SocialSignInButton';

const FacebookLoginButton = ({ responseCallback, context }) => {
  return (
    <SocialSignInButton type="facebook" context={context}>
      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        callback={responseCallback}
        disableMobileRedirect={true}
      />
    </SocialSignInButton>
  );
};

export default FacebookLoginButton;
