import withAuthHOC from 'components/components/auth/withAuthHOC';
import styled from 'styled-components';
import AuthModal from './AuthModal';
import IconButton from 'components/Basics/Buttons/IconButton';
import Logo from 'components/Basics/Layout/Logo';
import Image from 'components/Basics/Image';
import { useNavigate } from 'react-router-dom';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import device from 'styles/device';
import { useTranslation } from 'react-i18next';
import CreatorRegistrationKeys from 'translations/translationKeys/CreatorRegistrationPageKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';

const WelcomeModal = ({ flowManager }) => {
  const { forwardStep, closeModal } = flowManager;
  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <AuthModal show={true} onClose={() => {}}>
      <ContentWrapper>
        <IconButton to="/" className="align-self-center pb-1 md-hidden">
          <Logo />
        </IconButton>
        <RegisterType onClick={forwardStep}>
          <Image
            src="/img/misc/register_type_client.png"
            width={isMobile ? '84px' : '300px'}
            height={isMobile ? '84px' : '300px'}
          />
          <h2 className={isMobile ? 'bh4' : 'bh1'}>
            {t(LoginKeys.Modal.Register_type.buyer.title)}
          </h2>
          <h4 className={isMobile ? 'mh14' : 'h4.5'}>
            {t(LoginKeys.Modal.Register_type.buyer.description)}
          </h4>
        </RegisterType>
        <Divider />
        <RegisterType
          onClick={() => {
            closeModal();
            navigate('/creator-registration');
          }}
        >
          <Image
            src="/img/misc/register_type_creator.png"
            width={isMobile ? '84px' : '300px'}
            height={isMobile ? '84px' : '300px'}
          />
          <h2 className={isMobile ? 'bh4' : 'bh1'}>
            {t(CreatorRegistrationKeys.Topbar.title)}
          </h2>
          <h4 className={isMobile ? 'mh14' : 'h4.5'}>
            {t(LoginKeys.Modal.Register_type.creator.description)}
          </h4>
        </RegisterType>
        <SkipButton className={isMobile ? 'mh13' : 'h6'} onClick={closeModal}>
          {t(LoginKeys.Modal.Register_type.skip)}
        </SkipButton>
      </ContentWrapper>
    </AuthModal>
  );
};

export default withAuthHOC(WelcomeModal);

const ContentWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 4.5rem;
  padding: 5.25rem 6rem 4.5rem;
  position: relative;
  border-radius: 1rem;
  background: url('/img/misc/welcome-modal-bg.png') white no-repeat center;

  @media ${device.sm} {
    background: var(--color-white);
    flex-direction: column;
    justify-content: flex-start;
    border-radius: 0;
    gap: 1.5rem;
    min-height: 100dvh;
    min-width: 100dvw;
    padding: 1.75rem 1rem 0;
  }
`;

const Divider = styled.div`
  width: 2px;
  min-height: 456px;
  height: 100%;
  background: var(--color-indigo-25);

  @media ${device.sm} {
    display: none;
  }
`;

const RegisterType = styled.div.attrs({
  className: 'text-center',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  max-width: 440px;
  cursor: pointer;
  transition: opacity 150ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  @media ${device.sm} {
    background: url('/img/misc/welcome-modal-bg-mobile.jpg') no-repeat;
    background-size: cover;
    gap: 0.5rem;
    border-radius: 1.25rem;
    box-shadow: var(--shadow-card);
    width: 100%;
    max-width: 100%;
    padding: 1.5rem 1.25rem 1.25rem;
  }
`;

const SkipButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  text-decoration: underline;
  align-self: center;
  color: var(--color-indigo-100);
  transition: opacity 100ms ease-in-out;

  :hover {
    opacity: 0.65;
  }

  @media ${device.md} {
    color: var(--color-indigo-100);
    position: absolute;
    top: 2.5rem;
    right: 2.5rem;
    z-index: 2;
  }
`;
