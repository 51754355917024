import FAQKeys from '../translationKeys/FAQPageKeys';

export const FAQTranslations = {
  // General
  [FAQKeys.General.title]: '일반',

  [FAQKeys.General.Q1.title]: 'MegaEvolution을 시작하는 방법은 무엇인가요?',
  [FAQKeys.General.Q1.description]:
    'MegaEvolution과 함께 인플루언서 마케팅 여정을 시작하려면, 데스크톱이나 모바일 브라우저에서 megaevolution.io 웹사이트를 방문하세요. "로그인" 버튼을 클릭한 후 개인 이메일, Google, Facebook 또는 지갑(MetaMask 또는 Coinbase Wallet)을 사용하여 가입할 수 있습니다. 이용 약관에 동의하신 후 필요한 경우 이메일을 부탁드립니다. 등록 후, 인플루언서와 손쉽게 연결하고 제품이나 서비스를 넓은 관객에게 홍보할 수 있습니다. MegaEvolution 플랫폼은 크리에이터 채널/시청자 분석 및 통합된 주문 및 결제 시스템과 같은 고급 기능을 제공하여 비즈니스가 효과적인 인플루언서 캠페인을 시작할 수 있도록 돕습니다.',

  [FAQKeys.General.Q2.title]: 'MegaEvolution이란 무엇인가요?',
  [FAQKeys.General.Q2.description]:
    'MegaEvolution은 클라이언트와 글로벌 크리에이터를 연결하여 제품과 서비스를 홍보하는 인플루언서 마케팅 플랫폼입니다. 클라이언트는 쉽게 마케팅 패키지를 찾고 주문할 수 있으며, 크리에이터는 자신을 홍보하고 더 많은 기회와 수익을 창출할 수 있습니다. MegaEvolution은 주문부터 광고 관리까지 모든 과정을 간편하게 처리하여 모든 사용자에게 편리한 경험을 제공합니다.',

  [FAQKeys.General.Q3.title]:
    'MegaEvolution은 크리에이터에게 어떤 지원을 제공하나요?',
  [FAQKeys.General.Q3.description]:
    'MegaEvolution은 크리에이터가 전 세계의 브랜드 클라이언트와 연결할 수 있도록 돕는 역할을 하고 있습니다. MegaEvolution을 통해 자신의 서비스를 홍보하는 크리에이터는 쉽게 클라이언트로부터 주문을 받을 수 있으며, 우리는 주문 및 결제 과정 전체를 지원하여 크리에이터가 기회와 수익을 극대화할 수 있도록 돕습니다.',

  [FAQKeys.General.Q4.title]:
    'MegaEvolution은 클라이언트에게 어떤 지원을 제공하나요?',
  [FAQKeys.General.Q4.description]:
    'MegaEvolution은 다양한 크리에이터들과 클라이언트를 연결하여 채널 분석 및 마케팅 가격과 같은 정보에 접근할 수 있도록 제공합니다. 클라이언트는 클릭 한 번으로 크리에이터로부터 마케팅 패키지를 주문할 수 있으며, 주문 및 결제 과정 전체를 안전하고 편리하게 지원합니다.',

  [FAQKeys.General.Q5.title]: 'EVO 토큰은 무엇이며 어떻게 작동하나요?',
  [FAQKeys.General.Q5.description]:
    'EVO 토큰은 크리에이터 패키지 가격에 접근할 수 있게 해주며, 곧 이벤트, 투표 및 거버넌스에 사용될 수도 있습니다. 우리는 다양한 거래소에 상장할 계획이며, 다른 코인이나 현금으로 교환할 수 있도록 할 예정입니다.',

  // Package System
  [FAQKeys.Package_System.title]: '패키지 시스템',

  [FAQKeys.Package_System.Q1.title]: 'MegaEvolution의 패키지란 무엇인가요?',
  [FAQKeys.Package_System.Q1.description]:
    'MegaEvolution의 패키지는 크리에이터가 제공할 콘텐츠, 지속 시간, 가격 및 클라이언트에게 제공될 방식에 대한 자세한 설명을 포함하여 자신의 서비스를 소개하는 시스템입니다. 사용자는 몇 번의 클릭으로 이러한 패키지를 쉽게 구매할 수 있으며, 대시보드를 통해 모든 주문을 관리할 수 있습니다. 또한, 사용자는 패키지 주문의 상태에 대한 알림과 분석 정보를 받게 됩니다.',

  [FAQKeys.Package_System.Q2.title]: '패키지의 가격은 어떤 유형이 있나요?',
  [FAQKeys.Package_System.Q2.description]:
    '패키지의 가격 옵션으로는 "개방형 가격(Open Price)", "잠금 해제형 가격(Unlockable Price)", "크리에이터 문의형(Contact Creator)"의 세 가지 유형이 있습니다. 크리에이터는 각 패키지 계획마다 다른 가격 옵션을 설정할 수 있습니다. 예를 들어, 표준 계획은 개방형 가격으로 설정되고 프리미엄 계획은 잠금 해제형 가격으로 설정될 수 있습니다.',

  [FAQKeys.Package_System.Q3.title]:
    '가격 해제 시스템은 어떻게 작동하며, 해제된 가격은 얼마 동안 표시되나요?',
  [FAQKeys.Package_System.Q3.description]:
    '모든 사용자는 매월 일정 횟수만큼 무료로 가격 해제를 할 수 있습니다. 크리에이터의 패키지 가격을 해제하려면 잠긴 가격을 클릭하시면 됩니다. 해제되면 해당 크리에이터의 모든 가격을 볼 수 있습니다. 해제된 가격은 3일 동안 표시됩니다.',

  // Creators
  [FAQKeys.Creators.title]: '크리에이터',

  [FAQKeys.Creators.Q1.title]:
    '크리에이터를 위한 서비스 수수료에 대해 알고 싶습니다.',
  [FAQKeys.Creators.Q1.description]:
    '현재, 각 광고 주문에 대해 크리에이터에게 수수료를 부과하지 않고 있습니다.',

  [FAQKeys.Creators.Q2.title]: '잔고를 인출하는 데 얼마나 걸릴까요?',
  [FAQKeys.Creators.Q2.description]:
    '일반적으로 PayPal을 통한 이체는 1~3 영업일이 소요됩니다.',

  [FAQKeys.Creators.Q3.title]:
    '크리에이터로 등록한 후에 모든 소셜 네트워크 계정과 연결을 해제했습니다. 계정은 자동으로 삭제되나요?',
  [FAQKeys.Creators.Q3.description]:
    '계정은 자동으로 삭제되지 않습니다. 그러나 주문을 받을 수 없게 될 것입니다. 또한, 검색 창에서 표시되지 않을 것입니다.',

  [FAQKeys.Creators.Q4.title]:
    '클라이언트가 주문을 수락한 후에 주문을 취소할 수 있나요?',
  [FAQKeys.Creators.Q4.description]:
    '크리에이터가 주문을 수락한 후에는 양측이 취소에 동의하는 경우에만 취소가 가능합니다. 그러나 만약 크리에이터가 마감일까지 콘텐츠를 제공하지 못하거나 콘텐츠의 품질이 매우 불만족스럽거나 설명과 다른 경우에는 주문을 분쟁 처리를 통해 취소할 수 있습니다. 주문을 취소하는 것은 계정에 부정적인 영향을 미칠 수 있으며, MegaEvolution의 크리에이터로서의 평판에도 영향을 줄 수 있습니다.',

  // Orders Help
  [FAQKeys.Orders_Help.title]: '주문 도움말',

  [FAQKeys.Orders_Help.Q1.title]: '연장과 취소는 어떻게 이루어지나요?',
  [FAQKeys.Orders_Help.Q1.description]:
    '주문의 마감일을 연장하거나 취소하려면 상대방에게 요청을 보내야 합니다. 받은 요청에 대해 수락하거나 거절할 수 있습니다. 요청에 대한 응답이 일정 기간(연장의 경우 3일, 취소의 경우 5일) 이내에 이루어지지 않으면 요청은 자동으로 수락됩니다.',

  [FAQKeys.Orders_Help.Q2.title]:
    '크리에이터가 주문을 수락한 후에 주문을 취소할 수 있나요?',
  [FAQKeys.Orders_Help.Q2.description]:
    '일반적으로, 클라이언트가 주문을 수락한 후에는 크리에이터가 동의하는 경우에만 취소가 가능합니다. 그러나 만약 납기일을 지키지 않거나 품질이 불만족스러운 경우, 분쟁 처리를 통해 취소할 수 있습니다.',

  [FAQKeys.Orders_Help.Q3.title]: '콘텐츠를 받지 못한 경우 어떻게 해야 하나요?',
  [FAQKeys.Orders_Help.Q3.description]:
    '크리에이터는 각 주문된 콘텐츠의 링크를 보내야 합니다. 만약 마감일까지 콘텐츠를 받지 못한 경우, 취소를 요청하고 환불을 받을 수 있습니다.',

  // Payment Help
  [FAQKeys.Payment_Help.title]: '결제 도움말',

  [FAQKeys.Payment_Help.Q1.title]: '결제 내역을 어떻게 확인하나요?',
  [FAQKeys.Payment_Help.Q1
    .description]: `1. 계정에 로그인하고 웹 사이트의 오른쪽 상단에 있는 '마이 스페이스'를 클릭합니다. 그다음 '청구' 버튼을 클릭합니다.
2."결제 내역" 탭을 사용하여 거래 내역을 확인할 수 있습니다. 또한 PDF 형식으로 청구서를 다운로드할 수 있습니다.`,

  [FAQKeys.Payment_Help.Q2.title]: '어떻게 결제할 수 있나요?',
  [FAQKeys.Payment_Help.Q2
    .description]: `1. 페이지의 오른쪽 상단에 있는 '마이 스페이스'로로 이동합니다. 그다음 '청구'로 이동합니다.
2. '결제 수단' 탭에서 '신용 및 직불 카드' 또는 'PayPal 계정'을 추가합니다.
3. 그다음 추가한 정보를 결제에 사용할 수 있습니다.`,

  [FAQKeys.Payment_Help.Q3.title]:
    'MegaEvolution은 어떻게 클라이언트에게 환불을 제공하나요?',
  [FAQKeys.Payment_Help.Q3.description]:
    '크리에이터 주문의 경우, 취소 시 전액 환불이 이루어집니다. 이미 게시된 콘텐츠가 있는 경우 환불 전에 비례적인 차감이 이루어집니다. 월간 자동 청구 플랫폼 구독에 대해서는 환불이 이루어지지 않습니다.',

  [FAQKeys.Payment_Help.Q4.title]:
    'MegaEvolution을 사용하는 데 필요한 서비스 수수료는 얼마이며 작동 방식은 어떻게 되나요?',
  [FAQKeys.Payment_Help.Q4.description]:
    'MegaEvolution은 각 패키지 주문에 대해 5.5% + $3의 서비스 수수료를 부과합니다. 우리는 업계에서 가장 경쟁력 있는 서비스 수수료를 제공합니다. 이 작은 수수료는 플랫폼 운영 및 각 주문 프로세스 지원에 도움을 줍니다. 구독 사용자인 경우, 서비스 수수료에 할인이 적용됩니다.',

  [FAQKeys.Payment_Help.Q5.title]: '어떤 종류의 구독 요금제가 제공되나요?',
  [FAQKeys.Payment_Help.Q5.description]:
    '월간 구독 요금제로는 Basic(무료), Standard, Premium 3 종류가 있습니다. Basic은 대부분의 플랫폼 기능에 액세스할 수 있으며, Standard와 Premium은 가격 해제, 분석, 대량 메시징과 같은 추가 기능이 포함됩니다. 유료 요금제는 주문에 대한 서비스 수수료에 소량의 할인 혜택도 제공합니다.',

  // Account Help
  [FAQKeys.Account_Help.title]: '계정 도움말',

  [FAQKeys.Account_Help.Q1.title]:
    '로그인 정보(ID 또는 비밀번호)를 기억할 수 없는 경우 어떻게 해야 하나요?',
  [FAQKeys.Account_Help.Q1.description]:
    'MegaEvolution에서 계정을 만들기 위해 이메일, Google, Facebook 또는 지갑(MetaMask 또는 Coinbase Wallet)을 사용할 수 있습니다. 이메일 계정의 비밀번호를 잊은 경우, 로그인 페이지로 이동하여 "비밀번호를 잊으셨나요?"를 클릭하세요. 그다음, 이메일을 입력하면 비밀번호를 재설정할 수 있는 링크가 포함된 이메일을 받게 됩니다.',

  [FAQKeys.Account_Help.Q2.title]: '비밀번호를 변경하는 방법은 무엇인가요?',
  [FAQKeys.Account_Help.Q2
    .description]: `1. 로그인한 후 페이지의 오른쪽 상단에 있는 '마이 스페이스'로 이동하세요. 
  2. '설정'을 클릭하고 '계정' 섹션에서 새로운 비밀번호를 업데이트하세요.`,

  [FAQKeys.Account_Help.Q3.title]:
    '계정을 비활성화하거나 삭제하는 방법은 무엇인가요?',
  [FAQKeys.Account_Help.Q3
    .description]: `1. 로그인한 후 페이지의 오른쪽 상단에 있는 '마이 스페이스'로 이동하세요. 
2. '설정'을 클릭하고 아래로 스크롤하여 '비활성화/삭제' 섹션으로 이동하세요. 
3. '비활성화' 또는 '삭제' 버튼을 클릭하세요. (계정을 삭제한 후에는 모든 데이터가 손실되며 복원할 수 없습니다.)`,

  // Safety & Privacy
  [FAQKeys.Safety_Privacy.title]: '개인정보 보호',

  [FAQKeys.Safety_Privacy.Q1.title]: '내 정보는 어떻게 보호되나요?',
  [FAQKeys.Safety_Privacy.Q1.description]:
    'MegaEvolution에서는 개인정보와 보안을 매우 중요하게 생각합니다. 우리는 권한 없는 접근이나 남용으로부터 개인정보를 보호하기 위해 산업 표준 수준의 조치를 채택하고 있습니다. 개인정보 보호에 대한 저희의 약속에 대해 자세히 알아보려면 개인정보 처리방침을 참조하세요.',

  [FAQKeys.Safety_Privacy.Q2.title]:
    'MegaEvolution은 어떤 종류의 데이터를 보관하나요?',
  [FAQKeys.Safety_Privacy.Q2.description]:
    'MegaEvolution은 주문 기록, 결제 정보, 사용자 데이터, 프로필, 패키지 정보, 기술 로그 정보 등 다양한 중요 데이터를 사용자들을 위해 보관합니다.',

  [FAQKeys.Safety_Privacy.Q3.title]:
    '계정을 비활성화하거나 삭제하는 방법은 무엇인가요?',
  [FAQKeys.Safety_Privacy.Q3
    .description]: `1. 로그인한 후 페이지의 오른쪽 상단에 있는 '마이 스페이스'로 이동하세요. 
2. '설정'을 클릭하고 아래로 스크롤하여 '비활성화/삭제' 섹션으로 이동하세요. 
3. '비활성화' 또는 '삭제' 버튼을 클릭하세요. (계정을 삭제한 후에는 모든 데이터가 손실되며 복원할 수 없습니다.)`,
};
