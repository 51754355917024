import FilterKeys from '../translationKeys/FilterKeys';

export const FilterTranslations = {
  // Influencer Marketing Home Page
  [FilterKeys.home.search_keywords]: 'ค้นหาคีย์เวิร์ด',

  [FilterKeys.home.expand]: 'ขยาย',
  [FilterKeys.home.collapse]: 'ย่อ',

  [FilterKeys.home.more_filter]: '＋ ขยายฟิลเตอร์',
  [FilterKeys.home.less_filter]: '– ย่อฟิลเตอร์',

  [FilterKeys.home.try_searching]: 'ลองค้นหา',
  [FilterKeys.home.results_for_keyword]:
    '{{number}} ผลลัพธ์สำหรับ "{{searchText}}"',
  [FilterKeys.home.result_for_keyword]:
    '{{number}} ผลลัพธ์สำหรับ "{{searchText}}"',

  // Try Searching
  [FilterKeys.home.Try_Searching.brand_ambassador]: 'ตัวแทนของแบรนด์',
  [FilterKeys.home.Try_Searching.live_shows]: 'Live สด',
  [FilterKeys.home.Try_Searching.product_review]: 'รีวิวสินค้า',
  [FilterKeys.home.Try_Searching.game_review]: 'รีวิวเกม',

  // Sort By
  [FilterKeys.home.sort_by.suggested]: 'แนะนำ',
  [FilterKeys.home.sort_by.by_subscribers]: 'จำนวนผู้ติดตาม',
  [FilterKeys.home.sort_by.lowest_price]: 'ราคาต่ำสุด',
  [FilterKeys.home.sort_by.highest_price]: 'ราคาสูงสุด',

  // Country
  [FilterKeys.home.country.popular]: 'ประเทศยอดนิยม',
  [FilterKeys.home.country.selected]: 'ประเทศที่เลือก',
  [FilterKeys.home.country.no_result]: 'ไม่พบค้นหา',

  // Price
  [FilterKeys.home.price._50]: '-$50',
  [FilterKeys.home.price._50_100]: '$50-100',
  [FilterKeys.home.price._100_300]: '$100-300',
  [FilterKeys.home.price._300_500]: '$300-500',
  [FilterKeys.home.price._500_1k]: '$500-1K',
  [FilterKeys.home.price._1k_2k]: '$1K-2K',
  [FilterKeys.home.price._2k_5k]: '$2K-5K',
  [FilterKeys.home.price._5k_]: '$5K+',

  // Subscribers
  [FilterKeys.home.subs_and_views.tooltip_subs]: 'จำนวนสมาชิกหรือผู้ติดตาม',
  [FilterKeys.home.subs_and_views.tooltip_views]: `
    ค่าเฉลี่ยจำนวนการดูหรือการแสดงผล <br>
    ที่คุณคาดหวังจะได้ต่อโพสต์ในระยะเวลา 180 วัน`,

  [FilterKeys.home.subs_and_views.private]: 'ความเป็นส่วนตัว',
  [FilterKeys.home.subs_and_views._10k]: '-10K',
  [FilterKeys.home.subs_and_views._10k_100k]: '10K-100K',
  [FilterKeys.home.subs_and_views._100k_1m]: '100K-1M',
  [FilterKeys.home.subs_and_views._1m_]: '1M+',

  // Gender
  [FilterKeys.home.gender.male]: 'เพศชาย',
  [FilterKeys.home.gender.female]: 'เพศหญิง',

  // Mobile
  [FilterKeys.home.M.filter_by]: 'กรองตาม:',
  [FilterKeys.home.M.all_categories]: 'หมวดหมู่ทั้งหมด',
  [FilterKeys.home.M.all_price_ranges]: 'ราคาทั้งหมด',
};
