import Stack from 'components/Basics/Layout/Stack';
import LazyImage from 'components/Basics/LazyImage';
import PlatformIcon from 'components/Basics/PlatformIcon';
import OrderState from 'components/components/Orders/Common/OrderState';
import orderState from 'constants/orderState';
import UserType from 'constants/userType';
import { motion } from 'framer-motion';
import useUser from 'hooks/useUser';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const OrderItem = ({
  id,
  featuredImage,
  minFeaturedImage,
  title,
  platform,
  userName,
  status,
  closeMenu,
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentType } = useUser();

  return (
    <Wrapper
      layoutId={`order-${id}`}
      onClick={() => {
        closeMenu();

        if (
          status === orderState.PROPOSAL_REQUIRED &&
          pathname !== `/submit-proposal/${id}`
        ) {
          return navigate(`/submit-proposal/${id}`);
        }

        if (pathname !== `/orders/${id}`) {
          return navigate(`/orders/${id}`);
        }
      }}
    >
      <LazyImage
        src={featuredImage}
        lazySrc={minFeaturedImage}
        alt="Package"
        width="112px"
        height="63px"
        radius="0.25rem"
        className="flex-shrink-0"
      />

      <Stack gap="0.375rem" className="flex-1">
        <Title>{title}</Title>

        <Stack direction="row" align="center" justify="space-between" gap="0">
          <Stack direction="row" align="center" gap="0.5rem">
            <PlatformIcon variant="size5" platform={platform} />

            <span className="sbh8 text-indigo-100">
              {currentType === UserType.Creator ? 'Ordered' : ''} by {userName}
            </span>
          </Stack>

          <OrderState state={status} variant="text" />
        </Stack>
      </Stack>
    </Wrapper>
  );
};

export default OrderItem;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
})`
  width: 100%;
  padding: 1rem;
  padding-bottom: calc(1rem - 1px);
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border-bottom: 1px solid var(--color-indigo-25);
  background: #fff;
  transition: background 150ms ease-in-out;

  &:hover {
    background: var(--color-pink-75);
  }
`;

const Title = styled.p.attrs({
  className: 'h8',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  line-height: 150%;
  height: calc(1.5em * 2);
`;
