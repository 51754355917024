export const CardIcon = ({ size = 1, className, ...rest }) => (
  <svg
    width={18 * size}
    height={14 * size}
    viewBox="0 0 18 14"
    className={className}
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M4.5 9.25H7.2C7.43869 9.25 7.66761 9.15518 7.8364 8.9864C8.00518 8.81761 8.1 8.58869 8.1 8.35C8.1 8.1113 8.00518 7.88239 7.8364 7.7136C7.66761 7.54482 7.43869 7.45 7.2 7.45H4.5C4.2613 7.45 4.03239 7.54482 3.8636 7.7136C3.69482 7.88239 3.6 8.1113 3.6 8.35C3.6 8.58869 3.69482 8.81761 3.8636 8.9864C4.03239 9.15518 4.2613 9.25 4.5 9.25ZM15.3 0.25H2.7C1.98392 0.25 1.29716 0.534464 0.790812 1.04081C0.284464 1.54716 0 2.23392 0 2.95V11.05C0 11.7661 0.284464 12.4528 0.790812 12.9592C1.29716 13.4655 1.98392 13.75 2.7 13.75H15.3C16.0161 13.75 16.7028 13.4655 17.2092 12.9592C17.7155 12.4528 18 11.7661 18 11.05V2.95C18 2.23392 17.7155 1.54716 17.2092 1.04081C16.7028 0.534464 16.0161 0.25 15.3 0.25ZM16.2 11.05C16.2 11.2887 16.1052 11.5176 15.9364 11.6864C15.7676 11.8552 15.5387 11.95 15.3 11.95H2.7C2.46131 11.95 2.23239 11.8552 2.0636 11.6864C1.89482 11.5176 1.8 11.2887 1.8 11.05V5.65H16.2V11.05ZM16.2 3.85H1.8V2.95C1.8 2.71131 1.89482 2.48239 2.0636 2.3136C2.23239 2.14482 2.46131 2.05 2.7 2.05H15.3C15.5387 2.05 15.7676 2.14482 15.9364 2.3136C16.1052 2.48239 16.2 2.71131 16.2 2.95V3.85Z" />
  </svg>
);
