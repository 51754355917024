import IndigoButton from 'components/Basics/Buttons/IndigoButton';
import BorderedCard from 'components/Basics/Cards/BorderedCard';
import Image from 'components/Basics/Image';
import Stack from 'components/Basics/Layout/Stack';
import Heading from 'components/Basics/Typography/Heading';
import withAuthHOC from 'components/components/auth/withAuthHOC';
import { useNavigate } from 'react-router-dom';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import AuthModal from './AuthModal';
import { useTranslation } from 'react-i18next';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const ThankyouModal = ({ flowManager }) => {
  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();
  const { forwardStep, closeModal } = flowManager;
  const { t } = useTranslation();

  const nextStep = () => {
    forwardStep();
    closeModal();
    navigate('/');
  };

  return (
    <AuthModal show={true} onClose={nextStep}>
      {!isMobile && (
        <BorderedCard
          color="blue"
          borderWidth="0px"
          padding="5.25rem 3.5rem 3.75rem"
          shadow="var(--shadow-modal)"
          width="474px"
          className="flex-shrink-0 hidden md-block"
        >
          <Stack align="center" gap="3rem">
            <Stack align="center" gap="3.125rem">
              <Image
                src={process.env.PUBLIC_URL + '/img/misc/thank_you.png'}
                width="200px"
                alt=""
              />
              <Heading
                variant="BH1"
                style={{ fontSize: '28px' }}
                className="m-0 text-center"
              >
                {t(LoginKeys.Sign_Up_Form.password_updated)}
              </Heading>
            </Stack>
            <IndigoButton
              radius="999px"
              padding="0.875rem 37px"
              minWidth="max-content"
              className="sbh4 shadow-none"
              onClick={nextStep}
            >
              {t(GeneralKeys.continue)}
            </IndigoButton>
          </Stack>
        </BorderedCard>
      )}

      {/* mobile */}
      {isMobile && (
        <>
          <Stack
            align="center"
            gap="20px"
            className="md-hidden bg-white"
            bg="white"
            padding="50px 60px 40px"
            radius="30px"
          >
            <Image
              src={process.env.PUBLIC_URL + '/img/misc/thank_you.png'}
              width="150px"
              alt=""
            />
            <Stack align="center" gap="30px">
              <Heading variant="BH4" className="m-0 text-center">
                {t(LoginKeys.Sign_Up_Form.password_updated)}
              </Heading>
              <IndigoButton
                padding="0.625rem 32px"
                minWidth="max-content"
                className="bh5 shadow-none text-white"
                radius="100px"
                onClick={nextStep}
              >
                {t(GeneralKeys.continue)}
              </IndigoButton>
            </Stack>
          </Stack>
        </>
      )}
    </AuthModal>
  );
};

export default withAuthHOC(ThankyouModal);