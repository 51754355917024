export const ExclamationSolidIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 24}
    height={size * 24}
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.6 0 12 0ZM12 18C11.28 18 10.8 17.52 10.8 16.8C10.8 16.08 11.28 15.6 12 15.6C12.72 15.6 13.2 16.08 13.2 16.8C13.2 17.52 12.72 18 12 18ZM13.2 12C13.2 12.72 12.72 13.2 12 13.2C11.28 13.2 10.8 12.72 10.8 12V7.2C10.8 6.48 11.28 6 12 6C12.72 6 13.2 6.48 13.2 7.2V12Z" />
  </svg>
);
