import AboutKeys from '../translationKeys/AboutPageKeys';

export const AboutPageTranslations = {
  [AboutKeys.Side_Popup.Btn.creator]: `크리에이터 입니다`,
  [AboutKeys.Side_Popup.Btn.client]: `기업 · 브랜드 입니다`,
  [AboutKeys.Side_Popup.Btn1]: '메인 사이트로 이동',

  // Part 1
  [AboutKeys.Part1.Creator.title]: `
    인증된 글로벌  <br>
    기업들과 간편하게 <br>
    협업하세요!`,
  [AboutKeys.Part1.Creator.subtitle]: `
    1~2분만에 무료로 멋진 프로필을 만들고 <br>
    글로벌 기업 협업 · 정산까지 올인원`,
  [AboutKeys.Part1.Creator.btn]: '크리에이터로 등록하기',

  [AboutKeys.Part1.Client.title]: `
    글로벌 인플루언서와 <br>
    실시간 협업 - 올인원 <br>
    마케팅 플랫폼`,
  [AboutKeys.Part1.Client.subtitle]: `
    주문 · 정산부터 성과 분석까지 올인원 <br>
    가장 편하고 효과적인 글로벌 마케팅`,
  [AboutKeys.Part1.Client.btn]: '인플루언서 검색 · 주문',

  // Part 2
  [AboutKeys.Part2.Creator.title]:
    '글로벌 크리에이터를 위한 브랜드 협업 플랫폼',

  [AboutKeys.Part2.Creator.Section1.Image.description1]:
    '한국은 물론 미국, 유럽, 일본 브랜드와 협업하세요!',
  [AboutKeys.Part2.Creator.Section1.Image.description2]:
    '전세계 광고주에게 채널 자동 홍보',
  [AboutKeys.Part2.Creator.Section1.Image.description3]:
    '기업 협업을 위한 맞춤형 메세지 시스템',
  [AboutKeys.Part2.Creator.Section1.text1]:
    '40개국+ 인증된 *글로벌 기업*과 협업',
  [AboutKeys.Part2.Creator.Section1.text2]:
    '다양한 SNS 플랫폼 · 자동 번역 시스템',
  [AboutKeys.Part2.Creator.Section1.text3]: '*광고주*와 실시간 무제한 1:1 소통',

  [AboutKeys.Part2.Creator.Section2.Image.description1]:
    '나만의 패키지 간편 생성 및 판매',
  [AboutKeys.Part2.Creator.Section2.Image.description2]:
    '제안서 검토 후 협업 진행 · 편리한 정산',
  [AboutKeys.Part2.Creator.Section2.text1]:
    '나의 *채널 · 스타일*에 맞는 패키지를 \\n 1분 만에 자동 생성 및 판매 시작',
  [AboutKeys.Part2.Creator.Section2.text2]: '기업 제안서 확인 · 원클릭 수락',
  [AboutKeys.Part2.Creator.Section2.text3]: '편리한 협업 대시보드 · 정산 기능',

  [AboutKeys.Part2.Creator.Section3.Image.description1]:
    '복합적인 데이터 · 통계를 단순하고 직관적으로 제공',
  [AboutKeys.Part2.Creator.Section3.Image.description2]:
    '다양하고 강력한 SNS 통계 · 분석',
  [AboutKeys.Part2.Creator.Section3.text1]: '가장 직관적이고 편리한 UI',
  [AboutKeys.Part2.Creator.Section3.text2]: '채널 및 콘텐츠 상세 데이터 분석',

  [AboutKeys.Part2.Client.title]: 'All-In-One 인플루언서 마케팅 툴 제공',

  [AboutKeys.Part2.Client.Section1.Image.description1]:
    '한국 및 글로벌 입점 크리에이터 검색',
  [AboutKeys.Part2.Client.Section1.Image.description2]:
    '크리에이터 협업 맞춤형 메세지 시스템',
  [AboutKeys.Part2.Client.Section1.text1]: '협업이 준비 된 글로벌 크리에이터',
  [AboutKeys.Part2.Client.Section1.text2]:
    '다양한 SNS 플랫폼 · *자동 번역* 시스템',
  [AboutKeys.Part2.Client.Section1.text3]:
    '크리에이터와 실시간 무제한 1:1 소통',

  [AboutKeys.Part2.Client.Section2.Image.description1]:
    '예측 데이터가 아닌, 크리에이터가 직접 작성한 패키지',
  [AboutKeys.Part2.Client.Section2.Image.description2]:
    '쉽고 편리한 주문 · 콘텐츠 관리',
  [AboutKeys.Part2.Client.Section2.text1]: '제안 · 주문 전에 *광고 가격* 확인',
  [AboutKeys.Part2.Client.Section2.text2]: '원클릭으로 *전세계* 광고 주문',
  [AboutKeys.Part2.Client.Section2.text3]: '편리한 협업 대시보드 · 정산 기능',

  [AboutKeys.Part2.Client.Section3.Image.description1]:
    '복합적인 데이터 · 통계를 단순하고 직관적으로 제공',
  [AboutKeys.Part2.Client.Section3.Image.description2]:
    '다양하고 강력한 SNS 통계 & 콘텐츠 리포트',
  [AboutKeys.Part2.Client.Section3.text1]: '가장 직관적이고 편리한 UI',
  [AboutKeys.Part2.Client.Section3.text2]:
    '채널 분석 및 주문 별 상세 데이터 \\n 리포트 제공',
  [AboutKeys.Part2.Client.Section3.text2_sub]: '* 일부 콘텐츠 종류 제외',

  // Part 3

  // Part 4

  // Part 5
  [AboutKeys.Part5.title]: 'EVO 포인트 및 추가 혜택',
  [AboutKeys.Part5.text1]: '플랫폼 내 미션을 수행하여 EVO 획득',
  [AboutKeys.Part5.text2]: '크리에이터를 위한 특별한 상품 · 혜택',
  [AboutKeys.Part5.text3]:
    '크리에이터와 팬, 그리고 기업을 연결하는 오프라인 이벤트 제공',

  // Feedback
  [AboutKeys.Feedback.Creator1.name]: '5만+ 유튜브 크리에이터',
  [AboutKeys.Feedback.Creator1.comment]:
    '메가에볼루션 사이트를 꼼꼼히 둘러보면서 훌륭한 사이트라는 것을 알게 되었습니다! 광고주와 소통할 창구가 마땅치 않았는데 덕분에 편하게 소통할 수 있게 되어 만족합니다!',
  [AboutKeys.Feedback.Creator2.name]: '150만+ 틱톡 · 인스타그램 크리에이터',
  [AboutKeys.Feedback.Creator2.comment]:
    '저는 지금까지 3.5년 이상 크리에이터로 활동하고 있으며 그동안 150만 팔로워를 모으면서 사용한 크리에이터-브랜드 연결 플랫폼이 여럿 있었음에도, 메가에볼루션 플랫폼의 경험은 제가 그동안 경험한 다른 어떤 것 보다도 부드럽고 효과적이었습니다. 일단 인터페이스부터가 굉장히 사용자 친화적이고 유연할 뿐만 아니라, 티어별 패키지 시스템을 통해 브랜드에게 다양한 옵션을 제공할 수 있어서 정말 편리하다고 느꼈습니다. 이 플랫폼을 지금이라도 발견해서 정말 감사하지만, 솔직히 더 일찍 발견했어도 좋았을 것 같습니다 😄',
  [AboutKeys.Feedback.Creator3.name]:
    '5만+ 유튜브 스트리머 · 인스타그램 크리에이터',
  [AboutKeys.Feedback.Creator3.comment]:
    '저와 같은 크리에이터들에게 정말 필요한 곳인거 같습니다. 소속사가 없는 신규/기존 크리에이터들은 어디서 광고를 받는지도 모르거든요. 메가에볼루션을 통해 많은 크리에이터 분들이 도움을 받을 수 있는 것 같아 기쁩니다!',
  [AboutKeys.Feedback.Creator4.name]: '15만+ 유튜브 크리에이터',
  [AboutKeys.Feedback.Creator4.comment]:
    '채널 운영자로서 메가에볼루션 플랫폼은 그냥 프로필을 만들어두기만 해도 기업들과 협업 기회가 생길 수 있는 만큼, 굉장히 좋은 조건인 것 같습니다!',
  [AboutKeys.Feedback.Creator5.name]: '10만+ 유튜브 · 인스타그램 크리에이터',
  [AboutKeys.Feedback.Creator5.comment]:
    '작년 메가에볼루션 팀의 지원 덕분에 자신감이 상승했고, 그 덕분에 유튜브를 다시 시작하게 되었습니다. 그리고 지금 여기까지 성장할 수 있었습니다! (그때부터 지금까지 채널이 3배 성장했습니다!)',
  [AboutKeys.Feedback.Creator6.name]: '유튜브 게임 크리에이터',
  [AboutKeys.Feedback.Creator6.comment]:
    '콘텐츠 크리에이터들을 위한 서비스라는 것이 잘 느껴져서 정말 인상적이고, 분명 현재 플랫폼을 이용하시는 분들과 미래에 이용하실 분들 모두 메가에볼루션이 제공하는 서비스로 큰 혜택을 누릴 것이라 확신합니다!',
  [AboutKeys.Feedback.Creator7.name]: '3만+ 유튜브 테크 · 게임 크리에이터',
  [AboutKeys.Feedback.Creator7.comment]:
    '저는 메가에볼루션 플랫폼의 구글 인증된 서비스와 복잡한 계약 없이 글로벌 브랜드와 직접적인 연결이 가능한 점이 특히 매력적으로 다가왔습니다!',
  [AboutKeys.Feedback.Creator8.name]: '5만+ 유튜브 크리에이터',
  [AboutKeys.Feedback.Creator8.comment]:
    '친구 중 한 명으로부터 이 플랫폼에 대해 듣게 되었고, 그 후 다른 유튜버 친구들 사이에서 메가에볼루션이 매우 성공적인 플랫폼이라는 사실을 알게 되었습니다!',
  [AboutKeys.Feedback.Creator9.name]:
    '7만+ 유튜브 · 인스타그램 ·틱톡 뷰티 크리에이터',
  [AboutKeys.Feedback.Creator9.comment]:
    '저는 메가에볼루션에 대해 설레는 마음을 가지고 있는데요, 크리에이터들에게 훌륭한 플랫폼일 뿐만 아니라 플랫폼 각 부분의 레이아웃 또한 정말 좋아하고 있습니다!',
};
