import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import device from 'styles/device';

const InputField = ({
  name,
  type = 'text',
  placeholder,
  maxLength = 0,
  onChangeInterceptor,
  renderOnRight,
  ...remainingProps
}) => {
  const { register } = useFormContext();
  const inputRef = useRef(null);
  const { ref, onChange: internalOnChange, ...rest } = register(name);
  const renderRightRef = useRef(null);
  const [rightRenderWidth, setRightRenderWidth] = useState('0');

  useEffect(() => {
    if (!renderOnRight) return;
    setRightRenderWidth(renderRightRef.current?.offsetWidth || 0);
  }, [renderOnRight]);

  return (
    <Wrapper initial={false} onClick={() => inputRef.current?.focus()}>
      <Input
        placeholder={placeholder}
        maxLength={maxLength > 0 ? maxLength : undefined}
        type={type}
        subtractWidth={rightRenderWidth}
        {...rest}
        {...remainingProps}
        ref={(e) => {
          ref(e);
          inputRef.current = e;
        }}
        onChange={(e) => {
          if (onChangeInterceptor) {
            const updatedValue = onChangeInterceptor(e.target.value);
            if (updatedValue !== undefined) e.target.value = updatedValue;
          }
          internalOnChange(e);
        }}
      />
      <RightRender ref={renderRightRef}>{renderOnRight}</RightRender>
    </Wrapper>
  );
};

export default InputField;

const Wrapper = styled(motion.div)`
  display: flex;
  width: 100%;
  height: 50px;
  padding: 0.75rem 1.125rem;
  align-items: center;
  border-radius: 0.75rem;
  border: 2px solid var(--color-indigo-20);
  cursor: text;
  position: relative;

  @media ${device.sm} {
    padding: 0.75rem;
  }
`;

const Input = styled.input.attrs({
  className: 'h6',
})`
  flex: 1;
  border: none;
  padding: 0;
  background: white;
  max-width: ${({ subtractWidth }) =>
    subtractWidth ? `calc(100% - 20px - ${subtractWidth}px)` : '100%'};

  @media ${device.sm} {
    max-width: 100%;
  }

  &::placeholder {
    color: var(--color-indigo-100);
    transition: color 0.2s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  /* removing arrows from type = number input */

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;

const RightRender = styled.div`
  position: absolute;
  right: 1.25rem;
  width: max-content;
`;
