import styled from 'styled-components';
import TextField from 'components/Basics/Inputs/TextField';
import { SearchIcon } from 'assets/icons';
import { useSubscribersFollowersFilter } from 'components/components/MarketingHomePage/FiltersContext/SubscribersFollowersFilterContext';

const SearchSubscribers = () => {
  const {
    customSubscribersFollowers,
    setCustomMax,
    setCustomMin,
    applyCustomSubscribers,
  } = useSubscribersFollowersFilter();

  const onSubmit = (e) => {
    e.preventDefault();
    applyCustomSubscribers();
  };

  return (
    <SearchBar onSubmit={onSubmit}>
      <SearchBoxes>
        <TextField
          type="number"
          maxWidth="100px"
          variant="violet"
          padding="0.25rem 0.5rem"
          radius={8}
          fontSize="text-sm"
          value={customSubscribersFollowers.min}
          onChange={(e) => setCustomMin(e.target.value)}
        />
        <span className="text-sm">-</span>
        <TextField
          type="number"
          maxWidth="100px"
          variant="violet"
          padding="0.25rem 0.5rem"
          radius={8}
          fontSize="text-sm"
          value={customSubscribersFollowers.max}
          onChange={(e) => setCustomMax(e.target.value)}
        />
      </SearchBoxes>

      <Button>
        <SearchIcon size={0.64} />
      </Button>
    </SearchBar>
  );
};

export default SearchSubscribers;

const SearchBar = styled.form`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

const SearchBoxes = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Button = styled.button`
  background: var(--gradient-violet);
  color: var(--color-indigo-500);
  border: none;
  border-radius: 0.5rem;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;
