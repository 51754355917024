import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]: 'เชื่อมต่อและทำงานร่วมกับบริษัทระดับโลกโดยอัตโนมัติ',
  [InvitationKeys.title2]: '',
  [InvitationKeys.subtitle]:
    '* สำหรับประสบการณ์ที่ดีที่สุด เราขอแนะนำให้ใช้เว็บเบราว์เซอร์',

  [InvitationKeys.description]: `
    สร้างโปรไฟล์ของคุณฟรีได้แล้วตอนนี้เพื่อสามารถทำงานร่วมกับบริษัทระดับโลก <br> 
    ที่ได้รับการรับรองทั้งในประเทศไทย อเมริกา เกาหลี ญี่ปุ่น ฯลฯ ได้อย่างง่ายดาย โดยไม่ต้องมี MCN`,

  [InvitationKeys.view_creator_tutorial]: 'เข้าร่วมเป็นครีเอเตอร์',
  [InvitationKeys.explore_btn]: 'ตัวอย่างโปรไฟล์และลงทะเบียน',
  [InvitationKeys.learn_more]: 'เรียนรู้เพิ่มเติม',
  [InvitationKeys.main_site]: 'หน้าหลัก',

  // Browser
  [InvitationKeys.Browser.title]: 'ดูเหมือนว่าคุณจะใช้เบราว์เซอร์ที่ไม่รองรับ',
  [InvitationKeys.Browser.description]:
    'กรุณาใช้เบราว์เซอร์ด้านบนเพื่อประสบการณ์ที่ดีที่สุด คุณสมบัติบางอย่างอาจไม่รองรับในเบราว์เซอร์นี้',
  [InvitationKeys.Browser.button]: 'ฉันเข้าใจแล้ว',

  [InvitationKeys.Mega_Flow.title]: 'MegaEvolution เราดำเนินการดังนี้',
  [InvitationKeys.Mega_Flow.Caption.one]: 'ใช้เวลา\n 1-2นาทีในการสร้างโปรไฟล์',
  [InvitationKeys.Mega_Flow.Caption.two]:
    'แพลตฟอร์มจะนำเสนอโปรไฟล์ของคุณ\n *โดยอัตโนมัติ*กับบริษัทที่ผ่านการรับรอง',
  [InvitationKeys.Mega_Flow.Caption.three]:
    'บริษัทสั่งซื้อบริการและชำระเงิน\n ครีเอเตอร์ตรวจสอบและยอมรับข้อเสนอ',
  [InvitationKeys.Mega_Flow.Caption.four]:
    'ส่งคอนเทนต์และเสร็จสิ้นการทำงานร่วมกัน\n สร้าง*รายได้เพิ่มเติม*ด้วยการขายใบอนุญาต',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    'ใช้เวลา 1-2นาทีในการสร้างโปรไฟล์',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    'แพลตฟอร์มจะนำเสนอโปรไฟล์ของคุณ\n *โดยอัตโนมัติ*กับบริษัทที่ผ่านการรับรอง',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]:
    'บริษัทสั่งซื้อบริการและชำระเงิน\n ครีเอเตอร์ตรวจสอบและยอมรับข้อเสนอ',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    'ส่งคอนเทนต์และเสร็จสิ้น\n การทำงานร่วมกัน\n สร้าง*รายได้เพิ่มเติม*\n ด้วยการขายใบอนุญาต',

  [InvitationKeys.Creator_Review.title]:
    'รีวิวจากครีเอเตอร์ที่ใช้ MegaEvolution',
};
