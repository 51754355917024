import styled from 'styled-components';
import Box from './Box';
import AxiosClient from 'components/utilities/AxiosClient';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import BillingKeys from 'translations/translationKeys/BillingPageKeys';

const getBalance = async () => {
  const res = await AxiosClient.get('/balance');

  return res.data;
};

const BalanceSummary = () => {
  const [balance, setBalance] = useState(0);
  const [pendingPayment, setPendingPayment] = useState(0);
  const [totalWithdrawn, setTotalWithdrawn] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    getBalance()
      .then((res) => {
        setBalance(res.totalBalance);
        setPendingPayment(res.pendingPayment);
        setTotalWithdrawn(res.totalWithdrawn);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Wrapper>
      <h3 className="bh3">
        {t(BillingKeys.MEGA_Balance.Balance_Summary.title)}
      </h3>

      <Box
        title={t(
          BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance
        )}
        amount={balance}
      />

      <Row>
        <Box
          title={t(BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn)}
          amount={totalWithdrawn}
        />
        <Box
          title={t(BillingKeys.MEGA_Balance.Balance_Summary.pending_payment)}
          amount={pendingPayment}
          tooltip={
            <Trans
              components={{
                TooltipLink: (
                  <TooltipLink
                    href="/help-center"
                    target="_blank"
                  ></TooltipLink>
                ),
              }}
            >
              {t(
                BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip
              )}
            </Trans>
          }
        />
      </Row>
    </Wrapper>
  );
};

export default BalanceSummary;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
`;

const Row = styled.div`
  display: flex;
  gap: 2.25rem;
`;

const TooltipLink = styled.a.attrs({
  className: 'text-inherit',
})`
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`;
