export const FacebookIconBlue = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 18}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_45268_167440)">
      <path
        d="M18 9C18 4.02943 13.9706 0 9 0C4.02943 0 0 4.02943 0 9C0 13.4921 3.29115 17.2155 7.59375 17.8907V11.6016H5.30859V9H7.59375V7.01719C7.59375 4.76156 8.93742 3.51562 10.9932 3.51562C11.9776 3.51562 13.0078 3.69141 13.0078 3.69141V5.90625H11.873C10.755 5.90625 10.4062 6.60006 10.4062 7.3125V9H12.9023L12.5033 11.6016H10.4062V17.8907C14.7088 17.2155 18 13.4921 18 9Z"
        fill="#1877F2"
      />
      <path
        d="M12.5033 11.6016L12.9023 9H10.4062V7.3125C10.4062 6.60076 10.755 5.90625 11.873 5.90625H13.0078V3.69141C13.0078 3.69141 11.9779 3.51562 10.9932 3.51562C8.93742 3.51562 7.59375 4.76156 7.59375 7.01719V9H5.30859V11.6016H7.59375V17.8907C8.52558 18.0364 9.47442 18.0364 10.4062 17.8907V11.6016H12.5033Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_45268_167440">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
