const LoginKeys = {
  Google: {
    sign_in: 'LoginKeys.Google.sign_in',
  },

  Facebook: {
    sign_in: 'LoginKeys.Facebook.sign_in',
    sign_up: 'LoginKeys.Facebook.sign_up',
  },

  Wallet: {
    sign_in: 'LoginKeys.Wallet.sign_in',
    sign_up: 'LoginKeys.Wallet.sign_up',
  },

  Password: {
    forgot: 'LoginKeys.Password.forgot',

    Rule: {
      title: 'LoginKeys.Password.Rule.title',
      l1: 'LoginKeys.Password.Rule.l1',
      l2: 'LoginKeys.Password.Rule.l2',
      l3: 'LoginKeys.Password.Rule.l3',
      l4: 'LoginKeys.Password.Rule.l4',
    },
  },

  Login_Form: {
    Placeholder: {
      email: 'LoginKeys.Login_Form.Placeholder.email',
      password: 'LoginKeys.Login_Form.Placeholder.password',
    },

    no_account: 'LoginKeys.Login_Form.no_account',
    create_account: 'LoginKeys.Login_Form.create_account',

    sign_up: 'LoginKeys.Login_Form.sign_up',
  },

  Sign_Up_Form: {
    Placeholder: {
      email: 'LoginKeys.Sign_Up_Form.Placeholder.email',
      password: 'LoginKeys.Sign_Up_Form.Placeholder.password',
    },

    Terms_of_Service: {
      title: 'LoginKeys.Sign_Up_Form.Terms_of_Service.title',
      agree_all: 'LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all',
      agree_all_description:
        'LoginKeys.Sign_Up_Form.Terms_of_Service.agree_all_description',
      age: 'LoginKeys.Sign_Up_Form.Terms_of_Service.age',
      terms_conditions:
        'LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions',
      receive_marketing:
        'LoginKeys.Sign_Up_Form.Terms_of_Service.receive_marketing',
      receive_marketing_description:
        'LoginKeys.Sign_Up_Form.Terms_of_Service.eceive_marketing_description',
      privacy_policies:
        'LoginKeys.Sign_Up_Form.Terms_of_Service.privacy_policies',
      payment_policies:
        'LoginKeys.Sign_Up_Form.Terms_of_Service.payment_policies',
      optional: 'LoginKeys.Sign_Up_Form.Terms_of_Service.optional',
      required: 'LoginKeys.Sign_Up_Form.Terms_of_Service.required',
      agree: 'LoginKeys.Sign_Up_Form.Terms_of_Service.agree',
    },

    go_back: 'LoginKeys.Sign_Up_Form.go_back',

    password_updated: 'LoginKeys.Sign_Up_Form.password_updated',
    thank_you_for_signing_up: 'LoginKeys.Sign_Up_Form.thank_you_for_signing_up',
  },

  Error: {
    network: 'LoginKeys.Error.network',
    incorrect: 'LoginKeys.Error.incorrect',
    recently_deleted: 'LoginKeys.Error.recently_deleted',
    login_failed: 'LoginKeys.Error.login_failed',
    already_in_use: 'LoginKeys.Error.already_in_use',
    wallet_not_verified: 'LoginKeys.Error.wallet_not_verified',
    already_in_use_or_connect_another_method:
      'LoginKeys.Error.already_in_use_or_connect_another_method',
    currently_deactiated_reset_password:
      'LoginKeys.Error.currently_deactiated_reset_password',
    currently_deactiated_login: 'LoginKeys.Error.currently_deactiated_login',
    enter_valid_email: 'LoginKeys.Error.enter_valid_email',
    invalid_request: 'LoginKeys.Error.invalid_request',
    too_many_request: 'LoginKeys.Error.too_many_request',
    wallet_already_registered: 'LoginKeys.Error.wallet_already_registered',
    try_sign_in_social_app: 'LoginKeys.Error.try_sign_in_social_app',
    password_used: 'LoginKeys.Error.password_used',
  },

  Modal: {
    Connect_Wallet: {
      title: 'LoginKeys.Modal.Connect_Wallet.title',
      description: 'LoginKeys.Modal.Connect_Wallet.description',
    },

    Enter_Email: {
      title: 'LoginKeys.Modal.Enter_Email.title',
      description: 'LoginKeys.Modal.Enter_Email.description',
      Placeholder: 'LoginKeys.Modal.Enter_Email.Placeholder',
      mobile_placeholder: 'LoginKeys.Modal.Enter_Email.mobile_placeholder',
    },

    Reset_Password: {
      description: 'LoginKeys.Modal.Reset_Password.description',
      expired: 'LoginKeys.Modal.Reset_Password.expired',
      reset: 'LoginKeys.Modal.Reset_Password.reset',
      update_success: 'LoginKeys.Modal.Reset_Password.update_success',

      Error: {
        valid_password: 'LoginKeys.Modal.Reset_Password.Error.valid_password',
      },
    },

    Useful_Reward: {
      title: 'LoginKeys.Modal.Useful_Reward.title',
      description: 'LoginKeys.Modal.Useful_Reward.description',
      button: 'LoginKeys.Modal.Useful_Reward.button',
    },

    Verify_Email: {
      title: 'LoginKeys.Modal.Verify_Email.title',
      description: 'LoginKeys.Modal.Verify_Email.description',
    },

    Check_Email: {
      title: 'LoginKeys.Modal.Check_Email.title',
      description: 'LoginKeys.Modal.Check_Email.description',
      button: 'LoginKeys.Modal.Check_Email.button',
    },

    VIP_Influencer: {
      description: 'LoginKeys.Modal.VIP_Influencer.description',
      im_creator: 'LoginKeys.Modal.VIP_Influencer.im_creator',
      no_im_not: 'LoginKeys.Modal.VIP_Influencer.no_im_not',
    },

    Welcome_On_Board: {
      description: 'LoginKeys.Modal.Welcome_On_Board.description',
      hi_mega: 'LoginKeys.Modal.Welcome_On_Board.hi_mega',
    },

    Use_Another_Method: {
      title: 'LoginKeys.Modal.Use_Another_Method.title',
      description: 'LoginKeys.Modal.Use_Another_Method.description',
    },

    Enter_Your_Email: {
      title: 'LoginKeys.Modal.Enter_Your_Email.title',
      description: 'LoginKeys.Modal.Enter_Your_Email.description',
      placeholder: 'LoginKeys.Modal.Enter_Your_Email.placeholder',
    },

    Reactivate: {
      title: 'LoginKeys.Modal.Reactivate.title',
      description: 'LoginKeys.Modal.Reactivate.description',
    },
    Register_type: {
      skip: 'LoginKeys.Modal.Register_type.skip',
      buyer: {
        title: 'LoginKeys.Modal.Register_type.buyer.title',
        description: 'LoginKeys.Modal.Register_type.buyer.description',
      },
      creator: {
        description: 'LoginKeys.Modal.Register_type.creator.description',
      },
    },
  },
};

export default LoginKeys;
