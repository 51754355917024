import AxiosClient from 'components/utilities/AxiosClient';

const Metric = {
  ViewsPerCountry: 'viewsPerCountry',
  AgeGender: 'ageGender',
  Topics: 'topics',
  TopCategories: 'topCategories',
  TopTags: 'topTags',
  Keywords: 'keywords',
};

const getAudienceAnalytics = async ({
  userId,
  handle,
  metrics,
  negateMetrics,
}) => {
  if (!userId && !handle)
    return Promise.reject({
      message: 'Missing required parameters for getAudienceAnalytics',
    });

  const metricsParam =
    metrics && metrics.length > 0
      ? negateMetrics
        ? '-' + metrics.join(',')
        : metrics.join(',')
      : undefined;

  const res = await AxiosClient.get('/youtube/Channel/AudienceAnalytics', {
    params: {
      userId,
      handle,
      metrics: metricsParam,
    },
  });

  return res.data.data;
};

export default getAudienceAnalytics;

export { Metric };
