export const TwitterCircleIcon = ({ size = 1 }) => (
  <svg
    width={24 * size}
    height={24 * size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#1D9BF0" />
    <path
      d="M9.80481 18.3291C15.1268 18.3291 18.0368 13.9191 18.0368 10.0971C18.0368 9.97114 18.0368 9.84514 18.0308 9.72514C18.5948 9.31714 19.0868 8.80714 19.4768 8.22514C18.9608 8.45314 18.4028 8.60914 17.8148 8.68114C18.4148 8.32114 18.8708 7.75714 19.0868 7.07914C18.5288 7.40914 17.9108 7.64914 17.2508 7.78114C16.7228 7.21714 15.9728 6.86914 15.1388 6.86914C13.5428 6.86914 12.2468 8.16514 12.2468 9.76114C12.2468 9.98914 12.2708 10.2111 12.3248 10.4211C9.91881 10.3011 7.78881 9.14914 6.36081 7.39714C6.11481 7.82314 5.97081 8.32114 5.97081 8.84914C5.97081 9.85114 6.48081 10.7391 7.26081 11.2551C6.78681 11.2431 6.34281 11.1111 5.95281 10.8951C5.95281 10.9071 5.95281 10.9191 5.95281 10.9311C5.95281 12.3351 6.94881 13.4991 8.27481 13.7691C8.03481 13.8351 7.77681 13.8711 7.51281 13.8711C7.32681 13.8711 7.14681 13.8531 6.96681 13.8171C7.33281 14.9691 8.40081 15.8031 9.66681 15.8271C8.67681 16.6011 7.42881 17.0631 6.07281 17.0631C5.83881 17.0631 5.61081 17.0511 5.38281 17.0211C6.64881 17.8491 8.17281 18.3291 9.80481 18.3291Z"
      fill="white"
    />
  </svg>
);
