export const EnvelopeIcon = ({ size = 1, small = true }) => (
  <>
    {small ? (
      <svg
        width={16 * size}
        height={14 * size}
        viewBox="0 0 16 14"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M13.6 0.411621H2.4C1.76348 0.411621 1.15303 0.671915 0.702944 1.13524C0.252856 1.59856 0 2.22697 0 2.88221V11.1175C0 11.7727 0.252856 12.4011 0.702944 12.8645C1.15303 13.3278 1.76348 13.5881 2.4 13.5881H13.6C14.2365 13.5881 14.847 13.3278 15.2971 12.8645C15.7471 12.4011 16 11.7727 16 11.1175V2.88221C16 2.22697 15.7471 1.59856 15.2971 1.13524C14.847 0.671915 14.2365 0.411621 13.6 0.411621ZM2.4 2.05868H13.6C13.8122 2.05868 14.0157 2.14544 14.1657 2.29989C14.3157 2.45433 14.4 2.6638 14.4 2.88221L8 6.90103L1.6 2.88221C1.6 2.6638 1.68429 2.45433 1.83431 2.29989C1.98434 2.14544 2.18783 2.05868 2.4 2.05868ZM14.4 11.1175C14.4 11.3359 14.3157 11.5454 14.1657 11.6998C14.0157 11.8543 13.8122 11.941 13.6 11.941H2.4C2.18783 11.941 1.98434 11.8543 1.83431 11.6998C1.68429 11.5454 1.6 11.3359 1.6 11.1175V4.75986L7.584 8.52339C7.70562 8.59567 7.84357 8.63372 7.984 8.63372C8.12443 8.63372 8.26238 8.59567 8.384 8.52339L14.4 4.75986V11.1175Z" />
      </svg>
    ) : (
      <svg
        width={20 * size}
        height={16 * size}
        viewBox="0 0 20 16"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 2C20 0.9 19.1 0 18 0H2C0.9 0 0 0.9 0 2V14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V2ZM18 2L10 6.99L2 2H18ZM18 14H2V4L10 9L18 4V14Z" />
      </svg>
    )}
  </>
);
<svg
  width="20"
  height="16"
  viewBox="0 0 20 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect
    x="1"
    y="0.799805"
    width="18"
    height="14.4"
    rx="4"
    stroke="white"
    stroke-width="1.5"
  />
  <path
    d="M4.60001 5.2998L8.80001 8.4498C9.51112 8.98314 10.4889 8.98314 11.2 8.4498L15.4 5.2998"
    stroke="white"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</svg>;
