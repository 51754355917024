import BillingKeys from '../translationKeys/BillingPageKeys';

export const BillingTranslations = {
  [BillingKeys.Payment_Subscription.title]: 'Payment & Subscription',
  [BillingKeys.Payment_Subscription.Method.title]: 'Payment Methods',
  [BillingKeys.Payment_Subscription.Method.btn]: 'Add a credit · debit card',
  [BillingKeys.Payment_Subscription.Side.my_plan]: 'My Plan',
  [BillingKeys.Payment_Subscription.Side.renews_on]: 'Renews on',

  [BillingKeys.Billing_History.title]: 'Billing History',
  [BillingKeys.Billing_History.search]: 'Search by creator or service name',

  [BillingKeys.MEGA_Balance.title]: 'MEGA Balance',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.title]: 'Withdrawal Method',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with]:
    'Withdraw with <icon></icon>',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon]: 'Coming Soon',
  [BillingKeys.MEGA_Balance.Balance_Summary.title]: 'Balance Summary',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance]:
    'Total Available Balance',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn]: 'Total Withdrawn',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment]: 'Pending Payment',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip]: `
    This includes payments from orders <br>
    currently in progress. Pending funds <br>
    will be available for withdrawal 24 days <br>
    after the order is completed. <br><br>
    Please make sure the ordered contents <br>
    are not deleted or made private for at <br>
    least 60 days after uploaded. <TooltipLink>Learn More</TooltipLink>`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount]:
    'Withdrawal Amount',
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.details]: `
    For each withdrawal, a 2.0% PayPal fee will be applied (Fee cap is 20 USD). <br>
    Transfers may take up to 3~5 business days.`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.btn]: 'Withdraw Balance',
};
