const ProposalKeys = {
  saving: 'ProposalKeys.saving',
  changes_saved: 'ProposalKeys.changes_saved',

  New_Proposal: {
    text: 'ProposalKeys.New_Proposal.text',
    toast: 'ProposalKeys.New_Proposal.toast',
  },

  Clear: {
    text: 'ProposalKeys.Clear.text',
    toast: 'ProposalKeys.Clear.toast',
  },

  Modal: {
    Copy: {
      title: 'ProposalKeys.Modal.Copy.title',
      description: 'ProposalKeys.Modal.Copy.description',
    },
  },

  Part1: {
    Option_1: {
      title: 'ProposalKeys.Part1.Option_1.title',
      placeholder: 'ProposalKeys.Part1.Option_1.placeholder',
      error: 'ProposalKeys.Part1.Option_1.error',
    },
    Option_2: {
      title: 'ProposalKeys.Part1.Option_2.title',
      placeholder: 'ProposalKeys.Part1.Option_2.placeholder',
      error: 'ProposalKeys.Part1.Option_2.error',
    },
    Option_3: {
      title: 'ProposalKeys.Part1.Option_3.title',
      placeholder: 'ProposalKeys.Part1.Option_3.placeholder',

      Snippet: {
        title: 'ProposalKeys.Part1.Option_3.Snippet.title',
        option_1: 'ProposalKeys.Part1.Option_3.Snippet.option_1',
        option_2: 'ProposalKeys.Part1.Option_3.Snippet.option_2',
        option_3: 'ProposalKeys.Part1.Option_3.Snippet.option_3',
        option_4: 'ProposalKeys.Part1.Option_3.Snippet.option_4',
      },

      Error: {
        file_format: 'ProposalKeys.Part1.Option_3.Error.file_format',
        file_size_limit: 'ProposalKeys.Part1.Option_3.Error.file_size_limit',
        max_file: 'ProposalKeys.Part1.Option_3.Error.max_file',
      },
    },
  },

  caution: 'ProposalKeys.caution',

  Side_btn: {
    text: 'ProposalKeys.Side_btn.text',
    title: 'ProposalKeys.Side_btn.title',
  },

  Part2: {
    title: 'ProposalKeys.Part2.title',

    Option_1: {
      title: 'ProposalKeys.Part2.Option_1.title',
      tooltip: 'ProposalKeys.Part2.Option_1.tooltip',

      Image: {
        title: 'ProposalKeys.Part2.Option_1.Image.title',
        description: 'ProposalKeys.Part2.Option_1.Image.description',
      },

      Info: {
        title: 'ProposalKeys.Part2.Option_1.Info.title',
        placeholder: 'ProposalKeys.Part2.Option_1.Info.placeholder',
      },

      caution_1: 'ProposalKeys.Part2.Option_1.caution_1',
      caution_2: 'ProposalKeys.Part2.Option_1.caution_2',
      caution_3: 'ProposalKeys.Part2.Option_1.caution_3',
      caution_3_tooltip: 'ProposalKeys.Part2.Option_1.caution_3_tooltip',
    },
  },

  Part3: {
    title: 'ProposalKeys.Part3.title',
    applied: 'ProposalKeys.Part3.applied',

    Option_1: {
      title: 'ProposalKeys.Part3.Option_1.title',
      description_1: 'ProposalKeys.Part3.Option_1.description_1',
      description_2: 'ProposalKeys.Part3.Option_1.description_2',
      description_3: 'ProposalKeys.Part3.Option_1.description_3',
    },

    Option_2: {
      placeholder: 'ProposalKeys.Part3.Option_2.placeholder',
      description: 'ProposalKeys.Part3.Option_2.description',
    },
  },

  Part4: {
    title: 'ProposalKeys.Part4.title',

    Option_1: {
      title: 'ProposalKeys.Part4.Option_1.title',
      placeholder: 'ProposalKeys.Part4.Option_1.placeholder',
      description: 'ProposalKeys.Part4.Option_1.description',
    },

    Option_2: {
      title: 'ProposalKeys.Part4.Option_2.title',
      placeholder: 'ProposalKeys.Part4.Option_2.placeholder',
      btn: 'ProposalKeys.Part4.Option_2.btn',
      description: 'ProposalKeys.Part4.Option_2.description',
    },

    Option_3: {
      title: 'ProposalKeys.Part4.Option_3.title',
      placeholder: 'ProposalKeys.Part4.Option_3.placeholder',
      description: 'ProposalKeys.Part4.Option_3.description',
    },

    Option_4: {
      title: 'ProposalKeys.Part4.Option_4.title',
      placeholder: 'ProposalKeys.Part4.Option_4.placeholder',
      description: 'ProposalKeys.Part4.Option_4.description',
    },

    Option_5: {
      title: 'ProposalKeys.Part4.Option_5.title',
      description: 'ProposalKeys.Part4.Option_5.description',
    },

    Option_6: {
      title: 'ProposalKeys.Part4.Option_6.title',
      placeholder: 'ProposalKeys.Part4.Option_6.placeholder',
      description: 'ProposalKeys.Part4.Option_6.description',
    },

    Option_7: {
      title: 'ProposalKeys.Part4.Option_7.title',
      description: 'ProposalKeys.Part4.Option_7.description',
    },

    Option_8: {
      title: 'ProposalKeys.Part4.Option_8.title',
      placeholder: 'ProposalKeys.Part4.Option_8.placeholder',
      btn: 'ProposalKeys.Part4.Option_8.btn',
      description: 'ProposalKeys.Part4.Option_8.description',
    },
  },
};

export default ProposalKeys;
