import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: '자동 번역',
  [AutoTranslationKeys.Dropdown.description]:
    '설명과 사용자가 작성한 텍스트가 자동으로 번역됩니다.',

  [AutoTranslationKeys.Applying_Translation.title]:
    '자동 번역이 적용 중입니다.',
  [AutoTranslationKeys.Applying_Translation.btn]: '원본 텍스트 표시',

  [AutoTranslationKeys.Displaying_Original.title]: '원본 텍스트 표시 중입니다.',
  [AutoTranslationKeys.Displaying_Original.btn]: '번역하기',
};
