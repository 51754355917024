import NotFoundKeys from '../translationKeys/NotFoundPageKeys';

export const NotFoundTranslations = {
  // Not Found Page
  [NotFoundKeys.title]: '404: Página no encontrada',
  [NotFoundKeys.description]:
    'Lo sentimos. La página que estás buscando no se pudo encontrar.',

  // Server Updating Page
  [NotFoundKeys.Server_Updating.title]: 'El servidor está siendo actualizado',
  [NotFoundKeys.Server_Updating.description]: '¡Por favor, vuelve más tarde!',

  // Under Development Page
  [NotFoundKeys.Under_Development.title]: 'Actualmente en desarrollo',
  [NotFoundKeys.Under_Development.description]:
    '¡Bienvenido a Marketing Web 3.0!',
};
