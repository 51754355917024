const defaultValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  companyType: '',
  companyWebsite: '',
  jobPosition: '',
  country: '',
  companyDescription: '',
  verificationFile: [],
};

export default defaultValues;
