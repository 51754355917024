const size3 = () => (
  <svg
    width="26"
    height="18"
    viewBox="0 0 26 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6813 7.62177L21.2381 0H19.6844L13.9911 6.61788L9.44389 0H4.19922L11.0755 10.0074L4.19922 18H5.75306L11.7653 11.0113L16.5675 18H21.8122L14.6809 7.62177H14.6813ZM12.5531 10.0956L11.8564 9.09906L6.31294 1.16971H8.69955L13.1732 7.56895L13.8699 8.56546L19.6851 16.8835H17.2985L12.5531 10.096V10.0956Z"
      fill="white"
    />
  </svg>
);
export default size3;
