import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useEffect } from 'react';
import { useRef } from 'react';
import { $generateNodesFromDOM } from '@lexical/html';
import { $getRoot, $insertNodes } from 'lexical';

export const StateSyncPlugin = ({ state, setState }) => {
  const [editor] = useLexicalComposerContext();

  const savedStateRef = useRef(null);

  const debounceIdRef = useRef(null);

  const onChange = useCallback(
    (editorState) => {
      if (!setState) return;

      if (debounceIdRef.current) {
        clearTimeout(debounceIdRef.current);
      }

      debounceIdRef.current = setTimeout(() => {
        let text = '';
        let length = 0;

        editorState?.read?.(() => {
          const root = $getRoot();
          text = root.getTextContent();
          length = root.getTextContentSize();
        });
        const currentEditorState = editorState.toJSON();

        const compositeState = {
          editorState: currentEditorState,
          text,
          length,
        };

        const stringifiedState = JSON.stringify(compositeState);

        savedStateRef.current = stringifiedState;
        if (state !== stringifiedState) setState(stringifiedState);
      }, 500);
    },

    [state, setState]
  );

  useEffect(() => {
    if (savedStateRef.current !== state) {
      if (state instanceof Document) {
        editor.update(() => {
          const nodes = $generateNodesFromDOM(editor, state);

          const root = $getRoot();
          root.clear();

          $insertNodes(nodes);
        });
      } else {
        try {
          const parsedState = JSON.parse(state);

          if (!parsedState?.editorState) throw new Error('No editor state');

          const parsedEditorState = editor.parseEditorState(
            parsedState.editorState
          );
          editor.setEditorState(parsedEditorState);
        } catch (e) {
          editor.update(() => {
            const root = $getRoot();
            root.clear();
          });
          onChange(editor.getEditorState());
        }
      }
    }
  }, [state, editor, onChange]);

  return <OnChangePlugin onChange={onChange} />;
};
