import CookiePolicyKeys from '../translationKeys/CookiePolicyPageKeys';

export const CookiePolicyTranslations = {
  [CookiePolicyKeys.title]: '쿠키 정책',
  [CookiePolicyKeys.top_text_1]: `MegaEvolution은 귀하의 개인 데이터를 보호하고 수집하는 정보 유형과 해당 정보의 사용 방법에 대해 명확하고 투명한 공개를 제공하는 데 약속드립니다. www.megaevolution.io에서 당사 플랫폼을 방문할 때, 쿠키가 사용될 수 있어 다른 사용자와의 차이점을 나타내는 정보를 수집할 수 있습니다.`,
  [CookiePolicyKeys.top_text_2]: `본 쿠키 정책은 우리와 파트너가 쿠키 및 유사한 기술을 사용하는 목적 및 쿠키에 대한 기본 설정을 관리하는 방법을 설명합니다.`,

  // Who we are and how to contact us
  [CookiePolicyKeys.Who_We_Are.title]: '저희와 연락하는 방법',
  [CookiePolicyKeys.Who_We_Are
    .description_1]: `MegaEvolution으로서 우리는 대한민국 서울시 강남구 테헤란로82길 15, 1178 (우편번호 06178)에 위치한 Intaverse 주식회사로서, 당사 웹사이트 또는 서비스를 직접 이용하시는 경우, 한국의 개인정보보호법 ("PIPA"), EU의 개인정보 보호 및 전자 통신 규정 ("PECR") 및 일반 데이터 보호 규정 ("GDPR")에 따라 데이터 컨트롤러로서의 역할을 수행합니다.`,
  [CookiePolicyKeys.Who_We_Are
    .description_2]: `쿠키 또는 개인 정보 보호에 관한 질문이 있으시면 info@megaevolution.io로 문의하실 수 있습니다.`,

  // What is a cookie?
  [CookiePolicyKeys.What_Is_Cookie.title]: '쿠키란 무엇인가요?',

  [CookiePolicyKeys.What_Is_Cookie.Description
    .l1]: `쿠키는 인터넷을 통해 기기의 탐색 정보를 기록하는 텍스트 파일 또는 소프트웨어 요소입니다. 이는 브라우저에 의해 제어되며 때로는 고유한 번호 또는 임의의 번호를 포함합니다. "쿠키"라고 언급할 때, 이는 쿠키 또는 웹사이트 방문 시 사용하는 기기(컴퓨터, 태블릿, 스마트폰)에 대한 액세스를 기반으로 한 다른 유사한 기술일 수 있습니다. 파트너나 서비스 제공업체와 같은 제3자도 서비스와 관련하여 쿠키와 유사한 기술을 사용할 수 있습니다.`,
  [CookiePolicyKeys.What_Is_Cookie.Description
    .l2]: `쿠키를 생성한 우리 또는 파트너로서 쿠키를 통해 기기를 인식할 수 있으며, 동일한 생성자의 쿠키를 포함하는 디지털 콘텐츠에 접근할 때 그 유효 기간 동안 기기를 인식할 수 있게 합니다.`,
  [CookiePolicyKeys.What_Is_Cookie.Description
    .l3]: `쿠키에서 얻은 일부 정보는 직접적 또는 간접적으로 귀하를 식별할 수 없으며 개인 정보로 간주되지 않습니다. 다른 정보는 귀하의 식별을 허용할 수 있으며, 이는 우리의`,
  [CookiePolicyKeys.What_Is_Cookie.Description.l4]: `개인 정보 보호 정책`,
  [CookiePolicyKeys.What_Is_Cookie.Description
    .l5]: `에 따릅니다. 개인 정보를 어떻게 사용, 저장 및 보호하며 귀하의 권리에 대한 자세한 정보를 원하신다면, 개인 정보 보호 정책을 읽어보시기를 권장합니다.`,

  // Why does MegaEvolution use cookies?
  [CookiePolicyKeys.Why_Does_MegaEvolution_Use_Cookies.title]:
    'MegaEvolution은 왜 쿠키를 사용하나요?',
  [CookiePolicyKeys.Why_Does_MegaEvolution_Use_Cookies
    .description]: `우리는 항상 웹사이트를 개선하고 귀하에게 더 나은 콘텐츠와 경험을 제공하기 위한 새로운 방법을 찾고 있습니다. 따라서 우리와 파트너는 다양한 유형의 쿠키(아래에서 설명)를 사용합니다. 일부 쿠키 범주의 경우, 쿠키가 기기에 배치되거나 읽히기 전에 귀하의 동의가 필요하며 쿠키 팝업을 통해 동의를 받습니다. 어떤 경우에도 귀하는 항상 쿠키를 제어할 수 있으며, 쿠키를 거부하거나 허용하거나 삭제할 수 있습니다.`,

  // How can I manage cookies?
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.title]:
    '쿠키를 어떻게 관리할 수 있나요?',

  [CookiePolicyKeys.How_Can_I_Manage_Cookies.A.title]:
    'a) 저희에게 연락하고 저희 서비스를 이용하는 방법',
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.A
    .description]: `대부분의 브라우저는 쿠키 설정을 통해 쿠키 사용에 대한 동의를 제공하기 위해 사용자 정의할 수 있는 기능을 제공합니다. 또한 대부분의 브라우저는 쿠키를 검토하고 삭제할 수 있는 기능을 제공합니다. 이를 위해서는 Google Chrome, Mozilla Firefox, Flash 쿠키, Microsoft Internet Explorer 및 Edge, Opera, Safari와 관련된 링크를 따라 브라우저 제조업체에서 제공하는 설명서를 참조하시기 바랍니다.`,

  [CookiePolicyKeys.How_Can_I_Manage_Cookies.B.title]: 'b) 가입하기',
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.B
    .description_1]: `우리는 서비스에 가입한 사용자로부터만 쿠키를 수집합니다. 플랫폼에 가입함으로써 우리의 쿠키 정책에 동의하게 됩니다. 이를 통해 귀하는 우리가 사용하는 쿠키에 대해 숙지된 결정을 내릴 수 있습니다. 가입하지 않음으로써 쿠키를 거부할 수 있지만, 참고하십시오. 가입하지 않을 경우 웹사이트 탐색이 덜 사용자 친화적이며 관련 콘텐츠가 영향을 받을 수 있습니다.`,
  [CookiePolicyKeys.How_Can_I_Manage_Cookies.B
    .description_2]: `원래의 선택과는 상관없이 웹 브라우저의 제어 설정을 사용하여 시간이 지남에 따라 설정을 조정하거나 쿠키 폴더를 삭제할 수 있습니다.`,

  [CookiePolicyKeys.What_types_of_cookies_do_we_use.title]:
    '우리는 어떤 종류의 쿠키를 사용하나요?',

  // What types of cookies do we use?
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.title]:
    '우리는 어떤 종류의 쿠키를 사용하나요?',

  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l1]: `쿠키는 다양한 방식으로 분류될 수 있습니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l2]: `수명에 따라 쿠키는 다음 중 하나일 수 있습니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l3]: `· 세션 쿠키: 브라우저를 닫을 때 삭제되는 쿠키입니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l4]: `· 영구 쿠키: 특정 쿠키 수명에 따라 기기에 지정된 기간 동안 유지되는 쿠키입니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l5]: `소속 도메인에 따라 다음 중 하나일 수 있습니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l6]: `· 제1자 쿠키: 방문한 사이트의 웹 서버가 설정한 동일한 도메인을 가진 쿠키입니다. 이 경우 www.megaevolution.io입니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l7]: `· 제3자 쿠키: 방문한 페이지의 도메인에 다른 도메인에서 저장된 쿠키입니다. 제3자인 우리와 계약을 맺은 파트너가 설정한 쿠키입니다. 이러한 외부 파트너는 쿠키를 사용하는 한 사용하는 쿠키에 대해 책임을 집니다. 해당 파트너의 개인정보처리방침을 읽어보시기를 권장합니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l8]: `또한 쿠키는 작동 방식에 따라 분류될 수 있습니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l9]: `· 가장 개인정보 침해가 적은 쿠키 유형은 필수 쿠키입니다. 이러한 쿠키는 웹사이트가 올바르게 작동하고 안전하도록 보장하기 위해 필수적입니다. 웹사이트를 탐색하고 해당 기능을 사용할 수 있도록 합니다. 이러한 쿠키 없이는 웹사이트의 특정 기능이 작동하지 않으며 따라서 특정 서비스를 이용할 수 없습니다. 이러한 쿠키는 탐색에 절대적으로 필요하기 때문에 사전 동의가 필요하지 않습니다. 이러한 쿠키는 저희의 팝업 동의 도구로 관리되지 않습니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l10]: `· 기능성 쿠키 또는 편의성 쿠키라고도 불리는 쿠키도 있습니다. 이러한 쿠키는 사용자가 선택한 옵션 (사용자 ID 저장, 동의한 내용 또는 선택한 언어 등) 및 탐색 중 선택한 기타 개인화 옵션을 기억할 수 있게 합니다. 이러한 쿠키는 수락하거나 거부할 수 있지만, 차단되면 이전에 선택한 서비스 선택이 저장되지 않을 수 있습니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l11]: `· 또한 웹사이트의 기능과 서비스를 모니터링하고 개선하는 데 사용되는 분석 및 성능 쿠키도 있습니다. 이러한 쿠키는 웹사이트 사용 시 발생하는 문제를 추적하고 온라인 설문 조사를 용이하게하며 방문자 수를 기록하고 분석 메트릭을 제공할 수 있습니다. 이러한 쿠키를 수락하거나 거부할 수 있지만 차단되면 웹사이트의 성능을 개선하기 위해 사용할 수 있는 정보가 줄어듭니다.`,
  [CookiePolicyKeys.What_types_of_cookies_do_we_use.Description
    .l12]: `· 마지막으로 광고 쿠키 또는 타겟팅 쿠키가 있습니다. 이러한 쿠키는 사용자에게 맞춤형 광고를 전달하는 데 사용됩니다. 이는 매우 편리할 수 있지만 동시에 매우 광고성일 수도 있습니다. 이러한 쿠키의 사용에는 사전 동의가 필요합니다.`,

  // Google Analytics
  [CookiePolicyKeys.Google_Analytics.title]: '구글 분석정보',

  [CookiePolicyKeys.Google_Analytics.Description
    .l1]: `저희 웹사이트에서는 Google이 제공하는 웹 분석 서비스인 Google Analytics를 사용합니다. Google Analytics도 쿠키를 사용하여 사용자가 여러 장치에서 저희 웹사이트를 어떻게 사용하는지 분석할 수 있도록 합니다. 쿠키에 의해 생성된 귀하의 웹사이트 사용에 관한 정보는 Google로 전송되어 저장되며, 이는 미국으로 전송됩니다. 다음과 같은 데이터가 Google Analytics를 통해 처리됩니다.`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l2]: `· 웹사이트 방문자의 호출된 시스템의 IP 주소의 3 바이트 (익명화된 IP 주소)`,
  [CookiePolicyKeys.Google_Analytics.Description.l3]: `· 방문한 웹사이트`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l4]: `· 사용자가 저희 웹사이트의 액세스 페이지에 도달한 웹사이트 (리퍼러)`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l5]: `· 웹사이트에서 액세스한 하위 페이지`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l6]: `· 웹사이트에서의 체류 시간`,
  [CookiePolicyKeys.Google_Analytics.Description.l7]: `· 웹사이트 액세스 빈도`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l8]: `Google은 귀하의 IP 주소를 Google이 보유한 다른 데이터와 연결하지 않을 것이라고 밝히고 있습니다.`,
  [CookiePolicyKeys.Google_Analytics.Description
    .l9]: `Google Analytics의 추적을 미래에 걸쳐 비활성화하려면 현재 웹 브라우저에 Google Analytics Opt-out 브라우저 애드온을 다운로드하고 설치하면 됩니다. 다음 링크를 따라가세요: http://tools.google.com/dlpage/gaoptout?hl=en. 이의 법적 근거는 저희의 정당한 이익과 귀하의 동의입니다.`,

  // Google Analytics Does this policy change?
  [CookiePolicyKeys.Google_Analytics_Does_this_policy_change.title]:
    '구글 분석정보 정책은 변경될 수 있나요?',
  [CookiePolicyKeys.Google_Analytics_Does_this_policy_change
    .description]: `저희는 필요에 따라 쿠키 정책을 업데이트할 수 있습니다. 이는 법률의 변경이나 우리의 업무 방침 및 쿠키 사용 방식에 대한 변화 등 여러 이유로 인할 수 있습니다. 저희의 쿠키 정책에 대한 변경 사항이 있는지 주기적으로 확인하시기를 권장합니다.`,

  // Who should I contact for more information?
  [CookiePolicyKeys.Who_Should_I_Contact_For_More_Information.title]:
    '추가 정보를 얻으려면 누구에게 연락해야 하나요?',
  [CookiePolicyKeys.Who_Should_I_Contact_For_More_Information
    .description]: `쿠키 정책에 관한 질문이나 의견이 있거나 해당 법률에 따라 권리를 행사하고자 하는 경우, info@megaevolution.io로 연락주시기 바랍니다.`,
};
