import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'No hay creadores para mostrar',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(¡Ordena)#1 #2(global servicios)#2
  de #3(creador marketing)#3
  en un #4(solo)#4 #5(clic!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'Intenta "Marketing de Moda"',
  [InfluKeys.search.option2]: 'Intenta "Reseñas de Videojuegos"',
  [InfluKeys.search.option3]: 'Intenta "Reseñas de Productos"',
  [InfluKeys.search.option4]: 'Intenta "Reseñas de comida"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'Registrarse como Creador',

  // Mobile
  [InfluKeys.M.title]: 'Plataforma de Marketing<br/>de Influencers Global',
  [InfluKeys.M.search]: 'Buscar creadores',
  [InfluKeys.M.content_box.request_creators.title]: 'Solicitar Creadores',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'Obtén una lista personalizada de creadores globales para tu campaña.',

  // Card
  [InfluKeys.Card.my_card]: 'Mi Tarjeta',
  [InfluKeys.Card.view_package]: 'Ver paquete',
  [InfluKeys.Card.creator_profile]: 'Perfil del Creador',
  [InfluKeys.Card.view_packages]: 'Ver Paquetes',
  [InfluKeys.Card.view_analytics]: 'Ver Análisis',
  [InfluKeys.Card.send_a_message]: 'Enviar un Mensaje',
  [InfluKeys.Card.view_profile]: 'Ver perfil',
};
