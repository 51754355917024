import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]:
    'Hãy cùng kết nối và hợp tác với các công ty toàn cầu đã được chứng nhận',
  [InvitationKeys.title2]: 'trên nền tảng nhé!',
  [InvitationKeys.subtitle]:
    '* Để có trải nghiệm tối ưu, chúng tôi khuyến nghị sử dụng môi trường web.',

  [InvitationKeys.description]: `
    Gần như chỉ cần một hồ sơ đơn giản được tạo hoàn toàn miễn phí, bạn có thể dễ dàng hợp tác với các công ty toàn cầu đã được chứng nhận từ Hàn Quốc, Mỹ, Châu Âu, Nhật Bản mà không cần MCN.`,

  [InvitationKeys.view_creator_tutorial]: 'Đăng ký làm người sáng tạo',
  [InvitationKeys.explore_btn]: 'Xem và Tạo Profile',
  [InvitationKeys.learn_more]: 'Tìm hiểu thêm',
  [InvitationKeys.main_site]: 'Trang chủ',

  // Browser
  [InvitationKeys.Browser.title]:
    'Bạn đang sử dụng trình duyệt không được hỗ trợ',
  [InvitationKeys.Browser.description]:
    'Vui lòng sử dụng các trình duyệt ở trên để có trải nghiệm tối ưu. Một số tính năng có thể không được hỗ trợ trên trình duyệt này.',
  [InvitationKeys.Browser.button]: 'Tôi hiểu rồi',

  [InvitationKeys.Mega_Flow.title]:
    'Megaevolution được tiến hành theo các bước dưới đây!',
  [InvitationKeys.Mega_Flow.Caption.one]:
    'Tạo một profile độc quyền của bạn chỉ trong vòng 1-2 phút.',
  [InvitationKeys.Mega_Flow.Caption.two]:
    'Hồ sơ của bạn sẽ tự động đề xuất và giới thiệu đến với các công ty toàn cầu đã được chứng nhận. ',
  [InvitationKeys.Mega_Flow.Caption.three]:
    'Các doanh nghiệp sẽ thanh toán và booking đặt hàng dịch vụ, sau đó Creator sẽ xem xét hợp đồng booking.',
  [InvitationKeys.Mega_Flow.Caption.four]:
    'Bàn giao nội dung và hoàn tất hợp tác. Cơ cơ hội nhận thêm thu nhập từ việc bán giấy phép (License).',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    'Tạo một profile độc quyền của bạn chỉ trong vòng 1-2 phút.',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    'Hồ sơ của bạn sẽ tự động đề xuất và giới thiệu đến với các công ty toàn cầu đã được chứng nhận. ',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]:
    'Các doanh nghiệp sẽ thanh toán và booking đặt hàng dịch vụ, sau đó Creator sẽ xem xét hợp đồng booking.',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    'Bàn giao nội dung và hoàn tất hợp tác. Cơ cơ hội nhận thêm thu nhập từ việc bán giấy phép (License).',

  [InvitationKeys.Creator_Review.title]:
    'Đánh giá từ các nhà sáng tạo MegaEvolution',
};
