import FAQKeys from '../translationKeys/FAQPageKeys';

export const FAQTranslations = {
  // General
  [FAQKeys.General.title]: 'ทั่วไป',

  [FAQKeys.General.Q1.title]: 'ฉันจะเริ่มต้นใช้ MegaEvolution อย่างไร?',
  [FAQKeys.General.Q1.description]:
    "เพื่อเริ่มต้นการตลาดผู้มีอิทธิพลของคุณกับ MegaEvolution คุณสามารถเข้าชมเว็บไซต์ของเราที่ megaevolution.io จากเบราว์เซอร์บนคอมพิวเตอร์หรือมือถือของคุณ หลังจากคลิกที่ 'Login' คุณสามารถสมัครใช้งานโดยใช้อีเมลส่วนตัวของคุณ หรือเข้าสู่ระบบผ่านบัญชี Google, Facebook, หรือกระเป๋าเงิน (ด้วย MetaMask หรือ Coinbase Wallet) ยอมรับข้อตกลงการให้บริการของเราและยืนยันอีเมลของคุณ (หากมีการต้องการ) เมื่อลงทะเบียนแล้ว คุณสามารถเชื่อมต่อกับนักสร้างสรรค์ได้อย่างง่ายดาย และใช้ความครอบคลุมของพวกเขาในการโปรโมตผลิตภัณฑ์หรือบริการของคุณให้ไปยังผู้ชมทั่วไป แพลตฟอร์มของเรามีคุณสมบัติขั้นสูง เช่น การวิเคราะห์ช่องทาง / ผู้ชมและระบบสั่งซื้อและชำระเงินแบบ all-in-one ทำให้ธุรกิจสามารถเปิดตัวแคมเปญของนักสร้างสรรค์ได้อย่างมีประสิทธิภาพ",

  [FAQKeys.General.Q2.title]: 'MegaEvolution คืออะไร?',
  [FAQKeys.General.Q2.description]:
    'MegaEvolution เป็นแพลตฟอร์มการตลาดผู้มีอิทธิพลที่เชื่อมโยงลูกค้ากับนักสร้างสรรค์ทั่วโลกเพื่อโปรโมตผลิตภัณฑ์และบริการของพวกเขา ลูกค้าสามารถค้นหาและสั่งซื้อแพ็กเกจการตลาดได้อย่างง่ายดาย ในขณะที่นักสร้างสรรค์สามารถโปรโมตตัวเองและได้รับโอกาสและกำไรมากขึ้นผ่านบริการของพวกเขา MegaEvolution ปรับปรุงกระบวนการทั้งหมดตั้งแต่การสั่งซื้อไปจนถึงการจัดการโฆษณา เพื่อให้เป็นประสบการณ์ที่ง่ายและสะดวกสำหรับผู้ใช้ทุกคน',

  [FAQKeys.General.Q3.title]: 'MegaEvolution ให้บริการอะไรสำหรับนักสร้างสรรค์?',
  [FAQKeys.General.Q3.description]:
    'MegaEvolution ทำให้นักสร้างสรรค์สามารถเชื่อมต่อกับลูกค้าแบรนด์จากทั่วโลกได้ เมื่อโปรโมตบริการของพวกเขาผ่าน MegaEvolution นักสร้างสรรค์สามารถรับคำสั่งซื้อจากลูกค้าได้อย่างง่ายดาย และเรายังสนับสนุนกระบวนการสั่งซื้อและชำระเงินทั้งหมดเพื่อช่วยให้นักสร้างสรรค์สามารถขยายโอกาสและกำไรได้มากที่สุด',

  [FAQKeys.General.Q4.title]: 'MegaEvolution ให้บริการอะไรสำหรับลูกค้า?',
  [FAQKeys.General.Q4.description]:
    'MegaEvolution เชื่อมโยงลูกค้ากับนักสร้างสรรค์ที่หลากหลาย เพื่อให้ได้รับข้อมูลเช่นการวิเคราะห์ช่องทางและราคาการตลาด ลูกค้าสามารถสั่งซื้อแพ็คเกจการตลาดจากนักสร้างสรรค์ได้แค่คลิกเดียว และเรายังมีการสนับสนุนเต็มรูปแบบตลอดกระบวนการสั่งซื้อและชำระเงินเพื่อให้มั่นใจว่าประสบการณ์การใช้งานปลอดภัยและสะดวกสบาย',

  [FAQKeys.General.Q5.title]: 'EVO Point คืออะไรและทำงานอย่างไร?',
  [FAQKeys.General.Q5.description]:
    'EVO Point ช่วยให้สามารถเข้าถึงราคาแพ็คเกจของนักสร้างสรรค์ได้ และอาจใช้ในอีเวนต์ การลงคะแนนเสียง และการบริหารการดำเนินงานในอนาคตเร็ว ๆ นี้ เรากำลังวางแผนที่จะเพิ่มลิสต์บนแลกเปลี่ยนต่าง ๆ เพื่อทำให้สามารถแลกเปลี่ยนกับเหรียญหรือเงินสดอื่น ๆ',

  // Package System
  [FAQKeys.Package_System.title]: 'ระบบแพ็คเกจ',

  [FAQKeys.Package_System.Q1.title]: 'แพ็คเกจที่ MegaEvolution คืออะไร?',
  [FAQKeys.Package_System.Q1.description]:
    'แพ็คเกจที่ MegaEvolution เป็นระบบที่ช่วยให้นักสร้างสรรค์สามารถนำเสนอบริการของตนเองได้ รวมถึงเนื้อหาที่พวกเขาจะให้บริการ ระยะเวลา ราคา และคำอธิบายอย่างละเอียดเกี่ยวกับวิธีการจัดส่งให้แก่ลูกค้า ผู้ใช้สามารถซื้อแพ็คเกจเหล่านี้ได้ง่ายๆ ด้วยไม่กี่คลิก และจัดการคำสั่งซื้อทั้งหมดผ่านแดชบอร์ดของพวกเขา นอกจากนี้ ผู้ใช้ยังจะได้รับการแจ้งเตือนและการวิเคราะห์เกี่ยวกับสถานะของคำสั่งซื้อแพ็คเกจของพวกเขา',

  [FAQKeys.Package_System.Q2.title]:
    'มีรูปแบบการกำหนดราคาแพ็คเกจประเภทไหนบ้าง?',
  [FAQKeys.Package_System.Q2.description]:
    'มีทางเลือกในการกำหนดราคา 3 ประเภท: ‘ราคาเปิดเผย’ / ‘ราคาปลดล็อก’ / ‘ติดต่อสร้างสรรค์’ สร้างสรรค์สามารถตั้งค่าทางเลือกการกำหนดราคาที่แตกต่างกันสำหรับแพ็คเกจแต่ละแผน เช่น แผนมาตรฐานสามารถตั้งค่าเป็นราคาเปิดเผยและแผนพรีเมี่ยมเป็นราคาปลดล็อก',

  [FAQKeys.Package_System.Q3.title]:
    'ระบบปลดล็อกราคาทำอย่างไร? ราคาปลดล็อกแสดงนานเท่าไหร่?',
  [FAQKeys.Package_System.Q3.description]:
    'ผู้ใช้ทุกคนสามารถปลดล็อกราคาแพ็คเกจได้ฟรีตามจำนวนครั้งที่กำหนดไว้ในแต่ละเดือน เพื่อปลดล็อกราคาสำหรับแพ็คเกจของสร้างสรรค์ คลิกที่ราคาที่ล็อกเพื่อปลดล็อก หลังจากปลดล็อกแล้ว คุณจะสามารถดูราคาทั้งหมดสำหรับสร้างสรรค์นั้นได้ ราคาที่ปลดล็อกแสดงอยู่เป็นเวลา 3 วัน',

  // Creators
  [FAQKeys.Creators.title]: 'สร้างสรรค์',

  [FAQKeys.Creators.Q1.title]: 'ฉันอยากทราบเรื่องค่าบริการสำหรับสร้างสรรค์',
  [FAQKeys.Creators.Q1.description]:
    'ในปัจจุบันเราไม่คิดค่าบริการสำหรับสร้างโฆษณาทุกครั้ง',

  [FAQKeys.Creators.Q2.title]: 'เวลาที่ใช้ในการถอนยอดคงเหลือของฉันนานเท่าไหร่?',
  [FAQKeys.Creators.Q2.description]:
    'การโอนเงินใช้เวลาประมาณ 1-3 วันทำการผ่าน PayPal',

  [FAQKeys.Creators.Q3.title]:
    'หลังจากที่ฉันยกเลิกการเชื่อมโยงบัญชีโซเชียลของฉันทั้งหมดหลังจากลงทะเบียนเป็นสร้างสรรค์แล้ว บัญชีของฉันจะถูกลบโดยอัตโนมัติหรือไม่?',
  [FAQKeys.Creators.Q3.description]:
    'บัญชีของคุณจะไม่ถูกลบ แต่คุณจะไม่สามารถรับคำสั่งซื้อใด ๆ ได้อีก นอกจากนี้คุณจะไม่ปรากฏในหน้าต่างค้นหาของเรา',

  [FAQKeys.Creators.Q4.title]:
    'ลูกค้าสามารถยกเลิกคำสั่งซื้อหลังจากที่ฉันยอมรับไปแล้วหรือไม่',
  [FAQKeys.Creators.Q4.description]:
    'เมื่อสร้างสรรค์ยอมรับคำสั่งซื้อแล้ว การยกเลิกเป็นไปได้เฉพาะในกรณีที่ทั้งสองฝ่ายตกลงกันในการยกเลิก อย่างไรก็ตามหากสร้างสรรค์ไม่ส่งมอบเนื้อหาตามกำหนดเวลาหรือหากคุณภาพของเนื้อหาไม่ได้รับการยอมรับหรือต่างไปจากคำอธิบายมากเกินไป คำสั่งซื้อสามารถถูกยกเลิกผ่านศูนย์ความชัดเจนได้ โปรดทราบว่าการยกเลิกคำสั่งซื้ออาจมีผลกระทบที่เป็นลบต่อบัญชีของคุณและส่งผลกระทบต่อชื่อเสียงของคุณเป็นสร้างสรรค์บน MegaEvolution',

  // Orders Help
  [FAQKeys.Orders_Help.title]: 'การช่วยเหลือเกี่ยวกับคำสั่งซื้อ',

  [FAQKeys.Orders_Help.Q1.title]: 'วิธีการใช้งานส่วนขยายและยกเลิก',
  [FAQKeys.Orders_Help.Q1.description]:
    'คุณสามารถขยายวันที่คำสั่งซื้อหรือยกเลิกได้โดยส่งคำขอไปยังคู่ค้าของคุณ คุณสามารถยอมรับหรือปฏิเสธคำขอที่คุณได้รับ คำขอจะถูกยอมรับโดยอัตโนมัติหากไม่ได้ตอบกลับภายในระยะเวลาหลายวัน (3 วันสำหรับการขยาย และ 5 วันสำหรับการยกเลิก)',

  [FAQKeys.Orders_Help.Q2.title]:
    'สามารถยกเลิกคำสั่งซื้อหลังจากที่ผู้สร้างยอมรับไปแล้วได้หรือไม่?',
  [FAQKeys.Orders_Help.Q2.description]:
    'โดยปกติแล้ว หลังจากที่ลูกค้ายอมรับคำสั่งซื้อแล้ว สามารถยกเลิกได้เฉพาะในกรณีที่สร้างสรรค์เห็นด้วย อย่างไรก็ตาม สามารถยกเลิกได้ผ่านศูนย์ความชัดเจนหากวันที่ส่งมอบไม่ได้เป็นไปตามที่กำหนดหรือคุณภาพของผลงานไม่ได้รับการยอมรับ',

  [FAQKeys.Orders_Help.Q3.title]: 'ทำอย่างไรถ้าฉันไม่ได้รับเนื้อหา?',
  [FAQKeys.Orders_Help.Q3.description]:
    'สร้างสรรค์ควรส่งลิงก์ของเนื้อหาที่สั่งไว้ทุกครั้ง หากคุณไม่ได้รับเนื้อหาจนถึงวันที่กำหนด คุณสามารถขอยกเลิกและขอคืนเงินได้',

  // Payment Help
  [FAQKeys.Payment_Help.title]: 'การช่วยเหลือในการชำระเงิน',

  [FAQKeys.Payment_Help.Q1.title]: 'ฉันจะตรวจสอบประวัติการชำระเงินได้อย่างไร?',
  [FAQKeys.Payment_Help.Q1
    .description]: `1. เข้าสู่ระบบบัญชีของคุณและคลิกที่ 'MySpace' ที่มุมขวาบนของเว็บไซต์ จากนั้นคลิกที่ปุ่ม 'Billing'.
2. ใช้แท็บ "ประวัติการชำระเงิน" เพื่อดูธุรกรรมของคุณ คุณยังสามารถดาวน์โหลดใบแจ้งหนี้ในรูปแบบ PDF ได้อีกด้วย`,

  [FAQKeys.Payment_Help.Q2.title]: 'ฉันจะชำระเงินได้อย่างไร?',
  [FAQKeys.Payment_Help.Q2
    .description]: `1. ไปที่ 'Myspace' ที่มุมขวาบนของหน้าเว็บไซต์ จากนั้นไปที่ 'Billing'.
2. ในแท็บ 'วิธีการชำระเงิน' เพิ่ม 'บัตรเครดิตและบัตรเดบิต' หรือ 'บัญชี PayPal'.
3. จากนั้นคุณสามารถใช้ข้อมูลที่เพิ่มไว้สำหรับการชำระเงิน`,

  [FAQKeys.Payment_Help.Q3.title]:
    'MegaEvolution ทำการคืนเงินให้ลูกค้าอย่างไร?',
  [FAQKeys.Payment_Help.Q3.description]:
    'สำหรับคำสั่งซื้อของสร้างสรรค์ เราทำการคืนเงินเต็มจำนวนเมื่อยกเลิก หากมีเนื้อหาที่เผยแพร่อยู่แล้ว การหักจากเงินคืนจะเป็นไปตามสัดส่วน ไม่มีการคืนเงินสำหรับการสมัครสมาชิกรายเดือนอัตโนมัติบนแพลตฟอร์ม',

  [FAQKeys.Payment_Help.Q4.title]:
    'ค่าบริการใช้ MegaEvolution เป็นเท่าไรและการทำงานของมันเป็นอย่างไร?',
  [FAQKeys.Payment_Help.Q4.description]:
    'MegaEvolution คิดค่าบริการ 5.5% + $3 สำหรับแต่ละคำสั่งซื้อแพคเกจ เราให้ค่าบริการที่แข่งขันได้อย่างเด่น ค่าบริการน้อยๆ นี้ช่วยให้เราดำเนินการแพลตฟอร์มและสนับสนุนกระบวนการสั่งซื้อแต่ละครั้ง หากคุณเป็นผู้ใช้สมัครสมาชิก คุณจะได้รับส่วนลดในค่าบริการ',

  [FAQKeys.Payment_Help.Q5.title]: 'มีแผนสมัครสมาชิกประเภทใดบ้าง?',
  [FAQKeys.Payment_Help.Q5.description]:
    'เรามีแผนสมัครสมาชิกรายเดือน 3 ประเภท: Basic (ฟรี), Standard, และ Premium ในแผน Basic คุณสามารถเข้าถึงฟังก์ชั่นหลักๆ ของแพลตฟอร์มได้ ในขณะที่แผน Standard และ Premium มีคุณสมบัติเพิ่มเติม เช่น การปลดล็อคราคามากขึ้น การวิเคราะห์ข้อมูล และการส่งข้อความเป็นกลุ่ม แผนที่เสียค่าใช้จ่ายยังได้รับส่วนลดเล็กน้อยในค่าบริการสำหรับคำสั่งซื้อ',

  // Account Help
  [FAQKeys.Account_Help.title]: 'ความช่วยเหลือที่เกี่ยวข้องกับบัญชี',

  [FAQKeys.Account_Help.Q1.title]:
    'ฉันทำอย่างไรถ้าลืมรายละเอียดการเข้าสู่ระบบ (ID หรือรหัสผ่าน)?',
  [FAQKeys.Account_Help.Q1
    .description]: `ในการสร้างบัญชีใน MegaEvolution คุณสามารถใช้อีเมล์ของคุณ หรือบัญชี Google, Facebook, หรือกระเป๋าเงิน (ด้วย MetaMask หรือ Coinbase Wallet) หากลืมรหัสผ่านสำหรับบัญชีอีเมล์ของคุณ แค่ไปที่หน้าเข้าสู่ระบบและคลิกที่ 'ลืมรหัสผ่าน?" จากนั้นใส่ที่อยู่อีเมลของคุณและคุณจะได้รับอีเมลด้วยลิงก์สำหรับรีเซ็ตรหัสผ่าน`,

  [FAQKeys.Account_Help.Q2.title]: 'ฉันจะเปลี่ยนรหัสผ่านอย่างไร?',
  [FAQKeys.Account_Help.Q2
    .description]: `1. ไปที่ 'พื้นที่ของฉัน' ที่มุมบนขวาของหน้าเมื่อเข้าสู่ระบบ
  2. คลิกที่ 'การตั้งค่า' และอัปเดตรหัสผ่านใหม่ของคุณในส่วน 'บัญชี'`,

  [FAQKeys.Account_Help.Q3.title]: 'ฉันจะปิดใช้งานหรือลบบัญชีของฉันได้อย่างไร?',
  [FAQKeys.Account_Help.Q3
    .description]: `1. ไปที่ 'พื้นที่ของฉัน' ที่มุมบนขวาของหน้าเมื่อเข้าสู่ระบบ
  2. คลิกที่ 'การตั้งค่า' และเลื่อนลงไปที่ส่วน 'ปิดใช้งาน/ลบ'
  3. คลิกที่ปุ่ม 'ปิดใช้งาน' หรือ 'ลบ' (ข้อมูลทั้งหมดอาจหายไปและไม่สามารถกู้คืนได้หลังจากลบบัญชี)`,

  // Safety & Privacy
  [FAQKeys.Safety_Privacy.title]: 'ความปลอดภัยและความเป็นส่วนตัว',

  [FAQKeys.Safety_Privacy.Q1.title]: 'ข้อมูลของฉันถูกป้องกันอย่างไร?',
  [FAQKeys.Safety_Privacy.Q1.description]:
    'ที่ MegaEvolution เราใส่ใจเกี่ยวกับความเป็นส่วนตัวและความปลอดภัยอย่างยิ่ง เราใช้มาตรฐานอุตสาหกรรมเพื่อให้แน่ใจว่าข้อมูลส่วนบุคคลของคุณได้รับการป้องกันจากการเข้าถึงที่ไม่ได้รับอนุญาตหรือการใช้งานที่ไม่ถูกต้อง หากต้องการเรียนรู้เพิ่มเติมเกี่ยวกับการปกป้องความเป็นส่วนตัวของคุณ โปรดอ่านนโยบายความเป็นส่วนตัวของเรา',

  [FAQKeys.Safety_Privacy.Q2.title]:
    'ข้อมูลประเภทใดบ้างที่ MegaEvolution เก็บไว้?',
  [FAQKeys.Safety_Privacy.Q2.description]:
    'MegaEvolution เก็บข้อมูลสำคัญต่างๆ สำหรับผู้ใช้ของเรา เช่น บันทึกคำสั่งซื้อ ข้อมูลการชำระเงิน ข้อมูลผู้ใช้ โปรไฟล์ ข้อมูลแพ็กเกจ ข้อมูลบันทึกเทคนิค และอื่นๆ',

  [FAQKeys.Safety_Privacy.Q3.title]:
    'ฉันจะทำการปิดใช้งานหรือลบบัญชีได้อย่างไร?',
  [FAQKeys.Safety_Privacy.Q3
    .description]: `1. เข้าไปที่ ‘My Space’ บนมุมขวาบนของหน้าจอหลังจากเข้าสู่ระบบแล้ว
  2. คลิกที่การตั้งค่าและเลื่อนลงไปที่ส่วนการปิดใช้งาน/ลบบัญชี
  3. คลิกที่ปุ่ม ‘ปิดใช้งาน’ หรือ ‘ลบ’ (ข้อมูลทั้งหมดอาจหายไปและไม่สามารถกู้คืนได้หลังจากลบบัญชีแล้ว)`,
};
