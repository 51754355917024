import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';

const NotificationBox = ({
  title,
  renderFooter,
  width = '400px',
  height = '480px',
  translateX = '-50%',
  children,
  onEndReached,
}) => {
  const [endReached, setEndReached] = useState(false);

  useEffect(() => {
    if (endReached) {
      onEndReached();
    }
  }, [endReached, onEndReached]);

  return (
    <Wrapper
      style={{
        width: width,
        height: height,
        translate: `${translateX} 0`,
      }}
    >
      {title && <Title>{title}</Title>}

      <Scrollbars
        renderView={(props) => <Contents {...props} />}
        renderTrackVertical={(props) => <ScrollbarTrack {...props} />}
        renderThumbVertical={(props) => <ScrollbarThumb {...props} />}
        renderTrackHorizontal={(props) => (
          <div {...props} style={{ display: 'none' }} />
        )}
        thumbSize={50}
        onScrollFrame={(e) => {
          // end reached will be true if the user scrolls to the bottom of the list and only 1 screen of content is left
          if (e.scrollTop >= e.scrollHeight - e.clientHeight * 2)
            setEndReached(true);
          else setEndReached(false);
        }}
      >
        {children}
      </Scrollbars>

      {renderFooter && (
        <Footer>
          {typeof renderFooter === 'function' ? renderFooter() : renderFooter}
        </Footer>
      )}
    </Wrapper>
  );
};

export default NotificationBox;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.15,
      },
    },
    visible: {
      opacity: 1,
      scale: 1,
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
})`
  position: absolute;
  top: calc(100% + 1rem);
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background: #fff;
  box-shadow: var(--shadow-dropdown-art-assets);
  transform-origin: top center;
  overflow: hidden;
`;

const Title = styled.h3.attrs({
  className: 'sbh6',
})`
  width: 100%;
  padding: 0.625rem 1rem;
  border-bottom: 1px solid var(--color-indigo-15);
`;

const Contents = styled.div`
  flex: 1;
  position: relative;
`;

const ScrollbarTrack = styled.div`
  position: absolute;
  right: 6px;
  bottom: 2px;
  top: 2px;
  width: 5px;
  border-radius: 999px;
  padding-block: 0.5rem;
`;

const ScrollbarThumb = styled(motion.div)`
  background-color: var(--color-indigo-25);
  border-radius: 999px;
`;

const Footer = styled.div`
  width: 100%;
  padding: 0.625rem 1rem;
  border-top: 1px solid var(--color-indigo-15);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
