import AxiosClient from 'components/utilities/AxiosClient';

const Metric = {
  Name: 'name',
  Videos: 'contentCount',
  Description: 'description',
  Banner: 'banner',
  ProfilePic: 'profilePic',
  ProfilePicMedium: 'profilePicMedium',
  ProfilePicHigh: 'profilePicHigh',
  JoinedDate: 'joinedDate',
  SubscribersFollowers: 'subscribersFollowers',
  Views: 'views',
  UpdatedAt: 'updatedAt',
  Engagement: 'engagement',
  CustomUrl: 'customUrl',
  ViewsPerCountry: 'viewsPerCountry',
  AgeGender: 'ageGender',
  Topics: 'topics',
  Countries: 'countries',
  Likes: 'likes',
  TopCategories: 'topCategories',
  TopTag: 'topTag',
  platformUid: 'platformUid',
};

const getDetails = async ({
  userId,
  handle,
  linksId,
  platformUid,
  metrics,
  negateMetrics,
  platform,
  isBasic
}) => {
  if (!(userId || handle || linksId || platformUid)||!platform)
    return Promise.reject({
      message: 'Missing required parameters for getDetails',
    });

  const metricsParam =
    metrics && metrics.length > 0
      ? negateMetrics
        ? '-' + metrics.join(',')
        : metrics.join(',')
      : undefined;

  const res = await AxiosClient.get('platforms/getDetails', {
    params: {
      userId,
      handle,
      linksId,
      platformUid,
      metrics: metricsParam,
      platform,
      isBasic
    },
  });

  return res.data;
};

export default getDetails;

export { Metric };
