const GuideKeys = {
  Portfolio_Banner: {
    description: 'GuideKeys.Portfolio_Banner.description',
    view_sample: 'GuideKeys.Portfolio_Banner.view_sample',
  },

  Creator_Invitation: {
    hi_ginseng_mega: 'GuideKeys.Creator_Invitation.hi_ginseng_mega',
    nice_to_meet_you: 'GuideKeys.Creator_Invitation.nice_to_meet_you',
    we_help_you_receive: 'GuideKeys.Creator_Invitation.we_help_you_receive',
    proposal_golbal_brands:
      'GuideKeys.Creator_Invitation.proposal_golbal_brands',
    let_companies_directly:
      'GuideKeys.Creator_Invitation.let_companies_directly',
    order_your_package: 'GuideKeys.Creator_Invitation.order_your_package',
    click_to_join: 'GuideKeys.Creator_Invitation.click_to_join',
    anytime: 'GuideKeys.Creator_Invitation.anytime',
    package_featured: 'GuideKeys.Creator_Invitation.package_featured',
    showcase_channel: 'GuideKeys.Creator_Invitation.showcase_channel',
    showcase_channel_2: 'GuideKeys.Creator_Invitation.showcase_channel_2',
    order_package: 'GuideKeys.Creator_Invitation.order_package',

    Mobile: {
      we_help_you_receive:
        'GuideKeys.Creator_Invitation.Mobile.we_help_you_receive',
      proposal_golbal_brands:
        'GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands',
      let_companies_directly:
        'GuideKeys.Creator_Invitation.Mobile.let_companies_directly',
      order_your_package:
        'GuideKeys.Creator_Invitation.Mobile.order_your_package',
      click_to_join: 'GuideKeys.Creator_Invitation.Mobile.click_to_join',
      anytime: 'GuideKeys.Creator_Invitation.Mobile.anytime',
    },
  },
};

export default GuideKeys;
