export const VietnamFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.128418"
      y="0.064209"
      width="33"
      height="33"
      rx="16.5"
      fill="#F93939"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6385 20.59L12.0405 23.01L12.9205 17.8906L9.20248 14.2672L14.3417 13.5192L16.6385 8.86401L18.9375 13.5192L24.0745 14.2672L20.3565 17.8906L21.2365 23.0078"
      fill="#FFDA2C"
    />
  </svg>
);
