import AboutKeys from '../translationKeys/AboutPageKeys';

export const AboutPageTranslations = {
  [AboutKeys.Side_Popup.Btn.creator]: `I’m a creator`,
  [AboutKeys.Side_Popup.Btn.client]: `I’m a brand · company`,
  [AboutKeys.Side_Popup.Btn1]: 'Go to main page',

  // Part 1
  [AboutKeys.Part1.Creator.title]: `
    Connect with global <br>
    top-tier companies <br>
    Maximize your profit`,
  [AboutKeys.Part1.Creator.subtitle]: `
    Take 1~2 min to create a free <br>
    profile to meet global brands!`,
  [AboutKeys.Part1.Creator.btn]: 'Join as a creator',

  [AboutKeys.Part1.Client.title]: `
    All-In-One Global <br>
    Influencer marketing <br>
    Maximize your ROI`,
  [AboutKeys.Part1.Client.subtitle]: `
    Effortless global collaborations <br>
    Simple & effective marketing`,
  [AboutKeys.Part1.Client.btn]: 'Order from influencers',

  // Part 2
  [AboutKeys.Part2.Creator.title]:
    'Global collaboration opportunities & creator tools',

  [AboutKeys.Part2.Creator.Section1.Image.description1]:
    'Collaborate with global brands',
  [AboutKeys.Part2.Creator.Section1.Image.description2]:
    'Automatic channel exposure to global brands',
  [AboutKeys.Part2.Creator.Section1.Image.description3]:
    'A dedicated chat space for collaborations',
  [AboutKeys.Part2.Creator.Section1.text1]:
    'Connect with verified *global companies* from 40+ countries',
  [AboutKeys.Part2.Creator.Section1.text2]:
    'Supports multiple platforms · \\n Auto-translations',
  [AboutKeys.Part2.Creator.Section1.text3]:
    'Real-time 1:1 chat with *advertisers*',

  [AboutKeys.Part2.Creator.Section2.Image.description1]:
    'Effortlessly create and sell your packages',
  [AboutKeys.Part2.Creator.Section2.Image.description2]:
    'Streamlined proposal review · payments',
  [AboutKeys.Part2.Creator.Section2.text1]:
    'Automatically create and sell packages that suits your channel',
  [AboutKeys.Part2.Creator.Section2.text2]:
    'Check and accept offers in 1-click',
  [AboutKeys.Part2.Creator.Section2.text3]: 'Smart dashboard · Easy payments',

  [AboutKeys.Part2.Creator.Section3.Image.description1]:
    'Access comprehensive data · statistics',
  [AboutKeys.Part2.Creator.Section3.Image.description2]:
    'Powerful social media analytics',
  [AboutKeys.Part2.Creator.Section3.text1]: 'Simple and intuitive UI',
  [AboutKeys.Part2.Creator.Section3.text2]:
    'Get detailed analytics on your channel and contents',

  [AboutKeys.Part2.Client.title]:
    'Powerful All-In-One Influencer Marketing Tools',

  [AboutKeys.Part2.Client.Section1.Image.description1]:
    'Global marketplace for brands and creators',
  [AboutKeys.Part2.Client.Section1.Image.description2]:
    'A dedicated chat space for collaborations',
  [AboutKeys.Part2.Client.Section1.text1]:
    'Find *global creators* ready to air your product',
  [AboutKeys.Part2.Client.Section1.text2]:
    'Supports multiple platforms · \\n *Auto-translations*',
  [AboutKeys.Part2.Client.Section1.text3]: 'Real-time 1:1 chat with creators',

  [AboutKeys.Part2.Client.Section2.Image.description1]:
    'Packages directly offered by creators',
  [AboutKeys.Part2.Client.Section2.Image.description2]:
    'Easily manage all ordered contents',
  [AboutKeys.Part2.Client.Section2.text1]: 'Check *Ad prices* before ordering',
  [AboutKeys.Part2.Client.Section2.text2]:
    'Effortless *proposal* delivery · Ad orders',
  [AboutKeys.Part2.Client.Section2.text3]:
    'Manage your *orders* and \\n *payments* through a dashboard',

  [AboutKeys.Part2.Client.Section3.Image.description1]:
    'Access comprehensive data · statistics',
  [AboutKeys.Part2.Client.Section3.Image.description2]:
    'Powerful social media analytics & order reports',
  [AboutKeys.Part2.Client.Section3.text1]: 'Simple and intuitive UI',
  [AboutKeys.Part2.Client.Section3.text2]:
    'Get detailed analytics on your orders and contents',
  [AboutKeys.Part2.Client.Section3.text2_sub]:
    '* Not all content types may be included',

  // Part 3
  [AboutKeys.Part3.info1]: `
    <span>80%</span> of marketers say <span>influencer marketing</span> is effective, and <span>89%</span> say it has a comparable or <span>better ROI</span> compared to other methods.`,
  [AboutKeys.Part3.info2]: `
    In 2022, <span>92.1%</span> of all US marketers use social media marketing, while <span>72.5%</span> of them use <span>influencer marketing</span> for their campaigns.`,
  [AboutKeys.Part3.info3]: `
    <span>61</span> of consumers trust <span>influencer</span> recommendations, compared to <span>38%</span> who trust brand-produced content.`,

  // Part 4
  [AboutKeys.Part4.title]: 'Clients & Partners',

  // Part 5
  [AboutKeys.Part5.title]: 'EVO Points & Perks',
  [AboutKeys.Part5.text1]: 'Complete missions to receive EVOs',
  [AboutKeys.Part5.text2]: 'Exclusive creator custom goods',
  [AboutKeys.Part5.text3]:
    'Offline events connecting creators, fans, and brands',

  // Part 6
  [AboutKeys.Part6.title]: 'Join Events & Giveaways',
  [AboutKeys.Part6.placeholder]: 'X Posts Loading...!',

  // Feedback
  [AboutKeys.Feedback.Creator1.name]: '50K+ YouTube Creator',
  [AboutKeys.Feedback.Creator1.comment]:
    "I've thoroughly explored MegaEvolution and found it to be an excellent platform! I didn't have a suitable channel to communicate with advertisers, but thanks to the site, I'm satisfied to be able to communicate effortlessly!",
  [AboutKeys.Feedback.Creator2.name]: '1.5M+ TikTok · Instagram Creator',
  [AboutKeys.Feedback.Creator2.comment]:
    "MegaEvolution is far and above any other creator marketplace platform I've used. I've been a content creator for over 3.5 years now and have accumulated over 1.5 million followers, so I've used my fair share of creator-to-brand platforms. My experience with MegaEvolution has been the smoothest and most effective of any that I've tried. Not only is the interface extremely user-friendly and fluid, but these tiered package system allows me to really diversify my offerings to brands. So thankful I found it and honestly wish I found it sooner 😄",
  [AboutKeys.Feedback.Creator3.name]:
    '50K+ YouTube Streamer · Instagram Creator',
  [AboutKeys.Feedback.Creator3.comment]:
    "MegaEvolution is a really necessary place for creators like me. New/existing creators without agencies don't even know where to get advertisements. I'm glad that many creators can receive help through MegaEvolution!",
  [AboutKeys.Feedback.Creator4.name]: '150K+ YouTube Creator',
  [AboutKeys.Feedback.Creator4.comment]:
    'As a channel owner, MegaEvolution platform offers great conditions because just creating a profile can lead to collaboration opportunities with companies.',
  [AboutKeys.Feedback.Creator5.name]: '100K+ YouTube · Instagram Creator',
  [AboutKeys.Feedback.Creator5.comment]:
    'The help MegaEvolution gave me last year boosted my confidence, due to which I started doing YouTube again. And see where I have reached! (My channel grew triple times from then!)',
  [AboutKeys.Feedback.Creator6.name]: 'YouTube Gaming Creator',
  [AboutKeys.Feedback.Creator6.comment]:
    "I'm really impressed by the service for content creators here, and I'm sure that both those currently using the platform and those who will use it in the future will benefit greatly from the services provided by MegaEvolution!",
  [AboutKeys.Feedback.Creator7.name]: '30K+ YouTube Tech · Gaming Creator',
  [AboutKeys.Feedback.Creator7.comment]:
    'The platform’s Google-verified service and direct connection with global brands without complex contracts were particularly appealing to me! ',
  [AboutKeys.Feedback.Creator8.name]: '50K+ YouTube Creator ',
  [AboutKeys.Feedback.Creator8.comment]:
    'I was told about this platform from one of my friends and found that MegaEvolution is very successful among my other YouTuber friends!',
  [AboutKeys.Feedback.Creator9.name]:
    '70K+ YouTube · Instagram ·TikTok Beauty Creator',
  [AboutKeys.Feedback.Creator9.comment]:
    'I’m excited about MegaEvolution! It’s a great platform for creators and I really like the layout of everything!',
};
