const { default: AxiosClient } = require('components/utilities/AxiosClient');

const getGPTSuggestion = ({
  priorPrompt,
  prompt,
  temperature,
  topP,
  returnTokens,
  contextData,
}) => {
  const controller = new AbortController();

  const isMethod =
    priorPrompt === 'clientBio' ||
    priorPrompt === 'creatorBio' ||
    priorPrompt === 'youtubePackage' ||
    priorPrompt === 'twitterPackage';

  const promise = AxiosClient.get('/GPT/getTextCompletion', {
    params: {
      priorPrompt: isMethod ? undefined : priorPrompt || '',
      prompt: prompt,
      return_tokens: returnTokens || 50,
      temperature: temperature || 0.6,
      top_P: topP || 1,
      contextData: contextData || {},
      methods: isMethod ? priorPrompt : undefined,
    },
    signal: controller.signal,
  }).then((res) => {
    return res.data;
  });

  return { promise, dismiss: () => controller.abort() };
};

export default getGPTSuggestion;
