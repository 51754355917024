export const CrossIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15 * size}
    height={15 * size}
    viewBox="0 0 15 15"
    fill="currentColor"
  >
    <path
      d="M9.64266 7.49901L14.6552 2.49813C14.8747 2.27862 14.998 1.98091 14.998 1.67048C14.998 1.36005 14.8747 1.06233 14.6552 0.842824C14.4357 0.623318 14.138 0.5 13.8275 0.5C13.5171 0.5 13.2194 0.623318 12.9999 0.842824L7.99901 5.85536L2.99813 0.842824C2.77863 0.623318 2.48091 0.5 2.17048 0.5C1.86005 0.5 1.56234 0.623318 1.34283 0.842824C1.12332 1.06233 1.00001 1.36005 1.00001 1.67048C1.00001 1.98091 1.12332 2.27862 1.34283 2.49813L6.35537 7.49901L1.34283 12.4999C1.23357 12.6083 1.14685 12.7372 1.08767 12.8792C1.02849 13.0213 0.998016 13.1736 0.998016 13.3275C0.998016 13.4814 1.02849 13.6338 1.08767 13.7758C1.14685 13.9179 1.23357 14.0468 1.34283 14.1552C1.4512 14.2644 1.58013 14.3512 1.72218 14.4103C1.86423 14.4695 2.01659 14.5 2.17048 14.5C2.32437 14.5 2.47673 14.4695 2.61879 14.4103C2.76084 14.3512 2.88977 14.2644 2.99813 14.1552L7.99901 9.14265L12.9999 14.1552C13.1083 14.2644 13.2372 14.3512 13.3792 14.4103C13.5213 14.4695 13.6737 14.5 13.8275 14.5C13.9814 14.5 14.1338 14.4695 14.2758 14.4103C14.4179 14.3512 14.5468 14.2644 14.6552 14.1552C14.7645 14.0468 14.8512 13.9179 14.9104 13.7758C14.9695 13.6338 15 13.4814 15 13.3275C15 13.1736 14.9695 13.0213 14.9104 12.8792C14.8512 12.7372 14.7645 12.6083 14.6552 12.4999L9.64266 7.49901Z"
    />
  </svg>
);
