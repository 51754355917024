export const PaypalIcon = ({ size = 1 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size * 72}
      height={size * 18}
      viewBox="0 0 72 18"
      fill="none"
    >
      <path
        d="M55.3413 3.93243H51.3564C51.0844 3.93243 50.8525 4.13051 50.81 4.39967L49.1985 14.6165C49.1665 14.8181 49.3226 14.9999 49.5259 14.9999H51.5708C51.7607 14.9999 51.9233 14.8612 51.953 14.6724L52.4103 11.7764C52.4523 11.5067 52.6847 11.3086 52.9568 11.3086H54.2175C56.8427 11.3086 58.3568 10.0385 58.753 7.52176C58.9318 6.42067 58.7599 5.55552 58.2444 4.94963C57.6787 4.28432 56.6749 3.93243 55.3413 3.93243ZM55.801 7.66391C55.5837 9.09358 54.4913 9.09358 53.4345 9.09358H52.8339L53.2562 6.42358C53.2813 6.2622 53.4199 6.14335 53.5837 6.14335H53.8592C54.5781 6.14335 55.2574 6.14335 55.6081 6.5535C55.8173 6.79818 55.8808 7.16172 55.801 7.66391Z"
        fill="#179BD7"
      />
      <path
        d="M67.2505 7.61749H65.3437C65.18 7.61749 65.0408 7.73633 65.0163 7.89771L64.9318 8.43136L64.7978 8.23794C64.3848 7.63846 63.4643 7.43805 62.545 7.43805C60.4366 7.43805 58.6364 9.03492 58.2857 11.275C58.1039 12.3924 58.362 13.4608 58.9958 14.206C59.5784 14.8911 60.4092 15.1766 61.399 15.1766C63.0978 15.1766 64.0399 14.0842 64.0399 14.0842L63.9548 14.6144C63.9228 14.8171 64.0789 14.9989 64.2834 14.9989H66.0015C66.2735 14.9989 66.5054 14.8008 66.5479 14.5311L67.5797 8.00083C67.6112 7.79984 67.455 7.61749 67.2505 7.61749ZM64.591 11.3309C64.4081 12.4209 63.5418 13.1526 62.4384 13.1526C61.8855 13.1526 61.4416 12.975 61.1572 12.6382C60.8753 12.3038 60.7692 11.8278 60.8578 11.2977C61.0308 10.217 61.9094 9.46138 62.9959 9.46138C63.5377 9.46138 63.9781 9.6414 64.2683 9.98104C64.5601 10.3242 64.6755 10.8031 64.591 11.3309Z"
        fill="#179BD7"
      />
      <path
        d="M69.4999 4.21226L67.8645 14.6161C67.8325 14.8177 67.9886 14.9995 68.192 14.9995H69.836C70.1092 14.9995 70.3411 14.8014 70.3831 14.5316L71.9957 4.31538C72.0277 4.11381 71.8716 3.93146 71.6682 3.93146H69.8273C69.6647 3.93204 69.5249 4.05089 69.4999 4.21226Z"
        fill="#179BD7"
      />
      <path
        d="M26.9212 3.93243H22.9368C22.6642 3.93243 22.4323 4.13051 22.3898 4.39967L20.7784 14.6165C20.7463 14.8181 20.9024 14.9999 21.1069 14.9999H23.0091C23.2817 14.9999 23.5136 14.8018 23.5561 14.532L23.9907 11.7764C24.0327 11.5067 24.2651 11.3086 24.5372 11.3086H25.7985C28.4231 11.3086 29.9378 10.0385 30.3334 7.52176C30.5116 6.42067 30.3409 5.55552 29.8254 4.94963C29.2591 4.28432 28.2547 3.93243 26.9212 3.93243ZM27.3808 7.66391C27.1629 9.09358 26.0706 9.09358 25.0143 9.09358H24.4131L24.8349 6.42358C24.86 6.2622 24.9998 6.14335 25.1629 6.14335H25.4385C26.158 6.14335 26.8367 6.14335 27.1874 6.5535C27.3965 6.79818 27.4606 7.16172 27.3808 7.66391Z"
        fill="#253B80"
      />
      <path
        d="M38.8314 7.61846H36.9234C36.7609 7.61846 36.6205 7.73731 36.5954 7.89869L36.5109 8.43234L36.3775 8.23892C35.9645 7.63944 35.0434 7.43903 34.1241 7.43903C32.0157 7.43903 30.2149 9.0359 29.8642 11.2759C29.6819 12.3934 29.9411 13.4618 30.575 14.2069C31.1564 14.8921 31.9883 15.1775 32.9781 15.1775C34.677 15.1775 35.619 14.0852 35.619 14.0852L35.5339 14.6153C35.5019 14.8181 35.658 14.9998 35.8614 14.9998H37.58C37.8532 14.9998 38.0839 14.8018 38.127 14.532L39.1582 8.00181C39.1908 7.80081 39.0353 7.61846 38.8314 7.61846ZM36.1719 11.3319C35.9878 12.4219 35.1226 13.1536 34.0192 13.1536C33.4652 13.1536 33.0224 12.9759 32.7381 12.6392C32.4561 12.3048 32.3489 11.8288 32.4387 11.2987C32.6105 10.218 33.4902 9.46235 34.5768 9.46235C35.1186 9.46235 35.559 9.64237 35.8491 9.98202C36.1398 10.3252 36.2552 10.8041 36.1719 11.3319Z"
        fill="#253B80"
      />
      <path
        d="M48.9937 7.61993H47.0764C46.8935 7.61993 46.7216 7.71082 46.6179 7.86287L43.9736 11.7581L42.8527 8.01493C42.7822 7.78073 42.566 7.61993 42.3214 7.61993H40.4373C40.2083 7.61993 40.0493 7.84365 40.1221 8.05921L42.234 14.2568L40.2485 17.0596C40.0924 17.2804 40.2497 17.5839 40.5194 17.5839H42.4344C42.6161 17.5839 42.7863 17.4954 42.8894 17.3462L49.2664 8.14135C49.419 7.92113 49.2623 7.61993 48.9937 7.61993Z"
        fill="#253B80"
      />
      <path
        d="M4.23263 16.9858L4.53732 15.0505L3.85861 15.0348H0.617676L2.86996 0.75379C2.87695 0.710678 2.89967 0.67048 2.93288 0.641933C2.96609 0.613386 3.00861 0.597656 3.05289 0.597656H8.51756C10.3317 0.597656 11.5837 0.975173 12.2374 1.7203C12.5438 2.06985 12.739 2.43514 12.8334 2.83712C12.9324 3.25892 12.9342 3.76285 12.8374 4.37748L12.8305 4.42234V4.81617L13.1369 4.98978C13.395 5.12669 13.6001 5.28341 13.7574 5.46284C14.0195 5.76171 14.189 6.14156 14.2607 6.5919C14.3347 7.05506 14.3102 7.60618 14.189 8.23013C14.0492 8.94788 13.8232 9.573 13.5179 10.0845C13.2371 10.5558 12.8794 10.9467 12.4547 11.2497C12.0492 11.5375 11.5674 11.756 11.0227 11.8958C10.4949 12.0333 9.89305 12.1026 9.23298 12.1026H8.80769C8.50358 12.1026 8.20821 12.2121 7.97634 12.4085C7.74389 12.6089 7.59008 12.8827 7.54289 13.1821L7.51085 13.3563L6.97254 16.7674L6.94807 16.8926C6.94166 16.9322 6.93059 16.9521 6.91428 16.9655C6.89972 16.9777 6.87874 16.9858 6.85835 16.9858H4.23263Z"
        fill="#253B80"
      />
      <path
        d="M13.4276 4.4671C13.4113 4.57139 13.3926 4.678 13.3716 4.78753C12.651 8.48754 10.1855 9.76574 7.03659 9.76574H5.43331C5.04822 9.76574 4.72372 10.0454 4.66371 10.4252L3.84285 15.6312L3.6104 17.1069C3.57136 17.3563 3.76362 17.5811 4.01529 17.5811H6.8589C7.19564 17.5811 7.48169 17.3365 7.5347 17.0044L7.56267 16.8599L8.09807 13.4623L8.13244 13.2758C8.18487 12.9426 8.47151 12.6979 8.80824 12.6979H9.23353C11.9886 12.6979 14.1453 11.5793 14.7757 8.34247C15.039 6.99029 14.9027 5.86123 14.2059 5.06717C13.995 4.82772 13.7334 4.62906 13.4276 4.4671Z"
        fill="#179BD7"
      />
      <path
        d="M12.6737 4.16626C12.5636 4.13422 12.45 4.10509 12.3335 4.07887C12.2164 4.05324 12.0963 4.03052 11.9728 4.01071C11.5406 3.9408 11.0669 3.90759 10.5595 3.90759H6.2763C6.17085 3.90759 6.07064 3.93148 5.98093 3.97459C5.78343 4.06955 5.63662 4.25656 5.60108 4.48552L4.68991 10.2566L4.6637 10.425C4.7237 10.0452 5.0482 9.76551 5.43329 9.76551H7.03658C10.1855 9.76551 12.651 8.48673 13.3716 4.7873C13.3932 4.67777 13.4112 4.57116 13.4276 4.46688C13.2452 4.37017 13.0477 4.28744 12.8351 4.21695C12.7826 4.19947 12.7285 4.18257 12.6737 4.16626Z"
        fill="#222D65"
      />
      <path
        d="M5.60099 4.48534C5.63653 4.25638 5.78334 4.06937 5.98084 3.97499C6.07114 3.93188 6.17076 3.908 6.27621 3.908H10.5594C11.0668 3.908 11.5405 3.9412 11.9728 4.01111C12.0963 4.03092 12.2163 4.05364 12.3334 4.07928C12.4499 4.10549 12.5635 4.13462 12.6736 4.16667C12.7284 4.18298 12.7825 4.19987 12.8356 4.21677C13.0482 4.28726 13.2457 4.37057 13.4281 4.4667C13.6424 3.09936 13.4263 2.16839 12.687 1.32539C11.872 0.397325 10.4009 0 8.51859 0H3.05392C2.66941 0 2.34141 0.279642 2.28199 0.660072L0.00581936 15.0878C-0.0390399 15.3733 0.181178 15.6308 0.468976 15.6308H3.84274L4.68982 10.2565L5.60099 4.48534Z"
        fill="#253B80"
      />
    </svg>
  );
};
