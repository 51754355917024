import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import IconButton from '../Buttons/IconButton';
import { ArrowLeftIcon, CrossIconRounded } from 'assets/icons';
import { useClickAway, useEvent } from 'react-use';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import Stack from '../Layout/Stack';

const backdropVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const modalContentVariants = {
  hidden: {
    opacity: 0,
    scale: 0.9,
    translateY: '-50%',
    translateX: '-50%',
  },
  visible: {
    opacity: 1,
    scale: 1,
    translateY: '-50%',
    translateX: '-50%',
  },
};

const modalContentVariantsFullScreen = {
  hidden: {
    x: '-100vw',
    transition: {
      duration: 0.6,
      ease: 'easeInOut',
      type: 'spring',
      bounce: 0.15,
    },
  },
  visible: {
    x: 0,
    transition: {
      duration: 0.6,
      ease: 'easeInOut',
      type: 'spring',
      bounce: 0.15,
    },
  },
};

const Modal = ({
  title,
  size = 'sm',
  isOpen,
  onClose = () => {},
  children,
  backdropClosesModal = true,
  closeIconSize,
  wrapperStyles,
  fullScreen,
  backButton = true,
  titleCenterAligned,
}) => {
  const { isMobile } = useResponsiveScreen();
  const modalRoot = useRef(document.getElementById('root'));
  const ref = useRef(null);

  useClickAway(ref, () => (backdropClosesModal ? onClose(false) : {}));

  useEvent('keydown', (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  });

  return (
    <>
      {createPortal(
        <AnimatePresence>
          {isOpen && (
            <>
              {!fullScreen && (
                <Backdrop
                  variants={backdropVariants}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                />
              )}
              <ModalContent
                size={size}
                variants={
                  fullScreen
                    ? modalContentVariantsFullScreen
                    : modalContentVariants
                }
                initial="hidden"
                animate="visible"
                exit="hidden"
                ref={ref}
                fullScreen={fullScreen}
                style={wrapperStyles}
              >
                <TopBar>
                  <Stack direction="row" gap="1rem" align="center" width="100%">
                    {fullScreen && backButton && (
                      <span
                        className="d-flex align-items-center"
                        onClick={() => onClose()}
                      >
                        <ArrowLeftIcon size={0.875} />
                      </span>
                    )}
                    <ModalTitle
                      className={`${isMobile ? 'sbh5' : 'sbh3'} ${
                        titleCenterAligned ? 'text-center' : 'text-start'
                      }`}
                      backButton={backButton}
                    >
                      {title}
                    </ModalTitle>
                  </Stack>
                  {!fullScreen && backButton && (
                    <IconButton onClick={() => onClose()} className="pt-2">
                      <CrossIconRounded
                        size={
                          closeIconSize ? closeIconSize : isMobile ? 0.86 : 1
                        }
                        width={undefined}
                        height={undefined}
                      />
                    </IconButton>
                  )}
                </TopBar>

                <Separator />
                {children}
              </ModalContent>
            </>
          )}
        </AnimatePresence>,
        modalRoot.current
      )}
    </>
  );
};

export default Modal;

const Backdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  z-index: 9999;
`;

const ModalContent = styled(motion.div)`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 30px 20px;
  width: ${(props) => (props.size === 'sm' ? '560px' : '740px')};
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  box-shadow: var(--shadow-dropdown-art-assets);

  @media (${device.sm}) {
    width: calc(100% - 2rem);
    padding: ${({ fullScreen }) => (fullScreen ? '' : '16px 16px 24px 16px')};
  }

  ${(props) =>
    props.fullScreen &&
    `
    top: 0;
    left: 0;
    transform: none;
    overflow-y: scroll;
    min-width: 100vw;
    height: 100%;
    max-height: 100dvh;
    border-radius: 0;
    padding: 0;
    box-shadow:none;
    display: flex;
    flex-direction: column;

  `}
`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media (${device.sm}) {
    padding: 16px 16px 0 16px;
  }
`;

const ModalTitle = styled.h2`
  padding-right: 1.5rem;

  @media (${device.sm}) {
    font-size: 18px;
  }
`;

const Separator = styled.hr`
  height: 1px;
  border: none;
  background-color: var(--color-indigo-100);
  margin: 11px 0 0; // 12px - 1px | 1px is the height of the separator
`;
