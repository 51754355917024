export const CheckCircleIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.62 24.82L26.605 12.835L24.225 10.455L14.62 20.06L9.775 15.215L7.395 17.595L14.62 24.82ZM17 34C14.6483 34 12.4383 33.5535 10.37 32.6604C8.30167 31.7685 6.5025 30.5575 4.9725 29.0275C3.4425 27.4975 2.23153 25.6983 1.3396 23.63C0.446534 21.5617 0 19.3517 0 17C0 14.6483 0.446534 12.4383 1.3396 10.37C2.23153 8.30167 3.4425 6.5025 4.9725 4.9725C6.5025 3.4425 8.30167 2.23097 10.37 1.3379C12.4383 0.445967 14.6483 0 17 0C19.3517 0 21.5617 0.445967 23.63 1.3379C25.6983 2.23097 27.4975 3.4425 29.0275 4.9725C30.5575 6.5025 31.7685 8.30167 32.6604 10.37C33.5535 12.4383 34 14.6483 34 17C34 19.3517 33.5535 21.5617 32.6604 23.63C31.7685 25.6983 30.5575 27.4975 29.0275 29.0275C27.4975 30.5575 25.6983 31.7685 23.63 32.6604C21.5617 33.5535 19.3517 34 17 34Z" />
  </svg>
);
