import styled from 'styled-components';
import ContentBox from './ContentBox';
import DescriptionBox from './DescriptionBox';
import { usePackages } from 'contexts/PackagesContext';
import Variant from '../packagesCardVariant';
import { useMemo, useState } from 'react';
import { useCallback } from 'react';
import Platform from 'constants/platform';

const ContentSection = ({ variant, featuredImageAction }) => {
  const { platform, loading, isCurrentPackageComplete, currentPackage } =
    usePackages();

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const onInternalRefChange = useCallback((node) => {
    if (node !== null) {
      setContainerHeight(node.getBoundingClientRect().height);
      setContainerWidth(node.getBoundingClientRect().width);
    }
  }, []);

  // details box have 2x2 layout for instagram and tiktok and when there are sample contents
  const isTwoByTwo = useMemo(() => {
    return (
      (platform === Platform.Instagram || platform === Platform.TikTok) &&
      currentPackage?.sampleContents.length > 0
    );
  }, [platform, currentPackage?.sampleContents]);

  return (
    <Wrapper
      loading={
        loading || (variant !== Variant.Preview && !isCurrentPackageComplete)
          ? 1
          : 0
      }
    >
      <ContentBox
        variant={variant}
        onInternalRefChange={onInternalRefChange}
        featuredImageAction={featuredImageAction}
      />

      <FloatingBox maxHeight={containerHeight} width={containerWidth} />

      {(platform === Platform.TikTok || platform === Platform.Instagram) &&
      isTwoByTwo ? (
        <FloatingDescriptionBox width={containerWidth}>
          <DescriptionBox variant={variant} containerHeight={containerHeight} />
        </FloatingDescriptionBox>
      ) : (
        <DescriptionBox variant={variant} containerHeight={containerHeight} />
      )}
    </Wrapper>
  );
};

export default ContentSection;

const Wrapper = styled.div`
  width: 100%;
  margin-top: ${({ loading }) => (loading ? '1.5rem' : '1.25rem')};
  position: relative;

  &::after {
    content: '';
    display: table;
    clear: both;
  }
`;

const FloatingBox = styled.div`
  float: left;
  width: ${({ width }) => (width ? `${width}px` : '480px')};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '300px')};
  height: 1000px;
  overflow: hidden;
  margin-right: 1.875rem;
  transition: max-height 500ms ease-in-out;
`;

const FloatingDescriptionBox = styled.div`
  float: right;
  width: calc(
    100% - ${({ width }) => (width ? `${width}px` : '480px')} - 1.875rem
  );
`;
