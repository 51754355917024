import ProfileKeys from '../translationKeys/ProfilePageKeys';

export const ProfileTranslations = {
  // Menu
  [ProfileKeys.Menu.edit]: 'Edit profile',
  [ProfileKeys.Menu.save]: 'Save profile',
  [ProfileKeys.Menu.message_me]: 'Message me',

  // Menu Error
  [ProfileKeys.Menu.Error.creator_to_creator]:
    'Please use a non-creator account.',
  [ProfileKeys.Menu.Error.something_went_wrong]:
    'Something went wrong! Please try again later.',
  [ProfileKeys.Menu.Error.self_chat]: 'Cannot send message to this chat.',

  // Package
  [ProfileKeys.Package.edit]: 'Edit Package',
  [ProfileKeys.Package.no_content]: 'No Content',
  [ProfileKeys.Package.available]: 'Available',
  [ProfileKeys.Package.click_to_view]: 'Click to view',
  [ProfileKeys.Package.click_to_view_recent]:
    'Click to view recent {{content}}',
  [ProfileKeys.Package.view_recent_videos]: 'View Recent Videos',

  // Topbar
  [ProfileKeys.Package.TopBar.tooltip]: 'This plan is currently unavailable.',

  // YouTube
  [ProfileKeys.Package.YouTube.Contents.video_prepare]: 'Preparing package',
  [ProfileKeys.Package.YouTube.Contents.video_display]:
    'Your video will be displayed here.',

  // Description
  [ProfileKeys.Package.Description.default]: `
    The creator is currently preparing a package. <br>
    Please check back soon.`,

  // Details
  [ProfileKeys.Package.Details.Price.contact_me]: 'Contact me',
  [ProfileKeys.Package.Details.Price.contact_creator]: 'Contact creator',
  [ProfileKeys.Package.Details.Price.service_fee]: 'Includes service fee',
  [ProfileKeys.Package.Details.Price.tooltip]: `
    Please try again with <br>
    another creator’s package.`,

  [ProfileKeys.Package.Details.Toast.update_successful]: 'Update successful!',
  [ProfileKeys.Package.Details.Toast.could_not_update]: "Couldn't update!",

  // Creator Summary
  [ProfileKeys.Creator_Summary.title]: 'Creator Summary',
  [ProfileKeys.Creator_Summary.sub_title]:
    'Creator’s basic channel information',
  [ProfileKeys.Creator_Summary.package_for_other_platforms]:
    'Packages for other platforms',

  // Basic Info
  [ProfileKeys.Creator_Summary.BasicInfo.hollow]: 'Preparing Social Network',

  // Tooltip
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers]: 'Subscribers',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers]: 'Followers',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos]: 'Total Videos',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets]: 'Total X Posts',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts]: 'Total Posts',

  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube]:
    'YouTube Join Date',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter]:
    'X Join Date',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram]:
    'Instagram Join Date',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok]:
    'TikTok Join Date',

  // Channel Contents
  [ProfileKeys.Channel_Contents.title]: 'Channel Contents',
  [ProfileKeys.Channel_Contents.sub_title]:
    'Our smart metrics measure for content engagement levels',
  [ProfileKeys.Channel_Contents.views_to_subs]: 'Views vs Subs.',
  [ProfileKeys.Channel_Contents.views_to_followers]: 'Views vs Followers',
  [ProfileKeys.Channel_Contents.reach_to_followers]: 'Reach vs Followers',
  [ProfileKeys.Channel_Contents.est_engagement]: 'Est. Engagement',
  [ProfileKeys.Channel_Contents.channel_data]: 'Channel Data',
  [ProfileKeys.Channel_Contents.loading_post]: 'Loading Post',

  [ProfileKeys.Channel_Contents.Twitter.feed]: 'X Feed',
  [ProfileKeys.Channel_Contents.Twitter.loading]: 'Loading X Posts',
  [ProfileKeys.Channel_Contents.Twitter.most_popular]: 'Most Popular X Post',
  [ProfileKeys.Channel_Contents.Tiktok.loading]: 'Loading TikTok',

  [ProfileKeys.Channel_Contents.Filter._1_month]: '1 Month',
  [ProfileKeys.Channel_Contents.Filter._3_months]: '3 Months',
  [ProfileKeys.Channel_Contents.Filter._1_year]: '1 Year',
  [ProfileKeys.Channel_Contents.Filter.newest]: 'Newest',
  [ProfileKeys.Channel_Contents.Filter.most_viewed]: 'Most Viewed',
  [ProfileKeys.Channel_Contents.Filter.most_liked]: 'Most Liked',
  [ProfileKeys.Channel_Contents.Filter.most_commented]: 'Most Commented',

  [ProfileKeys.Channel_Contents.Tooltip.youtube]: `
    Analytics here are based on <br>
    data from the past 1~6 months. <br>
    Curated videos include contents <br>
    uploaded from the past 1 year.`,
  [ProfileKeys.Channel_Contents.Tooltip.twitter]: `
    We use estimates for some data <br>
    including engagements for X. <br>
    There may be a difference with real <br>
    time data. Analytics here are based <br>
    on data from the past 1~3 months.`,
  [ProfileKeys.Channel_Contents.Tooltip.instagram]: `
    Analytics here are based <br>
    on data from the past 1~3 months.`,
  [ProfileKeys.Channel_Contents.Tooltip.tiktok]: `
    Analytics here are based <br>
    on data from the past 1~3 months.`,

  [ProfileKeys.Channel_Contents.Tooltip.likes]: 'Likes per view rating',
  [ProfileKeys.Channel_Contents.Tooltip.comments]: 'Comments per view rating',
  [ProfileKeys.Channel_Contents.Tooltip.x_reposts]: 'X Reposts per view rating',
  [ProfileKeys.Channel_Contents.Tooltip.shares]: 'Shares per view rating',

  [ProfileKeys.Channel_Contents.Tooltip.views_subs_rating]:
    'Views per subscriber rating',
  [ProfileKeys.Channel_Contents.Tooltip.views_follower_rating]:
    'Views per follower rating',
  [ProfileKeys.Channel_Contents.Tooltip.engagements]:
    'Overall engagement rating',

  // Channel Analytics
  [ProfileKeys.Channel_Analytics.title]: 'Channel Analytics',
  [ProfileKeys.Channel_Analytics.sub_title]:
    'Channel indicators quantitatively measure overall performance',
  [ProfileKeys.Channel_Analytics.updated_on]: 'Updated on {{date}}',
  [ProfileKeys.Channel_Analytics.avg_views]: 'Avg. Views',
  [ProfileKeys.Channel_Analytics.avg_impressions]: 'Avg. Impressions',
  [ProfileKeys.Channel_Analytics.avg_reach]: 'Avg. Reach',
  [ProfileKeys.Channel_Analytics.avg_likes]: 'Avg. Likes',
  [ProfileKeys.Channel_Analytics.avg_x_reposts]: 'Avg. X Reposts',
  [ProfileKeys.Channel_Analytics.engagements]: 'Engagements',

  // Views Analysis
  [ProfileKeys.Channel_Analytics.Views_Analysis.title]: 'Views Analysis',
  [ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram]:
    'Reach Analysis',
  [ProfileKeys.Channel_Analytics.Views_Analysis.periodic]: 'Periodic',
  [ProfileKeys.Channel_Analytics.Views_Analysis.total]: 'Total',

  // Subscribers Data
  [ProfileKeys.Channel_Analytics.Subscribers_Data.title]: 'Subscribers Data',

  // Followers Data
  [ProfileKeys.Channel_Analytics.Followers_Data.title]: 'Followers Data',

  // Audience Analytics
  [ProfileKeys.Audience_Analytics.title]: 'Audience Analytics',
  [ProfileKeys.Audience_Analytics.sub_title]:
    'Big data analytics for target viewers of the channel',
  [ProfileKeys.Audience_Analytics.preparing_data]: 'Preparing Data',

  // Audience Interest
  [ProfileKeys.Audience_Analytics.Audience_Interest.title]: 'Audience Interest',
  [ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags]:
    'Popular Tags',
  [ProfileKeys.Audience_Analytics.Keyword_Cloud.title]: 'Keyword Cloud',
  [ProfileKeys.Audience_Analytics.Top_10.title]: 'Top 10 Keywords',

  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title]:
    'Check my plan',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description]: `
    Please check and upgrade your plan <br>
    to access more audience data.`,

  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.title]: 'Login required',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.description]: `
    Please login first to access <br>
    user’s audience data.`,

  // Review
  [ProfileKeys.Review.review_as_client]: 'Reviews as Client',
  [ProfileKeys.Review.most_recent]: 'Most recent',
  [ProfileKeys.Review.most_helpful]: 'Most helpful',
  [ProfileKeys.Review.no_reviews]: 'No reviews to display',
  [ProfileKeys.Review.recent_clients]: 'RECENT CLIENTS',

  [ProfileKeys.Review.star]: 'Star',
  [ProfileKeys.Review.stars]: 'Stars',

  // Edit
  [ProfileKeys.Edit.Toast.being_saved]: 'Your profile is being saved.',
  [ProfileKeys.Edit.Toast.saved]: 'Your profile was successfully saved!',
  [ProfileKeys.Edit.Toast.failed]:
    'Profile save failed. Please try again later.',

  // Package
  [ProfileKeys.Edit.Package.complete_percentage]: `
    This package is {{number}}% complete`,

  [ProfileKeys.Edit.Package.Topbar.tooltip]: `
    Packages will appear on your <br>
    profile once they are complete.`,

  [ProfileKeys.Edit.Package.Topbar.Representative.tooltip]: `
    The default package will <br>
    be used for featuring your <br>
    service to the clients.`,

  [ProfileKeys.Edit.Package.Topbar.Completion.tooltip]: `
    Please complete <br>
    the package to <br>
    select as a default.`,

  // Banner
  [ProfileKeys.Edit.Package.Banner.heading]:
    'Recommended 1920X318 / Max size 20MB',
  [ProfileKeys.Edit.Package.Banner.mobile_heading]:
    'Max size 20 MB / 1920X318 banner',
  [ProfileKeys.Edit.Package.Banner.tooltip]: `
    Add a layer of shadow <br>
    on your banner image.`,

  // Options
  [ProfileKeys.Edit.Package.Options.intro_and_closing]: 'Intro & Closing',
  [ProfileKeys.Edit.Package.Options.product_review]: 'Product Review',
  [ProfileKeys.Edit.Package.Options.visit_review]: 'Visit Review',
  [ProfileKeys.Edit.Package.Options.digital_product_review]:
    'Digital Product Review',
  [ProfileKeys.Edit.Package.Options.brand_ambassador]: 'Brand Ambassador',
  [ProfileKeys.Edit.Package.Options.short_video]: 'Short Video',
  [ProfileKeys.Edit.Package.Options.product_placement]: 'Product Placement',
  [ProfileKeys.Edit.Package.Options.interview]: 'Interview',
  [ProfileKeys.Edit.Package.Options.commercial_break]: 'Commercial Break',
  [ProfileKeys.Edit.Package.Options.live_streaming]: 'Live Streaming',
  [ProfileKeys.Edit.Package.Options.giveaways]: 'Giveaways',
  [ProfileKeys.Edit.Package.Options.meme_and_artwork]: 'Meme / Artwork',
  [ProfileKeys.Edit.Package.Options.interview_and_ama]: 'Interview / AMA',

  // Title
  [ProfileKeys.Edit.Package.Title.use_a_template]: 'Use a Template',
  [ProfileKeys.Edit.Package.Title.placeholder]: 'Enter package title',
  [ProfileKeys.Edit.Package.Title.placeholder2]:
    'Type your package title here.',

  // Featured Image
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.title]: 'Featured Thumbnail',
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip]: `
    We recommend uploading a YouTube <br>
    thumbnail that reflects your channel style.`,

  [ProfileKeys.Edit.Package.Featured_Image.title]: 'Featured Image',
  [ProfileKeys.Edit.Package.Featured_Image.placeholder]:
    'JPG, JPEG, PNG files are allowed. 16:9 recommended. Max 200 MB.',
  [ProfileKeys.Edit.Package.Featured_Image.tooltip]: `
    This will be used for featuring <br>
    your package to clients.`,

  [ProfileKeys.Edit.Package.Featured_Image.Error.file_upload]: `
    A file is still being uploaded. <br>
    Please try again later.`,
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_large]:
    'File size is too large. (Max ',
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid]:
    'Invalid file format. (Please use JPG, JPEG, PNG)',

  // Sample Contents
  [ProfileKeys.Edit.Package.Sample_Contents.title]: 'Sample Contents',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube]:
    'Copy and paste a video link',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter]:
    'Copy and paste a X link',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram]:
    'Copy and paste a post link',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok]:
    'Copy and paste a TikTok link',

  [ProfileKeys.Edit.Package.Sample_Contents.add_more_contents]:
    'Add more contents',
  [ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link]:
    'Invalid link detected',

  // Description
  [ProfileKeys.Edit.Package.Description.placeholder]: `
    Please describe the details of your service here. <br>
    Clients may look for information regarding, <br><br>
    <ul>
      <li>Main content or service</li>
      <li>Target audience</li>
      <li>Schedules</li>
    </ul>`,
  [ProfileKeys.Edit.Package.Description.placeholder_gpt]:
    'Start typing a sentence and ChatGPT will suggest the rest.',

  [ProfileKeys.Edit.Package.Description.ChatGPT.title]:
    'Create a unique package with ChatGPT',
  [ProfileKeys.Edit.Package.Description.ChatGPT.tooltip]: `
    Choose the one that best <br>
    describes your service.`,

  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title]:
    'Additional Info',
  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder]:
    'Enter any information that should be included in the description.',

  // Price
  [ProfileKeys.Edit.Package.Price.placeholder]: 'Enter Price',
  [ProfileKeys.Edit.Package.Price.tooltip]: `
    Set your price open for easy access <br>
    to clients. Other options will not have <br>
    a set price or make it available only <br>
    to verified companies and clients.`,

  [ProfileKeys.Edit.Package.Price.Type.open]:
    'Open Price - Visible to all clients',
  [ProfileKeys.Edit.Package.Price.Type.unlockable]:
    'Unlockable Price - Verified clients only',
  [ProfileKeys.Edit.Package.Price.Type.contact_me]:
    'Contact Me - No price needed',

  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title]:
    'Please get verified to send more messages',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description]: `
    To message additional creators, you need to complete a quick verification process, which only takes 1-2 minutes. Please try again after getting verified. <br><br> 
    Your next renewal date: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title]:
    'Please upgrade your plan to send more messages',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description]: `
    You need to upgrade your plan to send messages to creators. <br> 
    Please check your plan and try again. <br><br> 
    Your next renewal date: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title]:
    'Please get verified to unlock more prices',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description]: `
    To unlock additional prices, you need to complete a quick verification process, which only takes 1-2 minutes. Please try again after getting verified. <br><br> 
    Your next renewal date: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title]:
    'Please upgrade your plan to unlock more prices',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
    .description]: `
    Your next renewal date: {{date}} <br>
    Please upgrade your plan to unlock this price.`,
  [ProfileKeys.Edit.Package.Price.Modal.btn]: 'Upgrade now',

  [ProfileKeys.Edit.Package.Price.toast]: `
    Successfully unlocked! ({{number}} left)`,

  [ProfileKeys.Edit.Package.Price.Error.minimum]: `
    Minimum price is {{number}}`,
  [ProfileKeys.Edit.Package.Price.Error.maximum]: `
    Maximum price is {{number}}`,

  // Contents
  [ProfileKeys.Edit.Package.Contents.YouTube.video]: 'YouTube Video',
  [ProfileKeys.Edit.Package.Contents.YouTube.shorts]: 'YouTube Shorts',
  [ProfileKeys.Edit.Package.Contents.YouTube.live]: 'YouTube Live',

  [ProfileKeys.Edit.Package.Contents.Twitter.post]: 'X Post',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_post]:
    'Instagram Post',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_story]:
    'Instagram Story',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels]:
    'Instagram Reels',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_live]:
    'Instagram Live',

  [ProfileKeys.Edit.Package.Contents.Tiktok.video]: 'TikTok Video',
  [ProfileKeys.Edit.Package.Contents.Tiktok.live]: 'TikTok Live',

  // Delivery
  [ProfileKeys.Edit.Package.Delivery.placeholder]:
    'Enter delivery time (7~60 days)',
  [ProfileKeys.Edit.Package.Delivery.input]: ' Days',
  [ProfileKeys.Edit.Package.Delivery.tooltip]: `
    Select the time you need for <br>
    preparing the full delivery.`,

  // Revisions
  [ProfileKeys.Edit.Package.Revisions.no_revisions]: 'No revision',
  [ProfileKeys.Edit.Package.Revisions.tooltip]: `
    The number of revisions you <br>
    will offer in the orders.`,

  // Type/Length
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    'e.g. A 5~10 minute product review video',

  // User Info
  // Username
  [ProfileKeys.Edit.User_Info.Username.placeholder]: 'Enter username',
  [ProfileKeys.Edit.User_Info.Username.Error.enter_name]: 'Please enter a name',
  [ProfileKeys.Edit.User_Info.Username.Error.max_50]: 'Max 50 characters',
  [ProfileKeys.Edit.User_Info.Username.Error.max_30]: 'Enter 3~30 characters',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol]:
    'Invalid symbol or space detected',
  [ProfileKeys.Edit.User_Info.Username.Error.max_2000]: 'Max 2000 characters',
  [ProfileKeys.Edit.User_Info.Username.Error.max_100]: 'Max 100 characters',
  [ProfileKeys.Edit.User_Info.Username.Error.max_500]: 'Max 500 characters',
  [ProfileKeys.Edit.User_Info.Username.Error.max_200]: 'Max 200 characters',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_price]: 'Invalid price',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time]:
    'Invalid delivery time',
  [ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60]:
    'Delivery time must be between 1 and 60 days',

  // My URL
  [ProfileKeys.Edit.User_Info.My_URL.placeholder]: 'Enter a URL',

  [ProfileKeys.Edit.User_Info.My_URL.Error.char_limit]: 'Enter 3~30 characters',
  [ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol]:
    'Invalid symbol or space detected',
  [ProfileKeys.Edit.User_Info.My_URL.Error.required_handle]:
    'Please enter a URL',
  [ProfileKeys.Edit.User_Info.My_URL.Error.already_taken]:
    'This URL is already taken',

  // Social Network
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.priority]:
    "Couldn't change priority",
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink]:
    "Couldn't unlink platform",

  [ProfileKeys.Edit.User_Info.Social_Network.Popup.title]:
    'Please complete your order(s) first',
  [ProfileKeys.Edit.User_Info.Social_Network.Popup.description]: `
    You can unlink your verified social network account after you cancel or complete your ongoing order(s).`,

  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.title]:
    'Are you sure you want to unlink this account?',
  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.description]:
    'Once you unlink your verified social network account, data connected to it may be lost. Also, the associated package information will not appear on your profile and on our search anymore.',

  // Bio
  [ProfileKeys.Edit.User_Info.Bio.placeholder_creator]:
    'Share your story with global brands and clients!',
  [ProfileKeys.Edit.User_Info.Bio.placeholder_client]:
    'Share your story with global creators!',

  // Categories
  [ProfileKeys.Edit.User_Info.Categories.add_a_category]: 'Add a category',

  // Target Countries
  [ProfileKeys.Edit.User_Info.Target_Countries.title]: 'Countries',
  [ProfileKeys.Edit.User_Info.Target_Countries.add_a_country]: 'Add a country',
  [ProfileKeys.Edit.User_Info.Target_Countries.tooltip]: `
    Select 1-2 countries where you reside <br>
    or that best represent you.`,

  // Languages
  [ProfileKeys.Edit.User_Info.Languages.add_a_language]: 'Add a language',
  [ProfileKeys.Edit.User_Info.Website.placeholder]: 'Copy and paste a URL',

  // Company
  [ProfileKeys.Edit.User_Info.Company.placeholder]: 'Enter company name',
  [ProfileKeys.Edit.User_Info.Company.tooltip]: `
    Upload a company logo <br>
    in PNG, JPG, JPEG format. <br>
    360x360 recommended. <br>
    Max size is 50 MB.`,
  [ProfileKeys.Edit.User_Info.Company.Error.max_30]: 'Max 30 characters',

  // Modal
  [ProfileKeys.Modal.Request_Price.title]: 'Request a price',
  [ProfileKeys.Modal.Request_Price.btn]: 'Send a request',
  [ProfileKeys.Modal.Request_Price.toast]:
    'Your request was successfully sent!',
  [ProfileKeys.Modal.Request_Price.Message.title]: 'Send a message',
  [ProfileKeys.Modal.Request_Price.Message.placeholder]:
    'Ask a question or share your project details (budget, product summary, requirements, etc.)',

  [ProfileKeys.Modal.Edit.Banner.heading]: 'Select a method',
  [ProfileKeys.Modal.Edit.Banner.body]: 'Upload an image',
  [ProfileKeys.Modal.Edit.Banner.loading]: 'Loading from',
  [ProfileKeys.Modal.Edit.Banner.use_my]: 'Use my {{platform}} {{type}}',
  [ProfileKeys.Modal.Edit.Banner.not_found]: '{{platform}} {{type}} not found',

  [ProfileKeys.Modal.Edit.Incomplete_Package.title]:
    'Incomplete package detected',
  [ProfileKeys.Modal.Edit.Incomplete_Package.description]: `
    The following package(s) are incomplete: <br><br>
    <package></package> <br>
    Complete these packages to make them visible in search results and on your profile. Would you like to complete them now?`,

  [ProfileKeys.Modal.Edit.new_reward]: 'You unlocked a new reward.',
  [ProfileKeys.Modal.Edit.skip_for_now]: 'Skip for now',

  [ProfileKeys.Modal.Reconnection.title]: 'Reconnection required',
  [ProfileKeys.Modal.Reconnection.description]: `
    Due to long-term inactivity or policy · function updates on each social media platform, reconnection is required for the platforms listed below. Please reconnect or unlink the respective platform to continue. <br><br>
    MegaEvolution supports secure connections through officially verified · partner APIs from Google, Meta (Instagram), and TikTok.`,
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title]:
    'You can connect a new account after unlinking your current account',
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description]:
    'A different account has been detected from the one currently connected. To connect a new account, please unlink the existing one and try again.',

  // Guide
  [ProfileKeys.Guide.hi_ginseng_mega]: "Hi, i'm MEGA!",
  [ProfileKeys.Guide.nice_to_meet_you]: 'Nice to meet you~!',
  [ProfileKeys.Guide.create_unique_url]: 'Create a unique URL',
  [ProfileKeys.Guide.we_help_you_receive]: 'We help you receive',
  [ProfileKeys.Guide.we_help_you_receive_mobile]:
    'We help you receive proposals',
  [ProfileKeys.Guide.proposal_golbal_brands]: 'proposals from global brands!',
  [ProfileKeys.Guide.proposal_golbal_brands_mobile]: 'from global brands!',
  [ProfileKeys.Guide.create_unique_portfolio]: 'Create a unique portfolio',
  [ProfileKeys.Guide.just_few_clicks]: 'in just a few clicks!',
  [ProfileKeys.Guide.click_to_join]: 'Click to join',
  [ProfileKeys.Guide.click_to_join_mobile]: 'Click here',
  [ProfileKeys.Guide.anytime]: 'anytime!',
  [ProfileKeys.Guide.anytime_mobile]: 'to join anytime!',
  [ProfileKeys.Guide.what_a_pleasure]: 'What a pleasure to meet you today!',
  [ProfileKeys.Guide.hope_to_see]: 'Hope to see your unique page soon!',
  [ProfileKeys.create_unique_handle]: 'Create your URL with a unique @handle!',

  // Profile
  [ProfileKeys.Guide.Profile.welcome]: "Welcome, i'm MEGA!",
  [ProfileKeys.Guide.Profile.portfolio_space]: 'This is a portfolio space',
  [ProfileKeys.Guide.Profile.specially_created]: 'specially created for you.',
  [ProfileKeys.Guide.Profile.click_here_to_start_editing]:
    'Click here to start editing.',
  [ProfileKeys.Guide.Profile.package_will_show_up]:
    'Your package will show up here!',
  [ProfileKeys.Guide.Profile.click_here]: 'Click here',
  [ProfileKeys.Guide.Profile.to_start_editing]: 'to start editing',
  [ProfileKeys.Guide.Profile.package_will_be_displated]:
    'Your package will be displayed here!',

  // Profile Edit
  [ProfileKeys.Guide.Profile_Edit.welcome]: 'Welcome to your profile!',
  [ProfileKeys.Guide.Profile_Edit.try_me]: 'Try me to quickly set up',
  [ProfileKeys.Guide.Profile_Edit.compelling_package]: 'a compelling package',
  [ProfileKeys.Guide.Profile_Edit.upload_image]: 'Upload an image to',
  [ProfileKeys.Guide.Profile_Edit.showcase_service]: 'showcase your service',
  [ProfileKeys.Guide.Profile_Edit.add_post_video]: 'Add post/video links',
  [ProfileKeys.Guide.Profile_Edit.for_your_clients]: 'for your clients',
  [ProfileKeys.Guide.Profile_Edit.set_up_price]:
    'Set up a price in different modes.',
  [ProfileKeys.Guide.Profile_Edit.choose_best_option]:
    'Choose the best option for your package!',
  [ProfileKeys.Guide.Profile_Edit.complete_package]: 'Complete your package',
  [ProfileKeys.Guide.Profile_Edit.make_it_public]: 'to make it public',
  [ProfileKeys.Guide.Profile_Edit.save_description1]:
    'Make sure to save before you leave!',
  [ProfileKeys.Guide.Profile_Edit.save_description2]: '',
  [ProfileKeys.Guide.Profile_Edit.save_description3]: '',
  [ProfileKeys.Guide.Profile_Edit.create_unique_package]:
    'Create a unique profile for global clients!',
  [ProfileKeys.Guide.Profile_Edit.quick_setup]: 'Quick setup',

  [ProfileKeys.Guide.Profile_Edit.Toast.register_success]:
    'Successfully registered! Please set up a profile to receive orders.',
};
