export const AnalyticsBarIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 14}
    height={size * 15}
    viewBox="0 0 14 15"
    fill="currentColor"
  >
    <path d="M2.64865 7.87225H0.756757C0.556052 7.87225 0.363568 7.9518 0.221649 8.0934C0.0797295 8.235 0 8.42706 0 8.62731V13.7449C0 13.9452 0.0797295 14.1372 0.221649 14.2788C0.363568 14.4205 0.556052 14.5 0.756757 14.5H2.64865C2.84935 14.5 3.04184 14.4205 3.18376 14.2788C3.32568 14.1372 3.40541 13.9452 3.40541 13.7449V8.62731C3.40541 8.42706 3.32568 8.235 3.18376 8.0934C3.04184 7.9518 2.84935 7.87225 2.64865 7.87225ZM13.2432 5.1562H11.3514C11.1506 5.1562 10.9582 5.23575 10.8162 5.37736C10.6743 5.51896 10.5946 5.71101 10.5946 5.91126V13.7449C10.5946 13.9452 10.6743 14.1372 10.8162 14.2788C10.9582 14.4205 11.1506 14.5 11.3514 14.5H13.2432C13.4439 14.5 13.6364 14.4205 13.7784 14.2788C13.9203 14.1372 14 13.9452 14 13.7449V5.91126C14 5.71101 13.9203 5.51896 13.7784 5.37736C13.6364 5.23575 13.4439 5.1562 13.2432 5.1562ZM7.94595 0.5H6.05405C5.85335 0.5 5.66087 0.579551 5.51895 0.721152C5.37703 0.862753 5.2973 1.05481 5.2973 1.25506V13.7449C5.2973 13.9452 5.37703 14.1372 5.51895 14.2788C5.66087 14.4205 5.85335 14.5 6.05405 14.5H7.94595C8.14665 14.5 8.33913 14.4205 8.48105 14.2788C8.62297 14.1372 8.7027 13.9452 8.7027 13.7449V1.25506C8.7027 1.05481 8.62297 0.862753 8.48105 0.721152C8.33913 0.579551 8.14665 0.5 7.94595 0.5Z" />
  </svg>
);
