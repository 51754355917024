import PricingKeys from '../translationKeys/PricingPageKeys';

export const PricingTranslations = {
  [PricingKeys.preparing]: '(준비 중)',
  [PricingKeys.order_now]: '지금 주문하기',
  [PricingKeys.get_free]: '무료로 프리미엄 패스 획득',
  [PricingKeys.get_for_free]: '무료 등록하기',
  [PricingKeys.current_plan]: '적용중인 플랜',
  [PricingKeys.company_verified]: '기업 인증 완료',

  // Banner
  [PricingKeys.Banner.title]: '마케팅을 간단하고 스마트하게 만들어 보세요!',
  [PricingKeys.Banner.description]:
    '저희 서비스는 마케팅 성과를 더욱 높여줍니다!',

  [PricingKeys.Verified.Error.already_verified]: `
    이미 브랜드 또는 회사로 확인된 계정입니다.`,
  [PricingKeys.Verified.Error.already_using]: `
    이미 이 요금제를 사용 중인 계정입니다. <br>
    <span>여기</span>를 클릭하여 구독 내역을 확인하세요.`,
  [PricingKeys.Verified.Error.under_review]: `
    검토 중인 계정입니다. <br>
    나중에 다시 시도하십시오.`,
  [PricingKeys.Verified.Error
    .recently_rejected]: `귀하의 신청서가 최근 검토되었습니다.<br> 나중에 다시 시도해 주세요.`,

  // Left Col
  // General
  [PricingKeys.Left_Col.General.verification_req]: '인증 필요',
  [PricingKeys.Left_Col.General.smart_search]: '스마트 검색',

  // Analytics & Data
  [PricingKeys.Left_Col.Analytics_Data.title]: '통계 및 데이터',

  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title]: `
  크리에이터 통계 데이터`,
  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip]: `
  크리에이터의 소셜 네트워크 계정의 채널 및 <br>
  콘텐츠 통계입니다.`,

  [PricingKeys.Left_Col.Analytics_Data.audience_analytics]: '주요 시청자 통계',

  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title]: '가격 잠금 해제',
  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip]: `
  월마다 일정 수의 크리에이터의 패키지 <br>
  가격을 잠금 해제할 수 있습니다. <br>
  72시간 후에 가격은 다시 잠깁니다.`,

  [PricingKeys.Left_Col.Analytics_Data.order_analytics]: '주문 데이터 분석',

  [PricingKeys.Left_Col.Analytics_Data.creator_reviews]: '크리에이터 리뷰',

  // Orders
  [PricingKeys.Left_Col.Orders.title]: `주문 & 크리에이터`,
  [PricingKeys.Left_Col.Orders.order_packages]: '크리에이터 패키지 주문하기',

  [PricingKeys.Left_Col.Orders.message_creators]:
    '주문을 시작하기 전에 새로운 크리에이터에게 메시지 보내기',

  [PricingKeys.Left_Col.Orders.Order_Discount.title]: '주문 할인',
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1]: `
  할인은 크리에이터와 함께하는 각 주문의 총 금액에 적용됩니다. <br>
  * 추가 아이템, 배송 비용 및 월 구독에는 적용되지 않습니다.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2]: `
  할인은 크리에이터와 함께 하는 각 주문의 총 가격에 적용됩니다.<br>
  일부 추가 아이템이나 플랫폼 구독에는 적용되지 않습니다.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.content]: `
  주문 가격의  <Tooltip><span>{{number}}</span></Tooltip>`,

  [PricingKeys.Left_Col.Orders.Track_Delivery.title]: `
  국제 배송 추적`,
  [PricingKeys.Left_Col.Orders.Track_Delivery.tooltip]: `
  주문의 국제 배송을 추적하는 데 도움을 드립니다. <br>
  국내 배송 추적은 일부 국가에서 제공됩니다. <br><br>
  * 참고: 실제 배송 과정을 진행하거나 보장하지 않습니다.`,

  [PricingKeys.Left_Col.Orders.auto_translation]: '완전 자동 번역 시스템',

  [PricingKeys.Summary.basic]: `
    <li>무료이지만 강력합니다</li>
    <li>마케팅 성과 향상을 위한 모든 기초 기능이 포함되어 있습니다</li>`,
  [PricingKeys.Summary.verified]: `
    <li>1~3분 만에 인증 받으세요</li>
    <li>글로벌 브랜드와 비즈니스에 적합합니다</li>`,
  [PricingKeys.Summary.premium]: `
    <li>대용량</li>
    <li>확인 필요</li>
    <li>규모 확장에 초점을 맞춘 글로벌 기업에 적합</li>`,

  // Creator
  [PricingKeys.Creator.title]: '얼리 크리에이터를 위한 프리미엄 패스',

  [PricingKeys.Creator.h1]:
    '프리미엄 패스를 통해 크리에이터는 MegaEvolution 서비스 수수료를 면제받습니다.',
  [PricingKeys.Creator.h2]:
    '여러분의 재능을 마음껏 펼칠 수 있는 저희 플랫폼을 사용해보세요!',
  [PricingKeys.Creator.h3]: `
    * 잔고를 출금하기 위해 필요한 금액의 제3자 거래 수수료 (예: PayPal)는 포함되지 않습니다.`,

  // Modal
  [PricingKeys.Modal.title]: '브랜드 · 회사 인증',
  [PricingKeys.Modal.estimate]: '예상 시간 1~2분',
  [PricingKeys.Modal.caution]:
    '* 허위 또는 부정확한 정보는 인증 박탈 또는 계정 영구 차단으로 이어질 수 있습니다.',
  [PricingKeys.Modal.toast]: '성공적으로 인증되었습니다!',

  [PricingKeys.Modal.Btn.proceed_final]: '마지막 단계로 가기',
  [PricingKeys.Modal.Btn.verify_email]: '이메일 인증하기',
  [PricingKeys.Modal.Btn.no_company_email]: '회사 이메일이 없습니다',
  [PricingKeys.Modal.Btn.verify_with_email]: '이메일 인증하기',
  [PricingKeys.Modal.Btn.complete_verification]: '인증 완료하기',
  [PricingKeys.Modal.Btn.another_email]: '다른 이메일 입력하기',
  [PricingKeys.Modal.Btn.resend_code]: '코드 다시 전송하기',

  [PricingKeys.Modal.Placeholder.firstname]: '이름을 입력하세요',
  [PricingKeys.Modal.Placeholder.lastname]: '성을 입력하세요',
  [PricingKeys.Modal.Placeholder.companyname]: '회사 이름을 입력하세요',
  [PricingKeys.Modal.Placeholder.companyUrl_sm]:
    '회사 웹사이트 URL을 입력하세요 ',
  [PricingKeys.Modal.Placeholder.companyEmail]: '회사 이메일을 입력하세요',
  [PricingKeys.Modal.Placeholder.enter_code]: '받은 코드를 입력하세요',

  [PricingKeys.Modal.CompanyType.title]: '회사 유형 선택',
  [PricingKeys.Modal.CompanyType.option_1]: '브랜드 · 비즈니스',
  [PricingKeys.Modal.CompanyType.option_2]: '전자 상거래 스토어',
  [PricingKeys.Modal.CompanyType.option_3]: '마케팅 에이전시',
  [PricingKeys.Modal.CompanyType.option_4]: '기타',

  [PricingKeys.Modal.JobPosition.title]: '직책 선택',
  [PricingKeys.Modal.JobPosition.option_1]: '어시스턴트',
  [PricingKeys.Modal.JobPosition.option_2]: 'C-Level · VP',
  [PricingKeys.Modal.JobPosition.option_3]: '디렉터',
  [PricingKeys.Modal.JobPosition.option_4]: '임원',
  [PricingKeys.Modal.JobPosition.option_5]: '부서장',
  [PricingKeys.Modal.JobPosition.option_6]: '독립 업체',
  [PricingKeys.Modal.JobPosition.option_7]: '매니저 · 팀 리더',
  [PricingKeys.Modal.JobPosition.option_8]: '인턴',
  [PricingKeys.Modal.JobPosition.option_9]: '팀 멤버',

  // Final
  [PricingKeys.Modal.Final.explain]: `
    <li>인증을 위해 다음 중 하나의 파일을 업로드하세요: 사업 등록증, 세금계산서, 직원 신분증 또는 회사의 존재를 확인하는 다른 지원 문서.</li>
    <li>파일은 최소 300dpi의 해상도를 가진 JPG, JPEG, PNG, PDF 형식이어야 합니다.</li>`,
  [PricingKeys.Modal.Final.image_placeholder]:
    'JPG, JPEG, PNG, PDF 형식의 파일을 업로드하세요. 최대 5MB까지 가능합니다.',
  [PricingKeys.Modal.Final.description_placeholder]:
    '귀하의 회사 및 플랫폼을 통해 홍보하려는 제품, 서비스 또는 브랜드에 대해 알려주세요.',
  [PricingKeys.Modal.Final.spam_explain]:
    '* 이메일을 찾을 수 없는 경우 스팸함을 확인해주세요.',

  // Popup
  [PricingKeys.Modal.Popup.title]: '성공적으로 제출되었습니다!',
  [PricingKeys.Modal.Popup.description]:
    '1~3일 정도의 시간이 걸리며 이메일을 통해 결과를 보실 수 있습니다.',

  [PricingKeys.Modal.submission.toast]:
    '제출이 완료되었습니다! 인증에는 일반적으로 1-3 영업일이 소요<br/> 됩니다. 결과가 준비되는대로 곧 알려드리겠습니다.',

  // Error
  [PricingKeys.Modal.Error.required_field]: '필수 항목',
  [PricingKeys.Modal.Error.select_option]: '옵션을 선택하세요',
  [PricingKeys.Modal.Error.valid_url]: '유효한 URL을 입력하세요.',
  [PricingKeys.Modal.Error.valid_email]: '유효한 이메일을 입력하세요',
  [PricingKeys.Modal.Error.email_validation]:
    '개인용 이메일은 사용할 수 없습니다 (예: gmail.com, yahoo.com 등은 허용되지 않습니다)',
  [PricingKeys.Modal.Error.already_verified]: '이미 인증에 사용된 이메일입니다',
  [PricingKeys.Modal.Error.already_sent_code]:
    '코드가 이미 해당 이메일로 전송되었습니다. 이메일을 확인해주세요.',
  [PricingKeys.Modal.Error.invalid_code]: '유효하지 않은 코드입니다',
  [PricingKeys.Modal.Error.invalid_file_format]:
    '잘못된 파일 형식입니다. ({{fileFormats}}를 사용하십시오)',
  [PricingKeys.Modal.Error.total_file_size]:
    '총 파일 크기 제한은 최대 {{maxSize}}입니다',
  [PricingKeys.Modal.Error.send_max_files]:
    '최대 {{maxFiles}}개 파일을 전송할 수 있습니다',
};
