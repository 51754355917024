import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useToggle } from 'react-use';
import Stack from '../../Layout/Stack';
import useUser from 'hooks/useUser';
import { SheildCheckIcon } from 'assets/icons';
import SocialNetwork from './SocialNetwork';
import { useAuth } from 'hooks/useAauth';
import Platform from 'constants/platform';
import IndigoRoundButton from 'components/Basics/Buttons/IndigoRoundButton';
import TextButton from 'components/Basics/Buttons/TextButton';
import AxiosClient from 'components/utilities/AxiosClient';
import { useToast } from 'components/Basics/Toast';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { Trans, useTranslation } from 'react-i18next';
import Popup from 'components/Basics/Popup';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import UserType from 'constants/userType';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import SocialVerifier from 'components/utilities/SocialVerifier';

const unlinkPlatform = (platform) => {
  return AxiosClient.post('/removeLink', { platform });
};

const SocialReconnectionModal = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [isOpen, toggleIsOpen] = useToggle(false);
  const { user, updateUser, currentType, refetch } = useUser();
  const { utils } = useAuth();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const { isMobile } = useResponsiveScreen();

  const links = useMemo(() => {
    const filteredLinks = user?.links?.filter((item) => {
      return (
        item.expired === 1 ||
        item.recentlyAdded === 1 ||
        item.recentlyRemoved === 1
      );
    });

    if (filteredLinks?.length > 0) {
      toggleIsOpen(true);
      const event = new CustomEvent('toggle-patch-note-modal', {
        detail: 'close',
      });
      window.dispatchEvent(event);
      return filteredLinks;
    }
  }, [user?.links, toggleIsOpen]);

  const unlink = useCallback(
    (pl) => {
      const prevLinks = [...links];

      updateUser({
        ...(user || {}),
        links: links.map((link) => {
          if (link.type === pl) {
            return {
              type: link?.type,
              recentlyRemoved: 1,
            };
          }
          return link;
        }),
      });

      unlinkPlatform(pl).catch((err) => {
        if (err.response?.status === 433) {
          setOpenErrorModal('orderInprogress');
        } else {
          toast.error(
            t(ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink)
          );
        }

        updateUser({
          ...user,
          links: prevLinks,
        });
      });
    },
    [links, toast, updateUser, user, t]
  );

  if (!links || currentType === UserType.Client) return null;

  return (
    <>
      <AnimatePresence>
        <Wrapper visible={isOpen}>
          <motion.div
            onClick={(e) => e.stopPropagation()}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
          >
            <Content>
              <Stack
                align={isMobile && 'center'}
                gap={isMobile ? '0.75rem' : 'inherit'}
              >
                <Stack direction="row" align="cenetr">
                  <span className="flex align-items-center justify-content-center">
                    <SheildCheckIcon size={isMobile ? 0.67 : 1} />
                  </span>
                  <h3 className={isMobile ? 'text-17px' : 'sbh3'}>
                    {t(ProfileKeys.Modal.Reconnection.title)}
                  </h3>
                </Stack>
                <span
                  className={`${
                    isMobile ? 'h8 border-top text-indigo-200' : 'h5.5'
                  } border-bottom`}
                >
                  <Trans>{t(ProfileKeys.Modal.Reconnection.description)}</Trans>
                </span>
              </Stack>

              <SocialVerifier
                setLinks={(links) => {
                  updateUser({
                    ...(user || {}),
                    links,
                    availablePlatforms: links?.map?.((link) => link.type),
                  });
                }}
                errorModalPrimaryButtonClick={(platform) => {
                  toggleIsOpen(false);
                  unlink(platform);
                }}
                onErrorClose={() => toggleIsOpen(true)}
              >
                {links?.map((link) => (
                  <SocialNetwork
                    key={link.type}
                    platform={link.type}
                    name={link.name}
                    connected={!!link?.platformUid}
                    expired={!!link?.expired}
                    isInstagramBasic={
                      link.type === Platform.Instagram ? link.isBasic : false
                    }
                    unlink={unlink}
                    isReconnectionModalOpen={(e) => {
                      toggleIsOpen(e);
                    }}
                  />
                ))}
              </SocialVerifier>

              <Stack
                gap={isMobile ? '0.75rem' : '3rem'}
                direction={isMobile ? 'column-reverse' : 'row'}
                align="center"
                margin={isMobile ? '' : '0 auto'}
                width={isMobile ? '100%' : ''}
              >
                <CustomTextButton
                  onClick={async () => {
                    await utils.signOut();
                    toggleIsOpen(false);
                  }}
                >
                  {t(GeneralKeys.logout)}
                </CustomTextButton>
                <IndigoRoundButton
                  size={isMobile ? 'xs' : 'sm'}
                  padding={isMobile ? '0.5rem 0' : '13px 28px'}
                  borderRadius={isMobile ? '6px' : '999px'}
                  className={isMobile ? 'w-100' : ''}
                  onClick={async () => {
                    await refetch();
                    toggleIsOpen(false);
                    const event = new CustomEvent('toggle-patch-note-modal', {
                      detail: 'open',
                    });
                    window.dispatchEvent(event);
                  }}
                  disabled={links.filter((link) => link.expired).length > 0}
                >
                  {t(GeneralKeys.continue)}
                </IndigoRoundButton>
              </Stack>
            </Content>
          </motion.div>
        </Wrapper>
      </AnimatePresence>

      <Popup
        show={openErrorModal === 'orderInprogress'}
        onClose={() => setOpenErrorModal(false)}
        title={t(ProfileKeys.Edit.User_Info.Social_Network.Popup.title)}
        description={
          <Trans>
            {t(ProfileKeys.Edit.User_Info.Social_Network.Popup.description)}
          </Trans>
        }
        buttonText={t(GeneralKeys.return)}
        closeReconnectionModal={() => setOpenErrorModal(false)}
      />
    </>
  );
};

export default SocialReconnectionModal;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10000;
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .border-bottom {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-indigo-25);

    @media ${device.sm} {
      padding-bottom: 1.125rem;
    }
  }

  .border-top {
    border-bottom: 1px solid var(--color-indigo-25);

    @media ${device.sm} {
      padding-top: 1.125rem;
    }
  }

  .text-17px {
    font-size: 17px;
  }
`;

const Content = styled.div`
  width: 920px;
  background: var(--color-white);
  border-radius: 0.625rem;
  padding: 1.875rem 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media ${device.sm} {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    align-items: center;
    text-align: center;
    gap: 1.875rem;
    padding: 1.25rem 1rem;
  }
`;

const CustomTextButton = styled(TextButton).attrs({
  className: 'sbh5',
})`
  @media ${device.sm} {
    text-decoration: underline;
    font-size: 11px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
