import styled from 'styled-components';
import Heading from '../../Typography/Heading';
import Socials from './Socials';
import Logo from '../Logo';
import { useToggle } from 'react-use';
import device from 'styles/device';
import Stack from '../Stack';
import Menus from './Menus';
import IconButton from 'components/Basics/Buttons/IconButton';
import { useNavigate } from 'react-router-dom';
import { EnvelopeIcon } from 'assets/icons';
import useFooterStyles from './useFooterStyles';
import { useTranslation } from 'react-i18next';
import FooterKeys from 'translations/translationKeys/FooterKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import TermsConditionsKeys from 'translations/translationKeys/TermsAndConditionsPageKeys';

const Footer = ({ className, defaultBackground, defaultIsVisible }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { background, isWhite, isVisible } = useFooterStyles({
    defaultBackground,
    defaultIsVisible,
  });

  if (!isVisible) return null;

  return (
    <Wrapper background={background} className={className} id="footer">
      <Container>
        <Main>
          <Stack gap="1.875rem" align="center">
            <IconButton
              onClick={() => {
                navigate('/');
              }}
            >
              <Logo white={isWhite} />
            </IconButton>

            {isWhite ? (
              <Stack
                direction="row"
                align="center"
                justify="center"
                className="hover-underline hidden md-flex"
                role="button"
              >
                <EnvelopeIcon />
                <Heading
                  variant="H6"
                  onClick={() => window.open('mailto:info@megaevolution.io')}
                  size="sm"
                  className="md-block hidden m-0 text-white"
                >
                  {t(FooterKeys.buisiness_inquiry)}
                </Heading>
              </Stack>
            ) : (
              <Stack
                direction="row"
                align="center"
                justify="center"
                className="hover-underline hidden md-flex"
                role="button"
              >
                <EnvelopeIcon />
                <Heading
                  variant="H6"
                  onClick={() => window.open('mailto:info@megaevolution.io')}
                  size="sm"
                  className="md-block hidden m-0"
                >
                  {t(FooterKeys.buisiness_inquiry)}
                </Heading>
              </Stack>
            )}
          </Stack>

          <Menus className="hidden md-flex" />

          <Socials className="md-hidden" white={isWhite} />
        </Main>
        <BottomWrapper>
          <Bottom>
            <Stack>
              <Stack
                className="md-hidden"
                gap="0px"
                direction="row"
                align="center"
                justify="center"
              >
                <TextButton
                  onClick={() => {
                    navigate('/terms-and-conditions');
                  }}
                >
                  {t(LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions)}
                </TextButton>{' '}
                <StraingLine />{' '}
                <TextButton
                  onClick={() => {
                    navigate('/privacy-policy');
                  }}
                >
                  {t(TermsConditionsKeys.Privacy._19_1_l2)}
                </TextButton>
              </Stack>
              <Description>
                © 2024 MegaEvolution Inc. All rights reserved.
                <br />
                <span className="md-hidden">
                  info@megaevolution.io
                  <StraingLine />
                </span>
                8 The Green, STE R, Dover, DE 19901, USA
                <span className="hidden md-inline">
                  <StraingLine />
                  info@megaevolution.io
                </span>
              </Description>
            </Stack>

            <Socials className="hidden md-flex" white={isWhite} />
          </Bottom>
        </BottomWrapper>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ background }) => background};
`;

const Container = styled.footer.attrs({
  className: 'container',
})`
  padding-block: 0;
`;

const Main = styled.div.attrs({
  className: 'footer-main',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  @media ${device.md} {
    flex-direction: row;
    justify-content: space-between;
    padding: 3.125rem 0 2rem;
  }
`;

const BottomWrapper = styled.div`
  padding: 0.75rem;
  border-top: 1px solid var(--color-indigo-25);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  width: 328px;

  @media ${device.md} {
    padding: 1.25rem 0 1.5rem;
    width: 100%;
    align-items: start;
  }
`;

const Bottom = styled.div`
  @media ${device.md} {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
  }
`;

const StraingLine = styled.span.attrs({
  children: ' | ',
})`
  color: var(--color-indigo-50);

  @media ${device.sm} {
    font-size: 9px;
  }
`;

const Description = styled.p.attrs({
  className: 'text-indigo-200 mh14',
})`
  font-size: 15px;
  @media ${device.sm} {
    font-size: 9px;
    text-align: center;
    color: var(--color-indigo-150);
  }
`;

const TextButton = styled.button.attrs({
  className: 'sbh8',
})`
  background: none;
  border: none;
  color: var(--color-indigo-100);
  font-size: 9px;
`;