import { $applyNodeReplacement, DecoratorNode } from 'lexical';
import { lazy, Suspense } from 'react';
import removeImage from './removeImage';

const ImageComponent = lazy(() => import('./ImageComponent'));

function convertImageElement(domNode) {
  const img = domNode;
  if (img.src.startsWith('file:///')) {
    return null;
  }
  const { alt, src, width, height, objectFit } = img;
  const node = $createImageNode({ alt, height, src, width, objectFit });
  return { node };
}

export class ImageNode extends DecoratorNode {
  __src;
  __thumbnail;
  __alt;
  __width;
  __height;
  __maxWidth;
  __objectFit;

  static getType() {
    return 'image';
  }

  static clone(node) {
    return new ImageNode(
      node.__src,
      node.__thumbnail,
      node.__alt,
      node.__maxWidth,
      node.__width,
      node.__height,
      node.__objectFit,
      node.__key
    );
  }

  static importJSON(serializedNode) {
    const { alt, height, width, maxWidth, src, thumbnail, objectFit } =
      serializedNode;
    const node = $createImageNode({
      src,
      thumbnail,
      alt,
      width,
      height,
      maxWidth,
      objectFit,
    });

    return node;
  }

  exportDOM() {
    const element = document.createElement('img');
    element.setAttribute('src', this.__src);
    element.setAttribute('alt', this.__alt);
    element.setAttribute('width', this.__width.toString());
    element.setAttribute('height', this.__height.toString());
    if (this.__objectFit)
      element.setAttribute('style', `object-fit: ${this.__objectFit}`);
    return { element };
  }

  static importDOM() {
    return {
      img: (node) => ({
        conversion: convertImageElement,
        priority: 0,
      }),
    };
  }

  constructor(src, thumbnail, alt, maxWidth, width, height, objectFit, key) {
    super(key);
    this.__src = src;
    this.__thumbnail = thumbnail;
    this.__alt = alt;
    this.__maxWidth = maxWidth;
    this.__width = width || 'inherit';
    this.__height = height || 'inherit';
    this.__objectFit = objectFit;
  }

  exportJSON() {
    return {
      src: this.getSrc(),
      thumbnail: this.getThumbnail(),
      alt: this.getAlt(),
      width: this.__width === 'inherit' ? 0 : this.__width,
      height: this.__height === 'inherit' ? 0 : this.__height,
      maxWidth: this.__maxWidth,
      objectFit: this.__objectFit,
      type: 'image',
      version: 1,
    };
  }

  setWidthAndHeight(width, height) {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setObjectFit(objectFit) {
    const writable = this.getWritable();
    writable.__objectFit = objectFit;
  }

  // View
  createDOM(config) {
    const span = document.createElement('span');
    const theme = config.theme;
    const className = theme.image;
    if (className !== undefined) {
      span.className = className;
    }
    return span;
  }

  remove(preserveEmptyParent) {
    super.remove(preserveEmptyParent);
    removeImage(this.__src, this.__thumbnail);
  }

  updateDOM() {
    return false;
  }

  getSrc() {
    return this.__src;
  }

  getThumbnail() {
    return this.__thumbnail;
  }

  getAlt() {
    return this.__alt;
  }

  decorate() {
    return (
      <Suspense fallback={null}>
        <ImageComponent
          src={this.__src}
          thumbnail={this.__thumbnail}
          alt={this.__alt}
          width={this.__width}
          height={this.__height}
          maxWidth={this.__maxWidth}
          objectFit={this.__objectFit}
          nodeKey={this.getKey()}
        />
      </Suspense>
    );
  }
}

export function $createImageNode({
  src,
  thumbnail,
  alt,
  height,
  maxWidth = '100%',
  width,
  objectFit,
  key,
}) {
  return $applyNodeReplacement(
    new ImageNode(src, thumbnail, alt, maxWidth, width, height, objectFit, key)
  );
}

export function $isImageNode(node) {
  return node instanceof ImageNode;
}
