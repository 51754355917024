import Popup from 'components/Basics/Popup';
import dayjs from 'dayjs';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import clientPlans from 'constants/clientPlans';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

const UnlockPriceQuotaEndedModal = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [isOpen, toggleIsOpen] = useState();
  const [passedData, setPassedData] = useState({});

  useEffect(() => {
    const catchModalClose = (e) => {
      if (e?.detail?.event) {
        toggleIsOpen(true);
        setPassedData(e?.detail?.data);
      }
      e.stopImmediatePropagation();
    };

    window.addEventListener('open-unlock-price-quota-modal', catchModalClose);

    return () => {
      window.removeEventListener(
        'open-unlock-price-quota-modal',
        catchModalClose
      );
    };
  }, []);

  return (
    <Popup
      show={isOpen}
      onClose={() => toggleIsOpen(false)}
      title={t(data[user?.clientPlan?.currentPlan]?.title)}
      description={
        <Trans
          values={{
            date: dayjs(user?.clientPlan?.renewalDate).format(
              dayjs.Ls[i18n.language].dateFormat.dot_date
            ),
          }}
          components={{ span: <span className="hidden md-inline"></span> }}
        >
          {t(data[user?.clientPlan?.currentPlan]?.description)}
        </Trans>
      }
      buttonText={t(data[user?.clientPlan?.currentPlan]?.buttonText)}
      onButtonClick={() => {
        toggleIsOpen(false);
        window.open('/pricing', '_blank');
      }}
      secondaryButtonText={t(ProfileKeys.Modal.Request_Price.title)}
      onSecondaryButtonClick={() => {
        passedData?.openRequestPrice?.();
        toggleIsOpen(false);
      }}
    />
  );
};

export default UnlockPriceQuotaEndedModal;

const data = {
  [clientPlans.Basic]: {
    title: ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title,
    description:
      ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description,
    buttonText: GeneralKeys.continue,
  },
  [clientPlans.Verified]: {
    title: ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title,
    description:
      ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
        .description,
    buttonText: ProfileKeys.Edit.Package.Price.Modal.btn,
  },
};
