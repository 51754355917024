export const EnvelopeIconRounded = ({ size = 1}) => (
  <svg
    width={size * 20}
    height={size * 16}
    viewBox="0 0 20 16"
    stroke="currentColor"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="0.799805"
      width="18"
      height="14.4"
      rx="4"
      strokeWidth="1.5"
    />
    <path
      d="M4.60001 5.2998L8.80001 8.4498C9.51112 8.98314 10.4889 8.98314 11.2 8.4498L15.4 5.2998"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
