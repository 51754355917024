import styled from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import Error from './Error';
import InputField from './InputField';
import { useTranslation } from 'react-i18next';

const Content = ({
  name,
  placeholder,
  customComponent,
  renderOnRight,
  ...rest
}) => {
  const { t } = useTranslation();
  const { formState } = useFormContext();
  const error = formState.errors[name];

  return (
    <Container>
      <AnimatePresence>
        {error?.message && <Error>{t(error.message)}</Error>}
      </AnimatePresence>
      {customComponent ? (
        customComponent
      ) : (
        <InputField
          name={name}
          placeholder={placeholder}
          renderOnRight={renderOnRight}
          {...rest}
        />
      )}
    </Container>
  );
};

export default Content;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: end;
`;
