export const SwitchIcon = ({ size = 1 }) => (
  <svg
    width={16 * size}
    height={13 * size}
    viewBox="0 0 16 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.8009 4.86822C12.8009 4.65187 12.7166 4.44438 12.5665 4.2914C12.4165 4.13842 12.2129 4.05247 12.0007 4.05247H2.72704L4.56738 2.1844C4.71805 2.03079 4.80269 1.82246 4.80269 1.60522C4.80269 1.38799 4.71805 1.17965 4.56738 1.02604C4.41671 0.872429 4.21235 0.786133 3.99927 0.786133C3.78619 0.786133 3.58184 0.872429 3.43117 1.02604L0.230591 4.28904C0.119565 4.40375 0.044354 4.54942 0.0144493 4.70767C-0.0154554 4.86592 0.00128575 5.02965 0.0625604 5.17821C0.122587 5.32718 0.224524 5.4547 0.355524 5.54471C0.486524 5.63471 0.640725 5.68317 0.798694 5.68397H12.0007C12.2129 5.68397 12.4165 5.59803 12.5665 5.44504C12.7166 5.29206 12.8009 5.08457 12.8009 4.86822ZM15.9374 7.82124C15.8774 7.67227 15.7755 7.54474 15.6445 7.45474C15.5135 7.36473 15.3593 7.31628 15.2013 7.31547H3.99927C3.78706 7.31547 3.58354 7.40142 3.43349 7.5544C3.28343 7.70738 3.19913 7.91487 3.19913 8.13122C3.19913 8.34757 3.28343 8.55506 3.43349 8.70805C3.58354 8.86103 3.78706 8.94697 3.99927 8.94697H13.273L11.4326 10.815C11.3576 10.8909 11.2981 10.9811 11.2575 11.0805C11.2169 11.1799 11.1959 11.2865 11.1959 11.3942C11.1959 11.5019 11.2169 11.6085 11.2575 11.7079C11.2981 11.8073 11.3576 11.8976 11.4326 11.9734C11.507 12.0499 11.5955 12.1106 11.693 12.152C11.7905 12.1934 11.8951 12.2147 12.0007 12.2147C12.1064 12.2147 12.2109 12.1934 12.3084 12.152C12.4059 12.1106 12.4944 12.0499 12.5688 11.9734L15.7694 8.71041C15.8804 8.59569 15.9556 8.45002 15.9856 8.29177C16.0155 8.13353 15.9987 7.9698 15.9374 7.82124Z" />
  </svg>
);
