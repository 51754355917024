import Image from 'components/Basics/Image';
import Stack from 'components/Basics/Layout/Stack';
import Heading from 'components/Basics/Typography/Heading';
import { motion } from 'framer-motion';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import styled from 'styled-components';
import device from 'styles/device';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const Company = ({ company, companyLogo }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();

  if (!company) return null;

  return (
    <Wrapper>
      <Heading
        variant={`${isMobile ? 'sbh8' : 'sbh6'}`}
        color={isMobile ? 'var(--color-indigo-100)' : ''}
      >
        {t(GeneralKeys.company)}
      </Heading>

      <Stack direction="row" gap="0.625rem" align="center">
        {companyLogo && isMobile && (
          <Image src={companyLogo} width="30px" height="30px" circle />
        )}
        <span className={`${isMobile ? 'sbh7' : 'text-sm'}`}>{company}</span>
      </Stack>
    </Wrapper>
  );
};

export default Company;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  exit: { opacity: 0, y: 0 },
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (${device.sm}) {
    gap: 0.75rem;
  }
`;
