const PrivacyPolicyKeys = {
  title: 'PrivacyPolicyKeys.title',

  top_text: 'PrivacyPolicyKeys.top_text',

  Disclosure_Regarding_Google_APIs: {
    title: 'PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.title',

    Description: {
      l1: 'PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description.l1',
      l2: 'PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description.l2',
      l3: 'PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description.l3',
    },
  },

  Who_We_Are_And_How_To_Contact_Us: {
    title: 'PrivacyPolicyKeys.Who_We_Are_And_How_To_Contact_Us.title',

    description:
      'PrivacyPolicyKeys.Who_We_Are_And_How_To_Contact_Us.description',
  },

  How_Do_We_Use_Your_Personal_Information: {
    title: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.title',

    Description: {
      d1: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.d1',
      l1: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l1',
      l2: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l2',
      l3: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l3',
      l4: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l4',
      d2: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.d2',
      d3: 'PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.d3',
    },
  },

  Personal_Information_You_Provide_To_Us_Directly: {
    title:
      'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.title',

    A: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.A.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.A.description',
    },

    B: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.B.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.B.description',
    },

    C: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.C.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.C.description',
    },

    D: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.D.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.D.description',
    },

    E: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.E.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.E.description',
    },

    F: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.F.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.F.description',
    },
  },

  Personal_Information_You_Provide_To_Us_Automatically: {
    title:
      'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.title',

    A: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A.title',
      Description: {
        l1: 'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A.Description.l1',
        l2: 'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A.Description.l2',
        l3: 'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A.Description.l3',
      },
    },

    B: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.B.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.B.description',
    },

    C: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.C.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.C.description',
    },

    D: {
      title:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.D.title',
      description:
        'PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.D.description',
    },
  },

  _3rd_Party_Services_Links: {
    title: 'PrivacyPolicyKeys._3rd_Party_Services_Links.title',

    A: {
      title: 'PrivacyPolicyKeys._3rd_Party_Services_Links.A.title',
      description: 'PrivacyPolicyKeys._3rd_Party_Services_Links.A.description',
    },

    B: {
      title: 'PrivacyPolicyKeys._3rd_Party_Services_Links.B.title',
      description: 'PrivacyPolicyKeys._3rd_Party_Services_Links.B.description',
    },

    C: {
      title: 'PrivacyPolicyKeys._3rd_Party_Services_Links.C.title',
      description: 'PrivacyPolicyKeys._3rd_Party_Services_Links.C.description',
      Description: {
        d1: 'PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description.d1',
        d2: 'PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description.d2',
        d3: 'PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description.d3',
        d4: 'PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description.d4',
        d5: 'PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description.d5',
      },
    },

    D: {
      title: 'PrivacyPolicyKeys._3rd_Party_Services_Links.D.title',
      description: 'PrivacyPolicyKeys._3rd_Party_Services_Links.D.description',
    },
  },

  Disclosure_To_Third_Parties: {
    title: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.title',

    Description: {
      l1: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l1',
      l2: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l2',
      l3: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l3',
      l4: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l4',
      l5: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l5',
      l6: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l6',
      l7: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l7',
      l8: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l8',
      l9: 'PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description.l9',
    },
  },

  Marketing: {
    title: 'PrivacyPolicyKeys.Marketing.title',

    description: 'PrivacyPolicyKeys.Marketing.description',
  },

  Direct_Marketing_Disclosures: {
    title: 'PrivacyPolicyKeys.Direct_Marketing_Disclosures.title',

    description: 'PrivacyPolicyKeys.Direct_Marketing_Disclosures.description',
  },

  Automated_Decision_Making: {
    title: 'PrivacyPolicyKeys.Automated_Decision_Making.title',

    description: 'PrivacyPolicyKeys.Automated_Decision_Making.description',
  },

  Data_Of_Children: {
    title: 'PrivacyPolicyKeys.Data_Of_Children.title',

    description: 'PrivacyPolicyKeys.Data_Of_Children.description',
  },

  Do_Not_Sell: {
    title: 'PrivacyPolicyKeys.Do_Not_Sell.title',

    description: 'PrivacyPolicyKeys.Do_Not_Sell.description',
  },

  Data_Security: {
    title: 'PrivacyPolicyKeys.Data_Security.title',

    description: 'PrivacyPolicyKeys.Data_Security.description',
  },

  Your_Rights_Under_Data_Protection_Legislation: {
    title:
      'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.title',

    Description: {
      l1: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l1',
      l2: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l2',
      l3: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l3',
      l4: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l4',
      l5: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l5',
      l6: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l6',
      l7: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l7',
      l8: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l8',
      l9: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l9',
      l10: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l10',
      l11: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l11',
      l12: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l12',
      l13: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l13',
      l14: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l14',
      l15: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l15',
      l16: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l16',
      l17: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l17',
      l18: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l18',
      l19: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l19',
      l20: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l20',
      l21: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l21',
      l22: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l22',
      l23: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l23',
      l24: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l24',
      l25: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l25',
      l26: 'PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description.l26',
    },
  },

  Updating_Your_Information: {
    title: 'PrivacyPolicyKeys.Updating_Your_Information.title',

    description: 'PrivacyPolicyKeys.Updating_Your_Information.description',
  },

  Access_Request_And_Updating_Your_Personal_Information: {
    title:
      'PrivacyPolicyKeys.Access_Request_And_Updating_Your_Personal_Information.title',

    description:
      'PrivacyPolicyKeys.Access_Request_And_Updating_Your_Personal_Information.description',
  },

  Controls_For_Do_Not_Track_Features: {
    title: 'PrivacyPolicyKeys.Controls_For_Do_Not_Track_Features.title',

    description:
      'PrivacyPolicyKeys.Controls_For_Do_Not_Track_Features.description',
  },

  Does_This_Policy_Change: {
    title: 'PrivacyPolicyKeys.Does_This_Policy_Change.title',

    description: 'PrivacyPolicyKeys.Does_This_Policy_Change.description',
  },

  Who_Should_I_Contact_For_More_Information: {
    title: 'PrivacyPolicyKeys.Who_Should_I_Contact_For_More_Information.title',

    description:
      'PrivacyPolicyKeys.Who_Should_I_Contact_For_More_Information.description',
  },
};

export default PrivacyPolicyKeys;
