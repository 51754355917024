import styled from 'styled-components';
import { SnippetOptionsNames } from './SnippetOptions';
import { useSnippetValues } from './SnippetValuesContext';
import { useTranslation } from 'react-i18next';

const SnippetComponent = ({ name }) => {
  const snippets = useSnippetValues();
  const { t } = useTranslation();

  const value = snippets[name];

  if (value)
    return <Snippet>{Array.isArray(value) ? value.join(', ') : value}</Snippet>;

  return (
    <Snippet>
      {'{{'}
      {t(SnippetOptionsNames[name]) ?? name}
      {'}}'}
    </Snippet>
  );
};

export default SnippetComponent;

const Snippet = styled.span.attrs({
  className: 'snippet',
})`
  font-weight: bold;
`;
