export const ChevronUpIcon = ({ size = 1, style }) => (
  <svg
    width={size * 13}
    height={size * 8}
    viewBox="0 0 13 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'rotate(180deg)', ...style }}
  >
    <path d="M12.6532 0.733789C12.183 0.267122 11.4777 0.267122 11.0076 0.733789L6.89353 4.81712L2.7795 0.733789C2.30932 0.267122 1.60406 0.267122 1.13388 0.733789C0.663706 1.20046 0.663706 1.90046 1.13388 2.36712L6.07072 7.26712C6.30581 7.50046 6.5409 7.61712 6.89353 7.61712C7.24616 7.61712 7.48125 7.50046 7.71634 7.26712L12.6532 2.36712C13.1234 1.90046 13.1234 1.20046 12.6532 0.733789Z" />
  </svg>
);
