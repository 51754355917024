const EvoMissionsKeys = {
  claim_evos: 'EvoMissionsKeys.claim_evos',
  my_evos: 'EvoMissionsKeys.my_evos',
  special_missions: 'EvoMissionsKeys.special_missions',
  creator_missions: 'EvoMissionsKeys.creator_missions',
  general_missions: 'EvoMissionsKeys.general_missions',
  successfully_claimed: 'EvoMissionsKeys.successfully_claimed',
  go_back_home: 'EvoMissionsKeys.go_back_home',

  Token_Details: {
    _1k: 'EvoMissionsKeys.Token_Details._1k',
    _10k: 'EvoMissionsKeys.Token_Details._10k',
    no_data: 'EvoMissionsKeys.Token_Details.no_data',
    results_found: 'EvoMissionsKeys.Token_Details.results_found',
    result_found: 'EvoMissionsKeys.Token_Details.result_found',
  },

  Side_Popup: {
    text: 'EvoMissionsKeys.Side_Popup.text',
  },

  Transaction: {
    daily_reward: 'EvoMissionsKeys.Transaction.daily_reward',
    price_unlock_received: 'EvoMissionsKeys.Transaction.price_unlock_received',
    price_unlock_spent: 'EvoMissionsKeys.Transaction.price_unlock_spent',
    referral_reward: 'EvoMissionsKeys.Transaction.referral_reward',
    creator_registration_reward:
      'EvoMissionsKeys.Transaction.creator_registration_reward',

    complete_1_special_completed:
      'EvoMissionsKeys.Transaction.complete_1_special_completed',
    complete_777_special_completed:
      'EvoMissionsKeys.Transaction.complete_777_special_completed',
    complete_77_special_completed:
      'EvoMissionsKeys.Transaction.complete_77_special_completed',
    complete_7_special_completed:
      'EvoMissionsKeys.Transaction.complete_7_special_completed',
    create_premium_package_completed:
      'EvoMissionsKeys.Transaction.create_premium_package_completed',
    first_990_creator_claimed_completed:
      'EvoMissionsKeys.Transaction.first_990_creator_claimed_completed',
    refer_1000_early_vips_completed:
      'EvoMissionsKeys.Transaction.refer_1000_early_vips_completed',
    refer_100_early_vips_completed:
      'EvoMissionsKeys.Transaction.refer_100_early_vips_completed',
    refer_10_early_vips_completed:
      'EvoMissionsKeys.Transaction.refer_10_early_vips_completed',
    referral_ranking_completed:
      'EvoMissionsKeys.Transaction.referral_ranking_completed',
    special_daily_reward_completed:
      'EvoMissionsKeys.Transaction.special_daily_reward_completed',
    tv_easter_egg_completed:
      'EvoMissionsKeys.Transaction.tv_easter_egg_completed',
    update_creator_profile_completed:
      'EvoMissionsKeys.Transaction.update_creator_profile_completed',
    update_profile_completed:
      'EvoMissionsKeys.Transaction.update_profile_completed',
    visit_main_tabs_completed:
      'EvoMissionsKeys.Transaction.visit_main_tabs_completed',
  },

  Special: {
    early_990_vips: {
      title: 'EvoMissionsKeys.Special.early_990_vips.title',
      description: 'EvoMissionsKeys.Special.early_990_vips.description',

      Tooltip: {
        d1: 'EvoMissionsKeys.Special.early_990_vips.Tooltip.d1',
        d2: 'EvoMissionsKeys.Special.early_990_vips.Tooltip.d2',
        d3: 'EvoMissionsKeys.Special.early_990_vips.Tooltip.d3',

        heading: 'EvoMissionsKeys.Special.early_990_vips.Tooltip.heading',
      },
    },
  },

  // Genearl
  complete_1_special: {
    title: 'EvoMissionsKeys.complete_1_special.title',
    description: 'EvoMissionsKeys.complete_1_special.description',
    extra_reward: 'EvoMissionsKeys.complete_1_special.extra_reward',
    reward_description: 'EvoMissionsKeys.complete_1_special.reward_description',
  },

  complete_777_special: {
    title: 'EvoMissionsKeys.complete_777_special.title',
    description: 'EvoMissionsKeys.complete_777_special.description',
    extra_reward: 'EvoMissionsKeys.complete_777_special.extra_reward',
    reward_description:
      'EvoMissionsKeys.complete_777_special.reward_description',
  },

  complete_77_special: {
    title: 'EvoMissionsKeys.complete_77_special.title',
    description: 'EvoMissionsKeys.complete_77_special.description',
    extra_reward: 'EvoMissionsKeys.complete_77_special.extra_reward',
    reward_description:
      'EvoMissionsKeys.complete_77_special.reward_description',
  },

  complete_7_special: {
    title: 'EvoMissionsKeys.complete_7_special.title',
    description: 'EvoMissionsKeys.complete_7_special.description',
    extra_reward: 'EvoMissionsKeys.complete_7_special.extra_reward',
    reward_description: 'EvoMissionsKeys.complete_7_special.reward_description',
  },

  tv_easter_egg: {
    title: 'EvoMissionsKeys.tv_easter_egg.title',
    description: 'EvoMissionsKeys.tv_easter_egg.description',
    extra_reward: 'EvoMissionsKeys.tv_easter_egg.extra_reward',
    reward_description: 'EvoMissionsKeys.tv_easter_egg.reward_description',
  },

  update_profile: {
    title: 'EvoMissionsKeys.update_profile.title',
    description: 'EvoMissionsKeys.update_profile.description',
    extra_reward: 'EvoMissionsKeys.update_profile.extra_reward',
    reward_description: 'EvoMissionsKeys.update_profile.reward_description',
  },

  visit_main_tabs: {
    title: 'EvoMissionsKeys.visit_main_tabs.title',
    description: 'EvoMissionsKeys.visit_main_tabs.description',
    extra_reward: 'EvoMissionsKeys.visit_main_tabs.extra_reward',
    reward_description: 'EvoMissionsKeys.visit_main_tabs.reward_description',
  },

  // Creator
  create_premium_package: {
    title: 'EvoMissionsKeys.create_premium_package.title',
    description: 'EvoMissionsKeys.create_premium_package.description',
    extra_reward: 'EvoMissionsKeys.create_premium_package.extra_reward',
    reward_description:
      'EvoMissionsKeys.create_premium_package.reward_description',
  },

  update_creator_profile: {
    title: 'EvoMissionsKeys.update_creator_profile.title',
    description: 'EvoMissionsKeys.update_creator_profile.description',
    extra_reward: 'EvoMissionsKeys.update_creator_profile.extra_reward',
    reward_description:
      'EvoMissionsKeys.update_creator_profile.reward_description',
  },

  // Special
  first_990_creator_claimed: {
    title: 'EvoMissionsKeys.first_990_creator_claimed.title',
    description: 'EvoMissionsKeys.first_990_creator_claimed.description',
    extra_reward: 'EvoMissionsKeys.first_990_creator_claimed.extra_reward',
    reward_description:
      'EvoMissionsKeys.first_990_creator_claimed.reward_description',
  },

  refer_1000_early_vips: {
    title: 'EvoMissionsKeys.refer_1000_early_vips.title',
    description: 'EvoMissionsKeys.refer_1000_early_vips.description',
    extra_reward: 'EvoMissionsKeys.refer_1000_early_vips.extra_reward',
    reward_description:
      'EvoMissionsKeys.refer_1000_early_vips.reward_description',
  },

  refer_100_early_vips: {
    title: 'EvoMissionsKeys.refer_100_early_vips.title',
    description: 'EvoMissionsKeys.refer_100_early_vips.description',
    extra_reward: 'EvoMissionsKeys.refer_100_early_vips.extra_reward',
    reward_description:
      'EvoMissionsKeys.refer_100_early_vips.reward_description',
  },

  refer_10_early_vips: {
    title: 'EvoMissionsKeys.refer_10_early_vips.title',
    description: 'EvoMissionsKeys.refer_10_early_vips.description',
    extra_reward: 'EvoMissionsKeys.refer_10_early_vips.extra_reward',
    reward_description:
      'EvoMissionsKeys.refer_10_early_vips.reward_description',
  },

  referral_ranking: {
    title: 'EvoMissionsKeys.referral_ranking.title',
    description: 'EvoMissionsKeys.referral_ranking.description',
    extra_reward: 'EvoMissionsKeys.referral_ranking.extra_reward',
    reward_description: 'EvoMissionsKeys.referral_ranking.reward_description',
  },

  special_daily_reward: {
    title: 'EvoMissionsKeys.special_daily_reward.title',
    description: 'EvoMissionsKeys.special_daily_reward.description',
    extra_reward: 'EvoMissionsKeys.special_daily_reward.extra_reward',
    reward_description:
      'EvoMissionsKeys.special_daily_reward.reward_description',
  },

  Modal: {
    already_unlock: 'EvoMissionsKeys.Modal.already_unlock',
    easter_egg: 'EvoMissionsKeys.Modal.easter_egg',
    unlock_premium_pass: 'EvoMissionsKeys.Modal.unlock_premium_pass',
    visit_profile: 'EvoMissionsKeys.Modal.visit_profile',
    not_now: 'EvoMissionsKeys.Modal.not_now',
  },
};

export default EvoMissionsKeys;
