export const UploadIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 24}
      height={size * 24}
      viewBox="0 0 24 24"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8.052 6.84772L10.8 4.08704V15.5979C10.8 15.9162 10.9264 16.2215 11.1515 16.4466C11.3765 16.6717 11.6817 16.7982 12 16.7982C12.3183 16.7982 12.6235 16.6717 12.8485 16.4466C13.0736 16.2215 13.2 15.9162 13.2 15.5979V4.08704L15.948 6.84772C16.0596 6.96023 16.1923 7.04952 16.3385 7.11046C16.4847 7.1714 16.6416 7.20277 16.8 7.20277C16.9584 7.20277 17.1153 7.1714 17.2615 7.11046C17.4077 7.04952 17.5404 6.96023 17.652 6.84772C17.7645 6.73614 17.8537 6.60339 17.9147 6.45712C17.9756 6.31085 18.007 6.15396 18.007 5.99551C18.007 5.83706 17.9756 5.68017 17.9147 5.5339C17.8537 5.38764 17.7645 5.25488 17.652 5.1433L12.852 0.342102C12.7379 0.232826 12.6033 0.147166 12.456 0.0900387C12.1638 -0.0300129 11.8362 -0.0300129 11.544 0.0900387C11.3967 0.147166 11.2621 0.232826 11.148 0.342102L6.348 5.1433C6.23611 5.25521 6.14736 5.38807 6.08681 5.5343C6.02626 5.68052 5.99509 5.83724 5.99509 5.99551C5.99509 6.15378 6.02626 6.3105 6.08681 6.45673C6.14736 6.60295 6.23611 6.73581 6.348 6.84772C6.45989 6.95964 6.59271 7.04841 6.7389 7.10898C6.88509 7.16955 7.04177 7.20072 7.2 7.20072C7.35823 7.20072 7.51491 7.16955 7.6611 7.10898C7.80729 7.04841 7.94011 6.95964 8.052 6.84772ZM22.8 11.997C22.4817 11.997 22.1765 12.1235 21.9515 12.3486C21.7264 12.5737 21.6 12.879 21.6 13.1973V20.3991C21.6 20.7174 21.4736 21.0227 21.2485 21.2478C21.0235 21.4729 20.7183 21.5994 20.4 21.5994H3.6C3.28174 21.5994 2.97652 21.4729 2.75147 21.2478C2.52643 21.0227 2.4 20.7174 2.4 20.3991V13.1973C2.4 12.879 2.27357 12.5737 2.04853 12.3486C1.82348 12.1235 1.51826 11.997 1.2 11.997C0.88174 11.997 0.576515 12.1235 0.351472 12.3486C0.126428 12.5737 0 12.879 0 13.1973V20.3991C0 21.3541 0.379285 22.27 1.05442 22.9453C1.72955 23.6206 2.64522 24 3.6 24H20.4C21.3548 24 22.2705 23.6206 22.9456 22.9453C23.6207 22.27 24 21.3541 24 20.3991V13.1973C24 12.879 23.8736 12.5737 23.6485 12.3486C23.4235 12.1235 23.1183 11.997 22.8 11.997Z" />
    </svg>
  );
};
