import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: 'saving',
  [ProposalKeys.changes_saved]: 'changes saved',

  [ProposalKeys.New_Proposal.text]: 'New proposal',
  [ProposalKeys.New_Proposal.toast]:
    'You have reached the max number of proposals you can create.',

  [ProposalKeys.Clear.text]: 'Clear All',
  [ProposalKeys.Clear.toast]: `
    Successfully cleared. <button>Click here</button> to undo this change.`,

  [ProposalKeys.Modal.Copy.title]:
    'Are you sure you want to overwrite the proposal?',
  [ProposalKeys.Modal.Copy.description]: `
    The current proposal will be overwritten by the selected proposal. <br>
    This action cannot be undone. Do you want to continue?`,

  [ProposalKeys.Part1.Option_1.title]: 'Title',
  [ProposalKeys.Part1.Option_1.placeholder]: 'Enter project title',
  [ProposalKeys.Part1.Option_1.error]: 'Max 100 characters',

  [ProposalKeys.Part1.Option_2.title]: 'Product · Brand Website',
  [ProposalKeys.Part1.Option_2.placeholder]: 'Enter a URL',
  [ProposalKeys.Part1.Option_2.error]: 'Max 400 characters',

  [ProposalKeys.Part1.Option_3.title]: 'Description & Files',
  [ProposalKeys.Part1.Option_3.placeholder]: `
    Please describe the details of project and attach your files here. <br>
    Creators may look for information regarding, <br><br><br>
    <ul>
    <li>Project/Product description</li>
    <li>Campaign goals and target audience</li>
    <li>Detailed content guidelines</li>
    </ul>`,
  [ProposalKeys.Part1.Option_3.Snippet.title]: 'Select a snippet to add',
  [ProposalKeys.Part1.Option_3.Snippet.option_1]:
    'Creator’s social channel name',
  [ProposalKeys.Part1.Option_3.Snippet.option_2]: 'Creator’s username',
  [ProposalKeys.Part1.Option_3.Snippet.option_3]: 'Creator’s main country',
  [ProposalKeys.Part1.Option_3.Snippet.option_4]: 'Creator’s top categories',

  [ProposalKeys.Part1.Option_3.Error.file_format]: `
    Invalid file format. (Please use {{fileFormats}})`,
  [ProposalKeys.Part1.Option_3.Error.file_size_limit]: `
    Total file size limit is max {{maxSize}}`,
  [ProposalKeys.Part1.Option_3.Error.max_file]: `
    You can send max {{maxFiles}} files`,

  [ProposalKeys.caution]: `
    Upon ordering a package, all applicable <strong>requirements and content missions</strong> will be <strong>automatically applied</strong> to each content. <strong>Incomplete</strong> or <strong>non-applicable</strong> ones to the ordered content will not be applied.`,

  [ProposalKeys.Side_btn.text]: 'Applicable Contents',
  [ProposalKeys.Side_btn.title]: 'Applicable Contents : ',

  [ProposalKeys.Part2.title]: 'Send a Product to the Creator',

  [ProposalKeys.Part2.Option_1.title]: 'Send a sponsored product',
  [ProposalKeys.Part2.Option_1.tooltip]: `
    This option is incomplete and will not <br>
    be applied to the proposals being sent.`,

  [ProposalKeys.Part2.Option_1.Image.title]: 'Delivery Images (Optional)',
  [ProposalKeys.Part2.Option_1.Image.description]:
    'Add up to 5 images files in JPG, JPEG, PNG, GIF format. Max 100 MB.',

  [ProposalKeys.Part2.Option_1.Info.title]: 'Delivery Information (Required)',
  [ProposalKeys.Part2.Option_1.Info.placeholder]:
    'Please provide information about the product and shipping.',

  [ProposalKeys.Part2.Option_1.caution_1]:
    'You will receive the creator’s mailing address for product delivery. Content creation will begin upon arrival of the product, and the due date will be updated accordingly',
  [ProposalKeys.Part2.Option_1.caution_2]:
    'We do not process returns for delivered products. If your order was canceled after the product was shipped, you will need to discuss the return directly with the creator.',
  [ProposalKeys.Part2.Option_1.caution_3]: `
    We help track international delivery, but do not guarantee or cover shipping issues such as mis-delivery.
    <Tooltip>Domestic delivery</Tooltip> is trackable for only a few countries but you can still include it in the order.`,
  [ProposalKeys.Part2.Option_1.caution_3_tooltip]: `
    We support domestic tracking for major carriers <br>
    in limited countries, including the US and Canada. <br>
    We currently do not support domestic tracking <br>
    for other countries.`,

  [ProposalKeys.Part3.title]: 'Requirements',
  [ProposalKeys.Part3.applied]: '{{number}} applied',

  [ProposalKeys.Part3.Option_1.title]: 'Indicate paid promotion',
  [ProposalKeys.Part3.Option_1.description_1]:
    'One of the following hashtags: #Ad, #Advertisement, or #Sponsored will be placed at the beginning of the list of hashtags by the creator to clearly indicate paid promotion.',
  [ProposalKeys.Part3.Option_1.description_2]:
    'Add the "Includes paid promotion" label provided by YouTube inside the video.',
  [ProposalKeys.Part3.Option_1.description_3]:
    'Add the"Paid partnership" label provided by Instagram inside the Instagram Post/Story/Reels.',

  [ProposalKeys.Part3.Option_2.placeholder]:
    'Please enter your terms and conditions here.',
  [ProposalKeys.Part3.Option_2.description]:
    "The creator will be required to adhere to your Terms and Conditions for the provision of the service. Revision or deactivation of this option may be requested if it contradicts the creator's service.",

  [ProposalKeys.Part4.title]: 'Content Missions',

  [ProposalKeys.Part4.Option_1.title]: 'Include a Link',
  [ProposalKeys.Part4.Option_1.placeholder]: 'Enter a URL',
  [ProposalKeys.Part4.Option_2.btn]: 'Add this Hashtag',
  [ProposalKeys.Part4.Option_1.description]:
    'The provided link will be included in the post or video description. Use this to bring more traffic to a website or app.',

  [ProposalKeys.Part4.Option_2.title]: 'Include Hashtags',
  [ProposalKeys.Part4.Option_2.placeholder]: 'EnterHashtag',
  [ProposalKeys.Part4.Option_2.description]:
    'At least one or more of the hashtags from the left will be included in the post or in the video description.',

  [ProposalKeys.Part4.Option_3.title]: 'Tag a location',
  [ProposalKeys.Part4.Option_3.placeholder]: 'Enter a location',
  [ProposalKeys.Part4.Option_3.description]:
    'The provided location will be tagged in the post or video. Effective for promoting a specific location or venue.',

  [ProposalKeys.Part4.Option_4.title]: 'Tag our account',
  [ProposalKeys.Part4.Option_4.placeholder]: 'YourHandle',
  [ProposalKeys.Part4.Option_4.description]:
    'The account @handle will be included in the post or video description. (Only the handles corresponding to the platform of the ordered content will be applied.)',

  [ProposalKeys.Part4.Option_5.title]: 'Pin a comment with our account',
  [ProposalKeys.Part4.Option_5.description]:
    'A comment including the provided account @handle will be posted and pinned on the content. (Only the handles corresponding to the platform of the ordered content will be applied.)',

  [ProposalKeys.Part4.Option_6.title]: 'Pin a comment with a link',
  [ProposalKeys.Part4.Option_6.placeholder]: 'Enter a URL',
  [ProposalKeys.Part4.Option_6.description]:
    'A comment including the provided link will be posted and pinned on the content.',

  [ProposalKeys.Part4.Option_7.title]: 'Include specific images',
  [ProposalKeys.Part4.Option_7.description]:
    'The creator will be guided to include at least one or more of the provided images in the content.',

  [ProposalKeys.Part4.Option_8.title]: 'Add tags for videos',
  [ProposalKeys.Part4.Option_8.placeholder]: 'Enter a video tag',
  [ProposalKeys.Part4.Option_8.btn]: 'Add this Tag',
  [ProposalKeys.Part4.Option_8.description]:
    'At least one or more of the tags from the left will be added to the YouTube video for search optimization.',
};
