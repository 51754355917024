import { EnvelopeIcon, LoadingIcon, LockIcon } from 'assets/icons';
import Heading from 'components/Basics/Typography/Heading';
import { usePackages } from 'contexts/PackagesContext';
import styled from 'styled-components';
import { formatPriceInDollar } from 'components/utilities/stringFormatters';
import useUser from 'hooks/useUser';
import Tooltip from 'components/Basics/Tooltip';
import { useToast } from 'components/Basics/Toast';
import AxiosClient from 'components/utilities/AxiosClient';
import Variant from '../packagesCardVariant';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import device from 'styles/device';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { getCountryCode } from 'components/utilities/getCountryCode';
import { supportedLanguagesMap } from 'constants/languages';
import { Trans } from 'react-i18next';
import Stack from 'components/Basics/Layout/Stack';
import UserType from 'constants/userType';
import { useOfficialLaunchModal } from 'contexts/OfficialLaunchModal';

const unlockPrice = async (creatorId, handle) => {
  return AxiosClient.post('/unlockPriceWithQuota', {
    creatorId,
    handle,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const fireCustomEvent = (eventName, data) => {
  const event = new CustomEvent(eventName, {
    detail: data,
  });
  window.dispatchEvent(event);
};

const PriceBox = ({ variant }) => {
  const { t, i18n } = useTranslation();
  const toast = useToast();
  const {
    currentPackage,
    loading,
    userId,
    handle,
    refetch,
    isCurrentPackageComplete,
    userData,
  } = usePackages();
  const { openModal } = useAuthFlowManager();
  const { user, isLoggedIn, currentType } = useUser();
  const isMine =
    (user?.id && userId && user?.id === userId) ||
    (user?.handle && handle && user?.handle === handle);

  const [isUnlocking, setIsUnlocking] = useState(false);

  const { isMobile } = useResponsiveScreen();

  const browserLanguage = i18n.language;
  const packagePrice = currentPackage?.price;
  const [exchangedPrice, setExchangedPrice] = useState('--');
  const [errorFetchingExchangedPrice, setErrorFetchingExchangedPrice] =
    useState(false);
  const openOfficialLaunchModal = useOfficialLaunchModal();

  const isSupportedLanguage = useMemo(() => {
    return supportedLanguagesMap.some((lang) => {
      return lang === 'en' ? false : browserLanguage.startsWith(lang);
    });
  }, [browserLanguage]);

  const openRequestPrice = useCallback(() => {
    fireCustomEvent('open-request-price-modal', {
      data: {
        currentPackage: currentPackage,
        creatorId: userData?.id || userId,
        creatorHandle: handle,
      },
    });
  }, [currentPackage, handle, userData, userId]);

  const handleUnlock = useCallback(() => {
    if (!isLoggedIn) {
      openModal();
      return;
    }

    setIsUnlocking(true);
    unlockPrice(userId, handle)
      .then((res) => {
        toast.success(
          <Trans values={{ number: res.remaining }}>
            {t(ProfileKeys.Edit.Package.Price.toast)}
          </Trans>
        );
        refetch();
      })
      .catch((err) => {
        if (err.key === 'QUOTA_EXCEEDED') {
          fireCustomEvent('open-unlock-price-quota-modal', {
            event: true,
            data: {
              openRequestPrice: openRequestPrice,
            },
          });
        }
      })
      .finally(() => {
        setIsUnlocking(false);
      });
  }, [
    isLoggedIn,
    openModal,
    userId,
    toast,
    t,
    refetch,
    handle,
    openRequestPrice,
  ]);

  const creatorClick = useCallback(() => {
    toast.error(t(ProfileKeys.Menu.Error.creator_to_creator));
  }, [t, toast]);

  const handleClick = useCallback(() => {
    if (!isLoggedIn) {
      return openModal();
    }

    if (currentType === UserType.Creator) {
      return creatorClick();
    }

    if (currentPackage?.priceType === 'private') {
      return openRequestPrice();
    }

    if (
      currentPackage?.priceType === 'locked' &&
      !isUnlocking &&
      !isMine &&
      !currentPackage?.price
    ) {
      return handleUnlock();
    }

    return openOfficialLaunchModal();
  }, [
    isLoggedIn,
    currentType,
    creatorClick,
    currentPackage,
    isUnlocking,
    isMine,
    handleUnlock,
    openModal,
    openRequestPrice,
    openOfficialLaunchModal,
  ]);

  useEffect(() => {
    const fetchExchangedPrice = async () => {
      try {
        const countryCode = getCountryCode(browserLanguage);
        const response = await AxiosClient.get(
          `/getExchangedPrice/${countryCode}/${packagePrice}`
        );
        setExchangedPrice(response.data);
        setErrorFetchingExchangedPrice(false); // Reset error state if API call succeeds
      } catch (error) {
        console.error('Error fetching exchanged price:', error);
        setErrorFetchingExchangedPrice(true); // Set error state if API call fails
      }
    };

    fetchExchangedPrice();
  }, [packagePrice, browserLanguage]);

  useEffect(() => {
    const catchClick = (e) => {
      if (e.detail?.event === true) {
        handleClick();
        e.stopImmediatePropagation();
      }
    };

    window.addEventListener('package-order-button-click', catchClick);

    return () => {
      window.removeEventListener('package-order-button-click', catchClick);
    };
  }, [handleClick]);

  if (loading || (variant !== Variant.Preview && !isCurrentPackageComplete))
    return (
      <Heading variant={isMobile ? 'SBH6' : 'SBH4'}>
        {t(GeneralKeys.price_sign)}
      </Heading>
    );

  if (
    (isMine || !currentPackage.price) &&
    currentPackage.priceType === 'locked'
  )
    return (
      <>
        <Tooltip
          content={
            isMine ? (
              <Trans>{t(ProfileKeys.Package.Details.Price.tooltip)}</Trans>
            ) : (
              ''
            )
          }
        >
          <Stack
            direction="row"
            width={isMobile ? '' : '186px'}
            wrap="nowrap"
            gap="10px"
            style={{ maxWidth: isMobile ? '' : '186px' }}
          >
            {isUnlocking ? (
              <ActionButton>
                <LoadingIcon size={isMobile ? 0.625 : 1} />{' '}
                {t(GeneralKeys.unlocking)}
              </ActionButton>
            ) : (
              <ActionButton onClick={isMine ? undefined : handleUnlock}>
                {!isMobile && (
                  <span style={{ marginRight: '10px' }}>
                    <LockIcon size={0.81} />
                  </span>
                )}
                <span className="text-decoration-underline">
                  {t(GeneralKeys.click_to_unlock)}
                </span>
              </ActionButton>
            )}
          </Stack>
        </Tooltip>
      </>
    );

  if (currentPackage.priceType === 'private')
    return (
      <Tooltip
        content={
          isMine ? (
            <Trans>{t(ProfileKeys.Package.Details.Price.tooltip)}</Trans>
          ) : (
            ''
          )
        }
      >
        <Stack
          direction="row"
          width={isMobile ? '' : '200px'}
          wrap="nowrap"
          gap="10px"
          style={{ maxWidth: isMobile ? '' : '170px' }}
        >
          <ActionButton onClick={handleClick}>
            {!isMobile && (
              <span style={{ marginRight: '10px' }}>
                <EnvelopeIcon />
              </span>
            )}
            <span className="text-decoration-underline">
              {isMobile
                ? t(ProfileKeys.Package.Details.Price.contact_me)
                : t(ProfileKeys.Package.Details.Price.contact_creator)}
            </span>
          </ActionButton>
        </Stack>
      </Tooltip>
    );

  return (
    <Wrapper>
      <Heading variant={isMobile ? 'SBH6' : 'SBH4'}>
        {currentPackage?.price
          ? formatPriceInDollar(currentPackage?.price, {
              minFractionDigits: 0,
              maxFractionDigits: 2,
            })
          : t(GeneralKeys.price_sign)}
      </Heading>
      {isSupportedLanguage && !errorFetchingExchangedPrice ? (
        <>
          <Heading
            variant={isMobile ? 'H9' : 'H6.5'}
            style={{
              paddingTop: isMobile ? '8px' : '12px',
              color: 'var(--color-pink-100)',
            }}
          >
            <span style={{ color: 'var(--color-indigo-200)' }}>
              ~ {exchangedPrice}
            </span>
          </Heading>
          {!isMine ? (
            <Heading
              variant={isMobile ? 'H9' : 'H6.5'}
              style={{
                paddingTop: isMobile ? '8px' : '2px',
                color: 'var(--color-pink-100)',
              }}
            >
              <span style={{ color: 'var(--color-indigo-200)' }}>
                {t(ProfileKeys.Package.Details.Price.service_fee)}
              </span>
            </Heading>
          ) : null}
        </>
      ) : !isMine ? (
        <Heading
          variant={isMobile ? 'H9' : 'H6.5'}
          style={{
            paddingTop: isMobile ? '8px' : '12px',
            color: 'var(--color-pink-100)',
          }}
        >
          <span style={{ color: 'var(--color-indigo-200)' }}>
            {t(ProfileKeys.Package.Details.Price.service_fee)}
          </span>
        </Heading>
      ) : null}
    </Wrapper>
  );
};

export default PriceBox;

const ActionButton = styled.button`
  font-family: Kumbh Sans;
  font-size: 17px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0.15px;
  text-align: center;

  background: transparent;
  border: none;
  outline: none;
  padding: 0;
  width: 100%;
  padding: 0;

  @media ${device.sm} {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.15px;
    max-width: 140px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.375rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;
