import getThumbnail from 'components/utilities/getThumbnail';
import { AnimatePresence, motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import { memo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useController, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Files from './Files';
import Stack from 'components/Basics/Layout/Stack';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';
import device from 'styles/device';

const fileSize = (size) => {
  if (size < 1024) return `${size}B`;
  if (size < 1024 * 1024) return `${size / 1024}KB`;
  if (size < 1024 * 1024 * 1024) return `${size / 1024 / 1024}MB`;
  return `${size / 1024 / 1024 / 1024}GB`;
};

const FileUploader = ({
  name,
  placeholder,
  minRows,
  maxFiles,
  accept,
  maxSize,
  onError,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const { trigger, formState } = useFormContext();
  const { field } = useController({
    name,
  });
  const files = field.value;

  const onErrorChange = useCallback(
    (error) => {
      setError(error);
      onError?.(error);
    },
    [onError]
  );

  const onFileSelect = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      let cumulativeSize = files.reduce((acc, cur) => acc + cur.size, 0);
      let totalFiles = files.length;
      onErrorChange(null);
      let error = null; // Total file size limit is max 2GB / You can upload max 10 files

      acceptedFiles = acceptedFiles.filter((file) => {
        if (accept && !accept.includes(file.type)) {
          const fileFormats = accept
            .map((format) => {
              const [type, extension] = format.split('/');

              if (extension === '*') {
                return type;
              }
              return extension.toUpperCase();
            })
            .join(', ');
          error = (
            <Trans values={{ fileFormats: fileFormats }}>
              {t(PricingKeys.Modal.Error.invalid_file_format)}
            </Trans>
          );
          return false;
        }

        if (cumulativeSize + file.size > maxSize) {
          error = (
            <Trans values={{ maxSize: fileSize(maxSize) }}>
              {t(PricingKeys.Modal.Error.total_file_size)}
            </Trans>
          );
          return false;
        }

        if (totalFiles >= maxFiles) {
          error = (
            <Trans values={{ maxFiles: maxFiles }}>
              {t(PricingKeys.Modal.Error.send_max_files)}
            </Trans>
          );
          return false;
        }

        cumulativeSize += file.size;
        totalFiles += 1;
        return true;
      });

      acceptedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          const id = nanoid();
          const thumbnailObj = await getThumbnail(file);
          const thumbnail = thumbnailObj
            ? URL.createObjectURL(thumbnailObj)
            : null;
          const url = URL.createObjectURL(file);

          return {
            id,
            name: file.name,
            type: file.type,
            size: file.size,
            thumbnail,
            url,
            obj: file,
            thumbnailObj,
            remove: () => {
              URL.revokeObjectURL(thumbnail);
              URL.revokeObjectURL(url);
            },
          };
        })
      );

      field.onChange([...files, ...acceptedFiles]);
      if (error) {
        onErrorChange(error);
      }
    },
    [files, field, onErrorChange, maxSize, maxFiles, accept]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: onFileSelect,
  });

  return (
    <Wrapper>
      <AnimatePresence>
        {(error || formState.errors?.verificationFile?.message) && (
          <Error>
            {t(error || formState.errors?.verificationFile?.message)}
          </Error>
        )}
      </AnimatePresence>

      <DropBox
        {...getRootProps()}
        initial={false}
        animate={{
          borderColor: isDragActive
            ? 'var(--color-indigo-100)'
            : 'var(--color-indigo-20)',
        }}
        minRows={minRows}
        haveFiles={files?.length > 0}
      >
        <AnimatePresence>
          {files?.length > 0 ? (
            <Files
              name={name}
              onRemove={() => {
                onErrorChange(null);
                trigger(name);
              }}
            />
          ) : (
            <NoFiles>{placeholder(open)}</NoFiles>
          )}
        </AnimatePresence>
        <input {...getInputProps()} accept={accept?.join(',')} />
      </DropBox>
    </Wrapper>
  );
};

export default memo(FileUploader);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Error = styled(motion.span).attrs({
  className: 'h6',
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  color: var(--color-red-500);
`;

const DropBox = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: ${({ haveFiles }) => (haveFiles ? 'flex-start' : 'center')};
  align-items: ${({ haveFiles }) => (haveFiles ? 'flex-start' : 'center')};
  padding: 1.125rem;
  border-radius: 0.75rem;
  border-width: 2px;
  border-style: solid;
  min-height: ${({ minRows }) =>
    minRows
      ? `${
          minRows * 7.5 + // row height
          (minRows - 1) * 0.75 + // gap
          1.25 * 2 // padding + border
        }rem`
      : '0'};
`;

const NoFiles = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  text-align: center;

  @media ${device.sm} {
    font-size: 14px;
  }
`;
