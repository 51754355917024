const link1 = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#FF0000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6502 6.18903C15.8498 6.38858 15.9936 6.63693 16.0673 6.90934C16.4917 8.61786 16.3937 11.3162 16.0755 13.0907C16.0018 13.3631 15.858 13.6114 15.6585 13.811C15.4589 14.0105 15.2106 14.1543 14.9382 14.228C13.9409 14.5 9.92719 14.5 9.92719 14.5C9.92719 14.5 5.91346 14.5 4.9162 14.228C4.64379 14.1543 4.39545 14.0105 4.19589 13.811C3.99635 13.6114 3.85255 13.3631 3.77884 13.0907C3.35192 11.3896 3.46895 8.68956 3.7706 6.91758C3.84431 6.64517 3.9881 6.39682 4.18765 6.19727C4.3872 5.99772 4.63555 5.85393 4.90796 5.78022C5.90521 5.50824 9.91895 5.5 9.91895 5.5C9.91895 5.5 13.9327 5.5 14.9299 5.77198C15.2023 5.84569 15.4507 5.98949 15.6502 6.18903ZM11.9711 10L8.64148 11.9286V8.07142L11.9711 10Z"
      fill="white"
    />
  </svg>
);

export default link1;
