import AxiosClient from 'components/utilities/AxiosClient';
import { useSocket } from 'contexts/SocketContext';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useRef } from 'react';
import useUser from 'hooks/useUser';
import { useLocation } from 'react-router-dom';
import { useNotificationSound } from 'contexts/NotificationSoundContext';

export const SearchType = {
  ALL: 'all',
  USERS: 'users',
  MESSAGES: 'messages',
};

const ChatListContext = createContext({
  loadingChats: true,
  fetchingChats: true,
  chats: [],
  readChat: () => {},
  search: '',
  setSearch: () => {},
  searchType: SearchType.ALL,
  setSearchType: () => {},
});

const getChats = ({ search, type, counterpartOnly }) => {
  const controller = new AbortController();

  const promise = new Promise((resolve, reject) => {
    AxiosClient.get('/chats', {
      params: {
        searchTerm: search,
        type,
        counterpartOnly,
      },
      signal: controller.signal,
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        if (err?.code !== 'ERR_CANCELED') return reject(err);
        resolve([]);
      });
  });

  return {
    promise,
    dismiss: () => controller.abort(),
  };
};

const ChatListProvider = ({ counterpartOnly, children }) => {
  const socket = useSocket();
  const { pathname } = useLocation();
  const { user: currentUser } = useUser();
  const [loadingChats, setLoadingChats] = useState(true);
  const [fetchingChats, setFetchingChats] = useState(false);
  const [chats, setChats] = useState([]);
  const [search, setSearch] = useState('');
  const [searchType, setSearchType] = useState(SearchType.ALL);
  const { playSound } = useNotificationSound();

  const loadedOnce = useRef(false);

  // get chats
  useEffect(() => {
    if (!loadedOnce.current) {
      setLoadingChats(true);
    }
    setFetchingChats(true);

    const { promise, dismiss } = getChats({
      search,
      type: searchType,
      counterpartOnly,
    });

    promise
      .then((chats) => {
        setChats(chats);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (!loadedOnce.current) {
          loadedOnce.current = true;
          setLoadingChats(false);
        }
        setFetchingChats(false);
      });

    return () => {
      dismiss();
    };
  }, [search, searchType, counterpartOnly]);

  const updateMessage = useCallback(
    (message, user, updateReadCount) => {
      if (search) return;

      setChats((prevChats) => {
        const chatIndex = prevChats.findIndex(
          (chat) => chat.user.id === user.id
        );

        if (chatIndex === -1)
          return [
            {
              id: 'new',
              user,
              message,
              unreadCount: updateReadCount ? 1 : 0,
            },
            ...prevChats,
          ];

        const chat = prevChats[chatIndex];
        return [
          ...prevChats.slice(0, chatIndex),
          {
            ...chat,
            message,
            unreadCount: updateReadCount
              ? chat.unreadCount + 1
              : chat.unreadCount,
          },
          ...prevChats.slice(chatIndex + 1),
        ];
      });
    },
    [search]
  );

  // send message event listener
  useEffect(() => {
    if (counterpartOnly) return;

    const catchMessage = (e) => {
      const { message } = e.detail;

      updateMessage(message, message.recipient);
    };

    document.addEventListener('sendMessage', catchMessage);

    return () => {
      document.removeEventListener('sendMessage', catchMessage);
    };
  }, [updateMessage, counterpartOnly]);

  // message resent event listener
  useEffect(() => {
    if (counterpartOnly) return;

    const catchMessage = (e) => {
      const { message } = e.detail;

      updateMessage(message, message.recipient);
    };

    document.addEventListener('messageResent', catchMessage);

    return () => {
      document.removeEventListener('messageResent', catchMessage);
    };
  }, [updateMessage, counterpartOnly]);

  // new message event listener
  useEffect(() => {
    const catchMessage = async (message) => {
      if (counterpartOnly && message.sender.id === currentUser.id) return;

      const counterpart =
        message.sender.id === currentUser.id
          ? message.recipient
          : message.sender;

      const alreadyOpenedChat =
        pathname === `/messages/${counterpart.id}` ||
        pathname === `/messages/@${counterpart.handle}`;

      updateMessage(message, counterpart, !alreadyOpenedChat);

      if (alreadyOpenedChat) {
        // update last read status on backend
        await AxiosClient.get(`/updateUserReadStatus/${counterpart.id}`);
      } else {
        playSound();
      }
    };

    socket.on('newMessage', catchMessage);

    return () => socket.off('newMessage', catchMessage);
  }, [
    socket,
    updateMessage,
    currentUser,
    pathname,
    playSound,
    counterpartOnly,
  ]);

  // new chat event listener
  useEffect(() => {
    const catchChats = (chat) => {
      setChats((prevChats) => {
        return prevChats.concat(chat);
      });
    };

    socket.on('newChat', catchChats);

    return () => socket.off('newChat', catchChats);
  }, [socket]);

  const readChat = useCallback((chatRoomId) => {
    const event = new CustomEvent('readChat', {
      detail: {
        chatRoomId,
      },
    });

    document.dispatchEvent(event);
  }, []);

  useEffect(() => {
    const catchReadChat = (e) => {
      const { chatRoomId } = e.detail;

      setChats((prevChats) => {
        return prevChats.map((chat) => {
          if (chat.id === chatRoomId) {
            return {
              ...chat,
              unreadCount: 0,
            };
          }

          return chat;
        });
      });
    };

    document.addEventListener('readChat', catchReadChat);

    return () => {
      document.removeEventListener('readChat', catchReadChat);
    };
  }, []);

  const contextValue = useMemo(() => {
    let formattedChats = chats
      .sort(
        (a, b) =>
          new Date(b.message.date).getTime() -
          new Date(a.message.date).getTime()
      )
      .map((chat) => ({
        ...chat,
        disableUnSelect: true,
      }));

    return {
      loadingChats,
      fetchingChats,
      chats: formattedChats,
      readChat,
      search,
      setSearch,
      searchType,
      setSearchType,
    };
  }, [loadingChats, fetchingChats, chats, search, readChat, searchType]);

  return (
    <ChatListContext.Provider value={contextValue}>
      {children}
    </ChatListContext.Provider>
  );
};

const useChatList = () => {
  const context = useContext(ChatListContext);

  if (!context) {
    throw new Error('useChatList must be used within a ChatListProvider');
  }

  return context;
};

export { ChatListProvider, useChatList };
