import Tippy from '@tippyjs/react';
import styled from 'styled-components';
import 'tippy.js/animations/scale-subtle.css';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { memo } from 'react';

const Tip = styled.div`
  background: ${({ background }) => background};
  margin: 0;
  color: var(--color-white);
  padding: ${({ padding }) => padding};
  margin-block: ${({ margin }) => (margin ? margin : '0')};
  border-radius: 0.625rem;
  text-align: ${({ align }) => align || 'left'};
  box-shadow: ${({ shadow }) => shadow};
  width: ${({ width }) => width};
`;

/**
 * @param {string} content - The content of the tooltip
 * @param {Node} children - The children of the tooltip (component that triggers the tooltip)
 * @param {string} width - The width of the tooltip
 * @param {boolean} visible - to manually control the visibility of the tooltip
 * @param {string} disabled - to disable the tooltip
 */
const Tooltip = ({
  content,
  fontSize = 'h5',
  padding = '0.5rem 1.25rem',
  children,
  width,
  visible,
  disabled,
  zIndex,
  align,
  margin = '0',
  className,
  style,
  background = 'var(--color-indigo-500)',
  shadow = 'var(--shadow-icon)',
  interactive = false,
  placement = 'top',
}) => {
  const { isMobile } = useResponsiveScreen();

  if (!content) return children;

  return (
    <Tippy
      placement={placement}
      arrow={false}
      animation="scale-subtle"
      interactive={interactive}
      content={
        <Tip
          className={isMobile ? 'h7' : fontSize}
          width={width}
          padding={isMobile ? '0.5rem 0.75rem' : padding}
          align={align}
          margin={margin}
          background={background}
          shadow={shadow}
        >
          {content}
        </Tip>
      }
      visible={visible}
      disabled={disabled}
      maxWidth="max-content"
      zIndex={zIndex}
    >
      <div className={className} style={style}>
        {children}
      </div>
    </Tippy>
  );
};

export default memo(Tooltip);
