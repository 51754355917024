import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const profilePageRegex = /^\/(@[a-zA-Z0-9_.-]+|[0-9]+)$/;

const useHeaderStyles = (
  {
    defaultBackground,
    defaultIsAbsolute,
    defaultIsWhite,
    defaultIsVisible,
    defaultHaveLinks,
  } = {
    defaultBackground: undefined,
    defaultIsAbsolute: undefined,
    defaultIsWhite: undefined,
    defaultIsVisible: undefined,
    defaultHaveLinks: undefined,
  }
) => {
  const { pathname } = useLocation();
  const { isMobile } = useResponsiveScreen();

  const isWhite = useMemo(() => {
    if (defaultIsWhite !== undefined) return defaultIsWhite;

    return (
      pathname === '/pricing' ||
      profilePageRegex.test(pathname) ||
      pathname.includes('/profile-edit') ||
      (pathname.includes('/help-center') &&
        !pathname.includes('/help-center-editor'))
    );
  }, [pathname, defaultIsWhite]);

  const isVisible = useMemo(() => {
    if (defaultIsVisible !== undefined) return defaultIsVisible;

    if (
      isMobile &&
      (profilePageRegex.test(pathname) || pathname.includes('/profile-edit'))
    )
      return false;
    if (pathname.includes('/unsubscribe')) return false;
    if (isMobile && pathname.includes('/messages')) return false;
    // /platforms/:platform/auth/:req_type
    if (/platforms\/[a-zA-Z]+\/auth\/[a-zA-Z]+/.test(pathname)) return false;

    return true;
  }, [isMobile, pathname, defaultIsVisible]);

  const isAbsolute = useMemo(() => {
    if (defaultIsAbsolute !== undefined) return defaultIsAbsolute;

    return (
      ((pathname.includes('/profile-edit') ||
        profilePageRegex.test(pathname)) &&
        !isMobile) ||
      pathname === '/pricing' ||
      pathname === '/about' ||
      (pathname.includes('/help-center') &&
        !pathname.includes('/help-center-editor')) ||
      ((pathname === '/' || pathname === '') && isMobile)
    );
  }, [pathname, isMobile, defaultIsAbsolute]);

  const background = useMemo(() => {
    if (defaultBackground) return defaultBackground;

    if (pathname === '/about') return 'var(--color-pink-100)';
    if (pathname === '/creator-registration' && isMobile)
      return 'var(--color-white)';
    if (
      (pathname === '/creator-registration' && isMobile) ||
      pathname.includes('/search')
    )
      return 'var(--color-white)';

    return 'transparent';
  }, [pathname, isMobile, defaultBackground]);

  const haveLinks = useMemo(() => {
    if (defaultHaveLinks !== undefined) return defaultHaveLinks;

    if (pathname.includes('/profile-edit') || profilePageRegex.test(pathname))
      return false;

    return true;
  }, [pathname, defaultHaveLinks]);

  return {
    isWhite,
    isAbsolute,
    background,
    isVisible,
    haveLinks,
  };
};

export default useHeaderStyles;
