import FilterKeys from '../translationKeys/FilterKeys';

export const FilterTranslations = {
  // Influencer Marketing Home Page
  // Từ Khóa Tìm Kiếm
  [FilterKeys.home.search_keywords]: 'Từ khóa tìm kiếm',

  [FilterKeys.home.expand]: 'Mở rộng',
  [FilterKeys.home.collapse]: 'Thu gọn',

  [FilterKeys.home.more_filter]: '＋ Tăng Bộ lọc',
  [FilterKeys.home.less_filter]: '– Giảm bộ lọc',

  [FilterKeys.home.try_searching]: 'Thử tìm kiếm',
  [FilterKeys.home.results_for_keyword]:
    '{{number}} kết quả cho "{{searchText}}"',
  [FilterKeys.home.result_for_keyword]:
    '{{number}} kết quả cho "{{searchText}}"',

  // Thử Tìm Kiếm
  [FilterKeys.home.Try_Searching.brand_ambassador]: 'Đại sứ thương hiệu',
  [FilterKeys.home.Try_Searching.live_shows]: 'Live show',
  [FilterKeys.home.Try_Searching.product_review]: 'Review sản phẩm',
  [FilterKeys.home.Try_Searching.game_review]: 'Review trò chơi',

  // Sắp Xếp Theo
  [FilterKeys.home.sort_by.suggested]: 'Đề Xuất',
  [FilterKeys.home.sort_by.by_subscribers]: 'Số người đăng ký',
  [FilterKeys.home.sort_by.lowest_price]: 'Giá thấp nhất',
  [FilterKeys.home.sort_by.highest_price]: 'Giá cao nhất',

  // Quốc Gia
  [FilterKeys.home.country.popular]: 'Quốc Gia Phổ Biến',
  [FilterKeys.home.country.selected]: 'Quốc Gia Đã Chọn',
  [FilterKeys.home.country.no_result]: 'Không tìm thấy kết quả',

  // Giá
  [FilterKeys.home.price._50]: '~$50',
  [FilterKeys.home.price._50_100]: '$50~100',
  [FilterKeys.home.price._100_300]: '$100~300',
  [FilterKeys.home.price._300_500]: '$300~500',
  [FilterKeys.home.price._500_1k]: '$500~1K',
  [FilterKeys.home.price._1k_2k]: '$1K~2K',
  [FilterKeys.home.price._2k_5k]: '$2K~5K',
  [FilterKeys.home.price._5k_]: '$5K+',

  // Người Đăng Ký
  [FilterKeys.home.subs_and_views.tooltip_subs]:
    'Số người đăng ký hoặc người theo dõi',
  [FilterKeys.home.subs_and_views.tooltip_views]: `
    Đây là số lượt xem hoặc số lần hiển thị trung bình <br>
    trong 180 ngày mà bạn có thể mong đợi cho mỗi bài đăng`,

  [FilterKeys.home.subs_and_views.private]: 'Riêng tư',
  [FilterKeys.home.subs_and_views._10k]: '~10K',
  [FilterKeys.home.subs_and_views._10k_100k]: '10K~100K',
  [FilterKeys.home.subs_and_views._100k_1m]: '100K~1M',
  [FilterKeys.home.subs_and_views._1m_]: '1M+',

  // Giới Tính
  [FilterKeys.home.gender.male]: 'Nam',
  [FilterKeys.home.gender.female]: 'Nữ',

  // Di Động
  [FilterKeys.home.M.filter_by]: 'Lọc bởi:',
  [FilterKeys.home.M.all_categories]: 'Tất Cả Danh Mục',
  [FilterKeys.home.M.all_price_ranges]: 'Tất Cả khoảng giá',
};
