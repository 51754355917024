export const ArrowSolidDownIcon = ({ size = 1 }) => (
  <svg
    width={16 * size}
    height={13 * size}
    viewBox="0 0 16 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.26795 12C7.03775 13.3333 8.96225 13.3333 9.73205 12L14.9282 3C15.698 1.66667 14.7358 0 13.1962 0H2.80385C1.26425 0 0.301996 1.66667 1.0718 3L6.26795 12Z" />
  </svg>
);
