import styled from 'styled-components';
import TypeButtons from './TypeButtons';
import Ratings from './Ratings';
import Variant from '../packagesCardVariant';
import PlatformIconButton from './PlatformIconButton';
import DeatilsModalPlatformIconButton from './DeatilsModalPlatformIconButton';
import DeatilsModalRatings from './DeatilsModalRatings';

/**
 * @param {function} leftAction - Action to be performed when the left button is clicked
 */

const TopBar = ({ leftAction, variant }) => {
  return (
    <Wrapper>
      {
        {
          [Variant.Modal]: <DeatilsModalPlatformIconButton />,
          [Variant.Profile]: <PlatformIconButton />,
          [Variant.Preview]: <PlatformIconButton />,
        }[variant]
      }

      <TypeButtons variant={variant} />

      {
        {
          [Variant.Modal]: <DeatilsModalRatings />,
          [Variant.Profile]: <Ratings />,
          [Variant.Preview]: <Ratings />,
        }[variant]
      }
    </Wrapper>
  );
};

export default TopBar;

const Wrapper = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 1fr 500px 1fr;
  align-items: center;
`;
