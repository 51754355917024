export const MetamaskIcon = ({ size = 1 }) => (
  <svg
    width={size * 32}
    height={size * 31}
    viewBox="0 0 32 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_17_1286)">
      <path
        d="M31.2621 0L17.5312 10.3856L20.0846 4.26509L31.2621 0Z"
        fill="#E17726"
      />
      <path
        d="M0.773812 0.0126953L11.9214 4.26629L14.3459 10.4671L0.773812 0.0126953ZM25.7324 22.0878L31.8013 22.2059L29.6803 29.5713L22.2749 27.4871L25.7324 22.0878ZM6.26731 22.0878L9.71194 27.4871L2.31906 29.5714L0.210938 22.2059L6.26731 22.0878Z"
        fill="#E27625"
      />
      <path
        d="M14.0171 8.88726L14.2651 17.0756L6.84375 16.7305L8.95475 13.475L8.9815 13.4437L14.0171 8.88726ZM17.9075 8.7959L23.02 13.4439L23.0465 13.4751L25.1575 16.7306L17.7377 17.0756L17.9075 8.7959ZM9.93012 22.1112L13.9824 25.3388L9.27512 27.6619L9.93012 22.1112ZM22.0721 22.1107L22.7135 27.662L18.0194 25.3385L22.0721 22.1107Z"
        fill="#E27625"
      />
      <path
        d="M18.1224 25.0347L22.8858 27.3924L18.4549 29.545L18.5009 28.1223L18.1224 25.0347ZM13.8766 25.0357L13.5129 28.099L13.5428 29.5433L9.10156 27.3924L13.8766 25.0357Z"
        fill="#D5BFB2"
      />
      <path
        d="M12.5009 18.1445L13.7457 20.8187L9.50781 19.5496L12.5009 18.1445ZM19.4991 18.1448L22.5063 19.5496L18.2546 20.8183L19.4991 18.1448Z"
        fill="#233447"
      />
      <path
        d="M10.2551 22.0841L9.57006 27.8392L5.89844 22.21L10.2551 22.0841ZM21.7488 22.0842L26.1056 22.21L22.4202 27.8395L21.7488 22.0842ZM25.2658 16.4053L22.0952 19.7085L19.6506 18.5665L18.4802 21.0817L17.7129 16.7565L25.2658 16.4053ZM6.73619 16.4053L14.2906 16.7565L13.5232 21.0817L12.3526 18.5669L9.92094 19.7086L6.73619 16.4053Z"
        fill="#CC6228"
      />
      <path
        d="M6.52344 15.7273L10.1107 19.4484L10.2349 23.1218L6.52344 15.7273ZM25.4856 15.7207L21.7673 23.1284L21.9073 19.4484L25.4856 15.7207ZM14.1161 15.954L14.2604 16.883L14.6172 19.1972L14.3878 26.3048L13.3034 20.595L13.3031 20.536L14.1161 15.954ZM17.8874 15.9411L18.7026 20.536L18.7022 20.595L17.6151 26.3191L17.5721 24.8874L17.4024 19.155L17.8874 15.9411Z"
        fill="#E27525"
      />
      <path
        d="M22.2229 19.3008L22.1016 22.4924L18.3173 25.5063L17.5523 24.9538L18.4098 20.4388L22.2229 19.3008ZM9.78906 19.3008L13.5889 20.4388L14.4464 24.9538L13.6814 25.5063L9.89706 22.4922L9.78906 19.3008Z"
        fill="#F5841F"
      />
      <path
        d="M8.37891 26.6875L13.2204 29.0325L13.1999 28.0311L13.605 27.6676H18.3968L18.8165 28.0298L18.7855 29.0304L23.5964 26.6934L21.2554 28.6709L18.4248 30.6582H13.5662L10.7374 28.6627L8.37891 26.6875Z"
        fill="#C0AC9D"
      />
      <path
        d="M17.7762 24.7227L18.4607 25.217L18.8618 28.4886L18.2813 27.9876H13.7218L13.1523 28.4987L13.5403 25.2173L14.2251 24.7227H17.7762Z"
        fill="#161616"
      />
      <path
        d="M30.3517 0.287598L32 5.3421L30.9706 10.4528L31.7036 11.0308L30.7118 11.8043L31.4573 12.3929L30.4701 13.3117L31.0761 13.7604L29.4679 15.6803L22.8716 13.717L22.8145 13.6857L18.0611 9.58688L30.3517 0.287598ZM1.64825 0.287598L13.939 9.58688L9.1855 13.6857L9.12838 13.717L2.53212 15.6803L0.923875 13.7604L1.52937 13.3121L0.542875 12.3929L1.28688 11.805L0.280125 11.0292L1.04075 10.4509L0 5.34236L1.64825 0.287598Z"
        fill="#763E1A"
      />
      <path
        d="M22.5483 13.2881L29.5374 15.3682L31.808 22.5219H25.8175L21.69 22.5751L24.6918 16.5941L22.5483 13.2881ZM9.45025 13.2881L7.30638 16.5941L10.3085 22.5751L6.18288 22.5219H0.203125L2.461 15.3683L9.45025 13.2881ZM20.4223 4.23145L18.4674 9.62865L18.0525 16.9197L17.8938 19.205L17.8811 25.0429H14.1173L14.105 19.2159L13.9458 16.9177L13.5308 9.62865L11.5761 4.23145H20.4223Z"
        fill="#F5841F"
      />
    </g>
    <defs>
      <clipPath id="clip0_17_1286">
        <rect width="32" height="30.6667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
