import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'Auto Translation',
  [AutoTranslationKeys.Dropdown.description]:
    'Automatically translate descriptions and user-written text.',

  [AutoTranslationKeys.Applying_Translation.title]: 'Auto-translation applied.',
  [AutoTranslationKeys.Applying_Translation.btn]: 'Show original text',

  [AutoTranslationKeys.Displaying_Original.title]: 'Displaying original text.',
  [AutoTranslationKeys.Displaying_Original.btn]: 'Translate',
};
