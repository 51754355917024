import BillingKeys from '../translationKeys/BillingPageKeys';

export const BillingTranslations = {
  [BillingKeys.Payment_Subscription.title]: '付款与订阅',
  [BillingKeys.Payment_Subscription.Method.title]: '支付方式',
  [BillingKeys.Payment_Subscription.Method.btn]: '添加信用卡或借记卡',
  [BillingKeys.Payment_Subscription.Side.my_plan]: '我的计划',
  [BillingKeys.Payment_Subscription.Side.renews_on]: '续订日期',

  [BillingKeys.Billing_History.title]: '账单历史',
  [BillingKeys.Billing_History.search]: '按创建者或服务名称搜索',

  [BillingKeys.MEGA_Balance.title]: 'MEGA 余额',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.title]: '提现方式',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with]:
    '提现方式 <icon></icon>',
  [BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon]: '即将推出',
  [BillingKeys.MEGA_Balance.Balance_Summary.title]: '余额汇总',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance]:
    '可用余额总额',
  [BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn]: '总提现金额',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment]: '待付款',
  [BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip]: `
      包括当前进行中的订单付款。<br>
      待付款将在订单完成后24天内可提现。<br><br>
      请确保上传的订单内容在上传后至少60天内不被删除或设为私密。<TooltipLink>了解更多</TooltipLink>`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount]: '提现金额',
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.details]: `
      每次提现将收取2.0%的PayPal手续费（费用上限为20美元）。<br>
      转账可能需要3~5个工作日。`,
  [BillingKeys.MEGA_Balance.Balance_Summary.Side.btn]: '提现余额',
};
