export const MySpaceSupportIcon = ({
  size = 1,
  color = '#DE55C0',
  noColor,
  ...rest
}) => {
  return (
    <svg
      width={size * 55}
      height={size * 54}
      viewBox="0 0 55 54"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M27.5 0C12.6122 0 0.5 12.1122 0.5 27V38.1861C0.5 40.9509 2.9219 43.2 5.9 43.2H8.6C9.31608 43.2 10.0028 42.9155 10.5092 42.4092C11.0155 41.9028 11.3 41.2161 11.3 40.5V26.6139C11.3 25.8978 11.0155 25.2111 10.5092 24.7047C10.0028 24.1984 9.31608 23.9139 8.6 23.9139H6.1484C7.6496 13.4649 16.6406 5.4 27.5 5.4C38.3594 5.4 47.3504 13.4649 48.8516 23.9139H46.4C45.6839 23.9139 44.9972 24.1984 44.4908 24.7047C43.9845 25.2111 43.7 25.8978 43.7 26.6139V43.2C43.7 46.1781 41.2781 48.6 38.3 48.6H32.9V45.9H22.1V54H38.3C44.2562 54 49.1 49.1562 49.1 43.2C52.0781 43.2 54.5 40.9509 54.5 38.1861V27C54.5 12.1122 42.3878 0 27.5 0Z"
        style={{ transition: 'fill 0.3s ease' }}
      />
    </svg>
  );
};
