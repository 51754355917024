export const DeliveryIcon = ({ size = 1, color = '#FFC543', style }) => {
  return (
    <svg
      width={size * 40}
      height={size * 29}
      viewBox="0 0 40 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M34.5455 7.25H29.0909V0H3.63636C1.63636 0 0 1.63125 0 3.625V23.5625H3.63636C3.63636 26.5712 6.07273 29 9.09091 29C12.1091 29 14.5455 26.5712 14.5455 23.5625H25.4545C25.4545 26.5712 27.8909 29 30.9091 29C33.9273 29 36.3636 26.5712 36.3636 23.5625H40V14.5L34.5455 7.25ZM33.6364 9.96875L37.2 14.5H29.0909V9.96875H33.6364ZM9.09091 25.375C8.09091 25.375 7.27273 24.5594 7.27273 23.5625C7.27273 22.5656 8.09091 21.75 9.09091 21.75C10.0909 21.75 10.9091 22.5656 10.9091 23.5625C10.9091 24.5594 10.0909 25.375 9.09091 25.375ZM13.1273 19.9375C12.1273 18.8319 10.7091 18.125 9.09091 18.125C7.47273 18.125 6.05455 18.8319 5.05455 19.9375H3.63636V3.625H25.4545V19.9375H13.1273ZM30.9091 25.375C29.9091 25.375 29.0909 24.5594 29.0909 23.5625C29.0909 22.5656 29.9091 21.75 30.9091 21.75C31.9091 21.75 32.7273 22.5656 32.7273 23.5625C32.7273 24.5594 31.9091 25.375 30.9091 25.375Z"
        fill={color}
      />
    </svg>
  );
};
