export const PlusCircleIcon = ({ size = 1, className }) => (
  <svg
    width={18 * size}
    height={18 * size}
    viewBox="0 0 18 18"
    fill="currentColor"
    stroke="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.01371 8.80172C1.01371 13.0818 4.51979 16.5879 8.79984 16.5879C13.0799 16.5879 16.586 13.0818 16.586 8.80172C16.586 4.52166 13.0799 1.01558 8.79984 1.01558C4.51979 1.01558 1.01371 4.52166 1.01371 8.80172Z"
      strokeWidth="2.03117"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.79767 12.3244V5.27911"
      fill="none"
      stroke="white"
      strokeWidth="2.03117"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.27423 8.80157H12.3195"
      fill="none"
      stroke="white"
      strokeWidth="2.03117"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
