import EvoMissionsKeys from '../translationKeys/EvoMissionsPage';

export const EvoMissionsTranslations = {
  [EvoMissionsKeys.claim_evos]: 'Nhận EVOs',
  [EvoMissionsKeys.my_evos]: 'EVO của Tôi',
  [EvoMissionsKeys.special_missions]: 'Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.creator_missions]: 'Nhiệm vụ Người tạo nội dung',
  [EvoMissionsKeys.general_missions]: 'Nhiệm vụ Chung',
  [EvoMissionsKeys.successfully_claimed]: 'Đã nhận thành công!',
  [EvoMissionsKeys.go_back_home]: 'ĐI ĐẾN HỒ SƠ CỦA TÔI',

  // Token Details
  [EvoMissionsKeys.Token_Details._1k]: '1k',
  [EvoMissionsKeys.Token_Details._10k]: '10k',
  [EvoMissionsKeys.Token_Details.no_data]: 'Không có dữ liệu để hiển thị',
  [EvoMissionsKeys.Token_Details.results_found]:
    'Tổng cộng có {{number}} kết quả được tìm thấy',
  [EvoMissionsKeys.Token_Details.result_found]:
    'Tổng cộng có {{number}} kết quả được tìm thấy',

  // Side Popup
  [EvoMissionsKeys.Side_Popup.text]: 'EVO là gì?',

  // Transaction
  [EvoMissionsKeys.Transaction.daily_reward]: 'Phần thưởng hàng ngày',
  [EvoMissionsKeys.Transaction.price_unlock_received]:
    'Gửi bởi khách hàng - Mở khóa giá',
  [EvoMissionsKeys.Transaction.price_unlock_spent]: 'Mở khóa giá',
  [EvoMissionsKeys.Transaction.referral_reward]: 'Phần thưởng giới thiệu',
  [EvoMissionsKeys.Transaction.creator_registration_reward]:
    'Phần thưởng Đăng ký Người tạo nội dung',

  [EvoMissionsKeys.Transaction.complete_1_special_completed]:
    'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.Transaction.complete_777_special_completed]:
    'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.Transaction.complete_77_special_completed]:
    'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.Transaction.complete_7_special_completed]:
    'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.Transaction.create_premium_package_completed]:
    'Tạo Gói Premium',
  [EvoMissionsKeys.Transaction.first_990_creator_claimed_completed]:
    'Đầu tiên 990 VIPs',
  [EvoMissionsKeys.Transaction.refer_1000_early_vips_completed]:
    'Giới thiệu VIP Sớm 1000',
  [EvoMissionsKeys.Transaction.refer_100_early_vips_completed]:
    'Giới thiệu VIP Sớm 100',
  [EvoMissionsKeys.Transaction.refer_10_early_vips_completed]:
    'Giới thiệu VIP Sớm 10',
  [EvoMissionsKeys.Transaction.referral_ranking_completed]:
    'Xếp hạng Giới thiệu VIP',
  [EvoMissionsKeys.Transaction.special_daily_reward_completed]:
    'Phần thưởng Đặc biệt hàng ngày',
  [EvoMissionsKeys.Transaction.tv_easter_egg_completed]:
    'Hãy tìm quả trứng phục sinh trên nền tảng',
  [EvoMissionsKeys.Transaction.update_creator_profile_completed]:
    'Cập nhật Hồ sơ Người tạo nội dung',
  [EvoMissionsKeys.Transaction.update_profile_completed]: 'Cập nhật Hồ sơ',
  [EvoMissionsKeys.Transaction.visit_main_tabs_completed]:
    'Ghé thăm Tất cả Các Tab Chính',

  // Special Mission
  // Early 990 VIPs
  [EvoMissionsKeys.Special.early_990_vips.title]: 'VIP Sớm 990',
  [EvoMissionsKeys.Special.early_990_vips.description]:
    'Bạn là người ảnh hưởng VIP sớm của chúng tôi! Đăng ký làm người tạo nội dung ngay bây giờ và mở khóa gói Premium của chúng tôi. Đứng đầu ngay bây giờ!',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d1]:
    'Nhận đơn hàng mà không mất phí dịch vụ nền tảng',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d2]: 'với Gói Premium này!',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d3]: '',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    '*Phí của bên thứ ba như PayPal & thẻ tín dụng có thể vẫn áp dụng.',

  // General
  [EvoMissionsKeys.complete_1_special.title]: 'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.complete_1_special.description]:
    'Nhận một phần thưởng nhỏ sau khi hoàn thành các nhiệm vụ đặc biệt của bạn',
  [EvoMissionsKeys.complete_1_special.extra_reward]: '',
  [EvoMissionsKeys.complete_1_special.reward_description]: '',

  [EvoMissionsKeys.complete_777_special.title]: 'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.complete_777_special.description]:
    'Nhận một phần thưởng nhỏ sau khi hoàn thành các nhiệm vụ đặc biệt của bạn',
  [EvoMissionsKeys.complete_777_special.extra_reward]: '',
  [EvoMissionsKeys.complete_777_special.reward_description]: '',

  [EvoMissionsKeys.complete_77_special.title]: 'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.complete_77_special.description]:
    'Nhận một phần thưởng nhỏ sau khi hoàn thành các nhiệm vụ đặc biệt của bạn',
  [EvoMissionsKeys.complete_77_special.extra_reward]: '',
  [EvoMissionsKeys.complete_77_special.reward_description]: '',

  [EvoMissionsKeys.complete_7_special.title]: 'Hoàn thành Nhiệm vụ Đặc biệt',
  [EvoMissionsKeys.complete_7_special.description]:
    'Nhận một phần thưởng nhỏ sau khi hoàn thành các nhiệm vụ đặc biệt của bạn',
  [EvoMissionsKeys.complete_7_special.extra_reward]: '',
  [EvoMissionsKeys.complete_7_special.reward_description]: '',

  [EvoMissionsKeys.tv_easter_egg.title]:
    'Hãy tìm quả trứng phục sinh trên nền tảng!',
  [EvoMissionsKeys.tv_easter_egg.description]:
    'Tìm Quả Trứng Phục Sinh trên trang chủ. Nó đâu đó',
  [EvoMissionsKeys.tv_easter_egg.extra_reward]: '',
  [EvoMissionsKeys.tv_easter_egg.reward_description]: '',

  [EvoMissionsKeys.update_profile.title]: 'Cập nhật Hồ sơ',
  [EvoMissionsKeys.update_profile.description]:
    'Hãy tham gia cùng chúng tôi trong hành trình này! Cập nhật hồ sơ của bạn và mở khóa phần thưởng này.',
  [EvoMissionsKeys.update_profile.extra_reward]: '',
  [EvoMissionsKeys.update_profile.reward_description]: '',

  [EvoMissionsKeys.visit_main_tabs.title]: 'Ghé thăm Tất cả Các Tab Chính',
  [EvoMissionsKeys.visit_main_tabs.description]:
    'Ghé thăm tất cả bốn tab trên thanh điều hướng trên cùng của chúng tôi để mở khóa phần thưởng này. Khám phá điều mới mẻ cùng chúng tôi!',
  [EvoMissionsKeys.visit_main_tabs.extra_reward]: '',
  [EvoMissionsKeys.visit_main_tabs.reward_description]: '',

  // Creator
  [EvoMissionsKeys.create_premium_package.title]: 'Tạo Gói Premium',
  [EvoMissionsKeys.create_premium_package.description]:
    'Tạo một gói premium trên hồ sơ của bạn. Sử dụng mẫu của chúng tôi hoặc tính năng ChatGPT để giới thiệu dịch vụ tốt nhất của bạn!',
  [EvoMissionsKeys.create_premium_package.extra_reward]: '',
  [EvoMissionsKeys.create_premium_package.reward_description]: '',

  [EvoMissionsKeys.update_creator_profile.title]:
    'Cập nhật Hồ sơ Người tạo nội dung',
  [EvoMissionsKeys.update_creator_profile.description]:
    'Sẵn sàng trở thành ngôi sao toàn cầu chưa? Cập nhật hồ sơ của bạn và cho thế giới biết về kênh tuyệt vời của bạn!',
  [EvoMissionsKeys.update_creator_profile.extra_reward]: '',
  [EvoMissionsKeys.update_creator_profile.reward_description]: '',

  // Special
  [EvoMissionsKeys.first_990_creator_claimed.title]:
    'Những Người ảnh hưởng VIP Sớm 990!',
  [EvoMissionsKeys.first_990_creator_claimed.description]:
    'Bạn là người ảnh hưởng VIP sớm của chúng tôi! Đăng ký làm người tạo nội dung ngay bây giờ và cập nhật hồ sơ của bạn với ít nhất một gói dịch vụ. Đứng đầu ngay bây giờ!',
  [EvoMissionsKeys.first_990_creator_claimed.extra_reward]:
    'Thẻ Creator Premium',
  [EvoMissionsKeys.first_990_creator_claimed.reward_description]:
    'Người tạo nội dung với thẻ premium sẽ',

  [EvoMissionsKeys.refer_1000_early_vips.title]: 'Giới Thiệu VIP Sớm',
  [EvoMissionsKeys.refer_1000_early_vips.description]:
    'Giới thiệu 1000 người trở lên để nhận phần thưởng này!',
  [EvoMissionsKeys.refer_1000_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_1000_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_100_early_vips.title]: 'Giới Thiệu VIP Sớm',
  [EvoMissionsKeys.refer_100_early_vips.description]:
    'Giới thiệu 100 người trở lên để nhận phần thưởng này!',
  [EvoMissionsKeys.refer_100_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_100_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_10_early_vips.title]: 'Giới Thiệu VIP Sớm',
  [EvoMissionsKeys.refer_10_early_vips.description]:
    'Giới thiệu 10 người trở lên để nhận phần thưởng này!',
  [EvoMissionsKeys.refer_10_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_10_early_vips.reward_description]: '',

  [EvoMissionsKeys.referral_ranking.title]: 'Xếp Hạng Giới Thiệu VIP',
  [EvoMissionsKeys.referral_ranking.description]:
    'Đối với mỗi phần trăm trong xếp hạng giới thiệu, bạn sẽ nhận được thêm điểm EVO vào cuối tháng.',
  [EvoMissionsKeys.referral_ranking.extra_reward]: '',
  [EvoMissionsKeys.referral_ranking.reward_description]: '',

  [EvoMissionsKeys.special_daily_reward.title]:
    'Phần Thưởng Đặc Biệt Hằng Ngày',
  [EvoMissionsKeys.special_daily_reward.description]:
    'Hãy đến và nhận phần thưởng hằng ngày của bạn mỗi ngày trong một tuần.',
  [EvoMissionsKeys.special_daily_reward.extra_reward]: '',
  [EvoMissionsKeys.special_daily_reward.reward_description]: '',

  [EvoMissionsKeys.Modal.already_unlock]: 'Bạn đã mở khóa',
  [EvoMissionsKeys.Modal.easter_egg]: 'quả trứng Phục Sinh này!',
  [EvoMissionsKeys.Modal.unlock_premium_pass]:
    'Bạn đã mở khóa một thẻ premium.',
  [EvoMissionsKeys.Modal.visit_profile]: 'Ghé thăm Hồ sơ của Tôi',
  [EvoMissionsKeys.Modal.not_now]: 'Không phải lúc này',
};
