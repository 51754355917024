import styled from 'styled-components';
import FileType from './FileType';
import {
  FileCrossIcon,
  FileIcon,
  ImageIcon,
  MusicIcon,
  VideoIcon,
} from 'assets/icons';
import { AnimatePresence, motion } from 'framer-motion';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useState } from 'react';
import AudioPlayer from 'components/components/MessagesPage/MediaPlayer/AudioPlayer';
import FileViewer from 'components/components/MessagesPage/MediaPlayer/FileViewer';
import Tooltip from 'components/Basics/Tooltip';
import { useEffect } from 'react';
import { useRef } from 'react';

const IconPreview = ({ file, fileType, isViewable, borderRadius }) => {
  const { isMobile } = useResponsiveScreen();
  const [openMediaViewer, setOpenMediaViewer] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  // auto hide tooltip after 2 seconds
  const timeoutRef = useRef(null);
  useEffect(() => {
    if (showTooltip) {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        setShowTooltip(false);
      }, [2000]);

      return () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      };
    }
  }, [showTooltip]);

  return (
    <>
      <Tooltip
        content={fileType === FileType.Expired ? 'This file is expired.' : ''}
        className="w-100 h-100"
        visible={showTooltip}
      >
        <Wrapper
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            if (fileType === FileType.Expired) {
              setShowTooltip(true);
              return;
            }

            if (isMobile) {
              setOpenMediaViewer(true);
            } else {
              // open video in new tab on desktop
              const url =
                file.url.startsWith('blob:') || !file.dbName
                  ? file.url
                  : `${process.env.REACT_APP_MEGA_SERVER}/proposals/uploads/${file.dbName}`;

              if (url) {
                window.open(url, '_blank', 'noopener noreferrer');
              }
            }
          }}
          borderRadius={borderRadius}
        >
          <motion.div>
            {fileType === FileType.Video && <VideoIcon />}
            {fileType === FileType.Image && <ImageIcon />}
            {fileType === FileType.Audio && <MusicIcon />}
            {fileType === FileType.Other && <FileIcon />}
            {fileType === FileType.Expired && <FileCrossIcon />}
          </motion.div>
        </Wrapper>
      </Tooltip>

      <AnimatePresence>
        {fileType !== FileType.Expired &&
          openMediaViewer &&
          (fileType === FileType.Audio && isViewable ? (
            <AudioPlayer
              name={file.name}
              type={file.type}
              size={file.size}
              url={file.url}
              onClose={() => setOpenMediaViewer(false)}
            />
          ) : (
            <FileViewer
              name={file.name}
              type={fileType}
              size={file.size}
              url={file.url}
              onClose={() => setOpenMediaViewer(false)}
            />
          ))}
      </AnimatePresence>
    </>
  );
};

export default IconPreview;

const Wrapper = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: var(--color-indigo-50);
  border: 1px solid var(--color-indigo-25);
  border-radius: ${({ borderRadius }) => borderRadius || '0.25rem'};

  &:focus {
    outline: none;
  }
`;
