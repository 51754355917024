import styled, { keyframes } from 'styled-components';

export const LoadingIcon = ({ size = 1, opacity = 0.3 }) => (
  <LoadingSvg
    width={24 * size}
    height={24 * size}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity}
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM3.7418 12C3.7418 16.5609 7.43912 20.2582 12 20.2582C16.5609 20.2582 20.2582 16.5609 20.2582 12C20.2582 7.43912 16.5609 3.7418 12 3.7418C7.43912 3.7418 3.7418 7.43912 3.7418 12Z"
    />
    <path d="M22.1291 12C23.1624 12 24.0149 11.1574 23.8544 10.1367C23.7075 9.20204 23.4503 8.28591 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40042 18.0481 1.5165 16.5922 0.913445C15.7141 0.54972 14.798 0.292459 13.8633 0.145551C12.8426 -0.0148944 12 0.837631 12 1.8709C12 2.90417 12.8481 3.72074 13.8549 3.95283C14.2998 4.05538 14.7365 4.19488 15.1603 4.37042C16.1622 4.78543 17.0726 5.39373 17.8394 6.16057C18.6063 6.92742 19.2146 7.83779 19.6296 8.83972C19.8051 9.26351 19.9446 9.70017 20.0472 10.1451C20.2793 11.1519 21.0958 12 22.1291 12Z" />
  </LoadingSvg>
);

const spinning = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingSvg = styled.svg`
  animation: ${spinning} 1s linear infinite;
`;
