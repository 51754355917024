import { motion } from 'framer-motion';
import styled from 'styled-components';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import device from 'styles/device';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const Languages = ({ languages }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();

  if (!languages || !languages.length) return null;

  return (
    <Wrapper>
      <h6 className={isMobile ? 'sbh8 text-indigo-100' : 'sbh6'}>
        {t(GeneralKeys.languages)}
      </h6>

      <Items>
        {isMobile ? (
          <span className="sbh7">
            {languages.map((language) => language.name).join(', ')}
          </span>
        ) : (
          languages.map((language) => (
            <span key={language.name}>{language.name}</span>
          ))
        )}
      </Items>
    </Wrapper>
  );
};

export default Languages;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  exit: { opacity: 0, y: 0 },
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (${device.sm}) {
    gap: 0.75rem;
  }
`;

const Items = styled.div.attrs({
  className: 'text-sm',
})`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
`;
