import styled from 'styled-components';
import { CheckIcon } from 'assets/icons';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const Input = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

const CheckMark = styled.span`
  flex-shrink: 0;
  color: var(--color-white);
  background-color: ${({ checked, error }) =>
    checked
      ? error
        ? 'var(--color-red-500)'
        : 'var(--color-indigo-500)'
      : 'var(--color-white)'};
  border: ${({ size }) =>
      size === 'lg' ? '4px' : size === 'mdl' ? '1.44px' : '1.31px'}
    solid
    ${({ error }) =>
      error ? 'var(--color-red-500)' : 'var(--color-indigo-500)'};
  width: ${({ size, mobile }) =>
    size === 'lg'
      ? '1.875rem'
      : size === 'md'
      ? '1.125rem'
      : size === 'mdl'
      ? '1.5rem'
      : size === 'sm'
      ? '0.5rem'
      : mobile
      ? '0.75rem'
      : '12px'};
  height: ${({ size, mobile }) =>
    size === 'lg'
      ? '1.875rem'
      : size === 'md'
      ? '1.125rem'
      : size === 'mdl'
      ? '1.5rem'
      : size === 'sm'
      ? '0.5rem'
      : mobile
      ? '0.75rem'
      : '12px'};
  border-radius: ${({ circle, size }) =>
    circle
      ? '50%'
      : size === 'lg' || size === 'mdl'
      ? '6px'
      : size === 'md'
      ? '3px'
      : '2px'};
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  transition: all 150ms ease-in-out;
  margin: 3px 0 0;

  ${({ disabled }) =>
    disabled
      ? `
      cursor: default;
      `
      : ''}
`;

const Label = styled.label`
  flex-shrink: 0;
  display: flex;
  align-items: start;
  gap: ${({ gap }) => gap || '0.5rem'};
  text-transform: none;
  margin: 0;
  flex-wrap: nowrap;
  text-align: left;
  color: ${({ error }) =>
    error ? 'var(--color-red-500)' : 'var(--color-indigo-500)'};
  transition: all 150ms ease-in-out;

  ${({ disabled }) =>
    disabled
      ? `
      opacity: 0.7;
      cursor: default;
      `
      : ''}
`;

/**
 * @param {string} name - The name of the checkbox.
 * @param {string} [label] - The label of the checkbox.
 * @param {boolean} checked - The state of the checkbox.
 * @param {function} onClick - The function to be called when the checkbox is clicked.
 * @param {string} [circle] - To be used when the checkbox is circular.
 * @param {boolean} [disabled] - To be used when the checkbox is disabled.
 * @param {string} [boxPosition] - Checkbox position (left or right).
 * @param {string} [gap] - The gap between the checkbox and the label.
 * @param {string} [checkBoxSize] - The size of the checkbox.
 * md | lg | sm
 * @param {string} [className] - The additional class name of the checkbox.
 * @param {string} [size] - size of checkbox , default 1.125rem
 */
const CheckBox = ({
  name,
  label,
  checked,
  onClick,
  circle,
  gap,
  disabled,
  error,
  className,
  boxClassName,
  boxStyle,
  boxPosition = 'left',
  style,
  checkBoxSize = 'md',
}) => {
  const { isMobile: mobile } = useResponsiveScreen();
  return (
    <>
      <Label
        htmlFor={name}
        style={style}
        className={className}
        gap={gap}
        error={error}
        disabled={disabled}
      >
        {boxPosition === 'right' ? label : null}
        <CheckMark
          checked={checked}
          circle={circle}
          className={boxClassName}
          error={error}
          size={checkBoxSize}
          mobile={mobile}
          style={boxStyle}
          disabled={disabled}
        >
          <CheckIcon
            size={
              checkBoxSize === 'lg'
                ? 1.1
                : checkBoxSize === 'md'
                ? 0.7
                : checkBoxSize === 'mdl'
                ? 1
                : checkBoxSize === 'sm'
                ? 0.42
                : 0.5
            }
          />
        </CheckMark>
        {boxPosition === 'left' ? label : null}
      </Label>
      <Input
        id={name}
        name={name}
        checked={checked}
        onChange={onClick}
        disabled={disabled}
      />
    </>
  );
};

export default CheckBox;
