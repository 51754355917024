import styled from 'styled-components';
import MaxLines from 'components/Basics/Typography/MaxLines';
import LexicalEditor from 'components/Basics/LexicalEditor';
import { motion } from 'framer-motion';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';

const DescriptionBox = ({ bio }) => {
  const { getPreferredDescription } = useAutoTranslation();

  let invalid = false;
  try {
    const bioObj = JSON.parse(getPreferredDescription(bio));
    if (!bioObj.length) invalid = true;
  } catch (err) {
    invalid = true;
  }

  if (invalid) return null;

  return (
    <Wrapper className="md-hidden">
      <MaxLines limit={4}>
        <LexicalEditor
          name="Bio"
          key={getPreferredDescription(bio)}
          value={getPreferredDescription(bio)}
          readOnly
        >
          <LexicalEditor.Content className="h8" />
        </LexicalEditor>
      </MaxLines>
    </Wrapper>
  );
};

export default DescriptionBox;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  },
  exit: { opacity: 0 },
})`
  width: 100%;
  font-family: 'Kumbh Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.5px;
  transition: all 150ms ease-in-out;
`;
