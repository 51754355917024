import styled from 'styled-components';
import Stack from 'components/Basics/Layout/Stack';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const MobileMessageBox = ({ value, onChange, maxChar = 500 }) => {
  const { t } = useTranslation();

  return (
    <Stack gap="0.75rem">
      <Stack direction="row" align="center" justify="space-between">
        <span className="bh6">
          {t(ProfileKeys.Modal.Request_Price.Message.title)}
        </span>
        <span className="bh8">({t(GeneralKeys.optional)})</span>
      </Stack>
      <Container>
        <TextArea
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          maxLength={maxChar}
          placeholder={t(ProfileKeys.Modal.Request_Price.Message.placeholder)}
        />
        <CharCount className="text-indigo-100 h9">
          {value.length} / {maxChar}
        </CharCount>
      </Container>
    </Stack>
  );
};

export default MobileMessageBox;

const TextArea = styled.textarea.attrs({
  className: 'scrollbar-y-thin h8',
})`
  min-width: 100%;
  background: transparent;
  resize: none;
  padding: 7px 7px 17px 11px;
  overflow-y: scroll;
  flex: 1;
  border: none;

  &::placeholder {
    color: var(--color-indigo-100);
  }

  &:focus {
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }
`;

const Container = styled.div`
  background: white;
  border: 1px solid rgba(157, 153, 176, 0.16);
  height: 143px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  align-items: flex-start;
`;

const CharCount = styled.span`
  padding: 0 0 7px 11px;
`;
