export const EmptyStarIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 20}
      height={size * 20}
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.4681 7.80241C19.4082 7.62977 19.2992 7.47814 19.1544 7.36589C19.0096 7.25364 18.8352 7.18561 18.6522 7.17004L13.2541 6.38666L10.8349 1.50704C10.7572 1.34746 10.6359 1.21288 10.4849 1.11872C10.3339 1.02455 10.1593 0.974609 9.98104 0.974609C9.80282 0.974609 9.6282 1.02455 9.47719 1.11872C9.32618 1.21288 9.20489 1.34746 9.12721 1.50704L6.708 6.37722L1.30985 7.17004C1.13426 7.19487 0.969188 7.26817 0.833357 7.38161C0.697527 7.49506 0.596379 7.64411 0.541392 7.81185C0.491058 7.97577 0.486541 8.15023 0.528326 8.31651C0.570111 8.48279 0.65662 8.63461 0.778569 8.75568L4.69674 12.531L3.74803 17.892C3.71416 18.069 3.73189 18.2518 3.79914 18.4191C3.86638 18.5863 3.98032 18.7309 4.12751 18.8358C4.27097 18.9379 4.44018 18.9981 4.61614 19.0098C4.7921 19.0214 4.96785 18.9841 5.12366 18.9019L9.98104 16.3819L14.8195 18.9113C14.9526 18.9861 15.103 19.0251 15.2559 19.0246C15.4568 19.0253 15.6528 18.9625 15.8156 18.8453C15.9628 18.7404 16.0767 18.5958 16.144 18.4285C16.2112 18.2613 16.229 18.0784 16.1951 17.9014L15.2464 12.5405L19.1645 8.76512C19.3015 8.64967 19.4028 8.49793 19.4565 8.32751C19.5102 8.1571 19.5143 7.97502 19.4681 7.80241ZM13.6336 11.5777C13.5223 11.6848 13.4391 11.8173 13.3911 11.9638C13.3432 12.1102 13.3319 12.266 13.3584 12.4178L14.0415 16.3724L10.4744 14.4848C10.3371 14.412 10.184 14.374 10.0285 14.374C9.87297 14.374 9.71985 14.412 9.58259 14.4848L6.01544 16.3724L6.69851 12.4178C6.72501 12.266 6.7138 12.1102 6.66584 11.9638C6.61789 11.8173 6.53465 11.6848 6.42339 11.5777L3.57726 8.74625L7.57132 8.17051C7.72501 8.14924 7.87111 8.09079 7.99682 8.00029C8.12252 7.90979 8.224 7.78999 8.29234 7.6514L9.98104 4.05539L11.7646 7.66084C11.833 7.79943 11.9344 7.91922 12.0601 8.00973C12.1858 8.10023 12.3319 8.15868 12.4856 8.17995L16.4797 8.75568L13.6336 11.5777Z" />
    </svg>
  );
};
