export const CardViewIcon = ({ size = 1 }) => (
  <svg
    width={15 * size}
    height={14 * size}
    viewBox="0 0 15 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1.5 13.5H13.4197C14.247 13.5 14.9197 12.8273 14.9197 12V1.5C14.9197 0.67275 14.247 0 13.4197 0H1.5C0.67275 0 0 0.67275 0 1.5V12C0 12.8273 0.67275 13.5 1.5 13.5ZM1.5 12V8.25H4.5V12H1.5ZM9 3V6.75H6V3H9ZM4.5 3V6.75H1.5V3H4.5ZM6 12V8.25H9V12H6ZM10.5 12V8.25H13.4205V12H10.5ZM13.4197 6.75H10.5V3H13.4197V6.75Z" />
  </svg>
);
