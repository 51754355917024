import Popup from 'components/Basics/Popup';
import Heading from 'components/Basics/Typography/Heading';
import Platform from 'constants/platform';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { Trans } from 'react-i18next';

const PlatformMap = {
  [Platform.YouTube]: 'YouTube',
  [Platform.Instagram]: 'Instagram',
  [Platform.Twitter]: 'X.com',
  [Platform.TikTok]: 'Tiktok',
};

const IncomingPackagesModal = ({ incompletePackages, onClose, onSubmit }) => {
  const { isMobile } = useResponsiveScreen();
  const { t } = useTranslation();

  return (
    <>
      {isMobile ? (
        <Popup
          show={!!incompletePackages.length}
          onClose={onClose}
          title={t(ProfileKeys.Modal.Edit.Incomplete_Package.title)}
          buttonText={t(GeneralKeys.save)}
          onButtonClick={onSubmit}
          secondaryButtonText={t(GeneralKeys.return)}
          dividerBelowTitle
          radius="10px"
        >
          <div>
            <Description>
              <Trans
                components={{
                  package: (
                    <>
                      {incompletePackages.map(({ platform, packageType }) => (
                        <Description
                          bold
                          key={`${platform}-${packageType}`}
                          className="sbh5"
                        >
                          {PlatformMap[platform]}
                          {' - '}
                          {t(packageType).charAt(0).toUpperCase() +
                            t(packageType).slice(1)}
                        </Description>
                      ))}
                    </>
                  ),
                }}
              >
                {t(ProfileKeys.Modal.Edit.Incomplete_Package.description)}
              </Trans>
            </Description>
          </div>
        </Popup>
      ) : (
        <Popup
          show={!!incompletePackages.length}
          onClose={onClose}
          title={t(ProfileKeys.Modal.Edit.Incomplete_Package.title)}
          buttonText={t(GeneralKeys.save)}
          onButtonClick={onSubmit}
          secondaryButtonText={t(GeneralKeys.return)}
        >
          <div>
            <Trans
              components={{
                package: (
                  <>
                    {incompletePackages.map(
                      ({ platform, packageType }, index) => (
                        <p key={`${platform}-${packageType}`} className="sbh5">
                          {PlatformMap[platform]} -{' '}
                          {t(packageType).charAt(0).toUpperCase() +
                            t(packageType).slice(1)}
                          {index !== incompletePackages.length - 1 && ', '}
                        </p>
                      )
                    )}
                  </>
                ),
              }}
            >
              {t(ProfileKeys.Modal.Edit.Incomplete_Package.description)}
            </Trans>
          </div>
        </Popup>
      )}
    </>
  );
};

export default IncomingPackagesModal;

const Description = styled(Heading).attrs({
  variant: 'SBH7',
})`
  font-weight: 500;
  letter-spacing: 0.5px;

  ${({ bold }) =>
    bold &&
    css`
      font-weight: 600;
      letter-spacing: 0.15px;
    `}
`;
