import AxiosClient from 'components/utilities/AxiosClient';
import {
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
} from 'react';

const NotificationSoundContext = createContext({
  isSoundOn: true,
  toggleSound: () => {},
  playSound: () => {},
  reloadSetting: () => {},
});

const notificationSoundPath = 'assets/audio/realtime_notification.mp3';

const getInitialSetting = async () => {
  try {
    const res = await AxiosClient.get('/realTimeSound');
    return res.data.realTimeSound;
  } catch (err) {
    console.error(err);
  }
};

const setSetting = async (isSoundOn) => {
  await AxiosClient.post('/setNotificationSettings', {
    key: 'realTimeSound',
    value: isSoundOn,
  });
};

const NotificationSoundProvider = ({ children }) => {
  const [isSoundOn, setIsSoundOn] = useState(true);

  const fetchSetting = useCallback(() => {
    getInitialSetting().then((res) => {
      setIsSoundOn(res);
    });
  }, []);

  useEffect(() => {
    fetchSetting();
  }, [fetchSetting]);

  const toggleSound = useCallback(() => {
    setIsSoundOn((prev) => {
      const newValue = !prev;
      setSetting(newValue);
      return newValue;
    });
  }, []);

  const soundRef = useRef();

  useEffect(() => {
    soundRef.current = new Audio(notificationSoundPath, { volume: 0.5 });
  }, []);

  const playSound = useCallback(() => {
    if (isSoundOn) {
      soundRef.current.play();
    }
  }, [isSoundOn]);

  return (
    <NotificationSoundContext.Provider
      value={{
        isSoundOn,
        toggleSound,
        playSound,
        reloadSetting: fetchSetting,
      }}
    >
      {children}
    </NotificationSoundContext.Provider>
  );
};

const useNotificationSound = () => useContext(NotificationSoundContext);

export { NotificationSoundProvider, useNotificationSound };
