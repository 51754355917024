import PricingKeys from 'translations/translationKeys/PricingPageKeys';

export const clientJobPositions = [
  { name: PricingKeys.Modal.JobPosition.option_1, value: 'Assistant' },
  { name: PricingKeys.Modal.JobPosition.option_2, value: 'C-Level · VP' },
  { name: PricingKeys.Modal.JobPosition.option_3, value: 'Director' },
  { name: PricingKeys.Modal.JobPosition.option_4, value: 'Executive' },
  { name: PricingKeys.Modal.JobPosition.option_5, value: 'Head of Department' },
  { name: PricingKeys.Modal.JobPosition.option_6, value: 'Independent' },
  {
    name: PricingKeys.Modal.JobPosition.option_7,
    value: 'Manager · Team Lead',
  },
  { name: PricingKeys.Modal.JobPosition.option_8, value: 'Intern' },
  { name: PricingKeys.Modal.JobPosition.option_9, value: 'Team Member' },
];

export const companyTypes = [
  {
    name: PricingKeys.Modal.CompanyType.option_1,
    value: 'Brand · Business',
  },
  { name: PricingKeys.Modal.CompanyType.option_2, value: 'E-commerce Store' },
  { name: PricingKeys.Modal.CompanyType.option_3, value: 'Marketing Agency' },
  { name: PricingKeys.Modal.CompanyType.option_4, value: 'Others' },
];
