import {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { useEvent } from 'react-use';
import Mode from '../constants/Mode';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import useUser from 'hooks/useUser';
import Loading from 'components/components/Common/Loading';
import AxiosClient from 'components/utilities/AxiosClient';
import { useMemo } from 'react';
import { AnalyticsProvider } from 'contexts/AnalyticsContext';
import UserType from 'constants/userType';
import { PackagesProvider } from 'contexts/PackagesContext';
import countryCodes from 'constants/countryCodes';

const getUser = async ({ id, handle }) => {
  return AxiosClient.get('/getUser', {
    params: {
      id,
      handle,
    },
  })
    .then((res) => res.data)
    .catch((e) => {
      console.log(e);
      throw e;
    });
};

const getCountries = async ({ userId, handle, platform }) => {
  if (!(userId || handle) || !platform) return [];
  return AxiosClient.get('/getUserCountries', {
    params: {
      userId,
      handle,
      platform,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

export const ProfileContext = createContext();
const PAGE_PADDING = 160;
const COMPONENT_MARGIN = 48;

export const ProfileContextProvider = ({ children }) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const handle = useMemo(
    () => (id?.startsWith('@') ? id.slice(1) : null),
    [id]
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { user: currentUser, isLoading: currentUserLoading } = useUser();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [userDisplayCountries, setUserDisplayCountries] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (currentUserLoading) return;

    if (id) {
      if (
        (handle && handle === currentUser?.handle) ||
        currentUser?.id === parseInt(id)
      ) {
        setUser(currentUser);
        setLoading(false);
        return;
      }

      getUser({
        id: handle ? null : id,
        handle,
      })
        .then((user) => {
          setUser(user);
          setLoading(false);
        })
        .catch(() => {
          setUser(null);
          setLoading(false);
          navigate('/page-not-found', {
            replace: true,
          });
        });
      return;
    }

    if (currentUser) {
      setUser(currentUser);
      setLoading(false);
      return;
    }

    setUser(null);
    setLoading(false);
    return;
  }, [currentUserLoading, currentUser, id, handle, navigate]);

  const isLoading = loading || currentUserLoading;

  const type = useMemo(() => user?.accountType, [user?.accountType]);

  const isMine = useMemo(
    () =>
      handle ? currentUser?.handle === handle : currentUser?.id === user?.id,
    [currentUser, user, handle]
  );

  const availablePlatforms = useMemo(() => {
    return (
      user?.links
        ?.sort((a, b) => a.priority - b.priority)
        .map((link) => link.type) || []
    );
  }, [user]);
  const [platform, setPlatform] = useState(availablePlatforms[0]);

  const [packageType, setPackageType] = useState();
  // isBasic is true if the current link of the selected platform is basic
  const isBasic = useMemo(() => {
    return (
      user?.links?.find((link) => link.type === platform)?.isBasic || false
    );
  }, [user, platform]);
  // if the current platform is not in the available platforms, set the first available platform as the current platform
  useEffect(() => {
    if (availablePlatforms.length && !availablePlatforms.includes(platform)) {
      setPlatform(availablePlatforms[0]);
    }
  }, [availablePlatforms, platform]);

  // if there is a platform in the search params, set it as the current platform
  useEffect(() => {
    const openPlatform = searchParams.get('platform');
    if (openPlatform) {
      setPlatform(openPlatform);
      setSearchParams(
        // remove the platform param from the search params and keep the rest
        [...searchParams.entries()].reduce((acc, [key, value]) => {
          if (key !== 'platform') {
            acc.set(key, value);
          }
          return acc;
        }, new URLSearchParams())
      );
    }
  }, [searchParams, setSearchParams, availablePlatforms]);

  useEffect(() => {
    const packageTypeLocal = searchParams.get('packageType');
    if (packageTypeLocal) {
      setPackageType(packageTypeLocal);
    }

    setSearchParams(
      // remove the packageType param from the search params and keep the rest
      [...searchParams.entries()].reduce((acc, [key, value]) => {
        if (key !== 'packageType') {
          acc.set(key, value);
        }
        return acc;
      }, new URLSearchParams())
    );
  }, [searchParams, setSearchParams]);

    useEffect(() => {
      if (user) {
        if (user?.accountType === UserType.Client) {
          return setUserDisplayCountries(
            user?.countries?.map((country) => {
              return country?.value.length === 2
                ? countryCodes
                    .find(
                      (code) =>
                        code.alpha2?.toLocaleLowerCase() ===
                        country?.value?.toLocaleLowerCase()
                    )
                    ?.alpha3?.toLocaleLowerCase()
                : country?.value;
            }) || []
          );
        }
        getCountries({ userId: user?.id, handle, platform }).then(
          (countries) => {
            setUserDisplayCountries(
              countries.map((country) => {
                return country.length === 2
                  ? countryCodes
                      .find(
                        (code) =>
                          code.alpha2?.toLocaleLowerCase() ===
                          country?.toLocaleLowerCase()
                      )
                      ?.alpha3?.toLocaleLowerCase()
                  : country;
              }) || []
            );
          }
        );
      }
    }, [handle, user, platform]);

  const [activeSection, setActiveSection] = useState('');

  // check current profile page is view page or edit page, setter action won't be invoked
  const mode = useMemo(
    () =>
      isMine
        ? pathname.includes('profile-edit') && !pathname.includes('@')
          ? Mode.EDIT
          : Mode.VIEW
        : Mode.PUBLIC,
    [isMine, pathname]
  );

  if (isLoading) return <Loading />;

  return (
    <ProfileContext.Provider
      value={{
        activeSection,
        setActiveSection,
        type,
        isMine,
        mode,
        user,
        isLoading,
        availablePlatforms,
        platform,
        setPlatform,
        userDisplayCountries,
      }}
    >
      <AnalyticsProvider
        userId={handle ? null : user?.id}
        handle={handle}
        platform={platform}
        disabled={
          type !== UserType.Creator ||
          mode === Mode.EDIT ||
          availablePlatforms?.length === 0
        }
        isBasic={isBasic}
      >
        <PackagesProvider
          userId={handle ? null : user?.id}
          handle={handle}
          platform={platform}
          disabled={
            type !== UserType.Creator ||
            mode === Mode.EDIT ||
            availablePlatforms?.length === 0
          }
          packageType={packageType}
        >
          {children}
        </PackagesProvider>
      </AnalyticsProvider>
    </ProfileContext.Provider>
  );
};

export const useProfileState = () => {
  const {
    type,
    isMine,
    mode,
    loaded,
    user,
    isLoading,
    activeSection,
    availablePlatforms,
    platform,
    setPlatform,
    userDisplayCountries,
  } = useContext(ProfileContext);

  return {
    activeSection,
    type,
    isMine,
    mode,
    loaded,
    user,
    isLoading,
    availablePlatforms,
    platform,
    setPlatform,
    userDisplayCountries,
  };
};

export const useSectionDetector = (key) => {
  const { setActiveSection } = useContext(ProfileContext);
  const componentRef = useRef(null);

  const checkActiveStatus = useCallback(
    (e) => {
      const { current: element } = componentRef;
      const upperBound = element?.offsetTop;
      const lowerBound = element?.offsetTop + element?.clientHeight;
      const scrollPos =
        (e?.srcElement.scrollingElement.scrollTop || 0) + PAGE_PADDING;

      if (
        scrollPos > upperBound - COMPONENT_MARGIN / 2 &&
        scrollPos < lowerBound - COMPONENT_MARGIN / 2
      ) {
        setActiveSection(key);
      }
    },
    [setActiveSection, key]
  );

  useEvent('scroll', checkActiveStatus);
  useEffect(() => {
    checkActiveStatus();
  }, [checkActiveStatus]);

  return componentRef;
};
