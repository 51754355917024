import { useCallback, useEffect, useState } from 'react';
import AxiosClient from 'components/utilities/AxiosClient';
import { useSocket } from 'contexts/SocketContext';

const PAGE_SIZE = 10;

const getOrders = async (cursor) => {
  const res = await AxiosClient.get('/orders', {
    params: {
      pageSize: PAGE_SIZE,
      cursor,
    },
  });

  return res.data;
};

const useOrders = () => {
  const socket = useSocket();
  const [cursor, setCursor] = useState(null);
  const [haveMore, setHaveMore] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  const loadMore = useCallback(async () => {
    if (fetching || !haveMore) return;
    setFetching(true);
    const {
      orders,
      cursor: nextCursor,
      haveMore: nextHaveMore,
    } = await getOrders(cursor);
    setOrders((prev) => [...prev, ...orders]);
    setCursor(nextCursor);
    setHaveMore(nextHaveMore);
    setFetching(false);
  }, [fetching, haveMore, cursor]);

  useEffect(() => {
    setLoading(true);
    setFetching(true);
    getOrders()
      .then(({ orders, cursor, haveMore }) => {
        setOrders(orders);
        setCursor(cursor);
        setHaveMore(haveMore);
      })
      .finally(() => {
        setLoading(false);
        setFetching(false);
      });
  }, []);

  const catchNewOrder = useCallback((newItem) => {
    setOrders((prev) => [newItem, ...prev]);
  }, []);

  // Catch new orders
  useEffect(() => {
    if (!socket) return;
    socket.on('newOrder', catchNewOrder);
    return () => {
      socket.off('newOrder', catchNewOrder);
    };
  }, [socket, catchNewOrder]);

  // Order updates
  useEffect(() => {
    if (!socket) return;

    const catchUpdates = ({ id, updates }) => {
      setOrders((prev) =>
        prev.map((order) =>
          order.id === id ? { ...order, ...updates } : order
        )
      );
    };

    socket.on('orderUpdate', catchUpdates);
    return () => {
      socket.off('orderUpdate', catchUpdates);
    };
  }, [socket, setOrders]);

  return { orders, loading, fetching, loadMore };
};

export default useOrders;
