export const replaceSubdomain = (url, subdomain) => {
  const replace = '://' + subdomain;

  // Prepend http:// or https://
  if (!/^\w*:\/\//.test(url)) {
    if (url.includes('localhost')) {
      url = 'http://' + url;
    } else {
      url = 'https://' + url;
    }
  }

  // Detect the presence of subdomain-like patterns in the URL
  const matches = url.match(
    /(?:https?:\/\/)?(www|kr|cn|jp|de|es|fr|in|it|pt|th|vn)\./
  );

  if (matches) {
    // If there is subdomain like "https://kr.megaevolution.io", convert to "https://jp.megaevolution.io"
    return url.replace(/:\/\/(\w+\.)/, replace);
  } else {
    // If there is no subdomain like "https://megaevolution.io", convert to "https://kr.megaevolution.io"
    return url.replace(/:\/\//, replace);
  }
};
