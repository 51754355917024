import ModalKeys from '../translationKeys/ModalKeys';

export const ModalTranslations = {
  // OfficialLaunch
  [ModalKeys.official_launch.title]: '此功能将很快在您的国家发布！',
  [ModalKeys.official_launch.content]: `
    此功能将很快在您的地区额外解锁。<br>
    我们将为您的全球合作提供最佳服务。`,

  // Patch Note
  [ModalKeys.Patch_Note.close]: '14 天后关闭',

  [ModalKeys.Notification.Dropdown.title]: '通知',
  [ModalKeys.Notification.Dropdown.no_notification]: '没有通知',
  [ModalKeys.Notification.Dropdown.bottom_btn]: '全部标记为已读',

  [ModalKeys.My_Order.Dropdown.content]: '开始您的第一笔订单！',
  [ModalKeys.My_Order.Dropdown.bottom_btn]: '查看所有订单',
};
