import React from 'react';
import { useMemo } from 'react';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useEffect } from 'react';

const ProgressLoading = ({
  radius,
  strokeWidth,
  progress,
  baseColor = 'var(--color-indigo-25)',
  progressColor = 'var(--color-indigo-500)',
  className,
}) => {
  const [progressCompleted, setProgressCompleted] = useState(false);

  const circumference = useMemo(
    () => 2 * Math.PI * (radius - strokeWidth / 2),
    [radius, strokeWidth]
  );
  const progressOffset = useMemo(
    () => circumference - (progress / 100) * circumference,
    [circumference, progress]
  );

  useEffect(() => {
    if (progress >= 100) {
      const timer = setTimeout(() => {
        setProgressCompleted(true);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [progress]);

  return (
    <motion.svg
      width={radius * 2}
      height={radius * 2}
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      className={className}
      variants={{
        hidden: {
          opacity: 0,
        },
        visible: {
          opacity: 1,
        },
      }}
      initial="hidden"
      animate="visible"
      exit="hidden"
    >
      <circle
        cx={radius}
        cy={radius}
        r={radius - strokeWidth / 2}
        fill="none"
        strokeWidth={strokeWidth}
        stroke={baseColor}
      />
      {!progressCompleted && (
        <motion.circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="none"
          strokeWidth={strokeWidth - 1}
          stroke={progressColor}
          strokeDasharray={circumference}
          strokeLinecap="round"
          style={{
            transform: 'rotate(-90deg)',
            transformOrigin: '50% 50%',
          }}
          initial={{
            strokeDashoffset: circumference,
          }}
          animate={{
            strokeDashoffset: progressOffset,
          }}
          exit={{
            strokeDashoffset: circumference,
          }}
        />
      )}
      {progressCompleted && (
        <motion.circle
          cx={radius}
          cy={radius}
          r={radius - strokeWidth / 2}
          fill="none"
          strokeWidth={strokeWidth - 1}
          stroke={progressColor}
          strokeOpacity={0.7}
          strokeDasharray={circumference}
          strokeLinecap="round"
          style={{
            transformOrigin: '50% 50%',
          }}
          animate={{
            strokeDashoffset: [
              circumference / 1.5,
              circumference / 1.15,
              circumference / 1.5,
            ],
            rotate: [0, 360],
          }}
          transition={{
            duration: 1.5,
            ease: 'linear',
            repeat: Infinity,
          }}
        />
      )}
    </motion.svg>
  );
};

export default ProgressLoading;
