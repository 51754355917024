import { QuestionMarkIcon } from 'assets/icons';
import Stack from 'components/Basics/Layout/Stack';
import Tooltip from 'components/Basics/Tooltip';
import { formatPriceInDollar } from 'components/utilities/stringFormatters';
import styled from 'styled-components';

const Box = ({ title, amount, tooltip }) => {
  return (
    <Wrapper>
      <Stack direction="row" align="center">
        <h4 className="bh4">{title}</h4>
        {tooltip && (
          <Tooltip
            className="flex align-items-center"
            interactive
            content={tooltip}
          >
            <QuestionMarkIcon />
          </Tooltip>
        )}
      </Stack>

      <span className="sbh1">
        {formatPriceInDollar(amount, {
          minFractionDigits: 0,
          maxFractionDigits: 2,
        })}
      </span>
    </Wrapper>
  );
};

export default Box;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  min-height: 10rem;
  padding: 0.75rem 2.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  border-radius: 0.75rem;
  border: 2px solid var(--color-indigo-20);
`;
