export const BackArrowIcon = ({ size = 1 }) => (
  <svg
    width={16 * size}
    height={8 * size}
    viewBox="0 0 16 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.20713 1.2643C6.13581 1.2643 4.25989 2.03811 2.81387 3.29654L0 0.482666V7.51735H7.03469L4.20518 4.68784C5.29165 3.78115 6.67514 3.21838 8.20713 3.21838C10.668 3.21838 12.8012 4.64653 13.8204 6.71656C14.0316 7.14545 14.5156 7.3962 14.9698 7.24675C15.5383 7.05962 15.8532 6.442 15.5917 5.90351C14.2594 3.15946 11.4584 1.2643 8.20713 1.2643Z" />
  </svg>
);
