export const AbstractIcon = ({ size = 1 }) => (
  <svg
    width={size * 24}
    height={size * 25}
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.12209 2.95527L2.38143 4.87284L13.0296 10.7863L16.7702 8.8687L6.12209 2.95527ZM0.0144239 6.77543C0.00714384 6.83329 0.00341797 6.89199 0.00341797 6.95125V17.8108C0.00341797 18.3384 0.298694 18.8215 0.768162 19.0622L11.3602 24.492C11.5127 24.5702 11.6759 24.6187 11.8418 24.6377C11.6865 24.4116 11.5957 24.1378 11.5957 23.8427V13.2071L0.0144239 6.77543ZM14.4082 23.5872L23.2352 19.0622C23.7047 18.8215 23.9999 18.3384 23.9999 17.8108V8.32307L20.8807 9.92206V12.9851C20.8807 13.7618 20.2511 14.3914 19.4745 14.3914C18.6978 14.3914 18.0682 13.7618 18.0682 12.9851V11.3638L14.4082 13.2401V23.5872ZM23.0935 5.62722L12.6432 0.270081C12.2404 0.0636187 11.7629 0.0636187 11.3602 0.270081L9.13442 1.41106L19.7826 7.32449L23.0935 5.62722Z"
    />
  </svg>
);
