import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: 'Traducción automática',
  [AutoTranslationKeys.Dropdown.description]:
    'Traducir automáticamente descripciones y texto escrito por el usuario.',

  [AutoTranslationKeys.Applying_Translation.title]:
    'Traducción automática aplicada.',
  [AutoTranslationKeys.Applying_Translation.btn]: 'Mostrar texto original',

  [AutoTranslationKeys.Displaying_Original.title]: 'Mostrando texto original.',
  [AutoTranslationKeys.Displaying_Original.btn]: 'Traducir',
};
