const size2 = () => (
  <svg
    width="30"
    height="20"
    viewBox="0 0 30 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8655 8.46864L24.1509 0H22.4245L16.0986 7.3532L11.0462 0H5.21875L12.8591 11.1193L5.21875 20H6.94524L13.6255 12.2348L18.9613 20H24.7887L16.8651 8.46864H16.8655ZM14.5009 11.2173L13.7267 10.1101L7.56732 1.29968H10.2191L15.1898 8.40994L15.964 9.51718L22.4253 18.7594H19.7735L14.5009 11.2177V11.2173Z"
      fill="white"
    />
  </svg>
);
export default size2;
