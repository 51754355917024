import styled from 'styled-components';
import { ChevronDownIcon } from 'assets/icons';
import { useToggle } from 'react-use';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { useController } from 'react-hook-form';
import DropDownContent from './DropDownContent';
import { t } from 'i18next';
import device from 'styles/device';

const DynamicDropdown = ({
  name,
  list,
  haveSearchBox,
  triggerText = GeneralKeys.select,
  dropDownHeight,
}) => {
  const [show, toggleShow] = useToggle(false);

  const {
    field: { value, onChange },
  } = useController({
    name: name,
  });

  return (
    <BorderedContent>
      <DropDownContent
        show={show}
        toggleShow={toggleShow}
        list={list}
        selectedCountry={value}
        selectCountry={(value) => {
          onChange(value);
        }}
        trigger={
          <TextButton onClick={toggleShow}>
            {t(
              list.find((option) => option.value === value)?.name ?? triggerText
            )}

            <Chevron show={show} />
          </TextButton>
        }
        selector="label"
        haveSearchBox={haveSearchBox}
        height={dropDownHeight}
      />
    </BorderedContent>
  );
};
export default DynamicDropdown;

const BorderedContent = styled.div`
  border: 2px solid var(--color-indigo-20);
  border-radius: 0.75rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-inline: 1.25rem;

  @media ${device.sm} {
    width: 100%;
  }
`;

const TextButton = styled.button.attrs({
  className: 'sbh5',
})`
  height: 100%;
  width: 288px;

  border: none;
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.625rem;
  text-align: left;

  & > .contents {
    max-width: 370px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media ${device.sm} {
    min-width: calc(100vw - 4.6rem);
  }

  &:focus {
    outline: none;
  }
`;

const Chevron = styled.span.attrs({
  children: <ChevronDownIcon />,
})`
  transition: all 150ms ease-in-out;
  transform: ${({ show }) => (show ? 'rotateZ(180deg)' : 'rotateZ(0deg)')};
`;
