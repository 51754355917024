import SettingsKeys from '../translationKeys/SettingsPageKeys';

export const SettingsTranslations = {
  // Signed with
  [SettingsKeys.Signed_With.wallet]: 'เข้าสู่ระบบด้วย wallet',
  [SettingsKeys.Signed_With.google]: 'เข้าสู่ระบบด้วย Google',
  [SettingsKeys.Signed_With.facebook]: 'เข้าสู่ระบบด้วย Facebook',

  // Account
  [SettingsKeys.Account.connected_email]: 'อีเมลที่เชื่อมต่อ',

  // Password
  [SettingsKeys.Password.current_password]: 'รหัสผ่านปัจจุบัน',
  [SettingsKeys.Password.new_password]: 'รหัสผ่านใหม่',
  [SettingsKeys.Password.confirm_new_password]: 'ยืนยันรหัสผ่านใหม่',

  [SettingsKeys.Password.Rule.title]: 'รหัสผ่านของคุณต้องประกอบด้วย',
  [SettingsKeys.Password.Rule.rule_1]: '8-20 ตัวอักษร',
  [SettingsKeys.Password.Rule.rule_2]: 'อักษรตัวใหญ่อย่างน้อย 1 ตัว',
  [SettingsKeys.Password.Rule.rule_3]: 'อักษรตัวเล็กอย่างน้อย 1 ตัว',
  [SettingsKeys.Password.Rule.rule_4]: 'ตัวเลขหรืออักขระพิเศษอย่างน้อย 1 ตัว',

  [SettingsKeys.Password.Error.not_follow_rule]: 'รหัสผ่านไม่เป็นไปตามเงื่อนไข',
  [SettingsKeys.Password.Error.characters]:
    'รหัสผ่านควรมี8-20ตัวอักษร ประกอบไปด้วยตัวอักษร (A-Z, a-z),ตัวเลข หรืออักขระพิเศษ',
  [SettingsKeys.Password.Error.not_match]: 'รหัสผ่านไม่ตรงกัน',
  [SettingsKeys.Password.Error.current_password_incorrect]:
    'รหัสผ่านปัจจุบันไม่ถูกต้อง',

  [SettingsKeys.Password.update_success]:
    'รหัสผ่านของคุณได้รับการอัปเดตเรียบร้อยแล้ว!',

  // Notifications
  [SettingsKeys.Notification.Description.orders]:
    'รับอีเมลเกี่ยวกับสถานะคำสั่งซื้อของฉัน',
  [SettingsKeys.Notification.Description.messages]: 'รับอีเมลสำหรับข้อความ',
  [SettingsKeys.Notification.Description.reminders]:
    'รับอีเมลเตือนวันที่ครบกำหนด',
  [SettingsKeys.Notification.Description.newsletters]:
    'รับอีเมลที่เป็นประโยชน์เกี่ยวกับรางวัล ฟังก์ชันใหม่ โปรโมชัน ฯลฯ',
  [SettingsKeys.Notification.Description.packages]:
    'รับอีเมลเกี่ยวกับแพ็กเกจรวมถึงการปลดล็อกราคาและปฏิสัมพันธ์ะหว่างลูกค้าและครีเอเตอร์',
  [SettingsKeys.Notification.Description.real_time]: 'เปิดเสียงแจ้งเตือน',

  // Referral Code
  [SettingsKeys.Referral_Code.My.title]: 'รหัสอ้างอิงของฉัน',
  [SettingsKeys.Referral_Code.My.description]: 'เรียนรู้เกี่ยวกับรางวัล',
  [SettingsKeys.Referral_Code.My.copy_code]: 'คัดลอกรหัส',
  [SettingsKeys.Referral_Code.My.copied]: 'คัดลอกสำเร็จ!',

  [SettingsKeys.Referral_Code.Enter.title]: 'ใส่รหัส',

  [SettingsKeys.Referral_Code.Modal.reward_arrive]:
    'รางวัลอ้างอิงของคุณมาถึงแล้ว',
  [SettingsKeys.Referral_Code.Modal.my_evo]: 'EVO ของฉัน',
  [SettingsKeys.Referral_Code.Modal.check_later]: 'ตรวจสอบในภายหลัง',

  [SettingsKeys.Referral_Code.Referral_Page.instruction]:
    'โปรดใส่รหัสอ้างอิงที่ถูกต้อง\nคุณสามารถค้นหารหัสนี้ในหน้าการตั้งค่า',
  [SettingsKeys.Referral_Code.Referral_Page.evo_policy]:
    'คุณสามารถได้รับ EVO พอยท์เพิ่มเติมได้ \nกรุณาตรวจสอบนโยบายของเรา',
  [SettingsKeys.Referral_Code.Referral_Page.enter_code_here]:
    'ใส่รหัสอ้างอิงที่นี่',

  // Referral System
  [SettingsKeys.Referral_System.title]: 'ระบบอ้างอิง',
  [SettingsKeys.Referral_System.button]: 'คัดลอกรหัสอ้างอิง',

  [SettingsKeys.Referral_System.Refer_A_Friend.lang]: 'th',
  [SettingsKeys.Referral_System.Referral_Reward.lang]: 'th',

  // Deactivate/Delete
  [SettingsKeys.Deactivate_Delete.title]: 'ปิดการใช้งาน/ลบ',

  [SettingsKeys.Deactivate_Delete.Q1.title]:
    'จะเกิดอะไรขึ้นหากฉันปิดการใช้งานบัญชีของฉัน?',
  [SettingsKeys.Deactivate_Delete.Q1.description]: `
    <heading><span>{{dot}}</span> โปรไฟล์ของคุณจะไม่ปรากฏในรายการค้นหาจนกว่าคุณจะเปิดใช้งานบัญชีของคุณอีกครั้ง</heading> <br>
    <heading><span>{{dot}}</span> ข้อมูลที่เก็บไว้ในบัญชีของคุณจะถูกซ่อน</heading> <br>
    <heading><span>{{dot}}</span> คุณสามารถเปิดใช้งานบัญชีของคุณอีกครั้งได้โดยเข้าสู่ระบบ</heading>`,

  [SettingsKeys.Deactivate_Delete.Q2.title]:
    'จะเกิดอะไรขึ้นหากฉันลบบัญชีของฉัน?',
  [SettingsKeys.Deactivate_Delete.Q2.description]: `
    <heading><span>{{dot}}</span> โปรไฟล์ของคุณจะถูกนำออกจากรายการค้นหา</heading> <br>
    <heading><span>{{dot}}</span> ข้อมูลทั้งหมดในบัญชีของคุณจะถูกลบอย่างถาวร</heading> <br>
    <heading><span>{{dot}}</span> คุณไม่สามารถเปิดใช้งานบัญชีของคุณได้อีก</heading>`,

  [SettingsKeys.Deactivate_Delete.warning]:
    'หากคุณมีคำสั่งซื้อที่กำลังดำเนินการ ไม่สามารถปิดการใช้งาน/ลบบัญชีของคุณได้ โปรดยกเลิกคำสั่งซื้อของคุณก่อนที่จะปิดการใช้งาน/ลบบัญชีของคุณ',

  // Modal
  [SettingsKeys.Modal.Wallet_Connected.title]:
    'Wallet ใหม่ของคุณเชื่อมต่อสำเร็จแล้ว!',

  [SettingsKeys.Modal.Notification.Newsletters.title]:
    'คุณแน่ใจหรือไม่ว่าจะยกเลิกการสมัครรับจดหมายข่าวของเรา?',
  [SettingsKeys.Modal.Notification.Newsletters.description]:
    'คุณจะไม่สามารถรับข้อมูลอัปเดตเกี่ยวกับรางวัล ฟีเจอร์ใหม่ และอื่นๆอีกมากมายได้ หากคุณยกเลิกการสมัคร เราจะพยายามอย่างดีที่สุดเพื่อส่งมอบข้อมูลที่เป็นประโยชน์ให้กับคุณ',

  [SettingsKeys.Modal.Notification.Update_Success.title]:
    'การแจ้งเตือนของคุณถูกอัปเดตเรียบร้อยแล้ว!',

  [SettingsKeys.Modal.Referral_Code.Submit_Success.title]:
    'รหัสของคุณถูกส่งเรียบร้อยแล้ว!',
  [SettingsKeys.Modal.Referral_Code.Submit_Success.description]:
    'ตอนนี้คุณสามารถรับคะแนน EVO พิเศษจากการเข้าร่วมกิจกรรมต่างๆ บนแพลตฟอร์มของเรา โปรด<u>คลิกที่นี่</u>เพื่อเรียนรู้เพิ่มเติมเกี่ยวกับรางวัลรหัสอ้างอิง',

  [SettingsKeys.Modal.Referral_Code.Submit_Valid.title]:
    'โปรดใส่รหัสอ้างอิงที่ถูกต้อง',
  [SettingsKeys.Modal.Referral_Code.Submit_Valid.description]:
    'รหัสที่คุณใส่ไม่ถูกต้อง โปรดใส่รหัสอ้างอิงที่ถูกต้อง.',

  [SettingsKeys.Modal.Deactivate.title]:
    'คุณแน่ใจหรือไม่ว่าจะปิดการใช้งานบัญชีของคุณ?',
  [SettingsKeys.Modal.Deactivate.description]:
    'คุณสามารถเปิดใช้งานบัญชีของคุณอีกครั้งได้โดยเข้าสู่ระบบ',

  [SettingsKeys.Modal.Delete.title]: 'คุณแน่ใจหรือไม่ว่าต้องการลบบัญชีของคุณ?',
  [SettingsKeys.Modal.Delete.description]:
    'เมื่อคุณลบบัญชีของคุณแล้ว คุณจะไม่สามารถเปิดใช้งานอีกครั้งได้ ข้อมูลของคุณจะถูกลบและไม่สามารถกู้คืนได้ ข้อความและข้อมูลที่เกี่ยวข้องกับคำสั่งซื้อที่แสดงต่ออีกฝ่ายอาจยังคงอยู่',

  [SettingsKeys.Modal.Complete_Cancel_Order.title]:
    'โปรดยกเลิกคำสั่งซื้อของคุณก่อนที่จะปิดการใช้งาน/ลบบัญชีของคุณ',
  [SettingsKeys.Modal.Complete_Cancel_Order.description]:
    'หากคุณมีคำสั่งซื้อที่กำลังดำเนินการอยู่ จะไม่สามารถปิดการใช้งาน/ลบบัญชีของคุณได้',

  [SettingsKeys.Modal.Why_Leaving.title]: 'โปรดแจ้งเหตุผลที่คุณออกจากแพลตฟอร์ม',
  [SettingsKeys.Modal.Why_Leaving.reason_1]: 'ไม่มีบริการที่ฉันต้องการใช้งาน',
  [SettingsKeys.Modal.Why_Leaving.reason_2]: 'ราคาสูงเกินไป',
  [SettingsKeys.Modal.Why_Leaving.reason_3]: 'มีข้อผิดพลาดบ่อย',
  [SettingsKeys.Modal.Why_Leaving.reason_4]:
    'สิทธิประโยชน์มีน้อย เช่น คูปองและการจอง',
  [SettingsKeys.Modal.Why_Leaving.reason_5]: 'คุณภาพของบริการต่ำ',
  [SettingsKeys.Modal.Why_Leaving.reason_6]: 'ฉันพบบริการอื่นๆ',
  [SettingsKeys.Modal.Why_Leaving.reason_7]: 'ฉันพบสมาชิกที่ไม่สุภาพ',
  [SettingsKeys.Modal.Why_Leaving.reason_8]: 'ฉันต้องการลบข้อมูลของฉัน',
  [SettingsKeys.Modal.Why_Leaving.button]: 'ลบบัญชี',

  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.title]:
    'คุณไม่สามารถปิดใช้งานหรือลบบัญชีของคุณได้ในขณะนี้',
  [SettingsKeys.Modal.Cannot_Deactivate_Delete_Yet.description]:
    'คุณสามารถปิดการใช้งานหรือลบบัญชีได้ภายใน 24 ชั่วโมงหลังจากสร้างครั้งแรก กรุณาลองใหม่อีกครั้งในภายหลัง',
};
