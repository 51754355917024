import { ArrowLeftIcon, DownloadIcon } from 'assets/icons';
import IconButton from 'components/Basics/Buttons/IconButton';
import Stack from 'components/Basics/Layout/Stack';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const fileSize = (size) => {
  if (size < 1024) return `${size} B`;

  if (size < 1024 * 1024) return `${(size / 1024).toFixed(1)} KB`;

  if (size < 1024 * 1024 * 1024)
    return `${(size / (1024 * 1024)).toFixed(1)} MB`;

  return `${(size / (1024 * 1024 * 1024)).toFixed(1)} GB`;
};

const Viewer = ({
  name,
  size,
  url,
  onClose,
  enableFullscreen,
  className,
  hideableControls,
  controls,
  children,
}) => {
  const [showOptions, setShowOptions] = useState(true);

  // on browser back button press, onClose is called and actual back button press is ignored
  // this is a hack to fix that
  const handleBackButton = useCallback(
    (e) => {
      e.preventDefault();
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener('unload', handleBackButton);

    return () => {
      window.removeEventListener('unload', handleBackButton);
    };
  }, [handleBackButton]);

  return (
    <Wrapper>
      <AnimatePresence>
        {(!enableFullscreen || showOptions) && (
          <Topbar>
            <IconButton className="text-white" onClick={onClose}>
              <ArrowLeftIcon />
            </IconButton>
          </Topbar>
        )}
      </AnimatePresence>

      <Main className={className} onClick={() => setShowOptions(!showOptions)}>
        {children}
      </Main>

      <AnimatePresence>
        {enableFullscreen && !showOptions && hideableControls ? null : (
          <Footer>
            {controls}

            <AnimatePresence mode="popLayout">
              {enableFullscreen && !showOptions ? null : (
                <BottomBar disableAnimation={hideableControls}>
                  <Stack gap="0.625rem" direction="row" className="text-white">
                    <FileName>{name}</FileName>
                    <Size>({fileSize(size)})</Size>
                  </Stack>

                  <IconButton
                    className="text-white"
                    onClick={() => {
                      const link = document.createElement('a');
                      link.href = url;
                      link.download = name;
                      link.click();
                      link.remove();
                    }}
                  >
                    <DownloadIcon size={1.3} />
                  </IconButton>
                </BottomBar>
              )}
            </AnimatePresence>
          </Footer>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default Viewer;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10000;
`;

const Topbar = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  transition: {
    type: 'tween',
    duration: 0.15,
  },
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: flex-start;
  padding: 1rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10001;
`;

const Main = styled.div`
  flex: 1;
  width: 100%;
`;

const Footer = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  transition: {
    type: 'tween',
    duration: 0.15,
  },
  layout: true,
})`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding-bottom: 0.5rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10001;
`;

const BottomBar = styled(motion.div).attrs(({ disableAnimation }) =>
  disableAnimation
    ? {}
    : {
        variants: {
          hidden: { opacity: 0, y: 10 },
          visible: { opacity: 1, y: 0 },
        },
        initial: 'hidden',
        animate: 'visible',
        exit: 'hidden',
        transition: {
          type: 'tween',
          duration: 0.15,
        },
      }
)`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
`;

const FileName = styled.p.attrs({
  className: 'h8',
})`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: inherit;
`;

const Size = styled.p.attrs({
  className: 'h8',
})`
  color: inherit;
  opacity: 0.5;
`;
