import { AboutPageTranslations } from './AboutPageTranslations';
import { GeneralTranslations } from './GeneralTranslations';
import { DeactivatePageTranslations } from './DeactivatedPageTranslations';
import { DeletedPageTranslations } from './DeletedPageTranslations';
import { ModalTranslations } from './ModalTranslations';
import { FilterTranslations } from './FilterTranslations';
import { InfluencerMarketingPageTranslations } from './InfluencerMarketingPageTranslations';
import { ProfileTranslations } from './ProfilePageTranslations';
import { LoginTranslations } from './LoginPageTranslations';
import { FooterTranslations } from './FooterTranslations';
import { PricingTranslations } from './PricingPageTranslations';
import { CreatorRegistrations } from './CreatorRegistrationPageTranslations';
import { SettingsTranslations } from './SettingsPageTranslations';
import { ExpiredLinkTranslations } from './ExpiredLinkPageTranslations';
import { NotFoundTranslations } from './NotFoundPageTranslations';
import { FAQTranslations } from './FAQPageTranslations';
import { EvoMissionsTranslations } from './EvoMissionsPageTranslations';
import { CookiePolicyTranslations } from './CookiePolicyPageTranslations';
import { PrivacyPolicyTranslations } from './PrivacyPolicyPageTranslations';
import { TermsAndConditionsTranslations } from './TermsAndConditionsPageTranslations';
import { InvitationTranslations } from './InvitationPageTranslations';
import { GuideTranslations } from './GuidePageTranslations';
import { AutoTranslations } from './AutoTranslations';
import { MessageTranslations } from './MessagePageTranslations';
import { ProposalPageTranslations } from './ProposalPageTranslations';
import { BillingTranslations } from './BillingPageTranslations';

export const TRANSLATIONS_VI = {
  ...GeneralTranslations,
  ...ModalTranslations,
  ...FilterTranslations,
  ...AboutPageTranslations,
  ...DeactivatePageTranslations,
  ...DeletedPageTranslations,
  ...ProfileTranslations,
  ...InfluencerMarketingPageTranslations,
  ...LoginTranslations,
  ...FooterTranslations,
  ...PricingTranslations,
  ...CreatorRegistrations,
  ...SettingsTranslations,
  ...ExpiredLinkTranslations,
  ...NotFoundTranslations,
  ...CookiePolicyTranslations,
  ...FAQTranslations,
  ...EvoMissionsTranslations,
  ...PrivacyPolicyTranslations,
  ...TermsAndConditionsTranslations,
  ...InvitationTranslations,
  ...GuideTranslations,
  ...AutoTranslations,
  ...MessageTranslations,
  ...ProposalPageTranslations,
  ...BillingTranslations,
};
