import Stack from 'components/Basics/Layout/Stack';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const MessageBox = ({ value, onChange, maxChar }) => {
  const { t } = useTranslation();

  return (
    <Stack gap="1rem">
      <Stack direction="row" align="center" justify="space-between">
        <span className="sbh5">
          {t(ProfileKeys.Modal.Request_Price.Message.title)}{' '}
          <span className="sbh6">({t(GeneralKeys.optional)})</span>
        </span>
        <span
          className={`transition-all ${
            value.length >= maxChar ? 'text-red-500' : 'text-indigo-100'
          } h7`}
        >
          {value.length} / {maxChar}
        </span>
      </Stack>
      <TextArea
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        maxLength={maxChar}
        placeholder={t(ProfileKeys.Modal.Request_Price.Message.placeholder)}
      />
    </Stack>
  );
};

export default MessageBox;

const TextArea = styled.textarea.attrs({
  className: 'scrollbar-y-thin h6.5 line-height-1.6',
})`
  min-width: 100%;
  height: 158px;
  border-radius: 0.75rem;
  border: 2px solid rgba(157, 153, 176, 0.16);
  background: transparent;
  resize: none;
  padding: 0.625rem 0.75rem 0.625rem 1.125rem;
  overflow-y: scroll;

  &::placeholder {
    color: var(--color-indigo-100);
    transition: all 150ms ease-in-out;
  }

  &:focus {
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }
`;
