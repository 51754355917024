import Stack from 'components/Basics/Layout/Stack';
import { usePackages } from 'contexts/PackagesContext';
import { useMemo } from 'react';
import styled from 'styled-components';
import Variant from '../../packagesCardVariant';
import ContentCarousal from '../ContentBox/ContentCarousal';
import PlatformIcon from 'components/Basics/PlatformIcon';
import Platform, { idRegex } from 'constants/platform';
import { useCallback } from 'react';
import YoutubePlayer from './YoutubePlayer';
import Tweet from './Tweet';
import InstagramPost from './InstagramPost';
import TikTok from './TikTok';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import FeaturedImage from './FeaturedImage';

const getItemWidth = (platform) => {
  return {
    [Platform.YouTube]: 528,
    [Platform.Twitter]: 480,
    [Platform.Instagram]: 360,
    [Platform.TikTok]: 325,
  }[platform];
};

const getItemHeight = (platform) => {
  return {
    [Platform.YouTube]: 297,
    [Platform.Twitter]: 360,
    [Platform.Instagram]: 516,
    [Platform.TikTok]: 575,
  }[platform];
};

const getItem = (platform) => {
  return {
    [Platform.YouTube]: YoutubePlayer,
    [Platform.Twitter]: Tweet,
    [Platform.Instagram]: InstagramPost,
    [Platform.TikTok]: TikTok,
  }[platform];
};

const ContentBox = ({ variant, onInternalRefChange, featuredImageAction }) => {
  const { t } = useTranslation();

  const { loading, currentPackage, isCurrentPackageComplete, platform } =
    usePackages();
  // const platform = 'tiktok';
  // const platform = 'instagram';

  const extractContentsIds = useCallback(
    (contents) => {
      const regex = idRegex[platform];

      return contents.filter(Boolean).map((content) => {
        if (regex.test(content)) {
          return content.match(regex)[1];
        } else {
          return content;
        }
      });
    },
    [platform]
  );

  const contents = useMemo(() => {
    const ids = extractContentsIds(currentPackage?.sampleContents || []);
    // const ids = ['Ck7kvD3vQln', 'CQBHOhWhQ92']; // instagram
    // const ids = ['7202607261791931675', '7202637027550989614']; // tiktok

    if (ids.length > 0 && currentPackage.featuredContent)
      return ids.concat('featuredContent');

    return ids;
  }, [currentPackage, extractContentsIds]);

  // details box have 2x2 layout for instagram and tiktok and when there are sample contents
  const isTwoByTwo = useMemo(() => {
    return (
      (platform === Platform.Instagram || platform === Platform.TikTok) &&
      currentPackage?.sampleContents.length > 0
    );
  }, [platform, currentPackage?.sampleContents]);

  const itemWidth = useMemo(
    () =>
      getItemWidth(
        (platform === Platform.TikTok || platform === Platform.Instagram) &&
          !isTwoByTwo
          ? Platform.YouTube
          : platform
      ),
    [platform, isTwoByTwo]
  );
  const itemHeight = useMemo(
    () =>
      getItemHeight(
        (platform === Platform.TikTok || platform === Platform.Instagram) &&
          !isTwoByTwo
          ? Platform.YouTube
          : platform
      ),
    [platform, isTwoByTwo]
  );

  if (
    loading ||
    (variant !== Variant.Preview && !isCurrentPackageComplete) ||
    !contents.length ||
    !contents.filter(Boolean).length
  )
    return isCurrentPackageComplete && currentPackage.featuredContent ? (
      <Absolute>
        <FeaturedImage
          onRefChange={onInternalRefChange}
          itemWidth={itemWidth}
          itemHeight={itemHeight}
          onClick={featuredImageAction}
        />
      </Absolute>
    ) : (
      <Stack
        ref={onInternalRefChange}
        direction="row"
        gap="1rem"
        align="center"
        justify="center"
        width="528px"
        height="297px"
        className="bg-pink-75 position-absolute start-0 top-0"
      >
        {platform && <PlatformIcon platform={platform} variant="size3" />}
        <span className="h6 m-0 line-height-1.6 text-indigo-100">
          {t(ProfileKeys.Package.YouTube.Contents.video_prepare)}
        </span>
      </Stack>
    );

  const Item = getItem(platform);

  if (contents.length === 1) {
    return (
      <Absolute>
        <Item
          contentId={contents[0]}
          inView
          onInternalRefChange={onInternalRefChange}
        />
      </Absolute>
    );
  }

  return (
    <Absolute>
      <ContentCarousal
        contents={contents}
        itemWidth={itemWidth}
        itemHeight={itemHeight}
        onInternalRefChange={onInternalRefChange}
        featuredImageAction={featuredImageAction}
        Item={Item}
      />
    </Absolute>
  );
};

export default ContentBox;

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;
