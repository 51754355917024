import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import device from 'styles/device';
import { useToggle } from 'react-use';
import {
  ChevronDownIcon,
} from 'assets/icons';
import languages from 'constants/translationLanguages';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import Sidebar from './Header/Sidebar';
import LanguageModal from './LanguageModal';

const LanguageFlag = ({
  white,
  top = false,
  mobileLarge,
  showChevronIcon = true,
}) => {
  const [isOpen, toggleOpen] = useToggle(false);
  const { isMobile } = useResponsiveScreen();

  const { i18n } = useTranslation();

  return (
    <Wrapper>
      <FlagWrapper white={white} onClick={toggleOpen} mobileLarge={mobileLarge}>
        <Flag
          src={
            languages.find(
              ({ value }) =>
                value === i18n.language || i18n.language.includes(value)
            )?.flag
          }
          alt="lang"
          mobileLarge={mobileLarge}
        />
        {!top && showChevronIcon && (
          <span className="rotate me-2">
            <ChevronDownIcon />
          </span>
        )}
      </FlagWrapper>
      {isMobile && isOpen && (
        <Sidebar
          visible={isOpen && isMobile}
          toggleVisible={toggleOpen}
          viewType="languages"
        />
      )}
      <LanguageModal
        isOpen={isOpen && !isMobile}
        onClose={() => toggleOpen(false)}
      />
    </Wrapper>
  );
};

export default LanguageFlag;

const Wrapper = styled.div`
  position: relative;
`;

const FlagWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  color: ${({ white }) =>
    white ? 'var(--color-white)' : 'var(--color-indigo-500)'};
  background: transparent;

  .rotate {
    rotate: 180deg;
  }
  @media ${device.sm} {
    width: ${({ mobileLarge }) => mobileLarge && '57px'};
  }
`;

const Flag = styled.img`
  width: ${({ mobileLarge }) => (mobileLarge ? '34.26px' : '15px')};
  box-shadow: var(--shadow-flag);
  border-radius: 1px;

  @media ${device.md} {
    border-radius: 3px;
    width: 2rem;
  }
`;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 2.25rem;
  padding: 1.5rem 0 0;
`;

const Item = styled.button.attrs({
  className: 'h5',
})`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ selected }) =>
    selected ? 'var(--color-indigo-500)' : 'var(--color-indigo-100)'};
  width: 100%;
  text-align: left;
  display: flex;
  gap: 0.625rem;
  align-items: center;

  &:hover {
    background: var(--color-indigo-75);
  }

  &:disabled {
    color: var(--color-indigo-25);
  }
`;
