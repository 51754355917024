export const SpiralIcon = ({ size = 1, className }) => (
  <svg
    width={200 * size}
    height={200 * size}
    viewBox="0 0 200 200"
    fill="currentColor"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.0547 1.00784C89.8472 3.14124 75.9271 10.294 63.2396 19.3921C-28.4731 85.3371 -18.7583 181.212 81.5097 198.906C160.897 212.898 239.487 88.7881 177.935 46.6234C139.51 20.2707 79.4072 49.8861 68.2421 100.584C58.7446 143.878 107.972 175.44 140.742 147.142C174.527 117.965 174.165 77.1802 140.017 76.9293C113.482 76.741 97.8222 112.255 121.095 119.784C134.87 124.239 151.11 110.372 140.307 103.408C136.755 101.086 134.362 101.588 133.13 104.725C130.737 111.251 122.762 115.894 121.457 111.565C117.615 99.2665 140.235 77.1175 149.152 84.4587C161.84 94.8743 151.11 124.741 127.982 143.251C93.7622 170.67 64.1821 125.495 88.6872 83.1416C116.89 34.3885 176.195 28.7412 186.997 73.792C198.67 122.294 139.655 193.886 89.6297 191.564C25.5394 188.615 -7.30318 131.957 25.7569 75.6122C43.0121 46.1221 80.0596 14.3098 107.537 5.4C118.557 1.82353 111.452 -1.81569 99.0547 1.00784Z"
    />
  </svg>
);
