export const CoinBaseIcon = ({ size = 1, ...rest }) => {
  return (
    <svg
      width={size * 38}
      height={size * 39}
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g filter="url(#filter0_d_35945_155164)">
        <g clipPath="url(#clip0_35945_155164)">
          <rect x="3" y="1.3999" width="32" height="32" rx="6" fill="#0052FF" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.75 17.3999C7.75 23.6131 12.7868 28.6499 19 28.6499C25.2132 28.6499 30.25 23.6131 30.25 17.3999C30.25 11.1867 25.2132 6.1499 19 6.1499C12.7868 6.1499 7.75 11.1867 7.75 17.3999ZM16.125 13.7749C15.7108 13.7749 15.375 14.1107 15.375 14.5249V20.2749C15.375 20.6891 15.7108 21.0249 16.125 21.0249H21.875C22.2892 21.0249 22.625 20.6891 22.625 20.2749V14.5249C22.625 14.1107 22.2892 13.7749 21.875 13.7749H16.125Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_35945_155164"
          x="0"
          y="0.399902"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_35945_155164"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_35945_155164"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_35945_155164">
          <rect x="3" y="1.3999" width="32" height="32" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
