import LazyImage from 'components/Basics/LazyImage';
import Platform from 'constants/platform';
import { usePackages } from 'contexts/PackagesContext';
import { useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { Trans } from 'react-i18next';

const FeaturedImage = ({ onRefChange, itemWidth, itemHeight, onClick }) => {
  const { currentPackage, platform } = usePackages();
  const { t } = useTranslation();

  const contentName = useMemo(() => {
    return {
      [Platform.YouTube]: GeneralKeys.videos_lowercase,
      [Platform.Twitter]: GeneralKeys.tweets_lowercase,
      [Platform.Instagram]: GeneralKeys.posts_lowercase,
      [Platform.TikTok]: GeneralKeys.videos_lowercase,
    }[platform];
  }, [platform]);

  return (
    <Wrapper ref={onRefChange} itemWidth={itemWidth} itemHeight={itemHeight}>
      <LazyImage
        src={
          typeof currentPackage.featuredContent === 'object'
            ? URL.createObjectURL(currentPackage.featuredContent)
            : currentPackage.featuredContent
        }
        lazySrc={
          typeof currentPackage.minFeaturedContent === 'object'
            ? URL.createObjectURL(currentPackage.minFeaturedContent)
            : currentPackage.minFeaturedContent
        }
        alt="Featured Content"
        width="100%"
        height="100%"
        className="bg-pink-75"
      />

      <Overlay onClick={onClick}>
        <Trans values={{ content: t(contentName) }}>
          {t(ProfileKeys.Package.click_to_view_recent)}
        </Trans>
      </Overlay>
    </Wrapper>
  );
};

export default FeaturedImage;

const Wrapper = styled.div`
  flex-shrink: 0;
  width: ${({ itemWidth }) => (itemWidth ? itemWidth + 'px' : '100%')};
  height: ${({ itemHeight }) => (itemHeight ? itemHeight + 'px' : '100%')};
  position: relative;
`;

const Overlay = styled.button.attrs({
  className: 'sbh5',
})`
  border: none;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  z-index: 10;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;
