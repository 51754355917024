import AxiosClient from 'components/utilities/AxiosClient';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStorage, useToggle } from 'react-use';
import { languageSubdomains } from '../constants/languages';
import { replaceSubdomain } from '../components/utilities/replaceSubdomain';

const AutoTranslationContext = createContext();

const setAutoTranslateSession = async (
  autoTranslate = true,
  defaultLanguage = 'en'
) => {
  try {
    await AxiosClient.post('/setAutoTranslateSession', {
      autoTranslate,
      defaultLanguage,
    });
  } catch (err) {
    console.log(err);
  }
};

const updateUserLanguage = async (selectedLanguage) => {
  try {
    await AxiosClient.patch('/updateLanguage', {
      language: selectedLanguage,
    });
  } catch (err) {
    console.log(err);
  }
};

const reloadPageList = [
  // profile page regex
  {
    regex: /^\/(@[a-zA-Z0-9_.-]+|[0-9]+)$/,
    alwaysDisplayTranslation: true,
  },
  // search page regex
  {
    regex: /^\/search\b/,
    alwaysDisplayTranslation: true,
  },
  // regex for "/" path
  {
    regex: /^\/$/,
    alwaysDisplayTranslation: false,
  },
  // regex for "/checkout" path
  {
    regex: /^\/checkout$/,
    alwaysDisplayTranslation: false,
  },
];

const AutoTranslationProvider = ({ children }) => {
  const [autoTranslate, setAutoTranslate] = useLocalStorage(
    'autoTranslate',
    true
  );
  const [displayTranslatedText, toggleDisplayTranslatedText] = useToggle(false);
  const [haveTranslatedText, setHaveTranslatedText] = useState(false);

  const { i18n } = useTranslation();

  const toggleAutoTranslate = useCallback(() => {
    setAutoTranslate(!autoTranslate);
  }, [autoTranslate, setAutoTranslate]);

  const getPreferredText = useCallback(
    (value) => {
      if (typeof value === 'object') {
        setHaveTranslatedText(true);
        if (displayTranslatedText) {
          return value?.translatedText || value?.text;
        } else {
          return value?.text;
        }
      } else {
        return value;
      }
    },
    [displayTranslatedText, setHaveTranslatedText]
  );

  const getPreferredDescription = useCallback(
    (value) => {
      if (typeof value === 'object') {
        setHaveTranslatedText(true);
        if (displayTranslatedText) {
          return JSON.stringify({
            editorState: {
              root: {
                children: [
                  {
                    children: [
                      {
                        detail: 0,
                        format: 0,
                        mode: 'normal',
                        style: '',
                        text: value?.translatedText,
                        type: 'text',
                        version: 1,
                      },
                    ],
                    direction: 'ltr',
                    format: '',
                    indent: 0,
                    type: 'paragraph',
                    version: 1,
                  },
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'root',
                version: 1,
              },
            },
            text: value?.translatedText,
            length: value?.translatedText?.length,
          });
        } else {
          return value?.text;
        }
      } else {
        return value;
      }
    },
    [displayTranslatedText, setHaveTranslatedText]
  );

  const shouldReloadPageOnLanguageChange = useCallback(async () => {
    const selectedLanguage = i18n.language;
    const subdomain = languageSubdomains[selectedLanguage] || '';
    let url = window.location.href;
    await updateUserLanguage(selectedLanguage);

    if (process.env.REACT_APP_NODE_ENV !== 'development') {
      url = replaceSubdomain(url, subdomain);
      window.location.replace(url);
    }
  }, [i18n.language]);

  useEffect(() => {
    setAutoTranslateSession(autoTranslate, i18n.language);
  }, [i18n.language, autoTranslate]);

  useEffect(() => {
    if (
      reloadPageList.some(
        (item) =>
          item.regex.test(window.location.pathname) &&
          item.alwaysDisplayTranslation
      )
    ) {
      toggleDisplayTranslatedText(autoTranslate);
    } else {
      toggleDisplayTranslatedText(true);
    }
  }, [toggleDisplayTranslatedText, autoTranslate]);

  const values = useMemo(
    () => ({
      autoTranslate,
      toggleAutoTranslate,
      getPreferredText,
      getPreferredDescription,
      displayTranslatedText,
      toggleDisplayTranslatedText,
      haveTranslatedText:
        haveTranslatedText &&
        reloadPageList.some((item) =>
          item.regex.test(window.location.pathname)
        ),
      shouldReloadPageOnLanguageChange,
    }),
    [
      autoTranslate,
      toggleAutoTranslate,
      getPreferredText,
      getPreferredDescription,
      displayTranslatedText,
      toggleDisplayTranslatedText,
      haveTranslatedText,
      shouldReloadPageOnLanguageChange,
    ]
  );

  return (
    <AutoTranslationContext.Provider value={values}>
      {children}
    </AutoTranslationContext.Provider>
  );
};

const useAutoTranslation = () => useContext(AutoTranslationContext);

export { AutoTranslationProvider, useAutoTranslation };
