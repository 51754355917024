import BannerModal from 'components/Basics/Banners/BannerModal';
import GrayButton from 'components/Basics/Buttons/GrayButton';
import IndigoButton from 'components/Basics/Buttons/IndigoButton';
import YellowButton from 'components/Basics/Buttons/YellowButton';
import TopBorderedCard from 'components/Basics/Cards/TopBorderedCard';
import Heading from 'components/Basics/Typography/Heading';
import AxiosClient from 'components/utilities/AxiosClient';
import { useAuth } from 'hooks/useAauth';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import useUser from 'hooks/useUser';
import { useCallback } from 'react';
import styled from 'styled-components';
import device from 'styles/device';
import withAuthHOC from './withAuthHOC';
import LazyImage from 'components/Basics/LazyImage';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';

const ReactivateModal = ({ flowManager, setIsLoading, loading }) => {
  const { t } = useTranslation();

  const { refetch } = useUser();
  const { utils } = useAuth();
  const { isMobile } = useResponsiveScreen();

  const { navigateFlow, closeModal } = flowManager;

  const reactivate = useCallback(async () => {
    setIsLoading(true);
    const res = await AxiosClient.get('/reactivate');
    const storageData = {
      email: res.data?.email,
      id: res.data?.id,
      type: res.data?.type,
    };
    localStorage.setItem('user', JSON.stringify(storageData));
    setIsLoading(false);
    await refetch().then(() => {
      closeModal();
    });
  }, [refetch]);

  function onClose() {
    navigateFlow({
      flow: 'sign_in',
      step: 0,
    });
  }

  return (
    <>
      <BannerModal isOpen={true} onClose={onClose} forceOpen={true}>
        {!isMobile && (
          <ModalBody>
            {loading()}
            <TopBorderedCard color="violet" width="550px" height="600px">
              <Content>
                <LazyImage
                  src="img/misc/deactivate-image.png"
                  lazySrc="img/misc/deactivate-image_small.png"
                  width="250px"
                  circle
                  className="shadow-form"
                />
                <Heading variant="BH2">
                  {t(LoginKeys.Modal.Reactivate.title)}
                </Heading>
                <Heading variant="H4">
                  {t(LoginKeys.Modal.Reactivate.description)}
                </Heading>

                <Buttons>
                  <YellowButton
                    onClick={(e) => {
                      e.preventDefault();
                      reactivate();
                    }}
                  >
                    {t(GeneralKeys.reactivate)}
                  </YellowButton>
                  <TextButton
                    onClick={() => {
                      utils.signOut();
                      onClose();
                    }}
                  >
                    {t(GeneralKeys.cancel)}
                  </TextButton>
                </Buttons>
              </Content>
            </TopBorderedCard>
          </ModalBody>
        )}
        {isMobile && (
          <>
            <MobileContainer className="md-hidden">
              {loading()}
              <MobileContent>
                <Heading variant="BH4">
                  {t(LoginKeys.Modal.Reactivate.title)}
                </Heading>
                <LazyImage
                  src="img/misc/deactivate-image.png"
                  lazySrc="img/misc/deactivate-image_small.png"
                  width="120px"
                  circle
                  className="shadow-form"
                />
                <Heading variant="H8" color="var(--color-indigo-200)">
                  {t(LoginKeys.Modal.Reactivate.description)}
                </Heading>
                <Buttons>
                  <GrayButton
                    variant="outlined"
                    radius="999px"
                    padding="0.625rem 1rem"
                    className="bh5 shadow-mobile-text-box"
                    minWidth="136px"
                    onClick={() => {
                      utils.signOut();
                      onClose();
                    }}
                  >
                    {t(GeneralKeys.cancel)}
                  </GrayButton>

                  <IndigoButton
                    onClick={(e) => {
                      e.preventDefault();
                      reactivate();
                    }}
                    radius="999px"
                    padding="0.625rem 1rem"
                    className="bh5 shadow-none"
                    minWidth="136px"
                  >
                    {t(GeneralKeys.reactivate)}
                  </IndigoButton>
                </Buttons>
              </MobileContent>
            </MobileContainer>
          </>
        )}
      </BannerModal>
    </>
  );
};

export default withAuthHOC(ReactivateModal);

const ModalBody = styled.div`
  position: relative;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.875rem;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media ${device.md} {
    gap: 3.125rem;
  }
`;

const TextButton = styled.button.attrs({
  className: 'sbh4',
})`
  background: none;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
`;
const MobileContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  padding-top: 2.5rem;
`;
const MobileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  gap: 1.25rem;
  text-align: center;
`;
