import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/animated.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/style.scss';
import 'styles/main.scss';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './translations/i18n';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'AW-11040496226',
};

TagManager.initialize(tagManagerArgs);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
