export const JapanFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.128418"
      y="0.064209"
      width="33"
      height="33"
      rx="16.5"
      fill="#F5F5F5"
    />
    <path
      d="M16.6262 24.264C20.8788 24.264 24.3262 20.8166 24.3262 16.564C24.3262 12.3114 20.8788 8.86401 16.6262 8.86401C12.3736 8.86401 8.92621 12.3114 8.92621 16.564C8.92621 20.8166 12.3736 24.264 16.6262 24.264Z"
      fill="#F93939"
    />
  </svg>
);
