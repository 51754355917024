import EvoMissionsKeys from '../translationKeys/EvoMissionsPage';

export const EvoMissionsTranslations = {
  [EvoMissionsKeys.claim_evos]: 'เคลม EVOs',
  [EvoMissionsKeys.my_evos]: 'EVO ของฉัน',
  [EvoMissionsKeys.special_missions]: 'ภารกิจพิเศษ',
  [EvoMissionsKeys.creator_missions]: 'ภารกิจของครีเอเตอร์',
  [EvoMissionsKeys.general_missions]: 'ภารกิจทั่วไป',
  [EvoMissionsKeys.successfully_claimed]: 'เคลมสำเร็จ!',
  [EvoMissionsKeys.go_back_home]: 'ไปที่โปรไฟล์ของฉัน',

  // Token Details
  [EvoMissionsKeys.Token_Details._1k]: '1k',
  [EvoMissionsKeys.Token_Details._10k]: '10k',
  [EvoMissionsKeys.Token_Details.no_data]: 'ไม่มีข้อมูลที่จะแสดง',
  [EvoMissionsKeys.Token_Details.results_found]:
    'พบผลลัพธ์ทั้งหมด {{number}} รายการ',
  [EvoMissionsKeys.Token_Details.result_found]:
    'พบผลลัพธ์ทั้งหมด {{number}} รายการ',

  // Side Popup
  [EvoMissionsKeys.Side_Popup.text]: 'EVO คืออะไร?',

  // Transaction
  [EvoMissionsKeys.Transaction.daily_reward]: 'รางวัลประจำวัน',
  [EvoMissionsKeys.Transaction.price_unlock_received]:
    'ส่งโดยลูกค้า - ปลดล็อกราคา',
  [EvoMissionsKeys.Transaction.price_unlock_spent]: 'ปลดล็อกราคา',
  [EvoMissionsKeys.Transaction.referral_reward]: 'รางวัลการแนะนำ',
  [EvoMissionsKeys.Transaction.creator_registration_reward]:
    'รางวัลการลงทะเบียนของครีเอเตอร์',

  [EvoMissionsKeys.Transaction.complete_1_special_completed]:
    'เสร็จสิ้นภารกิจพิเศษ 1',
  [EvoMissionsKeys.Transaction.complete_777_special_completed]:
    'เสร็จสิ้นภารกิจพิเศษ 777',
  [EvoMissionsKeys.Transaction.complete_77_special_completed]:
    'เสร็จสิ้นภารกิจพิเศษ 77',
  [EvoMissionsKeys.Transaction.complete_7_special_completed]:
    'เสร็จสิ้นภารกิจพิเศษ 7',
  [EvoMissionsKeys.Transaction.create_premium_package_completed]:
    'สร้างแพ็กเกจพรีเมี่ยม',
  [EvoMissionsKeys.Transaction.first_990_creator_claimed_completed]:
    'VIP 990 คนแรก',
  [EvoMissionsKeys.Transaction.refer_1000_early_vips_completed]:
    'การแนะนำ VIP 1000',
  [EvoMissionsKeys.Transaction.refer_100_early_vips_completed]:
    'การแนะนำ VIP 100',
  [EvoMissionsKeys.Transaction.refer_10_early_vips_completed]:
    'การแนะนำ VIP 10',
  [EvoMissionsKeys.Transaction.referral_ranking_completed]:
    'การแนะนำ VIP อันดับ',
  [EvoMissionsKeys.Transaction.special_daily_reward_completed]:
    'รางวัลประจำวันพิเศษ',
  [EvoMissionsKeys.Transaction.tv_easter_egg_completed]:
    'ค้นหาไข่อีสเตอร์ในแพลตฟอร์ม',
  [EvoMissionsKeys.Transaction.update_creator_profile_completed]:
    'อัปเดตโปรไฟล์ของครีเอเตอร์',
  [EvoMissionsKeys.Transaction.update_profile_completed]: 'อัปเดตโปรไฟล์',
  [EvoMissionsKeys.Transaction.visit_main_tabs_completed]:
    'เยี่ยมชมแท็บหลักทั้งหมด',

  // Special Mission
  // Early 990 VIPs
  [EvoMissionsKeys.Special.early_990_vips.title]: 'VIP 990 คนแรก',
  [EvoMissionsKeys.Special.early_990_vips.description]:
    'คุณคือผู้มีอิทธิพล VIP กลุ่มแรกของเรา! ลงทะเบียนเป็นครีเอเตอร์ตอนนี้และปลดล็อคพาสปรีเมี่ยมของเรา จองที่ของคุณ!',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d1]:
    'รับคำสั่งงานโดยไม่เสียค่าบริการจากแพลตฟอร์ม',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d2]: 'ด้วยพาสปรีเมียมนี้!',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d3]: '',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    ' *ค่าบริการจากฝ่ายที่สามอาจยังใช้ในบางกรณี เช่น PayPal และบัตรเครดิต.',

  // General Missions
  [EvoMissionsKeys.complete_1_special.title]: 'เสร็จสิ้นภารกิจพิเศษ 1',
  [EvoMissionsKeys.complete_1_special.description]:
    'รับโบนัสเล็กน้อยสำหรับการเสร็จสิ้นภารกิจพิเศษของคุณ',
  [EvoMissionsKeys.complete_1_special.extra_reward]: '',
  [EvoMissionsKeys.complete_1_special.reward_description]: '',

  [EvoMissionsKeys.complete_777_special.title]: 'เสร็จสิ้นภารกิจพิเศษ 777',
  [EvoMissionsKeys.complete_777_special.description]:
    'รับโบนัสเล็กน้อยสำหรับการเสร็จสิ้นภารกิจพิเศษของคุณ',
  [EvoMissionsKeys.complete_777_special.extra_reward]: '',
  [EvoMissionsKeys.complete_777_special.reward_description]: '',

  [EvoMissionsKeys.complete_77_special.title]: 'เสร็จสิ้นภารกิจพิเศษ 77',
  [EvoMissionsKeys.complete_77_special.description]:
    'รับโบนัสเล็กน้อยสำหรับการเสร็จสิ้นภารกิจพิเศษของคุณ',
  [EvoMissionsKeys.complete_77_special.extra_reward]: '',
  [EvoMissionsKeys.complete_77_special.reward_description]: '',

  // Special Missions
  [EvoMissionsKeys.complete_7_special.title]: 'เสร็จสิ้นภารกิจพิเศษ 7',
  [EvoMissionsKeys.complete_7_special.description]:
    'รับโบนัสเล็กน้อยสำหรับการเสร็จสิ้นภารกิจพิเศษของคุณ',
  [EvoMissionsKeys.complete_7_special.extra_reward]: '',
  [EvoMissionsKeys.complete_7_special.reward_description]: '',

  [EvoMissionsKeys.tv_easter_egg.title]: 'ค้นหาไข่อีสเตอร์ในแพลตฟอร์ม',
  [EvoMissionsKeys.tv_easter_egg.description]:
    'ค้นหาไข่พระอาทิตย์ในหน้าหลัก มันอยู่ที่ไหน',
  [EvoMissionsKeys.tv_easter_egg.extra_reward]: '',
  [EvoMissionsKeys.tv_easter_egg.reward_description]: '',

  [EvoMissionsKeys.update_profile.title]: 'อัปเดตโปรไฟล์',
  [EvoMissionsKeys.update_profile.description]:
    'มาร่วมเดินทางกับเรา! อัปเดตโปรไฟล์ของคุณและปลดล็อครางวัลนี้',
  [EvoMissionsKeys.update_profile.extra_reward]: '',
  [EvoMissionsKeys.update_profile.reward_description]: '',

  [EvoMissionsKeys.visit_main_tabs.title]: 'เยี่ยมชมแท็บหลักทั้งหมด',
  [EvoMissionsKeys.visit_main_tabs.description]:
    'เยี่ยมชมแท็บทั้งสี่บนแถบเมนูด้านบนของเราเพื่อปลดล็อครางวัลนี้ ค้นพบสิ่งใหม่ๆ กับเรา!',
  [EvoMissionsKeys.visit_main_tabs.extra_reward]: '',
  [EvoMissionsKeys.visit_main_tabs.reward_description]: '',

  // Creator
  [EvoMissionsKeys.create_premium_package.title]: 'สร้างแพ็กเกจพรีเมียม',
  [EvoMissionsKeys.create_premium_package.description]:
    'สร้างแพ็กเกจพรีเมียมบนโปรไฟล์ของคุณ ใช้เทมเพลตของเราหรือคุณลักษณะ ChatGPT เพื่อแสดงผลบริการที่ดีที่สุดของคุณ!',
  [EvoMissionsKeys.create_premium_package.extra_reward]: '',
  [EvoMissionsKeys.create_premium_package.reward_description]: '',

  [EvoMissionsKeys.update_creator_profile.title]: 'อัปเดตโปรไฟล์ครีเอเตอร์',
  [EvoMissionsKeys.update_creator_profile.description]:
    'พร้อมเป็นดาวเทียมระดับโลกหรือยัง? อัปเดตโปรไฟล์ของคุณและให้โลกรู้จักกับช่องสุดเจ๋งของคุณ!',
  [EvoMissionsKeys.update_creator_profile.extra_reward]: '',
  [EvoMissionsKeys.update_creator_profile.reward_description]: '',

  // Special
  [EvoMissionsKeys.first_990_creator_claimed.title]:
    'ผู้ส่งท้าย VIP แรก 990 คน!',
  [EvoMissionsKeys.first_990_creator_claimed.description]:
    'คุณคือผู้ส่งท้าย VIP แรกของเรา! ลงทะเบียนเป็นครีเอเตอร์ตอนนี้และอัปเดตโปรไฟล์ของคุณด้วยแพ็กเกจอย่างน้อยหนึ่งแพ็กเกจ มาเข้าสู่ฐานะของคุณ!',
  [EvoMissionsKeys.first_990_creator_claimed.extra_reward]:
    'บัตรผ่านรายวันพรีเมียม',
  [EvoMissionsKeys.first_990_creator_claimed.reward_description]:
    'ผู้สร้างที่มีบัตรผ่านรายวันพรีเมียม',

  [EvoMissionsKeys.refer_1000_early_vips.title]: 'การแนะนำ VIP ไปแรก',
  [EvoMissionsKeys.refer_1000_early_vips.description]:
    'แนะนำ 1000 คนหรือมากกว่าเพื่อเรียกรับรางวัลนี้!',
  [EvoMissionsKeys.refer_1000_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_1000_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_100_early_vips.title]: 'การแนะนำ VIP ไปแรก',
  [EvoMissionsKeys.refer_100_early_vips.description]:
    'แนะนำ 100 คนหรือมากกว่าเพื่อเรียกรับรางวัลนี้!',
  [EvoMissionsKeys.refer_100_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_100_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_10_early_vips.title]: 'การแนะนำ VIP ไปแรก',
  [EvoMissionsKeys.refer_10_early_vips.description]:
    'แนะนำ 10 คนหรือมากกว่าเพื่อเรียกรับรางวัลนี้!',
  [EvoMissionsKeys.refer_10_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_10_early_vips.reward_description]: '',

  [EvoMissionsKeys.referral_ranking.title]: 'อันดับการแนะนำ VIP',
  [EvoMissionsKeys.referral_ranking.description]:
    'สำหรับแต่ละเปอร์เซ็นไทล์ในการจัดอันดับการแนะนำ คุณจะได้รับคะแนน EVO เพิ่มเติมที่สิ้นเดือน',
  [EvoMissionsKeys.referral_ranking.extra_reward]: '',
  [EvoMissionsKeys.referral_ranking.reward_description]: '',

  [EvoMissionsKeys.special_daily_reward.title]: 'รางวัลพิเศษรายวัน',
  [EvoMissionsKeys.special_daily_reward.description]:
    'มาเรียกรับรางวัลรายวันของคุณทุกวันเป็นเวลาหนึ่งสัปดาห์',
  [EvoMissionsKeys.special_daily_reward.extra_reward]: '',
  [EvoMissionsKeys.special_daily_reward.reward_description]: '',

  [EvoMissionsKeys.Modal.already_unlock]: 'คุณได้ปลดล็อคแล้ว',
  [EvoMissionsKeys.Modal.easter_egg]: 'ไข่พระอาทิตย์นี้!',
  [EvoMissionsKeys.Modal.unlock_premium_pass]:
    'คุณได้ปลดล็อคบัตรผ่านรายวันพรีเมียม',
  [EvoMissionsKeys.Modal.visit_profile]: 'เยี่ยมชมโปรไฟล์ของฉัน',
  [EvoMissionsKeys.Modal.not_now]: 'ไม่ตอนนี้',
};
