export const GlobeIcon = ({ size = 1 }) => (
  <svg
    width={20 * size}
    height={20 * size}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.4087 6.63017C19.4087 6.63017 19.4087 6.63017 19.4087 6.58002C18.7027 4.65062 17.4216 2.98482 15.7389 1.80797C14.0561 0.631113 12.0528 0 10 0C7.94718 0 5.94389 0.631113 4.26112 1.80797C2.57836 2.98482 1.29733 4.65062 0.59133 6.58002C0.59133 6.58002 0.59133 6.58002 0.59133 6.63017C-0.19711 8.80742 -0.19711 11.1926 0.59133 13.3698C0.59133 13.3698 0.59133 13.3698 0.59133 13.42C1.29733 15.3494 2.57836 17.0152 4.26112 18.192C5.94389 19.3689 7.94718 20 10 20C12.0528 20 14.0561 19.3689 15.7389 18.192C17.4216 17.0152 18.7027 15.3494 19.4087 13.42C19.4087 13.42 19.4087 13.42 19.4087 13.3698C20.1971 11.1926 20.1971 8.80742 19.4087 6.63017ZM2.22457 12.0059C1.87711 10.6912 1.87711 9.30877 2.22457 7.99415H4.08827C3.92798 9.32657 3.92798 10.6734 4.08827 12.0059H2.22457ZM3.0462 14.0117H4.44898C4.68417 14.9062 5.02033 15.7709 5.45097 16.5892C4.46832 15.9192 3.64689 15.0388 3.0462 14.0117ZM4.44898 5.9883H3.0462C3.63825 4.9644 4.44918 4.08421 5.42091 3.41078C5.00063 4.23041 4.67457 5.09511 4.44898 5.9883ZM8.97797 17.7225C7.74729 16.8186 6.88296 15.5013 6.54314 14.0117H8.97797V17.7225ZM8.97797 12.0059H6.11229C5.9253 10.6752 5.9253 9.32483 6.11229 7.99415H8.97797V12.0059ZM8.97797 5.9883H6.54314C6.88296 4.49872 7.74729 3.18143 8.97797 2.27747V5.9883ZM16.9137 5.9883H15.5109C15.2758 5.09385 14.9396 4.2291 14.5089 3.41078C15.4916 4.0808 16.313 4.96124 16.9137 5.9883ZM10.9819 2.27747C12.2126 3.18143 13.077 4.49872 13.4168 5.9883H10.9819V2.27747ZM10.9819 17.7225V14.0117H13.4168C13.077 15.5013 12.2126 16.8186 10.9819 17.7225ZM13.8476 12.0059H10.9819V7.99415H13.8476C14.0346 9.32483 14.0346 10.6752 13.8476 12.0059ZM14.539 16.5892C14.9696 15.7709 15.3058 14.9062 15.541 14.0117H16.9438C16.3431 15.0388 15.5217 15.9192 14.539 16.5892ZM17.7353 12.0059H15.8717C15.9531 11.3404 15.9933 10.6705 15.9919 10C15.993 9.32955 15.9528 8.65966 15.8717 7.99415H17.7353C18.0828 9.30877 18.0828 10.6912 17.7353 12.0059Z" />
  </svg>
);
