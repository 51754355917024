import { MusicIcon } from 'assets/icons';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import Viewer from './Viewer';
import { useCallback } from 'react';
import PlayerControls from './PlayerControls';

const AudioPlayer = ({ name, type, size, url, onClose }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  const togglePlay = useCallback(() => {
    if (audioRef.current)
      if (!audioRef.current?.paused) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        audioRef.current.play();
        setIsPlaying(true);
      }
  }, []);

  const handleSeek = useCallback((e) => {
    if (!audioRef.current) return;

    const audio = audioRef.current;
    const seekTo = e.target.value / 100;

    audio.currentTime = seekTo * audio.duration;
    setCurrentTime(seekTo * 100);
  }, []);

  useEffect(() => {
    const audio = audioRef.current;

    if (!audio) return;

    const handleTimeUpdate = () => {
      setCurrentTime((audio.currentTime / audio.duration) * 100);
    };

    const handleDurationChange = () => {
      setDuration(audio.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('ended', handleEnded);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleEnded);
    };
  }, []);

  return (
    <StyledViewer
      name={name}
      size={size}
      url={url}
      onClose={onClose}
      controls={
        <PlayerControls
          togglePlay={togglePlay}
          isPlaying={isPlaying}
          handleSeek={handleSeek}
          currentTime={currentTime}
          duration={duration}
        />
      }
    >
      <Icon
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          togglePlay();
        }}
      />

      <audio ref={audioRef} autoPlay>
        <source src={url} type={type} />
      </audio>
    </StyledViewer>
  );
};

export default AudioPlayer;

const StyledViewer = styled(Viewer)`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.div.attrs({
  children: <MusicIcon size={2.4} />,
})`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0.5;
`;
