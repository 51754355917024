const size1 = () => (
  <svg
    width="34"
    height="24"
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.2499 3.90411C33.0549 3.19061 32.6744 2.54014 32.1464 2.01749C31.6184 1.49484 30.9613 1.11824 30.2406 0.925195C27.6019 0.212891 16.9828 0.212891 16.9828 0.212891C16.9828 0.212891 6.3634 0.234507 3.72508 0.946811C3.00437 1.13988 2.34732 1.5165 1.81938 2.03915C1.29143 2.5618 0.910996 3.21225 0.715966 3.92572C-0.0821651 8.56702 -0.39181 15.6388 0.737802 20.0942C0.932832 20.8077 1.31326 21.4581 1.84121 21.9808C2.36916 22.5034 3.0262 22.88 3.74691 23.0731C6.38544 23.7854 17.0047 23.7854 17.0047 23.7854C17.0047 23.7854 27.6239 23.7854 30.2624 23.0731C30.9831 22.88 31.6402 22.5034 32.1681 21.9808C32.6961 21.4581 33.0765 20.8077 33.2715 20.0942C34.1131 15.4465 34.3727 8.37906 33.2497 3.90431L33.2499 3.90411Z"
      fill="#FF0000"
    />
    <path
      d="M13.6016 17.0557L22.411 12.0045L13.6016 6.95312L13.6016 17.0557Z"
      fill="white"
    />
  </svg>
);

export default size1;
