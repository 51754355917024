export const VisaIcon = ({ size = 1 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size * 40}
      height={size * 14}
      viewBox="0 0 40 14"
      fill="none"
    >
      <path
        d="M19.8054 0.769191L17.1331 13.2629H13.9007L16.5733 0.769191H19.8054ZM33.4037 8.83652L35.1049 4.14489L36.0839 8.83652H33.4037ZM37.0112 13.2629H40L37.3889 0.769191H34.632C34.0107 0.769191 33.4869 1.12944 33.2551 1.68498L28.4052 13.2629H31.7998L32.4736 11.397H36.6198L37.0112 13.2629ZM28.5736 9.18412C28.5876 5.88683 24.0154 5.70417 24.046 4.23087C24.0559 3.78323 24.483 3.30637 25.4165 3.18442C25.8793 3.12484 27.1565 3.07651 28.6042 3.74361L29.1704 1.09319C28.3929 0.811903 27.3922 0.541016 26.1471 0.541016C22.9518 0.541016 20.7041 2.23828 20.6861 4.67064C20.6656 6.46935 22.2917 7.47225 23.5144 8.07135C24.775 8.68365 25.1973 9.07621 25.1911 9.62333C25.1824 10.4616 24.186 10.8328 23.2581 10.8468C21.6322 10.8721 20.6897 10.4073 19.9386 10.0572L19.3519 12.7961C20.1083 13.1423 21.5021 13.4436 22.9453 13.459C26.3424 13.459 28.5634 11.7817 28.5736 9.18412ZM15.1868 0.769191L9.94977 13.2629H6.53361L3.95625 3.29204C3.80001 2.67889 3.66372 2.45352 3.18855 2.19444C2.41129 1.77237 1.12823 1.37756 0 1.13197L0.0764338 0.769191H5.57623C6.27678 0.769191 6.90707 1.23537 7.06752 2.04242L8.42898 9.27264L11.7912 0.769191H15.1868Z"
        fill="#1434CB"
      />
    </svg>
  );
};
