import GuideKeys from '../translationKeys/GuidePageKeys';

export const GuideTranslations = {
  [GuideKeys.click_to_join]: '参加するにはクリック',

  // Portfolio Banner
  [GuideKeys.Portfolio_Banner.description]: `
    自分だけのオリジナルURLで素敵なページを作ってみてください！`,
  [GuideKeys.Portfolio_Banner.view_sample]: 'サンプルを見る',

  // Creator Invitation
  [GuideKeys.Creator_Invitation.hi_ginseng_mega]: 'こんにちは、私はMEGAです！',
  [GuideKeys.Creator_Invitation.nice_to_meet_you]: 'はじめまして〜！',
  [GuideKeys.Creator_Invitation.we_help_you_receive]:
    'グローバル企業からコラボなどの',
  [GuideKeys.Creator_Invitation.proposal_golbal_brands]:
    '依頼を受けることができます！',
  [GuideKeys.Creator_Invitation.let_companies_directly]:
    'お客様はこちらから、あなたの',
  [GuideKeys.Creator_Invitation.order_your_package]:
    'パッケージを注文できます。',
  [GuideKeys.Creator_Invitation.click_to_join]: '登録するには、',
  [GuideKeys.Creator_Invitation.anytime]: 'こちらをクリックしてください。',
  [GuideKeys.Creator_Invitation.package_featured]:
    '世界128ヵ国の企業にあなたのチャンネルを',
  [GuideKeys.Creator_Invitation.showcase_channel]:
    '紹介しましょう！無料で簡単にプロフィールを作り、',
  [GuideKeys.Creator_Invitation.showcase_channel_2]:
    'グローバルなブランドとつながりましょう！',
  [GuideKeys.Creator_Invitation.order_package]: 'パッケージを注文',

  [GuideKeys.Creator_Invitation.Mobile.we_help_you_receive]: '私たちはあなたが',
  [GuideKeys.Creator_Invitation.Mobile.proposal_golbal_brands]:
    'グローバルブランドから直接依頼を受け取るお手伝いをします！',
  [GuideKeys.Creator_Invitation.Mobile.let_companies_directly]: '企業から直接',
  [GuideKeys.Creator_Invitation.Mobile.order_your_package]:
    '依頼を受け取りましょう！',
  [GuideKeys.Creator_Invitation.Mobile.click_to_join]: '登録するには、',
  [GuideKeys.Creator_Invitation.Mobile.anytime]:
    'こちらをクリックしてください。',
};
