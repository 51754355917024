export const YoutubeLightIcon = ({ size = 1 }) => (
  <svg
    width={size * 22}
    height={size * 18}
    viewBox="0 0 22 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.74 8.13227L9.35 4.78373C9.18278 4.69072 8.99309 4.64176 8.8 4.64176C8.60691 4.64176 8.41722 4.69072 8.25 4.78373C8.08691 4.87435 7.9507 5.00378 7.8544 5.15963C7.7581 5.31549 7.70494 5.49254 7.7 5.67385V12.3709C7.70143 12.5598 7.75521 12.7448 7.85577 12.9069C7.95634 13.069 8.10004 13.2022 8.272 13.2928C8.43197 13.3836 8.61435 13.4312 8.8 13.4306C8.99485 13.4177 9.18356 13.3595 9.35 13.2611L14.74 9.91251C14.8944 9.81644 15.0213 9.68465 15.1092 9.52916C15.1971 9.37367 15.2432 9.19944 15.2432 9.02239C15.2432 8.84534 15.1971 8.67111 15.1092 8.51562C15.0213 8.36013 14.8944 8.22834 14.74 8.13227ZM9.9 10.4211V7.62363L12.1 9.02239L9.9 10.4211ZM19.118 1.0855L17.413 0.873564C13.1901 0.375479 8.9199 0.375479 4.697 0.873564L2.992 1.0855C2.17218 1.17145 1.41394 1.54525 0.862043 2.13555C0.31015 2.72584 0.00327096 3.49127 0 4.28569V13.7591C0.00699232 14.5417 0.308557 15.2953 0.848701 15.88C1.38884 16.4646 2.13087 16.8406 2.937 16.9381L4.642 17.1394C6.75153 17.3864 8.87496 17.5067 11 17.4997C13.1255 17.4997 15.2489 17.3723 17.358 17.1182L19.063 16.9169C19.8691 16.8194 20.6112 16.4434 21.1513 15.8588C21.6914 15.2741 21.993 14.5205 22 13.7379V4.28569C21.9983 3.49939 21.6992 2.74065 21.1585 2.15156C20.6179 1.56248 19.8729 1.18346 19.063 1.0855H19.118ZM19.855 13.8015C19.8559 14.0684 19.7522 14.3259 19.5645 14.5223C19.3769 14.7188 19.1192 14.8398 18.843 14.8611L17.138 15.0625C13.0985 15.55 9.01155 15.55 4.972 15.0625L3.267 14.8611C2.99077 14.8398 2.73309 14.7188 2.54547 14.5223C2.35785 14.3259 2.25411 14.0684 2.255 13.8015V4.28569C2.26811 4.02284 2.37661 3.77276 2.56156 3.5791C2.74651 3.38543 2.99618 3.26047 3.267 3.22602L4.917 2.9823C8.957 2.5019 13.043 2.5019 17.083 2.9823L18.788 3.18363C19.0588 3.21809 19.3085 3.34305 19.4934 3.53671C19.6784 3.73037 19.7869 3.98045 19.8 4.2433L19.855 13.8015Z" />
  </svg>
);
