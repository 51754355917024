import FAQKeys from '../translationKeys/FAQPageKeys';

export const FAQTranslations = {
  // General
  [FAQKeys.General.title]: 'General',

  [FAQKeys.General.Q1.title]: '¿Cómo empiezo con MegaEvolution?',
  [FAQKeys.General.Q1.description]:
    "Para comenzar tu viaje en el marketing de influencers con MegaEvolution, simplemente visita nuestro sitio web en megaevolution.io desde tu computadora o navegador móvil. Después de hacer clic en el botón 'Iniciar sesión', puedes registrarte usando tu correo electrónico personal, Google, Facebook o billetera (con MetaMask o Coinbase Wallet). Acepta nuestros términos de servicio y verifica tu correo electrónico si es necesario. Una vez registrado, puedes conectarte fácilmente con influencers y aprovechar su alcance para promocionar tus productos o servicios a una audiencia más amplia. Nuestra plataforma ofrece funciones avanzadas, como analíticas de canales y audiencias de creadores, y un sistema de pedidos y pagos todo en uno, lo que hace que sea sencillo para las empresas lanzar una campaña de influencer efectiva.",

  [FAQKeys.General.Q2.title]: '¿Qué es MegaEvolution?',
  [FAQKeys.General.Q2.description]:
    'MegaEvolution es una plataforma de marketing de influencers que conecta a clientes con creadores globales para promocionar sus productos y servicios. Los clientes pueden encontrar y ordenar paquetes de marketing fácilmente, mientras que los creadores pueden promocionarse a sí mismos y obtener más oportunidades y ganancias a través de sus servicios. MegaEvolution agiliza todo el proceso, desde el pedido hasta la gestión de anuncios, lo que brinda una experiencia fácil y conveniente para todos los usuarios.',

  [FAQKeys.General.Q3.title]: '¿Qué ofrece MegaEvolution a los creadores?',
  [FAQKeys.General.Q3.description]:
    'MegaEvolution permite a los creadores conectarse con clientes de marcas de todo el mundo. Al promocionar sus servicios a través de MegaEvolution, los creadores pueden recibir fácilmente pedidos de clientes, y brindamos soporte completo durante todo el proceso de pedido y pago para ayudar a los creadores a maximizar sus oportunidades y ganancias.',

  [FAQKeys.General.Q4.title]: '¿Qué ofrece MegaEvolution a los clientes?',
  [FAQKeys.General.Q4.description]:
    'MegaEvolution conecta a los clientes con una amplia gama de creadores, proporcionando acceso a información como analíticas de canales y precios de marketing. Los clientes pueden ordenar paquetes de marketing de los creadores con solo un clic, y brindamos soporte completo durante todo el proceso de pedido y pago para garantizar una experiencia segura y conveniente.',

  [FAQKeys.General.Q5.title]: '¿Qué es el token EVO y cómo funciona?',
  [FAQKeys.General.Q5.description]:
    'El token EVO permite acceder a los precios de los paquetes de creadores y pronto se podrá utilizar para eventos, votaciones y gobernanza. Estamos planeando listar EVO en varios intercambios, lo que permitirá intercambiarlo por otras criptomonedas o dinero en efectivo.',

  // Package System
  [FAQKeys.Package_System.title]: 'Sistema de Paquetes',

  [FAQKeys.Package_System.Q1.title]: '¿Qué son los paquetes en MegaEvolution?',
  [FAQKeys.Package_System.Q1.description]:
    'Los paquetes en MegaEvolution son un sistema que permite a los creadores mostrar sus servicios, incluido el contenido que proporcionarán, su duración, precio y una descripción detallada de cómo se entregará a los clientes. Los usuarios pueden comprar fácilmente estos paquetes con unos pocos clics y gestionar todos los pedidos a través de su panel de control. Además, los usuarios recibirán notificaciones y análisis sobre el estado de sus pedidos de paquetes.',

  [FAQKeys.Package_System.Q2.title]:
    '¿Cuáles son los tipos de precios para los paquetes?',
  [FAQKeys.Package_System.Q2.description]:
    'Hay tres tipos de opciones de precios: "Precio Abierto" / "Precio Desbloqueable" / "Contactar al Creador". El creador puede configurar diferentes opciones de precios para cada plan de paquete. Por ejemplo, el plan estándar puede configurarse con un Precio Abierto y el plan premium como un Precio Desbloqueable.',

  [FAQKeys.Package_System.Q3.title]:
    '¿Cómo se realiza el sistema de desbloqueo de precios? ¿Cuánto tiempo se muestran los precios desbloqueados?',
  [FAQKeys.Package_System.Q3.description]:
    'Todos los usuarios pueden desbloquear el precio del paquete un cierto número de veces de forma gratuita cada mes. Para desbloquear los precios de un paquete del creador, simplemente haz clic en el precio bloqueado. Una vez desbloqueado, podrás ver todos los precios para ese creador específico. Los precios desbloqueados se muestran durante 3 días.',

  // Creators
  [FAQKeys.Creators.title]: 'Creadores',

  [FAQKeys.Creators.Q1.title]:
    'Quiero saber sobre la Tarifa de Servicio para Creadores.',
  [FAQKeys.Creators.Q1.description]:
    'Actualmente, no cobramos a los Creadores por cada pedido de anuncio.',

  [FAQKeys.Creators.Q2.title]: '¿Cuánto tiempo tarda en retirar mi saldo?',
  [FAQKeys.Creators.Q2.description]:
    'Las transferencias generalmente tardan de 1 a 3 días hábiles a través de PayPal.',

  [FAQKeys.Creators.Q3.title]:
    'He desvinculado todas mis cuentas de redes sociales después de registrarme como Creador. ¿Se eliminará automáticamente mi cuenta?',
  [FAQKeys.Creators.Q3.description]:
    'Tu cuenta no se eliminará. Sin embargo, no podrás recibir ningún pedido. Además, no aparecerás en nuestra ventana de búsqueda.',

  [FAQKeys.Creators.Q4.title]:
    '¿Puede el cliente cancelar el pedido después de que lo acepte?',
  [FAQKeys.Creators.Q4.description]:
    'Una vez que un creador acepta un pedido, la cancelación solo es posible si ambas partes están de acuerdo con la cancelación. Sin embargo, si el creador no entrega el contenido en la fecha de vencimiento o si la calidad del contenido es excesivamente insatisfactoria o diferente a la descripción, el pedido se puede cancelar a través del Centro de Resolución. Ten en cuenta que cancelar pedidos puede tener consecuencias negativas en tu cuenta y afectar tu reputación como creador en MegaEvolution.',

  // Orders Help
  [FAQKeys.Orders_Help.title]: 'Ayuda con Pedidos',

  [FAQKeys.Orders_Help.Q1.title]:
    '¿Cómo funcionan las extensiones y cancelaciones?',
  [FAQKeys.Orders_Help.Q1.description]:
    'Puedes extender la fecha de entrega de un pedido o cancelarlo enviando una solicitud a la otra parte. Puedes aceptar o rechazar la solicitud que recibas. Las solicitudes se aceptarán automáticamente si no se responden en varios días (3 días para extensiones, 5 días para cancelaciones).',

  [FAQKeys.Orders_Help.Q2.title]:
    '¿Puedo cancelar el pedido después de que el creador lo acepte?',
  [FAQKeys.Orders_Help.Q2.description]:
    'Normalmente, una vez que el Cliente acepta el pedido, solo se puede cancelar si el Creador está de acuerdo. Sin embargo, se puede cancelar a través del Centro de Resolución si la fecha de entrega no se cumplió o la calidad no fue satisfactoria.',

  [FAQKeys.Orders_Help.Q3.title]: '¿Qué hago si no recibo el contenido?',
  [FAQKeys.Orders_Help.Q3.description]:
    'Los Creadores deben enviar el enlace de cada contenido solicitado. Si no recibes el contenido hasta la fecha de entrega, puedes solicitar una cancelación y obtener un reembolso.',

  // Payment Help
  [FAQKeys.Payment_Help.title]: 'Ayuda con Pagos',

  [FAQKeys.Payment_Help.Q1.title]:
    '¿Cómo puedo consultar mi historial de pagos?',
  [FAQKeys.Payment_Help.Q1
    .description]: `1. Inicia sesión en tu cuenta y haz clic en 'Mi Espacio' en la esquina superior derecha del sitio web. Luego, haz clic en el botón 'Facturación'.
2. Utiliza la pestaña "Historial de Pagos" para ver tus transacciones. También puedes descargar la factura en formato PDF.`,

  [FAQKeys.Payment_Help.Q2.title]: '¿Cómo puedo pagar?',
  [FAQKeys.Payment_Help.Q2
    .description]: `1. Ve a 'Mi Espacio' en la esquina superior derecha de la página. Luego ve a 'Facturación'.
2. En la pestaña "Método de Pago", agrega "Tarjeta de Crédito y Débito" o "Cuenta de PayPal".
3. Luego, puedes utilizar la información agregada para los pagos.`,

  [FAQKeys.Payment_Help.Q3.title]:
    '¿Cómo realiza MegaEvolution un reembolso a los clientes?',
  [FAQKeys.Payment_Help.Q3.description]:
    'Para pedidos de creadores, se otorgan reembolsos completos al cancelar. Si ya hay contenidos publicados, se realizará una deducción proporcional antes del reembolso. No se realizan reembolsos para suscripciones mensuales con facturación automática de la plataforma.',

  [FAQKeys.Payment_Help.Q4.title]:
    '¿Cuál es la tarifa de servicio para usar MegaEvolution y cómo funciona?',
  [FAQKeys.Payment_Help.Q4.description]:
    'MegaEvolution cobra una tarifa de servicio del 5.5% + $3 por cada pedido de paquete. Ofrecemos una de las tarifas de servicio más competitivas de la industria. Esta pequeña tarifa nos ayuda a operar la plataforma y respaldar cada proceso de pedido. Si eres usuario de suscripción, recibirás un descuento en la tarifa de servicio.',

  [FAQKeys.Payment_Help.Q5.title]:
    '¿Qué tipos de planes de suscripción están disponibles?',
  [FAQKeys.Payment_Help.Q5.description]:
    'Ofrecemos 3 tipos de planes de suscripción mensual: Básico (gratuito), Estándar y Premium. El plan Básico brinda acceso a la mayoría de las funciones de la plataforma, mientras que el Estándar y el Premium incluyen características adicionales como más desbloqueos de precios, analíticas y mensajes masivos. Los planes de pago también vienen con un pequeño descuento en las tarifas de servicio para los pedidos.',

  // Account Help
  [FAQKeys.Account_Help.title]: 'Ayuda con la Cuenta',

  [FAQKeys.Account_Help.Q1
    .title]: `¿Qué puedo hacer si no recuerdo los detalles de inicio de sesión (ID o contraseña)?`,
  [FAQKeys.Account_Help.Q1.description]:
    'Para crear una cuenta en MegaEvolution, puedes utilizar tu correo electrónico, Google, Facebook o una billetera (con MetaMask o Coinbase Wallet). Si olvidaste tu contraseña de tu cuenta de correo electrónico, simplemente ve a la página de inicio de sesión y haz clic en "¿Olvidaste tu contraseña?". Luego, ingresa tu dirección de correo electrónico y recibirás un correo electrónico con un enlace para restablecer tu contraseña.',

  [FAQKeys.Account_Help.Q2.title]: '¿Cómo cambio mi contraseña?',
  [FAQKeys.Account_Help.Q2
    .description]: `1. Ve a 'Mi Espacio' en la esquina superior derecha de la página después de iniciar sesión. 
2. Haz clic en 'Configuración' y actualiza tu nueva contraseña en la sección 'Cuenta'.`,

  [FAQKeys.Account_Help.Q3.title]: '¿Cómo desactivo o elimino mi cuenta?',
  [FAQKeys.Account_Help.Q3
    .description]: `1. Ve a 'Mi Espacio' en la esquina superior derecha de la página después de iniciar sesión.
2. Haz clic en Configuración y desplázate hacia abajo hasta la sección Desactivar/Eliminar.
3. Haz clic en el botón 'Desactivar' o 'Eliminar'. (Todos los datos se pueden perder y no se pueden restaurar después de eliminar una cuenta.)`,

  // Safety & Privacy
  [FAQKeys.Safety_Privacy.title]: 'Seguridad y Privacidad',

  [FAQKeys.Safety_Privacy.Q1.title]: '¿Cómo se protege mi información?',
  [FAQKeys.Safety_Privacy.Q1.description]:
    'En MegaEvolution, tomamos la privacidad y seguridad muy en serio. Empleamos medidas estándar de la industria para garantizar que tu información personal esté protegida contra el acceso no autorizado o el uso indebido. Para obtener más información sobre nuestro compromiso de proteger tu privacidad, consulta nuestra Política de Privacidad.',

  [FAQKeys.Safety_Privacy.Q2.title]:
    '¿Qué tipo de datos conserva MegaEvolution?',
  [FAQKeys.Safety_Privacy.Q2.description]:
    'MegaEvolution almacena varios datos importantes para nuestros usuarios, que incluyen registros de pedidos, información de pago, datos de usuarios, perfiles, información de paquetes, información de registro técnico y más.',

  [FAQKeys.Safety_Privacy.Q3.title]: '¿Cómo desactivo o elimino mi cuenta?',
  [FAQKeys.Safety_Privacy.Q3
    .description]: `1. Ve a 'Mi Espacio' en la esquina superior derecha de la página después de iniciar sesión.
2. Haz clic en Configuración y desplázate hacia abajo hasta la sección Desactivar/Eliminar.
3. Haz clic en el botón 'Desactivar' o 'Eliminar'. (Todos los datos se pueden perder y no se pueden restaurar después de eliminar una cuenta.)`,
};
