export const YoutubeFullIcon = ({ size = 1 }) => (
  <svg
    width={size * 108}
    height={size * 24}
    viewBox="0 0 108 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7" clipPath="url(#clip0_72168_7048)">
      <path
        d="M33.7555 3.74812C33.3604 2.27212 32.2004 1.11219 30.7244 0.717082C28.0519 0.000244355 17.3303 0.000244141 17.3303 0.000244141C17.3303 0.000244141 6.60878 0.000244355 3.93616 0.717082C2.46016 1.11219 1.30024 2.27212 0.905131 3.74812C0.188294 6.42073 0.188293 12.0002 0.188293 12.0002C0.188293 12.0002 0.188294 17.5797 0.905131 20.2524C1.30024 21.7284 2.46016 22.8883 3.93616 23.2833C6.60878 24.0002 17.3303 24.0002 17.3303 24.0002C17.3303 24.0002 28.0519 24.0002 30.7244 23.2833C32.2004 22.8883 33.3604 21.7284 33.7555 20.2524C34.4723 17.5797 34.4723 12.0002 34.4723 12.0002C34.4723 12.0002 34.4695 6.42073 33.7555 3.74812Z"
        fill="#FF0000"
      />
      <path
        d="M13.8988 17.1425L22.8057 12.0005L13.8988 6.8584V17.1425Z"
        fill="white"
      />
      <path
        d="M41.7113 15.6043L37.8618 1.70215H41.2202L42.5693 8.0041C42.9136 9.55631 43.1647 10.8799 43.3284 11.9749H43.4271C43.5401 11.1904 43.7941 9.87522 44.1864 8.02669L45.5833 1.70215H48.9417L45.0443 15.6043V22.2732H41.7085V15.6043H41.7113Z"
        fill="#282828"
      />
      <path
        d="M49.952 21.8325C49.2747 21.3753 48.7921 20.664 48.5042 19.6989C48.2192 18.7337 48.0753 17.4525 48.0753 15.8494V13.6678C48.0753 12.0507 48.239 10.7497 48.5664 9.77042C48.8937 8.79113 49.4046 8.07428 50.0988 7.62556C50.7931 7.17683 51.7046 6.95105 52.8336 6.95105C53.9455 6.95105 54.8344 7.17965 55.5061 7.63685C56.175 8.09404 56.666 8.81088 56.9764 9.78171C57.2869 10.7554 57.4422 12.0507 57.4422 13.6678V15.8494C57.4422 17.4525 57.2898 18.7394 56.9878 19.7102C56.6858 20.6838 56.1948 21.3951 55.5174 21.8438C54.8401 22.2926 53.92 22.5183 52.7601 22.5183C51.5635 22.521 50.6294 22.2897 49.952 21.8325ZM53.7507 19.4788C53.937 18.9878 54.033 18.189 54.033 17.0771V12.3951C54.033 11.317 53.9398 10.5268 53.7507 10.0301C53.5616 9.53054 53.2315 9.28219 52.7572 9.28219C52.3002 9.28219 51.9756 9.53054 51.7893 10.0301C51.6002 10.5296 51.5071 11.317 51.5071 12.3951V17.0771C51.5071 18.189 51.5973 18.9905 51.778 19.4788C51.9586 19.9698 52.2831 20.2154 52.7572 20.2154C53.2315 20.2154 53.5616 19.9698 53.7507 19.4788Z"
        fill="#282828"
      />
      <path
        d="M68.3668 22.276H65.7196L65.4261 20.436H65.3528C64.633 21.8245 63.5549 22.5187 62.1157 22.5187C61.1194 22.5187 60.3829 22.1913 59.9087 21.5395C59.4346 20.8846 59.1975 19.8631 59.1975 18.4746V7.24501H62.5814V18.2769C62.5814 18.9487 62.6547 19.4256 62.8015 19.7107C62.9482 19.9957 63.1937 20.1396 63.538 20.1396C63.8315 20.1396 64.1138 20.0493 64.3847 19.8687C64.6557 19.688 64.8532 19.4594 64.9858 19.1829V7.24219H68.3668V22.276Z"
        fill="#282828"
      />
      <path
        d="M77.5589 4.42519H74.2005V22.2755H70.8901V4.42519H67.5316V1.70459H77.5589V4.42519Z"
        fill="#282828"
      />
      <path
        d="M85.7204 22.276H83.0733L82.7797 20.436H82.7064C81.9868 21.8245 80.9087 22.5187 79.4693 22.5187C78.4731 22.5187 77.7365 22.1913 77.2624 21.5395C76.7883 20.8846 76.5511 19.8631 76.5511 18.4746V7.24501H79.935V18.2769C79.935 18.9487 80.0083 19.4256 80.1551 19.7107C80.3019 19.9957 80.5474 20.1396 80.8918 20.1396C81.1852 20.1396 81.4674 20.0493 81.7384 19.8687C82.0093 19.688 82.2069 19.4594 82.3395 19.1829V7.24219H85.7204V22.276Z"
        fill="#282828"
      />
      <path
        d="M96.9191 9.64652C96.713 8.69827 96.3828 8.01247 95.9257 7.58632C95.4685 7.16017 94.8391 6.9485 94.0376 6.9485C93.4168 6.9485 92.8354 7.12348 92.2963 7.47625C91.7573 7.82903 91.3396 8.28905 91.046 8.86195H91.0207V0.942871H87.761V22.273H90.555L90.8993 20.8507H90.9727C91.2352 21.3586 91.6274 21.7565 92.1496 22.0529C92.6717 22.3465 93.2531 22.4932 93.8909 22.4932C95.0339 22.4932 95.8777 21.9655 96.4167 20.9127C96.9558 19.8572 97.2266 18.2119 97.2266 15.9711V13.592C97.2266 11.9127 97.1222 10.5948 96.9191 9.64652ZM93.8174 15.7791C93.8174 16.8741 93.7723 17.7321 93.682 18.353C93.5917 18.9739 93.4421 19.417 93.2276 19.6766C93.016 19.939 92.7281 20.0689 92.3696 20.0689C92.0903 20.0689 91.8335 20.0039 91.5964 19.8713C91.3594 19.7415 91.1675 19.544 91.0207 19.2843V10.7528C91.1335 10.3436 91.3312 10.0106 91.6105 9.74812C91.8871 9.48566 92.1919 9.35583 92.5164 9.35583C92.8608 9.35583 93.126 9.4913 93.3124 9.75941C93.5014 10.0303 93.6312 10.4819 93.7046 11.1197C93.7779 11.7575 93.8147 12.6634 93.8147 13.8403V15.7791H93.8174Z"
        fill="#282828"
      />
      <path
        d="M102.027 16.6455C102.027 17.6107 102.055 18.3332 102.112 18.8158C102.168 19.2983 102.287 19.6484 102.467 19.8713C102.648 20.0914 102.925 20.2014 103.3 20.2014C103.805 20.2014 104.155 20.0039 104.341 19.6116C104.53 19.2194 104.632 18.5647 104.649 17.6503L107.567 17.8223C107.584 17.9522 107.592 18.1328 107.592 18.3614C107.592 19.7499 107.211 20.7885 106.452 21.4743C105.693 22.1601 104.618 22.5044 103.229 22.5044C101.561 22.5044 100.393 21.9822 99.7242 20.9352C99.0525 19.8882 98.7195 18.2711 98.7195 16.081V13.4564C98.7195 11.2015 99.0667 9.55333 99.7609 8.51477C100.455 7.4762 101.643 6.95691 103.328 6.95691C104.488 6.95691 105.38 7.16858 106.001 7.59473C106.622 8.02087 107.059 8.68127 107.313 9.58156C107.567 10.4818 107.694 11.7236 107.694 13.3096V15.8835H102.027V16.6455ZM102.456 9.5618C102.284 9.77346 102.171 10.1206 102.112 10.6032C102.055 11.0858 102.027 11.8167 102.027 12.7989V13.877H104.502V12.7989C104.502 11.8337 104.468 11.1027 104.403 10.6032C104.339 10.1037 104.22 9.75371 104.048 9.54769C103.876 9.3445 103.61 9.24007 103.252 9.24007C102.891 9.24289 102.625 9.35014 102.456 9.5618Z"
        fill="#282828"
      />
    </g>
    <defs>
      <clipPath id="clip0_72168_7048">
        <rect
          width="107.506"
          height="24"
          fill="white"
          transform="translate(0.188538)"
        />
      </clipPath>
    </defs>
  </svg>
);
