import styled from 'styled-components';
import device from 'styles/device';
import {
  ClipboardIcon,
  EnvelopeIcon,
  MegaIcon,
  UserLightIcon,
} from 'assets/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import Tooltip from 'components/Basics/Tooltip';
import useUser from 'hooks/useUser';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';
import RedDot from './RedDot';
import { ChatListProvider } from 'components/components/MessagesPage/ChatsList/ChatListContext';

const BottomNavigationBar = ({ haveMargin }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isLoggedIn, navigateToProfile } = useUser();
  const { openModal } = useAuthFlowManager();

  const openMessages = () => {
    navigate(`/messages`);
  };

  return (
    <BottomNavigationBarWrapper haveMargin={haveMargin}>
      <NavigationButton onClick={() => pathname !== '/' && navigate('/')}>
        <Icon>
          <MegaIcon />
        </Icon>
      </NavigationButton>

      <NavigationButton
        onClick={() => {
          isLoggedIn ? navigateToProfile() : openModal();
        }}
      >
        <Icon>
          <UserLightIcon size={1.07} />
        </Icon>
      </NavigationButton>

      <NavigationButton
        onClick={() => {
          if (!isLoggedIn) {
            openModal(null, (user) => {
              if (user) openMessages();
            });
            return;
          }
          openMessages();
        }}
      >
        <Icon className="position-relative">
          <ChatListProvider counterpartOnly>
            <RedDot />
          </ChatListProvider>
          <EnvelopeIcon size={1.13} />
        </Icon>
      </NavigationButton>

      <NavigationButton>
        <Tooltip content="Coming soon!">
          <Icon>
            <ClipboardIcon size={0.942} />
          </Icon>
        </Tooltip>
      </NavigationButton>
    </BottomNavigationBarWrapper>
  );
};

export default BottomNavigationBar;

const BottomNavigationBarWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 50px;
  background-color: #fff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  align-items: center;

  @media ${device.md} {
    display: none;
  }
`;

const NavigationButton = styled.button`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: auto;
`;

const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
`;
