const link2 = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill="#FF0000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0837 5.56935C14.2633 5.74894 14.3927 5.97245 14.459 6.21762C14.841 7.75529 14.7527 10.1838 14.4664 11.7808C14.4001 12.026 14.2707 12.2495 14.0911 12.4291C13.9115 12.6087 13.688 12.7381 13.4428 12.8044C12.5453 13.0492 8.93291 13.0492 8.93291 13.0492C8.93291 13.0492 5.32055 13.0492 4.42302 12.8044C4.17785 12.7381 3.95434 12.6087 3.77474 12.4291C3.59515 12.2495 3.46574 12.026 3.39939 11.7808C3.01516 10.2498 3.12049 7.81982 3.39198 6.22504C3.45832 5.97988 3.58773 5.75636 3.76733 5.57677C3.94692 5.39717 4.17043 5.26776 4.4156 5.20142C5.31313 4.95663 8.92549 4.94922 8.92549 4.94922C8.92549 4.94922 12.5379 4.94922 13.4354 5.194C13.6805 5.26034 13.9041 5.38976 14.0837 5.56935ZM10.7725 8.99922L7.77577 10.7349V7.2635L10.7725 8.99922Z"
      fill="white"
    />
  </svg>
);

export default link2;
