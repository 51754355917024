const supportedLanguagesMap = ['en', 'ko', 'ja', 'zh', 'es', 'vi', 'th'];

const countryCodeMap = {
  en: 'USD',
  ko: 'KRW',
  ja: 'JPY',
  zh: 'CNY',
  es: 'EUR',
  vi: 'VND',
  th: 'THB',
  // hi: 'INR',
  // fr: 'EUR',
  // pt: 'EUR',
  // it: 'EUR',
  // de: 'EUR',
};

const languageSubdomains = {
  en: 'www.',
  ko: 'kr.',
  ja: 'jp.',
  zh: 'cn.',
  es: 'es.',
  vi: 'vn.',
  th: 'th.',
  // fr: 'fr.',
  // pt: 'pt.',
  // it: 'it.',
  // de: 'de.',
  // hi: 'in.',
};

const countrySubdomains = {
  www: 'en',
  kr: 'ko',
  jp: 'ja',
  cn: 'zh',
  es: 'es',
  vn: 'vi',
  th: 'th',
  // fr: 'fr',
  // pt: 'pt',
  // it: 'it',
  // de: 'de',
  // hi: 'in',
};

const languageMapping = {
  en: 'English',
  ko: '한국어',
  ja: '日本語',
  zh: '中文',
  es: 'Español',
  vi: 'Tiếng Việt',
  th: 'ไทย',
  fr: 'Français',
  pt: 'Português',
  it: 'Italiano',
  de: 'Deutsch',
  hi: 'हिन्दी',
};

const languageMappingEnglish = {
  en: 'English',
  ko: 'Korean',
  ja: 'Japanese',
  zh: 'Chinese',
  es: 'Spanish',
  vi: 'Vietnamese',
  th: 'Thai',
  fr: 'French',
  pt: 'Portuguese',
  it: 'Italian',
  de: 'German',
  hi: 'Hindi',
};

export {
  supportedLanguagesMap,
  countryCodeMap,
  languageSubdomains,
  countrySubdomains,
  languageMapping,
  languageMappingEnglish,
};
