export const FemaleIcon = ({ size = 1 }) => (
  <svg
    width={11 * size}
    height={15 * size}
    viewBox="0 0 11 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.4957 5.254C10.4969 4.24783 10.2088 3.26252 9.66572 2.41548C9.12266 1.56844 8.34755 0.895368 7.43273 0.476458C6.51791 0.0575484 5.50194 -0.0895389 4.50587 0.0527203C3.50981 0.19498 2.57563 0.620587 1.81465 1.27884C1.05367 1.93709 0.49796 2.80023 0.213733 3.76542C-0.0704943 4.73062 -0.0712576 5.75718 0.211534 6.72279C0.494326 7.68841 1.04875 8.55237 1.80875 9.21175C2.56876 9.87113 3.5023 10.2981 4.49815 10.4419V12.0012H3.74846C3.54963 12.0012 3.35894 12.0802 3.21835 12.2208C3.07775 12.3614 2.99877 12.5521 2.99877 12.7509C2.99877 12.9498 3.07775 13.1404 3.21835 13.281C3.35894 13.4216 3.54963 13.5006 3.74846 13.5006H4.49815V14.2503C4.49815 14.4491 4.57714 14.6398 4.71773 14.7804C4.85833 14.921 5.04901 15 5.24784 15C5.44667 15 5.63736 14.921 5.77796 14.7804C5.91855 14.6398 5.99754 14.4491 5.99754 14.2503V13.5006H6.74723C6.94606 13.5006 7.13675 13.4216 7.27734 13.281C7.41793 13.1404 7.49692 12.9498 7.49692 12.7509C7.49692 12.5521 7.41793 12.3614 7.27734 12.2208C7.13675 12.0802 6.94606 12.0012 6.74723 12.0012H5.99754V10.4419C7.24547 10.2617 8.38684 9.63834 9.21283 8.6857C10.0388 7.73305 10.4942 6.51487 10.4957 5.254ZM5.24784 9.00246C4.50647 9.00246 3.78174 8.78262 3.16531 8.37073C2.54888 7.95884 2.06843 7.37341 1.78472 6.68847C1.501 6.00353 1.42677 5.24984 1.57141 4.52271C1.71604 3.79558 2.07305 3.12767 2.59728 2.60344C3.12151 2.0792 3.78942 1.7222 4.51655 1.57756C5.24368 1.43293 5.99738 1.50716 6.68232 1.79087C7.36726 2.07458 7.95269 2.55503 8.36458 3.17147C8.77646 3.7879 8.99631 4.51262 8.99631 5.254C8.99631 5.74625 8.89935 6.23369 8.71097 6.68847C8.52259 7.14326 8.24648 7.55648 7.89841 7.90456C7.55033 8.25264 7.1371 8.52875 6.68232 8.71713C6.22753 8.9055 5.7401 9.00246 5.24784 9.00246Z" />
  </svg>
);
