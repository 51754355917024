export const CheckCircleLightIcon = ({ size = 1 }) => (
  <svg
    width={17 * size}
    height={18 * size}
    viewBox="0 0 17 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.812 6.2715L7.1655 9.9265L5.763 8.524C5.6868 8.43502 5.59303 8.36275 5.48758 8.31173C5.38212 8.26071 5.26726 8.23204 5.1502 8.22752C5.03314 8.223 4.9164 8.24272 4.80733 8.28546C4.69825 8.32819 4.59919 8.39301 4.51635 8.47585C4.43351 8.55868 4.36869 8.65775 4.32596 8.76682C4.28323 8.8759 4.2635 8.99263 4.26802 9.10969C4.27255 9.22676 4.30122 9.34162 4.35224 9.44707C4.40326 9.55253 4.47552 9.6463 4.5645 9.7225L6.562 11.7285C6.64143 11.8073 6.73562 11.8696 6.83918 11.9119C6.94274 11.9542 7.05364 11.9756 7.1655 11.975C7.38849 11.9741 7.60218 11.8855 7.7605 11.7285L12.0105 7.4785C12.0902 7.39948 12.1534 7.30547 12.1966 7.20189C12.2397 7.09831 12.2619 6.98721 12.2619 6.875C12.2619 6.76279 12.2397 6.65169 12.1966 6.54811C12.1534 6.44453 12.0902 6.35052 12.0105 6.2715C11.8512 6.11319 11.6358 6.02433 11.4113 6.02433C11.1867 6.02433 10.9713 6.11319 10.812 6.2715ZM8.5 0.5C6.81886 0.5 5.17547 0.998516 3.77766 1.93251C2.37984 2.8665 1.29037 4.19402 0.647028 5.74719C0.00368291 7.30036 -0.164645 9.00943 0.163329 10.6583C0.491303 12.3071 1.30085 13.8217 2.4896 15.0104C3.67834 16.1992 5.1929 17.0087 6.84174 17.3367C8.49057 17.6646 10.1996 17.4963 11.7528 16.853C13.306 16.2096 14.6335 15.1202 15.5675 13.7223C16.5015 12.3245 17 10.6811 17 9C17 7.88376 16.7801 6.77846 16.353 5.74719C15.9258 4.71592 15.2997 3.77889 14.5104 2.98959C13.7211 2.20029 12.7841 1.57419 11.7528 1.14702C10.7215 0.719859 9.61624 0.5 8.5 0.5ZM8.5 15.8C7.15509 15.8 5.84038 15.4012 4.72213 14.654C3.60387 13.9068 2.7323 12.8448 2.21762 11.6022C1.70295 10.3597 1.56828 8.99246 1.83066 7.67338C2.09304 6.35431 2.74068 5.14267 3.69168 4.19167C4.64267 3.24068 5.85432 2.59304 7.17339 2.33066C8.49246 2.06828 9.85971 2.20294 11.1022 2.71762C12.3448 3.23229 13.4068 4.10387 14.154 5.22212C14.9012 6.34038 15.3 7.65509 15.3 9C15.3 10.8035 14.5836 12.5331 13.3083 13.8083C12.0331 15.0836 10.3035 15.8 8.5 15.8Z" />
  </svg>
);
