import GeneralKeys from '../translationKeys/GeneralKeys';

export const GeneralTranslations = {
  [GeneralKeys.continue]: '続ける',
  [GeneralKeys.submit]: '提出する',
  [GeneralKeys.cancel]: 'キャンセル',
  [GeneralKeys.close]: '閉じる',
  [GeneralKeys.return]: '戻る',
  [GeneralKeys.account]: 'アカウント',
  [GeneralKeys.coinbase]: 'CoinBase',
  [GeneralKeys.password]: 'パスワード',
  [GeneralKeys.update]: 'アップデート',
  [GeneralKeys.coming_soon]: '準備中',
  [GeneralKeys.all]: 'すべて',
  [GeneralKeys.filter]: 'フィルター',
  [GeneralKeys.contents]: 'コンテンツ',
  [GeneralKeys.myspace]: 'マイスペース',
  [GeneralKeys.myproposal]: 'コラボ提案',
  [GeneralKeys.proposals]: 'コラボ提案',
  [GeneralKeys.billing]: '請求',
  [GeneralKeys.settings]: '設定',
  [GeneralKeys.profile]: 'プロフィール',
  [GeneralKeys.my_orders]: 'MY 注文',
  [GeneralKeys.orders]: '注文',
  [GeneralKeys.message]: 'メッセージ',
  [GeneralKeys.support]: 'サポート',
  [GeneralKeys.help_center]: 'ヘルプセンター',
  [GeneralKeys.creator_faq]: 'クリエーターFAQ',
  [GeneralKeys.company_faq]: '企業・ブランドFAQ',
  [GeneralKeys.myCart]: 'マイカート',
  [GeneralKeys.platform]: 'プラットフォーム',
  [GeneralKeys.sort_by]: '並び替え',
  [GeneralKeys.country]: '国',
  [GeneralKeys.categories]: 'カテゴリー',
  [GeneralKeys.price]: '価格',
  [GeneralKeys.subscribers]: '購読者',
  [GeneralKeys.avg_views]: '平均視聴数',
  [GeneralKeys.audience_gender]: '視聴者の性別',
  [GeneralKeys.audience_age]: '視聴者の年齢',
  [GeneralKeys.youtube]: 'YouTube',
  [GeneralKeys.twitter]: 'X (Twitter)',
  [GeneralKeys.instagram]: 'Instagram',
  [GeneralKeys.tiktok]: 'TikTok',
  [GeneralKeys.delivery]: '所要期間',
  [GeneralKeys.type_length]: 'タイプ/長さ',
  [GeneralKeys.day_singular]: `{{number}}日`,
  [GeneralKeys.day_plural]: `{{number}}日`,
  [GeneralKeys.loading]: '読み込み中',
  [GeneralKeys.company]: '所属',
  [GeneralKeys.bio]: '紹介',
  [GeneralKeys.subs]: '登録数',
  [GeneralKeys.followers]: 'フォロワー',
  [GeneralKeys.videos]: '動画',
  [GeneralKeys.x_posts]: 'X 投稿',
  [GeneralKeys.posts_reels]: '投稿とリール',
  [GeneralKeys.tiktok_videos]: 'TikTok 動画',
  [GeneralKeys.since]: '登録日 {{date}}',
  [GeneralKeys.countries]: '国',
  [GeneralKeys.reviews]: 'レビュー',
  [GeneralKeys.demographics]: 'デモグラフィック',
  [GeneralKeys.others]: 'その他',
  [GeneralKeys.check_plan]: 'プランを確認',
  [GeneralKeys.login]: 'ログイン',
  [GeneralKeys.login_upper_case]: 'ログイン',
  [GeneralKeys.total]: '合計',
  [GeneralKeys.likes]: 'いいね',
  [GeneralKeys.comments]: 'コメント',
  [GeneralKeys.reposts]: 'X リポスト',
  [GeneralKeys.search]: '検索',
  [GeneralKeys.contact]: '連絡する',
  [GeneralKeys.price_sign]: '$ --',
  [GeneralKeys.unlocking]: 'ロック解除中',
  [GeneralKeys.click_to_unlock]: 'クリックして解除',
  [GeneralKeys.add_to_cart]: 'カートに追加',
  [GeneralKeys.show_less]: '表示を縮小',
  [GeneralKeys.show_more]: '表示を拡大',
  [GeneralKeys.go_back]: '戻る',
  [GeneralKeys.load_more]: 'さらに読み込む',
  [GeneralKeys.metamask]: 'MetaMask',
  [GeneralKeys.coinbase_wallet]: 'Coinbase Wallet',
  [GeneralKeys.send]: '送信',
  [GeneralKeys.email]: 'Eメール',
  [GeneralKeys.or_upper_case]: 'または',
  [GeneralKeys.agree]: '同意する',
  [GeneralKeys.client]: 'ブランド',
  [GeneralKeys.influencer]: 'クリエイター',
  [GeneralKeys.marketing]: 'マーケティング',
  [GeneralKeys.creator_marketing]: 'マーケットプレイス',
  [GeneralKeys.partnerships]: 'ポイント · パートナ',
  [GeneralKeys.evo_tokens]: 'EVO ポイント',
  [GeneralKeys.information]: '情報',
  [GeneralKeys.website_credits]: 'ウェブサイトクレジット',
  [GeneralKeys.home]: 'ホーム',
  [GeneralKeys.about]: '会社概要',
  [GeneralKeys.pricing]: '価格情報',
  [GeneralKeys.proposal]: 'コラボ提案',
  [GeneralKeys.logout]: 'ログアウト',
  [GeneralKeys.view_more]: '詳細を見る',
  [GeneralKeys.view_less]: '表示を少なくする',
  [GeneralKeys.title]: 'タイトル',
  [GeneralKeys.preview]: 'プレビュー',
  [GeneralKeys.upload_a_file]: 'アップロード',
  [GeneralKeys.description]: 'パッケージ説明',
  [GeneralKeys.type]: 'タイプ',
  [GeneralKeys.category]: 'カテゴリー',
  [GeneralKeys.select]: '選択',
  [GeneralKeys.unlimited_revisions]: '無制限の修正',
  [GeneralKeys.unlimited]: '無制限',
  [GeneralKeys.revision]: '{{number}}回修正',
  [GeneralKeys.revisions]: '{{number}}回修正',
  [GeneralKeys.number_revision]: '修正回数',
  [GeneralKeys.optional]: 'オプション',
  [GeneralKeys.username]: 'ユーザー名',
  [GeneralKeys.social_network]: 'ソーシャルネットワーク',
  [GeneralKeys.unlink]: '接続を解除',
  [GeneralKeys.unlink_platform]: '{{platform}}の接続を解除',
  [GeneralKeys.language]: '言語',
  [GeneralKeys.languages]: '言語',
  [GeneralKeys.package]: 'パッケージ',
  [GeneralKeys.save]: '保存',
  [GeneralKeys.creator]: 'クリエイター',
  [GeneralKeys.basic]: 'ベーシック',
  [GeneralKeys.standard]: 'ベーシック',
  [GeneralKeys.premium]: 'スタンダード',
  [GeneralKeys.per_day]: '/日',
  [GeneralKeys.per_month]: '/月',
  [GeneralKeys.summary]: '概要',
  [GeneralKeys.general]: '一般',
  [GeneralKeys.register]: '登録完了',
  [GeneralKeys.click]: 'クリック',
  [GeneralKeys.connect]: '選択',
  [GeneralKeys.reconnect]: '再接続する',
  [GeneralKeys.connecting]: '接続中',
  [GeneralKeys.notifications]: '通知',
  [GeneralKeys.messages]: 'メッセージ',
  [GeneralKeys.reminders]: 'リマインダー',
  [GeneralKeys.newsletters]: 'ニュースレター',
  [GeneralKeys.packages]: 'パッケージ',
  [GeneralKeys.real_time]: 'リアルタイム',
  [GeneralKeys.unsubscribe]: '解除する',
  [GeneralKeys.referral_code]: '紹介コード',
  [GeneralKeys.total_invites]: '合計{{number}}の招待',
  [GeneralKeys.submitted]: '提出済み',
  [GeneralKeys.here]: 'こちら',
  [GeneralKeys.confirm]: '確認',
  [GeneralKeys.deactivate]: '無効化',
  [GeneralKeys.delete]: 'アカウントを削除',
  [GeneralKeys.wallet]: 'ウォレット',
  [GeneralKeys.yes]: 'はい',
  [GeneralKeys.privacy_policy]: 'プライバシーポリシー',
  [GeneralKeys.payment_policy]: '支払いポリシー',
  [GeneralKeys.reactivate]: '再有効化',
  [GeneralKeys.analytics]: '統計・分析',
  [GeneralKeys.user_info]: 'ユーザー情報',
  [GeneralKeys.enterprise]: 'プレミアム',
  [GeneralKeys.details]: '詳細',
  [GeneralKeys.claim]: 'クレーム',
  [GeneralKeys.in_progress]: '進行中',
  [GeneralKeys.free]: '無料',
  [GeneralKeys.report]: 'レポート',
  [GeneralKeys.link_copied]: 'リンクがコピーされました',
  [GeneralKeys.contents_lowercase]: 'コンテンツ',
  [GeneralKeys.channel_lowercase]: 'チャンネル',
  [GeneralKeys.audience_lowercase]: 'オーディエンス',
  [GeneralKeys.my_url]: 'マイURL',
  [GeneralKeys.username_lowercase]: 'username',
  [GeneralKeys.shorts]: 'ショーツ',
  [GeneralKeys.x_post]: 'X 投稿',
  [GeneralKeys.repost]: 'X リポスト',
  [GeneralKeys.website]: 'MYウェブサイト',
  [GeneralKeys.banner]: 'バナー',
  [GeneralKeys.profile_lowercase]: 'プロフィール',
  [GeneralKeys.congratulations]: 'おめでとうございます！',
  [GeneralKeys.skip]: 'スキップ',
  [GeneralKeys.back]: '戻る',
  [GeneralKeys.shares]: 'シェア',
  [GeneralKeys.posts]: '投稿',
  [GeneralKeys.client_company]: 'ブランド · 企業',
  [GeneralKeys.contents_in]:
    '{{categories}}コンテンツを{{countries}}の視聴者に提供します。',
  [GeneralKeys.videos_lowercase]: '動画',
  [GeneralKeys.tweets_lowercase]: 'X 投稿',
  [GeneralKeys.posts_lowercase]: '投稿',
  [GeneralKeys.rating]: '評価',
  [GeneralKeys.users]: 'ユーザー',
  [GeneralKeys.browse_files]: 'ファイルを参照',
  [GeneralKeys.upload_failed]: 'アップロードに失敗しました',
  [GeneralKeys.downloading]: 'ダウンロード中...',
  [GeneralKeys.package_summary]: 'パッケージ概要',
  [GeneralKeys.contents_plural]: '{{number}}個のコンテンツ',
  [GeneralKeys.contents_singular]: '{{number}}個のコンテンツ',
  [GeneralKeys.creator_brand_help_center]: 'ヘルプセンター＆FAQ',
  [GeneralKeys.main]: '代表',
  [GeneralKeys.date]: '日付',
  [GeneralKeys.service]: 'サービス',
  [GeneralKeys.order_id]: '注文ID',
  [GeneralKeys.transaction]: '取引',
  [GeneralKeys.pdf]: 'PDF',
  [GeneralKeys.no_result_to_display]: '表示する結果がありません',
  [GeneralKeys.oldest]: '最古',
  [GeneralKeys.rows_10]: '10 行',
  [GeneralKeys.rows_50]: '50 行',
  [GeneralKeys.rows_100]: '100 行',
  [GeneralKeys.rows_500]: '500 行',
  [GeneralKeys.rows_1000]: '1000 行',
  [GeneralKeys.refresh]: '更新',

  // Category
  [GeneralKeys.Category.digital]: 'デジタル',
  [GeneralKeys.Category.entertainment]: 'エンターテイメント',
  [GeneralKeys.Category.society]: '社会',
  [GeneralKeys.Category.life_style]: 'ライフスタイル',

  [GeneralKeys.Category.tech_science]: 'テクノロジー ・ サイエンス',
  [GeneralKeys.Category.video_games]: 'ビデオゲーム',
  [GeneralKeys.Category.metaverse]: 'メタバース',
  [GeneralKeys.Category.crypto_nft]: 'アプリ · チュートリアル',
  [GeneralKeys.Category.finance]: '経済',
  [GeneralKeys.Category.movies]: '映画',
  [GeneralKeys.Category.anime_cartoon]: 'アニメ ・ 漫画',
  [GeneralKeys.Category.music]: '音楽',
  [GeneralKeys.Category.dance]: 'ダンス',
  [GeneralKeys.Category.sports]: 'スポーツ',
  [GeneralKeys.Category.comedy]: 'コメディ',
  [GeneralKeys.Category.politics_society]: '政治 ・ 社会',
  [GeneralKeys.Category.people_blogs]: '人物 ・ ブログ',
  [GeneralKeys.Category.fashion]: 'ファッション',
  [GeneralKeys.Category.beauty]: '美容',
  [GeneralKeys.Category.food_cooking]: '食べ物 ・ 料理',
  [GeneralKeys.Category.youth_infant]: '子供向け',
  [GeneralKeys.Category.animals_pets]: '動物 ・ ペット',
  [GeneralKeys.Category.travel_event]: '旅行 ・ 文化',
  [GeneralKeys.Category.auto_vehicles]: '自動車 ・ オート',
  [GeneralKeys.Category.unboxing]: '開封動画',
  [GeneralKeys.Category.education]: '教育',
  [GeneralKeys.Category.art_crafts]: 'アート · クラフト',
  [GeneralKeys.Category.diy_home]: 'DIY · インテリア',
  [GeneralKeys.Category.virtual_vtuber]: 'バーチャル · VTuber',
  [GeneralKeys.Category.ai_automation]: 'AI · 自動化',
  [GeneralKeys.Category.gym_fitness]: 'ジム · フィットネス',

  // Country
  [GeneralKeys.Country.are]: 'アラブ首長国連邦',
  [GeneralKeys.Country.arg]: 'アルゼンチン',
  [GeneralKeys.Country.aus]: 'オーストラリア',
  [GeneralKeys.Country.aut]: 'オーストリア',
  [GeneralKeys.Country.bel]: 'ベルギー',
  [GeneralKeys.Country.bra]: 'ブラジル',
  [GeneralKeys.Country.can]: 'カナダ',
  [GeneralKeys.Country.che]: 'スイス',
  [GeneralKeys.Country.chn]: '中国',
  [GeneralKeys.Country.deu]: 'ドイツ',
  [GeneralKeys.Country.dnk]: 'デンマーク',
  [GeneralKeys.Country.eeu]: 'ヨーロッパ',
  [GeneralKeys.Country.esp]: 'スペイン',
  [GeneralKeys.Country.fin]: 'フィンランド',
  [GeneralKeys.Country.fra]: 'フランス',
  [GeneralKeys.Country.gbr]: 'イギリス',
  [GeneralKeys.Country.glb]: 'グローバル',
  [GeneralKeys.Country.hkg]: '香港',
  [GeneralKeys.Country.idn]: 'インドネシア',
  [GeneralKeys.Country.ind]: 'インド',
  [GeneralKeys.Country.irn]: 'イラン',
  [GeneralKeys.Country.isr]: 'イスラエル',
  [GeneralKeys.Country.ita]: 'イタリア',
  [GeneralKeys.Country.jpn]: '日本',
  [GeneralKeys.Country.kor]: '韓国',
  [GeneralKeys.Country.mex]: 'メキシコ',
  [GeneralKeys.Country.mys]: 'マレーシア',
  [GeneralKeys.Country.nld]: 'オランダ',
  [GeneralKeys.Country.nor]: 'ノルウェー',
  [GeneralKeys.Country.nzl]: 'ニュージーランド',
  [GeneralKeys.Country.pak]: 'パキスタン',
  [GeneralKeys.Country.phl]: 'フィリピン',
  [GeneralKeys.Country.pol]: 'ポーランド',
  [GeneralKeys.Country.prt]: 'ポルトガル',
  [GeneralKeys.Country.sau]: 'サウジアラビア',
  [GeneralKeys.Country.sgp]: 'シンガポール',
  [GeneralKeys.Country.swe]: 'スウェーデン',
  [GeneralKeys.Country.tha]: 'タイ',
  [GeneralKeys.Country.tur]: 'トルコ',
  [GeneralKeys.Country.twn]: '台湾',
  [GeneralKeys.Country.usa]: 'アメリカ合衆国',
  [GeneralKeys.Country.vnm]: 'ベトナム',

  // Client
  [GeneralKeys.Country.abw]: 'アルバ',
  [GeneralKeys.Country.afg]: 'アフガニスタン',
  [GeneralKeys.Country.ago]: 'アンゴラ',
  [GeneralKeys.Country.aia]: 'アンギラ',
  [GeneralKeys.Country.ala]: 'オーランド諸島',
  [GeneralKeys.Country.alb]: 'アルバニア',
  [GeneralKeys.Country.and]: 'アンドラ',
  [GeneralKeys.Country.arm]: 'アルメニア',
  [GeneralKeys.Country.asm]: 'アメリカ領サモア',
  [GeneralKeys.Country.ata]: '南極',
  [GeneralKeys.Country.atf]: 'フランス領南方・南極地域',
  [GeneralKeys.Country.atg]: 'アンティグア・バーブーダ',
  [GeneralKeys.Country.aze]: 'アゼルバイジャン',
  [GeneralKeys.Country.bdi]: 'ブルンジ',
  [GeneralKeys.Country.ben]: 'ベナン',
  [GeneralKeys.Country.bes]: 'ボネール、シント・ユースタティウスおよびサバ',
  [GeneralKeys.Country.bfa]: 'ブルキナファソ',
  [GeneralKeys.Country.bgd]: 'バングラデシュ',
  [GeneralKeys.Country.bgr]: 'ブルガリア',
  [GeneralKeys.Country.bhr]: 'バーレーン',
  [GeneralKeys.Country.bhs]: 'バハマ',
  [GeneralKeys.Country.bih]: 'ボスニア・ヘルツェゴビナ',
  [GeneralKeys.Country.blm]: 'サン・バルテルミー',
  [GeneralKeys.Country.blr]: 'ベラルーシ',
  [GeneralKeys.Country.blz]: 'ベリーズ',
  [GeneralKeys.Country.bmu]: 'バミューダ',
  [GeneralKeys.Country.bol]: 'ボリビア多民族国',
  [GeneralKeys.Country.brb]: 'バルバドス',
  [GeneralKeys.Country.brn]: 'ブルネイ・ダルサラーム',
  [GeneralKeys.Country.btn]: 'ブータン',
  [GeneralKeys.Country.bvt]: 'ブーベ島',
  [GeneralKeys.Country.bwa]: 'ボツワナ',
  [GeneralKeys.Country.caf]: '中央アフリカ共和国',
  [GeneralKeys.Country.cck]: 'ココス（キーリング）諸島',
  [GeneralKeys.Country.chl]: 'チリ',
  [GeneralKeys.Country.civ]: 'コートジボワール',
  [GeneralKeys.Country.cmr]: 'カメルーン',
  [GeneralKeys.Country.cod]: 'コンゴ民主共和国',
  [GeneralKeys.Country.cog]: 'コンゴ共和国',
  [GeneralKeys.Country.cok]: 'クック諸島',
  [GeneralKeys.Country.col]: 'コロンビア',
  [GeneralKeys.Country.com]: 'コモロ',
  [GeneralKeys.Country.cpv]: 'カーボベルデ',
  [GeneralKeys.Country.cri]: 'コスタリカ',
  [GeneralKeys.Country.cub]: 'キューバ',
  [GeneralKeys.Country.cuw]: 'キュラソー',
  [GeneralKeys.Country.cxr]: 'クリスマス島',
  [GeneralKeys.Country.cym]: 'ケイマン諸島',
  [GeneralKeys.Country.cyp]: 'キプロス',
  [GeneralKeys.Country.cze]: 'チェコ',
  [GeneralKeys.Country.dji]: 'ジブチ',
  [GeneralKeys.Country.dma]: 'ドミニカ国',
  [GeneralKeys.Country.dom]: 'ドミニカ共和国',
  [GeneralKeys.Country.dza]: 'アルジェリア',
  [GeneralKeys.Country.ecu]: 'エクアドル',
  [GeneralKeys.Country.egy]: 'エジプト',
  [GeneralKeys.Country.eri]: 'エリトリア',
  [GeneralKeys.Country.esh]: '西サハラ',
  [GeneralKeys.Country.est]: 'エストニア',
  [GeneralKeys.Country.eth]: 'エチオピア',
  [GeneralKeys.Country.fji]: 'フィジー',
  [GeneralKeys.Country.flk]: 'フォークランド諸島（マルビナス諸島）',
  [GeneralKeys.Country.fro]: 'フェロー諸島',
  [GeneralKeys.Country.fsm]: 'ミクロネシア連邦',
  [GeneralKeys.Country.gab]: 'ガボン',
  [GeneralKeys.Country.geo]: 'ジョージア',
  [GeneralKeys.Country.ggy]: 'ガーンジー',
  [GeneralKeys.Country.gha]: 'ガーナ',
  [GeneralKeys.Country.gib]: 'ジブラルタル',
  [GeneralKeys.Country.gin]: 'ギニア',
  [GeneralKeys.Country.glp]: 'グアドループ',
  [GeneralKeys.Country.gmb]: 'ガンビア',
  [GeneralKeys.Country.gnb]: 'ギニアビサウ',
  [GeneralKeys.Country.gnq]: '赤道ギニア',
  [GeneralKeys.Country.grc]: 'ギリシャ',
  [GeneralKeys.Country.grd]: 'グレナダ',
  [GeneralKeys.Country.grl]: 'グリーンランド',
  [GeneralKeys.Country.gtm]: 'グアテマラ',
  [GeneralKeys.Country.gum]: 'グアム',
  [GeneralKeys.Country.guy]: 'ガイアナ',
  [GeneralKeys.Country.hmd]: 'ハード島・マクドナルド諸島',
  [GeneralKeys.Country.hnd]: 'ホンジュラス',
  [GeneralKeys.Country.hrv]: 'クロアチア',
  [GeneralKeys.Country.hti]: 'ハイチ',
  [GeneralKeys.Country.hun]: 'ハンガリー',
  [GeneralKeys.Country.imn]: 'マン島',
  [GeneralKeys.Country.iot]: 'イギリス領インド洋地域',
  [GeneralKeys.Country.irl]: 'アイルランド',
  [GeneralKeys.Country.irq]: 'イラク',
  [GeneralKeys.Country.isl]: 'アイスランド',
  [GeneralKeys.Country.jam]: 'ジャマイカ',
  [GeneralKeys.Country.jey]: 'ジャージー島',
  [GeneralKeys.Country.jor]: 'ヨルダン',
  [GeneralKeys.Country.kaz]: 'カザフスタン',
  [GeneralKeys.Country.ken]: 'ケニア',
  [GeneralKeys.Country.kgz]: 'キルギス',
  [GeneralKeys.Country.khm]: 'カンボジア',
  [GeneralKeys.Country.kir]: 'キリバス',
  [GeneralKeys.Country.kna]: 'セントクリストファー・ネイビス',
  [GeneralKeys.Country.kwt]: 'クウェート',
  [GeneralKeys.Country.lao]: 'ラオス人民民主共和国',
  [GeneralKeys.Country.lbn]: 'レバノン',
  [GeneralKeys.Country.lbr]: 'リベリア',
  [GeneralKeys.Country.lby]: 'リビア',
  [GeneralKeys.Country.lca]: 'セントルシア',
  [GeneralKeys.Country.lie]: 'リヒテンシュタイン',
  [GeneralKeys.Country.lka]: 'スリランカ',
  [GeneralKeys.Country.lso]: 'レソト王国',
  [GeneralKeys.Country.ltu]: 'リトアニア',
  [GeneralKeys.Country.lux]: 'ルクセンブルク',
  [GeneralKeys.Country.lva]: 'ラトビア',
  [GeneralKeys.Country.mac]: 'マカオ',
  [GeneralKeys.Country.maf]: 'サン・マルタン（フランス領）',
  [GeneralKeys.Country.mar]: 'モロッコ',
  [GeneralKeys.Country.mco]: 'モナコ',
  [GeneralKeys.Country.mda]: 'モルドバ',
  [GeneralKeys.Country.mdg]: 'マダガスカル',
  [GeneralKeys.Country.mdv]: 'モルディブ',
  [GeneralKeys.Country.mhl]: 'マーシャル諸島',
  [GeneralKeys.Country.mkd]: '北マケドニア共和国',
  [GeneralKeys.Country.mli]: 'マリ',
  [GeneralKeys.Country.mlt]: 'マルタ',
  [GeneralKeys.Country.mmr]: 'ミャンマー',
  [GeneralKeys.Country.mne]: 'モンテネグロ',
  [GeneralKeys.Country.mng]: 'モンゴル',
  [GeneralKeys.Country.mnp]: '北マリアナ諸島',
  [GeneralKeys.Country.moz]: 'モザンビーク',
  [GeneralKeys.Country.mrt]: 'モーリタニア',
  [GeneralKeys.Country.msr]: 'モントセラト',
  [GeneralKeys.Country.mtq]: 'マルティニーク',
  [GeneralKeys.Country.mus]: 'モーリシャス',
  [GeneralKeys.Country.mwi]: 'マラウィ',
  [GeneralKeys.Country.myt]: 'マヨット',
  [GeneralKeys.Country.nam]: 'ナミビア',
  [GeneralKeys.Country.ncl]: 'ニューカレドニア',
  [GeneralKeys.Country.ner]: 'ニジェール',
  [GeneralKeys.Country.nfk]: 'ノーフォーク島',
  [GeneralKeys.Country.nga]: 'ナイジェリア',
  [GeneralKeys.Country.nic]: 'ニカラグア',
  [GeneralKeys.Country.niu]: 'ニウエ',
  [GeneralKeys.Country.npl]: 'ネパール',
  [GeneralKeys.Country.nru]: 'ナウル',
  [GeneralKeys.Country.omn]: 'オマーン',
  [GeneralKeys.Country.pan]: 'パナマ',
  [GeneralKeys.Country.pcn]: 'ピトケアン島',
  [GeneralKeys.Country.per]: 'ペルー',
  [GeneralKeys.Country.plw]: 'パラオ',
  [GeneralKeys.Country.png]: 'パプアニューギニア',
  [GeneralKeys.Country.pri]: 'プエルトリコ',
  [GeneralKeys.Country.prk]: '朝鮮民主主義人民共和国',
  [GeneralKeys.Country.pry]: 'パラグアイ',
  [GeneralKeys.Country.pse]: 'パレスチナ',
  [GeneralKeys.Country.qat]: 'カタール',
  [GeneralKeys.Country.reu]: 'レユニオン',
  [GeneralKeys.Country.rou]: 'ルーマニア',
  [GeneralKeys.Country.rus]: 'ロシア',
  [GeneralKeys.Country.rwa]: 'ルワンダ',
  [GeneralKeys.Country.sdn]: 'スーダン',
  [GeneralKeys.Country.sen]: 'セネガル',
  [GeneralKeys.Country.sgs]: 'サウスジョージア・サウスサンドウィッチ諸島',
  [GeneralKeys.Country.shn]:
    'セントヘレナ・アセンションおよびトリスタンダクーニャ',
  [GeneralKeys.Country.sjm]: 'スヴァールバル諸島およびヤンマイエン島',
  [GeneralKeys.Country.slb]: 'ソロモン諸島',
  [GeneralKeys.Country.sle]: 'シエラレオネ',
  [GeneralKeys.Country.slv]: 'エルサルバドル',
  [GeneralKeys.Country.smr]: 'サンマリノ',
  [GeneralKeys.Country.som]: 'ソマリア',
  [GeneralKeys.Country.spm]: 'サンピエール島・ミクロン島',
  [GeneralKeys.Country.srb]: 'セルビア',
  [GeneralKeys.Country.ssd]: '南スーダン',
  [GeneralKeys.Country.stp]: 'サントメ・プリンシペ',
  [GeneralKeys.Country.sur]: 'スリナム',
  [GeneralKeys.Country.svk]: 'スロバキア',
  [GeneralKeys.Country.svn]: 'スロベニア',
  [GeneralKeys.Country.swz]: 'エスワティニ',
  [GeneralKeys.Country.sxm]: 'シント・マールテン',
  [GeneralKeys.Country.syc]: 'セーシェル',
  [GeneralKeys.Country.syr]: 'シリア・アラブ共和国',
  [GeneralKeys.Country.tca]: 'タークス・カイコス諸島',
  [GeneralKeys.Country.tcd]: 'チャド',
  [GeneralKeys.Country.tgo]: 'トーゴ',
  [GeneralKeys.Country.tjk]: 'タジキスタン',
  [GeneralKeys.Country.tkl]: 'トケラウ',
  [GeneralKeys.Country.tkm]: 'トルクメニスタン',
  [GeneralKeys.Country.tls]: '東ティモール',
  [GeneralKeys.Country.ton]: 'トンガ',
  [GeneralKeys.Country.tto]: 'トリニダード・トバゴ',
  [GeneralKeys.Country.tun]: 'チュニジア',
  [GeneralKeys.Country.tuv]: 'ツバル',
  [GeneralKeys.Country.tza]: 'タンザニア連合共和国',
  [GeneralKeys.Country.uga]: 'ウガンダ',
  [GeneralKeys.Country.ukr]: 'ウクライナ',
  [GeneralKeys.Country.umi]: '合衆国外諸島',
  [GeneralKeys.Country.ury]: 'ウルグアイ',
  [GeneralKeys.Country.uzb]: 'ウズベキスタン',
  [GeneralKeys.Country.vat]: 'バチカン市国',
  [GeneralKeys.Country.vct]: 'セントビンセントおよびグレナディーン諸島',
  [GeneralKeys.Country.ven]: 'ベネズエラ・ボリバル共和国',
  [GeneralKeys.Country.vgb]: 'イギリス領ヴァージン諸島',
  [GeneralKeys.Country.vir]: 'アメリカ領ヴァージン諸島',
  [GeneralKeys.Country.vut]: 'バヌアツ共和国',
  [GeneralKeys.Country.wlf]: 'ウォリス・フツナ',
  [GeneralKeys.Country.wsm]: 'サモア',
  [GeneralKeys.Country.yem]: 'イエメン',
  [GeneralKeys.Country.zaf]: '南アフリカ',
  [GeneralKeys.Country.zmb]: 'ザンビア',
  [GeneralKeys.Country.zwe]: 'ジンバブエ',

  // 2-Char
  [GeneralKeys.Country.ae]: 'アラブ首長国連邦',
  [GeneralKeys.Country.ar]: 'アルゼンチン',
  [GeneralKeys.Country.au]: 'オーストラリア',
  [GeneralKeys.Country.at]: 'オーストリア',
  [GeneralKeys.Country.be]: 'ベルギー',
  [GeneralKeys.Country.br]: 'ブラジル',
  [GeneralKeys.Country.ca]: 'カナダ',
  [GeneralKeys.Country.ch]: 'スイス',
  [GeneralKeys.Country.cn]: '中国',
  [GeneralKeys.Country.de]: 'ドイツ',
  [GeneralKeys.Country.dk]: 'デンマーク',
  [GeneralKeys.Country.eeu]: 'ヨーロッパ',
  [GeneralKeys.Country.es]: 'スペイン',
  [GeneralKeys.Country.fi]: 'フィンランド',
  [GeneralKeys.Country.fr]: 'フランス',
  [GeneralKeys.Country.gb]: 'イギリス',
  [GeneralKeys.Country.glb]: 'グローバル',
  [GeneralKeys.Country.hk]: '香港',
  [GeneralKeys.Country.id]: 'インドネシア',
  [GeneralKeys.Country.in]: 'インド',
  [GeneralKeys.Country.ir]: 'イラン',
  [GeneralKeys.Country.il]: 'イスラエル',
  [GeneralKeys.Country.it]: 'イタリア',
  [GeneralKeys.Country.jp]: '日本',
  [GeneralKeys.Country.kr]: '韓国',
  [GeneralKeys.Country.mx]: 'メキシコ',
  [GeneralKeys.Country.my]: 'マレーシア',
  [GeneralKeys.Country.nl]: 'オランダ',
  [GeneralKeys.Country.no]: 'ノルウェー',
  [GeneralKeys.Country.nz]: 'ニュージーランド',
  [GeneralKeys.Country.pk]: 'パキスタン',
  [GeneralKeys.Country.ph]: 'フィリピン',
  [GeneralKeys.Country.pl]: 'ポーランド',
  [GeneralKeys.Country.pt]: 'ポルトガル',
  [GeneralKeys.Country.sa]: 'サウジアラビア',
  [GeneralKeys.Country.sg]: 'シンガポール',
  [GeneralKeys.Country.se]: 'スウェーデン',
  [GeneralKeys.Country.th]: 'タイ',
  [GeneralKeys.Country.tr]: 'トルコ',
  [GeneralKeys.Country.tw]: '台湾',
  [GeneralKeys.Country.us]: 'アメリカ',
  [GeneralKeys.Country.vn]: 'ベトナム',

  // Client
  [GeneralKeys.Country.aw]: 'アルバ',
  [GeneralKeys.Country.af]: 'アフガニスタン',
  [GeneralKeys.Country.ao]: 'アンゴラ',
  [GeneralKeys.Country.ai]: 'アンギラ',
  [GeneralKeys.Country.ax]: 'オーランド諸島',
  [GeneralKeys.Country.al]: 'アルバニア',
  [GeneralKeys.Country.ad]: 'アンドラ',
  [GeneralKeys.Country.am]: 'アルメニア',
  [GeneralKeys.Country.as]: 'アメリカ領サモア',
  [GeneralKeys.Country.aq]: '南極',
  [GeneralKeys.Country.tf]: 'フランス領南方・南極地域',
  [GeneralKeys.Country.ag]: 'アンティグア・バーブーダ',
  [GeneralKeys.Country.az]: 'アゼルバイジャン',
  [GeneralKeys.Country.bi]: 'ブルンジ',
  [GeneralKeys.Country.bj]: 'ベナン',
  [GeneralKeys.Country.bq]: 'ボネール、シント・ユースタティウスおよびサバ',
  [GeneralKeys.Country.bf]: 'ブルキナファソ',
  [GeneralKeys.Country.bd]: 'バングラデシュ',
  [GeneralKeys.Country.bg]: 'ブルガリア',
  [GeneralKeys.Country.bh]: 'バーレーン',
  [GeneralKeys.Country.bs]: 'バハマ',
  [GeneralKeys.Country.ba]: 'ボスニア・ヘルツェゴビナ',
  [GeneralKeys.Country.bl]: 'サン・バルテルミー',
  [GeneralKeys.Country.by]: 'ベラルーシ',
  [GeneralKeys.Country.bz]: 'ベリーズ',
  [GeneralKeys.Country.bm]: 'バミューダ',
  [GeneralKeys.Country.bo]: 'ボリビア（多民族国）',
  [GeneralKeys.Country.bb]: 'バルバドス',
  [GeneralKeys.Country.bn]: 'ブルネイ・ダルサラーム',
  [GeneralKeys.Country.bt]: 'ブータン',
  [GeneralKeys.Country.bv]: 'ブーベ島',
  [GeneralKeys.Country.bw]: 'ボツワナ',
  [GeneralKeys.Country.cf]: '中央アフリカ共和国',
  [GeneralKeys.Country.cc]: 'ココス（キーリング）諸島',
  [GeneralKeys.Country.cl]: 'チリ',
  [GeneralKeys.Country.ci]: 'コートジボワール',
  [GeneralKeys.Country.cm]: 'カメルーン',
  [GeneralKeys.Country.cd]: 'コンゴ民主共和国',
  [GeneralKeys.Country.cg]: 'コンゴ共和国',
  [GeneralKeys.Country.ck]: 'クック諸島',
  [GeneralKeys.Country.co]: 'コロンビア',
  [GeneralKeys.Country.km]: 'コモロ',
  [GeneralKeys.Country.cv]: 'カーボベルデ',
  [GeneralKeys.Country.cr]: 'コスタリカ',
  [GeneralKeys.Country.cu]: 'キューバ',
  [GeneralKeys.Country.cw]: 'キュラソー',
  [GeneralKeys.Country.cx]: 'クリスマス島',
  [GeneralKeys.Country.ky]: 'ケイマン諸島',
  [GeneralKeys.Country.cy]: 'キプロス',
  [GeneralKeys.Country.cz]: 'チェコ',
  [GeneralKeys.Country.dj]: 'ジブチ',
  [GeneralKeys.Country.dm]: 'ドミニカ',
  [GeneralKeys.Country.do]: 'ドミニカ共和国',
  [GeneralKeys.Country.dz]: 'アルジェリア',
  [GeneralKeys.Country.ec]: 'エクアドル',
  [GeneralKeys.Country.eg]: 'エジプト',
  [GeneralKeys.Country.er]: 'エリトリア',
  [GeneralKeys.Country.eh]: '西サハラ',
  [GeneralKeys.Country.ee]: 'エストニア',
  [GeneralKeys.Country.et]: 'エチオピア',
  [GeneralKeys.Country.fj]: 'フィジー',
  [GeneralKeys.Country.fk]: 'フォークランド諸島（マルビナス諸島）',
  [GeneralKeys.Country.fo]: 'フェロー諸島',
  [GeneralKeys.Country.fm]: 'ミクロネシア（連邦）',
  [GeneralKeys.Country.ga]: 'ガボン',
  [GeneralKeys.Country.ge]: 'ジョージア',
  [GeneralKeys.Country.gg]: 'ガーンジー',
  [GeneralKeys.Country.gh]: 'ガーナ',
  [GeneralKeys.Country.gi]: 'ジブラルタル',
  [GeneralKeys.Country.gn]: 'ギニア',
  [GeneralKeys.Country.gp]: 'グアドループ',
  [GeneralKeys.Country.gm]: 'ガンビア',
  [GeneralKeys.Country.gw]: 'ギニアビサウ',
  [GeneralKeys.Country.gq]: '赤道ギニア',
  [GeneralKeys.Country.gr]: 'ギリシャ',
  [GeneralKeys.Country.gd]: 'グレナダ',
  [GeneralKeys.Country.gl]: 'グリーンランド',
  [GeneralKeys.Country.gt]: 'グアテマラ',
  [GeneralKeys.Country.gu]: 'グアム',
  [GeneralKeys.Country.gy]: 'ガイアナ',
  [GeneralKeys.Country.hm]: 'ハード島とマクドナルド諸島',
  [GeneralKeys.Country.hn]: 'ホンジュラス',
  [GeneralKeys.Country.hr]: 'クロアチア',
  [GeneralKeys.Country.ht]: 'ハイチ',
  [GeneralKeys.Country.hu]: 'ハンガリー',
  [GeneralKeys.Country.im]: 'マン島',
  [GeneralKeys.Country.io]: '英領インド洋地域',
  [GeneralKeys.Country.ie]: 'アイルランド',
  [GeneralKeys.Country.iq]: 'イラク',
  [GeneralKeys.Country.is]: 'アイスランド',
  [GeneralKeys.Country.jm]: 'ジャマイカ',
  [GeneralKeys.Country.je]: 'ジャージー島',
  [GeneralKeys.Country.jo]: 'ヨルダン',
  [GeneralKeys.Country.kz]: 'カザフスタン',
  [GeneralKeys.Country.ke]: 'ケニア',
  [GeneralKeys.Country.kg]: 'キルギススタン',
  [GeneralKeys.Country.kh]: 'カンボジア',
  [GeneralKeys.Country.ki]: 'キリバス',
  [GeneralKeys.Country.kn]: 'セントクリストファー・ネイビス',
  [GeneralKeys.Country.kw]: 'クウェート',
  [GeneralKeys.Country.la]: 'ラオス人民民主共和国',
  [GeneralKeys.Country.lb]: 'レバノン',
  [GeneralKeys.Country.lr]: 'リベリア',
  [GeneralKeys.Country.ly]: 'リビア',
  [GeneralKeys.Country.lc]: 'セントルシア',
  [GeneralKeys.Country.li]: 'リヒテンシュタイン',
  [GeneralKeys.Country.lk]: 'スリランカ',
  [GeneralKeys.Country.ls]: 'レソト王国',
  [GeneralKeys.Country.lt]: 'リトアニア',
  [GeneralKeys.Country.lu]: 'ルクセンブルク',
  [GeneralKeys.Country.lv]: 'ラトビア',
  [GeneralKeys.Country.mo]: 'マカオ',
  [GeneralKeys.Country.mf]: 'サン・マルタン（フランス領）',
  [GeneralKeys.Country.ma]: 'モロッコ',
  [GeneralKeys.Country.mc]: 'モナコ',
  [GeneralKeys.Country.md]: 'モルドバ（共和国）',
  [GeneralKeys.Country.mg]: 'マダガスカル',
  [GeneralKeys.Country.mv]: 'モルディブ',
  [GeneralKeys.Country.mh]: 'マーシャル諸島',
  [GeneralKeys.Country.mk]: '北マケドニア（共和国）',
  [GeneralKeys.Country.ml]: 'マリ',
  [GeneralKeys.Country.mt]: 'マルタ',
  [GeneralKeys.Country.mm]: 'ミャンマー',
  [GeneralKeys.Country.me]: 'モンテネグロ',
  [GeneralKeys.Country.mn]: 'モンゴル',
  [GeneralKeys.Country.mp]: '北マリアナ諸島',
  [GeneralKeys.Country.mz]: 'モザンビーク',
  [GeneralKeys.Country.mr]: 'モーリタニア',
  [GeneralKeys.Country.ms]: 'モントセラト',
  [GeneralKeys.Country.mq]: 'マルティニーク',
  [GeneralKeys.Country.mu]: 'モーリシャス',
  [GeneralKeys.Country.mw]: 'マラウイ',
  [GeneralKeys.Country.yt]: 'マヨット',
  [GeneralKeys.Country.na]: 'ナミビア',
  [GeneralKeys.Country.nc]: 'ニューカレドニア',
  [GeneralKeys.Country.ne]: 'ニジェール',
  [GeneralKeys.Country.nf]: 'ノーフォーク島',
  [GeneralKeys.Country.ng]: 'ナイジェリア',
  [GeneralKeys.Country.ni]: 'ニカラグア',
  [GeneralKeys.Country.nu]: 'ニウエ',
  [GeneralKeys.Country.np]: 'ネパール',
  [GeneralKeys.Country.nr]: 'ナウル',
  [GeneralKeys.Country.om]: 'オマーン',
  [GeneralKeys.Country.pa]: 'パナマ',
  [GeneralKeys.Country.pn]: 'ピトケアン諸島',
  [GeneralKeys.Country.pe]: 'ペルー',
  [GeneralKeys.Country.pw]: 'パラオ',
  [GeneralKeys.Country.pg]: 'パプアニューギニア',
  [GeneralKeys.Country.pr]: 'プエルトリコ',
  [GeneralKeys.Country.kp]: '朝鮮民主主義人民共和国',
  [GeneralKeys.Country.py]: 'パラグアイ',
  [GeneralKeys.Country.ps]: 'パレスチナ、国家',
  [GeneralKeys.Country.qa]: 'カタール',
  [GeneralKeys.Country.re]: 'レユニオン',
  [GeneralKeys.Country.ro]: 'ルーマニア',
  [GeneralKeys.Country.ru]: 'ロシア',
  [GeneralKeys.Country.rw]: 'ルワンダ',
  [GeneralKeys.Country.sd]: 'スーダン',
  [GeneralKeys.Country.sn]: 'セネガル',
  [GeneralKeys.Country.gs]: 'サウスジョージア・サウスサンドウィッチ諸島',
  [GeneralKeys.Country.sh]:
    'セントヘレナ、アセンションおよびトリスタンダクーニャ',
  [GeneralKeys.Country.sj]: 'スヴァールバル諸島およびヤンマイエン島',
  [GeneralKeys.Country.sb]: 'ソロモン諸島',
  [GeneralKeys.Country.sl]: 'シエラレオネ',
  [GeneralKeys.Country.sv]: 'エルサルバドル',
  [GeneralKeys.Country.sm]: 'サンマリノ',
  [GeneralKeys.Country.so]: 'ソマリア',
  [GeneralKeys.Country.pm]: 'サンピエール島およびミクロン島',
  [GeneralKeys.Country.rs]: 'セルビア',
  [GeneralKeys.Country.ss]: '南スーダン',
  [GeneralKeys.Country.st]: 'サントメ・プリンシペ',
  [GeneralKeys.Country.sr]: 'スリナム',
  [GeneralKeys.Country.sk]: 'スロバキア',
  [GeneralKeys.Country.si]: 'スロベニア',
  [GeneralKeys.Country.sz]: 'エスワティニ',
  [GeneralKeys.Country.sx]: 'シント・マールテン',
  [GeneralKeys.Country.sc]: 'セーシェル',
  [GeneralKeys.Country.sy]: 'シリア・アラブ共和国',
  [GeneralKeys.Country.tc]: 'タークス・カイコス諸島',
  [GeneralKeys.Country.td]: 'チャド',
  [GeneralKeys.Country.tg]: 'トーゴ',
  [GeneralKeys.Country.tj]: 'タジキスタン',
  [GeneralKeys.Country.tk]: 'トケラウ',
  [GeneralKeys.Country.tm]: 'トルクメニスタン',
  [GeneralKeys.Country.tl]: '東ティモール',
  [GeneralKeys.Country.to]: 'トンガ',
  [GeneralKeys.Country.tt]: 'トリニダード・トバゴ',
  [GeneralKeys.Country.tn]: 'チュニジア',
  [GeneralKeys.Country.tv]: 'ツバル',
  [GeneralKeys.Country.tz]: 'タンザニア、合衆国',
  [GeneralKeys.Country.ug]: 'ウガンダ',
  [GeneralKeys.Country.ua]: 'ウクライナ',
  [GeneralKeys.Country.um]: '合衆国領有小離島',
  [GeneralKeys.Country.uy]: 'ウルグアイ',
  [GeneralKeys.Country.uz]: 'ウズベキスタン',
  [GeneralKeys.Country.va]: 'バチカン',
  [GeneralKeys.Country.vc]: 'セントビンセントおよびグレナディーン諸島',
  [GeneralKeys.Country.ve]: 'ベネズエラ（ボリバル共和国）',
  [GeneralKeys.Country.vg]: 'イギリス領ヴァージン諸島',
  [GeneralKeys.Country.vi]: 'アメリカ領ヴァージン諸島',
  [GeneralKeys.Country.vu]: 'バヌアツ共和国',
  [GeneralKeys.Country.wf]: 'ウォリス・フツナ',
  [GeneralKeys.Country.ws]: 'サモア',
  [GeneralKeys.Country.ye]: 'イエメン',
  [GeneralKeys.Country.za]: '南アフリカ',
  [GeneralKeys.Country.zm]: 'ザンビア',
  [GeneralKeys.Country.zw]: 'ジンバブエ',
};
