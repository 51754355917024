import styled from 'styled-components';

const IndigoLongButton = styled.button.attrs({
  className: 'sbh6.5',
})`
  width: 100%;
  padding: 0.625rem 1.375rem;
  border-radius: 0.5rem;
  background: var(--color-indigo-500);
  border: none;
  color: white;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.7;
  }
`;

export default IndigoLongButton;
