import { SearchIcon } from 'assets/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const SearchBox = ({ text, setText }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Input
        type="text"
        placeholder={t(GeneralKeys.search)}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />

      <Search />
    </Wrapper>
  );
};

export default SearchBox;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding-right: 10px;
`;

const Input = styled.input.attrs({
  className: 'h6',
})`
  border: 2px solid var(--color-indigo-25);
  border-radius: 6px;
  width: 100%;
  min-width: 0;
  padding: 2px 6px;
  margin: 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--color-indigo-100);
  }

  &:focus::placeholder {
    color: transparent;
  }
`;

const Search = styled.span.attrs({
  children: <SearchIcon size={0.45} />,
})`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
