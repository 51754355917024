export const MedalIcon = ({ size = 1 }) => {
  return (
    <svg
      width={16 * size}
      height={19 * size}
      viewBox="0 0 16 19"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.9805 4.18164C15.9515 4.07305 15.9012 3.9713 15.8326 3.88226C15.764 3.79322 15.6784 3.71867 15.5808 3.66291L11.1589 1.11181C11.0624 1.05523 10.9556 1.01827 10.8447 1.00303C10.7339 0.9878 10.6211 0.994598 10.5129 1.02304C10.4046 1.05148 10.3031 1.101 10.214 1.16877C10.125 1.23653 10.0502 1.32121 9.99391 1.41794L8.00405 4.87044L6.01419 1.41794C5.95792 1.32121 5.88312 1.23653 5.79407 1.16877C5.70501 1.101 5.60346 1.05148 5.49523 1.02304C5.387 0.994598 5.27422 0.9878 5.16336 1.00303C5.0525 1.01827 4.94573 1.05523 4.84918 1.11181L0.427265 3.66291C0.32967 3.71867 0.244097 3.79322 0.175495 3.88226C0.106893 3.9713 0.056623 4.07305 0.027592 4.18164C-0.0286882 4.39716 0.00184559 4.62616 0.112629 4.81941L3.5141 10.6274C3.13462 11.3226 2.92483 12.0977 2.90184 12.8894C2.89981 13.6618 3.07317 14.4245 3.40884 15.1202C3.74451 15.8158 4.23373 16.4261 4.83962 16.9052C5.4455 17.3842 6.15224 17.7194 6.90654 17.8856C7.66084 18.0517 8.44301 18.0444 9.19409 17.8643C9.94516 17.6841 10.6455 17.3358 11.2424 16.8456C11.8393 16.3553 12.317 15.736 12.6397 15.0342C12.9624 14.3325 13.1215 13.5666 13.1051 12.7944C13.0887 12.0222 12.8972 11.2638 12.545 10.5764L15.8615 4.82792C15.9232 4.73348 15.9655 4.6277 15.9859 4.51674C16.0064 4.40579 16.0045 4.29187 15.9805 4.18164ZM2.05147 4.70887L4.99374 3.00813L7.73193 7.7872C6.56473 7.85931 5.45871 8.33418 4.60257 9.13078L2.05147 4.70887ZM8.00405 16.2909C7.10192 16.2909 6.23674 15.9325 5.59884 15.2946C4.96094 14.6567 4.60257 13.7915 4.60257 12.8894C4.60257 11.9873 4.96094 11.1221 5.59884 10.4842C6.23674 9.8463 7.10192 9.48794 8.00405 9.48794C8.90617 9.48794 9.77135 9.8463 10.4093 10.4842C11.0472 11.1221 11.4055 11.9873 11.4055 12.8894C11.4055 13.7915 11.0472 14.6567 10.4093 15.2946C9.77135 15.9325 8.90617 16.2909 8.00405 16.2909ZM11.4055 9.10527C10.9438 8.68358 10.4074 8.35191 9.82384 8.12735L8.99047 6.57117L11.0484 3.00813L13.9906 4.70887L11.4055 9.10527Z" />
    </svg>
  );
};
