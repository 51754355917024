import AxiosClient from 'components/utilities/AxiosClient';

const getGPA = async ({ userId, handle, platformUid,platform,isBasic }) => {
  if (!(userId || handle || platformUid)||!platform)
    return Promise.reject({
      message: 'Missing required parameters for getGPA',
    });

  const res = await AxiosClient.get('/youtube/Channel/GPA', {
    params: {
      userId,
      handle,
      platformUid,
      platform,
      isBasic
    },
  });

  return res.data;
};

export default getGPA;
