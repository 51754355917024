export const FileIcon = ({ size = 1 }) => (
  <svg
    width={18 * size}
    height={22 * size}
    viewBox="0 0 18 22"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.6 7.634C17.5885 7.53295 17.5664 7.43339 17.534 7.337V7.238C17.4811 7.1249 17.4106 7.02093 17.325 6.93L10.725 0.33C10.6341 0.244438 10.5301 0.173889 10.417 0.121H10.318C10.2063 0.0569154 10.0828 0.0157786 9.955 0H3.3C2.42479 0 1.58542 0.347678 0.966548 0.966548C0.347678 1.58542 0 2.42479 0 3.3V18.7C0 19.5752 0.347678 20.4146 0.966548 21.0335C1.58542 21.6523 2.42479 22 3.3 22H14.3C15.1752 22 16.0146 21.6523 16.6335 21.0335C17.2523 20.4146 17.6 19.5752 17.6 18.7V7.7C17.6 7.7 17.6 7.7 17.6 7.634ZM11 3.751L13.849 6.6H12.1C11.8083 6.6 11.5285 6.48411 11.3222 6.27782C11.1159 6.07153 11 5.79174 11 5.5V3.751ZM15.4 18.7C15.4 18.9917 15.2841 19.2715 15.0778 19.4778C14.8715 19.6841 14.5917 19.8 14.3 19.8H3.3C3.00826 19.8 2.72847 19.6841 2.52218 19.4778C2.31589 19.2715 2.2 18.9917 2.2 18.7V3.3C2.2 3.00826 2.31589 2.72847 2.52218 2.52218C2.72847 2.31589 3.00826 2.2 3.3 2.2H8.8V5.5C8.8 6.37521 9.14768 7.21458 9.76655 7.83345C10.3854 8.45232 11.2248 8.8 12.1 8.8H15.4V18.7Z" />
  </svg>
);
