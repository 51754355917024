import PlatformIcon from 'components/Basics/PlatformIcon';
import { useEffect, useState } from 'react';
import { TikTokEmbed } from 'react-social-media-embed';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';

const TikTok = ({ contentId, inView, onInternalRefChange }) => {
  const [alreadyLoaded, setAlreadyLoaded] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (inView && !alreadyLoaded) {
      setAlreadyLoaded(true);
    }
  }, [inView, alreadyLoaded]);

  if (!alreadyLoaded) return <Empty />;

  return (
    <Item>
      <TiktokWrapper>
        <TikTokEmbed
          url={`https://www.tiktok.com/embed/v2/${contentId}`}
          width="100%"
          embedPlaceholder={
            <TikTokLoading>
              <PlatformIcon platform="tiktok" variant="size3" />
              <span className="h6 m-0 line-height-1.6 text-indigo-100">
                {t(ProfileKeys.Channel_Contents.Tiktok.loading)}
              </span>
            </TikTokLoading>
          }
        />

        <TikTokOverlay
          ref={(node) => {
            if (inView) onInternalRefChange(node);
          }}
        />
      </TiktokWrapper>
    </Item>
  );
};

export default TikTok;

const Empty = styled.div`
  width: 325px;
  height: 575px;
  flex-shrink: 0;
`;

const Item = styled.div`
  width: 325px;
  max-height: 575px;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 0.5rem;
`;

const TiktokWrapper = styled.div`
  overflow: scroll;
  width: 100%;
  max-height: 575px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const TikTokOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
`;

const TikTokLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 575px;
  flex-shrink: 0;
`;
