import { useEffect } from 'react';
import styled from 'styled-components';
import useOutsideAlerter from 'hooks/useOutsideAlerter';
import { useClickAway } from 'react-use';
import { useRef } from 'react';

const BannerModal = ({ isOpen, onClose, children, forceOpen = false, haveClickAway = true }) => {
  const ref = useRef();

  useClickAway(
    ref,
    () => {
      if (isOpen && haveClickAway) onClose?.();

    },
    ['mouseup', 'touchend']
  );
  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        onClose?.();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  return (
    <>
      <Backdrop isOpen={isOpen} />
      <Container
        isOpen={isOpen}
        onClick={() => {
          onClose?.();
        }}
      >
        <ModalWrapper>
          <div ref={ref} onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </ModalWrapper>
      </Container>
    </>
  );
};

export default BannerModal;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1060;
  animation: fadeIn 200ms ease-in-out;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  margin: 0;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  animation: fadeIn 250ms ease-in-out;
  z-index: 1100;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`;

const ModalWrapper = styled.div`
  /* width: max-content; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 1rem; */
  min-height: 100%;
`;
