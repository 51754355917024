import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { Tool } from '..';
import { SnippetOptionsNames } from '../plugins/SnippetPlugin/SnippetOptions';
import { motion, AnimatePresence } from 'framer-motion';
import { INSERT_SNIPPET_COMMAND } from '../plugins/SnippetPlugin';
import { useTranslation } from 'react-i18next';

const OptionNames = {
  [Tool.SNIPPET]: SnippetOptionsNames,
};

const commandMap = {
  [Tool.SNIPPET]: INSERT_SNIPPET_COMMAND,
};

const ToolOptions = ({ name, options, title, className, children }) => {
  const { t } = useTranslation();

  const [editor] = useLexicalComposerContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const wrapperRef = useRef(null);
  useClickAway(wrapperRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <Wrapper ref={wrapperRef}>
      <Trigger className={className} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {children}
      </Trigger>

      <AnimatePresence>
        {isMenuOpen && (
          <Menu>
            {title && <Title>{title}</Title>}
            {options.map((option) => (
              <Option
                key={option}
                onClick={() => {
                  editor.dispatchCommand(commandMap[name], option);
                  setIsMenuOpen(false);
                }}
                disabled={!editor.isEditable()}
              >
                {t(OptionNames[name][option]) || option}
              </Option>
            ))}
          </Menu>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default ToolOptions;

const Wrapper = styled.div`
  position: relative;
`;

const Trigger = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  background: 0;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const Menu = styled(motion.div).attrs({
  initial: {
    opacity: 0,
    scale: 0.95,
  },
  animate: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: 0,
    scale: 0.95,
  },
})`
  position: absolute;
  top: 100%;
  left: 50%;
  translate: -50%;
  z-index: 10;
  display: flex;
  margin-top: 0.375rem;
  padding-block: 0.375rem;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  border: 2px solid var(--color-pink-100);
  background: #fff;
  box-shadow: var(--shadow-dropdown-art-assets);
  transform-origin: top center;
`;

const Title = styled.div.attrs({
  className: 'sbh5.5',
})`
  width: 100%;
  min-width: max-content;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid var(--color-indigo-25);
`;

const Option = styled.button.attrs({
  className: 'h6',
})`
  width: 100%;
  min-width: max-content;
  padding: 0.75rem 1.25rem;
  background: transparent;
  border: none;
  text-align: left;
  transition: all 150ms ease-in-out;

  &:hover {
    background: var(--color-indigo-10);
  }

  &:focus {
    outline: none;
    background: var(--color-indigo-10);
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
`;
