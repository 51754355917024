import { useEffect, useState } from 'react';

/**
 *
 * @param {string} src - url of script source
 * @param {boolean} [setAsync] - set script async attribute
 * @param {boolean} [setDefer] - set script defer attribute
 * @param {boolean} [permanent] - disable script remove while component unmount
 */
const useScript = (src, setAsync, setDefer, permanent) => {
  // script loading status , [idle , loading , ready , error]
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    // if parameter is null , status = idle
    if (!src) {
      setStatus('idle');
      return;
    }

    // if src is given , configure script and attatch in body
    let script = null;
    const setScriptStatus = (event) => {
      script.setAttribute(
        'data-status',
        event.type === 'load' ? 'ready' : 'error'
      );

      if (event.type === 'load') {
        setStatus('ready');
      } else {
        setStatus('error');
      }
    };

    if (!script) {
      script = document.createElement('script');
      script.src = src;

      if (setAsync) {
        script.async = true;
      }

      if (setDefer) {
        script.defer = true;
      }

      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);
      script.addEventListener('load', setScriptStatus);
      script.addEventListener('error', setScriptStatus);
    }

    return () => {
      if (script && !permanent) {
        //free momory of script
        script.removeEventListener('load', setScriptStatus);
        script.removeEventListener('error', setScriptStatus);
        document.body.removeChild(script);
      }
    };
  }, [src, setAsync, setDefer, permanent]);

  return status;
};

export default useScript;
