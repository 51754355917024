export const ThreeDotIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 21}
      height={size * 6}
      viewBox="0 0 21 6"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.5 0.666016C10.0385 0.666016 9.58739 0.802863 9.20367 1.05925C8.81996 1.31564 8.52089 1.68006 8.34428 2.10642C8.16768 2.53278 8.12147 3.00194 8.2115 3.45456C8.30153 3.90718 8.52376 4.32294 8.85009 4.64926C9.17641 4.97559 9.59217 5.19782 10.0448 5.28785C10.4974 5.37788 10.9666 5.33167 11.3929 5.15507C11.8193 4.97846 12.1837 4.67939 12.4401 4.29568C12.6965 3.91196 12.8333 3.46084 12.8333 2.99935C12.8333 2.38051 12.5875 1.78702 12.1499 1.34943C11.7123 0.911849 11.1188 0.666016 10.5 0.666016ZM2.33333 0.666016C1.87184 0.666016 1.42072 0.802863 1.037 1.05925C0.653289 1.31564 0.35422 1.68006 0.177615 2.10642C0.00101099 2.53278 -0.0451968 3.00194 0.0448354 3.45456C0.134868 3.90718 0.357096 4.32294 0.683418 4.64926C1.00974 4.97559 1.4255 5.19782 1.87812 5.28785C2.33075 5.37788 2.7999 5.33167 3.22626 5.15507C3.65262 4.97846 4.01704 4.67939 4.27343 4.29568C4.52982 3.91196 4.66667 3.46084 4.66667 2.99935C4.66667 2.38051 4.42084 1.78702 3.98325 1.34943C3.54567 0.911849 2.95217 0.666016 2.33333 0.666016ZM18.6667 0.666016C18.2052 0.666016 17.7541 0.802863 17.3703 1.05925C16.9866 1.31564 16.6876 1.68006 16.5109 2.10642C16.3343 2.53278 16.2881 3.00194 16.3782 3.45456C16.4682 3.90718 16.6904 4.32294 17.0168 4.64926C17.3431 4.97559 17.7588 5.19782 18.2115 5.28785C18.6641 5.37788 19.1332 5.33167 19.5596 5.15507C19.986 4.97846 20.3504 4.67939 20.6068 4.29568C20.8632 3.91196 21 3.46084 21 2.99935C21 2.38051 20.7542 1.78702 20.3166 1.34943C19.879 0.911849 19.2855 0.666016 18.6667 0.666016Z" />
    </svg>
  );
};
