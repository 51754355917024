import styled from 'styled-components';
import { forwardRef } from 'react';
import devices from 'styles/device';

const Variant = {
  Pink: 'pink',
  Indigo: 'indigo',
  Red: 'red',
  Blue: 'blue',
  Green: 'green',
  Violet: 'violet',
  PinkLight: 'pinkLight',
  Plain: 'plain',
  Purple: 'purple',
  LightGray: 'lightGray',
  TransparentGray: 'transparentGray',
};

const getBorderColor = (variant, error) => {
  if (error && (variant === 'plain' || variant === 'plainWhite')) {
    return 'var(--pink-light-100)';
  }

  if (error) {
    return 'var(--color-red-500)';
  }

  return (
    {
      [Variant.Pink]: 'var(--color-pink-500)',
      [Variant.Indigo]: 'var(--color-indigo-500)',
      [Variant.Red]: 'var(--color-red-500)',
      [Variant.Blue]: 'var(--color-blue-500)',
      [Variant.Green]: 'var(--color-green-500)',
      [Variant.Violet]: 'var(--color-purple-50)',
      [Variant.PinkLight]: 'var(--color-pink-300)',
      [Variant.Plain]: 'var(--color-pink-100)',
      [Variant.Purple]: 'var(--color-purple-50)',
      [Variant.LightGray]: 'var(--color-indigo-50)',
      [Variant.TransparentGray]: 'var(--color-indigo-30)',
    }[variant] || 'var(--color-white)'
  );
};

const getBackgroundColor = (variant, error, background) => {
  if (error && (variant === 'plain' || variant === 'plainWhite')) {
    return 'var(--color-red-100)';
  }

  if (background) {
    return background;
  }

  if (variant === 'pinkLight' || variant === 'plain') {
    return 'var(--color-pink-100)';
  }

  return 'var(--color-white)';
};

const TextField = ({ variant, error, className, isTextarea, ...rest }, ref) => {
  return (
    <Input
      as={isTextarea ? 'textarea' : 'input'}
      variant={variant}
      error={error}
      className={className}
      ref={ref}
      {...rest}
    />
  );
};

const Input = styled.input`
  border: 2px solid ${(props) => getBorderColor(props.variant, props.error)};
  background-color: ${(props) =>
    getBackgroundColor(props.variant, props.error, props.background)};
  border-radius: 15px;
  padding: 0.9rem 1.25rem;

  &::placeholder {
    color: var(--color-indigo-100);
    transition: opacity 150ms ease-in-out;
  }
  &:focus::placeholder {
    opacity: 0;
  }

  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }

  @media ${devices.lg} {
    padding: 0.25rem 0.875rem;
  }
`;

const withLegacySupport = (Component) => {
  const UpdatedComponent = styled(Component)`
    ${({ color }) => color && `color: ${color};`}
    ${({ padding }) => padding && `padding: ${padding};`}
    ${({ radius }) => radius && `border-radius: ${radius};`}
    ${({ shadow }) => shadow && `box-shadow: ${shadow};`}
    ${({ borderWidth }) => borderWidth && `border-width: ${borderWidth};`}
    ${({ width }) => width && `width: ${width};`}
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
    ${({ minWidth }) => minWidth && `min-width: ${minWidth};`}
    ${({ background }) => background && `background: ${background};`}
    ${({ cursor }) => cursor && `cursor: ${cursor};`}

    &::placeholder {
      ${({ placeholderColor }) =>
        placeholderColor && `color: ${placeholderColor};`}
    }

    &:focus {
      ${({ colorOnFocus }) => colorOnFocus && `color: ${colorOnFocus};`}
      ${({ focusOutline }) => focusOutline && `border: ${focusOutline};`}
    }
  `;

  const WrappedComponent = forwardRef((props, ref) => {
    return (
      <UpdatedComponent
        {...props}
        ref={ref}
        className={`${props.fontSize} ${props.className}`}
      />
    );
  });

  WrappedComponent.displayName = Component.displayName;

  return WrappedComponent;
};

export default withLegacySupport(forwardRef(TextField));
