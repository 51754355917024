import styled from 'styled-components';
import Viewer from './Viewer';

const ImageViewer = ({ name, size, url, onClose }) => {
  return (
    <StyledViewer
      name={name}
      size={size}
      url={url}
      enableFullscreen
      onClose={onClose}
    >
      <Image src={url} alt={name} />
    </StyledViewer>
  );
};

export default ImageViewer;

const StyledViewer = styled(Viewer)`
  display: flex;
  justify-content: center;
  justify-items: center;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
`;
