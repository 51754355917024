export const MaleIcon = ({ size = 1 }) => {
  return (
    <svg
      width={14 * size}
      height={15 * size}
      viewBox="0 0 14 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.9341 1.01046C13.8506 0.809281 13.6907 0.649412 13.4895 0.565866C13.3906 0.523678 13.2843 0.501301 13.1767 0.5H9.88339C9.66503 0.5 9.45562 0.586743 9.30122 0.741145C9.14681 0.895548 9.06007 1.10496 9.06007 1.32332C9.06007 1.54168 9.14681 1.7511 9.30122 1.9055C9.45562 2.0599 9.66503 2.14664 9.88339 2.14664H11.1925L9.2165 4.12262C8.04979 3.24944 6.5956 2.85066 5.14669 3.00657C3.69777 3.16247 2.36171 3.86148 1.40746 4.96287C0.453208 6.06427 -0.048375 7.48627 0.00368158 8.94262C0.0557382 10.399 0.657569 11.7815 1.68802 12.812C2.71847 13.8424 4.10103 14.4443 5.55738 14.4963C7.01373 14.5484 8.43573 14.0468 9.53713 13.0925C10.6385 12.1383 11.3375 10.8022 11.4934 9.35331C11.6493 7.9044 11.2506 6.45022 10.3774 5.2835L12.3534 3.30753V4.61661C12.3534 4.83497 12.4401 5.04438 12.5945 5.19879C12.7489 5.35319 12.9583 5.43993 13.1767 5.43993C13.395 5.43993 13.6045 5.35319 13.7589 5.19879C13.9133 5.04438 14 4.83497 14 4.61661V1.32332C13.9987 1.21573 13.9763 1.10944 13.9341 1.01046ZM8.64841 11.6148C8.07602 12.1988 7.3427 12.5991 6.54193 12.7646C5.74117 12.9301 4.90925 12.8534 4.15224 12.5443C3.39523 12.2352 2.74744 11.7076 2.29146 11.0288C1.83547 10.3501 1.59195 9.55091 1.59195 8.73322C1.59195 7.91552 1.83547 7.11635 2.29146 6.4376C2.74744 5.75885 3.39523 5.23127 4.15224 4.92214C4.90925 4.613 5.74117 4.53631 6.54193 4.70185C7.3427 4.86738 8.07602 5.26764 8.64841 5.85159C9.40268 6.6211 9.82517 7.65568 9.82517 8.73322C9.82517 9.81075 9.40268 10.8453 8.64841 11.6148Z" />
    </svg>
  );
};
