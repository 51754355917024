export const AdIcon = ({ size = 1 }) => (
  <svg
    width={size * 20}
    height={size * 18}
    viewBox="0 0 20 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.76024 14.1781H0.5L4.68459 3.68164H6.95882L11.1364 14.1781H8.87618L8.01547 11.9249H3.62095L2.76024 14.1781ZM4.32771 10.0355H7.3157L5.81821 6.14481L4.32771 10.0355Z" />
    <path d="M19.5 14.1781H17.7366L17.6316 13.2264C17.3331 13.5623 16.9739 13.8282 16.554 14.0242C16.1388 14.2201 15.684 14.3181 15.1895 14.3181C14.5503 14.3181 13.9789 14.1618 13.475 13.8492C12.9759 13.532 12.5817 13.0981 12.2924 12.5477C12.0032 11.9972 11.8586 11.3744 11.8586 10.6793C11.8586 9.97952 12.0032 9.35673 12.2924 8.81091C12.5817 8.26043 12.9759 7.82891 13.475 7.51635C13.9789 7.19912 14.5503 7.04051 15.1895 7.04051C15.6466 7.04051 16.0712 7.12448 16.463 7.29242C16.8596 7.4557 17.2024 7.68429 17.4917 7.97819V3.68164H19.5V14.1781ZM15.7073 12.5197C16.0385 12.5197 16.3371 12.445 16.603 12.2957C16.8736 12.1465 17.0881 11.9435 17.2468 11.6869C17.41 11.4257 17.4917 11.1295 17.4917 10.7982V10.5463C17.4917 10.2151 17.41 9.92121 17.2468 9.66463C17.0881 9.40805 16.8736 9.20745 16.603 9.06283C16.3371 8.91355 16.0385 8.8389 15.7073 8.8389C15.3667 8.8389 15.0612 8.91821 14.7906 9.07682C14.52 9.23544 14.3054 9.4547 14.1468 9.7346C13.9929 10.0098 13.9159 10.3247 13.9159 10.6793C13.9159 11.0292 13.9929 11.3441 14.1468 11.624C14.3054 11.8992 14.52 12.1185 14.7906 12.2817C15.0612 12.4404 15.3667 12.5197 15.7073 12.5197Z" />
  </svg>
);
