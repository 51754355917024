import { useProfileState } from '../../common/ProfileContext';
import { useEditManager } from '.';
import { PackagesProvider } from 'contexts/PackagesContext';
import PackagesCard from 'components/Basics/PackagesCard';
import styled from 'styled-components';
import { useEffect } from 'react';

const PreviewModal = ({ show, onClose }) => {
  const { platform, user } = useProfileState();
  const { packages, selectedPackageType } = useEditManager();

  useEffect(() => {
    const handleEsc = (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  // lock scroll when modal is open
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  return (
    <PackagesProvider
      userId={user?.id}
      fixedPackages={packages}
      platform={platform}
      packageType={selectedPackageType}
    >
      {show && (
        <>
          <Backdrop className="hidden md-block" />
          <Container
            id="details-modal"
            className="hidden md-block"
            onClick={() => {
              onClose();
            }}
          >
            <ModalWrapper>
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <PackagesCard variant={PackagesCard.Variant.Preview} />
              </div>
            </ModalWrapper>
          </Container>
        </>
      )}
    </PackagesProvider>
  );
};

export default PreviewModal;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1010;

  animation: fadeIn 200ms ease-in-out;
  margin: 0;
`;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
  animation: fadeIn 250ms ease-in-out;
  z-index: 1015;
`;

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 2rem;
  min-height: 100%;
`;
