import { $isTextNode, TextNode } from 'lexical';

export class ExtendedTextNode extends TextNode {
  static getType() {
    return 'extended-text';
  }

  static clone(node) {
    return new ExtendedTextNode(node.__text, node.__key);
  }

  static importDOM() {
    const importers = TextNode.importDOM();
    return {
      ...importers,
      mark: () => ({
        conversion: convertHighlightElement,
        priority: 1,
      }),
    };
  }

  static importJSON(serializedNode) {
    return TextNode.importJSON(serializedNode);
  }
}

function convertHighlightElement() {
  const format = 'highlight';
  if (format === undefined) {
    return { node: null };
  }
  return {
    forChild: (lexicalNode) => {
      if ($isTextNode(lexicalNode) && !lexicalNode.hasFormat(format)) {
        lexicalNode.toggleFormat(format);
      }

      return lexicalNode;
    },
    node: null,
  };
}
