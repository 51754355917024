import styled from 'styled-components';
import Variant from './packagesCardVariant';
import TopBar from './TopBar';
import TitleBar from './TitleBar';
import ContentSection from './ContentSection';
import DetailsSection from './DetailsSection';
import LoadingOverlay from './LoadingOverlay';
import Platform from 'constants/platform';
import { usePackages } from 'contexts/PackagesContext';
import { useMemo } from 'react';

const PackagesCard = ({
  leftAction,
  featuredImageAction,
  variant = Variant.Modal,
}) => {
  const { platform, currentPackage } = usePackages();

  // details box have 2x2 layout for instagram and tiktok and when there are sample contents
  const isTwoByTwo = useMemo(() => {
    return (
      (platform === Platform.Instagram || platform === Platform.TikTok) &&
      currentPackage?.sampleContents.length > 0
    );
  }, [platform, currentPackage?.sampleContents]);

  return (
    <Card variant={variant} className="hidden md-block">
      <TopBar leftAction={leftAction} variant={variant} />

      <TitleBar variant={variant} />

      <ContentSection
        variant={variant}
        featuredImageAction={featuredImageAction}
      />

      {!isTwoByTwo && <DetailsSection variant={variant} />}

      <LoadingOverlay />
    </Card>
  );
};

PackagesCard.Variant = Variant;

export default PackagesCard;

const Card = styled.div`
  width: 1000px;
  padding: 2.25rem 2.5rem;
  background-color: #fff;
  border-radius: 1.25rem;
  box-shadow: ${({ variant }) =>
    ({
      modal: 'var(--shadow-modal)',
      profile: 'var(--shadow-card-innocent)',
    }[variant])};
  position: relative;
`;
