import styled from 'styled-components';
const SpinnerLoading = ({
  size,
  thickness = 2,
  color = 'var(--color-indigo-500)',
}) => {
  return <Spinner size={size} thickness={thickness} color={color}></Spinner>;
};
export default SpinnerLoading;
const Spinner = styled.div`
  width: ${({ size }) => (size ? `${size}px` : '100%')};
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
  border-radius: 50%;
  border: ${({ thickness }) => `${thickness}px solid var(--color-indigo-50)`};
  border-top: ${({ thickness, color }) => `${thickness}px solid ${color}`};
  @keyframes rotate {
    0% {
      transform: rotateX(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }

  animation: rotate 1s linear infinite;
`;
