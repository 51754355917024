export const MessageSendIcon = ({ size = 1 }) => (
  <svg
    width={16 * size}
    height={16 * size}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.6704 5.85309L3.47044 0.251487C3.02843 0.0314315 2.52933 -0.0469099 2.04119 0.0271443C1.55304 0.101198 1.09961 0.324044 0.742705 0.665298C0.385803 1.00655 0.142802 1.44961 0.0468343 1.93406C-0.0491337 2.41851 0.00660119 2.92077 0.20644 3.37238L2.12644 7.66961C2.17001 7.77351 2.19244 7.88504 2.19244 7.99771C2.19244 8.11037 2.17001 8.22191 2.12644 8.3258L0.20644 12.623C0.0438002 12.9885 -0.0249553 13.3888 0.00642179 13.7877C0.0377989 14.1865 0.168314 14.5711 0.386106 14.9067C0.603897 15.2422 0.902061 15.5179 1.2535 15.7089C1.60494 15.8998 1.99851 15.9999 2.39844 16C2.77303 15.9963 3.14203 15.9088 3.47844 15.7439L14.6784 10.1423C15.0757 9.94241 15.4097 9.63602 15.643 9.25733C15.8763 8.87864 15.9999 8.44255 15.9999 7.99771C15.9999 7.55287 15.8763 7.11677 15.643 6.73808C15.4097 6.35939 15.0757 6.053 14.6784 5.85309H14.6704ZM13.9584 8.70991L2.75844 14.3115C2.61137 14.3822 2.44623 14.4061 2.28515 14.3802C2.12407 14.3543 1.97477 14.2798 1.85725 14.1666C1.73973 14.0534 1.65962 13.9069 1.62766 13.7469C1.5957 13.5869 1.61342 13.4209 1.67844 13.2712L3.59044 8.97399C3.61519 8.9166 3.63656 8.85782 3.65444 8.79794H9.16644C9.37861 8.79794 9.5821 8.71363 9.73213 8.56355C9.88215 8.41348 9.96644 8.20994 9.96644 7.99771C9.96644 7.78547 9.88215 7.58193 9.73213 7.43186C9.5821 7.28179 9.37861 7.19748 9.16644 7.19748H3.65444C3.63656 7.13759 3.61519 7.07881 3.59044 7.02143L1.67844 2.7242C1.61342 2.57452 1.5957 2.40854 1.62766 2.24851C1.65962 2.08848 1.73973 1.94205 1.85725 1.82886C1.97477 1.71566 2.12407 1.64111 2.28515 1.6152C2.44623 1.58929 2.61137 1.61326 2.75844 1.6839L13.9584 7.2855C14.0895 7.35266 14.1995 7.45468 14.2762 7.58034C14.353 7.70601 14.3937 7.85043 14.3937 7.99771C14.3937 8.14499 14.353 8.28941 14.2762 8.41507C14.1995 8.54073 14.0895 8.64276 13.9584 8.70991Z" />
  </svg>
);
