export const LocationPinIcon = ({ size = 1 }) => (
  <svg
    width={22 * size}
    height={23 * size}
    viewBox="0 0 22 23"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.417 2.81468C16.4221 1.01247 13.7165 -1.89894e-08 10.8952 0C8.07402 1.89894e-08 5.36835 1.01247 3.37344 2.81468C1.37854 4.6169 0.257813 7.06122 0.257813 9.60993C0.257812 12.1586 1.37854 14.603 3.37344 16.4052L9.98009 22.385C10.0966 22.4911 10.2353 22.5754 10.3881 22.6329C10.5408 22.6904 10.7047 22.72 10.8702 22.72C11.0357 22.72 11.1995 22.6904 11.3523 22.6329C11.5051 22.5754 11.6437 22.4911 11.7602 22.385L18.417 16.3486C20.4036 14.5538 21.5197 12.1197 21.5197 9.58162C21.5197 7.04353 20.4036 4.6094 18.417 2.81468ZM16.6243 14.729L10.8952 19.9274L5.16614 14.729C4.03429 13.7056 3.26372 12.4019 2.95184 10.9829C2.63996 9.56388 2.80077 8.09317 3.41394 6.7567C4.02711 5.42022 5.06512 4.27798 6.39674 3.47436C7.72837 2.67074 9.29383 2.24182 10.8952 2.24182C12.4967 2.24182 14.0621 2.67074 15.3937 3.47436C16.7254 4.27798 17.7634 5.42022 18.3765 6.7567C18.9897 8.09317 19.1505 9.56388 18.8386 10.9829C18.5268 12.4019 17.7562 13.7056 16.6243 14.729ZM7.13434 6.13303C6.1223 7.05013 5.55401 8.2923 5.55401 9.58728C5.55401 10.8823 6.1223 12.1244 7.13434 13.0415C7.88622 13.7219 8.84378 14.1866 9.88688 14.3773C10.93 14.5679 12.0122 14.476 12.9977 14.1131C13.9832 13.7502 14.8281 13.1325 15.4265 12.3374C16.0248 11.5423 16.35 10.6053 16.3611 9.64391C16.3667 9.00199 16.2299 8.36556 15.9585 7.77226C15.6872 7.17895 15.287 6.64081 14.7815 6.18966C14.2847 5.7305 13.6922 5.36464 13.0382 5.11314C12.3843 4.86163 11.6817 4.72944 10.971 4.72418C10.2602 4.71892 9.55536 4.84068 8.89692 5.08247C8.23847 5.32426 7.63946 5.68129 7.13434 6.13303ZM13.0139 11.4333C12.5388 11.8691 11.911 12.1422 11.2376 12.206C10.5643 12.2698 9.8874 12.1204 9.32257 11.7832C8.75774 11.446 8.34009 10.9421 8.14102 10.3575C7.94195 9.77293 7.97384 9.14405 8.23122 8.57836C8.4886 8.01268 8.9555 7.54533 9.55208 7.25621C10.1487 6.9671 10.8379 6.87417 11.5019 6.99333C12.166 7.11248 12.7635 7.43631 13.1925 7.90946C13.6215 8.3826 13.8553 8.97567 13.8538 9.58728C13.8356 10.2864 13.5109 10.9503 12.9512 11.4333H13.0139Z" />
  </svg>
);
