export const ThreeDBoxIcon = ({ size = 1 }) => (
  <svg
    width={size * 14}
    height={size * 16}
    viewBox="0 0 14 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.4943 4.45538C13.4943 4.45538 13.4943 4.45538 13.4943 4.39378L13.4481 4.2783C13.4321 4.25379 13.414 4.23062 13.3942 4.209C13.3736 4.17641 13.3505 4.14551 13.3249 4.11661L13.2556 4.06272L13.1325 4.00112L7.35802 0.436367C7.23565 0.359888 7.09425 0.319336 6.94995 0.319336C6.80566 0.319336 6.66426 0.359888 6.54189 0.436367L0.813646 4.00112L0.744353 4.06272L0.675059 4.11661C0.649537 4.14551 0.626366 4.17641 0.605766 4.209C0.585972 4.23062 0.567949 4.25379 0.551871 4.2783L0.505675 4.39378C0.505675 4.39378 0.505675 4.39378 0.505675 4.45538C0.498108 4.52189 0.498108 4.58905 0.505675 4.65556V11.3847C0.505414 11.5156 0.538502 11.6443 0.601817 11.7588C0.665132 11.8733 0.756585 11.9698 0.867541 12.0391L6.64198 15.6039C6.67753 15.6259 6.7166 15.6415 6.75747 15.6501C6.75747 15.6501 6.79597 15.6501 6.81907 15.6501C6.94932 15.6914 7.08917 15.6914 7.21943 15.6501C7.21943 15.6501 7.25792 15.6501 7.28102 15.6501C7.3219 15.6415 7.36097 15.6259 7.39651 15.6039L13.1325 12.0391C13.2434 11.9698 13.3349 11.8733 13.3982 11.7588C13.4615 11.6443 13.4946 11.5156 13.4943 11.3847V4.65556C13.5019 4.58905 13.5019 4.52189 13.4943 4.45538ZM6.20313 13.5713L1.96853 10.9536V6.04143L6.20313 8.65147V13.5713ZM6.97305 7.3195L2.66147 4.65556L6.97305 1.99932L11.2846 4.65556L6.97305 7.3195ZM11.9776 10.9536L7.74298 13.5713V8.65147L11.9776 6.04143V10.9536Z" />
  </svg>
);
