import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import styled, { keyframes } from 'styled-components';

function TypeWriter({
  placeholder,
  value,
  cursorBeforeTyping = true,
  cursorAfterTyping = true,
  duration = 2000,
  delay = 0,
  className = '',
  onTypingStart,
  onTypingEnd,
  cursorType = 'text', // text, block
  ...props
}) {
  const [active, setActive] = useState(cursorBeforeTyping);
  const [text, setText] = useState('');

  useEffect(() => {
    setActive(cursorBeforeTyping);
  }, [value, cursorBeforeTyping]);

  const spaces = useMemo(() => value.split(' ').length - 1, [value]);

  // stay after space 1.5 times longer than character
  const delayPerCharacter = useMemo(
    () => duration / (value.length + spaces * 1.5),
    [value, duration, spaces]
  );
  const delayPerSpace = useMemo(
    () => delayPerCharacter * 1.5,
    [delayPerCharacter]
  );

  const timeoutId = useRef(null);

  const typeWriter = useCallback(
    (text, i = -1) => {
      if (i < value.length) {
        setText(text.slice(0, i + 1));
        clearTimeout(timeoutId.current);

        timeoutId.current = setTimeout(
          () => {
            typeWriter(text, i + 1);
          },
          value[i] === ' ' ? delayPerSpace : delayPerCharacter
        );
      } else {
        if (!cursorAfterTyping) {
          setActive(false);
        }
        onTypingEnd?.();
      }
    },
    [value, delayPerCharacter, delayPerSpace, cursorAfterTyping, onTypingEnd]
  );

  useEffect(() => {
    clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(() => {
      onTypingStart?.();
      setActive(true);
      typeWriter(value);
    }, delay);
  }, [typeWriter, value, delay, onTypingStart]);

  return (
    <Container
      className={`${!text ? 'placeholder' : ''} ${className}`}
      {...props}
    >
      {text || placeholder}
      {active && (cursorType === 'block' ? <BlockCursor /> : <TextCursor />)}
    </Container>
  );
}

export default TypeWriter;

const blinkCursor = keyframes`
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
`;

const Cursor = styled.span`
  border-color: var(--color-indigo-500);
  display: inline;
  animation: ${blinkCursor} 1s infinite alternate;
`;

const TextCursor = styled(Cursor)`
  border-left-width: 2px;
  border-left-style: solid;
`;

const BlockCursor = styled(Cursor)`
  border-left-width: 12px;
  border-left-style: solid;
`;

const Container = styled.p`
  margin: 0;
  color: var(--color-indigo-500);

  &.placeholder {
    color: var(--color-indigo-100);
  }
`;
