//provide functions that communicate with server
import AxiosClient from 'components/utilities/AxiosClient';
import useUser from './useUser';
import socket from 'components/utilities/connectSocket';
//standalone
import { SignClient } from '@walletconnect/sign-client';
import { Web3Modal } from '@web3modal/standalone';
import { ethers } from 'ethers';

const web3Modal = new Web3Modal({
  projectId: '4e10000856e620104a9b693a7847e8d6', //process.env.WALLETCONNECT_PW,
  standaloneChains: ['eip155:5'], //eip155:5 --> Test eip155:1 --> eth mainnet
});

const extensionInstallation = async (walletType) => {
  var account = null;
  if (walletType === 'MetaMask') {
    window.open('https://metamask.io/download/', '_self').focus();
  } else if (walletType === 'CoinBase') {
    window
      .open(
        'https://chrome.google.com/webstore/detail/coinbase-wallet-extension/hnfanknocfeofbddgcijnmhnfnkdnaad/related?hl=en&authuser=0',
        '_self'
      )
      .focus();
  } else {
    // for wallet Connect
    const signClient = await createClient();
    account = await handleConnect(signClient);
    web3Modal.closeModal();
  }
};

const handleConnect = async (signClient) => {
  if (!signClient) throw Error('Cannot connect. Sign Client is not created');

  var walletAccount = '';

  try {
    //dapp is going to send a proposal namespace; essentially asking for a permission
    const proposalNamespace = {
      eip155: {
        chains: ['eip155:5'], //eip155:5
        methods: ['eth_sign', 'eth_sendTransaction'],
        events: ['connect', 'disconnect', 'accountChanged'],
      },
    };
    const { uri, approval } = await signClient.connect({
      requiredNamespaces: proposalNamespace,
    });

    //if uri exists
    if (uri) {
      web3Modal.openModal({ uri });
      //we will be waiting for a result of approval
      const sessionNamespace = await approval();
      walletAccount = await onSessionConnect(sessionNamespace);
    }
    return walletAccount;
  } catch (e) {
    console.log(e);
  }
};

const onSessionConnect = async (session) => {
  if (!session) throw Error('session does not exist');
  try {
    const account = session.namespaces.eip155.accounts[0].slice(9);

    return account;
  } catch (e) {
    console.log(e);
  }
};

const createClient = async () => {
  try {
    const client = await SignClient.init({
      projectId: '4e10000856e620104a9b693a7847e8d6', //process.env.WALLETCONNECT_PW
    });
    return client;
  } catch (e) {
    console.log(e);
  }
};

const login = async (options) => {
  const response = await AxiosClient.post('/login', options);
  return response;
};
const logout = async () => {
  const response = await AxiosClient.get('/logout');

  return response;
};

const loginById = async (id, bypass = false) => {
  const response = await AxiosClient.post('/loginById', {
    userId: id,
    pass: bypass,
  }).catch((e) => {
    throw e;
  });

  return response;
};

const useAuth = () => {
  const { clearUser, updateUser } = useUser();

  const _getWallet = async (type) => {
    let walletAddress, provider;
    provider = _getProvider(type);
    if (provider) {
      walletAddress = await provider.send('eth_requestAccounts', []);
      walletAddress = ethers.utils.getAddress(walletAddress[0]);
      return [walletAddress, provider, false];
    } else {
      return [null, null, true];
    }
  };

  //get wallet data : walletAddress, signed message,message
  const generateWalletSignupData = async (type) => {
    const [walletAddress, provider, redirectPluginExtension] = await _getWallet(
      type
    );

    if (redirectPluginExtension) {
      extensionInstallation(type);
      return;
    }
    const nonce = await _getNonce(walletAddress).catch((e) => {
      return {
        error: !e.response.data ? "Current user isn't signed up" : e.message,
        errorCode: e.response.status,
        walletAddress,
        nonce: e.response.data,
      };
    });

    const [message, signedMessage] = await _signMessage(
      walletAddress,
      nonce.error ? nonce.nonce : nonce,
      provider
    );
    return { message, signedMessage, walletAddress };
  };
  const signIn = async (options) => {
    let nonce;
    let address;
    if (options.loginType === 'wallet') {
      if (options.walletType === 'WalletConnect') {
      }
      const [walletAddress, provider, redirectPluginExtension] =
        await _getWallet(options.walletType);

      if (redirectPluginExtension) {
        extensionInstallation(options.walletType);
        return;
      }
      address = walletAddress;
      const res = await _getNonce(walletAddress).catch((e) => {
        return {
          error: !e.response.data ? "Current user isn't signed up" : e.message,
          errorCode: e.response.status,
          walletAddress,
          nonce: e.response.data,
        };
      });
      nonce = res.nonce ?? res;
      if (res.errorCode !== 301) {
        const [message, signedMessage] = await _signMessage(
          walletAddress,
          res.error ? nonce : res,
          provider
        );
        if (res.error) {
          res['message'] = message;
          res['signedMessage'] = signedMessage;
          return res;
        }

        if (
          !nonce ||
          !walletAddress ||
          !provider ||
          !message ||
          !signedMessage
        ) {
          throw new Error('Validation Error while Sign in');
        }
        options['signedMessage'] = signedMessage;
        options['message'] = message;
      } else {
        options['address'] = res.walletAddress;
      }
    }
    const result = await login(options).catch((e) => {
      return e;
    });

    if (result instanceof Error) {
      const errorResponse = {
        error: result,
        errorCode: result.response.status,
      };
      if (options.loginType === 'wallet' && address) {
        if (errorResponse.errorCode === 409) {
          errorResponse['userId'] = result.response.data.user.id;
          errorResponse['email'] = result.response.data.user.email;
        }
        return {
          ...errorResponse,
          walletAddress: address,
        };
      }
      return {
        error: result,
        errorCode: result.response.status,
      };
    }

    if (result.data.type) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          type: result.data.type,
          email: result.data.email,
          id: result.data.id,
        })
      );
    }
    updateUser(result);
    return result;
  };

  //clear user from stored user data
  const signOut = async (cb) => {
    clearUser();
    window.onstorage = null;
    localStorage.removeItem('user');
    await logout().catch((e) => console.log(e));
    if (cb) {
      cb();
    }
  };
  const signInById = async (id, bypass) => {
    const userData = await loginById(id, bypass).catch((e) => {
      throw e;
    });

    const data = {
      result: null,
      error: null,
    };
    data.result = userData;
    const localStorageUser = {
      type: userData.data.type,
      email: userData.data.email,
      id: userData.data.userId,
    };
    localStorage.setItem('user', JSON.stringify(localStorageUser));
    return userData;
  };

  return {
    signOut,
    signIn,
    signInById,
    generateWalletSignupData,
  };
};

export default useAuth;

async function _getNonce(walletAddress) {
  const response = await AxiosClient.get(`/user/${walletAddress}/nonce`).catch(
    (e) => {
      throw e;
    }
  );
  return response.data;
}
function _getProvider(type) {
  switch (type) {
    case 'MetaMask':
      if (
        !window.ethereum?.providers?.find((x) => x.isMetaMask) &&
        !window.ethereum?.isMetaMask
      ) {
        return null;
      }
      if (!window.ethereum.providers && window.ethereum.isMetaMask) {
        return new ethers.providers.Web3Provider(window.ethereum);
      }
      window.ethereum.setSelectedProvider(
        window.ethereum.providers.find((x) => x.isMetaMask)
      );
      return new ethers.providers.Web3Provider(
        window.ethereum.providers.find((x) => x.isMetaMask)
      );
    case 'CoinBase':
      if (
        !window.ethereum?.providers?.find((x) => x.isCoinbaseWallet) &&
        !window.ethereum?.isCoinbaseWallet
      ) {
        return null;
      }
      if (!window.ethereum.providers && window.ethereum.isCoinbaseWallet) {
        return new ethers.providers.Web3Provider(window.ethereum);
      }
      window.ethereum.setSelectedProvider(
        window.ethereum.providers.find((x) => x.isCoinbaseWallet)
      );
      return new ethers.providers.Web3Provider(
        window.ethereum.providers.find((x) => x.isCoinbaseWallet)
      );
    default:
      return null;
  }
}

async function _signMessage(address, nonce, provider) {
  const message = `
        Welcome to MegaEvolutuion! Click to sign in. This request will not trigger a blockchain transaction or cost any gas fees.
        Wallet address:${address}
        Nonce:${nonce}`;
  const signer = provider.getSigner();
  const signedMessage = await signer.signMessage(message);
  return [message, signedMessage];
}

// some of the logic is called in here and some of logic is called other

// parsing error message .. bunch

// hard to

// onside of function

// one sign up function simplify color caller

//make the caller not to do anyting except call

//every thing is done by down level

// client code we just have to reflect server response!!!

// local storage only shows user state,  react query should only be dependeny onlocalstorage

// lets not save .. object inside localstorage...

// but get userId // session id get user i
