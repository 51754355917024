export const XIcon = ({ size = 1 }) => (
  <svg
    width={size * 21}
    height={size * 21}
    viewBox="0 0 21 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.8871 8.93501L20.3325 0.280273H18.5682L12.1033 7.79506L6.93984 0.280273H0.984375L8.79258 11.644L0.984375 20.7198H2.74881L9.57589 12.7839L15.0289 20.7198H20.9844L12.8867 8.93501H12.8871ZM10.4705 11.7441L9.67933 10.6125L3.38456 1.60851H6.09462L11.1746 8.87502L11.9657 10.0066L18.569 19.4519H15.859L10.4705 11.7445V11.7441Z" />
  </svg>
);
