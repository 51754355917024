import AxiosClient from 'components/utilities/AxiosClient';

const submitVerification = async ({ verificationFile, ...data }) => {
  const formData = new FormData();
  if (verificationFile) {
    formData.append('verificationFile', verificationFile, encodeURIComponent(verificationFile?.name));
  }

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key]);
    }
  }

  const res = await AxiosClient.post('/clientVerification', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return res.data;
};

export default submitVerification;
