import FilterKeys from '../translationKeys/FilterKeys';

export const FilterTranslations = {
  // Influencer Marketing Home Page
  [FilterKeys.home.search_keywords]: 'Palabras clave de búsqueda',

  [FilterKeys.home.expand]: 'Expandir',
  [FilterKeys.home.collapse]: 'Colapsar',

  [FilterKeys.home.more_filter]: '＋ Más filtros',
  [FilterKeys.home.less_filter]: '– Menos filtros',

  [FilterKeys.home.try_searching]: 'Prueba a buscar',
  [FilterKeys.home.results_for_keyword]:
    '{{number}} resultados para "{{searchText}}"',
  [FilterKeys.home.result_for_keyword]:
    '{{number}} resultado para "{{searchText}}"',

  // Try Searching
  [FilterKeys.home.Try_Searching.brand_ambassador]: 'Embajador de marca',
  [FilterKeys.home.Try_Searching.live_shows]: 'Shows en vivo',
  [FilterKeys.home.Try_Searching.product_review]: 'Reseña de producto',
  [FilterKeys.home.Try_Searching.game_review]: 'Reseña del juego',

  // Sort By
  [FilterKeys.home.sort_by.suggested]: 'Sugerido',
  [FilterKeys.home.sort_by.by_subscribers]: 'Suscriptores',
  [FilterKeys.home.sort_by.lowest_price]: 'Precio más bajo',
  [FilterKeys.home.sort_by.highest_price]: 'Precio más alto',

  // Country
  [FilterKeys.home.country.popular]: 'Países Populares',
  [FilterKeys.home.country.selected]: 'Países Seleccionados',
  [FilterKeys.home.country.no_result]: 'No se encontraron resultados',

  // Price
  [FilterKeys.home.price._50]: '~$50',
  [FilterKeys.home.price._50_100]: '$50~100',
  [FilterKeys.home.price._100_300]: '$100~300',
  [FilterKeys.home.price._300_500]: '$300~500',
  [FilterKeys.home.price._500_1k]: '$500~1K',
  [FilterKeys.home.price._1k_2k]: '$1K~2K',
  [FilterKeys.home.price._2k_5k]: '$2K~5K',
  [FilterKeys.home.price._5k_]: '$5K+',

  // Subscribers
  [FilterKeys.home.subs_and_views.tooltip_subs]:
    'El número de suscriptores o seguidores del usuario',
  [FilterKeys.home.subs_and_views.tooltip_views]: `
    El número promedio de vistas o impresiones <br>
    que puedes esperar por publicación en 180 días`,

  [FilterKeys.home.subs_and_views.private]: 'Privado',
  [FilterKeys.home.subs_and_views._10k]: '~10K',
  [FilterKeys.home.subs_and_views._10k_100k]: '10K~100K',
  [FilterKeys.home.subs_and_views._100k_1m]: '100K~1M',
  [FilterKeys.home.subs_and_views._1m_]: '1M+',

  // Gender
  [FilterKeys.home.gender.male]: 'Masculino',
  [FilterKeys.home.gender.female]: 'Femenino',

  // Mobile
  [FilterKeys.home.M.filter_by]: 'Filtrar por:',
  [FilterKeys.home.M.all_categories]: 'Todas las Categorías',
  [FilterKeys.home.M.all_price_ranges]: 'Todos los Rangos de Precio',
};
