export const IndonesiaFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29896_35078)">
      <rect
        x="0.128418"
        y="0.064209"
        width="33"
        height="33"
        rx="16.5"
        fill="#F5F5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.128571 0.064209H33.1286V17.6642H0.128571V0.064209Z"
        fill="#F93939"
      />
    </g>
    <defs>
      <clipPath id="clip0_29896_35078">
        <rect
          x="0.128418"
          y="0.064209"
          width="33"
          height="33"
          rx="16.5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
