import PricingKeys from '../translationKeys/PricingPageKeys';

export const PricingTranslations = {
  [PricingKeys.preparing]: '(Preparing)',
  [PricingKeys.order_now]: 'Order now',
  [PricingKeys.get_free]: 'Get Free Premium Pass',
  [PricingKeys.get_for_free]: 'Get it for free',
  [PricingKeys.current_plan]: 'Current plan',
  [PricingKeys.company_verified]: 'Company verified',

  // Banner
  [PricingKeys.Banner.title]: 'Make your marketing simple & smart!',
  [PricingKeys.Banner.description]:
    'Our plans help boost your marketing performance to the next level!',

  [PricingKeys.Verified.Error.already_verified]: `
    Your account is already verified as a brand · company.`,
  [PricingKeys.Verified.Error.already_using]: `
    Your account is already using this plan. <br>
    <span>Click here</span> to view your subscriptions.`,
  [PricingKeys.Verified.Error.under_review]: `
    Your account is under a review <br>
    Please try again later.`,
  [PricingKeys.Verified.Error.recently_rejected]: `
    Your application was recently reviewed.<br>
    Please try again later.`,

  // Left Col
  // General
  [PricingKeys.Left_Col.General.verification_req]: 'Verification Required',
  [PricingKeys.Left_Col.General.smart_search]: 'Smart Search',

  // Analytics & Data
  [PricingKeys.Left_Col.Analytics_Data.title]: 'Analytics & Data',

  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title]: `
    Creator Basic Analytics`,
  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip]: `
    This includes channel and content analytics <br>
    of the creator's social network accounts.`,

  [PricingKeys.Left_Col.Analytics_Data.audience_analytics]:
    'Audience Analytics',

  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title]: 'Price Unlock',
  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip]: `
    You can unlock the price of the creator’s <br>
    services using this monthly quota. Prices <br>
    will re-locked after 72 hours upon unlock.`,

  [PricingKeys.Left_Col.Analytics_Data.order_analytics]: 'Order Analytics',

  [PricingKeys.Left_Col.Analytics_Data.creator_reviews]: 'Creator Reviews',

  // Orders
  [PricingKeys.Left_Col.Orders.title]: `Orders & Creators`,
  [PricingKeys.Left_Col.Orders.order_packages]: 'Order Creator Packages',

  [PricingKeys.Left_Col.Orders.message_creators]:
    'Message new creators before starting an order',

  [PricingKeys.Left_Col.Orders.Order_Discount.title]: 'Order Discount',
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1]: `
    The discount is applied to the total price <br>
    of each order with creators. <br>
    * Does not apply to add-on items, delivery <br>
    cost, and monthly subscriptions.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2]: `
    The discount is applied to the total <br>
    price of each order with creators. <br>
    Does not apply to some add-on items <br>
    or platform subscriptions.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.content]: `
    <Tooltip><span>{{number}}</span></Tooltip>  of the order price`,

  [PricingKeys.Left_Col.Orders.Track_Delivery.title]: `
    Track International <br>
    Delivery`,
  [PricingKeys.Left_Col.Orders.Track_Delivery.tooltip]: `
    We help track international deliveries for orders. <br>
    Domestic delivery tracking is available to limited <br>
    countries. <br><br>
    * Note: We do not conduct or guarantee actual <br>
    shipping process.`,

  [PricingKeys.Left_Col.Orders.auto_translation]:
    'Full Auto-translation System',

  [PricingKeys.Summary.basic]: `
    <li>Free but powerful</li>
    <li>Everything but the kitchen sink</li>`,
  [PricingKeys.Summary.verified]: `
    <li>Get verified in just 1~3 minutes</li>
    <li>Great for global brands and business</li>`,
  [PricingKeys.Summary.premium]: `
    <li>Large Capacity</li>
    <li>Verification required</li>
    <li>Great for global firms focused on scaling</li>`,

  // Creator
  [PricingKeys.Creator.title]: 'Premium Pass for Early Creators',

  [PricingKeys.Creator.h1]:
    'With a premium pass, creators are exempt from MegaEvolution service fees.',
  [PricingKeys.Creator.h2]:
    'Please use our platform to fully share your talent with the world!',
  [PricingKeys.Creator.h3]: `
    * We do not cover the small amount of third-party transaction fees <br>
    (e.g. Paypal) needed for withdrawing your balance.`,

  // Modal
  [PricingKeys.Modal.title]: 'Brand · Company Verification',
  [PricingKeys.Modal.estimate]: 'Est. 1~2 minute',
  [PricingKeys.Modal.caution]:
    '* False or incorrect information may lead to verification loss or permanent account ban.',
  [PricingKeys.Modal.toast]: 'Your account was successfully verified!',

  [PricingKeys.Modal.Btn.proceed_final]: 'Proceed to final step',
  [PricingKeys.Modal.Btn.verify_email]: 'Verify Email',
  [PricingKeys.Modal.Btn.no_company_email]: `I don't have a company email`,
  [PricingKeys.Modal.Btn.verify_with_email]: 'Verify with Email',
  [PricingKeys.Modal.Btn.complete_verification]: 'Complete Verification',
  [PricingKeys.Modal.Btn.another_email]: 'Use another email',
  [PricingKeys.Modal.Btn.resend_code]: 'Resend Code',

  [PricingKeys.Modal.Placeholder.firstname]: 'Please enter your first name',
  [PricingKeys.Modal.Placeholder.lastname]: 'Please enter your last name',
  [PricingKeys.Modal.Placeholder.companyname]: 'Enter the company name',
  [PricingKeys.Modal.Placeholder.companyUrl]:
    'Enter company website URL (ex: www.megaevolution.io)',
  [PricingKeys.Modal.Placeholder.companyUrl_sm]: 'Enter company website URL',
  [PricingKeys.Modal.Placeholder.companyEmail]:
    'Please enter your company email',
  [PricingKeys.Modal.Placeholder
    .enter_code]: `Please enter the code you recieved`,

  [PricingKeys.Modal.CompanyType.title]: 'Select a company type',
  [PricingKeys.Modal.CompanyType.option_1]: 'Brand · Business',
  [PricingKeys.Modal.CompanyType.option_2]: 'E-commerce Store',
  [PricingKeys.Modal.CompanyType.option_3]: 'Marketing Agency',
  [PricingKeys.Modal.CompanyType.option_4]: 'Others',

  [PricingKeys.Modal.JobPosition.title]: 'Select a job position',
  [PricingKeys.Modal.JobPosition.option_1]: 'Assistant',
  [PricingKeys.Modal.JobPosition.option_2]: 'C-Level · VP',
  [PricingKeys.Modal.JobPosition.option_3]: 'Director',
  [PricingKeys.Modal.JobPosition.option_4]: 'Executive',
  [PricingKeys.Modal.JobPosition.option_5]: 'Head of Department',
  [PricingKeys.Modal.JobPosition.option_6]: 'Independent',
  [PricingKeys.Modal.JobPosition.option_7]: 'Manager · Team Lead',
  [PricingKeys.Modal.JobPosition.option_8]: 'Intern',
  [PricingKeys.Modal.JobPosition.option_9]: 'Team Member',

  // Final
  [PricingKeys.Modal.Final.explain]: `
    <li>Please upload one of the following files for verification: business registration certificate, Tax Invoice, employee ID card, or other supporting documents that verify your company’s existence.</li>
    <li>The file must be in JPG, JPEG, PNG, PDF format with a resolution of at least 300dpi.</li>`,
  [PricingKeys.Modal.Final.image_placeholder]:
    'Upload a file in JPG, JPEG, PNG, PDF format. Max 5 MB.',
  [PricingKeys.Modal.Final.description_placeholder]:
    'Please tell us about your company and what product, service, or brand you are trying to promote through the platform.',
  [PricingKeys.Modal.Final.spam_explain]:
    '* If you can’t find it, please look in your spam folder.',

  // Popup
  [PricingKeys.Modal.Popup.title]: 'Your form was successfully submitted!',
  [PricingKeys.Modal.Popup.description]:
    'Verification typically takes 1~3 business days. You will receive an email notification with the results after review.',

  [PricingKeys.Modal.submission
    .toast]: `Successfully submitted! Verification typically takes 1-3<br> business days. We'll notify you with the results soon.`,

  // Error
  [PricingKeys.Modal.Error.required_field]: 'Required field',
  [PricingKeys.Modal.Error.select_option]: 'Please select an option',
  [PricingKeys.Modal.Error.valid_url]: 'Enter a valid URL.',
  [PricingKeys.Modal.Error.valid_email]: 'Enter a valid email',
  [PricingKeys.Modal.Error.email_validation]:
    'Personal emails cannot be used (e.g. gmail.com, yahoo.com, etc. are not allowed)',
  [PricingKeys.Modal.Error.already_verified]:
    'This email was already used for verification',
  [PricingKeys.Modal.Error.already_sent_code]:
    'The code was already sent to this email. Please check your inbox.',
  [PricingKeys.Modal.Error.invalid_code]: 'Invalid code',
  [PricingKeys.Modal.Error.invalid_file_format]: `
    Invalid file format. (Please use {{fileFormats}})`,
  [PricingKeys.Modal.Error.total_file_size]: `
    Total file size limit is max {{maxSize}}`,
  [PricingKeys.Modal.Error.send_max_files]: `
    You can send max {{maxFiles}} files`,
};
