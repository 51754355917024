export const GoogleIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 18}
      height={size * 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9999 9.20519C17.9999 8.567 17.9415 7.95336 17.8329 7.36426H9.18359V10.8457H14.1261C13.9132 11.9707 13.2662 12.9239 12.2935 13.5621V15.8203H15.2615C16.9981 14.2534 17.9999 11.9461 17.9999 9.20519Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18392 18.0001C11.6635 18.0001 13.7424 17.1941 15.2618 15.8196L12.2938 13.5614C11.4715 14.1014 10.4195 14.4205 9.18392 14.4205C6.79199 14.4205 4.7674 12.8373 4.04523 10.71H0.977051V13.0418C2.48818 15.9832 5.59393 18.0001 9.18392 18.0001Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.04499 10.7099C3.86132 10.1699 3.75696 9.59307 3.75696 8.99988C3.75696 8.40669 3.86132 7.82986 4.04499 7.28986V4.95801H0.976809C0.354824 6.17302 0 7.54759 0 8.99988C0 10.4522 0.354824 11.8267 0.976809 13.0417L4.04499 10.7099Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.18379 3.57959C10.5321 3.57959 11.7427 4.03369 12.6945 4.92552L15.3285 2.34412C13.7381 0.89183 11.6592 0 9.18379 0C5.59381 0 2.48806 2.01684 0.976929 4.95825L4.04511 7.29009C4.76728 5.16279 6.79186 3.57959 9.18379 3.57959Z"
        fill="#EA4335"
      />
    </svg>
  );
};
