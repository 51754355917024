export const HeartIcon = ({ size = 1 }) => (
  <svg
    width={23 * size}
    height={21 * size}
    viewBox="0 0 23 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20.4943 2.80933C19.3279 1.63996 17.7819 0.926241 16.1353 0.797101C14.4888 0.667961 12.8504 1.13191 11.516 2.10515C10.1161 1.06388 8.37359 0.591724 6.63947 0.783752C4.90535 0.975779 3.30839 1.81773 2.17019 3.14005C1.03199 4.46238 0.437082 6.16685 0.505276 7.91024C0.57347 9.65362 1.2997 11.3064 2.53771 12.5358L9.37045 19.3795C9.94261 19.9426 10.7132 20.2582 11.516 20.2582C12.3188 20.2582 13.0894 19.9426 13.6615 19.3795L20.4943 12.5358C21.7789 11.2433 22.5 9.49493 22.5 7.67256C22.5 5.8502 21.7789 4.10186 20.4943 2.80933ZM18.9429 11.0174L12.1101 17.8501C12.0324 17.9287 11.9399 17.991 11.8379 18.0335C11.7359 18.076 11.6265 18.0979 11.516 18.0979C11.4055 18.0979 11.2961 18.076 11.1941 18.0335C11.0921 17.991 10.9996 17.9287 10.9218 17.8501L4.08911 10.9844C3.22622 10.1024 2.74304 8.91749 2.74304 7.68357C2.74304 6.44964 3.22622 5.26477 4.08911 4.38273C4.9684 3.51459 6.1543 3.02781 7.38994 3.02781C8.62559 3.02781 9.81149 3.51459 10.6908 4.38273C10.7931 4.48585 10.9148 4.56771 11.0488 4.62357C11.1829 4.67943 11.3267 4.70819 11.472 4.70819C11.6172 4.70819 11.761 4.67943 11.8951 4.62357C12.0292 4.56771 12.1509 4.48585 12.2532 4.38273C13.1325 3.51459 14.3184 3.02781 15.554 3.02781C16.7897 3.02781 17.9756 3.51459 18.8549 4.38273C19.7296 5.25321 20.2287 6.43166 20.2451 7.66561C20.2616 8.89957 19.7941 10.0909 18.9429 10.9844V11.0174Z" />
  </svg>
);
