export const AlternateIcon = ({ size = 1 }) => (
  <svg
    width={size * 12}
    height={size * 10}
    viewBox="0 0 12 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.60065 3.80007C9.60065 3.64103 9.53743 3.4885 9.42489 3.37603C9.31234 3.26357 9.1597 3.20039 9.00054 3.20039H2.04528L3.42553 1.82711C3.53854 1.71419 3.60202 1.56103 3.60202 1.40133C3.60202 1.24164 3.53854 1.08848 3.42553 0.975557C3.31253 0.862634 3.15927 0.799194 2.99946 0.799194C2.83965 0.799194 2.68638 0.862634 2.57338 0.975557L0.172943 3.3743C0.0896735 3.45863 0.0332655 3.56572 0.010837 3.68205C-0.0115916 3.79838 0.000964313 3.91875 0.0469203 4.02795C0.0919405 4.13747 0.168393 4.23122 0.266643 4.29738C0.364893 4.36355 0.480544 4.39917 0.59902 4.39976H9.00054C9.1597 4.39976 9.31234 4.33658 9.42489 4.22412C9.53743 4.11165 9.60065 3.95912 9.60065 3.80007ZM11.9531 5.97093C11.9081 5.86142 11.8316 5.76767 11.7334 5.70151C11.6351 5.63534 11.5195 5.59972 11.401 5.59913H2.99946C2.8403 5.59913 2.68766 5.66231 2.57512 5.77477C2.46257 5.88724 2.39935 6.03977 2.39935 6.19881C2.39935 6.35786 2.46257 6.51039 2.57512 6.62286C2.68766 6.73532 2.8403 6.7985 2.99946 6.7985H9.95472L8.57447 8.17178C8.51822 8.22753 8.47357 8.29385 8.44311 8.36693C8.41264 8.44001 8.39696 8.51839 8.39696 8.59756C8.39696 8.67672 8.41264 8.7551 8.44311 8.82818C8.47357 8.90126 8.51822 8.96758 8.57447 9.02333C8.63026 9.07954 8.69663 9.12415 8.76976 9.1546C8.84289 9.18504 8.92132 9.20072 9.00054 9.20072C9.07977 9.20072 9.1582 9.18504 9.23133 9.1546C9.30446 9.12415 9.37083 9.07954 9.42662 9.02333L11.8271 6.62459C11.9103 6.54026 11.9667 6.43317 11.9892 6.31684C12.0116 6.20051 11.999 6.08014 11.9531 5.97093Z" />
  </svg>
);
