export const ChevronDownSolidIcon = ({ size = 1 }) => (
  <svg
    width={12 * size}
    height={6 * size}
    viewBox="0 0 12 6"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0L6 6L12 0H0Z" />
  </svg>
);

export default ChevronDownSolidIcon;
