export const CheckIcon = ({ size = 1 }) => (
  <svg
    width={19 * size}
    height={16 * size}
    viewBox="0 0 19 16"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="1.06066" y1="8.62391" x2="6.71751" y2="14.2808" strokeWidth="3" />
    <line x1="5.25575" y1="14.3085" x2="17.6251" y2="1.93916" strokeWidth="3" />
  </svg>
);
