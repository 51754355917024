import styled from 'styled-components';
import Heading from '../../../Typography/Heading';
import { useNavigate } from 'react-router-dom';
import { useOfficialLaunchModal } from 'contexts/OfficialLaunchModal';

const MenuList = ({ title, items, width }) => {
  const navigate = useNavigate();
  const openOfficialLaunchModal = useOfficialLaunchModal();

  return (
    <Wrapper width={width}>
      <Heading variant="BH3">{title}</Heading>
      {items.map((item, index) => (
        <Item
          key={index}
          onClick={() => {
            if (item.href.startsWith('http')) {
              window.open(item.href, item.target || '_self');
            }
            else if (item.text === 'Help Desk') {
              openOfficialLaunchModal();
            }
             else {
              navigate(item.href, {
                state: item.state || {},
              });
            }
          }}
        >
          {item.text}
        </Item>
      ))}
    </Wrapper>
  );
};

export default MenuList;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.25rem;
  min-width: ${({ width }) => width || 'auto'};
  max-width: ${({ width }) => width || 'auto'};
`;

const Item = styled.button.attrs({
  className: 'h7',
})`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  text-align: left;

  &:focus {
    outline: none;
  }

  &:hover {
    font-weight: 600;
    color: var(--color-indigo-500);
  }
`;
