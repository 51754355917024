export const BillingIcon = ({ size = 1, className, ...rest }) => {
  return (
    <svg
      width={16 * size}
      height={14 * size}
      viewBox="0 0 16 14"
      className={className}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M14.4 0.600098H1.6C0.712 0.600098 0.00799999 1.3121 0.00799999 2.2001L0 11.8001C0 12.6881 0.712 13.4001 1.6 13.4001H14.4C15.288 13.4001 16 12.6881 16 11.8001V2.2001C16 1.3121 15.288 0.600098 14.4 0.600098ZM14.4 11.8001H1.6V7.0001H14.4V11.8001ZM14.4 3.8001H1.6V2.2001H14.4V3.8001Z" />
    </svg>
  );
};
