import MessageKeys from '../translationKeys/MessageKeys';

export const MessageTranslations = {
  // Dropdown Messages
  [MessageKeys.Dropdown.title]: 'Hộp thư đến',
  [MessageKeys.Dropdown.no_message]: 'Không có tin nhắn',
  [MessageKeys.Dropdown.bottom_btn]: 'Xem tất cả tin nhắn',

  // Chat Box Messages
  [MessageKeys.ChatBox.title]: 'Chào mừng đến với hộp thư của bạn!',
  [MessageKeys.ChatBox.no_chat]: `
    Dường như không có cuộc trò chuyện nào đã bắt đầu. <br>
    Nhưng đừng lo! Khi bạn bắt đầu một cuộc trò chuyện, bạn sẽ tìm thấy chúng ở đây.`,
  [MessageKeys.ChatBox.no_selected_chat]:
    'Nhấn vào cuộc trò chuyện để bắt đầu nhắn tin!',
  [MessageKeys.ChatBox.new_chat]: `
    Bắt đầu cuộc trò chuyện và kết nối với {{user}} để thảo luận về chi tiết dự án của bạn. <br>
    <span>Gửi một đề xuất để chia sẻ thông tin tất cả trong một nơi.</span>`,
  [MessageKeys.ChatBox.search]: `
    <h6>Không tìm thấy kết quả</h6>
    <p>Vui lòng thử từ khóa khác.</p>`,

  [MessageKeys.ChatBox.Message.me]: 'Tôi',
  [MessageKeys.ChatBox.Message.copy]: 'Sao chép tin nhắn',
  [MessageKeys.ChatBox.Message.report]: 'Báo cáo tin nhắn',
  [MessageKeys.ChatBox.Message.download_file]: 'Tải xuống tất cả tập tin',

  [MessageKeys.ChatBox.Message.typing]: 'Đang gõ...',
  [MessageKeys.ChatBox.Message.Offer_Package.text]:
    'Đây là gói dịch vụ tùy chỉnh dành cho bạn.',
  [MessageKeys.ChatBox.Message.Offer_Package.description]:
    'Đơn hàng sẽ bắt đầu khi bạn chấp nhận đề xuất của khách hàng.',
  [MessageKeys.ChatBox.Message.Offer_Package.btn]: 'Tiếp tục thanh toán',

  [MessageKeys.ChatBox.Message.Select_Package.title]: 'Chọn gói',
  [MessageKeys.ChatBox.Message.Select_Package.btn]: 'Tạo một gói mới',
  [MessageKeys.ChatBox.Message.Select_Package.complete_package_first]:
    'Hoàn thành gói đầu tiên của bạn để bắt đầu gửi một đề nghị!',
  [MessageKeys.ChatBox.Message.Select_Package.no_price]: 'Không có giá',
  [MessageKeys.ChatBox.Message.Select_Package.verified_only]:
    'Chỉ người dùng đã xác minh',

  [MessageKeys.ChatBox.Message.Custom_Package.title]: 'Gói tùy chỉnh',
  [MessageKeys.ChatBox.Message.Custom_Package.description]:
    'Giá hiển thị cho người mua sẽ bao gồm khoản phí dịch vụ khoảng {{number}}%.',
  [MessageKeys.ChatBox.Message.Custom_Package.offering]: 'Đang đề xuất...',
  [MessageKeys.ChatBox.Message.Custom_Package.btn1]: 'Đề xuất gói',
  [MessageKeys.ChatBox.Message.Custom_Package.btn2]: 'Quay lại Chọn',

  [MessageKeys.ChatBox.Message.Request_Price.text]:
    'Tôi muốn yêu cầu giá của gói dịch vụ của bạn.',
  [MessageKeys.ChatBox.Message.Request_Price.description]:
    'Yêu cầu giá đã được gửi thành công đến người sáng tạo.',
  [MessageKeys.ChatBox.Message.Request_Price.btn]: 'Đề xuất giá và gói dịch vụ',

  [MessageKeys.ChatBox.Message.File.singular]: 'tập tin',
  [MessageKeys.ChatBox.Message.File.plural]: 'tập tin',
  [MessageKeys.ChatBox.Message.File.plural_2]: '2 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_3]: '3 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_4]: '4 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_5]: '5 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_6]: '6 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_7]: '7 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_8]: '8 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_9]: '9 tập tin',
  [MessageKeys.ChatBox.Message.File.plural_10]: '10 tập tin',
  [MessageKeys.ChatBox.Message.File.expired]: 'Tập tin này đã hết hạn.',

  [MessageKeys.ChatBox.Message.Error.sending]: 'Đang gửi...',
  [MessageKeys.ChatBox.Message.Error.fail]: 'Gửi thất bại',

  [MessageKeys.InputField.chat_disabled]:
    'Người dùng này không thể được liên hệ nữa.',
  [MessageKeys.InputField.web]: 'Nhập văn bản hoặc kéo thả tệp',
  [MessageKeys.InputField.mobile]: 'Nhập tin nhắn của bạn',
  [MessageKeys.InputField.drag_drop]: 'Kéo và thả tập tin',

  [MessageKeys.InputField.Btn.offer_package]: 'Đề xuất một gói',
  [MessageKeys.InputField.Btn.send_proposal]: 'Gửi đề xuất',

  [MessageKeys.UserInfo.Verified.tooltip]: `
    Tài khoản này đại diện cho một tổ chức <br>
    đã xác minh như công ty hoặc tổ chức hoặc <br>
    là một thành viên liên quan đến họ.`,

  [MessageKeys.UserInfo.Status.online]: 'Trực tuyến',
  [MessageKeys.UserInfo.Status.offline]: 'Vừa xem lúc',

  [MessageKeys.UserInfo.order_together]: 'Đơn hàng Cùng nhau',

  [MessageKeys.UserInfo.Btn.edit_profile]: 'Chỉnh sửa Hồ sơ',
  [MessageKeys.UserInfo.Btn.visit_profile]: 'Xem Hồ sơ',

  [MessageKeys.Error.max_characters]: 'Tối đa {{number}} ký tự',
  [MessageKeys.Error.max_file_size_1]: 'Giới hạn kích thước tập tin tối đa là',
  [MessageKeys.Error.max_file_size_2]: 'MB',
  [MessageKeys.Error.max_file_1]: 'Bạn có thể gửi tối đa',
  [MessageKeys.Error.max_file_2]: 'tập tin',
};
