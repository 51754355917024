import TypeButton from './TypeButton';
import PackagesType from 'constants/packageType';
import styled from 'styled-components';
import { usePackages } from 'contexts/PackagesContext';
import Variant from '../../packagesCardVariant';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { useProfileState } from 'components/components/ProfilePage/common/ProfileContext';
import { useSearchParams } from 'react-router-dom';

const TypeButtons = ({ variant }) => {
  const { t } = useTranslation();
  const { selectType, selectedType, availablePackages, loading } =
    usePackages();
  const { user } = useProfileState();
  const [searchParams] = useSearchParams();

  return (
    <Wrapper>
      {Object.values(PackagesType).map((type) => {
        const isAvailable =
          variant === Variant.Preview || availablePackages.includes(type);

        return (
          <TypeButton
            key={type}
            type={t(type)}
            active={loading ? '' : selectedType === type}
            onClick={() => selectType(type)}
            disabled={
              !isAvailable ||
              (!!user?.isAdmin &&
                searchParams.get('guide') === '' &&
                selectedType !== type)
            }
            tooltip={!isAvailable && t(ProfileKeys.Package.TopBar.tooltip)}
          />
        );
      })}
    </Wrapper>
  );
};

export default TypeButtons;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
