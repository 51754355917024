import ModalKeys from '../translationKeys/ModalKeys';

export const ModalTranslations = {
  // OfficialLaunch
  [ModalKeys.official_launch.title]: `
    This feature will be released in your country soon!`,
  [ModalKeys.official_launch.content]: `
    This feature will be additionally unlocked in your region soon. <br>
    We will provide the best service for your global collaborations.`,

  // Patch Node
  [ModalKeys.Patch_Note.close]: 'Don’t open again for 14 days',

  // Notification
  [ModalKeys.Notification.Dropdown.title]: `Notifications`,
  [ModalKeys.Notification.Dropdown.no_notification]: `No notifications`,
  [ModalKeys.Notification.Dropdown.bottom_btn]: `Mark all as read`,

  [ModalKeys.My_Order.Dropdown.content]: 'Start your first order!',
  [ModalKeys.My_Order.Dropdown.bottom_btn]: 'View All Orders',
};
