export const CartLightIcon = ({ size = 1 }) => (
  <svg
    width={size * 21}
    height={size * 20}
    viewBox="0 0 21 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 0C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2V0ZM3.5 1L4.47014 0.757464C4.35885 0.312297 3.95887 0 3.5 0V1ZM6 11V12C6.30794 12 6.5987 11.8581 6.78821 11.6154C6.97771 11.3727 7.04483 11.0562 6.97014 10.7575L6 11ZM17 15C17.5523 15 18 14.5523 18 14C18 13.4477 17.5523 13 17 13V15ZM4 3V2C3.69206 2 3.4013 2.14187 3.21179 2.38459C3.02229 2.6273 2.95517 2.94379 3.02986 3.24254L4 3ZM19 3L19.9701 3.24254C20.0448 2.94379 19.9777 2.6273 19.7882 2.38459C19.5987 2.14187 19.3079 2 19 2V3ZM17 11V12C17.4589 12 17.8589 11.6877 17.9701 11.2425L17 11ZM6 11L5.02986 11.2425C5.14115 11.6877 5.54113 12 6 12V11ZM1 2H3.5V0H1V2ZM2.52986 1.24254L3.02986 3.24254L4.97014 2.75746L4.47014 0.757464L2.52986 1.24254ZM3.02986 3.24254L5.02986 11.2425L6.97014 10.7575L4.97014 2.75746L3.02986 3.24254ZM6 10H5.5V12H6V10ZM5.5 10C4.11929 10 3 11.1193 3 12.5H5C5 12.2239 5.22386 12 5.5 12V10ZM3 12.5C3 13.8807 4.11929 15 5.5 15V13C5.22386 13 5 12.7761 5 12.5H3ZM5.5 15H17V13H5.5V15ZM4 4H19V2H4V4ZM18.0299 2.75746L16.0299 10.7575L17.9701 11.2425L19.9701 3.24254L18.0299 2.75746ZM17 10H6V12H17V10ZM6.97014 10.7575L4.97014 2.75746L3.02986 3.24254L5.02986 11.2425L6.97014 10.7575ZM16 18V20C17.1046 20 18 19.1046 18 18H16ZM16 18H14C14 19.1046 14.8954 20 16 20V18ZM16 18V16C14.8954 16 14 16.8954 14 18H16ZM16 18H18C18 16.8954 17.1046 16 16 16V18ZM6 18V20C7.10457 20 8 19.1046 8 18H6ZM6 18H4C4 19.1046 4.89543 20 6 20V18ZM6 18V16C4.89543 16 4 16.8954 4 18H6ZM6 18H8C8 16.8954 7.10457 16 6 16V18Z" />
  </svg>
);
