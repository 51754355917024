export const Search2Icon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 20}
    height={size * 18}
    viewBox="0 0 20 18"
    fill="currentColor"
  >
    <path d="M17.7067 18L10.9867 11.7C10.4533 12.1 9.84 12.4167 9.14667 12.65C8.45333 12.8833 7.71556 13 6.93333 13C4.99556 13 3.35573 12.371 2.01387 11.113C0.671289 9.85433 0 8.31667 0 6.5C0 4.68333 0.671289 3.14567 2.01387 1.887C3.35573 0.629 4.99556 0 6.93333 0C8.87111 0 10.5113 0.629 11.8539 1.887C13.1957 3.14567 13.8667 4.68333 13.8667 6.5C13.8667 7.23333 13.7422 7.925 13.4933 8.575C13.2444 9.225 12.9067 9.8 12.48 10.3L19.2 16.6L17.7067 18ZM6.93333 11C8.26667 11 9.40018 10.5627 10.3339 9.688C11.2668 8.81267 11.7333 7.75 11.7333 6.5C11.7333 5.25 11.2668 4.18733 10.3339 3.312C9.40018 2.43733 8.26667 2 6.93333 2C5.6 2 4.46649 2.43733 3.5328 3.312C2.59982 4.18733 2.13333 5.25 2.13333 6.5C2.13333 7.75 2.59982 8.81267 3.5328 9.688C4.46649 10.5627 5.6 11 6.93333 11Z" />
  </svg>
);
