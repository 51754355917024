import { AnimatePresence, motion } from 'framer-motion';
import { memo } from 'react';
import { useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';

const Dropdown = ({
  showDropdown,
  toggleDropdown,
  trigger,
  className,
  children,
  marginTop,
  translateX,
  shadow,
  radius = 15,
  border,
  menuWidth = null,
}) => {
  const ref = useRef(null);
  useClickAway(ref, () => toggleDropdown(false));

  return (
    <Wrapper className={className ? className : ''} ref={ref}>
      {trigger}

      <AnimatePresence>
        {showDropdown && (
          <Menu
            marginTop={marginTop}
            translateX={translateX}
            shadow={shadow}
            radius={radius}
            menuWidth={menuWidth}
            border={border}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            {children}
          </Menu>
        )}
      </AnimatePresence>
    </Wrapper>
  );
};

export default memo(Dropdown);

const Wrapper = styled.div`
  position: relative;
`;

const Menu = styled(motion.div)`
  position: absolute;
  top: 100%;
  left: 50%;
  translate: ${({ translateX }) => translateX || '-50%'} 0;
  margin-top: ${({ marginTop }) => marginTop || '1rem'};
  box-shadow: ${({ shadow }) => shadow || 'var(--shadow-dropdown-art-assets)'};
  z-index: 10;
  background: var(--color-white);
  border: ${({ border }) =>
    border ? border : '2px solid var(--color-pink-100)'};
  border-radius: ${({ radius }) => radius}px;
  width: max-content;
  transform-origin: top center;
  min-width: ${({ menuWidth }) => menuWidth && menuWidth};
  max-width: ${({ menuWidth }) => menuWidth && menuWidth};
`;
