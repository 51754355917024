export const ShoppingCartIcon = ({ size = 1 }) => (
  <svg
    width={86 * size}
    height={90 * size}
    viewBox="0 0 86 90"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.6587 34.2857H51.1905V21.4286H63.9881V12.8571H51.1905V0H42.6587V12.8571H29.8611V21.4286H42.6587V34.2857ZM25.5952 72.8571C20.9028 72.8571 17.1062 76.7143 17.1062 81.4286C17.1062 86.1429 20.9028 90 25.5952 90C30.2877 90 34.127 86.1429 34.127 81.4286C34.127 76.7143 30.2877 72.8571 25.5952 72.8571ZM68.254 72.8571C63.5615 72.8571 59.7649 76.7143 59.7649 81.4286C59.7649 86.1429 63.5615 90 68.254 90C72.9464 90 76.7857 86.1429 76.7857 81.4286C76.7857 76.7143 72.9464 72.8571 68.254 72.8571ZM30.2877 51.4286H62.0685C65.2679 51.4286 68.0833 49.6714 69.5337 47.0143L86 16.9714L78.5774 12.8571L62.0685 42.8571H32.122L13.9494 4.28571H0V12.8571H8.53175L23.8889 45.3857L18.13 55.8429C15.0159 61.5857 19.1111 68.5714 25.5952 68.5714H76.7857V60H25.5952L30.2877 51.4286Z"
      fill="#E8B9FF"
    />
  </svg>
);
