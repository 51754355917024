import RequestPackagePriceModal from './RequestPackagePriceModal';
import MessageQuotaEndedModal from './MessageQuotaEndedModal';
import UnlockPriceQuotaEndedModal from 'components/Basics/GlobalModals/UnlockPriceQuotaEndedModal';
import SocialReconnectionModal from 'components/Basics/GlobalModals/SocialReconnectionModal';

const GlobalModals = () => {
  return (
    <>
      <SocialReconnectionModal />
      <RequestPackagePriceModal />
      <MessageQuotaEndedModal />
      <UnlockPriceQuotaEndedModal />
    </>
  );
};

export default GlobalModals;
