import Heading from 'components/Basics/Typography/Heading';
import { usePackages } from 'contexts/PackagesContext';
import styled, { css } from 'styled-components';
import PriceBox from './PriceBox';
import contentTypes from 'constants/contentTypes';
import { flatten, keys, map, sortBy } from 'lodash';
import Variant from '../packagesCardVariant';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { useMemo } from 'react';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import MaxLines from 'components/Basics/Typography/MaxLines';
import { Trans } from 'react-i18next';
import AutoTranslationKeys from 'translations/translationKeys/AutoTranslationKeys';
import Stack from 'components/Basics/Layout/Stack';
import { SettingsIcon } from 'assets/icons';
import Platform from 'constants/platform';

const contentTypesMap = flatten(Object.values(contentTypes)).reduce(
  (acc, item) => {
    acc[item.value] = item.label;
    return acc;
  },
  {}
);

const DetailsBox = ({ variant }) => {
  const { t } = useTranslation();
  const { currentPackage, loading, isCurrentPackageComplete, platform } =
    usePackages();
  const {
    haveTranslatedText,
    getPreferredText,
    displayTranslatedText,
    toggleDisplayTranslatedText,
  } = useAutoTranslation();

  const typeLength = useMemo(() => {
    return getPreferredText(currentPackage?.typeLength);
  }, [currentPackage, getPreferredText]);

  // details box have 2x2 layout for instagram and tiktok and when there are sample contents
  const isTwoByTwo = useMemo(() => {
    return (
      (platform === Platform.Instagram || platform === Platform.TikTok) &&
      currentPackage?.sampleContents.length > 0
    );
  }, [platform, currentPackage?.sampleContents]);

  return (
    <div>
      {haveTranslatedText && variant === Variant.Modal && (
        <AutoTranslationContainer className="h6.5">
          <span className="">
            <SettingsIcon size={0.7} />
          </span>
          <Stack direction="row" gap="3px">
            {displayTranslatedText
              ? t(AutoTranslationKeys.Applying_Translation.title)
              : t(AutoTranslationKeys.Displaying_Original.title)}
            <span
              className="text-decoration-underline cursor-pointer"
              onClick={toggleDisplayTranslatedText}
            >
              {displayTranslatedText
                ? t(AutoTranslationKeys.Applying_Translation.btn)
                : t(AutoTranslationKeys.Displaying_Original.btn)}
            </span>
          </Stack>
        </AutoTranslationContainer>
      )}
      <Container
        lessMargin={haveTranslatedText && variant === Variant.Modal}
        isTwoByTwo={isTwoByTwo}
      >
        <Box isTwoByTwo={isTwoByTwo}>
          <Heading variant="SBH5">{t(GeneralKeys.price)}</Heading>
          <PriceBox variant={variant} />
        </Box>
        {!isTwoByTwo && (
          <ContentsBox isTwoByTwo={isTwoByTwo} variant={variant} />
        )}
        <Box isTwoByTwo={isTwoByTwo}>
          <Heading variant="SBH5">{t(GeneralKeys.delivery)}</Heading>
          <div>
            <Heading variant="H5.5">
              {loading ||
              (variant !== Variant.Preview && !isCurrentPackageComplete) ? (
                '--'
              ) : (
                <>
                  {!currentPackage?.delivery &&
                  currentPackage?.delivery !== 0 ? (
                    '--'
                  ) : (
                    <Trans values={{ number: currentPackage?.delivery }}>
                      {currentPackage?.delivery > 1
                        ? t(GeneralKeys.day_plural)
                        : t(GeneralKeys.day_singular)}
                    </Trans>
                  )}
                  <br />
                  {currentPackage?.revisions ===
                  null ? null : currentPackage?.revisions === -1 ? (
                    t(GeneralKeys.unlimited_revisions)
                  ) : currentPackage?.revisions === 0 ? (
                    t(ProfileKeys.Edit.Package.Revisions.no_revisions)
                  ) : (
                    <Trans values={{ number: currentPackage?.revisions }}>
                      {currentPackage?.revisions > 1
                        ? t(GeneralKeys.revisions)
                        : t(GeneralKeys.revision)}
                    </Trans>
                  )}
                </>
              )}
            </Heading>
          </div>
        </Box>
        {isTwoByTwo && (
          <ContentsBox isTwoByTwo={isTwoByTwo} variant={variant} />
        )}

        <Box isTwoByTwo={isTwoByTwo}>
          <Heading variant="SBH5">{t(GeneralKeys.type_length)}</Heading>
          <MaxLineBox isTwoByTwo={isTwoByTwo} padding="0 2rem">
            <MaxLines limit={isTwoByTwo ? 4 : 5}>
              {loading ||
              (variant !== Variant.Preview && !isCurrentPackageComplete)
                ? '--'
                : typeLength || '--'}
            </MaxLines>
          </MaxLineBox>
        </Box>
      </Container>
    </div>
  );
};

export default DetailsBox;

const ContentsBox = ({ isTwoByTwo, variant }) => {
  const { t } = useTranslation();
  const { currentPackage, loading, isCurrentPackageComplete, platform } =
    usePackages();

  const currentPlatformContents = useMemo(() => {
    return map(contentTypes[platform], (item) => item.value);
  }, [platform]);

  const sortedContents = sortBy(keys(currentPackage?.contents || {}), (key) => {
    // Sort by platform first
    if (currentPlatformContents.includes(key)) {
      return 0;
    }
    return 1;
  });

  return (
    <Box isTwoByTwo={isTwoByTwo}>
      <Heading variant="SBH5">{t(GeneralKeys.contents)}</Heading>
      <MaxLineBox isTwoByTwo={isTwoByTwo} padding="0 1.5rem">
        <MaxLines limit={isTwoByTwo ? 4 : null}>
          {loading || (variant !== Variant.Preview && !isCurrentPackageComplete)
            ? '--'
            : sortedContents.map((key) =>
                currentPackage?.contents[key] === 0 ? null : (
                  <p key={key} className="h5.5">
                    {t(contentTypesMap[key])} x {currentPackage?.contents[key]}
                  </p>
                )
              )}
        </MaxLines>
      </MaxLineBox>
    </Box>
  );
};

const AutoTranslationContainer = styled.div`
  padding: 0;
  display: flex;
  align-items: start;
  gap: 0.5rem;
  margin: 1.25rem 0 0;
  color: var(--color-indigo-200);
`;

const Container = styled.div`
  width: 100%;
  border: 1px solid rgb(237, 214, 255, 0.5);
  border-radius: 0.5rem 0.5rem 1rem 1rem;
  grid-template-columns: repeat(2, max-content);
  display: grid;
  grid-template-columns: ${({ isTwoByTwo }) =>
    isTwoByTwo ? 'repeat(2, 1fr)' : '210px 230px 230px 250px'};
  margin-top: ${({ lessMargin, isTwoByTwo }) =>
    lessMargin ? '1.25rem' : isTwoByTwo ? '' : '1.5rem'};
  overflow: hidden;
`;

const Box = styled.div`
  width: 100%;
  border-right: 1px solid rgb(237, 214, 255, 0.5);
  background: var(--color-white);
  padding: 1.75rem 0 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || 'center'};
  gap: 0.75rem;
  text-align: center;

  &:last-child {
    border-right: none;
  }

  &:nth-child(even) {
    background: var(--color-pink-75);
  }

  ${({ isTwoByTwo }) =>
    isTwoByTwo &&
    css`
      padding: 1.25rem 0;
      &:nth-child(even) {
        border-right: none;
        border-bottom: 1px solid rgb(237, 214, 255, 0.5);
      }

      &:first-child {
        border-bottom: 1px solid rgb(237, 214, 255, 0.5);
      }
      &:last-child {
        border-bottom: none;
      }
    `}
`;

const MaxLineBox = styled.div.attrs({
  className: 'h5.5',
})`
  min-height: ${({ isTwoByTwo }) =>
    isTwoByTwo ? 'max-content' : 'calc(1em * 1.7 * 3)'};
  padding: ${({ padding }) => padding};

  p {
    text-align: left;
  }
`;
