export const IndiaFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29896_35070)">
      <rect
        x="0.128418"
        y="0.064209"
        width="33"
        height="33"
        rx="16.5"
        fill="#F5F5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6325 22.0642C19.6685 22.0642 22.1325 19.6002 22.1325 16.5642C22.1325 13.5282 19.6685 11.0642 16.6325 11.0642C13.5965 11.0642 11.1325 13.5282 11.1325 16.5642C11.1325 19.6002 13.5965 22.0642 16.6325 22.0642ZM16.6325 19.8642C18.4541 19.8642 19.9325 18.3858 19.9325 16.5642C19.9325 14.7426 18.4541 13.2642 16.6325 13.2642C14.8109 13.2642 13.3325 14.7426 13.3325 16.5642C13.3325 18.3858 14.8109 19.8642 16.6325 19.8642Z"
        fill="#1A47B8"
      />
      <path
        d="M16.6333 17.6642C17.2408 17.6642 17.7333 17.1717 17.7333 16.5642C17.7333 15.9567 17.2408 15.4642 16.6333 15.4642C16.0258 15.4642 15.5333 15.9567 15.5333 16.5642C15.5333 17.1717 16.0258 17.6642 16.6333 17.6642Z"
        fill="#1A47B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-6.47089 22.0643H39.7291V33.0643H-6.47089V22.0643Z"
        fill="#249F58"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-6.47089 0.0643311H39.7291V11.0643H-6.47089V0.0643311Z"
        fill="#FF6C2D"
      />
    </g>
    <defs>
      <clipPath id="clip0_29896_35070">
        <rect
          x="0.128418"
          y="0.064209"
          width="33"
          height="33"
          rx="16.5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
