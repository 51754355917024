export const FileReferenceIcon = ({ size, ...rest }) => {
  return (
    <svg
      width="12"
      height="25"
      viewBox="0 0 12 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.3636 5.95454L10.3636 18.5C10.3636 20.9109 8.41091 22.8636 6 22.8636C3.58909 22.8636 1.63636 20.9109 1.63636 18.5L1.63636 4.86364C1.63636 3.35818 2.85818 2.13636 4.36364 2.13636C5.86909 2.13636 7.09091 3.35818 7.09091 4.86364V16.3182C7.09091 16.9182 6.6 17.4091 6 17.4091C5.4 17.4091 4.90909 16.9182 4.90909 16.3182V5.95454H3.27273L3.27273 16.3182C3.27273 17.8236 4.49455 19.0455 6 19.0455C7.50545 19.0455 8.72727 17.8236 8.72727 16.3182L8.72727 4.86364C8.72727 2.45273 6.77455 0.5 4.36364 0.5C1.95273 0.5 0 2.45273 0 4.86364L0 18.5C0 21.8164 2.68364 24.5 6 24.5C9.31636 24.5 12 21.8164 12 18.5L12 5.95454H10.3636Z"
        fill="#0E0D39"
      />
    </svg>
  );
};
