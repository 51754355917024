import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useSelectedChat } from '../../SelectedChatContext';
import { useTranslation } from 'react-i18next';
import MessageKeys from 'translations/translationKeys/MessageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const OrdersTogether = () => {
  const { t } = useTranslation();
  const { user } = useSelectedChat();
  const orders = user.ordersTogether;

  return (
    <Wrapper className="hidden md-flex">
      <h6 className="sbh6">{t(MessageKeys.UserInfo.order_together)}</h6>

      <span
        className={`text-sm ${orders > 0 ? 'text-decoration-underline' : ''}`}
      >
        {orders > 0 ? `${GeneralKeys.total} ${orders}` : 'N/A'}
      </span>
    </Wrapper>
  );
};

export default OrdersTogether;

const Wrapper = styled(motion.div).attrs({
  variants: {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 },
  },
  exit: { opacity: 0, y: 0 },
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
