import AxiosClient from '../AxiosClient';
import { useCallback } from 'react';

const useInstagramVerifier = ({ onPopupFail }) => {
  const verify = useCallback(
    async (reqType, isBasic) => {
      const { data: tokenRequestUrl } = await AxiosClient.get(
        '/platforms/generateAuthUrl',
        {
          params: {
            platform: isBasic ? 'instagram' : 'instagramBasic',
            reqType,
          },
        }
      );
      const popup = window.open(
        tokenRequestUrl,
        'Instagram Verification',
        'height=600,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
      );

      if (!popup) {
        onPopupFail();
      }
    },
    [onPopupFail]
  );

  return verify;
};

export default useInstagramVerifier;
