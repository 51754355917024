import { PauseIcon, PlayIcon } from 'assets/icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

// format time in seconds to hh:mm:ss or mm:ss or ss
const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time - hours * 3600) / 60);
  const seconds = Math.floor(time - hours * 3600 - minutes * 60);

  const hoursStr = hours > 0 ? `${hours}:` : '';
  const minutesStr = `${minutes < 10 ? '0' : ''}${minutes}:`;
  const secondsStr = `${seconds < 10 ? '0' : ''}${seconds}`;

  return `${hoursStr}${minutesStr}${secondsStr}`;
};

const PlayerControls = ({
  togglePlay,
  isPlaying,
  handleSeek,
  currentTime,
  duration,
}) => {
  return (
    <Wrapper>
      <PlayButton onClick={togglePlay}>
        {isPlaying ? <PauseIcon /> : <PlayIcon size={0.42} />}
      </PlayButton>

      <ProgressContainer>
        <ProgressBar
          type="range"
          min={0}
          max={100}
          step="any"
          value={currentTime}
          onChange={handleSeek}
        />

        <span className="h9 text-white">
          {formatTime(currentTime)} / {formatTime(duration)}
        </span>
      </ProgressContainer>
    </Wrapper>
  );
};

export default PlayerControls;

const Wrapper = styled(motion.div).attrs({
  layout: true,
})`
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 0.5rem 1.5rem;
`;

const PlayButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  &:focus {
    outline: none;
  }
`;

const ProgressContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const ProgressBar = styled.input`
  flex: 1;

  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  border-radius: 5px;
  background-repeat: no-repeat;
  background: linear-gradient(
    to right,
    white ${(props) => props.value}%,
    rgba(255, 255, 255, 0.2) ${(props) => props.value}%
  );

  /* Thumb styles */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: white;
  }

  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: white;
  }

  &::-ms-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: white;
  }

  /* Track styles */
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
  }

  &::-moz-range-track {
    -webkit-appearance: none;
    appearance: none;
  }
`;
