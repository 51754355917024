import InvitationKeys from '../translationKeys/InvitationPageKeys';

export const InvitationTranslations = {
  [InvitationKeys.title1]:
    'Conecte y colabore automáticamente con marcas mundiales',
  [InvitationKeys.title2]: '',
  [InvitationKeys.subtitle]:
    '* Para una experiencia óptima, recomendamos utilizar un entorno web.',

  [InvitationKeys.description]:
    'Con un perfil sencillo y gratuito, puede colaborar fácilmente con empresas globales certificadas de EE.UU., Europa, Corea, Japón, etc., sin necesidad de un MCN.',

  [InvitationKeys.view_creator_tutorial]: 'Registrarse como Creador',
  [InvitationKeys.explore_btn]: 'Ver perfil de muestra',
  [InvitationKeys.learn_more]: 'Introducción de servicios',
  [InvitationKeys.main_site]: 'Sitio principal',

  [InvitationKeys.Browser.title]:
    'Parece que estás usando un navegador no compatible',
  [InvitationKeys.Browser.description]:
    'Por favor, utiliza los navegadores mencionados arriba para una experiencia óptima. Algunas funciones pueden no ser compatibles con este navegador.',
  [InvitationKeys.Browser.button]: 'Entiendo',

  [InvitationKeys.Mega_Flow.title]: '¡Así es como funciona en Megaevolution!',
  [InvitationKeys.Mega_Flow.Caption.one]:
    'Crear un perfil de creador\n en sólo 1~2 minutos',
  [InvitationKeys.Mega_Flow.Caption.two]:
    'Recomendamos y conectamos\n *automáticamente* perfiles con empresas ',
  [InvitationKeys.Mega_Flow.Caption.three]:
    'Revisar y aceptar pedidos\n de marcas globales',
  [InvitationKeys.Mega_Flow.Caption.four]:
    'Suba contenidos y *gane más* con las\n licencias de contenidos',
  [InvitationKeys.Mega_Flow.Caption_Mobile.one]:
    'Crear un perfil de creador en sólo 1~2 minutos',
  [InvitationKeys.Mega_Flow.Caption_Mobile.two]:
    'Recomendamos y conectamos *automáticamente* perfiles con empresas ',
  [InvitationKeys.Mega_Flow.Caption_Mobile.three]:
    'Revisar y aceptar pedidos de marcas globales',
  [InvitationKeys.Mega_Flow.Caption_Mobile.four]:
    'Suba contenidos y *gane más* con las licencias de contenidos',

  [InvitationKeys.Creator_Review.title]:
    'Lo que dicen los creadores sobre MegaEvolution',
};
