import BalanceSummary from 'components/components/Billing/Balance/BalanceSummary';

const BillingKeys = {
  Payment_Subscription: {
    title: 'BillingKeys.Payment_Subscription.title',

    Method: {
      title: 'BillingKeys.Payment_Subscription.Method.title',
      btn: 'BillingKeys.Payment_Subscription.Method.btn',
    },

    Side: {
      my_plan: 'BillingKeys.Payment_Subscription.Side.my_plan',
      renews_on: 'BillingKeys.Payment_Subscription.Side.renews_on',
    },
  },

  Billing_History: {
    title: 'BillingKeys.Billing_History.title',
    search: 'BillingKeys.Billing_History.search',
  },

  MEGA_Balance: {
    title: 'BillingKeys.MEGA_Balance.title',

    Withdrawal_Method: {
      title: 'BillingKeys.MEGA_Balance.Withdrawal_Method.title',
      Btn: {
        withdraw_with:
          'BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.withdraw_with',
        coming_soon:
          'BillingKeys.MEGA_Balance.Withdrawal_Method.Btn.coming_soon',
      },
    },

    Balance_Summary: {
      title: 'BillingKeys.MEGA_Balance.Balance_Summary.title',
      total_available_balance:
        'BillingKeys.MEGA_Balance.Balance_Summary.total_available_balance',
      total_withdrawn:
        'BillingKeys.MEGA_Balance.Balance_Summary.total_withdrawn',
      pending_payment:
        'BillingKeys.MEGA_Balance.Balance_Summary.pending_payment',
      pending_payment_tooltip:
        'BillingKeys.MEGA_Balance.Balance_Summary.pending_payment_tooltip',

      Side: {
        withdrawal_amount:
          'BillingKeys.MEGA_Balance.Balance_Summary.Side.withdrawal_amount',
        details: 'BillingKeys.MEGA_Balance.Balance_Summary.Side.details',
        btn: 'BillingKeys.MEGA_Balance.Balance_Summary.Side.btn',
      },
    },
  },
};

export default BillingKeys;
