import React, { useEffect } from 'react';
import Modal from '../Modal';
import Stack from './Stack';
import { AutoTranslationIcon, CheckRoundedIcon } from 'assets/icons';
import styled from 'styled-components';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import IndigoSwitch from '../Buttons/IndigoSwitch';
import { useTranslation } from 'react-i18next';
import languages from 'constants/translationLanguages';
import AutoTranslationKeys from 'translations/translationKeys/AutoTranslationKeys';

const LanguageModal = ({ isOpen, onClose }) => {
  const {
    autoTranslate,
    toggleAutoTranslate,
    shouldReloadPageOnLanguageChange,
  } = useAutoTranslation();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (!isOpen) return;
    const event = new CustomEvent('toggle-patch-note-modal', {
      detail: 'close',
    });
    window.dispatchEvent(event);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Stack gap="1.5rem">
          <Stack direction="row" gap="1.25rem" align="center">
            <Stack direction="row">
              <AutoTranslationIcon />
              <h5 className="h5">{t(AutoTranslationKeys.Dropdown.title)}</h5>
            </Stack>
            <IndigoSwitch
              id="footer-switch"
              checked={autoTranslate}
              onChange={() => {
                toggleAutoTranslate();
                setTimeout(() => {
                  window.location.reload();
                }, 70);
              }}
              size="lg"
            />
          </Stack>
          <p style={{ marginBottom: '0.75rem' }}>
            {t(AutoTranslationKeys.Dropdown.description)}
          </p>
        </Stack>
      }
      closeIconSize={1.29}
      size="lg"
      wrapperStyles={{ padding: '1.875rem 2.25rem', width: '856px' }}
    >
      <ItemsWrapper>
        {languages.map((language) => (
          <Item
            key={language.value}
            onClick={() => {
              i18n.changeLanguage(language.value);
              onClose();
              shouldReloadPageOnLanguageChange();
            }}
            disabled={language.disabled}
            selected={language.value === i18n.language}
          >
            {language.value === i18n.language && <CheckRoundedIcon />}
            {language.label}
          </Item>
        ))}
      </ItemsWrapper>
    </Modal>
  );
};

export default LanguageModal;

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 2.25rem;
  padding: 1.5rem 0 0;
`;

const Item = styled.button.attrs({
  className: 'h5',
})`
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  color: ${({ selected }) =>
    selected ? 'var(--color-indigo-500)' : 'var(--color-indigo-100)'};
  width: 100%;
  text-align: left;
  display: flex;
  gap: 0.625rem;
  align-items: center;

  &:hover {
    background: var(--color-indigo-75);
  }

  &:disabled {
    color: var(--color-indigo-25);
  }
`;
