const countryCodes = [
  {
    alpha2: 'AF',
    alpha3: 'AFG',
    dial_code: '+93',
  },
  {
    alpha2: 'AX',
    alpha3: 'ALA',
    dial_code: '+358',
  },
  {
    alpha2: 'AL',
    alpha3: 'ALB',
    dial_code: '+355',
  },
  {
    alpha2: 'DZ',
    alpha3: 'DZA',
    dial_code: '+213',
  },
  {
    alpha2: 'AS',
    alpha3: 'ASM',
    dial_code: '+1684',
  },
  {
    alpha2: 'AD',
    alpha3: 'AND',
    dial_code: '+376',
  },
  {
    alpha2: 'AO',
    alpha3: 'AGO',
    dial_code: '+244',
  },
  {
    alpha2: 'AI',
    alpha3: 'AIA',
    dial_code: '+1264',
  },
  {
    alpha2: 'AQ',
    alpha3: 'ATA',
    dial_code: '+672',
  },
  {
    alpha2: 'AG',
    alpha3: 'ATG',
    dial_code: '+1268',
  },
  {
    alpha2: 'AR',
    alpha3: 'ARG',
    dial_code: '+54',
  },
  {
    alpha2: 'AM',
    alpha3: 'ARM',
    dial_code: '+374',
  },
  {
    alpha2: 'AW',
    alpha3: 'ABW',
    dial_code: '+297',
  },
  {
    alpha2: 'AU',
    alpha3: 'AUS',
    dial_code: '+61',
  },
  {
    alpha2: 'AT',
    alpha3: 'AUT',
    dial_code: '+43',
  },
  {
    alpha2: 'AZ',
    alpha3: 'AZE',
    dial_code: '+994',
  },
  {
    alpha2: 'BS',
    alpha3: 'BHS',
    dial_code: '+1242',
  },
  {
    alpha2: 'BH',
    alpha3: 'BHR',
    dial_code: '+973',
  },
  {
    alpha2: 'BD',
    alpha3: 'BGD',
    dial_code: '+880',
  },
  {
    alpha2: 'BB',
    alpha3: 'BRB',
    dial_code: '+1246',
  },
  {
    alpha2: 'BY',
    alpha3: 'BLR',
    dial_code: '+375',
  },
  {
    alpha2: 'BE',
    alpha3: 'BEL',
    dial_code: '+32',
  },
  {
    alpha2: 'BZ',
    alpha3: 'BLZ',
    dial_code: '+501',
  },
  {
    alpha2: 'BJ',
    alpha3: 'BEN',
    dial_code: '+229',
  },
  {
    alpha2: 'BM',
    alpha3: 'BMU',
    dial_code: '+1441',
  },
  {
    alpha2: 'BT',
    alpha3: 'BTN',
    dial_code: '+975',
  },
  {
    alpha2: 'BO',
    alpha3: 'BOL',
    dial_code: '+591',
  },
  {
    alpha2: 'BA',
    alpha3: 'BIH',
    dial_code: '+387',
  },
  {
    alpha2: 'BW',
    alpha3: 'BWA',
    dial_code: '+267',
  },
  {
    alpha2: 'BR',
    alpha3: 'BRA',
    dial_code: '+55',
  },
  {
    alpha2: 'IO',
    alpha3: 'IOT',
    dial_code: '+246',
  },
  {
    alpha2: 'BN',
    alpha3: 'BRN',
    dial_code: '+673',
  },
  {
    alpha2: 'BG',
    alpha3: 'BGR',
    dial_code: '+359',
  },
  {
    alpha2: 'BF',
    alpha3: 'BFA',
    dial_code: '+226',
  },
  {
    alpha2: 'BI',
    alpha3: 'BDI',
    dial_code: '+257',
  },
  {
    alpha2: 'KH',
    alpha3: 'KHM',
    dial_code: '+855',
  },
  {
    alpha2: 'CM',
    alpha3: 'CMR',
    dial_code: '+237',
  },
  {
    alpha2: 'US',
    alpha3: 'USA',
    dial_code: '+1',
  },
  {
    alpha2: 'CA',
    alpha3: 'CAN',
    dial_code: '+1',
  },
  {
    alpha2: 'CV',
    alpha3: 'CPV',
    dial_code: '+238',
  },
  {
    alpha2: 'KY',
    alpha3: 'CYM',
    dial_code: '+ 345',
  },
  {
    alpha2: 'CF',
    alpha3: 'CAF',
    dial_code: '+236',
  },
  {
    alpha2: 'TD',
    alpha3: 'TCD',
    dial_code: '+235',
  },
  {
    alpha2: 'CL',
    alpha3: 'CHL',
    dial_code: '+56',
  },
  {
    alpha2: 'CN',
    alpha3: 'CHN',
    dial_code: '+86',
  },
  {
    alpha2: 'CX',
    alpha3: 'CXR',
    dial_code: '+61',
  },
  {
    alpha2: 'CC',
    alpha3: 'CCK',
    dial_code: '+61',
  },
  {
    alpha2: 'CO',
    alpha3: 'COL',
    dial_code: '+57',
  },
  {
    alpha2: 'KM',
    alpha3: 'COM',
    dial_code: '+269',
  },
  {
    alpha2: 'CG',
    alpha3: 'COG',
    dial_code: '+242',
  },
  {
    alpha2: 'CD',
    alpha3: 'COD',
    dial_code: '+243',
  },
  {
    alpha2: 'CK',
    alpha3: 'COK',
    dial_code: '+682',
  },
  {
    alpha2: 'CR',
    alpha3: 'CRI',
    dial_code: '+506',
  },
  {
    alpha2: 'CI',
    alpha3: 'CIV',
    dial_code: '+225',
  },
  {
    alpha2: 'HR',
    alpha3: 'HRV',
    dial_code: '+385',
  },
  {
    alpha2: 'CU',
    alpha3: 'CUB',
    dial_code: '+53',
  },
  {
    alpha2: 'CY',
    alpha3: 'CYP',
    dial_code: '+357',
  },
  {
    alpha2: 'CZ',
    alpha3: 'CZE',
    dial_code: '+420',
  },
  {
    alpha2: 'DK',
    alpha3: 'DNK',
    dial_code: '+45',
  },
  {
    alpha2: 'DJ',
    alpha3: 'DJI',
    dial_code: '+253',
  },
  {
    alpha2: 'DM',
    alpha3: 'DMA',
    dial_code: '+1767',
  },
  {
    alpha2: 'DO',
    alpha3: 'DOM',
    dial_code: '+1849',
  },
  {
    alpha2: 'EC',
    alpha3: 'ECU',
    dial_code: '+593',
  },
  {
    alpha2: 'EG',
    alpha3: 'EGY',
    dial_code: '+20',
  },
  {
    alpha2: 'SV',
    alpha3: 'SLV',
    dial_code: '+503',
  },
  {
    alpha2: 'GQ',
    alpha3: 'GNQ',
    dial_code: '+240',
  },
  {
    alpha2: 'ER',
    alpha3: 'ERI',
    dial_code: '+291',
  },
  {
    alpha2: 'EE',
    alpha3: 'EST',
    dial_code: '+372',
  },
  {
    alpha2: 'ET',
    alpha3: 'ETH',
    dial_code: '+251',
  },
  {
    alpha2: 'FK',
    alpha3: 'FLK',
    dial_code: '+500',
  },
  {
    alpha2: 'FO',
    alpha3: 'FRO',
    dial_code: '+298',
  },
  {
    alpha2: 'FJ',
    alpha3: 'FJI',
    dial_code: '+679',
  },
  {
    alpha2: 'FI',
    alpha3: 'FIN',
    dial_code: '+358',
  },
  {
    alpha2: 'FR',
    alpha3: 'FRA',
    dial_code: '+33',
  },
  {
    alpha2: 'GF',
    alpha3: 'GUF',
    dial_code: '+594',
  },
  {
    alpha2: 'PF',
    alpha3: 'PYF',
    dial_code: '+689',
  },
  {
    alpha2: 'GA',
    alpha3: 'GAB',
    dial_code: '+241',
  },
  {
    alpha2: 'GM',
    alpha3: 'GMB',
    dial_code: '+220',
  },
  {
    alpha2: 'GE',
    alpha3: 'GEO',
    dial_code: '+995',
  },
  {
    alpha2: 'DE',
    alpha3: 'DEU',
    dial_code: '+49',
  },
  {
    alpha2: 'GH',
    alpha3: 'GHA',
    dial_code: '+233',
  },
  {
    alpha2: 'GI',
    alpha3: 'GIB',
    dial_code: '+350',
  },
  {
    alpha2: 'GR',
    alpha3: 'GRC',
    dial_code: '+30',
  },
  {
    alpha2: 'GL',
    alpha3: 'GRL',
    dial_code: '+299',
  },
  {
    alpha2: 'GD',
    alpha3: 'GRD',
    dial_code: '+1473',
  },
  {
    alpha2: 'GP',
    alpha3: 'GLP',
    dial_code: '+590',
  },
  {
    alpha2: 'GU',
    alpha3: 'GUM',
    dial_code: '+1671',
  },
  {
    alpha2: 'GT',
    alpha3: 'GTM',
    dial_code: '+502',
  },
  {
    alpha2: 'GG',
    alpha3: 'GGY',
    dial_code: '+44',
  },
  {
    alpha2: 'GN',
    alpha3: 'GIN',
    dial_code: '+224',
  },
  {
    alpha2: 'GW',
    alpha3: 'GNB',
    dial_code: '+245',
  },
  {
    alpha2: 'GY',
    alpha3: 'GUY',
    dial_code: '+595',
  },
  {
    alpha2: 'HT',
    alpha3: 'HTI',
    dial_code: '+509',
  },
  {
    alpha2: 'VA',
    alpha3: 'VAT',
    dial_code: '+379',
  },
  {
    alpha2: 'HN',
    alpha3: 'HND',
    dial_code: '+504',
  },
  {
    alpha2: 'HK',
    alpha3: 'HKG',
    dial_code: '+852',
  },
  {
    alpha2: 'HU',
    alpha3: 'HUN',
    dial_code: '+36',
  },
  {
    alpha2: 'IS',
    alpha3: 'ISL',
    dial_code: '+354',
  },
  {
    alpha2: 'IN',
    alpha3: 'IND',
    dial_code: '+91',
  },
  {
    alpha2: 'ID',
    alpha3: 'IDN',
    dial_code: '+62',
  },
  {
    alpha2: 'IR',
    alpha3: 'IRN',
    dial_code: '+98',
  },
  {
    alpha2: 'IQ',
    alpha3: 'IRQ',
    dial_code: '+964',
  },
  {
    alpha2: 'IE',
    alpha3: 'IRL',
    dial_code: '+353',
  },
  {
    alpha2: 'IM',
    alpha3: 'IMN',
    dial_code: '+44',
  },
  {
    alpha2: 'IL',
    alpha3: 'ISR',
    dial_code: '+972',
  },
  {
    alpha2: 'IT',
    alpha3: 'ITA',
    dial_code: '+39',
  },
  {
    alpha2: 'JM',
    alpha3: 'JAM',
    dial_code: '+1876',
  },
  {
    alpha2: 'JP',
    alpha3: 'JPN',
    dial_code: '+81',
  },
  {
    alpha2: 'JE',
    alpha3: 'JEY',
    dial_code: '+44',
  },
  {
    alpha2: 'JO',
    alpha3: 'JOR',
    dial_code: '+962',
  },
  {
    alpha2: 'KZ',
    alpha3: 'KAZ',
    dial_code: '+77',
  },
  {
    alpha2: 'KE',
    alpha3: 'KEN',
    dial_code: '+254',
  },
  {
    alpha2: 'KI',
    alpha3: 'KIR',
    dial_code: '+686',
  },
  {
    alpha2: 'KP',
    alpha3: 'PRK',
    dial_code: '+850',
  },
  {
    alpha2: 'KR',
    alpha3: 'KOR',
    dial_code: '+82',
  },
  {
    alpha2: 'KW',
    alpha3: 'KWT',
    dial_code: '+965',
  },
  {
    alpha2: 'KG',
    alpha3: 'KGZ',
    dial_code: '+996',
  },
  {
    alpha2: 'LA',
    alpha3: 'LAO',
    dial_code: '+856',
  },
  {
    alpha2: 'LV',
    alpha3: 'LVA',
    dial_code: '+371',
  },
  {
    alpha2: 'LB',
    alpha3: 'LBN',
    dial_code: '+961',
  },
  {
    alpha2: 'LS',
    alpha3: 'LSO',
    dial_code: '+266',
  },
  {
    alpha2: 'LR',
    alpha3: 'LBR',
    dial_code: '+231',
  },
  {
    alpha2: 'LY',
    alpha3: 'LBY',
    dial_code: '+218',
  },
  {
    alpha2: 'LI',
    alpha3: 'LIE',
    dial_code: '+423',
  },
  {
    alpha2: 'LT',
    alpha3: 'LTU',
    dial_code: '+370',
  },
  {
    alpha2: 'LU',
    alpha3: 'LUX',
    dial_code: '+352',
  },
  {
    alpha2: 'MO',
    alpha3: 'MAC',
    dial_code: '+853',
  },
  {
    alpha2: 'MK',
    alpha3: 'MKD',
    dial_code: '+389',
  },
  {
    alpha2: 'MG',
    alpha3: 'MDG',
    dial_code: '+261',
  },
  {
    alpha2: 'MW',
    alpha3: 'MWI',
    dial_code: '+265',
  },
  {
    alpha2: 'MY',
    alpha3: 'MYS',
    dial_code: '+60',
  },
  {
    alpha2: 'MV',
    alpha3: 'MDV',
    dial_code: '+960',
  },
  {
    alpha2: 'ML',
    alpha3: 'MLI',
    dial_code: '+223',
  },
  {
    alpha2: 'MT',
    alpha3: 'MLT',
    dial_code: '+356',
  },
  {
    alpha2: 'MH',
    alpha3: 'MHL',
    dial_code: '+692',
  },
  {
    alpha2: 'MQ',
    alpha3: 'MTQ',
    dial_code: '+596',
  },
  {
    alpha2: 'MR',
    alpha3: 'MRT',
    dial_code: '+222',
  },
  {
    alpha2: 'MU',
    alpha3: 'MUS',
    dial_code: '+230',
  },
  {
    alpha2: 'YT',
    alpha3: 'MYT',
    dial_code: '+262',
  },
  {
    alpha2: 'MX',
    alpha3: 'MEX',
    dial_code: '+52',
  },
  {
    alpha2: 'FM',
    alpha3: 'FSM',
    dial_code: '+691',
  },
  {
    alpha2: 'MD',
    alpha3: 'MDA',
    dial_code: '+373',
  },
  {
    alpha2: 'MC',
    alpha3: 'MCO',
    dial_code: '+377',
  },
  {
    alpha2: 'MN',
    alpha3: 'MNG',
    dial_code: '+976',
  },
  {
    alpha2: 'ME',
    alpha3: 'MNE',
    dial_code: '+382',
  },
  {
    alpha2: 'MS',
    alpha3: 'MSR',
    dial_code: '+1664',
  },
  {
    alpha2: 'MA',
    alpha3: 'MAR',
    dial_code: '+212',
  },
  {
    alpha2: 'MZ',
    alpha3: 'MOZ',
    dial_code: '+258',
  },
  {
    alpha2: 'MM',
    alpha3: 'MMR',
    dial_code: '+95',
  },
  {
    alpha2: 'NA',
    alpha3: 'NAM',
    dial_code: '+264',
  },
  {
    alpha2: 'NR',
    alpha3: 'NRU',
    dial_code: '+674',
  },
  {
    alpha2: 'NP',
    alpha3: 'NPL',
    dial_code: '+977',
  },
  {
    alpha2: 'NL',
    alpha3: 'NLD',
    dial_code: '+31',
  },
  {
    dial_code: '+599',
  },
  {
    alpha2: 'NC',
    alpha3: 'NCL',
    dial_code: '+687',
  },
  {
    alpha2: 'NZ',
    alpha3: 'NZL',
    dial_code: '+64',
  },
  {
    alpha2: 'NI',
    alpha3: 'NIC',
    dial_code: '+505',
  },
  {
    alpha2: 'NE',
    alpha3: 'NER',
    dial_code: '+227',
  },
  {
    alpha2: 'NG',
    alpha3: 'NGA',
    dial_code: '+234',
  },
  {
    alpha2: 'NU',
    alpha3: 'NIU',
    dial_code: '+683',
  },
  {
    alpha2: 'NF',
    alpha3: 'NFK',
    dial_code: '+672',
  },
  {
    alpha2: 'MP',
    alpha3: 'MNP',
    dial_code: '+1670',
  },
  {
    alpha2: 'NO',
    alpha3: 'NOR',
    dial_code: '+47',
  },
  {
    alpha2: 'OM',
    alpha3: 'OMN',
    dial_code: '+968',
  },
  {
    alpha2: 'PK',
    alpha3: 'PAK',
    dial_code: '+92',
  },
  {
    alpha2: 'PW',
    alpha3: 'PLW',
    dial_code: '+680',
  },
  {
    alpha2: 'PS',
    alpha3: 'PSE',
    dial_code: '+970',
  },
  {
    alpha2: 'PA',
    alpha3: 'PAN',
    dial_code: '+507',
  },
  {
    alpha2: 'PG',
    alpha3: 'PNG',
    dial_code: '+675',
  },
  {
    alpha2: 'PY',
    alpha3: 'PRY',
    dial_code: '+595',
  },
  {
    alpha2: 'PE',
    alpha3: 'PER',
    dial_code: '+51',
  },
  {
    alpha2: 'PH',
    alpha3: 'PHL',
    dial_code: '+63',
  },
  {
    alpha2: 'PN',
    alpha3: 'PCN',
    dial_code: '+872',
  },
  {
    alpha2: 'PL',
    alpha3: 'POL',
    dial_code: '+48',
  },
  {
    alpha2: 'PT',
    alpha3: 'PRT',
    dial_code: '+351',
  },
  {
    alpha2: 'PR',
    alpha3: 'PRI',
    dial_code: '+1939',
  },
  {
    alpha2: 'QA',
    alpha3: 'QAT',
    dial_code: '+974',
  },
  {
    alpha2: 'RO',
    alpha3: 'ROU',
    dial_code: '+40',
  },
  {
    alpha2: 'RU',
    alpha3: 'RUS',
    dial_code: '+7',
  },
  {
    alpha2: 'RW',
    alpha3: 'RWA',
    dial_code: '+250',
  },
  {
    alpha2: 'RE',
    alpha3: 'REU',
    dial_code: '+262',
  },
  {
    alpha2: 'BL',
    alpha3: 'BLM',
    dial_code: '+590',
  },
  {
    alpha2: 'SH',
    alpha3: 'SHN',
    dial_code: '+290',
  },
  {
    alpha2: 'KN',
    alpha3: 'KNA',
    dial_code: '+1869',
  },
  {
    alpha2: 'LC',
    alpha3: 'LCA',
    dial_code: '+1758',
  },
  {
    alpha2: 'MF',
    alpha3: 'MAF',
    dial_code: '+590',
  },
  {
    alpha2: 'PM',
    alpha3: 'SPM',
    dial_code: '+508',
  },
  {
    alpha2: 'VC',
    alpha3: 'VCT',
    dial_code: '+1784',
  },
  {
    alpha2: 'WS',
    alpha3: 'WSM',
    dial_code: '+685',
  },
  {
    alpha2: 'SM',
    alpha3: 'SMR',
    dial_code: '+378',
  },
  {
    alpha2: 'ST',
    alpha3: 'STP',
    dial_code: '+239',
  },
  {
    alpha2: 'SA',
    alpha3: 'SAU',
    dial_code: '+966',
  },
  {
    alpha2: 'SN',
    alpha3: 'SEN',
    dial_code: '+221',
  },
  {
    alpha2: 'RS',
    alpha3: 'SRB',
    dial_code: '+381',
  },
  {
    alpha2: 'SC',
    alpha3: 'SYC',
    dial_code: '+248',
  },
  {
    alpha2: 'SL',
    alpha3: 'SLE',
    dial_code: '+232',
  },
  {
    alpha2: 'SG',
    alpha3: 'SGP',
    dial_code: '+65',
  },
  {
    alpha2: 'SK',
    alpha3: 'SVK',
    dial_code: '+421',
  },
  {
    alpha2: 'SI',
    alpha3: 'SVN',
    dial_code: '+386',
  },
  {
    alpha2: 'SB',
    alpha3: 'SLB',
    dial_code: '+677',
  },
  {
    alpha2: 'SO',
    alpha3: 'SOM',
    dial_code: '+252',
  },
  {
    alpha2: 'ZA',
    alpha3: 'ZAF',
    dial_code: '+27',
  },
  {
    alpha2: 'SS',
    alpha3: 'SSD',
    dial_code: '+211',
  },
  {
    alpha2: 'GS',
    alpha3: 'SGS',
    dial_code: '+500',
  },
  {
    alpha2: 'ES',
    alpha3: 'ESP',
    dial_code: '+34',
  },
  {
    alpha2: 'LK',
    alpha3: 'LKA',
    dial_code: '+94',
  },
  {
    alpha2: 'SD',
    alpha3: 'SDN',
    dial_code: '+249',
  },
  {
    alpha2: 'SR',
    alpha3: 'SUR',
    dial_code: '+597',
  },
  {
    alpha2: 'SJ',
    alpha3: 'SJM',
    dial_code: '+47',
  },
  {
    alpha2: 'SZ',
    alpha3: 'SWZ',
    dial_code: '+268',
  },
  {
    alpha2: 'SE',
    alpha3: 'SWE',
    dial_code: '+46',
  },
  {
    alpha2: 'CH',
    alpha3: 'CHE',
    dial_code: '+41',
  },
  {
    alpha2: 'SY',
    alpha3: 'SYR',
    dial_code: '+963',
  },
  {
    alpha2: 'TW',
    alpha3: 'TWN',
    dial_code: '+886',
  },
  {
    alpha2: 'TJ',
    alpha3: 'TJK',
    dial_code: '+992',
  },
  {
    alpha2: 'TZ',
    alpha3: 'TZA',
    dial_code: '+255',
  },
  {
    alpha2: 'TH',
    alpha3: 'THA',
    dial_code: '+66',
  },
  {
    alpha2: 'TL',
    alpha3: 'TLS',
    dial_code: '+670',
  },
  {
    alpha2: 'TG',
    alpha3: 'TGO',
    dial_code: '+228',
  },
  {
    alpha2: 'TK',
    alpha3: 'TKL',
    dial_code: '+690',
  },
  {
    alpha2: 'TO',
    alpha3: 'TON',
    dial_code: '+676',
  },
  {
    alpha2: 'TT',
    alpha3: 'TTO',
    dial_code: '+1868',
  },
  {
    alpha2: 'TN',
    alpha3: 'TUN',
    dial_code: '+216',
  },
  {
    alpha2: 'TR',
    alpha3: 'TUR',
    dial_code: '+90',
  },
  {
    alpha2: 'TM',
    alpha3: 'TKM',
    dial_code: '+993',
  },
  {
    alpha2: 'TC',
    alpha3: 'TCA',
    dial_code: '+1649',
  },
  {
    alpha2: 'TV',
    alpha3: 'TUV',
    dial_code: '+688',
  },
  {
    alpha2: 'UG',
    alpha3: 'UGA',
    dial_code: '+256',
  },
  {
    alpha2: 'UA',
    alpha3: 'UKR',
    dial_code: '+380',
  },
  {
    alpha2: 'AE',
    alpha3: 'ARE',
    dial_code: '+971',
  },
  {
    alpha2: 'GB',
    alpha3: 'GBR',
    dial_code: '+44',
  },

  {
    alpha2: 'UY',
    alpha3: 'URY',
    dial_code: '+598',
  },
  {
    alpha2: 'UZ',
    alpha3: 'UZB',
    dial_code: '+998',
  },
  {
    alpha2: 'VU',
    alpha3: 'VUT',
    dial_code: '+678',
  },
  {
    alpha2: 'VE',
    alpha3: 'VEN',
    dial_code: '+58',
  },
  {
    alpha2: 'VN',
    alpha3: 'VNM',
    dial_code: '+84',
  },
  {
    alpha2: 'VG',
    alpha3: 'VGB',
    dial_code: '+1284',
  },
  {
    alpha2: 'VI',
    alpha3: 'VIR',
    dial_code: '+1340',
  },
  {
    alpha2: 'WF',
    alpha3: 'WLF',
    dial_code: '+681',
  },
  {
    alpha2: 'YE',
    alpha3: 'YEM',
    dial_code: '+967',
  },
  {
    alpha2: 'ZM',
    alpha3: 'ZMB',
    dial_code: '+260',
  },
  {
    alpha2: 'ZW',
    alpha3: 'ZWE',
    dial_code: '+263',
  },
];

export default countryCodes;
