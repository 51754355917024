import Compressor from 'compressorjs';

const imageCompressor = async (file, maxWidth, quality) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: quality || 0.8,
      maxWidth: maxWidth * 2 || Infinity,

      success(result) {
        // convert blob back to file
        const compressedFile = new File([result], file.name, {
          type: file.type,
          lastModified: Date.now(),
        });

        resolve(compressedFile);
      },
      error(err) {
        reject(err);
      },
    });
  });
};

export default imageCompressor;
