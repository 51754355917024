import EvoMissionsKeys from '../translationKeys/EvoMissionsPage';

export const EvoMissionsTranslations = {
  [EvoMissionsKeys.claim_evos]: 'Claim EVOs',
  [EvoMissionsKeys.my_evos]: 'My Evos',
  [EvoMissionsKeys.special_missions]: 'Special Missions',
  [EvoMissionsKeys.creator_missions]: 'Creator Missions',
  [EvoMissionsKeys.general_missions]: 'General Missions',
  [EvoMissionsKeys.successfully_claimed]: 'Successfully claimed!',
  [EvoMissionsKeys.go_back_home]: 'GO TO MY PROFILE',

  // Token Details
  [EvoMissionsKeys.Token_Details._1k]: '1k',
  [EvoMissionsKeys.Token_Details._10k]: '10k',
  [EvoMissionsKeys.Token_Details.no_data]: 'No data to display',
  [EvoMissionsKeys.Token_Details.results_found]:
    'Total {{number}} results found',
  [EvoMissionsKeys.Token_Details.result_found]: 'Total {{number}} result found',

  // Side Popup
  [EvoMissionsKeys.Side_Popup.text]: 'What is EVO?',

  // Transaction
  [EvoMissionsKeys.Transaction.daily_reward]: 'Daily rewards',
  [EvoMissionsKeys.Transaction.price_unlock_received]:
    'Sent by client - Price unlock',
  [EvoMissionsKeys.Transaction.price_unlock_spent]: 'Price unlock',
  [EvoMissionsKeys.Transaction.referral_reward]: 'Referral Reward',
  [EvoMissionsKeys.Transaction.creator_registration_reward]:
    'Creator Registration Reward',

  [EvoMissionsKeys.Transaction.complete_1_special_completed]:
    'Complete Special Missions',
  [EvoMissionsKeys.Transaction.complete_777_special_completed]:
    'Complete Special Missions',
  [EvoMissionsKeys.Transaction.complete_77_special_completed]:
    'Complete Special Missions',
  [EvoMissionsKeys.Transaction.complete_7_special_completed]:
    'Complete Special Missions',
  [EvoMissionsKeys.Transaction.create_premium_package_completed]:
    'Create a Premium Package',
  [EvoMissionsKeys.Transaction.first_990_creator_claimed_completed]:
    'Early 990 VIPs',
  [EvoMissionsKeys.Transaction.refer_1000_early_vips_completed]:
    'Early VIP Referrals',
  [EvoMissionsKeys.Transaction.refer_100_early_vips_completed]:
    'Early VIP Referrals',
  [EvoMissionsKeys.Transaction.refer_10_early_vips_completed]:
    'Early VIP Referrals',
  [EvoMissionsKeys.Transaction.referral_ranking_completed]:
    'VIP Referral Rankings',
  [EvoMissionsKeys.Transaction.special_daily_reward_completed]:
    'Special Daily Reward',
  [EvoMissionsKeys.Transaction.tv_easter_egg_completed]:
    'Find the Easter Egg on the platform',
  [EvoMissionsKeys.Transaction.update_creator_profile_completed]:
    'Update Creator Profile',
  [EvoMissionsKeys.Transaction.update_profile_completed]: 'Update Profile',
  [EvoMissionsKeys.Transaction.visit_main_tabs_completed]: 'Visit All Main Tab',

  // Special Mission
  // Early 990 VIPs
  [EvoMissionsKeys.Special.early_990_vips.title]: 'Early 990 VIPs',
  [EvoMissionsKeys.Special.early_990_vips.description]:
    'You are our early VIP influencer! Register as a creator now and unlock our premium pass. Take your spot!',

  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d1]:
    'Get orders without platform service',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d2]:
    'fees with this Premium Pass!',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.d3]: '',
  [EvoMissionsKeys.Special.early_990_vips.Tooltip.heading]:
    ' *Third party fees such as PayPal & credit card may still apply.',

  // General
  [EvoMissionsKeys.complete_1_special.title]: 'Complete Special Missions',
  [EvoMissionsKeys.complete_1_special.description]:
    'Get a little bonus for completing your special missions',
  [EvoMissionsKeys.complete_1_special.extra_reward]: '',
  [EvoMissionsKeys.complete_1_special.reward_description]: '',

  [EvoMissionsKeys.complete_777_special.title]: 'Complete Special Missions',
  [EvoMissionsKeys.complete_777_special.description]:
    'Get a little bonus for completing your special missions',
  [EvoMissionsKeys.complete_777_special.extra_reward]: '',
  [EvoMissionsKeys.complete_777_special.reward_description]: '',

  [EvoMissionsKeys.complete_77_special.title]: 'Complete Special Missions',
  [EvoMissionsKeys.complete_77_special.description]:
    'Get a little bonus for completing your special missions',
  [EvoMissionsKeys.complete_77_special.extra_reward]: '',
  [EvoMissionsKeys.complete_77_special.reward_description]: '',

  [EvoMissionsKeys.complete_7_special.title]: 'Complete Special Missions',
  [EvoMissionsKeys.complete_7_special.description]:
    'Get a little bonus for completing your special missions',
  [EvoMissionsKeys.complete_7_special.extra_reward]: '',
  [EvoMissionsKeys.complete_7_special.reward_description]: '',

  [EvoMissionsKeys.tv_easter_egg.title]: 'Find the Easter Egg on the platform!',
  [EvoMissionsKeys.tv_easter_egg.description]:
    "Find the Easter Egg in the home page. It's somewhere",
  [EvoMissionsKeys.tv_easter_egg.extra_reward]: '',
  [EvoMissionsKeys.tv_easter_egg.reward_description]: '',

  [EvoMissionsKeys.update_profile.title]: 'Update Profile',
  [EvoMissionsKeys.update_profile.description]:
    'Join us on this journey! Update your profile and unlock this reward.',
  [EvoMissionsKeys.update_profile.extra_reward]: '',
  [EvoMissionsKeys.update_profile.reward_description]: '',

  [EvoMissionsKeys.visit_main_tabs.title]: 'Visit All Main Tab',
  [EvoMissionsKeys.visit_main_tabs.description]:
    'Visit all four tabs on our upper navbar to unlock this reward. Discover new things with us!',
  [EvoMissionsKeys.visit_main_tabs.extra_reward]: '',
  [EvoMissionsKeys.visit_main_tabs.reward_description]: '',

  // Creator
  [EvoMissionsKeys.create_premium_package.title]: 'Create a Premium Package',
  [EvoMissionsKeys.create_premium_package.description]:
    'Create a premium package on your profile. Use our template or ChatGPT features to showcase your best service!',
  [EvoMissionsKeys.create_premium_package.extra_reward]: '',
  [EvoMissionsKeys.create_premium_package.reward_description]: '',

  [EvoMissionsKeys.update_creator_profile.title]: 'Update Creator Profile',
  [EvoMissionsKeys.update_creator_profile.description]:
    'Ready to become a global superstar? Update your profile and let the world know about your amazing channel!',
  [EvoMissionsKeys.update_creator_profile.extra_reward]: '',
  [EvoMissionsKeys.update_creator_profile.reward_description]: '',

  // Special
  [EvoMissionsKeys.first_990_creator_claimed.title]:
    'Early 990 VIP Influencers!',
  [EvoMissionsKeys.first_990_creator_claimed.description]:
    'You are our early VIP influencer! Register as a creator now and update your profile with at least one package. Take your spot!',
  [EvoMissionsKeys.first_990_creator_claimed.extra_reward]:
    'Premium Creator Pass',
  [EvoMissionsKeys.first_990_creator_claimed.reward_description]:
    'Creators with a premium pass are',

  [EvoMissionsKeys.refer_1000_early_vips.title]: 'Early VIP Referrals',
  [EvoMissionsKeys.refer_1000_early_vips.description]:
    'Refer 1000 or more people to claim this reward!',
  [EvoMissionsKeys.refer_1000_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_1000_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_100_early_vips.title]: 'Early VIP Referrals',
  [EvoMissionsKeys.refer_100_early_vips.description]:
    'Refer 100 or more people to claim this reward!',
  [EvoMissionsKeys.refer_100_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_100_early_vips.reward_description]: '',

  [EvoMissionsKeys.refer_10_early_vips.title]: 'Early VIP Referrals',
  [EvoMissionsKeys.refer_10_early_vips.description]:
    'Refer 10 or more people to claim this reward!',
  [EvoMissionsKeys.refer_10_early_vips.extra_reward]: '',
  [EvoMissionsKeys.refer_10_early_vips.reward_description]: '',

  [EvoMissionsKeys.referral_ranking.title]: 'VIP Referral Rankings',
  [EvoMissionsKeys.referral_ranking.description]:
    'For each percentile in the referral ranking, you will be awared extra EVO points at the end of the month.',
  [EvoMissionsKeys.referral_ranking.extra_reward]: '',
  [EvoMissionsKeys.referral_ranking.reward_description]: '',

  [EvoMissionsKeys.special_daily_reward.title]: 'Special Daily Reward',
  [EvoMissionsKeys.special_daily_reward.description]:
    'Come and claim your daily reward every day for a week.',
  [EvoMissionsKeys.special_daily_reward.extra_reward]: '',
  [EvoMissionsKeys.special_daily_reward.reward_description]: '',

  [EvoMissionsKeys.Modal.already_unlock]: 'You have already unlocked',
  [EvoMissionsKeys.Modal.easter_egg]: 'this Easter Egg!',
  [EvoMissionsKeys.Modal.unlock_premium_pass]: 'You unlocked a premium pass.',
  [EvoMissionsKeys.Modal.visit_profile]: 'Visit My Profile',
  [EvoMissionsKeys.Modal.not_now]: 'Not now',
};
