import Stack from 'components/Basics/Layout/Stack';
import { motion } from 'framer-motion';
import { random } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const OrderSkeleton = ({ id }) => {
  return (
    <Wrapper layoutId={`order-skeleton-${id}`}>
      <Skeleton width={112} height={63} borderRadius={4} />

      <Stack gap="0.375rem" className="flex-1">
        <Title>
          <Skeleton width={random(70, 320)} height={10} count={random(1, 2)} />
        </Title>

        <Stack direction="row" align="center" justify="space-between" gap="0">
          <Stack direction="row" align="center" gap="0.5rem">
            <Skeleton width={16} height={16} />

            <Skeleton width={random(20, 100)} height={8} />
          </Stack>

          <Skeleton width={random(50, 80)} height={10} />
        </Stack>
      </Stack>
    </Wrapper>
  );
};

export default OrderSkeleton;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
})`
  width: 100%;
  padding: 1rem;
  padding-bottom: calc(1rem - 1px);
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  border-bottom: 1px solid var(--color-indigo-25);
  background: #fff;
  transition: background 150ms ease-in-out;

  &:hover {
    background: var(--color-pink-75);
  }
`;

const Title = styled.div`
  min-height: 38px;
`;
