const size4 = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4893 6.77491L19.3176 0H17.9365L12.8758 5.88256L8.8338 0H4.17188L10.2841 8.89547L4.17188 16H5.55307L10.8973 9.78782L15.1659 16H19.8278L13.4893 6.77491ZM11.5976 8.97384L10.9783 8.08805L6.05073 1.03974H8.17217L12.1487 6.72795L12.768 7.61374L17.9371 15.0075H15.8157L11.5976 8.97384Z"
      fill="black"
    />
  </svg>
);
export default size4;
