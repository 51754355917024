import styled from 'styled-components';

const TextButton = styled.button`
  background: none;
  border: none;
  padding: 0;

  font-family: Kumbh Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  transition: opacity 150ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;

export default TextButton;
