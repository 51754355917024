const size4 = () => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0295 2.50544C22.8971 2.02114 22.6389 1.57963 22.2805 1.22488C21.9221 0.870128 21.4761 0.614509 20.9869 0.483481C19.1959 0 11.9881 0 11.9881 0C11.9881 0 4.78014 0.0146721 2.98936 0.498153C2.50017 0.629202 2.0542 0.884831 1.69585 1.23958C1.3375 1.59433 1.07928 2.03583 0.946904 2.52011C0.405167 5.67042 0.194994 10.4704 0.961725 13.4946C1.0941 13.9788 1.35232 14.4203 1.71067 14.7751C2.06902 15.1298 2.51499 15.3855 3.00418 15.5165C4.7951 16 12.003 16 12.003 16C12.003 16 19.2108 16 21.0017 15.5165C21.4909 15.3855 21.9369 15.1298 22.2953 14.7751C22.6536 14.4203 22.9118 13.9788 23.0442 13.4946C23.6154 10.3399 23.7916 5.54284 23.0294 2.50558L23.0295 2.50544Z"
      fill="#FF0000"
    />
    <path
      d="M9.69141 11.4314L15.6708 8.00288L9.69141 4.57422L9.69141 11.4314Z"
      fill="white"
    />
  </svg>
);

export default size4;
