import { createContext, useContext } from 'react';

const SnippetValuesContext = createContext();

const SnippetValuesProvider = ({ values, children }) => {
  return (
    <SnippetValuesContext.Provider value={values || {}}>
      {children}
    </SnippetValuesContext.Provider>
  );
};

const useSnippetValues = () => useContext(SnippetValuesContext);

export { SnippetValuesProvider, useSnippetValues };
