import Footer from 'components/Basics/Layout/Footer';
import Header from 'components/Basics/Layout/Header';
import LazyImage from 'components/Basics/LazyImage';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import styled from 'styled-components';
import device from 'styles/device';

const ErrorFallbackPage = () => {
  const { isMobile } = useResponsiveScreen();

  return (
    <Page>
      <Header
        defaultBackground="var(--color-white)"
        defaultIsAbsolute={false}
        defaultIsVisible={true}
        defaultIsWhite={false}
        defaultHaveLinks={true}
      />

      <Container>
        <Title>Oops! Something went wrong</Title>

        <Text>
          We will investigate this issue. Please{' '}
          <a href="mailto:info@megaevolution.io">contact support</a> if the
          problem persists.
        </Text>

        <LazyImage
          src="/img/misc/Thinking Astronaut.png"
          lazySrc="/img/misc/Thinking Astronaut_small.png"
          alt="Thinking Astronaut"
          width={isMobile ? '247px' : '720px'}
        />
      </Container>

      <Footer defaultBackground="transparent" defaultIsVisible={true} />
    </Page>
  );
};

export default ErrorFallbackPage;

const Page = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div.attrs({
  className: 'container',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.25rem;
  padding-block: 3.125rem;
  flex: 1;
  text-align: center;
`;

const Title = styled.h1.attrs({
  className: 'ebh1',
})`
  @media (${device.sm}) {
    font-size: 1.25rem;
  }
`;

const Text = styled.p.attrs({
  className: 'sbh6',
})`
  @media (${device.md}) {
    font-family: Poppins, sans-serif;
    font-size: 1.5rem;
    font-weight: medium;
    letter-spacing: 0.15px;
    line-height: 1.7;
  }

  a {
    color: var(--color-indigo-500);
    text-decoration: underline;
    transition: all 150ms ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      outline: none;
    }
  }
`;
