export const TagIcon = ({ size = 1 }) => (
  <svg
    width={size * 18}
    height={size * 18}
    viewBox="0 0 18 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.2103 7.84004L9.64028 0.261049C9.55617 0.177625 9.45643 0.111624 9.34676 0.0668297C9.23709 0.0220357 9.11966 -0.000669849 9.0012 1.50448e-05H0.900133C0.661407 1.50448e-05 0.432458 0.0948486 0.263653 0.263653C0.0948486 0.432458 1.50448e-05 0.661407 1.50448e-05 0.900133V9.0012C-0.000669849 9.11966 0.0220357 9.23709 0.0668297 9.34676C0.111624 9.45643 0.177625 9.55617 0.261049 9.64028L7.84004 17.2103C8.34636 17.716 9.0327 18 9.74829 18C10.4639 18 11.1502 17.716 11.6565 17.2103L17.2103 11.7016C17.716 11.1952 18 10.5089 18 9.7933C18 9.0777 17.716 8.39137 17.2103 7.88505V7.84004ZM15.9411 10.3784L10.3784 15.9321C10.2097 16.0998 9.98159 16.1939 9.74379 16.1939C9.50599 16.1939 9.27786 16.0998 9.10921 15.9321L1.80025 8.63215V1.80025H8.63215L15.9411 9.10921C16.0245 9.19332 16.0905 9.29306 16.1353 9.40273C16.1801 9.5124 16.2028 9.62983 16.2021 9.74829C16.2011 9.98443 16.1074 10.2107 15.9411 10.3784Z" />
  </svg>
);
