export const GlobalFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 33 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.0243 11.004C32.0243 11.004 32.0243 11.004 32.0243 10.9212C30.8594 7.73773 28.7457 4.98916 25.9691 3.04735C23.1926 1.10555 19.8872 0.064209 16.5 0.064209C13.1128 0.064209 9.80741 1.10555 7.03085 3.04735C4.25429 4.98916 2.1406 7.73773 0.975694 10.9212C0.975694 10.9212 0.975694 10.9212 0.975694 11.004C-0.325231 14.5964 -0.325231 18.532 0.975694 22.1244C0.975694 22.1244 0.975694 22.1244 0.975694 22.2072C2.1406 25.3907 4.25429 28.1393 7.03085 30.0811C9.80741 32.0229 13.1128 33.0642 16.5 33.0642C19.8872 33.0642 23.1926 32.0229 25.9691 30.0811C28.7457 28.1393 30.8594 25.3907 32.0243 22.2072C32.0243 22.2072 32.0243 22.2072 32.0243 22.1244C33.3252 18.532 33.3252 14.5964 32.0243 11.004ZM3.67054 19.8739C3.09723 17.7047 3.09723 15.4237 3.67054 13.2546H6.74564C6.48117 15.453 6.48117 17.6754 6.74564 19.8739H3.67054ZM5.02623 23.1835H7.34083C7.72888 24.6594 8.28355 26.0862 8.99411 27.4364C7.37273 26.3309 6.01737 24.8782 5.02623 23.1835ZM7.34083 9.9449H5.02623C6.00311 8.25546 7.34114 6.80315 8.94451 5.69199C8.25105 7.04439 7.71305 8.47114 7.34083 9.9449ZM14.8137 29.3064C12.783 27.8149 11.3569 25.6413 10.7962 23.1835H14.8137V29.3064ZM14.8137 19.8739H10.0853C9.77675 17.6782 9.77675 15.4502 10.0853 13.2546H14.8137V19.8739ZM14.8137 9.9449H10.7962C11.3569 7.4871 12.783 5.31356 14.8137 3.82204V9.9449ZM27.9076 9.9449H25.593C25.205 8.46906 24.6503 7.04223 23.9398 5.69199C25.5611 6.79753 26.9165 8.25026 27.9076 9.9449ZM18.1202 3.82204C20.1508 5.31356 21.577 7.4871 22.1377 9.9449H18.1202V3.82204ZM18.1202 29.3064V23.1835H22.1377C21.577 25.6413 20.1508 27.8149 18.1202 29.3064ZM22.8486 19.8739H18.1202V13.2546H22.8486C23.1571 15.4502 23.1571 17.6782 22.8486 19.8739ZM23.9894 27.4364C24.6999 26.0862 25.2546 24.6594 25.6426 23.1835H27.9572C26.9661 24.8782 25.6107 26.3309 23.9894 27.4364ZM29.2633 19.8739H26.1882C26.3227 18.7758 26.3889 17.6705 26.3866 16.5642C26.3884 15.458 26.3222 14.3526 26.1882 13.2546H29.2633C29.8366 15.4237 29.8366 17.7047 29.2633 19.8739Z"
      fill="#26B9E8"
    />
  </svg>
);
