import styled from 'styled-components';
import { forwardRef } from 'react';

const Button = styled.button`
  border: none;
  padding: ${({ padding, size }) =>
    padding
      ? padding
      : size === 'ms'
      ? '0.15625rem 0.625rem'
      : size === 'mm'
      ? '7.5px 0.875rem'
      : size === 'xs'
      ? '0.46875rem 1rem'
      : size === 'sm'
      ? '9px 1.375rem'
      : size === 'md'
      ? '0.875rem 37px'
      : size === 'lg'
      ? '15px 45px'
      : size === 'lm'
      ? '1.25rem 57.5px'
      : size === 'lms'
      ? '1.25rem 45px'
      : '1.375rem 61px'};
  border-radius: ${({ size, borderRadius }) =>
    borderRadius
      ? borderRadius
      : size === 'ms' || size === 'lms'
      ? '10px'
      : size === 'sm' || size === 'mm'
      ? '20px'
      : size === 'md'
      ? '30px'
      : size === 'lm'
      ? '40px'
      : '24px'};
  background: var(--color-indigo-500);
  color: var(--color-white);
  box-shadow: ${({ shadow, size }) =>
    shadow || size === 'ms' || size === 'mm' || size === 'xs'
      ? 'var(--shadow-card-small)'
      : 'var(--shadow-yellow-btn)'};
  margin: ${({ margin }) => margin || '0'};
  transition: all 150ms ease-in-out;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.625rem;
  cursor: pointer;

  &:hover {
    background: var(--color-indigo-300);
  }

  &:active {
    outline: none;
    background: var(--color-indigo-300);
    box-shadow: none;
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &:disabled:hover {
    background: var(--color-indigo-50);
    box-shadow: none;
  }
`;

/**
 * @param {string} [size] - The size of the button.
 * - ms
 * - mm
 * - xs
 * - sm
 * - md
 * - lg
 * - lm
 * - xl
 * @param {string} [as] - The tag name of the button. (optional)
 * - button
 * - a
 * - Link
 * @param {Node} children - The content of the button.
 * @param {string} [className] - The additional class name of the button.
 * @param {string} [padding] - Set padding for the button
 * @additional The rest of the props are passed to the underlying component.
 */
const IndigoRoundButton = forwardRef(
  (
    {
      size = 'md',
      as = 'button',
      children,
      className,
      fontSize,
      shadow,
      margin,
      padding,
      borderRadius,
      ...rest
    },
    ref
  ) => {
    return (
      <Button
        as={as}
        size={size}
        ref={ref}
        className={`${
          fontSize ||
          (size === 'ms'
            ? 'sbh8'
            : size === 'mm'
            ? 'sbh8'
            : size === 'xs'
            ? 'sbh6'
            : size === 'sm'
            ? 'sbh5'
            : size === 'md'
            ? 'sbh4'
            : size === 'lg' || size === 'lm' || size === 'lms'
            ? 'sbh3'
            : 'sbh2')
        } ${className || ''}`}
        shadow={shadow}
        padding={padding}
        margin={margin}
        borderRadius={borderRadius}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);

export default IndigoRoundButton;
