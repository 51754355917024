export const BNBIcon = ({ size = 1 }) => (
  <svg
    width={20 * size}
    height={21 * size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.94286 20.331C15.4341 20.331 19.8857 15.8795 19.8857 10.3882C19.8857 4.89688 15.4341 0.445312 9.94286 0.445312C4.45157 0.445312 0 4.89688 0 10.3882C0 15.8795 4.45157 20.331 9.94286 20.331Z"
      fill="#EDD6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.68419 9.1894L10.1353 6.7394L12.5873 9.19139L14.0127 7.76505L10.1353 3.88672L6.2578 7.76405L7.68419 9.1894ZM3.82422 10.197L5.25008 8.77121L6.67595 10.197L5.25008 11.6229L3.82422 10.197ZM10.1334 13.655L7.68234 11.204L6.25396 12.6284L6.25595 12.6304L10.1334 16.5067L14.0109 12.6284L14.0119 12.6274L12.5855 11.203L10.1334 13.655ZM13.5938 10.1972L15.0197 8.77135L16.4455 10.1972L15.0197 11.623L13.5938 10.1972ZM10.1359 8.74892L11.5822 10.1962H11.5832L11.5822 10.1972L10.1359 11.6445L8.68955 10.1992L8.68755 10.1962L8.68955 10.1942L8.94273 9.94103L9.06633 9.81843L10.1359 8.74892Z"
      fill="#0E0D39"
    />
  </svg>
);
