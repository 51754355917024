import { EnvelopeIcon, VolumeMuteIcon, VolumeUpIcon } from 'assets/icons';
import IconButton from 'components/Basics/Buttons/IconButton';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useRef } from 'react';
import { useClickAway, useToggle } from 'react-use';
import styled from 'styled-components';
import NotificationBox from '../Common/NotificationBox';
import NoMessages from './NoMessages';
import MessageItem from './MessageItem';
import { useChatList } from 'components/components/MessagesPage/ChatsList/ChatListContext';
import MessageSkeleton from './MessageSkeleton';
import { useNotificationSound } from 'contexts/NotificationSoundContext';
import { useTranslation } from 'react-i18next';
import MessageKeys from 'translations/translationKeys/MessageKeys';

const Messages = () => {
  const { t } = useTranslation();
  const [showNotificationBox, toggleNotificationBox] = useToggle(false);
  const { isSoundOn, toggleSound } = useNotificationSound();
  const ref = useRef(null);
  useClickAway(ref, () => {
    toggleNotificationBox(false);
  });

  const { chats, readChat, loadingChats } = useChatList();
  const unreadCount = useMemo(
    () => chats.filter((chat) => chat.unreadCount > 0).length,
    [chats]
  );

  return (
    <>
      {showNotificationBox && <Backdrop />}

      <Wrapper ref={ref}>
        <IconButton
          className="text-inherit"
          onClick={() => {
            toggleNotificationBox();
          }}
        >
          <EnvelopeIcon small={false} />

          <AnimatePresence>{unreadCount > 0 && <Dot />}</AnimatePresence>
        </IconButton>

        <AnimatePresence>
          {showNotificationBox && (
            <NotificationBox
              title={`${t(MessageKeys.Dropdown.title)} ${
                unreadCount > 0 ? `(${unreadCount})` : ''
              }`}
              renderFooter={
                <>
                  <IconButton onClick={toggleSound} className="text-indigo-200">
                    {isSoundOn ? <VolumeUpIcon /> : <VolumeMuteIcon />}
                  </IconButton>

                  <TextButton
                    onClick={() => {
                      window.open('/messages', '_blank');
                    }}
                  >
                    {t(MessageKeys.Dropdown.bottom_btn)}
                  </TextButton>
                </>
              }
              translateX="-78%"
            >
              <AnimatePresence mode="wait">
                {loadingChats ? (
                  Array(10)
                    .fill()
                    .map((_, i) => <MessageSkeleton key={i} />)
                ) : chats.length > 0 ? (
                  chats.map((chat) => {
                    return (
                      <MessageItem
                        key={chat.id}
                        id={chat.id}
                        pic={chat.user.pic}
                        userName={chat.user.name}
                        userId={chat.user.id}
                        userHandle={chat.user.handle}
                        isRead={chat.unreadCount <= 0}
                        text={t(chat.message.content.text)}
                        date={chat.message.date}
                        navigateTo={`/messages/${
                          chat.user.handle
                            ? `@${chat.user.handle}`
                            : chat.user.id
                        }`}
                        readChat={() => {
                          readChat(chat.id);
                        }}
                        closeNotificationBox={() => {
                          toggleNotificationBox(false);
                        }}
                        sender={chat.message?.sender}
                      />
                    );
                  })
                ) : (
                  <NoMessages key="no-messages" />
                )}
              </AnimatePresence>
            </NotificationBox>
          )}
        </AnimatePresence>
      </Wrapper>
    </>
  );
};

export default Messages;

const Wrapper = styled.div`
  position: relative;
`;

const TextButton = styled.button.attrs({
  className: 'sbh6.5',
})`
  background: none;
  border: none;
  padding: 0;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

const Dot = styled(motion.span).attrs({
  initial: { scale: 0 },
  animate: { scale: 1 },
  exit: { scale: 0 },
  transition: { duration: 0.2 },
})`
  position: absolute;
  top: -6px;
  right: -8px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--color-red-500);
`;

const Backdrop = styled.div.attrs({
  onMouseOver: (e) => e.stopPropagation(),
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
