import ProposalKeys from '../translationKeys/ProposalPageKeys';

export const ProposalPageTranslations = {
  [ProposalKeys.saving]: 'guardando',
  [ProposalKeys.changes_saved]: 'cambios guardados',

  [ProposalKeys.New_Proposal.text]: 'Nueva propuesta',
  [ProposalKeys.New_Proposal.toast]:
    'Ha alcanzado el número máximo de propuestas que puede crear.',

  [ProposalKeys.Clear.text]: 'Limpiar todo',
  [ProposalKeys.Clear.toast]:
    'Se ha limpiado con éxito. Haga clic aquí para deshacer este cambio.',

  [ProposalKeys.Modal.Copy.title]:
    '¿Estás seguro de que deseas sobrescribir la propuesta?',
  [ProposalKeys.Modal.Copy.description]: `
    La propuesta actual será sobrescrita por la propuesta seleccionada. <br>
    Esta acción no se puede deshacer. ¿Deseas continuar?`,

  [ProposalKeys.Part1.Option_1.title]: 'Título',
  [ProposalKeys.Part1.Option_1.placeholder]: 'Ingrese el título del proyecto',
  [ProposalKeys.Part1.Option_1.error]: 'Máximo 100 caracteres',

  [ProposalKeys.Part1.Option_2.title]: 'Producto · Sitio web de la marca',
  [ProposalKeys.Part1.Option_2.placeholder]: 'Ingrese una URL',
  [ProposalKeys.Part1.Option_2.error]: 'Máximo 400 caracteres',

  [ProposalKeys.Part1.Option_3.title]: 'Descripción y archivos',
  [ProposalKeys.Part1.Option_3.placeholder]: `
    Por favor, describa los detalles del proyecto y adjunte sus archivos aquí. Los creadores pueden buscar información sobre, <br><br><br>
    <ul>
    <li>Descripción del proyecto/producto</li>
    <li>Objetivos de la campaña y público objetivo</li>
    <li>Directrices detalladas para el contenido</li>
    </ul>`,
  [ProposalKeys.Part1.Option_3.Snippet.title]:
    'Seleccione un fragmento para agregar',
  [ProposalKeys.Part1.Option_3.Snippet.option_1]:
    'Nombre del canal social del creador',
  [ProposalKeys.Part1.Option_3.Snippet.option_2]:
    'Nombre de usuario del creador',
  [ProposalKeys.Part1.Option_3.Snippet.option_3]: 'País principal del creador',
  [ProposalKeys.Part1.Option_3.Snippet.option_4]:
    'Categorías principales del creador',

  [ProposalKeys.Part1.Option_3.Error.file_format]:
    'Formato de archivo no válido. (Por favor, use {{fileFormats}})',
  [ProposalKeys.Part1.Option_3.Error.file_size_limit]:
    'El límite total de tamaño de archivo es de {{maxSize}}',
  [ProposalKeys.Part1.Option_3.Error.max_file]:
    'Puede enviar un máximo de {{maxFiles}} archivos',

  [ProposalKeys.caution]: `
  Al ordenar un paquete, todos los <strong>requisitos y misiones de contenido</strong> aplicables se aplicarán automáticamente a cada contenido. Los requisitos y misiones incompletos o no aplicables al contenido ordenado no se aplicarán.`,

  [ProposalKeys.Side_btn.text]: 'Contenidos aplicables',
  [ProposalKeys.Side_btn.title]: 'Contenidos aplicables: ',

  [ProposalKeys.Part2.title]: 'Enviar un producto al creador',

  [ProposalKeys.Part2.Option_1.title]: 'Enviar productos patrocinados',
  [ProposalKeys.Part2.Option_1.tooltip]: `
  Esta opción está incompleta y no se aplicará a las propuestas que se envíen.`,

  [ProposalKeys.Part2.Option_1.Image.title]: 'Imágenes de entrega (opcional)',
  [ProposalKeys.Part2.Option_1.Image.description]:
    'Agregue hasta 5 archivos de imágenes en formato JPG, JPEG, PNG, GIF. Máximo 100 MB.',

  [ProposalKeys.Part2.Option_1.Info.title]:
    'Información de entrega (requerida)',
  [ProposalKeys.Part2.Option_1.Info.placeholder]:
    'Proporcione información sobre el producto y el envío.',

  [ProposalKeys.Part2.Option_1.caution_1]:
    'Recibirá la dirección de envío del creador para la entrega del producto. La creación de contenido comenzará una vez que llegue el producto, y la fecha de vencimiento se actualizará en consecuencia.',
  [ProposalKeys.Part2.Option_1.caution_2]:
    'No procesamos devoluciones de productos entregados. Si su pedido se canceló después de que se enviara el producto, deberá discutir la devolución directamente con el creador.',
  [ProposalKeys.Part2.Option_1.caution_3]: `
  Ayudamos a rastrear la entrega internacional, pero no garantizamos ni cubrimos problemas de envío como entregas incorrectas.
  <Tooltip>Entrega nacional</Tooltip> solo se puede rastrear en algunos países, pero aún puede incluirla en el pedido.`,
  [ProposalKeys.Part2.Option_1.caution_3_tooltip]: `
  Soportamos el rastreo nacional para transportistas principales<br>
  en países limitados, incluyendo Estados Unidos y Canadá.<br>
  Actualmente no soportamos el rastreo nacional<br>
  para otros países.`,

  [ProposalKeys.Part3.title]: 'Requisitos',
  [ProposalKeys.Part3.applied]: '{{number}} aplicados',

  [ProposalKeys.Part3.Option_1.title]: 'Indicar promoción pagada',
  [ProposalKeys.Part3.Option_1.description_1]:
    'El creador colocará uno de los siguientes hashtags: #Ad, #Advertisement o #Sponsored al comienzo de la lista de hashtags para indicar claramente la promoción pagada.',
  [ProposalKeys.Part3.Option_1.description_2]:
    'Añadir la etiqueta "Incluye promoción pagada" proporcionada por YouTube dentro del video.',
  [ProposalKeys.Part3.Option_1.description_3]:
    'Añadir la etiqueta "Colaboración remunerada" proporcionada por Instagram dentro de la publicación/historia/reels de Instagram.',

  [ProposalKeys.Part3.Option_2.placeholder]:
    'Por favor, introduzca sus términos y condiciones aquí.',
  [ProposalKeys.Part3.Option_2.description]:
    'Se requerirá que el creador cumpla con sus Términos y Condiciones para la prestación del servicio. Se puede solicitar una revisión o desactivación de esta opción si entra en conflicto con el servicio del creador.',

  [ProposalKeys.Part4.title]: 'Misiones de contenido',

  [ProposalKeys.Part4.Option_1.title]: 'Incluir un enlace',
  [ProposalKeys.Part4.Option_1.placeholder]: 'Ingrese una URL',
  [ProposalKeys.Part4.Option_2.btn]: 'Agregar este hashtag',
  [ProposalKeys.Part4.Option_1.description]:
    'El enlace proporcionado se incluirá en la descripción de la publicación o el video. Úselo para dirigir más tráfico a un sitio web o aplicación.',

  [ProposalKeys.Part4.Option_2.title]: 'Incluir hashtags',
  [ProposalKeys.Part4.Option_2.placeholder]: 'Ingrese un hashtag',
  [ProposalKeys.Part4.Option_2.description]:
    'Se incluirá al menos uno o más de los hashtags de la izquierda en la publicación o en la descripción del video.',

  [ProposalKeys.Part4.Option_3.title]: 'Etiquetar una ubicación',
  [ProposalKeys.Part4.Option_3.placeholder]: 'Ingrese una ubicación',
  [ProposalKeys.Part4.Option_3.description]:
    'La ubicación proporcionada se etiquetará en la publicación o el video. Efectivo para promover una ubicación o lugar específico.',

  [ProposalKeys.Part4.Option_4.title]: 'Etiquetar nuestra cuenta',
  [ProposalKeys.Part4.Option_4.placeholder]: 'Su identificador',
  [ProposalKeys.Part4.Option_4.description]:
    'La cuenta @handle se incluirá en la descripción de la publicación o el video. (Solo se aplicarán los identificadores correspondientes a la plataforma del contenido ordenado).',

  [ProposalKeys.Part4.Option_5.title]: 'Fijar un comentario con nuestra cuenta',
  [ProposalKeys.Part4.Option_5.description]:
    'Se publicará un comentario que incluye la cuenta @handle proporcionada y se fijará en el contenido. (Solo se aplicarán los identificadores correspondientes a la plataforma del contenido ordenado).',

  [ProposalKeys.Part4.Option_6.title]: 'Fijar un comentario con un enlace',
  [ProposalKeys.Part4.Option_6.placeholder]: 'Ingrese una URL',
  [ProposalKeys.Part4.Option_6.description]:
    'Se publicará un comentario que incluye el enlace proporcionado y se fijará en el contenido.',

  [ProposalKeys.Part4.Option_7.title]: 'Incluir imágenes específicas',
  [ProposalKeys.Part4.Option_7.description]:
    'Se guiará al creador para incluir al menos una o más de las imágenes proporcionadas en el contenido.',

  [ProposalKeys.Part4.Option_8.title]: 'Agregar etiquetas a los videos',
  [ProposalKeys.Part4.Option_8.placeholder]: 'Ingrese una etiqueta de video',
  [ProposalKeys.Part4.Option_8.btn]: 'Agregar esta etiqueta',
  [ProposalKeys.Part4.Option_8.description]:
    'Se agregarán al menos una o más de las etiquetas de la izquierda al video de YouTube para la optimización de la búsqueda.',
};
