import Loading from 'components/components/Common/Loading';
import useUser from 'hooks/useUser';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ adminOnly, children }) => {
  const { isLoggedIn, user, isLoading } = useUser();

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  if (adminOnly && !user?.isAdmin) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
