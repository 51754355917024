export const CoinBaseCircleIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 18}
      height={size * 18}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_36011_152676)">
        <rect
          width="18"
          height="18"
          transform="translate(0 0.5)"
          fill="white"
        />
        <path
          d="M9 0.5C13.9711 0.5 18 4.52891 18 9.5C18 14.4711 13.9711 18.5 9 18.5C4.02891 18.5 0 14.4711 0 9.5C0 4.52891 4.02891 0.5 9 0.5Z"
          fill="#0052FF"
        />
        <path
          d="M9.00176 12.6641C7.25449 12.6641 5.8377 11.249 5.8377 9.5C5.8377 7.75098 7.25449 6.33594 9.00176 6.33594C10.568 6.33594 11.8688 7.47852 12.1184 8.97266H15.3053C15.0363 5.72422 12.317 3.17188 9 3.17188C5.50547 3.17188 2.67188 6.00547 2.67188 9.5C2.67188 12.9945 5.50547 15.8281 9 15.8281C12.317 15.8281 15.0363 13.2758 15.3053 10.0273H12.1166C11.8652 11.5215 10.568 12.6641 9.00176 12.6641Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_36011_152676">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
