export const VideoCircleIcon = ({ size = 1 }) => (
  <svg
    width={20 * size}
    height={21 * size}
    viewBox="0 0 20 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 8.76255L9 5.87255C8.69612 5.6971 8.35141 5.60469 8.00051 5.6046C7.64962 5.60451 7.30487 5.69675 7.00089 5.87204C6.69691 6.04733 6.4444 6.29952 6.26872 6.60327C6.09304 6.90702 6.00036 7.25165 6 7.60255V13.3826C6.00036 13.7333 6.09295 14.0777 6.26846 14.3814C6.44398 14.685 6.69627 14.9372 7 15.1126C7.30404 15.2881 7.64893 15.3805 8 15.3805C8.35108 15.3805 8.69596 15.2881 9 15.1126L14 12.2226C14.3031 12.0467 14.5547 11.7944 14.7295 11.4908C14.9044 11.1872 14.9965 10.8429 14.9965 10.4926C14.9965 10.1422 14.9044 9.79794 14.7295 9.49433C14.5547 9.19071 14.3031 8.93836 14 8.76255ZM13 10.4926L8 13.3826V7.60255L13 10.4926ZM10 0.492554C8.02219 0.492554 6.08879 1.07904 4.4443 2.17786C2.79981 3.27667 1.51809 4.83846 0.761209 6.66572C0.00433284 8.49298 -0.1937 10.5036 0.192152 12.4435C0.578004 14.3833 1.53041 16.1651 2.92894 17.5636C4.32746 18.9621 6.10929 19.9146 8.0491 20.3004C9.98891 20.6863 11.9996 20.4882 13.8268 19.7313C15.6541 18.9745 17.2159 17.6927 18.3147 16.0483C19.4135 14.4038 20 12.4704 20 10.4926C20 9.17933 19.7413 7.87897 19.2388 6.66572C18.7362 5.45246 17.9997 4.35007 17.0711 3.42149C16.1425 2.4929 15.0401 1.75631 13.8268 1.25376C12.6136 0.751211 11.3132 0.492554 10 0.492554ZM10 18.4925C8.41775 18.4925 6.87103 18.0234 5.55544 17.1443C4.23985 16.2653 3.21447 15.0158 2.60897 13.554C2.00347 12.0922 1.84504 10.4837 2.15372 8.93183C2.4624 7.37998 3.22433 5.95452 4.34315 4.8357C5.46197 3.71688 6.88743 2.95495 8.43928 2.64627C9.99113 2.33759 11.5997 2.49602 13.0615 3.10152C14.5233 3.70702 15.7727 4.7324 16.6518 6.04799C17.5308 7.36358 18 8.9103 18 10.4926C18 12.6143 17.1571 14.6491 15.6569 16.1494C14.1566 17.6497 12.1217 18.4925 10 18.4925Z" />
  </svg>
);
