import styled from 'styled-components';
import Variant from '../packagesCardVariant';
import { usePackages } from 'contexts/PackagesContext';
import Stack from 'components/Basics/Layout/Stack';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { useAutoTranslation } from 'contexts/AutoTranslationContext';
import { useMemo } from 'react';
import Button from './Button';

const TitleBar = ({ variant }) => {
  const { loading, currentPackage, isCurrentPackageComplete } = usePackages();
  const { t } = useTranslation();

  const { getPreferredText } = useAutoTranslation();

  const title = useMemo(() => {
    return getPreferredText(currentPackage?.title);
  }, [currentPackage, getPreferredText]);

  if (loading || (variant !== Variant.Preview && !isCurrentPackageComplete))
    return null;

  return (
    <Wrapper>
      <Stack
        gap="1rem"
        direction="row"
        align="flex-start"
        margin="0.25rem 0 0 0"
        wrap="nowrap"
      >
        <Title
          isPlaceholder={variant === Variant.Preview && !currentPackage?.title}
        >
          {title ||
            (variant === Variant.Preview
              ? t(ProfileKeys.Edit.Package.Title.placeholder2)
              : '')}
        </Title>
      </Stack>

      <Button variant={variant} />
    </Wrapper>
  );
};

export default TitleBar;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2.75rem;
  margin-top: 1.5rem;
`;

const Title = styled.h2.attrs({
  className: 'psbh20',
})`
  word-break: break-word;
  color: ${({ isPlaceholder }) =>
    isPlaceholder ? 'var(--color-indigo-100)' : 'var(--color-indigo-500)'};
`;
