export const ItalicIcon = ({ size = 1 }) => (
  <svg
    width={13 * size}
    height={14 * size}
    viewBox="0 0 13 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.75 0V3H6.96L3.54 11H0.75V14H8.75V11H6.54L9.96 3H12.75V0H4.75Z" />
  </svg>
);
