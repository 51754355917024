import { createContext, useContext, useEffect, useMemo, useState } from 'react';

const Context = createContext([
  (_cb) => () => {
    return;
  },
  (_newSuggestion) => {
    return;
  },
]);

export const SharedAutocompleteContext = ({ children }) => {
  const context = useMemo(() => {
    let suggestion = null;
    const listeners = new Set();

    const subscribe = (cb) => {
      cb(suggestion);
      listeners.add(cb);
      return () => {
        listeners.delete(cb);
      };
    };

    const publish = (newSuggestion) => {
      suggestion = newSuggestion;
      for (const listener of listeners) {
        listener(newSuggestion);
      }
    };

    return [subscribe, publish];
  }, []);
  return <Context.Provider value={context}>{children}</Context.Provider>;
};

export const useSharedAutocompleteContext = () => {
  const [subscribe, publish] = useContext(Context);
  const [suggestion, setSuggestion] = useState(null);

  useEffect(() => {
    return subscribe((newSuggestion) => {
      setSuggestion(newSuggestion);
    });
  }, [subscribe]);

  return [suggestion, publish];
};
