import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { mergeRegister } from '@lexical/utils';
import {
  $createTextNode,
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_TAB_COMMAND,
} from 'lexical';
import { useCallback, useEffect } from 'react';

import { useSharedAutocompleteContext } from './SharedAutocompleteContext';
import { $createAutocompleteNode, AutocompleteNode } from './AutocompleteNode';
import { addSwipeRightListener } from '../../utils/swipe';

import { useEditorContext } from '../../EditorContext';
import Popup from 'components/Basics/Popup';
import { useState } from 'react';
import getGPTSuggestion from './getGPTSuggestion';

const AUTOCOMPLETE_DEBOUNCE = 1000;

export const uuid = Math.random()
  .toString(36)
  .replace(/[^a-z]+/g, '')
  .substr(0, 5);

function $search(selection) {
  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return [false, ''];
  }

  const root = $getRoot();
  const textNodes = root.getAllTextNodes();

  const nodeAtEnd = selection.getNodes()[0];
  const nodeAtEndKey = nodeAtEnd.getKey();

  let textBefore = '';
  let textAfter = '';

  let currentFound = false;
  for (let i = 0; i < textNodes.length; i++) {
    const textNode = textNodes[i];
    const textNodeKey = textNode.getKey();

    if (textNodeKey === nodeAtEndKey) {
      currentFound = true;

      textBefore += textNode.getTextContent().slice(0, selection.anchor.offset);
      textAfter += textNode.getTextContent().slice(selection.anchor.offset);

      continue;
    }

    if (currentFound) {
      textAfter += ' ' + textNode.getTextContent() + ' ';
    } else {
      textBefore += ' ' + textNode.getTextContent() + ' ';
    }
  }

  // remove double spaces
  textBefore = textBefore.replace(/\s\s+/g, ' ');
  textAfter = textAfter.replace(/\s\s+/g, ' ');

  return [true, textBefore, textAfter];
}

function useQuery({ contextData, gptConfig }) {
  return useCallback(
    (searchText) => {
      const response = getGPTSuggestion({
        priorPrompt: gptConfig.priorPrompt,
        prompt: searchText,
        temperature: gptConfig.temperature,
        topP: gptConfig.topP,
        returnTokens: gptConfig.returnTokens,
        contextData,
      });
      return response;
    },
    [
      gptConfig.priorPrompt,
      gptConfig.temperature,
      gptConfig.topP,
      gptConfig.returnTokens,
      contextData,
    ]
  );
}

export default function AutocompletePlugin({
  disabled,
  gptConfig = {
    priorPrompt: '',
    temperature: 0.6,
    topP: 1,
    returnTokens: 50,
  },
  contextData,
  maxCharCount,
}) {
  const [editor] = useLexicalComposerContext();
  const { setIsSuggestionLoading, gptEnabled, setGptEnabled } =
    useEditorContext();
  const [gptQuotaDepleted, setGptQuotaDepleted] = useState(false);
  const [, setSuggestion] = useSharedAutocompleteContext();
  const query = useQuery({
    contextData,
    gptConfig,
  });

  useEffect(() => {
    if (disabled || !gptEnabled) return;

    let autocompleteNodeKey = null;
    let lastMatch = null;
    let lastSuggestion = null;
    let searchPromise = null;

    function $clearSuggestion() {
      const autocompleteNode =
        autocompleteNodeKey !== null
          ? $getNodeByKey(autocompleteNodeKey)
          : null;
      if (autocompleteNode !== null && autocompleteNode.isAttached()) {
        autocompleteNode.remove();
        autocompleteNodeKey = null;
      }
      if (searchPromise !== null) {
        searchPromise.dismiss();
        searchPromise = null;
      }
      lastMatch = null;
      lastSuggestion = null;
      setSuggestion(null);
    }

    function updateSuggestion(newSuggestion) {
      lastSuggestion = newSuggestion;
      setSuggestion(newSuggestion);
    }

    function updateAsyncSuggestion(refSearchPromise, newSuggestion) {
      if (searchPromise !== refSearchPromise || newSuggestion === null) {
        // Outdated or no suggestion
        return;
      }
      editor.update(
        () => {
          const selection = $getSelection();
          const [hasMatch, match] = $search(selection);
          if (
            !hasMatch ||
            match !== lastMatch ||
            !$isRangeSelection(selection)
          ) {
            // Outdated
            return;
          }
          const selectionCopy = selection.clone();
          const node = $createAutocompleteNode(uuid);
          autocompleteNodeKey = node.getKey();
          selection.insertNodes([node]);
          $setSelection(selectionCopy);
          updateSuggestion(newSuggestion);
        },
        { tag: 'history-merge' }
      );
    }

    function handleAutocompleteNodeTransform(node) {
      const key = node.getKey();
      if (node.__uuid === uuid && key !== autocompleteNodeKey) {
        // Max one Autocomplete node per session
        $clearSuggestion();
      }
    }

    let debounceTimeoutId = null;
    let prevText = '';
    let justInserted = false;
    function handleUpdate(text) {
      editor.update(() => {
        if (justInserted) {
          justInserted = false;
          return;
        }
        const newEdit = text
          .replace(prevText, '')
          // replace all markdown formats
          .replace(/(\*\*|__|~~|`)/g, '');
        prevText = text;
        // if lastSuggestion have same first word as newEdit then update suggestion
        const regex = new RegExp(`^${newEdit}`);
        if (lastSuggestion?.match(regex)) {
          updateSuggestion(lastSuggestion.replace(regex, ''));
          return;
        }

        const selection = $getSelection();
        const [hasMatch, match] = $search(selection);
        if (!hasMatch) {
          $clearSuggestion();
          return;
        }
        if (match === lastMatch) {
          return;
        }
        $clearSuggestion();

        if (debounceTimeoutId !== null) {
          clearTimeout(debounceTimeoutId);
        }

        const charCount = $getRoot().getTextContentSize();
        const endBuffer = 10;
        if (maxCharCount && charCount + endBuffer >= maxCharCount) return;

        setIsSuggestionLoading(true);
        debounceTimeoutId = setTimeout(() => {
          searchPromise = query(match);

          searchPromise.promise
            .then((newSuggestion) => {
              if (searchPromise !== null) {
                updateAsyncSuggestion(searchPromise, newSuggestion);
              }
            })
            .catch((e) => {
              if (gptEnabled && e?.key === "QUOTA_EXCEEDED") {
                setGptQuotaDepleted(true);
              }
            })
            .finally(() => {
              setIsSuggestionLoading(false);
            });
          lastMatch = match;
        }, AUTOCOMPLETE_DEBOUNCE);
      });
    }

    function $handleAutocompleteIntent() {
      if (lastSuggestion === null || autocompleteNodeKey === null) {
        return false;
      }
      const autocompleteNode = $getNodeByKey(autocompleteNodeKey);
      if (autocompleteNode === null) {
        return false;
      }

      if (maxCharCount) {
        const charCount = $getRoot().getTextContentSize();
        const charRemaining = maxCharCount - charCount;
        if (lastSuggestion.length > charRemaining) {
          lastSuggestion = lastSuggestion.substring(0, charRemaining);
        }
      }

      const textNode = $createTextNode(lastSuggestion);
      try {
        autocompleteNode.replace(textNode);
      } catch (e) {
        const paragraphNode = $createParagraphNode();
        paragraphNode.append(textNode);
        autocompleteNode.replace(paragraphNode);
      }
      textNode.selectNext();

      $clearSuggestion();
      justInserted = true;
      return true;
    }

    function $handleKeypressCommand(e) {
      if ($handleAutocompleteIntent()) {
        e.preventDefault();
        return true;
      }
      return false;
    }

    function handleSwipeRight(_force, e) {
      editor.update(() => {
        if ($handleAutocompleteIntent()) {
          e.preventDefault();
        }
      });
    }

    function unmountSuggestion() {
      editor.update(() => {
        $clearSuggestion();
      });
    }

    const rootElem = editor.getRootElement();

    return mergeRegister(
      editor.registerNodeTransform(
        AutocompleteNode,
        handleAutocompleteNodeTransform
      ),
      editor.registerTextContentListener(handleUpdate),
      editor.registerUpdateListener(({ tags }) => {
        if (tags.has('focus')) {
          unmountSuggestion();
        }
      }),
      editor.registerCommand(
        KEY_TAB_COMMAND,
        $handleKeypressCommand,
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ARROW_RIGHT_COMMAND,
        $handleKeypressCommand,
        COMMAND_PRIORITY_LOW
      ),
      ...(rootElem !== null
        ? [addSwipeRightListener(rootElem, handleSwipeRight)]
        : []),
      unmountSuggestion
    );
  }, [
    editor,
    query,
    setSuggestion,
    disabled,
    setIsSuggestionLoading,
    gptEnabled,
    setGptEnabled,
    maxCharCount,
  ]);

  if (gptEnabled) {
    return (
      <Popup
        title="Your ChatGPT Quota has depleted"
        description="Sorry, you have reached your daily ChatGPT quota limit. Please come back tomorrow to continue using our service. We appreciate your understanding!"
        show={gptQuotaDepleted}
        onClose={() => {
          setGptEnabled(false);
        }}
        buttonText="Return"
      />
    );
  }

  return null;
}
