const NotFoundKeys = {
  title: 'NotFoundKeys.title',
  description: 'NotFoundKeys.description',

  Server_Updating: {
    title: 'NotFoundKeys.Server_Updating.title',
    description: 'NotFoundKeys.Server_Updating.description',
  },

  Under_Development: {
    title: 'NotFoundKeys.Under_Development.title',
    description: 'NotFoundKeys.Under_Development.description',
  },
};

export default NotFoundKeys;
