import MessageKeys from '../translationKeys/MessageKeys';

export const MessageTranslations = {
  [MessageKeys.Dropdown.title]: 'Bandeja de entrada',
  [MessageKeys.Dropdown.no_message]: 'No hay mensajes',
  [MessageKeys.Dropdown.bottom_btn]: 'Ver todos los mensajes',

  [MessageKeys.ChatBox.title]: '¡Bienvenido a tu bandeja de entrada!',
  [MessageKeys.ChatBox.no_chat]: `
    Parece que no hay conversaciones iniciadas aún. <br>
    ¡Pero no te preocupes! Cuando comiences una, las encontrarás aquí.`,
  [MessageKeys.ChatBox.no_selected_chat]:
    '¡Haz clic en un chat para comenzar a enviar mensajes!',
  [MessageKeys.ChatBox.new_chat]: `
    Inicia una conversación y conecta con {{user}} para discutir los detalles de tu proyecto. <br>
    <span>Envía una propuesta para compartir toda la información en un solo lugar.</span>`,
  [MessageKeys.ChatBox.search]: `
    <h6>No se encontraron resultados</h6>
    <p>Por favor, prueba otra palabra clave.</p>`,

  [MessageKeys.ChatBox.Message.me]: 'Yo',
  [MessageKeys.ChatBox.Message.copy]: 'Copiar mensaje',
  [MessageKeys.ChatBox.Message.report]: 'Reportar mensaje',
  [MessageKeys.ChatBox.Message.download_file]: 'Descargar todos los archivos',

  [MessageKeys.ChatBox.Message.typing]: 'Escribiendo...',
  [MessageKeys.ChatBox.Message.Offer_Package.text]:
    'Aquí tienes el paquete personalizado para ti.',
  [MessageKeys.ChatBox.Message.Offer_Package.description]:
    'El pedido comenzará una vez que aceptes la propuesta del cliente.',
  [MessageKeys.ChatBox.Message.Offer_Package.btn]: 'Continuar al pago',

  [MessageKeys.ChatBox.Message.Select_Package.title]: 'Seleccionar un paquete',
  [MessageKeys.ChatBox.Message.Select_Package.btn]: 'Crear un nuevo paquete',
  [MessageKeys.ChatBox.Message.Select_Package.complete_package_first]:
    '¡Completa tu primer paquete para empezar a enviar una oferta!',
  [MessageKeys.ChatBox.Message.Select_Package.no_price]: 'Sin precio',
  [MessageKeys.ChatBox.Message.Select_Package.verified_only]:
    'Solo usuarios verificados',

  [MessageKeys.ChatBox.Message.Custom_Package.title]: 'Paquete personalizado',
  [MessageKeys.ChatBox.Message.Custom_Package.description]:
    'El precio mostrado al comprador incluirá una tarifa de servicio de aproximadamente el {{number}}%.',
  [MessageKeys.ChatBox.Message.Custom_Package.offering]: 'Ofreciendo...',
  [MessageKeys.ChatBox.Message.Custom_Package.btn1]: 'Ofrecer paquete',
  [MessageKeys.ChatBox.Message.Custom_Package.btn2]: 'Volver a seleccionar',

  [MessageKeys.ChatBox.Message.Request_Price.text]:
    'Me gustaría solicitar el precio de tu paquete.',
  [MessageKeys.ChatBox.Message.Request_Price.description]:
    'El precio se ha solicitado con éxito al creador.',
  [MessageKeys.ChatBox.Message.Request_Price.btn]:
    'Ofrecer un precio y un paquete',

  [MessageKeys.ChatBox.Message.File.singular]: 'archivo',
  [MessageKeys.ChatBox.Message.File.plural]: 'archivos',
  [MessageKeys.ChatBox.Message.File.plural_2]: '2 archivos',
  [MessageKeys.ChatBox.Message.File.plural_3]: '3 archivos',
  [MessageKeys.ChatBox.Message.File.plural_4]: '4 archivos',
  [MessageKeys.ChatBox.Message.File.plural_5]: '5 archivos',
  [MessageKeys.ChatBox.Message.File.plural_6]: '6 archivos',
  [MessageKeys.ChatBox.Message.File.plural_7]: '7 archivos',
  [MessageKeys.ChatBox.Message.File.plural_8]: '8 archivos',
  [MessageKeys.ChatBox.Message.File.plural_9]: '9 archivos',
  [MessageKeys.ChatBox.Message.File.plural_10]: '10 archivos',
  [MessageKeys.ChatBox.Message.File.expired]: 'Este archivo ha caducado.',

  [MessageKeys.ChatBox.Message.Error.sending]: 'Enviando...',
  [MessageKeys.ChatBox.Message.Error.fail]: 'Error al enviar',

  [MessageKeys.InputField.chat_disabled]:
    'Este usuario ya no puede ser contactado.',
  [MessageKeys.InputField.web]: 'Ingrese texto o arrastre y suelte un archivo',
  [MessageKeys.InputField.mobile]: 'Escribe tu mensaje',
  [MessageKeys.InputField.drag_drop]: 'Arrastra y suelta archivos',

  [MessageKeys.InputField.Btn.offer_package]: 'Ofrecer un paquete',
  [MessageKeys.InputField.Btn.send_proposal]: 'Enviar una propuesta',

  [MessageKeys.UserInfo.Verified.tooltip]: `
    Esta cuenta representa a una entidad <br>
    verificada como empresa u organización, <br>
    o es un miembro afiliado a ellas.`,

  [MessageKeys.UserInfo.Status.online]: 'En línea',
  [MessageKeys.UserInfo.Status.offline]: 'Última vez visto',

  [MessageKeys.UserInfo.order_together]: 'Pedidos realizados juntos',

  [MessageKeys.UserInfo.Btn.edit_profile]: 'Editar perfil',
  [MessageKeys.UserInfo.Btn.visit_profile]: 'Visitar perfil',

  [MessageKeys.Error.max_characters]: 'Máximo {{number}} caracteres',
  [MessageKeys.Error.max_file_size_1]:
    'El límite total de tamaño de archivo es de máximo',
  [MessageKeys.Error.max_file_size_2]: 'MB',
  [MessageKeys.Error.max_file_1]: 'Puede enviar máximo',
  [MessageKeys.Error.max_file_2]: 'archivos',
};
