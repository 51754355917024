import AutoTranslationKeys from '../translationKeys/AutoTranslationKeys';

export const AutoTranslations = {
  [AutoTranslationKeys.Dropdown.title]: '自動翻訳',
  [AutoTranslationKeys.Dropdown.description]:
    '説明やユーザーが書いたテキストを自動的に翻訳します。',

  [AutoTranslationKeys.Applying_Translation.title]:
    '自動翻訳が適用されました。',
  [AutoTranslationKeys.Applying_Translation.btn]: '元のテキストを表示',

  [AutoTranslationKeys.Displaying_Original.title]:
    '元のテキストを表示しています。',
  [AutoTranslationKeys.Displaying_Original.btn]: '翻訳',
};
