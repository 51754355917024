export const ChipIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size * 28}
    height={size * 32}
    viewBox="0 0 28 32"
    fill="none"
    stroke="currentColor"
  >
    <rect
      x="27.4"
      y="0.6"
      width="30.8"
      height="26.8"
      rx="5.4"
      transform="rotate(90 27.4 0.6)"
      stroke-width="1.2"
    />
    <path
      d="M28 12.1999L-4.17233e-07 12.1999M22.6 0.399902V12.3999M14.6 0.399902V12.3999M6.6 0.399902V12.3999M14.6 19.9999V31.9999M6.6 19.9999L6.6 31.9999M2.63453e-06 20.3999C2.63453e-06 20.3999 1.00885 20.3998 11.9044 20.3999C22.8 20.4 22.8 20.4 22.8 31.9999"
      stroke-width="1.2"
    />
  </svg>
);
