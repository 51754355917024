export const ChevronRightIcon = ({ size = 1, style }) => (
  <svg
    width={9 * size}
    height={14 * size}
    viewBox="0 0 9 14"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M1.06644 1.67896L6.40625 7.01876L1.06644 12.3586"
      strokeWidth="2.50485"
    />
  </svg>
);
