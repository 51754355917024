import Modal from 'components/Basics/Modal';
import Point from './Point';
import styled from 'styled-components';
import IndigoRoundButton from 'components/Basics/Buttons/IndigoRoundButton';
import PlatformIcon from 'components/Basics/PlatformIcon';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import { useNavigate } from 'react-router-dom';
import { useSocket } from 'contexts/SocketContext';
import { useState } from 'react';
import { useCallback } from 'react';
import AxiosClient from 'components/utilities/AxiosClient';
import Modals from './../index';
import { useTranslation } from 'react-i18next';
import CreatorRegistrationKeys from 'translations/translationKeys/CreatorRegistrationPageKeys';
import { useEditManager } from 'components/components/ProfilePage/ProfileEdit/EditManagerContext';
import { Trans } from 'react-i18next';

const UpdateInstaConnectionModal = ({
  onVerify,
  reqType = 'permanent',
  isOpen,
  onClose,
  clickHereOnClick = () => {},
  buttonOnClick = () => {},
  onPopupFail,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();
  const { links } = useEditManager();
  const profileName = links?.find((link) => link.type === 'instagram')?.name;

  const socket = useSocket();
  const [error, setError] = useState({
    code: null,
    message: null,
  });

  const addLink = useCallback(
    async (res) => {
      if (res.status?.toString()?.startsWith('4')) {
        setError({
          code: res.status,
          message: res.message,
        });
      } else {
        onVerify(res.message);
      }

      socket.off('addLink', addLink);
    },
    [onVerify, socket]
  );

  const redirectTokenRequestUrl = useCallback(
    async (isBasic) => {
      const { data: tokenRequestUrl } = await AxiosClient.get(
        '/platforms/generateAuthUrl',
        {
          params: {
            platform: isBasic ? 'instagram' : 'instagramBasic',
            reqType,
          },
        }
      );
      const popup = window.open(
        tokenRequestUrl,
        'Instagram Verification',
        'height=600,width=500,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes'
      );
      if (!popup) {
        onPopupFail();
      }

      socket.off('addLink');
      socket.on('addLink', addLink);
    },
    [reqType, socket, addLink, onPopupFail]
  );

  return (
    <>
      <Modal
        title={t(CreatorRegistrationKeys.Modal.Instagram_Facebook.title)}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <div>
          <PlatformContainer className="h6">
            <PlatformIcon
              platform="instagram"
              variant={isMobile ? 'size5' : 'size3'}
            />
            {profileName}
          </PlatformContainer>
          <Content>
            <Point
              text={
                <Trans
                  components={{
                    b: <b></b>,
                  }}
                >
                  {t(CreatorRegistrationKeys.Modal.Instagram_Facebook.point1)}
                </Trans>
              }
            />
            <Point
              text={
                <Trans
                  components={{
                    b: <b></b>,
                  }}
                >
                  {t(CreatorRegistrationKeys.Modal.Instagram_Facebook.point2)}
                </Trans>
              }
            />
            <Point
              text={
                <Trans
                  components={{
                    b: <b></b>,
                    u: (
                      <u
                        className="cursor-pointer"
                        onClick={() => navigate('/help-center')}
                      ></u>
                    ),
                  }}
                >
                  {t(CreatorRegistrationKeys.Modal.Instagram_Facebook.point3)}
                </Trans>
              }
            />

            <div className="w-100 d-flex justify-content-center">
              <IndigoRoundButton
                className={`text-white ${isMobile ? 'sbh6' : 'sbh5'}`}
                padding={isMobile ? '0.5rem 1rem' : '9px 22px'}
                onClick={() => {
                  redirectTokenRequestUrl(true);
                  buttonOnClick();
                }}
              >
                {t(
                  CreatorRegistrationKeys.Modal.Instagram_Notice
                    .update_connection
                )}
              </IndigoRoundButton>
            </div>
          </Content>
        </div>
      </Modal>
      <Modals
        error={error}
        onClose={() =>
          setError({
            code: null,
            message: null,
          })
        }
        instagram
      />
    </>
  );
};

export default UpdateInstaConnectionModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1.5rem;

  @media (${device.sm}) {
    gap: 1rem;
  }
`;

const PlatformContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 60px 0;
  width: 100%;

  @media (${device.sm}) {
    font-size: 12px;
    padding: 36px 0;
    gap: 0.5rem;
  }
`;
