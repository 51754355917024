export const QuestionMarkIcon2 = ({ size = 1, ...rest }) => (
  <svg
    width={14 * size}
    height={14 * size}
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path d="M6.965 11.2C7.21 11.2 7.4172 11.1153 7.5866 10.9459C7.75553 10.777 7.84 10.57 7.84 10.325C7.84 10.08 7.75553 9.87303 7.5866 9.7041C7.4172 9.5347 7.21 9.45 6.965 9.45C6.72 9.45 6.5128 9.5347 6.3434 9.7041C6.17447 9.87303 6.09 10.08 6.09 10.325C6.09 10.57 6.17447 10.777 6.3434 10.9459C6.5128 11.1153 6.72 11.2 6.965 11.2ZM6.335 8.505H7.63C7.63 8.12 7.67387 7.81667 7.7616 7.595C7.84887 7.37333 8.09667 7.07 8.505 6.685C8.80833 6.38167 9.0475 6.0928 9.2225 5.8184C9.3975 5.54447 9.485 5.215 9.485 4.83C9.485 4.17667 9.24583 3.675 8.7675 3.325C8.28917 2.975 7.72333 2.8 7.07 2.8C6.405 2.8 5.86553 2.975 5.4516 3.325C5.0372 3.675 4.74833 4.095 4.585 4.585L5.74 5.04C5.79833 4.83 5.9297 4.6025 6.1341 4.3575C6.33803 4.1125 6.65 3.99 7.07 3.99C7.44333 3.99 7.72333 4.09197 7.91 4.2959C8.09667 4.5003 8.19 4.725 8.19 4.97C8.19 5.20333 8.12 5.42197 7.98 5.6259C7.84 5.8303 7.665 6.02 7.455 6.195C6.94167 6.65 6.62667 6.99417 6.51 7.2275C6.39333 7.46083 6.335 7.88667 6.335 8.505ZM7 14C6.03167 14 5.12167 13.8161 4.27 13.4484C3.41833 13.0811 2.6775 12.5825 2.0475 11.9525C1.4175 11.3225 0.918867 10.5817 0.5516 9.73C0.183867 8.87833 0 7.96833 0 7C0 6.03167 0.183867 5.12167 0.5516 4.27C0.918867 3.41833 1.4175 2.6775 2.0475 2.0475C2.6775 1.4175 3.41833 0.918633 4.27 0.5509C5.12167 0.183633 6.03167 0 7 0C7.96833 0 8.87833 0.183633 9.73 0.5509C10.5817 0.918633 11.3225 1.4175 11.9525 2.0475C12.5825 2.6775 13.0811 3.41833 13.4484 4.27C13.8161 5.12167 14 6.03167 14 7C14 7.96833 13.8161 8.87833 13.4484 9.73C13.0811 10.5817 12.5825 11.3225 11.9525 11.9525C11.3225 12.5825 10.5817 13.0811 9.73 13.4484C8.87833 13.8161 7.96833 14 7 14ZM7 12.6C8.56333 12.6 9.8875 12.0575 10.9725 10.9725C12.0575 9.8875 12.6 8.56333 12.6 7C12.6 5.43667 12.0575 4.1125 10.9725 3.0275C9.8875 1.9425 8.56333 1.4 7 1.4C5.43667 1.4 4.1125 1.9425 3.0275 3.0275C1.9425 4.1125 1.4 5.43667 1.4 7C1.4 8.56333 1.9425 9.8875 3.0275 10.9725C4.1125 12.0575 5.43667 12.6 7 12.6Z" />
  </svg>
);
