import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';

const AnalyticsType = {
  Channel: GeneralKeys.channel_lowercase,
  Content: GeneralKeys.contents_lowercase,
  Audience: GeneralKeys.audience_lowercase,
};

const AnalysisOptions = {
  [AnalyticsType.Channel]: [
    { label: ProfileKeys.Channel_Contents.Filter._1_month, value: 30 },
    { label: ProfileKeys.Channel_Contents.Filter._3_months, value: 90 },
    { label: ProfileKeys.Channel_Contents.Filter._1_year, value: 365 },
  ],
  [AnalyticsType.Content]: [
    { label: ProfileKeys.Channel_Contents.Filter.newest, value: 'publishedAt' },
    { label: ProfileKeys.Channel_Contents.Filter.most_viewed, value: 'views' },
    { label: ProfileKeys.Channel_Contents.Filter.most_liked, value: 'likes' },
    {
      label: ProfileKeys.Channel_Contents.Filter.most_commented,
      value: 'comments',
    },
  ],
  [AnalyticsType.Audience]: null,
};

export { AnalyticsType, AnalysisOptions };
