export const CampaignIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 18}
      height={size * 16}
      viewBox="0 0 18 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.4 8.8998V7.0998H18V8.8998H14.4ZM15.48 15.1998L12.6 13.0398L13.68 11.5998L16.56 13.7598L15.48 15.1998ZM13.68 4.3998L12.6 2.9598L15.48 0.799805L16.56 2.2398L13.68 4.3998ZM2.7 14.2998V10.6998H1.8C1.305 10.6998 0.8814 10.5234 0.5292 10.1706C0.1764 9.8184 0 9.3948 0 8.8998V7.0998C0 6.6048 0.1764 6.1809 0.5292 5.8281C0.8814 5.4759 1.305 5.2998 1.8 5.2998H5.4L9.9 2.5998V13.3998L5.4 10.6998H4.5V14.2998H2.7ZM10.8 11.0148V4.9848C11.205 5.3448 11.5314 5.7837 11.7792 6.3015C12.0264 6.8187 12.15 7.3848 12.15 7.9998C12.15 8.6148 12.0264 9.1809 11.7792 9.6981C11.5314 10.2159 11.205 10.6548 10.8 11.0148ZM1.8 7.0998V8.8998H5.895L8.1 10.2048V5.7948L5.895 7.0998H1.8Z" />
    </svg>
  );
};
