import { OrderIcon } from 'assets/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ModalKeys from 'translations/translationKeys/ModalKeys';

const NoOrders = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <OrderIcon />
      {t(ModalKeys.My_Order.Dropdown.content)}
    </Wrapper>
  );
};

export default NoOrders;

const Wrapper = styled.div.attrs({
  className: 'sbh5.5',
})`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  color: var(--color-indigo-100);
`;
