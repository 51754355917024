export const TimeIcon = ({ size = 1 }) => (
  <svg
    width={15 * size}
    height={15 * size}
    viewBox="0 0 15 15"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324963 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03683 14.8559C7.49168 15.1453 8.99968 14.9968 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C15 6.51508 14.806 5.53981 14.4291 4.62987C14.0522 3.71993 13.4997 2.89314 12.8033 2.1967C12.1069 1.50026 11.2801 0.947814 10.3701 0.570903C9.46019 0.193993 8.48492 0 7.5 0ZM7.5 13.5C6.31331 13.5 5.15328 13.1481 4.16658 12.4888C3.17989 11.8295 2.41085 10.8925 1.95673 9.7961C1.5026 8.69974 1.38378 7.49334 1.61529 6.32946C1.8468 5.16557 2.41825 4.09647 3.25736 3.25736C4.09648 2.41824 5.16558 1.8468 6.32946 1.61529C7.49335 1.38378 8.69975 1.5026 9.7961 1.95672C10.8925 2.41085 11.8295 3.17988 12.4888 4.16658C13.1481 5.15327 13.5 6.31331 13.5 7.5C13.5 9.0913 12.8679 10.6174 11.7426 11.7426C10.6174 12.8679 9.0913 13.5 7.5 13.5ZM7.5 3C7.30109 3 7.11032 3.07902 6.96967 3.21967C6.82902 3.36032 6.75 3.55109 6.75 3.75V6.2025L5.925 5.7225C5.75195 5.62304 5.54647 5.59641 5.35378 5.64845C5.16109 5.70049 4.99696 5.82695 4.8975 6C4.79805 6.17305 4.77141 6.37853 4.82345 6.57122C4.87549 6.76391 5.00195 6.92804 5.175 7.0275L7.125 8.1525H7.1775H7.245C7.32332 8.20033 7.40975 8.23337 7.5 8.25C7.58741 8.2475 7.67371 8.22973 7.755 8.1975H7.815C7.89321 8.16339 7.96441 8.11507 8.025 8.055C8.06611 8.01046 8.10373 7.96282 8.1375 7.9125C8.1375 7.9125 8.1375 7.9125 8.1375 7.8675C8.15585 7.82368 8.17089 7.77856 8.1825 7.7325C8.18603 7.68256 8.18603 7.63244 8.1825 7.5825C8.1825 7.5825 8.1825 7.5825 8.1825 7.5375V3.75C8.18326 3.56225 8.11358 3.38104 7.98721 3.24218C7.86085 3.10331 7.68699 3.0169 7.5 3Z" />
  </svg>
);
