import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import device from 'styles/device';
import { useEvent } from 'react-use';
import { useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import PatchNoteModalContent from './PatchNoteModalContent';
import { useEffect } from 'react';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import dayjs from 'dayjs';
import getLatestPatchNote from '../../api/getPatchNote';
import { useTranslation } from 'react-i18next';
import { useAuthFlowManager } from 'contexts/AuthFlowManagerContext';

const backdropVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const mobileContentVariant = {
  hidden: {
    opacity: 0,
    scale: 0.8,
    translateY: '-50%',
    translateX: '-50%',
  },
  visible: {
    opacity: 1,
    scale: 1,
    translateY: '-50%',
    translateX: '-50%',
    top: '50%',
    left: '50%',
  },
};

const webContentVariants = {
  hidden: {
    opacity: 0,
    scale: 0.8,
  },
  visible: {
    opacity: 1,
    scale: 1,
  },
};

const PatchNoteModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useResponsiveScreen();
  const [data, setData] = useState(null);
  const { i18n } = useTranslation();
  const { isModalOpen } = useAuthFlowManager();

  const shouldDisplayModal =
    !sessionStorage.getItem('patchModalOpened') ||
    dayjs().isAfter(dayjs(localStorage.getItem('stopPatchModalUntill')));

  const onClose = () => {
    setIsOpen(false);

    sessionStorage.setItem('patchModalOpened', true);
  };

  const modalRoot = useRef(document.getElementById('root'));

  useEvent('keydown', (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  });

  useEffect(() => {
    if (shouldDisplayModal) {
      getLatestPatchNote(i18n.language)
        .then((data) => {
          if (data.releaseNote[0]) {
            setData(data.releaseNote[0]);
            setIsOpen(true);
          } else {
            // if current language does not have patch note, get english patch note
            getLatestPatchNote('en').then((data) => {
              if (data.releaseNote[0]) {
                setData(data.releaseNote[0]);
              }
            });
            setIsOpen(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [shouldDisplayModal, i18n.language]);

  useEffect(() => {
    if (isModalOpen) {
      setIsOpen(false);
    }
  }, [isModalOpen]);

  // listen for modal close change
  useEffect(() => {
    const catchModalClose = (e) => {
      if (e.detail === 'close') {
        onClose();
        e.stopImmediatePropagation();
      } else if (e.detail === 'open') {
        setIsOpen(true);
        e.stopImmediatePropagation();
      }
    };

    window.addEventListener('toggle-patch-note-modal', catchModalClose);

    return () => {
      window.removeEventListener('toggle-patch-note-modal', catchModalClose);
    };
  }, []);

  if (!data) return null;

  return createPortal(
    <AnimatePresence>
      {isOpen && !isModalOpen && (
        <>
          <Backdrop
            variants={backdropVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            onClick={onClose}
          />
          <ContentWrapper
            variants={isMobile ? mobileContentVariant : webContentVariants}
            transition={{ duration: 0.3 }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <PatchNoteModalContent
              image={data?.pic}
              title={data?.title}
              date={data?.date}
              description={data?.description}
              link={data?.link}
              onClose={onClose}
            />
          </ContentWrapper>
        </>
      )}
    </AnimatePresence>,
    modalRoot.current
  );
};

export default PatchNoteModal;

const Backdrop = styled(motion.div)`
  display: none;

  @media (${device.sm}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    display: block;
  }
`;

const ContentWrapper = styled(motion.div)`
  position: absolute;
  top: 100px;
  left: calc(100vw - 1344px - ((100vw - 1344px) / 2));
  z-index: 99990;

  @media (max-width: 1440px) {
    left: 2rem;
  }
`;
