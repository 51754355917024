import NotFoundKeys from '../translationKeys/NotFoundPageKeys';

export const NotFoundTranslations = {
  // Page Not Found
  [NotFoundKeys.title]: '404 : Không Tìm Thấy Trang',
  [NotFoundKeys.description]:
    'Chúng tôi xin lỗi. Trang bạn đang tìm không thể được tìm thấy.',

  // Server Updating Page
  [NotFoundKeys.Server_Updating.title]: 'Máy chủ đang được cập nhật',
  [NotFoundKeys.Server_Updating.description]: 'Vui lòng quay lại sau!',

  // Under Development Page
  [NotFoundKeys.Under_Development.title]: 'Đang trong quá trình phát triển',
  [NotFoundKeys.Under_Development.description]:
    'Chào mừng đến với Web 3.0 Marketing!',
};
