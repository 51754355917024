export const KlayIcon = ({ size = 1 }) => (
  <svg
    width={31 * size}
    height={30 * size}
    viewBox="0 0 31 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.9">
      <path d="M15.7266 14.9158L25.959 25.2242C28.4713 22.5336 30.002 18.9013 30.002 14.9158C30.002 10.9303 28.4713 7.29803 25.959 4.60742L15.7266 14.9158Z" />
      <path d="M15.0056 15.6222L4.90625 25.796L15.0056 30.0001L25.1049 25.796L15.0056 15.6222Z" />
      <path d="M14.6375 14.5461L25.1028 4.01909L15.4694 0L5.83594 23.4251L14.6375 14.5461Z" />
      <path d="M0 14.916C0 18.8847 1.53071 22.5001 4.00978 25.1908L14.0592 0.756714L0 14.916Z" />
    </g>
  </svg>
);
