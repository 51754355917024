export const ChinaFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29896_35061)">
      <rect
        x="0.128418"
        y="0.064209"
        width="33"
        height="33"
        rx="16.5"
        fill="#F93939"
      />
      <path
        d="M41.9284 0.064209H4.52842C2.09837 0.064209 0.128418 2.03416 0.128418 4.46421V28.6642C0.128418 31.0943 2.09837 33.0642 4.52842 33.0642H41.9284C44.3585 33.0642 46.3284 31.0943 46.3284 28.6642V4.46421C46.3284 2.03416 44.3585 0.064209 41.9284 0.064209Z"
        fill="#F93939"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.22 15.9703L8.98603 17.6709L9.60203 14.0695L6.98843 11.5175L10.603 10.9961L12.22 7.71811L13.8348 10.9961L17.4494 11.5175L14.8314 14.0695L15.4518 17.6687L12.22 15.9703ZM19.9244 6.66431H22.1244V8.86431H19.9244V6.66431ZM22.1244 11.0643H24.3244V13.2643H22.1244V11.0643ZM22.1244 15.4643H24.3244V17.6643H22.1244V15.4643ZM19.9244 19.8643H22.1244V22.0643H19.9244V19.8643Z"
        fill="#FFDA2C"
      />
    </g>
    <defs>
      <clipPath id="clip0_29896_35061">
        <rect
          x="0.128418"
          y="0.064209"
          width="33"
          height="33"
          rx="16.5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
