import ModalKeys from '../translationKeys/ModalKeys';

export const ModalTranslations = {
  // OfficialLaunch
  [ModalKeys.official_launch.title]:
    'この機能は、日本でも間もなくリリースされる予定です！',
  [ModalKeys.official_launch.content]: `
    一部の機能は、日本のサーバーに早急に追加される予定です。 <br>
    グローバル企業と快適にコラボレーションできる最高のサービスをお届けします。`,

  // Patch Note
  [ModalKeys.Patch_Note.close]: '14日間表示しない',

  [ModalKeys.Notification.Dropdown.title]: '通知',
  [ModalKeys.Notification.Dropdown.no_notification]: '通知なし',
  [ModalKeys.Notification.Dropdown.bottom_btn]: '全てを開封済みにする',

  [ModalKeys.My_Order.Dropdown.content]: '最初の注文を始める！',
  [ModalKeys.My_Order.Dropdown.bottom_btn]: 'すべての注文を表示',
};
