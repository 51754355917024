import { ExclamationMarkIcon } from 'assets/icons';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import React from 'react'
import styled from 'styled-components';
import device from 'styles/device';

const Point = ({ text }) => {
  const {isMobile} = useResponsiveScreen();
  return (
    <Container>
      <Icon >
        <ExclamationMarkIcon size={isMobile ? 0.78 : 1} />
      </Icon>
      <Text className='sbh5'>
        {text}
      </Text>
    </Container>

  )
}

export default Point;

const Container = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 0.75rem;
`;

const Icon  = styled.span`
  height: 18px;
  
  @media (${device.sm}) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    padding-top: 6px;
  }
  
`;

const Text = styled.span`
  @media (${device.sm}){
    font-size: 0.75rem;
  }
`;