export const YoutubeGrayIcon = ({ size = 1 }) => (
  <svg
    width={24 * 1.75 * size}
    height={18 * 1.75 * size}
    viewBox="0 0 24 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M23.4705 3.28623C23.3328 2.78258 23.0643 2.32343 22.6916 1.9545C22.3189 1.58557 21.8551 1.31973 21.3463 1.18347C19.4837 0.680664 11.9879 0.680664 11.9879 0.680664C11.9879 0.680664 4.49181 0.695923 2.62947 1.19873C2.12073 1.33501 1.65693 1.60086 1.28427 1.96979C0.911596 2.33872 0.643056 2.79786 0.505388 3.30149C-0.0579989 6.5777 -0.276572 11.5695 0.520801 14.7145C0.658469 15.2182 0.927009 15.6773 1.29968 16.0462C1.67235 16.4152 2.13614 16.681 2.64488 16.8173C4.50737 17.3201 12.0033 17.3201 12.0033 17.3201C12.0033 17.3201 19.4992 17.3201 21.3617 16.8173C21.8704 16.681 22.3342 16.4152 22.7069 16.0462C23.0796 15.6773 23.3481 15.2182 23.4858 14.7145C24.0799 11.4338 24.2631 6.44502 23.4704 3.28637L23.4705 3.28623Z" />
    <path
      d="M9.58594 12.5687L15.8043 9.00319L9.58594 5.4375L9.58594 12.5687Z"
      fill="white"
    />
  </svg>
);
