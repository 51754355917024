export const YoutubeIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={24 * size}
    viewBox="0 0 34 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.2499 3.90496C33.0549 3.19146 32.6744 2.54099 32.1464 2.01834C31.6184 1.49569 30.9613 1.11909 30.2406 0.926049C27.6019 0.213745 16.9828 0.213745 16.9828 0.213745C16.9828 0.213745 6.3634 0.235361 3.72508 0.947665C3.00437 1.14074 2.34732 1.51735 1.81938 2.04C1.29143 2.56265 0.910996 3.2131 0.715966 3.92658C-0.0821651 8.56788 -0.39181 15.6397 0.737802 20.0951C0.932832 20.8085 1.31326 21.459 1.84121 21.9816C2.36916 22.5043 3.0262 22.8809 3.74691 23.074C6.38544 23.7863 17.0047 23.7863 17.0047 23.7863C17.0047 23.7863 27.6239 23.7863 30.2624 23.074C30.9831 22.8809 31.6402 22.5043 32.1681 21.9816C32.6961 21.459 33.0765 20.8085 33.2715 20.0951C34.1131 15.4474 34.3727 8.37992 33.2497 3.90517L33.2499 3.90496Z"
      fill="#FF0000"
    />
    <path
      d="M13.6001 17.0575L22.4095 12.0063L13.6001 6.95496L13.6001 17.0575Z"
      fill="white"
    />
  </svg>
);
