import { createContext, useContext, useEffect, useState } from 'react';
import {
  ChannelAnalyticsProvider,
  useChannelAnalytics,
} from './ChannelAnalyticsContext';
import {
  ContentAnalyticsProvider,
  useContentAnalytics,
} from './ContentAnalyticsContext';
import { AudienceAnalyticsProvider } from './AudienceAnalyticsContext';
import { AnalyticsType } from './constants';
import { usePrevious } from 'react-use';
import { useCallback } from 'react';
import AxiosClient from 'components/utilities/AxiosClient';
import countryCodes from 'constants/countryCodes';

const getCountries = async ({ userId, handle, platform }) => {
  if (!(userId || handle) || !platform) return [];
  return AxiosClient.get('/getUserCountries', {
    params: {
      userId,
      handle,
      platform,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const checkIsAdmin = async ({ userId, handle }) => {
  return AxiosClient.post('/checkIsAdmin', {
    userId,
    handle,
  })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
      return [];
    });
};

const AnalyticsContext = createContext();

const AnalyticsProvider = ({
  children,
  userId,
  handle,
  platform,
  type,
  disabled = false,
  isBasic,
}) => {
  const [selectedType, setSelectedType] = useState(AnalyticsType.Channel);
  const prevSelectedType = usePrevious(selectedType);
  const [userDisplayCountries, setUserDisplayCountries] = useState([]);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const selectType = (type) => {
    if (!Object.values(AnalyticsType).includes(type)) return;

    setSelectedType(type);
  };
  useEffect(() => {
    if (type) return selectType(type);
  }, [type]);

  useEffect(() => {
    getCountries({ userId, handle, platform }).then((countries) => {
      setUserDisplayCountries(
        countries?.map((country) => {
          return country.length === 2
            ? countryCodes
                .find(
                  (code) =>
                    code.alpha2?.toLocaleLowerCase() ===
                    country?.toLocaleLowerCase()
                )
                ?.alpha3?.toLocaleLowerCase()
            : country;
        }) || []
      );
    });

    checkIsAdmin({ userId, handle }).then((u) => {
      if (u?.isAdmin) {
        setIsUserAdmin(u?.isAdmin);
      }
      if (!u?.isAdmin) {
        getCountries({ userId, handle }).then((countries) => {
          setUserDisplayCountries(countries || []);
        });
      }
    });
  }, [userId, handle, platform]);

  const selectPreviousType = useCallback(() => {
    if (prevSelectedType) {
      selectType(prevSelectedType);
    }
  }, [prevSelectedType]);

  if (disabled || (!userId && !handle)) return children;

  return (
    <AnalyticsContext.Provider
      value={{
        isUserAdmin,
        selectedType,
        selectType,
        selectPreviousType,
        platform,
        userDisplayCountries,
        isBasic,
      }}
    >
      <ChannelAnalyticsProvider
        userId={userId}
        handle={handle}
        platform={platform}
        isBasic={isBasic}
      >
        <ContentAnalyticsProvider
          userId={userId}
          handle={handle}
          platform={platform}
          isBasic={isBasic}
        >
          <AudienceAnalyticsProvider
            userId={userId}
            handle={handle}
            platform={platform}
            isBasic={isBasic}
          >
            {children}
          </AudienceAnalyticsProvider>
        </ContentAnalyticsProvider>
      </ChannelAnalyticsProvider>
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = ({ fixedType } = {}) => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error(
      'useAnalytics (Analytics card) must be used within AnalyticsProvider or AnalyticsProvider must be disabled'
    );
  }

  const {
    selectedType,
    selectType,
    selectPreviousType,
    platform,
    userDisplayCountries,
    isUserAdmin,
  } = context;

  const type = fixedType || selectedType;

  // useEffect(() => {
  //   if (fixedType && fixedType !== selectedType) {
  //     selectType(fixedType);
  //   }
  // }, [fixedType, selectedType, selectType]);

  return {
    selectedType: type,
    selectType: fixedType ? () => {} : selectType,
    selectPreviousType,
    platform,
    userDisplayCountries,
    isUserAdmin,
  };
};

export {
  AnalyticsProvider,
  useAnalytics,
  useChannelAnalytics,
  useContentAnalytics,
};
