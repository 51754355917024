export const HomeIcon = ({ size = 1 }) => (
  <svg
    width={size * 12}
    height={size * 12}
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.7979 4.94955L6.39614 0.14925C6.28661 0.0530525 6.1458 0 6.00001 0C5.85422 0 5.71342 0.0530525 5.60389 0.14925L0.202159 4.94955C0.110351 5.03075 0.0456093 5.13809 0.0166388 5.25716C-0.0123316 5.37623 -0.00414194 5.50132 0.0401067 5.6156C0.0836316 5.72836 0.160208 5.82536 0.259807 5.89389C0.359405 5.96241 0.477382 5.99926 0.598285 5.99962H1.19848V11.4C1.19848 11.5591 1.26171 11.7117 1.37427 11.8243C1.48683 11.9368 1.63949 12 1.79867 12H10.2014C10.3605 12 10.5132 11.9368 10.6258 11.8243C10.7383 11.7117 10.8015 11.5591 10.8015 11.4V5.99962H11.4017C11.5226 5.99926 11.6406 5.96241 11.7402 5.89389C11.8398 5.82536 11.9164 5.72836 11.9599 5.6156C12.0042 5.50132 12.0124 5.37623 11.9834 5.25716C11.9544 5.13809 11.8897 5.03075 11.7979 4.94955ZM6.60021 10.7999H5.39982V8.99981C5.39982 8.84067 5.46306 8.68805 5.57561 8.57552C5.68817 8.46299 5.84083 8.39977 6.00001 8.39977C6.15919 8.39977 6.31186 8.46299 6.42441 8.57552C6.53697 8.68805 6.60021 8.84067 6.60021 8.99981V10.7999ZM9.60117 10.7999H7.80059V8.99981C7.80059 8.52239 7.61089 8.06452 7.27321 7.72694C6.93554 7.38935 6.47756 7.1997 6.00001 7.1997C5.52247 7.1997 5.06449 7.38935 4.72681 7.72694C4.38914 8.06452 4.19944 8.52239 4.19944 8.99981V10.7999H2.39886V5.99962H9.60117V10.7999ZM2.17679 4.79954L6.00001 1.40333L9.82324 4.79954H2.17679Z" />
  </svg>
);
