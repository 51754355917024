import NotFoundKeys from '../translationKeys/NotFoundPageKeys';

export const NotFoundTranslations = {
  [NotFoundKeys.title]: '404 : Page Not Found',
  [NotFoundKeys.description]:
    'We are sorry. The page you are looking for could not be found.',

  // Server Updating Page
  [NotFoundKeys.Server_Updating.title]: 'The server is currently being updated',
  [NotFoundKeys.Server_Updating.description]: 'Please come back again!',

  // Under Development Page
  [NotFoundKeys.Under_Development.title]: 'Currently under development',
  [NotFoundKeys.Under_Development.description]: 'Welcome to Web 3.0 Marketing!',
};
