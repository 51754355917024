import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { INSERT_IMAGE_COMMAND } from '..';
import ImageUploader from './ImageUploader';
import Stack from 'components/Basics/Layout/Stack';
import removeImage from '../removeImage';

const InsertImageModal = ({ onClose }) => {
  const [editor] = useLexicalComposerContext();

  const [isUploading, setIsUploading] = useState(false);
  const { handleSubmit, register, watch, setValue, getValues, reset } =
    useForm();

  const onSubmit = useCallback(
    (data) => {
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
      onClose();
    },
    [editor, onClose]
  );

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <h2 className="bh2">Insert Image</h2>
      <Stack gap="1rem">
        <ImageUploader
          image={watch('src')}
          thumbnail={watch('thumbnail')}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
          setValue={setValue}
        />
        {watch('src') && (
          <Input {...register('alt')} placeholder="Alt Text" autoFocus />
        )}
      </Stack>

      {watch('src') && (
        <Stack direction="row" justify="center" gap="1rem">
          <SecondaryButton
            type="button"
            onClick={() => {
              removeImage(getValues('src'), getValues('thumbnail'));
              reset();
            }}
          >
            Reset
          </SecondaryButton>
          <Button type="submit" disabled={isUploading}>
            {watch('alt') ? 'Insert' : 'Skip & Insert Image'}
          </Button>
        </Stack>
      )}
    </Wrapper>
  );
};

export default InsertImageModal;

const Wrapper = styled.form`
  width: 750px;
  padding: 2rem 3rem;
  border-radius: 20px;
  background-color: white;
  box-shadow: var(--shadow-modal);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
`;

const Input = styled.input.attrs({
  className: 'h7',
})`
  width: 50%;
  padding: 0.75rem 1rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  border: 1.4px solid var(--color-indigo-25);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: var(--color-indigo-100);
  }
`;

const Button = styled.button.attrs({
  className: 'h6',
})`
  padding: 0.75rem 2rem;
  border-radius: 0.75rem;
  background-color: var(--color-indigo-500);
  color: white;
  border: none;
  transition: all 150ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: var(--color-indigo-200);
  border: 2px solid var(--color-indigo-100);
`;
