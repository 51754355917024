import imageCompressor from 'components/utilities/imageCompressor';
import ViewableFiles from '../components/MessagesPage/ChatBox/Chat/Files/Preview/ViewableFiles';

function captureThumbnail(file, seekTo = 0.0) {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', (ex) => {
      reject('error when loading video file', ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        seekTo = videoPlayer.duration / 2;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          'image/jpeg',
          0.75 /* quality */
        );
      });
    });
  });
};

const getThumbnail = async (file) => {
  // check file type
  const type = file.type.split('/')[0];

  if (type !== 'image' && type !== 'video') return null;

  // check if type is viewable or not
  const viewableExtensions = ViewableFiles[type];
  const extension = file.name.split('.').pop();
  const isViewable = viewableExtensions.includes(extension);

  // if viewable then get thumbnail for both
  if (isViewable) {
    try {
      if (type === 'image') {
        const compressed = await imageCompressor(file, 160, 0.6);
        const name =
          file.name.slice(0, file.name.lastIndexOf('.')) + '-thumbnail.jpg';
        const thumbnail = new File([compressed], name, { type: file.type });

        return thumbnail;
      }

      if (type === 'video') return await captureThumbnail(file, 1.0);
    } catch (err) {
      console.log(err);
    }
  }

  return null;
};

export default getThumbnail;
