import { countryCodeMap } from '../../constants/languages';

export const getCountryCode = (browserLanguage) => {
  const defaultCountryCode = 'USD';
  const language = browserLanguage.split('-')[0];

  if (countryCodeMap[language]) {
    return countryCodeMap[language];
  }

  return defaultCountryCode;
};
