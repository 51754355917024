export const AcceptanceIcon = ({
  size = 1,
  color = '#2867E0',
  excludeFill,
  style,
}) => {
  return (
    <svg
      width={size * 16}
      height={size * 17}
      viewBox="0 0 16 17"
      fill={excludeFill ? excludeFill : 'none'}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M6.88 12.18L12.52 6.54L11.4 5.42L6.88 9.94L4.6 7.66L3.48 8.78L6.88 12.18ZM8 16.5C6.89333 16.5 5.85333 16.2899 4.88 15.8696C3.90667 15.4499 3.06 14.88 2.34 14.16C1.62 13.44 1.05013 12.5933 0.6304 11.62C0.210133 10.6467 0 9.60667 0 8.5C0 7.39333 0.210133 6.35333 0.6304 5.38C1.05013 4.40667 1.62 3.56 2.34 2.84C3.06 2.12 3.90667 1.54987 4.88 1.1296C5.85333 0.709867 6.89333 0.5 8 0.5C9.10667 0.5 10.1467 0.709867 11.12 1.1296C12.0933 1.54987 12.94 2.12 13.66 2.84C14.38 3.56 14.9499 4.40667 15.3696 5.38C15.7899 6.35333 16 7.39333 16 8.5C16 9.60667 15.7899 10.6467 15.3696 11.62C14.9499 12.5933 14.38 13.44 13.66 14.16C12.94 14.88 12.0933 15.4499 11.12 15.8696C10.1467 16.2899 9.10667 16.5 8 16.5ZM8 14.9C9.78667 14.9 11.3 14.28 12.54 13.04C13.78 11.8 14.4 10.2867 14.4 8.5C14.4 6.71333 13.78 5.2 12.54 3.96C11.3 2.72 9.78667 2.1 8 2.1C6.21333 2.1 4.7 2.72 3.46 3.96C2.22 5.2 1.6 6.71333 1.6 8.5C1.6 10.2867 2.22 11.8 3.46 13.04C4.7 14.28 6.21333 14.9 8 14.9Z"
        fill={color}
      />
    </svg>
  );
};
