export const HamburgerIcon1 = ({ size = 1 }) => (
  <svg
    width={size * 26}
    height={size * 18}
    viewBox="0 0 26 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.5" width="26" height="3" rx="1" />
    <rect y="7.5" width="26" height="3" rx="1" />
    <rect y="14.5" width="26" height="3" rx="1" />
  </svg>
);
