import {
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  HIGHLIGHT,
  UNORDERED_LIST,
  ORDERED_LIST,
  $convertFromMarkdownString as convertFromMarkdown,
} from '@lexical/markdown';

const TRANSFORMERS = [
  BOLD_STAR,
  BOLD_UNDERSCORE,
  ITALIC_STAR,
  ITALIC_UNDERSCORE,
  BOLD_ITALIC_STAR,
  BOLD_ITALIC_UNDERSCORE,
  HIGHLIGHT,
  UNORDERED_LIST,
  ORDERED_LIST,
];

export const $convertFromMarkdownString = (string, node) => {
  return convertFromMarkdown(string, TRANSFORMERS, node);
};

export default TRANSFORMERS;
