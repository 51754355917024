export const SendIcon = ({ size = 1 }) => (
  <svg
    width={19 * size}
    height={18 * size}
    viewBox="0 0 19 18"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.7341 0.77833C17.3902 0.427924 16.9541 0.181975 16.4763 0.0689248C15.9985 -0.0441258 15.4984 -0.0196609 15.0339 0.13949L2.29817 4.39542C1.78452 4.5635 1.33573 4.88683 1.01375 5.32078C0.691774 5.75473 0.512482 6.2779 0.500628 6.81807C0.488774 7.35824 0.644944 7.88876 0.947577 8.33641C1.25021 8.78406 1.68439 9.12676 2.19016 9.31719L6.90644 11.1167C7.01383 11.1578 7.11161 11.2205 7.19368 11.301C7.27575 11.3815 7.34036 11.4781 7.38346 11.5846L9.18357 16.3084C9.3668 16.8069 9.69931 17.2368 10.1358 17.5395C10.5723 17.8422 11.0915 18.003 11.6227 18H11.6857C12.2267 17.9902 12.751 17.8114 13.1851 17.4887C13.6193 17.1659 13.9416 16.7155 14.1069 16.2005L18.3641 3.45066C18.5189 2.99082 18.542 2.49685 18.4306 2.0246C18.3193 1.55234 18.078 1.12065 17.7341 0.77833ZM16.699 2.8838L12.3968 15.6426C12.3468 15.8041 12.2465 15.9454 12.1104 16.0457C11.9744 16.1461 11.8098 16.2003 11.6407 16.2005C11.4726 16.2032 11.3077 16.1548 11.1678 16.0616C11.0279 15.9683 10.9198 15.8348 10.8577 15.6786L9.05756 10.9548C8.92705 10.6115 8.72579 10.2996 8.46684 10.0392C8.20789 9.77873 7.89703 9.57567 7.55447 9.44316L2.8292 7.64361C2.6698 7.58522 2.53277 7.47823 2.43748 7.33777C2.3422 7.1973 2.29346 7.03046 2.29817 6.86081C2.29831 6.69178 2.35255 6.52723 2.45296 6.39124C2.55336 6.25524 2.69467 6.15494 2.8562 6.105L15.6189 1.84906C15.7655 1.78936 15.9262 1.77337 16.0816 1.80302C16.237 1.83267 16.3805 1.90669 16.4948 2.01614C16.609 2.12559 16.6891 2.26577 16.7253 2.41975C16.7616 2.57373 16.7524 2.73491 16.699 2.8838Z" />
  </svg>
);
