import AxiosClient from 'components/utilities/AxiosClient';

const Sort = {
  PublishedAt: 'publishedAt',
  Views: 'views',
  Likes: 'likes',
  Comments: 'comments',
  Title: 'title',
  contentId: 'contentId',
};

const getContents = async ({
  userId,
  handle,
  linksId,
  platformUid,
  sort,
  limit,
  nextToken,
  platform,
  isBasic,
}) => {
  if (!(userId || handle || linksId || platformUid) || !platform)
    return Promise.reject({
      message: 'Missing required parameters for getVideos',
    });

  const res = await AxiosClient.get('/platforms/getContent', {
    params: {
      userId,
      handle,
      linksId,
      platformUid,
      sort,
      limit,
      nextToken,
      platform,
      isBasic,
    },
  });

  return res.data;
};

export default getContents;

export { Sort };
