const size3 = () => (
  <svg
    width="26"
    height="18"
    viewBox="0 0 26 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.4264 2.81862C25.2772 2.27379 24.9863 1.77709 24.5825 1.37799C24.1788 0.978894 23.6763 0.691323 23.1252 0.543916C21.1073 0 12.9869 0 12.9869 0C12.9869 0 4.86613 0.0165061 2.84859 0.560422C2.29746 0.707853 1.79501 0.995435 1.39129 1.39453C0.987562 1.79363 0.696644 2.29031 0.547504 2.83512C-0.0628321 6.37922 -0.29962 11.7792 0.564201 15.1814C0.713342 15.7262 1.00426 16.2229 1.40798 16.622C1.81171 17.0211 2.31415 17.3087 2.86528 17.4561C4.88299 18 13.0036 18 13.0036 18C13.0036 18 21.1242 18 23.1419 17.4561C23.693 17.3087 24.1954 17.0211 24.5992 16.622C25.0029 16.2229 25.2938 15.7262 25.4429 15.1814C26.0865 11.6324 26.285 6.2357 25.4262 2.81877L25.4264 2.81862Z"
      fill="#FF0000"
    />
    <path
      d="M10.3984 12.8608L17.135 9.00373L10.3984 5.14648L10.3984 12.8608Z"
      fill="white"
    />
  </svg>
);

export default size3;
