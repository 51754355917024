import styled from 'styled-components';
import { createPortal } from 'react-dom';
import device from 'styles/device';

const Loading = () => {
  return createPortal(
    <Container
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <MegaSpinning src="/assets/gif/MegaLoading.gif" />
    </Container>,
    document.getElementById('root')
  );
};

const Container = styled.div`
  position: fixed;
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
`;

const MegaSpinning = styled.img`
  width: 10%;
  height: auto;

  @media ${device.sm} {
    width: 20%;
  }
`;

export default Loading;
