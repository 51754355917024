import Popup from 'components/Basics/Popup';
import dayjs from 'dayjs';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import ProfileKeys from 'translations/translationKeys/ProfilePageKeys';
import { useEffect, useState } from 'react';
import AxiosClient from 'components/utilities/AxiosClient';
import clientPlans from 'constants/clientPlans';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import { Trans } from 'react-i18next';
import i18n from 'i18next';

const checkHaveMessageQouta = async (toId, toHandle) => {
  const res = await AxiosClient.get('/checkHaveMessageQouta', {
    params: {
      toId,
      toHandle,
    },
  });
  return res.data;
};

const MessageQuotaEndedModal = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [isOpen, toggleIsOpen] = useState();
  const [passedData, setPassedData] = useState({});

  useEffect(() => {
    const catchModalClose = (e) => {
      toggleIsOpen(false);
      if (e?.detail?.event) {
        if (e?.detail?.data?.backendCall) {
          checkHaveMessageQouta(
            e?.detail?.data?.messageTo,
            e?.detail?.data?.messageToHandle
          )
            .then((res) => {
              if (res) {
                toggleIsOpen(false);
                e?.detail?.data?.haveMessageQuotaLeft?.();
                setPassedData(e?.detail?.data);
              }
            })
            .catch((err) => {
              if (
                err?.response?.data?.key ===
                'QUOTA_EXCEEDED_FOR_SENDING_MESSAGE'
              ) {
                toggleIsOpen(true);
                e?.detail?.data?.onQoutaEnded?.();
              }
            });
        } else {
          toggleIsOpen(true);
          setPassedData(e?.detail?.data);
        }
      }
      e.stopImmediatePropagation();
    };

    window.addEventListener('open-message-quota-modal', catchModalClose);

    return () => {
      window.removeEventListener('open-message-quota-modal', catchModalClose);
    };
  }, []);

  const onClose = () => {
    toggleIsOpen(false);
    setPassedData({});
  };

  return (
    <Popup
      show={isOpen}
      onClose={onClose}
      title={t(data[user?.clientPlan?.currentPlan]?.title)}
      description={
        <Trans
          values={{
            date: dayjs(user?.clientPlan?.renewalDate).format(
              dayjs.Ls[i18n.language].dateFormat.dot_date
            ),
          }}
          components={{ span: <span className="hidden md-inline"></span> }}
        >
          {t(data[user?.clientPlan?.currentPlan]?.description)}
        </Trans>
      }
      buttonText={t(data[user?.clientPlan?.currentPlan]?.buttonText)}
      onButtonClick={() => {
        toggleIsOpen(false);
        passedData?.onClose?.();
        window.open('/pricing', '_blank');
      }}
      secondaryButtonText={t(GeneralKeys.return)}
      onSecondaryButtonClick={() => {
        passedData?.onClose?.();
        onClose();
      }}
    />
  );
};

export default MessageQuotaEndedModal;

const data = {
  [clientPlans.Basic]: {
    title: ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title,
    description:
      ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description,
    buttonText: GeneralKeys.continue,
  },
  [clientPlans.Verified]: {
    title: ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title,
    description:
      ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description,
    buttonText: ProfileKeys.Edit.Package.Price.Modal.btn,
  },
};
