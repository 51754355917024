export const ChevronRightIconRounded = ({ size = 1, style }) => (
  <svg
    width={5 * size}
    height={8 * size}
    viewBox="0 0 5 8"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path d="M1.41257 7.5396C1.15385 7.79486 0.738038 7.79486 0.479321 7.5396C0.215647 7.27944 0.215646 6.8539 0.479321 6.59374L2.38665 4.71184C2.78353 4.32026 2.78353 3.67974 2.38665 3.28816L0.479321 1.40626C0.215647 1.1461 0.215647 0.720562 0.479321 0.460403C0.738038 0.205136 1.15385 0.205136 1.41257 0.460403L4.27854 3.28816C4.67542 3.67974 4.67542 4.32026 4.27854 4.71184L1.41257 7.5396Z" />
  </svg>
);
