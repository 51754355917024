export const AllIcon = ({ size = 1 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16 * size}
    height={10 * size}
    viewBox="0 0 16 10"
    fill="currentColor"
  >
    <path d="M2.12476 9.43397H0L3.40883 0.796733H5.53935L8.94242 9.43397H6.81766L6.18426 7.73531H2.75816L2.12476 9.43397ZM3.40883 5.96756H5.53935L4.47409 3.13454L3.40883 5.96756Z" />
    <path d="M12.2035 9.43397H10.1766V0.566406H12.2035V9.43397Z" />
    <path d="M16 9.43397H13.9731V0.566406H16V9.43397Z" />
  </svg>
);
