export const InstagramReelsIcon = ({ size = 1 }) => (
  <svg
    width={size * 20}
    height={size * 20}
    viewBox="0 0 20 20"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_52851_245099)">
      <mask
        id="mask0_52851_245099"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="20"
        height="20"
      >
        <path d="M20 0H0V20H20V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_52851_245099)">
        <path
          d="M0.953613 5.45825H19.0454"
          fill="none"
          stroke-width="2"
          stroke-linejoin="round"
        />
        <path
          d="M11.3672 0.90918L13.9654 5.45554"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.64307 1.00854L8.18398 5.45582"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.90918 10.001V13.1365C0.90918 15.7265 1.54373 16.7783 2.36918 17.6319C3.22373 18.4574 4.27645 19.0928 6.86554 19.0928H13.1346C15.7237 19.0928 16.7765 18.4574 17.631 17.6319C18.4565 16.7783 19.091 15.7265 19.091 13.1365V6.86554C19.091 4.27645 18.4565 3.22373 17.631 2.37009C16.7765 1.54463 15.7237 0.90918 13.1346 0.90918H6.86554C4.27645 0.90918 3.22373 1.54463 2.36918 2.37009C1.54373 3.22373 0.90918 4.27645 0.90918 6.86554V10.001Z"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.96595 15.148C7.84039 15.0755 7.73612 14.9712 7.66367 14.8456C7.59121 14.72 7.55312 14.5775 7.55322 14.4325V9.66252C7.55306 9.51734 7.59114 9.37471 7.66364 9.24889C7.73614 9.12316 7.84051 9.01866 7.96621 8.94603C8.09192 8.87341 8.23456 8.83518 8.37974 8.83521C8.52492 8.83523 8.66754 8.87351 8.79322 8.94618L12.9251 11.3316C13.0508 11.4042 13.1552 11.5084 13.2279 11.6342C13.3004 11.7598 13.3387 11.9024 13.3387 12.0475C13.3387 12.1927 13.3004 12.3353 13.2279 12.461C13.1552 12.5866 13.0508 12.691 12.9251 12.7634L8.79322 15.1489C8.66747 15.2215 8.52481 15.2597 8.37959 15.2597C8.23438 15.2597 8.09171 15.2206 7.96595 15.148Z"
          stroke="none"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_52851_245099">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
