import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $rootTextContent } from '@lexical/text';
import { useMemo } from 'react';
import { createContext, useContext, useState, useEffect } from 'react';

const EditorContext = createContext({
  charCount: 0,
  isSuggestionLoading: false,
  setIsSuggestionLoading: () => {},
  gptEnabled: false,
  setGptEnabled: () => {},
  gptTyping: false,
  setGptTyping: () => {},
});

export const EditorContextProvider = ({ showImageCaption, children }) => {
  const [editor] = useLexicalComposerContext();

  const [charCount, setCharCount] = useState(0);
  const [isSuggestionLoading, setIsSuggestionLoading] = useState(false);
  const [gptEnabled, setGptEnabled] = useState(false);
  const [gptTyping, setGptTyping] = useState(false);

  useEffect(() => {
    let text = editor.getEditorState().read($rootTextContent);

    return editor.registerTextContentListener((currentText) => {
      text = currentText;
      setCharCount(text.length);
    });
  }, [editor]);

  const data = useMemo(
    () => ({
      charCount,
      isSuggestionLoading,
      setIsSuggestionLoading,
      gptEnabled,
      setGptEnabled,
      gptTyping,
      setGptTyping,
      showImageCaption,
    }),
    [charCount, isSuggestionLoading, gptEnabled, gptTyping, showImageCaption]
  );

  return (
    <EditorContext.Provider value={data}>{children}</EditorContext.Provider>
  );
};

export const useEditorContext = () => useContext(EditorContext);
