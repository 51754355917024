import styled from 'styled-components';
import { usePackages } from 'contexts/PackagesContext';
import { useProfileState } from 'components/components/ProfilePage/common/ProfileContext';
import PlatformIcon from 'components/Basics/PlatformIcon';

const PlatformIconButton = () => {
  const { platform } = usePackages();
  const { user } = useProfileState();

  const url = !!user.isAdmin
    ? null
    : user?.links?.find((link) => link.type === platform)?.url || '';

  return (
    <Wrapper href={url} target="_blank" rel="noopener noreferrer">
      <PlatformIcon platform={platform} variant="size1" />
    </Wrapper>
  );
};

export default PlatformIconButton;

const Wrapper = styled.a`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 150ms ease-in-out;
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }

  &:focus {
    outline: none;
  }
`;
