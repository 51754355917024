import NotFoundKeys from '../translationKeys/NotFoundPageKeys';

export const NotFoundTranslations = {
  [NotFoundKeys.title]: '404 : 페이지를 찾을 수 없음',
  [NotFoundKeys.description]:
    '죄송합니다. 찾고 있는 페이지를 찾을 수 없습니다.',

  // Server Updating Page
  [NotFoundKeys.Server_Updating.title]: '서버 업데이트 중입니다.',
  [NotFoundKeys.Server_Updating.description]: '다음에 다시 찾아주세요!',

  // Under Development Page
  [NotFoundKeys.Under_Development.title]: '현재 개발중인 페이지입니다.',
  [NotFoundKeys.Under_Development.description]: '웹 3.0에 오신걸 환영합니다!',
};
