export const FranceFlagIcon = ({ size = 1 }) => (
  <svg
    width={34 * size}
    height={34 * size}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_29896_35075)">
      <rect
        x="0.128418"
        y="0.064209"
        width="33"
        height="33"
        rx="16.5"
        fill="#F5F5F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-6.46701 0.064209H8.93299V33.0642H-6.46701V0.064209Z"
        fill="#1A47B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.335 0.064209H39.735V33.0642H24.335V0.064209Z"
        fill="#F93939"
      />
    </g>
    <defs>
      <clipPath id="clip0_29896_35075">
        <rect
          x="0.128418"
          y="0.064209"
          width="33"
          height="33"
          rx="16.5"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
);
