export const RefreshIcon = ({ size = 1 }) => (
  <svg
    width={12 * size}
    height={13 * size}
    viewBox="0 0 12 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 12.5C7.675 12.5 9.09375 11.9187 10.2563 10.7562C11.4188 9.59375 12 8.175 12 6.5C12 4.825 11.4188 3.40625 10.2563 2.24375C9.09375 1.08125 7.675 0.5 6 0.5C5.1375 0.5 4.3125 0.67825 3.525 1.03475C2.7375 1.39075 2.0625 1.9 1.5 2.5625V0.5H0V5.75H5.25V4.25H2.1C2.5 3.55 3.04675 3 3.74025 2.6C4.43425 2.2 5.1875 2 6 2C7.25 2 8.3125 2.4375 9.1875 3.3125C10.0625 4.1875 10.5 5.25 10.5 6.5C10.5 7.75 10.0625 8.8125 9.1875 9.6875C8.3125 10.5625 7.25 11 6 11C5.0375 11 4.16875 10.725 3.39375 10.175C2.61875 9.625 2.075 8.9 1.7625 8H0.1875C0.5375 9.325 1.25 10.4063 2.325 11.2438C3.4 12.0813 4.625 12.5 6 12.5Z" />
  </svg>
);
