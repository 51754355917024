export const BlogIcon = ({ size = 1 }) => (
  <svg
    width={size * 25}
    height={size * 33}
    viewBox="0 0 25 33"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.9857 9.62497C24.9857 8.361 24.7343 7.10941 24.246 5.94166C23.7577 4.7739 23.042 3.71285 22.1397 2.81909C21.2374 1.92533 20.1663 1.21636 18.9874 0.732658C17.8085 0.248958 16.545 0 15.269 0H2.77618C2.03989 0 1.33376 0.289731 0.813126 0.805455C0.292491 1.32118 0 2.02065 0 2.74999V30.2499C0 30.9793 0.292491 31.6787 0.813126 32.1945C1.33376 32.7102 2.03989 32.9999 2.77618 32.9999H15.269C17.2031 33.0082 19.0955 32.443 20.7025 31.3769C22.3095 30.3108 23.5576 28.7927 24.2862 27.018C25.0148 25.2432 25.1905 23.2931 24.7907 21.4186C24.3909 19.5442 23.4339 17.8311 22.0429 16.5C22.971 15.606 23.7095 14.5373 24.2148 13.3567C24.7201 12.1761 24.9822 10.9073 24.9857 9.62497ZM15.269 27.4999H5.55237V19.2499H15.269C16.3735 19.2499 17.4327 19.6845 18.2136 20.4581C18.9946 21.2317 19.4333 22.2809 19.4333 23.3749C19.4333 24.469 18.9946 25.5182 18.2136 26.2917C17.4327 27.0653 16.3735 27.4999 15.269 27.4999ZM15.269 13.75H5.55237V5.49998H15.269C16.3735 5.49998 17.4327 5.93458 18.2136 6.70817C18.9946 7.48175 19.4333 8.53096 19.4333 9.62497C19.4333 10.719 18.9946 11.7682 18.2136 12.5418C17.4327 13.3154 16.3735 13.75 15.269 13.75Z" />
  </svg>
);
