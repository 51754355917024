export const TableViewIcon = ({ size = 1 }) => (
  <svg
    width={18 * size}
    height={18 * size}
    viewBox="0 0 18 18"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 5H16" strokeWidth="2" />
    <path d="M5 9H16" strokeWidth="2" />
    <path d="M5 13H16" strokeWidth="2" />
    <path d="M1 5H3" strokeWidth="2" />
    <path d="M1 9H3" strokeWidth="2" />
    <path d="M1 13H3" strokeWidth="2" />
  </svg>
);
