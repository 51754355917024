import i18n from 'i18next';
import {
  supportedLanguagesMap,
  languageSubdomains,
} from '../constants/languages';
import { replaceSubdomain } from '../components/utilities/replaceSubdomain';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { countrySubdomains } from '../constants/languages';
import { TRANSLATIONS_EN } from './en/translations';
import { TRANSLATIONS_KO } from './kor/translations';
import { TRANSLATIONS_JA } from './ja/translations';
import { TRANSLATIONS_ZH } from './ch/translations';
import { TRANSLATIONS_ESP } from './esp/translations';
import { TRANSLATIONS_VI } from './vi/translations';
import { TRANSLATIONS_TH } from './th/translations';
// import { TRANSLATIONS_HINDI } from './hindi/translations';
// import { TRANSLATIONS_FR } from './fr/translations';
// import { TRANSLATIONS_POR } from './por/translations';
// import { TRANSLATIONS_ITA } from './ita/translations';
// import { TRANSLATIONS_GER } from './ger/translations';

const resources = {
  en: {
    translation: TRANSLATIONS_EN,
  },
  ko: {
    translation: TRANSLATIONS_KO,
  },
  ja: {
    translation: TRANSLATIONS_JA,
  },
  zh: {
    translation: TRANSLATIONS_ZH,
  },
  es: {
    translation: TRANSLATIONS_ESP,
  },
  vi: {
    translation: TRANSLATIONS_VI,
  },
  th: {
    translation: TRANSLATIONS_TH,
  },
  // hi: {
  //   translation: TRANSLATIONS_HINDI,
  // },
  // fr: {
  //   translation: TRANSLATIONS_FR,
  // },
  // pt: {
  //   translation: TRANSLATIONS_POR,
  // },
  // it: {
  //   translation: TRANSLATIONS_ITA,
  // },
  // de: {
  //   translation: TRANSLATIONS_GER,
  // },
};

// list of regexps of paths on which we wont bother of language, it will be whatever it is
const noLanguageChangePaths = [
  // /platforms/:platform/auth/:req_type
  /platforms\/[a-zA-Z]+\/auth\/[a-zA-Z]+/,
];

const subdomainDetector = {
  name: 'subdomainDetector',

  lookup(options) {
    const { lookupFromSubdomain } = options;
    let subdomain = window.location.hostname.split('.')[0];
    let lang = countrySubdomains[subdomain] || 'en';
    const browserLanguage = navigator.language.split('-')[0];

    if (lookupFromSubdomain) {
      if (countrySubdomains[subdomain]) {
        lang = countrySubdomains[subdomain];
      } else {
        if (supportedLanguagesMap.includes(browserLanguage)) {
          lang = browserLanguage;
          subdomain = languageSubdomains[lang];
          let url = window.location.href;

          if (
            process.env.REACT_APP_NODE_ENV !== 'development' &&
            !noLanguageChangePaths.some((path) => path.test(url))
          ) {
            url = replaceSubdomain(url, subdomain);
            window.location.replace(url);
          }
        }
      }
    }

    return lang;
  },
};

const languageDetector = new LanguageDetector();
languageDetector.addDetector(subdomainDetector);

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: supportedLanguagesMap,
    nonExplicitSupportedLngs: true,
    order: ['subdomainDetector'],
    caches: ['localStorage'],
    detection: {
      order: ['subdomainDetector'],
      lookupFromSubdomain: true,
    },
  });

export default i18n;
