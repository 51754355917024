export const YoutubeLiveIcon = ({ size = 1 }) => (
  <svg
    width={size * 22}
    height={size * 16}
    viewBox="0 0 22 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.22461 0.226562C1.23361 2.21856 0 4.96895 0 8.00195C0 11.035 1.23361 13.7853 3.22461 15.7773L4.63867 14.3633C3.00867 12.7333 2 10.483 2 8.00195C2 5.52095 3.00867 3.27063 4.63867 1.64062L3.22461 0.226562ZM18.7754 0.226562L17.3613 1.64062C18.9913 3.27063 20 5.52095 20 8.00195C20 10.483 18.9913 12.7333 17.3613 14.3633L18.7754 15.7773C20.7664 13.7853 22 11.035 22 8.00195C22 4.96895 20.7664 2.21856 18.7754 0.226562ZM6.05273 3.05469C4.78473 4.32169 4 6.07195 4 8.00195C4 9.93195 4.78473 11.6822 6.05273 12.9492L7.4668 11.5352C6.5608 10.6302 6 9.37995 6 8.00195C6 6.62395 6.5618 5.37475 7.4668 4.46875L6.05273 3.05469ZM15.9473 3.05469L14.5332 4.46875C15.4392 5.37375 16 6.62395 16 8.00195C16 9.37995 15.4382 10.6292 14.5332 11.5352L15.9473 12.9492C17.2143 11.6812 18 9.93195 18 8.00195C18 6.07195 17.2153 4.32169 15.9473 3.05469ZM11 5.00195C10.2044 5.00195 9.44129 5.31802 8.87868 5.88063C8.31607 6.44324 8 7.2063 8 8.00195C8 8.7976 8.31607 9.56067 8.87868 10.1233C9.44129 10.6859 10.2044 11.002 11 11.002C11.7956 11.002 12.5587 10.6859 13.1213 10.1233C13.6839 9.56067 14 8.7976 14 8.00195C14 7.2063 13.6839 6.44324 13.1213 5.88063C12.5587 5.31802 11.7956 5.00195 11 5.00195Z" />
  </svg>
);
