export const CommentHollowIcon = ({ size = 1 }) => (
  <svg
    width={16 * size}
    height={16 * size}
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.01126 0.0107422C6.96216 0.0107422 5.92334 0.217377 4.9541 0.618849C3.98486 1.02032 3.10419 1.60877 2.36237 2.35059C0.864186 3.84877 0.0225174 5.88074 0.0225174 7.99948C0.0155335 9.84419 0.654261 11.6332 1.82797 13.0564L0.230225 14.6541C0.119375 14.7664 0.0442836 14.9091 0.0144264 15.0641C-0.0154309 15.219 0.00128371 15.3794 0.062461 15.5249C0.128814 15.6686 0.236379 15.7894 0.371503 15.8719C0.506627 15.9544 0.663215 15.9949 0.821391 15.9882H8.01126C10.13 15.9882 12.162 15.1466 13.6602 13.6484C15.1583 12.1502 16 10.1182 16 7.99948C16 5.88074 15.1583 3.84877 13.6602 2.35059C12.162 0.852411 10.13 0.0107422 8.01126 0.0107422ZM8.01126 14.3905H2.74668L3.48963 13.6475C3.63842 13.4978 3.72194 13.2954 3.72194 13.0843C3.72194 12.8733 3.63842 12.6708 3.48963 12.5211C2.44357 11.4762 1.79216 10.101 1.64638 8.62963C1.5006 7.15831 1.86947 5.68195 2.69014 4.4521C3.51081 3.22225 4.73251 2.31498 6.1471 1.88488C7.56168 1.45478 9.08164 1.52845 10.448 2.09334C11.8144 2.65823 12.9426 3.67939 13.6405 4.98285C14.3384 6.2863 14.5627 7.79141 14.2753 9.24174C13.9879 10.6921 13.2066 11.9979 12.0644 12.9367C10.9222 13.8756 9.48979 14.3893 8.01126 14.3905Z" />
  </svg>
);
