import Dropdown from './Dropdown';
import styled from 'styled-components';
import SearchBox from './SearchBox';
import { useState } from 'react';
import { memo } from 'react';
import Option from './Option';

const CountriesDropdown = ({
  show,
  toggleShow,
  trigger,
  selectedCountry,
  selectCountry,
  list,
  className,
  haveSearchBox,
  height,
}) => {
  const [search, setSearch] = useState('');

  return (
    <Dropdown
      showDropdown={show}
      toggleDropdown={toggleShow}
      className={className}
      trigger={trigger}
      translateX="-50%"
      marginTop="20px"
      border="none"
      menuWidth="calc(100% + 40px)"
    >
      <Wrapper haveSearchBox={haveSearchBox}>
        {haveSearchBox && <SearchBox text={search} setText={setSearch} />}
        <Items className="dropdown-menu-parent" height={height}>
          {(haveSearchBox
            ? list.filter((country) =>
                country.name.toLowerCase().includes(search.toLowerCase())
              )
            : list
          ).map((country) => (
            <Option
              key={country.value}
              onClick={() => {
                selectCountry(country.value);
                toggleShow();
                setTimeout(() => {
                  setSearch('');
                }, 150);
              }}
              name={country.name}
            />
          ))}
        </Items>
      </Wrapper>
    </Dropdown>
  );
};

export default memo(CountriesDropdown);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: ${({ haveSearchBox }) =>
    haveSearchBox ? '18px 8px 14px 18px' : '16px 8px 16px 20px'};
`;

const Items = styled.div.attrs({
  className: 'scrollbar-y-thin',
})`
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: ${({ height }) => (height ? height : '172px')};
  padding-right: 10px;
`;
