export const MySpaceMessageIcon = ({
  size = 1,
  color = '#36BD8C',
  noColor,
  ...rest
}) => {
  return (
    <svg
      width={size * 54}
      height={size * 54}
      viewBox="0 0 54 54"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M45.8994 16.1998H43.1994V8.09989C43.1994 5.95166 42.346 3.89143 40.827 2.3724C39.308 0.853379 37.2477 0 35.0995 0H8.09989C5.95166 0 3.89143 0.853379 2.3724 2.3724C0.853379 3.89143 0 5.95166 0 8.09989V40.4994C0.00266076 41.0325 0.163045 41.5528 0.46094 41.9948C0.758836 42.4369 1.18091 42.7808 1.67398 42.9834C1.99441 43.1345 2.34579 43.2085 2.69996 43.1994C3.0553 43.2015 3.40754 43.1333 3.7365 42.999C4.06545 42.8646 4.36465 42.6666 4.61694 42.4164L12.2038 34.8025H16.1998V38.6905C16.1998 40.8387 17.0532 42.8989 18.5722 44.4179C20.0912 45.937 22.1514 46.7903 24.2997 46.7903H42.9834L49.3823 53.2163C49.6346 53.4665 49.9338 53.6645 50.2627 53.7988C50.5917 53.9332 50.944 54.0013 51.2993 53.9992C51.6535 54.0084 52.0048 53.9344 52.3253 53.7832C52.8183 53.5807 53.2404 53.2367 53.5383 52.7947C53.8362 52.3527 53.9966 51.8323 53.9992 51.2993V24.2997C53.9992 22.1514 53.1459 20.0912 51.6268 18.5722C50.1078 17.0532 48.0476 16.1998 45.8994 16.1998ZM16.1998 24.2997V29.4026H11.0968C10.7415 29.4005 10.3893 29.4686 10.0603 29.603C9.73135 29.7374 9.43216 29.9353 9.17987 30.1856L5.39992 33.9925V8.09989C5.39992 7.38381 5.68438 6.69707 6.19073 6.19073C6.69707 5.68438 7.38381 5.39992 8.09989 5.39992H35.0995C35.8156 5.39992 36.5023 5.68438 37.0087 6.19073C37.515 6.69707 37.7995 7.38381 37.7995 8.09989V16.1998H24.2997C22.1514 16.1998 20.0912 17.0532 18.5722 18.5722C17.0532 20.0912 16.1998 22.1514 16.1998 24.2997ZM48.5993 44.7924L45.8994 42.0924C45.6493 41.8373 45.3511 41.6343 45.022 41.4953C44.693 41.3563 44.3396 41.2839 43.9824 41.2824H24.2997C23.5836 41.2824 22.8968 40.998 22.3905 40.4916C21.8842 39.9853 21.5997 39.2985 21.5997 38.5825V24.2997C21.5997 23.5836 21.8842 22.8968 22.3905 22.3905C22.8968 21.8842 23.5836 21.5997 24.2997 21.5997H45.8994C46.6154 21.5997 47.3022 21.8842 47.8085 22.3905C48.3149 22.8968 48.5993 23.5836 48.5993 24.2997V44.7924Z"
        fill="#EDD6FF"
        style={{ transition: 'fill 0.3s ease' }}
      />
    </svg>
  );
};
