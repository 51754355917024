import { createContext, useCallback, useState } from 'react';
import getAudienceAnalytics from '../apis/getAudienceAnalytics';
import { useAsyncFn } from 'react-use';
import dummyData from './dummyData';
import { useContext } from 'react';
import { useMemo } from 'react';
import { useAnalytics } from '..';

const AudienceAnalyticsContext = createContext();

const AudienceAnalyticsProvider = ({ children, userId, handle }) => {
  const [isFetched, setIsFetched] = useState(false);
  const { isUserAdmin } = useAnalytics();

  const [{ value: analytics, loading, error }, fetchAnalytics] =
    useAsyncFn(() => {
      if ((!userId && !handle) || !!isUserAdmin) return Promise.resolve({});

      return getAudienceAnalytics({
        userId,
        handle,
      });
    }, [userId, handle, isUserAdmin]);

  const requestAnalytics = useCallback(
    (force) => {
      if (!isFetched || force) {
        setIsFetched(true);
        fetchAnalytics();
      }
    },
    [isFetched, fetchAnalytics]
  );

  return (
    <AudienceAnalyticsContext.Provider
      value={{
        requestAnalytics,
        analytics,
        loading: !isFetched || loading,
        error,
      }}
    >
      {children}
    </AudienceAnalyticsContext.Provider>
  );
};

const useAudienceAnalytics = (demographicsLabels) => {
  const context = useContext(AudienceAnalyticsContext);

  if (!context) {
    throw new Error(
      'useAudienceAnalytics must be used within a AudienceAnalyticsProvider'
    );
  }

  const { requestAnalytics, analytics, loading, error } = context;

  const viewsPerCountry = useMemo(
    () => analytics?.viewsPerCountry || dummyData.viewsPerCountry,
    [analytics]
  );
  const ageGender = useMemo(
    () => analytics?.ageGender || dummyData.ageGender,
    [analytics]
  );
  const topCategories = useMemo(
    () => analytics?.topCategories || dummyData.topCategories,
    [analytics]
  );
  const topTags = useMemo(
    () => analytics?.topTags || dummyData.topTags,
    [analytics]
  );
  const keywords = useMemo(
    () => analytics?.keywords || dummyData.keywords,
    [analytics]
  );

  return {
    requestAnalytics,
    viewsPerCountry,
    ageGender,
    topCategories,
    topTags,
    keywords,
    loading,
    error,
  };
};

export { AudienceAnalyticsProvider, useAudienceAnalytics };
