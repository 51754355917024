import AxiosClient from './AxiosClient';
import { lazy } from 'react';

export const CSRF_validation = (state) => {
  const requestState = sessionStorage.getItem('g_oauth_state');
  sessionStorage.removeItem('g_oauth_state');
  if (state !== requestState) {
    return true;
  } else {
    return false;
  }
};
export const generateState = () => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const stateLength = Math.floor(Math.random() * 200) + 48;
  for (var i = 0; i < stateLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const generateAuthProtection = async (platform) => {
  const data = await AxiosClient.get('/platforms/generateAuthProtection', {
    params: {
      platform,
      applicationType: process.env.APPLICATION_TYPE,
    },
  });
  return data.data
};

export const ComponentPreloader = (loaderFunc) => {
  const Component = lazy(loaderFunc);
  Component.preload = loaderFunc;
  return Component;
};

const setInterSectionObserver = (
  ref,
  option = {},
  onIntersection,
  oneoff = true
) => {
  const interSectionInstance = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onIntersection();

          if (oneoff) {
            observer.unobserve(entry.target);
          }
        }
      });
    },
    {
      root: ref.current,
      threshold: 0,
      rootMargin: '0px',
      ...option,
    }
  );
  return interSectionInstance;
};

/**
 * @param {string} time - ISO format
 *
 **/
export const msgTimeForm = (time) => {
  let t = new Date(time);
  if (
    t.getHours() !== new Date().getHours() &&
    t.getMinutes() !== new Date().getMinutes()
  ) {
    const tzo = new Date().getTimezoneOffset();
    t = new Date(t.getTime() - 60 * tzo * 1000);
  }

  let month = t.getMonth() + 1;

  switch (month) {
    case 1: {
      month = 'Jan';
      break;
    }
    case 2: {
      month = 'Feb';
      break;
    }
    case 3: {
      month = 'Mar';
      break;
    }
    case 4: {
      month = 'Apr';
      break;
    }
    case 5: {
      month = 'May';
      break;
    }
    case 6: {
      month = 'Jun';
      break;
    }
    case 7: {
      month = 'Jul';
      break;
    }
    case 8: {
      month = 'Aug';
      break;
    }
    case 9: {
      month = 'Sep';
      break;
    }
    case 10: {
      month = 'Oct';
      break;
    }
    case 11: {
      month = 'Nov';
      break;
    }
    case 12: {
      month = 'Dec';
      break;
    }
    default: {
      console.log('invalid month');
    }
  }
  const day = t.getDate();
  let h = t.getHours();

  let m = t.getMinutes();

  const meridiem = h > 12 ? 'PM' : 'AM';
  h = h > 12 ? h - 12 : h;

  h = h < 10 ? '0' + h : h;
  m = m < 10 ? '0' + m : m;

  return `${month} ${day}, ${h}:${m} ${meridiem}`;
};
export const meridiemForm = (time) => {
  time = time.split(':');

  let hour = parseInt(time[0]);
  let min = parseInt(time[1]);
  const meridiem = hour > 12 ? 'PM' : 'AM';

  hour = hour > 12 ? hour - 12 : hour;
  hour = hour < 10 ? '0' + hour : hour;
  min = min < 10 ? '0' + min : min;

  return hour + ':' + min + ' ' + meridiem;
};

export const checkFileType = (file) => {
  const types = new Map([
    ['jpg', 'img'],
    ['gif', 'img'],
    ['png', 'img'],
    ['svg', 'img'],
    ['jpeg', 'img'],
    ['mp4', 'video'],
    ['3gp', 'video'],
    ['avi', 'video'],
    ['mp3', 'video'],
    ['mov', 'video'],
  ]);

  // const url = new URL(file);
  const url = file.split('.');
  const length = url.length;
  // const extension = url.pathname.split('.')[length - 1];
  const extension = url[length - 1].toLowerCase();

  return types.get(extension);
};

/**
 * @param size - size of file (Byte)
 *
 *
 * **/
export const formatSize = (size) => {
  let unit = 1;

  while (1) {
    if (Math.floor(size / (unit * 1000)) <= 0) {
      break;
    }
    unit *= 1000;
  }

  switch (unit) {
    case 1000000000000: {
      return Math.ceil(size / unit) + ' TB';
    }
    case 1000000000: {
      return Math.ceil(size / unit) + ' GB';
    }
    case 1000000: {
      return Math.ceil(size / unit) + ' MB';
    }
    case 1000: {
      return Math.ceil(size / unit) + ' KB';
    }
    default: {
      return Math.ceil(size) + ' B';
    }
  }
};

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}

export const bufferToFile = async (buffer, setter) => {
  window.URL = window.URL || window.webkitURL;

  if (buffer.name) {
    readFileAsync(buffer, setter).then((result) => {
      const blob = new Blob([result]);

      setter((current) => {
        if (!current) {
          return [URL.createObjectURL(blob)];
        }

        return [...current, URL.createObjectURL(blob)];
      });
    });
    // // const blob = new Blob([res]);
    // return URL.createObjectURL(blob);
  } else {
    console.log('b', buffer);
    const blob = new Blob([buffer]);
    console.log(blob);

    setter((current) => {
      if (!current) {
        return [URL.createObjectURL(blob)];
      }

      return [...current, URL.createObjectURL(blob)];
    });
    return URL.createObjectURL(blob);
  }
};
export const jwtDecoder = (jwt) => {
  var base64Url = jwt.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return jsonPayload;
};

const Utils = {
  AxiosClient,
  meridiemForm,
  formatSize,
  jwtDecoder,
  setInterSectionObserver,
  ComponentPreloader,
  generateState,
};
export default Utils;
