import ProfileKeys from '../translationKeys/ProfilePageKeys';

export const ProfileTranslations = {
  // Menu
  [ProfileKeys.Menu.edit]: 'Editar perfil',
  [ProfileKeys.Menu.save]: 'Guardar perfil',
  [ProfileKeys.Menu.message_me]: 'Enviar mensaje',

  // Menu Error
  [ProfileKeys.Menu.Error.creator_to_creator]:
    'Por favor, use una cuenta de no-creador.',
  [ProfileKeys.Menu.Error.something_went_wrong]: 'Algo salió mal.',
  [ProfileKeys.Menu.Error.self_chat]: 'No se puede enviar mensaje a este chat.',

  // Package
  [ProfileKeys.Package.edit]: 'Editar paquete',
  [ProfileKeys.Package.no_content]: 'Sin contenido',
  [ProfileKeys.Package.available]: 'Disponible',
  [ProfileKeys.Package.click_to_view]: 'Haga clic para ver',
  [ProfileKeys.Package.click_to_view_recent]:
    'Haz clic para ver {{content}} recientes',
  [ProfileKeys.Package.view_recent_videos]: 'Ver Videos Recientes',

  // Topbar
  [ProfileKeys.Package.TopBar.tooltip]:
    'Este plan no está disponible actualmente.',

  // YouTube
  [ProfileKeys.Package.YouTube.Contents.video_prepare]: 'Preparando paquete',
  [ProfileKeys.Package.YouTube.Contents.video_display]:
    'Su video se mostrará aquí.',

  // Description
  [ProfileKeys.Package.Description.default]: `
    El creador está preparando actualmente un paquete.<br>
    Por favor, vuelva pronto.`,

  // Details
  [ProfileKeys.Package.Details.Price.contact_me]: 'Contáctame',
  [ProfileKeys.Package.Details.Price.contact_creator]: 'Contactar al creador',
  [ProfileKeys.Package.Details.Price.service_fee]: 'Incluye tarifa de servicio',
  [ProfileKeys.Package.Details.Price.tooltip]: `
    Por favor, inténtalo de nuevo con <br> 
    el paquete de otro creador.`,

  [ProfileKeys.Package.Details.Toast.update_successful]:
    '¡Actualización exitosa!',
  [ProfileKeys.Package.Details.Toast.could_not_update]:
    '¡No se pudo actualizar!',

  // Creator Summary
  [ProfileKeys.Creator_Summary.title]: 'Resumen del Creador',
  [ProfileKeys.Creator_Summary.sub_title]:
    'Información básica del canal del creador',
  [ProfileKeys.Creator_Summary.package_for_other_platforms]:
    'Paquetes para otras plataformas',

  // Basic Info
  [ProfileKeys.Creator_Summary.BasicInfo.hollow]: 'Preparando red social',

  // Tooltip
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.subscribers]: 'Suscriptores',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.followers]: 'Seguidores',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_videos]:
    'Total de videos',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_tweets]:
    'Total de X publicaciones',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.total_posts]:
    'Publicaciones Totales',

  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.youtube]:
    'Fecha de ingreso a YouTube',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.twitter]:
    'Fecha de ingreso a X',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.instagram]:
    'Fecha de ingreso a Instagram',
  [ProfileKeys.Creator_Summary.BasicInfo.Tooltip.Join_Date.tiktok]:
    'Fecha de ingreso a TikTok',

  // Channel Contents
  [ProfileKeys.Channel_Contents.title]: 'Contenidos del Canal',
  [ProfileKeys.Channel_Contents.sub_title]:
    'Nuestras métricas inteligentes miden los niveles de participación en el contenido',
  [ProfileKeys.Channel_Contents.views_to_subs]: 'Vistas vs Suscriptores',
  [ProfileKeys.Channel_Contents.views_to_followers]: 'Vistas vs Seguidores',
  [ProfileKeys.Channel_Contents.reach_to_followers]: 'Alcance vs Seguidores',
  [ProfileKeys.Channel_Contents.est_engagement]: 'Participación Estimada',
  [ProfileKeys.Channel_Contents.channel_data]: 'Datos del Canal',
  [ProfileKeys.Channel_Contents.loading_post]: 'Cargando publicación',

  [ProfileKeys.Channel_Contents.Twitter.feed]: 'X Feed',
  [ProfileKeys.Channel_Contents.Twitter.loading]: 'Cargando Publicaciones de X',
  [ProfileKeys.Channel_Contents.Twitter.most_popular]:
    'Publicación de X Más Popular',
  [ProfileKeys.Channel_Contents.Tiktok.loading]: 'Cargando TikTok',

  [ProfileKeys.Channel_Contents.Filter._1_month]: '1 Mes',
  [ProfileKeys.Channel_Contents.Filter._3_months]: '3 Meses',
  [ProfileKeys.Channel_Contents.Filter._1_year]: '1 Año',
  [ProfileKeys.Channel_Contents.Filter.newest]: 'Más Recientes',
  [ProfileKeys.Channel_Contents.Filter.most_viewed]: 'Más Vistos',
  [ProfileKeys.Channel_Contents.Filter.most_liked]: 'Más Gustados',
  [ProfileKeys.Channel_Contents.Filter.most_commented]: 'Más Comentados',

  [ProfileKeys.Channel_Contents.Tooltip.youtube]: `
  Utilizamos estimaciones para algunos datos <br>
    datos de los últimos 1~6 meses. <br>
    Los videos seleccionados incluyen <br>
    contenido subido en el último año.`,
  [ProfileKeys.Channel_Contents.Tooltip.twitter]: `
    Utilizamos estimaciones para algunos datos <br>
    incluyendo los compromisos en X. Puede haber <br>
    una diferencia con los datos en tiempo real. <br>
    Las estadísticas aquí se basan en datos de<br>
    los últimos 1~3 meses.`,
  [ProfileKeys.Channel_Contents.Tooltip.instagram]: `
    Esta estadística se basa en  <br>
    datos de los últimos 1-3 meses.`,
  [ProfileKeys.Channel_Contents.Tooltip.tiktok]: `
    Esta estadística se basa en <br>
    datos de los últimos 1-3 meses.`,

  [ProfileKeys.Channel_Contents.Tooltip.likes]:
    'Calificación de Likes por vista',
  [ProfileKeys.Channel_Contents.Tooltip.comments]:
    'Calificación de Comentarios por vista',
  [ProfileKeys.Channel_Contents.Tooltip.x_reposts]:
    'Calificación de X Reposts por vista',
  [ProfileKeys.Channel_Contents.Tooltip.shares]:
    'Calificación de Compartidos por vista',

  [ProfileKeys.Channel_Contents.Tooltip.views_subs_rating]:
    'Calificación de Vistas por suscriptor',
  [ProfileKeys.Channel_Contents.Tooltip.views_follower_rating]:
    'Calificación de Vistas por seguidor',
  [ProfileKeys.Channel_Contents.Tooltip.engagements]:
    'Calificación general de participación',

  // Channel Analytics
  [ProfileKeys.Channel_Analytics.title]: 'Analítica del Canal',
  [ProfileKeys.Channel_Analytics.sub_title]:
    'Los indicadores del canal miden cuantitativamente el rendimiento general',
  [ProfileKeys.Channel_Analytics.updated_on]: 'Actualizado en {{date}}',
  [ProfileKeys.Channel_Analytics.avg_views]: 'Promedio de Vistas',
  [ProfileKeys.Channel_Analytics.avg_impressions]: 'Promedio de Impresiones',
  [ProfileKeys.Channel_Analytics.avg_reach]: 'Alcance promedio',
  [ProfileKeys.Channel_Analytics.avg_likes]: 'Promedio de Likes',
  [ProfileKeys.Channel_Analytics.avg_x_reposts]: 'Promedio de X Reposts',
  [ProfileKeys.Channel_Analytics.engagements]: 'Participación',

  // Views Analysis
  [ProfileKeys.Channel_Analytics.Views_Analysis.title]: 'Análisis de Vistas',
  [ProfileKeys.Channel_Analytics.Views_Analysis.title_instagram]:
    'Análisis de Alcance',
  [ProfileKeys.Channel_Analytics.Views_Analysis.periodic]: 'Periódico',
  [ProfileKeys.Channel_Analytics.Views_Analysis.total]: 'Total',

  // Subscribers Data
  [ProfileKeys.Channel_Analytics.Subscribers_Data.title]:
    'Datos de Suscriptores',

  // Followers Data
  [ProfileKeys.Channel_Analytics.Followers_Data.title]: 'Datos de Seguidores',

  // Audience Analytics
  [ProfileKeys.Audience_Analytics.title]: 'Análisis de la Audiencia',
  [ProfileKeys.Audience_Analytics.sub_title]:
    'Análisis de datos masivos para los espectadores objetivo del canal',
  [ProfileKeys.Audience_Analytics.preparing_data]: 'Preparando Datos',
  // Audience Interest
  [ProfileKeys.Audience_Analytics.Audience_Interest.title]:
    'Intereses de la Audiencia',
  [ProfileKeys.Audience_Analytics.Audience_Interest.popular_tags]:
    'Etiquetas Populares',
  [ProfileKeys.Audience_Analytics.Keyword_Cloud.title]:
    'Nube de Palabras Clave',
  [ProfileKeys.Audience_Analytics.Top_10.title]: 'Top 10 Palabras Clave',

  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.title]:
    'Verificar mi plan',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Upgrade_Plan.description]: `
    Por favor, verifique y actualice su plan <br>
    para acceder a más datos de la audiencia.`,

  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.title]: 'Login Requerido',
  [ProfileKeys.Audience_Analytics.LockedOverlay.Login.description]: `
    Por favor, inicie sesión primero para acceder <br>
    a los datos de la audiencia del usuario.`,

  // Review
  [ProfileKeys.Review.review_as_client]: 'Reseñas como Cliente',
  [ProfileKeys.Review.most_recent]: 'Más reciente',
  [ProfileKeys.Review.most_helpful]: 'Más recomendado',
  [ProfileKeys.Review.no_reviews]: 'No hay reseñas para mostrar',
  [ProfileKeys.Review.recent_clients]: 'CLIENTES RECIENTES',

  [ProfileKeys.Review.star]: 'Estrella',
  [ProfileKeys.Review.stars]: 'Estrellas',

  // Edit
  [ProfileKeys.Edit.Toast.being_saved]: 'Tu perfil se está guardando.',
  [ProfileKeys.Edit.Toast.saved]: '¡Tu perfil se ha guardado correctamente!',
  [ProfileKeys.Edit.Toast.failed]:
    'Error al guardar el perfil. Por favor, inténtalo de nuevo más tarde.',

  // Package
  [ProfileKeys.Edit.Package.complete_percentage]:
    'Este paquete está completo al {{number}}%',

  [ProfileKeys.Edit.Package.Topbar.tooltip]: `
    Los paquetes aparecerán en tu <br>
    perfil una vez que estén completos.`,

  [ProfileKeys.Edit.Package.Topbar.Representative.tooltip]: `
    El paquete predeterminado será <br>
    usado para destacar tu <br>
    servicio a los clientes.`,

  [ProfileKeys.Edit.Package.Topbar.Completion.tooltip]: `
    Por favor completa el <br>
    paquete para seleccionarlo <br>
    como predeterminado.`,

  // Banner
  [ProfileKeys.Edit.Package.Banner.heading]:
    '1920X318 Recomendado / Tamaño máximo 20 MB',
  [ProfileKeys.Edit.Package.Banner.mobile_heading]:
    'Tamaño máximo 20 MB / Banner 1920X318',
  [ProfileKeys.Edit.Package.Banner.tooltip]: `
    Agrega una capa de sombra <br>
    a tu imagen de banner.`,

  // Options
  [ProfileKeys.Edit.Package.Options.intro_and_closing]: 'Introducción y Cierre',
  [ProfileKeys.Edit.Package.Options.product_review]: 'Revisión del producto',
  [ProfileKeys.Edit.Package.Options.visit_review]: 'Visita Revisión',
  [ProfileKeys.Edit.Package.Options.digital_product_review]:
    'Revisión de Productos Digitales',
  [ProfileKeys.Edit.Package.Options.brand_ambassador]: 'Embajador de la Marca',
  [ProfileKeys.Edit.Package.Options.short_video]: 'Vídeo Corto',
  [ProfileKeys.Edit.Package.Options.product_placement]:
    'Colocación de Productos',
  [ProfileKeys.Edit.Package.Options.interview]: 'Interview',
  [ProfileKeys.Edit.Package.Options.commercial_break]: 'Pausa Comercial',
  [ProfileKeys.Edit.Package.Options.live_streaming]: 'Stream en Vivo',
  [ProfileKeys.Edit.Package.Options.giveaways]: 'Regalos',
  [ProfileKeys.Edit.Package.Options.meme_and_artwork]: 'Meme / Obra de Arte',
  [ProfileKeys.Edit.Package.Options.interview_and_ama]: 'Interview / AMA',

  // Title
  [ProfileKeys.Edit.Package.Title.use_a_template]: 'Usar una Plantilla',
  [ProfileKeys.Edit.Package.Title.placeholder]: 'Ingresa el título del paquete',
  [ProfileKeys.Edit.Package.Title.placeholder2]:
    'Escribe el título de tu paquete aquí.',

  // Featured Image
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.title]:
    'Miniatura destacada',
  [ProfileKeys.Edit.Package.Featured_Image.YouTube.tooltip]: `
    Recomendamos subir una miniatura de YouTube <br>
    que refleje el estilo de tu canal.`,

  [ProfileKeys.Edit.Package.Featured_Image.title]: 'Imagen Destacada',
  [ProfileKeys.Edit.Package.Featured_Image.placeholder]:
    'Se permiten archivos JPG, JPEG y PNG. Se recomienda 16:9. Máximo 200 MB.',
  [ProfileKeys.Edit.Package.Featured_Image.tooltip]: `
    Esta imagen se utilizará como <br>
    miniatura representativa del paquete.`,

  [ProfileKeys.Edit.Package.Featured_Image.Error.file_upload]: `
    Todavía se está cargando un archivo. <br>
    Por favor, inténtalo de nuevo luego.`,
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_large]:
    'El tamaño del archivo es demasiado grande. (Máximo ',
  [ProfileKeys.Edit.Package.Featured_Image.Error.file_invalid]:
    'Formato de archivo no válido. (Utiliza JPG, JPEG, PNG)',

  // Sample Contents
  [ProfileKeys.Edit.Package.Sample_Contents.title]: 'Contenidos de Muestra',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_youtube]:
    'Copia y pega un enlace de vídeo',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_twitter]:
    'Copia y pega un enlace de X',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_instagram]:
    'Copia y pega un enlace de publicación',
  [ProfileKeys.Edit.Package.Sample_Contents.placeholder_tiktok]:
    'Copia y pega un enlace de TikTok',

  [ProfileKeys.Edit.Package.Sample_Contents.add_more_contents]:
    'Agregar más contenidos',
  [ProfileKeys.Edit.Package.Sample_Contents.Error.invalid_link]:
    'Se detectó un enlace inválido',

  // Description
  [ProfileKeys.Edit.Package.Description.placeholder]: `
    Por favor, describe los detalles de tu servicio aquí. <br>
    Los clientes pueden buscar información sobre, <br><br>
    <ul>
      <li>Contenido principal o servicio</li>
      <li>Público objetivo</li>
      <li>Horarios</li>
    </ul>`,
  [ProfileKeys.Edit.Package.Description.placeholder_gpt]:
    'Empieza a escribir una oración y ChatGPT te sugerirá el resto.',

  [ProfileKeys.Edit.Package.Description.ChatGPT.title]:
    'Crea un paquete único con ChatGPT',
  [ProfileKeys.Edit.Package.Description.ChatGPT.tooltip]: `
    Elige el que mejor <br> 
    describe tu servicio.`,

  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.title]:
    'Información Adicional',
  [ProfileKeys.Edit.Package.Description.ChatGPT.Additional_Info.placeholder]:
    'Ingresa cualquier información que deba incluirse en la descripción.',

  // Price
  [ProfileKeys.Edit.Package.Price.placeholder]: 'Ingresa el precio',
  [ProfileKeys.Edit.Package.Price.tooltip]: `
    Establezca su precio de forma abierta para un acceso <br>
    fácil para los clientes. Otras opciones no tendrán un <br>
    precio establecido o solo estarán disponibles para <br>
    empresas y clientes verificados.`,

  [ProfileKeys.Edit.Package.Price.Type.open]:
    'Precio Abierto - Visible para todos los clientes',
  [ProfileKeys.Edit.Package.Price.Type.unlockable]:
    'Precio Desbloqueable - Solo clientes verificados',
  [ProfileKeys.Edit.Package.Price.Type.contact_me]: 'Contáctame - Sin precio',

  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.title]:
    'Por favor, verifíquese para enviar más mensajes',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Basic.description]: `
    Para enviar mensajes adicionales a otros creadores, debe completar un proceso de verificación rápido, que solo toma 1 a 2 minutos. ¡Inténtelo ahora! <br><br> 
    Su próxima fecha de renovación: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.title]:
    'Por favor, actualice su plan para enviar más mensajes',
  [ProfileKeys.Edit.Package.Price.Modal.MessageQuota.Verified.description]: `
    Necesita actualizar su plan para enviar mensajes a los creadores. <br> 
    Por favor, verifique su plan e intente nuevamente. <br><br> 
    Su próxima fecha de renovación: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.title]:
    'Por favor, verifíquese para desbloquear más precios',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Basic.description]: `
    Para desbloquear precios adicionales, debe completar un proceso de verificación rápido, que solo toma 1 a 2 minutos. ¡Inténtelo ahora! <br><br> 
    Su próxima fecha de renovación: {{date}}`,
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified.title]:
    'Por favor, actualice su plan para desbloquear más precios',
  [ProfileKeys.Edit.Package.Price.Modal.UnlockPriceQuota.Verified
    .description]: `
    Su próxima fecha de renovación: {{date}} <br> 
    Por favor, actualice su plan para desbloquear estos precios.`,
  [ProfileKeys.Edit.Package.Price.Modal.btn]: 'Actualizar ahora',

  [ProfileKeys.Edit.Package.Price.toast]: `
    ¡Desbloqueado con éxito! ({{number}} restantes)`,

  [ProfileKeys.Edit.Package.Price.Error.minimum]:
    'El precio mínimo es {{number}}',
  [ProfileKeys.Edit.Package.Price.Error.maximum]:
    'El precio máximo es {{number}}',

  // Contents
  [ProfileKeys.Edit.Package.Contents.YouTube.video]: 'Video de YouTube',
  [ProfileKeys.Edit.Package.Contents.YouTube.shorts]: 'YouTube Shorts',
  [ProfileKeys.Edit.Package.Contents.YouTube.live]: 'YouTube en Vivo',

  [ProfileKeys.Edit.Package.Contents.Twitter.post]: 'X Publicación',

  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_post]:
    'Publicación de Instagram',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_story]:
    'Historia de Instagram',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_reels]:
    'Carretes de Instagram',
  [ProfileKeys.Edit.Package.Contents.Instagram.instagram_live]:
    'Instagram en Vivo',

  [ProfileKeys.Edit.Package.Contents.Tiktok.video]: 'Video de TikTok',
  [ProfileKeys.Edit.Package.Contents.Tiktok.live]: 'TikTok en Vivo',

  // Delivery
  [ProfileKeys.Edit.Package.Delivery.placeholder]:
    'Ingrese la duración de la entrega (7~60 días)',
  [ProfileKeys.Edit.Package.Delivery.input]: ' Días de entrega',
  [ProfileKeys.Edit.Package.Delivery.tooltip]: `
    Seleccione el tiempo que necesita para <br> 
    preparar la entrega completa.`,

  // Revisions
  [ProfileKeys.Edit.Package.Revisions.no_revisions]: 'No revision',
  [ProfileKeys.Edit.Package.Revisions.tooltip]: `
    El número de revisiones que <br>
    ofrecerá en los pedidos.`,

  // Type/Length
  [ProfileKeys.Edit.Package.Type_Length.placeholder]:
    'ejemplo : Un vídeo de 5~10 minutos sobre el producto',

  // User Info
  // Username
  [ProfileKeys.Edit.User_Info.Username.placeholder]:
    'Ingrese el nombre de usuario',
  [ProfileKeys.Edit.User_Info.Username.Error.enter_name]:
    'Por favor, ingrese un nombre',
  [ProfileKeys.Edit.User_Info.Username.Error.max_50]: 'Máximo 50 caracteres',
  [ProfileKeys.Edit.User_Info.Username.Error.max_30]:
    'Ingrese entre 3 y 30 caracteres',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_symbol]:
    'Se detectó un símbolo o espacio inválido',
  [ProfileKeys.Edit.User_Info.Username.Error.max_2000]:
    'Máximo 2000 caracteres',
  [ProfileKeys.Edit.User_Info.Username.Error.max_100]: 'Máximo 100 caracteres',
  [ProfileKeys.Edit.User_Info.Username.Error.max_500]: 'Máximo 500 caracteres',
  [ProfileKeys.Edit.User_Info.Username.Error.max_200]: 'Máximo 200 caracteres',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_price]: 'Precio inválido',
  [ProfileKeys.Edit.User_Info.Username.Error.invalid_delivery_time]:
    'Tiempo de entrega inválido',
  [ProfileKeys.Edit.User_Info.Username.Error.delivery_time_1_60]:
    'El tiempo de entrega debe estar entre 1 y 60 días',

  // My URL
  [ProfileKeys.Edit.User_Info.My_URL.placeholder]: 'Ingrese una URL',

  [ProfileKeys.Edit.User_Info.My_URL.Error.char_limit]:
    'Ingrese entre 3 y 30 caracteres',
  [ProfileKeys.Edit.User_Info.My_URL.Error.invalid_symbol]:
    'Se detectó un símbolo o espacio inválido',
  [ProfileKeys.Edit.User_Info.My_URL.Error.required_handle]:
    'Por favor ingresa una URL',
  [ProfileKeys.Edit.User_Info.My_URL.Error.already_taken]:
    'Esta URL ya está en uso',

  // Social Network
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.priority]:
    'No se pudo cambiar la prioridad',
  [ProfileKeys.Edit.User_Info.Social_Network.Toast.unlink]:
    'No se pudo desvincular la plataforma',

  [ProfileKeys.Edit.User_Info.Social_Network.Popup.title]:
    'Por favor, complete primero su(s) pedido(s)',
  [ProfileKeys.Edit.User_Info.Social_Network.Popup.description]:
    'Puede desvincular su cuenta de red social verificada después de cancelar o completar su(s) pedido(s) en curso.',

  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.title]:
    '¿Está seguro de que desea desvincular esta cuenta?',
  [ProfileKeys.Edit.User_Info.Social_Network.Unlink.description]:
    'Una vez que desvincules tu cuenta de red social verificada, los datos relacionados con ella pueden perderse. Además, la información del paquete asociado ya no aparecerá en tu perfil ni en nuestras búsquedas.',

  // Bio
  [ProfileKeys.Edit.User_Info.Bio.placeholder_creator]:
    '¡Comparte tu historia con marcas y clientes de todo el mundo!',
  [ProfileKeys.Edit.User_Info.Bio.placeholder_client]:
    '¡Comparte tu historia con creadores globales!',

  // Categories
  [ProfileKeys.Edit.User_Info.Categories.add_a_category]:
    'Agregar una categoría',

  // Target Countries
  [ProfileKeys.Edit.User_Info.Target_Countries.title]: 'Países',
  [ProfileKeys.Edit.User_Info.Target_Countries.add_a_country]:
    'Agregar un país',
  [ProfileKeys.Edit.User_Info.Target_Countries.tooltip]: `
    Seleccione el país donde reside actualmente o que <br>
    mejor represente su identidad. (Máximo 2 países)`,

  // Languages
  [ProfileKeys.Edit.User_Info.Languages.add_a_language]: 'Agregar un idioma',

  // Company
  [ProfileKeys.Edit.User_Info.Company.placeholder]:
    'Pertenencia · Introduce el nombre de la empresa',
  [ProfileKeys.Edit.User_Info.Company.tooltip]: `
    Cargue un logotipo de la empresa <br> 
    en formato PNG, JPG, JPEG. <br> 
    Se recomienda 360x360. <br> 
    El tamaño máximo es de 50 MB.`,
  [ProfileKeys.Edit.User_Info.Company.Error.max_30]: 'Máximo 30 caracteres',

  // Modal
  [ProfileKeys.Modal.Request_Price.title]: 'Solicitar un precio',
  [ProfileKeys.Modal.Request_Price.btn]: 'Enviar una solicitud',
  [ProfileKeys.Modal.Request_Price.toast]:
    '¡Su solicitud se ha enviado correctamente!',
  [ProfileKeys.Modal.Request_Price.Message.title]: 'Enviar un mensaje',
  [ProfileKeys.Modal.Request_Price.Message.placeholder]:
    'Haga una pregunta o comparta los detalles de su proyecto (precio, resumen del producto, requisitos, etc.)',

  [ProfileKeys.Modal.Edit.Banner.heading]: 'Seleccionar un método',
  [ProfileKeys.Modal.Edit.Banner.body]: 'Cargar una imagen',
  [ProfileKeys.Modal.Edit.Banner.loading]: 'Cargando desde',
  [ProfileKeys.Modal.Edit.Banner.use_my]: 'Usar mi {{platform}} {{type}}',
  [ProfileKeys.Modal.Edit.Banner.not_found]:
    '{{platform}} {{type}} no encontrado',

  [ProfileKeys.Modal.Edit.Incomplete_Package.title]:
    'Paquete incompleto detectado',
  [ProfileKeys.Modal.Edit.Incomplete_Package.description]: `
    Los siguientes paquetes están incompletos: <br><br>
    <package></package> <br>
    Completa estos paquetes para que sean visibles en los resultados de búsqueda y en tu perfil. ¿Te gustaría completarlos ahora?`,

  [ProfileKeys.Modal.Edit.new_reward]: 'Desbloqueaste una nueva recompensa.',
  [ProfileKeys.Modal.Edit.skip_for_now]: 'Saltar por ahora',

  [ProfileKeys.Modal.Reconnection.title]: 'Reconexión requerida',
  [ProfileKeys.Modal.Reconnection.description]: `
    Debido a la inactividad prolongada o actualizaciones de políticas y funciones en cada plataforma de redes sociales, se requiere una reconexión para las plataformas enumeradas a continuación. Por favor, vuelva a conectar o desvincule la plataforma respectiva para continuar. <br><br>
    MegaEvolution admite conexiones seguras a través de API oficialmente verificadas y socios de Google, Meta (Instagram) y TikTok.`,
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.title]:
    'Puede conectar una nueva cuenta después de desvincular su cuenta actual',
  [ProfileKeys.Modal.Reconnection.Reconnect_Btn_Modal.description]:
    'Se ha detectado una cuenta diferente a la que está actualmente conectada. Para conectar una nueva cuenta, por favor desvincule la existente e inténtelo de nuevo.',

  // Guide
  [ProfileKeys.Guide.hi_ginseng_mega]: '¡Hola, soy MEGA!',
  [ProfileKeys.Guide.nice_to_meet_you]: '¡Encantado de conocerte!',
  [ProfileKeys.Guide.create_unique_url]: 'Crea una URL única',
  [ProfileKeys.Guide.we_help_you_receive]: 'Te ayudamos a recibir',
  [ProfileKeys.Guide.we_help_you_receive_mobile]:
    'Te ayudamos a recibir propuestas',
  [ProfileKeys.Guide.proposal_golbal_brands]: 'de marcas globales.',
  [ProfileKeys.Guide.proposal_golbal_brands_mobile]: 'de marcas globales.',
  [ProfileKeys.Guide.create_unique_portfolio]: 'Crea un portafolio único',
  [ProfileKeys.Guide.just_few_clicks]: 'en solo unos pocos clics.',
  [ProfileKeys.Guide.click_to_join]: 'Haz clic para unirte',
  [ProfileKeys.Guide.click_to_join_mobile]: 'Haz clic aquí',
  [ProfileKeys.Guide.anytime]: 'en cualquier momento.',
  [ProfileKeys.Guide.anytime_mobile]: 'para unirte en cualquier momento.',
  [ProfileKeys.Guide.what_a_pleasure]: '¡Qué placer conocerte hoy!',
  [ProfileKeys.Guide.hope_to_see]: '¡Espero ver tu página única pronto!',
  [ProfileKeys.create_unique_handle]: '¡Crea tu URL con un @handle único!',

  // Profile
  [ProfileKeys.Guide.Profile.welcome]: '¡Bienvenido, soy MEGA!',
  [ProfileKeys.Guide.Profile.portfolio_space]:
    'Este es un espacio de portafolio',
  [ProfileKeys.Guide.Profile.specially_created]:
    'creado especialmente para ti.',
  [ProfileKeys.Guide.Profile.click_here_to_start_editing]:
    'Haz clic aquí para comenzar a editar.',
  [ProfileKeys.Guide.Profile.package_will_show_up]:
    '¡Tu paquete aparecerá aquí!',
  [ProfileKeys.Guide.Profile.click_here]: 'Haz clic aquí',
  [ProfileKeys.Guide.Profile.to_start_editing]: 'para comenzar a editar',
  [ProfileKeys.Guide.Profile.package_will_be_displated]:
    '¡Tu paquete se mostrará aquí!',

  // Profile Edit
  [ProfileKeys.Guide.Profile_Edit.welcome]: '¡Bienvenido a tu perfil!',
  [ProfileKeys.Guide.Profile_Edit.try_me]: 'Prueba para configurar rápidamente',
  [ProfileKeys.Guide.Profile_Edit.compelling_package]: 'un paquete convincente',
  [ProfileKeys.Guide.Profile_Edit.upload_image]: 'Carga una imagen a',
  [ProfileKeys.Guide.Profile_Edit.showcase_service]: 'muestra tu servicio',
  [ProfileKeys.Guide.Profile_Edit.add_post_video]:
    '¡Atrae la atención de tus clientes',
  [ProfileKeys.Guide.Profile_Edit.for_your_clients]:
    'agregando enlaces de contenido!',
  [ProfileKeys.Guide.Profile_Edit.set_up_price]:
    'Establecer un precio en diferentes modos.',
  [ProfileKeys.Guide.Profile_Edit.choose_best_option]:
    '¡Elige la mejor opción para tu paquete!',
  [ProfileKeys.Guide.Profile_Edit.complete_package]: 'Completa tu paquete',
  [ProfileKeys.Guide.Profile_Edit.make_it_public]: 'para hacerlo público',
  [ProfileKeys.Guide.Profile_Edit.save_description1]:
    '¡Por favor, asegúrate de haber guardado',
  [ProfileKeys.Guide.Profile_Edit.save_description2]:
    'antes de irte! El perfil se traducirá',
  [ProfileKeys.Guide.Profile_Edit.save_description3]:
    'automáticamente a varios idiomas.',
  [ProfileKeys.Guide.Profile_Edit.create_unique_package]:
    '¡Crea un perfil único para clientes globales!',
  [ProfileKeys.Guide.Profile_Edit.quick_setup]: 'Configuración rápida',

  [ProfileKeys.Guide.Profile_Edit.Toast.register_success]:
    '¡Registro exitoso! Por favor, configure un perfil para recibir pedidos.',
};
