import styled from 'styled-components';
import { ClientVerificationManagerProvider } from '../ClientVerificationManager';
import InitialStep from './InitialStep';
import { useToggle } from 'react-use';
import FinalStep from './FinalStep';
import { AnimatePresence } from 'framer-motion';
import device from 'styles/device';

const ModalContent = ({ onClose, setIsFormSubmittedPopupVisible, plan }) => {
  const [finalStep, setFinalStep] = useToggle(false);

  return (
    <ClientVerificationManagerProvider>
      <AnimatePresence>
        <Layout>
          {finalStep ? (
            <FinalStep
              plan={plan}
              onClose={onClose}
              setIsFormSubmittedPopupVisible={setIsFormSubmittedPopupVisible}
            />
          ) : (
            <InitialStep setFinalStep={setFinalStep} />
          )}
        </Layout>
      </AnimatePresence>
    </ClientVerificationManagerProvider>
  );
};

export default ModalContent;

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 1.5rem;
  padding-top: 1.5rem;

  @media ${device.sm} {
    align-items: start;
    justify-content: start;
    padding: 1rem;
    flex: 1;
  }
`;
