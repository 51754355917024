import { ErrorBoundary as ErrorBoundaryComponent } from 'react-error-boundary';
import ErrorFallbackPage from 'components/pages/ErrorFallbackPage';
import { useLocation } from 'react-router-dom';
import AxiosClient from 'components/utilities/AxiosClient';

const reportError = async (name, message, componentStack, pathname) => {
  await AxiosClient.post('/frontendError', {
    name,
    message,
    componentStack,
    pathname,
  });
};

const ErrorBoundary = ({ children }) => {
  const { pathname } = useLocation();

  return (
    <ErrorBoundaryComponent
      fallback={<ErrorFallbackPage />}
      onError={(error, info) => {
        reportError(error.name, error.message, info.componentStack, pathname);
      }}
    >
      {children}
    </ErrorBoundaryComponent>
  );
};

export default ErrorBoundary;
