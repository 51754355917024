import { DecoratorNode } from 'lexical';
import * as React from 'react';

import { useSharedAutocompleteContext } from './SharedAutocompleteContext';
import { uuid as UUID } from '.';
import TypeWriter from 'components/Basics/TypeWriter';
import { useCallback } from 'react';
import { useEditorContext } from '../../EditorContext';

export class AutocompleteNode extends DecoratorNode {
  // TODO add comment
  __uuid;

  static clone(node) {
    return new AutocompleteNode(node.__uuid, node.__key);
  }

  static getType() {
    return 'autocomplete';
  }

  static importJSON(serializedNode) {
    const node = $createAutocompleteNode(serializedNode.uuid);
    return node;
  }

  exportJSON() {
    return {
      // ...super.exportJSON(),
      type: 'autocomplete',
      uuid: this.__uuid,
      version: 1,
    };
  }

  constructor(uuid, key) {
    super(key);
    this.__uuid = uuid;
  }

  updateDOM(prevNode, dom, config) {
    return false;
  }

  createDOM(config) {
    return document.createElement('span');
  }

  decorate() {
    if (this.__uuid !== UUID) {
      return null;
    }
    return <AutocompleteComponent />;
  }
}

export function $createAutocompleteNode(uuid) {
  return new AutocompleteNode(uuid);
}

function AutocompleteComponent() {
  const [suggestion] = useSharedAutocompleteContext();
  const { setGptTyping, gptTyping } = useEditorContext();
  const userAgentData = window.navigator.userAgentData;
  const isMobile =
    userAgentData !== undefined
      ? userAgentData.mobile
      : window.innerWidth <= 800 && window.innerHeight <= 600;
  // TODO Move to theme

  const onTypingStart = useCallback(() => {
    setGptTyping(true);
  }, [setGptTyping]);

  const onTypingEnd = useCallback(() => {
    setGptTyping(false);
  }, [setGptTyping]);

  if (!suggestion) return null;

  return (
    <span>
      <TypeWriter
        as="span"
        style={{ color: 'var(--color-indigo-100)' }}
        spellCheck="false"
        value={suggestion}
        cursorAfterTyping={false}
        delay={0}
        duration={5000}
        onTypingStart={onTypingStart}
        onTypingEnd={onTypingEnd}
        cursorType="block"
      />
      {!gptTyping && (
        <span style={{ color: 'var(--color-indigo-100)' }}>
          {isMobile ? ' (SWIPE \u2B95)' : ' (Tab to insert)'}
        </span>
      )}
    </span>
  );
}
