import getPackageCompletion from './getPackageCompletion';

const getIncompletePackages = (packages) => {
  const incompletePackages = Object.keys(packages).reduce((acc, platform) => {
    const platformPackages = packages[platform];

    const incompletePlatformPackages = Object.keys(platformPackages).reduce(
      (acc, packageType) => {
        const currentPackage = platformPackages[packageType];

        const packageCompletion = getPackageCompletion(
          currentPackage,
          platform
        );

        if (packageCompletion < 100 && packageCompletion > 0) {
          return [...acc, { platform, packageType }];
        }

        return acc;
      },
      []
    );

    return [...acc, ...incompletePlatformPackages];
  }, []);

  return incompletePackages;
};

export default getIncompletePackages;
