export const YoutubeCircleIcon = ({ size = 1 }) => (
  <svg
    width={24 * size}
    height={24 * size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12" cy="12" r="12" fill="#FF0000" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7815 7.42645C19.0209 7.66591 19.1935 7.96392 19.2819 8.29082C19.7913 10.341 19.6736 13.5791 19.2918 15.7084C19.2034 16.0353 19.0308 16.3333 18.7914 16.5728C18.5519 16.8122 18.2539 16.9848 17.927 17.0732C16.7303 17.3996 11.9138 17.3996 11.9138 17.3996C11.9138 17.3996 7.09732 17.3996 5.90061 17.0732C5.57372 16.9848 5.27571 16.8122 5.03625 16.5728C4.79679 16.3333 4.62424 16.0353 4.53578 15.7084C4.02347 13.6671 4.16391 10.4271 4.52589 8.30071C4.61435 7.97382 4.7869 7.6758 5.02636 7.43634C5.26582 7.19688 5.56383 7.02433 5.89072 6.93588C7.08743 6.6095 11.9039 6.59961 11.9039 6.59961C11.9039 6.59961 16.7204 6.59961 17.9171 6.92598C18.244 7.01444 18.542 7.18699 18.7815 7.42645ZM14.3665 11.9996L10.3709 14.3139V9.68532L14.3665 11.9996Z"
      fill="white"
    />
  </svg>
);
