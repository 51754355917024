import useScript from 'hooks/useScript';
import { useState } from 'react';
import { generateAuthProtection } from '..';
import { useEffect } from 'react';
import AxiosClient from '../AxiosClient';
import { useCallback } from 'react';
import { countrySubdomains } from 'constants/languages';

const URL = 'https://accounts.google.com/gsi/client';
const client_id = process.env.REACT_APP_GOOGLE_API_CLIENT_KEY;

let myScope = null;
const useYoutubeVerifier = ({ onError, onPopupFail }) => {
  const GApiReadyStatus = useScript(URL, true, true, true);
  const [gApi, setGApi] = useState(null);
  let subDomain = window.location.hostname.split('.')[0];
  // check if the subdomain is a key in the countrySubdomains object
  if (!countrySubdomains.hasOwnProperty(subDomain)) {
    subDomain = null;
  }
  useEffect(() => {
    if (GApiReadyStatus === 'ready' && window.google && !gApi) {
      setGApi(window.google);
    }
  }, [GApiReadyStatus, gApi]);

  const handleCallback = useCallback(
    (reqType) => (TokenResponse) => {
      if (
        !gApi.accounts.oauth2.hasGrantedAnyScope(
          TokenResponse,
          myScope[0],
          ...myScope.slice(1)
        )
      ) {
        onError({
          code: 418,
          message: 'scope is not valid',
        });
        return;
      }

      if (TokenResponse.error) {
        onError({
          code: TokenResponse.error,
          message: TokenResponse.error_description,
        });
        return;
      }

      AxiosClient.post('/addLink', {
        auth_code: TokenResponse.code,
        platform: 'youtube',
        reqType,
        state: TokenResponse.state,
        subDomain,
      });
    },
    [gApi, subDomain, onError]
  );

  const verifier = useCallback(
    async (reqType) => {
      if (gApi) {
        const { state, scope } = await generateAuthProtection('youtube');
        myScope = scope;
        const client = gApi.accounts.oauth2.initCodeClient({
          client_id,
          ux_mode: 'popup',
          scope: scope.join(' '),
          state: state,
          select_account: true,
          callback: handleCallback(reqType),
        });
        client.requestCode();
        if (client.h === null) {
          onPopupFail();
        }
      }
    },
    [gApi, handleCallback, onPopupFail]
  );

  return verifier;
};

export default useYoutubeVerifier;
