import Popup from 'components/Basics/Popup';
import WalletOption from './common/WalletOption';
import { authPlatform, mainFlow } from 'constants/authFlow';
import { useState } from 'react';
import IndigoButton from 'components/Basics/Buttons/IndigoButton';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import withAuthHOC from 'components/components/auth/withAuthHOC';
import { useAuth } from 'hooks/useAauth';
import { platformToType } from 'constants/authType';
import useUser from 'hooks/useUser';
import { useTranslation } from 'react-i18next';
import GeneralKeys from 'translations/translationKeys/GeneralKeys';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';

const { METAMASK, COINBASE } = authPlatform;
const { SIGN_IN, SIGN_UP, REACTIVATE } = mainFlow;

const ConnectWalletModal = ({ flowManager }) => {
  const { t } = useTranslation();

  const [show, setShow] = useState(true);
  const {
    backwardStep,
    closeModal,
    navigateFlow,
    mainFlow,
    authMethod,
    setSharedValue,
  } = flowManager;
  const { isMobile } = useResponsiveScreen();
  const {
    manager: web3AuthManager,
    web3Utils,
    utils,
    errorHandlers,
  } = useAuth(platformToType(authMethod));
  const { refetch } = useUser();

  async function loginProcess(method) {
    let walletAddress = null;
    let provider = null;
    let message = null;
    let signedMessage = null;
    try {
      const [_walletAddress, _provider] = await web3Utils._getWallet(method);
      walletAddress = _walletAddress;
      provider = _provider;

      const nonce = await web3Utils._getNonce(walletAddress);
      if (nonce?.hasOwnProperty('walletAddress')) {
        await web3AuthManager.login(
          {
            loginType: platformToType(method),
            walletType: method,
            address: nonce.walletAddress,
          },
          {
            onSuccess: () => {
              refetch().then(() => {
                closeModal();
              });
            },
          }
        );
      }
      const [_message, _signedMessage] = await web3Utils._signMessage(
        walletAddress,
        nonce,
        provider
      );

      message = _message;
      signedMessage = _signedMessage;

      await web3AuthManager.login(
        {
          loginType: platformToType(method),
          walletType: method,
          message,
          signedMessage,
          address: walletAddress,
        },
        {
          onSuccess: () => {
            refetch().then(() => {
              closeModal();
            });
          },
        }
      );
    } catch (error) {
      //when user isn't verificated, pass user to sign-up flow with required data
      if (errorHandlers.needsVerification(error)) {
        setSharedValue({
          userData: {
            ...error.response.data.user,
            message,
            signedMessage,
            walletAddress,
          },
        });
        navigateFlow({
          flow: SIGN_UP,
          method,
          step: 4,
        });
      }
      if (errorHandlers.deactivated(error)) {
        navigateFlow({
          flow: REACTIVATE,
          step: 0,
        });
      }
      //when user isn't found, pass user to sign-up flow with required data
      if (errorHandlers.userNotFound(error)) {
        const nonce = error.response.data.nonce;
        const [message, signedMessage] = await web3Utils._signMessage(
          walletAddress,
          nonce,
          provider
        );
        setSharedValue({
          userData: {
            walletAddress,
            message,
            signedMessage,
          },
        });
        navigateFlow({
          flow: SIGN_UP,
          method,
          step: 2,
        });
      }
    }
  }

  async function signUpProcess(method) {
    try {
      const walletAndSignature = await web3Utils.createWalletDataAndSignature(
        method
      );
      await utils.checkWalletRegistable(
        walletAndSignature.walletAddress,
        method
      );
      setSharedValue({
        userData: walletAndSignature,
      });
      navigateFlow({
        method,
        step: 2,
      });
    } catch (error) {
      if (error.response?.status === 500) {
        setSharedValue({
          error: LoginKeys.Error.network,
        });
        navigateFlow({
          step: 0,
        });
      }
      if (error.message === 'This wallet is already registered.') {
        setSharedValue({
          error: LoginKeys.Error.wallet_already_registered,
        });
        navigateFlow({
          step: 0,
        });
      }
    }
  }

  async function handleClick(method) {
    switch (mainFlow) {
      default:
      case SIGN_IN: {
        loginProcess(method);
        break;
      }
      case SIGN_UP: {
        signUpProcess(method);
        break;
      }
    }
  }

  return (
    <Popup
      onClose={backwardStep}
      show={show}
      title={t(LoginKeys.Modal.Connect_Wallet.title)}
      disableButton={true}
      description={t(LoginKeys.Modal.Connect_Wallet.description)}
      noButton={true}
      onOuterBounderyClick={backwardStep}
      // padding="4.375rem 60px 3.125rem"
      asPage={isMobile}
    >
      <div>
        <WalletOption
          onClick={() => {
            handleClick(METAMASK);
          }}
          type={METAMASK}
          margin="0 0 8px 0"
        />
        <WalletOption
          onClick={() => handleClick(COINBASE)}
          type={COINBASE}
          margin="0 0 8px 0"
        />
      </div>

      {/* Hidden on destop version site */}
      <IndigoButton
        className="bh5 md-hidden shadow-none"
        radius="999px"
        minWidth="136px"
        padding="9.2px 16px"
        style={{ alignSelf: 'center', marginTop: '10px' }}
        onClick={() => {
          setShow(false);
        }}
      >
        {t(GeneralKeys.close)}
      </IndigoButton>
    </Popup>
  );
};

export default withAuthHOC(ConnectWalletModal);
