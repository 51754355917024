export const InstagramLiveIcon = ({ size = 1 }) => (
  <svg
    width={size * 30}
    height={size * 20}
    viewBox="0 0 30 20"
    fill="currentColor"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.59006 11.656H10.3021V13H6.81406V7.336H8.59006V11.656ZM12.6369 7.336V13H10.8609V7.336H12.6369ZM19.1431 7.336L17.2391 13H14.9431L13.0391 7.336H14.9271L16.0951 11.232L17.2551 7.336H19.1431ZM21.3166 8.752V9.448H23.0766V10.784H21.3166V11.584H23.3166V13H19.5406V7.336H23.3166V8.752H21.3166Z"
      stroke="none"
    />
    <rect
      x="0.8"
      y="0.8"
      width="28.4"
      height="18.4"
      rx="4.2"
      fill="none"
      stroke-width="1.6"
    />
  </svg>
);
