import BaseImage from './Image';
import { forwardRef } from 'react';
import useLazyImage from 'hooks/useLazyImage';
import Stack from './Layout/Stack';
import { LoadingIcon } from 'assets/icons';

const loadingSrc = '/assets/gif/MegaLoading.gif';

/**
 * A simple component that displays an image without distortion.
 * @param {string} src - The source of the image.
 * @param {string} alt - The alt text of the image.
 * @param {string} lazySrc - The source of the image when it is lazy loaded.
 * @param {string} width - The width of the image.
 * @param {string} height - The height of the image. If not provided, the image will be square.
 * @param {boolean} circle - Whether the image should be circular. (height will be ignored if this is true)
 * @param {boolean} square - Whether the image should be square. (height will be ignored if this is true)
 * @param {string} radius - The border radius of the image.
 * @param {boolean} gray - Whether the image should be gray.
 * @param {boolean} contain - Whether the image should be contained.
 * @param {string} position - The position of the image.
 * @param {boolean} rotate
 * @param {string} [margin]
 * @additionalProps - Any additional props to be passed to the image.
 */
const LazyImage = forwardRef(
  (
    {
      src,
      alt,
      lazySrc,
      fallback,
      width = 'auto',
      height,
      aspect,
      circle,
      square,
      radius,
      gray,
      contain,
      position,
      rotate,
      className,
      maxWidth,
      maxHeight,
      margin,
      background,
      circularLoader,
      ...props
    },
    ref
  ) => {
    const [imageSrc, isLoading, errorLoading] = useLazyImage(
      src,
      lazySrc,
      fallback
    );

    if (circularLoader && isLoading) {
      return (
        <Stack width={width} height={height} align="center" justify="center">
          <LoadingIcon />
        </Stack>
      );
    }
    return (
      <BaseImage
        ref={ref}
        src={imageSrc}
        alt={alt}
        width={width}
        height={height}
        aspect={aspect}
        circle={circle}
        square={square}
        radius={radius}
        gray={gray}
        contain={imageSrc === loadingSrc ? true : contain}
        background={
          background
            ? background
            : errorLoading
            ? 'var(--color-pink-75)'
            : 'transparent'
        }
        position={position}
        className={className}
        rotate={rotate}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        scaleDown={imageSrc === loadingSrc ? 0.8 : 1}
        margin={margin}
        {...props}
      />
    );
  }
);

export default LazyImage;
