import Platform from 'constants/platform';
import PackagesType from 'constants/packageType';
import { Package } from './dataTypes';
import extractId from './extractId';
import { cloneDeep } from 'lodash';

// 1. Remove empty and invalid urls from sample contents and extract id from url also resolve short urls for tiktok
// 2. Parse content values to int if are string and then stringify contents object
// 3. Parse int price
// 4. Parse int delivery time

const processPackages = (packages) => {
  try {
    const newPackages = cloneDeep(packages);

    // do the above using for loop for better performance
    const platforms = Object.values(Platform);
    for (const platform of platforms) {
      const types = Object.values(PackagesType);
      for (const type of types) {
        // extract id from url for sample contents and remove invalid urls
        const sampleContents =
          newPackages[platform][type][Package.SampleContents];
        const parsedSampleContents = [];
        for (let url of sampleContents) {
          if (platform === Platform.TikTok) {
            const shortUrlRegex = /https:\/\/(?:vt|vm)\.tiktok\.com\/\w+/;

            if (shortUrlRegex.test(url)) {
              parsedSampleContents.push(url);
              continue;
            }
          }

          const id = extractId(url, platform);
          if (id) {
            parsedSampleContents.push(id);
          }
        }

        newPackages[platform][type][Package.SampleContents] =
          parsedSampleContents;

        // parse content values to int if are string and then stringify contents object
        const contents = newPackages[platform][type][Package.Contents];
        const parsedContents = {};
        for (const [key, value] of Object.entries(contents)) {
          parsedContents[key] =
            typeof value === 'string' ? parseInt(value, 10) : value;
        }

        newPackages[platform][type][Package.Contents] =
          JSON.stringify(parsedContents);

        // parse int price and remove empty price
        const price = newPackages[platform][type][Package.Price];
        newPackages[platform][type][Package.Price] =
          price && typeof price === 'string'
            ? parseInt(newPackages[platform][type][Package.Price], 10)
            : price || 0;

        // parse int delivery time and remove empty delivery time
        const delivery = newPackages[platform][type][Package.Delivery];
        newPackages[platform][type][Package.Delivery] =
          delivery && typeof delivery === 'string'
            ? parseInt(newPackages[platform][type][Package.Delivery], 10)
            : delivery || 0;
      }
    }

    return newPackages;
  } catch (error) {
    console.error(error);
  }
};

export default processPackages;
