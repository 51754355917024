export const BUSDIcon = ({ size = 1 }) => (
  <svg
    width={20 * size}
    height={20 * size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.94286 19.8857C15.4341 19.8857 19.8857 15.4341 19.8857 9.94286C19.8857 4.45157 15.4341 0 9.94286 0C4.45157 0 0 4.45157 0 9.94286C0 15.4341 4.45157 19.8857 9.94286 19.8857Z"
      fill="#EDD6FF"
    />
    <path
      d="M10.0016 4L11.4884 5.51805L7.74459 9.25067L6.25781 7.76833L10.0016 4Z"
      fill="#555275"
      stroke="#555275"
      strokeWidth="0.5"
    />
    <path
      d="M12.2587 6.25L13.7454 7.76805L7.74459 13.7509L6.25781 12.2686L12.2587 6.25Z"
      fill="#555275"
      stroke="#555275"
      strokeWidth="0.5"
    />
    <path
      d="M5.48678 8.50098L6.97356 10.019L5.48678 11.5014L4 10.019L5.48678 8.50098Z"
      fill="#555275"
      stroke="#555275"
      strokeWidth="0.5"
    />
    <path
      d="M14.5165 8.50098L16.0033 10.019L10.0024 16.0019L8.51562 14.5196L14.5165 8.50098Z"
      fill="#555275"
      stroke="#555275"
      strokeWidth="0.5"
    />
  </svg>
);
