export const AnalyticsIcon = ({ size = 1 }) => {
  return (
    <svg
      width={size * 19}
      height={size * 18}
      viewBox="0 0 19 18"
      fill="currentColor"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7895 4.716C17.5015 4.428 17.0695 4.428 16.7815 4.716L12.2455 9.252L9.14947 6.156C9.00547 6.012 8.86147 5.94 8.64547 5.94C8.42947 5.94 8.28547 6.012 8.14147 6.156L3.82147 10.476C3.67747 10.62 3.60547 10.764 3.60547 10.98C3.60547 11.412 3.89347 11.7 4.32547 11.7C4.54147 11.7 4.68547 11.628 4.82947 11.484L8.64547 7.668L11.7415 10.764C11.8135 10.836 11.8855 10.908 11.9575 10.908C12.0295 10.98 12.1735 10.98 12.2455 10.98C12.3895 10.98 12.6055 10.908 12.6775 10.764C12.6775 10.764 12.6775 10.764 12.7495 10.764L17.7895 5.724C18.0775 5.436 18.0775 5.004 17.7895 4.716Z"
        strokeWidth="0.6"
      />
      <path
        d="M17.1 16.2H16.2C16.2 16.2 16.2 16.2 15.75 16.2C15.75 16.2 15.5387 16.2 15.3 16.2C15.0613 16.2 14.85 16.2 14.85 16.2C14.85 16.2 13.95 16.2 14.4 16.2H12.6C12.6 16.2 12.6 16.2 12.15 16.2C11.7 16.2 11.9387 16.2 11.7 16.2C11.4613 16.2 11.25 16.2 11.25 16.2C10.8 16.2 11.25 16.2 10.8 16.2H9H8.55C8.55 16.2 8.33869 16.2 8.1 16.2C7.8613 16.2 7.65 16.2 7.65 16.2C7.65 16.2 6.75 16.2 7.2 16.2H5.4C5.4 16.2 5.4 16.2 4.95 16.2C4.95 16.2 4.73869 16.2 4.5 16.2C4.26131 16.2 4.05 16.2 4.05 16.2H3.6H1.8V0.9C1.8 0.661305 1.70518 0.432387 1.5364 0.263604C1.36761 0.0948211 1.13869 0 0.9 0C0.661305 0 0.432387 0.0948211 0.263604 0.263604C0.0948211 0.432387 0 0.661305 0 0.9V17.1C0 17.3387 0.0948211 17.5676 0.263604 17.7364C0.432387 17.9052 0.661305 18 0.9 18H17.1C17.3387 18 17.5676 17.9052 17.7364 17.7364C17.9052 17.5676 18 17.3387 18 17.1C18 16.8613 17.9052 16.6324 17.7364 16.4636C17.5676 16.2948 17.3387 16.2 17.1 16.2Z"
        stroke="none"
      />
    </svg>
  );
};
