import Stack from 'components/Basics/Layout/Stack';
import { motion } from 'framer-motion';
import { random } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const MessageSkeleton = () => {
  return (
    <Wrapper>
      <Skeleton width={40} height={40} circle />

      <Stack gap="0.25rem" className="flex-1">
        <Skeleton width={random(70, 120)} height={12} />

        <Skeleton
          width={random(50, 100) + '%'}
          height={10}
          count={random(1, 3)}
        />

        <Skeleton width={20} height={8} />
      </Stack>
    </Wrapper>
  );
};

export default MessageSkeleton;

const Wrapper = styled(motion.div).attrs({
  initial: { opacity: 0, scale: 0.95 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0.95 },
})`
  width: 100%;
  padding: 1rem;
  padding-bottom: calc(1rem - 1px);
  display: flex;
  align-items: flex-start;
  gap: 0.625rem;
  cursor: pointer;
  border-bottom: 1px solid var(--color-indigo-25);
  background: #fff;
`;
