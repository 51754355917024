import { useState, useRef } from 'react';
import styled from 'styled-components';
import Viewer from './Viewer';
import { useCallback } from 'react';
import { useEffect } from 'react';
import PlayerControls from './PlayerControls';
import { LoadingIcon } from 'assets/icons';
import { AnimatePresence, motion } from 'framer-motion';

const VideoPlayer = ({ name, type, size, url, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const playerRef = useRef(null);

  const togglePlay = useCallback(() => {
    if (playerRef.current)
      if (!playerRef.current?.paused) {
        playerRef.current.pause();
        setIsPlaying(false);
      } else {
        playerRef.current.play();
        setIsPlaying(true);
      }
  }, []);

  const handleSeek = useCallback((e) => {
    if (!playerRef.current) return;

    const audio = playerRef.current;
    const seekTo = e.target.value / 100;

    audio.currentTime = seekTo * audio.duration;
    setCurrentTime(seekTo * 100);
  }, []);

  useEffect(() => {
    const player = playerRef.current;

    if (!player) return;

    const handleTimeUpdate = () => {
      setCurrentTime((player.currentTime / player.duration) * 100);
    };

    const handleDurationChange = () => {
      setDuration(player.duration);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    player.addEventListener('timeupdate', handleTimeUpdate);
    player.addEventListener('durationchange', handleDurationChange);
    player.addEventListener('ended', handleEnded);

    return () => {
      player.removeEventListener('timeupdate', handleTimeUpdate);
      player.removeEventListener('durationchange', handleDurationChange);
      player.removeEventListener('ended', handleEnded);
    };
  }, []);

  return (
    <StyledViewer
      name={name}
      size={size}
      url={url}
      onClose={onClose}
      enableFullscreen
      hideableControls
      controls={
        <PlayerControls
          togglePlay={togglePlay}
          isPlaying={isPlaying}
          handleSeek={handleSeek}
          currentTime={currentTime}
          duration={duration}
        />
      }
    >
      <Player
        ref={playerRef}
        autoPlay
        controls={false}
        onCanPlay={() => setLoading(false)}
      >
        <source src={url} type={type} />
      </Player>

      <AnimatePresence>{loading && <LoadingOverlay />}</AnimatePresence>
    </StyledViewer>
  );
};

export default VideoPlayer;

const StyledViewer = styled(Viewer)`
  display: flex;
  justify-content: center;
  justify-items: center;
`;

const LoadingOverlay = styled(motion.div).attrs({
  children: <LoadingIcon />,
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
})`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
`;

const Player = styled.video`
  width: 100%;
  height: auto;
  max-height: 100vh;
  object-fit: contain;
`;
