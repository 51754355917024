import PricingKeys from '../translationKeys/PricingPageKeys';

export const PricingTranslations = {
  // Preparing
  [PricingKeys.preparing]: '(Đang chuẩn bị)',
  [PricingKeys.order_now]: 'Đặt ngay',
  [PricingKeys.get_free]: 'Nhận Miễn Phí Premium Pass',
  [PricingKeys.get_for_free]: 'Nhận miễn phí',
  [PricingKeys.current_plan]: 'Gói hiện tại',

  // Banner
  [PricingKeys.Banner.title]:
    'Làm cho chiến lược marketing của bạn đơn giản & thông minh!',
  [PricingKeys.Banner.description]:
    'Các gói dịch vụ của chúng tôi giúp nâng cao hiệu suất marketing của bạn lên tầm cao mới!',

  [PricingKeys.Verified.Error.already_verified]: `
    Tài khoản đã được xác minh là thương hiệu hoặc công ty.`,
  [PricingKeys.Verified.Error.already_using]: `
    Tài khoản của bạn đã sử dụng gói này. <br>
    <span>Nhấn vào đây</span> để xem chi tiết đăng ký của bạn.`,
  [PricingKeys.Verified.Error.under_review]: `
    Tài khoản của bạn đang được xem xét. <br>
    Vui lòng thử lại sau.`,
  [PricingKeys.Verified.Error
    .recently_rejected]: `Yêu cầu của bạn đã được xem xét gần đây.<br>Vui lòng thử lại sau.`,

  // Left Col
  // General
  [PricingKeys.Left_Col.General.verification_req]: 'Yêu cầu xác minh',
  [PricingKeys.Left_Col.General.smart_search]: 'Tìm kiếm thông minh',

  // Analytics & Data
  [PricingKeys.Left_Col.Analytics_Data.title]: 'Phân Tích & Dữ Liệu',

  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.title]: `
    Phân Tích Cơ Bản <br>
    cho Người Tạo`,
  [PricingKeys.Left_Col.Analytics_Data.Creator_Basic_Analytics.tooltip]: `
    Điều này gồm phân tích kênh và nội dung của<br>
    các tài khoản mạng xã hội của người sáng tạo.`,

  [PricingKeys.Left_Col.Analytics_Data.audience_analytics]:
    'Phân Tích Khán Giả',

  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.title]: 'Mở khóa giá',
  [PricingKeys.Left_Col.Analytics_Data.Price_Unlock.tooltip]: `
    Mỗi tháng, bạn có thể mở khóa giá gói cho <br>
    một số lượng người sáng tạo nhất định.<br>
    Giá sẽ bị khóa lại sau 72 giờ.`,

  [PricingKeys.Left_Col.Analytics_Data.order_analytics]:
    'Phân Tích Đơn Đặt Hàng',

  [PricingKeys.Left_Col.Analytics_Data.creator_reviews]:
    'Đánh Giá Người Tạo Nội Dung',

  // Orders
  [PricingKeys.Left_Col.Orders.title]: `Đơn hàng và Người sáng tạo`,
  [PricingKeys.Left_Col.Orders.order_packages]: 'Đặt Gói Người Tạo Nội Dung',

  [PricingKeys.Left_Col.Orders.message_creators]:
    'Gửi tin nhắn cho các người sáng tạo mới trước khi bắt đầu đặt hàng',

  [PricingKeys.Left_Col.Orders.Order_Discount.title]: 'Giảm giá khi đặt hàng',
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_1]: `
    Chiết khấu được áp dụng vào tổng giá <br>
    của mỗi đơn hàng với các tạo sáng. <br>
    * Không áp dụng cho các mặt hàng bổ sung, phí giao hàng <br>
    và đăng ký hàng tháng.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.tooltip_2]: `
    Mức giảm giá áp dụng cho tổng giá của mỗi đơn hàng với người sáng tạo.<br>
    Không áp dụng cho một số mặt hàng bổ sung hoặc đăng ký sử dụng nền tảng.`,
  [PricingKeys.Left_Col.Orders.Order_Discount.content]: `
    <Tooltip><span>{{number}}</span></Tooltip>  của giá đặt hàng`,

  [PricingKeys.Left_Col.Orders.Track_Delivery.title]: `
    Theo dõi <br>
    giao hàng Quốc Tế`,
  [PricingKeys.Left_Col.Orders.Track_Delivery.tooltip]: `
    Chúng tôi hỗ trợ theo dõi giao hàng quốc tế cho các đơn đặt hàng. <br>
    Tính năng theo dõi lô hàng nội địa có sẵn ở một số quốc gia. <br><br>
    *Lưu ý: Chúng tôi không xử lý hoặc đảm bảo về quá trình giao hàng thực tế.`,

  [PricingKeys.Left_Col.Orders.auto_translation]:
    'Hệ Thống Tự Động Dịch Toàn Bộ',

  [PricingKeys.Summary.basic]: `
    <li>Miễn phí nhưng mạnh mẽ</li>
    <li>Bao gồm tất cả các tính năng cơ bản để cải thiện hiệu suất tiếp thị của bạn</li>`,
  [PricingKeys.Summary.verified]: `
    <li>Xác minh chỉ trong 1~3 phút</li>
    <li>Tốt cho các thương hiệu và doanh nghiệp toàn cầu</li>`,
  [PricingKeys.Summary.premium]: `
    <li>Dung lượng lớn</li>
    <li>Yêu cầu xác minh</li>
    <li>Lý tưởng cho các công ty toàn cầu tập trung vào quy mô hóa</li>`,

  // Creator
  [PricingKeys.Creator.title]: 'Pass Premium Cho Người Tạo Nội Dung Sớm',

  [PricingKeys.Creator.h1]:
    'Với Pass premium, các nhà sáng tạo sẽ được miễn phí dịch vụ của MegaEvolution.',
  [PricingKeys.Creator.h2]:
    'Vui lòng sử dụng nền tảng của chúng tôi để chia sẻ tài năng của bạn với thế giới!',
  [PricingKeys.Creator.h3]: `
    * Chúng tôi không bao gồm phí giao dịch bên thứ ba như <br>
    (ví dụ: Paypal) cần thiết để rút tiền của bạn.`,

  // Modal
  [PricingKeys.Modal.title]: 'Xác minh Thương hiệu · Công ty',
  [PricingKeys.Modal.estimate]: 'Ước tính 1~2 phút',
  [PricingKeys.Modal.caution]:
    '* Thông tin sai lệch hoặc không chính xác có thể dẫn đến mất xác minh hoặc bị cấm tài khoản vĩnh viễn.',
  [PricingKeys.Modal.toast]: 'Tài khoản của bạn đã được xác minh thành công!',

  [PricingKeys.Modal.Btn.proceed_final]: 'Tiến hành đến bước cuối cùng',
  [PricingKeys.Modal.Btn.verify_email]: 'Xác minh Email',
  [PricingKeys.Modal.Btn.no_company_email]: 'Tôi không có Email của công ty',
  [PricingKeys.Modal.Btn.verify_with_email]: 'Xác minh qua Email',
  [PricingKeys.Modal.Btn.complete_verification]: 'Hoàn tất xác minh',
  [PricingKeys.Modal.Btn.another_email]: 'Sử dụng địa chỉ email khác',
  [PricingKeys.Modal.Btn.resend_code]: 'Gửi lại mã',

  [PricingKeys.Modal.Placeholder.firstname]: 'Vui lòng nhập tên của bạn',
  [PricingKeys.Modal.Placeholder.lastname]: 'Vui lòng nhập họ của bạn',
  [PricingKeys.Modal.Placeholder.companyname]: 'Nhập tên công ty',
  [PricingKeys.Modal.Placeholder.companyUrl]:
    'Nhập URL của trang web công ty (ví dụ: www.megaevolution.io)',
  [PricingKeys.Modal.Placeholder.companyUrl_sm]:
    'Nhập URL của trang web công ty',
  [PricingKeys.Modal.Placeholder.companyEmail]:
    'Vui lòng nhập Email của công ty của bạn',
  [PricingKeys.Modal.Placeholder.enter_code]: 'Vui lòng nhập mã bạn đã nhận',

  [PricingKeys.Modal.CompanyType.title]: 'Chọn loại công ty',
  [PricingKeys.Modal.CompanyType.option_1]: 'Thương hiệu · Doanh nghiệp',
  [PricingKeys.Modal.CompanyType.option_2]: 'Cửa hàng thương mại điện tử',
  [PricingKeys.Modal.CompanyType.option_3]: 'Công ty tiếp thị',
  [PricingKeys.Modal.CompanyType.option_4]: 'Khác',

  [PricingKeys.Modal.JobPosition.title]: 'Chọn vị trí công việc',
  [PricingKeys.Modal.JobPosition.option_1]: 'Trợ lý',
  [PricingKeys.Modal.JobPosition.option_2]: 'C-Level · VP',
  [PricingKeys.Modal.JobPosition.option_3]: 'Giám đốc',
  [PricingKeys.Modal.JobPosition.option_4]: 'Nhà quản lý',
  [PricingKeys.Modal.JobPosition.option_5]: 'Trưởng phòng',
  [PricingKeys.Modal.JobPosition.option_6]: 'Cty độc lập',
  [PricingKeys.Modal.JobPosition.option_7]: 'Quản lý · Trưởng nhóm',
  [PricingKeys.Modal.JobPosition.option_8]: 'Thực tập sinh',
  [PricingKeys.Modal.JobPosition.option_9]: 'Thành viên nhóm',

  // Final
  [PricingKeys.Modal.Final.explain]: `
    <li>Vui lòng tải lên một trong các tệp sau để xác minh: chứng chỉ đăng ký doanh nghiệp, hóa đơn thuế, thẻ nhân viên hoặc các tài liệu hỗ trợ khác xác minh sự tồn tại của công ty của bạn.</li>
    <li>Tệp phải có định dạng JPG, JPEG, PNG hoặc PDF với độ phân giải ít nhất là 300dpi.</li>`,
  [PricingKeys.Modal.Final.image_placeholder]:
    'Tải lên một tệp có định dạng JPG, JPEG, PNG hoặc PDF. Tối đa 5 MB.',
  [PricingKeys.Modal.Final.description_placeholder]:
    'Vui lòng cho chúng tôi biết về công ty của bạn và sản phẩm, dịch vụ hoặc thương hiệu bạn đang muốn quảng cáo thông qua nền tảng của chúng tôi.',
  [PricingKeys.Modal.Final.spam_explain]:
    '* Nếu bạn không tìm thấy, vui lòng kiểm tra trong thư mục thư rác của bạn.',

  // Popup
  [PricingKeys.Modal.Popup.title]: 'Biểu mẫu của bạn đã được gửi thành công!',
  [PricingKeys.Modal.Popup.description]:
    'Xác minh thường mất từ 1 đến 3 ngày làm việc. Sau khi xem xét, bạn sẽ nhận được thông báo qua email với kết quả.',

  [PricingKeys.Modal.submission.toast]:
    'Gửi thành công! Việc xác minh thường mất 1-3 ngày làm việc.<br/> Chúng tôi sẽ thông báo cho bạn kết quả sớm.',

  // Error
  [PricingKeys.Modal.Error.required_field]: 'Trường bắt buộc',
  [PricingKeys.Modal.Error.select_option]: 'Vui lòng chọn một tùy chọn',
  [PricingKeys.Modal.Error.valid_url]: 'Nhập một URL hợp lệ.',
  [PricingKeys.Modal.Error.valid_email]: 'Nhập một địa chỉ email hợp lệ',
  [PricingKeys.Modal.Error.email_validation]:
    'Không thể sử dụng email cá nhân (ví dụ: gmail.com, yahoo.com, vv không được phép)',
  [PricingKeys.Modal.Error.already_verified]:
    'Địa chỉ email này đã được sử dụng để xác minh',
  [PricingKeys.Modal.Error.already_sent_code]:
    'Mã đã được gửi đến địa chỉ email này. Vui lòng kiểm tra hộp thư đến của bạn.',
  [PricingKeys.Modal.Error.invalid_code]: 'Mã không hợp lệ',
  [PricingKeys.Modal.Error.invalid_file_format]:
    'Định dạng tệp không hợp lệ. (Vui lòng sử dụng {{fileFormats}})',
  [PricingKeys.Modal.Error.total_file_size]:
    'Giới hạn tổng kích thước tệp là tối đa {{maxSize}}',
  [PricingKeys.Modal.Error.send_max_files]:
    'Bạn có thể gửi tối đa {{maxFiles}} tệp',
};
