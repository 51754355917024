export const MinusCircleGradientIcon = ({ size = 1, fill }) => (
  <svg
    width={20 * size}
    height={20 * size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 11H15V9H5V11ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6873 3.825 17.975 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.262667 12.6833 0 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31267 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.31233 6.1 0.787C7.31667 0.262333 8.61667 0 10 0C11.3833 0 12.6833 0.262333 13.9 0.787C15.1167 1.31233 16.175 2.025 17.075 2.925C17.975 3.825 18.6873 4.88333 19.212 6.1C19.7373 7.31667 20 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6873 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6873 13.9 19.212C12.6833 19.7373 11.3833 20 10 20Z"
      fill={fill ? fill : 'url(#paint0_linear_31864_72376)'}
    />
    <defs>
      <linearGradient
        id="paint0_linear_31864_72376"
        x1="0.668055"
        y1="1.66285"
        x2="21.7214"
        y2="4.0249"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EDD6FF" />
        <stop offset="1" stopColor="#E4E2FF" />
      </linearGradient>
    </defs>
  </svg>
);
